import React, { useState, useContext, useEffect } from "react";
import IstavrityContext from "../../context/istavrity/istavrityContext";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import { SET_ALERT } from "../../context/types";
import { YearSelect, MonthSelect } from "@srph/react-date-select";

import CsvDownload from "react-json-to-csv";

const ContributionDetails = (props) => {
  let misccode = props.location.state;
  const { code, key, desc } = misccode;
  const istavrityContext = useContext(IstavrityContext);
  const { getArghyaContributionDetails } = istavrityContext;
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const initialState = {
    result: [],
    initResult: [],
    year: "",
    month: "",
    showFilter: false,
    currency: ""
  };
  const [state, setState] = useState(initialState);
  const { result, year, month, showFilter, initResult } = state;

  const setYear = (value) => {
    if (isNaN(parseInt(value))) {
      value = "";
    }
    //if(isNaN(parseInt(value))){value=""}
    setState({ ...state, year: value });
  };
  const setMonth = (value) => {
    if (isNaN(parseInt(value))) {
      value = "";
    }
    setState({ ...state, month: value });
  };

  const navigateToSummary = (id)=>{
    props.history.push({
      pathname: "/deposititemsummary/"+id,
     // state: id,
    });
  }

  useEffect(() => {
    getDetails(key);
  }, []);

  const getDetails = async (key) => {
    startSpinner();
    let res = await getArghyaContributionDetails(key);
    setState({ ...state, initResult: res.records, result: res.records, currency: res.currency });
    // if(!res.records.length)
    // //if (res.type == "danger") {
    //   setAlert(res);
    // } else {
    //   setState({ ...state, initResult: res.msg.records, result: res.msg.records });
    // }
    stopSpinner();
  };

  const hideFilterDiv = () => {
    //getDetails(key);
    setState({
      ...state,
      result: initResult,
      showFilter: false,
      year: "",
      month: "",
    });
  };
  const showFilterDiv = () => {
    setState({ ...state, showFilter: true });
  };

  const filterResults = () => {
    if (year != "" && month != "") {
      var filteredResults = initResult.filter(
        (r) =>
          r.depositDate.substring(0, 4) == year &&
          r.depositDate.substring(5) == month
      );
      setState({ ...state, result: filteredResults });
    }

    if (year != "" && month == "") {
      var filteredResults = initResult.filter(
        (r) => r.depositDate.substring(0, 4) == year
      );
      setState({ ...state, result: filteredResults });
    }

    if (year == "" && month != "") {
      var filteredResults = initResult.filter(
        (r) => r.depositDate.substring(5) == month
      );
      setState({ ...state, result: filteredResults });
    }
  };
  return (
    <div className="uk-padding-large">
      <div uk-grid="true" className="uk-grid uk-child-width-1-2@s">
        <div>
          <div>
            <span className="uk-text-lead uk-text-bold">Code: </span>
            <span className="uk-text-large">{key}</span>
          </div>
          <div className="uk-margin-small">
            <span className="uk-text-lead uk-text-bold">Description: </span>
            <span className="uk-text-large uk-text-capitalize">{desc}</span>
          </div>
        </div>
        <div>
          <div className="uk-margin-small uk-flex-right">
            <span className="uk-text-lead uk-text-bold">Total Amount: </span>
            <span className="uk-text-large">
              {result.reduce((a, b) => +a + +b["amount"], 0)} {state.currency}
            </span>
          </div>
          <div className="uk-margin-small uk-flex-right">
            <CsvDownload
              className="uk-button uk-button-primary uk-button-small"
              data={result}
              filename={key + ".csv"}
            />
          </div>
        </div>
      </div>

      <div className="uk-margin-top">
        {showFilter ? (
          <div id="filter" className="uk-padding-small">
            <div className="uk-padding-small">
              <button
                className="uk-button uk-button-link uk-align-right"
                onClick={hideFilterDiv}
              >
                Hide Filter
              </button>
            </div>

            <div className="uk-padding-small uk-child-width-1-3@s">
              <YearSelect
                className="uk-select"
                start="2019"
                value={year}
                onChange={setYear}
              />
              <MonthSelect
                className="uk-select uk-text-small@s"
                value={month}
                onChange={setMonth}
              />
              <button
                className="uk-button uk-button-primary"
                onClick={filterResults}
              >
                FILTER
              </button>
            </div>
          </div>
        ) : (
          <button
            className="uk-button uk-button-link uk-align-right"
            onClick={showFilterDiv}
          >
            Show Filter
          </button>
        )}

        <table
          id="contributiondetails"
          className="uk-table uk-custom-table uk-table-striped uk-box-shadow-small"
        >
          <thead>
            <th>Family Code</th>
            <th>Member Name</th>
            <th>Amount</th>
            <th>Date</th>
            <th>RefId</th>
            <th>ApprovedBy</th>
            <th>SubmissionLink</th>
          </thead>
          <tbody>
            {result.map((entry) => (
              <>
                <tr key={entry.fcnum}>
                  <td>{entry.fcnum}</td>
                  <td>{entry.name}</td>
                  <td>{entry.amount}</td>
                  <td>{entry.depositDate}</td>
                  <td>{entry.submissionId}</td>
                  <td>{entry.approvedby === 'Direct Payment' ? 'STRIPE' : entry.approvedby}</td>
                  <td>
                    <a href="" onClick={()=>navigateToSummary(entry.ref)}>View</a>
                    </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>

      <button
        className="uk-button uk-button-primary uk-align-center uk-box-shadow-small"
        onClick={() => props.history.goBack()}
      >
        BACK
      </button>
    </div>
  );
};
export default ContributionDetails;
