import React from 'react'

const eventtable = ({events,history,url}) => {

    const OnSelectRow = (event) => {
        console.log("Inside row")
        console.log(event._id);
        history.push({
          pathname: "/"+url,
          state: { ...event }
        })
      }
    return (
        <div>
             <div className="uk-text-meta uk-text-right">Click record to view more details</div>
      <table className="uk-table uk-table-striped uk-table-hover">
        <thead >
          <th className="uk-background-cb-2">Event Year</th>
          <th className="uk-background-cb-2">Event Month</th>
          <th className="uk-background-cb-2">County</th>
          <th className="uk-background-cb-2">State</th>
          <th className="uk-background-cb-2">Date</th>
        </thead>
        <tbody>

          {events.map(event =>
            <tr onClick={() => OnSelectRow(event)}>
              <td>{event.eventyear}</td>
              <td>{event.eventmonth}</td>
              <td>{event.eventcountry}</td>
              <td>{event.eventstate}</td>
              <td>{event.eventdate.substr(0, 10)}</td>

            </tr>
          )}
        </tbody>
      </table>
      {events.length == 0 &&
        <span className="uk-text-center uk-background-muted">No Records Found</span>}
        </div>
    )
}

export default eventtable
