import React, { PureComponent, useState } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import axios from "axios";
import { getToken } from "../../storage";

const colors = scaleOrdinal(schemeCategory10).range();
const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
  y + height
} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default class Dashboard extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/rnywhbu8/";

  state = {
    members: [],
    families: [],
  };

  async componentDidMount() {
    const config = {
      headers: {
        "x-auth-token": getToken(),
      },
    };
    let res_gurubhais = await axios.get(
      process.env.REACT_APP_API_URL + "/api/user/getnumuserspercountry",
      config,
    );
    this.setState({ members: res_gurubhais.data });
    let res_families = await axios.get(
      process.env.REACT_APP_API_URL + "/api/user/getnumfamiliesspercountry",
      config
    );
    this.setState({ families: res_families.data });
  }

  render() {
    const { members, families } = this.state;

    return (
      <>
        <h1 className="uk-text-center uk-bolder">DASHBOARD</h1>
        <div className="">
          <div className="uk-width-1-1">
            <BarChart
              className="uk-align-center"
              width={300}
              height={400}
              data={families}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 5,
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 4" />

              <XAxis type="number" />
              <YAxis dataKey="_id" type="category" />
              <Tooltip />

              <Bar dataKey="count" fill="#8884d8">
                {families.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                ))}
              </Bar>
            </BarChart>
            <h6 className="uk-text uk-text-meta uk-bolder uk-text-center">
              NUMBER OF FAMILIES PER COUNTRY
            </h6>
          </div>

          <div className="">
            <BarChart
              className="uk-align-center"
              width={300}
              height={400}
              data={members}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 5,
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="_id" type="category" />
              <Tooltip />

              <Bar
                dataKey="count"
                fill="#8884d8"
                label={{ position: "center" }}
              >
                {members.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]}>
                    {entry._id}
                  </Cell>
                ))}
              </Bar>
            </BarChart>
            <h6 className="uk-text uk-text-meta uk-bolder uk-text-center">
              NUMBER OF GURUBHAIS PER COUNTRY
            </h6>
          </div>
        </div>
      </>
    );
  }
}
