import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarList from "./NavbarList";
import AccountContext from "../../context/accounts/accountContext";




const Navbar = (props) => {
  const { title } = props;
  const accountContext = useContext(AccountContext);
  const {isloggedOn} = accountContext;
  return (
   isloggedOn ? <>
      <nav className="uk-navbar uk-background-cb-2">
        <div className="uk-navbar-left">
          <span className="uk-text-bold uk-text-uppercase uk-text-large uk-padding-small">
            {title}
          </span>
        </div>
        <div className="uk-navbar-center uk-visible@l">
          {/* <img
            src="https://satsanngoceaniaimages.s3-ap-southeast-2.amazonaws.com/logo.jpg"
            className="uk-navbar-item uk-logo uk-width-small uk-height-xsmall"
            alt="Logo"
          ></img> */}
        </div>
        <div className="uk-navbar-right uk-visible@s">
          <ul className="uk-navbar-nav ">
            <NavbarList 
           
            />
          </ul>
        </div>

        <div className="uk-navbar-right uk-hidden@s">
          <button
            className="uk-button uk-button-default"
            type="button"
            uk-toggle="target: #toggle-usage"
          >
            <span uk-icon="menu"></span>
          </button>
        </div>
      </nav>
      <ul
        id="toggle-usage"
        uk-toggle="target: #toggle-usage"
        className="uk-nav uk-background-cb-2 uk-text-uppercase uk-text-center uk-hidden@m"
        hidden
      >
        <NavbarList />
        <br></br>
      </ul>
    </> : <></>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};
Navbar.defaultProps = {
  title: " Istavrity Portal",
};

export default Navbar;
