export const getPrivacyPolicy=()=>{
    return `
    <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
    <html>
    <head>
        <meta http-equiv="content-type" content="text/html; charset=windows-1252"/>
        <title></title>
        <meta name="generator" content="LibreOffice 6.4.5.2 (Windows)"/>
        <meta name="created" content="2020-11-03T17:39:00"/>
        <meta name="changed" content="2020-12-19T21:24:30.360000000"/>
        <style type="text/css">
            @page { size: 21.59cm 27.94cm; margin: 2cm }
            p { margin-bottom: 0.25cm; color: #000000; line-height: 115%; orphans: 2; widows: 2; background: transparent }
            p.western { font-family: "Liberation Serif", serif; font-size: 12pt }
            p.cjk { font-family: "Arial"; font-size: 12pt; so-language: hi-IN }
            p.ctl { font-family: "Liberation Serif"; font-size: 12pt; so-language: en-US }
            h1 { margin-bottom: 0.21cm; color: #000000; orphans: 2; widows: 2; background: transparent; page-break-after: avoid }
            h1.western { font-family: "Liberation Sans", serif; font-size: 24pt; font-weight: bold }
            h1.cjk { font-family: "Arial"; font-size: 24pt; so-language: hi-IN; font-weight: bold }
            h1.ctl { font-family: "Liberation Serif"; font-size: 24pt; so-language: en-US; font-weight: bold }
            h2 { margin-top: 0.35cm; margin-bottom: 0.21cm; color: #000000; orphans: 2; widows: 2; background: transparent; page-break-after: avoid }
            h2.western { font-family: "Liberation Sans", serif; font-size: 18pt; font-weight: bold }
            h2.cjk { font-family: "Arial"; font-size: 18pt; so-language: hi-IN; font-weight: bold }
            h2.ctl { font-family: "Liberation Serif"; font-size: 18pt; so-language: en-US; font-weight: bold }
            a:link { color: #000080; so-language: zxx; text-decoration: underline }
        </style>
    </head>
    <body lang="en-AU" text="#000000" link="#000080" vlink="#800000" dir="ltr"><h1 class="western" style="margin-top: 0.71cm; line-height: 137%">
   
    
    </p>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Satsang
    Oceania Inc. </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">is
    committed to protecting your privacy and giving you a safe online
    experience. This Privacy Policy applies to all of the products,
    services and websites offered by </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Satsang
    Oceania Inc.</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
    or its subsidiaries or affiliated companies. Sometimes, we may post
    product specific privacy notices or materials to explain our services
    and products in more detail. If you have any questions about this
    Privacy Policy, please feel free to contact us through our website.</span></span></font></font></span></font></span></p>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Last
    Updated: </b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>19</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>/</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>12</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>/2020</b></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Advertising
    Cookie and OPT-OUT</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
    don&rsquo;t use any cookies from Facebook, google or any advertisers
    and don&rsquo;t display any advertisements.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Information
    We Collect and How We Use It</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
    may collect the following types of information:</span></span></font></font></span></font></span></p>
    <ul>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Information
        you provide -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        When you sign up with Satsang Oceania Inc&rsquo;</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">s
        istavrityportal</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">,
        we ask you for personal information. We </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">do
        not share your information with any</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        third parties.</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Your
        information won&rsquo;t be accessed outside of this portal. Only
        </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">authenticated</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        users(admins and superadmins) may access your information from time
        to time if required. </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">This
        data is retained for as long as you have an active account with
        Satsang Oceania Inc..</span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Cookies
        </b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">-
        When you visit Satsang Oceania Inc., we </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">do
        not </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">send
        </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">any</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        cookies to your device. </span></span></font></font></span></font></span>
        </p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">However,
        our payment gateway(Stripe) uses cookies for security purposes. You
        can find Stripe&rsquo;s privacy policy by clicking this link:
        <a href="https://stripe.com/en-au/privacy">https://stripe.com/en-au/privacy</a></span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Log
        information -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        When you access the services of Satsang Oceania Inc. via browser,
        application, or other clients, our servers automatically record
        certain information. These server logs may include information such
        as your web request, your interaction with a service, Internet
        Protocol address, browser type, browser language, the date and time
        of your request and one or more cookies that may uniquely identify
        your browser or your account. These logs are retained for a period
        of </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">365
        </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">days
        and are used for anti-fraud and website security purposes. After
        this time, these logs are automatically deleted.</span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>User
        communications -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        When you send an email or other communications to Satsang Oceania
        Inc., we may retain those communications in order to process your
        inquiries, respond to your requests and improve our services. When
        you send and receive SMS messages to or from one of our services
        that provides SMS functionality, we may collect and maintain
        information associated with those messages, such as the phone
        number, the wireless carrier associated with the phone number, the
        content of the message, and the date and time of the transaction. We
        may use your email address to communicate with you about our
        services but never to send you communications that are not related
        to </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">your
        account.</span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Third
        Party Applications -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        Satsang Oceania Inc. may make available third party applications,
        such as plugins, modules or extensions, through its services. The
        information collected by Satsang Oceania Inc. when you enable a
        third party application is processed under this Privacy Policy.
        Information collected by the third party application provider is
        governed by their privacy policies.</span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Location
        data -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        Satsang Oceania Inc. </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">may
        </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">offer
        location-enabled services. If you use those services, Satsang
        Oceania Inc. may receive information about your actual location
        (such as GPS signals sent by a mobile device) or information that
        can be used to approximate a location (such as a cell ID). This data
        is not recorded without your permission and is not held for any
        longer than the period required to deliver the specific service.</span></span></font></font></span></font></span></p>
        <p class="western" style="margin-bottom: 0cm; line-height: 137%"></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><b>Other
        sites -</b></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
        This Privacy Policy applies to Satsang Oceania Inc.'s services only.
        We do not exercise control over the sites displayed as search
        results, sites that include Satsang Oceania Inc. applications,
        products or services, or links from within our various services.
        These other sites may place their own cookies or other files on your
        computer, collect data or solicit personal information from you.</span></span></font></font></span></font></span></p>
    </ul>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><br/>
    <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">In
    addition to the above, we may use the information we collect to:</span></span></font></font></span></font></span></p>
    <ul>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Provide,
        maintain, protect, and improve our services and develop new
        services; and</span></span></font></font></span></font></span></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Protect
        the rights or property of Satsang Oceania Inc. or our users.</span></span></font></font></span></font></span></p>
    </ul>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">If
    we use this information in a manner different than the purpose for
    which it was collected, then we will ask for your consent prior to
    such use.</span></span></font></font></span></font></span><br/>
    <br/>
    <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Satsang
    Oceania Inc. processes personal information on our servers in
    </span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Australia</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">.
    In some cases, we process personal information outside your own
    country.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Choices</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">You
    can use your account on the Satsang Oceania Inc&rsquo;</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">s
    itavrityportal</span></span></font></font></span></font></span><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">
    website to review and control the information stored in your Satsang
    Oceania Inc. account. Most browsers are initially set up to accept
    cookies, but you can reset your browser to refuse all cookies or to
    indicate when a cookie is being sent. However, some of the Satsang
    Oceania Inc. features and services may not function properly if your
    cookies are disabled.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Information
    Sharing</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Satsang
    Oceania Inc. only shares personal information with other companies or
    individuals outside of Satsang Oceania Inc. in the following limited
    circumstances:</span></span></font></font></span></font></span></p>
    <ul>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
        have your consent. We require opt-in consent for the sharing of any
        sensitive personal information.</span></span></font></font></span></font></span></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">If
        we provide such information to our subsidiaries, affiliated
        companies or other trusted businesses or persons for the purpose of
        processing personal information on our behalf. We require that these
        parties agree to process such information based on our instructions
        and in compliance with this Privacy Policy and any other appropriate
        confidentiality and security measures.</span></span></font></font></span></font></span></p>
        <li><p class="western" style="margin-bottom: 0cm; line-height: 137%">
        <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
        have a good faith belief that access, use, preservation or
        disclosure of such information is reasonably necessary to (a)
        satisfy any applicable law, regulation, legal process or enforceable
        governmental request, (b) enforce applicable Terms of Service,
        including investigation of potential violations thereof, (c) detect,
        prevent, or otherwise address fraud, security or technical issues,
        or (d) protect against harm to the rights, property or safety of
        Satsang Oceania Inc., its users or the public as required or
        permitted by law.</span></span></font></font></span></font></span></p>
    </ul>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">If
    Satsang Oceania Inc. becomes involved in a merger, acquisition, or
    any form of sale of some or all of its assets, we will ensure the
    confidentiality of any personal information involved in such
    transactions and provide notice before personal information is
    transferred and becomes subject to a different privacy policy.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Information
    Security</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
    take appropriate security measures to protect against unauthorized
    access to or unauthorized alteration, disclosure or destruction of
    data. These include internal reviews of our data collection, storage
    and processing practices and security measures, including appropriate
    encryption and physical security measures to guard against
    unauthorized access to systems where we store personal data.</span></span></font></font></span></font></span><br/>
    <br/>
    <span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
    restrict access to personal information to Satsang Oceania Inc.
    employees, contractors and agents who need to know that information
    in order to process it on our behalf. These individuals are bound by
    confidentiality obligations and may be subject to discipline,
    including termination and criminal prosecution if they fail to meet
    these obligations.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Accessing
    And Updating Personal Information</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">When
    you use Satsang Oceania Inc. services, we make good faith efforts to
    provide you with access to your personal information and either to
    correct this data if it is inaccurate or to delete such data at your
    request if it is not otherwise required to be retained by law or for
    legitimate business purposes.&nbsp;</span></span></font></font></span></font></span></p>
    <p class="western" style="line-height: 115%"><br/>
    <br/>
    
    </p>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">We
    ask individual users to identify themselves and the information
    requested to be accessed, corrected or removed before processing such
    requests, and we may decline to process requests that are
    unreasonably repetitive or systematic, require disproportionate
    technical effort, jeopardize the privacy of others, or would be
    extremely impractical (for instance, requests concerning information
    residing on backup tapes), or for which access is not otherwise
    required. In any case, where we provide information access and
    correction, we perform this service free of charge, except if doing
    so would require a disproportionate effort. Because of the way we
    maintain certain services, after you delete your information,
    residual copies may take a period of time before they are deleted
    from our active servers and may also remain in our backup systems for
    a period of time.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Enforcement</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Satsang
    Oceania Inc. regularly reviews its compliance with this Privacy
    Policy. When we receive formal written complaints, it is Satsang
    Oceania Inc.'s policy to contact the complaining user regarding his
    or her concerns. We will cooperate with the appropriate regulatory
    authorities, including local data protection authorities, to resolve
    any complaints regarding the transfer of personal data that cannot be
    resolved between Satsang Oceania Inc. and an individual.</span></span></font></font></span></font></span></p>
    <h2 class="western" style="margin-top: 0.64cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="4" style="font-size: 16pt"><span style="font-style: normal"><span style="font-weight: normal">Changes
    To This Privacy Policy</span></span></font></font></span></font></span></h2>
    <p class="western" style="margin-bottom: 0cm; line-height: 137%"><span style="font-variant: normal"><font color="#000000"><span style="text-decoration: none"><font face="Arial, serif"><font size="2" style="font-size: 11pt"><span style="font-style: normal"><span style="font-weight: normal">Please
    note that this Privacy Policy may change from time to time. We will
    not reduce your rights under this Privacy Policy without your
    explicit consent. We will post any Privacy Policy changes on this
    page and, if the changes are significant, we will provide a more
    prominent notice (including, for certain services, email notification
    of Privacy Policy changes).</span></span></font></font></span></font></span></p>
    <p class="western" style="line-height: 115%"><br/>
    <br/>
    <br/>
    
    </p>
    </body>
    </html>`
}
