import React, {  useEffect, useState } from "react";
import Enums from "../../enums/Enums";
import { Button } from "../presentation";
import { IoMdStarOutline,IoIosPhonePortrait } from "react-icons/io";
import { FaStar } from "react-icons/fa6";
import { FaUser, FaBook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import AccountContext from "../../context/accounts/accountContext";
import { useLibraryInterest } from "../../hooks/useLibraryInterest";
import { set } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BookInterests = ({  book, submitLendRequest, totalCopies,isInterestPage }) => {
  const history = useHistory();
  const accountContext = React.useContext(AccountContext);
  const { user } = accountContext;
  const { expressInterest, getInterests, interests, loading } =
    useLibraryInterest();
  const [isInterested, setIsInterested] = useState(false);

  useEffect(() => {
    getInterests({book});
  }, [book]);

  useEffect(() => {
    console.log("Interests123", interests);
    if (interests.length) {
      setIsInterested(true);
    }
  }, [interests]);

  const onClickExpressInterest = async (bookId) => {
    await expressInterest({
      bookId,
      accountId: user._id,
    });
    setIsInterested(!isInterested);
  };

  const onClickSubmit = async (bookId, userId) => {
    await submitLendRequest(userId);
    await getInterests({book:bookId})
  };

  const onBookClick = (book) => {
    console.log("Book Clicked", book);
    history.push({ pathname: "/book/" + book._id});
  };

  const showSection = () => {
    if (
      book &&
      user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.MEMBER
    ) {
      return (
        <div className="uk-flex uk-flex-center ">
          <button
            className="uk-button uk-button-link uk-margin-small-left uk-button-right uk-text-large"
            onClick={() => onClickExpressInterest(book)}
          >
            {isInterested ? <FaStar /> : <IoMdStarOutline />}

            {isInterested ? " Interested" : " Show Interest"}
          </button>
        </div>
      );
    }

    const showSecondSection = (interest) => {

      if(isInterestPage){
        return (
          <div className="uk-flex uk-flex-middle">
              <FaBook
                className="uk-icon uk-margin-right"
                style={{ fontSize: "2rem", color: "#2196F3" }}
              />
              <div className="">
                <h4 className="uk-card-title uk-margin-remove">
                  <button className="uk-button uk-button-link" onClick={()=>onBookClick(interest.book)}>
                  {interest.book.title}
                  </button>
                
                </h4>
              </div>
            </div>
        )
      }
      if (user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN) {
        return (
          <div>
            <button
              className="uk-button uk-button-secondary"
              disabled={!totalCopies}
              onClick={() =>
                onClickSubmit(interest.book._id, interest.user._id)
              }
            >
              Lend
            </button>
          </div>
        );
      }

    }

    return (
      <div className="uk-margin-top">
        <h3
        className="uk-text-center uk-text-bold"
        >{interests.length ? 'Interests' : 'No Interests Recorded'}</h3>
       
       
        {interests.map((interest, index) => (
        
          <div
            key={index}
            className="uk-card uk-card-default uk-margin uk-padding uk-flex uk-flex-between uk-flex-middle"
          >
            {/* Left Section: User Details */}
            <div className="uk-flex uk-flex-middle">
              <FaUser
                className="uk-icon uk-margin-right uk-border uk-margin uk-border-pill"
                style={{ fontSize: "2rem", color: "#4CAF50" }}
              />
              <div>
                <h4 className="uk-card-title uk-margin-remove">
                  Name: {interest.user.name}
                </h4>
                <p className="uk-margin-remove">{interest.state}, {interest.country}</p>
                <p className="uk-margin-remove"><MdEmail />: {interest.user.email}</p>
            
                <p className="uk-margin-remove"><IoIosPhonePortrait />: {interest.user.phone}</p>
              </div>
            </div>

            {showSecondSection(interest)}
           
          </div>
        ))}
      </div>
    );
  };

  return <div>{showSection()}</div>;
};
// default props
BookInterests.defaultProps = {
  bookId: null,
  submitLendRequest: () => {},
  totalCopies: 0,
  isInterestPage:true
};

export default BookInterests;
