import React, { useState, useContext } from "react";
import IstavrityContext from "../../../context/istavrity/istavrityContext";
import AlertContext from "../../../context/alert/alertContext";
import SpinnerContext from "../../../context/spinner/spinnerContext";
import AustraliaDirectTransfer from "./AustraliaDirectTransfer";
import { CardComponent } from "./Card/CardComponent";
import payByCardImg from '../../../img/paybycard.png'
import tapIcon from '../../../img/tapIcon.png'
import payByBankTransferImg from '../../../img/payByDirectTransfer.png'
import Enums from "../../../enums/Enums";

const AustraliaCheckoutForm = ({ history }) => {
  const istavrityContext = useContext(IstavrityContext);
  const alertContext = useContext(AlertContext);
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const initialState = istavrityContext.current;
  const {getStripeKeys,payDeposits}=istavrityContext;
  const { sumTotal, status, type = "arghya", subType = "general", _id, currency, refId } = initialState;

  
  if(!_id){
    history.push('/submissions/')
  }
  const [state, setState] = useState({
    paymentreferece: "",
    showCard: false,
    showBankDeposit: false,
  });

  const showCardDiv = () => {
    setState({ ...state, showCard: true, showBankDeposit: false });
  };

  const showBankDepositDiv = () => {
    setState({ ...state, showCard: false, showBankDeposit: true });
  };

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value.replace(/[^A-Z0-9]+/i, "").toUpperCase(),
    });
  };
  const { paymentreference, showCard, showBankDeposit } = state;

  const onSubmit = async (e) => {
    e.preventDefault();
    let res = await istavrityContext.updateSubmission({
      paymentreference,
      depositId: _id,
    });
    history.push("/deposititemsummary/" + _id);
    alertContext.setAlert(res);
  };

  const getPaymentOptions = () => {
    return (
      <div className="uk-margin-top uk-width-1-2@s  uk-flex uk-flex-column uk-align-center ">
        <h1>Select payment method</h1>
        <div className="uk-card uk-card-default"
          onClick={showCardDiv}
          id="payBycard"
        >
          <div className="uk-card-media-top">
            <img src={payByCardImg} width="100" height="100" alt="" />
          </div>
          <div className="uk-card-body">
            <h3 className="uk-card-title">Pay By Card  <img className="uk-align-right" src={tapIcon} width="50" height="50" /></h3>

            <p>Uses Stripe payment gateway.</p>
          </div>
        </div>
        <br></br>
        <div className="uk-card uk-card-default uk-margin-top"
          onClick={showBankDepositDiv}
          id="payByBankTransfer"
        >
          <div className="uk-card-media-top">
            <img src={payByBankTransferImg} width="150" height="150" alt="" />
          </div>
          <div className="uk-card-body">
            <h3 className="uk-card-title">Pay By Bank Transfer
              <img className="uk-align-right" src={tapIcon} width="50" height="50" />
            </h3>
            <p>Use your internet or mobile banking to transfer the amount</p>
          </div>
        </div>
      </div>
    )
  }
  const requiresPaymentOptions = () =>
    ((type == Enums.DEPOSIT_TYPE.arghya && subType == Enums.DEPOSIT_SUBTYPE.general) || type == Enums.DEPOSIT_TYPE.pronami);

  const shouldShowPaymentOptions = () => {
    return requiresPaymentOptions()
      && !showCard && !showBankDeposit
  }

  const showDirectDeposit = () => {

    const accountNumber =
      type === Enums.DEPOSIT_TYPE.arghya && subType === Enums.DEPOSIT_SUBTYPE.general ? "424 282 890" :
        "416 062 176";

    return (
      <div className="uk-grid-match uk-width-1-1 uk-child-width-1-2@s uk-text-center uk-margin-top" uk-grid="true"
      id="directDeposit">
        <div className="uk-card uk-card-body uk-card-primary uk-text-center uk-text-middle ">

          <img src={payByBankTransferImg} width="150" height="150" alt="" />
        </div>
        <div className="uk-align-center uk-width-1-2@s uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body ">
            <AustraliaDirectTransfer
              bsb="112-879"
              accountnum={accountNumber}
              bank="St George"
              refId={refId}

            />
            <form onSubmit={onSubmit}>
              <hr></hr>
              <div className="uk-card uk-card-body uk-background-grey">
                <span className="uk-text-large uk-text-white" id="transferamount">
                  Amount: {sumTotal} {currency}
                </span>
              </div>
              <hr></hr>
              {/* <span className="uk-text uk-text-large">Enter payment reference: </span>
              <input
                className="uk-input uk-form-width-medium uk-margin-left"
                type="text"
                name="paymentreference"
                value={paymentreference}
                placeholder="Payment Reference"
                onChange={onChange}
                required
              /> */}
              <hr className="uk-divider-icon"></hr>
              {status != "Done" && (
                <button
                  id="submitbtn"
                  className="uk-button uk-width-1-1 uk-button-success"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

    )

  }

  if (shouldShowPaymentOptions()) {
    return getPaymentOptions();
  }

  if (showCard || !requiresPaymentOptions()) return (
    <CardComponent
      type={type}
      _id={_id}
      getStripeKeys={getStripeKeys}
      currency={currency}
      payDeposits={payDeposits}
      startSpinner={startSpinner}
      stopSpinner={stopSpinner}
    />
  )

  if (showBankDeposit) return showDirectDeposit()
  // return (
  //   <>
  //     <div className="">
  //       {(type == "arghya" && subType == "general") ? 

  //             getPaymentOptions()

  //       :
  //         <CardComponent
  //           type = { type }
  //           sumTotal = { sumTotal }
  //           charge = { charge }
  //           finalAmount = { finalAmount }
  //           currency = { currency }
  //         />
  //       }


  //       {showCard && (
  //         <CardComponent
  //           type={type}
  //           sumTotal={sumTotal}
  //           charge={charge}
  //           finalAmount={finalAmount}
  //           currency={currency}
  //         />
  //       )}
  //       {showBankDeposit && (
  //         <div className="uk-align-center uk-width-1-2@s uk-width-1-1">
  //           <h5 className="uk-text-center uk-border  uk-background-grey uk-text-white">
  //             DIRECT DEPOSIT
  //             <span
  //               className="uk-padding-small"
  //               uk-icon="icon: pull ;ratio:1.5"
  //             ></span>
  //           </h5>
  //           <div className="uk-card uk-card-default uk-card-body ">
  //             <AustraliaDirectTransfer
  //               bsb="112879"
  //               accountnum="424282890"
  //               bank="St George Bank"
  //             />
  //             <form onSubmit={onSubmit}>
  //               <hr></hr>
  //               <h6>
  //                 <b>AMOUNT: {sumTotal}</b>
  //               </h6>
  //               <input
  //                 type="text"
  //                 name="paymentreference"
  //                 value={paymentreference}
  //                 placeholder="Payment Reference"
  //                 onChange={onChange}
  //                 required
  //               />
  //               <hr className="uk-divider-icon"></hr>
  //               {status != "Done" && (
  //                 <button
  //                   id="submitbtn"
  //                   className="uk-button  uk-width-1-1 uk-button-primary"
  //                   type="submit"
  //                 >
  //                   Submit
  //                 </button>
  //               )}
  //             </form>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </>
  // );
};

export default AustraliaCheckoutForm;
