import React from 'react'
import axios from 'axios';
import AccountContext from '../../context/accounts/accountContext'
import EventItem from './eventitem';
const event = (props) => {
  
    const {_id}=props.history.location.state;
        const onDelete = async()=>{
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": localStorage.getItem("token")
                }
              }
              const payload = {
                _id
              }
              try {
                let resp = await axios.post("api/events/delete", payload, config);
                  props.history.goBack();
              } catch (error) {
                
              }
        }
    return (

      <>
          <EventItem event={props.history.location.state} />
          <div className="uk-flex uk-flex-center">
           <button className="uk-button uk-button-default" onClick={()=>props.history.goBack()}>BACK</button>
           <button className="uk-button uk-button-danger" onClick={onDelete} >DELETE</button>
           </div>
        
        </>
    )
}

export default event
