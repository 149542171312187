
export const tnchtml=()=>{
    return `
<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=windows-1252"/>
	<title></title>
	<meta name="generator" content="LibreOffice 6.4.5.2 (Windows)"/>
	<meta name="created" content="2020-11-03T17:41:00"/>
	<meta name="changed" content="2020-12-16T12:44:13.754000000"/>
	<style type="text/css">
		@page { size: 21.59cm 27.94cm; margin: 2cm }
		p { margin-bottom: 0.25cm; color: #000000; line-height: 115%; orphans: 2; widows: 2; background: transparent }
		p.western { font-family: "Liberation Serif", serif; font-size: 12pt }
		p.cjk { font-family: "Arial"; font-size: 12pt; so-language: hi-IN }
		p.ctl { font-family: "Liberation Serif"; font-size: 12pt; so-language: en-US }
		h3 { margin-top: 0.25cm; margin-bottom: 0.21cm; color: #000000; orphans: 2; widows: 2; background: transparent; page-break-after: avoid }
		h3.western { font-family: "Liberation Sans", serif; font-size: 14pt; font-weight: bold }
		h3.cjk { font-family: "Arial"; font-size: 14pt; so-language: hi-IN; font-weight: bold }
		h3.ctl { font-family: "Liberation Serif"; font-size: 14pt; so-language: en-US; font-weight: bold }
		td p { color: #000000; orphans: 2; widows: 2; background: transparent }
		td p.western { font-family: "Liberation Serif", serif; font-size: 12pt }
		td p.cjk { font-family: "Arial"; font-size: 12pt; so-language: hi-IN }
		td p.ctl { font-family: "Liberation Serif"; font-size: 12pt; so-language: en-US }
	</style>
</head>
<body lang="en-AU" text="#000000" link="#000080" vlink="#800000" dir="ltr"><p class="western" style="margin-bottom: 0.5cm; font-variant: normal; letter-spacing: normal; font-style: normal; line-height: 100%; text-decoration: none">
<font face="Trebuchet MS, serif"><font size="20" style="font-size: 20pt">
<br></br>
<b id="header">Istavrity Portal Terms and Conditions</b></font></font></p>
<table width="100%" cellpadding="0" cellspacing="0">
	<col width="256*"/>

	<tr>
		<td width="100%" valign="top" style="border: none; padding: 0cm"><p class="western" align="left">
			&nbsp;</p>
			<table width="100%" cellpadding="0" cellspacing="0">
				<col width="256*"/>

				<tr>
					<td width="100%" style="border: none; padding: 0cm"><h3 class="western">
						TERMS OF USE</h3>
						<p class="western" style="margin-bottom: 0.5cm"><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
						Oceania Inc</font></font></font><font face="Liberation Serif, serif">
						requires that all clients using our </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Istavrity
						Portal</font></font></font><font face="Liberation Serif, serif">
						Site adhere to the following Terms of Use:</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Acknowledgement
						and Acceptance of Terms of Use</b></font></p>
						<ul>
							<li><p class="western"><font face="Liberation Serif, serif">You
							should read and familiarize yourself with these Terms of Use
							before you use this Web Site.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">By
							accessing the Site you expressly agree to these Terms of Use
							as they are amended from time to time and you acknowledge that
							you have read and understood them.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							may not assign or transfer your rights or benefits under these
							Terms of Use to any other person or entity without the prior
							written consent of </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
							Oceania Inc.</font></font></font></p>
							<li><p class="western" style="margin-bottom: 0.5cm"><font face="Liberation Serif, serif">If
							you do not agree with these Terms, you must discontinue your
							use of this site.</font></p>
						</ul>
						<p class="western" style="margin-bottom: 0.5cm"><font face="Liberation Serif, serif"><b>Governing
						Law</b></font><br/>
<br/>
<font face="Liberation Serif, serif">This
						agreement is governed by the law.</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Copyright</b></font><br/>
<br/>
<font face="Liberation Serif, serif">The
						trademarks and logos displayed on the Site are the property of
						</font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang</font></font></font><font face="Liberation Serif, serif">.
						Users are prohibited from copying, distributing, transmitting,
						displaying, publishing, selling, licensing, creating derivative
						works or using any content available on or through the Site for
						commercial or public purposes. The information provided in the
						Site is copyright to </font><font face="Liberation Serif, serif">Satsang</font><font face="Liberation Serif, serif">.</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Online
						Security</b></font></p>
						<ul>
							<li><p class="western"><font face="Liberation Serif, serif">We
							are committed to keeping secure the information you provide to
							us about you </font><font face="Liberation Serif, serif">and
							your family</font><font face="Liberation Serif, serif">. We
							take all reasonable precautions to protect this information
							from misuse and loss and from unauthorized access,
							modification or disclosure. We have a range of physical and
							technology policies in place to provide a robust security
							environment. We ensure the on-going adequacy of these measures
							by regular maintenance and reviews. This work will normally
							take place after working hours to minimize inconvenience. Our
							online security measures include, but are not limited to:</font></p>
							<ul type="square">
								<li><p class="western"><font face="Liberation Serif, serif">encrypting
								sensitive data sent from your computer to our systems during
								Internet transactions and client access codes transmitted
								across networks;</font></p>
								<li><p class="western"><font face="Liberation Serif, serif">employing
								firewalls, intrusion detection systems and virus scanning
								tools to prevent unauthorized persons and viruses from
								entering our systems; and</font></p>
								<li><p class="western"><font face="Liberation Serif, serif">using
								dedicated secure networks or encryption when we transmit
								electronic data.</font></p>
							</ul>
							<li><p class="western"><font face="Liberation Serif, serif">You
							will be responsible for providing the computer, hardware,
							software and all other equipment necessary to access the
							Internet.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							must not knowingly transmit any virus or other disabling
							feature to the Site.</font></p>
							<li><p class="western" style="margin-bottom: 0.5cm"><font face="Liberation Serif, serif">Access
							to your customers is protected by a security credential, which
							includes a</font><font face="Liberation Serif, serif">n email</font><font face="Liberation Serif, serif">
							and password. Access to this Site is strictly controlled and
							limited to </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
							Oceania</font></font></font><font face="Liberation Serif, serif">
							</font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">users</font></font></font><font face="Liberation Serif, serif">.</font></p>
						</ul>
						<p class="western" style="margin-bottom: 0.5cm"><font face="Liberation Serif, serif"><b>PIN
						(Personal Identification Number)</b></font></p>
						<ul>
							<p class="western"></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							acknowledge that </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
							Oceania Inc</font></font></font><font face="Liberation Serif, serif">
							is entitled to assume that all use of your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
							is by you, unless and until </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
							Oceania Inc </font></font></font><font face="Liberation Serif, serif">receives
							notification from you of a compromise of your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							are solely responsible for any loss or inconvenience caused by
							use of your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
							by you or any other person.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							must not send or disclose your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
							to any unauthorized person or entity or store it in a manner
							that would reasonably allow an unauthorized person or entity
							to obtain access to the </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							are solely responsible for the actions of any other person/s
							or entity you elect as your authorized representative/s. Such
							representative/s agrees to be bound by these Terms of Use.</font></p>
							<li><p class="western"><font face="Liberation Serif, serif">You
							must immediately notify us if you become aware:</font></p>
							<ul type="square">
								<li><p class="western"><font face="Liberation Serif, serif">that
								your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
								has been compromised or is known to a third party; or</font></p>
								<li><p class="western"><font face="Liberation Serif, serif">of
								any unauthorized use of your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">;
								so we can take steps to disactivate the </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
								and issue you with a new </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">password</font></font></font><font face="Liberation Serif, serif">
								within a reasonable time of being so notified. Notice
								provided by you under this clause does not release you from
								your responsibilities under this agreement.</font></p>
							</ul>
							<li><p class="western" style="margin-bottom: 0.5cm"><font face="Liberation Serif, serif">For
							security reasons we may require you to re-authenticate
							yourself after a period of inactivity (20 minutes) between
							your computer and our Site. We are not responsible for any
							information you may lose if our servers terminate your session
							due to prolonged periods of inactivity.</font></p>
						</ul>
						<p class="western"><font face="Liberation Serif, serif"><b>Changes
						to Terms of Use</b></font><br/>
<br/>
<font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
						Oceania Inc </font></font></font><font face="Liberation Serif, serif">reserves
						the right to revise these Terms of Use at any time and users
						are deemed to be apprised of and bound by any changes to these
						Terms of Use.</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Violations
						of Terms of Use</b></font><br/>
<br/>
<font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">Satsang
						Oceania Inc </font></font></font><font face="Liberation Serif, serif">reserves
						the right to seek all remedies available at law and in equity
						for violations of these Terms of Use, including the right to
						suspend, terminate or limit your access to the Site effective
						immediately. We reserve the right to cancel your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">user
						account</font></font></font><font face="Liberation Serif, serif">
						at any time without notice.</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Indemnity</b></font><br/>
<br/>
<font face="Liberation Serif, serif">You
						indemnify us and our employees from and against all actions,
						claims, suits, demands, damages, liabilities, costs or expenses
						arising out of or in any way connected to the use of the Site
						by you or someone using your </font><font color="#000000"><font face="Liberation Serif, serif"><font size="3" style="font-size: 12pt">email</font></font></font><font face="Liberation Serif, serif">
						and password.</font><br/>
<br/>
<font face="Liberation Serif, serif"><b>Disclaimer
						and Limitation of Liability</b></font><br/>
<br/>
<font face="Liberation Serif, serif">We
						do not warrant or guarantee the accuracy, adequacy or
						completeness of the information contained on this Site and
						expressly disclaim liability for any errors and/or omissions in
						this information. In addition, we do not warrant, guarantee or
						make any representations that access to this Site will be fully
						accessible at all times, uninterrupted or error-free.</font><br/>
<br/>
<font face="Liberation Serif, serif">To
						the maximum extent permitted by law, we disclaim liability for
						any damages, including, without limitation or indirect,
						special, incidental, compensatory, exemplary or consequential
						damages, losses or expenses arising out of or in connection
						with (1) use of or the inability to use this Site by any party;
						or (2) any failure or performance, error, omission,
						interruption or delay in operation or transmission; or (3) line
						or system failure or the introduction of a computer virus, or
						other technical sabotage.</font><br/>
<br/>
<font face="Liberation Serif, serif">To
						the maximum extent permitted by law, neither we nor any of our
						employees will be liable in any way (including for negligence)
						for any loss, damage, costs or expenses suffered by you or
						claims made against you through the use of the Site or any
						failure to provide the Site.</font></p>
					</td>
				</tr>
			</table>
			<p class="western"><br/>

			</p>
		</td>
	</tr>
</table>
<p class="western" style="margin-bottom: 0cm; line-height: 100%"><br/>

</p>
</body>
</html>
`
    
}