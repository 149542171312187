import React from "react";

export const BookDisplay = ({ coverPic, title, language, description, copiesAvailable, onClick, children }) => {
    // Use the default coverPic if the provided one is falsy (null, undefined, or empty string)
    const displayCoverPic = coverPic || BookDisplay.defaultProps.coverPic;

    return (
        <div className="uk-card uk-card-default uk-card-hover uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid="true" onClick={onClick}>
            {/* Left Side: Cover Picture in Rectangular Shape */}
            <div className="uk-card-media-left uk-cover-container" style={{ width: "150px", height: "200px" }}>
                <img
                    className="uk-margin"
                    src={displayCoverPic}
                    alt="Book Cover"
                    style={{ objectFit: "scale-down", width: "100%", height: "100%" }}
                />
            </div>

            {/* Right Side: Book Details */}
            <div>
                <div className="uk-card-body">
                    <h3 className="uk-card-title">{title || "Unknown Title"}</h3>
                    <p><strong>Language:</strong> {language || "English"}</p>
                    <p><strong>Copies Available:</strong> {copiesAvailable ?? "N/A"}</p>
                </div>
            </div>
            {children}
        </div>
    );
};

// Default Props for BookDisplay
BookDisplay.defaultProps = {
    coverPic: "https://nnpdev.wustl.edu/img/BookCovers/genericBookCover.jpg",
    title: "Untitled Book",
    description: "No description provided.",
    copiesAvailable: 0,
    language: "English",
};
