import React from "react";

const TotalDeposit = ({ total, sumTotal, currency }) => {
  let keys = Object.keys(total);

  return (
    <div
      id="totals"
      className="uk-card uk-card-body uk-card-small uk-box-shadow-small"
    >
      <h1 className="uk-text-center uk-card-title">
        <u>SUBTOTAL</u>
      </h1>
      <div className="uk-container uk-width-2-5@m">
        <table className="uk-table uk-table-striped uk-table-small uk-custom-table">
          <thead>
            <tr>
              <th>ACCOUNT_CODE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {keys.map((key, index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{total[`${key}`]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="uk-text-lead uk-text-large uk-text-bold">Total:</td>
              <td className="uk-text-lead uk-text-large uk-text-bold">
                {sumTotal.toFixed(2)} {currency}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default TotalDeposit;
