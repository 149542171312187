
export const clearLocalStorage = () => localStorage.clear();

export const setToken = (token) => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');

export const setImpersonatedToken = (token) => localStorage.setItem('impersonatedToken', token);
export const getImpersonatedToken = () => localStorage.getItem('impersonatedToken');
export const removeImpersonatedToken = () => localStorage.removeItem('impersonatedToken');

export const setTnCApprovalDate = (date) => localStorage.setItem('tncApprovalDate', date);
export const getTnCApprovalDate = () => localStorage.getItem('tncApprovalDate');

export const setSearchResults = (results) => localStorage.setItem('searchresults', JSON.stringify(results));
export const getSearchResults = () => JSON.parse(localStorage.getItem('searchresults'));

export const setImpersonated = (flag) => localStorage.setItem('impersonated', flag);
export const getImpersonated = () => localStorage.getItem('impersonated') || false;
export const removeImpersonated = () => localStorage.removeItem('impersonated');

export const setImpersonatedBy = (admin) => localStorage.setItem('impersonatedBy', admin);
export const getImpersonatedBy = () => localStorage.getItem('impersonatedBy');
export const removeImpersonatedBy = () => localStorage.removeItem('impersonatedBy');

export const setFilterCriteria = (criteria) => localStorage.setItem('filtercriteria', JSON.stringify(criteria));
export const getFilterCriteria = () => JSON.parse(localStorage.getItem('filtercriteria'))||{};
export const removeFilterCriteria = () => localStorage.removeItem('filtercriteria');

export const setMembersInStorage = (members) => localStorage.setItem('members', JSON.stringify(members));
export const getMembersFromStorage = () => JSON.parse(localStorage.getItem('members'))||[];
export const removeMembersFromStorage = () => localStorage.removeItem('members');

export const setUserInStorage = (user) => localStorage.setItem('user', JSON.stringify(user));
export const getUserFromStorage = () => JSON.parse(localStorage.getItem('user'));

export const setDepositCodesInStorage = (depositCodes) => localStorage.setItem('deposit_codes', JSON.stringify(depositCodes));
export const getDepositCodesFromStorage = () => JSON.parse(localStorage.getItem('deposit_codes'));
export const removeDepositCodesFromStorage = () => localStorage.removeItem('deposit_codes');

