import React from "react";
//import {account_codes} from '../../enums/Enumsbk'

const DepositSummaryMobile = ({ deposit, getKeyByValue }) => {
  const { name, ritwik, amount } = deposit;
  let values = Object.values(amount);
  let keys = Object.keys(amount);

  const getValue = (key) => {
    if (key == 301) {
      var value = amount[`${key}`];
      var res = "";
      if (value.length == 0) {
        return (
          <p>
            {getKeyByValue(key)}:{" N/A "}
          </p>
        );
      }
      value.map((val) => {
        res =
          res + "" + val.desc + "(" + val.code + "):" + val.item_amount + "|";
      });
      return (
        <p>
          {getKeyByValue(key)}:{res}
        </p>
      );
    } else {
      return (
        <p>
          {getKeyByValue(key)}:{amount[`${key}`]}
        </p>
      );
    }
  };
  return (
    <>
      <li>
        <span className="uk-accordion-title">{name}</span>
        <div className="uk-accordion-content">
          {keys.map((key,index) => (
            <p key={index}>
              {key}:{amount[`${key}`]}
            </p>
          ))}
        </div>
      </li>
    </>
  );
};

export default DepositSummaryMobile;
