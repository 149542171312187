import React from 'react'

const eventitem = ({ event }) => {
    const { _id, eventdate, eventname, eventtime,
        eventcountry, eventstate, eventhost, eventaddress, eventphone } = event
    return (
        <div className="uk-card uk-card-body uk-card-small uk-text-center">
            <h3 className="uk-card-title uk-text-center"><u>Event Details</u></h3>


            <div className=" uk-card  uk-card-default uk-width-2-3 uk-align-center">
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Event Description:</h5></div>
                    <div> {eventname}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Event Date:</h5></div>
                    <div> {eventdate.substr(0, 10)}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Event Time:</h5></div>
                    <div> {eventtime}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Hosted By:</h5></div>
                    <div> {eventhost}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Address:</h5></div>
                    <div> {eventaddress}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>State:</h5></div>
                    <div> {eventstate}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Country:</h5></div>
                    <div> {eventcountry}</div>
                </div>
                <div className="uk-grid  uk-child-width-1-2 uk-padding-small">
                    <div>  <h5>Phone:</h5></div>
                    <div> {eventphone}</div>
                </div>

            </div>

        </div>
    )
}

export default eventitem
