import React, { useState, useContext } from "react";
import { useForm } from 'react-hook-form'
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import SpinnerContext from "../../context/spinner/spinnerContext";
import AlertContext from "../../context/alert/alertContext";
import AccountContext from "../../context/accounts/accountContext"
import * as Elements from "../presentation"
import { getToken } from "../../storage";

const ListAdmins = () => {

  const { handleSubmit, control, register, reset } = useForm();
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const accountContext = useContext(AccountContext);
  const { inviteCountryAdmin, getWhitelistedCountries } = accountContext;

  const alert = useContext(AlertContext);
  const { setAlert } = alert;

  const initialState = {
    country: "",
    name: "",
    email: "",
    admins: [],
    showCreatePanel: true,
  };

  const [state, setState] = useState(initialState);
  const { admins, showCreatePanel } = state;

  const onSubmit = async (payload) => {
    startSpinner();
    try {
      let resp = await inviteCountryAdmin(payload);
      reset();
      setAlert({ type: "success", msg: resp.data.msg });
    } catch (error) {
      setAlert({ type: "danger", msg: error.response.data.msg });
    }
    setState(initialState);
    stopSpinner();
  };


  const getAdmins = async () => {
    startSpinner();
    const config = {
      headers: {
        "x-auth-token": getToken()  ,
      },
    };
    let resp = await axios.get(
      process.env.REACT_APP_API_URL + "/api/user/getAdmins",
      config
    );
    setState({ ...state, admins: resp.data, showCreatePanel: false });
    stopSpinner();
  };

  const onDelete = async (id) => {
    const config = {
      headers: {
        "x-auth-token": getToken(),
      },
    };
    startSpinner();
    try {
      await axios.get(
        process.env.REACT_APP_API_URL + `/api/user/deleteaccounts/${id}`,
        config
      );
      getAdmins();
    } catch (error) { }
    stopSpinner();
  };

  const showAdmins = () => {
    //setState({ ...state, showCreatePanel: false })
    getAdmins();
  };

  const addAdmin = () => {
    setState({ ...state, showCreatePanel: true });
  };

  return (
    <>
      {showCreatePanel ? (
        <div className="uk-align-center uk-background-muted">
          <button
            className="uk-button uk-button-link uk-align-right uk-text-bold"
            onClick={showAdmins}
          >
            SHOW ADMINS
          </button>
          <h4 className="uk-text-center">Invite Admin</h4>
          <form onSubmit={handleSubmit(onSubmit)} className="uk-width-1-2@s uk-align-center ">
            <div className="uk-padding-small">
              <span>Select Country*</span>
              <Elements.CountrySelector
                name="country"
                control={control}
                countries={getWhitelistedCountries()}
              />
            </div>
            <div className="uk-padding-small">
              <span>Enter Name*</span>
              <Elements.TextBox
                name="name"
                register={register}
                required={true}
              />
            </div>
            <div className="uk-padding-small">
              <span className="uk-text"> Enter Email*</span>
              <Elements.TextBox
                type="email"
                name="email"
                placeHolder="Enter Email*"
                register={register}
                required={true}
              />
            </div>
            <div className="uk-padding-small">
              <span className="uk-text"> Enter Phone*</span>
              <Elements.PhoneInputField
                defaultCountry="AU"
                name="phone"
                control={control}
                required={true}
              />
            </div>
            <div className="uk-padding-small">
              <button className="uk-button uk-button-primary uk-align-center">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      ) : (
          <div>
            <div>
              <button
                className="uk-button uk-button-link uk-align-right uk-text-bold"
                onClick={addAdmin}
              >
                ADD ADMIN
            </button>
              <h5 className="uk-text-bolder uk-text-center uk-padding-small">
                List Of Admins
            </h5>
            </div>
            <div>
              <table
                id="adminlist"
                className="uk-table uk-table-divider uk-table-striped"
              >
                <thead>
                  <th>COUNTRY</th>
                  <th>EMAIL</th>
                  <th>NAME</th>
                  <th>ACTION</th>
                </thead>
                <tbody>
                  {admins.length > 0 &&
                    admins.map((admin) => (
                      <tr>
                        <td>{admin.country}</td>
                        <td>{admin.email}</td>
                        <td>{admin.name}</td>
                        <td>
                          <button
                            onClick={(e) => onDelete(admin._id)}
                            className="uk-background-danger"
                          >
                            DELETE
                        </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </>
  );
};

export default ListAdmins;
