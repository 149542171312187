import React,{useContext, useEffect} from 'react'
import AccountContext from '../../context/accounts/accountContext'
import {tnchtml} from "./statichtml/termsofusehtml"
import {useHistory} from "react-router-dom"
import { getTnCApprovalDate } from '../../storage'


 export const AgreeTnC = (props) => {
   
    const accountContext= useContext(AccountContext);
    const {user}= accountContext;
    const{tncApprovalDate=0}=user;
    const history=useHistory();

    useEffect(()=>{
        //const{history}=props;
    
        if(getTnCApprovalDate() != "0"){
            history.push("/home");
          }      
    },[])
   

    const agreeTnC=async()=>{
        try {
            await accountContext.agreeTnC();
            props.history.push("/home");
        } catch (error) {
            window.alert(error.response.data.message);
        }
       
    }
    return (
        <div>
           
            <div>
            <div dangerouslySetInnerHTML={{__html:tnchtml()}}/>
            
            </div>

            <button className="uk-button uk-button-primary uk-align-center" onClick={agreeTnC}>Agree</button>
            
        </div>
    )
}


