import React, { useContext, useState } from "react";
import AccountContext from "../../context/accounts/accountContext";
import { RegionDropdown } from "react-country-region-selector";
import SpinnerContext from "../../context/spinner/spinnerContext";

const Profile = () => {
  const accountContext = useContext(AccountContext);
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;
  const { user, updateUser } = accountContext;
  const { name, address, ashramfcnum, phone, country, fcnum, email } = user;

  const initialState = {
    name,
    address,
    ashramfcnum,
    phone,
    country,
    fcnum,
    email,
  };

  const [profile, setProfile] = useState(initialState);

  const onChange = (e) => {
    if (e.target.name.includes(".")) {
      const [parent, child] = e.target.name.split(".");
      setProfile({
        ...profile,
        [parent]: { ...profile[parent], [child]: e.target.value },
      });
      return;
    }
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const onChangeState = (e) => {
    setProfile({ ...profile, address: { ...profile.address, state: e } });
  };

  const getSection = ({
    label,
    value,
    name,
    editable = false,
    type = "text",
    required = true,
  }) => {
    return (
      <div className="uk-align-center">
        <label className="uk-text uk-text-lead">{label}: </label>
        {editable ? (
          <input type={type} value={value} onChange={onChange} name={name} className="uk-input"
          required={required}
          />
        ) : (
          <span className="uk-text uk-text-bold uk-text-large uk-margin-left">
            {value}
          </span>
        )}
      </div>
    );
  };

  const onSubmit = async(e) => {
    e.preventDefault();
    const  {
      email,
      phone,
      address,
      name,
      ashramfcnum,
    }=profile;
    startSpinner();
   const res = await updateUser({
      email,
      phone,
      address,
      name,
      ashramfcnum,
    });
    stopSpinner();
    if(res){
      window.alert("Profile updated successfully");
    }
    else{
      window.alert("Error updating profile");
    }
  };

  return (
    <div className="uk-card uk-card-body ">
      <form className="uk-form-stacked" onSubmit={onSubmit}>
        <h2 className="uk-text-center">Profile</h2>
        {getSection({
          label: "Family Code",
          value: profile.fcnum,
          name: "fcnum",
        })}
        <br></br>
        {getSection({
          label: "Name",
          value: profile.name,
          name: "name",
          editable: true,
        })}
        {getSection({
          label: "Email",
          value: profile.email,
          name: "email",
          editable: true,
          type: "email",
        })}
        {getSection({
          label: "Phone",
          value: profile.phone,
          name: "phone",
          editable: true,
        })}
        {getSection({
          label: "Asharam Family Code",
          value: profile.ashramfcnum,
          name: "ashramfcnum",
          editable: true,
          required: false,
        })}

        <h2>Address</h2>
        {getSection({
          label: "Address Line 1",
          value: profile.address.line1,
          name: "address.line1",
          editable: true,
        })}
        {getSection({
          label: "Address Line 2",
          value: profile.address.line2,
          name: "address.line2",
          editable: true,
          required: false,
        })}
      
        {getSection({
          label: "Suburb",
          value: profile.address.suburb,
          name: "address.suburb",
          editable: true,
        })}
          {getSection({
          label: "Postcode",
          value: profile.address.postcode,
          name: "address.postcode",
          editable: true,
        })}

        <label className="uk-text uk-text-lead">State:</label>
        <div className="uk-width-1-1">
          <RegionDropdown
            defaultOptionLabel="Select State / Province"
            className="uk-select uk-margin-bottom"
            country={profile.country}
            value={profile.address.state}
            onChange={onChangeState}
            name="address.state"
          />
        </div>

        <button className="uk-button uk-button-primary uk-align-center">
          Update
        </button>
      </form>
    </div>
  );
};

export default Profile;
