import React, { useReducer } from "react";
import IstavrityContext from "./istavrityContext";
import istavrityReducer from "./istavrityReducer";
import { sendDeleteRequest, sendGetRequest, sendPostRequest } from "../../request/api_request"

import {
  CLEAR_DEPOSITS,
  SET_CURRENT_SUBMISSION,
  GET_ALL_SUBMISISSIONS,
  SET_CURRENT_STATUS,
  SET_DEPOSIT_CODES,
  RESET_ALL_STATES,
} from "../types";
import ENDPOINTS from "../../enums/endpoints";

const IstavrityState = (props) => {
  const initialState = {
    submissions: [],
    currentSubmission: {},
    deposit_codes:{}
  };

  const [state, dispatch] = useReducer(istavrityReducer, initialState);

  const setDepositItem = async (item) => {
     try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.SAVE_DEPOSIT,
        payload: item
      })
      return { type: "success", msg: res.data.data.id };
    } catch (error) {
      return { type: "danger", msg: error.response.data.errors };
    }
  };

  const updateSubmission = async (payload) => {
    try {
      let res = await sendPostRequest({
        endpoint:ENDPOINTS.SUBMIT_DEPOSIT,
        payload
      })
      return {type: "success", msg:res.data.data.msg};
    } catch (error) {
      return error.response.data;
    }
  };

  const getLastDeposit = async()=> {
    try {
      let res = await sendPostRequest({
        endpoint:ENDPOINTS.GET_LAST_DEPOSIT,
        payload:{
          type:"istavrity"
        }
      })
      return res.data.data;
    } catch (error) { }
  }

  const setCurrentStatus = (status) => {
    dispatch({ type: SET_CURRENT_STATUS, payload: status });
  };

  const getAllSubmissions = async (payload) => {
    try {
      let res = await sendPostRequest({
        endpoint:ENDPOINTS.GET_ALL_DEPOSITS,
        payload
      })
      dispatch({ type: GET_ALL_SUBMISISSIONS, payload: res.data.data });
    } catch (error) { }
  };

  const getDeposit = async (id) => {
    try {
      let res = await sendGetRequest({
        endpoint: ENDPOINTS.DEPOSITS+'/'+id,
        
      })
      dispatch({ type: SET_CURRENT_SUBMISSION, payload: res.data.data });
      return res.data.data;
    } catch (error) { }
  };
  const getDepositCodes = async (country) => {
  
    try {
      let res = await sendGetRequest({
        endpoint:ENDPOINTS.GET_DEPOSIT_CODES
      })
      dispatch({ type: SET_DEPOSIT_CODES, payload: res.data.data });
    } catch (error) { }
  };

  const getTotalAmountOfAllSubmissions = () => {
    let total = 0;
    state.submissions.map((submission) => {
      total = total + submission.sumTotal;
    });
    return total;
  };

  const clearDeposits = () => {
    dispatch({ type: CLEAR_DEPOSITS, payload: "" });
  };

  const resetState = () => {
    dispatch({ type: RESET_ALL_STATES, payload: initialState});
  };

  const getStripeKeys = async (payload) => {

    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.GET_STRIPE_KEYS,
        payload
      })
      return res.data.data;
    } catch (error) {
      return error.response.data.errors;
    }
  };
  const payDeposits = async (payload) => {

    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.CONFIRM_PAYMENT,
        payload
      })
      setCurrentStatus("Done");
      dispatch({ type: SET_CURRENT_SUBMISSION, payload: res.data.data.msg});
      return { status: 200 };
    } catch (error) {
      return { status: error.response.status, msg: error.response.data.errors };
    }
  };

  const sendEmailWithArghyaPraswasti = async () => {
    try {
       await sendPostRequest({
        endpoint:"/api/receipt/sendEmail",
        payload: { _id : state.currentSubmission}
      })
  
      return {
        type: "success",
        msg: "Arghya Praswasti has been succesfully sent to your email",
      };
    } catch (error) {
      return {
        type: "danger",
        msg: "Error while sending email. Please contact Admin",
      };
    }
  };

  const approveSubmission = async () => {
    try {
      await sendPostRequest({
        endpoint: ENDPOINTS.APPROVE_DEPOSIT,
        payload: { depositId : state.currentSubmission._id}
      })
    } catch (error) { }
  };

  const onDelete = async (_id) => {
    try {
      await sendDeleteRequest({
        endpoint: ENDPOINTS.DEPOSITS+'/'+_id
      })
      return true;
    } catch (error) { }
  };

  const getArghyaContributionDetails = async (code) => {
    try {
      let res = await sendGetRequest({
        endpoint:ENDPOINTS.GET_MISC_CODE_DATA+"/" + code
      })
      return res.data.data ;
    } catch (error) {
      return error.response.data;
    }
  };

  const getArghyaPraswasti = async (depositId) => {
    try {
      let res = await sendGetRequest({
        endpoint:ENDPOINTS.GET_ARGHYA_PRASAWASTI+'/'+depositId,
        responseType: "blob"
      })
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  return (
    <IstavrityContext.Provider
      value={{
        submissions: state.submissions,
        current: state.currentSubmission,
        test: state.test,
        setDepositItem,
        clearDeposits,
        setCurrentStatus,
        getAllSubmissions,
        payDeposits,
        updateSubmission,
        sendEmailWithArghyaPraswasti,
        approveSubmission,
        getTotalAmountOfAllSubmissions,
        onDelete,
        deposit_codes: state.deposit_codes,
        getDepositCodes,
        getDeposit,
        getArghyaContributionDetails,
        getStripeKeys,
        resetState,
        getArghyaPraswasti,
        getLastDeposit
      }}
    >
      {props.children}
    </IstavrityContext.Provider>
  );
};

export default IstavrityState;
