import React, { useContext, useEffect, useState } from "react";
import { BookDisplay } from "./BookDisplay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useBooks } from "../../context/BooksContext";
import { FaSearch } from "react-icons/fa";
import SpinnerContext from "../../context/spinner/spinnerContext";


const Catelogue = () => {

  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const { books, loading, error, fetchBooks } = useBooks();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const filterBooks = (books) => {
    if(!search){
      return books;
    }
    return books.filter((book) => {
      return book.title.toLowerCase().includes(search.toLowerCase());
    });
  };

  // const getCopiesAvaialble = (book) => {
  //   console.log(
  //     "Book",
  //     book,
  //     " copies:",
  //     book.stateInventory.find(
  //       (inventory) => inventory.state === user.address.state
  //     )
  //   );
  //   switch (user.libraryMemberType) {
  //     case (0, 1):
  //       return book.stateInventory.find(
  //         (inventory) => inventory.state === user.address.state
  //       )?.copiesAvailable;

  //     case 2:
  //       return book.totalCopies;
  //     default:
  //       return book.totalCopies;
  //   }
  // };

  // const sanitizeBooks = (responses) => {
  //   return responses.map((response) => {
  //     const { book, stateInventory } = response;
  //     return {
  //       _id: book._id,
  //       image: book.image,
  //       title: book.title,
  //       description: book.description,
  //       stateInventory,
  //       totalCopies: getCopiesAvaialble(response),
  //     };
  //   });
  // };
  // const books = sanitizeBooks(response);
  

  useEffect(() => {
    console.log("Fetching Books useEffect");
    const getBooks = async () => {
      await startSpinner();
      await fetchBooks();
      await stopSpinner();
    };
    getBooks();
  }, []);

  const onBookClick = (book) => {
    console.log("Book Clicked", book);
    history.push({ pathname: "/book/" + book._id});
  };
  console.log("Loading", loading);
  console.log("Books1", books);
  if(loading){
    return <div>Loading...</div>
  }
  else return (
    <>
   <div id="Search" className="">
   <div className="search-container uk-align-center">
      <FaSearch className="search-icon" />
      <input type="text" placeholder="Search" className="search-input" 
      onChange={(e) => setSearch(e.target.value)}
      />
    </div>
    </div>
    <div className="uk-grid uk-grid-small uk-child-width-1-2@m uk-margin-top" uk-grid="true">
      {filterBooks(books).length && filterBooks(books).map((book, index) => (
        <div key={index}>
          <BookDisplay
            id={book._id}
            coverPic={book.image}
            title={book.title}
            description={book.description}
            language={book.language}
            copiesAvailable={book.totalCopies}
            onClick={() => onBookClick(book)}
          />
        </div>
      ))}
    </div>
    </>
  );
};

export default Catelogue;
