import React from 'react'
import { Controller } from "react-hook-form"
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

export const PhoneInputField = ({ defaultCountry, control, name, errors }) => {
    const allowedCountries = ['AU', 'NZ'];
    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ onChange, value }) =>
                    <>
                     <p className="uk-text uk-text-danger uk-margin-top">{errors[name] && errors[name].message}</p>
                        <PhoneInput
                           // international
                           countries={allowedCountries}
                            countryCallingCodeEditable={false}
                            international={true}
                            placeholder="Phone number without ISD Code or 0"
                            defaultCountry={defaultCountry}
                            className=" uk-padding-small uk-margin-bottom uk-border-grey"
                            name={name}
                            id={name}
                            value={value}
                            onChange={onChange}
                           
                        />
                       
                    </>
                } />
        </div>
    )
}
PhoneInputField.defaultProps = {
    name: "phone",
    errors:[]
}
