import React from 'react'

const Feedback = () => {
    return (
        <div>
         <iframe className="uk-align-center" src="https://docs.google.com/forms/d/e/1FAIpQLScFULWODUH9-quDRwYJxG8vCpt1EPIRH6HMo3tU2mmj4ZqWIg/viewform?embedded=true" width="640" height="1179" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

export default Feedback