import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest } from "../request/api_request";

export const useGetIstavrityReports = () => {
    const initState = {
        details: [],
        loading: false,
        error: null,
    };

    const [state, setState] = useState(initState);
    const { details, loading, error } = state;

    const getReport = useCallback(async () => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendGetRequest({ endpoint: ENDPOINTS.ISTAVRITY_REPORTS });
            setState({ details: res.data, loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    }, []); // `useCallback` memoizes `getBooks` and prevents unnecessary re-creation

    
    return { details, loading, error, getReport };
};
