import React, { useState, useContext, useEffect } from "react";
import AccountContext from "../../context/accounts/accountContext";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import LoginPage from "./view/LoginPage";
import RegisterPage from "./view/RegisterPage";
import { getTnCApprovalDate } from "../../storage";


export const LoginRegister = (props) => {
  const accountContext = useContext(AccountContext);
  useEffect(() => {
    console.log("LoginRegister useEffect")
    accountContext.logout();
  }, []);

  const initialState = {
    showLogin: true,
    showRegistration: false,
  };
  const [state, setState] = useState(initialState);
  let { showLogin, showRegistration } = state;

  const alertContext = useContext(AlertContext);
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;
  const { setAlert } = alertContext;
  const { getWhitelistedCountries, login, registerUser } = accountContext;

  const onLogin = async (user) => {
    startSpinner();
    let resp = await login(user);
    if (resp.type === "danger") {
      setAlert(resp);
    } else {
      const tncApprovalDate = getTnCApprovalDate();
      if (tncApprovalDate == 0) {
        props.history.push("/termsandconditions");
      } else {
        props.history.push("/home");
      }
    }
    stopSpinner();
  };


  const onRegister = async (user) => {
    startSpinner();
    let resp = await registerUser(user);
    stopSpinner();
    if (resp.type === "success") {
      // recaptchaRef.current.reset();
      showLoginForm();
    }
    setAlert(resp);
  };

  const showRegistrationForm = () => {
    setState({ showLogin: false, showRegistration: true });
  };

  const showLoginForm = () => {
    setState(initialState);
  };

  return (
    <>
    
      <div className="uk-margin-top ">
      <div>
        <p className="uk-background-co-0">
        <h4 className="uk-text-center uk-margin-top">This application is exclusively available for Satsangees from <b>Australia</b> and <b>New Zealand</b>.</h4>
        </p>
        </div>
        <div className="uk-container">
        
          {showLogin && (
            <LoginPage
              onLogin={onLogin}
              showRegistrationForm={showRegistrationForm}
            />
          )}

          {showRegistration && (
            <RegisterPage
              onRegister={onRegister}
              showLoginForm={showLoginForm}
              countries={getWhitelistedCountries()}
            />
          )}
         
        </div>
       
      </div>
      <div>
        {/* <div className="uk-flex uk-flex-center uk-height-viewport uk-background-muted uk-visible@s">
    <div class="uk-card uk-card-default uk-card-body uk-width-1-2@m">
          <LoginPage
            onLogin={onLogin}
            showRegistrationForm={showRegistrationForm}
          />
          </div>
      <hr className="uk-divider-vertical" /> 
      <div class="uk-card uk-card-default uk-card-body uk-width-1-2@m">
          <RegisterPage
            onRegister={onRegister}
            showLoginForm={showLoginForm}
            countries={getWhitelistedCountries()}
          />
      </div>
      </div> */}
      </div>


    </>
  );
};


