import React from 'react'
import CardClickWithIcon from '../../presentation/CardClickWithIcon'

const SelectDepositType = ({setDepositType}) => {
    const cards = [
        {
            title: 'Istavrity',
            description: 'Deposit Istavrity. Can be submitted only once per month'
        },
        {
            title: 'Arghya',
            description: 'Deposit Arghya for specific events or reasons'

        },
        {
            title: 'Pronami',
            description: 'Deposit Pronami only'
        }
    ]
   
    const getBlankDiv = () => {
         return (
            <div className='uk-width-1-3'> </div>
         )
    }
    return (
        <div className='uk-container uk-align-center uk-margin-large-top'>
            
            <div class="uk-grid-match uk-width-1-1 uk-child-width-1-2@s uk-text-center uk-margin" uk-grid="true">
                {getBlankDiv()}
                <div className='uk-margin-top'>
                <h2>Select Deposit type</h2>
                   {/* {cards.map(getCard(setDepositType))
                   } */}
                     <CardClickWithIcon contents={cards} action={setDepositType}/>
                </div>
                {getBlankDiv()}
            </div>
        </div>

    )
}

export default SelectDepositType