import React from "react"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Controller } from "react-hook-form"

export const CountrySelector = ({ control, countries, errors }) => {

  return (
    <>
    <p className="uk-text uk-text-danger uk-margin-top">{errors["country"] && errors["country"].message}</p>
      <Controller
        name="country"
        control={control}
        defaultValue=""
        render={({ onChange, value }) =>
          <CountryDropdown
            className=" uk-select uk-margin-bottom"
            whitelist={countries}
            value={value}
            onChange={onChange}
          />}
      />
    </>
  )
}


export const RegionSelector = ({name, control, country }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) =>
          <RegionDropdown
            defaultOptionLabel="Select State / Province"
            className="uk-select uk-margin-bottom"
            country={country}
            value={value}
            onChange={onChange}
            
          />
        }
      />
    </>
  )
}

