import React from 'react'

export const Dropdown = ({ title,name, className, required,options, register ,errors,onChange}) => {
    return (
        <div>
             <p className="uk-text uk-text-danger uk-margin-top">{errors[name] && errors[name].message}</p>
            <select
                className={className}
                name={name}
                ref={register}
                placeholder={title}
                required={required}
                onChange={onChange}
            >
               <option key="default" value="-2">{title}</option>
                {Object.keys(options).map(optionKey => 
                    <option key={optionKey} value={optionKey}>
                        {options[optionKey].toUpperCase()}
                    </option>
                )}
            </select>
           
        </div>
    )
}
Dropdown.defaultProps = {
    title: "--Select---",
    required:false,
    errors:{},
    className:"uk-select uk-text-capitalize",
    register:()=>{},
    onChange:()=>{}
}
