export const ritvikList = [
  {
    firstName: "LATE SRI SRI BORDA",
    lastName: "",
    address: "*SATSANG",
    fullName: "LATE SRI SRI BORDA ",
    saID: "SPR0001",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "ACHARJEE",
    address: "VILL-SIMULIAPARA ARJASREE*SO-CHANDPARA BAZAR*DIST-24 PGS.",
    fullName: "CHITTARANJAN ACHARJEE",
    saID: "SPR0002",
  },
  {
    firstName: "LATE NITYAGOPAL",
    lastName: "ACHARJEE",
    address: "KAHILIPARA COLONY*SO-BINOVANAGAR*GAUHATI-18*KAMRUP*ASSAM",
    fullName: "LATE NITYAGOPAL ACHARJEE",
    saID: "SPR0003",
  },
  {
    firstName: "ARTABALLAV",
    lastName: "ACHARYA",
    address: "VILL-ARJUNPUR*PO-KUNDANNAGAR*KENDRAPARA",
    fullName: "ARTABALLAV ACHARYA",
    saID: "SPR0004",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "ACHARYA",
    address: "AT/PO-LATHANG*VIA-RAHAMA*JAGATSINGHPUR 754140*PAN-ADIPA5175F",
    fullName: "HRUSHIKESH ACHARYA",
    saID: "SPR0005",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "ACHARYA",
    address: "SATSANG VIHAR*GATE BAZAR*BERHAMPUR*GANJAM",
    fullName: "LATE NIRANJAN ACHARYA",
    saID: "SPR0006",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "ACHARYA",
    address: "VILL-DOTALA*P.O.MADHAIYA BAZAR*KUMILLA (BANGLADESH)",
    fullName: "PARIMAL CHANDRA ACHARYA",
    saID: "SPR0007",
  },
  {
    firstName: "LATE SUDARSAN",
    lastName: "ACHARYA",
    address: "VILL/PO-MIRIGINI*VIA-RAJNILGIRI*DIST-BALASORE.",
    fullName: "LATE SUDARSAN ACHARYA",
    saID: "SPR0008",
  },
  {
    firstName: "LATE SUDHANGSHU RANJAN",
    lastName: "ACHARYA",
    address: "VILL-NIAMATPUR*PO-GOWRARANG*DIST-SUNAMGANJ*BANGLADESH",
    fullName: "LATE SUDHANGSHU RANJAN ACHARYA",
    saID: "SPR0009",
  },
  {
    firstName: "SUJIT KUMAR",
    lastName: "ACHERJEE",
    address: "VILL-ARUN NAGAR*PO-KARAMBITOLA*DIST-BHANDARA*M.S.-441904",
    fullName: "SUJIT KUMAR ACHERJEE",
    saID: "SPR0010",
  },
  {
    firstName: "LATE ASUTOSH",
    lastName: "ACHERJEE",
    address: "VILL-CHECHAKHATYA*PO-ALIPURDUAR JN.*JALPAIGURI-736123",
    fullName: "LATE ASUTOSH ACHERJEE",
    saID: "SPR0011",
  },
  {
    firstName: "HIMANGSHU",
    lastName: "ACHARJEE",
    address: "JAKHLIRPAR TOWN AREA*PO-BARPETA ROAD*DIST BARPETA",
    fullName: "HIMANGSHU ACHARJEE",
    saID: "SPR0012",
  },
  {
    firstName: "ARABINDA",
    lastName: "ADAK",
    address:
      "VILL-GANGADHARPUR*PO-GANGADHARPUR BAZAR*HOOGHLY 712306*PAN-BWNPA8305A",
    fullName: "ARABINDA ADAK",
    saID: "SPR0013",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "ADAK",
    address: "VILL-KANAKPUR *PO.BALIPUR *DIST.HOOGHLY-712401",
    fullName: "CHITTARANJAN ADAK",
    saID: "SPR0014",
  },
  {
    firstName: "SAMIR RANJAN",
    lastName: "ADAK",
    address:
      "VILL-RAMPUR*PO-CHAITANYAPUR*PS-SUTAHATA*PURBA MIDNAPUR-721645*PAN-AIRPA4142N",
    fullName: "SAMIR RANJAN ADAK",
    saID: "SPR0015",
  },
  {
    firstName: "LATE SANKAR KUMAR",
    lastName: "ADAK",
    address: "VILL&PO-JALALSI*VIA-BEGRI*DT-HOWRAH.",
    fullName: "LATE SANKAR KUMAR ADAK",
    saID: "SPR0016",
  },
  {
    firstName: "BIKRAM KUMAR",
    lastName: "ADDYA",
    address: "AT-NATUNGRAM*PO-SWARUPNAGAR*DIST-24-PGS (NORTH) -743286",
    fullName: "BIKRAM KUMAR ADDYA",
    saID: "SPR0017",
  },
  {
    firstName: "GAJENDRA NATH",
    lastName: "ADHIKARI",
    address: "VIL-HEDAYETNAGAR*PO-DHULAGAON VIA-JATESWAR *JALPAIGURI",
    fullName: "GAJENDRA NATH ADHIKARI",
    saID: "SPR0018",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "ADHIKARY",
    address: "VILL-WEST PRATAPGARH*PO-ARUNDHUTINAGAR*AGARTALA*TRIPURA",
    fullName: "LATE NANIGOPAL ADHIKARY",
    saID: "SPR0019",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "ADHIKARY",
    address:
      "BHABANIPUR SATSANG CENTRE*PO-KHARAGPUR*DIST-PASCHIM MEDINIPUR-721301*PAN-BTHPA9741D",
    fullName: "LATE ASWINI KUMAR ADHIKARY",
    saID: "SPR0020",
  },
  {
    firstName: "LATE CHANCHAL",
    lastName: "ADHIKARY",
    address: "R&B DIVISION*P.O.SUMER*MEGHALAYA",
    fullName: "LATE CHANCHAL ADHIKARY",
    saID: "SPR0021",
  },
  {
    firstName: "DEBENDRA NARAYAN",
    lastName: "ADHIKARY",
    address: "AT-RAJAKALIPAT*PO-KISMATHHASDAHA*GOALPARA-783334*ASSAM",
    fullName: "DEBENDRA NARAYAN ADHIKARY",
    saID: "SPR0022",
  },
  {
    firstName: "LATE PRAVAT CHANDRA",
    lastName: "ADHIKARY",
    address: "VILL-SINDURAI *PO.BELGURI *GOALPARA",
    fullName: "LATE PRAVAT CHANDRA ADHIKARY",
    saID: "SPR0023",
  },
  {
    firstName: "LATE SANATAN",
    lastName: "ADHIKARY",
    address: "VILL/PO-CHILADANGI*DIST.HOOGHLY",
    fullName: "LATE SANATAN ADHIKARY",
    saID: "SPR0024",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "ADHIKARY",
    address: "SATSANG VIHAR,RAJNAGAR BAROUNI*PO-GARHARA*DT-BEGUSARAI 851126",
    fullName: "SANTOSH KUMAR ADHIKARY",
    saID: "SPR0025",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "ADITYA",
    address: "BANGLADESH SATSANG ASHRAM*P.O.D.PAKUTIA*DIST-TANGAIL*BANGLADESH",
    fullName: "ATUL CHANDRA ADITYA",
    saID: "SPR0026",
  },
  {
    firstName: "LATE KOHINOOR KIRAN",
    lastName: "ADITYA",
    address: "ARABINDANAGAR*PO-ALIPURDUAR COURT*JALPAIGURI",
    fullName: "LATE KOHINOOR KIRAN ADITYA",
    saID: "SPR0027",
  },
  {
    firstName: "MOHAN",
    lastName: "AGARWAL",
    address: "PO-MAKUMKILLA*VIA-MARGHERITA*DT-TINSUKIA 786181",
    fullName: "MOHAN AGARWAL",
    saID: "SPR0028",
  },
  {
    firstName: "PURUSOTTAM LAL",
    lastName: "AGARWAL",
    address: "C/O-SUBHAM AUTO MOBILE*A T ROAD*PO-HAIBARGAON*NAGAON  782002.",
    fullName: "PURUSOTTAM LAL AGARWAL",
    saID: "SPR0029",
  },
  {
    firstName: "CHIRANJIB",
    lastName: "AGARWALA",
    address: "A T ROAD*NAMTIALPATHAR*PO&DT-SIBSAGAR-785684*ASSAM",
    fullName: "CHIRANJIB AGARWALA",
    saID: "SPR0030",
  },
  {
    firstName: "LATE KISANLAL",
    lastName: "AGARWALA",
    address: "17/F A.C.PAL STREET*CALCUTTA-57",
    fullName: "LATE KISANLAL AGARWALA",
    saID: "SPR0031",
  },
  {
    firstName: "SADANANDA",
    lastName: "AICH",
    address:
      "C/O HIRANMAY AICH*BANKIMPALLY*PO-MADHYAMGRAM*DIST-24PARGANAS (NORTH)-700129.",
    fullName: "SADANANDA AICH",
    saID: "SPR0032",
  },
  {
    firstName: "SEKH MUKTER",
    lastName: "ALI",
    address: "AT/PO RAINA*BURDWAN",
    fullName: "SEKH MUKTER ALI",
    saID: "SPR0033",
  },
  {
    firstName: "MANIK CHANDRA",
    lastName: "ASH",
    address: "PO BALIHARPUR *DIST BIRBHUM",
    fullName: "MANIK CHANDRA ASH",
    saID: "SPR0034",
  },
  {
    firstName: "BRAJKISHORE NARAYAN",
    lastName: "AZAD",
    address:
      "I-8/2 NEW HOUSING COLONY*ADITYAPUR*SARAIKELLA KHARSWAN 832109*PAN.NO-ACVPA8021E",
    fullName: "BRAJKISHORE NARAYAN AZAD",
    saID: "SPR0035",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "BAG",
    address: "AT-KALITOLA*PO-GHANJA*DIST-24 PGS.",
    fullName: "LATE AJIT KUMAR BAG",
    saID: "SPR0036",
  },
  {
    firstName: "HRISHIKESH",
    lastName: "BAG",
    address: "VILL-BIDHAN NAGAR*PO-BUD BUD*BARDDHAMAN-713403",
    fullName: "HRISHIKESH BAG",
    saID: "SPR0037",
  },
  {
    firstName: "UPANANDA",
    lastName: "BAG",
    address: "VILL-BOULKHALI*PO-BAURIA*HOWRAH-711305",
    fullName: "UPANANDA BAG",
    saID: "SPR0038",
  },
  {
    firstName: "LATE HEMENDU NATH",
    lastName: "BAGCHI",
    address: "KALIPAHARI*CHASRAMPUR*BURDWAN.",
    fullName: "LATE HEMENDU NATH BAGCHI",
    saID: "SPR0039",
  },
  {
    firstName: "MAHENDRA",
    lastName: "BAGHOWAR",
    address:
      "VILL-NAHARANI*PO-MANIJHARANI*DIST-SONITPUR-784502*PAN-ARGPB3851P*ASSAM",
    fullName: "MAHENDRA BAGHOWAR",
    saID: "SPR0040",
  },
  {
    firstName: "PUNIRAM",
    lastName: "BAGLARY",
    address: "VILL-SWARGATHIGAON*PO-LANGHINTINYALI*KARBI ANGLONG-782441*ASSAM",
    fullName: "PUNIRAM BAGLARY",
    saID: "SPR0041",
  },
  {
    firstName: "LATE BAIKUNTHA BEHARI",
    lastName: "BAIDYA",
    address:
      "C/O VISHNU PRASAD BAIDYA*PO&VILL-DINESHPUR*UDHAMSINGH NAGAR*UTTARANCHAL",
    fullName: "LATE BAIKUNTHA BEHARI BAIDYA",
    saID: "SPR0042",
  },
  {
    firstName: "LATE BIRENDRA KISHORE",
    lastName: "BAIDYA",
    address: "AT/PO BELONIA*TRIPURA",
    fullName: "LATE BIRENDRA KISHORE BAIDYA",
    saID: "SPR0043",
  },
  {
    firstName: "LATE HARALAL",
    lastName: "BAIDYA",
    address: "C/O-D.R.B.ROY*CANARA BANK*LANKA*VARANASI*U.P.",
    fullName: "LATE HARALAL BAIDYA",
    saID: "SPR0044",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "BAIDYA",
    address: "HOSPITAL ROAD*PO-BELONIA*TRIPURA (SOUTH)",
    fullName: "NARESH CHANDRA BAIDYA",
    saID: "SPR0045",
  },
  {
    firstName: "LATE PULIN BIHARI",
    lastName: "BAIDYA",
    address: "GANDALPARA JUTE MILL*PO-GANDALPARA*DIST.HOOGHLY.",
    fullName: "LATE PULIN BIHARI BAIDYA",
    saID: "SPR0046",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "BAIDYA",
    address:
      "\"SWASTI VILLA'*VILL-MAYAPALLI*PO-ICHHAPUR*VIA-NABABGANJ*24 PARGANAS (NORTH)-743144",
    fullName: "SURESH CHANDRA BAIDYA",
    saID: "SPR0047",
  },
  {
    firstName: "ADHIR CHANDRA",
    lastName: "BAIN",
    address: "NABAGRAM SATSANG VIHAR*PO.BAROBOHERA*DT-HOOGHLY-712246",
    fullName: "ADHIR CHANDRA BAIN",
    saID: "SPR0048",
  },
  {
    firstName: "LATE SHIBAPRASAD",
    lastName: "BAIRAGI",
    address: "VILL-RAJA COLONY*PO-NEORIA-262305*DIST-PILIBHIT*U.P.",
    fullName: "LATE SHIBAPRASAD BAIRAGI",
    saID: "SPR0049",
  },
  {
    firstName: "NISHIKANTA",
    lastName: "BAISHYA",
    address:
      "E/45,PATULIA GOVT. QRS*PO-PATULIA*VIA-TITAGARH*24PARGANAS NORTH-700119",
    fullName: "NISHIKANTA BAISHYA",
    saID: "SPR0050",
  },
  {
    firstName: "LATE PRAFULLA",
    lastName: "BAKSHI",
    address: "C/O RATAN BAKSHI*SANTIPARA*PO/DIST-JALPAIGURI",
    fullName: "LATE PRAFULLA BAKSHI",
    saID: "SPR0051",
  },
  {
    firstName: "JNANENDRANATH",
    lastName: "BAL",
    address: "VIL&PO-KANKI*UTTAR DINAJPUR-733209",
    fullName: "JNANENDRANATH BAL",
    saID: "SPR0052",
  },
  {
    firstName: "LATE ANIL KRISHNA",
    lastName: "BALA",
    address: "GOSABA SATSANG VIHAR*PO-GOSABA*DIST-24 PGS (SOUTH)-743370",
    fullName: "LATE ANIL KRISHNA BALA",
    saID: "SPR0053",
  },
  {
    firstName: "PARITOSH KUMAR",
    lastName: "BALA",
    address: "AT/PO-SANGRAMPUR*VIA-BHABLA(BASIRHAT)*24 PGS (NORTH) 743422",
    fullName: "PARITOSH KUMAR BALA",
    saID: "SPR0054",
  },
  {
    firstName: "LATE ACHYUTANAND",
    lastName: "BANERJEE",
    address: "156,GOLAHAT*PO-SRIPALLI*BURDWAN 713103",
    fullName: "LATE ACHYUTANAND BANERJEE",
    saID: "SPR0055",
  },
  {
    firstName: "LATE AMALENDU",
    lastName: "BANDOPADHYAY",
    address:
      "SREE PALLY ASANSOLE*BEHIND UPENDRANATH HIGH SCHOOL*PO ASANSOLE*DIST BURDWAN",
    fullName: "LATE AMALENDU BANDOPADHYAY",
    saID: "SPR0056",
  },
  {
    firstName: "BHASWAR",
    lastName: "BANERJEE",
    address: "VILL-GUPTIPUR*PO-SIMLON*BARDDHAMAN-713425*PAN-CLIPB5835R",
    fullName: "BHASWAR BANERJEE",
    saID: "SPR0057",
  },
  {
    firstName: "LATE BIBHU PADA",
    lastName: "BANERJEE",
    address: "3A,LALABAGAN ROAD*CALCUTTA-700006",
    fullName: "LATE BIBHU PADA BANERJEE",
    saID: "SPR0058",
  },
  {
    firstName: "LATE BIJAY",
    lastName: "BANERJEE",
    address: "VILL/PO BIKRAMPUR*DIST BANKURA",
    fullName: "LATE BIJAY BANERJEE",
    saID: "SPR0059",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "BANERJEE",
    address: "KAMALA DIGHIRPAR*CHHOTONILPUR*BURDWAN.",
    fullName: "LATE CHITTARANJAN BANERJEE",
    saID: "SPR0060",
  },
  {
    firstName: "DEBASIS",
    lastName: "BANERJEE",
    address: "RAMKRISHNAPUR*PO-BURAR*BURDWAN",
    fullName: "DEBASIS BANERJEE",
    saID: "SPR0061",
  },
  {
    firstName: "DEBNARAYAN",
    lastName: "BANERJEE",
    address: "AT PATHANPARA*PO GANUTIA*DIST BIRBHUM",
    fullName: "DEBNARAYAN BANERJEE",
    saID: "SPR0062",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "BANERJEE",
    address: "DEPUTY INSPECTOR OF SCHOOL*PO.RAGHUNATHPUR *PURULIA",
    fullName: "LATE GANGADHAR BANERJEE",
    saID: "SPR0063",
  },
  {
    firstName: "LATE GOBINDA LAL",
    lastName: "BANERJEE",
    address: "GUPTIPARA  SOUTH*SIMLON*BURDWAN",
    fullName: "LATE GOBINDA LAL BANERJEE",
    saID: "SPR0064",
  },
  {
    firstName: "LATE GOBINDALAL",
    lastName: "BANERJEE",
    address: "BABUPARA ROAD*PO-ALIPURDUAR*JALPAIGURI",
    fullName: "LATE GOBINDALAL BANERJEE",
    saID: "SPR0065",
  },
  {
    firstName: "LATE GOUR MOHAN",
    lastName: "BANERJEE",
    address: "PO/VILL-KHANTURA*DIST-24PARGANAS (N)-743273",
    fullName: "LATE GOUR MOHAN BANERJEE",
    saID: "SPR0066",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "BANERJEE",
    address: "SATSANG COLONY*PO-KARIDHYA*DIST-BIRBHUM",
    fullName: "LATE JATINDRA NATH BANERJEE",
    saID: "SPR0067",
  },
  {
    firstName: "JIBAN",
    lastName: "BANERJEE",
    address: "AT/PO.JOYPUR RAJA BAZAR*CACHAR",
    fullName: "JIBAN BANERJEE",
    saID: "SPR0068",
  },
  {
    firstName: "LATE JIBAN KRISNA",
    lastName: "BANERJEE",
    address: "59 ANJANGARH  BIRATI*CALCUTTA-51",
    fullName: "LATE JIBAN KRISNA BANERJEE",
    saID: "SPR0069",
  },
  {
    firstName: "LATE JNAN CHANDRA",
    lastName: "BANERJEE",
    address: "AT-KHAMARPARA*PO-BANSBERIA*DIST.HOOGHLY",
    fullName: "LATE JNAN CHANDRA BANERJEE",
    saID: "SPR0070",
  },
  {
    firstName: "LATE KALIKRISHNA",
    lastName: "BANERJEE",
    address: "87-A/H/8 COSSIPORE ROAD*CALCUTTA-700002.",
    fullName: "LATE KALIKRISHNA BANERJEE",
    saID: "SPR0071",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "BANERJEE",
    address: "PORT BLAIR LINES EAST*PO BARRACKPUR*24 PARAGANAS",
    fullName: "LATE KIRAN CHANDRA BANERJEE",
    saID: "SPR0072",
  },
  {
    firstName: "LATE KRITI BANDHU",
    lastName: "BANERJEE",
    address:
      "SATSANG VIHAR DELHI*SATSANG VIHAR MARG*SPECIAL INSTITUTIONAL AREA*NEW DELHI 110067",
    fullName: "LATE KRITI BANDHU BANERJEE",
    saID: "SPR0073",
  },
  {
    firstName: "LATE MADHUSUDAN",
    lastName: "BANERJEE",
    address: "57 DIAMOND HARBOUR ROAD*CALCUTTA 1",
    fullName: "LATE MADHUSUDAN BANERJEE",
    saID: "SPR0074",
  },
  {
    firstName: "LATE MANI LAL",
    lastName: "BANERJEE",
    address: "F/T-68,ARMAPUR ESTATE*KANPUR*U.P.",
    fullName: "LATE MANI LAL BANERJEE",
    saID: "SPR0075",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "BANERJEE",
    address: "VILL-BALIA*PO-GARIA*DIST-24 PARGANAS",
    fullName: "LATE MANORANJAN BANERJEE",
    saID: "SPR0076",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "BANERJEE",
    address: "CALCUTTA",
    fullName: "LATE MANORANJAN BANERJEE",
    saID: "SPR0077",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "BANERJEE",
    address: "S.B.I. COOCHBEHAR MAIN BRANCH*SAGAR DIGHI*PO/DIST-COOCHBIHAR",
    fullName: "LATE MRINAL KANTI BANERJEE",
    saID: "SPR0078",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "BANERJEE",
    address: "388 UPEN BANERJEE ROAD*CALCUTTA 60",
    fullName: "LATE MRINAL KANTI BANERJEE",
    saID: "SPR0079",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "BANERJEE",
    address: "PO-BARABEHARA* C BLOCK*HOOGHLY",
    fullName: "LATE NANIGOPAL BANERJEE",
    saID: "SPR0080",
  },
  {
    firstName: "LATE NILRATAN",
    lastName: "BANERJEE",
    address: "C/O-CHOWBERA SATSANG*PO-BAINCHIGRAM*DIST.HOOGHLY",
    fullName: "LATE NILRATAN BANERJEE",
    saID: "SPR0081",
  },
  {
    firstName: "LATE NISIR KUMAR",
    lastName: "BANDOPADHYAY",
    address: "GOPALNAGAR*PO-DINHATA*COOCHBEHAR 736135",
    fullName: "LATE NISIR KUMAR BANDOPADHYAY",
    saID: "SPR0082",
  },
  {
    firstName: "LATE NITYA NARAYAN",
    lastName: "BANERJEE",
    address: "PO+V-KAGRAM.VIA-SALAR*DIST-MURSHIDABAD.",
    fullName: "LATE NITYA NARAYAN BANERJEE",
    saID: "SPR0083",
  },
  {
    firstName: "LATE PANNALAL",
    lastName: "BANERJEE",
    address: "STATE BANK OF INDIA*BARABAZAR BRANCH*CALCUTTA 7",
    fullName: "LATE PANNALAL BANERJEE",
    saID: "SPR0084",
  },
  {
    firstName: "PARTHAPRATIM",
    lastName: "BANERJEE",
    address:
      "ANNAPURNA APTMT;E BLOCK FLAT NO 1B*49 S K DEB ROAD*KOLKATA 700048",
    fullName: "PARTHAPRATIM BANERJEE",
    saID: "SPR0085",
  },
  {
    firstName: "RAJENDRANATH",
    lastName: "BANERJEE",
    address: "SATSANG-814116*DEOGHAR*PAN-CHLPB9236K",
    fullName: "RAJENDRANATH BANERJEE",
    saID: "SPR0086",
  },
  {
    firstName: "RATNESWAR",
    lastName: "BANERJEE",
    address:
      "SATSANG KARJYALAY*GOPAL NAGAR ROAD*PO-RANAGHAT*NADIA-741201*PAN-ACRPR1448J",
    fullName: "RATNESWAR BANERJEE",
    saID: "SPR0087",
  },
  {
    firstName: "LATE SACHINDRA MOHAN",
    lastName: "BANERJEE",
    address: "1 KALI CHARAN GHOSH ROAD*CALCUTTA 50",
    fullName: "LATE SACHINDRA MOHAN BANERJEE",
    saID: "SPR0088",
  },
  {
    firstName: "LATE SAKTI PADA",
    lastName: "BANERJEE",
    address: "AT/PO-SEHARA*BURDWAN",
    fullName: "LATE SAKTI PADA BANERJEE",
    saID: "SPR0089",
  },
  {
    firstName: "LATE SAMARENDRA NATH",
    lastName: "BANERJEE",
    address: "CHALDHANI, UTTARPARA*PO-RAMPURHAT*BIRBHUM.",
    fullName: "LATE SAMARENDRA NATH BANERJEE",
    saID: "SPR0090",
  },
  {
    firstName: "LATE SAMBHUNATH",
    lastName: "BANERJEE",
    address: "AT-MALANCHA*PO-MOHIPALPUR*DIST-HOOGHLY",
    fullName: "LATE SAMBHUNATH BANERJEE",
    saID: "SPR0091",
  },
  {
    firstName: "LATE SHANTI RANJAN",
    lastName: "BANERJEE",
    address: "BAIDYADANGA*PO RASULPUR*DIST BURDWAN",
    fullName: "LATE SHANTI RANJAN BANERJEE",
    saID: "SPR0092",
  },
  {
    firstName: "LATE SISIR KUMAR",
    lastName: "BANERJEE",
    address: "AT-PATAKURA*PO&DT-COOCHBEHAR.",
    fullName: "LATE SISIR KUMAR BANERJEE",
    saID: "SPR0093",
  },
  {
    firstName: "LATE SMARAJIT",
    lastName: "BANERJEE",
    address: "32V,DOCTOR BAGAN LANE*SERAMPUR *DIST-HOOGHLY 712201",
    fullName: "LATE SMARAJIT BANERJEE",
    saID: "SPR0094",
  },
  {
    firstName: "LATE SUBHASH CHANDRA",
    lastName: "BANERJEE",
    address: "78/4/1 SWARNAMAYEE ROAD*PO-BERHAMPUR*DIST-MURSHIDABAD.",
    fullName: "LATE SUBHASH CHANDRA BANERJEE",
    saID: "SPR0095",
  },
  {
    firstName: "LATE SUDHENDU",
    lastName: "BANERJEE",
    address: "AT-ARSHIHARI*PO.GHURNI*NADIA",
    fullName: "LATE SUDHENDU BANERJEE",
    saID: "SPR0096",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "BANERJEE",
    address: "VILL-NARAYANDIGHI (NORTH PAR)*PO/DIST-BARDDHAMAN-713101.",
    fullName: "SUDHIR KUMAR BANERJEE",
    saID: "SPR0097",
  },
  {
    firstName: "SUKHENDU",
    lastName: "BANERJEE",
    address: "GHURNI MASJID LANE*PO-GHURNI*DIST-NADIA-741103",
    fullName: "SUKHENDU BANERJEE",
    saID: "SPR0098",
  },
  {
    firstName: "LATE TARAKDAS",
    lastName: "BANERJEE",
    address: "THAKUR BUNGLOW*SATSANG*DEOGHAR",
    fullName: "LATE TARAKDAS BANERJEE",
    saID: "SPR0099",
  },
  {
    firstName: "TARASHANKAR",
    lastName: "BANERJEE",
    address: "SWASTI DHAM*PO-AHMEDPUR*BIRBHUM.",
    fullName: "TARASHANKAR BANERJEE",
    saID: "SPR0100",
  },
  {
    firstName: "LATE TULSI CHARAN",
    lastName: "BANERJEE",
    address: "VIL&PO-BIROHI*NADIA.",
    fullName: "LATE TULSI CHARAN BANERJEE",
    saID: "SPR0101",
  },
  {
    firstName: "TUSHARKANTI",
    lastName: "BANERJEE",
    address:
      "SHREE KRISHNA ABASAN*TRIKAN PARK,VIVEKANANDA ROAD*PO-BARASAT*KOLKATA 700124",
    fullName: "TUSHARKANTI BANERJEE",
    saID: "SPR0102",
  },
  {
    firstName: "AMARNATH",
    lastName: "BANERJEE",
    address: "PLOT NO-3E/428,C.D.A.*SECTOR-9*CUTTACK-753014*PAN-ADWPB7628N",
    fullName: "AMARNATH BANERJEE",
    saID: "SPR0103",
  },
  {
    firstName: "LATE HEMANTA",
    lastName: "BANERJI",
    address: "DR T N BANERJI ROAD*CHHAJJU BAGH*PATNA-1",
    fullName: "LATE HEMANTA BANERJI",
    saID: "SPR0104",
  },
  {
    firstName: "NABIN CHANDRA",
    lastName: "BANGSHIARY",
    address: "AT-AMRABATI*PO-MATIPUR*VIA-MUSSALPUR*DIST-NALBARI.",
    fullName: "NABIN CHANDRA BANGSHIARY",
    saID: "SPR0105",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "BANIK",
    address:
      "VILL-WEST PRATAPGARH*KABIRAJ TILLA*PO-A.D.NAGAR*AGARTALA*TRIPURA WEST.",
    fullName: "AJIT KUMAR BANIK",
    saID: "SPR0106",
  },
  {
    firstName: "AMULYA KUMAR",
    lastName: "BANIK",
    address: "301, KASTADANGA ROAD*PO-SARSUNA*CALCUTTA-700061.",
    fullName: "AMULYA KUMAR BANIK",
    saID: "SPR0107",
  },
  {
    firstName: "LATE ANIL MOHAN",
    lastName: "BANIK",
    address: "RADHA MADHAB PARK*BILPAR*PO-SILCHAR-1*DT-CACHAR.",
    fullName: "LATE ANIL MOHAN BANIK",
    saID: "SPR0108",
  },
  {
    firstName: "BIPIN CHANDRA",
    lastName: "BANIK",
    address: "VILL-SANTIPARA*PO-BANERHAT*JALPAIGURI 735202",
    fullName: "BIPIN CHANDRA BANIK",
    saID: "SPR0109",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "BANIK",
    address: "VILL-SUDARSANPUR*PO-RAIGANJ*DIST-UTTAR DINAJPUR 733134",
    fullName: "BIJOY KUMAR BANIK",
    saID: "SPR0110",
  },
  {
    firstName: "HARIPADA",
    lastName: "BANIK",
    address: "PO-AMBAGAN*DIST-NAGAON-782 120*ASSAM",
    fullName: "HARIPADA BANIK",
    saID: "SPR0111",
  },
  {
    firstName: "LATE MAKHAN LAL",
    lastName: "BANIK",
    address: '"BIJAY LAXMI RICE MILL"*PO-TELIAMURA*TRIPURA',
    fullName: "LATE MAKHAN LAL BANIK",
    saID: "SPR0112",
  },
  {
    firstName: "PREMDAS",
    lastName: "BANIK",
    address: "LALCHERRA KHOWAI*PO-KHOWAI*TRIPURA",
    fullName: "PREMDAS BANIK",
    saID: "SPR0113",
  },
  {
    firstName: "RASARAJ",
    lastName: "BANIK",
    address:
      '"SATSANG ADHIBESAN KENDRA"*PURBA PRATAPGARH*AGARTALA-799004*TRIPURA(W).',
    fullName: "RASARAJ BANIK",
    saID: "SPR0114",
  },
  {
    firstName: "BADAL CHANDRA",
    lastName: "BAR",
    address:
      "RAJAPUR*PO-DAKSHIN BARASAT*DIST-24 PGS (SOUTH)-743372*PAN-ACZPB5179J",
    fullName: "BADAL CHANDRA BAR",
    saID: "SPR0115",
  },
  {
    firstName: "NIMAI",
    lastName: "BAR",
    address: "VILL-JODDAPOTA*PO-KHASMORA*DT-HOWRAH-711411",
    fullName: "NIMAI BAR",
    saID: "SPR0116",
  },
  {
    firstName: "GOPINATH",
    lastName: "BARAL",
    address: "AT-BANDHUPUR*PO-ORIKANTA*VIA-LEMALO*CUTTACK-754293",
    fullName: "GOPINATH BARAL",
    saID: "SPR0117",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "BARAL",
    address: "SWASTI VARIETY STORE*LALITESWAR NAGAR*JAJPUR*ORISSA",
    fullName: "KRUSHNA CHANDRA BARAL",
    saID: "SPR0118",
  },
  {
    firstName: "LATE HIRENDRA NATH",
    lastName: "BARAT",
    address: "TYPE-2.QR.NO-12*N.B.MATIBAGH,SWASTRI NIKETAN*DELHI",
    fullName: "LATE HIRENDRA NATH BARAT",
    saID: "SPR0119",
  },
  {
    firstName: "LATE BEPIN BEHARI",
    lastName: "BARDHAN",
    address: "BADAM TALA,KALNA RD*CHANDMARY,KACHIPUKUR*BURDWAN",
    fullName: "LATE BEPIN BEHARI BARDHAN",
    saID: "SPR0120",
  },
  {
    firstName: "DIPNARAYAN",
    lastName: "BORGOYARY",
    address: "AT-SHIMLABAGAN*PO-SANTIPUR-783375*DT-CHIRANG B T A D*ASSAM",
    fullName: "DIPNARAYAN BORGOYARY",
    saID: "SPR0121",
  },
  {
    firstName: "HEM KANTA",
    lastName: "BARGOHAIN",
    address: "SANTIPUR,BOKAJAN*PO-BOKAJAN*DIST-KARBIANGLONG-782480*ASSAM",
    fullName: "HEM KANTA BARGOHAIN",
    saID: "SPR0122",
  },
  {
    firstName: "HEMKANTHA",
    lastName: "BARGOHAIN",
    address: "GOUHATI THERMAL PROJECT*PO-CHANRAPUR*DIST-KAMRUP",
    fullName: "HEMKANTHA BARGOHAIN",
    saID: "SPR0123",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "BARGOHAIN",
    address: "VILL&PO-MECHAGARH*DIST-SIBSAGAR.",
    fullName: "JATINDRA NATH BARGOHAIN",
    saID: "SPR0124",
  },
  {
    firstName: "LATE LAKHESWAR",
    lastName: "BARGOHAIN",
    address: "BHUBAN GOGAI ROAD*PO-ONGC COLONY*DT-SIBSAGAR.",
    fullName: "LATE LAKHESWAR BARGOHAIN",
    saID: "SPR0125",
  },
  {
    firstName: "LATE MAHENDRA NATH",
    lastName: "BARGOHAIN",
    address: "VILL/PO-SRIPURIA*TINSSUKIA 786145.",
    fullName: "LATE MAHENDRA NATH BARGOHAIN",
    saID: "SPR0126",
  },
  {
    firstName: "BHASKAR CHANDRA",
    lastName: "BARIK",
    address: "NEW UDAY NAGAR*ANGARGARIA*BALASORE*PAN-AIZPB8812E",
    fullName: "BHASKAR CHANDRA BARIK",
    saID: "SPR0127",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "BARIK",
    address:
      "SANDIPANA ; L IV 376*HOUSING BOARD COLONY*PO-DUMDUMA*BHUBANESWAR-19*PAN-ANIPB9944K",
    fullName: "BHIKARI CHARAN BARIK",
    saID: "SPR0128",
  },
  {
    firstName: "CHINTA MANI",
    lastName: "BARIK",
    address: "AT-JAGESWARPUR*PO-JAJPUR TOWN*JAJPUR-755001*PAN NO.ASJPB8687K",
    fullName: "CHINTA MANI BARIK",
    saID: "SPR0129",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "BARIK",
    address:
      "AT-UTEIPUR*PO-DEHURI ANANDAPUR*VIA-DASARATHPUR*DIST-JAJPUR-755006*ODISHA",
    fullName: "GOBINDA CHANDRA BARIK",
    saID: "SPR0130",
  },
  {
    firstName: "LOKENATH",
    lastName: "BARIK",
    address: "AT.SUNDARIA*PO.NEULPUR*VIA.HARIDASPUR*JAJPUR-755024",
    fullName: "LOKENATH BARIK",
    saID: "SPR0131",
  },
  {
    firstName: "NILAKANTHA",
    lastName: "BARIK",
    address: "AT-JAGESWARPUR*PO-JAJPUR*JAJPUR  755001",
    fullName: "NILAKANTHA BARIK",
    saID: "SPR0132",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "BARIK",
    address: "AT-UTEIPUR*PO-DEHURIANANDA PUR*VIA-DASARATHPUR*JAJPUR",
    fullName: "LATE NIRANJAN BARIK",
    saID: "SPR0133",
  },
  {
    firstName: "LATE DEBENDRA CHANDRA",
    lastName: "BARKATKI",
    address: "BHABANIPUR DERGAON WARD NO-3*PO-DERGAON*GOLAGHAT-785614.",
    fullName: "LATE DEBENDRA CHANDRA BARKATKI",
    saID: "SPR0134",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "BARMA",
    address:
      "HOSPITAL PARA*PO-BUNIADPUR*DAKSHIN DINAJPUR-733121*PAN-BUHPB5494H",
    fullName: "RAMESH CHANDRA BARMA",
    saID: "SPR0135",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "BARMAN",
    address: "TEZPUR SATSANG VIHAR*PO-TEZPUR*DT-SONITPUR-784001.",
    fullName: "LATE BASANTA KUMAR BARMAN",
    saID: "SPR0136",
  },
  {
    firstName: "LATE BENODE BEHARI",
    lastName: "BARMAN",
    address: "SATSANG VIHAR,BARABISHA*PO-BARABISHA*JALPAIGURI 736207",
    fullName: "LATE BENODE BEHARI BARMAN",
    saID: "SPR0137",
  },
  {
    firstName: "BHUBAN CHANDRA",
    lastName: "BARMAN",
    address: "VILL-BHOLAPARA*PO-HATIPARA*DIST-DHUBRI",
    fullName: "BHUBAN CHANDRA BARMAN",
    saID: "SPR0138",
  },
  {
    firstName: "BIDYADHAN",
    lastName: "BARMAN",
    address: "KACHARIBASTI*PO-JOGIJAN*DT-NOWGANG.",
    fullName: "BIDYADHAN BARMAN",
    saID: "SPR0139",
  },
  {
    firstName: "LATE BINOY KUMAR",
    lastName: "BARMAN",
    address: "REGT.CONTRACTOR,P W D*PO-LANKA*DIST-NAGAON*ASSAM.",
    fullName: "LATE BINOY KUMAR BARMAN",
    saID: "SPR0140",
  },
  {
    firstName: "BIPRADAS",
    lastName: "BARMAN",
    address: "AT/P.O.SULGURI*GARO HILLS*MEGHALAYA-794115",
    fullName: "BIPRADAS BARMAN",
    saID: "SPR0141",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "BARMAN",
    address: "AT&PO-PURIA*VIA-KALIAGANJ*DIST-UTTAR DINAJPUR",
    fullName: "LATE BIRENDRA NATH BARMAN",
    saID: "SPR0142",
  },
  {
    firstName: "LATE BIRESWAR",
    lastName: "BARMAN",
    address: "PO+VILL KUMRAKATA*HOJAI*NOWGAON 782 435",
    fullName: "LATE BIRESWAR BARMAN",
    saID: "SPR0143",
  },
  {
    firstName: "DEBABRATA",
    lastName: "BARMAN",
    address: "AT&PO-KHERONI*KARBIANGLONG 782448",
    fullName: "DEBABRATA BARMAN",
    saID: "SPR0144",
  },
  {
    firstName: "LATE DEBENDRA KUMAR",
    lastName: "BARMAN",
    address: "SATSANG KENDRA,DIPHU*P.O.DIPHU(AMLAPATTY)*KARBIANGLONG-782460",
    fullName: "LATE DEBENDRA KUMAR BARMAN",
    saID: "SPR0145",
  },
  {
    firstName: "LATE DHAN MOHAN",
    lastName: "BARMAN",
    address: "PO-GOSSAIRHAT BHANDER*DT-COOCHBEHAR.",
    fullName: "LATE DHAN MOHAN BARMAN",
    saID: "SPR0146",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "BARMAN",
    address: "VILL&PO-DHULAGAON*DT-JALPAIGURI.",
    fullName: "LATE DHIRENDRA NATH BARMAN",
    saID: "SPR0147",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "BARMAN",
    address: "VILL-KANAKPUR*PO-KANAKPUR EAST*CACHAR.",
    fullName: "GANESH CHANDRA BARMAN",
    saID: "SPR0148",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "BARMAN",
    address: "SATSANG VIHAR,GAUHATI-781005*DIST KAMRUP",
    fullName: "HEMANTA KUMAR BARMAN",
    saID: "SPR0149",
  },
  {
    firstName: "JALA KUMAR",
    lastName: "BARMAN",
    address: "VILL-SADAGRAM*PO-DHOLAI BAZAR*CACHAR-788114*ASSAM",
    fullName: "JALA KUMAR BARMAN",
    saID: "SPR0150",
  },
  {
    firstName: "LATE KSHITINDRA NATH",
    lastName: "BARMAN",
    address: "AT&PO-PASCHIM SHALBAN*ALIPURDUAR*JALPAIGURI",
    fullName: "LATE KSHITINDRA NATH BARMAN",
    saID: "SPR0151",
  },
  {
    firstName: "LATE NAGARBASI",
    lastName: "BARMAN",
    address: "AT/PO-SAPATGRAM*GOALPARA",
    fullName: "LATE NAGARBASI BARMAN",
    saID: "SPR0152",
  },
  {
    firstName: "NARENDRA CHANDRA",
    lastName: "BARMAN",
    address: "AT+PO-AMBAGAN*DIST-NAGAON 782 120*ASSAM",
    fullName: "NARENDRA CHANDRA BARMAN",
    saID: "SPR0153",
  },
  {
    firstName: "LATE NARROTTAM",
    lastName: "BARMAN",
    address: "VILL-SANTI NAGAR*PO-TELIAMURA*TRIPURA(799205)",
    fullName: "LATE NARROTTAM BARMAN",
    saID: "SPR0154",
  },
  {
    firstName: "LATE RAM MOHAN",
    lastName: "BARMAN",
    address: "AT BIJOYPUR-BARKHOLA*PO SILCHAR*CACHAR.",
    fullName: "LATE RAM MOHAN BARMAN",
    saID: "SPR0155",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "BARMAN",
    address: "PABITRAPARA *PO&DT.-JALPAIGURI",
    fullName: "LATE RAMESH CHANDRA BARMAN",
    saID: "SPR0156",
  },
  {
    firstName: "LATE RATIBAR",
    lastName: "BARMAN",
    address: "VILL-PAKURITALA*PO-TAPSIKHATA*DT-JALPAIGURI.",
    fullName: "LATE RATIBAR BARMAN",
    saID: "SPR0157",
  },
  {
    firstName: "SRIDHAR CHANDRA",
    lastName: "BARMAN",
    address: "VILL-BARA SOULMARI*PO-SUBACHANIRPATH*COOCHBEHAR",
    fullName: "SRIDHAR CHANDRA BARMAN",
    saID: "SPR0158",
  },
  {
    firstName: "LATE UDAY MOHAN",
    lastName: "BARMAN",
    address: "BIJNI SATSANG BIHAR*PO-BIJNI*DT-BONGAIGAON",
    fullName: "LATE UDAY MOHAN BARMAN",
    saID: "SPR0159",
  },
  {
    firstName: "UMENDRA",
    lastName: "BARMAN",
    address: "SATSANGVIHAR*PO-TURA-794001*GARO HILLS (WEST)",
    fullName: "UMENDRA BARMAN",
    saID: "SPR0160",
  },
  {
    firstName: "UMESH CHANDRA",
    lastName: "BARMAN",
    address: "SHITALKUCHI*PO-GOSSAIRHAT BHANDAR*DT-COOCHBEHAR.",
    fullName: "UMESH CHANDRA BARMAN",
    saID: "SPR0161",
  },
  {
    firstName: "LATE MATHUR PRASAD",
    lastName: "BARTHAKUR",
    address: "C/O-ELECTRICAL MART*PO-SRIPURIA*TINSUKIA.",
    fullName: "LATE MATHUR PRASAD BARTHAKUR",
    saID: "SPR0162",
  },
  {
    firstName: "BIMAL CHANDRA",
    lastName: "BARUA",
    address: "C/O BARUAH & SONS*PO-SEPON*SIBSAGAR 785673",
    fullName: "BIMAL CHANDRA BARUA",
    saID: "SPR0163",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "BARUA",
    address: "VILL-CHENGAMARI*PO-BIDYAPUR-783372*GOALPARA",
    fullName: "LATE DEBENDRA NATH BARUA",
    saID: "SPR0164",
  },
  {
    firstName: "LATE MUKTALAL",
    lastName: "BARUA",
    address: "NAMDEURI HIGH SCHOOL*PO-NAMDEURI*JORHAT",
    fullName: "LATE MUKTALAL BARUA",
    saID: "SPR0165",
  },
  {
    firstName: "SAMAR KUMAR",
    lastName: "BARUA",
    address:
      "RAJIB PATH,LANKA ROAD*PO-LUMDING*DIST-NAGAON 782447*ASSAM*PAN-AOQPB0659C",
    fullName: "SAMAR KUMAR BARUA",
    saID: "SPR0166",
  },
  {
    firstName: "SANJIB",
    lastName: "BARUA",
    address: "DGM (COM.REV) ASEB**BIJULI BHABAN*GUWAHATI-1.",
    fullName: "SANJIB BARUA",
    saID: "SPR0167",
  },
  {
    firstName: "SUBAL",
    lastName: "BARUA",
    address: "OPERATOR P/M/C*HPC LTD*N.P.P*PO-JAGI ROAD*DIST-MORIGAON-782410",
    fullName: "SUBAL BARUA",
    saID: "SPR0168",
  },
  {
    firstName: "LATE JOYRAM",
    lastName: "BARUAH",
    address: "2NO KACHUTALI*PO-MAYNAPARA*DT-LAKHIMPUR.",
    fullName: "LATE JOYRAM BARUAH",
    saID: "SPR0169",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "BARUA",
    address:
      "BYE LANE-4;PRAGJYOTISH NAGAR*PO-MALIGAON*GUWAHATI-781011*KAMRUP*ASSAM",
    fullName: "PRADIP KUMAR BARUA",
    saID: "SPR0170",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "BARUAH",
    address: "SILCHAR GHUMGUR MEDICAL COLLEGE*CACHAR",
    fullName: "PRAFULLA CHANDRA BARUAH",
    saID: "SPR0171",
  },
  {
    firstName: "LATE PRAFULLA CHANDRA",
    lastName: "BARUAH",
    address: "NORTH LAKHIMPUR NAGAR*PO&DT-LAKHIMPUR*ASSAM",
    fullName: "LATE PRAFULLA CHANDRA BARUAH",
    saID: "SPR0172",
  },
  {
    firstName: "PRANOBE KUMAR",
    lastName: "BARUAH",
    address: "A.I.D.EXECUTIVE ENGR.*P.W.D.F&D SILCHAR*CACHAR.",
    fullName: "PRANOBE KUMAR BARUAH",
    saID: "SPR0173",
  },
  {
    firstName: "LATE PUTUL CHANDRA",
    lastName: "BARUAH",
    address: "DIGBOI CIVIL HOSPITAL*PO-DIGBOI*DIBRUGARH.",
    fullName: "LATE PUTUL CHANDRA BARUAH",
    saID: "SPR0174",
  },
  {
    firstName: "LATE BRINDABAN BIHARI",
    lastName: "BASAK",
    address: "KALNA ROAD DESHBANDHUNAGAR*PO&DIST-BURDWAN",
    fullName: "LATE BRINDABAN BIHARI BASAK",
    saID: "SPR0175",
  },
  {
    firstName: "RAGHUNATH",
    lastName: "BASTIA",
    address:
      '"BEDAYAN",PLOT NO-491*AT-BORAPADA*PO-GAHAMAKHUNTI*VIA-GOBINDPUR*DHENKANAL-759001',
    fullName: "RAGHUNATH BASTIA",
    saID: "SPR0176",
  },
  {
    firstName: "BABULAL",
    lastName: "BASU",
    address: "SARANGABAD*MAHESHTALA*24PARGANAS (SOUTH)-700141",
    fullName: "BABULAL BASU",
    saID: "SPR0177",
  },
  {
    firstName: "LATE HIRENDRA MOHAN",
    lastName: "BASU",
    address: "WESTERN BOUNDARY RD.*DINHATA TOWN WORD-6*DINHATA*COOCH BEHAR",
    fullName: "LATE HIRENDRA MOHAN BASU",
    saID: "SPR0178",
  },
  {
    firstName: "LATE SIBNATH",
    lastName: "BASU",
    address: "AT-CHAPABERIA*PO-BONGAON*DIST-24 PGS (SOUTH)-700141",
    fullName: "LATE SIBNATH BASU",
    saID: "SPR0179",
  },
  {
    firstName: "KINKAR CHANDRA",
    lastName: "BASULI",
    address: "VILL-KALABERIA*PO-BELIATORE*DIST-BANKURA-722203*PAN-AENPH8354K",
    fullName: "KINKAR CHANDRA BASULI",
    saID: "SPR0180",
  },
  {
    firstName: "SHANKAR CHANDRA",
    lastName: "BASULI",
    address: "AT KALBERIA*PO BELIATORE*DIST BANKURA-722203",
    fullName: "SHANKAR CHANDRA BASULI",
    saID: "SPR0181",
  },
  {
    firstName: "MANIRAM",
    lastName: "BASUMATARI",
    address:
      "GORESWAR SATSANG VIHAR*PO-GORESWAR-781366*BAKSA (B.T.A.D.)*ASSAM*PAN-AWJPB8173A",
    fullName: "MANIRAM BASUMATARI",
    saID: "SPR0182",
  },
  {
    firstName: "LATE BALIT NARAYAN",
    lastName: "BASUMATARY",
    address: "VILL&PO-JOYPUR*VIA-BIJNI*DIST-KOKRAJHAR.",
    fullName: "LATE BALIT NARAYAN BASUMATARY",
    saID: "SPR0183",
  },
  {
    firstName: "DEBAPATI",
    lastName: "BASUMATARY",
    address: "AT/PO-ATHIABARI*VIA-TILU*DT-NALBARI",
    fullName: "DEBAPATI BASUMATARY",
    saID: "SPR0184",
  },
  {
    firstName: "DEBENDRA",
    lastName: "BASUMATARY",
    address: "NO-2 SHANTIPUR.*PO-GHARMARA*SONITPUR*PAN-BOTPB0829Q",
    fullName: "DEBENDRA BASUMATARY",
    saID: "SPR0185",
  },
  {
    firstName: "LATE DHANESWAR",
    lastName: "BASUMATARY",
    address: "PO+V-UDALGURI.MANPURGAON*DIST-DARRANG.",
    fullName: "LATE DHANESWAR BASUMATARY",
    saID: "SPR0186",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "BASUMATARY",
    address: "AT-RANGAPANI*PO KAMARDAICHA*DIST KAMRUP",
    fullName: "DHARANIDHAR BASUMATARY",
    saID: "SPR0187",
  },
  {
    firstName: "LATE DIBANANDA",
    lastName: "BASUMATARY",
    address: "VILL-PUTHIMARY*PO-DOTMA*DIST-GOALPARA.",
    fullName: "LATE DIBANANDA BASUMATARY",
    saID: "SPR0188",
  },
  {
    firstName: "HARI CHARAN",
    lastName: "BASUMATARY",
    address: "VILL-ISLAKHATA*PO-GORUBHASA-783373*DIST-BCHIRANG B T A D*ASSAM",
    fullName: "HARI CHARAN BASUMATARY",
    saID: "SPR0189",
  },
  {
    firstName: "JAGAT CHANDRA",
    lastName: "BASUMATARY",
    address: "VILL-BHALUKMARI*PO-JAMUGURI*VIA-ORANG*DARRANG 784114",
    fullName: "JAGAT CHANDRA BASUMATARY",
    saID: "SPR0190",
  },
  {
    firstName: "JATIN CHANDRA",
    lastName: "BASUMATARY",
    address: "VILL-DABDAPI RANGPUR*PO-RANGAPARA*SONITPUR-784505*ASSAM",
    fullName: "JATIN CHANDRA BASUMATARY",
    saID: "SPR0191",
  },
  {
    firstName: "LATE KRISHNA KANTA",
    lastName: "BASUMATARI",
    address: "V BALISIAHABI*PO ROWTACHARIALI*DARRANG.",
    fullName: "LATE KRISHNA KANTA BASUMATARI",
    saID: "SPR0192",
  },
  {
    firstName: "MOMENDRA NATH",
    lastName: "BASUMATARY",
    address: "D.F.O.OFFICE*KOKRAJHAR-783370*ASSAM",
    fullName: "MOMENDRA NATH BASUMATARY",
    saID: "SPR0193",
  },
  {
    firstName: "LATE NARENDRA CHANDRA",
    lastName: "BASUMATARY",
    address: "VILL-GHOSHKATA*PO-BANARGAON*VIA-DOTMA*GOALPARA",
    fullName: "LATE NARENDRA CHANDRA BASUMATARY",
    saID: "SPR0194",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "BASUMATARY",
    address: "PANBARI SATSANG KENDRA*PO-PANBARI BAZAR*KOKRAJHAR 783 360",
    fullName: "LATE RABINDRA NATH BASUMATARY",
    saID: "SPR0195",
  },
  {
    firstName: "LATE RUPRAO",
    lastName: "BASUMATARY",
    address: "PO-NEHERU BAJAR*GOALPARA",
    fullName: "LATE RUPRAO BASUMATARY",
    saID: "SPR0196",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "BASUMATARY",
    address: "VILL-BHIRANGGAON*PO-DANGAIGAON*BONGAIGAON*PAN-ADCPB0456E",
    fullName: "SARAT CHANDRA BASUMATARY",
    saID: "SPR0197",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "BASUMATARY",
    address: "VILL/PO-PATGAON*GOALPARA.",
    fullName: "LATE SUDHIR KUMAR BASUMATARY",
    saID: "SPR0198",
  },
  {
    firstName: "TARUN CHANDRA",
    lastName: "BASUMATARY",
    address: "AT-MANUJULI*PO-DHEKIAJULI*SONITPUR 784110",
    fullName: "TARUN CHANDRA BASUMATARY",
    saID: "SPR0199",
  },
  {
    firstName: "UTCHAB CHANDRA",
    lastName: "BASUMATARY",
    address: "VILL-BARGHAGRA*PO KAKLABARI*VIA JALAHGHAT*BARPETA-781327",
    fullName: "UTCHAB CHANDRA BASUMATARY",
    saID: "SPR0200",
  },
  {
    firstName: "RADHA KRISHNA",
    lastName: "BASURI",
    address: "AT/PO-GOPIBALLAVPUR*DIST-PASCHIM MEDINIPUR-721506",
    fullName: "RADHA KRISHNA BASURI",
    saID: "SPR0201",
  },
  {
    firstName: "LATE ANADI CHARAN",
    lastName: "BEHERA",
    address: "AT-DEWLI*PO-BASUDEBPUR*BHADRAK",
    fullName: "LATE ANADI CHARAN BEHERA",
    saID: "SPR0202",
  },
  {
    firstName: "BANSHIDHAR",
    lastName: "BEHERA",
    address:
      '"DAYAL TIRTHA"*AT-JANARDANPUR*PO-LENDURA*CUTTACK 754203*PAN NO.AEPPB7034J',
    fullName: "BANSHIDHAR BEHERA",
    saID: "SPR0203",
  },
  {
    firstName: "GADADHAR",
    lastName: "BEHERA",
    address: "JAGATPUR NARAYAN VIHAR*I B ROAD*CUTTACK*ODISHA",
    fullName: "GADADHAR BEHERA",
    saID: "SPR0204",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "BEHERA",
    address:
      '"DEBARATEE"*L.P.234,PRASANTI VIHAR H.B.COLONY*PO-KIIT CAMPUS*B.B.S.R-24*KHORDHA',
    fullName: "KAILASH CHANDRA BEHERA",
    saID: "SPR0205",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "BEHERA",
    address: "VILL.DHANSOLA*PO.MORADA-757020*MAYURBHANJ.",
    fullName: "KAMALA KANTA BEHERA",
    saID: "SPR0206",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "BEHERA",
    address: "AT/PO-ANANDAPUR*DIST-KEONJHAR-758021*ODISHA*PAN-CKKPB4475Q",
    fullName: "LAXMIDHAR BEHERA",
    saID: "SPR0207",
  },
  {
    firstName: "NIRANJAN",
    lastName: "BEHERA",
    address: "VILL-BANKICHHANDA*PO-DEULPARA*KENDRAPARA 754212*PAN-BDIPB0771R",
    fullName: "NIRANJAN BEHERA",
    saID: "SPR0208",
  },
  {
    firstName: "LATE NRUSHINGHA CHARAN",
    lastName: "BEHERA",
    address: "C/O SATSANG VIHAR,ROURKELA*PO-ROURKELA-769010*SUNDARGARH.",
    fullName: "LATE NRUSHINGHA CHARAN BEHERA",
    saID: "SPR0209",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "BEHERA",
    address: "VILL-ODAPADA*PO-HINDAL ROAD*DHENKANAL-759019",
    fullName: "PURNA CHANDRA BEHERA",
    saID: "SPR0210",
  },
  {
    firstName: "TARA KANTA",
    lastName: "BEHERA",
    address: "DEVELOPMENT OFFICER*NEW INDIA ASSURANCE*ANGUL-759122",
    fullName: "TARA KANTA BEHERA",
    saID: "SPR0211",
  },
  {
    firstName: "LATE ARUN KUMAR",
    lastName: "BEHURA",
    address: "VILL-RAMKUMARPUR*PO-SOMEPUR*CUTTACK 754130",
    fullName: "LATE ARUN KUMAR BEHURA",
    saID: "SPR0212",
  },
  {
    firstName: "NARASINGHA CHARAN",
    lastName: "BEHURA",
    address: "VILL-RAMKUMARPUR*PO-SOMEPUR*CUTTACK 754130",
    fullName: "NARASINGHA CHARAN BEHURA",
    saID: "SPR0213",
  },
  {
    firstName: "KSHITISH CHANDRA",
    lastName: "BEPARI",
    address: "KALYANI NUTANPALLI TALTALA*PO.KALYANI*NADIA-741235",
    fullName: "KSHITISH CHANDRA BEPARI",
    saID: "SPR0214",
  },
  {
    firstName: "BISWA RANJAN",
    lastName: "BERA",
    address: "SATSANG VIHAR TAMLUK*PO-TAMLUK*DIST-PURBA MEDINIPUR-721636",
    fullName: "BISWA RANJAN BERA",
    saID: "SPR0215",
  },
  {
    firstName: "LATE HARE KRISHNA",
    lastName: "BERA",
    address: "C/O GITA MAITY*BLOCK OFFICE-1 EGRA*PO-EGRA*DIST-MIDNAPUR",
    fullName: "LATE HARE KRISHNA BERA",
    saID: "SPR0216",
  },
  {
    firstName: "LATE KANAI CHARAN",
    lastName: "BERA",
    address: "AT&PO-CHOULKHOLA*DIST-PURBA MEDINIPUR",
    fullName: "LATE KANAI CHARAN BERA",
    saID: "SPR0217",
  },
  {
    firstName: "LATE KARTIK CHANDRA",
    lastName: "BERA",
    address: "ARAMBAG SATSANG VIHAR*PO-ARAMBAG*(KALIPUR)*DIST-HOOGHLY-712601",
    fullName: "LATE KARTIK CHANDRA BERA",
    saID: "SPR0218",
  },
  {
    firstName: "LATE KHAGENDRA NATH",
    lastName: "BERA",
    address: "VILL-UBIDPUR*PO-KHANAKUL*HOOGHLY.",
    fullName: "LATE KHAGENDRA NATH BERA",
    saID: "SPR0219",
  },
  {
    firstName: "LATE NANDALAL",
    lastName: "BERA",
    address: "SASHIBHUSAN HOMEO HALL*3 BANAMALI NASKAR ROAD*CALCUTTA 60",
    fullName: "LATE NANDALAL BERA",
    saID: "SPR0220",
  },
  {
    firstName: "PARITOSH",
    lastName: "BERA",
    address: "AT-MANSADWIP*PO-KHASMAHAL*DIST-24 PARGANAS (SOUTH)-743373",
    fullName: "PARITOSH BERA",
    saID: "SPR0221",
  },
  {
    firstName: "RAMESWAR",
    lastName: "BERA",
    address: "PO/VILL-SHIBNAGAR ABAD*DIST-24 PGS (SOUTH)-743399",
    fullName: "RAMESWAR BERA",
    saID: "SPR0222",
  },
  {
    firstName: "SHIBA PRASAD",
    lastName: "BERA",
    address: "AT-KHEJURI*PO-JOTNARAYAN*DIST-PURBA MEDINIPUR",
    fullName: "SHIBA PRASAD BERA",
    saID: "SPR0223",
  },
  {
    firstName: "SHYAMSUNDAR",
    lastName: "BERA",
    address: "VILL-JOYKRISHNAPUR*PO-BOROGOBINDA*DIST-PASCHIM MEDINIPUR",
    fullName: "SHYAMSUNDAR BERA",
    saID: "SPR0224",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "KARMAKAR",
    address: "627/1 D H ROAD*KOLKATA-34",
    fullName: "BIKASH CHANDRA KARMAKAR",
    saID: "SPR0225",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "BEURA",
    address:
      "BIDANASHI CDA ROAD*AT-BIDANASHI*PO-ABHIBAV BIDANASI*CUTTACK-753014",
    fullName: "SURENDRA NATH BEURA",
    saID: "SPR0226",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "BHADRA",
    address: "V-SAMSARA*PO-PAROI*DIST-MIDNAPUR.",
    fullName: "LATE ANANTA KUMAR BHADRA",
    saID: "SPR0227",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "BHADRA",
    address: "SATSANG ASHRAM PAKUTIA*P.O.D.PAKUTIA DIST-TANGAIL BANGLADESH",
    fullName: "GOPAL CHANDRA BHADRA",
    saID: "SPR0228",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "BHADURI",
    address: "AT/PO MAHARIPARA*DIST-KAMRUP",
    fullName: "LATE MANORANJAN BHADURI",
    saID: "SPR0229",
  },
  {
    firstName: "LATE BURAN",
    lastName: "BHAGAT",
    address: "KURTI T.E.*PO-NAGRAKATA*JALPAIGURI",
    fullName: "LATE BURAN BHAGAT",
    saID: "SPR0230",
  },
  {
    firstName: "LATE DAMODAR",
    lastName: "BHAINA",
    address: "VILL-PITHAGUTI*DIBRUGARH",
    fullName: "LATE DAMODAR BHAINA",
    saID: "SPR0231",
  },
  {
    firstName: "HEMANT  KUMAR",
    lastName: "BHANDARI",
    address: "AT&PO-GOBBERIA*DIST-24 PGS.",
    fullName: "HEMANT  KUMAR BHANDARI",
    saID: "SPR0232",
  },
  {
    firstName: "INDRA PRASAD",
    lastName: "BHANDARI",
    address: "C/O CHURAMANI UPADHYAY*PO-PATGAON*KOKRAJHAR-783346*ASSAM",
    fullName: "INDRA PRASAD BHANDARI",
    saID: "SPR0233",
  },
  {
    firstName: "LATE SAILEN KUMAR",
    lastName: "BHANDARI",
    address: "VILL/PO-RAJNAGAR*DIST-BIRBHUM-731130",
    fullName: "LATE SAILEN KUMAR BHANDARI",
    saID: "SPR0234",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "BHANJA",
    address: "SATSANG THAKUR BARI*MEDINIPUR*PASCHIM MEDINIPUR 721 101.",
    fullName: "ANIL KUMAR BHANJA",
    saID: "SPR0235",
  },
  {
    firstName: "MANMATHA NATH",
    lastName: "BHANJA",
    address: "AT/PO.KAMARDA*BALASORE-756035",
    fullName: "MANMATHA NATH BHANJA",
    saID: "SPR0236",
  },
  {
    firstName: "LATE LALIT CHANDRA",
    lastName: "BHARALI",
    address: "AT-RANGDALAI*PO-NARTAP*DIST-KAMRUP",
    fullName: "LATE LALIT CHANDRA BHARALI",
    saID: "SPR0237",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "BHAT",
    address: "SRIPUR COLONY*PO-KALYANPUR*DIST-GOPALGANJ",
    fullName: "LATE HARENDRA CHANDRA BHAT",
    saID: "SPR0238",
  },
  {
    firstName: "ASIM KUMAR",
    lastName: "BHATTACHARJEE",
    address: "PO-DULMI*NADIHA*PURULIA 723102*PAN-ACGPB6158C",
    fullName: "ASIM KUMAR BHATTACHARJEE",
    saID: "SPR0239",
  },
  {
    firstName: "LATE AJIT RANJAN",
    lastName: "BHATTACHARJEE",
    address: "UMPLING*SHILLONG-793006*MEGHALAYA",
    fullName: "LATE AJIT RANJAN BHATTACHARJEE",
    saID: "SPR0240",
  },
  {
    firstName: "LATE AMAL KUMAR",
    lastName: "BHATTACHARJEE",
    address: "VILL-SHYAMSUNDARPUR KHAPUKUR*PO-SAHAGANJ*HOOGHLY",
    fullName: "LATE AMAL KUMAR BHATTACHARJEE",
    saID: "SPR0241",
  },
  {
    firstName: "LATE ANIL RANJAN",
    lastName: "BHATTACHARJEE",
    address: "VILL/PO-PARBANGLA*DIST-24 PARAGANAS",
    fullName: "LATE ANIL RANJAN BHATTACHARJEE",
    saID: "SPR0242",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "BHATTACHARJEE",
    address: "AT-SATSANG-814116",
    fullName: "LATE ASHUTOSH BHATTACHARJEE",
    saID: "SPR0243",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "BHATTACHARJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE BIRENDRA NATH BHATTACHARJEE",
    saID: "SPR0244",
  },
  {
    firstName: "LATE BIRESH RANJAN",
    lastName: "BHATTACHARJEE",
    address: "MONORAMA STORES*37 B T ROAD*MANIPUR",
    fullName: "LATE BIRESH RANJAN BHATTACHARJEE",
    saID: "SPR0245",
  },
  {
    firstName: "LATE BRAJO GOPAL",
    lastName: "BHATTACHARJEE",
    address: "VILL-JHAPPUKUR,PYNE BAGAN*PO-SAHAGANJ*HOOGHLY",
    fullName: "LATE BRAJO GOPAL BHATTACHARJEE",
    saID: "SPR0246",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "BHATTACHARJEE",
    address: "172 SASHIBABU ROAD*SAHIDNAGAR*KANCHRAPARA*DIST-24-PGS.",
    fullName: "LATE CHITTARANJAN BHATTACHARJEE",
    saID: "SPR0247",
  },
  {
    firstName: "DEBIDAS",
    lastName: "BHATTACHARJEE",
    address: "SHABARI DASARATHPUR SCHOOL*P.O.RANGAT*MIDDLE ANDAMAN",
    fullName: "DEBIDAS BHATTACHARJEE",
    saID: "SPR0248",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "BHATTACHARJEE",
    address: "113 KALICHARAN GHOSH ROAD*CALCUTTA 50",
    fullName: "LATE DHIRENDRA NATH BHATTACHARJEE",
    saID: "SPR0249",
  },
  {
    firstName: "LATE DIBAKAR",
    lastName: "BHATTACHARJEE",
    address: "WARD NO-4,JUMBASTI*PO-BADARPUR*KARIMGANJ",
    fullName: "LATE DIBAKAR BHATTACHARJEE",
    saID: "SPR0250",
  },
  {
    firstName: "LATE DHIRENDRA KISHORE",
    lastName: "BHATTACHARJEE",
    address: "*SATSANG 814116.",
    fullName: "LATE DHIRENDRA KISHORE BHATTACHARJEE",
    saID: "SPR0251",
  },
  {
    firstName: "LATE DURGADAS",
    lastName: "BHATTACHARJEE",
    address: "1/1 MAHENDRA BHATTACHARJEE ROAD*PO.SANTRAGACHI*HOWRAH",
    fullName: "LATE DURGADAS BHATTACHARJEE",
    saID: "SPR0252",
  },
  {
    firstName: "LATE DWIJADAS",
    lastName: "BHATTACHARJEE",
    address:
      "SENIOR AUDIT OFFICER*10 ANANDALOK KATGOLA*PO-SODEPUR*24PGS(NORTH)-700110.",
    fullName: "LATE DWIJADAS BHATTACHARJEE",
    saID: "SPR0253",
  },
  {
    firstName: "LATE GURU PRASANNA",
    lastName: "BHATTACHARJEE",
    address: "SATSANG*814116",
    fullName: "LATE GURU PRASANNA BHATTACHARJEE",
    saID: "SPR0254",
  },
  {
    firstName: "LATE HEMENDRA BHUSAN",
    lastName: "BHATTACHARJEE",
    address: "AT/PO-RADHAKANTAPUR*VIA-MEMARI*DIST-BURDWAN",
    fullName: "LATE HEMENDRA BHUSAN BHATTACHARJEE",
    saID: "SPR0255",
  },
  {
    firstName: "JAGANNATH",
    lastName: "BHATTACHARJEE",
    address: "C/O POST MASTER*PO-FULERTAL-788106*CACHAR*PAN-AEHPB3607H",
    fullName: "JAGANNATH BHATTACHARJEE",
    saID: "SPR0256",
  },
  {
    firstName: "LATE JANARDAN",
    lastName: "BHATTACHARJEE",
    address: "A-10/278 KALYANI*PO-KALYANI*NADIA 741235",
    fullName: "LATE JANARDAN BHATTACHARJEE",
    saID: "SPR0257",
  },
  {
    firstName: "JANHABI CHARAN",
    lastName: "BHATTACHARJEE",
    address: "SINGARUI SATSANG ASHRAM*PO-RAJNAGAR*PASCHIM MIDNAPUR-721436.",
    fullName: "JANHABI CHARAN BHATTACHARJEE",
    saID: "SPR0258",
  },
  {
    firstName: "JIBAN KRISHNA",
    lastName: "BHATTACHARJEE",
    address: '"ISTAYAN"*SUBHAS PALLY*PO-JHAL JHALIA*MALDA-732102',
    fullName: "JIBAN KRISHNA BHATTACHARJEE",
    saID: "SPR0259",
  },
  {
    firstName: "LATE KANAI LAL",
    lastName: "BHATTACHARJEE",
    address: "RAMKRISHNA ASHRAM ROAD*PO-PANIHATI*24PARGANAS",
    fullName: "LATE KANAI LAL BHATTACHARJEE",
    saID: "SPR0260",
  },
  {
    firstName: "KASHINATH",
    lastName: "BHATTACHARJEE",
    address:
      '"ANANDA NIKETAN"*58, PANPARA ROAD*PO-TALPUKUR*24 PARGANAS (NORTH)-700123*AKJPB5512B',
    fullName: "KASHINATH BHATTACHARJEE",
    saID: "SPR0261",
  },
  {
    firstName: "LATE KRISHNA PRASANNA",
    lastName: "BHATTACHARJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE KRISHNA PRASANNA BHATTACHARJEE",
    saID: "SPR0262",
  },
  {
    firstName: "LATE KUMAR KRISHNA",
    lastName: "BHATTACHARJEE",
    address: "*SATSANG",
    fullName: "LATE KUMAR KRISHNA BHATTACHARJEE",
    saID: "SPR0263",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "BHATTACHARJEE",
    address: "BORDUBI SATSANG KENDRA*PO-HOOGRIJAN*DIST-DIBRUGARH.",
    fullName: "LATE KUMUD RANJAN BHATTACHARJEE",
    saID: "SPR0264",
  },
  {
    firstName: "MAKHAN CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "DHRITIDHA HOMEO CLINIC*EX POLICE LINE*PO-TEZPUR-784001*SONITPUR",
    fullName: "MAKHAN CHANDRA BHATTACHARJEE",
    saID: "SPR0265",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "BHATTACHARJEE",
    address: "45,PATALDANGA STREET*CALCUTTA 700009",
    fullName: "LATE MANINDRA NATH BHATTACHARJEE",
    saID: "SPR0266",
  },
  {
    firstName: "LATE NANDADULAL",
    lastName: "BHATTACHARJEE",
    address: "PO&VILL-ANGUNA*BURDWAN",
    fullName: "LATE NANDADULAL BHATTACHARJEE",
    saID: "SPR0267",
  },
  {
    firstName: "NANDA GOPAL",
    lastName: "BHATTACHARJEE",
    address:
      "C/O APARUPA MUKHERJEE*VILL/PO KAIYAR*VIA SEHARABAZAR*DIST-BARDDHAMAN-713423",
    fullName: "NANDA GOPAL BHATTACHARJEE",
    saID: "SPR0268",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "BHATTACHARJEE",
    address: "AT&PO-POLBA*DIST-HOOGHLY-712148",
    fullName: "NANIGOPAL BHATTACHARJEE",
    saID: "SPR0269",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "MAHISILA COLONY PO MAHISILA*DIST BURDWAN",
    fullName: "LATE NARAYAN CHANDRA BHATTACHARJEE",
    saID: "SPR0270",
  },
  {
    firstName: "LATE NISHIKANTA",
    lastName: "BHATTACHARJEE",
    address: "SATSANG CENTRE*P.N.MUKHARJEE ROAD*AT/PO-KHARDAHA*DIST-24PGS",
    fullName: "LATE NISHIKANTA BHATTACHARJEE",
    saID: "SPR0271",
  },
  {
    firstName: "PIJUSH",
    lastName: "BHATTACHARJEE",
    address:
      '"SNEHALATA"*IN FRONT OF RLY QRS.22/A*B.B.C.COLONY, PANDU*GUWAHATI-781012*KAMRUP',
    fullName: "PIJUSH BHATTACHARJEE",
    saID: "SPR0272",
  },
  {
    firstName: "LATE PRANAB KUMAR",
    lastName: "BHATTACHARJEE",
    address: "WATER WORKS ROAD*AT&PO-PURI*PURI 752002.",
    fullName: "LATE PRANAB KUMAR BHATTACHARJEE",
    saID: "SPR0273",
  },
  {
    firstName: "LATE RABINDRA KUMAR",
    lastName: "BHATTACHARJEE",
    address: "C/O-M.L.MAJUMDAR*P-85/A OXY TOWN*CALCUTTA 700 061",
    fullName: "LATE RABINDRA KUMAR BHATTACHARJEE",
    saID: "SPR0274",
  },
  {
    firstName: "LATE RAGHUNATH",
    lastName: "BHATTACHARJEE",
    address: "SATSANG VIHAR RAMNAGAR*PO SILCHAR-4*CACHAR",
    fullName: "LATE RAGHUNATH BHATTACHARJEE",
    saID: "SPR0275",
  },
  {
    firstName: "RAM KRISHNA",
    lastName: "BHATTACHARJEE",
    address: "VILL/PO-KAJLASAR*DIST-SYLHET*BANGLADESH",
    fullName: "RAM KRISHNA BHATTACHARJEE",
    saID: "SPR0276",
  },
  {
    firstName: "LATE RAM PRASAD",
    lastName: "BHATTACHARJEE",
    address: "PO/VILL-PANSILA(PRANTIK)*DIST-24 PGS.",
    fullName: "LATE RAM PRASAD BHATTACHARJEE",
    saID: "SPR0277",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "MARANGA*PO-JUNGALPUR*DHANBAD",
    fullName: "LATE RAMESH CHANDRA BHATTACHARJEE",
    saID: "SPR0278",
  },
  {
    firstName: "LATE RASH BEHARI",
    lastName: "BHATTACHARJEE",
    address: "12.NAYALANKA THAKUR ROAD*PO-BHATPARA*DIST-24 PGS.",
    fullName: "LATE RASH BEHARI BHATTACHARJEE",
    saID: "SPR0279",
  },
  {
    firstName: "LATE SADHAN CHANDRA",
    lastName: "BHATTACHARJEE",
    address:
      "C/O C R GHOSAL*BEKAY ENGINEERING CORPN.*NANDINI ROAD*BHILAI*DURG (M.P.)",
    fullName: "LATE SADHAN CHANDRA BHATTACHARJEE",
    saID: "SPR0280",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "BHATTACHARJEE",
    address: "*SATSANG 814116.",
    fullName: "LATE SAILENDRA NATH BHATTACHARJEE",
    saID: "SPR0281",
  },
  {
    firstName: "SAMIRAN",
    lastName: "BHATTACHARJEE",
    address: "353/1 BANAMALI BANERJEE ROAD*CALCUTTA 700 082",
    fullName: "SAMIRAN BHATTACHARJEE",
    saID: "SPR0282",
  },
  {
    firstName: "LATE SHANTI SARAN",
    lastName: "BHATTACHARJEE",
    address: "VILL-SARANGAPUR*PO-GUSHKARA*BURDWAN",
    fullName: "LATE SHANTI SARAN BHATTACHARJEE",
    saID: "SPR0283",
  },
  {
    firstName: "LATE SHYAMA PRASANNA",
    lastName: "BHATTACHARJEE",
    address: "COLONY-1,QR F/17*PO DHARAMPURA*DIST BASTER",
    fullName: "LATE SHYAMA PRASANNA BHATTACHARJEE",
    saID: "SPR0284",
  },
  {
    firstName: "LATE SUBHASH CHANDRA",
    lastName: "BHATTACHARJEE",
    address:
      "C/O KUMUD RANJAN SAHA*HOJAI MUNICIPALTY MARKET*SWASTI BASTRALAY*PO+DIST NOWGONG.",
    fullName: "LATE SUBHASH CHANDRA BHATTACHARJEE",
    saID: "SPR0285",
  },
  {
    firstName: "LATE AKSHAY KUMAR",
    lastName: "BHATTACHARJI",
    address: "VILL/PO-FATIKROY*TRIPURA 799 290.",
    fullName: "LATE AKSHAY KUMAR BHATTACHARJI",
    saID: "SPR0286",
  },
  {
    firstName: "ANADI NATH",
    lastName: "BHATTACHARJI",
    address:
      '"SWASTI KUTIR"*B.S.F.PARA*SHAL BAGAN*AGARTALA*TRIPURA (W) 799 012.',
    fullName: "ANADI NATH BHATTACHARJI",
    saID: "SPR0287",
  },
  {
    firstName: "LATE ARUNANGSHU",
    lastName: "BHATTACHARJI",
    address: "RILBONG*SHILLONG-13*MEGHALAYA",
    fullName: "LATE ARUNANGSHU BHATTACHARJI",
    saID: "SPR0288",
  },
  {
    firstName: "DHIRESH CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "KUMARITILLA*PO-ABHAYNAGAR*TRIPURA WEST* 99005",
    fullName: "DHIRESH CHANDRA BHATTACHARJEE",
    saID: "SPR0289",
  },
  {
    firstName: "LATE KALI PADA",
    lastName: "BHATTACHARJI",
    address: "DIVISIONAL ACCOUNTANT*NORTHERN(P.W.D)*PO-DHARMANAGAR*TRIPURA.",
    fullName: "LATE KALI PADA BHATTACHARJI",
    saID: "SPR0290",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "BHATTACHARJI",
    address: "347/2 DUMDUM ROAD*CALCUTTA 74",
    fullName: "LATE KALIPADA BHATTACHARJI",
    saID: "SPR0291",
  },
  {
    firstName: "LATE LAXMI KANT",
    lastName: "BHATTACHARJEE",
    address: '"OIL INDIA LTD"*PO/DIST-BONGAIGAON',
    fullName: "LATE LAXMI KANT BHATTACHARJEE",
    saID: "SPR0292",
  },
  {
    firstName: "LAXMI NATH",
    lastName: "BHATTACHARJEE",
    address: "DORONGI CHUK*PO-JORHAT,WORD NO-14*JORHAT",
    fullName: "LAXMI NATH BHATTACHARJEE",
    saID: "SPR0293",
  },
  {
    firstName: "PARIMAL",
    lastName: "BHATTACHARJI",
    address:
      "SREE PALLY, BADHARGHAT, AGARTALA*PO-MADHUBAN(DUKLI)*WEST TRIPURA-799003",
    fullName: "PARIMAL BHATTACHARJI",
    saID: "SPR0294",
  },
  {
    firstName: "LATE RATHINDRA",
    lastName: "BHATTACHARJI",
    address: "AT/PO-SINGHCHERRA*VIA-KHOWAI*TRIPURA(W)",
    fullName: "LATE RATHINDRA BHATTACHARJI",
    saID: "SPR0295",
  },
  {
    firstName: "LATE RATISH CHANDRA",
    lastName: "BHATTACHARJI",
    address: '"RAJENDRA BHABAN"*LABAN*SHILLONG-794004*MEGHALAYA.',
    fullName: "LATE RATISH CHANDRA BHATTACHARJI",
    saID: "SPR0296",
  },
  {
    firstName: "LATE RUDRENDU",
    lastName: "BHATTACHARJEE",
    address: "O/O THE A.G (A&E),MANIPUR*PO-IMPHAL*MANIPUR",
    fullName: "LATE RUDRENDU BHATTACHARJEE",
    saID: "SPR0297",
  },
  {
    firstName: "SUPRIT",
    lastName: "BHATTACHARJI",
    address:
      "ROAD NO-2*PO-RAMNAGAR*AGARTALA*TRIPURA WEST-799002*PAN-ACHPB1058J",
    fullName: "SUPRIT BHATTACHARJI",
    saID: "SPR0298",
  },
  {
    firstName: "TAPAN",
    lastName: "BHATTACHARJI",
    address: "VILL-MOHANPUR BAZAR*PO-MAJLISHPUR*TRIPURA-799035*PAN-CGTPB8001P",
    fullName: "TAPAN BHATTACHARJI",
    saID: "SPR0299",
  },
  {
    firstName: "BENODE BEHARI",
    lastName: "BHATTACHERJEE",
    address: "PO-JAMALDAHA*COOCHBEHAR",
    fullName: "BENODE BEHARI BHATTACHERJEE",
    saID: "SPR0300",
  },
  {
    firstName: "DURGAPRASAD",
    lastName: "BHATTACHERJEE",
    address: "2 NO GOBINDA NAGAR*NEAR SATSANG KENDRA*PO-MADANPUR*NADIA 741245",
    fullName: "DURGAPRASAD BHATTACHERJEE",
    saID: "SPR0301",
  },
  {
    firstName: "LATE JATINDRA CHANDRA",
    lastName: "BHATTACHERJEE",
    address: "PO-SURI*DT-BIRBHUM.",
    fullName: "LATE JATINDRA CHANDRA BHATTACHERJEE",
    saID: "SPR0302",
  },
  {
    firstName: "LATE KRISHNANANDA",
    lastName: "BHATTACHERJEE",
    address: "HASANHATI*PO-BAIDYAPUR*BURDWAN",
    fullName: "LATE KRISHNANANDA BHATTACHERJEE",
    saID: "SPR0303",
  },
  {
    firstName: "LATE RADHESHYAM",
    lastName: "BHATTACHERJEE",
    address: "KALICHARAN DUTTA ROAD*UTTARGHOSHPARA*PO-CHAKDAH*NADIA 741222.",
    fullName: "LATE RADHESHYAM BHATTACHERJEE",
    saID: "SPR0304",
  },
  {
    firstName: "LATE RANENDRA KUMAR",
    lastName: "BHATTACHERJEE",
    address: "PO-DHUBRI*GOALPARA.",
    fullName: "LATE RANENDRA KUMAR BHATTACHERJEE",
    saID: "SPR0305",
  },
  {
    firstName: "LATE SARANAN",
    lastName: "BHATTACHERJEE",
    address: "SATSANG ASHRAM*PO-RAJNAGAR*DT-MIDNAPORE.",
    fullName: "LATE SARANAN BHATTACHERJEE",
    saID: "SPR0306",
  },
  {
    firstName: "SUKUMAR",
    lastName: "BHATTACHARJEE",
    address: "VIL&PO-MOLLABELIA*NADIA-741249*PAN-BJRPB0283P",
    fullName: "SUKUMAR BHATTACHARJEE",
    saID: "SPR0307",
  },
  {
    firstName: "LATE TRIDIB RANJAN",
    lastName: "BHATTACHERJEE",
    address: "POLAI T.E*PO-POLAI*DT-CACHAR",
    fullName: "LATE TRIDIB RANJAN BHATTACHERJEE",
    saID: "SPR0308",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "BHOWAL",
    address:
      "911, AHLCON APARTMENTS*VAISHALI, SECTOR-III*GHAZIABAD 201010*U.P.",
    fullName: "LATE NARAYAN CHANDRA BHOWAL",
    saID: "SPR0309",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "BHOL",
    address: "VIL&PO-BANKOI*VIA-RAJSUNAKHALA*KHURDA-752066*PAN-AONPB3317C",
    fullName: "KESHAB CHANDRA BHOL",
    saID: "SPR0310",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "BHOUMICK",
    address: "PO-DURGAPUR-4*DT-BURDWAN.",
    fullName: "SURENDRA NATH BHOUMICK",
    saID: "SPR0311",
  },
  {
    firstName: "LATE BIKASH RANJAN",
    lastName: "BHOWMIK",
    address:
      "DEEPSHIKHA TOWER(A3)*AT-MAKARBAG SAHI*PO-BUXI BAZAR*CUTTACK-753001",
    fullName: "LATE BIKASH RANJAN BHOWMIK",
    saID: "SPR0312",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "BHOWMIK",
    address: '"SHANTI KUNJ"*PO-BISHALGARH*TRIPURA-799002',
    fullName: "DILIP KUMAR BHOWMIK",
    saID: "SPR0313",
  },
  {
    firstName: "LATE NAGENDRA KUMAR",
    lastName: "BHOUMIK",
    address: "EX.D.O.L.I.C.SURYANAGAR*PO-ALIPURDUAR*DT-JALPAIGURI.",
    fullName: "LATE NAGENDRA KUMAR BHOUMIK",
    saID: "SPR0314",
  },
  {
    firstName: "PREMATOSH",
    lastName: "BHOUMIK",
    address: "VILL JAMDEWA*PO-KHOWAI COURT*TRIPURA.",
    fullName: "PREMATOSH BHOUMIK",
    saID: "SPR0315",
  },
  {
    firstName: "LATE BHUPENDRA CHANDRA",
    lastName: "BHOWMICK",
    address:
      "MATIGARA ROAD*PATI COLONY NO-4*PO.PRADHAN NAGAR,SILIGURI*DARJEELING",
    fullName: "LATE BHUPENDRA CHANDRA BHOWMICK",
    saID: "SPR0316",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "BHOWMICK",
    address: "PEYTON SAHI*CUTTACK-753001.",
    fullName: "LATE KALIPADA BHOWMICK",
    saID: "SPR0317",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "BHOWMICK",
    address: "H-86,SAGAR BHANGA COLONY*DURGAPUR-11*DIST BURDWAN.",
    fullName: "LATE KALIPADA BHOWMICK",
    saID: "SPR0318",
  },
  {
    firstName: "LATE ROHITASWA",
    lastName: "BHOWMICK",
    address: "VILL-HARISHPUR*PO-MOLLAHAT*HOWRAH-711314",
    fullName: "LATE ROHITASWA BHOWMICK",
    saID: "SPR0319",
  },
  {
    firstName: "AJAY KUMAR",
    lastName: "BHOWMICK",
    address:
      "UNITED BANK OF INDIA,SILIGURI BRANCH*HILL CART ROAD*SILIGURI 734001*DARJEELING",
    fullName: "AJAY KUMAR BHOWMICK",
    saID: "SPR0320",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "BHOWMIK",
    address: "72 SADAR BAXI LANE*PO.HOWRAH*DT.HOWRAH",
    fullName: "LATE ANIL KUMAR BHOWMIK",
    saID: "SPR0321",
  },
  {
    firstName: "LATE HIRENDRA CHANDRA",
    lastName: "BHOWMIK",
    address: "PEALI PHUKAN ROAD*PO-PANIGAON*NOWGAON 782001.",
    fullName: "LATE HIRENDRA CHANDRA BHOWMIK",
    saID: "SPR0322",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "BHOWMIK",
    address:
      "C/O KHANAKUL GROUP ELEC.SUPPLY*W.B.S.E.BOARD*PO-KHANAKUL*HOOGHLY-712406",
    fullName: "JAYANTA KUMAR BHOWMIK",
    saID: "SPR0323",
  },
  {
    firstName: "PANKAJ KUMAR",
    lastName: "BHOWMIK",
    address:
      "VILL-BENEDIGHI*PO-LUTUNIA*DIST-PASCHIM MEDINIPUR-721166*PAN-ADVPB4853A",
    fullName: "PANKAJ KUMAR BHOWMIK",
    saID: "SPR0324",
  },
  {
    firstName: "LATE KHIRODE CHANDRA",
    lastName: "BHUIYAN",
    address: '"SWASTI NIBAS"*MAHATAPPUR*PO/DIST-MIDNAPORE',
    fullName: "LATE KHIRODE CHANDRA BHUIYAN",
    saID: "SPR0325",
  },
  {
    firstName: "LATE KISHORI MOHAN",
    lastName: "BHUKTA",
    address: "VILL&PO-PALASHPAI*DT-MIDNAPORE.",
    fullName: "LATE KISHORI MOHAN BHUKTA",
    saID: "SPR0326",
  },
  {
    firstName: "LATE BHUSAN CHANDRA",
    lastName: "BHUNIYA",
    address: "AT+PO-NANDAKUMARPUR*DIST-24 PGS.",
    fullName: "LATE BHUSAN CHANDRA BHUNIYA",
    saID: "SPR0327",
  },
  {
    firstName: "CHATURBHUJ",
    lastName: "BHUNIYA",
    address:
      "AT+PO-SRIDHARNAGAR*VIA-PATHARPRATIMA*DIST-24 PRAGANAS (SOUTH)-743371*PAN-ACWPB1995R",
    fullName: "CHATURBHUJ BHUNIYA",
    saID: "SPR0328",
  },
  {
    firstName: "LATE BANKU BIHARI",
    lastName: "BHUYAN",
    address: "PATHIMARA T.E.*PO-UDARBAND*CACHAR 788030",
    fullName: "LATE BANKU BIHARI BHUYAN",
    saID: "SPR0329",
  },
  {
    firstName: "LATE GUNEENDRA",
    lastName: "BHUYAN",
    address: "TULASHICHOURA*WARD NO.14*PO.BARIPADA*MAYURBHANJ.",
    fullName: "LATE GUNEENDRA BHUYAN",
    saID: "SPR0330",
  },
  {
    firstName: "HEMKANTA",
    lastName: "BHUYAN",
    address:
      "GOPI PATH, M-14, RUPNAGAR*PO-GUWAHATI-7810132.*PAN-AXPPB8187M     .",
    fullName: "HEMKANTA BHUYAN",
    saID: "SPR0331",
  },
  {
    firstName: "LATE HERAMBA NARAYAN",
    lastName: "BHUYAN",
    address: "VILL/PO KAMARDAHA*DIST BALASORE",
    fullName: "LATE HERAMBA NARAYAN BHUYAN",
    saID: "SPR0332",
  },
  {
    firstName: "JIBAN CHANDRA",
    lastName: "BHUYAN",
    address: "M G ROAD*JAGIROAD*PO-JAGIROAD*MORIGAON*ASSAM-782 410",
    fullName: "JIBAN CHANDRA BHUYAN",
    saID: "SPR0333",
  },
  {
    firstName: "JIBAN CHANDRA",
    lastName: "BHUYAN",
    address: "SPUN SILK MILLS LTD*PO JOGI ROAD*NOWGONG.",
    fullName: "JIBAN CHANDRA BHUYAN",
    saID: "SPR0334",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "BHUIYAN",
    address: "JYOTI NILAYA*PO-ULUBARI*GAUHATI-7*KAMRUP",
    fullName: "LATE JOGENDRA NATH BHUIYAN",
    saID: "SPR0335",
  },
  {
    firstName: "PRASANNA KUMAR",
    lastName: "BHUYAN",
    address: '"SWASTIKAYAN"*ARUNODAYNAGAR*CUTTACK-753012*PAN NO.ACLPB1431B',
    fullName: "PRASANNA KUMAR BHUYAN",
    saID: "SPR0336",
  },
  {
    firstName: "LATE RABINARAYAN",
    lastName: "BHUYAN",
    address: "VILL-BANDHUPUR*PO-ORIKANTA*CUTTACK 754 293",
    fullName: "LATE RABINARAYAN BHUYAN",
    saID: "SPR0337",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "BHUYAN",
    address:
      "C.T.S.(RETD)*AT-KUANSOL*PO-BHAGABANPUR*VIA-LEMALO*DIST-CUTTACK-754293*(ACNPB8336K)",
    fullName: "SARAT CHANDRA BHUYAN",
    saID: "SPR0338",
  },
  {
    firstName: "BASUDEB",
    lastName: "BIHARI",
    address: "RAMESWAR NAGAR*AT-ANGARGADIA*PO/DIST-BALASORE-756001",
    fullName: "BASUDEB BIHARI",
    saID: "SPR0339",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "BINDHANI",
    address: "AT/PO.BADAKHALADI-757040*MAYURBHANJ",
    fullName: "PURNA CHANDRA BINDHANI",
    saID: "SPR0340",
  },
  {
    firstName: "ABHAYA KUMAR",
    lastName: "BISWAL",
    address:
      "C/O SATSANG KENDRA,UDALA*PO-UDALA 757041*MAYURBHANJ*PAN-AFNPB8987K",
    fullName: "ABHAYA KUMAR BISWAL",
    saID: "SPR0341",
  },
  {
    firstName: "ALEKH CHANDRA",
    lastName: "BISWAL",
    address: "AT.PODANA*PO.PIKARALI*VIA-MARSHAGHAI*KENDRAPARA-754213.",
    fullName: "ALEKH CHANDRA BISWAL",
    saID: "SPR0342",
  },
  {
    firstName: "LATE BICHITRA NANDA",
    lastName: "BISWAL",
    address: "QR.NO.E 12/1*AT/PO.BURLA*SAMBALPUR.",
    fullName: "LATE BICHITRA NANDA BISWAL",
    saID: "SPR0343",
  },
  {
    firstName: "LATE CHAKRADHAR",
    lastName: "BISWAL",
    address: "VILL-DHANISHO*PO.BAIGANI*VIA.BALIKUDA*CUTTACK.",
    fullName: "LATE CHAKRADHAR BISWAL",
    saID: "SPR0344",
  },
  {
    firstName: "JUDHISTHIR",
    lastName: "BISWAL",
    address: "AT-BALISAHI*PO.MADHUPATNA-753010*CUTTACK",
    fullName: "JUDHISTHIR BISWAL",
    saID: "SPR0345",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "BISWAL",
    address: "LB-205, STAGE-II*LAXMISAGAR*BHUBANESWAR-751006*KHURDA",
    fullName: "RABINDRA NATH BISWAL",
    saID: "SPR0346",
  },
  {
    firstName: "SAHADEV",
    lastName: "BISWAL",
    address:
      "PLOT N-6/291,I R C VILLAGE*AT/PO-NUAPALLI*BHUBANESWAR-751015*KHURDA (ORISSA)",
    fullName: "SAHADEV BISWAL",
    saID: "SPR0347",
  },
  {
    firstName: "LATE ADYA PRASAD",
    lastName: "BISWAS",
    address: "PURBA JAGADANANDAPUR*PO-BETHUADAHARI*NADIA",
    fullName: "LATE ADYA PRASAD BISWAS",
    saID: "SPR0348",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "BISWAS",
    address: "VILL-CHACHAKHATA*PO-ALIPURDUAR JN.*JALPAIGURI-736123.",
    fullName: "AJIT KUMAR BISWAS",
    saID: "SPR0349",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "BISWAS",
    address: "VILL/PO-DEBOGRAM*(DARAKATA)*NADIA",
    fullName: "AJIT KUMAR BISWAS",
    saID: "SPR0350",
  },
  {
    firstName: "LATE AMIYA SANTI",
    lastName: "BISWAS",
    address:
      "KAHILIPARA COLONY HILL SIDE*PO-BINOVA NAGAR*GAUHATI-18*KAMRUP*ASSAM",
    fullName: "LATE AMIYA SANTI BISWAS",
    saID: "SPR0351",
  },
  {
    firstName: "LATE AMULLYA RANJAN",
    lastName: "BISWAS",
    address: "NALIAPOOL SATSANG VIHAR*DIBRUGARH*ASSAM",
    fullName: "LATE AMULLYA RANJAN BISWAS",
    saID: "SPR0352",
  },
  {
    firstName: "LATE AMULYA KUMAR",
    lastName: "BISWAS",
    address: "A-7/36*KALYANI*NADIA",
    fullName: "LATE AMULYA KUMAR BISWAS",
    saID: "SPR0353",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "BISWAS",
    address: "VILL-PATKATA COLONY*PO-DENGUAJHAR*JALPAIGURI",
    fullName: "ANIL KUMAR BISWAS",
    saID: "SPR0354",
  },
  {
    firstName: "ANUKUL CHANDRA",
    lastName: "BISWAS",
    address: "VILL-BAHIRAKRA*PO-BARAARIYA*DIST-KHULNA",
    fullName: "ANUKUL CHANDRA BISWAS",
    saID: "SPR0355",
  },
  {
    firstName: "LATE ASIMANANDA",
    lastName: "BISWAS",
    address: "VIL&PO.SIBPUR*NADIA",
    fullName: "LATE ASIMANANDA BISWAS",
    saID: "SPR0356",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "BISWAS",
    address:
      "FLAT-303,A WING(SAI TIRTH)*SIDDHARTH NAGAR KOPRI*THANE EAST*MUMBAI*PAN-AADPB5503G",
    fullName: "ASIT BARAN BISWAS",
    saID: "SPR0357",
  },
  {
    firstName: "LATE ATINDRA NATH",
    lastName: "BISWAS",
    address:
      "TEGHORIA MANDAL PARA*NEAR TARUNSANGHA CLUB*PO-HATIARA*24PARGANAS NORTH",
    fullName: "LATE ATINDRA NATH BISWAS",
    saID: "SPR0358",
  },
  {
    firstName: "BASUDEB",
    lastName: "BISWAS",
    address: "VILL-AMDOH*PO-BATKIDOH*DIST-BETUL*M.P.-460440",
    fullName: "BASUDEB BISWAS",
    saID: "SPR0359",
  },
  {
    firstName: "LATE BASUDEB",
    lastName: "BISWAS",
    address: "GOSABA SATSANG MONDIR*PO-GOSABA*DIST-24 PGS",
    fullName: "LATE BASUDEB BISWAS",
    saID: "SPR0360",
  },
  {
    firstName: "LATE BHOLANATH",
    lastName: "BISWAS",
    address: "VIL/PO-SANYALCHAR*NUTANPARA*NADIA",
    fullName: "LATE BHOLANATH BISWAS",
    saID: "SPR0361",
  },
  {
    firstName: "LATE BIMAL KUMAR",
    lastName: "BISWAS",
    address: "PASCHIM SANTINAGAR*PO-ANANDA NAGAR*VIA-BALLY*DIST-HOWRAH.",
    fullName: "LATE BIMAL KUMAR BISWAS",
    saID: "SPR0362",
  },
  {
    firstName: "LATE BINOY KUMAR",
    lastName: "BISWAS",
    address: "PO-SILCHAR*DIST-CACHAR*ASSAM",
    fullName: "LATE BINOY KUMAR BISWAS",
    saID: "SPR0363",
  },
  {
    firstName: "LATE BINAY KUMAR",
    lastName: "BISWAS",
    address: "SARASHI LIBRARY*HOJAI TOWN*NOWGONG",
    fullName: "LATE BINAY KUMAR BISWAS",
    saID: "SPR0364",
  },
  {
    firstName: "LATE BINOY KUMAR",
    lastName: "BISWAS",
    address: "201,N.N.ROAD*CALCUTTA-700028",
    fullName: "LATE BINOY KUMAR BISWAS",
    saID: "SPR0365",
  },
  {
    firstName: "BISWANATH",
    lastName: "BISWAS",
    address: "VILL+PO-SRINARAYANPUR*VIA-DHOLAHAT*DIST-24-PGS (SOUTH)-743399",
    fullName: "BISWANATH BISWAS",
    saID: "SPR0366",
  },
  {
    firstName: "LATE BRAJENDRA CHANDRA",
    lastName: "BISWAS",
    address: "BISWAS  & CO*PO-DIMAPUR*DT-KOHIMA*NAGALAND",
    fullName: "LATE BRAJENDRA CHANDRA BISWAS",
    saID: "SPR0367",
  },
  {
    firstName: "LATE CHAITANYA KUMAR",
    lastName: "BISWAS",
    address: "DEARAPARA GHAR*PO.NABADWIP*NADIA",
    fullName: "LATE CHAITANYA KUMAR BISWAS",
    saID: "SPR0368",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "BISWAS",
    address: "VILL-SERPUR*PO.NAYASARAI*DT.HOOGHLY-712513",
    fullName: "DEBENDRA NATH BISWAS",
    saID: "SPR0369",
  },
  {
    firstName: "LATE DHANANJOY",
    lastName: "BISWAS",
    address:
      "C/O-NANIGOPAL BISWAS*44 K.M.POST*SILPA SAMITIPARA*PO&DT-JALPAIGURI-735101",
    fullName: "LATE DHANANJOY BISWAS",
    saID: "SPR0370",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "BISWAS",
    address: "SATSANG*PURANDAHA*DEOGHAR",
    fullName: "LATE DHIRENDRA KUMAR BISWAS",
    saID: "SPR0371",
  },
  {
    firstName: "DINESH CHANDRA",
    lastName: "BISWAS",
    address: "AT-SHIBPUR*PO-ABHAYAPURI*DIST-BONGAIGAON  783384*ASSAM",
    fullName: "DINESH CHANDRA BISWAS",
    saID: "SPR0372",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "BISWAS",
    address: "PANCHMATHA*PO-NABADWIP*NADIA.",
    fullName: "GANESH CHANDRA BISWAS",
    saID: "SPR0373",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "BISWAS",
    address: "SATSANG 814116",
    fullName: "LATE GOPAL CHANDRA BISWAS",
    saID: "SPR0374",
  },
  {
    firstName: "GOSTHA BEHARI",
    lastName: "BISWAS",
    address: "VILL-SUNDARNAGAR*PO-KSHUDIRAM PALLI*DIST-GADCHIROLI-442707*M.S.",
    fullName: "GOSTHA BEHARI BISWAS",
    saID: "SPR0375",
  },
  {
    firstName: "GOURI SHANKAR",
    lastName: "BISWAS",
    address: "MAJHERPARA (CHAPATALA)*PO-KRISHNA NAGAR*NADIA*PAN-AVAPB1080G",
    fullName: "GOURI SHANKAR BISWAS",
    saID: "SPR0376",
  },
  {
    firstName: "LATE HARA CHANDRA",
    lastName: "BISWAS",
    address: "VILL-KAWLI NO 1*PO-KAWLI*DIST-BAKSHA  781360",
    fullName: "LATE HARA CHANDRA BISWAS",
    saID: "SPR0377",
  },
  {
    firstName: "HARIDAS",
    lastName: "BISWAS",
    address: "AT-CHANDPARA BAZAR*PO-CHANDPARA*DIST-24 PGS (NORTH)-743245",
    fullName: "HARIDAS BISWAS",
    saID: "SPR0378",
  },
  {
    firstName: "LATE HAZARI LAL",
    lastName: "BISWAS",
    address:
      "TYRE CORPORATION OF INDIA LTD*SECTOR 16A/41 FARIDABAD*HARIYANA 121002",
    fullName: "LATE HAZARI LAL BISWAS",
    saID: "SPR0379",
  },
  {
    firstName: "HAZRAKALI",
    lastName: "BISWAS",
    address: "VILL/PO-AMUDIA*DIST-24PARGANAS (NORTH)-743273*W B",
    fullName: "HAZRAKALI BISWAS",
    saID: "SPR0380",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "BISWAS",
    address: "VIL-MAHAKALGURI*PO-D.MAHAKALGURI*VIA-SAMUKTALA*JALPAIGURI .",
    fullName: "LATE HEM CHANDRA BISWAS",
    saID: "SPR0381",
  },
  {
    firstName: "JAGADISH CHANDRA",
    lastName: "BISWAS",
    address: "PO/VILL.RAMKRISHNA NAGAR*DIST-KARIMGANJ-788166*PAN-BCRPB8634D",
    fullName: "JAGADISH CHANDRA BISWAS",
    saID: "SPR0382",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "BISWAS",
    address: "AT-ASTANA ROAD*PO-BASIRHAT*DIST-24-PGS.",
    fullName: "LATE JATINDRA NATH BISWAS",
    saID: "SPR0383",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "BISWAS",
    address: "SATSANG KENDRA*PO-HAIDARPARA*(SILIGURI)*JALPAIGURI",
    fullName: "LATE JITENDRA NATH BISWAS",
    saID: "SPR0384",
  },
  {
    firstName: "LATE JOGENDRA MOHAN",
    lastName: "BISWAS",
    address: "19 EKBAL LANE*CALCUTTA 23",
    fullName: "LATE JOGENDRA MOHAN BISWAS",
    saID: "SPR0385",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "BISWAS",
    address: "Z/S 39,ZONAL MARKET*DURGAPUR-6*DIST BARDDHAMAN-713206",
    fullName: "LATE JOGESH CHANDRA BISWAS",
    saID: "SPR0386",
  },
  {
    firstName: "LATE JYOTISH CHANDRA",
    lastName: "BISWAS",
    address: "P-51,SARKELHAT*DAKSHIN BEHALA ROAD*CALCUTTA-61",
    fullName: "LATE JYOTISH CHANDRA BISWAS",
    saID: "SPR0387",
  },
  {
    firstName: "LATE KALISANKAR",
    lastName: "BISWAS",
    address: "KRISHNA PALLY*PO&DT-MALDA.",
    fullName: "LATE KALISANKAR BISWAS",
    saID: "SPR0388",
  },
  {
    firstName: "LATE KANTI BHUSAN",
    lastName: "BISWAS",
    address: "PO-RANAGHAT*DIST-NADIA.",
    fullName: "LATE KANTI BHUSAN BISWAS",
    saID: "SPR0389",
  },
  {
    firstName: "LATE KARTICK CHANDRA",
    lastName: "BISWAS",
    address: "C/O SUBHENDU BISWAS*VILL-MANUJULI*PO-DHEKIAJULI*SONITPUR 784110",
    fullName: "LATE KARTICK CHANDRA BISWAS",
    saID: "SPR0390",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "BISWAS",
    address: "P.V.22*PO.MIXED FARM*DIST-BASTER(M.P )",
    fullName: "LATE KIRAN CHANDRA BISWAS",
    saID: "SPR0391",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "BISWAS",
    address: "VILL-NIMTALA*PO-MASLANDPUR*24 PARGANAS",
    fullName: "LATE KIRAN CHANDRA BISWAS",
    saID: "SPR0392",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "BISWAS",
    address: "P.V. NO-23*PO-PAKHANJORE*DIST-BASTER (M.P)",
    fullName: "LATE KIRAN CHANDRA BISWAS",
    saID: "SPR0393",
  },
  {
    firstName: "LATE KUMUDBANDHU",
    lastName: "BISWAS",
    address: "VILL-KALAGACH*PO-CHOPRA*UTTER DINAJPUR",
    fullName: "LATE KUMUDBANDHU BISWAS",
    saID: "SPR0394",
  },
  {
    firstName: "LATE LAXMI KANT",
    lastName: "BISWAS",
    address: "RAJGARH NEW CYCLE MART*PO-RAJGARH*DIBRUGARH",
    fullName: "LATE LAXMI KANT BISWAS",
    saID: "SPR0395",
  },
  {
    firstName: "LATE MAKHAN LAL",
    lastName: "BISWAS",
    address: "NO-2 BARJHAR*PO BARJHAR*DARRANG.",
    fullName: "LATE MAKHAN LAL BISWAS",
    saID: "SPR0396",
  },
  {
    firstName: "LATE MANINDRA CHANDRA",
    lastName: "BISWAS",
    address: "SANYALCHAR NUTANPARA*SANYALCHAR*NADIA",
    fullName: "LATE MANINDRA CHANDRA BISWAS",
    saID: "SPR0397",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "BISWAS",
    address: "VILL-FOKRADI*PO-BASUDEVPUR-JEMARI*BURDWAN-713335",
    fullName: "LATE MANORANJAN BISWAS",
    saID: "SPR0398",
  },
  {
    firstName: "MANORANJAN",
    lastName: "BISWAS",
    address: "AT+PO-BANIPUR*DIST-24 PGS (NORTH)-743233",
    fullName: "MANORANJAN BISWAS",
    saID: "SPR0399",
  },
  {
    firstName: "MATHURA NATH",
    lastName: "BISWAS",
    address: "AT/PO-ARAGAHI*DIST-SURGUJA*M.P.",
    fullName: "MATHURA NATH BISWAS",
    saID: "SPR0400",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "BISWAS",
    address:
      "356 NILACHAL*BIRATI (OPP.TO NABADARSHA GATE)*KOLKATA-51*PAN-AEIPB4964N",
    fullName: "MRINAL KANTI BISWAS",
    saID: "SPR0401",
  },
  {
    firstName: "MUKUL RANJAN",
    lastName: "BISWAS",
    address: "AT-RANGPARA(LICHUTALA)*PO-KALNA*BURDWAN.",
    fullName: "MUKUL RANJAN BISWAS",
    saID: "SPR0402",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "BISWAS",
    address: "AMIT STORES*CHANDIDAS MARKET;B ZONE*DURGAPUR*BURDWAN",
    fullName: "LATE NAGENDRA NATH BISWAS",
    saID: "SPR0403",
  },
  {
    firstName: "LATE NANDADULAL",
    lastName: "BISWAS",
    address: "GAYESHPUR P.C.R.I.C.W*PO.GAYESHPUR*NADIA 741234",
    fullName: "LATE NANDADULAL BISWAS",
    saID: "SPR0404",
  },
  {
    firstName: "LATE NANI GOPAL",
    lastName: "BISWAS",
    address: "SATSANG VIHAR*PO SILCHAR-4*CACHAR",
    fullName: "LATE NANI GOPAL BISWAS",
    saID: "SPR0405",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "BISWAS",
    address: "AT-THANAPARA*PO-BONGAON*DIST-24 PARGANAS",
    fullName: "LATE NANIGOPAL BISWAS",
    saID: "SPR0406",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "BISWAS",
    address: "VILL-TAGORE NAGAR 4*PO-SAKTIFARM*UDHAMSINGH NAGAR*U.P.",
    fullName: "LATE NARENDRA NATH BISWAS",
    saID: "SPR0407",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "BISWAS",
    address: "FB-4/1, D P L TOWNSHIP*DURGAPUR-713202.*BURDWAN*PAN ADCPB5391A",
    fullName: "NEPAL CHANDRA BISWAS",
    saID: "SPR0408",
  },
  {
    firstName: "NISHI KANTA",
    lastName: "BISWAS",
    address: "VIVEKANANDAPUR*PO-GAJOLE*DIST-MALDA-732124",
    fullName: "NISHI KANTA BISWAS",
    saID: "SPR0409",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "BISWAS",
    address: "VILL-GHURNI*PO-KRISHNAGAR*DT-NADIA.",
    fullName: "LATE PANCHANAN BISWAS",
    saID: "SPR0410",
  },
  {
    firstName: "LATE PIJUSH KANTI",
    lastName: "BISWAS",
    address: "C/O S.R.OFFICE*PO-CHANDPARA BAZAR*DIST-24 PGS.",
    fullName: "LATE PIJUSH KANTI BISWAS",
    saID: "SPR0411",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "BISWAS",
    address: "AT-HARISHPUR*PO.RANAGHAT*NADIA.",
    fullName: "LATE PRAFULLA KUMAR BISWAS",
    saID: "SPR0412",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "BISWAS",
    address: "PO DHARAMTUL*NOWGONG.",
    fullName: "LATE PRAFULLA KUMAR BISWAS",
    saID: "SPR0413",
  },
  {
    firstName: "LATE PROMODE RANJAN",
    lastName: "BISWAS",
    address: "C/O KULADA RN BISWAS*GRASSMORE T.E.*PO-NAGRAKATA*JALPAIGURI",
    fullName: "LATE PROMODE RANJAN BISWAS",
    saID: "SPR0414",
  },
  {
    firstName: "LATE PURNA CHANDRA",
    lastName: "BISWAS",
    address: "H.S.SCHOOL*PO-MADHYAMGRAM*DIST-24 PGS.",
    fullName: "LATE PURNA CHANDRA BISWAS",
    saID: "SPR0415",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "BISWAS",
    address: "VILL-PRIYANAGAR*PO-SODEPUR*DIST-24 PGS.",
    fullName: "LATE RABINDRA NATH BISWAS",
    saID: "SPR0416",
  },
  {
    firstName: "LATE RADHA BINODE",
    lastName: "BISWAS",
    address: "BASIRHAT COLLEGE PARA*PO-BASIRHAT*24 PARAGANAS",
    fullName: "LATE RADHA BINODE BISWAS",
    saID: "SPR0417",
  },
  {
    firstName: "LATE RADHARAMAN",
    lastName: "BISWAS",
    address: "SATSANG COLONY*PO-KARIDHYA*DIST BIRBHUM",
    fullName: "LATE RADHARAMAN BISWAS",
    saID: "SPR0418",
  },
  {
    firstName: "LATE RADHIKA MOHAN",
    lastName: "BISWAS",
    address: "SHITALATALA LANE*ASWINI DUTTANAGAR*PO-HINDMOTOR *DIST.HOOGHLY",
    fullName: "LATE RADHIKA MOHAN BISWAS",
    saID: "SPR0419",
  },
  {
    firstName: "LATE RAKESH RANJAN",
    lastName: "BISWAS",
    address:
      "C/O SRI APURBA BHATTACHARJEE*SUNIL SARKAR LANE*PO-G.C.COLLEGE*CACHAR 788004",
    fullName: "LATE RAKESH RANJAN BISWAS",
    saID: "SPR0420",
  },
  {
    firstName: "LATE RAM KARAN",
    lastName: "BISWAS",
    address: "AT/PO-KADWA*VIA-SONALI*KATIHAR",
    fullName: "LATE RAM KARAN BISWAS",
    saID: "SPR0421",
  },
  {
    firstName: "RANGALAL",
    lastName: "BISWAS",
    address: "AT-DEHA*PO-GHOSH*DIST-BURDWAN-713146",
    fullName: "RANGALAL BISWAS",
    saID: "SPR0422",
  },
  {
    firstName: "RANJIT",
    lastName: "BISWAS",
    address:
      "SRI PALLY, ROAD NO-5*PO-SILIGURI BAZAR*SILIGURI-5*DARJEELING*PAN-ADBPB2701K",
    fullName: "RANJIT BISWAS",
    saID: "SPR0423",
  },
  {
    firstName: "LATE RASAMAY",
    lastName: "BISWAS",
    address: "VILL/PO-BHABANIPUR*DIST-24 PARAGANAS",
    fullName: "LATE RASAMAY BISWAS",
    saID: "SPR0424",
  },
  {
    firstName: "LATE REBATI MOHAN",
    lastName: "BISWAS",
    address: "*SATSANG 814116",
    fullName: "LATE REBATI MOHAN BISWAS",
    saID: "SPR0425",
  },
  {
    firstName: "LATE ROHINI MOHAN",
    lastName: "BISWAS",
    address: "PO-JOYPUR RAJA BAZAR*CACHAR",
    fullName: "LATE ROHINI MOHAN BISWAS",
    saID: "SPR0426",
  },
  {
    firstName: "LATE SACHINDRA NATH",
    lastName: "BISWAS",
    address: "CHAKARJU*PO-MADAN MOHANPUR*DT-BANKURA.",
    fullName: "LATE SACHINDRA NATH BISWAS",
    saID: "SPR0427",
  },
  {
    firstName: "SANTI KUMAR",
    lastName: "BISWAS",
    address: "PIONEER COLONY(AMTOLA)*PO-BARASAT*DIST-24 PGS (NORTH)",
    fullName: "SANTI KUMAR BISWAS",
    saID: "SPR0428",
  },
  {
    firstName: "LATE SATYA RANJAN",
    lastName: "BISWAS",
    address: "SAKTIFARM BAZAR*PO-SAKTIFARM*DIST-NAINITAL (U.P.)",
    fullName: "LATE SATYA RANJAN BISWAS",
    saID: "SPR0429",
  },
  {
    firstName: "LATE SIBENDRA NATH",
    lastName: "BISWAS",
    address: "AT-RANINAGAR*PO.GOPINATHPUR*NADIA",
    fullName: "LATE SIBENDRA NATH BISWAS",
    saID: "SPR0430",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "BISWAS",
    address: "GOSABA SATSANG VIHAR*PO-GOSABA*DIST-24 PGS.",
    fullName: "LATE SUDHIR CHANDRA BISWAS",
    saID: "SPR0431",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "BISWAS",
    address: "98,SUBHAS NAGAR*PO BARRACKPUR*DIST 24 PARAGANAS",
    fullName: "LATE SUDHIR KUMAR BISWAS",
    saID: "SPR0432",
  },
  {
    firstName: "LATE SUDHIR RANJAN",
    lastName: "BISWAS",
    address: "HOSPITAL ROAD*PO-TEZPUR*SONITPUR-784001.",
    fullName: "LATE SUDHIR RANJAN BISWAS",
    saID: "SPR0433",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "BISWAS",
    address: "PO-BADKULLA*DT-NADIA.",
    fullName: "LATE SUNIL KUMAR BISWAS",
    saID: "SPR0434",
  },
  {
    firstName: "LATE SURANJAN",
    lastName: "BISWAS",
    address: "SWASTIPALLY*BHAITA*BURDWAN.",
    fullName: "LATE SURANJAN BISWAS",
    saID: "SPR0435",
  },
  {
    firstName: "LATE SURENDRA MOHAN",
    lastName: "BISWAS",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SURENDRA MOHAN BISWAS",
    saID: "SPR0436",
  },
  {
    firstName: "LATE SURENDRA MOHAN",
    lastName: "BISWAS",
    address: "SATSANG VIHAR DEWANGANJ*PO-DEWANGANJ*COOCHBEHAR.",
    fullName: "LATE SURENDRA MOHAN BISWAS",
    saID: "SPR0437",
  },
  {
    firstName: "SUSHANTA KUMAR",
    lastName: "BISWAS",
    address: "PO KHAIRABARI*DARRANG.",
    fullName: "SUSHANTA KUMAR BISWAS",
    saID: "SPR0438",
  },
  {
    firstName: "LATE SWADESH BARAN",
    lastName: "BISWAS",
    address: "SATSANG ROAD*PO-SILCHAR 788007*CACHAR",
    fullName: "LATE SWADESH BARAN BISWAS",
    saID: "SPR0439",
  },
  {
    firstName: "LATE SWADESH RANJAN",
    lastName: "BISWAS",
    address: "SATSANG CENTRE,STATION MORE*AT/PO-FALAKATA*DIST-JALPAIGURI",
    fullName: "LATE SWADESH RANJAN BISWAS",
    saID: "SPR0440",
  },
  {
    firstName: "LATE TARAPADA",
    lastName: "BISWAS",
    address: "C/O-M.M.PANDIT*ST NO-27,QRS NO-1/1A*PO-CHITTARANJAN*BURDWAN.",
    fullName: "LATE TARAPADA BISWAS",
    saID: "SPR0441",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "BORA",
    address: "VILL-DEURICHILABANDHA*PO-BHOMORAGURI*DIST-NAGAON",
    fullName: "PRAFULLA CHANDRA BORA",
    saID: "SPR0442",
  },
  {
    firstName: "HARI CHARAN",
    lastName: "KAMILYA",
    address: "",
    fullName: "HARI CHARAN KAMILYA",
    saID: "SPR0443",
  },
  {
    firstName: "RAJENDRA NATH",
    lastName: "BORA",
    address: "PO+V DAGAON*NOWGONG.",
    fullName: "RAJENDRA NATH BORA",
    saID: "SPR0444",
  },
  {
    firstName: "BHUMI KANT",
    lastName: "BORAH",
    address: "QR NO.-14/IV/D*H.F.C.L NAMRUP*PO-PARBATPUR-786623*DIBRUGARH",
    fullName: "BHUMI KANT BORAH",
    saID: "SPR0445",
  },
  {
    firstName: "LATE PRASANTA",
    lastName: "BORAH",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE PRASANTA BORAH",
    saID: "SPR0446",
  },
  {
    firstName: "LATE DATARAM",
    lastName: "SARMABARAL",
    address: "C/O BIMAL STORES*PO-JOGBANI*PURNIA",
    fullName: "LATE DATARAM SARMABARAL",
    saID: "SPR0447",
  },
  {
    firstName: "LATE BALIRAM",
    lastName: "BORO",
    address: "PO MADARTOLI*NOWGONG.",
    fullName: "LATE BALIRAM BORO",
    saID: "SPR0448",
  },
  {
    firstName: "BARKHARAM",
    lastName: "BORO",
    address: "VILL-GARAMSINGH*PO-PASCHIM JHARGAON*DIST-KAMRUP.",
    fullName: "BARKHARAM BORO",
    saID: "SPR0449",
  },
  {
    firstName: "LATE BASIRAM",
    lastName: "BORO",
    address: "AT/PO-BAMUNIGAON*KAMRUP",
    fullName: "LATE BASIRAM BORO",
    saID: "SPR0450",
  },
  {
    firstName: "CHANDIRAM",
    lastName: "BORO",
    address: "PO JAMTALA,VIA RANGIA*DIST KAMRUP",
    fullName: "CHANDIRAM BORO",
    saID: "SPR0451",
  },
  {
    firstName: "DEOBAR",
    lastName: "BORO",
    address: "V-GARIAPATHAR.PO-BARSALA*DIST-DARRANG.",
    fullName: "DEOBAR BORO",
    saID: "SPR0452",
  },
  {
    firstName: "LATE DHAJENDRA NATH",
    lastName: "BORO",
    address: "VILL-MADARBARI*PO-NAZDIA*DIST-KAMRUP.",
    fullName: "LATE DHAJENDRA NATH BORO",
    saID: "SPR0453",
  },
  {
    firstName: "LATE DHANESWAR",
    lastName: "BORO",
    address: "VILL-BALAHATI*PO-GURMOW*DIST-KAMRUP",
    fullName: "LATE DHANESWAR BORO",
    saID: "SPR0454",
  },
  {
    firstName: "DHANESWAR",
    lastName: "BORO",
    address: "AT-TAMULBARI*PO-THAKURIAPARA*DARRANG-784522*ASSAM",
    fullName: "DHANESWAR BORO",
    saID: "SPR0455",
  },
  {
    firstName: "JIBAN CHANDRA",
    lastName: "BORO",
    address: "PO-PANIGAON*DIBRUGARH",
    fullName: "JIBAN CHANDRA BORO",
    saID: "SPR0456",
  },
  {
    firstName: "LATE KULARAM",
    lastName: "BORO",
    address: "AT&PO-JARKONA*VIA-JAMURPUR*DT-KAMRUP.",
    fullName: "LATE KULARAM BORO",
    saID: "SPR0457",
  },
  {
    firstName: "LATE PUNYESWAR",
    lastName: "BORAH",
    address: "AT&PO-UKHAMATI*VIA-PATHALIPAM*DIST-LAKHIMPUR.",
    fullName: "LATE PUNYESWAR BORAH",
    saID: "SPR0458",
  },
  {
    firstName: "LATE RATNESHWAR",
    lastName: "BORO",
    address: "AT-DIGHALI*PO-DHUPDHARA*GOALPARA",
    fullName: "LATE RATNESHWAR BORO",
    saID: "SPR0459",
  },
  {
    firstName: "SAGAR CHANDRA",
    lastName: "BORAH",
    address: "VILL/PO-PANIGAON*LAKHIMPUR NORTH",
    fullName: "SAGAR CHANDRA BORAH",
    saID: "SPR0460",
  },
  {
    firstName: "SURENDRA",
    lastName: "BORO",
    address:
      "RANGAGORAGAON*PO-BAGHPANI*VIA-DOKMOKA S/O*DIST-KARBI ANGLONG-782441*PAN-BJVPB1772K",
    fullName: "SURENDRA BORO",
    saID: "SPR0461",
  },
  {
    firstName: "YASHVANT SHIVARAM",
    lastName: "BORSE",
    address:
      "'AAI'BUILDING,IST FLOOR*SANE GURUJI PATH*SHIVAJI NAGAR,NAUPADA*THANE 400602*M.S.",
    fullName: "YASHVANT SHIVARAM BORSE",
    saID: "SPR0462",
  },
  {
    firstName: "LATE ARUN KUMAR",
    lastName: "BOSE",
    address:
      "FLAT 4D;BLOCK B*SURYA KIRAN APTMT*136 NORTH STATION ROAD*AGARPARA*KOLKATA 109",
    fullName: "LATE ARUN KUMAR BOSE",
    saID: "SPR0463",
  },
  {
    firstName: "ASIS KUMAR",
    lastName: "BASU",
    address: "1860,UNITY LOOP,CUMMING*GA 30040*USA",
    fullName: "ASIS KUMAR BASU",
    saID: "SPR0464",
  },
  {
    firstName: "LATE CHITTA RANJAN",
    lastName: "BOSE",
    address: "174/28A  N/S.C.BOSE ROAD*CALCUTTA-40.",
    fullName: "LATE CHITTA RANJAN BOSE",
    saID: "SPR0465",
  },
  {
    firstName: "DWIJENDRA MOHAN",
    lastName: "BOSE",
    address: "SATSANG CENTRE,TEMPLEGHAT*PO-PANDU*GAUHATI 781 012*KAMRUP*ASSAM",
    fullName: "DWIJENDRA MOHAN BOSE",
    saID: "SPR0466",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "BOSE",
    address: "SATSANG COLONY*BARABAZAR*SAMBALPUR-768003",
    fullName: "LATE GANESH CHANDRA BOSE",
    saID: "SPR0467",
  },
  {
    firstName: "LATE INDU BHUSAN",
    lastName: "BOSE",
    address: "ACHARYA PARA*PO-HALISAHAR*DIST-24-PGS.",
    fullName: "LATE INDU BHUSAN BOSE",
    saID: "SPR0468",
  },
  {
    firstName: "LATE JATINDRA MOHAN",
    lastName: "BOSE",
    address: "BHUBANESWAR SATSANG VIHAR*VANI VIHAR*BHUBANESWAR 751004.",
    fullName: "LATE JATINDRA MOHAN BOSE",
    saID: "SPR0469",
  },
  {
    firstName: "LATE MANINDRA KUMAR",
    lastName: "BOSE",
    address: "CE-28,RABINDRA NAGAR*CALCUTTA-700018",
    fullName: "LATE MANINDRA KUMAR BOSE",
    saID: "SPR0470",
  },
  {
    firstName: "LATE MANINDRA",
    lastName: "BOSE",
    address: "PO-JINJIA*DIST-SONITPUR",
    fullName: "LATE MANINDRA BOSE",
    saID: "SPR0471",
  },
  {
    firstName: "LATE NILGOPAL",
    lastName: "BOSE",
    address: "C/O M/S HINDUSTAN ENGG.CORP.*15 DUMDUM RD. *CALCUTTA-30",
    fullName: "LATE NILGOPAL BOSE",
    saID: "SPR0472",
  },
  {
    firstName: "LATE NRIPENDRA NARAYAN",
    lastName: "BOSE",
    address: "BARABAZAR HS SCHOOL*PO.BARABHUM*PURULIA",
    fullName: "LATE NRIPENDRA NARAYAN BOSE",
    saID: "SPR0473",
  },
  {
    firstName: "PARESH RANJAN",
    lastName: "BOSE",
    address: "283 SHANTINAGAR*KHOSHBASH MAHALLA*PO-CHAKDAH*NADIA-741222.",
    fullName: "PARESH RANJAN BOSE",
    saID: "SPR0474",
  },
  {
    firstName: "LATE PARITOSH KUMAR",
    lastName: "BASU",
    address: "VILL-BIRESHPOLLY*PO-MADHYAMGRAM*DIST-24 PGS",
    fullName: "LATE PARITOSH KUMAR BASU",
    saID: "SPR0475",
  },
  {
    firstName: "LATE PRAKASH CHANDRA",
    lastName: "BOSE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE PRAKASH CHANDRA BOSE",
    saID: "SPR0476",
  },
  {
    firstName: "LATE RANJIT KUMAR",
    lastName: "BOSE",
    address: "*BURDWAN.",
    fullName: "LATE RANJIT KUMAR BOSE",
    saID: "SPR0477",
  },
  {
    firstName: "LATE SACHINDRA KUMAR",
    lastName: "BOSE",
    address: "BASIRHAT COLLEGEPARA*PO-BASIRHAT*DIST-24 PGS.",
    fullName: "LATE SACHINDRA KUMAR BOSE",
    saID: "SPR0478",
  },
  {
    firstName: "LATE SAMIRENDRA MOHAN",
    lastName: "BOSE",
    address:
      "CHITTARANJAN COLONY*PIPE LINE ROAD*PO-NABANAGAR*24PARGANAS NORTH 743136",
    fullName: "LATE SAMIRENDRA MOHAN BOSE",
    saID: "SPR0479",
  },
  {
    firstName: "SANKAR",
    lastName: "BOSE",
    address: "109/18-6,RAMMOHAN SARANI*PO-BAIDYABATI*HOOGHLY-712222",
    fullName: "SANKAR BOSE",
    saID: "SPR0480",
  },
  {
    firstName: "LATE SHANTI RANJAN",
    lastName: "BOSE",
    address: "1/269,NAKTALA*CALCUTTA-47",
    fullName: "LATE SHANTI RANJAN BOSE",
    saID: "SPR0481",
  },
  {
    firstName: "LATE SURENDRA MOHAN",
    lastName: "BOSE",
    address: "PO-HOJAI*DIST-NOWGANG",
    fullName: "LATE SURENDRA MOHAN BOSE",
    saID: "SPR0482",
  },
  {
    firstName: "SWADHA PRIYA",
    lastName: "BOSE",
    address:
      "P-830, LAKETOWN BLOCK-A*2ND FLOOR, BIRBAHU APARTMENT*KOLKATA-700089*PAN-ADEPB2167N",
    fullName: "SWADHA PRIYA BOSE",
    saID: "SPR0483",
  },
  {
    firstName: "KERRY",
    lastName: "BRACE",
    address: "201,ORIN STREET*PITTSBURGH,PA*15235 U.S.A.",
    fullName: "KERRY BRACE",
    saID: "SPR0484",
  },
  {
    firstName: "CHILAROY",
    lastName: "BRAHMA",
    address: "VILL/PO-SERFANGURI*MEDICAL STORE*GOALPARA-783346*ASSAM",
    fullName: "CHILAROY BRAHMA",
    saID: "SPR0485",
  },
  {
    firstName: "DHANIRAM",
    lastName: "BRAHMA",
    address: "VILL-NO-2 GARMARAGAON*PO-KAJIAMATI*DIST-UDALGURI-784509*(ASSAM)",
    fullName: "DHANIRAM BRAHMA",
    saID: "SPR0486",
  },
  {
    firstName: "LATE GUNESHWAR",
    lastName: "BRAHMA",
    address: "VILL/PO-PATAKOTA*GOALPARA-783360*ASSAM",
    fullName: "LATE GUNESHWAR BRAHMA",
    saID: "SPR0487",
  },
  {
    firstName: "LATE JITENDRA CHANDRA",
    lastName: "BRAHMA",
    address: "VILL/PO-DONGAIGAON*VIA-BONGAIGAON*GOALPARA",
    fullName: "LATE JITENDRA CHANDRA BRAHMA",
    saID: "SPR0488",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "BRAHMA",
    address: "BURDWAN",
    fullName: "LATE JITENDRA NATH BRAHMA",
    saID: "SPR0489",
  },
  {
    firstName: "LATE KAMAL KUMAR",
    lastName: "BRAHMA",
    address: "PO PASCHIM JHARGAON,*DIST KAMRUP",
    fullName: "LATE KAMAL KUMAR BRAHMA",
    saID: "SPR0490",
  },
  {
    firstName: "LATE MADAN KUMAR",
    lastName: "BRAHMA",
    address: "VILL/PO-GOSSAIGAON TOWN WORD NO-2*(DALGAON)*KOKRAJHAR-783360.",
    fullName: "LATE MADAN KUMAR BRAHMA",
    saID: "SPR0491",
  },
  {
    firstName: "LATE MUGA CHARAN",
    lastName: "BRAHMA",
    address: "VILL-BHIRANG GAON*PO-DANGAIGAON*KOKRAJHAR",
    fullName: "LATE MUGA CHARAN BRAHMA",
    saID: "SPR0492",
  },
  {
    firstName: "NAGENDRA",
    lastName: "BRAHMA",
    address: "HATIMATHA ROAD*PO/DIST-KOKRAJHAR",
    fullName: "NAGENDRA BRAHMA",
    saID: "SPR0493",
  },
  {
    firstName: "LATE NARESWAR",
    lastName: "BRAHMA",
    address: "SUKANJHARA *PO.MAGURMARI *GOALPARA",
    fullName: "LATE NARESWAR BRAHMA",
    saID: "SPR0494",
  },
  {
    firstName: "NITYA RANJAN",
    lastName: "BRAHMA",
    address: "VILL-THURIBARI(PADMABILL)*PO-AFLAGAON*KOKRAJHAR-783346*ASSAM",
    fullName: "NITYA RANJAN BRAHMA",
    saID: "SPR0495",
  },
  {
    firstName: "LATE RABILOCHAN",
    lastName: "BRAHMA",
    address: "V-AMLAIGURI*PO-PATGAON*DT-GOALPARA.",
    fullName: "LATE RABILOCHAN BRAHMA",
    saID: "SPR0496",
  },
  {
    firstName: "LATE RUPENDRA NATH",
    lastName: "BRAHMA",
    address: "NAKARGAON*PO-PUTHIMARI*DIST-GOALPARA.",
    fullName: "LATE RUPENDRA NATH BRAHMA",
    saID: "SPR0497",
  },
  {
    firstName: "SAILENDRA NATH",
    lastName: "BRAHMA",
    address: "AT-THURIBARI(PADMABILL)*PO-PUTHIMARI*KOKRAJHAR-783347*ASSAM",
    fullName: "SAILENDRA NATH BRAHMA",
    saID: "SPR0498",
  },
  {
    firstName: "LATE SURENDRA NATH",
    lastName: "BRAHMA",
    address: "VILL-SEKADANI*PO-PATAKATA*KOKRAJHAR-783360*ASSAM",
    fullName: "LATE SURENDRA NATH BRAHMA",
    saID: "SPR0499",
  },
  {
    firstName: "LATE TAREN CHANDRA",
    lastName: "BRAHMA",
    address: "AT-NORTH KAJALGAON*PO-KAJALGAON*GOALPARA",
    fullName: "LATE TAREN CHANDRA BRAHMA",
    saID: "SPR0500",
  },
  {
    firstName: "LATE UMANAND",
    lastName: "BRAHMA",
    address:
      "RTD.EXECUTIVE ENGG.KOKRAJHAR*BAGANSALI*NEAR BRAHMA MANDIR WORD NO-7*PO/DT-KOKRAJHAR",
    fullName: "LATE UMANAND BRAHMA",
    saID: "SPR0501",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "BRAHMABASUMATARY",
    address: "VILL-GOKULKATA*PO-JARAGURI*KOKRAJHAR",
    fullName: "LATE BIRENDRA NATH BRAHMABASUMATARY",
    saID: "SPR0502",
  },
  {
    firstName: "LATE SADANANDA",
    lastName: "BRAHMACHARY",
    address: "AT&PO-SUTAHATA*MIDNAPORE-721635",
    fullName: "LATE SADANANDA BRAHMACHARY",
    saID: "SPR0503",
  },
  {
    firstName: "LATE SATCHIDANANDA",
    lastName: "BRAHMACHARY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SATCHIDANANDA BRAHMACHARY",
    saID: "SPR0504",
  },
  {
    firstName: "LATE AKENDRA NATH",
    lastName: "BRAHMA",
    address: "AT-INTIBIL*PO-LAUDANGA*KOKRAJHAR (B.T.A.D) ASSAM",
    fullName: "LATE AKENDRA NATH BRAHMA",
    saID: "SPR0505",
  },
  {
    firstName: "LATE SIDDHESWAR",
    lastName: "BUJARBARUAH",
    address: "STATE TRANSPORT OFFICE*PO-RANGIA*DT-KAMRUP.",
    fullName: "LATE SIDDHESWAR BUJARBARUAH",
    saID: "SPR0506",
  },
  {
    firstName: "YVES LE",
    lastName: "CADRE",
    address: "16,PLOKE DE LU DIGUE*VERDUN-55*FRANCE",
    fullName: "YVES LE CADRE",
    saID: "SPR0507",
  },
  {
    firstName: "TAPAN",
    lastName: "CHAKRABORTY",
    address:
      "PO+VILL-MEDIA COLONY*VIA-KHANTURA*DIST-24 PARGANAS (NORTH)-743273.",
    fullName: "TAPAN CHAKRABORTY",
    saID: "SPR0508",
  },
  {
    firstName: "LATE ACHUTANANDA",
    lastName: "CHAKRABORTY",
    address: "PO-PANDUA*SATSANG DENTAL CO*DIST-HOOGHLY",
    fullName: "LATE ACHUTANANDA CHAKRABORTY",
    saID: "SPR0509",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "CHAKRABORTY",
    address: "PO/VILL-EKRABARI*VIA-DALGAON*DARRANG 784514",
    fullName: "LATE AJIT KUMAR CHAKRABORTY",
    saID: "SPR0510",
  },
  {
    firstName: "AKHIL NARAYAN",
    lastName: "CHAKRABORTY",
    address:
      "151/2,BHAIRABTALA*GOPINATHPUR SAGARBHANGA*DURGAPUR-713219*BURDWAN*PAN-ACDPC4283G",
    fullName: "AKHIL NARAYAN CHAKRABORTY",
    saID: "SPR0511",
  },
  {
    firstName: "AKSHAY KUMAR",
    lastName: "CHAKRABARTY",
    address: "GAUHATI SATSANG VIHAR*BHANGAGARH,GAUHATI 781005*DIST KAMRUP",
    fullName: "AKSHAY KUMAR CHAKRABARTY",
    saID: "SPR0512",
  },
  {
    firstName: "AMALENDU",
    lastName: "CHAKRABORTY",
    address:
      "AT-ROWTA CHARIALI*PO-ROWTA*DIST-UDALGURI-784508*B T A D ASSAM*PAN NO AJOPCO127H",
    fullName: "AMALENDU CHAKRABORTY",
    saID: "SPR0513",
  },
  {
    firstName: "LATE AMAR CHANDRA",
    lastName: "CHAKRABORTY",
    address: "VILL.ITKHOLA*PO.SILCHAR-2*CACHAR",
    fullName: "LATE AMAR CHANDRA CHAKRABORTY",
    saID: "SPR0514",
  },
  {
    firstName: "LATE ANIL",
    lastName: "CHAKRABORTY",
    address: "ROAD NO-1,16 RAMNAGAR*AGARTALA(TRIPURA)",
    fullName: "LATE ANIL CHAKRABORTY",
    saID: "SPR0515",
  },
  {
    firstName: "ANIL",
    lastName: "CHAKRABARTY",
    address:
      "AT-SURI SATSANG THAKURBARI*PO-KARIDHYA*BIRBHUM-731126*PAN-ACSPC6542R",
    fullName: "ANIL CHAKRABARTY",
    saID: "SPR0516",
  },
  {
    firstName: "LATE BAKULJYOTI",
    lastName: "CHAKRABORTY",
    address: "117K/83,SARVODAYA NAGAR*KANPUR-5*U.P.",
    fullName: "LATE BAKULJYOTI CHAKRABORTY",
    saID: "SPR0517",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "CHAKRABORTY",
    address: "PO-DIGHALBAR*DT-MALDA.",
    fullName: "LATE BASANTA KUMAR CHAKRABORTY",
    saID: "SPR0518",
  },
  {
    firstName: "BHAJAN",
    lastName: "CHAKRABORTY",
    address:
      "S-69, BIDHAN NAGAR HOUSING*DURGAPUR-6*DIST-BURDWAN-713206*PAN-AHWPC2701R",
    fullName: "BHAJAN CHAKRABORTY",
    saID: "SPR0519",
  },
  {
    firstName: "BHASKAR",
    lastName: "CHAKRABORTY",
    address: "68 SURYA SEN STREET*CALCUTTA-700 009*PAN-ACFPC2060H",
    fullName: "BHASKAR CHAKRABORTY",
    saID: "SPR0520",
  },
  {
    firstName: "BINDU BHUSAN",
    lastName: "CHAKRABARTY",
    address: "SUBHASHNAGAR*PO/DIST.KARIMGANJ-788710*ASSAM",
    fullName: "BINDU BHUSAN CHAKRABARTY",
    saID: "SPR0521",
  },
  {
    firstName: "LATE BINOY",
    lastName: "CHAKRABORTY",
    address: "CLS/NBQ*PO-NEW BONGAIGAON*BONGAIGAON 783380",
    fullName: "LATE BINOY CHAKRABORTY",
    saID: "SPR0522",
  },
  {
    firstName: "LATE BIRAJA KANTA",
    lastName: "CHAKRABORTY",
    address: "PO-LUMDING*NOWGANG",
    fullName: "LATE BIRAJA KANTA CHAKRABORTY",
    saID: "SPR0523",
  },
  {
    firstName: "LATE BIRAJA KANTA",
    lastName: "CHAKRABORTY",
    address: "CACHAR.",
    fullName: "LATE BIRAJA KANTA CHAKRABORTY",
    saID: "SPR0524",
  },
  {
    firstName: "BROJENDRA LAL",
    lastName: "CHAKRABORTY",
    address: "42/129,DUM DUM ROAD*CALCUTTA-700030",
    fullName: "BROJENDRA LAL CHAKRABORTY",
    saID: "SPR0525",
  },
  {
    firstName: "LATE DHIRENDRA",
    lastName: "CHAKRABORTY",
    address: "P-24/168,JOYPUR ROAD*CALCUTTA 30",
    fullName: "LATE DHIRENDRA CHAKRABORTY",
    saID: "SPR0526",
  },
  {
    firstName: "LATE DHIRENDRA",
    lastName: "CHAKRABORTY",
    address: "SILCHAR TOWN ARYA PATTI*PO-SILCHAR-1*DIST-CACHAR",
    fullName: "LATE DHIRENDRA CHAKRABORTY",
    saID: "SPR0527",
  },
  {
    firstName: "LATE DHRUBA RANJAN",
    lastName: "CHAKRABORTY",
    address: "PO BINOVANAGAR*GAUHATI-18*DIST-KAMRUP",
    fullName: "LATE DHRUBA RANJAN CHAKRABORTY",
    saID: "SPR0528",
  },
  {
    firstName: "DIBAKAR",
    lastName: "CHAKRABORTY",
    address: "SATSANG VIHAR BADARPUR*PO-BADARPUR*CACHAR-788806*ASSAM",
    fullName: "DIBAKAR CHAKRABORTY",
    saID: "SPR0529",
  },
  {
    firstName: "LATE DILIP",
    lastName: "CHAKRABORTY",
    address: "186,SRIPALLY*ASANSOL-4*BURDWAN",
    fullName: "LATE DILIP CHAKRABORTY",
    saID: "SPR0530",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "VILL-D RAMPUR*PO-BAROBISHA*DT-JALPAIGURI.",
    fullName: "LATE DINESH CHANDRA CHAKRABORTY",
    saID: "SPR0531",
  },
  {
    firstName: "DINESH  CHANDRA",
    lastName: "CHAKRABORTY",
    address: "VILL-BARIGAON*PO-ABHAYAPURI*GOALPARA-783384*ASSAM",
    fullName: "DINESH  CHANDRA CHAKRABORTY",
    saID: "SPR0532",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "VILL-PIRAJPUR*PO&DT-MALDA.",
    fullName: "LATE DINESH CHANDRA CHAKRABORTY",
    saID: "SPR0533",
  },
  {
    firstName: "BHASKAR",
    lastName: "CHAKRABORTY",
    address: "H 1454,GFF CHITTARANJAN PARK*NEW DELHI 110019",
    fullName: "BHASKAR CHAKRABORTY",
    saID: "SPR0534",
  },
  {
    firstName: "DWIJENDRA CHANDRA",
    lastName: "CHAKRABORTY",
    address: "C/O ASHATARA NANDY*DUDHPUR COLONY*PO-KANCHANBARI*TRIPURA.",
    fullName: "DWIJENDRA CHANDRA CHAKRABORTY",
    saID: "SPR0535",
  },
  {
    firstName: "FALGUNI BIJOY",
    lastName: "CHAKRABORTY",
    address:
      "RLY.QR.NO 575-D*WIRELESS COLONY*PO.BADARPUR*CACHAR*PAN-AUZPC2970Q",
    fullName: "FALGUNI BIJOY CHAKRABORTY",
    saID: "SPR0536",
  },
  {
    firstName: "LATE GANAPATI",
    lastName: "CHAKRABORTY",
    address: "C/O GOURISH CHAKRABORTY*LINK ROAD,LANE NO-9*SILCHAR-6.",
    fullName: "LATE GANAPATI CHAKRABORTY",
    saID: "SPR0537",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "PO-MAHARIPARA*KAMRUP",
    fullName: "LATE GANESH CHANDRA CHAKRABORTY",
    saID: "SPR0538",
  },
  {
    firstName: "LATE GAURANGA",
    lastName: "CHAKRABORTY",
    address: "SATSANG VIHAR*PO-AGARTALA*DT-TRIPURA.",
    fullName: "LATE GAURANGA CHAKRABORTY",
    saID: "SPR0539",
  },
  {
    firstName: "LATE GITESH RANJAN",
    lastName: "CHAKRABORTY",
    address: "SUB DEPUTY COLLECTOR*BAR KSHETRI CIRCLE*PO-MUKALMUA*KAMRUP",
    fullName: "LATE GITESH RANJAN CHAKRABORTY",
    saID: "SPR0540",
  },
  {
    firstName: "GOPIKA RANJAN",
    lastName: "CHAKRABORTY",
    address: "AT-LINK ROAD,LANE NO-9*PO/DIST-SILCHAR-788006*ASSAM",
    fullName: "GOPIKA RANJAN CHAKRABORTY",
    saID: "SPR0541",
  },
  {
    firstName: "GOURIPADA",
    lastName: "CHAKRABORTY",
    address:
      '"DAYAL KUNJA"*VILL-GARH KAMALPUR (WEST)*PO-MAHISHADAL-721628*PURBA MEDINIPUR-721628',
    fullName: "GOURIPADA CHAKRABORTY",
    saID: "SPR0542",
  },
  {
    firstName: "LATE GOUTAM",
    lastName: "CHAKRABORTY",
    address: "68,SURYA SEN STREET*CALCUTTA-9.",
    fullName: "LATE GOUTAM CHAKRABORTY",
    saID: "SPR0543",
  },
  {
    firstName: "HARIPADA",
    lastName: "CHAKRABARTY",
    address: "PO-SHITALKUCHI*DIST-COOCHBEHAR.",
    fullName: "HARIPADA CHAKRABARTY",
    saID: "SPR0544",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "CHAKRABORTY",
    address:
      "C/O A C BANERJEE*DOMOHONI RLY COLONY*QTR NO 875/A*PO-KALLA*VIA-ASANSOLE*BURDWAN 40",
    fullName: "LATE HARIPADA CHAKRABORTY",
    saID: "SPR0545",
  },
  {
    firstName: "HIRANMOY",
    lastName: "CHAKRABARTY",
    address: "PATULIA GOVT.QR NO-E42*PO-PATULIA*DIST-24 PGS (NORTH)",
    fullName: "HIRANMOY CHAKRABARTY",
    saID: "SPR0546",
  },
  {
    firstName: "LATE JALADHAR",
    lastName: "CHAKRABORTY",
    address:
      "C/O-CHAKRADHAR CHAKRABARTY*L D CLERK,O/O THE S.P. CACHER*CACHER 788001.",
    fullName: "LATE JALADHAR CHAKRABORTY",
    saID: "SPR0547",
  },
  {
    firstName: "JATINDRA MOHAN",
    lastName: "CHAKRABORTY",
    address: "HOUSE NO 31 PUBERUN NAGAR*PO-UDAYAN VIHAR*GUWAHATI 781171",
    fullName: "JATINDRA MOHAN CHAKRABORTY",
    saID: "SPR0548",
  },
  {
    firstName: "JIBAN",
    lastName: "CHAKRABORTY",
    address: "130 R R PLOT*ANANDAPUR*KOLKATA 700107*PAN-ACMPC6095K",
    fullName: "JIBAN CHAKRABORTY",
    saID: "SPR0549",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "CHAKRABORTY",
    address: "SATSANG VIHAR*NAWRANGPUR",
    fullName: "LATE JOGENDRA NATH CHAKRABORTY",
    saID: "SPR0550",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "PO-NABADWIP*NADIA",
    fullName: "LATE JOGESH CHANDRA CHAKRABORTY",
    saID: "SPR0551",
  },
  {
    firstName: "LATE JOY KRISHNA",
    lastName: "CHAKRABORTY",
    address: "C/O P.KUNDU*19/55,EDISON ROAD DURGAPUR-5*DIST BURDWAN",
    fullName: "LATE JOY KRISHNA CHAKRABORTY",
    saID: "SPR0552",
  },
  {
    firstName: "LATE JUGAL KISHORE",
    lastName: "CHAKRABORTY",
    address: "PO/VILL-GARHBALIA*DIST-HOWRAH.",
    fullName: "LATE JUGAL KISHORE CHAKRABORTY",
    saID: "SPR0553",
  },
  {
    firstName: "LATE KABINDRA KISHORE",
    lastName: "CHAKRABORTY",
    address: "VILL-NAROURA*PO-BISALGARH*TRIPURA WEST",
    fullName: "LATE KABINDRA KISHORE CHAKRABORTY",
    saID: "SPR0554",
  },
  {
    firstName: "KISHORE",
    lastName: "CHAKRABORTY",
    address: "VILL-SONAICHARI*PO-SABROOM*TRIPURA SOUTH-799145*PAN-ABKPC0992J",
    fullName: "KISHORE CHAKRABORTY",
    saID: "SPR0555",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "CHAKRABORTY",
    address: "AT.SUBHASNAGAR*PO/DIST.KARIMGANJ",
    fullName: "LATE KUMUD RANJAN CHAKRABORTY",
    saID: "SPR0556",
  },
  {
    firstName: "LATE LAKSHMAN",
    lastName: "CHAKRABORTY",
    address: "44,SARAT SEN ROAD*CALCUTTA-700020",
    fullName: "LATE LAKSHMAN CHAKRABORTY",
    saID: "SPR0557",
  },
  {
    firstName: "LATE LALIT MOHAN",
    lastName: "CHAKRABORTY",
    address: "SRIPUR COLONY*PO KALYANPUR*GOPALGANJ.",
    fullName: "LATE LALIT MOHAN CHAKRABORTY",
    saID: "SPR0558",
  },
  {
    firstName: "MANAS KUMAR",
    lastName: "CHAKRABORTY",
    address: "SATSANG  814116*DEOGHAR*PAN-ABQPC8909D",
    fullName: "MANAS KUMAR CHAKRABORTY",
    saID: "SPR0559",
  },
  {
    firstName: "MANGALMOY",
    lastName: "CHAKRABORTY",
    address: "AT&PO-URA*VIA-GOLSI*DIST-BURDWAN-713433*PAN-BFIPC1476A",
    fullName: "MANGALMOY CHAKRABORTY",
    saID: "SPR0560",
  },
  {
    firstName: "LATE MANIK LAL",
    lastName: "CHAKRABORTY",
    address: "VILL-KALNA*PO-PARSURA*DT-BURDWAN.",
    fullName: "LATE MANIK LAL CHAKRABORTY",
    saID: "SPR0561",
  },
  {
    firstName: "MANILAL",
    lastName: "CHAKRABORTY",
    address: "*SATSANG 814116.",
    fullName: "MANILAL CHAKRABORTY",
    saID: "SPR0562",
  },
  {
    firstName: "LATE MANOTOSH",
    lastName: "CHAKRABORTY",
    address:
      "C/O SJ. N.C.KARMAKAR*ARYASREE JWELLARY WORKS B.C.ROAD*KALITALA*DIST BURDWAN",
    fullName: "LATE MANOTOSH CHAKRABORTY",
    saID: "SPR0563",
  },
  {
    firstName: "LATE MILAN",
    lastName: "CHAKRABORTY",
    address: "C/O B.CHAKRABORTY*RAMNAGAR RD NO-1*TRIPURA",
    fullName: "LATE MILAN CHAKRABORTY",
    saID: "SPR0564",
  },
  {
    firstName: "LATE MOHINI MOHAN",
    lastName: "CHAKRABORTY",
    address: "AT-BARA PARA*PO-BONGAIGAON*GOALPARA",
    fullName: "LATE MOHINI MOHAN CHAKRABORTY",
    saID: "SPR0565",
  },
  {
    firstName: "LATE MOHINI MOHAN",
    lastName: "CHAKRABORTY",
    address: "C/O N R BANERJEE*30/18 RADHAMADHAB DUTTA*GARDEN LANE*CALCUTTA-10",
    fullName: "LATE MOHINI MOHAN CHAKRABORTY",
    saID: "SPR0566",
  },
  {
    firstName: "MRIDUL KANTI",
    lastName: "CHAKRABORTY",
    address: "FOOD CORPORATION INDIA*PO-FAKIRAGRAM*KOKRAJHAR-783345*ASSAM",
    fullName: "MRIDUL KANTI CHAKRABORTY",
    saID: "SPR0567",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "CHAKRABORTY",
    address: "LAHORIAJAN THAKURBARI*PO-LAHORIAJAN*SONITPUR 784176.",
    fullName: "LATE MRINAL KANTI CHAKRABORTY",
    saID: "SPR0568",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "CHAKRABORTY",
    address: "ADARSHAPOLLY*PO-KHARDAHA*DIST-24 PGS.",
    fullName: "LATE NAGENDRA NATH CHAKRABORTY",
    saID: "SPR0569",
  },
  {
    firstName: "NANDA DULAL",
    lastName: "CHAKRABORTY",
    address: "VILL-DUBCHURURIA*PO-ANDAL GRAM*DIST-BURDWAN 713321",
    fullName: "NANDA DULAL CHAKRABORTY",
    saID: "SPR0570",
  },
  {
    firstName: "NANDAN",
    lastName: "CHAKRABORTY",
    address: "C/O-NIROD BANDHU CHAKRABORTY*PO-AGARTALA COLLEGE*TRIPURA-799004",
    fullName: "NANDAN CHAKRABORTY",
    saID: "SPR0571",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "CHAKRABORTY",
    address: "*SATSANG 814116",
    fullName: "LATE NANIGOPAL CHAKRABORTY",
    saID: "SPR0572",
  },
  {
    firstName: "LATE NARENDRA MOHAN",
    lastName: "CHAKRABORTY",
    address: "MAJILPUR*PO-JOYNAGAR*DIST-24PARGANAS.",
    fullName: "LATE NARENDRA MOHAN CHAKRABORTY",
    saID: "SPR0573",
  },
  {
    firstName: "NIKHIL RANJAN",
    lastName: "CHAKRABORTY",
    address: "NETAJEE ROAD*N'AREA*LANKA-782 446*DIST-NAGAON*ASSAM",
    fullName: "NIKHIL RANJAN CHAKRABORTY",
    saID: "SPR0574",
  },
  {
    firstName: "LATE NRIPENDRA NARAYAN",
    lastName: "CHAKRABORTY",
    address: "VILL-RAGHUNANDANPUR*PO-BOITAMARI*GOALPARA",
    fullName: "LATE NRIPENDRA NARAYAN CHAKRABORTY",
    saID: "SPR0575",
  },
  {
    firstName: "PANCHANAN",
    lastName: "CHAKRABORTY",
    address: "DURLAVGANJ*PO-SATBANKURA*DIST-PASCHIM MEDINIPUR-721253.",
    fullName: "PANCHANAN CHAKRABORTY",
    saID: "SPR0576",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "DANTAN SATSANG KENDRA*PO+V-DANTAN*DIST-MIDNAPORE",
    fullName: "LATE PARESH CHANDRA CHAKRABORTY",
    saID: "SPR0577",
  },
  {
    firstName: "LATE PARITOSH",
    lastName: "CHAKRABORTY",
    address: "AT+PO-GAIGHATA-743249*DIST-24 PGS.",
    fullName: "LATE PARITOSH CHAKRABORTY",
    saID: "SPR0578",
  },
  {
    firstName: "PARITOSH",
    lastName: "CHAKRABORTY",
    address:
      "AMRITMAYEE HOMEO HALL*AT-OFFICE TILLA*PO-DHARMANAGAR*TRIPURA NORTH-799250",
    fullName: "PARITOSH CHAKRABORTY",
    saID: "SPR0579",
  },
  {
    firstName: "PRAFULLA RANJAN",
    lastName: "CHAKRABORTY",
    address: "PRASADPUR*MILANPALLY*PO-BARASAT*DIST 24 PGS",
    fullName: "PRAFULLA RANJAN CHAKRABORTY",
    saID: "SPR0580",
  },
  {
    firstName: "LATE PRAMESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "C/O DIPAN PHARMACY*A T ROAD*PO-TINSUKIA*DIBRUGARH",
    fullName: "LATE PRAMESH CHANDRA CHAKRABORTY",
    saID: "SPR0581",
  },
  {
    firstName: "PRAN KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "BENGALI MARKET*PO-NEORIA*DIST PILIBHIT*U.P. 262305*PAN-ARAPC7345P",
    fullName: "PRAN KUMAR CHAKRABORTY",
    saID: "SPR0582",
  },
  {
    firstName: "LATE PRANAB KUMAR",
    lastName: "CHAKRABORTY",
    address: "PO-GOSSAIGAON*GOALPARA",
    fullName: "LATE PRANAB KUMAR CHAKRABORTY",
    saID: "SPR0583",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "CHAKRABORTY",
    address: "*SATSANG. 814116",
    fullName: "PRAVAT KUMAR CHAKRABORTY",
    saID: "SPR0584",
  },
  {
    firstName: "LATE PRIYANATH",
    lastName: "CHAKRABORTY",
    address: "4 NO SANKARI PUKUR*PO/DIST-BURDWAN.",
    fullName: "LATE PRIYANATH CHAKRABORTY",
    saID: "SPR0585",
  },
  {
    firstName: "LATE RAMENDRA KUMAR",
    lastName: "CHAKRABORTY",
    address: "SATSANG VIHAR;NAWGAON*PO&DIST-NAWGAON",
    fullName: "LATE RAMENDRA KUMAR CHAKRABORTY",
    saID: "SPR0586",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "CHAKRABORTY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE RAMESH CHANDRA CHAKRABORTY",
    saID: "SPR0587",
  },
  {
    firstName: "RAMHARI",
    lastName: "DASCHAKRABORTY",
    address: "VILL-SITARAMPUR*PO-CHINGRA*DIST-BANKURA-722150",
    fullName: "RAMHARI DASCHAKRABORTY",
    saID: "SPR0588",
  },
  {
    firstName: "REV AMAL KUMAR",
    lastName: "CHAKRABORTY",
    address: "*SATSANG",
    fullName: "REV AMAL KUMAR CHAKRABORTY",
    saID: "SPR0589",
  },
  {
    firstName: "REV AMBARISH",
    lastName: "CHAKRABORTY",
    address: "SATSANG*DEOGHAR-814116.",
    fullName: "REV AMBARISH CHAKRABORTY",
    saID: "SPR0590",
  },
  {
    firstName: "LATE REV AMITAVA",
    lastName: "CHAKRABORTY",
    address: "*SATSANG",
    fullName: "LATE REV AMITAVA CHAKRABORTY",
    saID: "SPR0591",
  },
  {
    firstName: "REV ANGSHUMAN",
    lastName: "CHAKRABORTY",
    address: "*SATSANG-814116.",
    fullName: "REV ANGSHUMAN CHAKRABORTY",
    saID: "SPR0592",
  },
  {
    firstName: "LATE REV ANUPAM",
    lastName: "CHAKRABORTY",
    address: "*SATSANG.",
    fullName: "LATE REV ANUPAM CHAKRABORTY",
    saID: "SPR0593",
  },
  {
    firstName: "PUJYAPAD ASHOKE",
    lastName: "CHAKRABORTY",
    address: "*SATSANG",
    fullName: "PUJYAPAD ASHOKE CHAKRABORTY",
    saID: "SPR0594",
  },
  {
    firstName: "REV DR ALOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "SATSANG*DEOGHAR",
    fullName: "REV DR ALOKE KUMAR CHAKRABORTY",
    saID: "SPR0595",
  },
  {
    firstName: "RICHINANDAN",
    lastName: "CHAKRABORTY",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "RICHINANDAN CHAKRABORTY",
    saID: "SPR0596",
  },
  {
    firstName: "SACHI BHUSAN",
    lastName: "CHAKRABORTY",
    address: "PO-RAJABARI*BANSBARI*DIST-JORHAT-785014",
    fullName: "SACHI BHUSAN CHAKRABORTY",
    saID: "SPR0597",
  },
  {
    firstName: "SAKTI KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "3/10,KEOTA MILITARY COLONY*PO.SAHAGANJ*DIST.HOOGHLY-712104*PAN-ACMPC0938K",
    fullName: "SAKTI KUMAR CHAKRABORTY",
    saID: "SPR0598",
  },
  {
    firstName: "SAKTI PADA",
    lastName: "CHAKRABORTY",
    address: "NEAR BURADANGARIA THAN*RUBBER BAGAN*PO-TEZPUR*SONITPUR 784 007",
    fullName: "SAKTI PADA CHAKRABORTY",
    saID: "SPR0599",
  },
  {
    firstName: "JOGDHAN",
    lastName: "MANKI",
    address: "",
    fullName: "JOGDHAN MANKI",
    saID: "SPR0600",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "CHAKRABORTY",
    address: "BALIDANGA COLONY*HORSE RACE FIELD*PO-SHREE PALLY*BURDWAN.",
    fullName: "LATE SANTOSH KUMAR CHAKRABORTY",
    saID: "SPR0601",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "CHAKRABORTY",
    address: "C/196 SECTOR-14*PO.ROURKELLA*SUNDARGARH.",
    fullName: "LATE SANTOSH KUMAR CHAKRABORTY",
    saID: "SPR0602",
  },
  {
    firstName: "SASANKA",
    lastName: "CHAKRABORTY",
    address: "VIL-DURGAPUR*PO-PAITURBAZAR*NORTH TRIPURA-799279.",
    fullName: "SASANKA CHAKRABORTY",
    saID: "SPR0603",
  },
  {
    firstName: "SHANKAR PRASAD",
    lastName: "CHAKRABORTY",
    address:
      "NUTAN CHATI*CIRCUS MAIDAN*PO&DIST-BANKURA-722101.*PAN- ACNPC7409A",
    fullName: "SHANKAR PRASAD CHAKRABORTY",
    saID: "SPR0604",
  },
  {
    firstName: "LATE SHYAMA PRASAD",
    lastName: "CHAKRABORTY",
    address: "BARJALA HIGH SCHOOL*PO-BARJALA*AGARTALA 799011*TRIPURA (W)",
    fullName: "LATE SHYAMA PRASAD CHAKRABORTY",
    saID: "SPR0605",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "VILL-BENEDIGHI*PO-LUTUNIA*VIA-SABANG*PASCHIM MEDINIPUR-721166*PAN.ASLPC9643A",
    fullName: "SISIR KUMAR CHAKRABORTY",
    saID: "SPR0606",
  },
  {
    firstName: "SISIR RANJAN",
    lastName: "CHAKRABORTY",
    address: "GOURIPUR WARD NO-1*DHUBRI-783331.",
    fullName: "SISIR RANJAN CHAKRABORTY",
    saID: "SPR0607",
  },
  {
    firstName: "SNIGDHENDU",
    lastName: "CHAKRABORTY",
    address: "BAIDYADANGA*PO RASULPUR*DIST BURDWAN-713151",
    fullName: "SNIGDHENDU CHAKRABORTY",
    saID: "SPR0608",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "CHAKRABARTY",
    address: "BIDHIDIPA PARISAD*PO-MALLICKPARA*DIST-HOOGHLY",
    fullName: "LATE SUDHIR CHANDRA CHAKRABARTY",
    saID: "SPR0609",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "CHAKRABORTY",
    address: "ASHA PURNA SHARANI*AT/PO-PRADHAN NAGAR*DERJEELING 734403",
    fullName: "LATE SUNIL KUMAR CHAKRABORTY",
    saID: "SPR0610",
  },
  {
    firstName: "LATE SUSHIL KUMAR",
    lastName: "CHAKRABORTY",
    address: "MAHAMMADPUR*PO-DALALPARA**MURSHIDABAD.",
    fullName: "LATE SUSHIL KUMAR CHAKRABORTY",
    saID: "SPR0611",
  },
  {
    firstName: "LATE SUSHIL KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "QR 853/161 DOMOHINI RLY COLONY*PO-KALLA,VIA-ASANSOLE*DIST-BURDWAN",
    fullName: "LATE SUSHIL KUMAR CHAKRABORTY",
    saID: "SPR0612",
  },
  {
    firstName: "LATE TRAILOKYA NATH",
    lastName: "CHAKRABORTY",
    address: "1/1 DURGACHARAN MUKHERJEE STREET*CALCUTTA-700003",
    fullName: "LATE TRAILOKYA NATH CHAKRABORTY",
    saID: "SPR0613",
  },
  {
    firstName: "ADHIR KUMAR",
    lastName: "CHAKRAVARTY",
    address: "4/163 KATAGANJ*PO-BEDIBHABAN*NADIA-741250",
    fullName: "ADHIR KUMAR CHAKRAVARTY",
    saID: "SPR0614",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "CHAKRAVARTY",
    address: "AKRA SRIKRISHNA PALLY*PO-JAGANNATH NAGAR*DIST-24 PGS.",
    fullName: "LATE ASHUTOSH CHAKRAVARTY",
    saID: "SPR0615",
  },
  {
    firstName: "BADAL",
    lastName: "CHAKRAVARTY",
    address:
      "B.P.CHEMICALS *PO.KANAIPUR *DAKSHINPOLLI *PIN-712234 *DIST.HOOGHLY.",
    fullName: "BADAL CHAKRAVARTY",
    saID: "SPR0616",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "CHAKRABARTY",
    address: "32 ADIBARTMA ROAD*PO.NABAGRAM*DIST.HOOGHLY-712246",
    fullName: "GOPAL CHANDRA CHAKRABARTY",
    saID: "SPR0617",
  },
  {
    firstName: "LATE HARERAM",
    lastName: "CHAKRAVARTY",
    address: "20A KALIPRASANNA CHAKRAVARTY STREET*CALCUTTA-3",
    fullName: "LATE HARERAM CHAKRAVARTY",
    saID: "SPR0618",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "CHAKRAVARTY",
    address: "4P,NANDARAM SEN STREET*CALCUTTA-700 005",
    fullName: "LATE HIRALAL CHAKRAVARTY",
    saID: "SPR0619",
  },
  {
    firstName: "LATE JAGAT MOHAN",
    lastName: "CHAKRAVARTY",
    address: "144/33 A DHARMATALA ROAD*PO.SALKIA*DT.HOWRAH",
    fullName: "LATE JAGAT MOHAN CHAKRAVARTY",
    saID: "SPR0620",
  },
  {
    firstName: "KAJAL",
    lastName: "CHAKRAVARTY",
    address: "VIL&PO-BOXIRHAT*COOCHBEHAR.-736131",
    fullName: "KAJAL CHAKRAVARTY",
    saID: "SPR0621",
  },
  {
    firstName: "LATE KALYAN",
    lastName: "CHAKRAVARTY",
    address: "5/H HAZRA BAGAN LANE*CALCUTTA 15",
    fullName: "LATE KALYAN CHAKRAVARTY",
    saID: "SPR0622",
  },
  {
    firstName: "LATE KSHITISH",
    lastName: "CHAKRAVARTY",
    address: "NABANAGAR*PO.BIRATI*CALCUTTA-51.",
    fullName: "LATE KSHITISH CHAKRAVARTY",
    saID: "SPR0623",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "SARADA VASTRALAYA*PO-SILAPATHAR*DIST-DHEMAJI-787059*ASSAM",
    fullName: "LATE KSHITISH CHANDRA CHAKRAVARTY",
    saID: "SPR0624",
  },
  {
    firstName: "LATE MADHUSUDAN",
    lastName: "CHAKRAVARTY",
    address: "115,GOVT COLONY*PO-BANSBERIA*DIST.HOOGHLY",
    fullName: "LATE MADHUSUDAN CHAKRAVARTY",
    saID: "SPR0625",
  },
  {
    firstName: "LATE MANASHA CHARAN",
    lastName: "CHAKRAVARTY",
    address: "BANIPUR P.G.B.T.COLLEGE*PO-BANIPUR*DIST-24 PGS (NORTH).",
    fullName: "LATE MANASHA CHARAN CHAKRAVARTY",
    saID: "SPR0626",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "CHAKRABORTY",
    address: "SARAK PARA*PO-RANAGHAT*NADIA-741201*PAN-AOMPC5543A",
    fullName: "NANIGOPAL CHAKRABORTY",
    saID: "SPR0627",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "CHAKRAVARTY",
    address: "H.P. MUKHERJEE ROAD*ROYPARA*PO-KRISNANAGAR*NADIA",
    fullName: "NANIGOPAL CHAKRAVARTY",
    saID: "SPR0628",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "CHAKRAVARTY",
    address: "AT/PO-MAHUR*DIST-N.C.HILLS-788830*ASSAM",
    fullName: "NANIGOPAL CHAKRAVARTY",
    saID: "SPR0629",
  },
  {
    firstName: "LATE NEPAL CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "VILL/PO-SULTANGACHA*DIST-HOOGHLY-712148",
    fullName: "LATE NEPAL CHANDRA CHAKRAVARTY",
    saID: "SPR0630",
  },
  {
    firstName: "NETAI",
    lastName: "CHAKRABORTY",
    address: "AT/PO-BAIRATIGURI*VIA-DHUPGURI*JALPAIGURI 735210.",
    fullName: "NETAI CHAKRABORTY",
    saID: "SPR0631",
  },
  {
    firstName: "NIKHIL CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "135 JATINDRANAGAR COLONY *CALCUTTA-35.",
    fullName: "NIKHIL CHANDRA CHAKRAVARTY",
    saID: "SPR0632",
  },
  {
    firstName: "SADHAN",
    lastName: "CHAKRAVARTY",
    address:
      "ARABINDAPALLI*PO.BHADRESWAR*DIST.HOOGHLY-712124*PAN NO.AEHPC5098M",
    fullName: "SADHAN CHAKRAVARTY",
    saID: "SPR0633",
  },
  {
    firstName: "LATE SHIBRAM",
    lastName: "CHAKRAVARTY",
    address:
      "SATSANG ADHIBESHAN KENDRA*NASIBPUR *VILL & PO-HOOGHLY*DIST.HOOGHLY.",
    fullName: "LATE SHIBRAM CHAKRAVARTY",
    saID: "SPR0634",
  },
  {
    firstName: "LATE SITAL CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "202A,HARISH MUKHERJEE ROAD*CALCUTTA-26",
    fullName: "LATE SITAL CHANDRA CHAKRAVARTY",
    saID: "SPR0635",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "AMAR SMRITI SATSANG VIHAR*VILL&PO-JAMALDAHA*COOCHBEHAR-735303",
    fullName: "SUBHASH CHANDRA CHAKRAVARTY",
    saID: "SPR0636",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "CHAKRAVARTY",
    address: "F/9/272 TAHERPUR*PO-TAHERPUR*NADIA-741159",
    fullName: "TAPAN KUMAR CHAKRAVARTY",
    saID: "SPR0637",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "CHAKRAVARTY",
    address:
      "C/O-UPENDRA NATH CHAKRABORTY*19/2A, LALA BABU SHIRE RD*PO-BELURMATH*HOWRAH.",
    fullName: "LATE UPENDRA NATH CHAKRAVARTY",
    saID: "SPR0638",
  },
  {
    firstName: "LATE ADHIR CHANDRA",
    lastName: "CHAKRAVORTY",
    address: "V.PV-58,PO KAPSI*DIST BASTER*M.P.",
    fullName: "LATE ADHIR CHANDRA CHAKRAVORTY",
    saID: "SPR0639",
  },
  {
    firstName: "ALOKE RANJAN",
    lastName: "CHAKRAVORTY",
    address:
      "SATSANG VIHAR, AGARTALA*PO-AGARTALA*TRIPURA WEST-799001*PAN-ASEPC0159N",
    fullName: "ALOKE RANJAN CHAKRAVORTY",
    saID: "SPR0640",
  },
  {
    firstName: "LATE ANIMESH",
    lastName: "CHAKRABORTY",
    address: "S.D.O'S OFFICE,UDAIPUR*PO-RADHAKRISHNAPUR*TRIPURA.",
    fullName: "LATE ANIMESH CHAKRABORTY",
    saID: "SPR0641",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "CHAKRAVORTY",
    address: "HOUSE NO-B/69*HOUSING COLONY*KANKARBAGH*PATNA 800020.",
    fullName: "GOPAL CHANDRA CHAKRAVORTY",
    saID: "SPR0642",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "CHAKRAVORTY",
    address: "9,ARUNDHUTINAGAR(SURYAPARA)*PO-ARUNDHUTINAGAR*TRIPURA-799003",
    fullName: "GOPAL CHANDRA CHAKRAVORTY",
    saID: "SPR0643",
  },
  {
    firstName: "LATE HARI NARAYAN",
    lastName: "CHAKRAVORTY",
    address: "PO-JOGIGHOPA*GOALPARA",
    fullName: "LATE HARI NARAYAN CHAKRAVORTY",
    saID: "SPR0644",
  },
  {
    firstName: "LATE IRESH CHANDRA",
    lastName: "CHAKRAVORTY",
    address: "VILL-CHINIHANY*PO-AGARTALA AIR PORT*TRIPURA (W) 799009.",
    fullName: "LATE IRESH CHANDRA CHAKRAVORTY",
    saID: "SPR0645",
  },
  {
    firstName: "LATE JOGESHWAR",
    lastName: "CHAKRAVORTY",
    address: "BORDOWALI HIGH SCHOOL*PO-ARUNDHUTINAGAR*AGARTALA*(TRIPURA).",
    fullName: "LATE JOGESHWAR CHAKRAVORTY",
    saID: "SPR0646",
  },
  {
    firstName: "PRAVAT",
    lastName: "CHAKRABORTY",
    address: "ROAD NO-6*PO-ARUNDHUTINAGAR*TRIPURA-799003",
    fullName: "PRAVAT CHAKRABORTY",
    saID: "SPR0647",
  },
  {
    firstName: "LATE PRIYA RANJAN",
    lastName: "CHAKRAVORTY",
    address: "34,AKHAWRA ROAD*PO.AGARTALA*TRIPURA",
    fullName: "LATE PRIYA RANJAN CHAKRAVORTY",
    saID: "SPR0648",
  },
  {
    firstName: "PROF ALAK KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "BLOCK D/01*PO-PANCHASAYAR*SRINAGAR(WEST)*GARIA STATION*KOLKATA 700094",
    fullName: "PROF ALAK KUMAR CHAKRABORTY",
    saID: "SPR0649",
  },
  {
    firstName: "LATE PULIN BIHARI",
    lastName: "CHAKRAVORTY",
    address: "WEST YARD COLONY*P.O.DIMAPUR*NAGALAND.",
    fullName: "LATE PULIN BIHARI CHAKRAVORTY",
    saID: "SPR0650",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "CHAKRAVORTY",
    address: "VILL-ALAMPUR*PO-NEW KOLORA*VIA-ANDULMOURI*HOWRAH-711302",
    fullName: "RANJIT KUMAR CHAKRAVORTY",
    saID: "SPR0651",
  },
  {
    firstName: "LATE RATHINDRA NARAYAN",
    lastName: "CHAKRAVORTY",
    address: "TELEPHONE EXCHANGE*JOWAI*MEGHALAYA-793150",
    fullName: "LATE RATHINDRA NARAYAN CHAKRAVORTY",
    saID: "SPR0652",
  },
  {
    firstName: "SUBODH KUMAR",
    lastName: "CHAKRAVORTY",
    address: '"SHREYASHRAY",I.C.D.COLONY*PO-AMINGAON*GUWAHATI-31*KAMRUP*ASSAM',
    fullName: "SUBODH KUMAR CHAKRAVORTY",
    saID: "SPR0653",
  },
  {
    firstName: "LATE MANIK CHANDRA",
    lastName: "CHAL",
    address: "AT/PO-SATISTHAN*HOOGHLY",
    fullName: "LATE MANIK CHANDRA CHAL",
    saID: "SPR0654",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "CHAND",
    address:
      '"SWASTI KUNJ"*PLOT NO-701/2005*AT-LINGIPUR*PO-SISHUPALGARH*BHUBANESWAR-2*DT-KHURDA',
    fullName: "KRUSHNA CHANDRA CHAND",
    saID: "SPR0655",
  },
  {
    firstName: "LATE RAKHAL CHANDRA",
    lastName: "CHAND",
    address: "QRS NO.E-3/14*PO-S.B.T.S.*DIST-ANGUL 759037",
    fullName: "LATE RAKHAL CHANDRA CHAND",
    saID: "SPR0656",
  },
  {
    firstName: "LATE ATINDRA CHANDRA",
    lastName: "CHANDA",
    address: "RLY STATION*PO CHAPARMUKH*NAGAON*ASSAM",
    fullName: "LATE ATINDRA CHANDRA CHANDA",
    saID: "SPR0657",
  },
  {
    firstName: "DAYAMAY",
    lastName: "CHANDA",
    address: "DEBRAPARCHARIALI*PO-NAKACHHARI*SIBSAGAR-785635.",
    fullName: "DAYAMAY CHANDA",
    saID: "SPR0658",
  },
  {
    firstName: "INDUNANDAN",
    lastName: "CHANDA",
    address:
      "F/9,SARASWATI NIWAS*AVINAS NAGAR,DIMNA ROAD*PO-MANGO*JAMSHEDPUR-831018*E/SINGHBHUM",
    fullName: "INDUNANDAN CHANDA",
    saID: "SPR0659",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "CHANDA",
    address: "PO-FATICKROY*DT-TRIPURA.",
    fullName: "LATE KALIPADA CHANDA",
    saID: "SPR0660",
  },
  {
    firstName: "LATE KETAKI RANJAN",
    lastName: "CHANDA",
    address: "PO.MATIGARA*TUMBA*DARJEELING",
    fullName: "LATE KETAKI RANJAN CHANDA",
    saID: "SPR0661",
  },
  {
    firstName: "LATE NAGENDRA KUMAR",
    lastName: "CHANDA",
    address: "PO-PATHARKANDI MUNDAMALA*KARIMGANJ 788724",
    fullName: "LATE NAGENDRA KUMAR CHANDA",
    saID: "SPR0662",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "CHANDA",
    address: "NAGAR MATHABHANGA WARD NO-11*PO-MATHABHANGA*COOCHBEHAR-736146",
    fullName: "NARAYAN CHANDRA CHANDA",
    saID: "SPR0663",
  },
  {
    firstName: "NIHAR RANJAN",
    lastName: "CHANDA",
    address: "VILL.FATIKROY BAJAR*P.O.FATIKROY*TRIPURA 792990",
    fullName: "NIHAR RANJAN CHANDA",
    saID: "SPR0664",
  },
  {
    firstName: "LATE NISHIKANTA",
    lastName: "CHANDA",
    address: "NEW PURULIA MAIN ROAD*MANGO,JAMSHEDPUR*SINGHBHUM",
    fullName: "LATE NISHIKANTA CHANDA",
    saID: "SPR0665",
  },
  {
    firstName: "PRATIVA KANCHAN",
    lastName: "CHANDA",
    address: "PO-JOGENDRANAGAR*BANKUMARI*AGARTALA*TRIPURA WEST 799004",
    fullName: "PRATIVA KANCHAN CHANDA",
    saID: "SPR0666",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "CHANDA",
    address:
      "MEDINIPUR THAKURBARI*AT-MIRZA BAZAR*PO-MEDINIPUR*PASCHIM MEDINIPUR-721101",
    fullName: "RABINDRA NATH CHANDA",
    saID: "SPR0667",
  },
  {
    firstName: "LATE RAJENDRA",
    lastName: "CHANDA",
    address: "VILL-SASTRI NAGAR*PO-CHHOTODUDH PATIL GRANT*CACHAR-788002*ASSAM",
    fullName: "LATE RAJENDRA CHANDA",
    saID: "SPR0668",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "CHANDA",
    address: "PO MEHERPUR,*ASSAM STATE ELECTRICITY BOARD*CACHAR.",
    fullName: "SISIR KUMAR CHANDA",
    saID: "SPR0669",
  },
  {
    firstName: "NATABAR",
    lastName: "CHANDRA",
    address: "AT-BENIPUR*PO.SALTORE*PURULIA",
    fullName: "NATABAR CHANDRA",
    saID: "SPR0670",
  },
  {
    firstName: "CHANDRABIR",
    lastName: "CHAR",
    address: "SHIB NAGAR*PO.PRADHANNAGAR-734003*DARJEELING",
    fullName: "CHANDRABIR CHAR",
    saID: "SPR0671",
  },
  {
    firstName: "ARUNODAY",
    lastName: "CHATTERJEE",
    address: "GOLAPBAGAN,HALDARPARA*PO-CHANDANNAGAR*HOOGHLY-712136",
    fullName: "ARUNODAY CHATTERJEE",
    saID: "SPR0672",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "CHATTERJEE",
    address: "23/1,PANCHANANTALA LANE*CALCUTTA-700034",
    fullName: "ASHUTOSH CHATTERJEE",
    saID: "SPR0673",
  },
  {
    firstName: "LATE BAIDYANATH",
    lastName: "CHATTERJEE",
    address:
      "ASSTT.GR.I,M.I.O.PROJECT*M & S DEPTT*P.O.MEGHAHATUBURU*SINGBHUM 833223",
    fullName: "LATE BAIDYANATH CHATTERJEE",
    saID: "SPR0674",
  },
  {
    firstName: "BALARAM",
    lastName: "CHATTERJEE",
    address: "MEMARI MAYERKOLE*PO MEMARI*DIST BURDWAN*PIN-713146",
    fullName: "BALARAM CHATTERJEE",
    saID: "SPR0675",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "CHATTERJEE",
    address: "56,ADHAR CHANDRA DAS LANE*CALCUTTA 700004",
    fullName: "LATE BIBHUTI BHUSAN CHATTERJEE",
    saID: "SPR0676",
  },
  {
    firstName: "LATE BRAHMA PADA",
    lastName: "CHATTERJEE",
    address:
      '"UPASANA NIKETAN"*14/1,HRIDAY KRISHNA BANERJEE*1ST BYE LANE*DT.HOWRAH',
    fullName: "LATE BRAHMA PADA CHATTERJEE",
    saID: "SPR0677",
  },
  {
    firstName: "LATE BROJENDRA LAL",
    lastName: "CHATTERJEE",
    address: "SUBHAS PALLY (SOUTH)*PO-DANKUNI*DIST-HOOGHLY-711224.",
    fullName: "LATE BROJENDRA LAL CHATTERJEE",
    saID: "SPR0678",
  },
  {
    firstName: "LATE BROJENDRANATH",
    lastName: "CHATTERJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE BROJENDRANATH CHATTERJEE",
    saID: "SPR0679",
  },
  {
    firstName: "LATE GIRINDRA NATH",
    lastName: "CHATTERJEE",
    address: "C/O SATSANG KENDRA BARPATHAR*PO-TINSUKIA*DIBRUGARH 786125",
    fullName: "LATE GIRINDRA NATH CHATTERJEE",
    saID: "SPR0680",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "CHATTERJEE",
    address: "38A,BENIATALA SREET*CALCUTTA-700005",
    fullName: "LATE GOPAL CHANDRA CHATTERJEE",
    saID: "SPR0681",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "CHATTERJEE",
    address: "AT-SAIPALA(TUFAN HOUSE)*PO-BASIRHAT 743411*DIST-24PGS",
    fullName: "LATE HARIPADA CHATTERJEE",
    saID: "SPR0682",
  },
  {
    firstName: "JNAN RANJAN",
    lastName: "CHATTERJEE",
    address: "C/O MANORANJAN CHATTERJEE*20,M BASU ROAD*PO/DIST-BURDWAN-713101",
    fullName: "JNAN RANJAN CHATTERJEE",
    saID: "SPR0683",
  },
  {
    firstName: "LATE KANAILAL",
    lastName: "CHATTERJEE",
    address: "SISUMAHAL NURSURY S.K.SCHOOL*PO&DT-JALPAIGURI.",
    fullName: "LATE KANAILAL CHATTERJEE",
    saID: "SPR0684",
  },
  {
    firstName: "LATE KRISHNALAL",
    lastName: "CHATTERJEE",
    address: "20,BANKIM CHATTERJEE ROAD*PO-NABAGRAM*DIST-HOOGHLY 712246",
    fullName: "LATE KRISHNALAL CHATTERJEE",
    saID: "SPR0685",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "CHATTERJEE",
    address: "AT/DWARBASINI*DIST.HOOGHLY",
    fullName: "LATE MADAN MOHAN CHATTERJEE",
    saID: "SPR0686",
  },
  {
    firstName: "MALAY KUMAR",
    lastName: "CHATTERJEE",
    address: "20,N BOSE ROAD*PO/DIST-BURDWAN-713101",
    fullName: "MALAY KUMAR CHATTERJEE",
    saID: "SPR0687",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "CHATTERJEE",
    address: "20 N BOSE ROAD*BURDWAN.",
    fullName: "LATE MANORANJAN CHATTERJEE",
    saID: "SPR0688",
  },
  {
    firstName: "LATE NALINAKSHYA",
    lastName: "CHATTERJEE",
    address: "5/C HAZRA BAGAN LANE*CALCUTTA 15",
    fullName: "LATE NALINAKSHYA CHATTERJEE",
    saID: "SPR0689",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "CHATTERJEE",
    address: "AT/PO-CHHAIGAON*KAMRUP",
    fullName: "LATE NARESH CHANDRA CHATTERJEE",
    saID: "SPR0690",
  },
  {
    firstName: "LATE NEPAL CHANDRA",
    lastName: "CHATTERJEE",
    address: "BANDEL SAHEB BAGAN*PO.MANASHPUR*DIST.HOOGHLY",
    fullName: "LATE NEPAL CHANDRA CHATTERJEE",
    saID: "SPR0691",
  },
  {
    firstName: "LATE NIKHIL CHANDRA",
    lastName: "CHATTERJEE",
    address: "BAGHAJATIN ROAD*PO-SILIGURI*DT-DARJEELING.",
    fullName: "LATE NIKHIL CHANDRA CHATTERJEE",
    saID: "SPR0692",
  },
  {
    firstName: "NILMANI",
    lastName: "CHATTERJEE",
    address: "VILL/PO-IKRAH*DIST-BARDDHAMAN-713362",
    fullName: "NILMANI CHATTERJEE",
    saID: "SPR0693",
  },
  {
    firstName: "LATE NILRATAN",
    lastName: "CHATTERJEE",
    address: "PUMPING STATION QR NO 5TH AVENUE*LADY COLONY*NEW DELHI-3",
    fullName: "LATE NILRATAN CHATTERJEE",
    saID: "SPR0694",
  },
  {
    firstName: "NISITH KUMAR",
    lastName: "CHATTERJEE",
    address: "C/O-D P BHATTACHARJEE*KALNA RD*PO-TRIBENI*DIST-HOOGHLY-712503",
    fullName: "NISITH KUMAR CHATTERJEE",
    saID: "SPR0695",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "CHATTERJEE",
    address: "12 RADHANAGAR SOUTH ROAD*BURDWAN.",
    fullName: "LATE PANCHANAN CHATTERJEE",
    saID: "SPR0696",
  },
  {
    firstName: "LATE SATYESH",
    lastName: "CHATTERJEE",
    address: "101 SARAT BOSE RD.*CALCUTTA-28",
    fullName: "LATE SATYESH CHATTERJEE",
    saID: "SPR0697",
  },
  {
    firstName: "ARBINDNATH",
    lastName: "CHATURVEDI",
    address: "AT-SINGHITALI*PO-CHAPRI(BHAWANATHPUR)*GARHWA-822114*JHARKHAND",
    fullName: "ARBINDNATH CHATURVEDI",
    saID: "SPR0698",
  },
  {
    firstName: "LATE SANTI RANJAN",
    lastName: "CHAUDHURY",
    address: "S.I.OF POLICE*RAMNAGAR ROAD NO 10*PO-RAMNAGAR*AGARTALA*TRIPURA.",
    fullName: "LATE SANTI RANJAN CHAUDHURY",
    saID: "SPR0699",
  },
  {
    firstName: "HEMARI",
    lastName: "SENAR",
    address: "VILL-CHINANADI*PO-GASHPARA*KARBIANGLONG 782425",
    fullName: "HEMARI SENAR",
    saID: "SPR0700",
  },
  {
    firstName: "CHANDRA BAHADUR",
    lastName: "CHETRI",
    address: "PO-TURA*WEST GARO HILLS*MEGHALAYA-794001",
    fullName: "CHANDRA BAHADUR CHETRI",
    saID: "SPR0701",
  },
  {
    firstName: "LATE RIT BAHADUR",
    lastName: "CHHETRI",
    address: "PO-TURA*DT-GARO HILLS",
    fullName: "LATE RIT BAHADUR CHHETRI",
    saID: "SPR0702",
  },
  {
    firstName: "LATE KULBAHADUR",
    lastName: "CHHETRY",
    address: "AT&PO- MAKUMKILLA *MARGHARITA*DIBRUGARH.",
    fullName: "LATE KULBAHADUR CHHETRY",
    saID: "SPR0703",
  },
  {
    firstName: "LALBAHADUR",
    lastName: "CHHETRY",
    address: "PO-MAKUMKILLA*VIL&PO-MARGHERITA*TINSUKIA-786181.",
    fullName: "LALBAHADUR CHHETRY",
    saID: "SPR0704",
  },
  {
    firstName: "LATE SURYA KUMAR",
    lastName: "CHINTE",
    address: "SATSANG VIHAR*CHIMAI MEDAK GAON*PO-BILMUKH*DIST-LAKHIMPUR.",
    fullName: "LATE SURYA KUMAR CHINTE",
    saID: "SPR0705",
  },
  {
    firstName: "LATE SANT  KUMAR",
    lastName: "CHOUBEY",
    address: "VILL-PUSAULI*PO-BHITTI(MOHANIA)*DT-KAIMUR(BHABUA)",
    fullName: "LATE SANT  KUMAR CHOUBEY",
    saID: "SPR0706",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "CHOUDHURY",
    address: "PO-SAITAN*MANIPUR",
    fullName: "LATE ASHUTOSH CHOUDHURY",
    saID: "SPR0707",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "CHOUDHURY",
    address: "FERRY GHAT NO-4 COLONY*PO GAUHATI-12*DIST KAMRUP",
    fullName: "LATE BIBHUTI BHUSAN CHOUDHURY",
    saID: "SPR0708",
  },
  {
    firstName: "LATE BISWA RANJAN",
    lastName: "CHOUDHURY",
    address: "68 SURJA SEN STREET*CAL-9",
    fullName: "LATE BISWA RANJAN CHOUDHURY",
    saID: "SPR0709",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "CHOUDHURY",
    address: "HEMAITPUR SATSANG*PO.RAMKANALI*PURULIA-723142*PAN-AEOPC1519G",
    fullName: "CHITTARANJAN CHOUDHURY",
    saID: "SPR0710",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "CHOUDHURY",
    address: "ASHRAM ROAD*PO/DIST-HAILAKANDI*(ASSAM)*PAN-BHCPC7705E",
    fullName: "DILIP KUMAR CHOUDHURY",
    saID: "SPR0711",
  },
  {
    firstName: "LATE HARENDRA KUMAR",
    lastName: "CHOUDHURY",
    address: "AT-JIRANIA*PO-BIRENDRA NAGAR*TRIPURA (WEST)",
    fullName: "LATE HARENDRA KUMAR CHOUDHURY",
    saID: "SPR0712",
  },
  {
    firstName: "LATE JATINDRA CHANDRA",
    lastName: "CHOUDHURY",
    address: "NARANGI KENDUGURI*GUWAHATI-26*KAMRUP",
    fullName: "LATE JATINDRA CHANDRA CHOUDHURY",
    saID: "SPR0713",
  },
  {
    firstName: "LATE JNANENDRA NATH",
    lastName: "CHOUDHURY",
    address: "182,B B CHATTERJEE ROAD*CALCUTTA 42",
    fullName: "LATE JNANENDRA NATH CHOUDHURY",
    saID: "SPR0714",
  },
  {
    firstName: "JYOTIRMOY",
    lastName: "CHOUDHURY",
    address:
      "KRISHNA NAGAR SWASTI SIBIR*PO-KRISHNANAGAR*NADIA-741101*PAN-AJGPC8126L",
    fullName: "JYOTIRMOY CHOUDHURY",
    saID: "SPR0715",
  },
  {
    firstName: "KAJAL SANKAR",
    lastName: "CHOUDHURY",
    address:
      '"MATRI PRASAD"*118 UMPLING*SHILLONG-793006*MEGHALAYA*PAN-AAVPC6614K',
    fullName: "KAJAL SANKAR CHOUDHURY",
    saID: "SPR0716",
  },
  {
    firstName: "KAUSIK KANTI",
    lastName: "CHOUDHURY",
    address: "STATION ROAD*PO&DIST-KARIMGANJ",
    fullName: "KAUSIK KANTI CHOUDHURY",
    saID: "SPR0717",
  },
  {
    firstName: "LATE KIRANSASHI",
    lastName: "CHOUDHURY",
    address: "PO-SHILLONG-6*DT-MEGHALAYA.",
    fullName: "LATE KIRANSASHI CHOUDHURY",
    saID: "SPR0718",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "CHOUDHURY",
    address: "VILL&PO-SOVANAGAR*DT-MALDA.",
    fullName: "LATE KSHITISH CHANDRA CHOUDHURY",
    saID: "SPR0719",
  },
  {
    firstName: "LATE KUMOD RANJAN",
    lastName: "CHOUDHURY",
    address: "PO-LAKSHI SAHAR*DT-CACHAR.",
    fullName: "LATE KUMOD RANJAN CHOUDHURY",
    saID: "SPR0720",
  },
  {
    firstName: "LATE LAKSHMI PADA",
    lastName: "CHOUDHURY",
    address: "FLAT 3C*225,DUM DUM PARK*CALCUTTA 700 055",
    fullName: "LATE LAKSHMI PADA CHOUDHURY",
    saID: "SPR0721",
  },
  {
    firstName: "LATE MAKHANLAL",
    lastName: "CHOUDHURY",
    address: "AT/PO-NAGARUKHRA*NADIA.",
    fullName: "LATE MAKHANLAL CHOUDHURY",
    saID: "SPR0722",
  },
  {
    firstName: "LATE NRIPENDRA NARAYAN",
    lastName: "CHOUDHURY",
    address: "C/O B.D.O.BOITAMARI DEV. BLOCK*PO-BOITAMARI*DIST-GOALPARA",
    fullName: "LATE NRIPENDRA NARAYAN CHOUDHURY",
    saID: "SPR0723",
  },
  {
    firstName: "PURUSHOTTAM",
    lastName: "CHOUDHURY",
    address: "AT/PO-KOTIAN*VIA-KADUAPADA*JAGATSINGHPUR",
    fullName: "PURUSHOTTAM CHOUDHURY",
    saID: "SPR0724",
  },
  {
    firstName: "LATE SOUMENDRA KRISHNA",
    lastName: "CHOUDHURY",
    address:
      "BIRENDRA HOMOEO HALL*G.S.ROAD,MONIPURI BASTI*GOUHATI 781008*KAMRUP",
    fullName: "LATE SOUMENDRA KRISHNA CHOUDHURY",
    saID: "SPR0725",
  },
  {
    firstName: "LATE SUKHENDU RANJAN",
    lastName: "CHOUDHURY",
    address: "VILL-DHALACHHARA*PO-EAST KANAKPUR*VIA-JAIPURRABHABAJAR*CACHAR",
    fullName: "LATE SUKHENDU RANJAN CHOUDHURY",
    saID: "SPR0726",
  },
  {
    firstName: "LATE SUSHIL KUMAR",
    lastName: "CHOUDHURY",
    address: "AT/PO SONEKUCHI*KAMRUP",
    fullName: "LATE SUSHIL KUMAR CHOUDHURY",
    saID: "SPR0727",
  },
  {
    firstName: "UDAI PRASAD",
    lastName: "CHOUDHURY",
    address: "JEC ROAD*PO-JORHAT 7*JORHAT 785007.",
    fullName: "UDAI PRASAD CHOUDHURY",
    saID: "SPR0728",
  },
  {
    firstName: "PANDURANG SHANKAR",
    lastName: "CHAVAN",
    address:
      "98/7,ADITYA HSG SOCIETY*NEAR PARANJPE UDHYAN*BHANDUP(E)*BOMBAY 400042",
    fullName: "PANDURANG SHANKAR CHAVAN",
    saID: "SPR0729",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "CHOWDHURY",
    address: "AT-POLLYSREE*PO-SHYAMNAGAR*24 PARGANAS (N)743127",
    fullName: "LATE AJIT KUMAR CHOWDHURY",
    saID: "SPR0730",
  },
  {
    firstName: "BASUDEB",
    lastName: "CHOWDHURY",
    address: "MAKARDAHA SHITALATALA*PO-MAKARDAHA*DT-HOWRAH-711409",
    fullName: "BASUDEB CHOWDHURY",
    saID: "SPR0731",
  },
  {
    firstName: "LATE BHUSAN CHANDRA",
    lastName: "CHOWDHURY",
    address: "AT-NUTANGRAM.PO-TALKACHI*DIST-MURSHIDABAD.",
    fullName: "LATE BHUSAN CHANDRA CHOWDHURY",
    saID: "SPR0732",
  },
  {
    firstName: "LATE BIDIT BHUSAN",
    lastName: "CHAUDHURY",
    address: "64/4,GARFA MAIN ROAD*JADAVPUR*CALCUTTA 700075",
    fullName: "LATE BIDIT BHUSAN CHAUDHURY",
    saID: "SPR0733",
  },
  {
    firstName: "LATE BRAJOGOPAL",
    lastName: "CHOWDHURY",
    address: "(GOSWAMI)*91 EAST KAMALAPUR COLONY*DUM DUM*CALCUTTA-28.",
    fullName: "LATE BRAJOGOPAL CHOWDHURY",
    saID: "SPR0734",
  },
  {
    firstName: "CHANDAN",
    lastName: "CHOWDHURY",
    address: "RAMNAGAR-11*PO-RAMNAGAR SADAR*AGARTALA*WEST TRIPURA-799002.",
    fullName: "CHANDAN CHOWDHURY",
    saID: "SPR0735",
  },
  {
    firstName: "LATE GOURHARI",
    lastName: "CHOWDHURY",
    address: "VILL-BARIJHATI CHOWDHURYPARA*PO-CHANDITOLA*DIST.HOOGHLY",
    fullName: "LATE GOURHARI CHOWDHURY",
    saID: "SPR0736",
  },
  {
    firstName: "KANU RANJAN",
    lastName: "CHOUDHURY",
    address:
      "123/A,PURBACHAL*AMBIKA MUKHERJEE ROAD*PO-NATAGARH*24PARGANAS (NORTH)-700113.",
    fullName: "KANU RANJAN CHOUDHURY",
    saID: "SPR0737",
  },
  {
    firstName: "N HARISANKAR",
    lastName: "CHOUDHURY",
    address: "3RD LANE,BRAJA NAGAR*BERHAMPUR-760001*GANJAM*PAN-ABHPC8853D",
    fullName: "N HARISANKAR CHOUDHURY",
    saID: "SPR0738",
  },
  {
    firstName: "NIRENDRA KUMAR",
    lastName: "CHOWDHURY",
    address: "BIDHAN PALLI*PO.BARABEHARA*VIA-NABAGRAM*DIST-HOOGHLY-712246",
    fullName: "NIRENDRA KUMAR CHOWDHURY",
    saID: "SPR0739",
  },
  {
    firstName: "LATE NIRODE KUMAR",
    lastName: "CHOWDHURY",
    address: "MASUNDA*SAJIRHAT*PO-NEW BARRACKPUR*DIST-24 PGS.",
    fullName: "LATE NIRODE KUMAR CHOWDHURY",
    saID: "SPR0740",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "CHOWDHURY",
    address: "AT/PO.CHOWDWAR*CUTTACK.",
    fullName: "LATE NITYANANDA CHOWDHURY",
    saID: "SPR0741",
  },
  {
    firstName: "LATE NRIPENDRA CHANDRA",
    lastName: "CHOWDHURY",
    address: "AGARTALA SATSANG VIHAR*AGARTALA*TRIPURA.",
    fullName: "LATE NRIPENDRA CHANDRA CHOWDHURY",
    saID: "SPR0742",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "CHOWDHURY",
    address:
      "C/O CHOWDHURY TRADERS*HOSPITAL ROAD*PO/DIST-SILCHAR-788001*CACHAR*PAN-BBGPC5363J",
    fullName: "RABINDRA KUMAR CHOWDHURY",
    saID: "SPR0743",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "CHOWDHURY",
    address:
      "H-19,WORD-2,VILL-SEWLI (BORO KATALYA)*PO-SEWLI TELENIPARA*24 PARGANAS (NORTH)",
    fullName: "LATE SATYENDRA NATH CHOWDHURY",
    saID: "SPR0744",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "CHOWDHURY",
    address: "SWASTIPALLI GANGPUR *PO JOTERAM*DIST BURDWAN",
    fullName: "LATE SATYENDRA NATH CHOWDHURY",
    saID: "SPR0745",
  },
  {
    firstName: "LATE SUBODH",
    lastName: "CHOWDHURY",
    address: '"CHHABIKUNJA"*110A SETH BAGAN ROAD*DUMDUM*CALCUTTA-700030.',
    fullName: "LATE SUBODH CHOWDHURY",
    saID: "SPR0746",
  },
  {
    firstName: "LATE TANKESWAR",
    lastName: "CHUTIA",
    address: "SATSANG VIHAR *PO-NALIAPUL*DIBRUGARH",
    fullName: "LATE TANKESWAR CHUTIA",
    saID: "SPR0747",
  },
  {
    firstName: "LATE KISHORE H",
    lastName: "CLERK",
    address:
      "CLERK RENCHHED BHABAN*FIRST FLOOR,APP.HIRABAG AMBAWAR*AHMADABAD*GUJARAT",
    fullName: "LATE KISHORE H CLERK",
    saID: "SPR0748",
  },
  {
    firstName: "CHANDRA KUMAR",
    lastName: "DAIMARI",
    address: "KHANGKHLABARI*PO/DIST-UDALGURI-784509*(ASSAM)",
    fullName: "CHANDRA KUMAR DAIMARI",
    saID: "SPR0749",
  },
  {
    firstName: "LATE SASHI MOHAN",
    lastName: "DAIMARY",
    address: "DIGHLIPAR(MAGURMARI)*PO MUHASIPURA*DIST KAMRUP",
    fullName: "LATE SASHI MOHAN DAIMARY",
    saID: "SPR0750",
  },
  {
    firstName: "SMRITI RANJAN",
    lastName: "DAM",
    address: '"DAM\'S LODGE"KUNJABAN*PO-AGARTALA*TRIPURA.',
    fullName: "SMRITI RANJAN DAM",
    saID: "SPR0751",
  },
  {
    firstName: "HARIBANDHU",
    lastName: "DANI",
    address: '"DAYAL NIWAS"*PALACE GARDEN*SONEPUR 767017*ORISSA',
    fullName: "HARIBANDHU DANI",
    saID: "SPR0752",
  },
  {
    firstName: "LATE SARAT KUMAR",
    lastName: "DANI",
    address:
      "C/O SUSHANTA KUMAR DANI*S D C C BANK LTD*AT/PO-MUDIPARA*SAMBALPUR",
    fullName: "LATE SARAT KUMAR DANI",
    saID: "SPR0753",
  },
  {
    firstName: "LATE ABHAYA KUMAR",
    lastName: "DAS",
    address: "AT-KENDUPADA*PO-SANKARA*DIST-SUNDARGARH 770020",
    fullName: "LATE ABHAYA KUMAR DAS",
    saID: "SPR0754",
  },
  {
    firstName: "ACHYUT CHANDRA",
    lastName: "DAS",
    address: "AT-GALIYA*PO-BHAWANIPUR*BARPETA",
    fullName: "ACHYUT CHANDRA DAS",
    saID: "SPR0755",
  },
  {
    firstName: "ADITYA KUMAR",
    lastName: "DAS",
    address: "BATA NAGAR SATSANG VIHAR*BUDGEBUDGE TRANK ROAD*24 PARGANAS S",
    fullName: "ADITYA KUMAR DAS",
    saID: "SPR0756",
  },
  {
    firstName: "AJOY KUMAR",
    lastName: "DAS",
    address: "AT/PO.ALIABAD*VIA.INDUPUR*JAJPUR",
    fullName: "AJOY KUMAR DAS",
    saID: "SPR0757",
  },
  {
    firstName: "LATE AKSHAY KUMAR",
    lastName: "DAS",
    address:
      "QR NO.PWD/CT/17*SALES TAX COLONY,UDITNAGAR*ROURKELA 769012*SUNDARGARH.",
    fullName: "LATE AKSHAY KUMAR DAS",
    saID: "SPR0758",
  },
  {
    firstName: "AMAR KRISHNA",
    lastName: "DAS",
    address: "ELECT SUB-DIVISION*RADHAKISHOREPUR*TRIPURA-799120",
    fullName: "AMAR KRISHNA DAS",
    saID: "SPR0759",
  },
  {
    firstName: "AMULLYA",
    lastName: "DAS",
    address: "DHAJANAGAR*PO-GOKULPUR(UDAIPUR)*S.TRIPURA",
    fullName: "AMULLYA DAS",
    saID: "SPR0760",
  },
  {
    firstName: "LATE AMULLYA RANJAN",
    lastName: "DAS",
    address: "SATI JOYMATI ROAD*PO-REHABARI*GOUHATI-8*KAMRUP",
    fullName: "LATE AMULLYA RANJAN DAS",
    saID: "SPR0761",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "DAS",
    address: "SANTIPARA*PO&DIST-JALPAIGURI",
    fullName: "LATE ANANTA KUMAR DAS",
    saID: "SPR0762",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "DAS",
    address: "C/O DAYAL BHABAN*BIBEKANANDA ROAD*PO-SILCHAR*CACHAR 788007",
    fullName: "LATE ANIL KUMAR DAS",
    saID: "SPR0763",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "DAS",
    address: "VILL-CHOTTO BEHERA*PO-BARABEHERA*HOOGHLY-712246",
    fullName: "ANIL KUMAR DAS",
    saID: "SPR0764",
  },
  {
    firstName: "ASOK KUMAR",
    lastName: "DAS",
    address: "RANIPUR ADHIBESANKENDRA*PO.NARAYANPUR*DIST-PURULIA-723121",
    fullName: "ASOK KUMAR DAS",
    saID: "SPR0765",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "DAS",
    address: "JAGATBER, AMBAGAN NUTANPALLY*PO-SREEPALLY*BURDWAN-713103.",
    fullName: "LATE ASWINI KUMAR DAS",
    saID: "SPR0766",
  },
  {
    firstName: "BAJRAPANI",
    lastName: "DAS",
    address: "SATSANG VIHAR DISPUR*GAUHATI-781005*DIST-KAMRUP*(ASSAM).",
    fullName: "BAJRAPANI DAS",
    saID: "SPR0767",
  },
  {
    firstName: "LATE BANKIM CHANDRA",
    lastName: "DAS",
    address: "*SATSANG 814116",
    fullName: "LATE BANKIM CHANDRA DAS",
    saID: "SPR0768",
  },
  {
    firstName: "LATE BENODE BIHARI",
    lastName: "DAS",
    address:
      "BARASAT NAPARA*KALIBARI RD*SWASTI 47 NO KUTIR*PO-BARASAT*DIST-24 PGS.",
    fullName: "LATE BENODE BIHARI DAS",
    saID: "SPR0769",
  },
  {
    firstName: "LATE BINOD BIHARI",
    lastName: "DASSARKAR",
    address:
      "C/O PRAFULLA KUMAR DEY*1 NO. KALIGHAT ROAD*PO-GURIAHATI*DT-COOCH BEHAR",
    fullName: "LATE BINOD BIHARI DASSARKAR",
    saID: "SPR0770",
  },
  {
    firstName: "BHABENDRA KUMAR",
    lastName: "DAS",
    address: "AT-JARADA M.E.SCHOOL*PO-PEDAGADI*KAPTIPADA*MAYURBHANJ",
    fullName: "BHABENDRA KUMAR DAS",
    saID: "SPR0771",
  },
  {
    firstName: "LATE BHOLA NATH",
    lastName: "DAS",
    address: "PO MUSSALPUR,GAUHATI*DIST KAMRUP",
    fullName: "LATE BHOLA NATH DAS",
    saID: "SPR0772",
  },
  {
    firstName: "BHUPENDRA MOHAN",
    lastName: "DAS",
    address: "AT-PADMARPAR*PO-GIRISHGANJ BAZAR*KARIMAGANJ*PAN-ABRPD6539R",
    fullName: "BHUPENDRA MOHAN DAS",
    saID: "SPR0773",
  },
  {
    firstName: "LATE BHUTESH CHANDRA",
    lastName: "DAS",
    address: "VILL-BALIADANGA*PO-KALIACHAK*DIST-MALDA.",
    fullName: "LATE BHUTESH CHANDRA DAS",
    saID: "SPR0774",
  },
  {
    firstName: "LATE BIBEKANANDA",
    lastName: "DAS",
    address: "PO-KSHUDIRAMPALLI*VIA-ASHTI*GADCHIROLI*M.S. 442707",
    fullName: "LATE BIBEKANANDA DAS",
    saID: "SPR0775",
  },
  {
    firstName: "LATE BIBEKANANDA",
    lastName: "DAS",
    address:
      "LAXMI MANJI BHANDAR*AT-MADHUPATNA*PO-KALYANI NAGAR*CUTTACK 753013",
    fullName: "LATE BIBEKANANDA DAS",
    saID: "SPR0776",
  },
  {
    firstName: "LATE BIJOY KUMAR",
    lastName: "DAS",
    address:
      "MIG-II-19/5 ,BDA COLONY*CHANDRASEKHARPUR*BHUBANESWAR-751016*KHURDA",
    fullName: "LATE BIJOY KUMAR DAS",
    saID: "SPR0777",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "DAS",
    address: "STATION ROAD*PO-PANDUA*DIST-HOOGHLY-712 149.",
    fullName: "LATE BIMAL CHANDRA DAS",
    saID: "SPR0778",
  },
  {
    firstName: "BIMBADHAR",
    lastName: "DASH",
    address: "AT.MALLIKADEIPUR*PO.NAYAKANIDIHI*BALASORE.",
    fullName: "BIMBADHAR DASH",
    saID: "SPR0779",
  },
  {
    firstName: "BINOD LAL",
    lastName: "DAS",
    address: "SHASTRINAGAR*PO-CHOTO DUDHPATIL*DIST-SILCHAR-788002*ASSAM",
    fullName: "BINOD LAL DAS",
    saID: "SPR0780",
  },
  {
    firstName: "LATE BIRENDRA CHANDRA",
    lastName: "DAS",
    address:
      "V-BAKRIHAWAR PART-1(ANWARPAR)*PO.PALARPAR-788801*VIA.KALINAGAR*HAILAKANDI-788801.",
    fullName: "LATE BIRENDRA CHANDRA DAS",
    saID: "SPR0781",
  },
  {
    firstName: "LATE BISHNU CHARAN",
    lastName: "DAS",
    address: "AT/PO-BINJHARPUR*JAJPUR*ORISSA",
    fullName: "LATE BISHNU CHARAN DAS",
    saID: "SPR0782",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "DAS",
    address: "AT&PO.BADKULLA*NADIA",
    fullName: "LATE BISWANATH DAS",
    saID: "SPR0783",
  },
  {
    firstName: "BRAHMA PRASAD",
    lastName: "DAS",
    address: "68 SURYA SEN STREET*CALCUTTA-9.*PAN NO.AUXPD7506P",
    fullName: "BRAHMA PRASAD DAS",
    saID: "SPR0784",
  },
  {
    firstName: "BRAJABANDHU",
    lastName: "DAS",
    address:
      "ASST,SOIL CONSERVATION OFFICE*AT/PO-CHHATIA*JAJPUR-754023*PAN-CLXPD3153M",
    fullName: "BRAJABANDHU DAS",
    saID: "SPR0785",
  },
  {
    firstName: "BRINDABAN",
    lastName: "DAS",
    address: "VILL MAHARANIPUR*TELIAMURA*PO-CHAKMAGHAT*TRIPURA*PAN-ABZTD8134A",
    fullName: "BRINDABAN DAS",
    saID: "SPR0786",
  },
  {
    firstName: "LATE CHAKRAPANI",
    lastName: "DAS",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE CHAKRAPANI DAS",
    saID: "SPR0787",
  },
  {
    firstName: "LATE CHANARAM",
    lastName: "DAS",
    address: "GORESWAR*DIST-KAMRUP.",
    fullName: "LATE CHANARAM DAS",
    saID: "SPR0788",
  },
  {
    firstName: "LATE CHANARANJAN",
    lastName: "DAS",
    address: "VILL-DURGAPUR*PO-BELONIA*TRIPURA",
    fullName: "LATE CHANARANJAN DAS",
    saID: "SPR0789",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "DAS",
    address:
      "ASSTT.H.M.*RAMGUNA CHOUDHURYPARA H.S*BHATIMACHMARA*KANCHANPUR*TRIPURA NORTH-799270",
    fullName: "CHITTARANJAN DAS",
    saID: "SPR0790",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "DAS",
    address: "PO/VILL-BALIPIPLA*CACHAR*ASSAM",
    fullName: "LATE CHITTARANJAN DAS",
    saID: "SPR0791",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "DASH",
    address: "AT-PYARATANGI*PO.MAKARDAH A*DT.HOWRAH-711409",
    fullName: "CHITTARANJAN DASH",
    saID: "SPR0792",
  },
  {
    firstName: "DAMBARUDHAR",
    lastName: "DAS",
    address: '"ASHIRBAD"*CHAULIAGANJ(MATHASAHI)*CUTTACK-753004*PAN-AAQPD0185K',
    fullName: "DAMBARUDHAR DAS",
    saID: "SPR0793",
  },
  {
    firstName: "DEBNATH",
    lastName: "DAS",
    address: "NO 1 MAKHLA GOVT. COLONY*PO-MAKHLA*HOOGHLY-712245",
    fullName: "DEBNATH DAS",
    saID: "SPR0794",
  },
  {
    firstName: "DEBENDRA CHANDRA",
    lastName: "DAS",
    address: "V/PO-BASUGAON*GOALPARA.",
    fullName: "DEBENDRA CHANDRA DAS",
    saID: "SPR0795",
  },
  {
    firstName: "LATE DEBENDRA CHANDRA",
    lastName: "DAS",
    address: "PO-ALIPURDUAR COURT*DT-JALPAIGURI.",
    fullName: "LATE DEBENDRA CHANDRA DAS",
    saID: "SPR0796",
  },
  {
    firstName: "LATE DEBENDRA KUMAR",
    lastName: "DAS",
    address: "C/O GANESH NAG*BBC COLONY QRS NO-108/A PANDU*GUWAHATI 12*KAMRUP",
    fullName: "LATE DEBENDRA KUMAR DAS",
    saID: "SPR0797",
  },
  {
    firstName: "LATE DEBENDRA  NATH",
    lastName: "DAS",
    address: "AT-BEJRA*PO-SINGEE*DIST-BIRBHUM*WEST BENGAL*PIN-731 240",
    fullName: "LATE DEBENDRA  NATH DAS",
    saID: "SPR0798",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "DAS",
    address: "VILL-JAYANTIPUR*PO-GOPALPUR*DIST-KAMRUP.",
    fullName: "DEBENDRA NATH DAS",
    saID: "SPR0799",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "DAS",
    address: "VILL/PO-KANDUA*VIA-ANDULMOURI*DIST-HOWRAH",
    fullName: "LATE DEBENDRA NATH DAS",
    saID: "SPR0800",
  },
  {
    firstName: "DEBESWAR",
    lastName: "DAS",
    address: "VILL-BARKHALA*PO-K.P.BARKHALA*KAMRUP",
    fullName: "DEBESWAR DAS",
    saID: "SPR0801",
  },
  {
    firstName: "DHANESWAR",
    lastName: "DAS",
    address:
      "QRT NO-B/6,MUNICIPALITY COLONY*THORIASAHI*PO-MANGLABAG*CUTTACK-753001",
    fullName: "DHANESWAR DAS",
    saID: "SPR0802",
  },
  {
    firstName: "LATE DIBAKAR",
    lastName: "DAS",
    address: "AT-RANIPUR SATSANG KENDRA*PO-NARAYANPUR*DIST-PURULIA",
    fullName: "LATE DIBAKAR DAS",
    saID: "SPR0803",
  },
  {
    firstName: "DIBYENDU KUMAR",
    lastName: "DAS",
    address: "RANIPUR COLLIERY*PO.NARAYANPUR*PURULIA-723121*PAN CLLPD2705H",
    fullName: "DIBYENDU KUMAR DAS",
    saID: "SPR0804",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "DAS",
    address: "AT/PO- BURDWAN H.O.*DIST-BURDWAN-713101",
    fullName: "DILIP KUMAR DAS",
    saID: "SPR0805",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "DAS",
    address: "VILL-JAIPUR*PO-BAGURIKHUTI*VIA-NIGRIGULI*KAMRUP",
    fullName: "LATE DINESH CHANDRA DAS",
    saID: "SPR0806",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "DAS",
    address: "V-SHIBNAGAR*PO-GOPINATHPUR*DIST-MURSHIDABAD-742149",
    fullName: "DULAL CHANDRA DAS",
    saID: "SPR0807",
  },
  {
    firstName: "LATE DULAL KRISHNA",
    lastName: "DAS",
    address: "AT-NISCHINDA-NO-1*PO.GHOSHPARA*VIA-BALLY*DT.HOWRAH",
    fullName: "LATE DULAL KRISHNA DAS",
    saID: "SPR0808",
  },
  {
    firstName: "LATE GAGAN CHANDRA",
    lastName: "DAS",
    address: "AT/PO.BAMRA*SAMBALPUR",
    fullName: "LATE GAGAN CHANDRA DAS",
    saID: "SPR0809",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "DAS",
    address: "AT/PO.KANDUA*VIA-ANDULMOURI*DT.HOWRAH",
    fullName: "LATE GANESH CHANDRA DAS",
    saID: "SPR0810",
  },
  {
    firstName: "GANGADHAR LAL",
    lastName: "DAS",
    address: "AT/PO-TILKAMANJHI*BHAGALPUR-812001*BIHAR PAN NO.AQJPD6641D",
    fullName: "GANGADHAR LAL DAS",
    saID: "SPR0811",
  },
  {
    firstName: "LATE GIRISH CHANDRA",
    lastName: "DAS",
    address: "VILL-PANITOLA*PO-BALIPARA*DARRANG.",
    fullName: "LATE GIRISH CHANDRA DAS",
    saID: "SPR0812",
  },
  {
    firstName: "LATE GOBINDA CHANDRA",
    lastName: "DAS",
    address: "VILL BANANA*PO MANARKUCHI*DIST KAMRUP",
    fullName: "LATE GOBINDA CHANDRA DAS",
    saID: "SPR0813",
  },
  {
    firstName: "LATE GOKULANANDA",
    lastName: "DASH",
    address: "AT/PO.MOHANTIPARA*VIA.B.T.PUR*BHADRAK-756115",
    fullName: "LATE GOKULANANDA DASH",
    saID: "SPR0814",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "DAS",
    address:
      "DY LAND REFORMS COMMISSIONER*BOARD OF REVENUE ORISSA CUTTACK*CHANDNICHAWK*CUTTACK",
    fullName: "LATE GOPAL CHANDRA DAS",
    saID: "SPR0815",
  },
  {
    firstName: "LATE GOPENDRA CHANDRA",
    lastName: "DAS",
    address: "VILL&PO-MARJATKANDI*KARIMGANJ",
    fullName: "LATE GOPENDRA CHANDRA DAS",
    saID: "SPR0816",
  },
  {
    firstName: "LATE GOUR CHANDRA",
    lastName: "DAS",
    address:
      "GOUR MOTOR WORKS*G P ROAD,BENGENAKHAWA ALI*PO-GOLAGHAT 785621*JORHAT",
    fullName: "LATE GOUR CHANDRA DAS",
    saID: "SPR0817",
  },
  {
    firstName: "LATE GOURANGA CHANDRA",
    lastName: "DAS",
    address: "C/O-DILIP DAS*TALPUKUR DHAR*PO-CHANDANNAGAR*HOOGHLY (W.B.)",
    fullName: "LATE GOURANGA CHANDRA DAS",
    saID: "SPR0818",
  },
  {
    firstName: "LATE GOURANGA CHANDRA",
    lastName: "DAS",
    address: "VILL.PO. KADAMTALA*TRIPURA(N)-799261",
    fullName: "LATE GOURANGA CHANDRA DAS",
    saID: "SPR0819",
  },
  {
    firstName: "LATE GOURHARI",
    lastName: "DAS",
    address: "AT&PO-PARAHARIPUR *W/DINAJPUR",
    fullName: "LATE GOURHARI DAS",
    saID: "SPR0820",
  },
  {
    firstName: "LATE GOURHARI",
    lastName: "DAS",
    address: "PO-AMLAMETHI*DIST-24 PGS.",
    fullName: "LATE GOURHARI DAS",
    saID: "SPR0821",
  },
  {
    firstName: "GUNANIDHI",
    lastName: "DAS",
    address: "V-BABALPUR*PO.NANDIPUR*VIA.DASARATHPUR*CUTTACK",
    fullName: "GUNANIDHI DAS",
    saID: "SPR0822",
  },
  {
    firstName: "LATE HARAN CHANDRA",
    lastName: "DAS",
    address: "12 SURYA SEN ROAD*PO-NABAGRAM*DIST-HOOGHLY",
    fullName: "LATE HARAN CHANDRA DAS",
    saID: "SPR0823",
  },
  {
    firstName: "HARE KRUSHNA",
    lastName: "DAS",
    address: "VILL-DHANISHO*PO.BAIGANI*VIA.BALIKUDA*DIST-JAGATSINGHPUR 754108.",
    fullName: "HARE KRUSHNA DAS",
    saID: "SPR0824",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "DAS",
    address: "VILL&PO-DEBINAGAR*RAIGANJ*DT-WEST DINAJPUR.",
    fullName: "LATE HARENDRA NATH DAS",
    saID: "SPR0825",
  },
  {
    firstName: "HARIHAR",
    lastName: "DAS",
    address: "AT-SAKILADIHI*PO-RAIRANGPUR*MAYURBHANJ-757043*PAN NO-AFKPD0105J",
    fullName: "HARIHAR DAS",
    saID: "SPR0826",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "DAS",
    address: "VIL-RAMAPUR*PO-HASNABAD RAMAPUR*24-PARGANAS",
    fullName: "LATE HARIPADA DAS",
    saID: "SPR0827",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "DAS",
    address: "DUMURPUR*PO-DWARBASINI*SHATITHAN*DIST.HOOGHLY",
    fullName: "LATE HARIPADA DAS",
    saID: "SPR0828",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "DAS",
    address: "TOKANKOTA.JHARGAON*PO-MASKAPUKHURI*DARRANG.",
    fullName: "LATE HEM CHANDRA DAS",
    saID: "SPR0829",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "DAS",
    address: "DEBINAGAR*RAYGANJ*PURULIA",
    fullName: "LATE HIRALAL DAS",
    saID: "SPR0830",
  },
  {
    firstName: "HIRENDRA CHANDRA",
    lastName: "DAS",
    address:
      "SWASTI HOMOEO HALL*SWAMIJI ROAD(IDGA RD)*PO/DIST-SILCHAR-788001*PAN-BIOPD3820N",
    fullName: "HIRENDRA CHANDRA DAS",
    saID: "SPR0831",
  },
  {
    firstName: "HIRENDRA CHANDRA",
    lastName: "DAS",
    address: "ANNAPARISHODHINI SILPAM*VILL/PO-THELAMARA-784149*SONITPUR.",
    fullName: "HIRENDRA CHANDRA DAS",
    saID: "SPR0832",
  },
  {
    firstName: "HRIDESH RANJAN",
    lastName: "DAS",
    address:
      "NEAR D I C OFFICE*LONGAI ROAD*PO/DIST-KARIMGANJ-788712*PAN NO.BAPPD7465L",
    fullName: "HRIDESH RANJAN DAS",
    saID: "SPR0833",
  },
  {
    firstName: "INDUDYUTI",
    lastName: "DAS",
    address: "DY MANAGER(CIVIL)*NEEPCO LTD*SHILLONG-793003*MEGHALAYA",
    fullName: "INDUDYUTI DAS",
    saID: "SPR0834",
  },
  {
    firstName: "JAGNESWAR",
    lastName: "DAS",
    address: "65/L-5 ROAD NO-2,B H AREA*PO-KADMA*JAMSHEDPUR 831005*SINGHBHUM",
    fullName: "JAGNESWAR DAS",
    saID: "SPR0835",
  },
  {
    firstName: "JANARDAN",
    lastName: "DAS",
    address: "MATHURAPUR*KABIRCHAK*NEAR STATE BORING*DARBHANGA",
    fullName: "JANARDAN DAS",
    saID: "SPR0836",
  },
  {
    firstName: "LATE JANARDAN",
    lastName: "DAS",
    address: "*MUNGER",
    fullName: "LATE JANARDAN DAS",
    saID: "SPR0837",
  },
  {
    firstName: "LATE JIBAN CHANDRA",
    lastName: "DAS",
    address: "LALPATTY*SWARUPANANDA ROAD*PO-HOJAI*NAGAON 782435",
    fullName: "LATE JIBAN CHANDRA DAS",
    saID: "SPR0838",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "DAS",
    address: "VIL&P.O.BALIA*CHAKDAH*NADIA",
    fullName: "LATE JITENDRA NATH DAS",
    saID: "SPR0839",
  },
  {
    firstName: "JAGAI CHANDRA",
    lastName: "DAS",
    address: "PANCHASHEEL NAGAR WEST*PO-B M Y*DURG*CHHATISGARH 490025",
    fullName: "JAGAI CHANDRA DAS",
    saID: "SPR0840",
  },
  {
    firstName: "LATE JOGENDRA KUMAR",
    lastName: "DAS",
    address: "PO-GHOKSARDANGA*DIST-COOCHBEHAR.",
    fullName: "LATE JOGENDRA KUMAR DAS",
    saID: "SPR0841",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "DAS",
    address: "PO-PATHAR KANDI*RAMKRISHNA NAGAR*DT-CACHAR.",
    fullName: "LATE JOGESH CHANDRA DAS",
    saID: "SPR0842",
  },
  {
    firstName: "LATE JUDHISTHIR",
    lastName: "DAS",
    address: "AT/PO.ALABOL*VIA.BALIKUDA*CUTTACK.",
    fullName: "LATE JUDHISTHIR DAS",
    saID: "SPR0843",
  },
  {
    firstName: "JUDHISTHIR",
    lastName: "PRADHAN",
    address: "VILL-NAHUNI*PO-PEGARPARA*KENDRAPARA 754243",
    fullName: "JUDHISTHIR PRADHAN",
    saID: "SPR0844",
  },
  {
    firstName: "LATE JUGAL KISORE",
    lastName: "DAS",
    address: "VILL-NAWPARA ON ELAHIPUR ROAD *PO-NALIKUL*DIST.HOOGHLY",
    fullName: "LATE JUGAL KISORE DAS",
    saID: "SPR0845",
  },
  {
    firstName: "LATE JYOTISH CHANDRA",
    lastName: "DAS",
    address: "SIDING MAIBONG*P.O.MAIBONG*N.C.HILLS",
    fullName: "LATE JYOTISH CHANDRA DAS",
    saID: "SPR0846",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "DASH",
    address: "QR NO-E/500,SECTOR-9*C.D.A.*CUTTACK-753014",
    fullName: "KAMALA KANTA DASH",
    saID: "SPR0847",
  },
  {
    firstName: "KAMADEV",
    lastName: "DAS",
    address:
      "HOUSE NO.FL/170,BASANTI COLONY*PO-ROURKELA 769012*SUNDARGARH*PAN.AMIPD4167D",
    fullName: "KAMADEV DAS",
    saID: "SPR0848",
  },
  {
    firstName: "LATE KAMINI CHANDRA",
    lastName: "DAS",
    address: "SUKIMBARI T.E*PO KATHIATOLI*NOWGONG.",
    fullName: "LATE KAMINI CHANDRA DAS",
    saID: "SPR0849",
  },
  {
    firstName: "LATE KASHINATH",
    lastName: "DAS",
    address: "C/O LAL BAHADUR NEWAR*KHORAHAT T.E.*PO-RAJMAI*SIBSAGAR 785672",
    fullName: "LATE KASHINATH DAS",
    saID: "SPR0850",
  },
  {
    firstName: "KESAB CHANDRA",
    lastName: "DAS",
    address: "AT/PO-BARABISHA*DIST-ALIPURDUAR-736207.",
    fullName: "KESAB CHANDRA DAS",
    saID: "SPR0851",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "DAS",
    address: "BHUIARTILA*P.O.MELAGHAR*DIST-WEST TRIPURA-799115",
    fullName: "KESHAB CHANDRA DAS",
    saID: "SPR0852",
  },
  {
    firstName: "LATE KHALESWAR",
    lastName: "DASH",
    address: "AT/PO-SATSANG KENDRA KORAPUT*KORAPUT 763008.",
    fullName: "LATE KHALESWAR DASH",
    saID: "SPR0853",
  },
  {
    firstName: "KSHITISH CHANDRA",
    lastName: "DAS",
    address:
      "GANDACHERA BAZAR*PO-SARMA*AMARPUR*TRIPURA SOUTH-799284*PAN-BFHPD3072G",
    fullName: "KSHITISH CHANDRA DAS",
    saID: "SPR0854",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "DAS",
    address: "ADVOCATE*AT&PO-KOKRAJHAR*KOKRAJHAR 783370.",
    fullName: "LATE KIRAN CHANDRA DAS",
    saID: "SPR0855",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "DASH",
    address:
      "AT-UTEIPUR*PO-DEHURIANANDAPUR*VIA-DASARATHPUR*JAJPUR-755006*PAN-AMKPD5417G",
    fullName: "KRUSHNA CHANDRA DASH",
    saID: "SPR0856",
  },
  {
    firstName: "KUMUD BANDHU",
    lastName: "DAS",
    address: "JOGENDRANAGAR BANAKUMARI*PO-JOGENDRANAGAR*TRIPURA WEST-799004",
    fullName: "KUMUD BANDHU DAS",
    saID: "SPR0857",
  },
  {
    firstName: "LAL BEHARI",
    lastName: "DAS",
    address: "VILL-SUKANTA PALLY*PO&PS-MOGRA*HOOGHLY-712148",
    fullName: "LAL BEHARI DAS",
    saID: "SPR0858",
  },
  {
    firstName: "LATE LALIT KUMAR",
    lastName: "DAS",
    address: "VILL-PAIKPARA*PO-BONGAON*DIST-24 PARGANAS NORTH-743235.",
    fullName: "LATE LALIT KUMAR DAS",
    saID: "SPR0859",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "DAS",
    address: "M/S-SILPASREE*VILL&PO-SEHARA*DIST BARDDHAMAN*PAN-AKTPD8226E",
    fullName: "MADAN MOHAN DAS",
    saID: "SPR0860",
  },
  {
    firstName: "MADANMOHAN",
    lastName: "DAS",
    address:
      "SATSANG ADHIBESAN KENDRA*PO-DAMANPUR*VIA-ALIPURDUAR*JALPAIGURI-736123",
    fullName: "MADANMOHAN DAS",
    saID: "SPR0861",
  },
  {
    firstName: "MADHUKANTA",
    lastName: "DAS",
    address:
      "C/O SATSANG COLONY*BHAIROPATTI*PO-MILKICHAK*VIA-LAXMISAGAR*DARBHANGA    846009",
    fullName: "MADHUKANTA DAS",
    saID: "SPR0862",
  },
  {
    firstName: "MAHENDRA NARAYAN",
    lastName: "DAS",
    address:
      "C/O DR MADHUSUDAN NARAYAN*ROAD NO.2 DWARKAPURI*HANUMAN NAGAR*KANKARBAGH*PATNA 20",
    fullName: "MAHENDRA NARAYAN DAS",
    saID: "SPR0863",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "DAS",
    address: "SATSANG VIHAR,KAILASAHAR*PO-KAILASAHAR*TRIPURA (NORTH).",
    fullName: "LATE MANORANJAN DAS",
    saID: "SPR0864",
  },
  {
    firstName: "LATE MATHURA MOHAN",
    lastName: "DAS",
    address: "C/O FRAMING HOUSE*GAR ALI*JORHAT 785001*ASSAM",
    fullName: "LATE MATHURA MOHAN DAS",
    saID: "SPR0865",
  },
  {
    firstName: "MATINDRA",
    lastName: "DAS",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCHBEHAR-735303",
    fullName: "MATINDRA DAS",
    saID: "SPR0866",
  },
  {
    firstName: "LATE MIHIR CHANDRA",
    lastName: "DAS",
    address: "DUPLEX PATTY,KUMARPARA*PO-CHANDANNAGAR*HOOGHLY 712136",
    fullName: "LATE MIHIR CHANDRA DAS",
    saID: "SPR0867",
  },
  {
    firstName: "MIHIR KUMAR",
    lastName: "DAS",
    address:
      "HOUSE NO-11,GITANAGAR PATH*N.E.GITANAGAR*PO-NOONMATI*GUWAHATI-781020*DIST-KAMRUP",
    fullName: "MIHIR KUMAR DAS",
    saID: "SPR0868",
  },
  {
    firstName: "MOHIT LAL",
    lastName: "DAS",
    address: "SILCHAR THAKUR LANE*SATSANG ASHRAM RD*SILCHAR-788007*ASSAM",
    fullName: "MOHIT LAL DAS",
    saID: "SPR0869",
  },
  {
    firstName: "LATE MUKUNDA RAM",
    lastName: "DAS",
    address: "AT-KANTHANALI*PO-GUJARPUR*DIST-HOWRAH",
    fullName: "LATE MUKUNDA RAM DAS",
    saID: "SPR0870",
  },
  {
    firstName: "MURALIDHAR",
    lastName: "DAS",
    address: "AT+PO-JALESWAR*DIST-BALASORE-756032",
    fullName: "MURALIDHAR DAS",
    saID: "SPR0871",
  },
  {
    firstName: "NAGENDRA CHANDRA",
    lastName: "DAS",
    address: "VILL-CHHANA PUKUR PAR*PO-RASULPUR*BURDWAN 713151*PAN-BEMPD5230G",
    fullName: "NAGENDRA CHANDRA DAS",
    saID: "SPR0872",
  },
  {
    firstName: "NAGENDRA CHANDRA",
    lastName: "DAS",
    address: "VILL/PO-UTTAR BARBILL*KARBIANGLONG",
    fullName: "NAGENDRA CHANDRA DAS",
    saID: "SPR0873",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "DAS",
    address: "AT/PO-BETBARI*DIST KAMRUP*ASSAM",
    fullName: "LATE NAGENDRA NATH DAS",
    saID: "SPR0874",
  },
  {
    firstName: "NAKUL CHARAN",
    lastName: "DAS",
    address:
      '"SANTWANA"*CHAULIAGANJ-APPARNA NAGAR*UTKALMANI MARG*CUTTACK-753004',
    fullName: "NAKUL CHARAN DAS",
    saID: "SPR0875",
  },
  {
    firstName: "LATE NALINI KANT",
    lastName: "DAS",
    address:
      '"DAYAL BHABAN"*OLD D.C.BUNGLOW ROAD*EAST CHAWKIDINGI*PO&DT-DIBRUGARH-786001.',
    fullName: "LATE NALINI KANT DAS",
    saID: "SPR0876",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DAS",
    address: "SURVEILLANCE INSPECTOR,DEWAN*PO-DEWAN*CACHAR 788102",
    fullName: "LATE NANIGOPAL DAS",
    saID: "SPR0877",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DAS",
    address: "PO/VILL-PATULIA*VIA-TITAGARH*24 PARGANAS",
    fullName: "LATE NANIGOPAL DAS",
    saID: "SPR0878",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DAS",
    address: "S.T.BOOKSTALL,TRANSPORT*PO GAUHATI-8*DIST KAMRUP",
    fullName: "LATE NANIGOPAL DAS",
    saID: "SPR0879",
  },
  {
    firstName: "NARAYAN",
    lastName: "DAS",
    address:
      "ADVOCATE,BIJAYKUMAR CHOWMUHANI*KRISHNANAGAR,AGARTALA*TRIPURA-799001*PAN-ABZPD9215E",
    fullName: "NARAYAN DAS",
    saID: "SPR0880",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DASH",
    address:
      "TIRTHA NIWAS*JOGESWARI LANE*BALIGHAT CHECK GATE*MARINE DRIVE ROAD*PURI*ORISSA",
    fullName: "LATE NARAYAN CHANDRA DASH",
    saID: "SPR0881",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DAS",
    address: "AT-GANDHINAGAR*PO-PANIHATI*DIST-24 PGS (N)-700114",
    fullName: "LATE NARAYAN CHANDRA DAS",
    saID: "SPR0882",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DAS",
    address: '"TELEPHONE BHABAN"*PO-BELONIA*SOUTH TRIPURA',
    fullName: "LATE NARAYAN CHANDRA DAS",
    saID: "SPR0883",
  },
  {
    firstName: "NARENDRA CHANDRA",
    lastName: "DAS",
    address: "HEMAITPUR SATSANG*PO.RAMKANALI*PURULIA",
    fullName: "NARENDRA CHANDRA DAS",
    saID: "SPR0884",
  },
  {
    firstName: "LATE NARENDRA CHANDRA",
    lastName: "DAS",
    address: '"UTTAM BHAWAN"*AT/PO-BALADMARI*GOALPARA 783121',
    fullName: "LATE NARENDRA CHANDRA DAS",
    saID: "SPR0885",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "DAS",
    address:
      "HOUSE NO MIG-51*KALINGA NAGAR*AT/PO-PATRAPARA*BHUBANESWAR-19*KHURDA.",
    fullName: "LATE NARENDRA NATH DAS",
    saID: "SPR0886",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "DAS",
    address: "B SARKAR HOUSE*PO&DIST-DEOGHAR",
    fullName: "LATE NARESH CHANDRA DAS",
    saID: "SPR0887",
  },
  {
    firstName: "NATABAR",
    lastName: "DAS",
    address: "B/92 SECTOR-4*PO-ROURKELA-769002*DIST-SUNDARGARH*PAN-ABIPD2626C",
    fullName: "NATABAR DAS",
    saID: "SPR0888",
  },
  {
    firstName: "NIKHILENDU",
    lastName: "DAS",
    address: "SATSANG ROAD*PO/DIST-KARIMGANJ 788710*PAN-ACHPD2966K",
    fullName: "NIKHILENDU DAS",
    saID: "SPR0889",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "DAS",
    address: "E/74 BAGHAJATIN PALLI*PO-BADMASUR*CALCUTTA-86",
    fullName: "LATE NIRANJAN DAS",
    saID: "SPR0890",
  },
  {
    firstName: "NIRMAL RANJAN",
    lastName: "DAS",
    address: "BONGAIGAON",
    fullName: "NIRMAL RANJAN DAS",
    saID: "SPR0891",
  },
  {
    firstName: "NITYA GOPAL",
    lastName: "DAS",
    address: "PO+V-SARBANGAPUR*DIST-MURSHIDABAD-742174",
    fullName: "NITYA GOPAL DAS",
    saID: "SPR0892",
  },
  {
    firstName: "NITYANAND",
    lastName: "DAS",
    address: "VILL/PO-PANISAGAR*TRIPURA (NORTH) 799260",
    fullName: "NITYANAND DAS",
    saID: "SPR0893",
  },
  {
    firstName: "NITYANANDA",
    lastName: "DAS",
    address: "LAKE TOWN*PO-BHAKTINAGAR*JALPAIGURI-734007",
    fullName: "NITYANANDA DAS",
    saID: "SPR0894",
  },
  {
    firstName: "NRIPESH CHANDRA",
    lastName: "DAS",
    address:
      "BHASKAR NAGAR B.R.PATH,BYE LANE-1*PO-GUWAHATI-781018*KAMRUP*ASSAM",
    fullName: "NRIPESH CHANDRA DAS",
    saID: "SPR0895",
  },
  {
    firstName: "PADMA",
    lastName: "DAS",
    address: "DAHATI*PO BARPETA*SANTINAGAR*DIST-BARPETA-781314*ASSAM",
    fullName: "PADMA DAS",
    saID: "SPR0896",
  },
  {
    firstName: "PADMANAVA",
    lastName: "DAS",
    address: "AT.SHANTIPARA*PO.PRITIPUR*JAJPUR 755 013.",
    fullName: "PADMANAVA DAS",
    saID: "SPR0897",
  },
  {
    firstName: "PANKOJ KUMAR",
    lastName: "DAS",
    address: "107/1 RAJA RAJENDRALAL MITRA RD*CALCUTTA-700010.",
    fullName: "PANKOJ KUMAR DAS",
    saID: "SPR0898",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DAS",
    address:
      "SANKAR NAGAR,BYE LANE-7*HOUSE NO-2,PANDU*PO-GUWAHATI-781012*DIST-KAMRUP*ASSAM",
    fullName: "PARESH CHANDRA DAS",
    saID: "SPR0899",
  },
  {
    firstName: "PARESH RANJAN",
    lastName: "DAS",
    address: "MASTER WATCH CO*PO-MARGHERITA*DIST-DIBRUGARH 786181",
    fullName: "PARESH RANJAN DAS",
    saID: "SPR0900",
  },
  {
    firstName: "LATE PHULLA CHANDRA",
    lastName: "DAS",
    address: "CHATIANGURI NO 2*PO-CHATIANGURI*DT-GOALPARA.",
    fullName: "LATE PHULLA CHANDRA DAS",
    saID: "SPR0901",
  },
  {
    firstName: "PRABHAT CHANDRA",
    lastName: "DAS",
    address: "SATSANG VIHAR RANGIA*PO-RANGIA*DIST-KAMRUP-781354*PAN-ACEPD2163R",
    fullName: "PRABHAT CHANDRA DAS",
    saID: "SPR0902",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "DAS",
    address: "*SATSANG.DEOGHAR",
    fullName: "LATE PRAFULLA KUMAR DAS",
    saID: "SPR0903",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "DAS",
    address: "J224,PAHARPUR ROAD*CALCUTTA-700024",
    fullName: "LATE PRAFULLA KUMAR DAS",
    saID: "SPR0904",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "DAS",
    address: "AT-KANTABANIA*PO-CHHATRAPADA*VIA-PRITIPUR*JAJPUR-755013",
    fullName: "PRAFULLA KUMAR DAS",
    saID: "SPR0905",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "DAS",
    address: "MAIBANG BAZAR*MAIBONG*N.C.HILLS",
    fullName: "LATE PRAFULLA KUMAR DAS",
    saID: "SPR0906",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "DAS",
    address: "AT+PO-CHANDPARA*DIST-24 PARGANAS [N].",
    fullName: "LATE PRAFULLA KUMAR DAS",
    saID: "SPR0907",
  },
  {
    firstName: "LATE PRAFULLA RANJAN",
    lastName: "DAS",
    address: "1/243 NAKTALA*CALCUTTA-47",
    fullName: "LATE PRAFULLA RANJAN DAS",
    saID: "SPR0908",
  },
  {
    firstName: "PRAKASH RANJAN",
    lastName: "DAS",
    address: "MAJHER PARA*PO-KRISHNANAGAR*DIST-NADIA-741101",
    fullName: "PRAKASH RANJAN DAS",
    saID: "SPR0909",
  },
  {
    firstName: "LATE PRAMESH CHANDRA",
    lastName: "DAS",
    address: "RLY.STN.KALINAGAR-*PO KALINAGAR-788801*CACHAR",
    fullName: "LATE PRAMESH CHANDRA DAS",
    saID: "SPR0910",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "DAS",
    address:
      '"PRITY NIVAS"*C.D.A.ROAD*AT/PO-BIDANASI*CUTTACK-753014*PAN-AEKPD2484H',
    fullName: "PRAMOD KUMAR DAS",
    saID: "SPR0911",
  },
  {
    firstName: "PRANAB KUMAR",
    lastName: "DAS",
    address: "PANDURANG APT.*121 KANSAI,AMBARNATH EAST*DT-THANE*M.S.-421501",
    fullName: "PRANAB KUMAR DAS",
    saID: "SPR0912",
  },
  {
    firstName: "PRANESH",
    lastName: "DAS",
    address: "KANDARPAPUR BOALIA*PO-GARIA*24PARGANAS (SOUTH)-700084",
    fullName: "PRANESH DAS",
    saID: "SPR0913",
  },
  {
    firstName: "LATE PRANESH CHANDRA",
    lastName: "DAS",
    address: "PROJECT E ROAD*PO&DIST-HAILAKANDI.",
    fullName: "LATE PRANESH CHANDRA DAS",
    saID: "SPR0914",
  },
  {
    firstName: "PRATAP CHANDRA",
    lastName: "DAS",
    address:
      "NO-2, SATSANG VIHAR RD*SHANKARA NAGAR*PO-PAMMAL*CHENNAI 600 075*PAN-ALSPP3601K",
    fullName: "PRATAP CHANDRA DAS",
    saID: "SPR0915",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "DAS",
    address: "AT-RAMAPUR*PO-HASNABAD*DT-24PARGANAS (NORTH)-743426",
    fullName: "PRAVAT KUMAR DAS",
    saID: "SPR0916",
  },
  {
    firstName: "LATE PRIYATOSH",
    lastName: "DAS",
    address: "4/17,AZADNAGAR*KOLKATA 700040",
    fullName: "LATE PRIYATOSH DAS",
    saID: "SPR0917",
  },
  {
    firstName: "LATE RAI MOHAN",
    lastName: "DAS",
    address: "MITALI CLINIC LABOROTORY*X RAY CLINIC*PO TEZPUR*DARRANG.",
    fullName: "LATE RAI MOHAN DAS",
    saID: "SPR0918",
  },
  {
    firstName: "LATE RAJANI MOHAN",
    lastName: "DAS",
    address: "NEAR SATSANG ASHRAM*(SUKANTA SARANI)*SILCHAR-7*CACHAR.",
    fullName: "LATE RAJANI MOHAN DAS",
    saID: "SPR0919",
  },
  {
    firstName: "LATE RAMCHARAN",
    lastName: "DAS",
    address: "PO-AHARAMPUR*DIST-24 PGS.",
    fullName: "LATE RAMCHARAN DAS",
    saID: "SPR0920",
  },
  {
    firstName: "LATE RANAJIT KUMAR",
    lastName: "DAS",
    address: "VILL&PO-HIMANIPOTA(PASCHIMPARA)*GANGAPUR*NADIA",
    fullName: "LATE RANAJIT KUMAR DAS",
    saID: "SPR0921",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "DAS",
    address: "VILL.HATIMARA(KALIBARI RD)*PO.BIHARA BAZAR*CACHAR-788 817",
    fullName: "RANJIT KUMAR DAS",
    saID: "SPR0922",
  },
  {
    firstName: "LATE RASAMOY",
    lastName: "DAS",
    address: "NANDAN PALLY*PO-CHEBRI*TRIPURA",
    fullName: "LATE RASAMOY DAS",
    saID: "SPR0923",
  },
  {
    firstName: "LATE RASIK CHANDRA",
    lastName: "DAS",
    address: "KANAKPUR ROAD*PO SILCHAR-5*CACHAR.",
    fullName: "LATE RASIK CHANDRA DAS",
    saID: "SPR0924",
  },
  {
    firstName: "LATE RATISH CHANDRA",
    lastName: "DAS",
    address: "O/O THE T D M SHILLONG*PO-SHILLONG*MEGHALAYA 793 001",
    fullName: "LATE RATISH CHANDRA DAS",
    saID: "SPR0925",
  },
  {
    firstName: "RATNAKAR",
    lastName: "DASH",
    address:
      "AT-NUA ALTUMA*NEAR ELECTRICAL OFFICE*AT&PO-KAMAKSHYANAGAR*DIST-DHENKANAL 759018",
    fullName: "RATNAKAR DASH",
    saID: "SPR0926",
  },
  {
    firstName: "LATE REBATI MOHAN",
    lastName: "DAS",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "LATE REBATI MOHAN DAS",
    saID: "SPR0927",
  },
  {
    firstName: "LATE ROHINI RANJAN",
    lastName: "DAS",
    address: "C/O ATAL JEWELLERS*NETAJI ROAD*PO-DHEKIAJULI*SONITPUR 784110",
    fullName: "LATE ROHINI RANJAN DAS",
    saID: "SPR0928",
  },
  {
    firstName: "SACHINDRA NATH",
    lastName: "DAS",
    address:
      "VILL-HAIPAT*PO-DEBRA BAZAR*PASCHIM MEDINIPUR 721126*PAN-ADDPD0337P",
    fullName: "SACHINDRA NATH DAS",
    saID: "SPR0929",
  },
  {
    firstName: "SAILENDRA KUMAR",
    lastName: "DAS",
    address: "D.GOLAGHATIA BASTI*PO HOJAI*NAGAON-782435",
    fullName: "SAILENDRA KUMAR DAS",
    saID: "SPR0930",
  },
  {
    firstName: "SAJAL KANTI",
    lastName: "DAS",
    address: "SATSANG VIHAR*SILCHAR ROAD*PO/DIST-SILCHAR-788004*ASSAM",
    fullName: "SAJAL KANTI DAS",
    saID: "SPR0931",
  },
  {
    firstName: "LATE SAKTI KUMAR",
    lastName: "DAS",
    address: "VILL/PO-SUNITI*KENDRAPARA-754 224*ORISSA",
    fullName: "LATE SAKTI KUMAR DAS",
    saID: "SPR0932",
  },
  {
    firstName: "SAMBHU NATH",
    lastName: "DAS",
    address: "VIVEKANANDA PALLY*PO-BIDHAN NAGAR*DIST-DARJEELING",
    fullName: "SAMBHU NATH DAS",
    saID: "SPR0933",
  },
  {
    firstName: "LATE SAMBHUNATH",
    lastName: "DAS",
    address: "NILKUTHI*RAIL GHUMTI*PO&DT-COOCHBEHAR",
    fullName: "LATE SAMBHUNATH DAS",
    saID: "SPR0934",
  },
  {
    firstName: "LATE SANKAR PRASAD",
    lastName: "DAS",
    address: "RADHA KISHOREPUR*DT-TRIPURA.",
    fullName: "LATE SANKAR PRASAD DAS",
    saID: "SPR0935",
  },
  {
    firstName: "SANTU CHANDRA",
    lastName: "DAS",
    address: "AUPHULIA T.GARDEN*PO-MORANHAT*DIBRUGARH-785670*ASSAM",
    fullName: "SANTU CHANDRA DAS",
    saID: "SPR0936",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "DAS",
    address: "VILL-SABALPUR*PO-BENTAKAR*CUTTACK",
    fullName: "SARAT CHANDRA DAS",
    saID: "SPR0937",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "DAS",
    address:
      '"SHUBHA NIVAS"*PLOT NO-E-1195/1,SEC-6*MARKAT NAGAR,C.D.A*CUTTACK-753014',
    fullName: "SARAT CHANDRA DAS",
    saID: "SPR0938",
  },
  {
    firstName: "LATE SARBESWAR",
    lastName: "DAS",
    address:
      "J.A.O.JALESWAR BLOCK*AT&PO.STATION BAZAR,JALESWAR*BALASORE-756032",
    fullName: "LATE SARBESWAR DAS",
    saID: "SPR0939",
  },
  {
    firstName: "SAROJ KANTA",
    lastName: "DAS",
    address: "SATSANG THAKURBARI*CHAKRATIRTHA ROAD*PURI-752002*PAN-CNPPD7823Q",
    fullName: "SAROJ KANTA DAS",
    saID: "SPR0940",
  },
  {
    firstName: "LATE SASANKA MOHAN",
    lastName: "DAS",
    address: "C/O DINABANDHU DAS*STATIONERY SHOP*PO-BENOVANAGAR*GUAHATI 781018",
    fullName: "LATE SASANKA MOHAN DAS",
    saID: "SPR0941",
  },
  {
    firstName: "LATE SASHI MOHAN",
    lastName: "DAS",
    address: "TELIAMURA BAZAR*PO.TELIAMURA*TRIPURA",
    fullName: "LATE SASHI MOHAN DAS",
    saID: "SPR0942",
  },
  {
    firstName: "LATE SASHI MOHAN",
    lastName: "DAS",
    address: '"KIRTANIA" DASHMIGHAT*PO-TELIAMURA 799205*TRIPURA (W)',
    fullName: "LATE SASHI MOHAN DAS",
    saID: "SPR0943",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "DAS",
    address: "AT+PO-EZARBARI*KRISHNANAGAR*DIST-NAGAON*ASSAM",
    fullName: "LATE SATISH CHANDRA DAS",
    saID: "SPR0944",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "DAS",
    address: "TELEPHONE BHABAN,AGARTALA*TRIPURA WEST-799001",
    fullName: "SATISH CHANDRA DAS",
    saID: "SPR0945",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "DAS",
    address: "K D'S COT*BELABAGAN*DEOGHAR",
    fullName: "SATISH CHANDRA DAS",
    saID: "SPR0946",
  },
  {
    firstName: "LATE SATISHCHANDRA",
    lastName: "DAS",
    address: "FISHARY OFFICER*DUMKA",
    fullName: "LATE SATISHCHANDRA DAS",
    saID: "SPR0947",
  },
  {
    firstName: "LATE SATYENDRA KUMAR",
    lastName: "DAS",
    address: "SWASTI PHARMACY*PO LANKA*NOWGONG.",
    fullName: "LATE SATYENDRA KUMAR DAS",
    saID: "SPR0948",
  },
  {
    firstName: "SATYENDRA NATH",
    lastName: "DAS",
    address:
      "AT/PO-SRIDHARNAGAR*DIST-24 PRAGANAS (SOUTH)-743371*PAN ACMPD1056F",
    fullName: "SATYENDRA NATH DAS",
    saID: "SPR0949",
  },
  {
    firstName: "SATYENDRA NATH",
    lastName: "DAS",
    address: "PO/VILL-BHOLARDABRI*JALPAIGURI-736123",
    fullName: "SATYENDRA NATH DAS",
    saID: "SPR0950",
  },
  {
    firstName: "LATE SHANTI RANJAN",
    lastName: "DAS",
    address: "1A/4, MARCONI AVENUE*PO-DURGAPUR-5*DIST-BURDWAN.",
    fullName: "LATE SHANTI RANJAN DAS",
    saID: "SPR0951",
  },
  {
    firstName: "SHANTI RANJAN",
    lastName: "DAS",
    address:
      "# 48(OLD NO-27),NR LAY OUT*OFF WIND TUNNEL ROAD*MURUGESHPALLY*BANGALORE-560017.",
    fullName: "SHANTI RANJAN DAS",
    saID: "SPR0952",
  },
  {
    firstName: "SIBA PRASAD",
    lastName: "DAS",
    address: "AT-SANTOSHPUR*PO-ADIKASHIPUR*DIST-24 PARGANAS (NORTH)-743248",
    fullName: "SIBA PRASAD DAS",
    saID: "SPR0953",
  },
  {
    firstName: "SIBESHWAR",
    lastName: "DAS",
    address: "NEW COLONY*DHARAMPUR*SAMASTIPUR 848101",
    fullName: "SIBESHWAR DAS",
    saID: "SPR0954",
  },
  {
    firstName: "LATE SITENDU KUMAR",
    lastName: "DAS",
    address: "SATSANG VIHAR,LANGAI ROAD*PO+DIST-KARIMGONJ.",
    fullName: "LATE SITENDU KUMAR DAS",
    saID: "SPR0955",
  },
  {
    firstName: "SREECHARAN",
    lastName: "DAS",
    address: "AT/PO-KAKATPUR*PURI-752108",
    fullName: "SREECHARAN DAS",
    saID: "SPR0956",
  },
  {
    firstName: "LATE SUBARNA BINDU",
    lastName: "DAS",
    address: "RLY.GODOWN ROAD*PO DURLAVCHERRA*CACHAR.",
    fullName: "LATE SUBARNA BINDU DAS",
    saID: "SPR0957",
  },
  {
    firstName: "LATE SUBODH RANJAN",
    lastName: "DAS",
    address:
      "JOYGURU BHABAN*MICHEL MADHUSUDAN SARANI*(UTTAR GHOSHPARA)*PO-GHOSHPARA,BALLY*HOWRAH",
    fullName: "LATE SUBODH RANJAN DAS",
    saID: "SPR0958",
  },
  {
    firstName: "LATE SUDHANGSU KUMAR",
    lastName: "DAS",
    address:
      "C/O G P GHOSH*PRASAD KUTIR*SAHEBZADA BAZAR*PO/DIST-CUTTACK 753002",
    fullName: "LATE SUDHANGSU KUMAR DAS",
    saID: "SPR0959",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "DAS",
    address: "'SASHI SADAN'*LAXMIPUR BAZAR ROAD*PO+DIST-KARIMGANJ. 788710",
    fullName: "LATE SUDHIR CHANDRA DAS",
    saID: "SPR0960",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "DAS",
    address: "AT-PATHARPURA*PO-JALESWAR*BALASORE.",
    fullName: "LATE SUDHIR KUMAR DAS",
    saID: "SPR0961",
  },
  {
    firstName: "SUHASH CHANDRA",
    lastName: "DAS",
    address: "QR NO L-33,H.A.L.*SUNABEDA*KORAPUT-763001",
    fullName: "SUHASH CHANDRA DAS",
    saID: "SPR0962",
  },
  {
    firstName: "SUJIT KUMAR",
    lastName: "DAS",
    address:
      "26/C,SURATH CH BANERJEE LANE*PO-KONNAGAR*HOOGHLY-712235*PAN-ADIPD7239D",
    fullName: "SUJIT KUMAR DAS",
    saID: "SPR0963",
  },
  {
    firstName: "LATE SUKDEB",
    lastName: "DAS",
    address: "PO-ANUGULIE*VIA BALADEVJIW*DIST CUTTACK",
    fullName: "LATE SUKDEB DAS",
    saID: "SPR0964",
  },
  {
    firstName: "LATE SUKHENDU KUMAR",
    lastName: "DAS",
    address: "FIRE OFFICER*OIL INDIA LTD*PO&DT-DIBRUGARH",
    fullName: "LATE SUKHENDU KUMAR DAS",
    saID: "SPR0965",
  },
  {
    firstName: "SUKUMAR",
    lastName: "DAS",
    address: "HACHHANNAGAR*PO/DIST SUNAMGANJ*BANGLADESH",
    fullName: "SUKUMAR DAS",
    saID: "SPR0966",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "DAS",
    address: "KHUDIRAM PALLY*BHOLARDABRI EAST*PO-BHOLARDABRI*JALPAIGURI",
    fullName: "LATE SUNIL KUMAR DAS",
    saID: "SPR0967",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "DAS",
    address: "VILL-BALARAMBATI*PASCHIMPARA*PO-BALARAMBATI*HOOGHLY",
    fullName: "LATE SUNIL KUMAR DAS",
    saID: "SPR0968",
  },
  {
    firstName: "SUNILMOHAN",
    lastName: "DAS",
    address: "S.E.S.OFFICE,A.S.E.B*B.T.P.S PO-SALAKATI*GOALPARA",
    fullName: "SUNILMOHAN DAS",
    saID: "SPR0969",
  },
  {
    firstName: "LATE SURENDRA NATH",
    lastName: "DAS",
    address: "PO-NAGAON*I.T.I.ROAD*NOWGANG*ASSAM",
    fullName: "LATE SURENDRA NATH DAS",
    saID: "SPR0970",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "DAS",
    address: "80 NO FEEDAR ROAD*BELGHARIA*CALCUTTA 56",
    fullName: "LATE SURESH CHANDRA DAS",
    saID: "SPR0971",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "DAS",
    address:
      "MISSION PARA*BEHIND DEYS' NURSING CLINIC*PO/DIST-TINSUKIA 786125*ASSAM",
    fullName: "SURESH CHANDRA DAS",
    saID: "SPR0972",
  },
  {
    firstName: "LATE TARAK CHANDRA",
    lastName: "DAS",
    address: "KHANPUR SATSANG KENDRA*PO-KHANPUR*HOOGHLY",
    fullName: "LATE TARAK CHANDRA DAS",
    saID: "SPR0973",
  },
  {
    firstName: "TARAK CHANDRA",
    lastName: "DAS",
    address: "AT/PO-POLBA*DIST.HOOGHLY-712148",
    fullName: "TARAK CHANDRA DAS",
    saID: "SPR0974",
  },
  {
    firstName: "LATE TARAPADA",
    lastName: "DAS",
    address: "H.NO-17/1, CHAITANYAVITA LANE*PO-HALISAHAR*24 PARGANAS.",
    fullName: "LATE TARAPADA DAS",
    saID: "SPR0975",
  },
  {
    firstName: "TARASHANKER",
    lastName: "DAS",
    address: "SATSANG KENDRA TURA*P.O.TURA*GARO HILLS-794001",
    fullName: "TARASHANKER DAS",
    saID: "SPR0976",
  },
  {
    firstName: "TARUN KANTI",
    lastName: "DAS",
    address: "VILL-BUDHITIA, KALITALA*PO-TELIAMURA*WEST TRIPURA-799205.",
    fullName: "TARUN KANTI DAS",
    saID: "SPR0977",
  },
  {
    firstName: "LATE THAKUR NATH",
    lastName: "DAS",
    address: "VILL&PO-BALOUR*VIA-MANIGACHI*DARBHANGA 847 422",
    fullName: "LATE THAKUR NATH DAS",
    saID: "SPR0978",
  },
  {
    firstName: "LATE UPENDRA KUMAR",
    lastName: "DAS",
    address: "AT&PO-RAGHUNATHPUR*MURSHIDABAD",
    fullName: "LATE UPENDRA KUMAR DAS",
    saID: "SPR0979",
  },
  {
    firstName: "LATE ANATH BANDHU",
    lastName: "DASBAIRAGYA",
    address: "VILL/PO-PANCHDARA*DIST-NADIA",
    fullName: "LATE ANATH BANDHU DASBAIRAGYA",
    saID: "SPR0980",
  },
  {
    firstName: "LATE NALINI RANJAN",
    lastName: "DASCHOUDHURY",
    address: "OLD KALIBARI LANE*KRISHNA NAGAR*PO-AGARTALA*TRIPURA 799001",
    fullName: "LATE NALINI RANJAN DASCHOUDHURY",
    saID: "SPR0981",
  },
  {
    firstName: "LATE JOY KUMAR",
    lastName: "DASPURAKAYASTHA",
    address: "DIBRUGARH SATSANG VIHAR*PO/DIST-DIBRUGARH.",
    fullName: "LATE JOY KUMAR DASPURAKAYASTHA",
    saID: "SPR0982",
  },
  {
    firstName: "LATE PHANI BHUSAN",
    lastName: "DASSARMA",
    address: "VILL&PO-SUBARNAPUR*DT-NADIA.",
    fullName: "LATE PHANI BHUSAN DASSARMA",
    saID: "SPR0983",
  },
  {
    firstName: "LATE AMULLYA KUMAR",
    lastName: "DASSHARMA",
    address: "68,SURYA SEN STREET*CALCUTTA-700009",
    fullName: "LATE AMULLYA KUMAR DASSHARMA",
    saID: "SPR0984",
  },
  {
    firstName: "SUNIL",
    lastName: "DASBISWAS",
    address: "VILL-NORTH MAZIDKHANA*PO-TATPARA*JALPAIGURI-736208",
    fullName: "SUNIL DASBISWAS",
    saID: "SPR0985",
  },
  {
    firstName: "ATUL",
    lastName: "DASGUPTA",
    address: "VILL-82,MILES*PO-KANCHANCHERRA*TRIPURA 799264",
    fullName: "ATUL DASGUPTA",
    saID: "SPR0986",
  },
  {
    firstName: "DEEP NARAYAN",
    lastName: "DASGUPTA",
    address: "PO/VILL-MATHURAPUR*MALDA-732203*PAN NO.BDVPG1788A",
    fullName: "DEEP NARAYAN DASGUPTA",
    saID: "SPR0987",
  },
  {
    firstName: "LATE NALINI RANJAN",
    lastName: "DASGUPTA",
    address: "10,BAIDIKPARA LANE*PO-HINDMOTOR*DIST-HOOGHLY.",
    fullName: "LATE NALINI RANJAN DASGUPTA",
    saID: "SPR0988",
  },
  {
    firstName: "LATE NIRMAL",
    lastName: "DASGUPTA",
    address: "222,MIRAZA OSTAGAR LANE*FLAT NO-12*CALCUTTA 17",
    fullName: "LATE NIRMAL DASGUPTA",
    saID: "SPR0989",
  },
  {
    firstName: "NIRMAL KANTI",
    lastName: "DASGUPTA",
    address: "UTTARJHAPARDAHA*PO.DOMJUR*HOWRAH-711405",
    fullName: "NIRMAL KANTI DASGUPTA",
    saID: "SPR0990",
  },
  {
    firstName: "PRANAB",
    lastName: "DASGUPTA",
    address: "*SATSANG.DEOGHAR.",
    fullName: "PRANAB DASGUPTA",
    saID: "SPR0991",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "DASGUPTA",
    address: "H/O LT GOPAL SANYAL*BABU PARA*PO/DIST-JALPAIGURI-735101",
    fullName: "SAMIR KUMAR DASGUPTA",
    saID: "SPR0992",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "DASGUPTA",
    address: "3 A/30,MAITRI NAGAR*BHILAI*DIST-DURG*CHHATTISGARH-490001",
    fullName: "SAMIR KUMAR DASGUPTA",
    saID: "SPR0993",
  },
  {
    firstName: "SHIBABRATA",
    lastName: "DASGUPTA",
    address: "C/O SRI SRI BORDA*SATSANG 814116",
    fullName: "SHIBABRATA DASGUPTA",
    saID: "SPR0994",
  },
  {
    firstName: "ANIRUDDHA",
    lastName: "DASH",
    address: "AT-F.C.I.MINABAZAR*PO-VIKRAMPUR*DIST-ANGUL 759106*ODISHA",
    fullName: "ANIRUDDHA DASH",
    saID: "SPR0995",
  },
  {
    firstName: "LATE BANSHIDHAR",
    lastName: "DASH",
    address: "AT/PO.SIDHAL*CUTTACK",
    fullName: "LATE BANSHIDHAR DASH",
    saID: "SPR0996",
  },
  {
    firstName: "LATE NABA KISHORE",
    lastName: "DASH",
    address:
      "BHARADWAJ ASHRAM*VILL-OKALA*PO-KOTHAMUL*VIA-RAHAMA*JAGATSINGHPUR-754140*ODISHA",
    fullName: "LATE NABA KISHORE DASH",
    saID: "SPR0997",
  },
  {
    firstName: "RAMA KANTA",
    lastName: "DASH",
    address: "AT/PO-KALYANI NAGAR*CUTTACK 753013",
    fullName: "RAMA KANTA DASH",
    saID: "SPR0998",
  },
  {
    firstName: "RANJIT",
    lastName: "DAS",
    address: "SATSANG NAGAR(NETAJI LANE)*PO/DIST-SILCHAR-788007*ASSAM",
    fullName: "RANJIT DAS",
    saID: "SPR0999",
  },
  {
    firstName: "SARADA PRASANNA",
    lastName: "DASH",
    address:
      "V-SAHAS GOBINDAPUR*PO.GOBINDAPUR*VIA.KANAKPUR*JAGATSINGHPUR-754136",
    fullName: "SARADA PRASANNA DASH",
    saID: "SPR1000",
  },
  {
    firstName: "SITAKANTA",
    lastName: "DASH",
    address:
      "304,DATTATREYA HERITAGE*PO-MAHALAXMI VIHAR*BHUBANESWAR-751029*PAN-AHLPD6698P",
    fullName: "SITAKANTA DASH",
    saID: "SPR1001",
  },
  {
    firstName: "LATE GATI GOBINDA",
    lastName: "DASMAHANTA",
    address: "VILL-NETAJEEPALLY*PO-SAINTHIA*DIST-BIRBHUM",
    fullName: "LATE GATI GOBINDA DASMAHANTA",
    saID: "SPR1002",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "DASPURAKAYASTHA",
    address: "VILL-INATHPUR*PO-AMBOKHANA*KARIMGANJ.",
    fullName: "LATE KUMUD RANJAN DASPURAKAYASTHA",
    saID: "SPR1003",
  },
  {
    firstName: "LATE SACHINDRA CHANDRA",
    lastName: "DASPARASAVSARMA",
    address: "JORAI UTTAR RAMPUR*PO-JORAI*COOCHBEHAR",
    fullName: "LATE SACHINDRA CHANDRA DASPARASAVSARMA",
    saID: "SPR1004",
  },
  {
    firstName: "LATE PRAMOD RANJAN",
    lastName: "DASPURKAYSTHA",
    address: "VILL/P.O.HAFLONG*N.C.HILLS",
    fullName: "LATE PRAMOD RANJAN DASPURKAYSTHA",
    saID: "SPR1005",
  },
  {
    firstName: "LATE JAGATBANDHU",
    lastName: "DASSHARMA",
    address: "583C,KASTADANGA ROAD*SARSUNA*CALCUTTA 700 061",
    fullName: "LATE JAGATBANDHU DASSHARMA",
    saID: "SPR1006",
  },
  {
    firstName: "DHANYADHAR",
    lastName: "DAW",
    address: "LAIBILGAON*MIRIPATHAR*PO-DEHINGMUKH*DT-SIBSAGAR.",
    fullName: "DHANYADHAR DAW",
    saID: "SPR1007",
  },
  {
    firstName: "LATE GOBINDA LAL",
    lastName: "DAW",
    address: "4B LOCK GATE RD *CALCUTTA-2",
    fullName: "LATE GOBINDA LAL DAW",
    saID: "SPR1008",
  },
  {
    firstName: "LATE MURARI MOHAN",
    lastName: "DAW",
    address: "*SATSANG 814116",
    fullName: "LATE MURARI MOHAN DAW",
    saID: "SPR1009",
  },
  {
    firstName: "ABINASH CHANDRA",
    lastName: "DEB",
    address: "PO.MARUGANJ *COOCHBEHAR",
    fullName: "ABINASH CHANDRA DEB",
    saID: "SPR1010",
  },
  {
    firstName: "LATE AKSHOY KUMAR",
    lastName: "DEB",
    address: "VILL-BARTHAL*PO-THAILU*CACHAR.",
    fullName: "LATE AKSHOY KUMAR DEB",
    saID: "SPR1011",
  },
  {
    firstName: "LATE BINOY BHUSAN",
    lastName: "DEB",
    address: "RETD. INSPECTOR CENTRAL EXCISE*PO-SRIPURIA*DIBRUGARH",
    fullName: "LATE BINOY BHUSAN DEB",
    saID: "SPR1012",
  },
  {
    firstName: "CHUNILAL",
    lastName: "DEB",
    address: "C/O D.C.S.OFFICE.*P.O MAN*NAGALAND",
    fullName: "CHUNILAL DEB",
    saID: "SPR1013",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "DEB",
    address: "VILL-JATRABARI*PO-KHAYERPUR*WEST TRIPURA",
    fullName: "LATE DINESH CHANDRA DEB",
    saID: "SPR1014",
  },
  {
    firstName: "LATE GOUR GOPAL",
    lastName: "DEB",
    address: "352/B.D.L.W.VARANASI*U.P.",
    fullName: "LATE GOUR GOPAL DEB",
    saID: "SPR1015",
  },
  {
    firstName: "LATE HARENDRA KUMAR",
    lastName: "DEB",
    address: "C/O-SUBRATA CHAKRABORTY*SATSANG-814116.",
    fullName: "LATE HARENDRA KUMAR DEB",
    saID: "SPR1016",
  },
  {
    firstName: "LATE HIRANYA KUMAR",
    lastName: "DEB",
    address: "KAHILIPARA COLONY*PO-GUWAHATI-781018*KAMRUP*ASSAM",
    fullName: "LATE HIRANYA KUMAR DEB",
    saID: "SPR1017",
  },
  {
    firstName: "LATE JAHAR LAL",
    lastName: "DEB",
    address: "TARAPUR*PO/DIST-SILCHAR-3*CACHAR*ASSAM",
    fullName: "LATE JAHAR LAL DEB",
    saID: "SPR1018",
  },
  {
    firstName: "LATE JATINDRA CHANDRA",
    lastName: "DEB",
    address: "SATSANG VIHAR,RAMNAGAR*SATSANG ROAD*PO.SILCHAR-7*CACHAR",
    fullName: "LATE JATINDRA CHANDRA DEB",
    saID: "SPR1019",
  },
  {
    firstName: "LATE KUMAR PURNENDU NR",
    lastName: "DEB",
    address: "H.E.*P.O.KANCHUGAON*GOALPARA",
    fullName: "LATE KUMAR PURNENDU NR DEB",
    saID: "SPR1020",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "DEB",
    address: '"SURESH BHAWAN"RD NO-9*P.O.RAMNAGAR*AGARTALA*WEST TRIPURA-799002',
    fullName: "NARESH CHANDRA DEB",
    saID: "SPR1021",
  },
  {
    firstName: "NIHAR RANJAN",
    lastName: "DEB",
    address: "SATSANG VIHAR*HOJAI*DIST-NAGAON-782435*ASSAM",
    fullName: "NIHAR RANJAN DEB",
    saID: "SPR1022",
  },
  {
    firstName: "LATE RASAMOY CHANDRA",
    lastName: "DEB",
    address: "PO-SARUPATHAR*SIBSAGAR",
    fullName: "LATE RASAMOY CHANDRA DEB",
    saID: "SPR1023",
  },
  {
    firstName: "RATISH CHANDRA",
    lastName: "DEB",
    address: "PO/VILL-N.AREA*VIA-LANKA*NAGAON-782446*ASSAM*PAN-BNRPD8775Q",
    fullName: "RATISH CHANDRA DEB",
    saID: "SPR1024",
  },
  {
    firstName: "SASANKA SHEKHAR",
    lastName: "DEB",
    address: "R.K SARANI*PO-MEHERPUR*DIST-SILCHAR 15*(ASSAM)",
    fullName: "SASANKA SHEKHAR DEB",
    saID: "SPR1025",
  },
  {
    firstName: "SHIMUL KUMAR",
    lastName: "DEB",
    address: "SUB DIVN WELFARE OFFICE*SILCHAR*CACHER.",
    fullName: "SHIMUL KUMAR DEB",
    saID: "SPR1026",
  },
  {
    firstName: "SUDHANGSU RANJAN",
    lastName: "DEB",
    address:
      '"ASHIRBAD"KRISHNANAGAR NUTANPALLY*NEAR CHATRASANGA*PO-AGARTALA*TRIPURA (W) 799001.',
    fullName: "SUDHANGSU RANJAN DEB",
    saID: "SPR1027",
  },
  {
    firstName: "LATE SUDHANYA MOHAN",
    lastName: "DEB",
    address: "VILL ROUTGRAM*PO CHATULBAZAR*DIST SYLHET*BANGLADESH",
    fullName: "LATE SUDHANYA MOHAN DEB",
    saID: "SPR1028",
  },
  {
    firstName: "SUDHENDU BIKASH",
    lastName: "DEB",
    address: "VILL-RAUTHGRAM*PO-CHATULBAJAR*SYLHET*BANGLADESH",
    fullName: "SUDHENDU BIKASH DEB",
    saID: "SPR1029",
  },
  {
    firstName: "LATE SUJIT KUMAR",
    lastName: "DEB",
    address: "BHARATNAGAR*SILIGURI*PO.RABINDRA SARANI-734406*DARJEELING",
    fullName: "LATE SUJIT KUMAR DEB",
    saID: "SPR1030",
  },
  {
    firstName: "LATE SUKHADA RANJAN",
    lastName: "DEB",
    address: '"SANTI KUTIR",MANDIR DIGHIR PAR*PO-SILCHAR 1*CACHAR.',
    fullName: "LATE SUKHADA RANJAN DEB",
    saID: "SPR1031",
  },
  {
    firstName: "LATE SUNIL CHANDRA",
    lastName: "DEB",
    address: "BARDUBI ROAD*PO-HOOGRIZAN*DIBRUGARH.",
    fullName: "LATE SUNIL CHANDRA DEB",
    saID: "SPR1032",
  },
  {
    firstName: "SWADESH CHANDRA",
    lastName: "DEB",
    address: "PO-KHAYERPUR*VILL-JATRABARI*TRIPURA WEST-799008",
    fullName: "SWADESH CHANDRA DEB",
    saID: "SPR1033",
  },
  {
    firstName: "THAKUR DAS",
    lastName: "DEB",
    address:
      '"SATSANG VIHAR"*PO-SANTIR BAJAR*TRIPURA(S)-799144*PAN NO.APCPD3826N',
    fullName: "THAKUR DAS DEB",
    saID: "SPR1034",
  },
  {
    firstName: "LATE KIRITY MOHAN",
    lastName: "DEBBURMAN",
    address: "KALIBARI ROAD*KRISNANAGAR*PO-AGARTALA*TRIPURA",
    fullName: "LATE KIRITY MOHAN DEBBURMAN",
    saID: "SPR1035",
  },
  {
    firstName: "LATE BIJIT",
    lastName: "DEBCHOUDHURY",
    address: "VILL&PO-BOALIPAR*DT-CACHAR.",
    fullName: "LATE BIJIT DEBCHOUDHURY",
    saID: "SPR1036",
  },
  {
    firstName: "LATE PRANESWAR",
    lastName: "DEBGOSWAMI",
    address: "C/O HAJO POLICE STATION*HAJO*KAMRUP",
    fullName: "LATE PRANESWAR DEBGOSWAMI",
    saID: "SPR1037",
  },
  {
    firstName: "LATE TARANI MOHAN",
    lastName: "DEBROY",
    address: "VILL-DURGAPUR*PO-PAITURBAJAR*TRIPURA NORTH 799279",
    fullName: "LATE TARANI MOHAN DEBROY",
    saID: "SPR1038",
  },
  {
    firstName: "LATE BRAJENDRANATH",
    lastName: "DEBADHIKARY",
    address: "PARMEKHLIGANJ*PO-DEWANGANJ*COOCHBEHAR-735122",
    fullName: "LATE BRAJENDRANATH DEBADHIKARY",
    saID: "SPR1039",
  },
  {
    firstName: "LATE BISWESWAR",
    lastName: "DEBBARMAN",
    address: "*SATSANG 814116",
    fullName: "LATE BISWESWAR DEBBARMAN",
    saID: "SPR1040",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "DEBBARMAN",
    address: "*SATSANG 814116",
    fullName: "LATE JITENDRA NATH DEBBARMAN",
    saID: "SPR1041",
  },
  {
    firstName: "ASANKA",
    lastName: "DEBBURMAN",
    address: "EAST OF MAHILA ASHRAM*PO-ABHOYNAGAR*AGARTALA*TRIPURA WEST-799005",
    fullName: "ASANKA DEBBURMAN",
    saID: "SPR1042",
  },
  {
    firstName: "BISHNU KUMAR",
    lastName: "DEBBARMA",
    address: "NUTANBARI*PO-NOABADH*TRIPURA",
    fullName: "BISHNU KUMAR DEBBARMA",
    saID: "SPR1043",
  },
  {
    firstName: "LATE KAMINI MOHAN",
    lastName: "DEBBURMAN",
    address: "NORTH BANAMALIPUR*PO-AGARTALA*TRIPURA.",
    fullName: "LATE KAMINI MOHAN DEBBURMAN",
    saID: "SPR1044",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "DEBBURMAN",
    address: "AT CHUNIMATI PO THERANIGANJ*PURNIA",
    fullName: "LATE PRAFULLA KUMAR DEBBURMAN",
    saID: "SPR1045",
  },
  {
    firstName: "PRAVASH CHANDRA",
    lastName: "DEBBURMAN",
    address: "C/O NIBARAN CH.DAS*PO+V-PILKI*VIA-MANIGRAM*MURSHIDABAD-742237",
    fullName: "PRAVASH CHANDRA DEBBURMAN",
    saID: "SPR1046",
  },
  {
    firstName: "LATE HORESWAR",
    lastName: "DEBCHOUDHURY",
    address: "DISPUR SATSANG VIHAR,GAUHATI-5*PO DISPUR*KAMRUP.",
    fullName: "LATE HORESWAR DEBCHOUDHURY",
    saID: "SPR1047",
  },
  {
    firstName: "LATE PRABIR KUMAR",
    lastName: "DEBCHOWDHURY",
    address: "PO-BADARPUR*JUMBASTI WARD NO 4*KARIMGANJ .",
    fullName: "LATE PRABIR KUMAR DEBCHOWDHURY",
    saID: "SPR1048",
  },
  {
    firstName: "LATE PRALOY KUMAR",
    lastName: "DEBCHOUDHURY",
    address: "VILL/PO-BOALIPAR*HAILAKANDI.",
    fullName: "LATE PRALOY KUMAR DEBCHOUDHURY",
    saID: "SPR1049",
  },
  {
    firstName: "AMIYA SHANKAR",
    lastName: "DEBNATH",
    address:
      '"SWASTI DHAM"*BARABAZAR,BENGALIPARA*PO-KHETRAJPUR*SAMBALPUR-768003',
    fullName: "AMIYA SHANKAR DEBNATH",
    saID: "SPR1050",
  },
  {
    firstName: "BEJOY KUMAR",
    lastName: "DEBNATH",
    address: "VILL/PO-CHAKCHAKA*COOCH BEHAR-736156",
    fullName: "BEJOY KUMAR DEBNATH",
    saID: "SPR1051",
  },
  {
    firstName: "LATE BIDHICHARAN",
    lastName: "DEBNATH",
    address: "VILL/PO-SASARGRAM*DHUBRI 783345",
    fullName: "LATE BIDHICHARAN DEBNATH",
    saID: "SPR1052",
  },
  {
    firstName: "LATE BRAJA GOPAL",
    lastName: "DEBNATH",
    address: "SATSANG KENDRA*PO HOJAI*NOWGONG",
    fullName: "LATE BRAJA GOPAL DEBNATH",
    saID: "SPR1053",
  },
  {
    firstName: "CHANDRA KUMAR",
    lastName: "DEBNATH",
    address: "VILL-CHAKITOP,WARD NO-26*PO-CHOTO HAIBOR*NAGAON-782003*ASSAM",
    fullName: "CHANDRA KUMAR DEBNATH",
    saID: "SPR1054",
  },
  {
    firstName: "CHANDRA MOHAN",
    lastName: "DEBNATH",
    address: "VILL-DEOLIATILLA*PO-PAHAR MURA*TRIPURA WEST 799202.",
    fullName: "CHANDRA MOHAN DEBNATH",
    saID: "SPR1055",
  },
  {
    firstName: "CHITTA RANJAN",
    lastName: "DEBNATH",
    address: "VILL/PO-CHAKCHAKA*COOCHBEHAR-736156.*PAN ADWPD5142R",
    fullName: "CHITTA RANJAN DEBNATH",
    saID: "SPR1056",
  },
  {
    firstName: "LATE DHIRENDRA CHANDRA",
    lastName: "DEBNATH",
    address: "PO.BAXIRHAT*COOCHBEHAR",
    fullName: "LATE DHIRENDRA CHANDRA DEBNATH",
    saID: "SPR1057",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "DEBNATH",
    address: "PO-BAGMA*S.TRIPURA-799119",
    fullName: "GOPAL CHANDRA DEBNATH",
    saID: "SPR1058",
  },
  {
    firstName: "LATE HARI MOHAN",
    lastName: "DEBNATH",
    address: "NUTANPARA COLLAGE ROAD*PO/DIST-BONGAIGAON 783380",
    fullName: "LATE HARI MOHAN DEBNATH",
    saID: "SPR1059",
  },
  {
    firstName: "LATE HARIHAR",
    lastName: "DEBNATH",
    address: "AT/PO-NARAYANPUR*VIA-KANKINARA*DIST-24-PGS.",
    fullName: "LATE HARIHAR DEBNATH",
    saID: "SPR1060",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "DEBNATH",
    address: "C/O HARISH CHANDRA*EAST SHIBNAGAR*AGARTALA(TRIPURA)",
    fullName: "LATE HARIPADA DEBNATH",
    saID: "SPR1061",
  },
  {
    firstName: "JAHAR",
    lastName: "DEBNATH",
    address: "C/O S.P.(S.B) OFFICE*P.O.AGARTALA*W.TRIPURA  799001",
    fullName: "JAHAR DEBNATH",
    saID: "SPR1062",
  },
  {
    firstName: "LATE KRISHNA LAL",
    lastName: "DEBNATH",
    address: '"SWASTIDHAM"SONARPUR*NETAJIPALLY*PO-KAMRABAD*DIST-24-PGS 743369',
    fullName: "LATE KRISHNA LAL DEBNATH",
    saID: "SPR1063",
  },
  {
    firstName: "LATE MANINDRA CHANDRA",
    lastName: "DEBNATH",
    address: "VILL 1 NO SHYAMBARI*PO SHYAMBARI.VIA LANKA*NOWGONG.",
    fullName: "LATE MANINDRA CHANDRA DEBNATH",
    saID: "SPR1064",
  },
  {
    firstName: "MRITYUNJOY",
    lastName: "DEBNATH",
    address:
      "AT+PO-SAGUNA*VIA-KHANTURA*DIST-24 PGS (NORTH)-743371*PAN AEEPP5881J",
    fullName: "MRITYUNJOY DEBNATH",
    saID: "SPR1065",
  },
  {
    firstName: "NIKHIL CHANDRA",
    lastName: "DEBNATH",
    address: "VIL&PO-KHOLTA*COOCHBEHAR",
    fullName: "NIKHIL CHANDRA DEBNATH",
    saID: "SPR1066",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DEBNATH",
    address: "AT-DAHARTHUBA*PO-HATTHUBA*24 PARGANAS (NORTH)-743263.",
    fullName: "PARESH CHANDRA DEBNATH",
    saID: "SPR1067",
  },
  {
    firstName: "RAMANANDA",
    lastName: "DEBNATH",
    address: "AT&PO.-KAMAKHYAGURI*JALPAIGURI-735132",
    fullName: "RAMANANDA DEBNATH",
    saID: "SPR1068",
  },
  {
    firstName: "LATE SHIBENDRA NATH",
    lastName: "DEBNATH",
    address: "PO-JOYPUR*B-DEOGHAR",
    fullName: "LATE SHIBENDRA NATH DEBNATH",
    saID: "SPR1069",
  },
  {
    firstName: "DWIJENDRA KUMAR",
    lastName: "DEBROY",
    address: "INDUSTRIAL LAXMI TAILORING*WARD NO-10*N.LAKHIMPUR.",
    fullName: "DWIJENDRA KUMAR DEBROY",
    saID: "SPR1070",
  },
  {
    firstName: "SALIL KUMAR",
    lastName: "DEBRAY",
    address: "PO/VILL-GHILATALI*TRIPURA-799203",
    fullName: "SALIL KUMAR DEBRAY",
    saID: "SPR1071",
  },
  {
    firstName: "LATE HARASIT",
    lastName: "DEBSARMA",
    address: "AT-TANGIBILPUR*PO-BAGHAN*UTTAR DINAJPUR-733129",
    fullName: "LATE HARASIT DEBSARMA",
    saID: "SPR1072",
  },
  {
    firstName: "PRIYANATH",
    lastName: "DEBSARMA",
    address: "SATSANG VIHAR DISPUR*PO-DISPUR*GUWAHATI-5",
    fullName: "PRIYANATH DEBSARMA",
    saID: "SPR1073",
  },
  {
    firstName: "LALIT KUMAR",
    lastName: "DEBSHARMA",
    address: "PO MAHARAJGANJ*PURBA SULTANPUR*DIST DINAJPUR*BANGLADESH",
    fullName: "LALIT KUMAR DEBSHARMA",
    saID: "SPR1074",
  },
  {
    firstName: "LATE SATYA NATH",
    lastName: "DEBSHARMA",
    address: "VILL&PO-ABHAYAPURI*DT-GOALPARA.",
    fullName: "LATE SATYA NATH DEBSHARMA",
    saID: "SPR1075",
  },
  {
    firstName: "LATE SHIB PRASAD",
    lastName: "DEBSHARMA",
    address: "SATSANG VIHAR,GAUHATI-5*DIST KAMRUP",
    fullName: "LATE SHIB PRASAD DEBSHARMA",
    saID: "SPR1076",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "DEBSHARMA",
    address: "RAIGANJ SATSANG KENDRA*PO-RAIGANJ*DT-WEST DINAJPUR.",
    fullName: "LATE SUBODH CHANDRA DEBSHARMA",
    saID: "SPR1077",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "DEBSHARMA",
    address: "SATSANG 814116",
    fullName: "LATE SURESH CHANDRA DEBSHARMA",
    saID: "SPR1078",
  },
  {
    firstName: "LATE AMBIKA CHARAN",
    lastName: "DEKA",
    address: "AT DHAMDHAMA*PO BARBARI*DIST KAMRUP",
    fullName: "LATE AMBIKA CHARAN DEKA",
    saID: "SPR1079",
  },
  {
    firstName: "ANNADA CHARAN",
    lastName: "DEKA",
    address: "CHANDRAPUR THERMAL*PO-CHANDRAPUR*DIST-KAMRUP-781150*ASSAM",
    fullName: "ANNADA CHARAN DEKA",
    saID: "SPR1080",
  },
  {
    firstName: "LATE BAIKUNTHA",
    lastName: "DEKA",
    address: "G.N.B.M.HIGH SCHOOL*BARPETA ROAD*DIST KAMRUP",
    fullName: "LATE BAIKUNTHA DEKA",
    saID: "SPR1081",
  },
  {
    firstName: "LATE HARAMOHAN",
    lastName: "DEKA",
    address: "FATASIL AMBARI*GUWAHATI-781 025*KAMRUP",
    fullName: "LATE HARAMOHAN DEKA",
    saID: "SPR1082",
  },
  {
    firstName: "LATE HARABALLAV",
    lastName: "DEKA",
    address: "SATSANG BIHAR RAJGARH DISPUR*PO GAUHATI-5*KAMRUP",
    fullName: "LATE HARABALLAV DEKA",
    saID: "SPR1083",
  },
  {
    firstName: "JAGAT CHANDRA",
    lastName: "DEKA",
    address: "AT-BARIGAON*PO-DAKSHINGAON*DIST-KAMRUP-781350*ASSAM",
    fullName: "JAGAT CHANDRA DEKA",
    saID: "SPR1084",
  },
  {
    firstName: "LATE KABI CHANDRA",
    lastName: "DEKA",
    address: "AT-BARPHULCHUKI*PO-GORESWAR*DT-KAMRUP 781366",
    fullName: "LATE KABI CHANDRA DEKA",
    saID: "SPR1085",
  },
  {
    firstName: "LATE KAMINI KANTA",
    lastName: "DEKA",
    address: "SATSANG VIHAR, BHANGAGARH*GUWAHATI-5*KAMRUP",
    fullName: "LATE KAMINI KANTA DEKA",
    saID: "SPR1086",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "DEKA",
    address: "AT-BARNURIKANA*PO-BARABARI*DIST KAMRUP",
    fullName: "NARAYAN CHANDRA DEKA",
    saID: "SPR1087",
  },
  {
    firstName: "LATE AMRIT LAL",
    lastName: "DESAI",
    address: "ADVOCATE 7-7-851/1*ESAMIYA BAZAR HYDRABAD*A.P.",
    fullName: "LATE AMRIT LAL DESAI",
    saID: "SPR1088",
  },
  {
    firstName: "LATE GOPALBHAI CHOTABHAI",
    lastName: "DESAI",
    address: "DR.KAMBHI BLOCK*VILE PARLE(EAST)*BOMBAY-57",
    fullName: "LATE GOPALBHAI CHOTABHAI DESAI",
    saID: "SPR1089",
  },
  {
    firstName: "LATE KRISHNABHAI C",
    lastName: "DESAI",
    address: "DR.KAMBHI BLOCK*VILE PARLE(EAST)*BOMBAY-57",
    fullName: "LATE KRISHNABHAI C DESAI",
    saID: "SPR1090",
  },
  {
    firstName: "NRIPEN",
    lastName: "DEURI",
    address: "AT/PO-NAMDEURI*DIST-JORHAT-785108*ASSAM",
    fullName: "NRIPEN DEURI",
    saID: "SPR1091",
  },
  {
    firstName: "SHIBA PRASAD",
    lastName: "DEWANJEE",
    address: "PO-DHUPGURI*JALPAIGURI",
    fullName: "SHIBA PRASAD DEWANJEE",
    saID: "SPR1092",
  },
  {
    firstName: "AMARENDRA",
    lastName: "DEY",
    address: "TEZPUR ACADEMI*PO-TEZPUR*DIST-DARRANG.",
    fullName: "AMARENDRA DEY",
    saID: "SPR1093",
  },
  {
    firstName: "LATE AMIYA RANJAN",
    lastName: "DEY",
    address: "BARANILPUR BAZAR*PO&DIST-BURDWAN",
    fullName: "LATE AMIYA RANJAN DEY",
    saID: "SPR1094",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "DEY",
    address: "15/1, SITALA TALA STREET*BELGHORIA-700056*KOLKATA",
    fullName: "ASIT BARAN DEY",
    saID: "SPR1095",
  },
  {
    firstName: "BALARAM",
    lastName: "DEY",
    address: "THANA ROAD*PO-ANDAL*BURDWAN",
    fullName: "BALARAM DEY",
    saID: "SPR1096",
  },
  {
    firstName: "LATE BARODA KUMAR",
    lastName: "DEY",
    address: "V-BAIKUNTHAPUR*PO-SIDDHESWARI*DT-COOCHBEHAR.",
    fullName: "LATE BARODA KUMAR DEY",
    saID: "SPR1097",
  },
  {
    firstName: "LATE BASUDEB",
    lastName: "DEY",
    address: "AT-BHABANIPUR*PO-BASIRHAT COLLEGE*DIST-24 PGS.",
    fullName: "LATE BASUDEB DEY",
    saID: "SPR1098",
  },
  {
    firstName: "LATE BENIMADHAB",
    lastName: "DEY",
    address: "H.NO.-283/N,FULIA UPANAGARI*PO-FULIA*NADIA",
    fullName: "LATE BENIMADHAB DEY",
    saID: "SPR1099",
  },
  {
    firstName: "BHISMADEB",
    lastName: "DEY",
    address: "AT-NETAJEE NAGAR*PO-DEBAGRAM*NADIA-741238",
    fullName: "BHISMADEB DEY",
    saID: "SPR1100",
  },
  {
    firstName: "BIDHU BHUSAN",
    lastName: "DEY",
    address: "SATSANG VIHAR,KENCHTRACE*SHILLONG*MEGHALAYA-793004",
    fullName: "BIDHU BHUSAN DEY",
    saID: "SPR1101",
  },
  {
    firstName: "LATE BIDHUBHUSHAN",
    lastName: "DEY",
    address: "AT-RABINDRAPALLY*PO-HALISAHAR*DIST-24 PGS (NORTH)-743134",
    fullName: "LATE BIDHUBHUSHAN DEY",
    saID: "SPR1102",
  },
  {
    firstName: "BIJOY BALLAV",
    lastName: "DEY",
    address: "VILL/PO MANGALPUR*BANKURA",
    fullName: "BIJOY BALLAV DEY",
    saID: "SPR1103",
  },
  {
    firstName: "LATE BIPIN BEHARI",
    lastName: "DEY",
    address: "815A,JESSORE ROAD*GREEN PARK*CALCUTTA-700055",
    fullName: "LATE BIPIN BEHARI DEY",
    saID: "SPR1104",
  },
  {
    firstName: "BISHWANATH",
    lastName: "DEY",
    address:
      "KYNJIAT FUTBALL,RAID LABAN*H/NO 27/175*PO-LABAN*SHILLONG 793004*MEGHALAY*AHJPD1121F",
    fullName: "BISHWANATH DEY",
    saID: "SPR1105",
  },
  {
    firstName: "LATE BRAJENDRA CHANDRA",
    lastName: "DEY",
    address: "LAKHIPUR TEA GARDEN*PO-BINNAKANDIGHAT*CACHAR.",
    fullName: "LATE BRAJENDRA CHANDRA DEY",
    saID: "SPR1106",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "DEY",
    address:
      '"SWASTIDHAM"*82 KESHPUR ROAD*PO-CHANDRAKONA*PASCHIM MEDINIPUR-721201',
    fullName: "CHITTARANJAN DEY",
    saID: "SPR1107",
  },
  {
    firstName: "LATE DEBENDRA CHANDRA",
    lastName: "DEY",
    address: "EX. POSTAL SUPERVISOR*PO.SILCHAR-4*CACHAR",
    fullName: "LATE DEBENDRA CHANDRA DEY",
    saID: "SPR1108",
  },
  {
    firstName: "LATE DHARANI KANT",
    lastName: "DEY",
    address: "SATSANG VIHAR*P.O.MAIBANG*N.C.HILLS",
    fullName: "LATE DHARANI KANT DEY",
    saID: "SPR1109",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "DEY",
    address: "MIDNAPUR THAKURBARI*MIRZABAZAR*MIDNAPUR 721101",
    fullName: "DIPAK KUMAR DEY",
    saID: "SPR1110",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "DEY",
    address: "*BURDWAN",
    fullName: "LATE GANESH CHANDRA DEY",
    saID: "SPR1111",
  },
  {
    firstName: "LATE GOPILAL",
    lastName: "DEY",
    address: "AT-ASIGHAR-PO-BHOGOMALI*SILIGURI*JALPAIGURI.",
    fullName: "LATE GOPILAL DEY",
    saID: "SPR1112",
  },
  {
    firstName: "LATE GOPINATH",
    lastName: "DEY",
    address: "AT&PO-SATGACHIA*DIST BURDWAN",
    fullName: "LATE GOPINATH DEY",
    saID: "SPR1113",
  },
  {
    firstName: "LATE HARIRAM",
    lastName: "DEY",
    address: "C/O LT RAJMOHAN DEY*VILL-DHAJNAGAR*P.O.GOKULPUR*TRIPURA (SOUTH)",
    fullName: "LATE HARIRAM DEY",
    saID: "SPR1114",
  },
  {
    firstName: "HARIMOHAN",
    lastName: "DEY",
    address: "DIPAN PHARMACY*A.T.ROAD*PO/DIST-TINSUKIA-786125*(ASSAM)",
    fullName: "HARIMOHAN DEY",
    saID: "SPR1115",
  },
  {
    firstName: "HARIPADA",
    lastName: "DEY",
    address: "GIRISH GHOSH SARANI*HAKIMPARA*PO-SILIGURI*DIST-DARJEELING-734001",
    fullName: "HARIPADA DEY",
    saID: "SPR1116",
  },
  {
    firstName: "HARISH CHANDRA",
    lastName: "DEY",
    address: "NEHRU HIGH SCHOOL*AT/PO.PAILAPOOL*CACHAR*PAN-BAAPD6563N",
    fullName: "HARISH CHANDRA DEY",
    saID: "SPR1117",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "DEY",
    address: "AT.DUTTAPUR*PO.MARJATKANDI*KARIMGANJ.",
    fullName: "LATE JAGADISH CHANDRA DEY",
    saID: "SPR1118",
  },
  {
    firstName: "LATE KESHABRANJAN",
    lastName: "DEY",
    address: "AT-DIGHRA*PO-DUTTAPUKUR*DIST-24-PGS.",
    fullName: "LATE KESHABRANJAN DEY",
    saID: "SPR1119",
  },
  {
    firstName: "LATE MAHENDRA LAL",
    lastName: "DEY",
    address: "BISHALAXMITALA*VILL-MANIRAMPUR*PO-BARRACKPORE*24PARGANAS",
    fullName: "LATE MAHENDRA LAL DEY",
    saID: "SPR1120",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "DEY",
    address: "4C,PITAMBAR GHATAK LANE*CALCUTTA-700027",
    fullName: "LATE MANMATHA NATH DEY",
    saID: "SPR1121",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "DEY",
    address: "1/A HAZARIMAL LANE*CALCUTTA-14",
    fullName: "LATE MANMATHA NATH DEY",
    saID: "SPR1122",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "DEY",
    address: "QRS NO 37-B,ACCOUNTS COLONY*PO GAUHATI-12*KAMRUP.",
    fullName: "LATE MANORANJAN DEY",
    saID: "SPR1123",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DEY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE NANIGOPAL DEY",
    saID: "SPR1124",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DEY",
    address: "SATSANG VIHAR NALIAPOOL*DIST-DIBRUGARH.",
    fullName: "LATE NARAYAN CHANDRA DEY",
    saID: "SPR1125",
  },
  {
    firstName: "NARENDRA NARAYAN",
    lastName: "DEY",
    address: "215 NETAJI SUBHASH ROAD*GREEN PARK*PO-SEORAHPHULI*HOOGHLY-712223",
    fullName: "NARENDRA NARAYAN DEY",
    saID: "SPR1126",
  },
  {
    firstName: "LATE NIKHIL CHANDRA",
    lastName: "DEY",
    address: "GOPINATH PUR COLONY*PO&DIST-HOOGHLY",
    fullName: "LATE NIKHIL CHANDRA DEY",
    saID: "SPR1127",
  },
  {
    firstName: "PANCHANAN",
    lastName: "DEY",
    address: "BIBEK PALLY*PO.SHEORAFULLY*DT.HOOGHLY 712223",
    fullName: "PANCHANAN DEY",
    saID: "SPR1128",
  },
  {
    firstName: "LATE PHANI BHUSAN",
    lastName: "DEY",
    address: "PO INDUS*DIST BANKURA",
    fullName: "LATE PHANI BHUSAN DEY",
    saID: "SPR1129",
  },
  {
    firstName: "LATE PITAMBAR",
    lastName: "DEY",
    address: "RETIRED POST MASTER*ARYAPALLI*PO TEZPUR*DARRANG.",
    fullName: "LATE PITAMBAR DEY",
    saID: "SPR1130",
  },
  {
    firstName: "PRADIP",
    lastName: "DEY",
    address:
      "BINAYAK APTT.FLAT NO-F/2*M S SAHA SARANI*PRADHANNAGAR,SILIGURI*PAN-AEWPD1479C",
    fullName: "PRADIP DEY",
    saID: "SPR1131",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "DEY",
    address:
      "SARAJU APP.3RD FLOOR*T.S.ROAD*INDOOR SATDIUM*DESHBANDHUPARA*SILIGURI*DARJEELING-4",
    fullName: "PRADIP KUMAR DEY",
    saID: "SPR1132",
  },
  {
    firstName: "LATE PRAVAT CHANDRA",
    lastName: "DEY",
    address: "*SATSANG 814116*PAN-BOLPD2009G",
    fullName: "LATE PRAVAT CHANDRA DEY",
    saID: "SPR1133",
  },
  {
    firstName: "RABIN",
    lastName: "DEY",
    address: "34 NO RAFI AHAMED KIDWAI ROAD*DUM DUM*CALCUTTA-700055.",
    fullName: "RABIN DEY",
    saID: "SPR1134",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "DEY",
    address: "S IV/314 BIDHANNAGAR HSG COLONY*DURGAPUR 6*BURDWAN",
    fullName: "LATE RABINDRA NATH DEY",
    saID: "SPR1135",
  },
  {
    firstName: "LATE RADHA BALLAV",
    lastName: "DEY",
    address: "ANANDA NAGAR BYE LANE NO-4*PANDU*PO-GUWAHATI 781012*KAMRUP",
    fullName: "LATE RADHA BALLAV DEY",
    saID: "SPR1136",
  },
  {
    firstName: "LATE RATNESWAR",
    lastName: "DEY",
    address: "VILL BARANILPUR MORE G.T.ROAD*PO/DIST BURDWAN",
    fullName: "LATE RATNESWAR DEY",
    saID: "SPR1137",
  },
  {
    firstName: "SACHINDRA CHANDRA",
    lastName: "DEY",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCHBEHAR-735303",
    fullName: "SACHINDRA CHANDRA DEY",
    saID: "SPR1138",
  },
  {
    firstName: "SAMIRAN KUMAR",
    lastName: "DEY",
    address: "SATSANG VIHAR ANGARGADIA*AT/PO-DIST-BALASORE-756001",
    fullName: "SAMIRAN KUMAR DEY",
    saID: "SPR1139",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "DEY",
    address: "KALINAGAR*PO-KALINAGARHAT*DIST-24 PGS",
    fullName: "LATE SANTOSH KUMAR DEY",
    saID: "SPR1140",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "DEY",
    address: "KAMALA STORES,A.T.ROAD*PO/DIST-JORHAT",
    fullName: "LATE SARAT CHANDRA DEY",
    saID: "SPR1141",
  },
  {
    firstName: "LATE SASANKA MOHAN",
    lastName: "DEY",
    address: "100/28,BHAGABATI COLONY*JESSORE ROAD*CALCUTTA-700028",
    fullName: "LATE SASANKA MOHAN DEY",
    saID: "SPR1142",
  },
  {
    firstName: "LATE SASHI BHUSAN",
    lastName: "DEY",
    address: "VILL/PO JAMJURI*R.K.PUR*UDAIPUR*TRIPURA",
    fullName: "LATE SASHI BHUSAN DEY",
    saID: "SPR1143",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "DEY",
    address: "PO-THAILU T.E*BARTHAL T.E*DT-CACHAR",
    fullName: "LATE SATISH CHANDRA DEY",
    saID: "SPR1144",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "DEY",
    address: "BENGAL ENAMEL WORKS LTD*PO-BENGAL ENAMEL*24 PGS(NORTH) 743122.",
    fullName: "SATISH CHANDRA DEY",
    saID: "SPR1145",
  },
  {
    firstName: "SATYARANJAN",
    lastName: "DEY",
    address: "44 KEOTA LAT BAGAN*SAHAGANJ*HOOGHLY",
    fullName: "SATYARANJAN DEY",
    saID: "SPR1146",
  },
  {
    firstName: "SISHIR KUMAR",
    lastName: "DEY",
    address: "WEST BENGAL CO-OP.BANK LTD*29A,WATERLOO STREET*CALCUTTA-1",
    fullName: "SISHIR KUMAR DEY",
    saID: "SPR1147",
  },
  {
    firstName: "LATE SONTOSH KUMAR",
    lastName: "DEY",
    address: '"SWASTIDHAM"*GOBARDANGA STATION ROAD*PO-KHANTURA*24PARGANAS.',
    fullName: "LATE SONTOSH KUMAR DEY",
    saID: "SPR1148",
  },
  {
    firstName: "SUBODH CHANDRA",
    lastName: "DEY",
    address: "VILL/PO-GARAIMARI*VIA-BIJNI*BONGAIGAON 783390",
    fullName: "SUBODH CHANDRA DEY",
    saID: "SPR1149",
  },
  {
    firstName: "SUNIL BARAN",
    lastName: "DEY",
    address:
      "VIVEKANANDA NAGAR*NEAR SATSANG THAKURBARI*PO-EAST UDAYRAJPUR*MADHAYAMGRAM*KOL-129.",
    fullName: "SUNIL BARAN DEY",
    saID: "SPR1150",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "DEY",
    address: "VILL-NANGI BENEPARA*PO-BATANAGAR*DIST-24 PGS.",
    fullName: "LATE SURESH CHANDRA DEY",
    saID: "SPR1151",
  },
  {
    firstName: "LATE MAKHANLAL",
    lastName: "DEYBHOUMIK",
    address: "GAROBADHA SATSANG VIHAR*GAROHILLS*MEGHALAYA",
    fullName: "LATE MAKHANLAL DEYBHOUMIK",
    saID: "SPR1152",
  },
  {
    firstName: "GOPENDU KUMAR",
    lastName: "DEYBISWAS",
    address: "A/196 RAM KRISHNA NAGAR*PO-LASKARPUR*24PARGANAS (SOUTH)-700153",
    fullName: "GOPENDU KUMAR DEYBISWAS",
    saID: "SPR1153",
  },
  {
    firstName: "BISHNU PADA",
    lastName: "DEYCHOUDHURY",
    address:
      "HAIDERPARA WARD-40,BEHIND WONDERS CLUB*PO-HAIDERPARA*JALAPAIGURI-734006*ACTPD5767E.",
    fullName: "BISHNU PADA DEYCHOUDHURY",
    saID: "SPR1154",
  },
  {
    firstName: "LATE NIHARENDU",
    lastName: "DEYCHOUDHURY",
    address:
      "STATION MORE*FALAKATA SATSANG VIHAR*PO-FALAKATA*DIST-JALPAIGURI 735211",
    fullName: "LATE NIHARENDU DEYCHOUDHURY",
    saID: "SPR1155",
  },
  {
    firstName: "LATE GURUDAS",
    lastName: "DEYSARKAR",
    address: "3 NO SRIRAMPUR*EASTERN RD*PO-GARIA*DIST-24 PGS.",
    fullName: "LATE GURUDAS DEYSARKAR",
    saID: "SPR1156",
  },
  {
    firstName: "LATE MANINDRA CHANDRA",
    lastName: "DEYSARKAR",
    address: "C/O CHANDAN DEYSARKAR*PO-GOSSAINGAON*DIST-KOKRAJHAR*ASSAM",
    fullName: "LATE MANINDRA CHANDRA DEYSARKAR",
    saID: "SPR1157",
  },
  {
    firstName: "LATE KARTIK CHANDRA",
    lastName: "DHAK",
    address: "AT/PO.PALBA*DIST.HOOGHLY",
    fullName: "LATE KARTIK CHANDRA DHAK",
    saID: "SPR1158",
  },
  {
    firstName: "LATE BHAGIRATHI",
    lastName: "DHAL",
    address: "SATSANG VIHAR*AT/PO.BHADRAK*BALASORE.",
    fullName: "LATE BHAGIRATHI DHAL",
    saID: "SPR1159",
  },
  {
    firstName: "RATNAKAR",
    lastName: "DHAL",
    address: "AT/PO.KOIRA*SUNDARGARH-770048",
    fullName: "RATNAKAR DHAL",
    saID: "SPR1160",
  },
  {
    firstName: "LATE KAMAL KANTA",
    lastName: "DHALI",
    address: "NORTH BASUDEBPUR*CALCUTTA-700056.",
    fullName: "LATE KAMAL KANTA DHALI",
    saID: "SPR1161",
  },
  {
    firstName: "MANINDRA",
    lastName: "DHALI",
    address: "525, V I P NAGAR*KOLKATA - 700100",
    fullName: "MANINDRA DHALI",
    saID: "SPR1162",
  },
  {
    firstName: "SARANAN",
    lastName: "DHALI",
    address: "19/1 THAKURTALA ROAD*PURBABARISA*CALCUTTA-8",
    fullName: "SARANAN DHALI",
    saID: "SPR1163",
  },
  {
    firstName: "LATE SATINDRANATH",
    lastName: "DHALI",
    address: "P V 6*PO-KAPSI*BASTAR*M P 494771",
    fullName: "LATE SATINDRANATH DHALI",
    saID: "SPR1164",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "DHAR",
    address: "*SATSANG  814116",
    fullName: "LATE AJIT KUMAR DHAR",
    saID: "SPR1165",
  },
  {
    firstName: "BINIT KUMAR",
    lastName: "DHAR",
    address: "GOALTULI (WEST)*PO/DIST-HOOGHLY-712103.",
    fullName: "BINIT KUMAR DHAR",
    saID: "SPR1166",
  },
  {
    firstName: "LATE BIRENDRA BHUSAN",
    lastName: "DHAR",
    address: "RABINDRANAGAR*PO-SILIGURI*DT-DARJEELING.",
    fullName: "LATE BIRENDRA BHUSAN DHAR",
    saID: "SPR1167",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "DHAR",
    address: "RAMKRISHNAPUR KHAGROJOLE*PO.CHINSURAH*DIST.HOOGHLY",
    fullName: "LATE NITYANANDA DHAR",
    saID: "SPR1168",
  },
  {
    firstName: "LATE PRABHAT RANJAN",
    lastName: "DHAR",
    address: "TICKET COLLECTOR OFFICE*PO LUMDING*NOWGONG.",
    fullName: "LATE PRABHAT RANJAN DHAR",
    saID: "SPR1169",
  },
  {
    firstName: "LATE RAKHAL KUMAR",
    lastName: "DHAR",
    address: "CHAKCHAKA CHECKPOST*PO-NILKUTHI-736156*COOCH BEHAR",
    fullName: "LATE RAKHAL KUMAR DHAR",
    saID: "SPR1170",
  },
  {
    firstName: "LATE SITA NATH",
    lastName: "DHAR",
    address: "PO-KADAMTALA*TRIPURA NORTH 799261",
    fullName: "LATE SITA NATH DHAR",
    saID: "SPR1171",
  },
  {
    firstName: "SUNIL KANTA",
    lastName: "DHAR",
    address: "JOYMATI NAGAR*PANDU*GUWAHATI-781012*DIST-KAMRUP*ASSAM",
    fullName: "SUNIL KANTA DHAR",
    saID: "SPR1172",
  },
  {
    firstName: "LATE SUNIL RANJAN",
    lastName: "DHAR",
    address: "TALPUKUR 3RD LANE*PO.CHANDANNAGAR*DIST.HOOGHLY",
    fullName: "LATE SUNIL RANJAN DHAR",
    saID: "SPR1173",
  },
  {
    firstName: "LATE SITAL PRASAD",
    lastName: "DHUSIYA",
    address: "68/1A, DEWANGAZI RD*BALLY*HOWRAH.",
    fullName: "LATE SITAL PRASAD DHUSIYA",
    saID: "SPR1174",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "DAKSHIT",
    address: "VILL-PURUNIA*PO-RAIGRAM*BURDWAN-713422",
    fullName: "KRISHNA CHANDRA DAKSHIT",
    saID: "SPR1175",
  },
  {
    firstName: "DHANANJOY",
    lastName: "DINDA",
    address: "PO&VILL-D.DAMODARPUR*VIA-KUMARCHAK*DIST-PURBA MEDINIPUR-721652",
    fullName: "DHANANJOY DINDA",
    saID: "SPR1176",
  },
  {
    firstName: "SRIRAM CHANDRA",
    lastName: "DINDA",
    address: "VILL/PO-DAKSHIN DAMODARPUR*PURBA MEDINIPUR-721652",
    fullName: "SRIRAM CHANDRA DINDA",
    saID: "SPR1177",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "DINDA",
    address: "VILL-DHARMADASBAR*PO-CONTAI*DIST-PURBA MEDINIPUR-721401",
    fullName: "SISIR KUMAR DINDA",
    saID: "SPR1178",
  },
  {
    firstName: "LATE SARBESWAR",
    lastName: "DOLEY",
    address: "DHIRITI DHAM*JAYA NAGAR*GUWAHATI-22*KAMRUP*ASSAM",
    fullName: "LATE SARBESWAR DOLEY",
    saID: "SPR1179",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "DOLAI",
    address:
      "VILL-UTTAR GOBINDANAGAR*PO-CHENCHUA GOBINDANAGAR*PASCHIM MEDINIPUR 721 146",
    fullName: "KARTICK CHANDRA DOLAI",
    saID: "SPR1180",
  },
  {
    firstName: "SATI PRASAD",
    lastName: "DOLUI",
    address:
      "VILL-RAMDEVPUR*PO-CHETUARAJNAGAR*DIST-PASCHIM MEDINIPUR-721211*PAN-AEDPD5390M",
    fullName: "SATI PRASAD DOLUI",
    saID: "SPR1181",
  },
  {
    firstName: "LATE ACHYUT CHANDRA",
    lastName: "DUTTA",
    address: "BURMAN CABIN*PO-TANGLA*DIST-DARRANG*ASSAM",
    fullName: "LATE ACHYUT CHANDRA DUTTA",
    saID: "SPR1182",
  },
  {
    firstName: "LATE AMARENDRA NATH",
    lastName: "DUTTA",
    address: "HOOGHLY MOHASIN COLLEGE*PO-CHINSURAH*HOOGHLY",
    fullName: "LATE AMARENDRA NATH DUTTA",
    saID: "SPR1183",
  },
  {
    firstName: "LATE ANANDA",
    lastName: "DUTTA",
    address: "SATSANG*DEOGHAR*JHARKHAND 814116.",
    fullName: "LATE ANANDA DUTTA",
    saID: "SPR1184",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "DUTTA",
    address: "B.T.COLLEGE*PO-ABHAYNAGAR*AGARTALA-799005.TRIPURA",
    fullName: "ANANTA KUMAR DUTTA",
    saID: "SPR1185",
  },
  {
    firstName: "LATE ANIL CHANDRA",
    lastName: "DUTTA",
    address: "PO/VILL-UDALGURI*DARRANG",
    fullName: "LATE ANIL CHANDRA DUTTA",
    saID: "SPR1186",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "DUTTA",
    address: "C/O SWASTI HARDWARE STORE*PO-GAUR BAJAR*BURDWAN 713377",
    fullName: "LATE ANIL KUMAR DUTTA",
    saID: "SPR1187",
  },
  {
    firstName: "ATISENDRA",
    lastName: "DUTTA",
    address: "22/3A/2B,SRINATH MUKHERJEE LANE,PAIKPARA*CALCUTTA 700030.",
    fullName: "ATISENDRA DUTTA",
    saID: "SPR1188",
  },
  {
    firstName: "BHABENDU",
    lastName: "DUTTA",
    address:
      "KHARAGPUR SATSANG VIHAR*BHAWANIPUR*PO-KHARAGPUR*PASCHIM MEDINIPUR-721301.",
    fullName: "BHABENDU DUTTA",
    saID: "SPR1189",
  },
  {
    firstName: "BHABESH",
    lastName: "DUTTA",
    address: "AT-MURAGACHHA*PO-JUGBERIA*24PARGANAS NORTH-700110",
    fullName: "BHABESH DUTTA",
    saID: "SPR1190",
  },
  {
    firstName: "LATE BHABESH CHANDRA",
    lastName: "DUTTA",
    address: "VILL-JITPUR*PO-BHOLARDABRI*JALPAIGURI",
    fullName: "LATE BHABESH CHANDRA DUTTA",
    saID: "SPR1191",
  },
  {
    firstName: "LATE BIBHAS",
    lastName: "DUTTA",
    address: "37,SARAT CHATTERJEE ROAD*PO-NEW BARRACKPUR*24 PARAGANAS NORTH.",
    fullName: "LATE BIBHAS DUTTA",
    saID: "SPR1192",
  },
  {
    firstName: "LATE BIMAL PRASAD",
    lastName: "DUTTA",
    address: "P.H.E.OFFICE.POST BOX NO 54*JORHAT-785001",
    fullName: "LATE BIMAL PRASAD DUTTA",
    saID: "SPR1193",
  },
  {
    firstName: "LATE BINOY KRISHNA",
    lastName: "DUTTA",
    address: "AMINGAON BAZAR*GAUHATI 31*DIST KAMRUP",
    fullName: "LATE BINOY KRISHNA DUTTA",
    saID: "SPR1194",
  },
  {
    firstName: "LATE BIRENDRA LAL",
    lastName: "DUTTA",
    address: "VILL/PO ABHAYNAGAR*TRIPURA.",
    fullName: "LATE BIRENDRA LAL DUTTA",
    saID: "SPR1195",
  },
  {
    firstName: "LATE CHANDRA KANT",
    lastName: "DUTTA",
    address: "LOCO SHED*PO-LEDO*DIBRUGARH",
    fullName: "LATE CHANDRA KANT DUTTA",
    saID: "SPR1196",
  },
  {
    firstName: "CHIRARANJAN",
    lastName: "DUTTA",
    address: "99/94.JESSORE ROAD*BAPUJEE COLONY*DUM DUM*CALCUTTA-28.",
    fullName: "CHIRARANJAN DUTTA",
    saID: "SPR1197",
  },
  {
    firstName: "DEBENDRA KUMAR",
    lastName: "DUTTA",
    address: "VILL-KHUDRA SANKARA*PO-TERECHIA*DIST-NALBARI-781334*ASSAM",
    fullName: "DEBENDRA KUMAR DUTTA",
    saID: "SPR1198",
  },
  {
    firstName: "LATE DILIP",
    lastName: "DUTTA",
    address:
      "H/O GUNENDRA MOHAN GHOSH*PANIHATI GHOSHPARA*THAKURNITYAGOPAL RD*PO-PANIHATI*24 PGS.",
    fullName: "LATE DILIP DUTTA",
    saID: "SPR1199",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "DUTTA",
    address: "SAMUKTALA ROAD STATION*PO-MAZIDKHANA*JALPAIGURI",
    fullName: "LATE DINESH CHANDRA DUTTA",
    saID: "SPR1200",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "DUTTA",
    address: "BINAPANI HIGH SCHOOL*PO-DIPHU*KARBIANGLONG-782460*PAN-ACBPD9207B",
    fullName: "GANESH CHANDRA DUTTA",
    saID: "SPR1201",
  },
  {
    firstName: "LATE GOLAK CHANDRA",
    lastName: "DUTTA",
    address: "WARD NO-7*PO-NORTH LAKHIMPUR*DT-LAKHIMPUR.",
    fullName: "LATE GOLAK CHANDRA DUTTA",
    saID: "SPR1202",
  },
  {
    firstName: "HARADHAN",
    lastName: "DUTTA",
    address:
      "ASTT TEACHER*UDAIPUR HIGH SCHOOL*PO-RADHAKISHOREPUR*TRIPURA-799120",
    fullName: "HARADHAN DUTTA",
    saID: "SPR1203",
  },
  {
    firstName: "LATE INDRESWAR",
    lastName: "DUTTA",
    address: "ASSAM MEDICAL COLLEGE*PO&DIST-DIBRUGARH",
    fullName: "LATE INDRESWAR DUTTA",
    saID: "SPR1204",
  },
  {
    firstName: "LATE JAGAT BANDHU",
    lastName: "DUTTA",
    address: "VILL&PO-SAPEKHATI*DIST-SIBSAGAR.",
    fullName: "LATE JAGAT BANDHU DUTTA",
    saID: "SPR1205",
  },
  {
    firstName: "LATE JAMINI KUMAR",
    lastName: "DUTTA",
    address: "C/O POSTMASTER*PO-MUHURIPUR*TRIPURA",
    fullName: "LATE JAMINI KUMAR DUTTA",
    saID: "SPR1206",
  },
  {
    firstName: "LATE JAMINI MOHAN",
    lastName: "DUTTA",
    address: "SUPPLY INSPECTER*RABINDRANAGAR*LASKARPARA*PO-GARIFA*DIST-24-PGS.",
    fullName: "LATE JAMINI MOHAN DUTTA",
    saID: "SPR1207",
  },
  {
    firstName: "LATE JAMINI RANJAN",
    lastName: "DUTTA",
    address: "VILL-UPARSOLE*PO-METALI*VIA-MALIARA*BANKURA.",
    fullName: "LATE JAMINI RANJAN DUTTA",
    saID: "SPR1208",
  },
  {
    firstName: "LATE JATINDRA LAL",
    lastName: "DUTTA",
    address: "10/7,NAGENDRA NATH ROAD*CALCUTTA-700028",
    fullName: "LATE JATINDRA LAL DUTTA",
    saID: "SPR1209",
  },
  {
    firstName: "JITENDRA CHANDRA",
    lastName: "DUTTA",
    address:
      "SATSANG VIHAR JAMALDAHA*PO-JAMALDAHA*COOCHBEHAR-735303*PAN-AZRPD4107F",
    fullName: "JITENDRA CHANDRA DUTTA",
    saID: "SPR1210",
  },
  {
    firstName: "LATE KAMAL CHANDRA",
    lastName: "DUTTA",
    address: "KRISNANAGAR CITY RLY COLONY*PO.KRISNANAGAR *NADIA",
    fullName: "LATE KAMAL CHANDRA DUTTA",
    saID: "SPR1211",
  },
  {
    firstName: "KARUNA KANTA",
    lastName: "DUTTA",
    address: "VILL-ROYPUR*PO-PATHSALA*DIST-BARPETA-781325*ASSAM",
    fullName: "KARUNA KANTA DUTTA",
    saID: "SPR1212",
  },
  {
    firstName: "KHAGENDRA CHANDRA",
    lastName: "DUTTA",
    address: "SATSANG VIHAR*PO/DIST-SILCHAR-4*ASSAM",
    fullName: "KHAGENDRA CHANDRA DUTTA",
    saID: "SPR1213",
  },
  {
    firstName: "LATE LAXMINARAYAN",
    lastName: "DUTTA",
    address: "PO.ARANGHATA*NADIA.",
    fullName: "LATE LAXMINARAYAN DUTTA",
    saID: "SPR1214",
  },
  {
    firstName: "LATE MADHAB",
    lastName: "DUTTA",
    address: "VILL/PO-BISHRAMGANJ*TRIPURA-799103.",
    fullName: "LATE MADHAB DUTTA",
    saID: "SPR1215",
  },
  {
    firstName: "LATE MAHENDRA NATH",
    lastName: "DUTTA",
    address: "AT/PO-PATHSALA*DIST KAMRUP",
    fullName: "LATE MAHENDRA NATH DUTTA",
    saID: "SPR1216",
  },
  {
    firstName: "MANIKLAL",
    lastName: "DUTTA",
    address: "VILL-EAST CHARAKBARI*P.O.BAIKHORA*SOUTH TRIPURA",
    fullName: "MANIKLAL DUTTA",
    saID: "SPR1217",
  },
  {
    firstName: "LATE MANINDRA CHANDRA",
    lastName: "DUTTA",
    address: "PO-BAIRATIGURI * JALPAIGURI",
    fullName: "LATE MANINDRA CHANDRA DUTTA",
    saID: "SPR1218",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "DUTTA",
    address: "VILL&PO-RAJGRAM*DT-BIRBHUM.",
    fullName: "LATE MANINDRA NATH DUTTA",
    saID: "SPR1219",
  },
  {
    firstName: "LATE MANTU CHANDRA",
    lastName: "DUTTA",
    address: "VILL-LAXMIDANGA*PO-MAJDIA*NADIA",
    fullName: "LATE MANTU CHANDRA DUTTA",
    saID: "SPR1220",
  },
  {
    firstName: "LATE MEGHNAD",
    lastName: "DUTTA",
    address: "SUBHAS COLONY*PO-SODEPUR*DIST-24 PARGANAS.",
    fullName: "LATE MEGHNAD DUTTA",
    saID: "SPR1221",
  },
  {
    firstName: "MIHIR KANTI",
    lastName: "DUTTA",
    address: "AT-SUBHASHPOLLY*PO-MADHYAMGRAM*DIST-24 PGS (NORTH)-700129",
    fullName: "MIHIR KANTI DUTTA",
    saID: "SPR1222",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DUTTA",
    address: "C.I.T.B.R.  S.NO.-3,BLOCK NO.-9*59/60 BAGMARI ROAD *CALCUTTA-54",
    fullName: "LATE NANIGOPAL DUTTA",
    saID: "SPR1223",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "DUTTA",
    address: "KANTHAL PALLY*PO-CHAKDAHA*DT-NADIA.",
    fullName: "LATE NARENDRA NATH DUTTA",
    saID: "SPR1224",
  },
  {
    firstName: "LATE NEMAI CHANDRA",
    lastName: "DUTTA",
    address: "AT-SIMULTOLA*PO-MATIGONJ*VIA-BANGAON*DIST-24 PARGANAS",
    fullName: "LATE NEMAI CHANDRA DUTTA",
    saID: "SPR1225",
  },
  {
    firstName: "LATE NIHAR RANJAN",
    lastName: "DUTTA",
    address: "RETD.HEAD CLERK*LALDANGA*PO.ADRA*PURULIA",
    fullName: "LATE NIHAR RANJAN DUTTA",
    saID: "SPR1226",
  },
  {
    firstName: "LATE NRIPENDRA NARAYAN",
    lastName: "DUTTA",
    address: "PO/VILL-PRAFULLANAGAR*VIA-HABRA*DIST-24 PGS 743268",
    fullName: "LATE NRIPENDRA NARAYAN DUTTA",
    saID: "SPR1227",
  },
  {
    firstName: "PARESH NATH",
    lastName: "DUTTA",
    address: "AT-SALTORE*PO-SABRAKONE*DIST-BANKURA",
    fullName: "PARESH NATH DUTTA",
    saID: "SPR1228",
  },
  {
    firstName: "PHULENDRA",
    lastName: "DUTTA",
    address: "BIKASH NAGAR*BARO SAJAI*GUWAHATI 29",
    fullName: "PHULENDRA DUTTA",
    saID: "SPR1229",
  },
  {
    firstName: "LATE PRABHAT KUMAR",
    lastName: "DUTTA",
    address: "30/1 MOHAN BAGAN LANE*CALCUTTA-4",
    fullName: "LATE PRABHAT KUMAR DUTTA",
    saID: "SPR1230",
  },
  {
    firstName: "PRANTOSH",
    lastName: "DUTTA",
    address: "AT-LALPUR*PO.CHAKDAH*NADIA-741222",
    fullName: "PRANTOSH DUTTA",
    saID: "SPR1231",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "DUTTA",
    address: "68 SURYA SEN STREET*KOLKATA-700009",
    fullName: "RABINDRA NATH DUTTA",
    saID: "SPR1232",
  },
  {
    firstName: "LATE RAMENDRA KUMAR",
    lastName: "DUTTA",
    address: "PO-BANSKANDI*DT-CACHAR.",
    fullName: "LATE RAMENDRA KUMAR DUTTA",
    saID: "SPR1233",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "DUTTA",
    address: "STATISTIC OFFICE*PO NALBARI*DIST KAMRUP.",
    fullName: "RAMESH CHANDRA DUTTA",
    saID: "SPR1234",
  },
  {
    firstName: "RATIRANJAN",
    lastName: "DUTTA",
    address: "62/3 GARIFA MAIN RD *JADAVPUR*KOLKATA-700 075*PAN-ACPPD1281R",
    fullName: "RATIRANJAN DUTTA",
    saID: "SPR1235",
  },
  {
    firstName: "LATE SANAT KUMAR",
    lastName: "DUTTA",
    address: "RAMPURHAT SHYAMABAS*PO RAMPURHAT*DIST-BIRBHUM",
    fullName: "LATE SANAT KUMAR DUTTA",
    saID: "SPR1236",
  },
  {
    firstName: "SANKAR KUMAR",
    lastName: "DUTTA",
    address: "SATSANG VIHAR,PABITRAPARA*PO&DT-JALPAIGURI 735101*PAN-ADEPD3505G",
    fullName: "SANKAR KUMAR DUTTA",
    saID: "SPR1237",
  },
  {
    firstName: "SANKAR LAL",
    lastName: "DUTTA",
    address: "VILL-PABINCHHARA*PO-KUMARGHAT*TRIPURA (N)-799264",
    fullName: "SANKAR LAL DUTTA",
    saID: "SPR1238",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "DUTTA",
    address: "PO-CHAKDAHA*DT-NADIA.",
    fullName: "LATE SANTOSH KUMAR DUTTA",
    saID: "SPR1239",
  },
  {
    firstName: "LATE SASIBHUSAN",
    lastName: "DUTTA",
    address: "OLD POLICE LINE*PO&DIST-JALPAIGURI",
    fullName: "LATE SASIBHUSAN DUTTA",
    saID: "SPR1240",
  },
  {
    firstName: "LATE SATISH",
    lastName: "DUTTA",
    address: "46 EAST KAMALAPUR*DUM DUM*CALCUTTA-700028.",
    fullName: "LATE SATISH DUTTA",
    saID: "SPR1241",
  },
  {
    firstName: "SHIBA PRASAD",
    lastName: "DUTTA",
    address:
      '"SWASTI BAGH",SAROJINI PALLI*(2 1/2 MILE CHECK POST*PO-SALUGARA-734008*SILIGURI*W.B',
    fullName: "SHIBA PRASAD DUTTA",
    saID: "SPR1242",
  },
  {
    firstName: "SOURENDRA CHANDRA",
    lastName: "DUTTA",
    address: "BACHURDOBA*PO-JHARGRAM*PASCHIM MEDINIPUR 721 507",
    fullName: "SOURENDRA CHANDRA DUTTA",
    saID: "SPR1243",
  },
  {
    firstName: "SUDHANGSU SEKHAR",
    lastName: "DUTTA",
    address: "FIRST LINK ROAD,LANE NO-6,H.NO-46*PO/DIST-SILCHAR-788006*ASSAM",
    fullName: "SUDHANGSU SEKHAR DUTTA",
    saID: "SPR1244",
  },
  {
    firstName: "LATE SUDHANGSU SEKHAR",
    lastName: "DUTTA",
    address:
      "ARINDAM APARTMENT FLAT NO-1G*1649,GARIA STN ROAD,FARTABAD MORE*KOLKATA-84",
    fullName: "LATE SUDHANGSU SEKHAR DUTTA",
    saID: "SPR1245",
  },
  {
    firstName: "LATE SUJOY KUMAR",
    lastName: "DUTTA",
    address: "PO-BIDYAPARA*DIST-DHUBRI.",
    fullName: "LATE SUJOY KUMAR DUTTA",
    saID: "SPR1246",
  },
  {
    firstName: "LATE SUKRIT KUMAR",
    lastName: "DUTTA",
    address:
      "C/O SRI SAMIR KANTI DUTTA*RADHA GOBINDA COLONY*PO-SHEORAPHULI*HOOGHLY 712223",
    fullName: "LATE SUKRIT KUMAR DUTTA",
    saID: "SPR1247",
  },
  {
    firstName: "LATE SURAJIT",
    lastName: "DUTTA",
    address: "M/S LAXMI STORES,LABON*SHILLONG *MEGHALAYA",
    fullName: "LATE SURAJIT DUTTA",
    saID: "SPR1248",
  },
  {
    firstName: "SUSHANKAR",
    lastName: "DUTTA",
    address: "RADHAKISOREPUR*PO-KHILPARA*TRIPURA-799114",
    fullName: "SUSHANKAR DUTTA",
    saID: "SPR1249",
  },
  {
    firstName: "LATE UMAPADA",
    lastName: "DUTTA",
    address: "VILL-SUTRA*PO.GOURIPUR*VIA CHAKDAH*NADIA",
    fullName: "LATE UMAPADA DUTTA",
    saID: "SPR1250",
  },
  {
    firstName: "LATE ARUN KIRAN",
    lastName: "DUTTAJOARDAR",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "LATE ARUN KIRAN DUTTAJOARDAR",
    saID: "SPR1251",
  },
  {
    firstName: "LATE RANENDRA CHANDRA",
    lastName: "DUTTAKANUNGO",
    address:
      "H/O SUDHIR RN.DASGUPTA*ITKHOLA OPP-IDGAH*PO-SILCHAR*CACHAR 788001",
    fullName: "LATE RANENDRA CHANDRA DUTTAKANUNGO",
    saID: "SPR1252",
  },
  {
    firstName: "LATE PRITIMOY",
    lastName: "DUTTACHOWDHURY",
    address: "PO/VILL-NILAM BAZAR*DIST-KARIMGANJ-788722.",
    fullName: "LATE PRITIMOY DUTTACHOWDHURY",
    saID: "SPR1253",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "DWIVEDY",
    address: "AT/PO-PANPOSH*ROURKELA-769004*SUNDARGARH",
    fullName: "PRAVAT KUMAR DWIVEDY",
    saID: "SPR1254",
  },
  {
    firstName: "LATE CHANDRA KANT",
    lastName: "ENGTI",
    address: "DIPHU CIVIL HOSPITAL*P.O.DIPHU*KARBIANGLONG.",
    fullName: "LATE CHANDRA KANT ENGTI",
    saID: "SPR1255",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "FAUJDAR",
    address: "VILL/PO-KULTI*DIST-KHULNA *BANGLADESH",
    fullName: "MRINAL KANTI FAUJDAR",
    saID: "SPR1256",
  },
  {
    firstName: "PRAMATHA NATH",
    lastName: "FAUZDAR",
    address: "VILL-BATIAGHATA*PO KANCHANNAGAR*KHULNA*BANGLADESH",
    fullName: "PRAMATHA NATH FAUZDAR",
    saID: "SPR1257",
  },
  {
    firstName: "CHENIRAM",
    lastName: "GAGAI",
    address: "AMGURI BHUYANGAON*PO-AMGURI*SIBSAGAR 785680.",
    fullName: "CHENIRAM GAGAI",
    saID: "SPR1258",
  },
  {
    firstName: "LATE KHAGESWAR",
    lastName: "GAGOI",
    address: "BHOJO M E SCHOOL*PO-BHOJO*SIBSAGAR",
    fullName: "LATE KHAGESWAR GAGOI",
    saID: "SPR1259",
  },
  {
    firstName: "LATE ANIL",
    lastName: "GANGULI",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE ANIL GANGULI",
    saID: "SPR1260",
  },
  {
    firstName: "LATE HARI CHARAN",
    lastName: "GANGULI",
    address: "1B,KANSARIPARA*CALCUTTA-700025",
    fullName: "LATE HARI CHARAN GANGULI",
    saID: "SPR1261",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "GANGOPADHYAY",
    address: "SATSANG*DEOGHAR-814116",
    fullName: "LATE AJIT KUMAR GANGOPADHYAY",
    saID: "SPR1262",
  },
  {
    firstName: "LATE AJOY NATH",
    lastName: "GANGULY",
    address: "*SATSANG-814116",
    fullName: "LATE AJOY NATH GANGULY",
    saID: "SPR1263",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "GANGULY",
    address: "*SATSANG 814 116.",
    fullName: "ARUN KUMAR GANGULY",
    saID: "SPR1264",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "GANGULY",
    address: "SATSANG*DEOGHAR",
    fullName: "ASHUTOSH GANGULY",
    saID: "SPR1265",
  },
  {
    firstName: "BIRESH KUMAR",
    lastName: "GANGULY",
    address: "WARD NO-1*PO-GAURIPUR.PIN-783331*DHUBRI",
    fullName: "BIRESH KUMAR GANGULY",
    saID: "SPR1266",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "GANGULY",
    address: "KHABERA ROAD*PO/DT-MUJAFFARPUR",
    fullName: "LATE MANMATHA NATH GANGULY",
    saID: "SPR1267",
  },
  {
    firstName: "LATE NIROD",
    lastName: "GANGULY",
    address: "BARANILPUR*BURDWAN.",
    fullName: "LATE NIROD GANGULY",
    saID: "SPR1268",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "GANGULY",
    address:
      "C/O JADAV LAL GANGULY*198/C CENTRAL COLONY *PO-BHAKTINAGAR*JALPAIGURI",
    fullName: "LATE PANCHANAN GANGULY",
    saID: "SPR1269",
  },
  {
    firstName: "LATE PARESH KUMAR",
    lastName: "GANGOPADHYAY",
    address:
      "ZERO LANE-W.NO-16,RUBBER BAGAN*OPP IRR COMPLEX,DARRANG*PO-TEZPUR*SONITPUR-784001",
    fullName: "LATE PARESH KUMAR GANGOPADHYAY",
    saID: "SPR1270",
  },
  {
    firstName: "LATE PULIN BEHARI",
    lastName: "GANGULY",
    address: "48.CHURAMANI CHOWDHURY LANE*PO-BERHAMPUR*DIST-MURSHIDABAD.",
    fullName: "LATE PULIN BEHARI GANGULY",
    saID: "SPR1271",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "GANGULY",
    address:
      "SCHOOL OF LIFE SCIENCES*JAWAHARLAL NEHRU UNIVERSITY*N.DELHI-110 067*PAN-AMAPG3246J",
    fullName: "SHYAMAL KUMAR GANGULY",
    saID: "SPR1272",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "GANGULY",
    address: "GHOSHPARA*PO-CHAKDAHA*DT-NADIA.",
    fullName: "LATE SUDHIR KUMAR GANGULY",
    saID: "SPR1273",
  },
  {
    firstName: "SUKHENDU",
    lastName: "GANGULY",
    address: "VILL-SUKLALPUR*PO-GEONKHALI*DIST-PURBA MEDINIPUR-721603",
    fullName: "SUKHENDU GANGULY",
    saID: "SPR1274",
  },
  {
    firstName: "LATE TARASANKAR",
    lastName: "GANGULY",
    address: "V-SUKLALPUR*PO-GEONKHALI*DIST-MIDNAPUR.",
    fullName: "LATE TARASANKAR GANGULY",
    saID: "SPR1275",
  },
  {
    firstName: "CHANDRASEN",
    lastName: "GANGWAR",
    address: "5 DWARIKESH*HARGOVIND NAGAR*STADIUM ROAD*BAREILLY*U P 243122",
    fullName: "CHANDRASEN GANGWAR",
    saID: "SPR1276",
  },
  {
    firstName: "LATE HARADHAN",
    lastName: "GARANG",
    address: "AT-BHARAMALYAPUR*PO-SOMSERPUR*DIST-HOOGHLY 712410",
    fullName: "LATE HARADHAN GARANG",
    saID: "SPR1277",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "GATIDAR",
    address:
      "C/O-SARASWATI GATIDAR*17 NO DURGAPUR*PO.16 NO CHITTARANJAN,ASHTI*GORCHIRULI(M.S)",
    fullName: "LATE HARENDRA NATH GATIDAR",
    saID: "SPR1278",
  },
  {
    firstName: "JOGESWAR",
    lastName: "GAYARI",
    address: "VILL-MERBANGCHUBA*THACHOBARI*PO-BATAKUCHI*DARRANG 784510",
    fullName: "JOGESWAR GAYARI",
    saID: "SPR1279",
  },
  {
    firstName: "KARENDRA NATH",
    lastName: "GAYARI",
    address: "SATSANG VIHAR *AT/PO-KOKRAJHAR*KOKRAJHAR 783370*ASSAM",
    fullName: "KARENDRA NATH GAYARI",
    saID: "SPR1280",
  },
  {
    firstName: "LATE LALIT MOHAN",
    lastName: "GAYARY",
    address: "AT-KATRIBARI*PO-SARAIBIL*GOALPARA.",
    fullName: "LATE LALIT MOHAN GAYARY",
    saID: "SPR1281",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "GAYEN",
    address: "SATSANG VIHAR CHIMTA*PO-CHIMTA KACHARI*VIA-BHEBIA*24 PARGANAS.",
    fullName: "LATE BASANTA KUMAR GAYEN",
    saID: "SPR1282",
  },
  {
    firstName: "BHIMPADA",
    lastName: "GAYEN",
    address:
      "SARANKUTHI*VILL/PO-LAKSHYA*DIST-PURBA MEDINIPUR-721654*PAN-ADGPG6421L",
    fullName: "BHIMPADA GAYEN",
    saID: "SPR1283",
  },
  {
    firstName: "DURLOV CHANDRA",
    lastName: "GAYEN",
    address: "JAMGURIHAT BATIARAKHA KENDRA*PO-GUMRIPOOL*DIST DARRANG.",
    fullName: "DURLOV CHANDRA GAYEN",
    saID: "SPR1284",
  },
  {
    firstName: "LATE KHAGENDRA NATH",
    lastName: "GAYEN",
    address: "AT&PO-DHUCHNIKHALI*24-PARGANAS",
    fullName: "LATE KHAGENDRA NATH GAYEN",
    saID: "SPR1285",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "GAYEN",
    address:
      "VILL-SUNDARNAGAR C-NO-206*PO-KSHUDIRAMPALLI*DIST-GARHCHIROLI*M.S. 442707.",
    fullName: "MADHUSUDAN GAYEN",
    saID: "SPR1286",
  },
  {
    firstName: "LATE MANIMOHAN",
    lastName: "GAYEN",
    address: "AT&PO-KOLSUR*DIST-24 PGS (NORTH)-743438",
    fullName: "LATE MANIMOHAN GAYEN",
    saID: "SPR1287",
  },
  {
    firstName: "SANAT KUMAR",
    lastName: "GAYEN",
    address: "PO+VILL-BANKRA*VIA-HINGALGONJ*DIST-24 PARGANAS (NORTH)-743435",
    fullName: "SANAT KUMAR GAYEN",
    saID: "SPR1288",
  },
  {
    firstName: "LATE SUDHIR KRISHNA",
    lastName: "GAYEN",
    address: "KHANPUKURIA*PO-HASNABAD SEMULIA*24PARGANAS NORTH.",
    fullName: "LATE SUDHIR KRISHNA GAYEN",
    saID: "SPR1289",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "GAIN",
    address:
      "18/12,BIJOY NAGAR*PO-KAZIPARA*PS-BARASAT *24PARGANAS NORTH-700125",
    fullName: "SUNIL KUMAR GAIN",
    saID: "SPR1290",
  },
  {
    firstName: "LATE TARINI MOHAN",
    lastName: "GAYEN",
    address: "AT&PO-GHATESWAR*DIST-24 PGS.",
    fullName: "LATE TARINI MOHAN GAYEN",
    saID: "SPR1291",
  },
  {
    firstName: "NIKHIL KUMAR",
    lastName: "GHATAK",
    address: "*SATSANG 814116.",
    fullName: "NIKHIL KUMAR GHATAK",
    saID: "SPR1292",
  },
  {
    firstName: "NITYANANDA",
    lastName: "GHATAK",
    address: "A-10/68 KALYANI*PO.KALYANI*NADIA-741235",
    fullName: "NITYANANDA GHATAK",
    saID: "SPR1293",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "GHATAK",
    address: "VILL-BHAKURI.PO-CHALTIA*DIST-MURSHIDABAD-742165",
    fullName: "LATE SATISH CHANDRA GHATAK",
    saID: "SPR1294",
  },
  {
    firstName: "BISHNUPADA",
    lastName: "GHATI",
    address: "AT-KHASBATI*PO-MALANCHA*DIST-24 PGS.",
    fullName: "BISHNUPADA GHATI",
    saID: "SPR1295",
  },
  {
    firstName: "LATE ABHAY PADA",
    lastName: "GHOSAL",
    address: "ENGLISH BAJAR*PO&DT-MALDA.",
    fullName: "LATE ABHAY PADA GHOSAL",
    saID: "SPR1296",
  },
  {
    firstName: "BIDHAN CHANDRA",
    lastName: "GHOSAL",
    address: "VILL/PO-PURBAPUTIARY*DAKSHIN PARA*CALCUTTA-700093.",
    fullName: "BIDHAN CHANDRA GHOSAL",
    saID: "SPR1297",
  },
  {
    firstName: "GANGADHAR",
    lastName: "GHOSAL",
    address: "PO-BARSUL*DIST-BURDWAN.",
    fullName: "GANGADHAR GHOSAL",
    saID: "SPR1298",
  },
  {
    firstName: "JAJNESWAR",
    lastName: "GHOSAL",
    address: "SARKAR BAGAN LANE*PO-BHADRESWAR*DIST.HOOGHLY 712124",
    fullName: "JAJNESWAR GHOSAL",
    saID: "SPR1299",
  },
  {
    firstName: "LATE NETAI CHANDRA",
    lastName: "GHOSAL",
    address: "9 DUTTABAD ROAD*CALCUTTA-64",
    fullName: "LATE NETAI CHANDRA GHOSAL",
    saID: "SPR1300",
  },
  {
    firstName: "LATE NIRAPADA",
    lastName: "GHOSE",
    address: "BANAGRAM COLLAGE ROAD*KHAIRAMARI*PO-BANAGRAM*DIST-24PARGANAS",
    fullName: "LATE NIRAPADA GHOSE",
    saID: "SPR1301",
  },
  {
    firstName: "AMALESH KUMAR",
    lastName: "GHOSH",
    address: "VILL-NABASAN*PO-SORISHA*24PARGANAS (SOUTH)-743368.",
    fullName: "AMALESH KUMAR GHOSH",
    saID: "SPR1302",
  },
  {
    firstName: "AMITAVO",
    lastName: "GHOSH",
    address: "47 DR G C GOSWAMI STREET*PO-SERAMPORE*HOOGHLY-712201",
    fullName: "AMITAVO GHOSH",
    saID: "SPR1303",
  },
  {
    firstName: "LATE AMIYA RANJAN",
    lastName: "GHOSH",
    address:
      "C/O MRIYUNJOY GHOSH*5/5,SAI REGENCY,TAPARIA COMPLEX*JUGSALAI*JAMSHEDPUR-831006.",
    fullName: "LATE AMIYA RANJAN GHOSH",
    saID: "SPR1304",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "GHOSH",
    address: "VILL/PO.TINNA(PANDUA)*DIST.HOOGHLY.",
    fullName: "LATE ANIL KUMAR GHOSH",
    saID: "SPR1305",
  },
  {
    firstName: "ANANDA MOHAN",
    lastName: "GHOSH",
    address: "AT-NAGARKONA*PO-PALLA ROAD*DIST-BURDWAN-713151",
    fullName: "ANANDA MOHAN GHOSH",
    saID: "SPR1306",
  },
  {
    firstName: "LATE ANUKUL CHANDRA",
    lastName: "GHOSH",
    address: "AT-BALIACHANDI*PO-GOCHARAN*DIST-24 PGS.",
    fullName: "LATE ANUKUL CHANDRA GHOSH",
    saID: "SPR1307",
  },
  {
    firstName: "LATE ASIT KUMAR",
    lastName: "GHOSH",
    address: "AT.BHARATNAGAR*PO.RABINDRASARANI*SILIGURI-734406*DARJEELING",
    fullName: "LATE ASIT KUMAR GHOSH",
    saID: "SPR1308",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "GHOSH",
    address:
      "FLAT NO-351;D D A SFS FLATS*POCKET NO-2 SECTOR NO-9*DWARKA*NEWDELHI 110075",
    fullName: "ASWINI KUMAR GHOSH",
    saID: "SPR1309",
  },
  {
    firstName: "BAIDYANATH",
    lastName: "GHOSH",
    address:
      "RAIGANJ SATSANG VIHAR*PO-RAIGANJ*UTTAR DINAJPUR-733134*PAN-AURPG6104A",
    fullName: "BAIDYANATH GHOSH",
    saID: "SPR1310",
  },
  {
    firstName: "LATE BALAI CHANDRA",
    lastName: "GHOSH",
    address: "AT NUTAN AMARPUR PO JOTRAM*DIST BURDWAN",
    fullName: "LATE BALAI CHANDRA GHOSH",
    saID: "SPR1311",
  },
  {
    firstName: "BALAI CHANDRA",
    lastName: "GHOSE",
    address:
      "VILL-KASHIPUR*PO-KUMRAKASHIPUR*VIA-HABRA*DIST-24 PARGANAS (NORTH)-743271",
    fullName: "BALAI CHANDRA GHOSE",
    saID: "SPR1312",
  },
  {
    firstName: "BALAI CHANDRA",
    lastName: "GHOSH",
    address: "AT-NAGARKONA*PO-PALLA ROAD*DIST-BURDWAN-713151*PAN-ACUPG7523J",
    fullName: "BALAI CHANDRA GHOSH",
    saID: "SPR1313",
  },
  {
    firstName: "LATE BALARAM",
    lastName: "GHOSH",
    address: "PO-BARA BAINAN*DT-BURDWAN.",
    fullName: "LATE BALARAM GHOSH",
    saID: "SPR1314",
  },
  {
    firstName: "LATE BANAMALI",
    lastName: "GHOSH",
    address: "PO-METIA*DIST-24 PGS.",
    fullName: "LATE BANAMALI GHOSH",
    saID: "SPR1315",
  },
  {
    firstName: "BASUDEB",
    lastName: "GHOSH",
    address: "68 SURYA SEN STREET*CALCUTTA-700009*PAN-BNUPG2717A",
    fullName: "BASUDEB GHOSH",
    saID: "SPR1316",
  },
  {
    firstName: "LATE BHABANIPATI",
    lastName: "GHOSH",
    address: "LAL BAZAR*PO BARIPADA*DIST MAYURBHANJ",
    fullName: "LATE BHABANIPATI GHOSH",
    saID: "SPR1317",
  },
  {
    firstName: "BIMAL CHANDRA",
    lastName: "GHOSH",
    address: "AT&PO.MAYAKUL*VIA-BAHADURPUR*NADIA",
    fullName: "BIMAL CHANDRA GHOSH",
    saID: "SPR1318",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "GHOSH",
    address: "AT/PO-POLBA*DIST-HOOGHLY",
    fullName: "BIRENDRA NATH GHOSH",
    saID: "SPR1319",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "GHOSH",
    address: "PLOT NO-N 3/422*IRC VILLAGE*NAYAPALLI*BHUBANESWAR-751015*KHURDA",
    fullName: "LATE BISWANATH GHOSH",
    saID: "SPR1320",
  },
  {
    firstName: "LATE CHARU CHANDRA",
    lastName: "GHOSH",
    address: "PO-DURGAPUR-1*DT-BURDWAN.",
    fullName: "LATE CHARU CHANDRA GHOSH",
    saID: "SPR1321",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "GHOSH",
    address: "SATSANG VIHAR*PO-DALKHOLA*UTTAR DINAJPUR",
    fullName: "LATE CHITTARANJAN GHOSH",
    saID: "SPR1322",
  },
  {
    firstName: "DASUDEB",
    lastName: "GHOSH",
    address:
      "SATSANG VIHAR DALKHOLA*DALKHOLA*UTTAR DINAJPUR-733201*PAN-BSZPG9311E",
    fullName: "DASUDEB GHOSH",
    saID: "SPR1323",
  },
  {
    firstName: "DEBASIS",
    lastName: "GHOSH",
    address:
      "14, RISHI BANKIM CH ST.*PO-KONNAGAR 712235*DIST-HOOGHLY*PAN-ADPPG2787A",
    fullName: "DEBASIS GHOSH",
    saID: "SPR1324",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "GHOSH",
    address: "MANASAPOTA*PO-SIMURALI*NADIA-741248*PAN-AYWPG6647D",
    fullName: "DILIP KUMAR GHOSH",
    saID: "SPR1325",
  },
  {
    firstName: "LATE DILIP KUMAR",
    lastName: "GHOSH",
    address:
      "I-303 CAPRICCIO SOCIETY*DATTA MANDIR ROAD*WAKAD*PUNE 411 057  M.S.",
    fullName: "LATE DILIP KUMAR GHOSH",
    saID: "SPR1326",
  },
  {
    firstName: "LATE DURGAPADA",
    lastName: "GHOSH",
    address:
      "VILL-RADHARAMANPUR*PO-PIARBERA*VIA-PAKHANNA*BANKURA-722208*PAN-AUQPG7936F",
    fullName: "LATE DURGAPADA GHOSH",
    saID: "SPR1327",
  },
  {
    firstName: "LATE GOURLAL",
    lastName: "GHOSE",
    address: "SATSANG ADHIBESAN KENDRA*PO-GANGARAMPUR*DAKSHIN DINAJPUR",
    fullName: "LATE GOURLAL GHOSE",
    saID: "SPR1328",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "GHOSH",
    address: "VILL-MADHUPUR*PO-BADU*DIST-24 PGS.",
    fullName: "LATE HARIPADA GHOSH",
    saID: "SPR1329",
  },
  {
    firstName: "HARIPADA",
    lastName: "GHOSH",
    address:
      "RENTAL HOUSING ESTATE,KALYANI*FLAT NO-TYPE D/1,E/5,CAT-IV*PO-KALYANI*NADIA 741235",
    fullName: "HARIPADA GHOSH",
    saID: "SPR1330",
  },
  {
    firstName: "LATE HIRENDRA NATH",
    lastName: "GHOSH",
    address: "511 RABINDRA SARANI*CALCUTTA-5",
    fullName: "LATE HIRENDRA NATH GHOSH",
    saID: "SPR1331",
  },
  {
    firstName: "INDRAJIT",
    lastName: "GHOSH",
    address: "VILL&PO-RAGHUNATHGANJ*PHANSITOLA*DIST-MURSHIDABAD-742225",
    fullName: "INDRAJIT GHOSH",
    saID: "SPR1332",
  },
  {
    firstName: "JAGANNATH",
    lastName: "GHOSH",
    address: "VILL/PO-HARIT*DIST-HOOGHLY-712305",
    fullName: "JAGANNATH GHOSH",
    saID: "SPR1333",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "GHOSH",
    address:
      "315,R B AVENUE BYE LANE*PO-BHADRESWAR*HOOGHLY 712 124*PAN-ADHPG0790K",
    fullName: "JAYANTA KUMAR GHOSH",
    saID: "SPR1334",
  },
  {
    firstName: "KALI KINKAR",
    lastName: "GHOSH",
    address:
      "VILL-KERANICHATI*PO-BHADUTALA*PASCHIM MEDINIPUR-721129*PAN-AYEPG1295P",
    fullName: "KALI KINKAR GHOSH",
    saID: "SPR1335",
  },
  {
    firstName: "LATE KANAI LAL",
    lastName: "GHOSH",
    address: "SRIRAMPUR(MATHPARA)*PO-KESHABPUR*VIA-SEHARA*DIST-BURDWAN",
    fullName: "LATE KANAI LAL GHOSH",
    saID: "SPR1336",
  },
  {
    firstName: "LATE KANGALI CHARAN",
    lastName: "GHOSH",
    address: "AT&PO.TALOKPUR*NADIA",
    fullName: "LATE KANGALI CHARAN GHOSH",
    saID: "SPR1337",
  },
  {
    firstName: "KINKAR CHANDRA",
    lastName: "GHOSH",
    address: "AT SRIRAMPUR*PO KESHABPUR*DIST BURDWAN-713423",
    fullName: "KINKAR CHANDRA GHOSH",
    saID: "SPR1338",
  },
  {
    firstName: "LATE KINKAR PRASAD",
    lastName: "GHOSH",
    address: "VILL&PO-PALBA*DIST.HOOGHLY-712148",
    fullName: "LATE KINKAR PRASAD GHOSH",
    saID: "SPR1339",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "GHOSH",
    address: "VILL-DYAMRA*PO-MAGRA*DIST.HOOGHLY",
    fullName: "KRISHNA CHANDRA GHOSH",
    saID: "SPR1340",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "GHOSE",
    address: "AT-MODHUPUR*PO-BADU*DIST-24 PGS (NORTH)-700128",
    fullName: "KRISHNA CHANDRA GHOSE",
    saID: "SPR1341",
  },
  {
    firstName: "LATE LAL MOHAN",
    lastName: "GHOSH",
    address: "QR NO-219B *NEW COLONY*PO&DT-BONGAIGAON",
    fullName: "LATE LAL MOHAN GHOSH",
    saID: "SPR1342",
  },
  {
    firstName: "MADAN GOPAL",
    lastName: "GHOSH",
    address: "SEKENDARPUR SWASTI SIBIR*PO-AMRITI*DIST-MALDA-732208",
    fullName: "MADAN GOPAL GHOSH",
    saID: "SPR1343",
  },
  {
    firstName: "LATE MADHAI CHANDRA",
    lastName: "GHOSH",
    address: "VILL.CHANDRAHATI *PO.PANDUA*DIST.HOOGHLY",
    fullName: "LATE MADHAI CHANDRA GHOSH",
    saID: "SPR1344",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "GHOSH",
    address: '"JIBAN SMRITI"*78/A, J.P. ROAD*PO-SAHAGANJ*DT-HOOGHLY-712104.',
    fullName: "MADHUSUDAN GHOSH",
    saID: "SPR1345",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "GHOSH",
    address: "PANINALA*KRISHNAGAR*PO-GHURNI*NADIA 741103",
    fullName: "MADHUSUDAN GHOSH",
    saID: "SPR1346",
  },
  {
    firstName: "LATE MAHADEV",
    lastName: "GHOSH",
    address: "AT SRIRAMPUR MATHPARA*PO KESHABPUR*DIST BURDWAN",
    fullName: "LATE MAHADEV GHOSH",
    saID: "SPR1347",
  },
  {
    firstName: "MANORANJAN",
    lastName: "GHOSH",
    address:
      "C/O SATSANG VIHAR*AT/PO-NEW COOCH BEHAR*COOCH BEHAR-736179*PAN-BDOPG5996B",
    fullName: "MANORANJAN GHOSH",
    saID: "SPR1348",
  },
  {
    firstName: "LATE NANDA DULAL",
    lastName: "GHOSH",
    address: "AT-DHIAPUR*PO-JHIKURIA*DIST-MIDNAPUR",
    fullName: "LATE NANDA DULAL GHOSH",
    saID: "SPR1349",
  },
  {
    firstName: "LATE NANDALAL",
    lastName: "GHOSH",
    address: "AT-PANINALA*PO-KRISNANAGAR*GHURNI*NADIA",
    fullName: "LATE NANDALAL GHOSH",
    saID: "SPR1350",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "GHOSH",
    address: "AT+PO-KHALISADI*DIST-24 PGS.",
    fullName: "NANIGOPAL GHOSH",
    saID: "SPR1351",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "GHOSH",
    address: "MANORAMA BHAWAN RAKHALDIGHI*PO-PANDAPARA KALIBARI*JALPAIGURI",
    fullName: "LATE NANIGOPAL GHOSH",
    saID: "SPR1352",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "GHOSH",
    address: "AT-GANGPUR*PO-JOTRAM*DIST-BURDWAN",
    fullName: "LATE NANIGOPAL GHOSH",
    saID: "SPR1353",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "GHOSH",
    address: "SATSANG VIHAR,AMRITY*PO-AMRITY*MALDA-732208*PAN-BMOPG1782J",
    fullName: "NARAYAN CHANDRA GHOSH",
    saID: "SPR1354",
  },
  {
    firstName: "NEMAI CHANDRA",
    lastName: "GHOSH",
    address: "SRINATHPUR*PO.-SRIMAYAPUR*NADIA-741313*PAN-BKVPG5078E",
    fullName: "NEMAI CHANDRA GHOSH",
    saID: "SPR1355",
  },
  {
    firstName: "NETAI PADA",
    lastName: "GHOSH",
    address: "VILL-PURNAGANJ*PO-MAJDIA*NADIA 741 507.",
    fullName: "NETAI PADA GHOSH",
    saID: "SPR1356",
  },
  {
    firstName: "NRIPATI RANJAN",
    lastName: "GHOSH",
    address: "SATSANG VIHAR*PO-ALIPURDUAR JUNCTION*JALPAIGURI",
    fullName: "NRIPATI RANJAN GHOSH",
    saID: "SPR1357",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "GHOSE",
    address: "NEW UKILPARA*PO.RAIGANJ*UTTAR DINAJPUR-733134",
    fullName: "LATE PANCHANAN GHOSE",
    saID: "SPR1358",
  },
  {
    firstName: "LATE PARBATI PRASAD",
    lastName: "GHOSH",
    address: "AT/PO-BARABAINAN*VIA-RAINA*DIST-BURDWAN",
    fullName: "LATE PARBATI PRASAD GHOSH",
    saID: "SPR1359",
  },
  {
    firstName: "PARITOSH KUMAR",
    lastName: "GHOSH",
    address: '"SATSANG ASRAM"*PO-BHAKTINAGAR*DIST-JALPAIGURI*PIN-734 007.',
    fullName: "PARITOSH KUMAR GHOSH",
    saID: "SPR1360",
  },
  {
    firstName: "PHANINDRA MOHAN",
    lastName: "GHOSH",
    address: "VILL&PO-BARASAKDAL*DT-COOCHBEHAR.",
    fullName: "PHANINDRA MOHAN GHOSH",
    saID: "SPR1361",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "GHOSH",
    address: "MAHANANDA COLONY*PO-JHALJHALIA R.C.*DIST-MALDA",
    fullName: "LATE PRAFULLA KUMAR GHOSH",
    saID: "SPR1362",
  },
  {
    firstName: "PRAMATHA NATH",
    lastName: "GHOSH",
    address: "VILL-PANINALA*PO-BHANDARKOLA*DIST-NADIA-741103",
    fullName: "PRAMATHA NATH GHOSH",
    saID: "SPR1363",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "GHOSH",
    address: "316 G.T.ROAD*PO.SHIBPUR*DT-HOWRAH-711102",
    fullName: "RABINDRA NATH GHOSH",
    saID: "SPR1364",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "GHOSH",
    address: "AT&PO.TILAKPUR*NADIA-741164*PAN-AEAPG8479B",
    fullName: "RANJIT KUMAR GHOSH",
    saID: "SPR1365",
  },
  {
    firstName: "LATE SADHAN CHANDRA",
    lastName: "GHOSH",
    address: "HASADANGA*PO-DHUBULIA*NADIA-741139",
    fullName: "LATE SADHAN CHANDRA GHOSH",
    saID: "SPR1366",
  },
  {
    firstName: "SAILENDRA NATH",
    lastName: "GHOSH",
    address: "VILL-BARANILPUR*NEAR BHANGA MASJID*PO-SRIPALLY*BURDWAN.",
    fullName: "SAILENDRA NATH GHOSH",
    saID: "SPR1367",
  },
  {
    firstName: "LATE SAMBHU NATH",
    lastName: "GHOSH",
    address: "396,G.T.ROAD TOWNHALLPARA*PO/DIST BURDWAN",
    fullName: "LATE SAMBHU NATH GHOSH",
    saID: "SPR1368",
  },
  {
    firstName: "LATE SANKAR PADA",
    lastName: "GHOSH",
    address: "R.K.PALLY*NORTH INDA*PO-KHARAGPUR*DIST-MIDNAPORE",
    fullName: "LATE SANKAR PADA GHOSH",
    saID: "SPR1369",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "GHOSH",
    address: "VILL-MASHYAMPUR*PO-BAGDA*DIST-24 PGS (NORTH)-743232",
    fullName: "SANTOSH KUMAR GHOSH",
    saID: "SPR1370",
  },
  {
    firstName: "SHANTIMAY",
    lastName: "GHOSH",
    address: "AT-NIAL *PO.BALLALDIGHI*DIST-HOOGHLY-712135",
    fullName: "SHANTIMAY GHOSH",
    saID: "SPR1371",
  },
  {
    firstName: "LATE SHYAMA PRASAD",
    lastName: "GHOSH",
    address: "NABANAGAR (BADAMTOLA)*PO-DUILLYA*HOWRAH.",
    fullName: "LATE SHYAMA PRASAD GHOSH",
    saID: "SPR1372",
  },
  {
    firstName: "SHIBAPADA",
    lastName: "GHOSH",
    address: "AT-BADE-KHANTURA*PO-KHANTURA*DIST-24 PARGANAS NORTH-743273",
    fullName: "SHIBAPADA GHOSH",
    saID: "SPR1373",
  },
  {
    firstName: "LATE SIBAPADA",
    lastName: "GHOSH",
    address: "VIL&PO.DALIMOULA* NADIA",
    fullName: "LATE SIBAPADA GHOSH",
    saID: "SPR1374",
  },
  {
    firstName: "LATE SITA RAM",
    lastName: "GHOSH",
    address: "VILL/PO RAINA*DIST-BARDDHAMAN",
    fullName: "LATE SITA RAM GHOSH",
    saID: "SPR1375",
  },
  {
    firstName: "LATE SMARAJIT",
    lastName: "GHOSH",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SMARAJIT GHOSH",
    saID: "SPR1376",
  },
  {
    firstName: "SRISTIDHAR",
    lastName: "GHOSH",
    address: "VILL-KARSANA*PO-TANGRA*NADIA-741506*PAN-ADZPB6728M",
    fullName: "SRISTIDHAR GHOSH",
    saID: "SPR1377",
  },
  {
    firstName: "LATE SUDHANGSHU SEKHAR",
    lastName: "GHOSH",
    address: "MANKAR P.H.C*PO-MANKAR*DIST-BURDWAN",
    fullName: "LATE SUDHANGSHU SEKHAR GHOSH",
    saID: "SPR1378",
  },
  {
    firstName: "LATE SUDHASINDHU",
    lastName: "GHOSH",
    address: "AT/PO-GOCHARAN*DIST-24PARGANAS",
    fullName: "LATE SUDHASINDHU GHOSH",
    saID: "SPR1379",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "GHOSH",
    address: "AT-MADIAN*PO-GANPUR*DIST-BIRBHUM",
    fullName: "LATE SUDHIR KUMAR GHOSH",
    saID: "SPR1380",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "GHOSH",
    address: "AT-PALANGSUGURI*PO-JOYPUR*VIA-BIJNI*GOALPARA",
    fullName: "LATE SUDHIR KUMAR GHOSH",
    saID: "SPR1381",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "GHOSH",
    address: "VILL/PO.TINNA*DIST.HOOGHLY",
    fullName: "LATE SUNIL KUMAR GHOSH",
    saID: "SPR1382",
  },
  {
    firstName: "SURENDRA",
    lastName: "GHOSH",
    address:
      "C/O PRANAGOPAL M BHANDAR*G B BAZAR,ITI RD*PO-KUNJABAN*AGARTALA-799006*TRIPURA WEST",
    fullName: "SURENDRA GHOSH",
    saID: "SPR1383",
  },
  {
    firstName: "LATE SWAPAN",
    lastName: "GHOSH",
    address: "HAPANIA SATSANG KENDRA*NETAJINAGAR*PO-ONGC COLONY*WEST TRIPURA.",
    fullName: "LATE SWAPAN GHOSH",
    saID: "SPR1384",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "GHOSE",
    address: "VILL-FATEPUR*PO-MALLARPUR*DIST-BIRBHUM-731216",
    fullName: "TAPAN KUMAR GHOSE",
    saID: "SPR1385",
  },
  {
    firstName: "UMA SANKAR",
    lastName: "GHOSH",
    address: "AT-KORAPUR*PO-SUTRA*VIA-NANDANGHAT*DIST-BURDWAN",
    fullName: "UMA SANKAR GHOSH",
    saID: "SPR1386",
  },
  {
    firstName: "UTTAM KUMAR",
    lastName: "GHOSH",
    address:
      "C/OSURENDRA NATH GHOSH*4.JAHAR BOX LANE.GORA BAZAR*PO-BERHAMPUR*DIST-MURSHIDABAD",
    fullName: "UTTAM KUMAR GHOSH",
    saID: "SPR1387",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "GHOSHJADAV",
    address:
      "C/O DR ANATH BANDHU GHOSHYADAV*VILL-CHANDRAPUR*PO-DIGHA*24PARGANAS (N) 743248",
    fullName: "LATE ANIL KUMAR GHOSHJADAV",
    saID: "SPR1388",
  },
  {
    firstName: "LATE SUKHENDU",
    lastName: "GHOSHCHOUDHURY",
    address: "80/1A,KANKULIA ROAD*CALCUTTA-700 019",
    fullName: "LATE SUKHENDU GHOSHCHOUDHURY",
    saID: "SPR1389",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "GHOSHDEBBURMAN",
    address: "DHITARA PASCHIMPARA*PO.SARADAPALLY*HOOGHLY",
    fullName: "LATE ANIL KUMAR GHOSHDEBBURMAN",
    saID: "SPR1390",
  },
  {
    firstName: "LATE SHANKAR",
    lastName: "GHOSHDASTIDAR",
    address: "54 TALLYGANG ROAD*KALITALA*CALCUTTA-26",
    fullName: "LATE SHANKAR GHOSHDASTIDAR",
    saID: "SPR1391",
  },
  {
    firstName: "BABAJI",
    lastName: "GIRI",
    address: "ADDL. DIR. OF AGRIL.,ORISSA*BHUBANESWAR-751001*KHURDA",
    fullName: "BABAJI GIRI",
    saID: "SPR1392",
  },
  {
    firstName: "LATE BANKIM BIHARI",
    lastName: "GIRI",
    address: "BAHIRTOLA*PO.JADURBERIA*DT.HOWRAH",
    fullName: "LATE BANKIM BIHARI GIRI",
    saID: "SPR1393",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "GIRI",
    address: "AT-KHARUIBAZAR*PO-KHARUIGARH*VIA-EGRA*DIST-MIDNAPORE",
    fullName: "KAMALA KANTA GIRI",
    saID: "SPR1394",
  },
  {
    firstName: "LATE LALIT BAHADUR",
    lastName: "GIRI",
    address: "TIPONG COLLIERY*PO-TIPONG*VIA-LEDO*DIBRUGARH*ASSAM",
    fullName: "LATE LALIT BAHADUR GIRI",
    saID: "SPR1395",
  },
  {
    firstName: "NIKHILESWAR",
    lastName: "GIRI",
    address: "VILL&PO-OLARAH*VIA-BORIKINA*JAGATSINGHPUR-754110",
    fullName: "NIKHILESWAR GIRI",
    saID: "SPR1396",
  },
  {
    firstName: "NIRMAL KUMAR",
    lastName: "GIRI",
    address: "AT-TEDUBI*PO-DHANDALIBAR*DIST-PURBA MEDINIPUR-721452.",
    fullName: "NIRMAL KUMAR GIRI",
    saID: "SPR1397",
  },
  {
    firstName: "NIRMALENDU",
    lastName: "GIRI",
    address: "PO/VILL-BAMANKHALI*VIA-RUDRANAGAR*DIST-24 PGS (SOUTH)-743373",
    fullName: "NIRMALENDU GIRI",
    saID: "SPR1398",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "GIRI",
    address:
      "AT/PO-CHITRAPOSI*VIA-KARANJIA*DIST-MAYURBHANJ 757037*PAN-AZNPG6816G",
    fullName: "PRAFULLA CHANDRA GIRI",
    saID: "SPR1399",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "GIRI",
    address: "VILL/PO OLARAH*DIST CUTTACK",
    fullName: "LATE SANTOSH KUMAR GIRI",
    saID: "SPR1400",
  },
  {
    firstName: "SRIRAM",
    lastName: "GIRI",
    address: "ADVOCATE*RAJENDRA SAROVAR*DAHIYAWA TOLA*PO&DT-CHAPRA 841301",
    fullName: "SRIRAM GIRI",
    saID: "SPR1401",
  },
  {
    firstName: "LATE SWADESH RANJAN",
    lastName: "GIRI",
    address: "VILL/PO-BAHURUPA*DIST-MIDNAPUR",
    fullName: "LATE SWADESH RANJAN GIRI",
    saID: "SPR1402",
  },
  {
    firstName: "LATE GOLOK BEHARI",
    lastName: "GOLDER",
    address:
      "JHARKHALI SATSANG CENTRE*PO-JHARKHALIBAZAR*DIST-24 PGS (SOUTH)-743312",
    fullName: "LATE GOLOK BEHARI GOLDER",
    saID: "SPR1403",
  },
  {
    firstName: "HIRANMOY",
    lastName: "GOPEGARAI",
    address:
      "SWAPNIL APARTT*CF-13, FLAT-D2, 4TH FLOOR*SUBHAS SARANI*PO-KRISHNAPUR,*KOL-700102.",
    fullName: "HIRANMOY GOPEGARAI",
    saID: "SPR1404",
  },
  {
    firstName: "BIJOYENDRA",
    lastName: "GOSWAMI",
    address: "CHURCH ROAD,AMBIKAPATTY*PO-SILCHAR 788004*DIST-SILCHAR",
    fullName: "BIJOYENDRA GOSWAMI",
    saID: "SPR1405",
  },
  {
    firstName: "DURGESWAR",
    lastName: "GOSWAMI",
    address: "SWASTI PATH*RUPNAGAR*PO-GUWAHATI*KAMRUP 781032",
    fullName: "DURGESWAR GOSWAMI",
    saID: "SPR1406",
  },
  {
    firstName: "LATE JNANENDU BIKASH",
    lastName: "GOSWAMI",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE JNANENDU BIKASH GOSWAMI",
    saID: "SPR1407",
  },
  {
    firstName: "NAGENDRA NATH",
    lastName: "GOSWAMI",
    address: "PO KHAIRABARI*DARRANG.",
    fullName: "NAGENDRA NATH GOSWAMI",
    saID: "SPR1408",
  },
  {
    firstName: "RABINDRA",
    lastName: "GOSWAMI",
    address: "NABARUN VIDYALAY*PO+V-LUMDING*DIST-NAGAON-782447*PAN-AHEPG3024D",
    fullName: "RABINDRA GOSWAMI",
    saID: "SPR1409",
  },
  {
    firstName: "LATE SATCHIDANANDA",
    lastName: "GOSWAMI",
    address: "SATSNG*DEOGHAR",
    fullName: "LATE SATCHIDANANDA GOSWAMI",
    saID: "SPR1410",
  },
  {
    firstName: "GOURHARI",
    lastName: "GUCHHAIT",
    address: "AT+PO-MUSTAFAPUR*DIST-PASCHIM MEDINIPUR-721443",
    fullName: "GOURHARI GUCHHAIT",
    saID: "SPR1411",
  },
  {
    firstName: "LATE BHUPESH CHANDRA",
    lastName: "GUHA",
    address: "PO-GANRAPOTA*DIST-24PARAGANAS",
    fullName: "LATE BHUPESH CHANDRA GUHA",
    saID: "SPR1412",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "GUHA",
    address:
      "BISWANATH CHARIALI*AMBARI HEN HAZIRIKA PATH*DIST-SONITPUR*(ASSAM)",
    fullName: "LATE BIRENDRA NATH GUHA",
    saID: "SPR1413",
  },
  {
    firstName: "LATE DILIP RANJAN",
    lastName: "GUHA",
    address:
      "H P APARTMENT*2ND FLOOR*FLAT NO-2C, HARABALA PATH*2ND BYE LANE, ULUBARI*GUWAHATI-7",
    fullName: "LATE DILIP RANJAN GUHA",
    saID: "SPR1414",
  },
  {
    firstName: "NALINI PRASAD",
    lastName: "GUHA",
    address: "H-38,NETAJEE COLONY*CALCUTTA-700050",
    fullName: "NALINI PRASAD GUHA",
    saID: "SPR1415",
  },
  {
    firstName: "LATE SASANKA SEKHAR",
    lastName: "GUHA",
    address: "C/O ANJALI BOSE*C/E 28 RABINDRA NAGAR*CALCUTTA 18",
    fullName: "LATE SASANKA SEKHAR GUHA",
    saID: "SPR1416",
  },
  {
    firstName: "SUKUMAR",
    lastName: "GUHA",
    address: "AT/PO.KANDUA*VIA-ANDULMOURI*DT.HOWRAH-711302",
    fullName: "SUKUMAR GUHA",
    saID: "SPR1417",
  },
  {
    firstName: "LATE BAIDYA NATH PRASAD",
    lastName: "GUPTA",
    address: "PO-BISWANATHGHAT*DIST-DARRANG.",
    fullName: "LATE BAIDYA NATH PRASAD GUPTA",
    saID: "SPR1418",
  },
  {
    firstName: "LATE BISHWANATH",
    lastName: "GUPTA",
    address: "SITA SARAN LANE*PO-MITHAPUR*PATNA",
    fullName: "LATE BISHWANATH GUPTA",
    saID: "SPR1419",
  },
  {
    firstName: "LATE DWARAKA PROSAD",
    lastName: "GUPTA",
    address: "GUPTA WATCH CO.*GOLAGHAT*SIBSAGAR",
    fullName: "LATE DWARAKA PROSAD GUPTA",
    saID: "SPR1420",
  },
  {
    firstName: "LATE GOPINATH",
    lastName: "GUPTA",
    address: "GENERAL STORES*PO BOKAKHAT*SIBSAGAR",
    fullName: "LATE GOPINATH GUPTA",
    saID: "SPR1421",
  },
  {
    firstName: "LATE LAXMI KANT",
    lastName: "GUPTA",
    address: "IN FRONT OF KALI MANDIR*HANUMANTIKRI*DEOGHAR*JHARKHAND",
    fullName: "LATE LAXMI KANT GUPTA",
    saID: "SPR1422",
  },
  {
    firstName: "LATE NIRMAL KANTI",
    lastName: "GUPTA",
    address: "100 DAKSHINPARA ROAD*SATGACHHI- DUMDUM*CALCUTTA-28",
    fullName: "LATE NIRMAL KANTI GUPTA",
    saID: "SPR1423",
  },
  {
    firstName: "P C",
    lastName: "GUPTA",
    address: "DHANWANTARY CHIKITSALAY*PO-MACHHLISAHAR*DIST-JAUNPUR*U.P-222143",
    fullName: "P C GUPTA",
    saID: "SPR1424",
  },
  {
    firstName: "PREM CHANDRA",
    lastName: "GUPTA",
    address: "VILL-MANGAL BAZAR*MACHHLISAHAR*JAUNPUR*U.P.-222143",
    fullName: "PREM CHANDRA GUPTA",
    saID: "SPR1425",
  },
  {
    firstName: "PRODYUT KUMAR",
    lastName: "GUPTA",
    address: "C/O RLY TEA STALL*PO-NEW BONGAIGAON N.F.RLY*GOALPARA",
    fullName: "PRODYUT KUMAR GUPTA",
    saID: "SPR1426",
  },
  {
    firstName: "PUSKAR NARAYAN",
    lastName: "GUPTA",
    address: "C/O GENERAL BOOK STALL*PO-BOKAKHAT*GOLAGHAT*PAN NO.AHVPG7540H",
    fullName: "PUSKAR NARAYAN GUPTA",
    saID: "SPR1427",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "GUPTA",
    address: "VILL-CHHACHHO*PO-MACHHLISAHAR*DIST-JAUNPUR*(U.P.)-222143",
    fullName: "RAM CHANDRA GUPTA",
    saID: "SPR1428",
  },
  {
    firstName: "LATE SHEO KUMAR",
    lastName: "GUPTA",
    address: "AT.TRIBENI ROAD*MAGRA*PO.MAGRA*DT.HOOGHLY",
    fullName: "LATE SHEO KUMAR GUPTA",
    saID: "SPR1429",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "GUPTA",
    address: "VILL/PO-MACHHLISAHAR*DIST-JAUNPUR (U.P.)",
    fullName: "SURESH CHANDRA GUPTA",
    saID: "SPR1430",
  },
  {
    firstName: "LATE TARA DAS",
    lastName: "GUPTA",
    address: "ADVOCATE*DUMKA",
    fullName: "LATE TARA DAS GUPTA",
    saID: "SPR1431",
  },
  {
    firstName: "ASITAVA",
    lastName: "GUPTACHOWDHURY",
    address: "HOUSE NO.7 BYE LANE-1*SONIRAM BORA PATH*ULUBARI*GUWAHATI 7*ASSAM",
    fullName: "ASITAVA GUPTACHOWDHURY",
    saID: "SPR1432",
  },
  {
    firstName: "BROJO GOPAL",
    lastName: "GURU",
    address:
      "GHATAL SATSANG MONDIR*PO-GHATAL*DIST-PASCHIM MEDINIPUR-721212*PAN-AHWPG0056F",
    fullName: "BROJO GOPAL GURU",
    saID: "SPR1433",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "HAJONG",
    address: "BARABHUYNAGAON*PO-JOYRAMPUR*LAKHIMPUR.",
    fullName: "DHIRENDRA HAJONG",
    saID: "SPR1434",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "HAJRA",
    address: "VILL&PO-PALASAN*DT-BURDWAN.",
    fullName: "LATE PANCHANAN HAJRA",
    saID: "SPR1435",
  },
  {
    firstName: "LATE AMRITA LAL",
    lastName: "HALDAR",
    address: "VILL&PO-GARALGACHA*HOOGHLY",
    fullName: "LATE AMRITA LAL HALDAR",
    saID: "SPR1436",
  },
  {
    firstName: "LATE ANNADA BEJOY",
    lastName: "HALDAR",
    address: "SEVA PHARMACY*PO-RRAGHUNATHPUR*PURULIA",
    fullName: "LATE ANNADA BEJOY HALDAR",
    saID: "SPR1437",
  },
  {
    firstName: "DHARMADAS",
    lastName: "HALDAR",
    address: "VILL-BANDU*PO-HATKRISHNANAGAR*BANKURA-722206",
    fullName: "DHARMADAS HALDAR",
    saID: "SPR1438",
  },
  {
    firstName: "JALADHAR",
    lastName: "HALDAR",
    address: "VILL-KAITARA*PO-ADRAHATI*DIST BARDDHAMAN-713428*PAN--AMPPH8938P",
    fullName: "JALADHAR HALDAR",
    saID: "SPR1439",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "HALDAR",
    address: "93/2A DR.G.S.BOSE RD.*CALCUTTA-700039.",
    fullName: "PARESH CHANDRA HALDAR",
    saID: "SPR1440",
  },
  {
    firstName: "LATE RAJENDRA NATH",
    lastName: "HALDAR",
    address: "SRIDURGA COLONY*HOOGHLY",
    fullName: "LATE RAJENDRA NATH HALDAR",
    saID: "SPR1441",
  },
  {
    firstName: "LATE SATYA CHARAN",
    lastName: "HALDAR",
    address: "VILL&PO-KALSUR*DIST-24PARGANAS.",
    fullName: "LATE SATYA CHARAN HALDAR",
    saID: "SPR1442",
  },
  {
    firstName: "BHABARANJAN",
    lastName: "HALDAR",
    address: "GOBARDANGA COLLEGE ROAD*PO-KHANTURA*DIST-24 PGS (NORTH)-743273",
    fullName: "BHABARANJAN HALDAR",
    saID: "SPR1443",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "HALDER",
    address: "HALISAHAR CHAKRABORTY PARA ROAD*DISTRICT-24 PGS",
    fullName: "LATE DINESH CHANDRA HALDER",
    saID: "SPR1444",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "HALDAR",
    address: "SARAJIT PALLY*PO.BIRNAGAR*NADIA",
    fullName: "LATE JAGADISH CHANDRA HALDAR",
    saID: "SPR1445",
  },
  {
    firstName: "LATE MADHAB",
    lastName: "HALDER",
    address: "KHAMARPARA*PO-BANSBERIA*DIST.HOOGHLY",
    fullName: "LATE MADHAB HALDER",
    saID: "SPR1446",
  },
  {
    firstName: "MUKUNDA BEHARI",
    lastName: "HALDER",
    address: "CANNING SATSANG CENTRE*PO-CANNING*DIST-24 PGS (SOUTH)-743329",
    fullName: "MUKUNDA BEHARI HALDER",
    saID: "SPR1447",
  },
  {
    firstName: "LATE NALINI RANJAN",
    lastName: "HALDER",
    address: "LAXMIKANTAPUR(POLAGHAT)*PO-GHATESWAR*DIST-24 PGS.",
    fullName: "LATE NALINI RANJAN HALDER",
    saID: "SPR1448",
  },
  {
    firstName: "LATE PRAKASH CHANDRA",
    lastName: "HALDER",
    address: "AT-DAYARAMPUR*PO-GOBBERIA*DIST-24 PARAGANAS",
    fullName: "LATE PRAKASH CHANDRA HALDER",
    saID: "SPR1449",
  },
  {
    firstName: "LATE RAJENDRA NATH",
    lastName: "HALDER",
    address: "26/1 GAPIKRISHNA GOSWAMI ROAD*PO-BHATPARA*DIST-24 PGS.",
    fullName: "LATE RAJENDRA NATH HALDER",
    saID: "SPR1450",
  },
  {
    firstName: "LATE RAM KRISHNA",
    lastName: "HALDER",
    address: "PO PANPUA*DIST 24 PARAGANAS",
    fullName: "LATE RAM KRISHNA HALDER",
    saID: "SPR1451",
  },
  {
    firstName: "LATE SACHINDRA NATH",
    lastName: "HALDER",
    address: "AT&PO-NRISINGHAPUR*NADIA",
    fullName: "LATE SACHINDRA NATH HALDER",
    saID: "SPR1452",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "HALDER",
    address: "*SATSANG",
    fullName: "LATE SARAT CHANDRA HALDER",
    saID: "SPR1453",
  },
  {
    firstName: "LATE SUSHIL",
    lastName: "HALDER",
    address: "CALCUTTA SATSANG KENDRA*68, SURYA SEN STREET*CALCUTTA-700009.",
    fullName: "LATE SUSHIL HALDER",
    saID: "SPR1454",
  },
  {
    firstName: "LATE TRAILAKYA NATH",
    lastName: "HALDER",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE TRAILAKYA NATH HALDER",
    saID: "SPR1455",
  },
  {
    firstName: "LATE BHADAW",
    lastName: "HANSDA",
    address: "VILL-JAGANNATHPUR*PO-BAGBERA*JAMSHEDPUR*SINGHBHUM-831002",
    fullName: "LATE BHADAW HANSDA",
    saID: "SPR1456",
  },
  {
    firstName: "BUDHU RAM",
    lastName: "HANSDA",
    address: "VILL-JANEGORA *PO-SARJAMDA*VIA-TATANAGAR*SINGBHUM-831002",
    fullName: "BUDHU RAM HANSDA",
    saID: "SPR1457",
  },
  {
    firstName: "GOBINDA",
    lastName: "HANSDA",
    address: "VILL.SIMLA*P.O.KUNJORA*DIST-DEOGHAR-814146*(JHARKHAND).",
    fullName: "GOBINDA HANSDA",
    saID: "SPR1458",
  },
  {
    firstName: "LATE SANATAN",
    lastName: "HARAPAL",
    address: "C/O L.N.DUTTA*DHUBULIA SATSANG VIHAR*PO.DHUBULIA*NADIA",
    fullName: "LATE SANATAN HARAPAL",
    saID: "SPR1459",
  },
  {
    firstName: "GOURI SHANKAR",
    lastName: "HATI",
    address: "AT-SASANBAD*PO-JALESWAR*DIST-BALASORE-756032",
    fullName: "GOURI SHANKAR HATI",
    saID: "SPR1460",
  },
  {
    firstName: "LATE CHENNARAM",
    lastName: "HAZARIKA",
    address: "VILL-KUSUMTALA*PO-KARCHANTOLA*SONITPUR 784 189",
    fullName: "LATE CHENNARAM HAZARIKA",
    saID: "SPR1461",
  },
  {
    firstName: "BINOY KUMAR",
    lastName: "HAZRA",
    address: "VILL/PO-RADHAGHAT*DIST-MURSHIDABAD-742187*PAN-AENPH1290N",
    fullName: "BINOY KUMAR HAZRA",
    saID: "SPR1462",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "HAZRA",
    address: "F.I.T.QR.SECT-II,BLOCK.G*ROOM.NO.3*ICHAPUR ROAD*DT.HOWRAH",
    fullName: "LATE DEBENDRA NATH HAZRA",
    saID: "SPR1463",
  },
  {
    firstName: "KARUNAMOY",
    lastName: "HAZRA",
    address: "DISTRICT SATSANG CENTRE*KARANI BAZAR*PO/DIST BANKURA",
    fullName: "KARUNAMOY HAZRA",
    saID: "SPR1464",
  },
  {
    firstName: "PANKOJ KUMAR",
    lastName: "HAZRA",
    address: "AT/PO PALASON*VIA-SHYAMSUNDAR*DIST-BARDDHAMAN-713424",
    fullName: "PANKOJ KUMAR HAZRA",
    saID: "SPR1465",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "HAZRA",
    address: "AT+PO-TIKASI*DIST-PURBA MEDINIPUR-721430",
    fullName: "SASANKA SEKHAR HAZRA",
    saID: "SPR1466",
  },
  {
    firstName: "RABAN",
    lastName: "HEMBRAM",
    address: "VILL-AMBAGAN*PO-MIHIJAM*JAMTARA-815354*JHARKHAND*PAN-AANPH5336D",
    fullName: "RABAN HEMBRAM",
    saID: "SPR1467",
  },
  {
    firstName: "SATYABRATA",
    lastName: "HOM",
    address:
      "A-109, DEFENCE COLONY*AMARABATI*DURGAPUR-713214*BURDWAN*PAN-ABMPH6633B",
    fullName: "SATYABRATA HOM",
    saID: "SPR1468",
  },
  {
    firstName: "SUBODH RANJAN",
    lastName: "HORE",
    address: "LINK ROAD,LANE NO -3*PO/DIST-SILCHAR-788 006*ASSAM  .",
    fullName: "SUBODH RANJAN HORE",
    saID: "SPR1469",
  },
  {
    firstName: "LALIT KUMAR",
    lastName: "HOTA",
    address:
      "DEBOTTAM MARKET*AT-KATHAGADA*PO-BHUTAMUNDEI*DIST-JAGATSINGHPUR-754141",
    fullName: "LALIT KUMAR HOTA",
    saID: "SPR1470",
  },
  {
    firstName: "SARADINDU BHUSAN",
    lastName: "HOTA",
    address: "PALACE LINE*BOLANGIR-767001.",
    fullName: "SARADINDU BHUSAN HOTA",
    saID: "SPR1471",
  },
  {
    firstName: "LATE HAROSIT CHANDRA",
    lastName: "HOULI",
    address: "V-UTTAR BHABANIPUR*PO-KHARAGPORE*DIST-MIDNAPORE.",
    fullName: "LATE HAROSIT CHANDRA HOULI",
    saID: "SPR1472",
  },
  {
    firstName: "LATE SRIPATI",
    lastName: "HOULI",
    address: "SATSANG VIHAR*AT+PO-SULKUNI.VIA-HASNABAD*DIST-24 PARGANAS",
    fullName: "LATE SRIPATI HOULI",
    saID: "SPR1473",
  },
  {
    firstName: "HENASING",
    lastName: "INGLENG",
    address: "VILL/PO-JENGKHA*VIA-KHERONI*DIST-KARBIANGLONG-782448*ASSAM",
    fullName: "HENASING INGLENG",
    saID: "SPR1474",
  },
  {
    firstName: "LATE MALESHWAR",
    lastName: "INGTI",
    address: "VILL-BALIGAON*PO-TUMPRENG*KARBIANGLONG.",
    fullName: "LATE MALESHWAR INGTI",
    saID: "SPR1475",
  },
  {
    firstName: "RAM BALLAV",
    lastName: "YADAV",
    address: "VILL/PO-MADANPUR*VIA-DHABALI*MADHEPURA",
    fullName: "RAM BALLAV YADAV",
    saID: "SPR1476",
  },
  {
    firstName: "JAGABANDHU",
    lastName: "JALI",
    address: "PLOT NO-N4/35,I R C VILLAGE*BHUBANESWAR-751 015*PAN-AXRPJ9435G",
    fullName: "JAGABANDHU JALI",
    saID: "SPR1477",
  },
  {
    firstName: "RADHAKANTA",
    lastName: "JAMDARY",
    address: "SATSANG  VIHAR*PO-TUFANGANJ*COOCHBEHAR-736159",
    fullName: "RADHAKANTA JAMDARY",
    saID: "SPR1478",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "JANA",
    address: "68 SURJASEN STREET*CALCUTTA-9",
    fullName: "LATE ANANTA KUMAR JANA",
    saID: "SPR1479",
  },
  {
    firstName: "LATE GIRISH CHANDRA",
    lastName: "JANA",
    address: "AT-DHANBERIA*PO-ANANTAPORE*VIA-SUTAHATA*MIDNAPUR.",
    fullName: "LATE GIRISH CHANDRA JANA",
    saID: "SPR1480",
  },
  {
    firstName: "LATE GOBARDHAN",
    lastName: "JANA",
    address: "AT-GURLI*PO-CHETUA RAJNAGAR*DIST-MIDNAPORE",
    fullName: "LATE GOBARDHAN JANA",
    saID: "SPR1481",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "JANA",
    address: "AT+PO-D.KALYANPUR*VIA-CHOULKHOLA*DIST-MIDNAPORE.",
    fullName: "LATE HARIPADA JANA",
    saID: "SPR1482",
  },
  {
    firstName: "JYOTIRMOY",
    lastName: "JANA",
    address:
      "V-PURUSOTTAMPUR*PO-NAYAPUT*DIST-PURBA MEDINIPUR-721401*PAN NO.AEZPJ71932",
    fullName: "JYOTIRMOY JANA",
    saID: "SPR1483",
  },
  {
    firstName: "LATE MADHAB CHANDRA",
    lastName: "JANA",
    address: "7 NO.ANDUL ROAD*BOTANICAL GARDEN*DT.HOWRAH",
    fullName: "LATE MADHAB CHANDRA JANA",
    saID: "SPR1484",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "JANA",
    address: "VILL-SEHAI*PO-KAMRA*VIA-BIRLAPUR*DIST-24 PARGANAS (SOUTH)-743318",
    fullName: "PRASANTA KUMAR JANA",
    saID: "SPR1485",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "JANA",
    address: "AT-MIRZAPUR*PO-TALBANDI*DIST-PASCHIM MEDINIPUR-721156",
    fullName: "RANJIT KUMAR JANA",
    saID: "SPR1486",
  },
  {
    firstName: "SATYENDRA NATH",
    lastName: "JANA",
    address:
      "SATSANG VIHAR BADLAPUR*BADLAPUR WEST*PO-KULGAON-421503*THANE*PAN-ARVPJ6065G",
    fullName: "SATYENDRA NATH JANA",
    saID: "SPR1487",
  },
  {
    firstName: "SUDHIR CHANDRA",
    lastName: "JANA",
    address: "VILL-KAKHURIABARI*PO-NAZIR BAZAR*PURBA MEDINIPUR-721655",
    fullName: "SUDHIR CHANDRA JANA",
    saID: "SPR1488",
  },
  {
    firstName: "ALOK",
    lastName: "JENA",
    address: "44, SATYANAGAR (GOVT. PLOT)*BHUBANESWAR-751007*PAN-AGOPJ9492K",
    fullName: "ALOK JENA",
    saID: "SPR1489",
  },
  {
    firstName: "ALOK KUMAR",
    lastName: "JENA",
    address: "AT.NAGABALI*PO.SANKHATRAS*CUTTACK-753011",
    fullName: "ALOK KUMAR JENA",
    saID: "SPR1490",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "JENA",
    address: '"A/258" KOELNAGAR*ROURKELA 769014*SUNDARGARH',
    fullName: "BASANTA KUMAR JENA",
    saID: "SPR1491",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "JANA",
    address: "AT/PO-MOHANPUR*MIDNAPUR.",
    fullName: "LATE BASANTA KUMAR JANA",
    saID: "SPR1492",
  },
  {
    firstName: "LATE BATA KRUSHNA",
    lastName: "JENA",
    address: "QR.NO-IVR-219*UNIT-6,BHUBANESWAR-1*KHURDA",
    fullName: "LATE BATA KRUSHNA JENA",
    saID: "SPR1493",
  },
  {
    firstName: "BHUTNATH",
    lastName: "JANA",
    address:
      "VILL-GANGASAGAR*PO-MUSTAFAPUR*VIA-JAHALDA*PURBA MEDINIPUR-721443*PAN-ACFPJ6320H",
    fullName: "BHUTNATH JANA",
    saID: "SPR1494",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "JENA",
    address: "AT-CHANDRADEIPUR*PO-SALIPUR*CUTTACK-754202*PAN-AUNPJ0121E",
    fullName: "DHIRENDRA KUMAR JENA",
    saID: "SPR1495",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "JENA",
    address: "VILL-NALAPADA*PO-DEULAPARA*VIA-SRIBALADEVJEW*KENDRAPARA 754212",
    fullName: "GIRIDHARI JENA",
    saID: "SPR1496",
  },
  {
    firstName: "LATE KANGAL CHAND",
    lastName: "JENA",
    address: "VILL/PO.NANGALESWAR-756024*BALASORE.",
    fullName: "LATE KANGAL CHAND JENA",
    saID: "SPR1497",
  },
  {
    firstName: "LINGARAJ",
    lastName: "JENA",
    address: "V-BALIPADA*PO.KHANDASAHI*CUTTACK-754207",
    fullName: "LINGARAJ JENA",
    saID: "SPR1498",
  },
  {
    firstName: "LATE MAHESWAR",
    lastName: "JENA",
    address: "AT/PO-GARADPUR*VIA/DIST-BHADRAK.",
    fullName: "LATE MAHESWAR JENA",
    saID: "SPR1499",
  },
  {
    firstName: "PARESWAR",
    lastName: "JENA",
    address: "CHARYA NILAYA*ASHOK NAGAR*AT/PO-ATHGARH*CUTTACK-754029.",
    fullName: "PARESWAR JENA",
    saID: "SPR1500",
  },
  {
    firstName: "RAMA CHANDRA",
    lastName: "JENA",
    address: "AT & PO-BALIBAREI*VIA-HADAGARH*DIST-KEONJHAR-758023*ADRPJ6273C",
    fullName: "RAMA CHANDRA JENA",
    saID: "SPR1501",
  },
  {
    firstName: "LATE SUDARSAN",
    lastName: "JENA",
    address: "VILL-SARIPAL*PO-ALANTI*VIA-BARAPADA*DIST-BHADRAK-756113.",
    fullName: "LATE SUDARSAN JENA",
    saID: "SPR1502",
  },
  {
    firstName: "BHUPENDRA CHANDRA",
    lastName: "JHA",
    address: "PUSA ROAD SATSANGKENDRA*PO-WAINI*SAMASTIPUR-848131",
    fullName: "BHUPENDRA CHANDRA JHA",
    saID: "SPR1503",
  },
  {
    firstName: "DR BINODANAND",
    lastName: "JHA",
    address: '"APARAJITA"*BILASHI TOWN*PO-BILASHI*DEOGHAR',
    fullName: "DR BINODANAND JHA",
    saID: "SPR1504",
  },
  {
    firstName: "DYUTI BALLAV",
    lastName: "JHA",
    address: "C/O MANORANJAN JHA*COLONY NO 1*PO&DT KATIHAR-854105*BIHAR",
    fullName: "DYUTI BALLAV JHA",
    saID: "SPR1505",
  },
  {
    firstName: "KRIPANAND",
    lastName: "JHA",
    address: "IX/B,STREET-13,QR.NO.470*BOKARO STEEL CITY-827009*BOKARO",
    fullName: "KRIPANAND JHA",
    saID: "SPR1506",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "JHA",
    address: "COLONY NO-1*PO-KATIHAR*KATIHAR",
    fullName: "LATE MANORANJAN JHA",
    saID: "SPR1507",
  },
  {
    firstName: "PHANIBHUSAN",
    lastName: "JHA",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCHBEHAR-735303*PAN-AREPJ2691R",
    fullName: "PHANIBHUSAN JHA",
    saID: "SPR1508",
  },
  {
    firstName: "LATE RAJESHWAR",
    lastName: "JHA",
    address: "VILL-MALPUR*PO-SINGHARA*DIST-VAISHALI",
    fullName: "LATE RAJESHWAR JHA",
    saID: "SPR1509",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "JOARDAR",
    address: "68,SURYASEN STREET*CALCUTTA-9",
    fullName: "LATE ASHUTOSH JOARDAR",
    saID: "SPR1510",
  },
  {
    firstName: "HIRENDRA KUMAR",
    lastName: "JOARDAR",
    address: "SATSANG*DEOGHAR",
    fullName: "HIRENDRA KUMAR JOARDAR",
    saID: "SPR1511",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "JOARDAR",
    address: "SATSANG*DEOGHAR.",
    fullName: "SANTOSH KUMAR JOARDAR",
    saID: "SPR1512",
  },
  {
    firstName: "LATE JAGANNATH",
    lastName: "JOSHI",
    address:
      "PURUSOTTAM ENGG WORKS*KINGNAYA,JETHATALI*NEAR AMERICAN EMBASSY*KATHMANDU*NEPAL",
    fullName: "LATE JAGANNATH JOSHI",
    saID: "SPR1513",
  },
  {
    firstName: "PRAVAKAR DAMODAR",
    lastName: "JOSHI",
    address:
      "20,KHAMA STATE BANK SOCIETY*PO-NOWPADA*DIST-THANE*M.S.400 602*PAN-ANZPJ9797P",
    fullName: "PRAVAKAR DAMODAR JOSHI",
    saID: "SPR1514",
  },
  {
    firstName: "NARAYAN",
    lastName: "ACHARYA",
    address:
      "SR.CLERK*O/O DY.DIRECTOR,S.F.P.DIVISION*AT&PO-RAIRANGPUR*MAYURBHANJ-757043",
    fullName: "NARAYAN ACHARYA",
    saID: "SPR1515",
  },
  {
    firstName: "LATE RAM CHANDRA",
    lastName: "KABASI",
    address: "AT+PO-BADURIA*DIST-24 PGS.",
    fullName: "LATE RAM CHANDRA KABASI",
    saID: "SPR1516",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "KABIRAJ",
    address: "VILL-BHAWANIPUR*PO-MILANGARH*HOOGHLY.",
    fullName: "LATE ASHUTOSH KABIRAJ",
    saID: "SPR1517",
  },
  {
    firstName: "LAGHANURAM",
    lastName: "KACHARI",
    address: "V GURIHATI*PO KHAIRABARI*DARRANG.",
    fullName: "LAGHANURAM KACHARI",
    saID: "SPR1518",
  },
  {
    firstName: "HARENDRA NATH",
    lastName: "KAKATI",
    address: "GAREHAGA DHEKIAL GAON*PO-AZAD*LAKHIMPUR (N)",
    fullName: "HARENDRA NATH KAKATI",
    saID: "SPR1519",
  },
  {
    firstName: "KHAGENDRA NATH",
    lastName: "KAKATI",
    address: "AT/PO-MAHABHAIRAB*SONITPUR.",
    fullName: "KHAGENDRA NATH KAKATI",
    saID: "SPR1520",
  },
  {
    firstName: "LATE HOMESWAR",
    lastName: "KAKOTI",
    address: "*SATSANG",
    fullName: "LATE HOMESWAR KAKOTI",
    saID: "SPR1521",
  },
  {
    firstName: "LATE UMAKANTA",
    lastName: "KAKOTI",
    address: "BARPETA ROAD*PO BARPETA*DIST KAMRUP",
    fullName: "LATE UMAKANTA KAKOTI",
    saID: "SPR1522",
  },
  {
    firstName: "LATE DHARMA KANTA",
    lastName: "KALITA",
    address: "DHEKIAJULI SATSANG KENDRA*PO-DHEKIAJULI*DIST-DARRANG.",
    fullName: "LATE DHARMA KANTA KALITA",
    saID: "SPR1523",
  },
  {
    firstName: "LATE DIMBESWAR",
    lastName: "KALITA",
    address: "SATSANG VIHAR,GAUHATI*BHANGAGHAR*KAMRUP",
    fullName: "LATE DIMBESWAR KALITA",
    saID: "SPR1524",
  },
  {
    firstName: "LATE JANARDAN",
    lastName: "KALITA",
    address: "AT-SIMLAGURI*PO-BARPETA ROAD*DIST-BARPETA",
    fullName: "LATE JANARDAN KALITA",
    saID: "SPR1525",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "KALITA",
    address: "VILL/PO-NALBARI-BIDYAPUR*WORD NO-11*DIST-NALBARI-781335*ASSAM",
    fullName: "LATE JATINDRA NATH KALITA",
    saID: "SPR1526",
  },
  {
    firstName: "LATE JAY CHANDRA",
    lastName: "KALITA",
    address: "AT/PO-NAGARBERA*DIST-KAMRUP-781127*ASSAM",
    fullName: "LATE JAY CHANDRA KALITA",
    saID: "SPR1527",
  },
  {
    firstName: "JOGENDRA",
    lastName: "KALITA",
    address:
      "MAZBOOT LAOHA PRATISTHAN*HATIGARH CHARIALI*PO-ZOO ROAD*GUWAHATI-781024*KAMRUP",
    fullName: "JOGENDRA KALITA",
    saID: "SPR1528",
  },
  {
    firstName: "KALI CHARAN",
    lastName: "KALITA",
    address: "VILL/PO SANDA;VIA NALBARI*DIST KAMRUP",
    fullName: "KALI CHARAN KALITA",
    saID: "SPR1529",
  },
  {
    firstName: "LATE KANAK CHANDRA",
    lastName: "KALITA",
    address: "KABAITARY*PO-JOGHIGHOPA*GOALPARA",
    fullName: "LATE KANAK CHANDRA KALITA",
    saID: "SPR1530",
  },
  {
    firstName: "LATE RAMESWAR",
    lastName: "KALITA",
    address: "KATHALMURI*PO-BAGHMARA BAZAR*DIST-BARPETA*ASSAM",
    fullName: "LATE RAMESWAR KALITA",
    saID: "SPR1531",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "KALITA",
    address: "VILL-BHOTAIGAON*PO-URIYAGAON*NAGAON-782003*PAN-AFQPK9075G*ASSAM",
    fullName: "SARAT CHANDRA KALITA",
    saID: "SPR1532",
  },
  {
    firstName: "SONESWAR",
    lastName: "KALITA",
    address: "AT-DAKUAPARA*PO-BOKO*DIST-KAMRUP-781123*ASSAM",
    fullName: "SONESWAR KALITA",
    saID: "SPR1533",
  },
  {
    firstName: "LATE SURYA KANTA",
    lastName: "KALITA",
    address: "VILL-DAKUAPARA*PO-BOKO*DIST-KAMRUP-781123*ASSAM",
    fullName: "LATE SURYA KANTA KALITA",
    saID: "SPR1534",
  },
  {
    firstName: "LATE UMAKANTA",
    lastName: "KALITA",
    address: "PO-CHHAYGAON*DIST KAMRUP",
    fullName: "LATE UMAKANTA KALITA",
    saID: "SPR1535",
  },
  {
    firstName: "LATE MADAN CHANDRA",
    lastName: "KAMAN",
    address: "UKHMATI NAHA RANIGAON*PO-BHIMPARA*LAKHIMPUR NORTH.",
    fullName: "LATE MADAN CHANDRA KAMAN",
    saID: "SPR1536",
  },
  {
    firstName: "MOHAN CHANDRA",
    lastName: "KAMAN",
    address: "VILL/PO-GARAI MARI*LAKHIMPUR*ASSAM",
    fullName: "MOHAN CHANDRA KAMAN",
    saID: "SPR1537",
  },
  {
    firstName: "LATE SRIHARI CHARAN",
    lastName: "KAMILA",
    address: "SATSANG ASHRAM *PO-CHHATNA*DIST-BANKURA",
    fullName: "LATE SRIHARI CHARAN KAMILA",
    saID: "SPR1538",
  },
  {
    firstName: "LATE SARBESWAR",
    lastName: "KANDAR",
    address: "AT-DURGAPUR*PO-TEKARBAZAR*DIST-24 PARAGANAS",
    fullName: "LATE SARBESWAR KANDAR",
    saID: "SPR1539",
  },
  {
    firstName: "AMARENDRA NATH",
    lastName: "KANRAR",
    address: "68 SURYA SEN STREET*CALCUTTA-9*PAN-AGVPK4746N",
    fullName: "AMARENDRA NATH KANRAR",
    saID: "SPR1540",
  },
  {
    firstName: "LATE BALA KRISHNA",
    lastName: "KAPOOR",
    address: "ASHOKE INDUSTRIAL CORPORATION*138-B.R.B.ROAD IST FLOOR*CAL 1",
    fullName: "LATE BALA KRISHNA KAPOOR",
    saID: "SPR1541",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "KAR",
    address: "SATSANG 814116*DEOGHAR*JHARKHAND",
    fullName: "AJIT KUMAR KAR",
    saID: "SPR1542",
  },
  {
    firstName: "LATE AMRITA LAL",
    lastName: "KAR",
    address: "39 SAKUNTALA ROAD*PO-J.B.ROAD(AGARTALA)*DT-TRIPURA 799001",
    fullName: "LATE AMRITA LAL KAR",
    saID: "SPR1543",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "KAR",
    address: "HIMAITPUR SATSANG ASHRAM*PO-RAMKANALI*PURULIA",
    fullName: "LATE ASHUTOSH KAR",
    saID: "SPR1544",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "KAR",
    address: "VILL-KALYANPUR*PO-PICHABANI*MIDNAPORE",
    fullName: "BASANTA KUMAR KAR",
    saID: "SPR1545",
  },
  {
    firstName: "LATE BIBHUTI KUMAR",
    lastName: "KAR",
    address: "VILL&PO-MOHANPUR*DT-MIDNAPORE.",
    fullName: "LATE BIBHUTI KUMAR KAR",
    saID: "SPR1546",
  },
  {
    firstName: "LATE BISWA RANJAN",
    lastName: "KAR",
    address: "P/72,JADU COLONY*CALCUTTA-700034",
    fullName: "LATE BISWA RANJAN KAR",
    saID: "SPR1547",
  },
  {
    firstName: "DEBESH RANJAN",
    lastName: "KAR",
    address:
      "VILL SARKHEL*PO CHATULBAZAR*P.S.JAINTAPUR* DIST SYLHET*BANGLADESH",
    fullName: "DEBESH RANJAN KAR",
    saID: "SPR1548",
  },
  {
    firstName: "GODABARISH",
    lastName: "KAR",
    address: "AT-ARUAL*PO-BARI-CUTTACK*DIST-JAJPUR-755003",
    fullName: "GODABARISH KAR",
    saID: "SPR1549",
  },
  {
    firstName: "GOPIKISORE",
    lastName: "KAR",
    address: "VIL&PO SUBARNAPUR*HARINGHATA*NADIA-741249",
    fullName: "GOPIKISORE KAR",
    saID: "SPR1550",
  },
  {
    firstName: "HARIPADA",
    lastName: "KAR",
    address: "25,KSHUDIRAM BOSE ROAD*PO-NABAGRAM*DIST-HOOGHLY-712246",
    fullName: "HARIPADA KAR",
    saID: "SPR1551",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "KAR",
    address: "VILL-JAGATPUR*PO-ABHAYNAGAR*TRIPURA WEST",
    fullName: "LATE HARIPADA KAR",
    saID: "SPR1552",
  },
  {
    firstName: "KRIPESH RANJAN",
    lastName: "KAR",
    address: "DURGABARI LANE*BHASKAR NAGAR*GUWAHATI-781018*KAMRUP*ASSAM",
    fullName: "KRIPESH RANJAN KAR",
    saID: "SPR1553",
  },
  {
    firstName: "LATE MAKHAN LAL",
    lastName: "KAR",
    address: "AT/PO-KANCHRAPARA*DIST-24 PGS.",
    fullName: "LATE MAKHAN LAL KAR",
    saID: "SPR1554",
  },
  {
    firstName: "LATE MANINDRA CHANDRA",
    lastName: "KAR",
    address: "SATSANG 814116",
    fullName: "LATE MANINDRA CHANDRA KAR",
    saID: "SPR1555",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "KAR",
    address: 'BLOCK "U", FLAT NO.-30 *37 BELGACHHIA RD*CALCUTTA-37',
    fullName: "NARESH CHANDRA KAR",
    saID: "SPR1556",
  },
  {
    firstName: "NITYANANDA",
    lastName: "KAR",
    address:
      "H.NO.1374,PHASE-2*DUMDUMA HOUSING BOARD COLONY*BHUBANESWAR-751019*KHURDA",
    fullName: "NITYANANDA KAR",
    saID: "SPR1557",
  },
  {
    firstName: "NRIPENDRA NARAYAN",
    lastName: "KAR",
    address:
      "M M DUTTA ROAD,NEAR BOWBAZAR*DESHBANDHUPARA*PO-SILIGURI*DARJEELING 734001",
    fullName: "NRIPENDRA NARAYAN KAR",
    saID: "SPR1558",
  },
  {
    firstName: "LATE PRATUL CHANDRA",
    lastName: "KAR",
    address: "NAIPUKUR*PO RAZARHAT*DIST 24 PGS.",
    fullName: "LATE PRATUL CHANDRA KAR",
    saID: "SPR1559",
  },
  {
    firstName: "PRIYA NATH",
    lastName: "KAR",
    address: "VILL-ARUAL*PO-BARI CUTTACK*DIST-JAJPUR-755003*PAN-DCMPK6587F",
    fullName: "PRIYA NATH KAR",
    saID: "SPR1560",
  },
  {
    firstName: "LATE RADHABALLAV",
    lastName: "KAR",
    address: "KALABAGAN*GORASTHAN*PO&DT-COOCHBEHAR.",
    fullName: "LATE RADHABALLAV KAR",
    saID: "SPR1561",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "KAR",
    address: "ALIPURDUAR SATSANG VIHAR*PO-ALIPURDUAR*JALPAIGURI",
    fullName: "LATE RAMESH CHANDRA KAR",
    saID: "SPR1562",
  },
  {
    firstName: "SHANTI RANJAN",
    lastName: "KAR",
    address: "*SATSANG 814116.",
    fullName: "SHANTI RANJAN KAR",
    saID: "SPR1563",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "KAR",
    address: "AT KANGSHA HATTALA*PO PANAGARH BAZAR*DIST-BARDDHAMAN",
    fullName: "LATE SUDHIR KUMAR KAR",
    saID: "SPR1564",
  },
  {
    firstName: "LATE CHARU BHUSAN",
    lastName: "KARAN",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE CHARU BHUSAN KARAN",
    saID: "SPR1565",
  },
  {
    firstName: "LAXMI KANTA",
    lastName: "KARAN",
    address:
      "PO/VILL-PURUNDA*VIA-KHAKURDA*DIST-PASCHIM MEDINIPUR-721445*PAN AEXPK7407C",
    fullName: "LAXMI KANTA KARAN",
    saID: "SPR1566",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "KARAN",
    address:
      "SATSANG ASHRAM*KOTE BAZAR MEDINIPUR*DIST-PASCHIM MEDINIPUR 721201*PAN-CMYPK8404N",
    fullName: "SUNIL KUMAR KARAN",
    saID: "SPR1567",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "KARAR",
    address: "BALITIKURI BAMUNPARA*PO-BALITIKURI*HOWRAH-711 402.",
    fullName: "LATE HARENDRA NATH KARAR",
    saID: "SPR1568",
  },
  {
    firstName: "ANIL",
    lastName: "KARMAKAR",
    address: "VILL/PO-DAKHIN CHARILAM*SIPAHIJALA, TRIPURA.",
    fullName: "ANIL KARMAKAR",
    saID: "SPR1569",
  },
  {
    firstName: "LATE BHOLANATH",
    lastName: "KARMAKAR",
    address:
      "PLOT NO=7/5*PRIYADARSHINI PARISHAR (WEST)*BHILAI, DURG (C.S.)-490020.",
    fullName: "LATE BHOLANATH KARMAKAR",
    saID: "SPR1570",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "KARMAKAR",
    address: "126, HAZARI LANE*CHATTAGRAM (BANGLADESH).",
    fullName: "BIKASH CHANDRA KARMAKAR",
    saID: "SPR1571",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "KARMAKAR",
    address: "4/48  JAHURA BAZAR LANE*CALCUTTA-42.",
    fullName: "LATE BISWANATH KARMAKAR",
    saID: "SPR1572",
  },
  {
    firstName: "LATE HARISH CHANDRA",
    lastName: "KARMAKAR",
    address: "PO-BARENGAPARA*GARO HILLS",
    fullName: "LATE HARISH CHANDRA KARMAKAR",
    saID: "SPR1573",
  },
  {
    firstName: "LATE KRISHNA KANTA",
    lastName: "KARMAKAR",
    address: "174/25D,N/S.C.BOSE ROAD*AMARKETAN*CALCUTTA-700040",
    fullName: "LATE KRISHNA KANTA KARMAKAR",
    saID: "SPR1574",
  },
  {
    firstName: "LATE MONTULAL",
    lastName: "KARMAKAR",
    address: "AT/PO.LALGOLA*DIST.MURSHIDABAD",
    fullName: "LATE MONTULAL KARMAKAR",
    saID: "SPR1575",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "KARMAKAR",
    address: "TEJGANJ*PO-NUTANGANJ*BURDWAN 713102.",
    fullName: "LATE NARAYAN CHANDRA KARMAKAR",
    saID: "SPR1576",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "KARMAKAR",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SARAT CHANDRA KARMAKAR",
    saID: "SPR1577",
  },
  {
    firstName: "LATE TARAKESWAR",
    lastName: "KARMAKAR",
    address: "AT&PO.BIRNAGAR*NADIA",
    fullName: "LATE TARAKESWAR KARMAKAR",
    saID: "SPR1578",
  },
  {
    firstName: "PRANBALLAV",
    lastName: "KARMAKER",
    address: "V/PO CHHABAN UDAIPUR*RADHAKISHOREPUR*S.TRIPURA-799120",
    fullName: "PRANBALLAV KARMAKER",
    saID: "SPR1579",
  },
  {
    firstName: "LATE RAM NARESH",
    lastName: "KARN",
    address: "VILL-JHARDHURLAKH*PO-KISHANPUR*DT-DARBHANGA",
    fullName: "LATE RAM NARESH KARN",
    saID: "SPR1580",
  },
  {
    firstName: "SATYA NARAYAN LAL",
    lastName: "KARN",
    address: "THAKURBARI*SATSANG*DEOGHAR 814116",
    fullName: "SATYA NARAYAN LAL KARN",
    saID: "SPR1581",
  },
  {
    firstName: "LATE BASANTA BASUDEO",
    lastName: "KATALKAR",
    address: "*MAHARASTRA",
    fullName: "LATE BASANTA BASUDEO KATALKAR",
    saID: "SPR1582",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "KHADANGA",
    address: "NEW BUSSTAND*AT/PO-PHULBANI*DIST-KANDHAMALA*ODISHA-762001",
    fullName: "CHITTARANJAN KHADANGA",
    saID: "SPR1583",
  },
  {
    firstName: "SAROJ KANTA",
    lastName: "KHAMARI",
    address: "SATSANG VIHAR*AT/PO-BHAWANIPATNA*KALAHANDI 766001*PAN-AHMPK1002D",
    fullName: "SAROJ KANTA KHAMARI",
    saID: "SPR1584",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "KHAMARU",
    address: "VILL/PO-MELKI*VIA-PANDUA*DT-HOOGHLY-712149*PAN-EDKPK4424H",
    fullName: "SANJAY KUMAR KHAMARU",
    saID: "SPR1585",
  },
  {
    firstName: "TUKARAM",
    lastName: "KHAN",
    address:
      "BIRLAPUR SATSANG KENDRA*PO-CHAK KASHIPUR*24 PARGANAS SOUTH-743318.",
    fullName: "TUKARAM KHAN",
    saID: "SPR1586",
  },
  {
    firstName: "RASESWAR",
    lastName: "KHANIKAR",
    address: "SWASTI NIVASH*KARENG TINALI,A T RD*PO-JOYSAGAR*SIBSAGAR",
    fullName: "RASESWAR KHANIKAR",
    saID: "SPR1587",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "KHARATI",
    address: "AT-RAMNAGAR*PO-DWARANDA*DIST-BIRBHUM",
    fullName: "SURENDRA NATH KHARATI",
    saID: "SPR1588",
  },
  {
    firstName: "LATE KEDAR NATH",
    lastName: "KHOSHLA",
    address: "76 PINNER ROAD SAKACHI*JAMSHEDPUR*SINGHBHUM.",
    fullName: "LATE KEDAR NATH KHOSHLA",
    saID: "SPR1589",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "KHUNTIA",
    address: "TINIGHARIA, NUASAHI*CUTTACK-753004",
    fullName: "BIJOY KUMAR KHUNTIA",
    saID: "SPR1590",
  },
  {
    firstName: "LATE AMULLYA KUMAR",
    lastName: "KIRTANIA",
    address: "SECTOR NO-4/1.BLOCK NO-12*P.O.KURUD CAMP*DIST-RAIPUR (M.P.)",
    fullName: "LATE AMULLYA KUMAR KIRTANIA",
    saID: "SPR1591",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "KIRTANIA",
    address: "NEW BARRACKPUR RLY STATION*PO-NEW BARRACKPUR*DIST-24-PGS.",
    fullName: "LATE KSHITISH CHANDRA KIRTANIA",
    saID: "SPR1592",
  },
  {
    firstName: "BIRENDRA CHANDRA",
    lastName: "KOCH",
    address: "RISU JONGLAPORAH*PO-RISUBELPARA*MEGHALAYA",
    fullName: "BIRENDRA CHANDRA KOCH",
    saID: "SPR1593",
  },
  {
    firstName: "LATE LILA NATH",
    lastName: "KOCH",
    address:
      "A.E.E(RETD)P.W.D*WARD NO-11,NEAR TUL L.P.SCHOOL*PO-NORTH LAKHIMPUR*LAKHIMPUR 787001",
    fullName: "LATE LILA NATH KOCH",
    saID: "SPR1594",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "KODALI",
    address: "VILL/PO.KANDUA*VIA-ANDULMOURI*DIST-HOWRAH-711302",
    fullName: "AJIT KUMAR KODALI",
    saID: "SPR1595",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "KOLEY",
    address: "AT/PO.DASNAGAR*DT.HOWRAH",
    fullName: "LATE BASANTA KUMAR KOLEY",
    saID: "SPR1596",
  },
  {
    firstName: "BANCHARAM",
    lastName: "KONAR",
    address: "AT MAMUDPUR,PO RATRA*DIST BANKURA",
    fullName: "BANCHARAM KONAR",
    saID: "SPR1597",
  },
  {
    firstName: "SISIR BARAN",
    lastName: "KONAR",
    address:
      "AT-ARRAH BIDHAN PARK*PO-ARRAH(BURDWAN)*DURGAPUR-713212*BARDDHAMAN",
    fullName: "SISIR BARAN KONAR",
    saID: "SPR1598",
  },
  {
    firstName: "KRISHNADHAN",
    lastName: "KONER",
    address: "AT-BORA*PO RATRA*DIST BANKURA-722201*PAN-AGLPK2229G",
    fullName: "KRISHNADHAN KONER",
    saID: "SPR1599",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "KONER",
    address: "VILL-BAMNIA*PO-GANGARAMPUR*DIST-BANKURA-722201",
    fullName: "PURNA CHANDRA KONER",
    saID: "SPR1600",
  },
  {
    firstName: "LATE RATNESWAR",
    lastName: "KONWAR",
    address: "SATSANG VIHAR JYOTINAGAR*PO&DIST-DIBRUGARH-786001*PAN-BRK0379J",
    fullName: "LATE RATNESWAR KONWAR",
    saID: "SPR1601",
  },
  {
    firstName: "BINOY",
    lastName: "SINGHAKOWAR",
    address: "VILL-KHALISANIMARI*PO-BHOWRAGURI*KOKRAJHAR-783336*PAN-AFTPK9412Q",
    fullName: "BINOY SINGHAKOWAR",
    saID: "SPR1602",
  },
  {
    firstName: "MADHABI MOHAN",
    lastName: "KONAR",
    address:
      "DEPTT OF MINERAL ENGINEERING*INDIAN SCHOOL OF MINES*DHANBAD-826001*PAN-ADWPK4383K",
    fullName: "MADHABI MOHAN KONAR",
    saID: "SPR1603",
  },
  {
    firstName: "LATE PRABHAKAR RAO",
    lastName: "KULKARNI",
    address: "ADVOCATE,3-2-843/2*VEER SAVARKAR MARG*KACHGUDA*HYDERABAD-500027",
    fullName: "LATE PRABHAKAR RAO KULKARNI",
    saID: "SPR1604",
  },
  {
    firstName: "BHANU",
    lastName: "KUMAR",
    address: "SATSANG NAGAR,BAIGNA*PO/DIST-KATIHAR-854105*BIHAR",
    fullName: "BHANU KUMAR",
    saID: "SPR1605",
  },
  {
    firstName: "BINOY",
    lastName: "KUMAR",
    address: "PLOT NO-M/1*SRIKRISHNA NAGAR*PATNA 800001*PAN-CRKPK1123P",
    fullName: "BINOY KUMAR",
    saID: "SPR1606",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "KUMAR",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCHBEHAR-735303*PAN-CIZPK7553B",
    fullName: "JITENDRA NATH KUMAR",
    saID: "SPR1607",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "KUMAR",
    address: "SHIBARANI KUTIR*SUBHAS PALLY*PO-BURNPUR-713325*BARDDHAMAN.",
    fullName: "SASANKA SEKHAR KUMAR",
    saID: "SPR1608",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "KUNDU",
    address: "ASHOK NAGAR*VIA-K G R S PATH*PO-ANGUS*HOOGHLY-712221",
    fullName: "AJIT KUMAR KUNDU",
    saID: "SPR1609",
  },
  {
    firstName: "LATE BANGSHIDHAR",
    lastName: "KUNDU",
    address: "AT/PO-JALANDI*DIST-BIRBHUM 731240",
    fullName: "LATE BANGSHIDHAR KUNDU",
    saID: "SPR1610",
  },
  {
    firstName: "BHUPENDRA NATH",
    lastName: "KUNDU",
    address: "AT-BASUDEVPUR*PO.HILI*DAKSHIN DINAJPUR",
    fullName: "BHUPENDRA NATH KUNDU",
    saID: "SPR1611",
  },
  {
    firstName: "BIKASHENDU",
    lastName: "KUNDU",
    address:
      "MAHANANDA PALLY*NEAR SATSANG KENDRA*PO-JHALJHALIA*MALDA-732102*AFZPK7773E",
    fullName: "BIKASHENDU KUNDU",
    saID: "SPR1612",
  },
  {
    firstName: "LATE BISNUPADA",
    lastName: "KUNDU",
    address: "KRISNANAGAR ROAD*PO.KRISNANAGAR*NADIA",
    fullName: "LATE BISNUPADA KUNDU",
    saID: "SPR1613",
  },
  {
    firstName: "LATE DHIRENDRA LAL",
    lastName: "KUNDU",
    address: "25 K.G.R.S.PATH*PO-ANGUR*DIST-HOOGHLY.",
    fullName: "LATE DHIRENDRA LAL KUNDU",
    saID: "SPR1614",
  },
  {
    firstName: "LATE KALI PADA",
    lastName: "KUNDU",
    address: "CHARALI PHARMACY*PO-CHARALI*DARRANG.",
    fullName: "LATE KALI PADA KUNDU",
    saID: "SPR1615",
  },
  {
    firstName: "KANAI LAL",
    lastName: "KUNDU",
    address: "AT/PO-NAHINA*DIST-BIRBHUM-731240.",
    fullName: "KANAI LAL KUNDU",
    saID: "SPR1616",
  },
  {
    firstName: "LAL MOHAN",
    lastName: "KUNDU",
    address: "VILL/PO KOKRAJORE*VIA GANGAJALGHATI*DIST BANKURA-722133",
    fullName: "LAL MOHAN KUNDU",
    saID: "SPR1617",
  },
  {
    firstName: "NETAI",
    lastName: "KUNDU",
    address:
      "VILL-BELEDANGA 36 NO.RAIL GATE*PO-MASLANDAPUR*DIST-24 PGS (NORTH)-743289",
    fullName: "NETAI KUNDU",
    saID: "SPR1618",
  },
  {
    firstName: "NIRANJAN",
    lastName: "KUNDU",
    address:
      "B/2,SRISRI SARADAMA UPANIBESH*JOYRAMPURJALA ROAD*KOLKATA 700 060.",
    fullName: "NIRANJAN KUNDU",
    saID: "SPR1619",
  },
  {
    firstName: "PRADIP",
    lastName: "KUNDU",
    address:
      "C/O-JITENDRA KISORE KUNDU*VILL-NASRAPARA*PO-RANAGHAT*NADIA-741201",
    fullName: "PRADIP KUNDU",
    saID: "SPR1620",
  },
  {
    firstName: "PREMANANDA",
    lastName: "KUNDU",
    address: "UTTAR COLONY*PO-MAL*JALPAIGURI",
    fullName: "PREMANANDA KUNDU",
    saID: "SPR1621",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "KUNDU",
    address: "13H,LAKETERRACE,PO-SANTOSHPUR*CALCUTTA 700075.",
    fullName: "LATE SUDHIR KUMAR KUNDU",
    saID: "SPR1622",
  },
  {
    firstName: "LATE TRIPURARI BHUSAN",
    lastName: "KUNDU",
    address: "STATION ROAD,SANTIKANAN*PO-BERHAMPUR 742101*DIST-MURSHIDABAD.",
    fullName: "LATE TRIPURARI BHUSAN KUNDU",
    saID: "SPR1623",
  },
  {
    firstName: "SRIKANTO",
    lastName: "KURMI",
    address: "SATSANG VIHAR*VILL/PO-BARBIL*SONITPUR*ASSAM",
    fullName: "SRIKANTO KURMI",
    saID: "SPR1624",
  },
  {
    firstName: "LATE RABINDRA CHANDRA",
    lastName: "KUSHARI",
    address: "SECTOR-II,QR.NO.NO.2-315*BOKARO STEEL CITY*JHARKHAND",
    fullName: "LATE RABINDRA CHANDRA KUSHARI",
    saID: "SPR1625",
  },
  {
    firstName: "LANKESWAR",
    lastName: "LAHARI",
    address: "VILL-NILIBARI*PO-SIMLAGURI*DIST-CHIRANG (B.T.A.D.)*ASSAM-783381",
    fullName: "LANKESWAR LAHARI",
    saID: "SPR1626",
  },
  {
    firstName: "LATE ARUN KUMAR",
    lastName: "LAHIRI",
    address: "VILL-TAPAN SATSANG COLONY*PO-TAPAN*D/DINAJPUR",
    fullName: "LATE ARUN KUMAR LAHIRI",
    saID: "SPR1627",
  },
  {
    firstName: "HARADHAN",
    lastName: "LAHIRI",
    address: "81 NO. BARENDRA GALI*PO-HALISAHAR*DIST-24-PGS. 743134.",
    fullName: "HARADHAN LAHIRI",
    saID: "SPR1628",
  },
  {
    firstName: "RADHA KRISHNA",
    lastName: "LAL",
    address: "SATSANG 814116*DEOGHAR*PAN-ABLPL1216F",
    fullName: "RADHA KRISHNA LAL",
    saID: "SPR1629",
  },
  {
    firstName: "LATE DINESHWAR",
    lastName: "LALL",
    address: "C/O PANKAJ KUMAR VERMA*QR NO-491GH RAMPUR COLONY,JAMALPUR*MUNGER",
    fullName: "LATE DINESHWAR LALL",
    saID: "SPR1630",
  },
  {
    firstName: "SHYAM PRASAD",
    lastName: "LALL",
    address: "SWASTI MEDICAL,BHATTABAZAR*PO/DIST-PURNIA-854301*PAN--ACCPL3515B",
    fullName: "SHYAM PRASAD LALL",
    saID: "SPR1631",
  },
  {
    firstName: "DINABANDHU",
    lastName: "LENKA",
    address: "PLOT NO-41,MADHUSUDAN NAGAR*BHUBANESWAR-751001*KHURDA*ORISSA",
    fullName: "DINABANDHU LENKA",
    saID: "SPR1632",
  },
  {
    firstName: "GOURANGA CHARAN",
    lastName: "LENKA",
    address: "CHIEF DISTRICT MEDICAL OFFICE*PO&DIST-CUTTACK-753001",
    fullName: "GOURANGA CHARAN LENKA",
    saID: "SPR1633",
  },
  {
    firstName: "KANHU CHARAN",
    lastName: "LENKA",
    address: "PLOT NO-240/1010*AERODROME AREA*BHUBANESWAR-751009*KHURDA",
    fullName: "KANHU CHARAN LENKA",
    saID: "SPR1634",
  },
  {
    firstName: "LATE PATITPABAN",
    lastName: "LENKA",
    address: "AT-BALAJITPARA*PO-BALABHADRAPUR*VIA-ARNAPAL*BHADRAK.",
    fullName: "LATE PATITPABAN LENKA",
    saID: "SPR1635",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "LENKA",
    address: "HEALTH SUPERVISOR;THERUBALI*AT&PO-THERUBALI*KORAPUT-765018.",
    fullName: "PURNA CHANDRA LENKA",
    saID: "SPR1636",
  },
  {
    firstName: "MUKUNDA",
    lastName: "LODH",
    address: "NEW JAIL ROAD*PO-BELONIA*S.TRIPURA-799155",
    fullName: "MUKUNDA LODH",
    saID: "SPR1637",
  },
  {
    firstName: "LATE DILIP KUMAR",
    lastName: "MAHAJAN",
    address: '"MODERN STORES"WINO BAJAR"*UKHRUL*MANIPUR.',
    fullName: "LATE DILIP KUMAR MAHAJAN",
    saID: "SPR1638",
  },
  {
    firstName: "LATE RAM KRISHNA",
    lastName: "MAHANTA",
    address: "SAGARBHANGA COLONY F-95*DURGAPUR 11*DIST BURDWAN",
    fullName: "LATE RAM KRISHNA MAHANTA",
    saID: "SPR1639",
  },
  {
    firstName: "JAYDEB",
    lastName: "MAHANTO",
    address: "DEB KUTIR*RAMPUR HAZRA LANE*PO&DT-BANKURA 722101*PAN-AEMPM0255P",
    fullName: "JAYDEB MAHANTO",
    saID: "SPR1640",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "MOHANTY",
    address:
      "SATSANG VIHAR DELHI*Q.I.AREA*SATSANG VIHAR MARG*NEW DELHI-110 067",
    fullName: "JATINDRA NATH MOHANTY",
    saID: "SPR1641",
  },
  {
    firstName: "BINAYAK",
    lastName: "MOHAPATRA",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "BINAYAK MOHAPATRA",
    saID: "SPR1642",
  },
  {
    firstName: "LATE AKHIL PRASAD",
    lastName: "MAHATO",
    address: "TUNGRI T F ROAD*PO-CHAIBASA*DT-W SINGBHUM   833201",
    fullName: "LATE AKHIL PRASAD MAHATO",
    saID: "SPR1643",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "MAHATO",
    address: "AT-CHAKUA*PO-BIRIHANDI*DIST-MIDNAPUR",
    fullName: "BASANTA KUMAR MAHATO",
    saID: "SPR1644",
  },
  {
    firstName: "BHOLANATH",
    lastName: "MAHATO",
    address:
      "AT-BHURSATORA*PO-AMLATORA*DIST-PASCHIM MEDINIPUR-721505*PAN CTAPM5746C",
    fullName: "BHOLANATH MAHATO",
    saID: "SPR1645",
  },
  {
    firstName: "BHUPATI BHUSHAN",
    lastName: "MAHATO",
    address: "VILL-JORAM*PO-BHANDARU*SINGHBHUM 832301",
    fullName: "BHUPATI BHUSHAN MAHATO",
    saID: "SPR1646",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "MAHATO",
    address: "MAHESHKUTRO*KOIRADIH*VIA-PORAIYAHAT*GODDA-814153",
    fullName: "GIRIDHARI MAHATO",
    saID: "SPR1647",
  },
  {
    firstName: "LATE GOBINDA",
    lastName: "MAHATO",
    address: "B S E B*PO JHUMRITILAYA*HAJARIBAGH",
    fullName: "LATE GOBINDA MAHATO",
    saID: "SPR1648",
  },
  {
    firstName: "LATE HARISH CHANDRA",
    lastName: "MAHATO",
    address: "PO+VILL-BIRIHANDI*VIA-JHARGRAM*DIST-PASCHIM MEDINIPUR-721507",
    fullName: "LATE HARISH CHANDRA MAHATO",
    saID: "SPR1649",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "MAHATO",
    address: "QR NO-696;STREET 14*SECTOR IX B*BOKARO STEEL CITY*BOKARO",
    fullName: "LATE MADAN MOHAN MAHATO",
    saID: "SPR1650",
  },
  {
    firstName: "AJODHYA",
    lastName: "MAHATO",
    address: "AT/PO-BEDETI*DIST-SONITPUR*(ASSAM)",
    fullName: "AJODHYA MAHATO",
    saID: "SPR1651",
  },
  {
    firstName: "LATE AMULLYA RATAN",
    lastName: "MAHATO",
    address: "MAJURKATA*PO-PIRAKATA*DIST-MIDNAPUR",
    fullName: "LATE AMULLYA RATAN MAHATO",
    saID: "SPR1652",
  },
  {
    firstName: "BANAMALI",
    lastName: "MAHATO",
    address: "VILL-CHAMRUDIHA*PO-KHARSUAGARH*SINGHBHUM-833216",
    fullName: "BANAMALI MAHATO",
    saID: "SPR1653",
  },
  {
    firstName: "BANKU BEHARI",
    lastName: "MAHATO",
    address: "VIL&PO-TATARI*PURULIA-723151",
    fullName: "BANKU BEHARI MAHATO",
    saID: "SPR1654",
  },
  {
    firstName: "LATE BHOLANATH",
    lastName: "MAHATO",
    address: "NO-2,KERUMARA*PO-GOWALTORE*DIST-MIDNAPORE",
    fullName: "LATE BHOLANATH MAHATO",
    saID: "SPR1655",
  },
  {
    firstName: "BINODE BEHARI",
    lastName: "MAHATO",
    address: "VILL/PO JAMDA*BANKURA-722140",
    fullName: "BINODE BEHARI MAHATO",
    saID: "SPR1656",
  },
  {
    firstName: "LATE CHHATRADHAR",
    lastName: "MAHATO",
    address: "KULDIHA*PO-PIRAKATA*DT-MIDNAPORE.",
    fullName: "LATE CHHATRADHAR MAHATO",
    saID: "SPR1657",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "MAHATO",
    address: "VILL-GURUR*PO-HETYAKOLE*PURULIA-723129",
    fullName: "DILIP KUMAR MAHATO",
    saID: "SPR1658",
  },
  {
    firstName: "LATE DURGA CHARAN",
    lastName: "MAHATO",
    address: "AT/PO-KADMAGARH*VIA-RANIBANDH*BANKURA 722 148",
    fullName: "LATE DURGA CHARAN MAHATO",
    saID: "SPR1659",
  },
  {
    firstName: "LATE FAKIR CHANDRA",
    lastName: "MAHATO",
    address: "AT-AMDIHA*PO-DARSOLE*DIST-BANKURA.",
    fullName: "LATE FAKIR CHANDRA MAHATO",
    saID: "SPR1660",
  },
  {
    firstName: "FALGUNI",
    lastName: "MAHATO",
    address: "AT-DULMI MORE*PO-DULMI NADIHA*PURULIA-723102*PAN-AFEPM8646K",
    fullName: "FALGUNI MAHATO",
    saID: "SPR1661",
  },
  {
    firstName: "GURU CHARAN",
    lastName: "MAHATO",
    address: "AT-KAMARDIHA*PO-SIROMANIPUR*DIST-BANKURA-722134",
    fullName: "GURU CHARAN MAHATO",
    saID: "SPR1662",
  },
  {
    firstName: "JAGANNATH",
    lastName: "MAHATO",
    address: "VILL-SUSHUNIA*PO-BANDUBRAJPUR*BANKURA-722151*PAN-AEEPM3244E",
    fullName: "JAGANNATH MAHATO",
    saID: "SPR1663",
  },
  {
    firstName: "LAXMI KANTA",
    lastName: "MAHATO",
    address: "VILL-BORBIL*PO-GHATUA*SONITPUR",
    fullName: "LAXMI KANTA MAHATO",
    saID: "SPR1664",
  },
  {
    firstName: "LATE NEMAI CHANDRA",
    lastName: "MAHATO",
    address: "AT+PO-BELPAHARI*DIST-MIDNAPORE",
    fullName: "LATE NEMAI CHANDRA MAHATO",
    saID: "SPR1665",
  },
  {
    firstName: "PARMANAND",
    lastName: "MAHATO",
    address:
      "RUKMANI NEST;FLAT NO 3 B*KUMARTOLI 56 SET*DORANDA*RANCHI--834002.*PAN-ABGPM4953R",
    fullName: "PARMANAND MAHATO",
    saID: "SPR1666",
  },
  {
    firstName: "PARMESHWAR PRASAD",
    lastName: "MAHATO",
    address: "AT&PO-ASANCHUA*DIST-JAMTARA*JHARKHAND-815351",
    fullName: "PARMESHWAR PRASAD MAHATO",
    saID: "SPR1667",
  },
  {
    firstName: "LATE PREM",
    lastName: "MAHATO",
    address: "VIL&PO.CHAKIRBON*PURULIA",
    fullName: "LATE PREM MAHATO",
    saID: "SPR1668",
  },
  {
    firstName: "RAM RANJAN",
    lastName: "MAHATO",
    address: "C/O MADAN BORO,NUTAN BAJAR*PO-CHAKULIA*SINGHBHUM",
    fullName: "RAM RANJAN MAHATO",
    saID: "SPR1669",
  },
  {
    firstName: "LATE RAM PADA",
    lastName: "MAHATO",
    address: "VIL&PO-TATARI*VIA-PUNCHA*PURULIA",
    fullName: "LATE RAM PADA MAHATO",
    saID: "SPR1670",
  },
  {
    firstName: "SIBRAM",
    lastName: "MAHATO",
    address: "VILL-RAIBANDH*PO-SIMULIA*PURULIA-723102",
    fullName: "SIBRAM MAHATO",
    saID: "SPR1671",
  },
  {
    firstName: "TULSI",
    lastName: "MAHATO",
    address: "AT-J.K.COLLEGE*PURULIA 723 101",
    fullName: "TULSI MAHATO",
    saID: "SPR1672",
  },
  {
    firstName: "LATE DALBAHADUR",
    lastName: "MAHATTRA",
    address: "BIMAL STORES JOGBANI BAZAR*PO-JOGBANI-854328*DIST-PURNEA",
    fullName: "LATE DALBAHADUR MAHATTRA",
    saID: "SPR1673",
  },
  {
    firstName: "LATE BANKIM BEHARI",
    lastName: "MAHISH",
    address: "AT-NIMTOLA*PO-PIRAKATA*DIST-MIDNAPORE.",
    fullName: "LATE BANKIM BEHARI MAHISH",
    saID: "SPR1674",
  },
  {
    firstName: "DUKHAHARAN",
    lastName: "MAHATO",
    address: "VILL&PO-KUNJORA*VIA-PALAJORI*DEOGHAR-814 146*PAN DBHPM5990Q",
    fullName: "DUKHAHARAN MAHATO",
    saID: "SPR1675",
  },
  {
    firstName: "LATE REV ASIT",
    lastName: "MAITRA",
    address: "62/102, PRATAP NAGAR HOUSING COLONY*SANGANER*JAIPUR-303906.",
    fullName: "LATE REV ASIT MAITRA",
    saID: "SPR1676",
  },
  {
    firstName: "LATE SALIL KUMAR",
    lastName: "MAITRA",
    address: "MIRABAZAR*PO-PALASI*NADIA-741155",
    fullName: "LATE SALIL KUMAR MAITRA",
    saID: "SPR1677",
  },
  {
    firstName: "BHUPENDRA CHANDRA",
    lastName: "MAITRI",
    address: "V-SIRAJULIPATHAR*PO-PIRAKATA NO-2*DIST-DARRANG",
    fullName: "BHUPENDRA CHANDRA MAITRI",
    saID: "SPR1678",
  },
  {
    firstName: "LATE HIMANGSHU KUMAR",
    lastName: "MAITRI",
    address: "VILL SIRAJULIPATHAR*PO PIRAKATA*VIA DHEKIAJULI*DARRANG",
    fullName: "LATE HIMANGSHU KUMAR MAITRI",
    saID: "SPR1679",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "MAITY",
    address: "AT-KHARKI*PO-CONTAI*DIST-PURBA MEDINIPUR-721401",
    fullName: "AJIT KUMAR MAITY",
    saID: "SPR1680",
  },
  {
    firstName: "AMALENDU",
    lastName: "MAITY",
    address: "VILL.KOCHILA*PO.JAPA*VIA.ERSAMA*JAGATSINGHPUR-754139",
    fullName: "AMALENDU MAITY",
    saID: "SPR1681",
  },
  {
    firstName: "AMULLYA CHARAN",
    lastName: "MAITY",
    address: "PO&VILL-HAUR*DIST-PASCHIM MEDINIPUR-721131*PAN NO.BOKPM7181M",
    fullName: "AMULLYA CHARAN MAITY",
    saID: "SPR1682",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "MAITI",
    address: "AT-SAHADABEDI*PO-ERASAMA*CUTTACK",
    fullName: "LATE ASHUTOSH MAITI",
    saID: "SPR1683",
  },
  {
    firstName: "LATE BHUDHAR CHANDRA",
    lastName: "MAITY",
    address: "AT+PO-AJOYA*DIST-MIDNAPORE.",
    fullName: "LATE BHUDHAR CHANDRA MAITY",
    saID: "SPR1684",
  },
  {
    firstName: "LATE BRAJENDRA NATH",
    lastName: "MAITY",
    address: "VILL-CHAKMANG*PO-JHIKURIA*DT-MIDNAPORE.",
    fullName: "LATE BRAJENDRA NATH MAITY",
    saID: "SPR1685",
  },
  {
    firstName: "LATE GUNADHAR",
    lastName: "MAITY",
    address: "MAHAMMADPUR*PO-NANDAKUMAR*DIST-MIDNAPORE",
    fullName: "LATE GUNADHAR MAITY",
    saID: "SPR1686",
  },
  {
    firstName: "JOYDEB",
    lastName: "MAITY",
    address: "VILL-RANBAGPUR*PO.GHARGOHAL*DT.HOOGHLY-712414",
    fullName: "JOYDEB MAITY",
    saID: "SPR1687",
  },
  {
    firstName: "KSHUDIRAM",
    lastName: "MAITY",
    address: "AT-EKTARPORE*PO-BHUPATINAGAR*DIST-MIDNAPORE",
    fullName: "KSHUDIRAM MAITY",
    saID: "SPR1688",
  },
  {
    firstName: "MANASH RANJAN",
    lastName: "MAITY",
    address: "AT+PO-AJOYA*DIST-PURBA MEDINIPUR-721430*PAN-CHNPM9814Q",
    fullName: "MANASH RANJAN MAITY",
    saID: "SPR1689",
  },
  {
    firstName: "LATE MONORANJAN",
    lastName: "MAITY",
    address: "B-30/6 KALINDI HOUSING ESTATE*CALCUTTA 55",
    fullName: "LATE MONORANJAN MAITY",
    saID: "SPR1690",
  },
  {
    firstName: "NIRMAL CHANDRA",
    lastName: "MAITY",
    address: "AT/PO-DEHATI*VIA-PANSKURA*PURBA MEDINIPUR-721139*PAN-BYPPM3347C",
    fullName: "NIRMAL CHANDRA MAITY",
    saID: "SPR1691",
  },
  {
    firstName: "LATE PABITRA KUMAR",
    lastName: "MAITI",
    address: "PASCHIM BHAGAWANPUR*PO-DARUA*VIA-CONTAI*PURBA MEDINIPUR-721401",
    fullName: "LATE PABITRA KUMAR MAITI",
    saID: "SPR1692",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MAITY",
    address:
      '"SWASTI KUNJA"*75/2/1 BAKSARA VILLAGE RD*PO-BAKSARA*HOWRAH 711306.',
    fullName: "PANCHANAN MAITY",
    saID: "SPR1693",
  },
  {
    firstName: "PARITOSH",
    lastName: "MAITY",
    address: "VILL-DAYALPUR*PO-BALICHAK*PASCHIM MEDINIPUR.",
    fullName: "PARITOSH MAITY",
    saID: "SPR1694",
  },
  {
    firstName: "LATE PRAN KRISHNA",
    lastName: "MAITY",
    address: "C/O RAM NIWAS PANDEY*AT-BALIPUR*PO/DT-PRATAPGARH*U.P.-230001",
    fullName: "LATE PRAN KRISHNA MAITY",
    saID: "SPR1695",
  },
  {
    firstName: "LATE PULIN BIHARI",
    lastName: "MAITY",
    address: "VILL&PO-CHOUDDACHULI*DT-MIDNAPORE",
    fullName: "LATE PULIN BIHARI MAITY",
    saID: "SPR1696",
  },
  {
    firstName: "LATE RADHA KRISHNA",
    lastName: "MAITY",
    address: "DIGHA SATSANG CENTRE*PO-DIGHA*DIST-MIDNAPORE.",
    fullName: "LATE RADHA KRISHNA MAITY",
    saID: "SPR1697",
  },
  {
    firstName: "LATE RAJKRISHNA",
    lastName: "MAITY",
    address: "AT/PO-GANESHNAGAR*DIST-24 PGS.",
    fullName: "LATE RAJKRISHNA MAITY",
    saID: "SPR1698",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "MAITY",
    address: "RANBAGPUR*PO.GHARGOHAL*DIST.HOOGHLY-712414",
    fullName: "SAROJ KUMAR MAITY",
    saID: "SPR1699",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "MAITY",
    address: "BIRENDRA PAPER HOUSE*PO-CONTAI*DIST-MIDNAPORE.",
    fullName: "LATE SATISH CHANDRA MAITY",
    saID: "SPR1700",
  },
  {
    firstName: "SHYAMAPADA",
    lastName: "MAITY",
    address:
      "PRIYABANI APPARTMENT*AG-147/1, TALBAGAN*PO-PRAFULLAKANAN*KOLKATA-700101.",
    fullName: "SHYAMAPADA MAITY",
    saID: "SPR1701",
  },
  {
    firstName: "LATE SRIKANTHA",
    lastName: "MAITY",
    address: '"AMAR DYOTON"*PO-KAKDWIP*DIST-24 PGS.(S)',
    fullName: "LATE SRIKANTHA MAITY",
    saID: "SPR1702",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "MAITY",
    address: "VILL-MASURIA*PO-LAKSHYA*MIDNAPUR",
    fullName: "LATE SUDHIR CHANDRA MAITY",
    saID: "SPR1703",
  },
  {
    firstName: "SUKUMAR",
    lastName: "MAITY",
    address: "VILL/PO-TALCHUA*KENDRAPARA",
    fullName: "SUKUMAR MAITY",
    saID: "SPR1704",
  },
  {
    firstName: "LATE SURENDRA",
    lastName: "MAITY",
    address: "*MIDNAPORE.",
    fullName: "LATE SURENDRA MAITY",
    saID: "SPR1705",
  },
  {
    firstName: "TIMIR BARAN",
    lastName: "MAITI",
    address:
      "VILL-DAKSHIN MECHOGRAM*PO-UTTAR MECHOGRAM*VIA-PANSKURA*DIST-PURBA MEDINIPUR-721139",
    fullName: "TIMIR BARAN MAITI",
    saID: "SPR1706",
  },
  {
    firstName: "TRILOCHAN",
    lastName: "MAITY",
    address:
      "AT/PO-SHYAMSUNDARPUR PATNA*VIA-PANSKURA*DIST-PURBA MEDINIPUR-721169*PAN-ADRPM7375E",
    fullName: "TRILOCHAN MAITY",
    saID: "SPR1707",
  },
  {
    firstName: "RAKHAHARI",
    lastName: "MAJHI",
    address: "VIL-PUAPUR*PO.CHALMARA*PURULIA-723121",
    fullName: "RAKHAHARI MAJHI",
    saID: "SPR1708",
  },
  {
    firstName: "AMIYABINDU",
    lastName: "MAJHI",
    address:
      "SATSANG VIHAR BADLAPUR*L.B.S. MARG, HENDRAPADA*PO-KULGAON*DIST-THANE-421503.",
    fullName: "AMIYABINDU MAJHI",
    saID: "SPR1709",
  },
  {
    firstName: "ANNADA PRASAD",
    lastName: "MAJI",
    address: "SATSANG-814116*PAN-ASBPM0547R",
    fullName: "ANNADA PRASAD MAJI",
    saID: "SPR1710",
  },
  {
    firstName: "LATE BHAKTAPADA",
    lastName: "MAJHI",
    address: "AT-SIHIGRAM*PO-MADHYAMGRAM*DIST-BURDWAN",
    fullName: "LATE BHAKTAPADA MAJHI",
    saID: "SPR1711",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "MAJI",
    address: "AT-ALIPIN*PO-SHYAMPUR*DT-HOWRAH-711314.",
    fullName: "BIKASH CHANDRA MAJI",
    saID: "SPR1712",
  },
  {
    firstName: "DHANANJOY",
    lastName: "MAJHI",
    address: "AT&PO-JHARPUKHURIA*MAYURBHANJ 757 086.*PAN-BTBPM8872M",
    fullName: "DHANANJOY MAJHI",
    saID: "SPR1713",
  },
  {
    firstName: "LATE DHANANJOI",
    lastName: "MAJHI",
    address: "RLY.QR NO 359,UNIT I*SOUTH KHARAGPUR*PO-KHARAGPUR*MIDNAPUR",
    fullName: "LATE DHANANJOI MAJHI",
    saID: "SPR1714",
  },
  {
    firstName: "LATE NABANI CHANDRA",
    lastName: "MAJHI",
    address: "BALIGHATA*PO-RAGHUNATHGANJ*MURSHIDABAD.",
    fullName: "LATE NABANI CHANDRA MAJHI",
    saID: "SPR1715",
  },
  {
    firstName: "LATE RAGDA",
    lastName: "MAJHI",
    address: "AT&PO-RANGJULI*VIA-KHOWANG*DIST-DIBRUGARH-785676*ASSAM",
    fullName: "LATE RAGDA MAJHI",
    saID: "SPR1716",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "MAJHI",
    address: "VILL & PO-THAKURANICHAK*DIST.HOOGHLY-712613",
    fullName: "SUBHASH CHANDRA MAJHI",
    saID: "SPR1717",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "MAJUMDAR",
    address:
      "24A,SHYAMSUNDAR PALLY MAIN RD*SAKUNTALA PARK*SARSAUNA*CALCUTTA 700061",
    fullName: "AJIT KUMAR MAJUMDAR",
    saID: "SPR1718",
  },
  {
    firstName: "LATE ARUN CHANDRA",
    lastName: "MAJUMDAR",
    address: "AT SHYAMPUR COLONY*PO DURGAPUR-1*DIST BURDWAN",
    fullName: "LATE ARUN CHANDRA MAJUMDAR",
    saID: "SPR1719",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "MAJUMDAR",
    address: "PO-RENTERS COLONY*BIBEKANAND NAGAR*TRIPURA",
    fullName: "LATE ASHUTOSH MAJUMDAR",
    saID: "SPR1720",
  },
  {
    firstName: "CHANDAN",
    lastName: "MAJUMDAR",
    address:
      "C/O SANTI NARAYAN MAJUMDAR*PO/DIST-KOKRAJHAR-783370*ASSAM*PAN-AJKPM4165B",
    fullName: "CHANDAN MAJUMDAR",
    saID: "SPR1721",
  },
  {
    firstName: "LATE CHITTA RANJAN",
    lastName: "MAJUMDAR",
    address: "VILL-BARABHAIYA*PO-BAGMA UDAIPUR*S.TRIPURA.",
    fullName: "LATE CHITTA RANJAN MAJUMDAR",
    saID: "SPR1722",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "MAJUMDAR",
    address: "PO-BARPETA*DIST KAMRUP",
    fullName: "LATE DHIRENDRA KUMAR MAJUMDAR",
    saID: "SPR1723",
  },
  {
    firstName: "DIBYENDU SEKHAR",
    lastName: "MAJUMDAR",
    address: "ASHRAM ROAD*BILPAR*DIST-SILCHAR 5*ASSAM",
    fullName: "DIBYENDU SEKHAR MAJUMDAR",
    saID: "SPR1724",
  },
  {
    firstName: "LATE HARAPRASANNA",
    lastName: "MAJUMDAR",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE HARAPRASANNA MAJUMDAR",
    saID: "SPR1725",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "MAJUMDAR",
    address: "PO/VILL-BAGMA*VIA-R K PUR*TRIPURA SOUTH",
    fullName: "LATE HARIPADA MAJUMDAR",
    saID: "SPR1726",
  },
  {
    firstName: "LATE HARIBAR",
    lastName: "MAJUMDAR",
    address:
      "C/O SHEFALI RANI MAJUMDER*UTTAM NAGAR,MUL ROAD*PO-CHANDRAPUR 442401*M.S",
    fullName: "LATE HARIBAR MAJUMDAR",
    saID: "SPR1727",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "MAJUMDAR",
    address: "VIL&PO-TUFANGANJ*COOCHBEHAR",
    fullName: "LATE JOGESH CHANDRA MAJUMDAR",
    saID: "SPR1728",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "MAJUMDAR",
    address: "MANIKPUR THAKURPARA*ITALGACHH*CALCUTTA 79",
    fullName: "LATE KIRAN CHANDRA MAJUMDAR",
    saID: "SPR1729",
  },
  {
    firstName: "MANIK",
    lastName: "MAJUMDAR",
    address: "85/A OXY TOWN*CALCUTTA 61",
    fullName: "MANIK MAJUMDAR",
    saID: "SPR1730",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MAJUMDAR",
    address: "PLOT NO-2,AVINASH NAGAR*BHOPAL-462021*M.P.",
    fullName: "NARAYAN CHANDRA MAJUMDAR",
    saID: "SPR1731",
  },
  {
    firstName: "NIRANJAN KUMAR",
    lastName: "MAJUMDAR",
    address: "AT&PO-MOHITNAGAR*JALPAIGURI-735102*PAN-CUOPM5264Q",
    fullName: "NIRANJAN KUMAR MAJUMDAR",
    saID: "SPR1732",
  },
  {
    firstName: "LATE RAJENDRA NATH",
    lastName: "MAJUMDAR",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE RAJENDRA NATH MAJUMDAR",
    saID: "SPR1733",
  },
  {
    firstName: "LATE SATINDRA NATH",
    lastName: "MAJUMDAR",
    address: "68/1,SURYA SEN STREET*CALCUTTA-9",
    fullName: "LATE SATINDRA NATH MAJUMDAR",
    saID: "SPR1734",
  },
  {
    firstName: "SATYENDRA KRISHNA",
    lastName: "MAJUMDAR",
    address: "KAILASHDHAM*PURANDAHA*DEOGHAR",
    fullName: "SATYENDRA KRISHNA MAJUMDAR",
    saID: "SPR1735",
  },
  {
    firstName: "SURJYA KUMAR",
    lastName: "MAZUMDER",
    address: "ANANDA PARA*PO-SABROOM*TRIPURA SOUTH 799145.",
    fullName: "SURJYA KUMAR MAZUMDER",
    saID: "SPR1736",
  },
  {
    firstName: "LATE ANNADA KANTA",
    lastName: "MAJUMDER",
    address: "KAIBARTA PARA ROAD*PO-BARASAT*DIST-24 PGS.",
    fullName: "LATE ANNADA KANTA MAJUMDER",
    saID: "SPR1737",
  },
  {
    firstName: "LATE DEBENDRA",
    lastName: "MAJUMDER",
    address: "PO+V-HARISINGHA*DIST-DARRANG.",
    fullName: "LATE DEBENDRA MAJUMDER",
    saID: "SPR1738",
  },
  {
    firstName: "LATE DHRUBA KUMAR",
    lastName: "MAJUMDER",
    address: "PO+VILL-SIPOY BAZAR*DIST-PASCHIM MEDINIPUR",
    fullName: "LATE DHRUBA KUMAR MAJUMDER",
    saID: "SPR1739",
  },
  {
    firstName: "LATE GOURKANTI",
    lastName: "MAJUMDER",
    address: "C/O DULAL KANTI MAJUMDAR*MIRZABAZAR*MIDNAPUR-721101",
    fullName: "LATE GOURKANTI MAJUMDER",
    saID: "SPR1740",
  },
  {
    firstName: "LATE KANAILAL",
    lastName: "MAJUMDER",
    address: "PO-KAMALPUR.*DT-TRIPURA.",
    fullName: "LATE KANAILAL MAJUMDER",
    saID: "SPR1741",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "MAJUMDER",
    address: "AT-HUDA*PO-AKAIPUR*DIST-24 PGS",
    fullName: "LATE MANORANJAN MAJUMDER",
    saID: "SPR1742",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "MAJUMDER",
    address: "MIRZABAZAR*PO-MEDINIPUR*DIST-PASCHIM MEDINIPUR",
    fullName: "LATE MRINAL KANTI MAJUMDER",
    saID: "SPR1743",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MAJUMDER",
    address: "VILL-PRANTA POLLY*PO-GAJAL*MALDA 732124*PAN-AELPM4256K",
    fullName: "NIRANJAN MAJUMDER",
    saID: "SPR1744",
  },
  {
    firstName: "PRABIR KUMAR",
    lastName: "MAZUMDER",
    address: "RABINDRA SARANI,WARD NO-2*PO&DT-HAILAKANDI",
    fullName: "PRABIR KUMAR MAZUMDER",
    saID: "SPR1745",
  },
  {
    firstName: "RADHASHYAM",
    lastName: "MAJUMDER",
    address: "KUNDUGHAT*PO-CHANDANNAGAR*DIST-HOOGHLY-712136",
    fullName: "RADHASHYAM MAJUMDER",
    saID: "SPR1746",
  },
  {
    firstName: "TARUN KUMAR",
    lastName: "MAJUMDER",
    address: "DIMAPUR SATSANG VIHAR*PO-DIMAPUR*NAGALAND 797112",
    fullName: "TARUN KUMAR MAJUMDER",
    saID: "SPR1747",
  },
  {
    firstName: "LATE GUNESWAR",
    lastName: "MAKRARI",
    address: "VILL&PO-BHADEI PANCHALI*DIBRUGARH.",
    fullName: "LATE GUNESWAR MAKRARI",
    saID: "SPR1748",
  },
  {
    firstName: "LATE AMULLYA RATAN",
    lastName: "MALAKAR",
    address: "NEAR DR B BISWAS*SUKANTAPALLY*PO-SILIGURI BAZAR*JALPAIGURI",
    fullName: "LATE AMULLYA RATAN MALAKAR",
    saID: "SPR1749",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "MALAKAR",
    address: "VILL/PO-KAPATI*DARRANG",
    fullName: "PRADIP KUMAR MALAKAR",
    saID: "SPR1750",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "MALAKAR",
    address: "5/24 NETAJEENAGAR*CALCUTTA 40",
    fullName: "LATE SATISH CHANDRA MALAKAR",
    saID: "SPR1751",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "MALAKAR",
    address: "AT+PO-GAJAL*DIST-MALDA.",
    fullName: "SUBAL CHANDRA MALAKAR",
    saID: "SPR1752",
  },
  {
    firstName: "LATE HAZARILAL",
    lastName: "MALI",
    address: "BENGALI COLONY NO-3*P.H.COLONY,VIA MANRI*DIST RAICHUR*KARNATAKA",
    fullName: "LATE HAZARILAL MALI",
    saID: "SPR1753",
  },
  {
    firstName: "PRASANNA",
    lastName: "MALLABARUA",
    address: "L.N.BEJBARUA RD*AT/PO-NALBARI*DIST-NALBARI-781335*ASSAM",
    fullName: "PRASANNA MALLABARUA",
    saID: "SPR1754",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "MALLICK",
    address: "BONT CHAWK*BHADRAK*ORISSA",
    fullName: "LATE DEBENDRA NATH MALLICK",
    saID: "SPR1755",
  },
  {
    firstName: "GOPENDRA NARAYAN",
    lastName: "MALLICK",
    address:
      "C/O-VIDYANAND SAHAY*BACK OF NONIA TOLI*WEST SARISTABAD*PO-ANISABAD*PATNA-800 002.",
    fullName: "GOPENDRA NARAYAN MALLICK",
    saID: "SPR1756",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MALLICK",
    address: "VILL KALINAGAR*PO DARUN MALLIK*DIST KHULNA BANGLADESH",
    fullName: "RANJIT KUMAR MALLICK",
    saID: "SPR1757",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "MALLICK",
    address: "SARASHI BHAVAN*PO-SATSANG*DIST-DEOGHAR 814116",
    fullName: "DHIRENDRA KUMAR MALLICK",
    saID: "SPR1758",
  },
  {
    firstName: "LATE HARIDAS",
    lastName: "MALLIK",
    address: "NARASINGHPARA*PO/DT-GOALPARA.",
    fullName: "LATE HARIDAS MALLIK",
    saID: "SPR1759",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "MALLIK",
    address: "123 LIBRARY ROAD*SHANTIPALLY*PO BHADRESWAR*DIST HOOGHLY",
    fullName: "LATE JATINDRA NATH MALLIK",
    saID: "SPR1760",
  },
  {
    firstName: "LATE KUMUD BEHARI",
    lastName: "MALLIK",
    address: "NALINI BOSE ROAD*PO-KANCHRAPARA*DIST-24 PGS",
    fullName: "LATE KUMUD BEHARI MALLIK",
    saID: "SPR1761",
  },
  {
    firstName: "NANDA LAL",
    lastName: "MALLIK",
    address: "PO-KHATRA*DIST-BANKURA-722140",
    fullName: "NANDA LAL MALLIK",
    saID: "SPR1762",
  },
  {
    firstName: "RASAMAY",
    lastName: "MALLIK",
    address:
      "MEENAKSHI HOUSING COMPLEX*FLAT-A-3,SOUTH SARANIA,ULUBARI*GUWAHATI-781007*ADRPM9348M",
    fullName: "RASAMAY MALLIK",
    saID: "SPR1763",
  },
  {
    firstName: "LATE SACHINDRA",
    lastName: "MALLIK",
    address: '"SATSANG VIHAR"*KRISHNANAGAR*PO-AGARTALA*W-TRIPURA',
    fullName: "LATE SACHINDRA MALLIK",
    saID: "SPR1764",
  },
  {
    firstName: "LATE SANTIRAM",
    lastName: "MALLIK",
    address: "VILL&PO-NAGARUKHRA*DT-NADIA.",
    fullName: "LATE SANTIRAM MALLIK",
    saID: "SPR1765",
  },
  {
    firstName: "LATE ANIL BARAN",
    lastName: "MANDAL",
    address: "AT-SULTANPUR*PO.JAGACHHA*DT.HOWRAH",
    fullName: "LATE ANIL BARAN MANDAL",
    saID: "SPR1766",
  },
  {
    firstName: "LATE DIGAMBAR",
    lastName: "MANDAL",
    address: "GOPAL PRASAD*PO-CHARALMANI*DT-MALDA.",
    fullName: "LATE DIGAMBAR MANDAL",
    saID: "SPR1767",
  },
  {
    firstName: "HEMANTA NATH",
    lastName: "MANDAL",
    address: "NEAR N.C.C OFFICE*PO.DHUBRI*GOALPARA",
    fullName: "HEMANTA NATH MANDAL",
    saID: "SPR1768",
  },
  {
    firstName: "HEMENDRA NARAYAN",
    lastName: "MANDAL",
    address: "COLLEGE NAGAR*PO-BIDYAPARA*DIST-DHUBRI-783324*ASSAM",
    fullName: "HEMENDRA NARAYAN MANDAL",
    saID: "SPR1769",
  },
  {
    firstName: "LATE JAGANNATH",
    lastName: "MANDAL",
    address: "AT+PO-BANKRA*VIA-HINGALGANJ*DIST-24 PARGANAS.",
    fullName: "LATE JAGANNATH MANDAL",
    saID: "SPR1770",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "MANDAL",
    address: "AT/PO-RAMNATHPUR*DIST.HOOGHLY",
    fullName: "JATINDRA NATH MANDAL",
    saID: "SPR1771",
  },
  {
    firstName: "KALI CHARAN",
    lastName: "MANDAL",
    address: "VILL/PO-TUARDANGA*SATKHIRA*BANGLADESH",
    fullName: "KALI CHARAN MANDAL",
    saID: "SPR1772",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "MANDAL",
    address: "VILL-NIYAL*PO-BALLALDIGHI*DIST-HOOGHLY-712135",
    fullName: "KARTIK CHANDRA MANDAL",
    saID: "SPR1773",
  },
  {
    firstName: "KRISHNADAS",
    lastName: "MANDAL",
    address: "RAJAPUR*PO-DAKSHIN BARASAT*DIST-24PARGANAS (SOUTH)-7743372",
    fullName: "KRISHNADAS MANDAL",
    saID: "SPR1774",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "MANDAL",
    address: "VILL-SULTANPUR*PO-MUKSUDPUR*DT-MIDNAPORE.",
    fullName: "LATE MANMATHA NATH MANDAL",
    saID: "SPR1775",
  },
  {
    firstName: "LATE SASANKA SEKHAR",
    lastName: "MANDAL",
    address: "AT-KRISHNAPUR CHAK*PO.GANESHPUR*DT.HOWRAH",
    fullName: "LATE SASANKA SEKHAR MANDAL",
    saID: "SPR1776",
  },
  {
    firstName: "LATE SUDHANGSU",
    lastName: "MANDAL",
    address:
      "534/6 BAGBERA RAIL COLONY*NEAR JHUPRI*PO-JAMSHEDPUR 831002*SINGHBHUM",
    fullName: "LATE SUDHANGSU MANDAL",
    saID: "SPR1777",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "MANDHATA",
    address: "AT-NUAGAN*PO/DIST-JAJPUR*PAN-OMTPM4000R",
    fullName: "HRUSHIKESH MANDHATA",
    saID: "SPR1778",
  },
  {
    firstName: "ASIT KUMAR",
    lastName: "MANDOL",
    address: "VILL&PO-UKSA*VIA-KALIGANJ*DT-SATKHIRA*BANGLADESH",
    fullName: "ASIT KUMAR MANDOL",
    saID: "SPR1779",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "MANDOL",
    address: "VILL&PO-NAGARUKHRA*NADIA",
    fullName: "LATE GANGADHAR MANDOL",
    saID: "SPR1780",
  },
  {
    firstName: "LATE LALIT MOHAN",
    lastName: "MANDOL",
    address: "VILL&PO-KAKDWIP*DIST-24PARGANAS.",
    fullName: "LATE LALIT MOHAN MANDOL",
    saID: "SPR1781",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MONDAL",
    address: "AT-JAYNAGAR*PO-BATTALA*AGARTALA*TRIPURA",
    fullName: "PRAFULLA KUMAR MONDAL",
    saID: "SPR1782",
  },
  {
    firstName: "LATE SAKALDIP",
    lastName: "MANDOL",
    address: "SAHEBGANJ COLLEGE*SAHEBGANJ",
    fullName: "LATE SAKALDIP MANDOL",
    saID: "SPR1783",
  },
  {
    firstName: "LATE AMULYA RATAN",
    lastName: "MANNA",
    address: "DANGARPARA SATSANG KENDRA*PO-BHADUPARA*DT-MIDNAPORE.",
    fullName: "LATE AMULYA RATAN MANNA",
    saID: "SPR1784",
  },
  {
    firstName: "LATE BHAGYADHAR",
    lastName: "MANNA",
    address:
      "BRAJABALLAVPUR*B.M.T.SIKSHA NIKETAN*PO-BRA JABALLAVPUR*DIST-24 PGS.",
    fullName: "LATE BHAGYADHAR MANNA",
    saID: "SPR1785",
  },
  {
    firstName: "NABA KUMAR",
    lastName: "MANNA",
    address: "V-SHANKERPUR*PO-SARJAMDA*VIA-TATANAGER*SINGHBHUM  831002.",
    fullName: "NABA KUMAR MANNA",
    saID: "SPR1786",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "MANNA",
    address: "VILL-GAJA*PO-UDAYNARAYANPUR*HOWRAH-711226",
    fullName: "NIMAI CHANDRA MANNA",
    saID: "SPR1787",
  },
  {
    firstName: "LATE RAKHAHARI",
    lastName: "MANNA",
    address:
      "C/O DR.TARAK CHATTERJEE*L/3.SARATPALLY,MEDINIPUR*PASCHIM MEDINIPUR",
    fullName: "LATE RAKHAHARI MANNA",
    saID: "SPR1788",
  },
  {
    firstName: "LATE SANATAN",
    lastName: "MANNA",
    address: "AT-RAKSHATAK*PO-KOLAGHAT*MIDNAPORE",
    fullName: "LATE SANATAN MANNA",
    saID: "SPR1789",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "MANNA",
    address: "VILL-DAKSHIN SHIBGANJ*PO-PATHARPRATIMA*DIST-24 PGS.",
    fullName: "LATE SUBODH CHANDRA MANNA",
    saID: "SPR1790",
  },
  {
    firstName: "BISHESWAR",
    lastName: "MARANDI",
    address: "JAMTARA COURT*PO-JAMTARA*DIST-JAMTARA-815351*(JHARKHAND)",
    fullName: "BISHESWAR MARANDI",
    saID: "SPR1791",
  },
  {
    firstName: "PREMANAND",
    lastName: "MUSHAHARY",
    address:
      "LANGHIN SATSANG VIHAR*P.O LANGHIN TINYALI*KARBIANGLONG-782441*ASSAM",
    fullName: "PREMANAND MUSHAHARY",
    saID: "SPR1792",
  },
  {
    firstName: "LATE NAGENDRA CHANDRA",
    lastName: "MASWARY",
    address: "VILL-BALINAHABI*PO-RANGTA CHARALI*DARRANG.",
    fullName: "LATE NAGENDRA CHANDRA MASWARY",
    saID: "SPR1793",
  },
  {
    firstName: "LATE RATAN KUMAR",
    lastName: "MOTHEY",
    address: "CAPITAL TAILORS*NEAR N.S.T.ROAD*KOHIMA*NAGALAND.",
    fullName: "LATE RATAN KUMAR MOTHEY",
    saID: "SPR1794",
  },
  {
    firstName: "LATE K B",
    lastName: "MATHUR",
    address: "63,SHASTRINAGAR*LUCKNOW-226004*U. P.",
    fullName: "LATE K B MATHUR",
    saID: "SPR1795",
  },
  {
    firstName: "LATE BISWANATH JAGANNATH",
    lastName: "MATRA",
    address: "PANDURANGWADI*GOREGAON*BOMBAY-63",
    fullName: "LATE BISWANATH JAGANNATH MATRA",
    saID: "SPR1796",
  },
  {
    firstName: "AMAL KRISHNA",
    lastName: "MAZUMDAR",
    address: "1,PARK TERRACE*SANTOSHPUR*CALCUTTA-700032",
    fullName: "AMAL KRISHNA MAZUMDAR",
    saID: "SPR1797",
  },
  {
    firstName: "LATE BIJOY KRISHNA",
    lastName: "MAZUMDAR",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE BIJOY KRISHNA MAZUMDAR",
    saID: "SPR1798",
  },
  {
    firstName: "LATE DIPAK KUMAR",
    lastName: "GUHAMAZUMDER",
    address: "SATSANG VIHAR DURGAPUR*NABA PALLY*DURGAPUR-713213*BURDWAN",
    fullName: "LATE DIPAK KUMAR GUHAMAZUMDER",
    saID: "SPR1799",
  },
  {
    firstName: "LATE PRAFULLA CHANDRA",
    lastName: "MAZUMDAR",
    address: "1,KALI CHARAN GHOSH ROAD*CALCUTTA 700050",
    fullName: "LATE PRAFULLA CHANDRA MAZUMDAR",
    saID: "SPR1800",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "MAZUMDAR",
    address: "14/1 BEHALA PARA LANE*PO-ALAMBAZAR*KOLKATA-700 035",
    fullName: "PRASANTA KUMAR MAZUMDAR",
    saID: "SPR1801",
  },
  {
    firstName: "RASAMOY",
    lastName: "MAZUMDAR",
    address: "SUR BHAVAN*PO-PRADHAN NAGAR*DARJEELING-734003",
    fullName: "RASAMOY MAZUMDAR",
    saID: "SPR1802",
  },
  {
    firstName: "SUBODH CHANDRA",
    lastName: "MAZUMDAR",
    address:
      "SHYAM SUNDAR CHAK GOVT.COLONY*PO-SANTOSHPUR*HOWRAH 711310 PAN NO.ADQPM3326E",
    fullName: "SUBODH CHANDRA MAZUMDAR",
    saID: "SPR1803",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "MAZUMDER",
    address: "VILL-DANGAPARA L.P.COLONY*PO-RAHARA*DIST-24PARGANAS.",
    fullName: "LATE BIMAL CHANDRA MAZUMDER",
    saID: "SPR1804",
  },
  {
    firstName: "LATE BANGSHIDHAR",
    lastName: "MEDAK",
    address: "CHUMNI MEDAKGAON*PO-BILMUKH*DT-LAKHIMPUR.",
    fullName: "LATE BANGSHIDHAR MEDAK",
    saID: "SPR1805",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "MEDAK",
    address: "DHEMAJI COLLEGE*PO/DIST-DHEMAJI-787057*ASSAM",
    fullName: "GOBINDA CHANDRA MEDAK",
    saID: "SPR1806",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "MEDHI",
    address: "C/O SATSANG VIHAR NALIAPOOL*PO&DIST-DIBRUGARH-786001*ASSAM",
    fullName: "RAMESH CHANDRA MEDHI",
    saID: "SPR1807",
  },
  {
    firstName: "LATE SATINDRA NATH",
    lastName: "MEDHI",
    address: "CHANDRAPUR A.I.A.N.T.C.*PO CHANDRAPUR*DIST KAMRUP.",
    fullName: "LATE SATINDRA NATH MEDHI",
    saID: "SPR1808",
  },
  {
    firstName: "UDAY SHANKAR",
    lastName: "MIDDER",
    address: "VILL.KAMALNAGAR*PO.GUSHKARA*BARDDHAMAN-713128",
    fullName: "UDAY SHANKAR MIDDER",
    saID: "SPR1809",
  },
  {
    firstName: "AKSHAY KUMAR",
    lastName: "MISHRA",
    address: "SATSANG-814116*DEOGHAR",
    fullName: "AKSHAY KUMAR MISHRA",
    saID: "SPR1810",
  },
  {
    firstName: "LATE ALOKESH",
    lastName: "MISHRA",
    address: "SATSANG BIHAR KAKDWIP*PO-KAKDWIP*DIST-24 PGS (SOUTH)-743347",
    fullName: "LATE ALOKESH MISHRA",
    saID: "SPR1811",
  },
  {
    firstName: "BABAJI",
    lastName: "MISHRA",
    address: "AT-DEULABANDHA,MUDIPARA*PO-MUDIPARA*SAMBALPUR-768002*ORISSA",
    fullName: "BABAJI MISHRA",
    saID: "SPR1812",
  },
  {
    firstName: "BAIKUNTHA NATH",
    lastName: "MISHRA",
    address:
      '"BAIKUNTHA-BITAN" *MADHUSUDAN NAGAR*CUTTACK 753008*PAN-BJOPM1199Q',
    fullName: "BAIKUNTHA NATH MISHRA",
    saID: "SPR1813",
  },
  {
    firstName: "LATE BALDEV",
    lastName: "MISHRA",
    address: "67,JATINDRA MOHAN AVENUE*CALCUTTA-700005",
    fullName: "LATE BALDEV MISHRA",
    saID: "SPR1814",
  },
  {
    firstName: "LATE BANCHIT KUMAR",
    lastName: "MISHRA",
    address: "AT/PO-GOBERODAL*NALBARI*ASSAM",
    fullName: "LATE BANCHIT KUMAR MISHRA",
    saID: "SPR1815",
  },
  {
    firstName: "LATE BARENDRA NATH",
    lastName: "MISHRA",
    address: "BENTKAR HIGH SCHOOL*AT/PO-BENTKAR*CUTTACK.",
    fullName: "LATE BARENDRA NATH MISHRA",
    saID: "SPR1816",
  },
  {
    firstName: "BIJOY BHUSAN",
    lastName: "MISHRA",
    address:
      '"BIJAY BITAN"*HOUSE NO-29,RATNAKAR BAG(I)*TANKAPANI ROAD*BHUBANESWAR-751018*KHURDA',
    fullName: "BIJOY BHUSAN MISHRA",
    saID: "SPR1817",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "MISHRA",
    address: "AT-MALLIKADEIPUR*PO-NAIKANIDIHI*DIST-BHADRAK 756164",
    fullName: "BIJOY KUMAR MISHRA",
    saID: "SPR1818",
  },
  {
    firstName: "BIPIN BIHARI",
    lastName: "MISHRA",
    address: "AT-MADHUBAN*PO-NAYAHAT*PURI-752107",
    fullName: "BIPIN BIHARI MISHRA",
    saID: "SPR1819",
  },
  {
    firstName: "DILIP CHANDRA",
    lastName: "MISHRA",
    address:
      '"DWITINIRJHAR"*E 1.2.3.4,BRIT COLONY*LAXMISAGAR*BHUBANESWAR-751006*KHURDA',
    fullName: "DILIP CHANDRA MISHRA",
    saID: "SPR1820",
  },
  {
    firstName: "DURGAPADA",
    lastName: "MISHRA",
    address: "AT/PO-BAMANKHALI*DIST-24 PARGANAS (SOUTH)-743373",
    fullName: "DURGAPADA MISHRA",
    saID: "SPR1821",
  },
  {
    firstName: "GANESH PRASAD",
    lastName: "MISHRA",
    address: "VILL-DAULATPUR*PO-PIRHATBAZAR*BALASORE 756131.",
    fullName: "GANESH PRASAD MISHRA",
    saID: "SPR1822",
  },
  {
    firstName: "GAYADHAR",
    lastName: "MISHRA",
    address: "V-BHABANIPUR*PO.RAMCHANDRAPUR*CUTTACK.",
    fullName: "GAYADHAR MISHRA",
    saID: "SPR1823",
  },
  {
    firstName: "GYANENDRA NATH",
    lastName: "MISHRA",
    address:
      '"SUBHAM"*PLOT NO.610/2331*DIBYA VIHAR,ROAD-3,SAMANTARAPUR*BBSR-2*PAN-CIAPM9099M.',
    fullName: "GYANENDRA NATH MISHRA",
    saID: "SPR1824",
  },
  {
    firstName: "LATE HARI KANTA",
    lastName: "MISHRA",
    address:
      "CHINAKURI SATSANG ADHIBESAN KENDRA*PO-RADHANAGAR RLY COLONY*BURDWAN",
    fullName: "LATE HARI KANTA MISHRA",
    saID: "SPR1825",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "MISHRA",
    address:
      "AT&PO-SATSANG VIHAR  LIPLOY*VIA-RAJGANGPUR-770017*DIST-SUNDARGARH*PAN-AVBPM6991J",
    fullName: "HEMANTA KUMAR MISHRA",
    saID: "SPR1826",
  },
  {
    firstName: "KALIPADA",
    lastName: "MISHRA",
    address:
      "V-SIMULIA*PO-BHIMESWARI BAZAR*VIA-PALPARA*DIST-PURBA MEDINIPUR-721458",
    fullName: "KALIPADA MISHRA",
    saID: "SPR1827",
  },
  {
    firstName: "LATE KEDAR",
    lastName: "MISHRA",
    address: "SHANTI NIKETAN SCHOOL*AT&PO-HATIA*RANCHI 834 003.",
    fullName: "LATE KEDAR MISHRA",
    saID: "SPR1828",
  },
  {
    firstName: "KULDIP",
    lastName: "MISHRA",
    address: "AT-AKALWANI*PO-LAGMA*GARHWA-822114",
    fullName: "KULDIP MISHRA",
    saID: "SPR1829",
  },
  {
    firstName: "NABAKISHORE",
    lastName: "MISHRA",
    address: "VILL/PO-KHAMAR*ANGUL-759118*PAN-AFNPM7179L",
    fullName: "NABAKISHORE MISHRA",
    saID: "SPR1830",
  },
  {
    firstName: "NANDAN KUNJA",
    lastName: "MISHRA",
    address:
      "SATSANG ASHRAM*KARKULI*PO-CONTAI*DIST-PURBA MEDINIPUR-721401*PAN-BQEPM7361K",
    fullName: "NANDAN KUNJA MISHRA",
    saID: "SPR1831",
  },
  {
    firstName: "LATE NATABAR",
    lastName: "MISHRA",
    address: "AT/PO-KALYANI NAGAR*CUTTACK 13*ORISSA",
    fullName: "LATE NATABAR MISHRA",
    saID: "SPR1832",
  },
  {
    firstName: "LATE NIKUNJA BEHARI",
    lastName: "MISHRA",
    address: "CONTAI SATSANG CENTRE*PO-CONTAI*DIST-MIDNAPUR",
    fullName: "LATE NIKUNJA BEHARI MISHRA",
    saID: "SPR1833",
  },
  {
    firstName: "PITAMBAR",
    lastName: "MISHRA",
    address: "AT-KALIDASPUR*PO-HARIPUR*VIA-MOTIGANJ*BALASORE 756 003",
    fullName: "PITAMBAR MISHRA",
    saID: "SPR1834",
  },
  {
    firstName: "PRASANNA KUMAR",
    lastName: "MISHRA",
    address:
      "A/4 BHUBANESWAR HEIGHTS*ROAD NO-1,JAGANNATH NAGAR*JHARPADA*BHUBANESWAR*DIST-KHURDA",
    fullName: "PRASANNA KUMAR MISHRA",
    saID: "SPR1835",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "MISHRA",
    address:
      "DIST. SATSANG CENTRE*BIBIGONJ*PO-MEDINIPUR*DIST-PASCHIM MEDINIPUR*PAN-BFZPM1284H",
    fullName: "PURNA CHANDRA MISHRA",
    saID: "SPR1836",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "MISHRA",
    address:
      "AT-PATARAJAPUR*PO-BALICHANDRAPUR*DIST-JAJPUR-754 205*PAN-AHLPM7022J",
    fullName: "RABINDRA KUMAR MISHRA",
    saID: "SPR1837",
  },
  {
    firstName: "RADHASHYAM",
    lastName: "MISHRA",
    address:
      "PLOT NO-23,PH-II*G.G.P CANAL ROAD,RASULGARH*BHUBANESWAR-751010*KHURDA",
    fullName: "RADHASHYAM MISHRA",
    saID: "SPR1838",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "MISHRA",
    address: "AT/PO.MARTHA PUR*DHENKANAL-759023*PAN-CLXPM4901Q",
    fullName: "RAMAKANTA MISHRA",
    saID: "SPR1839",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "MISHRA",
    address: "AT.KENDUMUNDI*PO.RENGALI*VIA.SOHELLA*SAMBALPUR-768033",
    fullName: "RAMESH CHANDRA MISHRA",
    saID: "SPR1840",
  },
  {
    firstName: "BODHIBYAS",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "BODHIBYAS BHATTACHARJEE",
    saID: "SPR1841",
  },
  {
    firstName: "SASANKA KUMAR",
    lastName: "MISHRA",
    address:
      "90-M,PHASE-II*KAPIL PRASAD,O S H B*BHUBANESWAR-751002*KHURDA*PAN-ADPPM3212J",
    fullName: "SASANKA KUMAR MISHRA",
    saID: "SPR1842",
  },
  {
    firstName: "LATE SOM DUTTA",
    lastName: "MISHRA",
    address: "C/O DINESH DAS*VILL-JAIPUR*PO-BAGURI KUTHI*NALBARI 781368",
    fullName: "LATE SOM DUTTA MISHRA",
    saID: "SPR1843",
  },
  {
    firstName: "SRIDHAR SUNDAR",
    lastName: "MISHRA",
    address: "SATSANG 814116*DEOGHAR*JHARKHAND*PAN-BVUPM6868A",
    fullName: "SRIDHAR SUNDAR MISHRA",
    saID: "SPR1844",
  },
  {
    firstName: "LATE SUBHRANGSU SEKHAR",
    lastName: "MISHRA",
    address: "AGARTALA*TRIPURA",
    fullName: "LATE SUBHRANGSU SEKHAR MISHRA",
    saID: "SPR1845",
  },
  {
    firstName: "LATE TAROKESH",
    lastName: "MISHRA",
    address: "AMIYASUDHA*VILL/PO-SAGAR FULBARI*SOUTH 24 PARGANAS.",
    fullName: "LATE TAROKESH MISHRA",
    saID: "SPR1846",
  },
  {
    firstName: "LATE TRILOKESH",
    lastName: "MISHRA",
    address: "VILL/PO-KARANJALI*DIST-24 PGS-743348",
    fullName: "LATE TRILOKESH MISHRA",
    saID: "SPR1847",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "MISTRY",
    address: "AT+PO-GANJA*GAIGHATA*DIST-24 PGS (NORTH)-743249",
    fullName: "LATE ANANTA KUMAR MISTRY",
    saID: "SPR1848",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "MISTRI",
    address:
      "AT-CHIMTA SATSANG VIHAR*PO-CHIMTA KACHARI*24PARGANAS NORTH-743456",
    fullName: "ANIL KUMAR MISTRI",
    saID: "SPR1849",
  },
  {
    firstName: "LATE SURENDRA KUMAR",
    lastName: "MISTRY",
    address: "*SATSANG 814 116",
    fullName: "LATE SURENDRA KUMAR MISTRY",
    saID: "SPR1850",
  },
  {
    firstName: "LATE APARNA PADA",
    lastName: "MITRA",
    address: "VILL-RAGHUNATHPUR*PO-MAHAMMADBAZAR*DIST-BIRBHUM.",
    fullName: "LATE APARNA PADA MITRA",
    saID: "SPR1851",
  },
  {
    firstName: "BIMAL BIKASH",
    lastName: "MITRA",
    address: "SATSANG KENDRA,AMARPUR*PO-AMARPUR*TRIPURA.",
    fullName: "BIMAL BIKASH MITRA",
    saID: "SPR1852",
  },
  {
    firstName: "LATE BIRENDRA LAL",
    lastName: "MITRA",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "LATE BIRENDRA LAL MITRA",
    saID: "SPR1853",
  },
  {
    firstName: "LATE DINABANDHU",
    lastName: "MITRA",
    address: "JALUKBARI (WORD NO-1)*PO-TANGLA *DARRANG 784521.",
    fullName: "LATE DINABANDHU MITRA",
    saID: "SPR1854",
  },
  {
    firstName: "LATE GANGA NARAYAN",
    lastName: "MITRA",
    address: "PO-KALNA*DT-BURDWAN.",
    fullName: "LATE GANGA NARAYAN MITRA",
    saID: "SPR1855",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "MITRA",
    address: "SRIPUR ROAD*PO-KONNAGAR*HOOGHLY",
    fullName: "LATE JITENDRA NATH MITRA",
    saID: "SPR1856",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "MITRA",
    address: "KARUNABARI*PO-LALUK*NORTH LAKHIMPUR",
    fullName: "LATE KALIPADA MITRA",
    saID: "SPR1857",
  },
  {
    firstName: "KANCHAN PRASAD",
    lastName: "MITRA",
    address: "SATSANG*DEOGHAR.",
    fullName: "KANCHAN PRASAD MITRA",
    saID: "SPR1858",
  },
  {
    firstName: "LATE NALINI KANTA",
    lastName: "MITRA",
    address: "NABAPALLY*PO-BATANAGAR*DIST-24 PGS.",
    fullName: "LATE NALINI KANTA MITRA",
    saID: "SPR1859",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "MITRA",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE NARENDRA NATH MITRA",
    saID: "SPR1860",
  },
  {
    firstName: "LATE NIROJ KUMAR",
    lastName: "MITRA",
    address: "PLOT-GM-3,V.S.S NAGAR*BHUBANESWAR-751004*KHURDA",
    fullName: "LATE NIROJ KUMAR MITRA",
    saID: "SPR1861",
  },
  {
    firstName: "NITYAGOPAL",
    lastName: "MITRA",
    address: "BOWBAZAR BAXI LANE*PO.KRISNANAGAR*NADIA-741101",
    fullName: "NITYAGOPAL MITRA",
    saID: "SPR1862",
  },
  {
    firstName: "NRIPENDRA CHANDRA",
    lastName: "MITRA",
    address: "C/O-AMARJYOTI CLINIC*PO-HAMILTANGANJ*JALPAIGURI-735214",
    fullName: "NRIPENDRA CHANDRA MITRA",
    saID: "SPR1863",
  },
  {
    firstName: "PARIMAL",
    lastName: "MITRA",
    address: "SATSANG VIHAR,SANTIR BAZAR*PO-SANTIR BAZAR*TRIPURA SOUTH-799144",
    fullName: "PARIMAL MITRA",
    saID: "SPR1864",
  },
  {
    firstName: "LATE PRADYOT KUMAR",
    lastName: "MITRA",
    address: "SRI RAJKAMAL TENJING ROAD*DARJEELING",
    fullName: "LATE PRADYOT KUMAR MITRA",
    saID: "SPR1865",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "MITRA",
    address: "SUKANTA PALLY*PO-BAIRATIGURI*JALPAIGURI-735210.",
    fullName: "PRAVAT KUMAR MITRA",
    saID: "SPR1866",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "MITRA",
    address: "VILL/PO MALA,B.COLONY*DIST PILIBHIT*U.P.",
    fullName: "LATE SATISH CHANDRA MITRA",
    saID: "SPR1867",
  },
  {
    firstName: "LATE SATYENDRA KUMAR",
    lastName: "MITRA",
    address: "49B,SASHI BHUSAN DEY STREET*CALCUTTA 12",
    fullName: "LATE SATYENDRA KUMAR MITRA",
    saID: "SPR1868",
  },
  {
    firstName: "LATE SRIBHUSHAN",
    lastName: "MITRA",
    address: "110/4,SELIMPUR ROAD*CALCUTTA-700031",
    fullName: "LATE SRIBHUSHAN MITRA",
    saID: "SPR1869",
  },
  {
    firstName: "SUNIL BARAN",
    lastName: "MITRA",
    address: "MAJHERHATI NIMTA ASHRAM ROAD*KOLKATA-49*PAN-AEJPM7509P",
    fullName: "SUNIL BARAN MITRA",
    saID: "SPR1870",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "MITRA",
    address: "116,ARABINDA BOSE NAGAR*P.O.SARSUNA*CALCUTTA-61",
    fullName: "SURESH CHANDRA MITRA",
    saID: "SPR1871",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "MITRAMAZUMDAR",
    address: "ST. NO.36,QRS NO. 46/D*PO-CHITTARANJAN*BURDWAN.",
    fullName: "LATE SATYENDRA NATH MITRAMAZUMDAR",
    saID: "SPR1872",
  },
  {
    firstName: "LATE JATRA MOHAN",
    lastName: "MITRA",
    address: "SATSANG VIHAR*PO-BELONIA*DAKSHIN TRIPURA-799155",
    fullName: "LATE JATRA MOHAN MITRA",
    saID: "SPR1873",
  },
  {
    firstName: "LATE SADHAN CHANDRA",
    lastName: "MITRA",
    address: "PARESHNATH DHAM*14, GARIA PLACE NORTH*KOLKATA  700084.",
    fullName: "LATE SADHAN CHANDRA MITRA",
    saID: "SPR1874",
  },
  {
    firstName: "LATE HALESWAR",
    lastName: "MODAK",
    address: "AT/PO-PATIKA*PO-BAKLA*COOCHBEHAR",
    fullName: "LATE HALESWAR MODAK",
    saID: "SPR1875",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "MODAK",
    address: "PO-DHUBULIA*DT-NADIA.",
    fullName: "LATE MANMATHA NATH MODAK",
    saID: "SPR1876",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MODAKBHOWMIK",
    address:
      "THE SUDIPTA BAKERY*DURGA CHOWMOHANI*PO-RAMNAGAR*AGARTALA *TRIPURA(W)-799002",
    fullName: "RANJIT KUMAR MODAKBHOWMIK",
    saID: "SPR1877",
  },
  {
    firstName: "LATE DURGANATH",
    lastName: "MOHANTA",
    address: "JAIGURU KUTIR*MILANPUR*BAMUNI MAIDAN*GUWAHATI-21.",
    fullName: "LATE DURGANATH MOHANTA",
    saID: "SPR1878",
  },
  {
    firstName: "LATE JAHARLAL",
    lastName: "MOHANTA",
    address:
      "VILL-BAIDAPOSI*PO-MAHULDIHA*RAIRANGPUR N.A.C.*MAYURBHANJ PIN-757 043",
    fullName: "LATE JAHARLAL MOHANTA",
    saID: "SPR1879",
  },
  {
    firstName: "LATE SATCHIDANANDA",
    lastName: "MOHANTA",
    address: "23 EAST KAMALAPUR*DUMDUM *CALCUTTA-28",
    fullName: "LATE SATCHIDANANDA MOHANTA",
    saID: "SPR1880",
  },
  {
    firstName: "ANANTA CHARAN",
    lastName: "MOHANTY",
    address: "AT/PO.BANCHO*VIA.FAKIRPUR-758022*KEONJHAR.",
    fullName: "ANANTA CHARAN MOHANTY",
    saID: "SPR1881",
  },
  {
    firstName: "BIJOY GOBINDA",
    lastName: "MOHANTY",
    address: "SATSANG VIHAR*PO-SUNDARGARH-770001*SUNDARGARH.",
    fullName: "BIJOY GOBINDA MOHANTY",
    saID: "SPR1882",
  },
  {
    firstName: "JANMEJOY",
    lastName: "MOHANTY",
    address: "AT-KAPALESWAR NUASAHI*PO-KAPALESWAR*CHOUDWAR*CUTTACK-754025",
    fullName: "JANMEJOY MOHANTY",
    saID: "SPR1883",
  },
  {
    firstName: "JOGESWAR",
    lastName: "MOHANTY",
    address:
      "ADVOCATE*PALACE LINE,BOLANGIR*ADJACENT TO SATSANG VIHAR*PO/DT-BOLANGIR  767 001.",
    fullName: "JOGESWAR MOHANTY",
    saID: "SPR1884",
  },
  {
    firstName: "KABI CHANDRA",
    lastName: "MOHANTY",
    address:
      "MIG-1/226,LANE-21*SRISATYASAI ENCLAVE*PO-KHANDAGIRI*BHUBANESWAR-751030*(ADYPM9888K)",
    fullName: "KABI CHANDRA MOHANTY",
    saID: "SPR1885",
  },
  {
    firstName: "LATE LAXMIDHAR",
    lastName: "MOHANTY",
    address: "V-DASABATIA*PO-BARAMUNDALI*JAGATSINGHPUR-754119*ODISHA",
    fullName: "LATE LAXMIDHAR MOHANTY",
    saID: "SPR1886",
  },
  {
    firstName: "LOKANATH",
    lastName: "MOHANTY",
    address:
      "PLOT NO-2273/2987*(FIREWOOD DEPOT)*CUTTACK ROAD*BHUBANESWAR-751006*PAN-AVBPM5855D",
    fullName: "LOKANATH MOHANTY",
    saID: "SPR1887",
  },
  {
    firstName: "MANAMOHAN",
    lastName: "MOHANTY",
    address:
      "AT-SILPASRI RAVI TALKIES ROAD*PO-BHUBANESWAR*DIST-KHURDA-751 002.",
    fullName: "MANAMOHAN MOHANTY",
    saID: "SPR1888",
  },
  {
    firstName: "LATE MONORANJAN",
    lastName: "MOHANTY",
    address: "CHATANIPARA  NADIHA*PURULIA 723102.",
    fullName: "LATE MONORANJAN MOHANTY",
    saID: "SPR1889",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MOHANTY",
    address: "AT-SATSANGVIHAR JAJPUR*PO.DEVIDWAR*JAJPUR  755007",
    fullName: "NARAYAN CHANDRA MOHANTY",
    saID: "SPR1890",
  },
  {
    firstName: "NITYANANDA",
    lastName: "MOHANTY",
    address: "AT-DARABACHHA*PO-MARSHAGHAI*DIST-KENDRAPARA-754213*ODISHA",
    fullName: "NITYANANDA MOHANTY",
    saID: "SPR1891",
  },
  {
    firstName: "PADMANAVA",
    lastName: "MOHANTY",
    address: "AT-MATIAPADA*PO-MANIABANDHA*DIST-CUTTACK-754035*PAN-AGFPM5637C",
    fullName: "PADMANAVA MOHANTY",
    saID: "SPR1892",
  },
  {
    firstName: "PATITAPABAN",
    lastName: "MOHANTY",
    address: "PLOT NO-562,SAHIDNAGAR*BHUBANESWAR 751007*KHURDA",
    fullName: "PATITAPABAN MOHANTY",
    saID: "SPR1893",
  },
  {
    firstName: "LATE REBATI KANTA",
    lastName: "MOHANTY",
    address: "AT/PO-SUNDARGAON-754002*CUTTACK.",
    fullName: "LATE REBATI KANTA MOHANTY",
    saID: "SPR1894",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "MOHANTY",
    address: "37, NAYAN VIHAR*PO-PANDIRI*KENDRAPARA 754211*PAN-ABFPM4468A",
    fullName: "SAROJ KUMAR MOHANTY",
    saID: "SPR1895",
  },
  {
    firstName: "SITAKANTA",
    lastName: "MOHANTY",
    address:
      "216-B/6,SECTOR-A*MANCHESWAR INDUSTRIAL ESTATE*BHUBANESWAR-751010*KHURDA",
    fullName: "SITAKANTA MOHANTY",
    saID: "SPR1896",
  },
  {
    firstName: "SUBRATA KUMAR",
    lastName: "MOHANTY",
    address:
      "C/O BIPIN BIHARI MOHANTY*PLOT NO-633,SAHIDNAGAR*BHUBANESWAR-751007*PAN-AASPN9936H",
    fullName: "SUBRATA KUMAR MOHANTY",
    saID: "SPR1897",
  },
  {
    firstName: "SURANJAN",
    lastName: "MOHANTY",
    address: "INDRADYUMNA ROAD*MATIAPARA WAY TO TIKARPARA*PURI-752002.",
    fullName: "SURANJAN MOHANTY",
    saID: "SPR1898",
  },
  {
    firstName: "BIJOY KETAN",
    lastName: "MOHAPATRA",
    address: "VILL-SAHASPUR*PO-KANSAR*KENDRAPARA-754 224*PAN-AGGPM6397J",
    fullName: "BIJOY KETAN MOHAPATRA",
    saID: "SPR1899",
  },
  {
    firstName: "BIRA KISHORE",
    lastName: "MAHAPATRA",
    address: "AT/PO.BAMRA*SAMBALPUR-768221*ODISHA*PAN-BILPM7969Q",
    fullName: "BIRA KISHORE MAHAPATRA",
    saID: "SPR1900",
  },
  {
    firstName: "LATE CHANDRA SEKHAR",
    lastName: "MOHAPATRA",
    address: "AT/PO.BIRRAMCHANDRAPUR*VIA.SAKSHIGOPAL*PURI.",
    fullName: "LATE CHANDRA SEKHAR MOHAPATRA",
    saID: "SPR1901",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "MOHAPATRA",
    address: "SATSANG COLONY*ANGARGADIA*PO/DIST-BALASORE.",
    fullName: "LATE HARIPADA MOHAPATRA",
    saID: "SPR1902",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "MOHAPATRA",
    address: "VILL/PO-NUASASAN(UTTARASAHI)*VIA-PIPILI*PURI-752104",
    fullName: "KRUSHNA CHANDRA MOHAPATRA",
    saID: "SPR1903",
  },
  {
    firstName: "LATE LOKANATH",
    lastName: "MOHAPATRA",
    address:
      "S.E.(RETD),PLOT NO-551/2*PUNAMA GATE AREA*OLD TOWN*BHUBANESWAR 751002",
    fullName: "LATE LOKANATH MOHAPATRA",
    saID: "SPR1904",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MAHAPATRA",
    address: "LIFT IRRIGATION OFFICE,SORO*PO-SORO*DIST-BALASORE-756045",
    fullName: "NIRANJAN MAHAPATRA",
    saID: "SPR1905",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MAHAPATRA",
    address: "AT-BABUSAHI WARD NO-1*PO-BARIPADA*MAYURBHANJ-757001",
    fullName: "NIRANJAN MAHAPATRA",
    saID: "SPR1906",
  },
  {
    firstName: "PRAFULLA KR BIDHAR",
    lastName: "MOHAPATRA",
    address: "VILL-GRAMESWAR*PO-BOLGARH*DIST-KHURDA-752066",
    fullName: "PRAFULLA KR BIDHAR MOHAPATRA",
    saID: "SPR1907",
  },
  {
    firstName: "PRAMOD  KUMAR",
    lastName: "MOHAPATRA",
    address: "AT-DIHA SARICHUAIN*PO-SARICHUAIN*DT-CUTTACK-754112",
    fullName: "PRAMOD  KUMAR MOHAPATRA",
    saID: "SPR1908",
  },
  {
    firstName: "LATE PRAVAKAR",
    lastName: "MOHAPATRA",
    address: "AT/PO-BHATPADA*VIA-TIHIDI*BHADRAK",
    fullName: "LATE PRAVAKAR MOHAPATRA",
    saID: "SPR1909",
  },
  {
    firstName: "RABI NARAYAN",
    lastName: "MOHAPATRA",
    address: "VILL NUASAHI*PO RASULGARH*BHUBANESWAR-751010*KHURDA",
    fullName: "RABI NARAYAN MOHAPATRA",
    saID: "SPR1910",
  },
  {
    firstName: "LATE RABI NARAYAN",
    lastName: "MOHAPATRA",
    address: "SATSANG VIHAR,SUNDARGARH*SUNDARGARH 770001*ODISHA",
    fullName: "LATE RABI NARAYAN MOHAPATRA",
    saID: "SPR1911",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "MOHAPATRA",
    address: "BAIKUNTHA NAGAR THIRD LINE*AT/PO-BERHAMPUR*GANJAM-760001.",
    fullName: "SARAT CHANDRA MOHAPATRA",
    saID: "SPR1912",
  },
  {
    firstName: "SURENDRA",
    lastName: "MOHAPATRA",
    address: "AMALAPARA COLONY*TALCHER P.H.OFFICE*PO.TALCHER*ANGUL--759100",
    fullName: "SURENDRA MOHAPATRA",
    saID: "SPR1913",
  },
  {
    firstName: "ARAKSHIT",
    lastName: "MOHARANA",
    address: "AT&PO.DAULATABAD*CUTTACK-754026",
    fullName: "ARAKSHIT MOHARANA",
    saID: "SPR1914",
  },
  {
    firstName: "BAISHNAB CHARAN",
    lastName: "MOHARANA",
    address: "VILL-JAMUNAPUR*PO-BHAGABANPUR*VIA-LEMALO*CUTTACK-754293",
    fullName: "BAISHNAB CHARAN MOHARANA",
    saID: "SPR1915",
  },
  {
    firstName: "BENUDHAR",
    lastName: "MOHARANA",
    address:
      "AT-BIJOY RAMACHANDRAPUR*(WARD NO.21)*PO-BHANJAPUR*BARIPADA*MAYURBHANJ-757002",
    fullName: "BENUDHAR MOHARANA",
    saID: "SPR1916",
  },
  {
    firstName: "BHAGIRATHI",
    lastName: "MOHARANA",
    address: "AT-BHURUDUBANI*PO-BHIMTALI*VIA-UDALA*DIST-MAYURBHANJ-757041",
    fullName: "BHAGIRATHI MOHARANA",
    saID: "SPR1917",
  },
  {
    firstName: "DHANESWAR",
    lastName: "MOHARANA",
    address:
      "O/O-G.M.TELECOM CIRCLE,OPTCL(TECH WING)*PO-BHOINAGAR*BHUBANESWAR-751022*DIST-KHURDA",
    fullName: "DHANESWAR MOHARANA",
    saID: "SPR1918",
  },
  {
    firstName: "GOPINATH",
    lastName: "MOHARANA",
    address: "VILL/PO-GHASIPURA*KEONJHAR-758015",
    fullName: "GOPINATH MOHARANA",
    saID: "SPR1919",
  },
  {
    firstName: "LATE MANMOHAN",
    lastName: "MOHARANA",
    address: "QR NO MA-29 GGP COLONY*RASULGARH*BHUBANESWAR 751010",
    fullName: "LATE MANMOHAN MOHARANA",
    saID: "SPR1920",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "MAHARANA",
    address: "AT/PO.KHANDAITA*VIA.GOPALPUR*CUTTACK 753011",
    fullName: "PRASANTA KUMAR MAHARANA",
    saID: "SPR1921",
  },
  {
    firstName: "LATE UCHHAB",
    lastName: "MAHARANA",
    address: "AT-HINDOL TOWN*PO.HINDOL*DHENKANAL.",
    fullName: "LATE UCHHAB MAHARANA",
    saID: "SPR1922",
  },
  {
    firstName: "LATE BARADA KANTA",
    lastName: "MONDAL",
    address:
      "AT-NUTANGRAM*PO-EAST VIDYADHARPUR*VIA-SHYAMNAGAR*DIST-24 PARGANAS (NORTH).",
    fullName: "LATE BARADA KANTA MONDAL",
    saID: "SPR1923",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "MONDAL",
    address: "PO+VILL-PUNPUA*VIA-DAKSHIN BARASAT*DIST-24 PGS (SOUTH)-743372",
    fullName: "AJIT KUMAR MONDAL",
    saID: "SPR1924",
  },
  {
    firstName: "AMULLYA KRISHNA",
    lastName: "MONDAL",
    address: "AT-KORAKATI*PO-D KORAKATI*DIST-24 PGS NORTH-743446",
    fullName: "AMULLYA KRISHNA MONDAL",
    saID: "SPR1925",
  },
  {
    firstName: "LATE AMULLYA KUMAR",
    lastName: "MONDAL",
    address: "PANCHANANPUR*PO-DINESHPUR*DIST-NAINITAL*U.P.",
    fullName: "LATE AMULLYA KUMAR MONDAL",
    saID: "SPR1926",
  },
  {
    firstName: "ANIL KRISHNA",
    lastName: "MONDAL",
    address: "AT&PO-KORAKATI*VIA-SANDESHKHALI*DIST-24 PGS (NORTH)-743446",
    fullName: "ANIL KRISHNA MONDAL",
    saID: "SPR1927",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "MONDAL",
    address: "VILL-GUPTIPUR*PO-SIMLAN*DIST-BARDDHAMAN-713425*PAN-CBKPM9269A",
    fullName: "ANIL KUMAR MONDAL",
    saID: "SPR1928",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "MONDAL",
    address: "PO/VILL-PARGHUMTI*VIA-HATGACHA*DIST-24 PGS.",
    fullName: "LATE ANIL KUMAR MONDAL",
    saID: "SPR1929",
  },
  {
    firstName: "LATE ARUN KUMAR",
    lastName: "MONDAL",
    address: "MALANA CAMP P.O.CHARKHERA*DIST-HOSHENGABAD",
    fullName: "LATE ARUN KUMAR MONDAL",
    saID: "SPR1930",
  },
  {
    firstName: "ASIM KUMAR",
    lastName: "MONDAL",
    address: "AT&PO-BANKRA*VIA-HINGALGANJ*24 PARGANAS (NORTH)-743435",
    fullName: "ASIM KUMAR MONDAL",
    saID: "SPR1931",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "MONDAL",
    address: "STATION ROAD*BISHNUPUR*BANKURA-722122*PAN-ADYPM7915P",
    fullName: "ASWINI KUMAR MONDAL",
    saID: "SPR1932",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "MONDAL",
    address: "AT-RAIGRAM*PO-AMRITI*DIST-MALDA",
    fullName: "LATE ASWINI KUMAR MONDAL",
    saID: "SPR1933",
  },
  {
    firstName: "BANAMALI",
    lastName: "MONDAL",
    address:
      "NUTAN PALLY,CENTRAL ROAD*PO-SONARPUR*24 PARGANAS SOUTH-700150*PAN-AEHPM9662F",
    fullName: "BANAMALI MONDAL",
    saID: "SPR1934",
  },
  {
    firstName: "LATE BANKIM CHANDRA",
    lastName: "MONDAL",
    address: "AT/PO METALI*DIST BANKURA.",
    fullName: "LATE BANKIM CHANDRA MONDAL",
    saID: "SPR1935",
  },
  {
    firstName: "BARUN CHANDRA",
    lastName: "MONDAL",
    address: "AT-CHHOTO CHAMAGRAM*PO-CHAMAGRAM*MALDA-732127*PAN-AIYPM1045K",
    fullName: "BARUN CHANDRA MONDAL",
    saID: "SPR1936",
  },
  {
    firstName: "BHAKTI BHUSAN",
    lastName: "MONDAL",
    address: "AT+PO-AMTOLA*DIST-MURSHIDABAD.",
    fullName: "BHAKTI BHUSAN MONDAL",
    saID: "SPR1937",
  },
  {
    firstName: "BHUJANGA BHUSAN",
    lastName: "MONDAL",
    address:
      "PO-AMTALIBAZAR*VIA-CHHOTOMOLLAKHALI*DIST-24PARGANAS (SOUTH)-743378",
    fullName: "BHUJANGA BHUSAN MONDAL",
    saID: "SPR1938",
  },
  {
    firstName: "BHUPATI MOHAN",
    lastName: "MONDAL",
    address: "VILL/PO ARPANGASIA*DIST SATKHIRA*BANGLADESH",
    fullName: "BHUPATI MOHAN MONDAL",
    saID: "SPR1939",
  },
  {
    firstName: "LATE BIDHUBHUSAN",
    lastName: "MONDAL",
    address: "9,KABI SUKANTA ROAD*CALCUTTA-75",
    fullName: "LATE BIDHUBHUSAN MONDAL",
    saID: "SPR1940",
  },
  {
    firstName: "BIJOY CHAND",
    lastName: "MONDAL",
    address: "GUPTIPUR*PO-SIMLON*DIST-BURDWAN-713425",
    fullName: "BIJOY CHAND MONDAL",
    saID: "SPR1941",
  },
  {
    firstName: "LATE BIJOY KRISHNA",
    lastName: "MONDAL",
    address: "VILL/PO-MAITIRHAT*VIA-MAGRAHAT*24 PARGANAS (SOUTH)-743355",
    fullName: "LATE BIJOY KRISHNA MONDAL",
    saID: "SPR1942",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "MONDAL",
    address: "VILL+PO-GHONJA*DIST-24-PGS NORTH-743249",
    fullName: "BIJOY KUMAR MONDAL",
    saID: "SPR1943",
  },
  {
    firstName: "BIMAL KRISHNA",
    lastName: "MONDAL",
    address:
      "18/23,KAZIPARA MAIN ROAD*PO-KAZI PARA*BARASAT *24 PARGANAS NORTH-743201.",
    fullName: "BIMAL KRISHNA MONDAL",
    saID: "SPR1944",
  },
  {
    firstName: "LATE BINOD CHANDRA",
    lastName: "MONDAL",
    address: "AT&PO-KORAKATI*DIST-24 PGS.",
    fullName: "LATE BINOD CHANDRA MONDAL",
    saID: "SPR1945",
  },
  {
    firstName: "LATE CHANDMOHAN",
    lastName: "MONDAL",
    address: "VILL/PO-MIRGI*NADIA",
    fullName: "LATE CHANDMOHAN MONDAL",
    saID: "SPR1946",
  },
  {
    firstName: "LATE CHANDRA SEKHAR",
    lastName: "MONDAL",
    address: "AT+PO-JHARKHALI BAZAR*DIST-24 PGS.",
    fullName: "LATE CHANDRA SEKHAR MONDAL",
    saID: "SPR1947",
  },
  {
    firstName: "CHAPAL KANTI",
    lastName: "MONDAL",
    address: "BHAWANIPUR*PO-BASIRHAT COLLEGE*24PARGANAS NORTH-743412",
    fullName: "CHAPAL KANTI MONDAL",
    saID: "SPR1948",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "MONDAL",
    address:
      "NIVEDITA PLACE*BLOCK-C;TAKI ROAD*PO-BARASAT*NORTH 24PARGANAS-700124",
    fullName: "CHITTARANJAN MONDAL",
    saID: "SPR1949",
  },
  {
    firstName: "DAYALHARI",
    lastName: "MONDAL",
    address: "MAGRA*PO-RUKANPUR*DIST-MURSHIDABAD",
    fullName: "DAYALHARI MONDAL",
    saID: "SPR1950",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "MONDAL",
    address: "AT-CHARPANPARA*PO-HARIPUR*NADIA",
    fullName: "LATE DEBENDRA NATH MONDAL",
    saID: "SPR1951",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "MONDAL",
    address:
      "SATSANG SWASTI SIBIR*SEKENDARPUR*PO-AMRITI*DIST-MALDA-732208*PAN BIOPM8945B",
    fullName: "DHIRENDRA NATH MONDAL",
    saID: "SPR1952",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "MONDAL",
    address: '"SABITRI VILLA"*PURANDAH*DEOGHAR*SATSANG',
    fullName: "LATE DHIRENDRA NATH MONDAL",
    saID: "SPR1953",
  },
  {
    firstName: "LATE DIGAMBAR",
    lastName: "MONDAL",
    address: "VILL/PO-BARSUL*MILL GATE BAZAR*BURDWAN",
    fullName: "LATE DIGAMBAR MONDAL",
    saID: "SPR1954",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "MONDAL",
    address: "AMIRPUR*PO-BAHINBARIA*KHULNA*BANGLADESH",
    fullName: "DULAL CHANDRA MONDAL",
    saID: "SPR1955",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "MONDAL",
    address: "VILL-PRATAPPUR*PO-RAGHUNATHGANJ*MURSHIDABAD*PAN-AHBPM4059K",
    fullName: "GANESH CHANDRA MONDAL",
    saID: "SPR1956",
  },
  {
    firstName: "GIRINDRA NATH",
    lastName: "MONDAL",
    address: "VILL-SAINPALA*PO-BASIRHHAT*24PARGANAS NORTH-743411.",
    fullName: "GIRINDRA NATH MONDAL",
    saID: "SPR1957",
  },
  {
    firstName: "GOPESHWAR",
    lastName: "MONDAL",
    address: "1 NO.CHIKAJHORA*PO-BHOTAGAON*GOALPARA",
    fullName: "GOPESHWAR MONDAL",
    saID: "SPR1958",
  },
  {
    firstName: "LATE LT.GOUR CHANDRA",
    lastName: "MONDAL",
    address: "*SATSANG*DEOGHAR",
    fullName: "LATE LT.GOUR CHANDRA MONDAL",
    saID: "SPR1959",
  },
  {
    firstName: "LATE GOUR CHANDRA",
    lastName: "MONDAL",
    address: "C/O BISHNUDEO SIKDAR*VILL-GEMAN COLONY*PO-DURGAPUR-3*BURDWAN",
    fullName: "LATE GOUR CHANDRA MONDAL",
    saID: "SPR1960",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "MONDAL",
    address:
      "AT-CHIMTA SATSANG VIHAR*PO-CHIMTA KACHARI*24PARGANAS (NORTH)-743456",
    fullName: "LATE HARENDRA NATH MONDAL",
    saID: "SPR1961",
  },
  {
    firstName: "LATE HARISH CHANDRA",
    lastName: "MONDAL",
    address: "VILL-KANKRASOLE*PO-SHIROMANIPUR*BANKURA",
    fullName: "LATE HARISH CHANDRA MONDAL",
    saID: "SPR1962",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "MANDAL",
    address: "HAPANIA DIGHIRPARA*PO-NAGARUKHRA*NADIA-741257",
    fullName: "LATE JATINDRA NATH MANDAL",
    saID: "SPR1963",
  },
  {
    firstName: "LATE JUDHISTHIR",
    lastName: "MONDAL",
    address: "PO+AT-MAMATHANAGAR*VIA-GOSABA*DIST-24-PGS.",
    fullName: "LATE JUDHISTHIR MONDAL",
    saID: "SPR1964",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "MONDAL",
    address: "VILL-DURGAPUR-NO.2*P.O.DINESHPUR*DIST-NAINITAL-263001",
    fullName: "LATE KALIPADA MONDAL",
    saID: "SPR1965",
  },
  {
    firstName: "KALIPADA",
    lastName: "MONDAL",
    address: "LALKOTHI;HALISAHAR*PO-HAJIRNAGAR*24PARGANAS NORTH",
    fullName: "KALIPADA MONDAL",
    saID: "SPR1966",
  },
  {
    firstName: "KAMAKSHYA CHARAN",
    lastName: "MONDAL",
    address: "PATEL NAGAR*PO-M D BAZAR T-S*BIRBHUM-731132.*PAN-CAMPM7341E.",
    fullName: "KAMAKSHYA CHARAN MONDAL",
    saID: "SPR1967",
  },
  {
    firstName: "LATE KAMAL KRISHNA",
    lastName: "MONDAL",
    address: "P/123,NEW C.I.T. ROAD*PO-ENTALLY*CALCUTTA 700 014",
    fullName: "LATE KAMAL KRISHNA MONDAL",
    saID: "SPR1968",
  },
  {
    firstName: "LATE KENARAM",
    lastName: "MONDAL",
    address: "AT-DHOPAGACHHI*PO-KUNDARALI*DIST-24-PGS.",
    fullName: "LATE KENARAM MONDAL",
    saID: "SPR1969",
  },
  {
    firstName: "KESHAB LAL",
    lastName: "MONDAL",
    address:
      "F-2 Ist FLOOR SREE ENCLAVE*SWARUP NAGAR;LALACHERU*RAJAMUNDRI*EAST GODAVARI(A.P.)",
    fullName: "KESHAB LAL MONDAL",
    saID: "SPR1970",
  },
  {
    firstName: "MANTU LAL",
    lastName: "MONDAL",
    address:
      "HOUSE NO 10/1852,BLOCK-30*SHIVANANDA NAGAR*SRINAGAR*PO-WRS COLONY-492008*RAIPUR*C.G",
    fullName: "MANTU LAL MONDAL",
    saID: "SPR1971",
  },
  {
    firstName: "LATE MRITYUNJOY",
    lastName: "MONDAL",
    address: "OFFICE OF THE HORTICULTURE*KEONJHAR",
    fullName: "LATE MRITYUNJOY MONDAL",
    saID: "SPR1972",
  },
  {
    firstName: "MUKUNDA BEHARI",
    lastName: "MONDAL",
    address:
      "VILL-RAJBARI*PO-AGARHATI*VIA-CANNING TOWN*DIST-24 PARGANAS (NORTH)-743329.",
    fullName: "MUKUNDA BEHARI MONDAL",
    saID: "SPR1973",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "MONDAL",
    address:
      "26,DHANWANTARI ROAD*AT/PO-JOYNAGAR MAJILPUR*VIA-BARUIPUR*24PARGANAS (SOUTH)-743337",
    fullName: "NANIGOPAL MONDAL",
    saID: "SPR1974",
  },
  {
    firstName: "LATE NETAI CHANDRA",
    lastName: "MONDAL",
    address: "AT+PO-SULKUNI*DIST-24 PGS.",
    fullName: "LATE NETAI CHANDRA MONDAL",
    saID: "SPR1975",
  },
  {
    firstName: "NIRODE BARAN",
    lastName: "MONDAL",
    address: "VILL-KARIDHYA KANAIPUR*PO-KARIDHYA*BIRBHUM-731126",
    fullName: "NIRODE BARAN MONDAL",
    saID: "SPR1976",
  },
  {
    firstName: "LATE NITYAGOPAL",
    lastName: "MONDAL",
    address: "AT-SANDIPUR*PO-CHOUHATTA*BIRBHUM",
    fullName: "LATE NITYAGOPAL MONDAL",
    saID: "SPR1977",
  },
  {
    firstName: "NRIPENDRA NATH",
    lastName: "MONDAL",
    address: "HAPANIA DIGHIRPARA*PO.NAGARUKHRA*NADIA-741257*PAN-AEIPM5156R",
    fullName: "NRIPENDRA NATH MONDAL",
    saID: "SPR1978",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MONDAL",
    address: "AT-SWARUPKATI*PO-AMBERIA*DIST-24 PARGANAS NORTH-743435",
    fullName: "PANCHANAN MONDAL",
    saID: "SPR1979",
  },
  {
    firstName: "PASHUPATI",
    lastName: "MONDAL",
    address:
      "VILL-MAHESHPUR (BISWANATH MORE)*PO-MAKDUMPUR*DIST-MALDA-732103*PAN-AEMPM1173Q",
    fullName: "PASHUPATI MONDAL",
    saID: "SPR1980",
  },
  {
    firstName: "PRABIR KUMAR",
    lastName: "MANDAL",
    address:
      "DANGAL PARA (NEAR LOKNATH MANDIR)*PO-SURI*BIRBHUM-731101*PAN-AELPM9971M",
    fullName: "PRABIR KUMAR MANDAL",
    saID: "SPR1981",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "MONDAL",
    address: "AT-JAGADANANDAPUR*PO-BETHUADAHARI*NADIA-741126",
    fullName: "LATE PRAFULLA KUMAR MONDAL",
    saID: "SPR1982",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MONDAL",
    address: "6/2 ANANDAPURI HOUSING SOCIETY*DURGAPUR-713213*BURDWAN",
    fullName: "PRAFULLA KUMAR MONDAL",
    saID: "SPR1983",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MONDAL",
    address: "PO&VILL-CHANDANPUR*DIST-24 PGS (N)-743439",
    fullName: "RABINDRA NATH MONDAL",
    saID: "SPR1984",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MONDAL",
    address:
      "PO+VILL-GOBARDANGA*PURBASHA PARK(PURBACHAL)*24 PARGANAS(NORTH)-743252",
    fullName: "RABINDRA NATH MONDAL",
    saID: "SPR1985",
  },
  {
    firstName: "LATE RADHESHYAM",
    lastName: "MONDAL",
    address: "PAWGRAM BAKSHIBAD*PO NRISINGPUR*VIA BHADIA*DIST BURDWAN",
    fullName: "LATE RADHESHYAM MONDAL",
    saID: "SPR1986",
  },
  {
    firstName: "LATE RAJKRISHNA",
    lastName: "MONDAL",
    address: "ADVOCATE*NEW MARKET*PO-BASIRHAT*DIST-24 PGS.",
    fullName: "LATE RAJKRISHNA MONDAL",
    saID: "SPR1987",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "MONDAL",
    address: "UTTAR PATASPUR*PO-HODALNARAYANPUR*DIST-BANKURA-722112",
    fullName: "RAMESH CHANDRA MONDAL",
    saID: "SPR1988",
  },
  {
    firstName: "RAMPADA",
    lastName: "MONDAL",
    address: "VIL&PO-MURADI*PURULIA-723156",
    fullName: "RAMPADA MONDAL",
    saID: "SPR1989",
  },
  {
    firstName: "LATE RANGALAL",
    lastName: "MONDAL",
    address: "AT&PO.FATEPUR*VIA SUBARNAPUR*NADIA",
    fullName: "LATE RANGALAL MONDAL",
    saID: "SPR1990",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MONDAL",
    address: "SATSANG COLONY*SATSANG*DEOGHAR 814112",
    fullName: "RANJIT KUMAR MONDAL",
    saID: "SPR1991",
  },
  {
    firstName: "RASH BEHARI",
    lastName: "MANDAL",
    address: "SATSANG VIHAR*PO-BAHARAGARA*SINGBHUM 832101*PAN-BYWPM2266B",
    fullName: "RASH BEHARI MANDAL",
    saID: "SPR1992",
  },
  {
    firstName: "LATE RATIKANTA",
    lastName: "MONDAL",
    address: "SATSANG COLONY*PO KARIDHYA*DIST BIRBHUM",
    fullName: "LATE RATIKANTA MONDAL",
    saID: "SPR1993",
  },
  {
    firstName: "RITLAL",
    lastName: "MONDAL",
    address: "C/O BINDESWARI SAH*STATION ROAD*PO-BEHARIGANJ*MADHEPURA-852101",
    fullName: "RITLAL MONDAL",
    saID: "SPR1994",
  },
  {
    firstName: "SANATAN",
    lastName: "MONDAL",
    address: "V-MAHESHNARAINGANJ*PO-BHAGABANGOLA*MURSHIDABAD 742135",
    fullName: "SANATAN MONDAL",
    saID: "SPR1995",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "MANDAL",
    address: "C/O-SRI SRI BARDA*SATSANG*DEOGHAR-814116.*PAN-AHCPM7700Q",
    fullName: "SANJIB KUMAR MANDAL",
    saID: "SPR1996",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "MONDAL",
    address: "AT+PO-JAHANABAD*DIST-MIDNAPORE",
    fullName: "LATE SARAT CHANDRA MONDAL",
    saID: "SPR1997",
  },
  {
    firstName: "LATE SHYAMLAL",
    lastName: "MONDAL",
    address: "G.T.ROAD.EAST END*PO-SRIPOLLI*BURDWAN",
    fullName: "LATE SHYAMLAL MONDAL",
    saID: "SPR1998",
  },
  {
    firstName: "SRIDAM",
    lastName: "MONDAL",
    address:
      "ASST.H.M.(RETD)  *BISHNUPUR NIRMALA PROVA HIGH SCHOOL*PO-BISHNUPUR*24PGS (N)-743273",
    fullName: "SRIDAM MONDAL",
    saID: "SPR1999",
  },
  {
    firstName: "LATE SUBHASH CHANDRA",
    lastName: "MONDAL",
    address: "G.T.ROAD.EAST END*PO&DIST-BURDWAN.",
    fullName: "LATE SUBHASH CHANDRA MONDAL",
    saID: "SPR2000",
  },
  {
    firstName: "SUBODH CHANDRA",
    lastName: "MONDAL",
    address: "GOSABA SATSANG VIHAR*PO-GOSABA*DIST-24 PGS (SOUTH)-743370",
    fullName: "SUBODH CHANDRA MONDAL",
    saID: "SPR2001",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "MONDAL",
    address: "BANGALI CAMP NO-2*PO-BACHELI*BASTAR*M.P.494553",
    fullName: "LATE SUBODH CHANDRA MONDAL",
    saID: "SPR2002",
  },
  {
    firstName: "SUDHANGSHU SEKHAR",
    lastName: "MONDAL",
    address: "VILL-JANGALIA*PO-PUNPUA*DIST-24 PGS (SOUTH)743372*PAN-AENPM6067N",
    fullName: "SUDHANGSHU SEKHAR MONDAL",
    saID: "SPR2003",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "MONDAL",
    address:
      "SALAR S.S.SHIBIR*PO+V-SALAR*DIST-MURSHIDABAD-742401*PAN CXKPM3342Q",
    fullName: "SUDHIR KUMAR MONDAL",
    saID: "SPR2004",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "MONDAL",
    address: "AT-KAZIALPARA*PO-RAZARHAT*DIST-24 PARGANAS",
    fullName: "LATE SUDHIR KUMAR MONDAL",
    saID: "SPR2005",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "MONDAL",
    address: "P.V.NO-23*PO.MIXED FARM*VIA PAKHANJORE*DIST-BASTER 494776 (M.P.)",
    fullName: "LATE SUDHIR KUMAR MONDAL",
    saID: "SPR2006",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "MANDAL",
    address: "GOLSI BAGAN G.T.ROAD*PO-GOLSI*DIST-BURDWAN-713406",
    fullName: "SUDHIR KUMAR MANDAL",
    saID: "SPR2007",
  },
  {
    firstName: "SUKUMAR",
    lastName: "MONDAL",
    address: "VILL/PO.BALICHATURI-711315*HOWRAH",
    fullName: "SUKUMAR MONDAL",
    saID: "SPR2008",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "MANDAL",
    address: "AT-TIURA*PO-KOCHDIHI*BANKURA",
    fullName: "LATE SUKUMAR MANDAL",
    saID: "SPR2009",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "MONDAL",
    address: "VILL-SULTANPUR*PO-JAGACHA*HOWRAH-711111",
    fullName: "SUNIL KUMAR MONDAL",
    saID: "SPR2010",
  },
  {
    firstName: "SURATH CHANDRA",
    lastName: "MANDAL",
    address: "AT+PO-SULKUNI*DIST-24 PGS NORTH-743426*PAN-BHZPM0740G.",
    fullName: "SURATH CHANDRA MANDAL",
    saID: "SPR2011",
  },
  {
    firstName: "LATE SURENDRA NATH",
    lastName: "MONDAL",
    address: "AT-GOPAL PRASAD*PO-MADHUGHAT*MALDA-732206.*PAN-BAJPM4086N",
    fullName: "LATE SURENDRA NATH MONDAL",
    saID: "SPR2012",
  },
  {
    firstName: "LATE SWADHIN KUMAR",
    lastName: "MANDAL",
    address: "V-NAMORAHAR*PO-SITESHNAGAR*DIST-MURSHIDABAD",
    fullName: "LATE SWADHIN KUMAR MANDAL",
    saID: "SPR2013",
  },
  {
    firstName: "LATE SWAPAN KUMAR",
    lastName: "MONDAL",
    address: "GOSABA SATSANG VIHAR*PO-GOSABA*DIST-24 PARGANAS(S).",
    fullName: "LATE SWAPAN KUMAR MONDAL",
    saID: "SPR2014",
  },
  {
    firstName: "LATE TARAPADA",
    lastName: "MONDAL",
    address: "VILL-ETAPUR*PO-USHARDIHI*DIST-BIRBHUM",
    fullName: "LATE TARAPADA MONDAL",
    saID: "SPR2015",
  },
  {
    firstName: "LATE TARAK NATH",
    lastName: "MONDAL",
    address:
      "PO-PASCHIM RADHANAGAR*VIA-CHOTA MOLLAKHALI*DIST-24PARGANAS (S)*PAN-BCQPM4958L",
    fullName: "LATE TARAK NATH MONDAL",
    saID: "SPR2016",
  },
  {
    firstName: "TARAPADA",
    lastName: "MONDAL",
    address: "AT&PO-KUKHRAJORE*DIST BANKURA-722133",
    fullName: "TARAPADA MONDAL",
    saID: "SPR2017",
  },
  {
    firstName: "LATE TRAILOKYA CHARAN",
    lastName: "MONDAL",
    address: "PLOT NO 400/1318*LAXMI SAGAR HALDIPADIA*BHUBANESWAR-751006.",
    fullName: "LATE TRAILOKYA CHARAN MONDAL",
    saID: "SPR2018",
  },
  {
    firstName: "TULSIDAS",
    lastName: "MONDAL",
    address: "VILL-KASHIPUR*PO-P.K.RAJ*THANA ROAD*PURULIA",
    fullName: "TULSIDAS MONDAL",
    saID: "SPR2019",
  },
  {
    firstName: "BHADRESWAR",
    lastName: "MORANG",
    address: "VILL/PO-GABHARU TUNIJAN*VIA-LALUK*NORTH LAKHIMPUR-784160.",
    fullName: "BHADRESWAR MORANG",
    saID: "SPR2020",
  },
  {
    firstName: "LATE KHAGEN NATH",
    lastName: "MOULICK",
    address: "MATIGANJ*PO BONGAON*DIST 24 PARAGANAS",
    fullName: "LATE KHAGEN NATH MOULICK",
    saID: "SPR2021",
  },
  {
    firstName: "LATE SUDHINDRA",
    lastName: "MOULICK",
    address: "SATISH CHANDRA SARKAR LANE*MASHAK CHAWK*BHAGALPUR.",
    fullName: "LATE SUDHINDRA MOULICK",
    saID: "SPR2022",
  },
  {
    firstName: "LATE BIHARI LAL",
    lastName: "MRIDHA",
    address: "VILL/PO GELEKI T.E.,GELEKI BAGAN*SIBSAGAR",
    fullName: "LATE BIHARI LAL MRIDHA",
    saID: "SPR2023",
  },
  {
    firstName: "LATE JAGANNATH",
    lastName: "MRIDHA",
    address: "HIRANMOYPUR*PO-NAFARGANJ*DIST-24-PGS.",
    fullName: "LATE JAGANNATH MRIDHA",
    saID: "SPR2024",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "MRIDHA",
    address: "AT+PO-HATGACHA*DIST-24 PGS (NORTH)-700059",
    fullName: "KARTICK CHANDRA MRIDHA",
    saID: "SPR2025",
  },
  {
    firstName: "LATE KRISHNAPADA",
    lastName: "MRIDHA",
    address: "AT/PO-SATJELIA*DIST-24-PARGANAS. 743 370",
    fullName: "LATE KRISHNAPADA MRIDHA",
    saID: "SPR2026",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "MUDI",
    address: "AT/PO.JAYNAGAR*DT.HOWRAH",
    fullName: "LATE JITENDRA NATH MUDI",
    saID: "SPR2027",
  },
  {
    firstName: "PANCHU GOPAL",
    lastName: "MUDI",
    address: "VILL/PO.JOYNAGAR*HAWRAH-711302",
    fullName: "PANCHU GOPAL MUDI",
    saID: "SPR2028",
  },
  {
    firstName: "SANAT KUMAR",
    lastName: "MUDI",
    address: "AT/PO.JAYNAGAR*VIA-ANDULMOURI*DT.HOWRAH-711302",
    fullName: "SANAT KUMAR MUDI",
    saID: "SPR2029",
  },
  {
    firstName: "SUBRATA KUMAR",
    lastName: "MUDI",
    address: "VILL/PO.JOYNAGAR*HOWRAH-711302",
    fullName: "SUBRATA KUMAR MUDI",
    saID: "SPR2030",
  },
  {
    firstName: "JALADHAR",
    lastName: "MUDULI",
    address: "AT/PO-MANIPUR*DHENKANAL-759027*PAN-BJNPM2343D",
    fullName: "JALADHAR MUDULI",
    saID: "SPR2031",
  },
  {
    firstName: "LATE KANDURI",
    lastName: "MUDULI",
    address: "VILL-BALABHADRAPUR*PO-BRAHMAN SADANGI*VIA-BALI PATANA*KHURDA",
    fullName: "LATE KANDURI MUDULI",
    saID: "SPR2032",
  },
  {
    firstName: "LAXMIKANTA",
    lastName: "MUHURI",
    address: "765/N KASTADANGA ROAD*SARSUNA*CALCUTTA 700061*PAN-BEUPM5103G",
    fullName: "LAXMIKANTA MUHURI",
    saID: "SPR2033",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "MUHURI",
    address: "AT-MAZDIA*PO-JOYGHATA*NADIA-741508",
    fullName: "MANINDRA NATH MUHURI",
    saID: "SPR2034",
  },
  {
    firstName: "AJOY KUMAR",
    lastName: "MUKHARJEE",
    address: "68 SURYA SEN STREET*CALCUTTA-700009",
    fullName: "AJOY KUMAR MUKHARJEE",
    saID: "SPR2035",
  },
  {
    firstName: "LATE BIDYUT",
    lastName: "MUKHARJEE",
    address:
      "C/O ANNAPURNA TIMBER CO.*PO-DIAMOND HARBOUR*STATION BAZAR*DIST-24 PGS.",
    fullName: "LATE BIDYUT MUKHARJEE",
    saID: "SPR2036",
  },
  {
    firstName: "LATE GIRIJA PRASAD",
    lastName: "MUKHARJEE",
    address: "SEVAYATAN JR. BASIC SCHOOL*PO-SEVAYATAN*MIDNAPUR",
    fullName: "LATE GIRIJA PRASAD MUKHARJEE",
    saID: "SPR2037",
  },
  {
    firstName: "MANIK LAL",
    lastName: "MUKHARJEE",
    address:
      "84,BARENDRA GALI MAIN ROAD*PO-HALISAHAR*DIST-24 PARGANAS (NORTH)-743134",
    fullName: "MANIK LAL MUKHARJEE",
    saID: "SPR2038",
  },
  {
    firstName: "LATE SUBHASH CHANDRA",
    lastName: "MUKHARJEE",
    address: "VILL-NABASAN*PO-SARISHA*DIST-24 PGS.(SOUTH).",
    fullName: "LATE SUBHASH CHANDRA MUKHARJEE",
    saID: "SPR2039",
  },
  {
    firstName: "LATE SUBIMAL",
    lastName: "MUKHARJEE",
    address:
      "C/O S MUKHERJEE*O/O SUPT.OF POLICE*CARISTION BOSTI*GUWAHATI*KAMRUP",
    fullName: "LATE SUBIMAL MUKHARJEE",
    saID: "SPR2040",
  },
  {
    firstName: "LATE SUDHANSU KUMAR",
    lastName: "MUKHARJEE",
    address:
      "C/O S P BANERJEE*TOWER BLOCK,3RD FLOOR,FLAT NO 24*BROAD STREET*CALCUTTA-19",
    fullName: "LATE SUDHANSU KUMAR MUKHARJEE",
    saID: "SPR2041",
  },
  {
    firstName: "TUSAR KANTI",
    lastName: "MUKHARJEE",
    address: "AT/PO-RAMPARA*DIST-MURSHIDABAD*PIN 742189",
    fullName: "TUSAR KANTI MUKHARJEE",
    saID: "SPR2042",
  },
  {
    firstName: "LATE SHYAMAPADA",
    lastName: "MUKHERJEE",
    address: "C/O-K K MUKHERJI*H G MAJUMDAR ROAD*DHANBAD-826001.",
    fullName: "LATE SHYAMAPADA MUKHERJEE",
    saID: "SPR2043",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "MUKHERJEE",
    address: "45/11 CHOWDHURYPARA LANE*PO.SANTRAGACHI*DT.HOWRAH",
    fullName: "LATE AJIT KUMAR MUKHERJEE",
    saID: "SPR2044",
  },
  {
    firstName: "LATE BANBEHARI",
    lastName: "MUKHERJEE",
    address: "AT&PO.PATIRAM  *W/DINAJPUR",
    fullName: "LATE BANBEHARI MUKHERJEE",
    saID: "SPR2045",
  },
  {
    firstName: "LATE BINAY GOPAL",
    lastName: "MUKHERJEE",
    address: "P-105,NEW CIT ROAD*CALCUTTA 14",
    fullName: "LATE BINAY GOPAL MUKHERJEE",
    saID: "SPR2046",
  },
  {
    firstName: "LATE BINODE BIHARI",
    lastName: "MUKHERJEE",
    address: "BIBEK PALLI*PO-SHEORAFULLY*HOOGHLY",
    fullName: "LATE BINODE BIHARI MUKHERJEE",
    saID: "SPR2047",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "MUKHERJEE",
    address: "AT/PO JALANDI*DIST-BIRBHUM",
    fullName: "LATE BISWANATH MUKHERJEE",
    saID: "SPR2048",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "MUKHERJEE",
    address: "*SATSANG 814116",
    fullName: "LATE BISWANATH MUKHERJEE",
    saID: "SPR2049",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "MUKHERJEE",
    address: "MEMARY SWASTI SADAN*PO MEMARY*BURDWAN",
    fullName: "LATE CHITTARANJAN MUKHERJEE",
    saID: "SPR2050",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "MUKHERJEE",
    address: "MEMARY SATSANG CENTER*PO-MEMARY*BURDWAN",
    fullName: "LATE CHITTARANJAN MUKHERJEE",
    saID: "SPR2051",
  },
  {
    firstName: "LATE DEB KUMAR",
    lastName: "MUKHARJEE",
    address: "MITHA PUKUR LANE*DURGABATI*PO/DIST-BARDDHAMAN",
    fullName: "LATE DEB KUMAR MUKHARJEE",
    saID: "SPR2052",
  },
  {
    firstName: "DEBI PRASAD",
    lastName: "MUKHERJEE",
    address: "*SATSANG 814116",
    fullName: "DEBI PRASAD MUKHERJEE",
    saID: "SPR2053",
  },
  {
    firstName: "LATE DESHBANDHU",
    lastName: "MUKHERJEE",
    address: "14/1/1 HRIDAYKRISHNA BANERJEE*1ST BYE LANE*DT.HOWRAH",
    fullName: "LATE DESHBANDHU MUKHERJEE",
    saID: "SPR2054",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "MUKHERJEE",
    address: "ANDAL BAZAR*PO ANDAL*DIST BURDWAN",
    fullName: "LATE HARENDRA NATH MUKHERJEE",
    saID: "SPR2055",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "MUKHERJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE HEM CHANDRA MUKHERJEE",
    saID: "SPR2056",
  },
  {
    firstName: "LATE KALI KINKAR",
    lastName: "MUKHERJEE",
    address: "C/O-MORNING TEA*AT/PO-GALSI*BURDWAN",
    fullName: "LATE KALI KINKAR MUKHERJEE",
    saID: "SPR2057",
  },
  {
    firstName: "LATE KARUNA SINDHU",
    lastName: "MUKHERJEE",
    address: "SATSANG CENTRE*14/1 H.K.BANERJEE IST BY LANE*PO-BANTRA*HOWRAH",
    fullName: "LATE KARUNA SINDHU MUKHERJEE",
    saID: "SPR2058",
  },
  {
    firstName: "MRITYUNJOY",
    lastName: "MUKHERJEE",
    address: "LOKENATH NAGAR*AIRPORT MORE*BAGDOGRA*DARJEELING-734421",
    fullName: "MRITYUNJOY MUKHERJEE",
    saID: "SPR2059",
  },
  {
    firstName: "LATE NANDAGOPAL",
    lastName: "MUKHERJEE",
    address: "PO RANIGANJ*DIST BURDWAN",
    fullName: "LATE NANDAGOPAL MUKHERJEE",
    saID: "SPR2060",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "MUKHERJEE",
    address: "NETAJI MAHALLA*SERAMPORE*HOOGHLY",
    fullName: "LATE PARESH CHANDRA MUKHERJEE",
    saID: "SPR2061",
  },
  {
    firstName: "LATE PARESH NATH",
    lastName: "MUKHERJEE",
    address: "DIST SATSANG CENTRE*KERANI BAJAR*PO&DT-BANKURA.",
    fullName: "LATE PARESH NATH MUKHERJEE",
    saID: "SPR2062",
  },
  {
    firstName: "LATE RABILOCHAN",
    lastName: "MUKHERJEE",
    address: "PARBELIA COLLIERY HIGH SCHOOL*PO.NETURIA*PURULIA-723121",
    fullName: "LATE RABILOCHAN MUKHERJEE",
    saID: "SPR2063",
  },
  {
    firstName: "LATE RADHARAMAN",
    lastName: "MUKHERJEE",
    address: "PO-CHITTARANJAN*DT-BURDWAN.",
    fullName: "LATE RADHARAMAN MUKHERJEE",
    saID: "SPR2064",
  },
  {
    firstName: "LATE SAILESH CHANDRA",
    lastName: "MUKHERJEE",
    address:
      "VILL-CHECHAKHATA*(SHIBBARI COLONY)*PO-ALIPURDUAR JN*DIST-JALPAIGURI-736123.",
    fullName: "LATE SAILESH CHANDRA MUKHERJEE",
    saID: "SPR2065",
  },
  {
    firstName: "SAMBHU CHARAN",
    lastName: "MUKHERJEE",
    address: "BHIRINGI SCHOOL PARA*DURGAPUR-713213*DIST BURDWAN",
    fullName: "SAMBHU CHARAN MUKHERJEE",
    saID: "SPR2066",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "MUKHERJEE",
    address: "PO-NABADWIP*DT-NADIA.",
    fullName: "LATE SANTOSH KUMAR MUKHERJEE",
    saID: "SPR2067",
  },
  {
    firstName: "SATYANANDA",
    lastName: "MUKHERJEE",
    address: "AT/PO.HANRAL*PS.DADPUR*DIST.HOOGHLY-712149",
    fullName: "SATYANANDA MUKHERJEE",
    saID: "SPR2068",
  },
  {
    firstName: "SIRSHENDU",
    lastName: "MUKHARJEE",
    address: "538, JODHPUR PARK*KOLKATA-700068.",
    fullName: "SIRSHENDU MUKHARJEE",
    saID: "SPR2069",
  },
  {
    firstName: "LATE S RINATH",
    lastName: "MUKHERJEE",
    address:
      "UPASANA NIKETAN*HOWRAH SATSANG KENDRA*14/1 HRIDAY KR BANERJI IST BYE LANE*HOWRAH",
    fullName: "LATE S RINATH MUKHERJEE",
    saID: "SPR2070",
  },
  {
    firstName: "LATE SRINATH",
    lastName: "MUKHARJEE",
    address:
      "SARADA APARTMENT,3RD.FLOOR*108,NARSINGH DUTTA ROAD*PO/DIST-HOWRAH",
    fullName: "LATE SRINATH MUKHARJEE",
    saID: "SPR2071",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "MUKHERJEE",
    address: "200,GOSWAI PARA ROAD*PO-BALLY*HOWRAH",
    fullName: "LATE SUDHIR CHANDRA MUKHERJEE",
    saID: "SPR2072",
  },
  {
    firstName: "LATE SUNIL",
    lastName: "MUKHERJEE",
    address: "AT-HETAMPUR*PO-HETAMPUR RAJBATI*DIST BIRBHUM",
    fullName: "LATE SUNIL MUKHERJEE",
    saID: "SPR2073",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "MALLICK",
    address: "BALAGANA STATION,PO BALAGANA T.O.*DIST BURDWAN",
    fullName: "LATE ANANTA KUMAR MALLICK",
    saID: "SPR2074",
  },
  {
    firstName: "LATE BIRSHA",
    lastName: "MUNDA",
    address: "SARUDIRIGAON*PO RAJMAI*SIBSAGAR.",
    fullName: "LATE BIRSHA MUNDA",
    saID: "SPR2075",
  },
  {
    firstName: "LATE HALADHAR",
    lastName: "MUNDA",
    address: "DERANG RAJABARI,PO DERANGHAT*SIBSAGAR",
    fullName: "LATE HALADHAR MUNDA",
    saID: "SPR2076",
  },
  {
    firstName: "SURA",
    lastName: "MUNDA",
    address: "MEDHIPAM*PO-GHARMARA*SONITPUR.",
    fullName: "SURA MUNDA",
    saID: "SPR2077",
  },
  {
    firstName: "LATE BINODE BIHARI",
    lastName: "MUNSHI",
    address: "80/4 BELILIAS LANE*HOWRAH 711 101.",
    fullName: "LATE BINODE BIHARI MUNSHI",
    saID: "SPR2078",
  },
  {
    firstName: "LATE HIRANMOY",
    lastName: "MUNSHI",
    address: "PO/VILL-JAGTAI*MURSHIDABAD.",
    fullName: "LATE HIRANMOY MUNSHI",
    saID: "SPR2079",
  },
  {
    firstName: "LATE RAGHUNATH",
    lastName: "MURMOO",
    address: "PO.DIGHA*PURULIA",
    fullName: "LATE RAGHUNATH MURMOO",
    saID: "SPR2080",
  },
  {
    firstName: "LATE SUDARSAN",
    lastName: "MURMOO",
    address: "SIMLA KELPARA*PO KUNJORA*S P",
    fullName: "LATE SUDARSAN MURMOO",
    saID: "SPR2081",
  },
  {
    firstName: "ABISWAR",
    lastName: "MURMU",
    address: "VILL-GHORADAHA*PO-BAGDAHA*DEOGHAR-815351*(JHARKHAND)",
    fullName: "ABISWAR MURMU",
    saID: "SPR2082",
  },
  {
    firstName: "LATE BHIMSEN",
    lastName: "MURMU",
    address: "AT BHOTAIJURI;PO ANARA *DIST BANKURA",
    fullName: "LATE BHIMSEN MURMU",
    saID: "SPR2083",
  },
  {
    firstName: "KASTURI",
    lastName: "MURMOO",
    address: "*SATSANG 814116",
    fullName: "KASTURI MURMOO",
    saID: "SPR2084",
  },
  {
    firstName: "LATE ISHAN CHANDRA",
    lastName: "MUSHAHARI",
    address: "NO 2 GORMARA GAON*PO-KAJIAMATI*DIST-UDALGURI*(ASSAM)",
    fullName: "LATE ISHAN CHANDRA MUSHAHARI",
    saID: "SPR2085",
  },
  {
    firstName: "LATE KAMIL",
    lastName: "MUSHAHARI",
    address: "2 NO.SHANTIPURGAON*PO-GARMARA*DARRANG.",
    fullName: "LATE KAMIL MUSHAHARI",
    saID: "SPR2086",
  },
  {
    firstName: "LATE MAHENDRA NATH",
    lastName: "MUSHAHARY",
    address: "AT-SHYAMDASGURI*PO-BHADIAGURI*GOALPARA.",
    fullName: "LATE MAHENDRA NATH MUSHAHARY",
    saID: "SPR2087",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MUSHIB",
    address: "VILL-UPARDIHI*PO-ALIJHARA*BANKURA-722137",
    fullName: "NARAYAN CHANDRA MUSHIB",
    saID: "SPR2088",
  },
  {
    firstName: "SUKUMAR",
    lastName: "MUSTAFI",
    address: "C/O BIMAL STORES*JOGBANI*PURNIA-854328",
    fullName: "SUKUMAR MUSTAFI",
    saID: "SPR2089",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "NAG",
    address: "2 NO BANKIMNAGAR*PO-ATHPUR*DIST-24 PGS (NORTH)-743128",
    fullName: "ARUN KUMAR NAG",
    saID: "SPR2090",
  },
  {
    firstName: "LATE PHANINDRA LAL",
    lastName: "NAG",
    address: "NAG PHARMACY*PO.KALIAGANJ*W/DINAJPUR",
    fullName: "LATE PHANINDRA LAL NAG",
    saID: "SPR2091",
  },
  {
    firstName: "LATE HIRA LAL",
    lastName: "NAHA",
    address: "CHANDIGARH 3 NO*PO-MADHYAMGRAM BAJAR*24PARGANAS NORTH",
    fullName: "LATE HIRA LAL NAHA",
    saID: "SPR2092",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "NAIDING",
    address: "P.O.HAFLONG BAJAR(HAFLONG)*N.C.HILLS.",
    fullName: "GOPAL CHANDRA NAIDING",
    saID: "SPR2093",
  },
  {
    firstName: "JAIDHAN",
    lastName: "NAIDU",
    address: "AMGURI GARDEN*PO-PANGURI*SIBSAGAR",
    fullName: "JAIDHAN NAIDU",
    saID: "SPR2094",
  },
  {
    firstName: "LATE BAURIBANDHU",
    lastName: "NANDA",
    address: "H/NO-LB/54,STAGE-IV*LAXMISAGAR*PO-BHUBANESWAR-6*KHURDA",
    fullName: "LATE BAURIBANDHU NANDA",
    saID: "SPR2095",
  },
  {
    firstName: "LATE ASHATARU",
    lastName: "NANDI",
    address: "PO-KANCHAN PARA*82 MILE*TRIPURA SOUTH",
    fullName: "LATE ASHATARU NANDI",
    saID: "SPR2096",
  },
  {
    firstName: "LATE BHABANI PRASAD",
    lastName: "NANDI",
    address: "AT/PO-KHANPUR*DIST-HOOGHLY",
    fullName: "LATE BHABANI PRASAD NANDI",
    saID: "SPR2097",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "NANDY",
    address: "VILL-BIBEKANAND PALLY*PO-BHATTA BAJAR*PURNIA",
    fullName: "LATE CHITTARANJAN NANDY",
    saID: "SPR2098",
  },
  {
    firstName: "GOPAL KRISHNA",
    lastName: "NANDI",
    address: "KAHILIPARA COLONY*PO-BINOVANAGAR*GAUHATI-781 018*KAMRUP",
    fullName: "GOPAL KRISHNA NANDI",
    saID: "SPR2099",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "NANDY",
    address: "C/O B.N.BISWAS*SABJEE ARAT*P.O.DIMAPUR*NAGALAND",
    fullName: "LATE HARIPADA NANDY",
    saID: "SPR2100",
  },
  {
    firstName: "RAKHAL KRISHNA",
    lastName: "NANDY",
    address:
      "WEST BHASKAR NAGAR*PO BINOVANAGAR*GUWAHATI-781018*DIST-KAMRUP*PAN-ARSPN7508B",
    fullName: "RAKHAL KRISHNA NANDY",
    saID: "SPR2101",
  },
  {
    firstName: "LATE SANAT KUMAR",
    lastName: "NANDY",
    address: "VILL-SATITHAN*PO-DWARBASINI*HOOGHLY",
    fullName: "LATE SANAT KUMAR NANDY",
    saID: "SPR2102",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "NANDY",
    address: '"BINODABAS",DEBTABARI ROAD*PO-ABHOYNAGAR 799005*AGARTALA*TRIPURA',
    fullName: "SANJIB KUMAR NANDY",
    saID: "SPR2103",
  },
  {
    firstName: "LATE SANKAR KUMAR",
    lastName: "NANDI",
    address: "DESHABANDHUPARA*PO.SILIGURI*DARJEELING",
    fullName: "LATE SANKAR KUMAR NANDI",
    saID: "SPR2104",
  },
  {
    firstName: "SARIT PRASAD",
    lastName: "NANDY",
    address: "PO-KANCHANBARI*NORTH TRIPURA-799288",
    fullName: "SARIT PRASAD NANDY",
    saID: "SPR2105",
  },
  {
    firstName: "LATE SUDHANGSHU SEKHAR",
    lastName: "NANDY",
    address: "PO/VILL-BAIDYAPUR*BURDWAN.",
    fullName: "LATE SUDHANGSHU SEKHAR NANDY",
    saID: "SPR2106",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "NARANG",
    address: "36,GURUDWAR ROAD K ROAD*DEHRADUN",
    fullName: "LATE MADAN MOHAN NARANG",
    saID: "SPR2107",
  },
  {
    firstName: "LATE ACHALDEO",
    lastName: "NARAYAN",
    address: "VILL KASHIPUR*PO/DT SAMASTIPUR",
    fullName: "LATE ACHALDEO NARAYAN",
    saID: "SPR2108",
  },
  {
    firstName: "LATE HARIBALLAV",
    lastName: "NARAYAN",
    address: "*SATSANG",
    fullName: "LATE HARIBALLAV NARAYAN",
    saID: "SPR2109",
  },
  {
    firstName: "BIRENDRA CHANDRA",
    lastName: "NARJARI",
    address: "VILL-NORTH KOILA MAILA*PO-AMTEKA*DIST-BONGAIGAON-783393*ASSAM",
    fullName: "BIRENDRA CHANDRA NARJARI",
    saID: "SPR2110",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "NARJARI",
    address: "AT-NARENGURI*PO-GAMBARIBILL*KOKRAJHAR-783336*ASSAM",
    fullName: "LATE SAILENDRA NATH NARJARI",
    saID: "SPR2111",
  },
  {
    firstName: "SURATH",
    lastName: "NARZARY",
    address: "AT-KUMGURI*PO-SERFANGURI*KOKRAJHAR 783346*PAN-ABQPN3822J",
    fullName: "SURATH NARZARY",
    saID: "SPR2112",
  },
  {
    firstName: "LATE GOPINATH",
    lastName: "NARZARY",
    address: "NO-1,SHANTIPURGAON*PO MEJENGJULI*SONITPUR",
    fullName: "LATE GOPINATH NARZARY",
    saID: "SPR2113",
  },
  {
    firstName: "NAMAL CHANDRA",
    lastName: "NARZARY",
    address: "DAKSHIN AFLAGAON*VIA-SHERFANGURI*GOALPARA",
    fullName: "NAMAL CHANDRA NARZARY",
    saID: "SPR2114",
  },
  {
    firstName: "NRIPENDRA",
    lastName: "NARZARY",
    address: "NARENGURI*PO-PANBARI BAZAR*KOKRAJHAR 783360",
    fullName: "NRIPENDRA NARZARY",
    saID: "SPR2115",
  },
  {
    firstName: "BADAL CHANDRA",
    lastName: "NASKARDEBBARMA",
    address: "VILL-SIMLA KALITALA*PO-SERAMPUR*DIST-HOOGHLY-712201",
    fullName: "BADAL CHANDRA NASKARDEBBARMA",
    saID: "SPR2116",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "NASKAR",
    address: "33 NO, BAGPOTA RD*SARSUNA*KOLKATA-700061.",
    fullName: "LATE BISWANATH NASKAR",
    saID: "SPR2117",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "NASKAR",
    address: "VILL+PO-RAMCHANDRANAGAR*VIA-DHOLAHAT*DIST-24-PGS.",
    fullName: "LATE CHITTARANJAN NASKAR",
    saID: "SPR2118",
  },
  {
    firstName: "LATE CHUNILAL",
    lastName: "NASKAR",
    address: "AT-MAHISHMARI*PO-MEROGANJ*DIST-24 PGS.",
    fullName: "LATE CHUNILAL NASKAR",
    saID: "SPR2119",
  },
  {
    firstName: "LATE MOHIM CHANDRA",
    lastName: "NASKAR",
    address: "PO+VILL-RAMCHANDRA NAGAR*VIA-DHOLAHAT*DIST-24 PGS.",
    fullName: "LATE MOHIM CHANDRA NASKAR",
    saID: "SPR2120",
  },
  {
    firstName: "LATE SACHINDRA NATH",
    lastName: "NASKAR",
    address: "VILL-SWARUPKATI*PO-AMBERIA*DIST-24PGS 743435",
    fullName: "LATE SACHINDRA NATH NASKAR",
    saID: "SPR2121",
  },
  {
    firstName: "LATE AMULLYA KUMAR",
    lastName: "NATH",
    address: "GUNAMAYEE ROAD*SILCHAR-3*PO.TARAPUR*CACHAR",
    fullName: "LATE AMULLYA KUMAR NATH",
    saID: "SPR2122",
  },
  {
    firstName: "LATE BINODE BIHARI",
    lastName: "NATH",
    address: "VILL&PO-CHAKPARA*VIA-LILUAH*DT-HOWRAH.",
    fullName: "LATE BINODE BIHARI NATH",
    saID: "SPR2123",
  },
  {
    firstName: "BINOY BHUSAN",
    lastName: "NATH",
    address: "VILL-SRIRAMPUR*PO-KAILA SAHAR*TRIPURA (N)-799277",
    fullName: "BINOY BHUSAN NATH",
    saID: "SPR2124",
  },
  {
    firstName: "BIPIN CHANDRA",
    lastName: "NATH",
    address: "JYOTINAGAR*AT/PO-KRISHNAI*GOALPARA-783126*(ASSAM)",
    fullName: "BIPIN CHANDRA NATH",
    saID: "SPR2125",
  },
  {
    firstName: "LATE BRAJENDRALAL",
    lastName: "NATH",
    address: "AT/PO-GANKI*TRIPURA.",
    fullName: "LATE BRAJENDRALAL NATH",
    saID: "SPR2126",
  },
  {
    firstName: "LATE JOGENDRA CHANDRA",
    lastName: "NATH",
    address:
      "H.NO.VI-M/472,SAILASHREE VIHAR*CHANDRASEKHARPUR*BHUBANESWAR 751016*ORISSA",
    fullName: "LATE JOGENDRA CHANDRA NATH",
    saID: "SPR2127",
  },
  {
    firstName: "KHARGESHWAR",
    lastName: "NATH",
    address: "VILL-HARIPUR*PO-ABHAYAPURI*DIST-BONGAIGAON-783 384 *(ASSAM)",
    fullName: "KHARGESHWAR NATH",
    saID: "SPR2128",
  },
  {
    firstName: "KUMUD RANJAN",
    lastName: "NATH",
    address: "VILL-MIRZAPUR*PO-BHANGABAZAR*KARIMGANJ 788701",
    fullName: "KUMUD RANJAN NATH",
    saID: "SPR2129",
  },
  {
    firstName: "MOHENDRA CHANDRA",
    lastName: "NATH",
    address:
      "SATSANG ADHIBESAN KENDRA*MATHURA BAZAR*PO.KALAMAGURA*CACHAR*PAN-AOYPN4841G",
    fullName: "MOHENDRA CHANDRA NATH",
    saID: "SPR2130",
  },
  {
    firstName: "NIKHIL RANJAN",
    lastName: "NATH",
    address: "TURA SATSANG VIHAR*PO-TURA*MEGHALAYA-794001*PAN-AJFPN7049G",
    fullName: "NIKHIL RANJAN NATH",
    saID: "SPR2131",
  },
  {
    firstName: "NIRMAL CHANDRA",
    lastName: "NATH",
    address:
      "SHANTI BHAWAN, 3 NO WARD*PO-BARPETA ROAD*DIST-BARPETA-781315*ASSAM",
    fullName: "NIRMAL CHANDRA NATH",
    saID: "SPR2132",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "NATH",
    address: "VILL.JAYPUR*PO.MOGRA*DIST.HOOGHLY",
    fullName: "LATE PARESH CHANDRA NATH",
    saID: "SPR2133",
  },
  {
    firstName: "LATE PRAN KUMAR",
    lastName: "DEBNATH",
    address: "SATSANG KENDRA*PO HOJAI*NOWGONG.",
    fullName: "LATE PRAN KUMAR DEBNATH",
    saID: "SPR2134",
  },
  {
    firstName: "PRASANNA KUMAR",
    lastName: "NATH",
    address: "VILL-DURGAPUR*PO-PAITUR BAZAR*KAILASAHAR*NORTH TRIPURA 799277",
    fullName: "PRASANNA KUMAR NATH",
    saID: "SPR2135",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "NATH",
    address: "BARPARA*BONGAIGAON*PO/DIST-BONGAIGAON -783380",
    fullName: "LATE SATISH CHANDRA NATH",
    saID: "SPR2136",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "NATH",
    address: "AT-HELAGURI*PO-RANIGANJ*GOALPARA-783345*ASSAM",
    fullName: "SATISH CHANDRA NATH",
    saID: "SPR2137",
  },
  {
    firstName: "LATE SURENDRA KUMAR",
    lastName: "NATH",
    address: "HEAD MASTER N.U.HIGH SCHOOL*PO ANIPARA*CACHAR.",
    fullName: "LATE SURENDRA KUMAR NATH",
    saID: "SPR2138",
  },
  {
    firstName: "LATE SUDAM",
    lastName: "NATHSHARMA",
    address: "VILL-ALIPUR*PO-RAMCHANDRAPUR*JAJPUR",
    fullName: "LATE SUDAM NATHSHARMA",
    saID: "SPR2139",
  },
  {
    firstName: "ABHIMANYU",
    lastName: "NAYAK",
    address: "AT.BRAJANANDAPUR*PO.GOBINDAPUR*BHADRAK-756137",
    fullName: "ABHIMANYU NAYAK",
    saID: "SPR2140",
  },
  {
    firstName: "LATE BAISHNAB CHARAN",
    lastName: "NAYAK",
    address: "AT&PO-KORUA*VIA-TYANDAKURA*CUTTACK 754134",
    fullName: "LATE BAISHNAB CHARAN NAYAK",
    saID: "SPR2141",
  },
  {
    firstName: "BARENDRA NATH",
    lastName: "NAYAK",
    address: "AT&PO-BETNOTI*BAHANADA ROAD*MAYURBHANJ 757025",
    fullName: "BARENDRA NATH NAYAK",
    saID: "SPR2142",
  },
  {
    firstName: "BASU KRUSHNA",
    lastName: "NAYAK",
    address: "AT-TALANAGAR*PO-B.M.PUR*VIA-SORO*BALASORE-756045",
    fullName: "BASU KRUSHNA NAYAK",
    saID: "SPR2143",
  },
  {
    firstName: "BHAGAT CHARAN",
    lastName: "NAYAK",
    address:
      "PLOT NO.MA-25*AT/PO-BARGARH BRIT COLONY*BHUBANESWAR 751 018*KHURDA",
    fullName: "BHAGAT CHARAN NAYAK",
    saID: "SPR2144",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "NAYAK",
    address:
      "VILL-SUNDARPUR*PO-MADHUSUDANPUR*VIA-BINJHARPUR*JAJPUR-755023*PAN-ABPPN1262M",
    fullName: "BIJOY KUMAR NAYAK",
    saID: "SPR2145",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "NAYAK",
    address:
      "SATSANG VIHAR BHUBANESWAR*PO-SAHID NAGAR*BHUBANESWAR-751007*KHURDA",
    fullName: "GIRIDHARI NAYAK",
    saID: "SPR2146",
  },
  {
    firstName: "GOPABANDHU",
    lastName: "NAYAK",
    address: "VILL/PO.KURUDA(SORO)*ANANTAPUR*BALASORE-756046",
    fullName: "GOPABANDHU NAYAK",
    saID: "SPR2147",
  },
  {
    firstName: "JAPENDRA",
    lastName: "NAYAK",
    address: "AT BAICHAHABI T.E.*PO CHALENGHAT*SIBSAGAR",
    fullName: "JAPENDRA NAYAK",
    saID: "SPR2148",
  },
  {
    firstName: "LATE MARKANDESWAR",
    lastName: "NAYAK",
    address:
      "PLOT NO-324,RADHAKRUSHNA NAGAR*GARAGE CHHAK*BHUBANESWAR-751002*KHURDA",
    fullName: "LATE MARKANDESWAR NAYAK",
    saID: "SPR2149",
  },
  {
    firstName: "NARENDRA MOHAN",
    lastName: "NAYAK",
    address: "AT-NAGPAL WARD NO-3 *PO.UDALA*MAYURBHANJ-757041*PAN-AKHPN7798K",
    fullName: "NARENDRA MOHAN NAYAK",
    saID: "SPR2150",
  },
  {
    firstName: "NIRAKAR",
    lastName: "NAYAK",
    address: "AT/PO-PURUNABANDHAGODA*VIA-SAINKUL*KEONJHAR 758 043",
    fullName: "NIRAKAR NAYAK",
    saID: "SPR2151",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "NAYAK",
    address: "NAYAPALLI,PLOT-622*KALINGA STADIUM*BHUBANESWAR-751012*KHURDA",
    fullName: "PRAVAKAR NAYAK",
    saID: "SPR2152",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "NAYAK",
    address: "AT/PO-GELPUR*DIST-BHADRAK-756181",
    fullName: "PURNA CHANDRA NAYAK",
    saID: "SPR2153",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "NAYAK",
    address:
      "PLOT NO-502,NAYAPALLI*NEAR ALAKA GAS GODOWN*BHUBANESWAR 751012*KHURDA",
    fullName: "PURNA CHANDRA NAYAK",
    saID: "SPR2154",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "NAYAK",
    address: "AT/PO-KHARASAHAPUR*VIA.ANANTAPUR*BALASORE-756046*PAN-ACCPN1106G",
    fullName: "PURNA CHANDRA NAYAK",
    saID: "SPR2155",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "NAYAK",
    address: "BAZAR PARA*PO-JUNAGARH*KALAHANDI-766014*ODISHA",
    fullName: "RAMESH CHANDRA NAYAK",
    saID: "SPR2156",
  },
  {
    firstName: "RATNAKAR",
    lastName: "NAYAK",
    address:
      "AT-MATTAGAJPUR*PO-KADAMCHHANDA*VIA-KRISHNANANDAPUR*CUTTACK 754135.",
    fullName: "RATNAKAR NAYAK",
    saID: "SPR2157",
  },
  {
    firstName: "LATE SADANANDA",
    lastName: "NAYAK",
    address: "AT/PO-CHABISHPARA*VIA-TIHIRI*BHADRAK-756130",
    fullName: "LATE SADANANDA NAYAK",
    saID: "SPR2158",
  },
  {
    firstName: "SANATAN",
    lastName: "NAYAK",
    address: "26/A,BIMA VIHAR*C.D.A,SECTOR-6*CUTTACK-753014",
    fullName: "SANATAN NAYAK",
    saID: "SPR2159",
  },
  {
    firstName: "SANATAN",
    lastName: "NAYAK",
    address: "AT.UMAPADA*PO.JAJPUR ROAD-755019*JAJPUR",
    fullName: "SANATAN NAYAK",
    saID: "SPR2160",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "NAYAK",
    address:
      "SATSANG VIHAR,JHARSUGUDA*AT-SARASMAL*PO/DIST-JHARSUGUDA*ODISHA-768201",
    fullName: "SARAT CHANDRA NAYAK",
    saID: "SPR2161",
  },
  {
    firstName: "LATE SACHIDANANDA",
    lastName: "NAYAK",
    address: "INSTITUTE OF PHYSICS*SACHIBALAYA MARG*BHUBANESWAR-751005*KHURDA",
    fullName: "LATE SACHIDANANDA NAYAK",
    saID: "SPR2162",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "NAYEK",
    address: "VILL-BARANATA*PO-KENDADANGRI*SINGHBHUM.",
    fullName: "LATE JOGENDRA NATH NAYEK",
    saID: "SPR2163",
  },
  {
    firstName: "LATE MANIK CHANDRA",
    lastName: "NEOG",
    address: "AT-BAHABARIAGAON*PO-JAMUGURIHAT*DARRANG.784180",
    fullName: "LATE MANIK CHANDRA NEOG",
    saID: "SPR2164",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "NEOG",
    address: "AGRIL ENGINEER,DEPT OF AGRONOMY*PO-A.A.U.*JORHAT-785013.",
    fullName: "PRADIP KUMAR NEOG",
    saID: "SPR2165",
  },
  {
    firstName: "HENASINGH",
    lastName: "ENGLENG",
    address: "",
    fullName: "HENASINGH ENGLENG",
    saID: "SPR2166",
  },
  {
    firstName: "GANGA RAM",
    lastName: "NEWAR",
    address: "VIL&PO-NEMUPATHAR*VIA-BAMUNBARI*DIST-DIBRUGARH-786613*ASSAM",
    fullName: "GANGA RAM NEWAR",
    saID: "SPR2167",
  },
  {
    firstName: "CHANDRADHWAJ",
    lastName: "NIROLA",
    address: "SATSANG 814116*PAN ANXPN0226B",
    fullName: "CHANDRADHWAJ NIROLA",
    saID: "SPR2168",
  },
  {
    firstName: "LATE AMARESHWAR",
    lastName: "OJHA",
    address: "CHANDANKIARY*DHANBAD.",
    fullName: "LATE AMARESHWAR OJHA",
    saID: "SPR2169",
  },
  {
    firstName: "LATE KRUSHNA CHANDRA",
    lastName: "OJHA",
    address: '"SADHAN JYOTI"*N4/77,I R C VILLAGE*BHUBANESWAR 751015*KHURDA',
    fullName: "LATE KRUSHNA CHANDRA OJHA",
    saID: "SPR2170",
  },
  {
    firstName: "LATE SHITAL CHANDRA",
    lastName: "PANCHAL",
    address: "14/1 H.K.BANERJEE Ist BY LANE*PO-BANTRA*HOWRAH",
    fullName: "LATE SHITAL CHANDRA PANCHAL",
    saID: "SPR2171",
  },
  {
    firstName: "ACHINTA KUMAR",
    lastName: "PAL",
    address: "SATSANG SWASTI SIBIR*PO.KRISNANAGAR*NADIA-741101*PAN-BNOPP2267C",
    fullName: "ACHINTA KUMAR PAL",
    saID: "SPR2172",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "PAL",
    address: "NIBEDITA PALLY*PO-SRIPALLY*BURDWAN-713103*PAN-CYVPP1296C.",
    fullName: "ARUN KUMAR PAL",
    saID: "SPR2173",
  },
  {
    firstName: "LATE BANKU BEHARI",
    lastName: "PAL",
    address: "VIL-BELGHARIA*PO.PRITINAGAR*NADIA",
    fullName: "LATE BANKU BEHARI PAL",
    saID: "SPR2174",
  },
  {
    firstName: "BHASAN CHANDRA",
    lastName: "PAL",
    address: "61/36,BENI MASTER LANE*PO-SARSUNA*KOLKATA-700 061.",
    fullName: "BHASAN CHANDRA PAL",
    saID: "SPR2175",
  },
  {
    firstName: "LATE BISNUPADA",
    lastName: "PAL",
    address: "AT&PO.ARANGHATA *NADIA-741501",
    fullName: "LATE BISNUPADA PAL",
    saID: "SPR2176",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "PAL",
    address: "68 SURYASEN STREET*CALCUTTA-9",
    fullName: "DHARANIDHAR PAL",
    saID: "SPR2177",
  },
  {
    firstName: "LATE GOSTHA BEHARI",
    lastName: "PAL",
    address: "162 S.N.ROY ROAD*CALCUTTA-38",
    fullName: "LATE GOSTHA BEHARI PAL",
    saID: "SPR2178",
  },
  {
    firstName: "HIREN KRISNA",
    lastName: "PAL",
    address: "30 UMESH MUKHERJEE ROAD*PO-BELGHARIA*CALCUTTA 56*PAN AFVPP4128C",
    fullName: "HIREN KRISNA PAL",
    saID: "SPR2179",
  },
  {
    firstName: "NIRANJAN",
    lastName: "PAL",
    address: "PO.KANKI*UTTAR DINAJPUR-733209",
    fullName: "NIRANJAN PAL",
    saID: "SPR2180",
  },
  {
    firstName: "LATE PRAN BALLAV",
    lastName: "PAL",
    address: "HOSPITAL PARA*PO-BAIRATIGURI*JALPAIGURI",
    fullName: "LATE PRAN BALLAV PAL",
    saID: "SPR2181",
  },
  {
    firstName: "LATE SUDHIR RANJAN",
    lastName: "PAL",
    address: "OLD SILIGURI*PO-SILIGURI BAZAR*JALPAIGURI",
    fullName: "LATE SUDHIR RANJAN PAL",
    saID: "SPR2182",
  },
  {
    firstName: "LATE MILAN",
    lastName: "PALMAJUMDAR",
    address: "SATSANG VIHAR GANGARAMPUR*PO-GANGARAMPUR*DAKSHIN DINAJPUR",
    fullName: "LATE MILAN PALMAJUMDAR",
    saID: "SPR2183",
  },
  {
    firstName: "LATE BAIDHAR",
    lastName: "PALAI",
    address: "VILL.RANIPUR*PO.KADABARANG*VIA.R-HAT*BALASORE.",
    fullName: "LATE BAIDHAR PALAI",
    saID: "SPR2184",
  },
  {
    firstName: "LATE KESAB CHANDRA",
    lastName: "PALCHOUDHURI",
    address: "GATE ROAD*PO.KRISNANAGAR*NADIA",
    fullName: "LATE KESAB CHANDRA PALCHOUDHURI",
    saID: "SPR2185",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "PALIT",
    address:
      "SRIGURU MEDICAL STORES*PO-MARGHERITA CHARALI*DIST-TINSUKIA-786181*ASSAM",
    fullName: "LATE BIBHUTI BHUSAN PALIT",
    saID: "SPR2186",
  },
  {
    firstName: "LATE GANAPATI",
    lastName: "PAM",
    address: "AT&PO-CHHOTAMATIA*GOALPARA.",
    fullName: "LATE GANAPATI PAM",
    saID: "SPR2187",
  },
  {
    firstName: "LATE MADAN C",
    lastName: "PANCHAL",
    address: "AAMGAM ROAD SANJAN*TAL.UMBERGAON*BULSAV*GUJRAT",
    fullName: "LATE MADAN C PANCHAL",
    saID: "SPR2188",
  },
  {
    firstName: "BHAGBAN",
    lastName: "PANDA",
    address:
      "H.NO-12,RATNA VILLAGE*JAYDURGA NAGAR(BOMIKHAL)*PO-RASULGARH*BHUBANESWAR-10*KHURDA",
    fullName: "BHAGBAN PANDA",
    saID: "SPR2189",
  },
  {
    firstName: "BIJOY CHANDRA",
    lastName: "PANDA",
    address:
      "BAIKUNTHA NAGAR IST LINE*PO-BERHAMPUR*GANJAM 760001*PAN.ABDPP1038R",
    fullName: "BIJOY CHANDRA PANDA",
    saID: "SPR2190",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "PANDA",
    address: "PO-PADUMBASAN*PO-TAMLUK*MIDNAPORE.",
    fullName: "LATE BIRENDRA NATH PANDA",
    saID: "SPR2191",
  },
  {
    firstName: "LATE CHAKRADHAR",
    lastName: "PANDA",
    address: "PLOT NO 575 NUAPALLI*BHUBANESWAR 12",
    fullName: "LATE CHAKRADHAR PANDA",
    saID: "SPR2192",
  },
  {
    firstName: "LATE DHRUBA CHARAN",
    lastName: "PANDA",
    address: "AT/PO.KHANNAGAR*CUTTACK",
    fullName: "LATE DHRUBA CHARAN PANDA",
    saID: "SPR2193",
  },
  {
    firstName: "DILIP CHANDRA",
    lastName: "PANDA",
    address: '"NIRMALYA"*D-2,KALPANA AREA*BHUBANESWAR 751014*DIST-KHURDA',
    fullName: "DILIP CHANDRA PANDA",
    saID: "SPR2194",
  },
  {
    firstName: "LATE GANESWAR",
    lastName: "PANDA",
    address: "VILL-KHANKARPUR*PO-PARAHAT*VIA-BALIKUDA*DIST-CUTTACK.",
    fullName: "LATE GANESWAR PANDA",
    saID: "SPR2195",
  },
  {
    firstName: "LATE GOPINATH",
    lastName: "PANDA",
    address:
      "SWASTI CHALAN BUILDING*NEAR PWD OFFICE*AT-UTTARESWAR*PO-SORO*BALASORE-756045.",
    fullName: "LATE GOPINATH PANDA",
    saID: "SPR2196",
  },
  {
    firstName: "LATE GURU CHARAN",
    lastName: "PANDA",
    address: "APILA GOUDASAHI*PO.DIHASAHI*VIA.TIRAN*JAGATSINGHPUR-754138",
    fullName: "LATE GURU CHARAN PANDA",
    saID: "SPR2197",
  },
  {
    firstName: "MADHAB CHANDRA",
    lastName: "PANDA",
    address: "VILL/PO.MAYURJHALIA*VIA.GOPALPUR*PURI 752025",
    fullName: "MADHAB CHANDRA PANDA",
    saID: "SPR2198",
  },
  {
    firstName: "MAHESWAR",
    lastName: "PANDA",
    address: "AT.KUTARI*PO.KANHEIBINDHA*VIA.BETADA*BALASORE.",
    fullName: "MAHESWAR PANDA",
    saID: "SPR2199",
  },
  {
    firstName: "MUKTI KANTA",
    lastName: "PANDA",
    address: "AT-BALIBINDHA*PO-BHAIRABPUR*VIA-BASUDEVPUR*BHADRAK-756125",
    fullName: "MUKTI KANTA PANDA",
    saID: "SPR2200",
  },
  {
    firstName: "LATE NIRAPADA",
    lastName: "PANDA",
    address: "PADUMBASAN*PO-TAMLUK*DIST-MIDNAPORE",
    fullName: "LATE NIRAPADA PANDA",
    saID: "SPR2201",
  },
  {
    firstName: "RAJANI KANTA",
    lastName: "PANDA",
    address: "AT.GHUMERGUDA*PO.CHARBAHAL*KALAHANDI-766017*PAN-BJLPP3824J",
    fullName: "RAJANI KANTA PANDA",
    saID: "SPR2202",
  },
  {
    firstName: "SANATAN",
    lastName: "PANDA",
    address: "AT&PO-DASARATHPUR*DIST-JAJPUR 755006.",
    fullName: "SANATAN PANDA",
    saID: "SPR2203",
  },
  {
    firstName: "SATYA NARAYAN",
    lastName: "PANDA",
    address: "AT/PO-MALIGUDA-766023*KALAHANDI.",
    fullName: "SATYA NARAYAN PANDA",
    saID: "SPR2204",
  },
  {
    firstName: "SUKUMAR",
    lastName: "PANDA",
    address: "VILL-KASBA,WORD NO-5*PO-EGRA*PURBA MEDINIPUR  721 429",
    fullName: "SUKUMAR PANDA",
    saID: "SPR2205",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "PANDA",
    address: "AT-GHATAL SATSANG VIHAR *PO-GHATAL*PASCHIM MEDINIPUR-721212",
    fullName: "LATE SUNIL KUMAR PANDA",
    saID: "SPR2206",
  },
  {
    firstName: "SURENDRA PRASAD",
    lastName: "PANDA",
    address: "AT/PO-BACHHIPUR*VIA-BRAHMANGAON*BHADRAK 756165",
    fullName: "SURENDRA PRASAD PANDA",
    saID: "SPR2207",
  },
  {
    firstName: "TAPODHAN",
    lastName: "PANDA",
    address:
      "VIM-741,SAILASHREE VIHAR*PO-SAILASHREE VIHAR*BHUBANESWAR 751 021*PAN-AEAPP9930L",
    fullName: "TAPODHAN PANDA",
    saID: "SPR2208",
  },
  {
    firstName: "ARUN PRAKASH",
    lastName: "PANDEY",
    address: "AT&PO-JARAIKELLA*DT-SINGHBHUM WEST-833105*PAN-ACBPP8139L",
    fullName: "ARUN PRAKASH PANDEY",
    saID: "SPR2209",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "PANDEY",
    address: "SATSANG*DEOGHAR",
    fullName: "GHANASHYAM PANDEY",
    saID: "SPR2210",
  },
  {
    firstName: "GURU KINKAR",
    lastName: "PANDEY",
    address: "*SATSANG*PAN BOAPP6815F",
    fullName: "GURU KINKAR PANDEY",
    saID: "SPR2211",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "PANDEY",
    address: "BRAHMANGRAM*PO-NAYANSUKH*DIST-MURSHIDABAD.",
    fullName: "LATE NAGENDRA NATH PANDEY",
    saID: "SPR2212",
  },
  {
    firstName: "SHIO PRASAD",
    lastName: "PANDEY",
    address: "S.I. OF POLICE*VILL/PO-SALUK CHAPRA*DHANBAD 828 204",
    fullName: "SHIO PRASAD PANDEY",
    saID: "SPR2213",
  },
  {
    firstName: "SHYAMAPADA",
    lastName: "PANDEY",
    address: "SATSANG SWASTI SIBIR*SEKENDARPUR*PO-AMRITY*MALDA-732208",
    fullName: "SHYAMAPADA PANDEY",
    saID: "SPR2214",
  },
  {
    firstName: "LATE SURESH",
    lastName: "PANDEY",
    address: "BISHNUPUR*SHILLONG-4*MEGHALAYA",
    fullName: "LATE SURESH PANDEY",
    saID: "SPR2215",
  },
  {
    firstName: "THAKURDAYAL",
    lastName: "PANDEY",
    address: "16/28,JOYDEB ROAD*DURGAPUR-5*DIST BARDDHAMAN-713205",
    fullName: "THAKURDAYAL PANDEY",
    saID: "SPR2216",
  },
  {
    firstName: "DIGENDRA",
    lastName: "PANDIT",
    address: "SATSANG KENDRA*PO-MATHABHANGA*COOCH BEHAR 736146",
    fullName: "DIGENDRA PANDIT",
    saID: "SPR2217",
  },
  {
    firstName: "LATE JYOTILAL",
    lastName: "PANDIT",
    address: "V/PO MEJIA*JAMTARA",
    fullName: "LATE JYOTILAL PANDIT",
    saID: "SPR2218",
  },
  {
    firstName: "BISHNU CHARAN",
    lastName: "PANI",
    address: "AT-SANTARA*PO-KUJANG*JAGATSINGHPUR 754141",
    fullName: "BISHNU CHARAN PANI",
    saID: "SPR2219",
  },
  {
    firstName: "KAMINI KANTA",
    lastName: "PANI",
    address: "AT-KANTHISAHI*PO-BACHHIPUR*VIA-BRAHMANGAN*BHADRAK 756 165",
    fullName: "KAMINI KANTA PANI",
    saID: "SPR2220",
  },
  {
    firstName: "BIDYADHAR",
    lastName: "PANIGRAHI",
    address: "AT/PO.BHAPUR*DHENKANAL-759015",
    fullName: "BIDYADHAR PANIGRAHI",
    saID: "SPR2221",
  },
  {
    firstName: "LATE KANAILAL",
    lastName: "PANJA",
    address: "20/1A,BABURAM SEAL LANE*CALCUTTA 700 012",
    fullName: "LATE KANAILAL PANJA",
    saID: "SPR2222",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "PANJA",
    address: "AT/PO-RAGHUNATHGONJ*DIST-MURSHIDABAD.",
    fullName: "SASANKA SEKHAR PANJA",
    saID: "SPR2223",
  },
  {
    firstName: "TARA PADA",
    lastName: "PANJA",
    address: "BARDA GANGADHAR HIGH SCHOOL*PO-BARDA*HOWRAH",
    fullName: "TARA PADA PANJA",
    saID: "SPR2224",
  },
  {
    firstName: "LATE THAKUR PRASAD",
    lastName: "PANJIAR",
    address: "NAYATOLA*PO/DT-KATIHAR",
    fullName: "LATE THAKUR PRASAD PANJIAR",
    saID: "SPR2225",
  },
  {
    firstName: "GOPI KRISHNA",
    lastName: "PARAJULI",
    address: "TIPONG COLLIERY HOSPITAL*PO-TIPONG*DIST-DIBRUGARH-786179*ASSAM",
    fullName: "GOPI KRISHNA PARAJULI",
    saID: "SPR2226",
  },
  {
    firstName: "RATAN",
    lastName: "PARAMANIK",
    address: "VILL-GOURIPUR*PO-AMRITI*DIST-MALDA-732208",
    fullName: "RATAN PARAMANIK",
    saID: "SPR2227",
  },
  {
    firstName: "LATE AMULYA BHUSHAN",
    lastName: "PARBAT",
    address: "1,PANDIT MOTILAL COLONY*PLOT NO-60,DUM DUM*CALCUTTA-28",
    fullName: "LATE AMULYA BHUSHAN PARBAT",
    saID: "SPR2228",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "PARBAT",
    address: "SATSANG-814 116*DEOGHAR*JHARKHAND*PAN-AKOPP7537K",
    fullName: "SAMIR KUMAR PARBAT",
    saID: "SPR2229",
  },
  {
    firstName: "SUSHIL KUMAR",
    lastName: "PARBAT",
    address: "24/60 JESSORE ROAD*KOLKATA-700 028*PAN-DDFPP6581J",
    fullName: "SUSHIL KUMAR PARBAT",
    saID: "SPR2230",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "PARHI",
    address: "AT-UTTARESWAR*PO-SORO*BALASORE-756045.*PAN-ABMPP6057F",
    fullName: "PURNA CHANDRA PARHI",
    saID: "SPR2231",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "PARIDA",
    address: "VILL-OUPARA*PO-BAINSIRIA*JAJPUR-755021.",
    fullName: "LATE CHITTARANJAN PARIDA",
    saID: "SPR2232",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "PARIDA",
    address: "AT/PO-ANANDAPUR*DIST-KEONJHAR-758021",
    fullName: "CHITTARANJAN PARIDA",
    saID: "SPR2233",
  },
  {
    firstName: "LATE GOBARDHAN",
    lastName: "PARIDA",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "LATE GOBARDHAN PARIDA",
    saID: "SPR2234",
  },
  {
    firstName: "GOPINATH",
    lastName: "PARIDA",
    address:
      "RETD.DIRECTOR,HORTICULTURE*PLOT NO-1761,NAYAPALLY*BHUBANESWAR-751012*PAN-AHZPP2233Q",
    fullName: "GOPINATH PARIDA",
    saID: "SPR2235",
  },
  {
    firstName: "LATE HARADHAN",
    lastName: "PARUI",
    address: "9/1 A.C.PAL STREET*ARIADAHA*CALCUTTA-57",
    fullName: "LATE HARADHAN PARUI",
    saID: "SPR2236",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "PARUI",
    address: "SATSANG MANDIR*AT/PO-UDAYNARAYANPUR*DIST-HOWRAH-711226",
    fullName: "KARTICK CHANDRA PARUI",
    saID: "SPR2237",
  },
  {
    firstName: "CHENISWAR",
    lastName: "PATAR",
    address: "VILL-CHARAI HAGI*PO-BARCHALA*VIA-RAHA*DT-MARIGAON-782103*ASSAM",
    fullName: "CHENISWAR PATAR",
    saID: "SPR2238",
  },
  {
    firstName: "LATE SURESH",
    lastName: "PATEL",
    address: "STATE DISP.MOTIPUR*MOTIPUR*MUZAFFARPUR-843111",
    fullName: "LATE SURESH PATEL",
    saID: "SPR2239",
  },
  {
    firstName: "SAMBHU NATH",
    lastName: "PATHAK",
    address: "VILL-RAIPUR*PO-MANKAR*DT-BURDWAN.",
    fullName: "SAMBHU NATH PATHAK",
    saID: "SPR2240",
  },
  {
    firstName: "ACHYUTANANDA",
    lastName: "PATI",
    address: '"SATSANG VIHAR JAJPUR"*PO-DEVIDWAR*JAJPUR 755007*ORISSA',
    fullName: "ACHYUTANANDA PATI",
    saID: "SPR2241",
  },
  {
    firstName: "PITAMBAR",
    lastName: "PATI",
    address:
      "AT.UTEIPUR*PO.DEHURI ANANDAPUR*VIA.DASARATHPUR*JAJPUR-755006*ODISHA",
    fullName: "PITAMBAR PATI",
    saID: "SPR2242",
  },
  {
    firstName: "MADHU SUDAN",
    lastName: "PATJOSHI",
    address: "VILL/PO-KUMBHARBANDH*VIA-B.B.COLONY*JHARSUGUDA 768211*ORISSA",
    fullName: "MADHU SUDAN PATJOSHI",
    saID: "SPR2243",
  },
  {
    firstName: "KISHORIMOHAN",
    lastName: "PATNAIK",
    address: "PLOT NO-298,GANGA NAGAR CHHAK*UNIT-6*BHUBANESWAR 751 001*KHURDA",
    fullName: "KISHORIMOHAN PATNAIK",
    saID: "SPR2244",
  },
  {
    firstName: "DAYA NARAYAN",
    lastName: "PATOWARY",
    address: "AT/PO ARANGAMOW*P.S.KAMARKUCHI*DIST KAMRUP",
    fullName: "DAYA NARAYAN PATOWARY",
    saID: "SPR2245",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "PATOWARY",
    address: "SATSANG VIHAR GUWAHATI*GUWAHATI-781 005*KAMRUP*ASSAM",
    fullName: "HEMANTA KUMAR PATOWARY",
    saID: "SPR2246",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "PATOWARY",
    address: "VILL-KATHARA*PO-DAKSHINGAON*VIA-SONAKUCHI*NALBARI",
    fullName: "RAMESH CHANDRA PATOWARY",
    saID: "SPR2247",
  },
  {
    firstName: "UMAKANTA",
    lastName: "PATOWARY",
    address: "VILL-KATHORA*PO-DAKSHINGAON*KAMRUP .",
    fullName: "UMAKANTA PATOWARY",
    saID: "SPR2248",
  },
  {
    firstName: "ARABINDA",
    lastName: "PATRA",
    address: "VIL&PO.TARAKNAGAR*NADIA",
    fullName: "ARABINDA PATRA",
    saID: "SPR2249",
  },
  {
    firstName: "B NRUSINGHA NATH",
    lastName: "PATRO",
    address:
      "LB-205,BHIMTANGI*HOUSING BOARD COLONY*BHUBANESWAR-751002*PAN-AAPPP6626E",
    fullName: "B NRUSINGHA NATH PATRO",
    saID: "SPR2250",
  },
  {
    firstName: "BHARAT CHANDRA",
    lastName: "PATRA",
    address: "V-DHITAIBASAN*PO-NANDAKUMAR*DIST-PURBA MEDINIPUR-721632",
    fullName: "BHARAT CHANDRA PATRA",
    saID: "SPR2251",
  },
  {
    firstName: "LATE BIRENDRA KUMAR",
    lastName: "PATRA",
    address: "VILL-MIRZAPUR*PO-KAJLAGARH*DIST-MIDNAPUR-721626",
    fullName: "LATE BIRENDRA KUMAR PATRA",
    saID: "SPR2252",
  },
  {
    firstName: "CHINMAY",
    lastName: "PATRA",
    address: "5 NO DAKSHINPARA ROAD*THAKURPUKUR*CALCUTTA-63.",
    fullName: "CHINMAY PATRA",
    saID: "SPR2253",
  },
  {
    firstName: "GANDHARBASEN",
    lastName: "PATRA",
    address: "VILL-WASADIHA*PO-NADIALI*VIA-GOVINDAPUR*DHENKANAL-759027",
    fullName: "GANDHARBASEN PATRA",
    saID: "SPR2254",
  },
  {
    firstName: "LATE GOBARDHAN",
    lastName: "PATRA",
    address: "1 NO DEWANMARO*PO-NIMPURA*DIST-MIDNAPUR",
    fullName: "LATE GOBARDHAN PATRA",
    saID: "SPR2255",
  },
  {
    firstName: "CH JAYPRAKASH",
    lastName: "PATRA",
    address:
      "C/O GRANTHI DHRUTI*ASKA ROAD*BERHAMPUR*GANJAM-760001*PAN AELPP7695H",
    fullName: "CH JAYPRAKASH PATRA",
    saID: "SPR2256",
  },
  {
    firstName: "PADMA CHARAN",
    lastName: "PATRA",
    address: '"DAYAL NIWAS"*AT-GANDARPUR*PO-COLLEGE SQUARE*DT-CUTTACK-753003.',
    fullName: "PADMA CHARAN PATRA",
    saID: "SPR2257",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "PATRA",
    address: "BHATHANDIA*PO-JANAGHATI*DIST-PASCHIM MEDINIPUR-721506",
    fullName: "SISIR KUMAR PATRA",
    saID: "SPR2258",
  },
  {
    firstName: "SUSHEN",
    lastName: "PATRA",
    address: "BHATHANDIA*PO-JANAGHATI*PASCHIM MEDINIPUR-721506*PAN-AMTPP6619F",
    fullName: "SUSHEN PATRA",
    saID: "SPR2259",
  },
  {
    firstName: "LATE UPENDRA PRASAD",
    lastName: "PATRA",
    address: "AT/PO.SARTHA-756070*BALASORE.",
    fullName: "LATE UPENDRA PRASAD PATRA",
    saID: "SPR2260",
  },
  {
    firstName: "CHANDRA SEKHAR",
    lastName: "PATTANAYAK",
    address: "AT-JAGANNATHPADA*PO-KESINGA*KALAHANDI-766012",
    fullName: "CHANDRA SEKHAR PATTANAYAK",
    saID: "SPR2261",
  },
  {
    firstName: "LATE JALADHAR",
    lastName: "PATTANAYAK",
    address: "B.N.HIGH SCHOOL*AT/PO.MANTRI*MAYURBHANJ.",
    fullName: "LATE JALADHAR PATTANAYAK",
    saID: "SPR2262",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "PATTANAYAK",
    address: "RATNALI*PO-RADHABALLAVPUR*PURBA MEDINIPUR-721627",
    fullName: "JITENDRA NATH PATTANAYAK",
    saID: "SPR2263",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "PATTANAYAK",
    address: "VILL-CHANDIPUR*PO-CHHATRAPARA*VIA-PRITIPUR*JAJPUR-755013",
    fullName: "NARAYAN CHANDRA PATTANAYAK",
    saID: "SPR2264",
  },
  {
    firstName: "SOMEN",
    lastName: "HALDAR",
    address: "M V 7 MALKANGIRI*PO-TAMSA*DIST-MALKANGIRI 764045*PAN AMEPH1708E",
    fullName: "SOMEN HALDAR",
    saID: "SPR2265",
  },
  {
    firstName: "ROHINI NANDAN",
    lastName: "PATTANAYAK",
    address:
      "REMUNA SATSANG CENTER*PO-REMUNA*DIST-BALASORE-756019*PAN-AJYPP5086E",
    fullName: "ROHINI NANDAN PATTANAYAK",
    saID: "SPR2266",
  },
  {
    firstName: "SANKARSAN",
    lastName: "PATTANAYAK",
    address: "AT-DHIMPURA*PO-OUPADA*DIST-BALASORE-756049",
    fullName: "SANKARSAN PATTANAYAK",
    saID: "SPR2267",
  },
  {
    firstName: "AMIYA KUMAR",
    lastName: "PAUL",
    address: "VILL-NANDUR*PO-JOTERAM*BURDWAN-713104*PAN NO.AXHPP6948E",
    fullName: "AMIYA KUMAR PAUL",
    saID: "SPR2268",
  },
  {
    firstName: "LATE ANIL KRISHNA",
    lastName: "PAUL",
    address: "39 NO OLD NIMTA ROAD*RANI PARK*CALCUTTA-56",
    fullName: "LATE ANIL KRISHNA PAUL",
    saID: "SPR2269",
  },
  {
    firstName: "LATE BASUDEB",
    lastName: "PAUL",
    address: "PO+VILL-DAKSHIN ROYPUR*VIA-BUDGE BUDGE*DIST-24 PGS.",
    fullName: "LATE BASUDEB PAUL",
    saID: "SPR2270",
  },
  {
    firstName: "LATE BHUBAN MOHAN",
    lastName: "PAUL",
    address: "C T I BULIDINGS*59/60 BAGHMARI ROAD*CALCUTTA 54",
    fullName: "LATE BHUBAN MOHAN PAUL",
    saID: "SPR2271",
  },
  {
    firstName: "BINAY KUMAR",
    lastName: "PAUL",
    address: "COLLEGEPARA*PO-KATWA*DIST-BURDWAN-713130",
    fullName: "BINAY KUMAR PAUL",
    saID: "SPR2272",
  },
  {
    firstName: "BINOD CHANDRA",
    lastName: "PAUL",
    address:
      "AT-NORTH INDRANAGAR*(S D O CHOWMOHANI)*PO-BANKUMARI*VIA-KUNJABAN*TRIPURA (W) 799006",
    fullName: "BINOD CHANDRA PAUL",
    saID: "SPR2273",
  },
  {
    firstName: "LATE BINOY KRISHNA",
    lastName: "PAUL",
    address: "MEMARY SATSANG KENDRA*PO MEMARI CHECK POST*DIST BURDWAN",
    fullName: "LATE BINOY KRISHNA PAUL",
    saID: "SPR2274",
  },
  {
    firstName: "LATE BIRENDRA KISHORE",
    lastName: "PAUL",
    address: "SUB-DIVISION OFFICE*PO-SANTIRBAJAR*TRIPURA SOUTH",
    fullName: "LATE BIRENDRA KISHORE PAUL",
    saID: "SPR2275",
  },
  {
    firstName: "LATE CHANDRA SEKHAR",
    lastName: "PAUL",
    address: "MIR BAZAR*PO-MIDNAPUR*DIST-PASCHIM MEDINIPUR-721101.",
    fullName: "LATE CHANDRA SEKHAR PAUL",
    saID: "SPR2276",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "PAUL",
    address: "PASCHIM MAYAPUR ROAD 3RD LANE*PO-NABADWIP*NADIA.",
    fullName: "LATE DEBENDRA NATH PAUL",
    saID: "SPR2277",
  },
  {
    firstName: "LATE DEB NARAYAN",
    lastName: "PAUL",
    address: "KANSARI PARA*PO-KALNA*BURDWAN 713409",
    fullName: "LATE DEB NARAYAN PAUL",
    saID: "SPR2278",
  },
  {
    firstName: "LATE DHANANJOY",
    lastName: "PAUL",
    address: "MANOMOHINI PALLY*PO-KATWA*DIST-BURDWAN",
    fullName: "LATE DHANANJOY PAUL",
    saID: "SPR2279",
  },
  {
    firstName: "LATE DHIRENDRA CHANDRA",
    lastName: "PAUL",
    address: "76 BANDHABNAGAR*PO-BELGHARIA*CALCUTTA-700 056",
    fullName: "LATE DHIRENDRA CHANDRA PAUL",
    saID: "SPR2280",
  },
  {
    firstName: "DINESH CHANDRA",
    lastName: "PAUL",
    address: "NEAR CINEMA HALL,AMARPUR*PO-AMARPUR*TRIPURA (SOUTH)",
    fullName: "DINESH CHANDRA PAUL",
    saID: "SPR2281",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "PAUL",
    address:
      "RAMKRISHNA ASHRAM ROAD*AT&PO-PANIHATI (PAUL PARA)*DIST-24 PGS NORTH-700114",
    fullName: "GOBINDA CHANDRA PAUL",
    saID: "SPR2282",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "PAUL",
    address: "50 NO EAST KAMALAPUR*DUM DUM*CALCUTTA-28.",
    fullName: "LATE GOPAL CHANDRA PAUL",
    saID: "SPR2283",
  },
  {
    firstName: "GOURANGA CHANDRA",
    lastName: "PAUL",
    address: "VILL&PO-LAHARIGHAT*DT-NOWGANG.",
    fullName: "GOURANGA CHANDRA PAUL",
    saID: "SPR2284",
  },
  {
    firstName: "LATE GOURANGA CHANDRA",
    lastName: "PAUL",
    address: "*SATSANG 814116.",
    fullName: "LATE GOURANGA CHANDRA PAUL",
    saID: "SPR2285",
  },
  {
    firstName: "LATE GURUPADA",
    lastName: "PAUL",
    address: "SATSANG ROAD,(NEAR SATSANG VIHAR)*PO-KARIMGANJ*KARIMGANJ 788 712",
    fullName: "LATE GURUPADA PAUL",
    saID: "SPR2286",
  },
  {
    firstName: "LATE HARE KRISHNA",
    lastName: "PAUL",
    address: "NO-2, JYOTINAGAR*AT/PO-SONAPUR*DIST-KAMRUP",
    fullName: "LATE HARE KRISHNA PAUL",
    saID: "SPR2287",
  },
  {
    firstName: "HARI PRASSANA",
    lastName: "PAUL",
    address:
      "VILL-A D NAGAR-15*PO-S.D.MISSION*A.D.NAGAR*AGARTALA*(TRIPURA)-799003",
    fullName: "HARI PRASSANA PAUL",
    saID: "SPR2288",
  },
  {
    firstName: "LATE HIRANMOY",
    lastName: "PAUL",
    address: "SADARGHAT ROAD*PO/DIST-SILCHAR-1*CACHAR*ASSAM",
    fullName: "LATE HIRANMOY PAUL",
    saID: "SPR2289",
  },
  {
    firstName: "LATE HRISHI SEKHAR",
    lastName: "PAUL",
    address: "C/O SWASTI MEDICAL STORES*SADARGHAT ROAD*PO SILCHAR-1*CACHAR.",
    fullName: "LATE HRISHI SEKHAR PAUL",
    saID: "SPR2290",
  },
  {
    firstName: "LATE HRISHIKESH",
    lastName: "PAUL",
    address: "VILL/PO-NORTH DURGAPUR*DT-HOWRAH.",
    fullName: "LATE HRISHIKESH PAUL",
    saID: "SPR2291",
  },
  {
    firstName: "JADUNANDAN",
    lastName: "PAUL",
    address: "M.PHARMACY*PO-ORANG*DARRANG.",
    fullName: "JADUNANDAN PAUL",
    saID: "SPR2292",
  },
  {
    firstName: "JAGANNATH",
    lastName: "PAUL",
    address:
      "2/13,DAVID HARE AVENUE*DURGAPUR-713205*DIST-BARDDHAMAN*PAN-AFTPP6598N",
    fullName: "JAGANNATH PAUL",
    saID: "SPR2293",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "PAUL",
    address: "AT-AYMA GAJANKOL*PO-GOHALBERIA*DT-HOWRAH 711315",
    fullName: "LATE JATINDRA NATH PAUL",
    saID: "SPR2294",
  },
  {
    firstName: "LATE JIBAN KRISHNA",
    lastName: "PAUL",
    address: "AT-KESHABCHAK*PO-TARAKESWAR*DIST-HOOGHLY",
    fullName: "LATE JIBAN KRISHNA PAUL",
    saID: "SPR2295",
  },
  {
    firstName: "JOYDEB",
    lastName: "PAUL",
    address: '"SRIDHRITI"*VIVEKANANDA NAGAR*PO-PANDUA*DIST-HOOGHLY',
    fullName: "JOYDEB PAUL",
    saID: "SPR2296",
  },
  {
    firstName: "LATE KARTICK CHANDRA",
    lastName: "PAUL",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE KARTICK CHANDRA PAUL",
    saID: "SPR2297",
  },
  {
    firstName: "LATE KETAKI RANJAN",
    lastName: "PAUL",
    address: "OFFICE TILLA*DHARMANAGAR*TRIPURA/*TRIPURA",
    fullName: "LATE KETAKI RANJAN PAUL",
    saID: "SPR2298",
  },
  {
    firstName: "KRISHNA PRASANNA",
    lastName: "PAUL",
    address: "RAHAMAN PATTY*PO-SILCHAR-788001.",
    fullName: "KRISHNA PRASANNA PAUL",
    saID: "SPR2299",
  },
  {
    firstName: "LATE KSHIRODE RANJAN",
    lastName: "PAUL",
    address: "PO+VILL-PANIBHORA*DIST-CACHAR. 788 123",
    fullName: "LATE KSHIRODE RANJAN PAUL",
    saID: "SPR2300",
  },
  {
    firstName: "LATE KUMAR NARAYAN",
    lastName: "PAUL",
    address: "CHAMPATALA*PO-SAPKHALI*VIA-RUDRANAGAR*DIST-24-PGS",
    fullName: "LATE KUMAR NARAYAN PAUL",
    saID: "SPR2301",
  },
  {
    firstName: "LALIT MOHAN",
    lastName: "PAUL",
    address: "404/28,N C BANERJEE ROAD*PO.BAIDYABATI*DIST.HOOGHLY-712222",
    fullName: "LALIT MOHAN PAUL",
    saID: "SPR2302",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "PAUL",
    address: "AT/PO-CHATRA*BIRBHUM",
    fullName: "LATE MADAN MOHAN PAUL",
    saID: "SPR2303",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "PAUL",
    address: "AT-BALARAMPUR*PO-BELIATORE*DIST BANKURA",
    fullName: "LATE MADAN MOHAN PAUL",
    saID: "SPR2304",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "PAUL",
    address: "AT SINGIMARI*PO BARAPATHAR*SIBSAGAR",
    fullName: "LATE MANORANJAN PAUL",
    saID: "SPR2305",
  },
  {
    firstName: "LATE MIHIR KANTI",
    lastName: "PAUL",
    address:
      "GENERAL HOSPITAL*PASIGHAT-791102*DIST-EAST SIANG*ARUNACHAL PRADESH",
    fullName: "LATE MIHIR KANTI PAUL",
    saID: "SPR2306",
  },
  {
    firstName: "MURALIMOHAN",
    lastName: "PAUL",
    address:
      "AT-KUSHPATA (VIVEKANANDAPALLY)*PO-GHATAL*DIST-PASCHIM MEDINIPUR-721212.",
    fullName: "MURALIMOHAN PAUL",
    saID: "SPR2307",
  },
  {
    firstName: "MURALIDHAR",
    lastName: "PAUL",
    address: "AT-BALARAMPUR*PO-BELIATORE*BANKURA-722203",
    fullName: "MURALIDHAR PAUL",
    saID: "SPR2308",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "PAUL",
    address: "O.T.COLONY,RLY QR NO-L/558B*PO/DT-KATIHAR",
    fullName: "LATE NARENDRA NATH PAUL",
    saID: "SPR2309",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "PAUL",
    address: "VILL&PO-SRIRAMPUR*GOALPARA.",
    fullName: "LATE NARESH CHANDRA PAUL",
    saID: "SPR2310",
  },
  {
    firstName: "LATE NEPAL CHANDRA",
    lastName: "PAUL",
    address: "58 SASHI BHUSAN DEY STREET*CALCUTTA 12",
    fullName: "LATE NEPAL CHANDRA PAUL",
    saID: "SPR2311",
  },
  {
    firstName: "NITAI CHANDRA",
    lastName: "PAL",
    address:
      "WEST LAKE TOWN*BACKSIDE OF GRAMIN BANK*PO-BHAKTINAGAR*SILIGURI*DARJEELING-734007",
    fullName: "NITAI CHANDRA PAL",
    saID: "SPR2312",
  },
  {
    firstName: "LATE NITAI CHANDRA",
    lastName: "PAUL",
    address: "PO-SOUTH SALMARA*DT-GOALPARA.",
    fullName: "LATE NITAI CHANDRA PAUL",
    saID: "SPR2313",
  },
  {
    firstName: "NITYANANDA",
    lastName: "PAUL",
    address: "VILL/PO-BHAKTINAGAR*JALPAIGURI",
    fullName: "NITYANANDA PAUL",
    saID: "SPR2314",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "PAUL",
    address: "SWASTI MEDICAL STORES*SADARGHAT ROAD*PO-SILCHAR 788001*CACHAR",
    fullName: "LATE NITYANANDA PAUL",
    saID: "SPR2315",
  },
  {
    firstName: "LATE PARAMESWAR",
    lastName: "PAUL",
    address: "PODDER'S BUILDING*57/1,KESHAB CH SEN STREET*CALCUTTA-700009",
    fullName: "LATE PARAMESWAR PAUL",
    saID: "SPR2316",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "PAUL",
    address: "BATASIVITA BEAT OFFICE*PO-SHIKARPUR*DT-JALPAIGURI.",
    fullName: "LATE PRAFULLA KUMAR PAUL",
    saID: "SPR2317",
  },
  {
    firstName: "LATE PROMODE BIHARI",
    lastName: "PAUL",
    address: "ICHLABAD*434,G.T.ROAD*PO-BURDWAN*BURDWAN",
    fullName: "LATE PROMODE BIHARI PAUL",
    saID: "SPR2318",
  },
  {
    firstName: "LATE PULIN BEHARI",
    lastName: "PAUL",
    address: "A.C.S.DUM DUM AIRPORT*CALCUTTA-700052.",
    fullName: "LATE PULIN BEHARI PAUL",
    saID: "SPR2319",
  },
  {
    firstName: "LATE PURNA CHANDRA",
    lastName: "PAUL",
    address: "COLLEGEPARA*PO-SILIGURI*DT-DARJEELING.",
    fullName: "LATE PURNA CHANDRA PAUL",
    saID: "SPR2320",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "PAUL",
    address: "PO KANCHRAPARA*24 PARAGANAS",
    fullName: "LATE RABINDRA NATH PAUL",
    saID: "SPR2321",
  },
  {
    firstName: "LATE RADHESHYAM",
    lastName: "PAUL",
    address: "KUSHPATA MONDIR*PO-GHATAL*DIST-MIDNAPUR",
    fullName: "LATE RADHESHYAM PAUL",
    saID: "SPR2322",
  },
  {
    firstName: "LATE RAMNATH",
    lastName: "PAUL",
    address: "2 NO MOTILAL COLONY*MAZUMDER PARA*CALCUTTA 700079",
    fullName: "LATE RAMNATH PAUL",
    saID: "SPR2323",
  },
  {
    firstName: "LATE RANJIT KUMAR",
    lastName: "PAL",
    address: "ANANDAMATH*PO-TURA*GARO HILLS",
    fullName: "LATE RANJIT KUMAR PAL",
    saID: "SPR2324",
  },
  {
    firstName: "LATE RASIK CHANDRA",
    lastName: "PAUL",
    address:
      "C/O SURENDRA CHANDRA DEY*QR.747(A)SOUTH HILLS COLONY*PO LUMDING*NOWGONG.",
    fullName: "LATE RASIK CHANDRA PAUL",
    saID: "SPR2325",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "PAUL",
    address: "SATSANG MONDIR*PO-KAKDWIP*DIST-24 PGS.",
    fullName: "LATE SATISH CHANDRA PAUL",
    saID: "SPR2326",
  },
  {
    firstName: "SATYA RANJAN",
    lastName: "PAUL",
    address:
      "PO+VILL-SRIKRISHNA NAGAR*DIST-24 PGS (SOUTH)-743372*PAN-AEMPP0412K",
    fullName: "SATYA RANJAN PAUL",
    saID: "SPR2327",
  },
  {
    firstName: "LATE SUBHENDU",
    lastName: "PAUL",
    address: "8/1A,CHAKRABERIA ROAD(SOUTH)*CALCUTTA-25",
    fullName: "LATE SUBHENDU PAUL",
    saID: "SPR2328",
  },
  {
    firstName: "SUKUMAR",
    lastName: "PAUL",
    address:
      "SATSANG THAKURBARI*MIRZABAZAR*PO-MEDINIPUR*DIST-PASCHIM MEDINIPUR*PAN-CSRPP4660G",
    fullName: "SUKUMAR PAUL",
    saID: "SPR2329",
  },
  {
    firstName: "SUNIL CHANDRA",
    lastName: "PAUL",
    address:
      "RLY QR NO-4/F 4,UNIT-2*OLD SETTLEMENT*PO-KHARAGPUR*W/MIDNAPUR*PAN-BTGPP0160C",
    fullName: "SUNIL CHANDRA PAUL",
    saID: "SPR2330",
  },
  {
    firstName: "LATE SURENDRA PRASAD",
    lastName: "PAUL",
    address: '"ISTAYAN"*KEONJHAR COLONY*KANIKA CHHAK*CUTTACK 753008',
    fullName: "LATE SURENDRA PRASAD PAUL",
    saID: "SPR2331",
  },
  {
    firstName: "TAMESH CHANDRA",
    lastName: "PAUL",
    address: "SATSANG VIHAR*KENCH'S STRECE*SHILLONG-793004*MEGHALAYA",
    fullName: "TAMESH CHANDRA PAUL",
    saID: "SPR2332",
  },
  {
    firstName: "LATE SUDHIR",
    lastName: "PALCHOUDHURI",
    address: "GANIALA*PO/DIST-SILCHAR-2*(ASSAM)",
    fullName: "LATE SUDHIR PALCHOUDHURI",
    saID: "SPR2333",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "PAYENG",
    address: "PANIGAON HIGH SCHOOL*PO-PANIGAON*DIST-LAKHIMPUR",
    fullName: "PRAFULLA KUMAR PAYENG",
    saID: "SPR2334",
  },
  {
    firstName: "LATE LOBURAM",
    lastName: "PEGU",
    address: "SATSANG VIHAR JORHAT*VILL-LAKHINAGAR*PO-LICHUBARI*JORHAT*ASSAM",
    fullName: "LATE LOBURAM PEGU",
    saID: "SPR2335",
  },
  {
    firstName: "MAHESWAR",
    lastName: "PEGU",
    address: "TARIANI BAGINADI*PO-BHIMPARA BALIJAN*DT-LAKHIMPUR.",
    fullName: "MAHESWAR PEGU",
    saID: "SPR2336",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "PHUKAN",
    address: "CHANDMARI GOLAGHAT*PO-GOLAGHAT*JORHAT 785621.",
    fullName: "LATE HARENDRA NATH PHUKAN",
    saID: "SPR2337",
  },
  {
    firstName: "LATE RATIKANTA",
    lastName: "PODDAR",
    address: "SHANTINAGAR*PO-NONACHANDANPUKUR*24 PGS.",
    fullName: "LATE RATIKANTA PODDAR",
    saID: "SPR2338",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "PODDER",
    address:
      "VILL-BHATENDA NEAR KALIBARI*PO&VILL-RAJARHAT*24PARGANAS(NORTH)-700135",
    fullName: "GIRIDHARI PODDER",
    saID: "SPR2339",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "PODDER",
    address: "C/O SANTOSH SEN*PO-BAGDAHA*DIST-24-PGS.",
    fullName: "LATE JITENDRA NATH PODDER",
    saID: "SPR2340",
  },
  {
    firstName: "LATE MAHADEV",
    lastName: "PODDER",
    address: "79,ROBERTSON ROAD*PO-NAIHATI-743165*24 PARGANAS",
    fullName: "LATE MAHADEV PODDER",
    saID: "SPR2341",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "PODDER",
    address: "CHHABI GHAR*NEW MARKET*PO-ANDAL*NORTH BAZAR*BURDWAN",
    fullName: "LATE NITYANANDA PODDER",
    saID: "SPR2342",
  },
  {
    firstName: "LATE SUDHANGSHU KUMAR",
    lastName: "PODDER",
    address: "AT+PO-ARAIDANGA*DIST-MALDA",
    fullName: "LATE SUDHANGSHU KUMAR PODDER",
    saID: "SPR2343",
  },
  {
    firstName: "DUDH KUMAR",
    lastName: "POLLEY",
    address: "VILL/PO-KULDANGA*PO-ANDULMOURI*DT-HOWRAH-711302",
    fullName: "DUDH KUMAR POLLEY",
    saID: "SPR2344",
  },
  {
    firstName: "LATE GOBARDHAN",
    lastName: "POREL",
    address: "AT-BORA*PO-BUJRUKDIGHI*DIST-BURDWAN.",
    fullName: "LATE GOBARDHAN POREL",
    saID: "SPR2345",
  },
  {
    firstName: "LATE ABHIMANYU",
    lastName: "PRADHAN",
    address: "AT/PO-KARAPADA*VIA-BAMAKOYI*GANJAM",
    fullName: "LATE ABHIMANYU PRADHAN",
    saID: "SPR2346",
  },
  {
    firstName: "AKADASHI",
    lastName: "PRADHAN",
    address: "AT/PO.GOVINDPUR-770051*SUNDARGARH.",
    fullName: "AKADASHI PRADHAN",
    saID: "SPR2347",
  },
  {
    firstName: "ANANDA CHANDRA",
    lastName: "PRADHAN",
    address: "SATSANG VIHAR*PO-PURUNAGARH*DIST-DEOGARH 768108*PAN-COAPP7542",
    fullName: "ANANDA CHANDRA PRADHAN",
    saID: "SPR2348",
  },
  {
    firstName: "APURBA KUMAR",
    lastName: "PRADHAN",
    address:
      "AT/PO-SABRA*VIA-KHAKURDA*DIST-PASCHIM MEDINIPUR-721445*PAN AFCPP8871K",
    fullName: "APURBA KUMAR PRADHAN",
    saID: "SPR2349",
  },
  {
    firstName: "LATE DURYODHAN",
    lastName: "PRADHAN",
    address: "BONAI SATSANG KENDRA*AT/PO-BONAI*SUNDARGARH 770038",
    fullName: "LATE DURYODHAN PRADHAN",
    saID: "SPR2350",
  },
  {
    firstName: "JAGADISWAR",
    lastName: "PRADHAN",
    address: "SATSANG VIHAR*AT/PO-PHULBANI*DIST-KANDHAMAL-762001",
    fullName: "JAGADISWAR PRADHAN",
    saID: "SPR2351",
  },
  {
    firstName: "KIRTAN CHANDRA",
    lastName: "PRADHAN",
    address: "AT-MOTANGA*PO-MERAMANDALI*DHENKANAL-759121.",
    fullName: "KIRTAN CHANDRA PRADHAN",
    saID: "SPR2352",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "PRADHAN",
    address: "VILL/PO BIRAJANAGAR*DIST-24 PARAGANAS",
    fullName: "LATE MANINDRA NATH PRADHAN",
    saID: "SPR2353",
  },
  {
    firstName: "NIRANJAN",
    lastName: "PRADHAN",
    address: "AT.JASHAPUR*PO.GODA*VIA.BORIKINA*JAGATSINGHPUR-754110",
    fullName: "NIRANJAN PRADHAN",
    saID: "SPR2354",
  },
  {
    firstName: "LATE PRAVASH CHANDRA",
    lastName: "PRADHAN",
    address: "VILL-SUTAHATA*PO-DEBHOG*DT-MIDNAPORE.",
    fullName: "LATE PRAVASH CHANDRA PRADHAN",
    saID: "SPR2355",
  },
  {
    firstName: "LATE PURNA CHANDRA",
    lastName: "PRADHAN",
    address: "PO+VILL-SAGUNA*DIST-24 PGS.",
    fullName: "LATE PURNA CHANDRA PRADHAN",
    saID: "SPR2356",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "PRADHAN",
    address:
      '"DIPALOKA"*AT-JANARDANPUR*PO.LENDURA*VIA.KENDUPATNA*CUTTACK-754203',
    fullName: "RABINDRA NATH PRADHAN",
    saID: "SPR2357",
  },
  {
    firstName: "LATE SANTARAM BALKRISHNA",
    lastName: "PRADHAN",
    address: "SREERANGA SOCIETY.C-1-11/6*DIST-THANE*M.S.",
    fullName: "LATE SANTARAM BALKRISHNA PRADHAN",
    saID: "SPR2358",
  },
  {
    firstName: "LATE SASANKA SEKHAR",
    lastName: "PRADHAN",
    address: "*HOOGHLY.",
    fullName: "LATE SASANKA SEKHAR PRADHAN",
    saID: "SPR2359",
  },
  {
    firstName: "LATE SATYABRATA",
    lastName: "PRADHAN",
    address:
      "JR SOIL CONSERVATION OFFICER*AT-SAMANTSAHI,CANEL ROAD*PO/DIST-CUTTACK-1.",
    fullName: "LATE SATYABRATA PRADHAN",
    saID: "SPR2360",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "PRAMANIK",
    address: "VILL/PO-NANDAKUMARPUR*24PARGANAS",
    fullName: "LATE DHIRENDRA NATH PRAMANIK",
    saID: "SPR2361",
  },
  {
    firstName: "LATE HARISADHAN",
    lastName: "PRAMANIK",
    address: "AT/PO-SRIKRISHNANAGAR*VIA-DAKSHIN BARASAT*24PARGANAS",
    fullName: "LATE HARISADHAN PRAMANIK",
    saID: "SPR2362",
  },
  {
    firstName: "BIRCHAND",
    lastName: "PRASAD",
    address:
      "259,SRIKRISHNANAGAR*PO-PRABHASHNAGAR*RISHRA-712249*DIST.HOOGHLY*PAN-AFGPP6266A",
    fullName: "BIRCHAND PRASAD",
    saID: "SPR2363",
  },
  {
    firstName: "LATE BIRENDRA",
    lastName: "PRASAD",
    address: "VILL-RAJPUR TOLA*PO-SUPI*VIA-BARHARWA*SITAMARHI 843315",
    fullName: "LATE BIRENDRA PRASAD",
    saID: "SPR2364",
  },
  {
    firstName: "DASHRATH",
    lastName: "PRASAD",
    address: "TRIPURARI COLONY*MAJHI TOALA*ADITYAPUR-832 109*DIST-SERAIKELA",
    fullName: "DASHRATH PRASAD",
    saID: "SPR2365",
  },
  {
    firstName: "LATE DHIRENDRA MOHAN",
    lastName: "PRASAD",
    address: "2A/40 KANKE ROAD*RANCHI 8",
    fullName: "LATE DHIRENDRA MOHAN PRASAD",
    saID: "SPR2366",
  },
  {
    firstName: "LATE HARINANDAN",
    lastName: "PRASAD",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE HARINANDAN PRASAD",
    saID: "SPR2367",
  },
  {
    firstName: "YOGENDRA",
    lastName: "PRASAD",
    address: "SRIVASTAV BHAWAN*BENTA CHOWK*PO-D M C *DARBHANGA-846003",
    fullName: "YOGENDRA PRASAD",
    saID: "SPR2368",
  },
  {
    firstName: "LATE JUGAL KISHORE",
    lastName: "PRASAD",
    address: "ASTT.R.R.BRANCH*P.O.TURA*GARO HILLS",
    fullName: "LATE JUGAL KISHORE PRASAD",
    saID: "SPR2369",
  },
  {
    firstName: "LATE JUGAL KISHORE",
    lastName: "PRASAD",
    address: "FIRST CLASS BOOKING OFFICE*PO/DT-SAMASTIPUR",
    fullName: "LATE JUGAL KISHORE PRASAD",
    saID: "SPR2370",
  },
  {
    firstName: "LATE MAHADEO",
    lastName: "PRASAD",
    address: "VILL-GAIGHAT*PO-THUMA*VIA-RUNNISAIDPUR*DIST-SITAMARHI",
    fullName: "LATE MAHADEO PRASAD",
    saID: "SPR2371",
  },
  {
    firstName: "NARAYAN",
    lastName: "PRASAD",
    address:
      "SATSANG VIHAR*SATSANG NAGAR*PO-DANAPUR*PATNA-801503*PAN-BXTPP3892G",
    fullName: "NARAYAN PRASAD",
    saID: "SPR2372",
  },
  {
    firstName: "RAGHO",
    lastName: "PRASAD",
    address:
      "VILL-MUZAFFARPUR*PO-KATHAULI*VIA-NURSARAI*DIST-NALANDA-803113*PAN-DANPP5245J",
    fullName: "RAGHO PRASAD",
    saID: "SPR2373",
  },
  {
    firstName: "LATE RAMNANDAN",
    lastName: "PRASAD",
    address: "*SATSANG 814116",
    fullName: "LATE RAMNANDAN PRASAD",
    saID: "SPR2374",
  },
  {
    firstName: "LATE SHAMBHU SHARAN",
    lastName: "PRASAD",
    address:
      "C/O BHASKAR SHARAN*NEAR RAJKIYA DIMNA M VIDYALAYA*PO-M G MEDICAL COLLEGE*JAMSHEDPUR",
    fullName: "LATE SHAMBHU SHARAN PRASAD",
    saID: "SPR2375",
  },
  {
    firstName: "SHIVANAND",
    lastName: "PRASAD",
    address: "SATSANG COMPUTER CENTER*SATSANG 814116*PAN NO-CJNPP2118N.",
    fullName: "SHIVANAND PRASAD",
    saID: "SPR2376",
  },
  {
    firstName: "LATE TAPESHWAR",
    lastName: "PRASAD",
    address: "RETIRED DIST SUB REGISTRAR*VILL-BAGMALI*PO-HAJIPUR*VAISHALI",
    fullName: "LATE TAPESHWAR PRASAD",
    saID: "SPR2377",
  },
  {
    firstName: "KANHU CHARAN",
    lastName: "PRADHAN",
    address: "AT/PO-O.S.E.B. BISRA*SUNDARGARH-770036*ODISHA",
    fullName: "KANHU CHARAN PRADHAN",
    saID: "SPR2378",
  },
  {
    firstName: "LATE LAXMAN",
    lastName: "PRADHAN",
    address:
      "QR NO 20,TYPE IV*AGRICULTURE COLONY*PO-BARAMUNDA*BHUBANESWAR 751003.",
    fullName: "LATE LAXMAN PRADHAN",
    saID: "SPR2379",
  },
  {
    firstName: "NAROTTAM",
    lastName: "PRADHAN",
    address: "AT/PO-PALLAHARA*DIST-ANGUL-759119*ODISHA",
    fullName: "NAROTTAM PRADHAN",
    saID: "SPR2380",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "PRODHAN",
    address: "VILL-SARBA*PO.ERSAMA*VIA.JIRAILO*CUTTACK.",
    fullName: "LATE PARESH CHANDRA PRODHAN",
    saID: "SPR2381",
  },
  {
    firstName: "SUBASH CHANDRA",
    lastName: "PRADHAN",
    address: "AT-LINGIPUR*PO-SISUPALGARH*BHUBANESWAR-751002*KHURDA",
    fullName: "SUBASH CHANDRA PRADHAN",
    saID: "SPR2382",
  },
  {
    firstName: "LATE RAMESHWAR",
    lastName: "PRUSHTY",
    address: "AT-JAGANNATHPUR*PO-BAGBERA*SINGHBHUM",
    fullName: "LATE RAMESHWAR PRUSHTY",
    saID: "SPR2383",
  },
  {
    firstName: "SUDHANGSHU",
    lastName: "PUSTI",
    address: "68,SURYA SEN STREET*CALCUTTA 700 009*PAN-BGOPP9829B",
    fullName: "SUDHANGSHU PUSTI",
    saID: "SPR2384",
  },
  {
    firstName: "GOURANGA CHANDRA",
    lastName: "PRUSTY",
    address: "AT-JANUGANJ*PO-MADHABNAGAR*DIST-BHADRAK-756181*PAN-APJPP7703H",
    fullName: "GOURANGA CHANDRA PRUSTY",
    saID: "SPR2385",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "PRUSTY",
    address: "AT-RAGUDIPADA*GRAMYA BANK CHHAK*PO.BOLANGIR-767001*BOLANGIR",
    fullName: "PRAMOD KUMAR PRUSTY",
    saID: "SPR2386",
  },
  {
    firstName: "SREEDHAR",
    lastName: "PRUSTY",
    address:
      "AT-DHRUTI BIHAR,(KATAKULA)*PO-PARADIP GARH*VIA-KUJANG*JAGATSINGHPUR-754141*ODISHA",
    fullName: "SREEDHAR PRUSTY",
    saID: "SPR2387",
  },
  {
    firstName: "BALABHADRA",
    lastName: "PUHAN",
    address: "AT&PO-SAILONG*VIA-GHSIPURA*KEONJHAR 758015",
    fullName: "BALABHADRA PUHAN",
    saID: "SPR2388",
  },
  {
    firstName: "LATE VIVEKANANDA",
    lastName: "PURIGOSWAMI",
    address: "ATHPUR SUNDIA GOVT. COLONY*PLOT NO 52*PO-ATHPUR*DIST-24PARGANAS.",
    fullName: "LATE VIVEKANANDA PURIGOSWAMI",
    saID: "SPR2389",
  },
  {
    firstName: "LATE RASHBALLAV",
    lastName: "PURKAIT",
    address: "AT/PO-KAORAKHALI*DIST-24 PARGANAS.",
    fullName: "LATE RASHBALLAV PURKAIT",
    saID: "SPR2390",
  },
  {
    firstName: "LATE BINODE BEHARI",
    lastName: "PURKAYASTHA",
    address: "AT+PO LANKA*NOWGONG",
    fullName: "LATE BINODE BEHARI PURKAYASTHA",
    saID: "SPR2391",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "PURAKAYASTHA",
    address: "LEDY KEENE COLLEGE*PO-SHILLONG*MEGHALAYA.",
    fullName: "LATE DHIRENDRA KUMAR PURAKAYASTHA",
    saID: "SPR2392",
  },
  {
    firstName: "NIRANJAN",
    lastName: "PURKAYASTHA",
    address:
      "CHANDRAPUR COLONY*PO-CHANDRAPUR*VIA-DHARMANAGAR*TRIPURA (NORTH)-799251",
    fullName: "NIRANJAN PURKAYASTHA",
    saID: "SPR2393",
  },
  {
    firstName: "LATE BHUBANESWAR",
    lastName: "PUROHIT",
    address: "AT/PO-PUDAMARI 761014*GANJAM",
    fullName: "LATE BHUBANESWAR PUROHIT",
    saID: "SPR2394",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "PUROHIT",
    address: "AT/PO-GAISILET*VIA-MELCHHAMUNDA*BARGARH-768037*ODISHA",
    fullName: "RADHESHYAM PUROHIT",
    saID: "SPR2395",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "RAHA",
    address: "36 NO NEWBARRACKPUR*MAIN RD(EAST)*PO-NEWBARRACKPUR*DIST-24 PGS.",
    fullName: "DHIRENDRA NATH RAHA",
    saID: "SPR2396",
  },
  {
    firstName: "KALIPADA",
    lastName: "RAHA",
    address:
      "86/1/1 BANERJEE PARA (BARA BAGAN)*PO-SHYAMNAGAR*24 PARGANAS (N) 743127.",
    fullName: "KALIPADA RAHA",
    saID: "SPR2397",
  },
  {
    firstName: "LATE SAMBHU CHARAN",
    lastName: "RAHA",
    address: "J C GIRLS HIGH SCHOOL ROAD*PO-UDHARBAND*CACHAR 788 030",
    fullName: "LATE SAMBHU CHARAN RAHA",
    saID: "SPR2398",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "RAHACHOUDHURY",
    address: "PO-HAFLONG CHERRA*DT-TRIPURA.",
    fullName: "LATE NARESH CHANDRA RAHACHOUDHURY",
    saID: "SPR2399",
  },
  {
    firstName: "LATE PROMODE RANJAN",
    lastName: "RAHACHOWDHURY",
    address: "VILL NILCHARA*PO SONAPUR*VIA-BEHARA BAZAR*CACHAR",
    fullName: "LATE PROMODE RANJAN RAHACHOWDHURY",
    saID: "SPR2400",
  },
  {
    firstName: "SITANGSHU",
    lastName: "RAHACHOWDHURY",
    address: "AT-NILCHARA*PO-SONARPUR*CACHAR 788 817",
    fullName: "SITANGSHU RAHACHOWDHURY",
    saID: "SPR2401",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "RAHAROY",
    address: "PO-PATILADAHA*BONGAIGAON 783391",
    fullName: "LATE NIRMAL CHANDRA RAHAROY",
    saID: "SPR2402",
  },
  {
    firstName: "LATE KSHITISH",
    lastName: "RAIDAS",
    address: "11/1 GORACHAND LANE*CALCUTTA-14",
    fullName: "LATE KSHITISH RAIDAS",
    saID: "SPR2403",
  },
  {
    firstName: "EKKARI",
    lastName: "RAJAK",
    address: "NUTANHAT PIRTALA*GUSHKARA ROAD*PO-NUTANHAT*BURDWAN-713147",
    fullName: "EKKARI RAJAK",
    saID: "SPR2404",
  },
  {
    firstName: "KISAN",
    lastName: "RAJAK",
    address: "VILL-MAGALTULI*PO-OLD MALDA*DIST-MALDA 732128*PAN-ADKPR4797E.",
    fullName: "KISAN RAJAK",
    saID: "SPR2405",
  },
  {
    firstName: "LATE HARENDRA",
    lastName: "RAJBANGSHI",
    address: "AT/PO-TULSIBARI*DIST-KAMRUP.",
    fullName: "LATE HARENDRA RAJBANGSHI",
    saID: "SPR2406",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "RAJKONWAR",
    address: "SHANTIPUR*PO-MORANHAT*SIBSAGAR 785670.",
    fullName: "LATE SAILENDRA NATH RAJKONWAR",
    saID: "SPR2407",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "RAKSHIT",
    address: "CHHOTANILPUR ROAD*BURDWAN.",
    fullName: "LATE CHITTARANJAN RAKSHIT",
    saID: "SPR2408",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "RAKSHIT",
    address: "PO-SILIGURI*DT-DARJEELING.",
    fullName: "LATE HARENDRA CHANDRA RAKSHIT",
    saID: "SPR2409",
  },
  {
    firstName: "LATE JALADHAR",
    lastName: "RAKSHIT",
    address: "PO+VILL JAMUNAMUKH*NOWGONG.",
    fullName: "LATE JALADHAR RAKSHIT",
    saID: "SPR2410",
  },
  {
    firstName: "KRIPASINDHU",
    lastName: "RAKSHIT",
    address: "AT/PO SATSANG*DIST-DEOGHAR 814116*PAN BOWPR3715Q",
    fullName: "KRIPASINDHU RAKSHIT",
    saID: "SPR2411",
  },
  {
    firstName: "LATE SATYA RANJAN",
    lastName: "RAKSHIT",
    address: "C/O SATSANG KENDRA,RANGAPARA*PO-RANGAPARA*SONITPUR 784 505",
    fullName: "LATE SATYA RANJAN RAKSHIT",
    saID: "SPR2412",
  },
  {
    firstName: "LATE SHYAMSUNDAR",
    lastName: "RAKSHIT",
    address: "VIDYASAGAR PALLY*PO-KHATRA*DIST-BANKURA",
    fullName: "LATE SHYAMSUNDAR RAKSHIT",
    saID: "SPR2413",
  },
  {
    firstName: "MALDEO",
    lastName: "RAM",
    address: "DEO NIWAS*DURGASTHAN;GANDHINAGAR*PO&DT-KATIHAR*854105",
    fullName: "MALDEO RAM",
    saID: "SPR2414",
  },
  {
    firstName: "V ANANTHA",
    lastName: "RAMULU",
    address: "H.NO-2-1-434/A, NALLAKUNTA*HYDERABAD-500044*ANDHRA PRADESH.",
    fullName: "V ANANTHA RAMULU",
    saID: "SPR2415",
  },
  {
    firstName: "ANANDA",
    lastName: "RAMCHIARY",
    address: "VILL-MONPUR*PO-ODALGURI*DIST-UDALGURI*PAN-ALXPR8835Q",
    fullName: "ANANDA RAMCHIARY",
    saID: "SPR2416",
  },
  {
    firstName: "PHANINDRA",
    lastName: "RAMCHIARY",
    address: "VILL-SHANTIPUR*CHUTIAPARA*PO-ATHIABARI*DIST-NALBARI-781377*ASSAM",
    fullName: "PHANINDRA RAMCHIARY",
    saID: "SPR2417",
  },
  {
    firstName: "LATE ADHIKARY",
    lastName: "RANA",
    address: "VILL-GOBINDAPUR*PO-SAHIGAN GOPALPUR*VIA-ATHGARH*CUTTACK",
    fullName: "LATE ADHIKARY RANA",
    saID: "SPR2418",
  },
  {
    firstName: "SARMUNG",
    lastName: "RANGPHAR",
    address:
      "VILL-DHANSING JOKBIGAON*PO-LANGHIN TINIALI*DIST-KARBIANGLONG-782441*ASSAM",
    fullName: "SARMUNG RANGPHAR",
    saID: "SPR2419",
  },
  {
    firstName: "LATE BAIDYANATH",
    lastName: "RATH",
    address: "AT-GAJAPATINAGAR*PO/DIST-PURI 752002",
    fullName: "LATE BAIDYANATH RATH",
    saID: "SPR2420",
  },
  {
    firstName: "NARENDRA NATH",
    lastName: "RATH",
    address: "VILL-DAGARAPADA*PO-TIRTOL*JAGATSINGHPUR",
    fullName: "NARENDRA NATH RATH",
    saID: "SPR2421",
  },
  {
    firstName: "LATE GOVINDA CHANDRA",
    lastName: "RAVA",
    address: "AT-GATHIAPARA*PO-DHANUBHANGA*GOALPARA",
    fullName: "LATE GOVINDA CHANDRA RAVA",
    saID: "SPR2422",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "RABHA",
    address: "VILL-MAKALIKANDA*PO-MECHKAPUKHURI*VIA-TANGLA*DARRANG.",
    fullName: "UPENDRA NATH RABHA",
    saID: "SPR2423",
  },
  {
    firstName: "BALAWANT SINGH",
    lastName: "RAWAT",
    address: "B-811 GAUR HOMES*GOBINDA PURAM*GHAZIABAD*U.P.-201001",
    fullName: "BALAWANT SINGH RAWAT",
    saID: "SPR2424",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "RAY",
    address: "VILL-BANSDIHA*PO-KAMARDAH-756035*DIST BALASORE",
    fullName: "LATE KSHITISH CHANDRA RAY",
    saID: "SPR2425",
  },
  {
    firstName: "JEFFERY CHARLES",
    lastName: "RENERT",
    address: "30,CRESCENT AVE.*FARMINGTON*C.T. 06032*U.S.A.",
    fullName: "JEFFERY CHARLES RENERT",
    saID: "SPR2426",
  },
  {
    firstName: "SRIKRISHNA",
    lastName: "ROUL",
    address: "80/70 BENIMASTER LANE*PO-SARSUNA*KOLKATA 61",
    fullName: "SRIKRISHNA ROUL",
    saID: "SPR2427",
  },
  {
    firstName: "LATE GATIKRUSHNA",
    lastName: "ROUT",
    address: "V/PO-RENANDA PATANA*VIA-BHUBAN*DHENKANAL.",
    fullName: "LATE GATIKRUSHNA ROUT",
    saID: "SPR2428",
  },
  {
    firstName: "GATIKRISNA",
    lastName: "ROUT",
    address: "AT/PO-MANIPUR*DHENKANAL-759027",
    fullName: "GATIKRISNA ROUT",
    saID: "SPR2429",
  },
  {
    firstName: "NABA KISHORE",
    lastName: "ROUT",
    address: "LABOUR TENAMENT QR NO 18*CHARBATIA*CHOWDWAR*CUTTACK -754028",
    fullName: "NABA KISHORE ROUT",
    saID: "SPR2430",
  },
  {
    firstName: "PRAKASH KUMAR",
    lastName: "ROUT",
    address: "DHRUTI DIPTI*DAKSHINA KALI ROAD*DHENKANAL 759001",
    fullName: "PRAKASH KUMAR ROUT",
    saID: "SPR2431",
  },
  {
    firstName: "LATE RADHESHYAM",
    lastName: "ROUT",
    address: "AT/PO-SAHIRA*VIA-PATTAMUNDAI*KENDRAPARA",
    fullName: "LATE RADHESHYAM ROUT",
    saID: "SPR2432",
  },
  {
    firstName: "LATE SUBAL CHANDRA",
    lastName: "ROUT",
    address: "GOSABA SATSANG VIHAR*PO-GOSABA*24 PARGANAS SOUTH",
    fullName: "LATE SUBAL CHANDRA ROUT",
    saID: "SPR2433",
  },
  {
    firstName: "UPENDRA",
    lastName: "ROUT",
    address: "AT/PO-JHADAPADA*VIA-GOPALPUR*NAYAGARH-752025*ODISHA",
    fullName: "UPENDRA ROUT",
    saID: "SPR2434",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "ROUTH",
    address: "AT/PO-BAHARDA*VIA-KAMARDA*BALASORE.",
    fullName: "LATE BIBHUTI BHUSAN ROUTH",
    saID: "SPR2435",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "ROUTH",
    address: "VILL&PO-MADARIHAT*DT-JALPAIGURI.",
    fullName: "LATE HARENDRA NATH ROUTH",
    saID: "SPR2436",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "ROUTH",
    address: "VILL-SARANJA*PO-ALOK KENDRA*DIST-PASCHIM MEDINIPUR-721156",
    fullName: "MANINDRA NATH ROUTH",
    saID: "SPR2437",
  },
  {
    firstName: "KISHORE CHANDRA",
    lastName: "ROUTROY",
    address: "VILL-DEKURI*PO-BARADA*VIA-BALICHANDRAPUR*JAJPUR",
    fullName: "KISHORE CHANDRA ROUTROY",
    saID: "SPR2438",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "RAY",
    address: "RABINDRA PALLY*NEAR SATSANG VIHAR,BELONIA*SOUTH TRIPURA",
    fullName: "LATE AJIT KUMAR RAY",
    saID: "SPR2439",
  },
  {
    firstName: "AMARENDRA",
    lastName: "RAY",
    address: "21/C SATYEN ROY ROAD*JAMES LONG SARANI*BEHALA*KOLKATA-34.",
    fullName: "AMARENDRA RAY",
    saID: "SPR2440",
  },
  {
    firstName: "LATE AMULLYA CHARAN",
    lastName: "ROY",
    address: "VILL-PALENGBARI 2*PO-MONAKOCHA*VIA-PATILADAHA*KOKRAJHAR 783391 .",
    fullName: "LATE AMULLYA CHARAN ROY",
    saID: "SPR2441",
  },
  {
    firstName: "AMULLYA RATAN",
    lastName: "ROY",
    address: "VILL-BARATANGLA*PO-PURANATANGLA*DARRANG-784528*ASSAM",
    fullName: "AMULLYA RATAN ROY",
    saID: "SPR2442",
  },
  {
    firstName: "LATE AMULLYARATAN",
    lastName: "ROY",
    address: "*SATSANG  814116",
    fullName: "LATE AMULLYARATAN ROY",
    saID: "SPR2443",
  },
  {
    firstName: "LATE ANANDA KUMAR",
    lastName: "ROY",
    address: "AT-PURBA CHAKCHAKA*PO-BARABHISHA*JALPAIGURI",
    fullName: "LATE ANANDA KUMAR ROY",
    saID: "SPR2444",
  },
  {
    firstName: "ANIL CHANDRA",
    lastName: "ROY",
    address: "ROY BAKERY*JAKHLABANDHA*NAGAON-782 136*ASSAM",
    fullName: "ANIL CHANDRA ROY",
    saID: "SPR2445",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "ROY",
    address: "VILL TIWARA*PO KOCHDIHI*DIST BANKURA",
    fullName: "LATE ANIL KUMAR ROY",
    saID: "SPR2446",
  },
  {
    firstName: "LATE ARABINDA KUMAR",
    lastName: "RAY",
    address:
      "VILL-CHANDIPUR*PO-BOTOKHALI CHANDIPUR*VIA-PATHANKHALI*24 PARGANAS (S)",
    fullName: "LATE ARABINDA KUMAR RAY",
    saID: "SPR2447",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "ROY",
    address: "VILL/PO-PATILADAHA*BONGAIGAON-783391*ASSAM",
    fullName: "ASWINI KUMAR ROY",
    saID: "SPR2448",
  },
  {
    firstName: "BANIJ KUMAR",
    lastName: "ROY",
    address: "AT-JAREGAON*PO-KALIPUKHURI*KOKRAJHAR-783369*ASSAM",
    fullName: "BANIJ KUMAR ROY",
    saID: "SPR2449",
  },
  {
    firstName: "LATE BANKIM CHANDRA",
    lastName: "ROY",
    address: "T.BUNGLOW*PO-SATSANG*DEOGHAR",
    fullName: "LATE BANKIM CHANDRA ROY",
    saID: "SPR2450",
  },
  {
    firstName: "BEPIN CHANDRA",
    lastName: "ROY",
    address: "SATSANG MANDIR*PO. PUTIMARI*COOCHBEHAR.",
    fullName: "BEPIN CHANDRA ROY",
    saID: "SPR2451",
  },
  {
    firstName: "BHABANI CHARAN",
    lastName: "ROY",
    address: "AMAR ANANDA*BAISHNAB PARA*PO-NABADWIP*NADIA",
    fullName: "BHABANI CHARAN ROY",
    saID: "SPR2452",
  },
  {
    firstName: "LATE BHABATARAN",
    lastName: "ROY",
    address: "2/1 A GARCHA 2ND LANE*DOVER TERRACE*CALCUTTA-700019.",
    fullName: "LATE BHABATARAN ROY",
    saID: "SPR2453",
  },
  {
    firstName: "BHAJAN KUMAR",
    lastName: "ROY",
    address:
      "SATSANG VIHAR BADLAPUR*HENDREPADA*PO-KULGAON*THANE*M.S.-421503*PAN-AKDPR5362D",
    fullName: "BHAJAN KUMAR ROY",
    saID: "SPR2454",
  },
  {
    firstName: "LATE BHUBANESWAR",
    lastName: "ROY",
    address: "RADHABAZAR*PO.NABADWIP*NADIA",
    fullName: "LATE BHUBANESWAR ROY",
    saID: "SPR2455",
  },
  {
    firstName: "LATE BIBHUTI RANJAN",
    lastName: "ROY",
    address: "NETAJI ROAD*PO-ALIPURDUAR*JALPAIGURI",
    fullName: "LATE BIBHUTI RANJAN ROY",
    saID: "SPR2456",
  },
  {
    firstName: "LATE BRAJA GOPAL",
    lastName: "RAY",
    address: "VILL-MRIJABAZAR*PO-JANAKALYAN BAZAR*KARIMGANJ-788720.",
    fullName: "LATE BRAJA GOPAL RAY",
    saID: "SPR2457",
  },
  {
    firstName: "CHITTA RANJAN",
    lastName: "ROY",
    address: "SATSANG VIHAR*PO-ALIPURDUAR *JALPAIGURI-736123",
    fullName: "CHITTA RANJAN ROY",
    saID: "SPR2458",
  },
  {
    firstName: "LATE DANABENDRA NATH",
    lastName: "ROY",
    address: "AT-PAKRAPARA*PO-BOKO*DT-KAMRUP",
    fullName: "LATE DANABENDRA NATH ROY",
    saID: "SPR2459",
  },
  {
    firstName: "LATE DARPA NARAYAN",
    lastName: "ROY",
    address: "AT/PO-CHITHILA*KOKRAJHAR.",
    fullName: "LATE DARPA NARAYAN ROY",
    saID: "SPR2460",
  },
  {
    firstName: "LATE DEWAN CHANDRA",
    lastName: "ROY",
    address: "PO-KAMAKHYAGURI*JALPAIGURI.",
    fullName: "LATE DEWAN CHANDRA ROY",
    saID: "SPR2461",
  },
  {
    firstName: "DHARMESHWAR",
    lastName: "ROY",
    address: "SATSANG KENDRA KOKRAJHAR*KOKRAJHAR-783370*ASSAM",
    fullName: "DHARMESHWAR ROY",
    saID: "SPR2462",
  },
  {
    firstName: "DHARMESHWAR",
    lastName: "ROY",
    address:
      "VILL/PO-GOSAIGAON WARD NO-3*COLLEGE RD*KOKRAJHAR-783360*BTAD*(ASSAM)",
    fullName: "DHARMESHWAR ROY",
    saID: "SPR2463",
  },
  {
    firstName: "LATE DUDH KUMAR",
    lastName: "ROY",
    address: "45 KUSUNTIA ROAD*PO.KHURUT*DT-HOWRAH",
    fullName: "LATE DUDH KUMAR ROY",
    saID: "SPR2464",
  },
  {
    firstName: "GANDHI CHANDRA",
    lastName: "ROY",
    address:
      '"HEMANTA KUTIR"*N M SARANI,SAHID COLONY*BHAKTI NAGAR*JALPAIGURI-734007*PAN-AERPR58A',
    fullName: "GANDHI CHANDRA ROY",
    saID: "SPR2465",
  },
  {
    firstName: "LATE GOBINDA DAS",
    lastName: "ROY",
    address:
      "VINAYAK SANGSAD ASRAM*VILL-RAIPUR*PO-RAIPUR DAYARAMPUR*VIA-GHATESWAR*24PARGANAS",
    fullName: "LATE GOBINDA DAS ROY",
    saID: "SPR2466",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "ROY",
    address: "WARD NO.7 BOARDING PARA*PO-DINHATA*COOCHBEHAR",
    fullName: "GOPAL CHANDRA ROY",
    saID: "SPR2467",
  },
  {
    firstName: "HARAN KRISHNA",
    lastName: "RAY",
    address: "47A,REST CAMP,ACCOUNTS COLONY*PO-GUWAHATI-781012*DIST KAMRUP",
    fullName: "HARAN KRISHNA RAY",
    saID: "SPR2468",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "RAY",
    address: "PRASANNA PARK*PO-GOBARDANGA*24PARGANAS",
    fullName: "LATE HARENDRA CHANDRA RAY",
    saID: "SPR2469",
  },
  {
    firstName: "LATE HARIHAR",
    lastName: "ROY",
    address: "AT/PO-NASIGRAM*DIST-BURDWAN",
    fullName: "LATE HARIHAR ROY",
    saID: "SPR2470",
  },
  {
    firstName: "LATE HIMANGSU RANJAN",
    lastName: "ROY",
    address: "DT-TRIPURA.",
    fullName: "LATE HIMANGSU RANJAN ROY",
    saID: "SPR2471",
  },
  {
    firstName: "LATE IMANESWAR",
    lastName: "ROY",
    address: "AT-PAGLARHAT*PO-GADRA*JALPAIGURI",
    fullName: "LATE IMANESWAR ROY",
    saID: "SPR2472",
  },
  {
    firstName: "LATE JADU NATH",
    lastName: "ROY",
    address:
      "HARHARGHUTTU UPPERTOLA*PO-HARHARGHUTTU*VIA-TATANAGAR*SINGHBHUM 831002           .",
    fullName: "LATE JADU NATH ROY",
    saID: "SPR2473",
  },
  {
    firstName: "LATE JAGDEO",
    lastName: "ROY",
    address: "HEAD CLERK*D C S I OFFICE N F RLY*SAMASTIPUR",
    fullName: "LATE JAGDEO ROY",
    saID: "SPR2474",
  },
  {
    firstName: "JATINDRA LAL",
    lastName: "ROY",
    address: '"SWASTI BHAWAN"*PANCHAYET ROAD*DIST-SILCHAR-788005*ASSAM',
    fullName: "JATINDRA LAL ROY",
    saID: "SPR2475",
  },
  {
    firstName: "LATE JIBAN KRISNA",
    lastName: "ROY",
    address: "AT-D.SANKAJORA*PO-SAJNAPARA*JALPAIGURI",
    fullName: "LATE JIBAN KRISNA ROY",
    saID: "SPR2476",
  },
  {
    firstName: "JITENDRA CHANDRA",
    lastName: "ROY",
    address: "BHATIBARI HIGH SCHOOL*PO-BHATIBARI*DIST-ALIPURDUAR-736121",
    fullName: "JITENDRA CHANDRA ROY",
    saID: "SPR2477",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "ROY",
    address: "BIDHAN PALLY*BARABEHARA*HOOGHLY",
    fullName: "LATE JITENDRA NATH ROY",
    saID: "SPR2478",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "ROY",
    address:
      'KORAKATI "KANAK BHAWAN"*PO-KORAKATI*VIA-SANDASH KHALI*24PARGANAS (N)',
    fullName: "LATE JOGESH CHANDRA ROY",
    saID: "SPR2479",
  },
  {
    firstName: "KALI MOHAN",
    lastName: "ROY",
    address: "C/O-SHANTI NIBAS*DESHABANDHUPARA*PO-SILIGURI-734004*DARJEELING.",
    fullName: "KALI MOHAN ROY",
    saID: "SPR2480",
  },
  {
    firstName: "LATE KALIDAS",
    lastName: "ROY",
    address: "KRISHNADEVPUR*PO-MANDIR BAZAR*DIST-24-PGS.",
    fullName: "LATE KALIDAS ROY",
    saID: "SPR2481",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "ROY",
    address: "QR NO 10*PO.KAPSI*DIST BASTER(M.P.)",
    fullName: "LATE KALIPADA ROY",
    saID: "SPR2482",
  },
  {
    firstName: "KAMAL KRISHNA",
    lastName: "RAY",
    address: "6/215A,BIRSANAGAR*JAMSHEDPUR-4*SINGHBHUM-831004",
    fullName: "KAMAL KRISHNA RAY",
    saID: "SPR2483",
  },
  {
    firstName: "LATE KAMALKRISNA",
    lastName: "ROY",
    address: "VILL-LELA(KADAMTALA)*PO-LELA*GOALPARA-783124*ASSAM",
    fullName: "LATE KAMALKRISNA ROY",
    saID: "SPR2484",
  },
  {
    firstName: "LATE KANAI LAL",
    lastName: "RAY",
    address: "AT/PO-KOTASUR*DIST-BIRBHUM",
    fullName: "LATE KANAI LAL RAY",
    saID: "SPR2485",
  },
  {
    firstName: "KEDAR NATH",
    lastName: "ROY",
    address: "30/63 ATAPARA LANE*CALCUTTA-50",
    fullName: "KEDAR NATH ROY",
    saID: "SPR2486",
  },
  {
    firstName: "LATE KESHAB CHANDRA",
    lastName: "ROY",
    address: "35/1,MILAN PARK*PO-GARIA*CALCUTTA 700 084",
    fullName: "LATE KESHAB CHANDRA ROY",
    saID: "SPR2487",
  },
  {
    firstName: "KHAGENDRA NATH",
    lastName: "ROY",
    address: "VIL&PO-MANIKGANJHAT*JALPAIGURI.",
    fullName: "KHAGENDRA NATH ROY",
    saID: "SPR2488",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "ROY",
    address: "VILL/PO-PAITUR BAZAR*VIA-KAILASAHAR*TRIPURA (N)",
    fullName: "LATE KSHITISH CHANDRA ROY",
    saID: "SPR2489",
  },
  {
    firstName: "LATE KHOUNISH CHANDRA",
    lastName: "ROY",
    address: "ROYBAHADUR PALLI*SARKARHAT LANE*CALCUTTA-61",
    fullName: "LATE KHOUNISH CHANDRA ROY",
    saID: "SPR2490",
  },
  {
    firstName: "KRISHNA BALAK",
    lastName: "ROY",
    address: "391,RENTAL FLAT*KANKARBAGH*PATNA 800020",
    fullName: "KRISHNA BALAK ROY",
    saID: "SPR2491",
  },
  {
    firstName: "KSHITISH CHANDRA",
    lastName: "ROY",
    address: "VILL-KUTABGACHH*PO-GADRA*JALPAIGURI-735134",
    fullName: "KSHITISH CHANDRA ROY",
    saID: "SPR2492",
  },
  {
    firstName: "MANAS KUMAR",
    lastName: "ROY",
    address:
      "OPPOSITE CENTRAL BANK OF INDIA*THANGAL BAJAR*IMPHAL-795001*PAN- ABZPR9615G",
    fullName: "MANAS KUMAR ROY",
    saID: "SPR2493",
  },
  {
    firstName: "LATE MANIBHUSAN",
    lastName: "ROY",
    address: "NEAR SATSANG KENDRA*AT/PO-BHOLARDABRI*JALPAIGURI 736123",
    fullName: "LATE MANIBHUSAN ROY",
    saID: "SPR2494",
  },
  {
    firstName: "MANIK",
    lastName: "ROY",
    address: "4/203 SETH BAGAN ROAD*CALCUTTA-700030.",
    fullName: "MANIK ROY",
    saID: "SPR2495",
  },
  {
    firstName: "MANIK LAL",
    lastName: "ROY",
    address: "40,DHOLESHWAR*PO.AGARTALA COLLEGE*TRIPURA-799007",
    fullName: "MANIK LAL ROY",
    saID: "SPR2496",
  },
  {
    firstName: "LATE MANINDRA LAL",
    lastName: "ROY",
    address: "VILL&PO-SONATALA*KHOWAI*TRIPURA WEST",
    fullName: "LATE MANINDRA LAL ROY",
    saID: "SPR2497",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "ROY",
    address: '"AMAR ANANDA"*BAISNABPARA*PO-NABADWIP*NADIA',
    fullName: "LATE MANINDRA NATH ROY",
    saID: "SPR2498",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "ROY",
    address: "VILL/PO-KISMAT HASDAHA*DHUBRI-783334*ASSAM",
    fullName: "MANINDRA NATH ROY",
    saID: "SPR2499",
  },
  {
    firstName: "MANMATHANATH",
    lastName: "ROY",
    address: "RAIGANJ SATSANG VIHAR*PO-RAIGANJ*DT-UTTAR DINAJPUR-733134",
    fullName: "MANMATHANATH ROY",
    saID: "SPR2500",
  },
  {
    firstName: "LATE MATILAL",
    lastName: "ROY",
    address: "AT/PO-GORESWAR*DIST KAMRUP",
    fullName: "LATE MATILAL ROY",
    saID: "SPR2501",
  },
  {
    firstName: "MIHIR RANJAN",
    lastName: "ROY",
    address: "DISTRICT & SESSIONS JUDGE COURT*PO-KAILASAHAR*TRIPURA N-799277",
    fullName: "MIHIR RANJAN ROY",
    saID: "SPR2502",
  },
  {
    firstName: "LATE MOHIT CHANDRA",
    lastName: "ROY",
    address: "MURAGACHHA*PO-BAGULA*NADIA",
    fullName: "LATE MOHIT CHANDRA ROY",
    saID: "SPR2503",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "ROY",
    address: "VILL-NATUN PALLI*PO-MORPUKUR*HOOGHLY.",
    fullName: "LATE MRINAL KANTI ROY",
    saID: "SPR2504",
  },
  {
    firstName: "MRITYUNJOY",
    lastName: "ROY",
    address:
      "SREENIBAS-SWASTINEER*910,DAKSHINPARA*NUTANPALLY-N-6*PO-PURBA PUTIARY*KOLKATA-700093",
    fullName: "MRITYUNJOY ROY",
    saID: "SPR2505",
  },
  {
    firstName: "LATE MRITYUNJOY",
    lastName: "ROY",
    address: "V-BENAGARH*PO-JHARGRAM*DIST-MIDNAPORE.",
    fullName: "LATE MRITYUNJOY ROY",
    saID: "SPR2506",
  },
  {
    firstName: "LATE MUKUL CHANDRA",
    lastName: "ROY",
    address: '"ROY LODGE"*NORTH BHABANIPUR*PO-KHARAGPUR*MIDNAPUR 721301',
    fullName: "LATE MUKUL CHANDRA ROY",
    saID: "SPR2507",
  },
  {
    firstName: "MUKUNDA BEHARI",
    lastName: "ROY",
    address: '"DHRITIDIPA"*300 KASTADANGA ROAD*PO-SARSUNA*CALCUTTA-700061',
    fullName: "MUKUNDA BEHARI ROY",
    saID: "SPR2508",
  },
  {
    firstName: "MURARI MOHAN",
    lastName: "RAY",
    address: "436/A,G.T.ROAD(EAST)*PO-SRIPALLI*BURDWAN 713103",
    fullName: "MURARI MOHAN RAY",
    saID: "SPR2509",
  },
  {
    firstName: "NAGENDRA NATH",
    lastName: "ROY",
    address: "RAMKRISHNA COLONY*PO-MAJHIPARA*DIST-24 PARGANAS (NORTH)-743145",
    fullName: "NAGENDRA NATH ROY",
    saID: "SPR2510",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "ROY",
    address: "AT/PO NASARATHPUR*DIST-BURDWAN",
    fullName: "NANIGOPAL ROY",
    saID: "SPR2511",
  },
  {
    firstName: "NARENDRA NATH",
    lastName: "ROY",
    address: "PO/VILL-SENDANGA*VIA-KALYANGARH*DIST-24PARGANAS.",
    fullName: "NARENDRA NATH ROY",
    saID: "SPR2512",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "ROY",
    address: "GUDHEARKUTHI*PO-BHANDANI*VIA-DHUPGURI*JALPAIGURI",
    fullName: "LATE NARENDRA NATH ROY",
    saID: "SPR2513",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "ROY",
    address: "NO.1 SHIBSOHAGINI COLONY*PO-TRIBENI*DIST.HOOGHLY",
    fullName: "LATE NARENDRA NATH ROY",
    saID: "SPR2514",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "ROY",
    address:
      "AT-SUKANTA SARANI*LAUTORE SANKHA PUKUR*PO-SAINTHIA*BIRBHUM-731234",
    fullName: "NARESH CHANDRA ROY",
    saID: "SPR2515",
  },
  {
    firstName: "LATE NIKHIL RANJAN",
    lastName: "ROY",
    address: "UTTARPARA BARANILPUR*PO SRIPALLI*DIST-BURDWAN",
    fullName: "LATE NIKHIL RANJAN ROY",
    saID: "SPR2516",
  },
  {
    firstName: "LATE NIRA PADA",
    lastName: "ROY",
    address: "29/A NARASINGHA DUTTA ROAD*PO.KADAMTALA*DT.HOWRAH",
    fullName: "LATE NIRA PADA ROY",
    saID: "SPR2517",
  },
  {
    firstName: "NIRANJAN",
    lastName: "ROY",
    address: "AT&PO-KHARINASHI*KENDRAPARA 754224",
    fullName: "NIRANJAN ROY",
    saID: "SPR2518",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "ROY",
    address: "AT-SHYAMPUR COLONY*PO-DURGPUR -1*DIST-BURDWAN.",
    fullName: "LATE NIRMAL CHANDRA ROY",
    saID: "SPR2519",
  },
  {
    firstName: "LATE NITINDRA CHANDRA",
    lastName: "ROY",
    address: "VILL-UPARTARY*PO-HAKAMA(BILASIPARA)*DHUBRI",
    fullName: "LATE NITINDRA CHANDRA ROY",
    saID: "SPR2520",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "ROY",
    address: "J.C.M.HIGH SCHOOL*PO.BETHUADAHARI*NADIA",
    fullName: "LATE NITYANANDA ROY",
    saID: "SPR2521",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "ROY",
    address: "AT&PO-GARIALTARI*JALPAIGURI",
    fullName: "LATE NITYANANDA ROY",
    saID: "SPR2522",
  },
  {
    firstName: "PARIMAL KANTI",
    lastName: "ROY",
    address: "187, BARANILPUR RD, SAKTIPARA*PO-SRIPALLY*DIST-BURDWAN-713103.",
    fullName: "PARIMAL KANTI ROY",
    saID: "SPR2523",
  },
  {
    firstName: "LATE PRABHAT CHANDRA",
    lastName: "ROY",
    address: "AT-TIADAHA*PO-K.DASGRAM*COOCHBEHAR",
    fullName: "LATE PRABHAT CHANDRA ROY",
    saID: "SPR2524",
  },
  {
    firstName: "PRANAB KUMAR",
    lastName: "ROY",
    address: "C.K.63/2 CHOTIPEARY*SARASHI BHABAN*PO/DT-VARANASI*PAN-BLSPR0400B",
    fullName: "PRANAB KUMAR ROY",
    saID: "SPR2525",
  },
  {
    firstName: "LATE PREM NATH",
    lastName: "RAY",
    address: "GOSSAINGAON WARD NO-3*PO-GOSSAINGAON*KOKRAJHAR",
    fullName: "LATE PREM NATH RAY",
    saID: "SPR2526",
  },
  {
    firstName: "PROBHAT KUMAR",
    lastName: "ROY",
    address: "AT-DAKSHIN SHIBGANJ*PO-PATHARPRATIMA*DIST-24 PGS (SOUTH)-743371",
    fullName: "PROBHAT KUMAR ROY",
    saID: "SPR2527",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "ROY",
    address: "AT&PO-DIGAMBARPUR*NADIA-741508",
    fullName: "PURNA CHANDRA ROY",
    saID: "SPR2528",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "ROY",
    address: "SATSANG VIHAR DISPUR*GAUHATI-5*KAMRUP.",
    fullName: "LATE RABINDRA NATH ROY",
    saID: "SPR2529",
  },
  {
    firstName: "LATE RAJENDRA NATH",
    lastName: "ROY",
    address: "PANDAPARA ROAD*PO&DT-JALPAIGURI",
    fullName: "LATE RAJENDRA NATH ROY",
    saID: "SPR2530",
  },
  {
    firstName: "LATE RAMANAND",
    lastName: "ROY",
    address: "A/303 3RD FLOOR*MEERA SADAN*GOLA ROAD*DANAPUR*PATNA",
    fullName: "LATE RAMANAND ROY",
    saID: "SPR2531",
  },
  {
    firstName: "RAMANI MOHAN",
    lastName: "RAY",
    address: "AT-BARKHASUA*PO-SRINAGAR*KOKRAJHAR-783332*ASSAM",
    fullName: "RAMANI MOHAN RAY",
    saID: "SPR2532",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "ROY",
    address: "AT/PO BIRHA*SAMASTIPUR",
    fullName: "RAMESH CHANDRA ROY",
    saID: "SPR2533",
  },
  {
    firstName: "LATE RATAN MONI",
    lastName: "RAY",
    address: "VILL-NAPALIPALLY*PO-TEZPUR*DARRANG.",
    fullName: "LATE RATAN MONI RAY",
    saID: "SPR2534",
  },
  {
    firstName: "SACHINDRANATH",
    lastName: "ROY",
    address: "BASISTA NAGAR*BELTOLA*GAUHATI 5",
    fullName: "SACHINDRANATH ROY",
    saID: "SPR2535",
  },
  {
    firstName: "LATE SACHINDRA KUMAR",
    lastName: "ROY",
    address: "AT-KHOLAIGRAM*PO-SALBARI*DIST-JALPAIGURI.",
    fullName: "LATE SACHINDRA KUMAR ROY",
    saID: "SPR2536",
  },
  {
    firstName: "LATE SAMBHU NATH",
    lastName: "ROY",
    address: "KONA MALIK PARA*PO-KONA*HOWRAH 711323",
    fullName: "LATE SAMBHU NATH ROY",
    saID: "SPR2537",
  },
  {
    firstName: "LATE SANKAR LAL",
    lastName: "ROY",
    address: "SREENIKETAN*KUMARDINGI, PANCHANANTALA*PO-MOREPUKUR*HOOGHLY.",
    fullName: "LATE SANKAR LAL ROY",
    saID: "SPR2538",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "ROY",
    address: "J1/2,KARIM BOSE ROAD*GOVT.HOUSING ESTATE*CALCUTTA-2",
    fullName: "LATE SANTOSH KUMAR ROY",
    saID: "SPR2539",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "RAY",
    address: "72/2 BAGH BAZAR ST*CALCUTTA 3",
    fullName: "LATE SANTOSH KUMAR RAY",
    saID: "SPR2540",
  },
  {
    firstName: "LATE SASADHAR",
    lastName: "ROY",
    address: "VILL-NANGISHIBTALA*PO-BATANAGAR*DIST-24 PGS-SOUTH-743313.",
    fullName: "LATE SASADHAR ROY",
    saID: "SPR2541",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "ROY",
    address: "MALIKUCHI(DIGHILE)*PO-BIDYAPUR*DIST-NALBARI-781335*ASSAM",
    fullName: "SATISH CHANDRA ROY",
    saID: "SPR2542",
  },
  {
    firstName: "LATE SHAILAJA KANT",
    lastName: "ROY",
    address: "BHURKUNDA COLLIERY HOSPITAL*PO-BHURKUNDA*HAZARIBAGH",
    fullName: "LATE SHAILAJA KANT ROY",
    saID: "SPR2543",
  },
  {
    firstName: "LATE SIBA PRASAD",
    lastName: "ROY",
    address: "VILL.RAJESWARI NAGAR*PO.TALCHUA*KENDRAPARA",
    fullName: "LATE SIBA PRASAD ROY",
    saID: "SPR2544",
  },
  {
    firstName: "LATE SRIKUMAR",
    lastName: "ROY",
    address: "D.T.C.OF INDIA*PO-BOLMURI*JAMSHEDPUR-3.",
    fullName: "LATE SRIKUMAR ROY",
    saID: "SPR2545",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "ROY",
    address: "AT+PO-GHANZA*VIA-GAIGHATA*DIST-24 PGS (NORTH)-743249",
    fullName: "SUBAL CHANDRA ROY",
    saID: "SPR2546",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "ROY",
    address: "NO-4,SANKERPUKUR COLONY*PO/DIST BURDWAN.",
    fullName: "LATE SUBODH CHANDRA ROY",
    saID: "SPR2547",
  },
  {
    firstName: "SUBRATA",
    lastName: "ROY",
    address: "MAHATMA GANDHI LANE*SATSANG ROAD*PO/DIST-SILCHAR 788007*ASSAM",
    fullName: "SUBRATA ROY",
    saID: "SPR2548",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "ROY",
    address: "VILL/PO PANKHALI*DIST KHULNA*BANGLADESH",
    fullName: "SUDHIR KUMAR ROY",
    saID: "SPR2549",
  },
  {
    firstName: "LATE SUNIL CHANDRA",
    lastName: "RAY",
    address: "AT.RAJYADHARPUR*PO.SERAMPUR*DIST.HOOGHLY",
    fullName: "LATE SUNIL CHANDRA RAY",
    saID: "SPR2550",
  },
  {
    firstName: "SURAMONI",
    lastName: "ROY",
    address: "ROYPARA*PO-PALANGHAT*CACHAR 788 116",
    fullName: "SURAMONI ROY",
    saID: "SPR2551",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "ROY",
    address: "PO-BAIRATIGURI*VIA-DHUPGURI*JALPAIGURI-735210",
    fullName: "SURENDRA NATH ROY",
    saID: "SPR2552",
  },
  {
    firstName: "SUSHEN KUMAR",
    lastName: "ROY",
    address: "VILL/PO-GOKULNAGAR*VIA-FATIKROY*DIST-UNAKOTI  TRIPURA-799290",
    fullName: "SUSHEN KUMAR ROY",
    saID: "SPR2553",
  },
  {
    firstName: "SUSHIL KUMAR",
    lastName: "ROY",
    address: "VILL/PO SHIBNAGAR*DIST KHULNA*BANGLADESH",
    fullName: "SUSHIL KUMAR ROY",
    saID: "SPR2554",
  },
  {
    firstName: "TEJENDRA CHANDRA",
    lastName: "ROY",
    address: "AT-FULKUMARI*PO-SILKHAGURI*VIA-BIJNI*KOKRAJHAR-783390*ASSAM",
    fullName: "TEJENDRA CHANDRA ROY",
    saID: "SPR2555",
  },
  {
    firstName: "LATE UMESH CHANDRA",
    lastName: "ROY",
    address: "LANKA SATSANG MANDIR*PO-LANKA*NAGAON.",
    fullName: "LATE UMESH CHANDRA ROY",
    saID: "SPR2556",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "ROYADHIKARI",
    address:
      "VILL-HARIPUR*PO-TOKIPUR*24 PARAGANAS (NORTH) 743429*PAN-ADJPR8061N",
    fullName: "RABINDRA NATH ROYADHIKARI",
    saID: "SPR2557",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "ROYBURMAN",
    address: "AT/PO-MONDALPARA*VIA-SHYAMNAGAR*DIST-24 PARAGANAS NORTH-743127",
    fullName: "MRINAL KANTI ROYBURMAN",
    saID: "SPR2558",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "ROYMANDAL",
    address: "BARANILPUR BAJAR*BURDWAN.",
    fullName: "LATE DHIRENDRA NATH ROYMANDAL",
    saID: "SPR2559",
  },
  {
    firstName: "LATE NILKANTHA",
    lastName: "ROYBARMAN",
    address: "AT-KAYAKHATA*PO-SALSALABARI*JALPAIGURI.",
    fullName: "LATE NILKANTHA ROYBARMAN",
    saID: "SPR2560",
  },
  {
    firstName: "SATISH CHANDRA",
    lastName: "ROYBASUNIA",
    address: "VIL&PO-JAMALDAHA*COOCHBEHAR-735303",
    fullName: "SATISH CHANDRA ROYBASUNIA",
    saID: "SPR2561",
  },
  {
    firstName: "BIMALENDU",
    lastName: "ROY",
    address: "C/O JAGAJYOTI ROYCHATTERJEE*AT/PO-SATSANG*DEOGHAR*PAN-ADCPR1973Q",
    fullName: "BIMALENDU ROY",
    saID: "SPR2562",
  },
  {
    firstName: "JAGAJYOTI",
    lastName: "ROYCHATTERJEE",
    address: "SATSANG 814116.",
    fullName: "JAGAJYOTI ROYCHATTERJEE",
    saID: "SPR2563",
  },
  {
    firstName: "LATE ANNADA CHANDRA",
    lastName: "ROYCHOUDHURY",
    address: "PO-KAMAKHYAGURI*JALPAIGURI",
    fullName: "LATE ANNADA CHANDRA ROYCHOUDHURY",
    saID: "SPR2564",
  },
  {
    firstName: "ATAPENDRA",
    lastName: "ROYCHOUDHURY",
    address: "SATSANG*DEOGHAR",
    fullName: "ATAPENDRA ROYCHOUDHURY",
    saID: "SPR2565",
  },
  {
    firstName: "LATE CHUNI LAL",
    lastName: "ROYCHOUDHURY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE CHUNI LAL ROYCHOUDHURY",
    saID: "SPR2566",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "ROYCHOUDHURY",
    address: "17 PARASAR ROAD*CALCUTTA 29",
    fullName: "LATE DEBENDRA NATH ROYCHOUDHURY",
    saID: "SPR2567",
  },
  {
    firstName: "DIPAK",
    lastName: "ROYCHOWDHURY",
    address: "VILL HRIDAYPUR(SIBTALA)*PO HRIDAYPUR*DIST-24 PGS NORTH-700127",
    fullName: "DIPAK ROYCHOWDHURY",
    saID: "SPR2568",
  },
  {
    firstName: "LATE JAMINI KANTA",
    lastName: "ROYCHOUDHURY",
    address: "*SATSANG 814116",
    fullName: "LATE JAMINI KANTA ROYCHOUDHURY",
    saID: "SPR2569",
  },
  {
    firstName: "MIHIR",
    lastName: "ROYCHOUDHURY",
    address:
      "EX HEAD TRAINS CLERK*YARD MASTER OFFICE*N F RLY*KATIHAR-854105*PAN-AXPPC6777N",
    fullName: "MIHIR ROYCHOUDHURY",
    saID: "SPR2570",
  },
  {
    firstName: "LATE SUBHASH KUMAR",
    lastName: "ROYCHOWDHURY",
    address:
      "QR NO.2-R-29*NEAR SATSANGVIHAR PARADIPPORT*PO-PARADIP PORT*CUTTACK",
    fullName: "LATE SUBHASH KUMAR ROYCHOWDHURY",
    saID: "SPR2571",
  },
  {
    firstName: "LATE SUDHIR RANJAN",
    lastName: "ROYCHOWDHURY",
    address: "211, S.K.DEB ROAD*PATIPUKUR*CALCUTTA-700048",
    fullName: "LATE SUDHIR RANJAN ROYCHOWDHURY",
    saID: "SPR2572",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "ROYPALADHI",
    address: "PALADHI BHABAN,SURYA NAGAR*PO-ALIPURDUAR COURT*JALPAIGURI",
    fullName: "LATE JATINDRA NATH ROYPALADHI",
    saID: "SPR2573",
  },
  {
    firstName: "LATE DINANATH",
    lastName: "ROYSARKAR",
    address: "SANTIMARI*PO-PANIKHOWA*COOCHBEHAR",
    fullName: "LATE DINANATH ROYSARKAR",
    saID: "SPR2574",
  },
  {
    firstName: "LATE MAHESH CHANDRA",
    lastName: "ROYSARKAR",
    address: "AT-CHENGMARI*PO-KUMARGRAMDUAR*JALPAIGURI",
    fullName: "LATE MAHESH CHANDRA ROYSARKAR",
    saID: "SPR2575",
  },
  {
    firstName: "NRIPENDRA NATH",
    lastName: "ROYSARKAR",
    address: "AT-PURNI*PO-KACHOKHANA*DHUBRI-783334*PAN.NO.BKMPR3323L",
    fullName: "NRIPENDRA NATH ROYSARKAR",
    saID: "SPR2576",
  },
  {
    firstName: "LATE RAMAPADA",
    lastName: "ROYSARKAR",
    address: "VILL-BELAKOBA*PO-PRASANNANAGAR*JALPAIGURI-735133",
    fullName: "LATE RAMAPADA ROYSARKAR",
    saID: "SPR2577",
  },
  {
    firstName: "BAJARANGBALI",
    lastName: "ROYSHARMA",
    address: "PO-SERAIPALLI*DIST-AZAMGARH*U.P.",
    fullName: "BAJARANGBALI ROYSHARMA",
    saID: "SPR2578",
  },
  {
    firstName: "LATE KALA CHAND",
    lastName: "RUDRA",
    address: "91/C.CHARAKDANGA ROAD*CALCUTTA-10",
    fullName: "LATE KALA CHAND RUDRA",
    saID: "SPR2579",
  },
  {
    firstName: "LATE PURNENDU KUMAR",
    lastName: "RUDRA",
    address: "2A RAJABAGAN STREET*HATIBAGAN*CALCUTTA-6",
    fullName: "LATE PURNENDU KUMAR RUDRA",
    saID: "SPR2580",
  },
  {
    firstName: "SUKDEB",
    lastName: "RUDRA",
    address:
      "PO+V-GANGADHARI*VIA-AMTALA*DIST-MURSHIDABAD-742121*PAN-ACZPR2425H",
    fullName: "SUKDEB RUDRA",
    saID: "SPR2581",
  },
  {
    firstName: "LATE GOSAIDAS",
    lastName: "RUDRAPAUL",
    address: "ACCOUNTS OFFICE(CIVIL),D.V.C*PO-MAITHONDAM*DHANBAD",
    fullName: "LATE GOSAIDAS RUDRAPAUL",
    saID: "SPR2582",
  },
  {
    firstName: "HEMENDRA",
    lastName: "RUDRAPAL",
    address:
      "VILL-SOUTH PULINPUR*PO-HOWAIBARI TELIAMURA*TRIPURA(WEST)-799205*PAN-AFXPR6032H",
    fullName: "HEMENDRA RUDRAPAL",
    saID: "SPR2583",
  },
  {
    firstName: "KAJAL KANTI",
    lastName: "RUDRAROY",
    address: "AT-CHHANBAN*PO-RANJARBAG*TRIPURA (SOUTH)-799114",
    fullName: "KAJAL KANTI RUDRAROY",
    saID: "SPR2584",
  },
  {
    firstName: "LATE BHAKTA MOHAN",
    lastName: "RUPINI",
    address: "BHIGUDASBARI*PO-CHAMPAKNAGAR*TRIPURA-799045",
    fullName: "LATE BHAKTA MOHAN RUPINI",
    saID: "SPR2585",
  },
  {
    firstName: "LATE PRIYANATH",
    lastName: "SADHAK",
    address: "NORTH BASUDEBPUR*PO.BELGHARIA*CALCUTTA-56",
    fullName: "LATE PRIYANATH SADHAK",
    saID: "SPR2586",
  },
  {
    firstName: "LATE JAGANNATH",
    lastName: "SADHU",
    address: "PO&VILL-DEVALAYA*DIST-24 PGS.",
    fullName: "LATE JAGANNATH SADHU",
    saID: "SPR2587",
  },
  {
    firstName: "NIRMAL KUMAR",
    lastName: "SADHU",
    address: "VILL&PO-BHABANIPUR*BIRBHUM 731126",
    fullName: "NIRMAL KUMAR SADHU",
    saID: "SPR2588",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "SADHU",
    address: "AT-AA-19 KESTAPUR*PO-PRAFULLA KANAN (W)*KOLKATA 700101",
    fullName: "LATE SANTOSH KUMAR SADHU",
    saID: "SPR2589",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "SADHUKHAN",
    address: "VIL-PABAKHALI*PO-SIBNIBASH*NADIA.*PAN ALVPS9726P",
    fullName: "GOBINDA CHANDRA SADHUKHAN",
    saID: "SPR2590",
  },
  {
    firstName: "LATE GOURHARI",
    lastName: "SADHUKHAN",
    address: "AT/PO.MOGRA*DIST.HOOGHLY",
    fullName: "LATE GOURHARI SADHUKHAN",
    saID: "SPR2591",
  },
  {
    firstName: "LATE NANDALAL",
    lastName: "SADHUKHAN",
    address: "GARIA STATION ROAD*PO-GARIA*24 PARGANAS",
    fullName: "LATE NANDALAL SADHUKHAN",
    saID: "SPR2592",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "SADHUKHAN",
    address: "C/O GANGADHAR MITRA*VILL-DHARSA*PO-G.I.P COLONY*HOWRAH",
    fullName: "LATE PANCHANAN SADHUKHAN",
    saID: "SPR2593",
  },
  {
    firstName: "LATE SADANANDA",
    lastName: "SADHUKHAN",
    address: "VILL.SULTANPUR*PO-KASIPUR*DIST.HOOGHLY",
    fullName: "LATE SADANANDA SADHUKHAN",
    saID: "SPR2594",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "SADHYA",
    address: "PO-SHYAMNAGAR*GOVT-QR,BLOCK-B,NO-52*24 PARGANAS (NORTH)-743127",
    fullName: "NARAYAN CHANDRA SADHYA",
    saID: "SPR2595",
  },
  {
    firstName: "LATE JIBACHHLAL",
    lastName: "SAH",
    address: "MIDDLE SCHOOL*PO-BIHARIGANJ*MADHEPURA.",
    fullName: "LATE JIBACHHLAL SAH",
    saID: "SPR2596",
  },
  {
    firstName: "KANAILAL",
    lastName: "SAHA",
    address:
      "MAHARAJA MANINDRA NANDI ROAD*ANJALIKA BHABAN*MANIPUR,NABADWIP*NADIA-741302",
    fullName: "KANAILAL SAHA",
    saID: "SPR2597",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "SAHA",
    address: "BELTALA PARK*PO-BALURGHAT*DAKSHIN DINAJPUR-733101",
    fullName: "AJIT KUMAR SAHA",
    saID: "SPR2598",
  },
  {
    firstName: "LATE AMULLYA KUMAR",
    lastName: "SAHA",
    address: "KIRINDUL SATSANG MANDIR*P.O.KIRINDUL*DIST-BASTER*C.G.",
    fullName: "LATE AMULLYA KUMAR SAHA",
    saID: "SPR2599",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "SAHA",
    address: "NETAJI NAGAR*PO-KHARUPETIA*DIST-DARRANG-784115*ASSAM",
    fullName: "ASHUTOSH SAHA",
    saID: "SPR2600",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "SAHA",
    address:
      "SATSANG VIHAR TURA*P.O.TURA*DIST-GARO HILLS*MEGHALAYA-794001*PAN-GWKPS5460J",
    fullName: "ASIT BARAN SAHA",
    saID: "SPR2601",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "SAHA",
    address: "PO/VILL-BANIPUR*DIST-24 PGS. 743233",
    fullName: "LATE ASWINI KUMAR SAHA",
    saID: "SPR2602",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "SAHA",
    address: "AGENT L.I.C.I*PO BARPETA ROAD*DIST KAMRUP",
    fullName: "LATE ASWINI KUMAR SAHA",
    saID: "SPR2603",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "SAHA",
    address: "SV 3/905 R.K.PURAM*NEW DELHI-10",
    fullName: "LATE ASWINI KUMAR SAHA",
    saID: "SPR2604",
  },
  {
    firstName: "LATE ATUL KRISHNA",
    lastName: "SAHA",
    address: "AT-DEBAGRAM*PO-GOALTORE*DIST-MIDNAPUR.",
    fullName: "LATE ATUL KRISHNA SAHA",
    saID: "SPR2605",
  },
  {
    firstName: "LATE BAIKUNTHA CHANDRA",
    lastName: "SAHA",
    address: "LEBUBAGAN*PO-ALIPURDUAR COURT*JALPAIGURI",
    fullName: "LATE BAIKUNTHA CHANDRA SAHA",
    saID: "SPR2606",
  },
  {
    firstName: "LATE BANAMALI",
    lastName: "SAHA",
    address: "AT/PO ABHOYNAGAR*AGARTALA*TRIPURA.",
    fullName: "LATE BANAMALI SAHA",
    saID: "SPR2607",
  },
  {
    firstName: "BIMALENDU",
    lastName: "SAHA",
    address:
      '" NIRODA BHAWAN "*WORD NO-37, SILIGURI*PO-GHUGUMALI*DIST-JALPAIGURI-734006',
    fullName: "BIMALENDU SAHA",
    saID: "SPR2608",
  },
  {
    firstName: "BISESHWAR",
    lastName: "SAHA",
    address: "AT/PO-BEHARIGANJ*DT-MADHEPURA",
    fullName: "BISESHWAR SAHA",
    saID: "SPR2609",
  },
  {
    firstName: "LATE BUDHANATH",
    lastName: "SAHA",
    address: "DUNLOP NEW QRT-N/S-2*PO-SAHAGANJ*DIST.HOOGHLY",
    fullName: "LATE BUDHANATH SAHA",
    saID: "SPR2610",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "SAHA",
    address: "KALIACHAK THANAPARA*PO-KALIACHAK*MALDA.",
    fullName: "CHITTARANJAN SAHA",
    saID: "SPR2611",
  },
  {
    firstName: "LATE DHIRENDRA LAL",
    lastName: "SAHA",
    address: "PO-LENGTISINGA*DT-BONGAIGAON 783384*ASSAM",
    fullName: "LATE DHIRENDRA LAL SAHA",
    saID: "SPR2612",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "SAHA",
    address: "VILL&PO-MOTHABARI*MALDA  732207",
    fullName: "LATE DHIRENDRA NATH SAHA",
    saID: "SPR2613",
  },
  {
    firstName: "DULAL",
    lastName: "SAHA",
    address: "AT-PATAKURA,GOLEBAGAN*COOCH BEHAR 736101*PAN-AKRPS9158A.",
    fullName: "DULAL SAHA",
    saID: "SPR2614",
  },
  {
    firstName: "LATE GOBINDA CHANDRA",
    lastName: "SAHA",
    address: "AT-BASIRHAT BELTOLA*PO-BASIRHAT*DIST-24 PARGANAS-(N)",
    fullName: "LATE GOBINDA CHANDRA SAHA",
    saID: "SPR2615",
  },
  {
    firstName: "LATE GOPIBALLAV",
    lastName: "SAHA",
    address: "16/1.KSHUDIRAM BOSE ROAD*PO-NABAGRAM*DIST.HOOGHLY",
    fullName: "LATE GOPIBALLAV SAHA",
    saID: "SPR2616",
  },
  {
    firstName: "LATE GOURMOHAN",
    lastName: "SAHA",
    address: "68 SURYA SEN STREET*CALCUTTA-9",
    fullName: "LATE GOURMOHAN SAHA",
    saID: "SPR2617",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "SAHA",
    address: "PLOT NO-6/1920*PO-GAYESHPUR*NADIA.",
    fullName: "LATE JAGADISH CHANDRA SAHA",
    saID: "SPR2618",
  },
  {
    firstName: "JAGANNATH",
    lastName: "SAHA",
    address: "NEAR PETROL PUMP*PO-BASIRHAT*24 PGS NORTH-743411",
    fullName: "JAGANNATH SAHA",
    saID: "SPR2619",
  },
  {
    firstName: "LATE JASODAJIBAN",
    lastName: "SAHA",
    address: "110/1P COSSIPORE ROAD*CALCUTTA-2",
    fullName: "LATE JASODAJIBAN SAHA",
    saID: "SPR2620",
  },
  {
    firstName: "LATE KRISNALAL",
    lastName: "SAHA",
    address: "KANAIPUR HARISAVA LANE*PO-KONNAGAR*HOOGHLY",
    fullName: "LATE KRISNALAL SAHA",
    saID: "SPR2621",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "SAHA",
    address: "SWASTI BASTRALAY HOJAI BAZAR*PO HOJAI*DIST-NAGAON*ASSAM",
    fullName: "LATE KUMUD RANJAN SAHA",
    saID: "SPR2622",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "SAHA",
    address: "VILL-PODMUR*PO-AMLAI*DIST-MURSHIDABAD-742301",
    fullName: "MADHUSUDAN SAHA",
    saID: "SPR2623",
  },
  {
    firstName: "LATE NABA KUMAR",
    lastName: "SAHA",
    address: "AT/PO-BARABELUN*DIST-BURDWAN",
    fullName: "LATE NABA KUMAR SAHA",
    saID: "SPR2624",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "SAHA",
    address: "AT-SRIRAMPUR*PO-BAHADURPUR*DIST-MALDA",
    fullName: "LATE NAGENDRA NATH SAHA",
    saID: "SPR2625",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "SAHA",
    address:
      "SATSANG MANDIR*NILGANJ RD, BELGHORIA*KOLKATA-700051*PAN-EYDPS7468M.",
    fullName: "NARAYAN CHANDRA SAHA",
    saID: "SPR2626",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "SAHA",
    address: "VILL-BHABANIPUR CHAR*PO-MILANGARH*DIST-HOOGHLY",
    fullName: "LATE NARAYAN CHANDRA SAHA",
    saID: "SPR2627",
  },
  {
    firstName: "LATE NITYA GOPAL",
    lastName: "SAHA",
    address:
      "KHARAGPUR SATSANG VIHAR*PO-KHARAGPUR*DIST-PASCHIM MEDINIPUR-721301.",
    fullName: "LATE NITYA GOPAL SAHA",
    saID: "SPR2628",
  },
  {
    firstName: "NRIPENDRA",
    lastName: "SAHA",
    address: "VILL-CHAYAGAON*PO-BIDYAPUR*GOALPARA",
    fullName: "NRIPENDRA SAHA",
    saID: "SPR2629",
  },
  {
    firstName: "NRIPENDRA KUMAR",
    lastName: "SAHA",
    address: "NO.2 ASUTOSH PALLY*COOCHBEHAR-736101",
    fullName: "NRIPENDRA KUMAR SAHA",
    saID: "SPR2630",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "SAHA",
    address: "MALLOCK BARGANG*PO-GUMACO*TRIPURA",
    fullName: "LATE PARESH CHANDRA SAHA",
    saID: "SPR2631",
  },
  {
    firstName: "PRANBALLAV",
    lastName: "SAHA",
    address: "SATSANG CENTER SIMURALI*PO-SIMURALI*NADIA 741248",
    fullName: "PRANBALLAV SAHA",
    saID: "SPR2632",
  },
  {
    firstName: "LATE PRANAB KRISNA",
    lastName: "SAHA",
    address: "ARANI ABASHAN,RADHANAGAR*PO-GHURNI*NADIA 741 101",
    fullName: "LATE PRANAB KRISNA SAHA",
    saID: "SPR2633",
  },
  {
    firstName: "PRANBALLAV",
    lastName: "SAHA",
    address: "JAGANNATHPUR*SIBRAMPUR*PO-ASUTI*CALCUTTA 61",
    fullName: "PRANBALLAV SAHA",
    saID: "SPR2634",
  },
  {
    firstName: "LATE RADHESHYAM",
    lastName: "SAHA",
    address: "14/2 M.B.ROAD*BELGHARIA*CALCUTTA-56",
    fullName: "LATE RADHESHYAM SAHA",
    saID: "SPR2635",
  },
  {
    firstName: "RAICHAND",
    lastName: "SAHA",
    address:
      "SAHA X-RAY CLINIC*FIRE SERVICE CHOUMOHONI*PO-RAMNAGAR, AGARTALA*TRIPURA WEST-799002",
    fullName: "RAICHAND SAHA",
    saID: "SPR2636",
  },
  {
    firstName: "LATE SADHU CHARAN",
    lastName: "SAHA",
    address: "PO-CHHAYGAON BAZAR*DIST-KAMRUP",
    fullName: "LATE SADHU CHARAN SAHA",
    saID: "SPR2637",
  },
  {
    firstName: "LATE SAMBHU NATH",
    lastName: "SAHA",
    address: "SATSANG VIHAR,AMGOLA*MUZAFFARPUR",
    fullName: "LATE SAMBHU NATH SAHA",
    saID: "SPR2638",
  },
  {
    firstName: "LATE SHANTI RANJAN",
    lastName: "SAHA",
    address: "SWASTI NIWAS*GOPALPUR*PO-GHATSILA*SINGBHUM",
    fullName: "LATE SHANTI RANJAN SAHA",
    saID: "SPR2639",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "SAHA",
    address: "BLOCK NO-10.S.B.66*PO-FARAKKA BARRAGE*DIST-MURSHIDABAD-742212",
    fullName: "SHYAMAL KUMAR SAHA",
    saID: "SPR2640",
  },
  {
    firstName: "LATE SIBAPADA",
    lastName: "SAHA",
    address: "AT-ARJUNPUR*PO-MAGRAHAT*DIST-24 PARGANAS (S)",
    fullName: "LATE SIBAPADA SAHA",
    saID: "SPR2641",
  },
  {
    firstName: "LATE SIBKALI",
    lastName: "SAHA",
    address: "26/2 RAMCHANDRA MUKHARJEE LANE*CALCUTTA-36",
    fullName: "LATE SIBKALI SAHA",
    saID: "SPR2642",
  },
  {
    firstName: "SUDARSHAN",
    lastName: "SAHA",
    address: "AT-SALTORA*PO-SABRAKONE*DIST-BANKURA",
    fullName: "SUDARSHAN SAHA",
    saID: "SPR2643",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "SAHA",
    address: "PO-PATIRAM*D/DINAJPUR",
    fullName: "LATE SUDHIR KUMAR SAHA",
    saID: "SPR2644",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "SAHA",
    address: "VIL&PO.ARANGHATA*NADIA",
    fullName: "LATE SUDHIR KUMAR SAHA",
    saID: "SPR2645",
  },
  {
    firstName: "SUKHARANJAN",
    lastName: "SAHA",
    address: "ARUNDHUTINAGAR ROAD-3*PO-ARUNDHUTI NAGAR*TRIPURA WEST 799003.",
    fullName: "SUKHARANJAN SAHA",
    saID: "SPR2646",
  },
  {
    firstName: "SUKUMAR",
    lastName: "SAHA",
    address:
      'EDITOR""DEEPTADEEN"*VILL-STAFF QTR *PO-HABRA*24 PGS (NORTH)*PIN-743263',
    fullName: "SUKUMAR SAHA",
    saID: "SPR2647",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "SAHAKUNDU",
    address: "MISSION COMPOUND*PO-BOLPUR*BIRBHUM",
    fullName: "LATE KALIPADA SAHAKUNDU",
    saID: "SPR2648",
  },
  {
    firstName: "LATE RAMLOLIN  PRASAD",
    lastName: "SAHANI",
    address: "VILL&PO-THATHAN BUZARG*VIA-SARAI*VAISHALI 844125",
    fullName: "LATE RAMLOLIN  PRASAD SAHANI",
    saID: "SPR2649",
  },
  {
    firstName: "ADHIR CHANDRA",
    lastName: "SAHAROY",
    address: "AT-CHUAKHOLA*PO-FALAKATA*JALPAIGURI",
    fullName: "ADHIR CHANDRA SAHAROY",
    saID: "SPR2650",
  },
  {
    firstName: "LATE CHITRAGUPT",
    lastName: "SAHAY",
    address: "RAJENDRANAGAR MADHUBANI*PO/DIST-PURNEA*PIN 854301",
    fullName: "LATE CHITRAGUPT SAHAY",
    saID: "SPR2651",
  },
  {
    firstName: "LATE SHEONANDAN",
    lastName: "SAHAY",
    address:
      "HOMEO DISPENSARY*IN FRONT OF MAHAVIR TEMPLE*AT&PO-BARAUNI*BEGUSARAI",
    fullName: "LATE SHEONANDAN SAHAY",
    saID: "SPR2652",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "SAHOO",
    address:
      "VILL-CHANABERIA*PO-DHODANGRI*DIST-PASCHIM MEDINIPUR*PAN-AKEPS7840Q",
    fullName: "ATUL CHANDRA SAHOO",
    saID: "SPR2653",
  },
  {
    firstName: "LATE BABAJI",
    lastName: "SAHOO",
    address: "AT-MALIGAON*PO-LEMALO*CUTTACK",
    fullName: "LATE BABAJI SAHOO",
    saID: "SPR2654",
  },
  {
    firstName: "BAIKUNTHA NATH",
    lastName: "SAHU",
    address:
      "AT/PO-BACHHURIPAL*VIA-RASGOBINDAPUR*MAYURBHANJ-757016*PAN-AOKPS4516F",
    fullName: "BAIKUNTHA NATH SAHU",
    saID: "SPR2655",
  },
  {
    firstName: "BAIKUNTHA NATH",
    lastName: "SAHU",
    address: "AT/PO SANKERKO*MAYURBHANJ-757024*PAN-BWGPS4899L",
    fullName: "BAIKUNTHA NATH SAHU",
    saID: "SPR2656",
  },
  {
    firstName: "BANAMALI",
    lastName: "SAHOO",
    address:
      "AT-ANANDA NAGAR(CHARGHATI)*PO-DHANUPALI*SAMBALPUR-768001*PAN-FBTPS9040F",
    fullName: "BANAMALI SAHOO",
    saID: "SPR2657",
  },
  {
    firstName: "BANSHIDHAR",
    lastName: "SAHOO",
    address: "AT-NAHUNI*PO-PEGARPARA*VIA-OLAVER*KENDRAPARA.",
    fullName: "BANSHIDHAR SAHOO",
    saID: "SPR2658",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "SAHOO",
    address: "SATSANG THAKUR BARI*C T ROAD*PURI-752002",
    fullName: "BASANTA KUMAR SAHOO",
    saID: "SPR2659",
  },
  {
    firstName: "BHAGIRATHI",
    lastName: "SAHOO",
    address: "AT.BAJAPUR*PO.K.N.PATANA*VIA.KAKATPUR*PURI-752108*PAN-GNZPS2637K",
    fullName: "BHAGIRATHI SAHOO",
    saID: "SPR2660",
  },
  {
    firstName: "BHIMASEN",
    lastName: "SAHOO",
    address: "DULADEI ROAD*KALYANI NAGAR*CUTTACK-753013",
    fullName: "BHIMASEN SAHOO",
    saID: "SPR2661",
  },
  {
    firstName: "LATE BHRAMARBAR",
    lastName: "SAHOO",
    address: "PLOT NO.335 BRAHMHESWAR COLONY*AT&PO-RASULGARH*BHUBANESWAR 10",
    fullName: "LATE BHRAMARBAR SAHOO",
    saID: "SPR2662",
  },
  {
    firstName: "BHRAMARBAR",
    lastName: "SAHOO",
    address: "AT/PO-MATHURA*VIA-POLASARA*DIST-GANJAM-761112*PAN-AYSPS0698C",
    fullName: "BHRAMARBAR SAHOO",
    saID: "SPR2663",
  },
  {
    firstName: "LATE BIPATI HARAN",
    lastName: "SAHOO",
    address: "DHOBABERIA*PO-SAFIABAD*MIDNAPORE 72 442.",
    fullName: "LATE BIPATI HARAN SAHOO",
    saID: "SPR2664",
  },
  {
    firstName: "LATE BISHNU CHARAN",
    lastName: "SAHOO",
    address:
      "STORES & MECH DIVN,JUNAGARH*U.I.P.JUNAGARH*SEC-II,QR NO. E/14*KALAHANDI",
    fullName: "LATE BISHNU CHARAN SAHOO",
    saID: "SPR2665",
  },
  {
    firstName: "LATE BISWANATH",
    lastName: "SAHOO",
    address:
      "353,BRAHMESWAR NAGAR*RASULGARH SATSANG VIHAR FRONT*BHUBANESWAR-10*KHURDA",
    fullName: "LATE BISWANATH SAHOO",
    saID: "SPR2666",
  },
  {
    firstName: "BRAJA KISHORE",
    lastName: "SAHOO",
    address: "AT-KUNDIBAG*PO.BHADRAK BYPASS*BHADRAK 756181*PAN-AFVPS0862A",
    fullName: "BRAJA KISHORE SAHOO",
    saID: "SPR2667",
  },
  {
    firstName: "BRAJASUNDAR",
    lastName: "SAHOO",
    address: "AT/PO-SATSANG 814116*SATSANG.",
    fullName: "BRAJASUNDAR SAHOO",
    saID: "SPR2668",
  },
  {
    firstName: "BRIJ LAL",
    lastName: "SAHOO",
    address: "MAHAKHUTI T.E.*PO-CHANDBASA*SIBSAGAR-785674*ASSAM",
    fullName: "BRIJ LAL SAHOO",
    saID: "SPR2669",
  },
  {
    firstName: "BUDDHIMANTA",
    lastName: "SAHOO",
    address: "AT-DIHADOL*PO-PARJANG*DHENKANAL 759120.",
    fullName: "BUDDHIMANTA SAHOO",
    saID: "SPR2670",
  },
  {
    firstName: "LATE CHAITANYA CHARAN",
    lastName: "SAHOO",
    address: "AT-KUSHANPURI*PO-BHATLI*SAMBALPUR.",
    fullName: "LATE CHAITANYA CHARAN SAHOO",
    saID: "SPR2671",
  },
  {
    firstName: "CHAKRADHAR",
    lastName: "SAHOO",
    address: "AT-NARENDRAPUR*PO-JALESWARPADA*VIA-KAKATPUR*PURI-752108",
    fullName: "CHAKRADHAR SAHOO",
    saID: "SPR2672",
  },
  {
    firstName: "DHANURDHAR",
    lastName: "SAHOO",
    address: "DHRUTI BITAN*AT-JANARDANPUR*PO-LENDURA*CUTTACK 754203",
    fullName: "DHANURDHAR SAHOO",
    saID: "SPR2673",
  },
  {
    firstName: "DHRUBA CHARAN",
    lastName: "SAHOO",
    address:
      "CHAHATA NAGAR,LANE-4*PLOT NO-877/961*PO-ABHINABA BIDANASI*CUTTACK-14*PAN-ALQPS2393R",
    fullName: "DHRUBA CHARAN SAHOO",
    saID: "SPR2674",
  },
  {
    firstName: "DILESWAR",
    lastName: "SAHOO",
    address: "AT/PO-GURUSULEI*VIA-PALLAHARA*ANGUL-759119",
    fullName: "DILESWAR SAHOO",
    saID: "SPR2675",
  },
  {
    firstName: "DINABANDHU",
    lastName: "SAHOO",
    address: "AT.THAKURPATANA*PO.SANKHACHILLA*CUTTACK-755015",
    fullName: "DINABANDHU SAHOO",
    saID: "SPR2676",
  },
  {
    firstName: "DUSHASAN",
    lastName: "SAHOO",
    address: "JUBULEE MARKET*AT/PO/DIST-NAYAGARH-752069",
    fullName: "DUSHASAN SAHOO",
    saID: "SPR2677",
  },
  {
    firstName: "FAKIR",
    lastName: "SAHOO",
    address: "AT-AHUMUNIAPATANA*PO-RASULPUR*VIA-KAKATPUR-752108*PURI",
    fullName: "FAKIR SAHOO",
    saID: "SPR2678",
  },
  {
    firstName: "GIRINDRA KUMAR",
    lastName: "SAHOO",
    address:
      "AT-KALYAN NAGAR*S.R.I.T.COLONY*PO-BUDHARAJA*SAMBALPUR-768004*PAN-AHZPS1304D",
    fullName: "GIRINDRA KUMAR SAHOO",
    saID: "SPR2679",
  },
  {
    firstName: "GOLOK BEHARI",
    lastName: "SAHOO",
    address: "AT/PO-SIARIA*CUTTACK",
    fullName: "GOLOK BEHARI SAHOO",
    saID: "SPR2680",
  },
  {
    firstName: "GORAKH NATH",
    lastName: "SAHOO",
    address: "VILL-CHAPAGURI*PO-GHORAMARI*SONITPUR-784105*ASSAM",
    fullName: "GORAKH NATH SAHOO",
    saID: "SPR2681",
  },
  {
    firstName: "HARIHAR",
    lastName: "SAHOO",
    address: "AHMUNIA PATANA*PO-RASULPUR*VIA-LATAHARAN*PURI 752119",
    fullName: "HARIHAR SAHOO",
    saID: "SPR2682",
  },
  {
    firstName: "JATADHARI",
    lastName: "SAHOO",
    address: "AT/PO-NUAGAON*VIA-DEBIDOL*JAGATSINGHPUR-754109*PAN-DJHPS2192N",
    fullName: "JATADHARI SAHOO",
    saID: "SPR2683",
  },
  {
    firstName: "LATE JAY KRISHNA",
    lastName: "SAHOO",
    address: "ASST.ENGINEER,LIFT IRRIGATION*AT-BANKA SAHI*PO/DIST-BHADRAK",
    fullName: "LATE JAY KRISHNA SAHOO",
    saID: "SPR2684",
  },
  {
    firstName: "YUDHISTIR",
    lastName: "SAHOO",
    address: "UCO BANK,RAJNILGIRI*PO-RAJNILGIRI*BALASORE-756040",
    fullName: "YUDHISTIR SAHOO",
    saID: "SPR2685",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "SAHOO",
    address: "AT.BANKSARPUR*PO.ANANTAPUR*BALASORE.",
    fullName: "KAILASH CHANDRA SAHOO",
    saID: "SPR2686",
  },
  {
    firstName: "KANDURI CHARAN",
    lastName: "SAHOO",
    address: "AT/PO-RENANDA PATANA*VIA-BHUBAN*DHENKANAL 759017",
    fullName: "KANDURI CHARAN SAHOO",
    saID: "SPR2687",
  },
  {
    firstName: "KEDAR NATH",
    lastName: "SAHOO",
    address: '"SUBHASIS"*SRIRAMNAGAR*BHUBANESWAR-751002*KHURDA',
    fullName: "KEDAR NATH SAHOO",
    saID: "SPR2688",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "SAHOO",
    address: "AT-RADHADARSANPUR*PO-KHUNTAKATA*VIA-ATHGARH*CUTTACK PIN-754029.",
    fullName: "LAXMIDHAR SAHOO",
    saID: "SPR2689",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "SAHOO",
    address: "DEPARTMENTAL TELEGRAPH OFFICE*SAMBALPUR-768001",
    fullName: "LAXMIDHAR SAHOO",
    saID: "SPR2690",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "SAHOO",
    address:
      "AT-NABINABAG *KHURDA HOLDING NO-154*PO/DIST-KHURDA-752055*PAN-DTSPS2853D",
    fullName: "LAXMIDHAR SAHOO",
    saID: "SPR2691",
  },
  {
    firstName: "MADHAB",
    lastName: "SAHOO",
    address:
      "AT-RANIGODA,NAYAKSAHI*NEAR HANUMAN STATUE*PO-TURANG*ANGUL 759123.",
    fullName: "MADHAB SAHOO",
    saID: "SPR2692",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "SAHOO",
    address: "AT/PO-KAPASI CHHAK*VIA-DEVIDWAR*CUTTACK",
    fullName: "LATE NARAYAN CHANDRA SAHOO",
    saID: "SPR2693",
  },
  {
    firstName: "NETRANANDA",
    lastName: "SAHOO",
    address:
      "SATSANG KENDRA BRAMHANPARA*PATNA JEWELLERY*PO/DIST.BOLANGIR 767001",
    fullName: "NETRANANDA SAHOO",
    saID: "SPR2694",
  },
  {
    firstName: "NITYANANDA",
    lastName: "SAHOO",
    address: "AT-CHHATRA*PO-SRIRAMPUR*BALASORE-756029*PAN-FISPS4485J",
    fullName: "NITYANANDA SAHOO",
    saID: "SPR2695",
  },
  {
    firstName: "PRAN KRISHNA",
    lastName: "SAHOO",
    address: "VILL/PO-JUMKI*VIA-EGRA*DIST-PURBA MEDINIPUR-721429.",
    fullName: "PRAN KRISHNA SAHOO",
    saID: "SPR2696",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "SAHOO",
    address:
      "C/O BABAJI SAHOO*AT.MALIGAON*PO.LEMALO*VIA.KENDUPATNA*CUTTACK-754293",
    fullName: "PRAVAKAR SAHOO",
    saID: "SPR2697",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "SAHOO",
    address: "AT/PO-PALLAHARA*DIST-ANGUL-759119*PAN-AOKPS4600N",
    fullName: "RABINDRA KUMAR SAHOO",
    saID: "SPR2698",
  },
  {
    firstName: "RABINDRA PRASAD",
    lastName: "SAHOO",
    address: "AT-BHAGAPUR*PO-ARUHA*BALASORE-756044",
    fullName: "RABINDRA PRASAD SAHOO",
    saID: "SPR2699",
  },
  {
    firstName: "LATE RAMA CHANDRA",
    lastName: "SAHOO",
    address: "AT/PO.MOHANTIPARA*VIA.B.T.PUR*BHADRAK.",
    fullName: "LATE RAMA CHANDRA SAHOO",
    saID: "SPR2700",
  },
  {
    firstName: "LATE SADANANDA",
    lastName: "SAHOO",
    address: "VILL-NUAREKULA*PO-KAMAKHYANAGAR*DHENKANAL-759018",
    fullName: "LATE SADANANDA SAHOO",
    saID: "SPR2701",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "SAHOO",
    address: "AT-MANGARAJPUR*PO-S MANGARAJPUR*KENDRAPARA.          .",
    fullName: "SARAT CHANDRA SAHOO",
    saID: "SPR2702",
  },
  {
    firstName: "SUJAN KUMAR",
    lastName: "SAHOO",
    address: "AT-RAGHABPUR*PO-MANDIA*CUTTACK.",
    fullName: "SUJAN KUMAR SAHOO",
    saID: "SPR2703",
  },
  {
    firstName: "LATE SURENDRA NATH",
    lastName: "SAHOO",
    address: "KRUSTIDHAM*CHUNNABELLARI*PO-PARADEEP GARH*JAGATSINGHPUR 754142",
    fullName: "LATE SURENDRA NATH SAHOO",
    saID: "SPR2704",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "SAMADDAR",
    address: "4,RABINDRA NAGAR*BEHALA*CALCUTTA-700060.",
    fullName: "LATE SATISH CHANDRA SAMADDAR",
    saID: "SPR2705",
  },
  {
    firstName: "LATE PRASANT KUMAR",
    lastName: "SAMADDER",
    address: "VIVEKANANDA COLONY*BHATTA BAZAR*PURNIA",
    fullName: "LATE PRASANT KUMAR SAMADDER",
    saID: "SPR2706",
  },
  {
    firstName: "LATE SUBAL CHANDRA",
    lastName: "SAMAJDAR",
    address: "QR.NO 1B,AVENUE B*SECTOR-2,BHILAI-1*DURG",
    fullName: "LATE SUBAL CHANDRA SAMAJDAR",
    saID: "SPR2707",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "SAMAJDAR",
    address: "69/1  S.K.DEV ROAD*BLOCK NO-A,FLAT NO-12*CALCUTTA-48",
    fullName: "LATE SUDHIR KUMAR SAMAJDAR",
    saID: "SPR2708",
  },
  {
    firstName: "AJIT",
    lastName: "SAMAJPATI",
    address: "6/A SURYA SEN NAGAR*CALCUTTA 700 061",
    fullName: "AJIT SAMAJPATI",
    saID: "SPR2709",
  },
  {
    firstName: "LATE BAURIBANDHU",
    lastName: "SAMAL",
    address: "V-DHANISHO*PO.BAIGANI*VIA.BALIKUDA*CUTTACK",
    fullName: "LATE BAURIBANDHU SAMAL",
    saID: "SPR2710",
  },
  {
    firstName: "BENUDHAR",
    lastName: "SAMAL",
    address:
      "SUDHRITI NILAY*GURUVARISAHI, SRIKORUAN*PO-URALI*VIA-GOPALPUR*CUTTACK-753011.",
    fullName: "BENUDHAR SAMAL",
    saID: "SPR2711",
  },
  {
    firstName: "KUMUD BANDHU",
    lastName: "SAMAL",
    address: "VILL-RAMA*PO-LAMDNGRI*DIST-MIDNAPORE",
    fullName: "KUMUD BANDHU SAMAL",
    saID: "SPR2712",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "SAMAL",
    address: "AT/PO.KAMALPUR*JAJPUR-755036*PAN NO-GORPS3387R",
    fullName: "NARAYAN CHANDRA SAMAL",
    saID: "SPR2713",
  },
  {
    firstName: "BABLU",
    lastName: "SAMANTA",
    address: "VILL-HATKIRTINAGAR*PO-HATKIRTINAGAR*BURDWAN-713128",
    fullName: "BABLU SAMANTA",
    saID: "SPR2714",
  },
  {
    firstName: "LATE BANBEHARI",
    lastName: "SAMANTA",
    address: "VILL&PO-GEONKHALI*DT-MIDNAPORE.",
    fullName: "LATE BANBEHARI SAMANTA",
    saID: "SPR2715",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "SAMANTA",
    address: "VILL-PRATAP CHAK*PO-UDAYNARAYANPUR*DIST-HOWRAH.",
    fullName: "LATE GANESH CHANDRA SAMANTA",
    saID: "SPR2716",
  },
  {
    firstName: "GOURHARI",
    lastName: "SAMANTA",
    address:
      "AT-GOBINDAPUR*PO-JOTEBANI*DIST-PASCHIM MEDINIPUR-721211*PAN FCVPS0713Q",
    fullName: "GOURHARI SAMANTA",
    saID: "SPR2717",
  },
  {
    firstName: "LATE JAJNESWAR",
    lastName: "SAMANTA",
    address: "PO/VILL-GOPALPUR*MIDNAPORE",
    fullName: "LATE JAJNESWAR SAMANTA",
    saID: "SPR2718",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "SAMANTA",
    address: "AT/PO-GHATAL*DIST-MIDNAPORE  721212.",
    fullName: "MADAN MOHAN SAMANTA",
    saID: "SPR2719",
  },
  {
    firstName: "RAMPADA",
    lastName: "SAMANTA",
    address: "RAMDEVPUR*PO-CHETUARAJNAGAR*DIST-PASCHIM MEDINIPUR-721211",
    fullName: "RAMPADA SAMANTA",
    saID: "SPR2720",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "SAMANTA",
    address: "PO+VILL-PALASHPAI*DIST-PASCHIM MEDINIPUR-721146",
    fullName: "SANTOSH KUMAR SAMANTA",
    saID: "SPR2721",
  },
  {
    firstName: "LATE SITAL CHANDRA",
    lastName: "SAMANTA",
    address: "PO.KISHOREPUR*DIST.HOOGHLY",
    fullName: "LATE SITAL CHANDRA SAMANTA",
    saID: "SPR2722",
  },
  {
    firstName: "SUBODH KUMAR",
    lastName: "SAMANTA",
    address: "VILL-BALGONA CHATI*PO-NITYANANDAPUR*BURDWAN",
    fullName: "SUBODH KUMAR SAMANTA",
    saID: "SPR2723",
  },
  {
    firstName: "LATE SUDHENDU SEKHAR",
    lastName: "SAMANTA",
    address: "AT-DWARIBAND*PO-MEDINIPUR*PASCHIM MEDINIPUR.",
    fullName: "LATE SUDHENDU SEKHAR SAMANTA",
    saID: "SPR2724",
  },
  {
    firstName: "LATE SUKHAMOY",
    lastName: "SAMANTA",
    address: "VILL/PO-FATEPUR*VIA-PALAJORI*DUMKA",
    fullName: "LATE SUKHAMOY SAMANTA",
    saID: "SPR2725",
  },
  {
    firstName: "LATE BANSHIDHAR",
    lastName: "SAMANTARAY",
    address: "E B-229,STAGE-II*LAXMISAGAR*B R I T COLLONEY*BHUBANESWAR-6*PURI.",
    fullName: "LATE BANSHIDHAR SAMANTARAY",
    saID: "SPR2726",
  },
  {
    firstName: "NARAYAN",
    lastName: "SAMANTAROY",
    address: "AT/PO.DASARATHPUR*JAJPUR-755006",
    fullName: "NARAYAN SAMANTAROY",
    saID: "SPR2727",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "SAMANTARAY",
    address: "SATSANG VIHAR JAGATSINGHPUR*PO&DIST-JAGATSINGHPUR-754103",
    fullName: "ASHUTOSH SAMANTARAY",
    saID: "SPR2728",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "SANA",
    address: "AT/PO-KALIMELA-764047*DIST-MALKANGIRI (ORISSA)",
    fullName: "DHIRENDRA NATH SANA",
    saID: "SPR2729",
  },
  {
    firstName: "HAZARI LAL",
    lastName: "SANA",
    address: "C/O SHIBAPADA SANA*DHARAMJAIGARH COLONY*PO-SHAHAPUR*RAIGARH*M.P.",
    fullName: "HAZARI LAL SANA",
    saID: "SPR2730",
  },
  {
    firstName: "KALIDAS",
    lastName: "SANA",
    address:
      "AT-CHANDRAPUR BENGALI CAMP*STAFF QRS NO-2/5*PO/DIST-CHANDRAPUR*M S 442401",
    fullName: "KALIDAS SANA",
    saID: "SPR2731",
  },
  {
    firstName: "BADAL PADA",
    lastName: "SANTRA",
    address: "CHIRANJIBPUR*C.P.T.DY.MANAGER-3,1+CF.*PO-HALDIA*DIST-MIDNAPORE.",
    fullName: "BADAL PADA SANTRA",
    saID: "SPR2732",
  },
  {
    firstName: "LATE MADHUSUDAN",
    lastName: "SANYAL",
    address: "VILL-HARINAVI*PO-RAJPUR*24PARGANAS",
    fullName: "LATE MADHUSUDAN SANYAL",
    saID: "SPR2733",
  },
  {
    firstName: "DHRUBA CHARAN",
    lastName: "SARANGI",
    address: "AT-SANATHAKAN*PO-CHARIRAKABA*CUTTACK-754280*PAN-BDWPS0634Q",
    fullName: "DHRUBA CHARAN SARANGI",
    saID: "SPR2734",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "SARANGI",
    address: "AT/PO-RAMKRISHNAPUR*VIA-RAMESWAR*DIST-CUTTACK-754201.",
    fullName: "JITENDRA NATH SARANGI",
    saID: "SPR2735",
  },
  {
    firstName: "KALIPADA",
    lastName: "SARDAR",
    address:
      "AT+PO-SRIDHARKATI*VIA-HATGACHA*DIST-24-PARGANAS(NORTH)-743439*PAN-ANCPS4927K",
    fullName: "KALIPADA SARDAR",
    saID: "SPR2736",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "SARDAR",
    address: "AT-BIHARI*PO-DHAMUA*DIST-24 PGS.",
    fullName: "LATE ANANTA KUMAR SARDAR",
    saID: "SPR2737",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "SARDER",
    address:
      "VILL+PO-SRIKRISHNANAGAR*VIA-DAKSHIN BARASAT*DIST-24PARGANAS SOUTH-743372",
    fullName: "BIBHUTI BHUSAN SARDER",
    saID: "SPR2738",
  },
  {
    firstName: "LATE LALIT MOHAN",
    lastName: "SARDER",
    address: "AT-DUMKI*PO-JOYRAMKHALI*DIST-24PARGANAS",
    fullName: "LATE LALIT MOHAN SARDER",
    saID: "SPR2739",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "SARDERDEBBARMAN",
    address: "C/O-HAV HARASHIT ROY*NO-13853053, 228/2283 MED REGT*C/O-56APO",
    fullName: "LATE JOGESH CHANDRA SARDERDEBBARMAN",
    saID: "SPR2740",
  },
  {
    firstName: "LATE CHANDRA KANTA",
    lastName: "SARGIARY",
    address: "AT+PO-UDALGURI*DARRANG.",
    fullName: "LATE CHANDRA KANTA SARGIARY",
    saID: "SPR2741",
  },
  {
    firstName: "SITARAM",
    lastName: "SWARGIARY",
    address: "VILL-SONAPUR*PO-SOMKANG*DIST-DHEMAJI 787059*ASSAM",
    fullName: "SITARAM SWARGIARY",
    saID: "SPR2742",
  },
  {
    firstName: "LATE ABONI BHUSAN",
    lastName: "SARKAR",
    address: "AT+PO-UDALGURI*DIST-DARRANG.",
    fullName: "LATE ABONI BHUSAN SARKAR",
    saID: "SPR2743",
  },
  {
    firstName: "LATE ABUL HOSSAIN",
    lastName: "SARKAR",
    address: "C/O RANI PRESS*PO MOGRA*DIST-HOOGHLY",
    fullName: "LATE ABUL HOSSAIN SARKAR",
    saID: "SPR2744",
  },
  {
    firstName: "AKHIL CHANDRA",
    lastName: "SARKAR",
    address: "VILL-PURBA NARARTHALI*PO-KHOWERDANGA*JALPAIGURI-736202",
    fullName: "AKHIL CHANDRA SARKAR",
    saID: "SPR2745",
  },
  {
    firstName: "AMULYARATAN",
    lastName: "SARKAR",
    address: "BADKULYA KADAMTALA*PO-BADKULYA*NADIA-741121",
    fullName: "AMULYARATAN SARKAR",
    saID: "SPR2746",
  },
  {
    firstName: "ANANGA MOHAN",
    lastName: "SARKAR",
    address: "JANATA LANE*PO-SILCHAR-5*DIST-SILCHAR-788005*ASSAM",
    fullName: "ANANGA MOHAN SARKAR",
    saID: "SPR2747",
  },
  {
    firstName: "BABUL CHANDRA",
    lastName: "SARKAR",
    address: "AT&PO-BALURGHAT*DAKSHIN DINAJPUR-733101",
    fullName: "BABUL CHANDRA SARKAR",
    saID: "SPR2748",
  },
  {
    firstName: "LATE BEJOY KRISNA",
    lastName: "SARKAR",
    address: "BARAKULBERIA*PO.KALIGANJ*NADIA",
    fullName: "LATE BEJOY KRISNA SARKAR",
    saID: "SPR2749",
  },
  {
    firstName: "LATE BHAJAHARI",
    lastName: "SARKAR",
    address: "AT/P.O.TURA BAZAR*GARO HILLS",
    fullName: "LATE BHAJAHARI SARKAR",
    saID: "SPR2750",
  },
  {
    firstName: "BHUBAN MOHAN",
    lastName: "SARKAR",
    address: "169/1/5 KASTHADANGA RD.*PO-SARSUNA*CALCUTTA-61",
    fullName: "BHUBAN MOHAN SARKAR",
    saID: "SPR2751",
  },
  {
    firstName: "BIJAY SANKAR",
    lastName: "SARKAR",
    address: "307,STATION WORKSHOP E.M.E*C/O 99APO",
    fullName: "BIJAY SANKAR SARKAR",
    saID: "SPR2752",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "SARKAR",
    address: "WEST APCAR GARDEN*PO-ASANSOLE*BURDWAN 713304",
    fullName: "LATE BIMAL CHANDRA SARKAR",
    saID: "SPR2753",
  },
  {
    firstName: "LATE BIMAL KUMAR",
    lastName: "SARKAR",
    address: "AT-BIRESHPALLY*PO-MADHYAMGRAM*DIST-24 PARGANAS.",
    fullName: "LATE BIMAL KUMAR SARKAR",
    saID: "SPR2754",
  },
  {
    firstName: "BIMAL KUMAR",
    lastName: "SARKAR",
    address:
      "C/O.RENU BANERJEE*77 BHATTACHARJEEE GARDEN ROAD*PO-SERAMPUR*DIST.HOOGHLY",
    fullName: "BIMAL KUMAR SARKAR",
    saID: "SPR2755",
  },
  {
    firstName: "BIMAL KUMAR",
    lastName: "SARKAR",
    address:
      "PHULBARI SATSANG KENDRA*VIA-BHEBIA*BASIRHAT*NORTH 24 PARGANAS-743456",
    fullName: "BIMAL KUMAR SARKAR",
    saID: "SPR2756",
  },
  {
    firstName: "LATE BIMALAKSHA",
    lastName: "SARKAR",
    address: "AT&PO-BOYAR*VIA-SAGARDIGHI*MURSHIDABAD-742226",
    fullName: "LATE BIMALAKSHA SARKAR",
    saID: "SPR2757",
  },
  {
    firstName: "LATE BIR CHARAN",
    lastName: "SARKAR",
    address: "C/O.GOPAL CHAND DUTTA*P.O.BOKOJAN*MIKIR HILLS",
    fullName: "LATE BIR CHARAN SARKAR",
    saID: "SPR2758",
  },
  {
    firstName: "LATE BIRENDRA KISORE",
    lastName: "SARKAR",
    address: "PO-MADARIHAT*JALPAIGURI-735220",
    fullName: "LATE BIRENDRA KISORE SARKAR",
    saID: "SPR2759",
  },
  {
    firstName: "BIRUPAKSHYA",
    lastName: "SARKAR",
    address: "9,MANDEVILLA GARDENS FLAT-3A*CALCUTTA 700 019",
    fullName: "BIRUPAKSHYA SARKAR",
    saID: "SPR2760",
  },
  {
    firstName: "BISWAPATI",
    lastName: "SARKAR",
    address: "*SATSANG 814116.",
    fullName: "BISWAPATI SARKAR",
    saID: "SPR2761",
  },
  {
    firstName: "LATE BRAJAGOPAL",
    lastName: "SARKAR",
    address: "AT-RAMKRISHNANAGAR*PO-LASKARPUR*VIA-GARIA* DIST-24 PGS.",
    fullName: "LATE BRAJAGOPAL SARKAR",
    saID: "SPR2762",
  },
  {
    firstName: "LATE CHAND MOHAN",
    lastName: "SARKAR",
    address: "RAMKRISHNA COLONY*PO-MAL*DT-JALPAIGURI.",
    fullName: "LATE CHAND MOHAN SARKAR",
    saID: "SPR2763",
  },
  {
    firstName: "CHANDRA KUMAR",
    lastName: "SARKAR",
    address: "V-LAKSHMINARAYANPUR*PO-AHARA*DIST-MALDA.",
    fullName: "CHANDRA KUMAR SARKAR",
    saID: "SPR2764",
  },
  {
    firstName: "LATE DHANANJOY",
    lastName: "SARKAR",
    address: "AT-BAMUNIA*PO-DALALPARA*DIST-MURSHIDABAD.",
    fullName: "LATE DHANANJOY SARKAR",
    saID: "SPR2765",
  },
  {
    firstName: "LATE DHARANI KANTA",
    lastName: "SARKAR",
    address: "CHANBILGIRI TURA*PO-SIBBARI*DT-GARO HILLS",
    fullName: "LATE DHARANI KANTA SARKAR",
    saID: "SPR2766",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "SARKAR",
    address: "31A/H/2,BAGHMARI ROAD*CALCUTTA-700054",
    fullName: "LATE DHIRENDRA KUMAR SARKAR",
    saID: "SPR2767",
  },
  {
    firstName: "LATE DWIJENDRA LAL",
    lastName: "SARKAR",
    address: "SWASTIPALLI*PO JOTRAM*DIST BURDWAN",
    fullName: "LATE DWIJENDRA LAL SARKAR",
    saID: "SPR2768",
  },
  {
    firstName: "LATE FAKIR CHANDRA",
    lastName: "SARKAR",
    address:
      "SUKANTAPALLI(GHOLA)*PO-GHOLA BAZAR(SODEPUR)*24PARGANAS NORTH 743150",
    fullName: "LATE FAKIR CHANDRA SARKAR",
    saID: "SPR2769",
  },
  {
    firstName: "LATE GOBINDA DAS",
    lastName: "SARKAR",
    address: "AT-BALIADANGA*PO-KALIACHAK*DIST-MALDA",
    fullName: "LATE GOBINDA DAS SARKAR",
    saID: "SPR2770",
  },
  {
    firstName: "LATE GOUR",
    lastName: "SARKAR",
    address: "87B,COSSIPORE ROAD*BLOCK-F, ROOM-26*CALCUTTA-700002",
    fullName: "LATE GOUR SARKAR",
    saID: "SPR2771",
  },
  {
    firstName: "LATE GOURI KISHORE",
    lastName: "SARKAR",
    address: "PO-RANAGHAT*DIST-NADIA",
    fullName: "LATE GOURI KISHORE SARKAR",
    saID: "SPR2772",
  },
  {
    firstName: "HABUL CHANDRA",
    lastName: "SARKAR",
    address:
      "SATSANG CENTRE SATMUKH*CANNIG TOWN*PO-JOYRAMKHALI*DIST-24 PGS SOUTH-743329",
    fullName: "HABUL CHANDRA SARKAR",
    saID: "SPR2773",
  },
  {
    firstName: "LATE HARA KANTA",
    lastName: "SARKAR",
    address: "PO-BAITAMARI*DT-GOALPARA.",
    fullName: "LATE HARA KANTA SARKAR",
    saID: "SPR2774",
  },
  {
    firstName: "LATE HARE KRISHNA",
    lastName: "SARKAR",
    address:
      "78 NO KRISHNANAGAR*PO-SONAPUR*VIA-CHAMORSHI*GADCHIROLI 442603*MAHARASTRA",
    fullName: "LATE HARE KRISHNA SARKAR",
    saID: "SPR2775",
  },
  {
    firstName: "HARENDRA NATH",
    lastName: "SARKAR",
    address: "VILL-KAMATH FULBARI*PO-TUFANGANJ*COOCHBEHAR*PAN-AYIPS0137J",
    fullName: "HARENDRA NATH SARKAR",
    saID: "SPR2776",
  },
  {
    firstName: "HIMANGSHU CHANDRA",
    lastName: "SARKAR",
    address:
      "SATSANG THAKURBARI PANIHATI*T.N.BANERJEE ROAD*PO-PANIHATI*24PGS(N)*PAN-DEMPS7209E",
    fullName: "HIMANGSHU CHANDRA SARKAR",
    saID: "SPR2777",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "SARKAR",
    address: "NABAPALLY*PO-NATAGARH*DIST-24 PGS.",
    fullName: "LATE HIRALAL SARKAR",
    saID: "SPR2778",
  },
  {
    firstName: "LATE JAGABANDHU",
    lastName: "SARKAR",
    address: "AT&PO.PUTIMARI*NADIA",
    fullName: "LATE JAGABANDHU SARKAR",
    saID: "SPR2779",
  },
  {
    firstName: "JATINDRA CHANDRA",
    lastName: "SARKAR",
    address: "VILL-CHARMAJDIA*PO-CHARBRAHMANAGAR*NADIA-741301",
    fullName: "JATINDRA CHANDRA SARKAR",
    saID: "SPR2780",
  },
  {
    firstName: "LATE JATINDRA CHANDRA",
    lastName: "SARKAR",
    address: "BARABISA BAZAR*PO-BARABISA*JALPAIGURI.",
    fullName: "LATE JATINDRA CHANDRA SARKAR",
    saID: "SPR2781",
  },
  {
    firstName: "LATE JAYDEB",
    lastName: "SARKAR",
    address: "AT-SHIBPUR*PO.SINGTISHIBPUR*DT.HOWRAH",
    fullName: "LATE JAYDEB SARKAR",
    saID: "SPR2782",
  },
  {
    firstName: "JNANESH CHANDRA",
    lastName: "SARKAR",
    address: "AT-RUDRANAGAR*PO-KRISHNANAGAR*NADIA-741101",
    fullName: "JNANESH CHANDRA SARKAR",
    saID: "SPR2783",
  },
  {
    firstName: "LATE JOGENDRA CHANDRA",
    lastName: "SARKAR",
    address: "DAKHIN RAMPUR*PO-BARABISA*JALPAIGURI",
    fullName: "LATE JOGENDRA CHANDRA SARKAR",
    saID: "SPR2784",
  },
  {
    firstName: "LATE JOGESH CHANDRA",
    lastName: "SARKAR",
    address: "VILL&PO-MADHYA NARARTHALI*PO-KHOWADANGA*DT-JALPAIGURI.",
    fullName: "LATE JOGESH CHANDRA SARKAR",
    saID: "SPR2785",
  },
  {
    firstName: "LATE JYOTISH CHANDRA",
    lastName: "SARKAR",
    address: "VILL-SUKPARA*PO-JOYENPUR*DIST-MALDA.",
    fullName: "LATE JYOTISH CHANDRA SARKAR",
    saID: "SPR2786",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "SARKAR",
    address: "SATSANG*DEOGHAR*JHARKHAND",
    fullName: "KARTIK CHANDRA SARKAR",
    saID: "SPR2787",
  },
  {
    firstName: "LATE KRISHNAPADA",
    lastName: "SARKAR",
    address: "VILL&PO-HATGACHA*24PARGANAS 743439",
    fullName: "LATE KRISHNAPADA SARKAR",
    saID: "SPR2788",
  },
  {
    firstName: "LATE LAXMAN CHANDRA",
    lastName: "SARKAR",
    address: "AT+PO-ARJUNPUR*MALDA 732124",
    fullName: "LATE LAXMAN CHANDRA SARKAR",
    saID: "SPR2789",
  },
  {
    firstName: "LAXMI KANTA",
    lastName: "SARKAR",
    address: "VILL-AMRAKANDA*PO-SAIDARIA*NAGAON-782125*ASSAM",
    fullName: "LAXMI KANTA SARKAR",
    saID: "SPR2790",
  },
  {
    firstName: "LATE MANI BHUSAN",
    lastName: "SARKAR",
    address: "VILL/PO-KRISHNAI*GOALPARA-783126*ASSAM",
    fullName: "LATE MANI BHUSAN SARKAR",
    saID: "SPR2791",
  },
  {
    firstName: "LATE MATHUR CHANDRA",
    lastName: "SARKAR",
    address: "PO-BIJNI (KAWATIKA)*DT-GOALPARA",
    fullName: "LATE MATHUR CHANDRA SARKAR",
    saID: "SPR2792",
  },
  {
    firstName: "LATE MONORANJAN",
    lastName: "SARKAR",
    address: "AT&PO-PUNDIBARI*COOCH BEHAR 736165",
    fullName: "LATE MONORANJAN SARKAR",
    saID: "SPR2793",
  },
  {
    firstName: "NABA KRISHNA",
    lastName: "SARKAR",
    address: "CHANDNAGAR*PO-MAHADIA*VIA-KANDI*MURSHIDABAD",
    fullName: "NABA KRISHNA SARKAR",
    saID: "SPR2794",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "SARKAR",
    address: "RADHANAGAR COLONY*PO-G.ATHIABARI*VIA-DINHATA*COOCHBEHAR",
    fullName: "LATE NANIGOPAL SARKAR",
    saID: "SPR2795",
  },
  {
    firstName: "LATE NARENDRANATH",
    lastName: "SARKAR",
    address: "AT/PO-RAGHUNATHPUR*BELTALA PARK*D/DINAJPUR",
    fullName: "LATE NARENDRANATH SARKAR",
    saID: "SPR2796",
  },
  {
    firstName: "LATE NIBARAN CHANDRA",
    lastName: "SARKAR",
    address: "C/O MURARI SAHA*AT+PO-PIFA*DIST-24 PGS.",
    fullName: "LATE NIBARAN CHANDRA SARKAR",
    saID: "SPR2797",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "SARKAR",
    address: "96/1,NISCHINDA*PO.GHOSHPARA*DT.HOWRAH",
    fullName: "LATE NIRMAL CHANDRA SARKAR",
    saID: "SPR2798",
  },
  {
    firstName: "LATE NIRMAL KUMAR",
    lastName: "SARKAR",
    address: "MAHAMAYA COLONY*PO.CHINSURAH*DIST.HOOGHLY",
    fullName: "LATE NIRMAL KUMAR SARKAR",
    saID: "SPR2799",
  },
  {
    firstName: "LATE NIRODE BARAN",
    lastName: "SARKAR",
    address: "*SATSANG  814116",
    fullName: "LATE NIRODE BARAN SARKAR",
    saID: "SPR2800",
  },
  {
    firstName: "NISHIKANTA",
    lastName: "SARKAR",
    address: "AT/PO-DHARMAPUR*24PARGANAS NORTH",
    fullName: "NISHIKANTA SARKAR",
    saID: "SPR2801",
  },
  {
    firstName: "LATE NRIPENDRA NATH",
    lastName: "SARKAR",
    address: "17, JATIN DAS SARANI*ASHRAMPARA*PO-SILIGURI-734 401*DARJEELING.",
    fullName: "LATE NRIPENDRA NATH SARKAR",
    saID: "SPR2802",
  },
  {
    firstName: "LATE PANCHANAN",
    lastName: "SARKAR",
    address: "CALCUTTA",
    fullName: "LATE PANCHANAN SARKAR",
    saID: "SPR2803",
  },
  {
    firstName: "PHANINDRA NATH",
    lastName: "SARKAR",
    address: "AT-AYESHBAG*PO-RASUNBAG*DIST-MURSHIDABAD-742184*PAN EUVPS8030M",
    fullName: "PHANINDRA NATH SARKAR",
    saID: "SPR2804",
  },
  {
    firstName: "LATE PRAKASH CHANDRA",
    lastName: "SARKAR",
    address: "GHURNI*PO-KRISHNANAGAR*DT-NADIA.",
    fullName: "LATE PRAKASH CHANDRA SARKAR",
    saID: "SPR2805",
  },
  {
    firstName: "LATE PRAMODE RANJAN",
    lastName: "SARKAR",
    address: "AT+PO DHARAMTUL*MARIGAON.",
    fullName: "LATE PRAMODE RANJAN SARKAR",
    saID: "SPR2806",
  },
  {
    firstName: "LATE PRIYANATH",
    lastName: "SARKAR",
    address: "*SATSANG.DEOGHAR",
    fullName: "LATE PRIYANATH SARKAR",
    saID: "SPR2807",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "SARKAR",
    address: "*BURDWAN.",
    fullName: "LATE RABINDRA NATH SARKAR",
    saID: "SPR2808",
  },
  {
    firstName: "LATE RAMANIMOHAN",
    lastName: "SARKAR",
    address: "NO.2 DURGAPURCOLONY*PO.CHAKDAH*NADIA",
    fullName: "LATE RAMANIMOHAN SARKAR",
    saID: "SPR2809",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "SARKAR",
    address: "AT-LOWER MUKH*PO-RAJGARH*DIBRUGARH",
    fullName: "LATE RAMESH CHANDRA SARKAR",
    saID: "SPR2810",
  },
  {
    firstName: "RATAN CHANDRA",
    lastName: "SARKAR",
    address:
      "SUBHA SMRITI*MAHABEER COLONY,E/RANGAMATIA*PO-RUPNARAYANPUR*BURDWAN*PAN-ALAPS 0989H",
    fullName: "RATAN CHANDRA SARKAR",
    saID: "SPR2811",
  },
  {
    firstName: "RATNESWAR",
    lastName: "SARKAR",
    address:
      "SUDHA HOMEO HALL*ABHAYPURI BAZAR*PO-ABHAYPURI*GOALPARA 783384*ASSAM",
    fullName: "RATNESWAR SARKAR",
    saID: "SPR2812",
  },
  {
    firstName: "LATE SACHINDRA NATH",
    lastName: "SARKAR",
    address: "AMAR DEEP*B.M.ROAD*DIST-NAGAON",
    fullName: "LATE SACHINDRA NATH SARKAR",
    saID: "SPR2813",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "SARKAR",
    address: "RC/6, SALLAYRIDE PATH*BIDHAN NAGAR*DURGAPUR-12.",
    fullName: "SANTOSH KUMAR SARKAR",
    saID: "SPR2814",
  },
  {
    firstName: "LATE SAROJ KUMAR",
    lastName: "SARKAR",
    address: "AT-BAIKUNTHAPUR*PO-MASUNDA*VIA-SURIA*24PARGANAS NORTH",
    fullName: "LATE SAROJ KUMAR SARKAR",
    saID: "SPR2815",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "SARKAR",
    address: "PO-NAZIRHAT*VIA -DEWANHAT*COOCHBEHAR",
    fullName: "LATE SATISH CHANDRA SARKAR",
    saID: "SPR2816",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "SARKAR",
    address: "L.I.C CITY BRANCH-5 *24CHITTARANJAN AVENUE *CALCUTTA-12",
    fullName: "LATE SATYENDRA NATH SARKAR",
    saID: "SPR2817",
  },
  {
    firstName: "LATE SHYAMSUNDAR",
    lastName: "SARKAR",
    address: "DAKSHIN MAJHER DABRI*PO-MAJHERDABRI*JALPAIGURI",
    fullName: "LATE SHYAMSUNDAR SARKAR",
    saID: "SPR2818",
  },
  {
    firstName: "LATE SIBAPRASAD",
    lastName: "SARKAR",
    address: "163 BENI MASTER LANE*PO-SARSUNA*CALCUTTA 700061",
    fullName: "LATE SIBAPRASAD SARKAR",
    saID: "SPR2819",
  },
  {
    firstName: "LATE SISIR BINDU",
    lastName: "SARKAR",
    address: "C/O SITANSHU KUMAR MANDAL*PO/VILL-FATEPUR*NADIA",
    fullName: "LATE SISIR BINDU SARKAR",
    saID: "SPR2820",
  },
  {
    firstName: "LATE SUBODH KUMAR",
    lastName: "SARKAR",
    address: "VILL-MURAGACHHA*PO-JUGBERIA*24 PARGANAS (NORTH).",
    fullName: "LATE SUBODH KUMAR SARKAR",
    saID: "SPR2821",
  },
  {
    firstName: "LATE SUDHANGSHU SEKHAR",
    lastName: "SARKAR",
    address: "PO+VILL-GOLAPGONJ*DIST-MALDA.",
    fullName: "LATE SUDHANGSHU SEKHAR SARKAR",
    saID: "SPR2822",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "SARKAR",
    address: "PO+V HOJAI(JOGIJAN ROAD)*NOWGONG.",
    fullName: "LATE SUDHIR CHANDRA SARKAR",
    saID: "SPR2823",
  },
  {
    firstName: "SUDHIR CHANDRA",
    lastName: "SARKAR",
    address:
      "215,R K BANERJEE SARANI*PO-M G COLONY*CHANDANNAGAR*HOOGHLY 712139 PAN>AKCPS6125Q",
    fullName: "SUDHIR CHANDRA SARKAR",
    saID: "SPR2824",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "SARKAR",
    address: "DHEKIAJULI WORD NO-5*PO-DHEKIAJULI*SONITPUR-784110*ASSAM",
    fullName: "SUNIL KUMAR SARKAR",
    saID: "SPR2825",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "SARKAR",
    address: "VILL&PO-FULBARI*VIA-BHEBIA*DT-24PARGANAS NORTH-743456",
    fullName: "SUNIL KUMAR SARKAR",
    saID: "SPR2826",
  },
  {
    firstName: "SURAPATI",
    lastName: "SARKAR",
    address: "SATSANG BIHAR*CONTRUCTORS COLONY*PO-SUPELLA*BHILAI*M.P 490023",
    fullName: "SURAPATI SARKAR",
    saID: "SPR2827",
  },
  {
    firstName: "LATE SURENDRANATH",
    lastName: "SARKAR",
    address: "AT-MANASI*PO-DEVAGRAM*COOCHBEHAR",
    fullName: "LATE SURENDRANATH SARKAR",
    saID: "SPR2828",
  },
  {
    firstName: "LATE SURENDRANATH",
    lastName: "SARKAR",
    address: "JAMALDAHA SATSANG VIHAR*PO-JAMALDAHA*COOCH BEHAR",
    fullName: "LATE SURENDRANATH SARKAR",
    saID: "SPR2829",
  },
  {
    firstName: "TARANI KANTA",
    lastName: "SARKAR",
    address: "303/3 BAGMARI ROAD*CALCUTTA 54",
    fullName: "TARANI KANTA SARKAR",
    saID: "SPR2830",
  },
  {
    firstName: "UMAPADA",
    lastName: "SARKAR",
    address: "ASTANA ROAD*PO-BASIRHAT*24 PGS/N",
    fullName: "UMAPADA SARKAR",
    saID: "SPR2831",
  },
  {
    firstName: "ANATH BANDHU",
    lastName: "SARKER",
    address: "CHANDSI DOCTOR*H.B.ROAD*PO-AGARTALA*TRIPURA-799001",
    fullName: "ANATH BANDHU SARKER",
    saID: "SPR2832",
  },
  {
    firstName: "BIJOY LAL",
    lastName: "SARKER",
    address:
      "O/O THE EX.ENGINEER.PHE*HOJAI DIVISION*PO-HOJAI*NAGAON 782435*PAN-ANGPS5321M",
    fullName: "BIJOY LAL SARKER",
    saID: "SPR2833",
  },
  {
    firstName: "LATE BIJOY SANKAR",
    lastName: "SARKER",
    address: "507 ARMY BOSE WORKSHOP E.M.E.*PO-ESD(M) KANKINARA*DIST-24 PGS.",
    fullName: "LATE BIJOY SANKAR SARKER",
    saID: "SPR2834",
  },
  {
    firstName: "HARIDHAN",
    lastName: "SARKER",
    address: "NORTH BADARGHAT*MADHYAPARA*PO-ARUNDHUTINAGAR*TRIPURA WEST-799003",
    fullName: "HARIDHAN SARKER",
    saID: "SPR2835",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "SARKER",
    address: "VILL CHAMPLAI*PO-MAIONGTUKUPARA*TRIPURA(W)",
    fullName: "LATE HIRALAL SARKER",
    saID: "SPR2836",
  },
  {
    firstName: "LATE JOGENDRA CHANDRA",
    lastName: "SARKER",
    address: "AT/PO-BIJNI*GOALPARA.",
    fullName: "LATE JOGENDRA CHANDRA SARKER",
    saID: "SPR2837",
  },
  {
    firstName: "LATE PRAFFULA CHANDRA",
    lastName: "SARKER",
    address: "MAHARANIPUR*PO-CHAKMAGHAT*TRIPURA",
    fullName: "LATE PRAFFULA CHANDRA SARKER",
    saID: "SPR2838",
  },
  {
    firstName: "SUDHANWA KUMAR",
    lastName: "SARKAR",
    address: "AT/PO-SAIDPUR II*DIST-CACHAR 788013",
    fullName: "SUDHANWA KUMAR SARKAR",
    saID: "SPR2839",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "SARMA",
    address:
      "C/O HARENDRA NATH SARMA*FLOOD CONTROL MECHANICAL SUB DIVN*NO-1CHANDMARI*GAUHATI-1",
    fullName: "LATE BIRENDRA NATH SARMA",
    saID: "SPR2840",
  },
  {
    firstName: "DEBABRATA",
    lastName: "SARMA",
    address:
      "SEWA KUTIR,MAIN ROAD*BARPARA*DIST-BONGAIGAON-783380*(ASSAM)*PAN-AUZPS8195D",
    fullName: "DEBABRATA SARMA",
    saID: "SPR2841",
  },
  {
    firstName: "HARENDRA KUMAR",
    lastName: "SARMA",
    address: "VILL-NIR LAHOAL*PO-LAHOAL*DIBRUGARH 786010",
    fullName: "HARENDRA KUMAR SARMA",
    saID: "SPR2842",
  },
  {
    firstName: "HEM PRASAD",
    lastName: "SARMA",
    address: "VILL- MANPUR(GOLMAGAON)*PO/DIST-UDALGURI-784 509*(ASSAM)",
    fullName: "HEM PRASAD SARMA",
    saID: "SPR2843",
  },
  {
    firstName: "LATE JAGAT CHANDRA",
    lastName: "SARMA",
    address: "GOBINDA HOMEOPHARMACY*TANGLA ROAD*PO MANGALDAI*DARRANG.",
    fullName: "LATE JAGAT CHANDRA SARMA",
    saID: "SPR2844",
  },
  {
    firstName: "NILA KANTA",
    lastName: "SARMA",
    address: "L.N.B.RD.SOUTH HAIBARGAON*PO-HAIBARGAON*NAGAON 782002.",
    fullName: "NILA KANTA SARMA",
    saID: "SPR2845",
  },
  {
    firstName: "LATE RAMANI MOHAN",
    lastName: "SARMA",
    address: "QUALITY CENTRAL LABORATORY*P.O.BARAPANI*MEGHALAYA",
    fullName: "LATE RAMANI MOHAN SARMA",
    saID: "SPR2846",
  },
  {
    firstName: "LATE SHIBSANKAR",
    lastName: "SARMA",
    address: "65 NETAJI SUBHASH PATH*PO-KANCHRAPARA*DIST-24 PGS.",
    fullName: "LATE SHIBSANKAR SARMA",
    saID: "SPR2847",
  },
  {
    firstName: "LATE UDAY CHANDRA",
    lastName: "SARMA",
    address: "SATSANG VIHAR,GAUHATI-5*DIST KAMRUP",
    fullName: "LATE UDAY CHANDRA SARMA",
    saID: "SPR2848",
  },
  {
    firstName: "DEBABRATA",
    lastName: "SARMAPURAKAYASTHA",
    address:
      '"CHANDRALAYA"*CHAKRABARTY PARA WORD NO VIII*PO-LAKSHMISAHAR*HAILAKANDI  788 152',
    fullName: "DEBABRATA SARMAPURAKAYASTHA",
    saID: "SPR2849",
  },
  {
    firstName: "GIRISH CHANDRA",
    lastName: "SARMATAMULI",
    address: "BELTOLA SEUJNAGAR*NEAR BELTALA BAZAR*PO-GUWAHATI-781028*KAMRUP.",
    fullName: "GIRISH CHANDRA SARMATAMULI",
    saID: "SPR2850",
  },
  {
    firstName: "LATE TIKARAM",
    lastName: "SARMASUBEDI",
    address: "VILL-BHURBHATI*PO.SOATIA*DARRANG",
    fullName: "LATE TIKARAM SARMASUBEDI",
    saID: "SPR2851",
  },
  {
    firstName: "GOURHARI",
    lastName: "SASMAL",
    address:
      "AT+PO-SHYAMSUNDARPUR PATNA*VIA-PANSKURA*DIST-PURBA MEDINIPUR-721169",
    fullName: "GOURHARI SASMAL",
    saID: "SPR2852",
  },
  {
    firstName: "LATE NITAI CHANDRA",
    lastName: "SASMAL",
    address: "AT-BAHIRTOFA*PO.JADURBERIA*DT-HOWRAH",
    fullName: "LATE NITAI CHANDRA SASMAL",
    saID: "SPR2853",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "SASMAL",
    address: "VILL-SINARY*PO-ARJUNNAGAR*PURBA MEDINIPUR-721456",
    fullName: "LATE SUKUMAR SASMAL",
    saID: "SPR2854",
  },
  {
    firstName: "LATE BHAGABAN",
    lastName: "SATPATHY",
    address: "HD.MASTER,BAPUJI.S.U.VIDYALAYA*PO-UTTARKUL*DIST CUTTACK",
    fullName: "LATE BHAGABAN SATPATHY",
    saID: "SPR2855",
  },
  {
    firstName: "LATE DINABANDHU",
    lastName: "SATPATHY",
    address: "AT/PO-BASUDEVPUR*BHADRAK-756125",
    fullName: "LATE DINABANDHU SATPATHY",
    saID: "SPR2856",
  },
  {
    firstName: "HARI BANDHU",
    lastName: "SATPATHY",
    address: "AT/PO-RAMBHADEIPUR*JAGATSINGHPUR",
    fullName: "HARI BANDHU SATPATHY",
    saID: "SPR2857",
  },
  {
    firstName: "KALINDI CHARAN",
    lastName: "SATPATHY",
    address:
      'AT-"SASWATA NILAYA"*COLLEGE ROAD,BADAHALA COLONEY*PO/DIST-KEONJHAR 758001.',
    fullName: "KALINDI CHARAN SATPATHY",
    saID: "SPR2858",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "SATPATHI",
    address: "AT/PO-PANKAPAL*VIA-RAHAMA*JAGATSINGHPUR-754140",
    fullName: "KRUSHNA CHANDRA SATPATHI",
    saID: "SPR2859",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "SATPATHY",
    address: "AT/PO.KAMPAGARH*VIA.INDUPUR*CUTTACK-754214*PAN-CHFPS9023R",
    fullName: "PRAMOD KUMAR SATPATHY",
    saID: "SPR2860",
  },
  {
    firstName: "LATE KASHINATH",
    lastName: "SATPATHY",
    address: "VILL-TORANTA*PO-BHANDARI POKHARI*BALASORE",
    fullName: "LATE KASHINATH SATPATHY",
    saID: "SPR2861",
  },
  {
    firstName: "LATE BRAJENDRA NARAYAN",
    lastName: "SAVAPANDIT",
    address: "NEAR SASTRI PEETH*CHOLADHARA*PO/DT-JORHAT-785001*ASSAM",
    fullName: "LATE BRAJENDRA NARAYAN SAVAPANDIT",
    saID: "SPR2862",
  },
  {
    firstName: "LATE BAIDYANATH",
    lastName: "SEAL",
    address: "VIDYAMANDIR*THAKURPALLI*CHHOTONILPUR*BURDWAN.",
    fullName: "LATE BAIDYANATH SEAL",
    saID: "SPR2863",
  },
  {
    firstName: "LATE BRINDABAN CHANDRA",
    lastName: "SEAL",
    address: "AT-JAKHIRPUR*PO.DIGHIRPUR*W/DINAJPUR",
    fullName: "LATE BRINDABAN CHANDRA SEAL",
    saID: "SPR2864",
  },
  {
    firstName: "DHIRENDRA SEKHAR",
    lastName: "SEAL",
    address: "C/O ISWAR PHARMACY*VILL/PO AMTALI*DIST PATUAKHALI*BANGLADESH",
    fullName: "DHIRENDRA SEKHAR SEAL",
    saID: "SPR2865",
  },
  {
    firstName: "JITENDRA KUMAR",
    lastName: "SEAL",
    address: "PO-LANGTING*DIST-DIMA HASAO*ASSAM-788 832",
    fullName: "JITENDRA KUMAR SEAL",
    saID: "SPR2866",
  },
  {
    firstName: "SUDHIR CHANDRA",
    lastName: "SEAL",
    address:
      "KRISHNA APPTT.FIRST FLOOR CHANDRABITAN 1-A*NO 2 MOTILAL COLONY*KOLKATA  700081",
    fullName: "SUDHIR CHANDRA SEAL",
    saID: "SPR2867",
  },
  {
    firstName: "TARANI CHARAN",
    lastName: "SEAL",
    address: "C/O RAJENDRA LAL CHAND*PO-CHOTODUDHPATIL*VILL-SASTRINAGAR*CACHAR",
    fullName: "TARANI CHARAN SEAL",
    saID: "SPR2868",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "SEAL",
    address: "VILL/PO GOLAKPARA*DIST BARISAL*BANGLADESH",
    fullName: "LATE UPENDRA NATH SEAL",
    saID: "SPR2869",
  },
  {
    firstName: "LATE AMBIKA CHARAN",
    lastName: "SEN",
    address: "VILL.MANIPUR*PO.HILARA*CACHAR",
    fullName: "LATE AMBIKA CHARAN SEN",
    saID: "SPR2870",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SEN",
    address: "300/A, SILVER CLOUD*SUNDARNAGAR-2*KALINA*MUMBAI-400098.",
    fullName: "ARUN KUMAR SEN",
    saID: "SPR2871",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "SEN",
    address: "SEN BARI*M D ROAD, PALLISHREE*CALCUTTA-700060.",
    fullName: "LATE BIMAL CHANDRA SEN",
    saID: "SPR2872",
  },
  {
    firstName: "HRIDAY RANJAN",
    lastName: "SEN",
    address: "PO.BONAIGARH*SUNDARGARH-770038.",
    fullName: "HRIDAY RANJAN SEN",
    saID: "SPR2873",
  },
  {
    firstName: "LATE JADUNATH",
    lastName: "SEN",
    address: "PO-SARUPATHAR*SIBSAGAR",
    fullName: "LATE JADUNATH SEN",
    saID: "SPR2874",
  },
  {
    firstName: "LATE JAGAJYOTI",
    lastName: "SEN",
    address: "C-1/12,SABDARJANG DEV AREA*NEW DELHI-110016",
    fullName: "LATE JAGAJYOTI SEN",
    saID: "SPR2875",
  },
  {
    firstName: "JATINDRANATH",
    lastName: "SEN",
    address: "PO-NAGARURHAT*COOCHBEHAR",
    fullName: "JATINDRANATH SEN",
    saID: "SPR2876",
  },
  {
    firstName: "JYOTISH CHANDRA",
    lastName: "SEN",
    address: "B.ED,COLLEGEPARA*PO-KADAMTALA*DARJEELING 734011*PAN-AMPPS8446K",
    fullName: "JYOTISH CHANDRA SEN",
    saID: "SPR2877",
  },
  {
    firstName: "BIMAL",
    lastName: "CHANDA",
    address: "JYOTIKUCHI*LAKHRA ROAD*BYE LAND KALIBARI RD*GAUHATI 34",
    fullName: "BIMAL CHANDA",
    saID: "SPR2878",
  },
  {
    firstName: "LATE KARUNAKANTA",
    lastName: "SEN",
    address: "KANCHANPUR*TRIPURA(NORTH)",
    fullName: "LATE KARUNAKANTA SEN",
    saID: "SPR2879",
  },
  {
    firstName: "LATE MADHU SUDAN",
    lastName: "SEN",
    address: "SATSANG COLONY*PO-ANGARGARIA*BALASORE-756001",
    fullName: "LATE MADHU SUDAN SEN",
    saID: "SPR2880",
  },
  {
    firstName: "LATE NANDA LAL",
    lastName: "SEN",
    address: "SATSANG CENTRE*14/1 H.K BANERJEE IST BY LANE*PO-BANTRA*HOWRAH",
    fullName: "LATE NANDA LAL SEN",
    saID: "SPR2881",
  },
  {
    firstName: "LATE NARENDRA CHANDRA",
    lastName: "SEN",
    address: "T-50 KALIBARI COLONY*PO-LUMDING*NOWGONG",
    fullName: "LATE NARENDRA CHANDRA SEN",
    saID: "SPR2882",
  },
  {
    firstName: "LATE NIBARAN CHANDRA",
    lastName: "SEN",
    address: "PO-NAGURURHAT*VIA-TUFANGANJ*COOCHBEHAR",
    fullName: "LATE NIBARAN CHANDRA SEN",
    saID: "SPR2883",
  },
  {
    firstName: "PHANIBHUSAN",
    lastName: "SEN",
    address: "48/3 RUSSA ROAD EAST,2ND LANE*CALCUTTA-33*ALEPS7436B",
    fullName: "PHANIBHUSAN SEN",
    saID: "SPR2884",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "SEN",
    address: "PO-NAGURURHAT*VIA-TUFANGANJ*COOCHBEHAR",
    fullName: "LATE RAMESH CHANDRA SEN",
    saID: "SPR2885",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "SEN",
    address: "VILL/P.O CHANTAIL*N-TRIPURA",
    fullName: "RANJIT KUMAR SEN",
    saID: "SPR2886",
  },
  {
    firstName: "SAMARJIT",
    lastName: "SEN",
    address: "NEAR TELEPHONE BHAWAN*P H D ROAD*SUNARGARH-770001",
    fullName: "SAMARJIT SEN",
    saID: "SPR2887",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "SEN",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SARAT CHANDRA SEN",
    saID: "SPR2888",
  },
  {
    firstName: "LATE SAROJ KUMAR",
    lastName: "SEN",
    address: "M/S H.M.MOTORS*M.G.AVENUE*PO-IMPHAL 795001*MANIPUR",
    fullName: "LATE SAROJ KUMAR SEN",
    saID: "SPR2889",
  },
  {
    firstName: "LATE SIB CHARAN",
    lastName: "SEN",
    address: "PO+V KHAIRABARI*DARRANG.",
    fullName: "LATE SIB CHARAN SEN",
    saID: "SPR2890",
  },
  {
    firstName: "LATE SURENDRA KUMAR",
    lastName: "SEN",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SURENDRA KUMAR SEN",
    saID: "SPR2891",
  },
  {
    firstName: "LATE SURENDRA MOHAN",
    lastName: "SEN",
    address: "VILL-BANIPUR*PO-BAIGACHI*DIST-24 PGS.",
    fullName: "LATE SURENDRA MOHAN SEN",
    saID: "SPR2892",
  },
  {
    firstName: "LATE SANKAR",
    lastName: "SENISHORE",
    address: "PO.TUFANGANJ*COOCHBEHAR",
    fullName: "LATE SANKAR SENISHORE",
    saID: "SPR2893",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "SENAPATI",
    address:
      "TRUPANA-TIRTHA*1215/1382,KHANDAGIRIBARI*PO-KHANDAGIRI*BHUBANESWAR-751030*ADQPS3979R",
    fullName: "HRUSHIKESH SENAPATI",
    saID: "SPR2894",
  },
  {
    firstName: "MUKUNDA PRASAD",
    lastName: "SENAPATI",
    address:
      "PLOT NO.1475,JAGANNATH MATH LANE*PO-OLD TOWN*BHUBANESWAR 751002*KHURDA",
    fullName: "MUKUNDA PRASAD SENAPATI",
    saID: "SPR2895",
  },
  {
    firstName: "LATE RATAN KUMAR",
    lastName: "SENAPATI",
    address:
      "HOWRAH SATSANG CENTRE*14/1 HRIDOY KRISHNA BANERJEE*1ST BYE LANE*DT.HOWRAH",
    fullName: "LATE RATAN KUMAR SENAPATI",
    saID: "SPR2896",
  },
  {
    firstName: "RATI KANTA",
    lastName: "SENAPATI",
    address: "69/2 C.ROAD*BAMANGACHI*SALKIA*HOWRAH 711 106",
    fullName: "RATI KANTA SENAPATI",
    saID: "SPR2897",
  },
  {
    firstName: "BINAY BHUSHAN",
    lastName: "SENGUPTA",
    address: "SATSANG CENTER *BISHNUPUR,P.O.SHILLONG*MEGHALAYA",
    fullName: "BINAY BHUSHAN SENGUPTA",
    saID: "SPR2898",
  },
  {
    firstName: "DEB KUMAR",
    lastName: "SENGUPTA",
    address:
      '"PURNA SUDHA"*349/2 DAKSHIN BEHALA ROAD*NEAR SASTHI MORE*KOLKATA-61*PAN-AKUPS9084L',
    fullName: "DEB KUMAR SENGUPTA",
    saID: "SPR2899",
  },
  {
    firstName: "DEB KUMAR",
    lastName: "SENGUPTA",
    address: "BANK OF BARODA*MOTIHARI*E CHAMPARAN.",
    fullName: "DEB KUMAR SENGUPTA",
    saID: "SPR2900",
  },
  {
    firstName: "LATE GIRISH CHANDRA",
    lastName: "SENGUPTA",
    address: "PO-BASUGAON*DT-GOALPARA.",
    fullName: "LATE GIRISH CHANDRA SENGUPTA",
    saID: "SPR2901",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "SENGUPTA",
    address: "P-69 NEW GOVT COLONY*PO-BANSDRONI*DIST-24 PGS 743501",
    fullName: "LATE HARIPADA SENGUPTA",
    saID: "SPR2902",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "SENGUPTA",
    address: "*SATSANG.DEOGHAR",
    fullName: "LATE KSHITISH CHANDRA SENGUPTA",
    saID: "SPR2903",
  },
  {
    firstName: "LATE NIRMAL KUMAR",
    lastName: "SENGUPTA",
    address: "121 SOUTH SINTHI ROAD*CALCUTTA 30",
    fullName: "LATE NIRMAL KUMAR SENGUPTA",
    saID: "SPR2904",
  },
  {
    firstName: "LATE SUNIL BIKASH",
    lastName: "SENGUPTA",
    address:
      "68,RLY PARK*ISWAR CHATTERJEE ROAD*PO-SODEPUR*24PARGANAS NORTH-700110",
    fullName: "LATE SUNIL BIKASH SENGUPTA",
    saID: "SPR2905",
  },
  {
    firstName: "LATE SUNIL BIKASH",
    lastName: "SENGUPTA",
    address: "23/14,KABI NABIN SEN ROAD*CALCUTTA-28",
    fullName: "LATE SUNIL BIKASH SENGUPTA",
    saID: "SPR2906",
  },
  {
    firstName: "LATE AMARBIJOY",
    lastName: "SENSARMA",
    address: "*SATSANG",
    fullName: "LATE AMARBIJOY SENSARMA",
    saID: "SPR2907",
  },
  {
    firstName: "LATE PRIYANATH",
    lastName: "SENSHARMA",
    address: "SATSANG VIHAR*PO-BALURGHAT 733101*WEST DINAJPUR",
    fullName: "LATE PRIYANATH SENSHARMA",
    saID: "SPR2908",
  },
  {
    firstName: "LATE REBATI MOHAN",
    lastName: "SENSHARMA",
    address: "MATRIDHAM,PURANDAHA*SATSANG*DEOGHAR",
    fullName: "LATE REBATI MOHAN SENSHARMA",
    saID: "SPR2909",
  },
  {
    firstName: "JUGAL KISHORE",
    lastName: "SETH",
    address: "AT/PO.GAISILAT-768037*BARGARH.",
    fullName: "JUGAL KISHORE SETH",
    saID: "SPR2910",
  },
  {
    firstName: "NIMAI CHAND",
    lastName: "SETH",
    address: "VILL/PO.BANSBERIA KUNDUGALI*PO.BANSBERIA*DIST-HOOGHLY-712502",
    fullName: "NIMAI CHAND SETH",
    saID: "SPR2911",
  },
  {
    firstName: "LATE NIROD BARAN",
    lastName: "SETH",
    address: "29/8, H K SETH LANE*CALCUTTA 50",
    fullName: "LATE NIROD BARAN SETH",
    saID: "SPR2912",
  },
  {
    firstName: "ACHYUT CHANDRA",
    lastName: "SHARMA",
    address: "SATSANG VIHAR JORHAT*BANGALPUKHURI*NA-ALI*JORHAT-785001.",
    fullName: "ACHYUT CHANDRA SHARMA",
    saID: "SPR2913",
  },
  {
    firstName: "ANANDA",
    lastName: "SARMA",
    address: "VILL-CHAKIALGAON*PO-DERGAON*GOLAGHAT-785614*ASSAM",
    fullName: "ANANDA SARMA",
    saID: "SPR2914",
  },
  {
    firstName: "BAIDYA NATH",
    lastName: "SARMA",
    address: "VILL-KUWARIBARI*PO-SAPEKHATI*DIST-SIBSAGAR 785692*ASSAM",
    fullName: "BAIDYA NATH SARMA",
    saID: "SPR2915",
  },
  {
    firstName: "LATE BANGSHIDHAR",
    lastName: "SHARMA",
    address: "C/O PARAG CH SHARMA*BARHOLLA*PO-BARHOLLA*SIBSAGAR",
    fullName: "LATE BANGSHIDHAR SHARMA",
    saID: "SPR2916",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "SHARMA",
    address: "KENDUGURI NO 2 BAMUNGAON*PO-CHENGELIGAON 785010*JORHAT",
    fullName: "LATE BIRENDRA NATH SHARMA",
    saID: "SPR2917",
  },
  {
    firstName: "LATE BRIJ NANDAN",
    lastName: "SHARMA",
    address: "VILL HANDIA,PO-NARDIGANJ*NAWADA",
    fullName: "LATE BRIJ NANDAN SHARMA",
    saID: "SPR2918",
  },
  {
    firstName: "LATE CHANDRA KANTA",
    lastName: "SHARMA",
    address: "PATHSALA BAZAR*PO-PATHSALA*DIST-BARPETA",
    fullName: "LATE CHANDRA KANTA SHARMA",
    saID: "SPR2919",
  },
  {
    firstName: "CHIDANANDA",
    lastName: "SHARMA",
    address: "PO-RANJIA*KAMRUP.",
    fullName: "CHIDANANDA SHARMA",
    saID: "SPR2920",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "SHARMA",
    address: "KOKRAJHAR COLLEGE*PO-KOKRAJHAR*GOALPARA",
    fullName: "DEBENDRA NATH SHARMA",
    saID: "SPR2921",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "SHARMA",
    address: "VILL/PO-SUHAGPUR*DIST-KAMRUP.",
    fullName: "DHARANIDHAR SHARMA",
    saID: "SPR2922",
  },
  {
    firstName: "LATE DINANATH",
    lastName: "SHARMA",
    address: "ASHOK ASHRAM*SATSANG-814116.",
    fullName: "LATE DINANATH SHARMA",
    saID: "SPR2923",
  },
  {
    firstName: "GIRINDRA NATH",
    lastName: "SHARMA",
    address: "SATSANG VIHAR*PO-GAUHATI 5*KAMRUP.",
    fullName: "GIRINDRA NATH SHARMA",
    saID: "SPR2924",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "SHARMA",
    address: "CHIRINGCHAPURI*SHIVNATH BHATTACHARJEE PATH*DIBRUGARH 786001",
    fullName: "LATE HARENDRA NATH SHARMA",
    saID: "SPR2925",
  },
  {
    firstName: "LATE KALI NATH",
    lastName: "SHARMA",
    address: "UDALGURI*DARRANG.",
    fullName: "LATE KALI NATH SHARMA",
    saID: "SPR2926",
  },
  {
    firstName: "LATE KAPILDEO NARAYAN",
    lastName: "SHARMA",
    address: "VILL&PO-BANDEY*VIA-PATORY*SAMASTIPUR",
    fullName: "LATE KAPILDEO NARAYAN SHARMA",
    saID: "SPR2927",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "SHARMA",
    address: "B.A.S.F.INDIA LTD.MAYBAKER HOUSE*S.K.AHIRA MARG,BOMBAY 400325",
    fullName: "KESHAB CHANDRA SHARMA",
    saID: "SPR2928",
  },
  {
    firstName: "MAHENDRA NATH",
    lastName: "SHARMA",
    address: "PANIGAON HIGH SCHOOL*PO-PANIGAON*LAKHIMPUR.",
    fullName: "MAHENDRA NATH SHARMA",
    saID: "SPR2929",
  },
  {
    firstName: "LATE MURALI MOHAN",
    lastName: "SHARMA",
    address: "PO-BARPETA*RANGPURHATI*DIST-KAMRUP.",
    fullName: "LATE MURALI MOHAN SHARMA",
    saID: "SPR2930",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "SHARMA",
    address: "PO-HAILAKANDI*CACHAR.",
    fullName: "NARESH CHANDRA SHARMA",
    saID: "SPR2931",
  },
  {
    firstName: "LATE NRIPENDRA CHANDRA",
    lastName: "SHARMA",
    address: "NO-2,BRAHMINGAON;PO JORHAT*SIBSAGAR",
    fullName: "LATE NRIPENDRA CHANDRA SHARMA",
    saID: "SPR2932",
  },
  {
    firstName: "LATE NRIPESH CHANDRA",
    lastName: "SHARMA",
    address: "LAXMI BAZAR ROAD*KARIMGONJ TOWN*PO+DIST-KARIMGONJ.",
    fullName: "LATE NRIPESH CHANDRA SHARMA",
    saID: "SPR2933",
  },
  {
    firstName: "LATE PADMA",
    lastName: "SHARMA",
    address: "SARMA HOMEO PHARMACY*BHEBARGHAT*PO-MANGALDOI*DARRANG",
    fullName: "LATE PADMA SHARMA",
    saID: "SPR2934",
  },
  {
    firstName: "PRASHANTA",
    lastName: "SHARMA",
    address: "CHAKIYAL GAON*PO-DERGAON*DIST-GOLAGHAT*ASSAM-785614",
    fullName: "PRASHANTA SHARMA",
    saID: "SPR2935",
  },
  {
    firstName: "LATE PRATAP CHANDRA",
    lastName: "SHARMA",
    address: "V-DHARAMPUR*PO-ABHAYAPURI*DT-GOALPARA.",
    fullName: "LATE PRATAP CHANDRA SHARMA",
    saID: "SPR2936",
  },
  {
    firstName: "PRODYUMNA KUMAR",
    lastName: "SHARMA",
    address: "VILL-TOLAGRAM*PO-SONAIMUKH*CACHAR-788119*ASSAM",
    fullName: "PRODYUMNA KUMAR SHARMA",
    saID: "SPR2937",
  },
  {
    firstName: "PUSPARAJ",
    lastName: "SARMA",
    address:
      "VILL-ODALGURI*PO-MAINAMATA*VIA-BARPETA ROAD*DIST-BARPETA-781315*ASSAM",
    fullName: "PUSPARAJ SARMA",
    saID: "SPR2938",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "SHARMA",
    address: "MONNBARI T.E*PO MONABARI*DARRANG.",
    fullName: "RADHESHYAM SHARMA",
    saID: "SPR2939",
  },
  {
    firstName: "LATE RAM LAGAN",
    lastName: "SHARMA",
    address: "VILL-BELKUNDA,GANNIPUR*PO-BIRNA LAKHANSEN*VAISHALI",
    fullName: "LATE RAM LAGAN SHARMA",
    saID: "SPR2940",
  },
  {
    firstName: "REBATI MOHAN",
    lastName: "SEALSHARMA",
    address: "VILL/PO-TIKRIKILLA*WEST GARO HILLS*MEGHALAYA-794109",
    fullName: "REBATI MOHAN SEALSHARMA",
    saID: "SPR2941",
  },
  {
    firstName: "LATE SARADA MOHAN",
    lastName: "SARMA",
    address: "AT/PO-SARBHOG*WARD NO-4*DIST-BARPETA*ASSAM.",
    fullName: "LATE SARADA MOHAN SARMA",
    saID: "SPR2942",
  },
  {
    firstName: "LATE SATYENDRA KUMAR",
    lastName: "SHARMA",
    address: "SATSANG VIHAR NAGAON*M G ROAD*PO/DIST-NAGAON 782 001*ASSAM",
    fullName: "LATE SATYENDRA KUMAR SHARMA",
    saID: "SPR2943",
  },
  {
    firstName: "LATE UDAYCHANDRA",
    lastName: "SHARMA",
    address: "HD CLERK I TAX OFFICE*PO-DHUBRI*GOALPARA.",
    fullName: "LATE UDAYCHANDRA SHARMA",
    saID: "SPR2944",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "SHARMABARDOLOI",
    address: "BAPUJIPATH*PO-NAJIRA*DT-SIBSAGAR.",
    fullName: "LATE HEM CHANDRA SHARMABARDOLOI",
    saID: "SPR2945",
  },
  {
    firstName: "TIKARAM",
    lastName: "SHARMAPARAJULI",
    address: "VILL&PO-GAMERIPAL*VIA-JAMUGURIHAT*DIST-SONITPUR",
    fullName: "TIKARAM SHARMAPARAJULI",
    saID: "SPR2946",
  },
  {
    firstName: "BHADRESWAR",
    lastName: "SHAW",
    address: "VILL/PO.JANGALPARA*VIA-TARAKESWAR*DIST.HOOGHLY-712410",
    fullName: "BHADRESWAR SHAW",
    saID: "SPR2947",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "SHAW",
    address: "DHAPIPUKUR ROAD*PO-KANCHRAPARA*DIST-24-PGS.",
    fullName: "LATE HIRALAL SHAW",
    saID: "SPR2948",
  },
  {
    firstName: "RAJKUMAR",
    lastName: "SAU",
    address: "39/B1 S D MUKHERJEE LANE*PO-RISHRA*HOOGHLY 712248",
    fullName: "RAJKUMAR SAU",
    saID: "SPR2949",
  },
  {
    firstName: "SHEW KUMAR",
    lastName: "SHAW",
    address: "VILL&PO-ITAKHOLA*VIA-NAHARBARI*SONITPUR-784182*ASSAM",
    fullName: "SHEW KUMAR SHAW",
    saID: "SPR2950",
  },
  {
    firstName: "SHIB NARAYAN",
    lastName: "SHAW",
    address: "63/2 OLD MULAJORE ROAD*PO-KANKINARA*DIST-24 PGS (NORTH)-743126",
    fullName: "SHIB NARAYAN SHAW",
    saID: "SPR2951",
  },
  {
    firstName: "ANIL",
    lastName: "SIB",
    address: "VILL-DEBIPUR*PO-KAMLASAGAR*TRIPURA.",
    fullName: "ANIL SIB",
    saID: "SPR2952",
  },
  {
    firstName: "BIRENDRA BIJAY",
    lastName: "SHIL",
    address: "SILCHAR POLYTECHNIC*PO-MEHERPUR*CACHAR 788015",
    fullName: "BIRENDRA BIJAY SHIL",
    saID: "SPR2953",
  },
  {
    firstName: "NIRANJAN",
    lastName: "SEAL",
    address: "NORTH BADARGHAT*P.O.A.D.NAGAR*AGARTALA-799003*TRIPURA(W).",
    fullName: "NIRANJAN SEAL",
    saID: "SPR2954",
  },
  {
    firstName: "LATE BHUPESH CHANDRA",
    lastName: "SHUKLABAIDYA",
    address: "P K COLONY, STATION ROAD*PO-DULIAJAN*DIBRUGARH-786 602.",
    fullName: "LATE BHUPESH CHANDRA SHUKLABAIDYA",
    saID: "SPR2955",
  },
  {
    firstName: "LATE PRABIR KUMAR",
    lastName: "SHYAM",
    address: "VILL-BRAHMANKUDA*PO-SIMNA*TRIPURA.",
    fullName: "LATE PRABIR KUMAR SHYAM",
    saID: "SPR2956",
  },
  {
    firstName: "RATHINDRA LAL",
    lastName: "SHYAM",
    address: "PO&VILL-NELLI*DIST-MARIGAON-782410*(ASSAM)",
    fullName: "RATHINDRA LAL SHYAM",
    saID: "SPR2957",
  },
  {
    firstName: "AMAL KUMAR",
    lastName: "SHYAMAL",
    address:
      "VILL-BISWANATHPUT*PO-DAKSHIN DAUKI*VIA-CONTAI*DIST-PURBA MEDINIPUR-721450",
    fullName: "AMAL KUMAR SHYAMAL",
    saID: "SPR2958",
  },
  {
    firstName: "ATISABHA",
    lastName: "SIKDAR",
    address: "C/O DURGARANI BISWAS*VILL-ADITYAPUR*PO-MAJDIA*NADIA-741507",
    fullName: "ATISABHA SIKDAR",
    saID: "SPR2959",
  },
  {
    firstName: "LATE JYOTISH CHANDRA",
    lastName: "SIKDAR",
    address: "DIST SEED FIRM*PO-SUSUNIA*DT-BANKURA.",
    fullName: "LATE JYOTISH CHANDRA SIKDAR",
    saID: "SPR2960",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "SIKDAR",
    address: "P62 SENHATI COLONY *CALCUTTA-34",
    fullName: "LATE SURESH CHANDRA SIKDAR",
    saID: "SPR2961",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "SIKDER",
    address: "VILL/PO-BHADRESWAR*DIST-HOOGHLY",
    fullName: "LATE DEBENDRA NATH SIKDER",
    saID: "SPR2962",
  },
  {
    firstName: "LATE BAKSURAM",
    lastName: "SIL",
    address: "BAGBERA*PO-JAMSHEDPUR-2*SINGHBHUM",
    fullName: "LATE BAKSURAM SIL",
    saID: "SPR2963",
  },
  {
    firstName: "LATE MONORANJAN",
    lastName: "SIL",
    address: "VIL&PO.BALIA*NADIA",
    fullName: "LATE MONORANJAN SIL",
    saID: "SPR2964",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "SIL",
    address: "VILL-JYOTINAGAR*PO-PHANSHIDEWA*DARJEELING-734434",
    fullName: "NARAYAN CHANDRA SIL",
    saID: "SPR2965",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "SIL",
    address: "PO&VILL-JIRAT*DIST-HOOGHLY-712501",
    fullName: "PRAFULLA KUMAR SIL",
    saID: "SPR2966",
  },
  {
    firstName: "RATAN KRISNA",
    lastName: "BRAHMACHARISEAL",
    address: "SATSANG  SWASTISIBIR*PO.KRISNANAGAR*NADIA-741101",
    fullName: "RATAN KRISNA BRAHMACHARISEAL",
    saID: "SPR2967",
  },
  {
    firstName: "LATE ACHHALAL",
    lastName: "SINGH",
    address: "P.N.T.BONGAIGAON*PO-BANGAIGAON*GOALPARA",
    fullName: "LATE ACHHALAL SINGH",
    saID: "SPR2968",
  },
  {
    firstName: "AMRIT NARAYAN",
    lastName: "SINGH",
    address: "VILL/PO-RAMPARA.*DIST-MURSHIDABAD-742189.",
    fullName: "AMRIT NARAYAN SINGH",
    saID: "SPR2969",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "SINGH",
    address: "VILL-HASPONA*PO-MAHESHBATTANA*PURNEA",
    fullName: "ANIL KUMAR SINGH",
    saID: "SPR2970",
  },
  {
    firstName: "LATE ASHOKE KUMAR",
    lastName: "SINGH",
    address: "T D E*PO-MOTIHARI*DT-EAST CHAMPARAN-845401",
    fullName: "LATE ASHOKE KUMAR SINGH",
    saID: "SPR2971",
  },
  {
    firstName: "LATE BAIKUNTHA PRASAD",
    lastName: "SINGH",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE BAIKUNTHA PRASAD SINGH",
    saID: "SPR2972",
  },
  {
    firstName: "LATE BALESHWAR PRASAD",
    lastName: "SINGH",
    address: "GUARD N E RLY*PO/DT-SAMASTIPUR",
    fullName: "LATE BALESHWAR PRASAD SINGH",
    saID: "SPR2973",
  },
  {
    firstName: "BASUDEO",
    lastName: "SINGH",
    address: "DISTRICT POST MASTER*PO/DT-KATIHAR",
    fullName: "BASUDEO SINGH",
    saID: "SPR2974",
  },
  {
    firstName: "BHAGABAN SARAN",
    lastName: "SINGH",
    address: "AT+PO-RAMPARA*DIST-MURSHIDABAD-742189",
    fullName: "BHAGABAN SARAN SINGH",
    saID: "SPR2975",
  },
  {
    firstName: "LATE BIJOY BAHADUR PD",
    lastName: "SINGH",
    address: "*SATSANG.DEOGHAR",
    fullName: "LATE BIJOY BAHADUR PD SINGH",
    saID: "SPR2976",
  },
  {
    firstName: "LATE BIRMAN SINGH",
    lastName: "THAPA",
    address: "SORASHI BHAWAN*SATSANG 814116",
    fullName: "LATE BIRMAN SINGH THAPA",
    saID: "SPR2977",
  },
  {
    firstName: "CHANDRAMOHAN",
    lastName: "SINGH",
    address: "SITALGACHH*PO.GHORAGACHH*UTTAR DINAJPUR",
    fullName: "CHANDRAMOHAN SINGH",
    saID: "SPR2978",
  },
  {
    firstName: "LATE CHANDRASEKHAR PD",
    lastName: "SINGH",
    address: "AT/PO PANAPURKARIAT*VIA KANTI*MUZZAFARPUR-843109",
    fullName: "LATE CHANDRASEKHAR PD SINGH",
    saID: "SPR2979",
  },
  {
    firstName: "LATE DAMODER PD",
    lastName: "SINGH",
    address: "ADVOCATE*VILL-MOHANNAGAR*PO-CHAPRA*DIST-CHAPRA",
    fullName: "LATE DAMODER PD SINGH",
    saID: "SPR2980",
  },
  {
    firstName: "LATE DASARATH",
    lastName: "SINGH",
    address: "VILL-CHARKATAR*PO-THAKUR NAHAN*GODDA",
    fullName: "LATE DASARATH SINGH",
    saID: "SPR2981",
  },
  {
    firstName: "LATE DASARATH",
    lastName: "SINGH",
    address: "AT-KHEMPUR*PO-CHARALMANI*DIST-MALDA",
    fullName: "LATE DASARATH SINGH",
    saID: "SPR2982",
  },
  {
    firstName: "DEB NARAYAN",
    lastName: "SINGH",
    address: "VILL-GODIKAJRA*PO-PATTAJORINAZRA*DIST-S.P.",
    fullName: "DEB NARAYAN SINGH",
    saID: "SPR2983",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "SINHA",
    address:
      "RAMALAYAM*D.NO-10-7-20,IST FLOOR*RAMANAGAR*VISAKHAPATTANAM 530 002",
    fullName: "DILIP KUMAR SINHA",
    saID: "SPR2984",
  },
  {
    firstName: "DIPNARAYAN",
    lastName: "SINGH",
    address: "ARRAHA+RATANPURA*BHABAN (GANGJALA)*SAHARSA-852201*PAN-AIMPS5219D",
    fullName: "DIPNARAYAN SINGH",
    saID: "SPR2985",
  },
  {
    firstName: "LATE GAGANDEO PRASAD",
    lastName: "SINGH",
    address: "VILL&PO/DIST-VAISHALI*BIHAR-844128",
    fullName: "LATE GAGANDEO PRASAD SINGH",
    saID: "SPR2986",
  },
  {
    firstName: "GAJENDRA PRASAD",
    lastName: "SINGH",
    address: "TEACHER,NEW COLONY*BALUGHAT*MUZAFFARPUR-842001*PAN AIAPS9340R",
    fullName: "GAJENDRA PRASAD SINGH",
    saID: "SPR2987",
  },
  {
    firstName: "GOKUL",
    lastName: "SINGH",
    address: "SATSANG*PO-SATSANG*JHARKHAND",
    fullName: "GOKUL SINGH",
    saID: "SPR2988",
  },
  {
    firstName: "HARERAM",
    lastName: "SINGH",
    address: "ARRAHA*RATANPURA BHABAN*GANGJALA*PO-SAHARSA*SAHARSA",
    fullName: "HARERAM SINGH",
    saID: "SPR2989",
  },
  {
    firstName: "LATE HARIDAS",
    lastName: "SINGH",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE HARIDAS SINGH",
    saID: "SPR2990",
  },
  {
    firstName: "HARI SANKAR",
    lastName: "SINGH",
    address: "C-33/148-B-6D*CHANDUA CHHITTUPUR*VARANASI U.P.",
    fullName: "HARI SANKAR SINGH",
    saID: "SPR2991",
  },
  {
    firstName: "LATE JOGENDRA PRASAD",
    lastName: "SINGH",
    address: "*SATSANG",
    fullName: "LATE JOGENDRA PRASAD SINGH",
    saID: "SPR2992",
  },
  {
    firstName: "LATE KESHAB",
    lastName: "SINGH",
    address: "T.T.E N F RLY*PO/DT SAMASTIPUR",
    fullName: "LATE KESHAB SINGH",
    saID: "SPR2993",
  },
  {
    firstName: "KUNWAR PRATAP",
    lastName: "SINGH",
    address: "VILL-GORAIYYA*PO-DHANKOLI*DIST-UNNAO*U.P.-209863",
    fullName: "KUNWAR PRATAP SINGH",
    saID: "SPR2994",
  },
  {
    firstName: "LATE LAXMI NARAYAN",
    lastName: "SINGH",
    address:
      "C/O-LT RAMPRATAP SINGH*AT/PO-TOPE*VIA-KARAIPARSURAI*PATNA 801 304",
    fullName: "LATE LAXMI NARAYAN SINGH",
    saID: "SPR2995",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "SINGH",
    address: "TIRRIHANA T.E.*PO-BELGACHI*DARJEELING.",
    fullName: "LATE NARAYAN CHANDRA SINGH",
    saID: "SPR2996",
  },
  {
    firstName: "LATE NARENDRA PRATAP",
    lastName: "SINGH",
    address: "VILL-PARTAPATTI(KHARARI)*PO-HATHAURI*DARBHANGA.",
    fullName: "LATE NARENDRA PRATAP SINGH",
    saID: "SPR2997",
  },
  {
    firstName: "LATE NARSINGH PD",
    lastName: "SINGH",
    address: "115 CLERK PAYBILL OFFICE*E RLY OFFICE*PO-JAMALPUR*MUNGER",
    fullName: "LATE NARSINGH PD SINGH",
    saID: "SPR2998",
  },
  {
    firstName: "LATE PRABHU",
    lastName: "SINGH",
    address: "C/O RABINDRA PD. SHARMA*ISMAIL CHAK*GOVIND CHAK*SARAN 841101.",
    fullName: "LATE PRABHU SINGH",
    saID: "SPR2999",
  },
  {
    firstName: "LATE PRATAP",
    lastName: "SINGH",
    address: "MALLAGURI BUS STAND*PO-PRADHANNAGAR*SILIGURI*DARJEELING.",
    fullName: "LATE PRATAP SINGH",
    saID: "SPR3000",
  },
  {
    firstName: "PRATAP NARAYAN",
    lastName: "SINGH",
    address:
      '"ARCHANA BHAWAN"*PROFESSOR\'S COLONY*GANGJALA*SAHARSA-852201*PAN NO-AGNPS6538A',
    fullName: "PRATAP NARAYAN SINGH",
    saID: "SPR3001",
  },
  {
    firstName: "LATE RAMPRAVESH",
    lastName: "SINGH",
    address: "GANDHI TOLA*CHAIBASA*SINGBHUM.",
    fullName: "LATE RAMPRAVESH SINGH",
    saID: "SPR3002",
  },
  {
    firstName: "LATE RAMANAND PD",
    lastName: "SINGH",
    address: "NAYATOLA NADHAPUR*PO-BASUDEOPUR*DT-MONGHYR.",
    fullName: "LATE RAMANAND PD SINGH",
    saID: "SPR3003",
  },
  {
    firstName: "LATE RAMANUJ",
    lastName: "SINGH",
    address: "T.T.E;QR NO -T37/B*BARAUNI JN*BEGUSARAI",
    fullName: "LATE RAMANUJ SINGH",
    saID: "SPR3004",
  },
  {
    firstName: "LATE RAMEKBAL",
    lastName: "SINGH",
    address: "AMGOLA SATSANG KENDRA*PLANNING CUM SURVEY OFFICE*MUZAFFARPUR.",
    fullName: "LATE RAMEKBAL SINGH",
    saID: "SPR3005",
  },
  {
    firstName: "LATE RAMESHWAR PD",
    lastName: "SINGH",
    address: "R.F. 319,KANKARBAG*PATNA-800020.",
    fullName: "LATE RAMESHWAR PD SINGH",
    saID: "SPR3006",
  },
  {
    firstName: "LATE RAMNAGINA",
    lastName: "SINGH",
    address: "AT/PO BRINDABAN LOHCHI*MUNGER",
    fullName: "LATE RAMNAGINA SINGH",
    saID: "SPR3007",
  },
  {
    firstName: "RAMPAL",
    lastName: "SINGH",
    address:
      "VILL/PO-BHADUGIRI*VIA-GOSSAINGAON*KOKRAJHAR-783360*PAN AIKPS1383A",
    fullName: "RAMPAL SINGH",
    saID: "SPR3008",
  },
  {
    firstName: "RANBIR",
    lastName: "SINGH",
    address: "VILL-PANHANS* PO-SUHRIDNAGAR*BEGUSARAI.",
    fullName: "RANBIR SINGH",
    saID: "SPR3009",
  },
  {
    firstName: "SADAN PRASAD",
    lastName: "SINGH",
    address: "AT&PO-BELABAGAN*DEOGHAR",
    fullName: "SADAN PRASAD SINGH",
    saID: "SPR3010",
  },
  {
    firstName: "LATE SANATAN",
    lastName: "SINGH",
    address: "SATSANG KENDRA*PHULBANI-762001",
    fullName: "LATE SANATAN SINGH",
    saID: "SPR3011",
  },
  {
    firstName: "SANCHA",
    lastName: "SINGH",
    address:
      "SWASTIRANJANI*ADARSH GRAM ROAD NO-1 EAST SIDE*KOLHUA PAYGAMBARPUR*MUZAFFARPUR",
    fullName: "SANCHA SINGH",
    saID: "SPR3012",
  },
  {
    firstName: "SATISH KUMAR",
    lastName: "SINHA",
    address:
      "H/O ASHOK KUMAR SINGH*ASHOK PATH;CHANDNI CHAWK*KANKE ROAD*RANCHI 8*PAN-ABUPS2609G",
    fullName: "SATISH KUMAR SINHA",
    saID: "SPR3013",
  },
  {
    firstName: "SATRUGHAN PD",
    lastName: "SINGH",
    address: "VILL/PO-GAMAIL*VIA-BEHARIGANJ*MADHEYPURA-852101",
    fullName: "SATRUGHAN PD SINGH",
    saID: "SPR3014",
  },
  {
    firstName: "SHANKER",
    lastName: "SINGH",
    address: "VILL-CHHOLAGORA*SARJAMDA*VIA-TATANAGAR*EAST SINGHBHUM",
    fullName: "SHANKER SINGH",
    saID: "SPR3015",
  },
  {
    firstName: "LATE SHEO NATH",
    lastName: "SINGH",
    address: "GUARD,QR NO.435A*G FIELDS COLONY*PO/DT-SAMASTIPUR.",
    fullName: "LATE SHEO NATH SINGH",
    saID: "SPR3016",
  },
  {
    firstName: "SIBDAYAL",
    lastName: "SINGH",
    address: "AT+PO-RAMPARA*DIST-MURSHIDABAD. PIN-742189",
    fullName: "SIBDAYAL SINGH",
    saID: "SPR3017",
  },
  {
    firstName: "LATE SURAJ",
    lastName: "SINGH",
    address:
      "JAIPRAKASH NAGAR*ROAD NO-5,SANJAY PATH*DIMNA RD,MANGO*JAMSHEDPUR-831012",
    fullName: "LATE SURAJ SINGH",
    saID: "SPR3018",
  },
  {
    firstName: "SUSHIL KUMAR",
    lastName: "SINGH",
    address:
      "SATSANG VIHAR,DHARBHANGA*VILL-BHAIROPATTY*PO-MILKICHAK*DHARBHANGA-846009",
    fullName: "SUSHIL KUMAR SINGH",
    saID: "SPR3019",
  },
  {
    firstName: "YUGAL KISHORE",
    lastName: "SINGH",
    address: "SATSANG COLONY*BHAIRO PATTI*PO-MILKI CHAWK*DARBHANGA-846009",
    fullName: "YUGAL KISHORE SINGH",
    saID: "SPR3020",
  },
  {
    firstName: "LATE ANADI RANJAN",
    lastName: "SINGHMAHAPATRA",
    address: "VILL/PO-AYMA*BANKURA PIN-722138",
    fullName: "LATE ANADI RANJAN SINGHMAHAPATRA",
    saID: "SPR3021",
  },
  {
    firstName: "ANADI PRASAD",
    lastName: "SINGHA",
    address: "PO-GANGAJALGHATI*DIST-BANKURA-722133",
    fullName: "ANADI PRASAD SINGHA",
    saID: "SPR3022",
  },
  {
    firstName: "LATE ANIL CHANDRA",
    lastName: "SINGHA",
    address: "AT-KHANTA*PO-RASOKHOWA*UTTAR DINAJPUR",
    fullName: "LATE ANIL CHANDRA SINGHA",
    saID: "SPR3023",
  },
  {
    firstName: "LATE BATUL CHANDRA",
    lastName: "SINGHA",
    address: "PANCHPATA SATSANG CENTRE*PO-PANCHPUTA*DIST-24 PARGANAS 743273",
    fullName: "LATE BATUL CHANDRA SINGHA",
    saID: "SPR3024",
  },
  {
    firstName: "LATE BENODE LAL",
    lastName: "SINGHA",
    address: "AT&PO-BAKLA*VIA-BAXIRHAT*COOCHBEHAR",
    fullName: "LATE BENODE LAL SINGHA",
    saID: "SPR3025",
  },
  {
    firstName: "DURGAPADA",
    lastName: "SINGHA",
    address: "VILL-KATNAR*PO-SANBANDHA*BANKURA-722180",
    fullName: "DURGAPADA SINGHA",
    saID: "SPR3026",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "SINGHA",
    address: "VILL/PO-KAKRAGAON*DIST-BONGAIGAON 783372",
    fullName: "LATE GANESH CHANDRA SINGHA",
    saID: "SPR3027",
  },
  {
    firstName: "GIRISH CHANDRA",
    lastName: "SINGHA",
    address: "KAYASTHA PARA*AT/PO-RANGJULI*GOALPARA-783130*ASSAM",
    fullName: "GIRISH CHANDRA SINGHA",
    saID: "SPR3028",
  },
  {
    firstName: "LATE HIMANGSHU KUMAR",
    lastName: "SINGHA",
    address: "NAROTTAMPUR*PO-KAHALA*MALDA",
    fullName: "LATE HIMANGSHU KUMAR SINGHA",
    saID: "SPR3029",
  },
  {
    firstName: "INDRAJIT",
    lastName: "SINHA",
    address: "PO-AMARKANAN*BANKURA-722133",
    fullName: "INDRAJIT SINHA",
    saID: "SPR3030",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "SINGHA",
    address: "AT BAMUNGORA,PO NUTANGARH*DIST BANKURA",
    fullName: "MADAN MOHAN SINGHA",
    saID: "SPR3031",
  },
  {
    firstName: "NANDA KUMAR",
    lastName: "SINGHA",
    address: "VILL-CHAYARANBASTI*PO-BIHARA BAZAR*CACHAR 788817*PAN-CODPS3716Q",
    fullName: "NANDA KUMAR SINGHA",
    saID: "SPR3032",
  },
  {
    firstName: "PANCHANAN",
    lastName: "SINGHA",
    address: "VILL/PO-AMARKANAN*BANKURA-722133",
    fullName: "PANCHANAN SINGHA",
    saID: "SPR3033",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "SINGHA",
    address: "VILL-GAJGHANTA*PO-MOGRA*STATION ROAD*DIST.HOOGHLY",
    fullName: "LATE RABINDRA NATH SINGHA",
    saID: "SPR3034",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "SINGHA",
    address: "MIRJAPUR*U.P.(DUPLICATE)",
    fullName: "RABINDRA NATH SINGHA",
    saID: "SPR3035",
  },
  {
    firstName: "LATE RAMSADAY",
    lastName: "SINGHA",
    address: "KERANI BAJAR*PO&DT-BANKURA",
    fullName: "LATE RAMSADAY SINGHA",
    saID: "SPR3036",
  },
  {
    firstName: "SADHAN CHANDRA",
    lastName: "SINGHA",
    address: "VILL-MOBARAKPUR*KALITALA*PO-DEBIPUR*DIST-BARDDHAMAN-713146",
    fullName: "SADHAN CHANDRA SINGHA",
    saID: "SPR3037",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "SINGHA",
    address: "AT-TILOKCHANDRAPUR*PO-KAKDWIP*KALINAGAR*DIST-24 PGS SOUTH-743347",
    fullName: "SANTOSH KUMAR SINGHA",
    saID: "SPR3038",
  },
  {
    firstName: "LATE SUBAL CHANDRDA",
    lastName: "SINGHA",
    address: "VILL/PO-PATILADAHA*DIST-BONGAIGAON",
    fullName: "LATE SUBAL CHANDRDA SINGHA",
    saID: "SPR3039",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "SINGHA",
    address: "178,M B ROAD*KAILASH APPT*PO-BIRATI*KOLKATA-51",
    fullName: "LATE SUDHIR KUMAR SINGHA",
    saID: "SPR3040",
  },
  {
    firstName: "LATE NISHI KANTA",
    lastName: "SINGHACHOUDHURY",
    address:
      "QR NO 118(A)D.T.S.HILL COLONY*PO-LUMDING*DT-NOWGANG*PAN BDBPC7757B",
    fullName: "LATE NISHI KANTA SINGHACHOUDHURY",
    saID: "SPR3041",
  },
  {
    firstName: "LATE BARINDRA NARAYAN",
    lastName: "SINGHABARUA",
    address:
      "ASST.HEAD MASTER*FAKIRAGRAM HIGH SCHOOL*PO-FAKIRAGRAM*DIST-GOALPARA",
    fullName: "LATE BARINDRA NARAYAN SINGHABARUA",
    saID: "SPR3042",
  },
  {
    firstName: "LATE SAURINDRA PRASAD",
    lastName: "SINGHAROY",
    address: "VILL/PO-MITHIPUR*MURSHIDABAD",
    fullName: "LATE SAURINDRA PRASAD SINGHAROY",
    saID: "SPR3043",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "SINGHAROY",
    address: "77/1,SRIRAMDHANG  ROAD*PO-SALKIA*DT.HOWRAH",
    fullName: "LATE SUKUMAR SINGHAROY",
    saID: "SPR3044",
  },
  {
    firstName: "LATE JOY KUMAR",
    lastName: "SINHA",
    address: "VILL/PO-ABHANGA *KAMALPUR*DHALAI*TRIPURA-799289",
    fullName: "LATE JOY KUMAR SINHA",
    saID: "SPR3045",
  },
  {
    firstName: "LATE NARENDRA KUMAR",
    lastName: "SINHA",
    address: "BENTA ROAD*PO-LAHERIASARAI*DARBHANGA-846001",
    fullName: "LATE NARENDRA KUMAR SINHA",
    saID: "SPR3046",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "SINHA",
    address: "SMRITI MANDIR*PURANDAH*DEOGHAR*PAN NO.AGRPS5500N",
    fullName: "PRAMOD KUMAR SINHA",
    saID: "SPR3047",
  },
  {
    firstName: "LATE R C",
    lastName: "SINHA",
    address: "147 MIG KANKARBAGH*PATNA-800020",
    fullName: "LATE R C SINHA",
    saID: "SPR3048",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "SINHA",
    address: "PLOT NO.10187 PHASE 5*K K SINGH COLONY*CHIRA CHAS*BOKARO",
    fullName: "RADHESHYAM SINHA",
    saID: "SPR3049",
  },
  {
    firstName: "SAMAY CHAND",
    lastName: "SINHA",
    address: "VIL-DHANPARA*PO.KARANDIGHI*UTTAR DINAJPUR-733215",
    fullName: "SAMAY CHAND SINHA",
    saID: "SPR3050",
  },
  {
    firstName: "LATE SRIKRISHNA",
    lastName: "SINHA",
    address: "VILL&PO-RAMPARA*MURSHIDABAD.",
    fullName: "LATE SRIKRISHNA SINHA",
    saID: "SPR3051",
  },
  {
    firstName: "SUBODH KUMAR",
    lastName: "SINHA",
    address: "SMRITI MANDIR*PURANDAH*DEOGHAR",
    fullName: "SUBODH KUMAR SINHA",
    saID: "SPR3052",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "SOMEROY",
    address: "PO-DURGAPUR-5*DT-BURDWAN",
    fullName: "LATE KUMUD RANJAN SOMEROY",
    saID: "SPR3053",
  },
  {
    firstName: "LATE NIRAN CHANDRA",
    lastName: "SONOWAL",
    address: "SATSANG KENDRA*PO-JOYRAMPUR*VIA-GHILOMALA*DIST-DHEMAJI",
    fullName: "LATE NIRAN CHANDRA SONOWAL",
    saID: "SPR3054",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "SOREN",
    address: "VILL-MAHULIBANI*PO-NUTANGARH*DT-BANKURA.",
    fullName: "LATE MANMATHA NATH SOREN",
    saID: "SPR3055",
  },
  {
    firstName: "LATE RAMCHANDRA",
    lastName: "SOREN",
    address: "BATHANBARI*PO.BHURKUNDABARI*PURULIA-723133",
    fullName: "LATE RAMCHANDRA SOREN",
    saID: "SPR3056",
  },
  {
    firstName: "BINOD KUMAR",
    lastName: "SRIVASTAV",
    address:
      "HOUSE NO-1,NAGINAPURI COLONY*ADITYAPUR-1*JAMSHEDPUR-831013*PAN-ADEPS7516A",
    fullName: "BINOD KUMAR SRIVASTAV",
    saID: "SPR3057",
  },
  {
    firstName: "N",
    lastName: "SRINIVASAN",
    address: "27,ABDUL AZIZ STREET*T NAGAR MADRAS-17",
    fullName: "N SRINIVASAN",
    saID: "SPR3058",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SRIVASTAVA",
    address:
      "H N 821 GR FLOOR*11 TH CROSS RD,KPC LAYOUT*KASAVANNAHALLI*BANGALORE*PAN-ADEPS7516A",
    fullName: "ARUN KUMAR SRIVASTAVA",
    saID: "SPR3059",
  },
  {
    firstName: "LATE R C B",
    lastName: "SRIVASTAB",
    address: "RUNGTA HOUSE*PO.CHAIBASA*SINGHBHUM 833201",
    fullName: "LATE R C B SRIVASTAB",
    saID: "SPR3060",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "SRIVASTAV",
    address:
      "EAST OF TAHSIL*SAROJ NAGAR*PO-MOUNATH BHANJAN*DIST-MAU*U.P. 275101",
    fullName: "LATE SURESH CHANDRA SRIVASTAV",
    saID: "SPR3061",
  },
  {
    firstName: "LATE HEM KUMARI",
    lastName: "SUBBA",
    address: "C/O BIMAL STORES*JOGBANI*PURNIA",
    fullName: "LATE HEM KUMARI SUBBA",
    saID: "SPR3062",
  },
  {
    firstName: "S V",
    lastName: "SUBRAMANIUM",
    address: "FLAT NO-5,2ND FLOOR*PRICE COURT*BOMBAY-1",
    fullName: "S V SUBRAMANIUM",
    saID: "SPR3063",
  },
  {
    firstName: "RAI MOHAN",
    lastName: "SUR",
    address: "PO/VILL SANTIR BAJAR*TRIPURA-799144",
    fullName: "RAI MOHAN SUR",
    saID: "SPR3064",
  },
  {
    firstName: "RAMDAS LAXMAN",
    lastName: "SURYAWANSHI",
    address: "VILL/PO-CHAKAN*TAL-RAJGURUNAGAR*DIST-PUNE-410501*PAN-ADQPS9577H",
    fullName: "RAMDAS LAXMAN SURYAWANSHI",
    saID: "SPR3065",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "SUTAR",
    address: "SHAKTIFARM BAJAR*PO-SHAKTIFARM*NAINITAL*U.P.",
    fullName: "LATE MANINDRA NATH SUTAR",
    saID: "SPR3066",
  },
  {
    firstName: "LATE ABANI",
    lastName: "SUTRADHAR",
    address: "PO-KATLICHERRA*DT-HAILAKANDI",
    fullName: "LATE ABANI SUTRADHAR",
    saID: "SPR3067",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SWAIN",
    address: "AT-TANGI(MATHASAHI)*PO-RAMGARH*CUTTACK-754022*PAN-AYKPS1806Q",
    fullName: "ARUN KUMAR SWAIN",
    saID: "SPR3068",
  },
  {
    firstName: "BANCHHA NIDHI",
    lastName: "SWAIN",
    address: "AT-BENAPUR*PO-KANTIGARIA*DIST-JAJPUR",
    fullName: "BANCHHA NIDHI SWAIN",
    saID: "SPR3069",
  },
  {
    firstName: "LATE BANSHIDHAR",
    lastName: "SWAIN",
    address:
      "ASHIS NIKET,K-4, L I G 509*AT-KALINGANAGAR*PO-PATRAPADA*BHUBANESWAR-751019*KHURDA",
    fullName: "LATE BANSHIDHAR SWAIN",
    saID: "SPR3070",
  },
  {
    firstName: "BANSHIDHAR",
    lastName: "SWAIN",
    address:
      "AT-PANISALIA*UGRATARA COLONY)*PO/ DIST-JAGATSINGHPUR-754103*PAN-DRTPS2950C",
    fullName: "BANSHIDHAR SWAIN",
    saID: "SPR3071",
  },
  {
    firstName: "BHIMSEN",
    lastName: "SWAIN",
    address:
      "FLAT NO-304,APPOLO ASHIYANA APPT.*PALASUNI*BHUBANESWAR-751025*KHURDA",
    fullName: "BHIMSEN SWAIN",
    saID: "SPR3072",
  },
  {
    firstName: "BIRABAR",
    lastName: "SWAIN",
    address:
      '"SWASTYAYANA"*AT/PO-SISUPALGARH*OLD TOWN,BHUBANESWAR-751002*PAN-DMZPS7770H',
    fullName: "BIRABAR SWAIN",
    saID: "SPR3073",
  },
  {
    firstName: "LATE CHANDRAMANI",
    lastName: "SWAIN",
    address: "AT/PO.ASTARANGA*PURI-752109.",
    fullName: "LATE CHANDRAMANI SWAIN",
    saID: "SPR3074",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "SWAIN",
    address: "SATSANG VIHAR BHANDARI POKHARI*BHADRAK*ODISHA",
    fullName: "DHARANIDHAR SWAIN",
    saID: "SPR3075",
  },
  {
    firstName: "GOKULANANDA",
    lastName: "SWAIN",
    address: "AT-SRIDHARPUR*PO-KORKORA*DIST-BHADRAK-756116",
    fullName: "GOKULANANDA SWAIN",
    saID: "SPR3076",
  },
  {
    firstName: "NAGENDRA NATH",
    lastName: "SWAIN",
    address:
      "SUDHA NILAYA*PLOT NO-1331,MAHANADI VIHAR*NAYABAZAR*CUTTACK 753004*PAN-AGLPS2767B",
    fullName: "NAGENDRA NATH SWAIN",
    saID: "SPR3077",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "SWAIN",
    address:
      "L B 120,STAGE IV*LAXMISAGAR BRIT COLONY*BHUBANESWAR-751006*KHURDA*PAN-CNAPS2336C",
    fullName: "RAMESH CHANDRA SWAIN",
    saID: "SPR3078",
  },
  {
    firstName: "SAHADEV",
    lastName: "SWAIN",
    address: "SATSANG VIHAR,ANGUL*AT&PO-ANGUL*DIST-ANGUL-759122.",
    fullName: "SAHADEV SWAIN",
    saID: "SPR3079",
  },
  {
    firstName: "LATE BIRAJ MOHAN",
    lastName: "SWAMI",
    address:
      "WARD NO-16A,NEAR STADIUM,DHUBRI*PO-BIDYAPARA(KHALILPUR)*DHUBRI 783324",
    fullName: "LATE BIRAJ MOHAN SWAMI",
    saID: "SPR3080",
  },
  {
    firstName: "LATE KANDARPA RANJAN",
    lastName: "SWAMI",
    address: "*SATSANG  814116",
    fullName: "LATE KANDARPA RANJAN SWAMI",
    saID: "SPR3081",
  },
  {
    firstName: "LATE REBATI RANJAN",
    lastName: "SWAMI",
    address: "LINK ROAD,JOGENDRA BHAWAN*SILCHAR 6*CACHAR",
    fullName: "LATE REBATI RANJAN SWAMI",
    saID: "SPR3082",
  },
  {
    firstName: "SWARAJ",
    lastName: "SWAMI",
    address:
      "C/O K N OJHA*BISHNUPUR POINT*KENCH'S TRACE*SHILLONG-793004*MEGHALAYAA",
    fullName: "SWARAJ SWAMI",
    saID: "SPR3083",
  },
  {
    firstName: "CAVATUR PADMANATH",
    lastName: "SWAMY",
    address: "KSSMAT PLOT NO 2 MADARAKHAN TANK ROAD*RILPONG MADRAS-10",
    fullName: "CAVATUR PADMANATH SWAMY",
    saID: "SPR3084",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "SWARGIARY",
    address: "VILL-MANPURGAON*PO/DIST-UDALGURI-784509*(ASSAM)",
    fullName: "LATE NAGENDRA NATH SWARGIARY",
    saID: "SPR3085",
  },
  {
    firstName: "LATE RAJANI KANTA",
    lastName: "SWARGIARY",
    address: "V NIZ HARISINGA*PO HARISINGA*DARRANG 784510",
    fullName: "LATE RAJANI KANTA SWARGIARY",
    saID: "SPR3086",
  },
  {
    firstName: "LATE KHAGENDRA NATH",
    lastName: "TAPADER",
    address: "*SATSANG.DEOGHAR",
    fullName: "LATE KHAGENDRA NATH TAPADER",
    saID: "SPR3087",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "TALAPATRA",
    address: "VILL/PO-GAKULPUR*UDAIPUR*DIST-SOUTH TRIPURA-799 114",
    fullName: "NARAYAN CHANDRA TALAPATRA",
    saID: "SPR3088",
  },
  {
    firstName: "LATE SATYA RANJAN",
    lastName: "TALAPATRA",
    address: "ASTT.UNIT OFFICE N.M.E.P*PO-RADHAKISOREPUR,UDAIPUR*TRIPURA",
    fullName: "LATE SATYA RANJAN TALAPATRA",
    saID: "SPR3089",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "TALAPATRA",
    address: "AT/PO-GANGANAGAR*24PARGANAS (NORTH)",
    fullName: "LATE SUKUMAR TALAPATRA",
    saID: "SPR3090",
  },
  {
    firstName: "LATE SUSHIL KUMAR",
    lastName: "TALAPATRA",
    address: "PO-KAMAKSHYAGURI*DT-JALPAIGURI.",
    fullName: "LATE SUSHIL KUMAR TALAPATRA",
    saID: "SPR3091",
  },
  {
    firstName: "LATE DHIRENDRA",
    lastName: "TALUKDAR",
    address: "*CALCUTTA",
    fullName: "LATE DHIRENDRA TALUKDAR",
    saID: "SPR3092",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "TALUKDAR",
    address: "CHIRAILPARA*PO-KALIAGANJ*DT-WEST DINAJPUR.",
    fullName: "LATE DHIRENDRA NATH TALUKDAR",
    saID: "SPR3093",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "TALUKDAR",
    address:
      "SWASTI DEEPA B-17*AMRAPALI H B CO.SOCIETY*NEAR PANCHPERI NAKA*PO-RAIPUR*C.G.-492001",
    fullName: "JATINDRA NATH TALUKDAR",
    saID: "SPR3094",
  },
  {
    firstName: "LATE MADANMOHAN",
    lastName: "TALUKDAR",
    address: "C/O P.K.ROY*VIL&PO-KHAGENHAT*JALPAIGURI",
    fullName: "LATE MADANMOHAN TALUKDAR",
    saID: "SPR3095",
  },
  {
    firstName: "PARITOSH",
    lastName: "TALUKDAR",
    address: "49/1/3, NASKARPARA LANE*PO-B GARDEN*HOWRAH-711103.",
    fullName: "PARITOSH TALUKDAR",
    saID: "SPR3096",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "TALUKDAR",
    address: "22,NABANAGAR*CALCUTTA-700051",
    fullName: "LATE RABINDRA NATH TALUKDAR",
    saID: "SPR3097",
  },
  {
    firstName: "LATE SAMARENDRA NARAYAN",
    lastName: "TALUKDAR",
    address: "VILL RAMPUR*PO SACHNA*DIST SUNAMGANJ*BANGLADESH",
    fullName: "LATE SAMARENDRA NARAYAN TALUKDAR",
    saID: "SPR3098",
  },
  {
    firstName: "SURENDRA NARAYAN",
    lastName: "TALUKDAR",
    address: "VILL-MANPARA*PO-GUWAHATI-781025*KAMRUP*ASSAM",
    fullName: "SURENDRA NARAYAN TALUKDAR",
    saID: "SPR3099",
  },
  {
    firstName: "LATE BIKASH RANJAN",
    lastName: "TALUKDER",
    address: "AT/PO-BHALUKMARI*NAGAON*ASSAM",
    fullName: "LATE BIKASH RANJAN TALUKDER",
    saID: "SPR3100",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "TALUKDER",
    address: "GOVT.CONTRACTOR*PO HAIBARGAN*NOWGONG.",
    fullName: "LATE GANESH CHANDRA TALUKDER",
    saID: "SPR3101",
  },
  {
    firstName: "NRIPENDRA CHANDRA",
    lastName: "TALUKDER",
    address: "AT-PAZAMPARA*PO-NAGARBERA*DIST-KAMRUP-781127*ASSAM",
    fullName: "NRIPENDRA CHANDRA TALUKDER",
    saID: "SPR3102",
  },
  {
    firstName: "PRATAP CHANDRA",
    lastName: "TALUKDER",
    address: "SATSANG VIHAR LANKA*PO LANKA*NAGAON-782446*ASSAM",
    fullName: "PRATAP CHANDRA TALUKDER",
    saID: "SPR3103",
  },
  {
    firstName: "LATE SUJIT RANJAN",
    lastName: "TALUKDER",
    address: "KSHUDIRAM PALLY*PO-ISLAMPUR*UTTAR DINAJPUR*PIN-733 202",
    fullName: "LATE SUJIT RANJAN TALUKDER",
    saID: "SPR3104",
  },
  {
    firstName: "LATE TARINI CHARAN",
    lastName: "TALUKDAR",
    address: "VILL-BARSARKUCHI*PO-MILANPARA*NALBARI",
    fullName: "LATE TARINI CHARAN TALUKDAR",
    saID: "SPR3105",
  },
  {
    firstName: "DIPENDRA NATH",
    lastName: "TANTI",
    address: "DIKHARI T.E*VIL-RANGRANGIA*po-DEMOW*DIBRUGARH-785675*ASSAM",
    fullName: "DIPENDRA NATH TANTI",
    saID: "SPR3106",
  },
  {
    firstName: "LATE FULCHANDA",
    lastName: "TANTI",
    address: "AT/PO-MAHARAJPUR*SAHEBGANJ",
    fullName: "LATE FULCHANDA TANTI",
    saID: "SPR3107",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "TANTY",
    address: "VILL/PO-KUSUMTULA*DIST-SONITPUR 784189.*ASSAM",
    fullName: "LATE GANGADHAR TANTY",
    saID: "SPR3108",
  },
  {
    firstName: "LATE HIRA LAL",
    lastName: "TAPADAR",
    address: "J.K.WATER CO.*148,JESSORE ROAD*CALCUTTA-700055",
    fullName: "LATE HIRA LAL TAPADAR",
    saID: "SPR3109",
  },
  {
    firstName: "LATE MATI RANJAN",
    lastName: "TAR",
    address: "*COOCHBEHAR.",
    fullName: "LATE MATI RANJAN TAR",
    saID: "SPR3110",
  },
  {
    firstName: "LATE BARADA PD",
    lastName: "TEJ",
    address: "SUBASH COLONY*DIMNA ROAD*PO-MANGO*SINGBHUM 831012.",
    fullName: "LATE BARADA PD TEJ",
    saID: "SPR3111",
  },
  {
    firstName: "BARKI PRASAD",
    lastName: "TELENGA",
    address: "MINISTER,LABOUR*JANATA BHABAN,DISPUR*GAUHATI-6*KAMRUP.",
    fullName: "BARKI PRASAD TELENGA",
    saID: "SPR3112",
  },
  {
    firstName: "LATE RATNESWAR",
    lastName: "TERON",
    address: "VILL/PO-BONDA*PO-NARENGI*GUWAHATI-781026*KAMRUP",
    fullName: "LATE RATNESWAR TERON",
    saID: "SPR3113",
  },
  {
    firstName: "DHANIRAM",
    lastName: "TERONG",
    address: "VILL-THESA THERANGAON*PO-JENKHA*DIST-KARBIANGLONG",
    fullName: "DHANIRAM TERONG",
    saID: "SPR3114",
  },
  {
    firstName: "HEM CHANDRA",
    lastName: "TEWARY",
    address:
      "C/O MRINMOY GHOSH*SHIVA ENCLAVE*GROUND FLOOR,FLAT-1*SOUTH HILL VIEW*ASANSOL-713304",
    fullName: "HEM CHANDRA TEWARY",
    saID: "SPR3115",
  },
  {
    firstName: "AMARNATH",
    lastName: "THAKUR",
    address:
      "NEAR STATE BANK OF INDIA*AT/PO-BARJAMDA*SINGHBHUM-833221*PAN.ACEPT9504G",
    fullName: "AMARNATH THAKUR",
    saID: "SPR3116",
  },
  {
    firstName: "CHANDESWAR PRASAD",
    lastName: "THAKUR",
    address: "AT-RASULPUR*PO-BISHUNDUTTPUR*MUZAFFARPUR",
    fullName: "CHANDESWAR PRASAD THAKUR",
    saID: "SPR3117",
  },
  {
    firstName: "DAMODAR",
    lastName: "THAKUR",
    address:
      "KRISHNA SADAN ;ROAD NO.4*JURAN CHHAPRA*MUZAFFERPUR 842001*PAN-ABBPT4711F",
    fullName: "DAMODAR THAKUR",
    saID: "SPR3118",
  },
  {
    firstName: "DEOKRISHNA",
    lastName: "THAKUR",
    address: "VILL-DEORH*PO-GHOGARDIHA*DT-MADHUBANI",
    fullName: "DEOKRISHNA THAKUR",
    saID: "SPR3119",
  },
  {
    firstName: "GAUTAM PRASAD",
    lastName: "THAKUR",
    address: "M P.ZILA SCHOOL*PO/DT-MUZAFFARPUR",
    fullName: "GAUTAM PRASAD THAKUR",
    saID: "SPR3120",
  },
  {
    firstName: "LATE RAM SHOVIT",
    lastName: "THAKUR",
    address: "SATSANG VIHAR*AMGOLA*MUZAFFARPUR",
    fullName: "LATE RAM SHOVIT THAKUR",
    saID: "SPR3121",
  },
  {
    firstName: "SHESHADEV",
    lastName: "THAKUR",
    address:
      "DEWANSHAHEB PARA*AT&PO-BHAWANIPATNA*KALAHANDI-766001*PAN AAZPT2063R",
    fullName: "SHESHADEV THAKUR",
    saID: "SPR3122",
  },
  {
    firstName: "LATE MANABIR",
    lastName: "THAPA",
    address: "SATSANG CENTER KOHIMA*CHANDMARI*NAGALAND-797001",
    fullName: "LATE MANABIR THAPA",
    saID: "SPR3123",
  },
  {
    firstName: "LATE NAR BAHARDUR",
    lastName: "THAPA",
    address: "DIHARANG HATIALI*PO-DIGHALIA*DIBRUGARH.",
    fullName: "LATE NAR BAHARDUR THAPA",
    saID: "SPR3124",
  },
  {
    firstName: "SAJENDRA",
    lastName: "THOUSEN",
    address: "NUTANHAJA*P.O.LANTING*N.C.HILLS",
    fullName: "SAJENDRA THOUSEN",
    saID: "SPR3125",
  },
  {
    firstName: "LATE HARIBANS",
    lastName: "TIWARY",
    address: "VILL-SAIDPUR KAKARIA*PO-DIGHWARA*DHANBAD",
    fullName: "LATE HARIBANS TIWARY",
    saID: "SPR3126",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "TRIBEDI",
    address: "AT&PO-BELPUKUR*NADIA-741125*PAN-VBVPT7564L",
    fullName: "DILIP KUMAR TRIBEDI",
    saID: "SPR3127",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "TRIPATHI",
    address: "AT/PO.KHANPUR*DIST.HOOGHLY",
    fullName: "LATE DINESH CHANDRA TRIPATHI",
    saID: "SPR3128",
  },
  {
    firstName: "RABINARAYAN",
    lastName: "TRIPATHY",
    address:
      "AT-DEWAN PATNA*PO-PRITIPUR*P.S.-BINJHARPUR*DIST-JAJPUR 755013*PAN-ABWPT6075F",
    fullName: "RABINARAYAN TRIPATHY",
    saID: "SPR3129",
  },
  {
    firstName: "LATE AMBIKESH",
    lastName: "TRIPATHY",
    address: "PURUSHOTTAMPUR*PO-NANDIGRAM*MIDNAPORE.",
    fullName: "LATE AMBIKESH TRIPATHY",
    saID: "SPR3130",
  },
  {
    firstName: "CHITTA RANJAN",
    lastName: "TRIPATHY",
    address: "AT-KHAGADAPAL*PO-BALIAPAL*BALASORE-756026*ORISSA",
    fullName: "CHITTA RANJAN TRIPATHY",
    saID: "SPR3131",
  },
  {
    firstName: "GOPINATH",
    lastName: "TRIPATHY",
    address:
      "PLOT NO-73/5*NEAR CAPITAL HOSPITAL, UNIT-6*BHUBANESWAR-751001*PAN-ABJPT3007C",
    fullName: "GOPINATH TRIPATHY",
    saID: "SPR3132",
  },
  {
    firstName: "KASHINATH",
    lastName: "TRIPATHY",
    address:
      "AT-COLLEGE ROAD*NEAR FCI GODOWN*PO-KEONJHARGARH*KEONJHAR 758001*PAN-ABEPT7170P",
    fullName: "KASHINATH TRIPATHY",
    saID: "SPR3133",
  },
  {
    firstName: "LALIT KUMAR",
    lastName: "TRIPATHY",
    address:
      "AT-NEAR DEULBANDH MODIPADA*PO-MODIPADA*DIST-SAMBALPUR-768001*PAN-AJLPT6081E",
    fullName: "LALIT KUMAR TRIPATHY",
    saID: "SPR3134",
  },
  {
    firstName: "NABIN KUMAR",
    lastName: "TRIPATHY",
    address:
      "DRIVER COLONY, SWAPNAPURI LANE*AT-GOVINDTOLA*PO-DHANUPALI*SAMBALPUR-768100.",
    fullName: "NABIN KUMAR TRIPATHY",
    saID: "SPR3135",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "TRIPATHY",
    address:
      "SATSANG VIHAR ROURKELA*SATSANG MARG,NUABAZAR*ROURKELA-769010*SUNDARGARH",
    fullName: "LATE PRAFULLA KUMAR TRIPATHY",
    saID: "SPR3136",
  },
  {
    firstName: "LATE RAMASHANKAR",
    lastName: "TRIVEDI",
    address: "RAMNAGAR ROAD NO-9*PO-RAMNAGAR*AGARTALA*TRIPURA 799002",
    fullName: "LATE RAMASHANKAR TRIVEDI",
    saID: "SPR3137",
  },
  {
    firstName: "BINOY",
    lastName: "TUDU",
    address: "AT/PO-ASANCHUA*DIST-JAMTARA-815351",
    fullName: "BINOY TUDU",
    saID: "SPR3138",
  },
  {
    firstName: "CHUNKU",
    lastName: "TUDU",
    address: "VILL NO.2 PANIBARI*PO-GURUFALA BAZAR*VIA-GOSSAINGAON*GOALPARA.",
    fullName: "CHUNKU TUDU",
    saID: "SPR3139",
  },
  {
    firstName: "LATE DHARMA",
    lastName: "TUDU",
    address: "AT-MAHALLA*PO.NAZIRPUR*W/DINAJPUR",
    fullName: "LATE DHARMA TUDU",
    saID: "SPR3140",
  },
  {
    firstName: "LATE SHYAMLAL",
    lastName: "TUDU",
    address: "PO-ASANCHUA*VIA-JAMTARA*DIST-DEOGHAR",
    fullName: "LATE SHYAMLAL TUDU",
    saID: "SPR3141",
  },
  {
    firstName: "HIRENDRA CHANDRA",
    lastName: "TULAL",
    address: "THE PICK-UP,LALPURCHAK*PO/DIST-RANCHI-834001",
    fullName: "HIRENDRA CHANDRA TULAL",
    saID: "SPR3142",
  },
  {
    firstName: "LATE NRIPENDRA",
    lastName: "UPADHYAY",
    address: '"SACHI RANI BHABAN"*BHATI ABHAYNAGAR*PO-AGARTALA*TRIPURA',
    fullName: "LATE NRIPENDRA UPADHYAY",
    saID: "SPR3143",
  },
  {
    firstName: "DURGA PRASAD",
    lastName: "UPADHYAY",
    address: "PO/VILL-BEDETI*SONITPUR 784179.",
    fullName: "DURGA PRASAD UPADHYAY",
    saID: "SPR3144",
  },
  {
    firstName: "LATE CHURAMANI",
    lastName: "UPADHYAY",
    address: "AT-GUABARI*PO-PATGAON*KOKRAJHAR-783346.",
    fullName: "LATE CHURAMANI UPADHYAY",
    saID: "SPR3145",
  },
  {
    firstName: "BHAKTA PRASAD",
    lastName: "UPADHYAY",
    address: "UDALGURI SATSANG VIHAR*PO/DIST-UDALGURI-784 509*(ASSAM)",
    fullName: "BHAKTA PRASAD UPADHYAY",
    saID: "SPR3146",
  },
  {
    firstName: "LATE CHANDRA KANTA",
    lastName: "UPADHYAY",
    address: "RAJAKIYA SANSKRIT MAHAVIDYALAYA*LALEPUR,GWALIOR*M.P.",
    fullName: "LATE CHANDRA KANTA UPADHYAY",
    saID: "SPR3147",
  },
  {
    firstName: "LATE CHATURBHUJ",
    lastName: "UPADHYAY",
    address: "VILL.MALUGAON*PO.SILCHAR-2*CACHAR",
    fullName: "LATE CHATURBHUJ UPADHYAY",
    saID: "SPR3148",
  },
  {
    firstName: "LATE PURUSHOTTAM",
    lastName: "UPADHYAYA",
    address: "AT-GOLAIBAM*PO-DIHINGULA*VIA-DIKAM*DIST-DIBRUGARH",
    fullName: "LATE PURUSHOTTAM UPADHYAYA",
    saID: "SPR3149",
  },
  {
    firstName: "RAJANI KANT",
    lastName: "VARMA",
    address: "ENGINEER*NALA ROAD*KADAMKUAN*PATNA-3",
    fullName: "RAJANI KANT VARMA",
    saID: "SPR3150",
  },
  {
    firstName: "LATE RAJESWAR PRASAD",
    lastName: "VERMA",
    address: "AT&PO-KORRA*HAZARIBAGH",
    fullName: "LATE RAJESWAR PRASAD VERMA",
    saID: "SPR3151",
  },
  {
    firstName: "SIYABAR SHARAN",
    lastName: "VERMA",
    address: "AT&PO-SARSI*PURNEA-854306",
    fullName: "SIYABAR SHARAN VERMA",
    saID: "SPR3152",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "VORA",
    address: "*SATSANG*DEOGHAR",
    fullName: "LATE PARESH CHANDRA VORA",
    saID: "SPR3153",
  },
  {
    firstName: "NANI GOPAL",
    lastName: "CHAKRABARTY",
    address: "VILL&PO-ATHURIA*PALTA CINEMA ROAD*24PARGANAS 743427",
    fullName: "NANI GOPAL CHAKRABARTY",
    saID: "SPR3154",
  },
  {
    firstName: "KANAI LAL",
    lastName: "CHOUDHURY",
    address: "SUPARI BAGAN*KRISHNA NAGAR*AGARTALA 799001.*TRIPURA [W]",
    fullName: "KANAI LAL CHOUDHURY",
    saID: "SPR3155",
  },
  {
    firstName: "SANKAR CHANDRA",
    lastName: "KOCH",
    address: "AT-MAGURMARI*PO-ZIKZAK*DIST-WEST GAROHILLS*MEGHALAYA",
    fullName: "SANKAR CHANDRA KOCH",
    saID: "SPR3156",
  },
  {
    firstName: "ASHIM",
    lastName: "RAY",
    address: "VILL-AURANGABAD  TANTIPARA*PO-AURANGABAD*MURSHIDABAD-742201",
    fullName: "ASHIM RAY",
    saID: "SPR3157",
  },
  {
    firstName: "PURAN",
    lastName: "THAPA",
    address:
      "O/O ASSTT.EX ENGR,P.W.D(R&B)*SARIHAJAN SUBDIISION*PO-SARIHAJAN*KARBIANGLONG 782480.",
    fullName: "PURAN THAPA",
    saID: "SPR3158",
  },
  {
    firstName: "DEB KUMAR",
    lastName: "GURUNG",
    address: "BONGAL GAON*PO-BANIPUR*DIBRUGARH 786003",
    fullName: "DEB KUMAR GURUNG",
    saID: "SPR3159",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "GHOSE",
    address:
      "C/O SOMA ALLUMINIUM STORES*MOHONBAGANMORE*PO-SAINTHIA*BIRBHUM-731234",
    fullName: "NARAYAN CHANDRA GHOSE",
    saID: "SPR3160",
  },
  {
    firstName: "PRITI BINOD",
    lastName: "DEY",
    address:
      "D,30 NATUN PALLY*BENACHITY*DURGAPUR-713213*DIST-BURDWAN*PAN-AIYPD3808K",
    fullName: "PRITI BINOD DEY",
    saID: "SPR3161",
  },
  {
    firstName: "SUNURAM",
    lastName: "RAMCHIARI",
    address: "VILL-BAGHMARA*PO-ULUBARI*VIA-THALAMARA*SONITPUR",
    fullName: "SUNURAM RAMCHIARI",
    saID: "SPR3162",
  },
  {
    firstName: "LATE NARENDRA KISHORE",
    lastName: "ACHERJEE",
    address: "VILL-LASKARPARA*PO-BARABISHA*JALPAIGURI.",
    fullName: "LATE NARENDRA KISHORE ACHERJEE",
    saID: "SPR3163",
  },
  {
    firstName: "KSHIRENDRA NATH",
    lastName: "SARKAR",
    address:
      "UJJWALA CHHAPAKHANA,BAROBISHA*PO-BAROBISHA*JALPAIGURI-736207*PAN-CFYPS2718N",
    fullName: "KSHIRENDRA NATH SARKAR",
    saID: "SPR3164",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "SEN",
    address: "PO-KHIRPAI*PASCHIM MEDINIPUR 721 232",
    fullName: "PRAFULLA KUMAR SEN",
    saID: "SPR3165",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "DUTTA",
    address: "VILL-SALTORA*PO-MURAKATI SALTORA*BANKURA 722149",
    fullName: "NEPAL CHANDRA DUTTA",
    saID: "SPR3166",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MAITY",
    address:
      "PO & VILL-NARMA*VIA-LUTUNIA*PASCHIM MEDINIPUR-721166*PAN-CKAPM5708D",
    fullName: "NARAYAN CHANDRA MAITY",
    saID: "SPR3167",
  },
  {
    firstName: "SUBRATA KUMAR",
    lastName: "MUSTAFI",
    address:
      "FALAKATA SATSANG ADHIBESAN KENDRA*STATION MORE*PO-FALAKATA*JALPAIGURI-735211",
    fullName: "SUBRATA KUMAR MUSTAFI",
    saID: "SPR3168",
  },
  {
    firstName: "KSHITISH KUMAR",
    lastName: "KAKATI",
    address: "VILL-SONKANI*PO-BARBARI*DIST-NALBARI",
    fullName: "KSHITISH KUMAR KAKATI",
    saID: "SPR3169",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "HANSDA",
    address: "CCS(OUTSTANDING) OFFICE*14 STAND ROAD(8TH FLOOR)*CALCUTTA 1",
    fullName: "ASHUTOSH HANSDA",
    saID: "SPR3170",
  },
  {
    firstName: "JOGENDRA NARAYAN",
    lastName: "KANGSHABANIK",
    address: "SATSANG ROAD*SILCHAR-788 007",
    fullName: "JOGENDRA NARAYAN KANGSHABANIK",
    saID: "SPR3171",
  },
  {
    firstName: "LATE RATHINDRA KUMAR",
    lastName: "DEB",
    address:
      "(S.P.R.)*SATSANG ASHRAM ROAD*NEAR SATSANG VIHAR,KARIMGANJ*PO/DIST-KARIMGANJ*ASSAM",
    fullName: "LATE RATHINDRA KUMAR DEB",
    saID: "SPR3172",
  },
  {
    firstName: "ANANTA BANDHU",
    lastName: "RAY",
    address: "78B,THAKURPARA ROAD*PO-NAIHATI*24PARGANAS (NORTH)-743165",
    fullName: "ANANTA BANDHU RAY",
    saID: "SPR3173",
  },
  {
    firstName: "BUDHESWAR",
    lastName: "KAIRY",
    address: "PO&VILL-CHANDBASA*SIBSAGAR-785674*ASSAM",
    fullName: "BUDHESWAR KAIRY",
    saID: "SPR3174",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "GHOSH",
    address: "VILL-SADHANPUR*PO-SADHANPUR ULUDANGA*24 PARGANAS (NORTH)-743221",
    fullName: "NIMAI CHANDRA GHOSH",
    saID: "SPR3175",
  },
  {
    firstName: "HIRANMAY",
    lastName: "CHOUDHURY",
    address: "VILL-KHARGRAM*PO-KHARGA KARANDA*BURDWAN.",
    fullName: "HIRANMAY CHOUDHURY",
    saID: "SPR3176",
  },
  {
    firstName: "HARAPRASAD",
    lastName: "DEY",
    address:
      "C/O-SHREE DURGA COLD STORAGE*G T ROAD*PO-MEMARI*BURDWAN*PAN-BEEPD4553E",
    fullName: "HARAPRASAD DEY",
    saID: "SPR3177",
  },
  {
    firstName: "LATE MALAY KUMAR",
    lastName: "NARJARY",
    address: "VILL-NANDARBARI*PO-NEHRUBAZAR 783390*KOKRAJHAR",
    fullName: "LATE MALAY KUMAR NARJARY",
    saID: "SPR3178",
  },
  {
    firstName: "MAHADEB",
    lastName: "SARMA",
    address: "BIJNI SATSANG VIHAR*PO-BIJNI*KOKRAJHAR-783390*ASSAM",
    fullName: "MAHADEB SARMA",
    saID: "SPR3179",
  },
  {
    firstName: "DILIP",
    lastName: "CHETRY",
    address: "SATSANG VIHAR*POLO FIELD*PO-TEZPUR*SONITPUR 784003.",
    fullName: "DILIP CHETRY",
    saID: "SPR3180",
  },
  {
    firstName: "CHIRANJIB KUMAR",
    lastName: "DUTTA",
    address: "C/O L PATI SINGH*PAONA BAJAR*GOVERNOR ROAD*IMPHAL 795001*MANIPUR",
    fullName: "CHIRANJIB KUMAR DUTTA",
    saID: "SPR3181",
  },
  {
    firstName: "ABANI KUMAR",
    lastName: "BRAHMA",
    address: "VILL-BANSGAON M.E.SCHOOL*PO-SARAIBIL*KOKRAJHAR-783350*ASSAM",
    fullName: "ABANI KUMAR BRAHMA",
    saID: "SPR3182",
  },
  {
    firstName: "LATE PITENDRA NATH",
    lastName: "BRAHMA",
    address: "O/O THE D.I. OF SCHOOLS*PO-KOKRAJHAR*KOKRAJHAR",
    fullName: "LATE PITENDRA NATH BRAHMA",
    saID: "SPR3183",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "MALAKAR",
    address: "VILL&PO-BOKAKHAT*SANTIPUR WORD NO-2*GOLAGHAT-785612.",
    fullName: "PARIMAL CHANDRA MALAKAR",
    saID: "SPR3184",
  },
  {
    firstName: "UMESH CHANDRA",
    lastName: "BRAHMA",
    address: "VILL-TANGABARI*PO-NEHERU BAZAR*DIST-CHIRANG BTAD*ASSAM-783393",
    fullName: "UMESH CHANDRA BRAHMA",
    saID: "SPR3185",
  },
  {
    firstName: "LATE BALENDRA",
    lastName: "BASUMATARY",
    address: "VILL-VIRANGGAON*PO-DANGAIGAON*KOKRAJHAR",
    fullName: "LATE BALENDRA BASUMATARY",
    saID: "SPR3186",
  },
  {
    firstName: "NABA KUMAR",
    lastName: "ROY",
    address: "VILL-BHUTUDA*PO-ROL*P.S-PATRASAYER*BANKURA-722206",
    fullName: "NABA KUMAR ROY",
    saID: "SPR3187",
  },
  {
    firstName: "RAMABALLAV",
    lastName: "LENKA",
    address: "ALOK NAGAR*AT/PO-NIALI*DIST-CUTTACK-754004*ODISHA",
    fullName: "RAMABALLAV LENKA",
    saID: "SPR3188",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "MISHRA",
    address: "EX POSTAL EMPLOYEE*VSS NAGAR*BARGARH 768028*PAN-BGSPM4401J",
    fullName: "HRUSHIKESH MISHRA",
    saID: "SPR3189",
  },
  {
    firstName: "NATABAR",
    lastName: "NATH",
    address: "AT-NUA ALATUMA MUNDIDEULI*PO-KAMAKHYANAGAR*DHENKANAL-759018",
    fullName: "NATABAR NATH",
    saID: "SPR3190",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "NATHSARMA",
    address: "AT/PO-HARIPURHAT*VIA-KABIRPUR*DIST-JAJPUR-755009*ODISHA",
    fullName: "AJIT KUMAR NATHSARMA",
    saID: "SPR3191",
  },
  {
    firstName: "RAMCHANDRA",
    lastName: "SAHOO",
    address: "CHHATRA BAZAR*CUTTACK-753012.",
    fullName: "RAMCHANDRA SAHOO",
    saID: "SPR3192",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "PARIDA",
    address: "AT-PURUNA PADA DUDHI COLONY*PO-BHAWANIPATNA*KALAHANDI-766001",
    fullName: "RAMESH CHANDRA PARIDA",
    saID: "SPR3193",
  },
  {
    firstName: "HAREKRUSHNA",
    lastName: "MOHANTY",
    address: "AT/PO-SAKSHIGOPAL*DIST-PURI-752014",
    fullName: "HAREKRUSHNA MOHANTY",
    saID: "SPR3194",
  },
  {
    firstName: "LATE RAMA CHANDRA",
    lastName: "SAHOO",
    address: "ROAD NO.3,GODAVARISH NAGAR*AT/PO-BERHAMPUR*GANJAM",
    fullName: "LATE RAMA CHANDRA SAHOO",
    saID: "SPR3195",
  },
  {
    firstName: "BAIKUNTHA NATH",
    lastName: "BISWAL",
    address: "AT-GHATGHARSAHI*PO-RAJNILGIRI*BALASORE-756040",
    fullName: "BAIKUNTHA NATH BISWAL",
    saID: "SPR3196",
  },
  {
    firstName: "SESHADEVA",
    lastName: "PATTANAIK",
    address:
      "AT-ADUASAHI*PO-BHEJIPUT*VIA-BHANJANAGAR*GANJAM-761126*PAN-BMRPP0281Q",
    fullName: "SESHADEVA PATTANAIK",
    saID: "SPR3197",
  },
  {
    firstName: "DURGA PRASAD",
    lastName: "MISHRA",
    address: "KEONJHAR CENTRAL CO-OP BANK LTD*KEONJHAR-758001",
    fullName: "DURGA PRASAD MISHRA",
    saID: "SPR3198",
  },
  {
    firstName: "RAMESH KUMAR",
    lastName: "NAYAK",
    address: "PLOT NO.-K-4-449*KALINGA NAGAR*BHUBANESWAR 751019*KHURDA",
    fullName: "RAMESH KUMAR NAYAK",
    saID: "SPR3199",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "MONDAL",
    address: "VILL-M.P.V.8*PO-CHITRANGAPALLY*DIST-KORAPUT",
    fullName: "ASHOK KUMAR MONDAL",
    saID: "SPR3200",
  },
  {
    firstName: "LATE PARAMANANDA",
    lastName: "BEHERA",
    address: "AT-BAHARAPITHA*PO-RANGANIPATANA*VIA-ODAGAON*NAYAGARH 752081",
    fullName: "LATE PARAMANANDA BEHERA",
    saID: "SPR3201",
  },
  {
    firstName: "LATE BRUNDABAN",
    lastName: "NATH",
    address: "VILL & PO-HARIPURHAT*VIA-KABIRPUR*JAJPUR 755 009",
    fullName: "LATE BRUNDABAN NATH",
    saID: "SPR3202",
  },
  {
    firstName: "DINESWAR",
    lastName: "TACHHA",
    address: "HATIGARH TEA GARDEN*PO/DIST-JORHAT 785007",
    fullName: "DINESWAR TACHHA",
    saID: "SPR3203",
  },
  {
    firstName: "CHANDRA MOHAN",
    lastName: "MAHATO",
    address:
      "SWASTI BHABAN*RAGHUNATHPUR*PO-JHARGRAM*PASCHIM MEDINIPUR-721507*PAN-AEPPM4717B",
    fullName: "CHANDRA MOHAN MAHATO",
    saID: "SPR3204",
  },
  {
    firstName: "LATE BAMA CHARAN",
    lastName: "PARASAVSHARMA",
    address: "MAGURA*PO.BARUIPARA*KHULNA*BANGLADESH",
    fullName: "LATE BAMA CHARAN PARASAVSHARMA",
    saID: "SPR3205",
  },
  {
    firstName: "D ALENDRA",
    lastName: "NARJARY",
    address: "VILL-PANBARI*PO-MERAPANI*VIA-FURKATING}*JORHAT",
    fullName: "D ALENDRA NARJARY",
    saID: "SPR3206",
  },
  {
    firstName: "DEBABRATA",
    lastName: "PURKAYASTHA",
    address: "STATE BANK OF INDIA*AT/PO.GOLAKGANJ*GOALPARA",
    fullName: "DEBABRATA PURKAYASTHA",
    saID: "SPR3207",
  },
  {
    firstName: "DEBABRATA",
    lastName: "PURKAYASTHA",
    address: "STATE BANK OF INDIA*AT/PO.GOLAKGANJ*GOALPARA",
    fullName: "DEBABRATA PURKAYASTHA",
    saID: "SPR3208",
  },
  {
    firstName: "LATE DAKSHINA RANJAN",
    lastName: "CHATTERJEE",
    address: "*SATSANG",
    fullName: "LATE DAKSHINA RANJAN CHATTERJEE",
    saID: "SPR3209",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "GHOSH",
    address: "GOALPARA",
    fullName: "LATE KIRAN CHANDRA GHOSH",
    saID: "SPR3210",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "BISWAS",
    address: "SATSANG  814116*DEOGHAR*PAN-AFIPB1739A",
    fullName: "SWAPAN KUMAR BISWAS",
    saID: "SPR3211",
  },
  {
    firstName: "NIRAPADA",
    lastName: "SARKAR",
    address: "VILL-GOALPOTA BASIRHAT*PO-BASIRHAT*24PARGANAS NORTH",
    fullName: "NIRAPADA SARKAR",
    saID: "SPR3212",
  },
  {
    firstName: "LATE ROHINI KANTA",
    lastName: "CHAKRABORTY",
    address: "BANSI*PO.DIRAICHANDPUR*SYLHET*BANGLADESH",
    fullName: "LATE ROHINI KANTA CHAKRABORTY",
    saID: "SPR3213",
  },
  {
    firstName: "PROVASH CHANDRA",
    lastName: "MAZUMDAR",
    address: "PO/VILL-BAPUJINAGAR*DIST-NADIA 741121.",
    fullName: "PROVASH CHANDRA MAZUMDAR",
    saID: "SPR3214",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "GHOSHBARMAN",
    address: "DHITARA PASCHIMPARA*PO-SARADAPALLI*HOOGHLY",
    fullName: "LATE ANIL KUMAR GHOSHBARMAN",
    saID: "SPR3215",
  },
  {
    firstName: "LATE GIRIJA KANTA",
    lastName: "SARKAR",
    address: "GHUGHUMARI*PO.KPSANDAH*KHULNA*BANGLADESH",
    fullName: "LATE GIRIJA KANTA SARKAR",
    saID: "SPR3216",
  },
  {
    firstName: "HIMANGSHU RANJAN",
    lastName: "BRAHMA",
    address:
      "HOUSE NO-36/PKT-10,DDA  S-1(A)*NASIRPUR;PALAM*PO-PALAM *NEW DELHI 110045",
    fullName: "HIMANGSHU RANJAN BRAHMA",
    saID: "SPR3217",
  },
  {
    firstName: "GOUR",
    lastName: "SINGHAROY",
    address:
      "C/O M/S GM ENTERPRISES*GURDWARA RD*PO-DURGAPUR-713213*BURDWAN*PAN-ACRPR7018N",
    fullName: "GOUR SINGHAROY",
    saID: "SPR3218",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "ROY",
    address:
      "28, MANORANJAN ROY NAGAR (A)*VIA-A K MUKHERJEE ROAD*4 NO. PARTY OFFICE*CALCUTTA-90.",
    fullName: "ANIL KUMAR ROY",
    saID: "SPR3219",
  },
  {
    firstName: "LATE BHABANI PRASAD",
    lastName: "MEDHI",
    address: "VILL-FUTKIBARI (MEDHIPARA)*PO-FUTKIBARI*DHUBRI",
    fullName: "LATE BHABANI PRASAD MEDHI",
    saID: "SPR3220",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "CHHATUI",
    address: "VILL/PO-MAITIRHAT*P.S.-MAGRAHAT*24PARGANAS (SOUTH)",
    fullName: "GOPAL CHANDRA CHHATUI",
    saID: "SPR3221",
  },
  {
    firstName: "ALANATH",
    lastName: "GHOSH",
    address: "VILL-CHAPRA*PO-BANGALJHEE*NADIA-741123*PAN ADZPG7361A",
    fullName: "ALANATH GHOSH",
    saID: "SPR3222",
  },
  {
    firstName: "LATE RAMESH",
    lastName: "GHOSH",
    address: "2A NETAJI SUBHAS ROAD*PO-BUDGE BUDGE*24 PARGANAS (SOUTH)-700137",
    fullName: "LATE RAMESH GHOSH",
    saID: "SPR3223",
  },
  {
    firstName: "BHOLANATH",
    lastName: "PANDEY",
    address: "VILL/PO-BARIA*24PARGANAS (SOUTH)",
    fullName: "BHOLANATH PANDEY",
    saID: "SPR3224",
  },
  {
    firstName: "SADHU CHARAN",
    lastName: "MAHATO",
    address: "LALUDUNGRI*PO-ANKRO*PURULIA-723129.",
    fullName: "SADHU CHARAN MAHATO",
    saID: "SPR3225",
  },
  {
    firstName: "MANOHAR",
    lastName: "MAHATO",
    address: "VILL&PO-CHATUMATHUR*PURULIA 723130",
    fullName: "MANOHAR MAHATO",
    saID: "SPR3226",
  },
  {
    firstName: "LATE SUSANTA KUMAR",
    lastName: "BANDOPADHYAY",
    address: "B-12/64,KALYANI*PO-KALYANI*DIST-NADIA-741235",
    fullName: "LATE SUSANTA KUMAR BANDOPADHYAY",
    saID: "SPR3227",
  },
  {
    firstName: "MUKUT CHANDRA",
    lastName: "SARMA",
    address: "AC1, FLAT NO-503*ESSEL TOWER, M G RD*GURGAON*HARYANA-122002.",
    fullName: "MUKUT CHANDRA SARMA",
    saID: "SPR3228",
  },
  {
    firstName: "LATE ANANDIRAM",
    lastName: "BORO",
    address: "VILL-DHULABARI,CHARANPARA*PO-DEULKUCHI*VIA-RANGIA*KAMRUP",
    fullName: "LATE ANANDIRAM BORO",
    saID: "SPR3229",
  },
  {
    firstName: "MURALI MOHAN",
    lastName: "BANIK",
    address: "C/O SATSANG BIHAR*PO-CHAKCHAKA*COOCHBIHAR",
    fullName: "MURALI MOHAN BANIK",
    saID: "SPR3230",
  },
  {
    firstName: "ARUP",
    lastName: "GANGULY",
    address:
      "DISTRICT STATISTICAL OFFICER*PO-NAHARLAGUN*DT-PAPUM PARE*A.P-791110*PAN-AQEPG1653R",
    fullName: "ARUP GANGULY",
    saID: "SPR3231",
  },
  {
    firstName: "BISHNU PRASAD",
    lastName: "GUPIT",
    address: "JENGRAI MUKH GAON*PO-JENGRAIMUKH*MAJULI*JORHAT-785105.",
    fullName: "BISHNU PRASAD GUPIT",
    saID: "SPR3232",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "MONDAL",
    address:
      "18/25/A KAZIPARA MAIN ROAD*N BIJOYNAGAR*PO-KAZIPARA*24PGS N*743201*PAN.AERPM3586N",
    fullName: "TAPAN KUMAR MONDAL",
    saID: "SPR3233",
  },
  {
    firstName: "BISWADEB",
    lastName: "RAJOAR",
    address: "VILL&PO-BANDARMARI*SONITPUR-784151.",
    fullName: "BISWADEB RAJOAR",
    saID: "SPR3234",
  },
  {
    firstName: "PINAK KISHOR",
    lastName: "DAS",
    address: "VILL&PO-PANCHGRAM*CACHAR*PAN-BFGPD2662M",
    fullName: "PINAK KISHOR DAS",
    saID: "SPR3235",
  },
  {
    firstName: "BASUDEB",
    lastName: "DUTTA",
    address:
      "HIMAITPUR SATSANG ASHRAM*PO-RAMKANALI*PURULIA-723142*PAN-AWLPD9586F",
    fullName: "BASUDEB DUTTA",
    saID: "SPR3236",
  },
  {
    firstName: "MAHADEB",
    lastName: "MANDAL",
    address:
      "NASARATPUR ADARSHA PALLY*PO-NASARATPUR*BURDWAN-713519*PAN-BQQPM4307P",
    fullName: "MAHADEB MANDAL",
    saID: "SPR3237",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "BISWAS",
    address: "GOPALBAG;TEMATHA*CHANDAN NAGAR*HOOGHLY-712136*PAN ADUPB0085F",
    fullName: "SUBHAS CHANDRA BISWAS",
    saID: "SPR3238",
  },
  {
    firstName: "TRILOCHAN",
    lastName: "GHOSH",
    address: "VILL-RADHAMADHABPUR(PIALA)*PO-DURGAPUR*BURDWAN  713201",
    fullName: "TRILOCHAN GHOSH",
    saID: "SPR3239",
  },
  {
    firstName: "UPENDRA",
    lastName: "SAHOO",
    address: "AT-BAGHADI*PO-BELAGAM*VIA-POLASARA*GANJAM-761105.",
    fullName: "UPENDRA SAHOO",
    saID: "SPR3240",
  },
  {
    firstName: "SURATH KUMAR",
    lastName: "BEHERA",
    address:
      "AT-SATSANG VIHAR*PO-VANIVIHAR*BHUBANESWAR-751004*KHURDA*PAN-AYQPB9601G",
    fullName: "SURATH KUMAR BEHERA",
    saID: "SPR3241",
  },
  {
    firstName: "LATE BRAJA KISHORE",
    lastName: "PATTANAYAK",
    address: "ICHINDA WARD NO-12*AT/PO-RAIRANGPUR*MAYURBHANJ",
    fullName: "LATE BRAJA KISHORE PATTANAYAK",
    saID: "SPR3242",
  },
  {
    firstName: "ARTATRAN",
    lastName: "MISHRA",
    address:
      "AT-KULADERA*PO-MANDUA*VIA-KEONJHARGARH*DIST-KEONJHAR-758001*ODISHA",
    fullName: "ARTATRAN MISHRA",
    saID: "SPR3243",
  },
  {
    firstName: "KALICHARAN",
    lastName: "SWAIN",
    address: "VILL-MONDAR*PO-ANGINATHI*VIA-TANARADA*GANJAM",
    fullName: "KALICHARAN SWAIN",
    saID: "SPR3244",
  },
  {
    firstName: "NAGENDRA KUMAR",
    lastName: "BHUYAN",
    address: "INSTRUCTOR,SARALA MAHAVIDYALAYA*RAHAMA*JAGATSINGHPUR.",
    fullName: "NAGENDRA KUMAR BHUYAN",
    saID: "SPR3245",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "PATNAIK",
    address:
      "C/O-SATSANG VIHAR POLASARA*AT/PO-POLASARA*GANJAM-761105*PAN-ABFPP3843G.",
    fullName: "RABINDRA KUMAR PATNAIK",
    saID: "SPR3246",
  },
  {
    firstName: "BRUNDABAN",
    lastName: "PRADHAN",
    address: "AT/PO-BADAKELAJHURI*VIA-NUAPADA*GANJAM-761011",
    fullName: "BRUNDABAN PRADHAN",
    saID: "SPR3247",
  },
  {
    firstName: "PRATAP KUMAR",
    lastName: "NAYAK",
    address: "AT&PO-SARASADA*VIA-BHANDARI POKHARI*BHADRAK 756120",
    fullName: "PRATAP KUMAR NAYAK",
    saID: "SPR3248",
  },
  {
    firstName: "LATE MAHABIR",
    lastName: "PANDEY",
    address: "VILL/PO-KHUNTI(CHANDIL)*DT-SINGHBHUM",
    fullName: "LATE MAHABIR PANDEY",
    saID: "SPR3249",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "DAS",
    address:
      "H NO-27,ARYABARTA COLONY*JAGANNATH NAGAR RD-12*PO-RASULGARH*BHUBANESWAR-25*KHURDA",
    fullName: "ARUN KUMAR DAS",
    saID: "SPR3250",
  },
  {
    firstName: "LATE BINAYAK",
    lastName: "JYOTISHI",
    address: "AT-BAIDAPOSHI*PO-RAIRANGPUR*MAYURBHANJ",
    fullName: "LATE BINAYAK JYOTISHI",
    saID: "SPR3251",
  },
  {
    firstName: "RAJAKISHOR",
    lastName: "PRADHAN",
    address:
      "QRS NO N/6*RAJABAGICHA LABOUR COLONY*PO-TELENGA BAZAR*CUTTACK-753009",
    fullName: "RAJAKISHOR PRADHAN",
    saID: "SPR3252",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "JENA",
    address: "AT-BARABAZAR*PO-JALESWAR 756032*BALASORE.",
    fullName: "GANESH CHANDRA JENA",
    saID: "SPR3253",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "MOHANTY",
    address: "LAB TECHNICIAN,GOPALPUR PHC*PO-GOPALPUR*BALASORE",
    fullName: "LATE SATYENDRA NATH MOHANTY",
    saID: "SPR3254",
  },
  {
    firstName: "SANDIP KUMAR",
    lastName: "MANDAL",
    address: "VILL/PO-PILKI*VIA-MANIGRAM*MURSHIDABAD 742237.",
    fullName: "SANDIP KUMAR MANDAL",
    saID: "SPR3255",
  },
  {
    firstName: "SADAN CHANDRA",
    lastName: "KAR",
    address:
      "VILL-JUJKHULA*PO-NARAYANHAT*PS-BHUJPUR(FATIKCHARI)*CHITTAGONG*BANGLADESH",
    fullName: "SADAN CHANDRA KAR",
    saID: "SPR3256",
  },
  {
    firstName: "HARADHAN",
    lastName: "GHOSE",
    address: "68,SURJYA SEN STREET*CAL 700009*PAN-AVZPG7291H",
    fullName: "HARADHAN GHOSE",
    saID: "SPR3257",
  },
  {
    firstName: "NABA KUMAR",
    lastName: "MANDAL",
    address: "VILL/PO-KHANTURA-743273*24 PARGANAS (NORTH)",
    fullName: "NABA KUMAR MANDAL",
    saID: "SPR3258",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "KARMAKAR",
    address: "AT&PO-DALKHOLA*UTTAR DINAJPUR-733201",
    fullName: "LATE SUBODH CHANDRA KARMAKAR",
    saID: "SPR3259",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "DAS",
    address: "SUBHASH PALLY*PO-BILASIPARA*DIST-DHUBRI-783348*ASSAM",
    fullName: "SUBAL CHANDRA DAS",
    saID: "SPR3260",
  },
  {
    firstName: "SITAL CHANDRA",
    lastName: "JANA",
    address: "VILL-SIMULIA*PO-CHOWKA*PASCHIM MEDINIPUR  721232.",
    fullName: "SITAL CHANDRA JANA",
    saID: "SPR3261",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "KAKATI",
    address: "VILL-SUONKANI*PO-BARBARI*NALBARI*ASSAM",
    fullName: "HEMANTA KUMAR KAKATI",
    saID: "SPR3262",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "PRADHAN",
    address: "VILL-SARIA*PO-RAIPUR PASCHIMBAR*DIST-PURBA MEDINIPUR-721401",
    fullName: "SASANKA SEKHAR PRADHAN",
    saID: "SPR3263",
  },
  {
    firstName: "MEGHNAD",
    lastName: "ROY",
    address: "VILL/PO-KHOKSA*UTTAR DINAJPUR-733156",
    fullName: "MEGHNAD ROY",
    saID: "SPR3264",
  },
  {
    firstName: "KAMAL KRISHNA",
    lastName: "KHANRA",
    address: "VILL-ISWARIGACHA*PO-BADURIA*24PARGANAS (NORTH)-743401",
    fullName: "KAMAL KRISHNA KHANRA",
    saID: "SPR3265",
  },
  {
    firstName: "ANIL BARAN",
    lastName: "CHOUDHURY",
    address: "VILL-NORTH KARIANI*PO-DEYGANGA*24PARGANAS (NORTH)",
    fullName: "ANIL BARAN CHOUDHURY",
    saID: "SPR3266",
  },
  {
    firstName: "NITAI CHANDRA",
    lastName: "JANA",
    address: "VILL/PO-KEDARPUR*VIA-ROYDIGHI*24 PARGANAS (SOUTH)-743383",
    fullName: "NITAI CHANDRA JANA",
    saID: "SPR3267",
  },
  {
    firstName: "KHAGENDRA NATH",
    lastName: "BERA",
    address: "SWASTI ABAS*PURBA KUMARPORE*PO-CONTAI*PURBA MEDINIPUR-721401",
    fullName: "KHAGENDRA NATH BERA",
    saID: "SPR3268",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "DAS",
    address:
      "J C GIRLS HIGH SCHOOL RD*PO-UDHARBOND*CACHAR-788030*PAN--AJSPD8071B",
    fullName: "NANIGOPAL DAS",
    saID: "SPR3269",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "DAS",
    address: "VILL&PO-MATIA*GOALPARA-783125*ASSAM",
    fullName: "DHIRENDRA DAS",
    saID: "SPR3270",
  },
  {
    firstName: "BANSHI BADAN",
    lastName: "DAS",
    address: "SINGUR SATMANDIR TALA*PO-SINGUR *HOOGHLY 712409",
    fullName: "BANSHI BADAN DAS",
    saID: "SPR3271",
  },
  {
    firstName: "GAJENDRA NATH",
    lastName: "ROYSARKAR",
    address: "VILL-D K KUTHI*PO-PUNDIBARI*COOCHBEHAR.",
    fullName: "GAJENDRA NATH ROYSARKAR",
    saID: "SPR3272",
  },
  {
    firstName: "LATE JAYDEB",
    lastName: "CHAKRABARTY",
    address: "M/S SWASTI HOMEO NIKETAN*PO-TARAKESWAR*HOOGHLY-712410",
    fullName: "LATE JAYDEB CHAKRABARTY",
    saID: "SPR3273",
  },
  {
    firstName: "MOHAN LAL",
    lastName: "AGARWAL",
    address:
      "MOHAN SAREE CENTRE*45,47,APANNA LINE*SAKCHI BAZAR*JAMSHEDPUR-831001*PAN-AAWPA5177A",
    fullName: "MOHAN LAL AGARWAL",
    saID: "SPR3274",
  },
  {
    firstName: "LATE HEM NARAYAN",
    lastName: "YADAV",
    address: "AT-TULSIA*PO-BAIJNATHPUR*VIA-BEHARIGANJ*MADHEPURA.",
    fullName: "LATE HEM NARAYAN YADAV",
    saID: "SPR3275",
  },
  {
    firstName: "RATNAKAR",
    lastName: "SWAIN",
    address:
      "VILL-BADAGADA(UPPER SAHI)*PO-BADAGADA BRIT COLONY*BHUBANESWAR-751018*KHURDA",
    fullName: "RATNAKAR SWAIN",
    saID: "SPR3276",
  },
  {
    firstName: "LATE DHANESWAR",
    lastName: "SAHOO",
    address: "PRASAD RAO PETTA*JOYPUR*KORAPUT",
    fullName: "LATE DHANESWAR SAHOO",
    saID: "SPR3277",
  },
  {
    firstName: "JATADHARI",
    lastName: "NAYAK",
    address: "AT-GODA(MURARIPUR)*PO-GODA*VIA-BORIKINA*DT-JAGATSINGHPUR-754110",
    fullName: "JATADHARI NAYAK",
    saID: "SPR3278",
  },
  {
    firstName: "BAIRAGI",
    lastName: "SAHOO",
    address:
      "AT-SATSANG THAKURBARI*CHAKRATIRTHA ROAD*PURI-752002*PAN-AZYPS2268R",
    fullName: "BAIRAGI SAHOO",
    saID: "SPR3279",
  },
  {
    firstName: "LATE SRI SRI THAKUR",
    lastName: "",
    address: "SATSANG.DEOGHAR",
    fullName: "LATE SRI SRI THAKUR ",
    saID: "SPR3280",
  },
  {
    firstName: "MAGANTA",
    lastName: "SAHOO",
    address: "AT/PO-ITIPUR*VIA-BHUBANESWAR*DIST-KHURDA-751002",
    fullName: "MAGANTA SAHOO",
    saID: "SPR3281",
  },
  {
    firstName: "UJALA",
    lastName: "BEHERA",
    address:
      "SR MANAGER(MECH)*PL.NO-J/1098,KHANDAGIRI BARI*PO-KHANDAGIRI*BHUBANESWAR-30*KHURDA",
    fullName: "UJALA BEHERA",
    saID: "SPR3282",
  },
  {
    firstName: "LATE REV KALYANI",
    lastName: "MAITRA",
    address:
      "C/O REV A K MOITRA*62/102,PRATAP NAGAR*HOUSING COLONY,SANGANER*JAIPUR-303 906.",
    fullName: "LATE REV KALYANI MAITRA",
    saID: "SPR3283",
  },
  {
    firstName: "RADHAKANTA",
    lastName: "MISHRA",
    address: "B-31,SECTOR-20*PO-ROURKELA-769005*SUNDARGARH*PAN ABHPM2031K",
    fullName: "RADHAKANTA MISHRA",
    saID: "SPR3284",
  },
  {
    firstName: "BHASKAR",
    lastName: "SARAN",
    address:
      '"PEARL",FLAT NO-H1/3*VATIKA GREEN CITY,DIMNA RD*PO-MGM,MANGO*J.S.R.*PAN-AFWPS4945K',
    fullName: "BHASKAR SARAN",
    saID: "SPR3285",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "BEHERA",
    address: "AT-SAMBALPUR COURT AREA*PO-SAMBALPUR-768001*SAMBALPUR",
    fullName: "CHITTARANJAN BEHERA",
    saID: "SPR3286",
  },
  {
    firstName: "BRAJABANDHU",
    lastName: "BEHERA",
    address:
      "AT-SHREE JAGANNATH HIGH SCHOOL*CHANDIBAUNSAMUL*PO-BHITARAGARH*KENDRAPARA",
    fullName: "BRAJABANDHU BEHERA",
    saID: "SPR3287",
  },
  {
    firstName: "SUBASH CHANDRA",
    lastName: "SASMAL",
    address: "AT-SANTO*PO-ALIKANTA*JAGATSINGHPUR-754 108.",
    fullName: "SUBASH CHANDRA SASMAL",
    saID: "SPR3288",
  },
  {
    firstName: "LATE BISWESWAR",
    lastName: "SAH",
    address: "PO.BIHARIGANJ*MADHEPURA",
    fullName: "LATE BISWESWAR SAH",
    saID: "SPR3289",
  },
  {
    firstName: "KISHORI SHARAN",
    lastName: "SINGH",
    address:
      "LEKHA NAGAR*NEAR DAV SCHOOL.CANTT. ROAD*KHAGAUL*PATNA-801 105*(PAN-AOSPS7395N)",
    fullName: "KISHORI SHARAN SINGH",
    saID: "SPR3290",
  },
  {
    firstName: "AMIYA BHUSAN",
    lastName: "MANDAL",
    address: "VILL-SHIBSAGAR*PO-HIRAPUR*DT-BETUL*M.P.-460440",
    fullName: "AMIYA BHUSAN MANDAL",
    saID: "SPR3291",
  },
  {
    firstName: "LATE Y G",
    lastName: "PRADHAN",
    address: "ASHOKE ASHRAM*SATSANG*DEOGHAR",
    fullName: "LATE Y G PRADHAN",
    saID: "SPR3292",
  },
  {
    firstName: "PANKAJ KUMAR",
    lastName: "MONDAL",
    address:
      "AMARABATI ROAD 1ST LANE*PO-NABAPALLI*BARASAT*24PARGANAS N 743203*PAN.AHKPM6230F",
    fullName: "PANKAJ KUMAR MONDAL",
    saID: "SPR3293",
  },
  {
    firstName: "BIRINCHI BANDHU",
    lastName: "MONDAL",
    address:
      "SULKUNI SATSANG VIHAR*PO-SULKUNI*PS-HASNABAD*24 PARGANAS NORTH-743426",
    fullName: "BIRINCHI BANDHU MONDAL",
    saID: "SPR3294",
  },
  {
    firstName: "RANJAN",
    lastName: "PAL",
    address: "63,H P DUTTA LANE*CALCUTTA-700033",
    fullName: "RANJAN PAL",
    saID: "SPR3295",
  },
  {
    firstName: "DHURJATI",
    lastName: "CHAKRAVORTY",
    address: "SATSANG VIHAR MARG*BADARPUR*KARIMGANJ 788806*PAN-ACNPC8218P",
    fullName: "DHURJATI CHAKRAVORTY",
    saID: "SPR3296",
  },
  {
    firstName: "NITYANANDA",
    lastName: "RAY",
    address: "MOULANA AZAD ROAD*HRIDAYPUR(BARASAT)*DT-24PARGANAS NORTH-700127",
    fullName: "NITYANANDA RAY",
    saID: "SPR3297",
  },
  {
    firstName: "RAGHUNATH",
    lastName: "ROY",
    address: "VILL-ANOARBERIA*PO-MANIKTALA*DT-24 PARGANAS (NORTH)",
    fullName: "RAGHUNATH ROY",
    saID: "SPR3298",
  },
  {
    firstName: "NANDESWAR",
    lastName: "SAIKIA",
    address: "AT&PO-PANIGAON*DIST-NOWGONG",
    fullName: "NANDESWAR SAIKIA",
    saID: "SPR3299",
  },
  {
    firstName: "SOHAN",
    lastName: "NAIK",
    address: "SONAJULI T.E*PO-BARJULI*SONITPUR",
    fullName: "SOHAN NAIK",
    saID: "SPR3300",
  },
  {
    firstName: "GAJENDRA NARAYAN",
    lastName: "ROY",
    address: "WARD NO-2*PO-GAURIPUR*DHUBRI-783331*ASSAM",
    fullName: "GAJENDRA NARAYAN ROY",
    saID: "SPR3301",
  },
  {
    firstName: "GIRISH CHANDRA",
    lastName: "DAS",
    address: "AT/PO-KAMRUP BARKHOLA*NALBARI*ASSAM",
    fullName: "GIRISH CHANDRA DAS",
    saID: "SPR3302",
  },
  {
    firstName: "RABILOCHAN",
    lastName: "HANSDA",
    address: "VILL-AMLA BAHAL*PO-MALANCHA*PURULIA 723132",
    fullName: "RABILOCHAN HANSDA",
    saID: "SPR3303",
  },
  {
    firstName: "NILA",
    lastName: "SONOWAL",
    address: "SINGPHURA GOAN*PO-MOKRONG(BORHOLLA)*GOLAGHAT-785631*ASSAM",
    fullName: "NILA SONOWAL",
    saID: "SPR3304",
  },
  {
    firstName: "NIMAI",
    lastName: "TANTI",
    address: "MANABAG T.E.*PO-DARRANG PANBARI*SONITPUR 784111",
    fullName: "NIMAI TANTI",
    saID: "SPR3305",
  },
  {
    firstName: "LATE MAKHAN",
    lastName: "SARMA",
    address: "AT/PO-KACHHAGARAL*JORHAT 785 010",
    fullName: "LATE MAKHAN SARMA",
    saID: "SPR3306",
  },
  {
    firstName: "RANJAN KUMAR",
    lastName: "SARKAR",
    address: "SWASTI BIBHA*BELTALA ROAD*PO-BOKAJAN*KARBIANGLONG 782480*ASSAM",
    fullName: "RANJAN KUMAR SARKAR",
    saID: "SPR3307",
  },
  {
    firstName: "SUBHASH",
    lastName: "SAMANTA",
    address: "VILL&PO-GARHKAMALPUR*PS-MAHISHADAL*PURBA MEDINIPUR-721628",
    fullName: "SUBHASH SAMANTA",
    saID: "SPR3308",
  },
  {
    firstName: "NIBEDAN PRASAD",
    lastName: "GHOSE",
    address: "HARIPAL SATSANG SWASTI SIBIR*GOPINAGAR*KHAMARCHANDI*HOOGHLY",
    fullName: "NIBEDAN PRASAD GHOSE",
    saID: "SPR3309",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "CHATTERJEE",
    address:
      "DURGAPUR SATSANG VIHAR*BENACHITY*NABAPALLY*DURGAPUR-713013*BURDWAN PAN NO.AQKPC192D",
    fullName: "AJIT KUMAR CHATTERJEE",
    saID: "SPR3310",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DAS",
    address:
      "C/O ATUL KRISHNA DAS*HASNABAD(NASKAR NAGAR)*PO-HASNABAD*24 PARGANAS (NORTH)-743426",
    fullName: "PARESH CHANDRA DAS",
    saID: "SPR3311",
  },
  {
    firstName: "DINABANDHU",
    lastName: "SARMA",
    address: "L N B ROAD*PO-MANGALDAI*DARRANG-784125*ASSAM",
    fullName: "DINABANDHU SARMA",
    saID: "SPR3312",
  },
  {
    firstName: "DEBEN CHANDRA",
    lastName: "DAS",
    address:
      "PIYOLI PHUKAN NAGAR*H.NO-3,M C CHOUDHURY RD*PO-BAMUNIMAIDAN*GOUHATI-781021*KAMRUP",
    fullName: "DEBEN CHANDRA DAS",
    saID: "SPR3313",
  },
  {
    firstName: "SASADHAR",
    lastName: "PRADHAN",
    address:
      "VILL-SARIYA*PO-RAIPUR PASCHIMBAR*PURBA MEDINIPUR-721401*PAN-AEMPP7799D",
    fullName: "SASADHAR PRADHAN",
    saID: "SPR3314",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "SAHA",
    address: "C/O SATSANG VIHAR TURA*PO-TURA*WEST GAROHILLS*MEGHALAYA 794001.",
    fullName: "PRADIP KUMAR SAHA",
    saID: "SPR3315",
  },
  {
    firstName: "NALENDRA CHANDRA",
    lastName: "RABHA",
    address: "VILL-ROMPARA*PO-RESUBELPARA*NORTH GAROHILLS*MEGHALAYA 794108",
    fullName: "NALENDRA CHANDRA RABHA",
    saID: "SPR3316",
  },
  {
    firstName: "DEBDAS",
    lastName: "CHATTERJEE",
    address:
      "SATSANG KENDRA SONAMUKHI*PO-SONAMUKHI*BANKURA-722207*PAN-APJPC0477K",
    fullName: "DEBDAS CHATTERJEE",
    saID: "SPR3317",
  },
  {
    firstName: "DEBDAS",
    lastName: "DUTTA",
    address: "VILL&PO-RASULPUR*BANKURA",
    fullName: "DEBDAS DUTTA",
    saID: "SPR3318",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "BHOUMICK",
    address: "BIJNI WORD NO-2*PO-BIJNI*DR B N SAHA RD*BONGAIGAON-783390*ASSAM",
    fullName: "ANIL KUMAR BHOUMICK",
    saID: "SPR3319",
  },
  {
    firstName: "BIJOY KRISHNA",
    lastName: "MANDAL",
    address: "36/1 B SURYASEN PALLI*PO-HARIDEBPUR*KOLKATA 82",
    fullName: "BIJOY KRISHNA MANDAL",
    saID: "SPR3320",
  },
  {
    firstName: "JAYDEV",
    lastName: "GAYEN",
    address: "VILL-HARISANKARPUR*PO-MAGRAHAT*24PARGANAS (SOUTH)-743355",
    fullName: "JAYDEV GAYEN",
    saID: "SPR3321",
  },
  {
    firstName: "LALMOHAN",
    lastName: "PAL",
    address:
      "VILL-RAMKRISHNAPALLY*PO-SARISHA*DT-24PARGANAS (SOUTH) 743368*PAN-BIVPP4733P.",
    fullName: "LALMOHAN PAL",
    saID: "SPR3322",
  },
  {
    firstName: "BABUL",
    lastName: "DASGUPTA",
    address:
      "D BAGHA JATIN COLONY*GURUNG BASTI*PO-PRADHAN NAGAR*DATJEELING-3*PAN.ADPPD5338N",
    fullName: "BABUL DASGUPTA",
    saID: "SPR3323",
  },
  {
    firstName: "UMAPADA",
    lastName: "MISHRA",
    address: "KAKDIP SATSANG VIHAR*PO-KAKDIP*24PARGANAS (SOUTH)",
    fullName: "UMAPADA MISHRA",
    saID: "SPR3324",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "DAS",
    address:
      "C/O NIRMAL KUMAR DAS*PO&VILL-BADKULLA*NADIA-741121*PAN BSMPD9453M",
    fullName: "SWAPAN KUMAR DAS",
    saID: "SPR3325",
  },
  {
    firstName: "LATE SUNITI KUMAR",
    lastName: "PAUL",
    address: "*SATSANG 814116",
    fullName: "LATE SUNITI KUMAR PAUL",
    saID: "SPR3326",
  },
  {
    firstName: "MANIRAM",
    lastName: "MAHATO",
    address: "VILL-BARBILL PATHAR*PO-GHATUA*SONITPUR 784 149*PAN-BHEPM7125A",
    fullName: "MANIRAM MAHATO",
    saID: "SPR3327",
  },
  {
    firstName: "DEBKUMAR",
    lastName: "SAHOO",
    address: "",
    fullName: "DEBKUMAR SAHOO",
    saID: "SPR3328",
  },
  {
    firstName: "GANAPATI",
    lastName: "MAHANTA",
    address: "VILL-PICHHULI*PO-BALA*VIA-TATO*MAYURBHANJ-757036",
    fullName: "GANAPATI MAHANTA",
    saID: "SPR3329",
  },
  {
    firstName: "RITISH RANJAN",
    lastName: "CHAKRAVARTY",
    address: "C/O SURNANDAN BHARATI*D 24/2 RABINDRA PALLY*KOLKATA 86",
    fullName: "RITISH RANJAN CHAKRAVARTY",
    saID: "SPR3330",
  },
  {
    firstName: "ANANTA CHARAN",
    lastName: "SAMAL",
    address: "AT-DHANISHO*PO-BAIGONI*VIA-BALIKUDA*DIST-JAGATSINGHPUR-754108",
    fullName: "ANANTA CHARAN SAMAL",
    saID: "SPR3331",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "DAS",
    address: "AT-SATSANG VIHAR*PO/DIST-BHADRAK*ODISHA-756100*PAN-BNNPD0526L",
    fullName: "CHITTARANJAN DAS",
    saID: "SPR3332",
  },
  {
    firstName: "KSHIRODE CHANDRA",
    lastName: "SAHOO",
    address:
      "VILL-SINGHARPUR*PO-NABAPATANA*DIST-JAGATSINGHPUR-754103*PAN-ADBPS8139E",
    fullName: "KSHIRODE CHANDRA SAHOO",
    saID: "SPR3333",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "SAHOO",
    address: "AT-KANTOL BAZAR*PO-ATHGARH*CUTTACK-754029*PAN-AHCPP8543Q",
    fullName: "PRAVAT KUMAR SAHOO",
    saID: "SPR3334",
  },
  {
    firstName: "MANGULI CHARAN",
    lastName: "SAHOO",
    address: "AT-KARADAPAL*PO-UCHABALI*VIA-KUSHALESWAR*JAJPUR.",
    fullName: "MANGULI CHARAN SAHOO",
    saID: "SPR3335",
  },
  {
    firstName: "RAJANI KANTA",
    lastName: "MAHAPATRA",
    address:
      "SUVANGAN*308,SITALSASTHI LANE*WEST BINDUSAGAR,OLD TOWN*BHUBANESWAR-751002.",
    fullName: "RAJANI KANTA MAHAPATRA",
    saID: "SPR3336",
  },
  {
    firstName: "KHIROD KUMAR",
    lastName: "TRIPATHY",
    address: "VILL-ITUA*PO-BIROL*VIA-CHOUDWAR*CUTTACK-754025*PAN-AAMPT8943R",
    fullName: "KHIROD KUMAR TRIPATHY",
    saID: "SPR3337",
  },
  {
    firstName: "DHANESWAR",
    lastName: "SAHOO",
    address: "AT-LAHUNI*PO-PEGARPARA*KENDRAPARA 754243",
    fullName: "DHANESWAR SAHOO",
    saID: "SPR3338",
  },
  {
    firstName: "LATE MAHENDRA KUMAR",
    lastName: "ROUT",
    address: "M.D.S.F.,PATANGI*PO-PATANGI*KORAPUT",
    fullName: "LATE MAHENDRA KUMAR ROUT",
    saID: "SPR3339",
  },
  {
    firstName: "BISHNU MOHAN",
    lastName: "PATRA",
    address:
      "PLOT-29(P),SAHID NAGAR*BEHIND MAHARSHI COLLEGE*BHUBANESWAR-751007*PAN-AGZPP4109J",
    fullName: "BISHNU MOHAN PATRA",
    saID: "SPR3340",
  },
  {
    firstName: "NAKUL CHARAN",
    lastName: "SAHOO",
    address:
      "ADVOCATE*BIJU PATNAIK COLONY*PO-TULSIPUR*CUTTACK 753008*PAN-ALSPS2676R",
    fullName: "NAKUL CHARAN SAHOO",
    saID: "SPR3341",
  },
  {
    firstName: "BHUBAN MOHAN",
    lastName: "SWAIN",
    address: "VILL-TARILO*PO-SOMPUR*DIST-CUTTACK-754130*ODISHA*PAN-AGLPS3013Q",
    fullName: "BHUBAN MOHAN SWAIN",
    saID: "SPR3342",
  },
  {
    firstName: "UDAY KRISHNA",
    lastName: "SAILA",
    address: "VILL-ISWARIGACHA*PO-BADURIA*24 PARGANAS NORTH-743401",
    fullName: "UDAY KRISHNA SAILA",
    saID: "SPR3343",
  },
  {
    firstName: "LATE GIRINDRA NATH",
    lastName: "ROY",
    address: "VILL/PO-KAPILESWARPUR*D/CHATRA*24 PGS (N) 743247.",
    fullName: "LATE GIRINDRA NATH ROY",
    saID: "SPR3344",
  },
  {
    firstName: "AJOY KUMAR",
    lastName: "PATRA",
    address:
      "SATSANG VIHAR BANGALORE*KARNASHREE LAYOUT;MEDAHALLI*BANGALORE 560049*PAN-AFCPP9757L",
    fullName: "AJOY KUMAR PATRA",
    saID: "SPR3345",
  },
  {
    firstName: "BIPLAB KUMAR",
    lastName: "GUPTA",
    address: "100, DAKSHINPARA ROAD*DUM DUM*CALCUTTA-700028",
    fullName: "BIPLAB KUMAR GUPTA",
    saID: "SPR3346",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "HALDER",
    address: "KAPATHAT*PO-DIAMOND HARBOUR*24 PARGANAS (SOUTH)-743331",
    fullName: "HEMANTA KUMAR HALDER",
    saID: "SPR3347",
  },
  {
    firstName: "ARCHANAMAY",
    lastName: "SAHA",
    address:
      "FLAT NO-10/8,NEW CAPITAL COMPLEX*PO-KUNJABAN*AGARTALA*WEST TRIPURA",
    fullName: "ARCHANAMAY SAHA",
    saID: "SPR3348",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "CHAKRABARTY",
    address: "VILL-RANIDANGA*PO-MACHHLANDAPUR*24PARGANAS (NORTH)",
    fullName: "MADHUSUDAN CHAKRABARTY",
    saID: "SPR3349",
  },
  {
    firstName: "KASHINATH",
    lastName: "SAHOO",
    address: "AT-BUDDHIBAGICHA*PO-KHURDA*DT-KHURDA*PIN-752 055",
    fullName: "KASHINATH SAHOO",
    saID: "SPR3350",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "MONDAL",
    address:
      "VILL-PATASHPUR*PO-HODALNARAYANPUR*BANKURA-722207 .*PAN-AMQPM9869F",
    fullName: "SUNIL KUMAR MONDAL",
    saID: "SPR3351",
  },
  {
    firstName: "MURALIDHAR",
    lastName: "DAS",
    address: "AT-KONARK NAGAR*PO-PALLOHAL-KHURDA-2*KHURDA",
    fullName: "MURALIDHAR DAS",
    saID: "SPR3352",
  },
  {
    firstName: "DEBOTOSH",
    lastName: "DAS",
    address:
      "SATSANG THAKUR BARI*P-233 C I T ROAD*KANKURGACHHI*KOLKATA-700054.",
    fullName: "DEBOTOSH DAS",
    saID: "SPR3353",
  },
  {
    firstName: "RATIKANTA",
    lastName: "BEHERA",
    address: "AT-CHARPADA*PO-GUDIKHAL-756030*VIA-AMARDA ROAD*BALASORE",
    fullName: "RATIKANTA BEHERA",
    saID: "SPR3354",
  },
  {
    firstName: "LATE GOURAHARI",
    lastName: "PATNAIK",
    address: "AT-KARANSAHI,BUGUDA*PO-BUGUDA-761118*GANJAM",
    fullName: "LATE GOURAHARI PATNAIK",
    saID: "SPR3355",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "ROY",
    address: "LANE NO-3, SONAI ROAD*PO/DIST-SILCHAR-788005*(ASSAM)",
    fullName: "ANIL KUMAR ROY",
    saID: "SPR3356",
  },
  {
    firstName: "LATE ANIL",
    lastName: "BARMAN",
    address: "AT/PO-MELAGHAR*TRIPURA.",
    fullName: "LATE ANIL BARMAN",
    saID: "SPR3357",
  },
  {
    firstName: "JADAB KUMAR",
    lastName: "BHAYA",
    address: "TELKALPARA*PO-NAMOPARA*PURULIA 723103",
    fullName: "JADAB KUMAR BHAYA",
    saID: "SPR3358",
  },
  {
    firstName: "LATE BIJAY SHANKAR",
    lastName: "GUPTA",
    address: "BADAMTALA KALNA ROAD*PO&DIST-BARDDHAMAN.",
    fullName: "LATE BIJAY SHANKAR GUPTA",
    saID: "SPR3359",
  },
  {
    firstName: "PATITPABAN",
    lastName: "MUKHARJEE",
    address: "AMARALAY*HARIDRADANGA MAIN ROAD*PO-CHANDANNAGAR*HOOGHLY 712136",
    fullName: "PATITPABAN MUKHARJEE",
    saID: "SPR3360",
  },
  {
    firstName: "SUKUMAR",
    lastName: "MONDAL",
    address: "1,RAMKRISHNAPALLY*PO-BARASAT*24PARGANAS NORTH-743201.",
    fullName: "SUKUMAR MONDAL",
    saID: "SPR3361",
  },
  {
    firstName: "ASOKE KUMAR",
    lastName: "GHOSH",
    address: "LECTURER OF J.R.C.*AT/PO-BELIATORE*BANKURA-722203*PAN-ADHPG9420A",
    fullName: "ASOKE KUMAR GHOSH",
    saID: "SPR3362",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "GHOSH",
    address: "VILL&PO-BANKADAHA*BANKURA-722164*PAN- BFOPG8951C",
    fullName: "AJIT KUMAR GHOSH",
    saID: "SPR3363",
  },
  {
    firstName: "MANORANJAN",
    lastName: "HALDAR",
    address: "JANGALPARA*ADARSHA DARJEE BIJNAN COLLEGE*PO-MAGRA*HOOGHLY-712148",
    fullName: "MANORANJAN HALDAR",
    saID: "SPR3364",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "DAS",
    address: "PATAIHAT*PO-DAINHAT*BURDWAN",
    fullName: "SHYAMAL KUMAR DAS",
    saID: "SPR3365",
  },
  {
    firstName: "PARAMANANDA",
    lastName: "MAHANTA",
    address: "AT-SINGARPUR*PO-MATIAGARH*VIA-KHAIRI JASHIPUR*MAYURBHANJ-757091",
    fullName: "PARAMANANDA MAHANTA",
    saID: "SPR3366",
  },
  {
    firstName: "LATE LALA ABHIJIT",
    lastName: "DEB",
    address: "'ANANDA BHAWAN' LABAN*PO-SHILLONG 793004*MEGHALAYA",
    fullName: "LATE LALA ABHIJIT DEB",
    saID: "SPR3367",
  },
  {
    firstName: "KRISHNA PRASAD",
    lastName: "PANDAMAHAPATRA",
    address:
      "QTR NO IV/2,NEW TOWN SHIP*GHORADHARA*PO-JHARGRAM*PASCHIM MEDINIPUR PAN.AFAPP9750M",
    fullName: "KRISHNA PRASAD PANDAMAHAPATRA",
    saID: "SPR3368",
  },
  {
    firstName: "SHIB SANKAR",
    lastName: "NAYAK",
    address: "AT-WALLIGANJ; WARD NO-3*PO-BARIPADA-757002*MAYURBHANJ",
    fullName: "SHIB SANKAR NAYAK",
    saID: "SPR3369",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "BEURA",
    address: "AT&PO-GHASIPUT*VIA-BANKI*CUTTACK 754008",
    fullName: "KRUSHNA CHANDRA BEURA",
    saID: "SPR3370",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "RATH",
    address:
      "DIPTI BHAWAN*AT/PO-RAJRANPUR(AMLAPADA)*NAYAGARH 752026*PAN-AJYPR0235R",
    fullName: "PRAVAKAR RATH",
    saID: "SPR3371",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "GHOSAL",
    address: "AT/PO-DAHUNDA*VIA-KAMARDA*BALASORE-756035.",
    fullName: "TAPAS KUMAR GHOSAL",
    saID: "SPR3372",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "JENA",
    address: "AT-BINDHANAUKONA*PO-BADANAUKONA*VIA-RAJANAGAR*KENDRAPARA 754 225",
    fullName: "RABINDRA KUMAR JENA",
    saID: "SPR3373",
  },
  {
    firstName: "AKSHAY KUMAR",
    lastName: "PAL",
    address: "AT-GAREI*PO-LATHANG*VIA-RAHAMA*JAGATSINGHPUR 754 140.",
    fullName: "AKSHAY KUMAR PAL",
    saID: "SPR3374",
  },
  {
    firstName: "LATE CHAGALI CHANDRA",
    lastName: "KHATUA",
    address: "AT&PO-DANAGADI*VIA-J K ROAD*JAJPUR 755026",
    fullName: "LATE CHAGALI CHANDRA KHATUA",
    saID: "SPR3375",
  },
  {
    firstName: "GANGA NARAYAN",
    lastName: "SWAIN",
    address: "AT/PO-JAMUDIHA*VIA-SAJANAGARH-756041*BALASORE*PAN-BQBPS4263D",
    fullName: "GANGA NARAYAN SWAIN",
    saID: "SPR3376",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "BEHERA",
    address:
      "QR NO D/97,STAGE-2*N T P C,KANIHA*PO-DEEPSHIKHA*DIST-ANGUL 759147*PAN-ABEPB5750B",
    fullName: "SASANKA SEKHAR BEHERA",
    saID: "SPR3377",
  },
  {
    firstName: "NILAKANTHA",
    lastName: "SATPATHY",
    address: "AT&PO-BIRMOHARAJPUR*DIST-SUBARNAPUR-767018*ORISSA",
    fullName: "NILAKANTHA SATPATHY",
    saID: "SPR3378",
  },
  {
    firstName: "LATE SADANANDA",
    lastName: "BANIKYA",
    address: "RAJGARH,ULUBARI*PO-GAUHATI-781007*KAMRUP.",
    fullName: "LATE SADANANDA BANIKYA",
    saID: "SPR3379",
  },
  {
    firstName: "LATE LAKSHMI NARAYAN",
    lastName: "DAS",
    address: "GOVINDAPUR*PO-PATHSALA*BARPETA",
    fullName: "LATE LAKSHMI NARAYAN DAS",
    saID: "SPR3380",
  },
  {
    firstName: "MOHAN CHARAN",
    lastName: "NAIK",
    address: "AT-SATSANG VIHAR BHINGARPUR*PO-SASAN*DIST-KHURDA 752 102",
    fullName: "MOHAN CHARAN NAIK",
    saID: "SPR3381",
  },
  {
    firstName: "LATE JITENDRA CHANDRA",
    lastName: "BARMAN",
    address: "VILL&PO-DANGAIGAON*VIA-BONGAIGAON*GOALPARA",
    fullName: "LATE JITENDRA CHANDRA BARMAN",
    saID: "SPR3382",
  },
  {
    firstName: "LATE GANESH LAL",
    lastName: "DAS",
    address: "DURGASTHAN*KATIHAR",
    fullName: "LATE GANESH LAL DAS",
    saID: "SPR3383",
  },
  {
    firstName: "LATE SUBHAS RANJAN",
    lastName: "DEB",
    address: "AMARDHAM*10/3,A.B.SARKAR ROAD*PO-NAIHATI*24PARGANAS 743165",
    fullName: "LATE SUBHAS RANJAN DEB",
    saID: "SPR3384",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "GHOSH",
    address: "15/1/1 OLA BIBI TALA IST BYE LANE*HOWRAH 711104",
    fullName: "MADAN MOHAN GHOSH",
    saID: "SPR3385",
  },
  {
    firstName: "DEV KUMAR",
    lastName: "PAL",
    address: "VILL-NALDA*PO-POLGUSTIA*VIA-MAJU*HOWRAH-711414",
    fullName: "DEV KUMAR PAL",
    saID: "SPR3386",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "SAHA",
    address: "VILL&PO-SALAR(HINDUPARA)*MURSHIDABAD-742401",
    fullName: "SHYAMAL KUMAR SAHA",
    saID: "SPR3387",
  },
  {
    firstName: "LATE JAGABANDHU",
    lastName: "CHATTERJEE",
    address: "RASHTALA (BANPUKUR LANE)*PO/DIST-BANKURA.",
    fullName: "LATE JAGABANDHU CHATTERJEE",
    saID: "SPR3388",
  },
  {
    firstName: "LAXMI KANTA",
    lastName: "SRIMANI",
    address:
      "VILL-JOYNAGAR TILIPARA*PO-JOYNAGAR MAJILPUR*24PARGANAS SOUTH-743337",
    fullName: "LAXMI KANTA SRIMANI",
    saID: "SPR3389",
  },
  {
    firstName: "LATE ANIL CHANDRA",
    lastName: "DAS",
    address: "VILL-BIRGRAM*PO-NAGENDRANAGAR*KARIMGANJ.",
    fullName: "LATE ANIL CHANDRA DAS",
    saID: "SPR3390",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR3391",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "MAITY",
    address: "JAMALDAH SATSANG VIHAR*PO-JAMALDAH*COOCHBIHAR-735303",
    fullName: "DEBENDRA NATH MAITY",
    saID: "SPR3392",
  },
  {
    firstName: "ARABINDA",
    lastName: "SAHA",
    address:
      "C/O ASIT KUMAR SAHA*VILL-BHAKURI*PO-CHALTIA(BERHAMPUR)*MURSHIDABAD-742 101.",
    fullName: "ARABINDA SAHA",
    saID: "SPR3393",
  },
  {
    firstName: "NARAYAN",
    lastName: "BHOUMICK",
    address:
      "SANKARARA*NR SHIBMANDIR*DAKSHINPARA WARD NO-17*PO-TAMLUK*PURBA MEDINIPUR-721636",
    fullName: "NARAYAN BHOUMICK",
    saID: "SPR3394",
  },
  {
    firstName: "DAMODAR",
    lastName: "SAHOO",
    address:
      "AT-DASPALLA(MAIN ROAD)*PO-DASPALLA*NAYAGARH-752084*PAN-GTSPS0771M",
    fullName: "DAMODAR SAHOO",
    saID: "SPR3395",
  },
  {
    firstName: "JOYRAM",
    lastName: "NATH",
    address: "NORTH LAKHIMPUR POLICE STATION*PO/DIST-LAKHIMPUR-787001*ASSAM",
    fullName: "JOYRAM NATH",
    saID: "SPR3396",
  },
  {
    firstName: "RAJESWAR",
    lastName: "DUTTA",
    address:
      "QTR NO-C-33*O N G C COLONY, SECTOR-39*NOIDA-202301*U P*PAN-ABSPD5230A",
    fullName: "RAJESWAR DUTTA",
    saID: "SPR3397",
  },
  {
    firstName: "BHIM CHARAN",
    lastName: "JANA",
    address: "VILL&PO-MUSTAFAPUR-721443*PURBA MEDINIPUR",
    fullName: "BHIM CHARAN JANA",
    saID: "SPR3398",
  },
  {
    firstName: "BISWANATH",
    lastName: "DAS",
    address: "VILL&PO-NANGULPARA*PS-KHANAKUL*HOOGHLY 712406",
    fullName: "BISWANATH DAS",
    saID: "SPR3399",
  },
  {
    firstName: "BARID BARAN",
    lastName: "SAHOO",
    address: "GOSABA SATSANG MANDIR*PO-GOSABA*24 PARGANAS SOUTH-743370",
    fullName: "BARID BARAN SAHOO",
    saID: "SPR3400",
  },
  {
    firstName: "SRIDAM CHANDRA",
    lastName: "BISWAS",
    address: "VILL-BAGDIA*PO-BARAJIAKUR*VIA-SANTIPUR*NADIA-741404",
    fullName: "SRIDAM CHANDRA BISWAS",
    saID: "SPR3401",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "PAL",
    address: "VILL/PO-ADHATA*DT-24 PARGANAS NORTH-743221",
    fullName: "ASIT BARAN PAL",
    saID: "SPR3402",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "DAS",
    address:
      "VILL-INDRANARAYANPORE*PO-RAMNAGAR ABAD*VIA-KASHINAGAR*24PARGANAS (SOUTH)",
    fullName: "ANIL KUMAR DAS",
    saID: "SPR3403",
  },
  {
    firstName: "GOKUL",
    lastName: "DEY",
    address: "639/2 ASHOK NAGAR*PO-ASHOK NAGAR*DT-24 PARGANAS (NORTH) 743222",
    fullName: "GOKUL DEY",
    saID: "SPR3404",
  },
  {
    firstName: "JAJNESWAR",
    lastName: "ROY",
    address: "SATSANG VIHAR JATESWAR*PO-JATESWAR*JALPAIGURI-735216",
    fullName: "JAJNESWAR ROY",
    saID: "SPR3405",
  },
  {
    firstName: "LATE RADHAGOBINDA",
    lastName: "PAL",
    address: "NIBEDITA  PALLY*BURDWAN 713103",
    fullName: "LATE RADHAGOBINDA PAL",
    saID: "SPR3406",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "SAHA",
    address: "SATSANG VIHAR TURA*PO-TURA*DT-WEST GARO HILLS*MEGHALAYA-794001",
    fullName: "RANJIT KUMAR SAHA",
    saID: "SPR3407",
  },
  {
    firstName: "BISWA NATH",
    lastName: "MANDAL",
    address:
      "S/O LATE GOURHARI MANDAL*VILL&PO-KUSHBERIA*VIA-ULUBERIA*HOWRAH 711315.",
    fullName: "BISWA NATH MANDAL",
    saID: "SPR3408",
  },
  {
    firstName: "LATE BATA KRUSHNA",
    lastName: "MOHARANA",
    address: "VILL-TARAPUR*PO-MALANDA*VIA-KANAKPUR*CUTTACK-754136.",
    fullName: "LATE BATA KRUSHNA MOHARANA",
    saID: "SPR3409",
  },
  {
    firstName: "UTTAM CHARAN",
    lastName: "SAHOO",
    address: "SATSANG VIHAR CUTTACK*BAXI BAZAR*CUTTACK-753001",
    fullName: "UTTAM CHARAN SAHOO",
    saID: "SPR3410",
  },
  {
    firstName: "HRUDAY RANJAN",
    lastName: "TRIPATHY",
    address: "AT/PO-BALIAPAL*BALASORE 756026.",
    fullName: "HRUDAY RANJAN TRIPATHY",
    saID: "SPR3411",
  },
  {
    firstName: "BHAGABAT PRASAD",
    lastName: "PANDA",
    address: "AT/PO-RAJNILGIRI*BALASORE-756040*PAN-CMNPP9133J",
    fullName: "BHAGABAT PRASAD PANDA",
    saID: "SPR3412",
  },
  {
    firstName: "SANATAN",
    lastName: "SATPATHY",
    address: "AT-KHANDIDOLE*PO-MADANPUR*VIA-SAINKUL*KEONJHAR 758043",
    fullName: "SANATAN SATPATHY",
    saID: "SPR3413",
  },
  {
    firstName: "NARENDRA KUMAR",
    lastName: "MAHAPATRA",
    address: '"NANDA NILAY"*RAMACHANDRAPURA*PO-JATNI*KHURDA-752050',
    fullName: "NARENDRA KUMAR MAHAPATRA",
    saID: "SPR3414",
  },
  {
    firstName: "MAHESWAR",
    lastName: "NAYAK",
    address: "AT-MALLIKADEIPUR*PO-NAYAKANIDIHA*BHADRAK 756164",
    fullName: "MAHESWAR NAYAK",
    saID: "SPR3415",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "LENKA",
    address:
      "AT/PO-CHATIKONA*VIA-BISSAM CUTTACK*DIST-RAYAGADA 765 019*PAN-ABDPL7482L",
    fullName: "SURESH CHANDRA LENKA",
    saID: "SPR3416",
  },
  {
    firstName: "SUBASH CHANDRA",
    lastName: "MOHAPATRA",
    address:
      "PLOT NO-392,PRACHI VIHAR*PO-RASULGARH*BHUBANESWAR-751010*PAN-AHTPM5103AA",
    fullName: "SUBASH CHANDRA MOHAPATRA",
    saID: "SPR3417",
  },
  {
    firstName: "LATE GOKULANANDA",
    lastName: "DASH",
    address: "SATABDHI VIHAR*ABHINAB BIDANASI*CUTTACK-14",
    fullName: "LATE GOKULANANDA DASH",
    saID: "SPR3418",
  },
  {
    firstName: "MANORANJAN",
    lastName: "BIHARI",
    address:
      "D NO.58-22-4,SUSARLA COLONY*BAJI JN, PO-GOPALPATNAM*VISAKHAPATNAM-27*A,P*ANOPM5846K",
    fullName: "MANORANJAN BIHARI",
    saID: "SPR3419",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SAMAL",
    address: "AT-DHANISHO*PO-BAIGANI*PS-BALIKUDA*DIST-JAGATSINGHPUR-754108",
    fullName: "SURENDRA NATH SAMAL",
    saID: "SPR3420",
  },
  {
    firstName: "KSHIROD BIHARI",
    lastName: "PRADHAN",
    address: "AT&PO-GANGANAN*DIST-DEOGARH-768108*ODISHA",
    fullName: "KSHIROD BIHARI PRADHAN",
    saID: "SPR3421",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "GHOSH",
    address: "GUJRATI COLONY*PO/DIST-SAMBALPUR 768001*PAN-APCPG8625A",
    fullName: "PABITRA KUMAR GHOSH",
    saID: "SPR3422",
  },
  {
    firstName: "JATADHARI",
    lastName: "BARIK",
    address:
      "NEHRU GALI;PADAMPUR*PO-RAJBORA SAMBAR*BARGARH-768036*PAN-BVQPB2732N",
    fullName: "JATADHARI BARIK",
    saID: "SPR3423",
  },
  {
    firstName: "MANORANJAN",
    lastName: "ROY",
    address: "VILL-NAKUADAHA*PO-ITINDA*24 PARGANAS (NORTH)-743292.",
    fullName: "MANORANJAN ROY",
    saID: "SPR3424",
  },
  {
    firstName: "SHIBAPADA",
    lastName: "SWARNAKAR",
    address:
      "VILL-PARBATIPUR*PO-JHARKHALI BAZAR*24 PARGANAS (SOUTH)-743312*PAN-FENPS0878L.",
    fullName: "SHIBAPADA SWARNAKAR",
    saID: "SPR3425",
  },
  {
    firstName: "TARAKNATH",
    lastName: "MAITI",
    address:
      "C/O-DHARANIDHAR MAITY*VILL-MAMUDPUR*PO-TAKI-MAMUDPUR*24PARGANAS (NORTH)-743435",
    fullName: "TARAKNATH MAITI",
    saID: "SPR3426",
  },
  {
    firstName: "DEBASISH",
    lastName: "SENGUPTA",
    address:
      "C/O-WEAVERS SERVICE CENTRE*I.I.OF HANDLOOM TECH.CAMPUS*CHOWKAGHAT*VARANASI 221002.",
    fullName: "DEBASISH SENGUPTA",
    saID: "SPR3427",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "DAS",
    address: "VILL-SAMBHRIDHIPUR*PO-DARGABAND BAZAR*KARIMGANJ",
    fullName: "LATE HARENDRA CHANDRA DAS",
    saID: "SPR3428",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "DUTTA",
    address: "SATSANG VIHAR GOUHATI*PO-DISPUR*GOUHATI-781005*DIST-KAMRUP*ASSAM",
    fullName: "PRAFULLA CHANDRA DUTTA",
    saID: "SPR3429",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "SAHA",
    address: "VILL&PO-CHHAYGOAN*KAMRUP-781124.",
    fullName: "DULAL CHANDRA SAHA",
    saID: "SPR3430",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DEY",
    address: "VILL-CHOTOJALENGA(BAZARTILA)*PO-CHOTOJALENGA*CACHAR",
    fullName: "LATE NARAYAN CHANDRA DEY",
    saID: "SPR3431",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "PANDA",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCH BEHAR",
    fullName: "SWAPAN KUMAR PANDA",
    saID: "SPR3432",
  },
  {
    firstName: "MRITYUNJAY",
    lastName: "DAS",
    address:
      "B/49,SECTOR-8*PO-HALDIA TOWNSHIP*DIST-PURBA MEDINIPUR 721 607*PAN ABEPD1187C",
    fullName: "MRITYUNJAY DAS",
    saID: "SPR3433",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "DEY",
    address: "NEW MARKET*PO-DOBOKA*DIST-NAGAON  782440",
    fullName: "PRADIP KUMAR DEY",
    saID: "SPR3434",
  },
  {
    firstName: "JAGANNATH",
    lastName: "KALITA",
    address: "C/O JAYAGURU KUTIR*MILANPUR*PO-BAMUNIMAIDAN*GAUHATI-12*KAMRUP",
    fullName: "JAGANNATH KALITA",
    saID: "SPR3435",
  },
  {
    firstName: "SUKARNA",
    lastName: "DEBNATH",
    address: "VILL/PO-HANSKHALI*NADIA-741505.",
    fullName: "SUKARNA DEBNATH",
    saID: "SPR3436",
  },
  {
    firstName: "KALIPADA",
    lastName: "PAUL",
    address: "VILL-DAULATPUR*PO-B.DAULATPUR*MALDA 732125",
    fullName: "KALIPADA PAUL",
    saID: "SPR3437",
  },
  {
    firstName: "HALADHAR",
    lastName: "SARKAR",
    address: "NABAPALLY CHHOTOBAZAR*PO-NABAPALLY*KOLKATA-700126.",
    fullName: "HALADHAR SARKAR",
    saID: "SPR3438",
  },
  {
    firstName: "AKINCHAN",
    lastName: "MRIDHA",
    address:
      "BHABLA KALIBARI*PO-BHABLA*BASIRHAT*24PARGANAS NORTH-743422*PAN-AFVPM5052N.",
    fullName: "AKINCHAN MRIDHA",
    saID: "SPR3439",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "SARKAR",
    address:
      "RAIL BAUNDARY ROAD*PURBACHAL*PO-NABANAGAR*24PARGANAS (NORTH)-743136",
    fullName: "LATE NARAYAN CHANDRA SARKAR",
    saID: "SPR3440",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "KUNDU",
    address: "VILL&PO-KUNKHRAJORE*P.S-GANGAJALGHATI*BANKURA-722133",
    fullName: "JITENDRA NATH KUNDU",
    saID: "SPR3441",
  },
  {
    firstName: "LATE HARA KUMAR",
    lastName: "DAS",
    address: "BALAT BAZAR*PO-BALAT*WEST KHASI HILLS*MEGHALAY",
    fullName: "LATE HARA KUMAR DAS",
    saID: "SPR3442",
  },
  {
    firstName: "LATE SUBODH KUMAR",
    lastName: "DEY",
    address:
      "AT-RAMBANDH PARA,NADIHA*PO-DULMI NADIHA*PURULIA 723102*PAN-AKGPD1166L",
    fullName: "LATE SUBODH KUMAR DEY",
    saID: "SPR3443",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "DEBNATH",
    address: "EAST PRATAPGARH*TRIPURA (WEST).",
    fullName: "NANIGOPAL DEBNATH",
    saID: "SPR3444",
  },
  {
    firstName: "SHYAMAPADA",
    lastName: "MANDAL",
    address: "VILL&PO-GALSI*BURDWAN-713406",
    fullName: "SHYAMAPADA MANDAL",
    saID: "SPR3445",
  },
  {
    firstName: "LATE MOHINI MOHAN",
    lastName: "BISWAS",
    address: "SUBHASH NAGAR*PO-BANDEL*DIST-HOOGHLY",
    fullName: "LATE MOHINI MOHAN BISWAS",
    saID: "SPR3446",
  },
  {
    firstName: "PRAHALLAD",
    lastName: "NATHSHARMA",
    address: "VILL-ALIPUR*PO-RAMCHANDRAPUR*DIST-JAJPUR-755003*PAN-DXNPS8627A",
    fullName: "PRAHALLAD NATHSHARMA",
    saID: "SPR3447",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "SWAIN",
    address: "AT-MANGALA COLONY*GOPALSAGAR*JAGATSINGHPUR-754103*PAN-EMCPS4921J",
    fullName: "GHANASHYAM SWAIN",
    saID: "SPR3448",
  },
  {
    firstName: "ABHINNA KUMAR",
    lastName: "PATTANAYAK",
    address:
      "OFFICE OF THE C.D.V.O.BHADRAK*AT-KUANSH*PO/DIST-BHADRAK-756100*PAN-BBFPP6092L",
    fullName: "ABHINNA KUMAR PATTANAYAK",
    saID: "SPR3449",
  },
  {
    firstName: "LATE CHOUDHURY HRUSIKESH",
    lastName: "DAS",
    address: "C/O CHHAYA ENTERPRISERS*UNIT IV,BHUBANESWAR-751001*PURI",
    fullName: "LATE CHOUDHURY HRUSIKESH DAS",
    saID: "SPR3450",
  },
  {
    firstName: "SACHIDANANDA",
    lastName: "CHAND",
    address:
      "PLOT NO-340,BRAHMESWAR COLONY*RASULGARH*BHUBANESWAR-751010*KHURDA",
    fullName: "SACHIDANANDA CHAND",
    saID: "SPR3451",
  },
  {
    firstName: "MAHENDRA KUMAR",
    lastName: "SAHOO",
    address: "AT/PO-DHAKOTHA*KEONJHAR 758049*PAN-AIWPS1166P",
    fullName: "MAHENDRA KUMAR SAHOO",
    saID: "SPR3452",
  },
  {
    firstName: "APARTI CHARAN",
    lastName: "SAMAL",
    address: "AT&PO-GHASIPUT*VIA-BANKI*CUTTACK 754008",
    fullName: "APARTI CHARAN SAMAL",
    saID: "SPR3453",
  },
  {
    firstName: "SANKARSHAN",
    lastName: "JOSHI",
    address: "AT-BARAMUNDA*PO-KANDHAKEL GAON*VIA-SAINTALA*BOLANGIR-767032",
    fullName: "SANKARSHAN JOSHI",
    saID: "SPR3454",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "PRUSTY",
    address: "AT-BAJAPUR*PO-KRISHNA NAGAR PATNA*VIA-KAKATPUR*PURI-752108",
    fullName: "PRAVAKAR PRUSTY",
    saID: "SPR3455",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "SAHOO",
    address: "AT&PO-RAHANIAGANJ*GOPALPUR*BALASORE-756044",
    fullName: "RABINDRA KUMAR SAHOO",
    saID: "SPR3456",
  },
  {
    firstName: "SHYAMSUNDAR",
    lastName: "SINGH",
    address:
      "AT-MULISINGH*PO-HARISINGHPUR*VIA-KANAKPUR*DIST-JAGATSINGHPUR-754136",
    fullName: "SHYAMSUNDAR SINGH",
    saID: "SPR3457",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "JENA",
    address:
      "EA-184 LAXMISAGAR*BDA COLONY STAGE-II*BHUBANESWAR-751006*PAN-ADXPJ2574N",
    fullName: "SUDHIR KUMAR JENA",
    saID: "SPR3458",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "RATH",
    address:
      "C/O H K PATI*QRS NO.B2/5,LABOUR COLONY*KHARVEL NAGAR,UNIT-3*BHUBANESWAR-3*KHURDA",
    fullName: "PRAFULLA CHANDRA RATH",
    saID: "SPR3459",
  },
  {
    firstName: "JAMUNA PRASAD",
    lastName: "SHAW",
    address: "1/55 GHOSE BAGAN LANE*PO-COSSIPUR*CALCUTTA-700002*PAN-AIRPS4353H",
    fullName: "JAMUNA PRASAD SHAW",
    saID: "SPR3460",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "GHOSH",
    address: "VILL/PO-GAIGHATA*24 PARGANAS(NORTH)-743249.*ALRPG9791N",
    fullName: "RANJIT KUMAR GHOSH",
    saID: "SPR3461",
  },
  {
    firstName: "RICHI NANDAN",
    lastName: "PAL",
    address: "C/O MEMARI SATSANG MANDIR*PO-MEMARI*BURDWAN-713146",
    fullName: "RICHI NANDAN PAL",
    saID: "SPR3462",
  },
  {
    firstName: "LATE RAMBASAWAN",
    lastName: "SINGH",
    address: "*SATSANG  814116",
    fullName: "LATE RAMBASAWAN SINGH",
    saID: "SPR3463",
  },
  {
    firstName: "LATE DALE NDRA",
    lastName: "NARZARY",
    address: "VILL : PANBARI*P.O : MERAPANI VIA FURKATING*JORHAT",
    fullName: "LATE DALE NDRA NARZARY",
    saID: "SPR3464",
  },
  {
    firstName: "LATE DALENDRA",
    lastName: "NARJARY",
    address: "VILL- PANBARI*P.O.-RATANPUR*VIA FURKATING*GOLAGHAT",
    fullName: "LATE DALENDRA NARJARY",
    saID: "SPR3465",
  },
  {
    firstName: "LATE SUDARSAN",
    lastName: "MURMOO",
    address: "SIMLA KELPARA*PO-KUNJORA*SANTHAL PARGANAS",
    fullName: "LATE SUDARSAN MURMOO",
    saID: "SPR3466",
  },
  {
    firstName: "LATE RAMBIR",
    lastName: "SINGH",
    address:
      "EX.HAWALDAR*AYACHIRAM PVT.BUS STAND*PO-M I T MUZAFFARPUR*MUZAFFARPUR",
    fullName: "LATE RAMBIR SINGH",
    saID: "SPR3467",
  },
  {
    firstName: "PHULENDU",
    lastName: "DUTTA",
    address:
      "JR ENGR*BARPETA ROAD ELECTRICAL SUB DIVN*A.S.E.B.BARPETA ROAD*KAMRUP",
    fullName: "PHULENDU DUTTA",
    saID: "SPR3468",
  },
  {
    firstName: "LATE JADU GOPAL",
    lastName: "RUDRAPAUL",
    address: "LAXMI ROANDAR*WORD NO-4*SAPAT GRAM*DHUBRI",
    fullName: "LATE JADU GOPAL RUDRAPAUL",
    saID: "SPR3469",
  },
  {
    firstName: "LATE SONESWAR",
    lastName: "BORO",
    address: "AT-BARIGAON*PO-TAMULPUR*BAKSA B.T.A.D.*ASSAM",
    fullName: "LATE SONESWAR BORO",
    saID: "SPR3470",
  },
  {
    firstName: "BHUDHAR CHANDRA",
    lastName: "KAKOTI",
    address: "PO-KHAIRABARI*VIA-BARPETA ROAD*BARPETA 781315",
    fullName: "BHUDHAR CHANDRA KAKOTI",
    saID: "SPR3471",
  },
  {
    firstName: "RAJENDRA NATH",
    lastName: "BORAH",
    address: "BISHNURABHA UPAPATH*PALI ROAD*NOWGAON 782001",
    fullName: "RAJENDRA NATH BORAH",
    saID: "SPR3472",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "SAHA",
    address: "NEPALI PATTI*PO-TEZPUR*SONITPUR.",
    fullName: "LATE NIRANJAN SAHA",
    saID: "SPR3473",
  },
  {
    firstName: "GADADHAR",
    lastName: "PAL",
    address: "VILL-DEMURIA*PO-LAXMINARAYANPUR*BIRBHUM-731123*PAN-CLIPP2314M.",
    fullName: "GADADHAR PAL",
    saID: "SPR3474",
  },
  {
    firstName: "ASHIT RANJAN",
    lastName: "DHAR",
    address: "BISHNUPALLI*PO-HOJAI*NAGAON-782435*PAN-AUGPD0323H",
    fullName: "ASHIT RANJAN DHAR",
    saID: "SPR3475",
  },
  {
    firstName: "AMAL",
    lastName: "DEY",
    address:
      "OMAXE GREEN VALLEY,B-59*NEAR SARAIKHOWAJA,SECTOR 41-42*FARIDABAD 121003",
    fullName: "AMAL DEY",
    saID: "SPR3476",
  },
  {
    firstName: "DHARA PRASAD",
    lastName: "GAUTAM",
    address: "VILL&PO-SIMALUGURI*VIA-DHOLEPUR*DIST-LAKHIMPUR-784165*ASSAM",
    fullName: "DHARA PRASAD GAUTAM",
    saID: "SPR3477",
  },
  {
    firstName: "BANKIM CHANDRA",
    lastName: "PATRA",
    address: "KALIMELA*STATION ROAD*BISHNUPUR*BANKURA-722122",
    fullName: "BANKIM CHANDRA PATRA",
    saID: "SPR3478",
  },
  {
    firstName: "MANORANJAN",
    lastName: "DAS",
    address: "AT-ITLA*PO-PARBATPUR-713408*BURDWAN*PAN-AXPPD3857N",
    fullName: "MANORANJAN DAS",
    saID: "SPR3479",
  },
  {
    firstName: "BHOLANATH",
    lastName: "KUNDU",
    address: "68 SURYA SEN STREET*KOLKATA 700009",
    fullName: "BHOLANATH KUNDU",
    saID: "SPR3480",
  },
  {
    firstName: "TARAPADA",
    lastName: "CHATTERJEE",
    address: "VILL&PO-CHHATNA*BANKURA 722132.",
    fullName: "TARAPADA CHATTERJEE",
    saID: "SPR3481",
  },
  {
    firstName: "ABHOY SANKAR",
    lastName: "BISWAS",
    address: "VILL-SIMULIAPARA*PO-CHANDPARA BAJAR*24PARGANAS (NORTH)-743245",
    fullName: "ABHOY SANKAR BISWAS",
    saID: "SPR3482",
  },
  {
    firstName: "LATE SUBHASH",
    lastName: "CHANDA",
    address: "PO-SANNICHERA*DHARMANAGAR*TRIPURA (N)-799250",
    fullName: "LATE SUBHASH CHANDA",
    saID: "SPR3483",
  },
  {
    firstName: "NIRMAL KUMAR",
    lastName: "ROY",
    address:
      "C/O-NARESH CHANDRA ROY*SRIGOPAL LANE,JAIL RD*PO-DHARMANAGAR-799250*TRIPURA (N)",
    fullName: "NIRMAL KUMAR ROY",
    saID: "SPR3484",
  },
  {
    firstName: "SUDHANGSHU SEKHAR",
    lastName: "MISHRA",
    address: "PO-KUMARGHAT*TRIPURA (NORTH)-799264.",
    fullName: "SUDHANGSHU SEKHAR MISHRA",
    saID: "SPR3485",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "ACHARJEE",
    address: "SATSANG VIHAR*PO-PADMAPUR*DHARMANAGAR*TRIPURA NORTH 799250.",
    fullName: "LATE KALIPADA ACHARJEE",
    saID: "SPR3486",
  },
  {
    firstName: "NIRANJAN",
    lastName: "SARMA",
    address: "VILL/PO-CHIBITABICHIA*CACHAR  788150",
    fullName: "NIRANJAN SARMA",
    saID: "SPR3487",
  },
  {
    firstName: "LATE RAMDAS",
    lastName: "BAGCHI",
    address: "WORD NO 1*SUBHASH PALLY*PO-BILASIPARA*DHUBRI",
    fullName: "LATE RAMDAS BAGCHI",
    saID: "SPR3488",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "SAHA",
    address: "AT-AMLAPARA*PO-CHANCHAL*DT-MALDA 732123.",
    fullName: "HEMANTA KUMAR SAHA",
    saID: "SPR3489",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "DAS",
    address: "GOURIPUR SATSANG MANDIR*PO-GOURIPUR*DHUBRI-783331*ASSAM",
    fullName: "GOPAL CHANDRA DAS",
    saID: "SPR3490",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "NASKAR",
    address: "VILL/PO-HIRAPUR*DIST-HOWRAH-711310*pan ABMPN6995L",
    fullName: "PRAFULLA KUMAR NASKAR",
    saID: "SPR3491",
  },
  {
    firstName: "LAL MOHAN",
    lastName: "DAS",
    address: "VILL-MOUBESIA*PO-DHULASIMLA*HOWRAH-711315",
    fullName: "LAL MOHAN DAS",
    saID: "SPR3492",
  },
  {
    firstName: "AMARENDRA",
    lastName: "HAJRA",
    address: "VILL-KANDI BLOCK PARA*PO-KANDI*MURSHIDABAD-742137",
    fullName: "AMARENDRA HAJRA",
    saID: "SPR3493",
  },
  {
    firstName: "LATE SURENDRA KUMAR",
    lastName: "ROY",
    address: "VILL-HARIBHANGA*PO-BHULKI*COOCHBIHAR.",
    fullName: "LATE SURENDRA KUMAR ROY",
    saID: "SPR3494",
  },
  {
    firstName: "RUDRA KINKAR",
    lastName: "GHOSE",
    address: "VILL&PO-GRAMSALIKA*VIA-KANDI*MURSHIDABAD-742132",
    fullName: "RUDRA KINKAR GHOSE",
    saID: "SPR3495",
  },
  {
    firstName: "SUDHANGSU",
    lastName: "DEB",
    address: "FRAMING HOUSE*GAR-ALI*JORHAT-785001*PAN ASFPD7000G",
    fullName: "SUDHANGSU DEB",
    saID: "SPR3496",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "BISWAS",
    address: "VILL/PO-TEHATTA*CINEMA HALL PARA*NADIA-741160",
    fullName: "MADAN MOHAN BISWAS",
    saID: "SPR3497",
  },
  {
    firstName: "DURGAPADA",
    lastName: "MONDAL",
    address: "VILL&PO-MAYNAPUR*BANKURA-722138.",
    fullName: "DURGAPADA MONDAL",
    saID: "SPR3498",
  },
  {
    firstName: "JOYCHAND",
    lastName: "SAHA",
    address:
      "VILL-MANGALBARI GHOSHPARA*PO-MANGALBARI*MALDA 792142*PAN NO-AQUPS5295B",
    fullName: "JOYCHAND SAHA",
    saID: "SPR3499",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MONDAL",
    address: "SAKENDARPUR SATSANG*SWASTI SIBIR*PO-AMRITI*MALDA-732208",
    fullName: "PRAFULLA KUMAR MONDAL",
    saID: "SPR3500",
  },
  {
    firstName: "PULIN BIHARI",
    lastName: "MANDAL",
    address:
      "VILL&PO-KALIDASPUR*VIA-CHHOTE MOLLAKHALI*24PARGANNAS S 743378 PAN<AJEPM5777F",
    fullName: "PULIN BIHARI MANDAL",
    saID: "SPR3501",
  },
  {
    firstName: "DOMAN CHANDRA",
    lastName: "ORANG",
    address:
      "VILL-MUDIDIH*PO-MATHFOREST*VIA-RANGADIH*PURULIA-723152*PAN- AAEPO8380M",
    fullName: "DOMAN CHANDRA ORANG",
    saID: "SPR3502",
  },
  {
    firstName: "JOGESH CHANDRA",
    lastName: "HAIT",
    address: "400 AMRITA LAL MUKHERJEE ROAD*CALCUTTA 63",
    fullName: "JOGESH CHANDRA HAIT",
    saID: "SPR3503",
  },
  {
    firstName: "LATE ABHIMANYU",
    lastName: "MANDAL",
    address: "MUNSHIGANJ*KALINAGAR*KHULNA*BANGLADESH",
    fullName: "LATE ABHIMANYU MANDAL",
    saID: "SPR3504",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "KHASKEL",
    address:
      "12 NO LAHABAGAN*PO-PANIHATI*24 PARGANAS (NORTH)-700114*PAN-AFYPK3632H.",
    fullName: "SUNIL KUMAR KHASKEL",
    saID: "SPR3505",
  },
  {
    firstName: "NARAYAN PROSAD",
    lastName: "RATH",
    address: "ORISSA CLOTH STORE*AT/PO-JODA*KEONJHAR 758034",
    fullName: "NARAYAN PROSAD RATH",
    saID: "SPR3506",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "MOHANTA",
    address: "AT-GOPAPUR*PO-KERAJOLI*VIA-KANKADAHAD*DHENKANAL 759028",
    fullName: "ANANTA KUMAR MOHANTA",
    saID: "SPR3507",
  },
  {
    firstName: "BALARAM",
    lastName: "SINGH",
    address: "AT-RESO*PO-SANSARPHAL*VIA-ALVA*KENDRAPARA-754 217",
    fullName: "BALARAM SINGH",
    saID: "SPR3508",
  },
  {
    firstName: "KESHABANANDA",
    lastName: "DAS",
    address: "AT-KORUA*PO-TYENDAKURA*CUTTACK 754 134*PAN-AGPPD8868A",
    fullName: "KESHABANANDA DAS",
    saID: "SPR3509",
  },
  {
    firstName: "LATE GOURANGA KUMAR",
    lastName: "KAR",
    address: "VILL&PO-KUDUMULUGUMA*VIA-BAIPARIGUDA*DIST-MALKANGIRI-764 043.",
    fullName: "LATE GOURANGA KUMAR KAR",
    saID: "SPR3510",
  },
  {
    firstName: "DUSHASAN",
    lastName: "BISWAL",
    address:
      "VILL-PANIMAR BHADRAPARA*PO-BALCO*DIST-KORBA*CHHATISHGARH 495684*PAN-AHAPB5534H",
    fullName: "DUSHASAN BISWAL",
    saID: "SPR3511",
  },
  {
    firstName: "GURU CHARAN",
    lastName: "PARIDA",
    address:
      "SATSANG VIHAR KENDRAPARA,DUHURIA*PO/DIST-KENDRAPARA-754211*PAN-ABMPP5486E",
    fullName: "GURU CHARAN PARIDA",
    saID: "SPR3512",
  },
  {
    firstName: "JANARDAN",
    lastName: "BANDOPADHYAY",
    address:
      '"SADHAN TIRTHA"*PLOT NO-506,NAYAPALLY*BHUBANESWAR-12*PAN-BAVPB6225Q',
    fullName: "JANARDAN BANDOPADHYAY",
    saID: "SPR3513",
  },
  {
    firstName: "RADHAMADHAB",
    lastName: "MISHRA",
    address: "AT&PO-BRAHMANPAL*VIA-DHUSURI*BALASORE",
    fullName: "RADHAMADHAB MISHRA",
    saID: "SPR3514",
  },
  {
    firstName: "LATE GANAPATI",
    lastName: "DUTTA",
    address: "VILL MALGARIA*PO NAWPARA*VIA NADANGHAT*DIST BURDWAN",
    fullName: "LATE GANAPATI DUTTA",
    saID: "SPR3515",
  },
  {
    firstName: "MONORANJAN",
    lastName: "DEBNATH",
    address: "AT-DHARMADANGA*PO-KALNA*BURDWAN-713409",
    fullName: "MONORANJAN DEBNATH",
    saID: "SPR3516",
  },
  {
    firstName: "BISWAJIT",
    lastName: "CHANDA",
    address:
      'C/O-" MISTI MUKH"*AT/PO-KALAIN*CACHAR-788 815*ASSAM*PAN-ABHPC5841M',
    fullName: "BISWAJIT CHANDA",
    saID: "SPR3517",
  },
  {
    firstName: "NIRMALENDU",
    lastName: "DAS",
    address: "123 UMPLING,SHILLONG*MEGHALAYA 793006",
    fullName: "NIRMALENDU DAS",
    saID: "SPR3518",
  },
  {
    firstName: "JASHODA RANJAN",
    lastName: "DEB",
    address: "BARPATHAR BASISTHA*GUWAHATI-781029*DIST-KAMRUP*ASSAM",
    fullName: "JASHODA RANJAN DEB",
    saID: "SPR3519",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "DAS",
    address: "DIGHA SATSANG VIHAR*PO-DIGHA*PURBA MEDINIPUR-721428",
    fullName: "TAPAS KUMAR DAS",
    saID: "SPR3520",
  },
  {
    firstName: "LATE JATINDRA MOHAN",
    lastName: "CHOUDHURY",
    address: "SATSANG VIHAR*BHANGAGHAR*GOUHATI-781 005",
    fullName: "LATE JATINDRA MOHAN CHOUDHURY",
    saID: "SPR3521",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "MOHAPATRA",
    address:
      "AT-TRUPTI SADAN*VILL-NIMIDIHI*PO-PARADEEPGARH*VIA-KUJANG*JAGATSINGHPUR-754141",
    fullName: "PRASANTA KUMAR MOHAPATRA",
    saID: "SPR3522",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "PRASAD",
    address: "VILL-GOCHHARI*PO-GOPALPUR*KHAGARIA (BIHAR)",
    fullName: "LATE NIRANJAN PRASAD",
    saID: "SPR3523",
  },
  {
    firstName: "DAYAL CHANDRA",
    lastName: "KHAMARU",
    address: "VILL&PO-MELKI*HOOGHLY-712149.",
    fullName: "DAYAL CHANDRA KHAMARU",
    saID: "SPR3524",
  },
  {
    firstName: "PRASAD KUMAR",
    lastName: "MRIDHA",
    address: "AT-KUMARPUR BANKIM PALLY*PO-CONTAI*PURBA MEDINIPUR-721401",
    fullName: "PRASAD KUMAR MRIDHA",
    saID: "SPR3525",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "MANDAL",
    address: "VILL-PIYARA*PO-GANDHARBAPUR*24 PARGANAS NORTH-743427",
    fullName: "KARTIK CHANDRA MANDAL",
    saID: "SPR3526",
  },
  {
    firstName: "LATE GOBIND",
    lastName: "PRASAD",
    address: "AT&PO-ALINAGAR RAJPUR*VIA-SABAOUR*BHAGALPUR",
    fullName: "LATE GOBIND PRASAD",
    saID: "SPR3527",
  },
  {
    firstName: "BHAGYADHAR",
    lastName: "JAGADEB",
    address:
      "PLOT NO-1040,GADASAHI*AT/PO-NAYAPALLY*BHUBANESWAR-751012*PAN-AEKPJ2316Q",
    fullName: "BHAGYADHAR JAGADEB",
    saID: "SPR3528",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "CHATTERJEE",
    address: "AT&PO-HATGRAM*BANKURA 722136",
    fullName: "SAMIR KUMAR CHATTERJEE",
    saID: "SPR3529",
  },
  {
    firstName: "GIRIDHARI LAL",
    lastName: "ROY",
    address: "PADMAPUR*PO-DHARMANAGAR*DIST-NORTH TRIPURA-799250*PAN-ABPPR8732C",
    fullName: "GIRIDHARI LAL ROY",
    saID: "SPR3530",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "NAYAK",
    address: "VILL-BANKADANGA*PO-CHAPDA*PURBA MEDINIPUR",
    fullName: "SAROJ KUMAR NAYAK",
    saID: "SPR3531",
  },
  {
    firstName: "ANIL CHANDRA",
    lastName: "PAUL",
    address:
      "JUMBASTI,BADARPUR WARD NO-IV*PO-BADARPUR*KARIMGANJ-788806*PAN-AIXPP1229P.",
    fullName: "ANIL CHANDRA PAUL",
    saID: "SPR3532",
  },
  {
    firstName: "LATE RAJANI KANTA",
    lastName: "SARKAR",
    address: "KRISHNA PALLY TARU KUNJA*PO/DIST-MALDA",
    fullName: "LATE RAJANI KANTA SARKAR",
    saID: "SPR3533",
  },
  {
    firstName: "PARTHESH KUMAR",
    lastName: "CHOUDHURY",
    address: "VILL-NUTANGRAM*PO-TALGACHHI*MURSHIDABAD-742149*PAN-AFDPC8814N",
    fullName: "PARTHESH KUMAR CHOUDHURY",
    saID: "SPR3534",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "LAHA",
    address:
      "SATSANG SRIMANDIR,LAMBALINE*PO-JUNGLIGHAT*PORT BLAIR-744103*ANDAMAN (S)*(ABBPL8793)",
    fullName: "RANJIT KUMAR LAHA",
    saID: "SPR3535",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "SIL",
    address: "VILL&PO-BALIA*VIA-PURBA BISHNUPUR*NADIA.",
    fullName: "LATE CHITTARANJAN SIL",
    saID: "SPR3536",
  },
  {
    firstName: "LATE ANATHBANDHU",
    lastName: "BAIRAGYA",
    address: "VILL/PO-PANCHDARA*NADIA",
    fullName: "LATE ANATHBANDHU BAIRAGYA",
    saID: "SPR3537",
  },
  {
    firstName: "SUNANDAN",
    lastName: "DEB",
    address: "BORDUBI ROAD*PO-HOOGRIJAN*DIST-TINSUKIA-786601*PAN ALLPD9944J",
    fullName: "SUNANDAN DEB",
    saID: "SPR3538",
  },
  {
    firstName: "ANIMESH KUMAR",
    lastName: "CHAKRABARTY",
    address:
      "VILL-GANGAPUR*NILGANJ ROAD*PO-DUTTAPUKUR*24PARAGANAS (NORTH)-743248.",
    fullName: "ANIMESH KUMAR CHAKRABARTY",
    saID: "SPR3539",
  },
  {
    firstName: "LATE DWIPENDRA NARAYAN",
    lastName: "PANDIT",
    address: "VILL/PO-BAKLA*VIA-BAZIRHAT*COOCHBEHAR.",
    fullName: "LATE DWIPENDRA NARAYAN PANDIT",
    saID: "SPR3540",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "DUTTA",
    address: "VILL-JALUKBARI *PO-P.TANGLA*DARRANG-784528*ASSAM",
    fullName: "ARUN KUMAR DUTTA",
    saID: "SPR3541",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "BISWAS",
    address: "SASTRI NAGAR*PO-CHHOTODUDH PATIL GRANT*CACHAR 788002",
    fullName: "SUNIL KUMAR BISWAS",
    saID: "SPR3542",
  },
  {
    firstName: "SUKDEV",
    lastName: "DAS",
    address:
      "VILL/PO-DWARIKNAGAR*NAMKHANA*24 PARGANAS (SOUTH)-743357*PAN.ALZPD7339P",
    fullName: "SUKDEV DAS",
    saID: "SPR3543",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "HAWLADAR",
    address: "VILL-GOURNAGAR*PO-SRIMAYAPUR*NADIA-741313",
    fullName: "GOBINDA CHANDRA HAWLADAR",
    saID: "SPR3544",
  },
  {
    firstName: "JOTEEN",
    lastName: "SONOWAL",
    address: "KHEREMA GAON*PO-BORHOLA*JORHAT 785631",
    fullName: "JOTEEN SONOWAL",
    saID: "SPR3545",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "SAHAROY",
    address: "PO-FAKIRAGRAM*KOKRAJHAR-783345*ASSAM*PAN-BWGPS1986L",
    fullName: "PRASANTA KUMAR SAHAROY",
    saID: "SPR3546",
  },
  {
    firstName: "NAYAN MANGAL",
    lastName: "CHAUDHURY",
    address: "VILL&PO-BAZARGHAT*KARIMGANJ-788733*ASSAM",
    fullName: "NAYAN MANGAL CHAUDHURY",
    saID: "SPR3547",
  },
  {
    firstName: "LATE ASUTOSH",
    lastName: "CHAUDHURY",
    address: "P.W.D COLONY*PO-HAFLONG*N C HILLS",
    fullName: "LATE ASUTOSH CHAUDHURY",
    saID: "SPR3548",
  },
  {
    firstName: "LATE PIJUSH KIRAN",
    lastName: "DAS",
    address: "STEAMERGHAT ROAD*PO/DIST-KARIMGANJ",
    fullName: "LATE PIJUSH KIRAN DAS",
    saID: "SPR3549",
  },
  {
    firstName: "SUKHENDU BIKASH",
    lastName: "DHAR",
    address:
      "SATSANG ADHIBESHAN KENDRA*PO-BELONIA*UDAIPUR*TRIPURA (SOUTH)-799155.",
    fullName: "SUKHENDU BIKASH DHAR",
    saID: "SPR3550",
  },
  {
    firstName: "DIPANKAR",
    lastName: "DASSARKAR",
    address: "VILL&PO-ALAL*MALDA-732128*PAN AEZPD3524K",
    fullName: "DIPANKAR DASSARKAR",
    saID: "SPR3551",
  },
  {
    firstName: "PHOLENDRA",
    lastName: "NARJARY",
    address: "AT-LOKNATHPUR*PO-TURTURI*JALPAIGURI-736206",
    fullName: "PHOLENDRA NARJARY",
    saID: "SPR3552",
  },
  {
    firstName: "BINOD",
    lastName: "SAO",
    address: "HN-81;ROAD NO-3*BHALUBASA*PO-AGRICO*JAMSHEDPUR-831009",
    fullName: "BINOD SAO",
    saID: "SPR3553",
  },
  {
    firstName: "NAWAL KISHORE PD",
    lastName: "SINGH",
    address: "AT&PO-ORRO*NAWADAH-805133",
    fullName: "NAWAL KISHORE PD SINGH",
    saID: "SPR3554",
  },
  {
    firstName: "BISHNU BAHADUR",
    lastName: "KC",
    address: "C/O-BIMAL STORE*JOGBANI BAZAR*PO-JOGBANI*PURNEA-854328",
    fullName: "BISHNU BAHADUR KC",
    saID: "SPR3555",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "BANIK",
    address: "48,RAMNAGAR COLONY*PO-KANKINARA*DT-24 PARGANAS (NORTH)-743126.",
    fullName: "NARAYAN CHANDRA BANIK",
    saID: "SPR3556",
  },
  {
    firstName: "LATE RABINDRA",
    lastName: "CHAKRABORTY",
    address: "C/O SATSANG VIHAR NALIAPOOL*PO-DIBRUGARH*DIBRUGARH.",
    fullName: "LATE RABINDRA CHAKRABORTY",
    saID: "SPR3557",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "SARKAR",
    address: "VILL-CHOPRA(RANGAGACHH)*PO-CHOPRA*UTTAR DINAJPUR-733207",
    fullName: "ATUL CHANDRA SARKAR",
    saID: "SPR3558",
  },
  {
    firstName: "DINESH",
    lastName: "ROY",
    address: "VILL-PANIBHORA*PO-PANIBHORA-788123 *DIST-CACHAR*ASSAM",
    fullName: "DINESH ROY",
    saID: "SPR3559",
  },
  {
    firstName: "LATE PRANAB CHANDRA",
    lastName: "BORAH",
    address: "AMBIKAGIRI NAGAR*R.G.B.ROAD*GUWAHATI 781024",
    fullName: "LATE PRANAB CHANDRA BORAH",
    saID: "SPR3560",
  },
  {
    firstName: "SIB NARAYAN",
    lastName: "PAN",
    address:
      "GANGASAGAR EAST*WARD NO-10,DHARMARAJTALA*PO-BOLPUR*BIRBHUM-731204.",
    fullName: "SIB NARAYAN PAN",
    saID: "SPR3561",
  },
  {
    firstName: "PRADIP RANJAN",
    lastName: "SAHOO",
    address: "751,OLD STATION BAZAR*BHUBANESWAR-751006*KHURDA",
    fullName: "PRADIP RANJAN SAHOO",
    saID: "SPR3562",
  },
  {
    firstName: "BAISHNAB CHARAN",
    lastName: "SAHOO",
    address: "ANYASIPUR*PO-KAIPARA*JAJPUR  755010*PAN-AOJPS1682M",
    fullName: "BAISHNAB CHARAN SAHOO",
    saID: "SPR3563",
  },
  {
    firstName: "JAYDEV",
    lastName: "GIRIRAJ",
    address: "AT&PO-KHARINASI*KENDRAPARA 754224",
    fullName: "JAYDEV GIRIRAJ",
    saID: "SPR3564",
  },
  {
    firstName: "GIRISH CHANDRA",
    lastName: "BARMA",
    address:
      "CLUB LANE*GANGADHAR NAGAR*AT/PO/DIST-BARGARH-768028*PAN-AFWPB5559C",
    fullName: "GIRISH CHANDRA BARMA",
    saID: "SPR3565",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "PARIDA",
    address: "VILL/PO-KALAI*VIA-ARNAPAL*BHADRAK*ODISSA-756116",
    fullName: "LATE NIRMAL CHANDRA PARIDA",
    saID: "SPR3566",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "MOHANTY",
    address: "DELTA COLONY*AT&PO-BALIGUDA*KANDHAMAL 762103*DBNPM6875G",
    fullName: "SURESH CHANDRA MOHANTY",
    saID: "SPR3567",
  },
  {
    firstName: "SUNIL CHANDRA",
    lastName: "ROYPRAMANIK",
    address: "VILL-SITALKHUCHI*PO-GOSAIRHAT BANDAR*COOCHBIHAR 736172",
    fullName: "SUNIL CHANDRA ROYPRAMANIK",
    saID: "SPR3568",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "DEY",
    address: "VILL-DALONG*PO-SAHASPUR*PASCHIM MEDINIPUR-721122",
    fullName: "AJIT KUMAR DEY",
    saID: "SPR3569",
  },
  {
    firstName: "BHABANI SHANKAR",
    lastName: "MAITI",
    address: "AT&PO-BOLANI*(QR-W/215)*KEONJHAR-758037.",
    fullName: "BHABANI SHANKAR MAITI",
    saID: "SPR3570",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "MAHATO",
    address: "VILL-HUNDRU PATHARDIH*PO-ADARDIH*DT-SINGHBHUM-832401",
    fullName: "PURNA CHANDRA MAHATO",
    saID: "SPR3571",
  },
  {
    firstName: "RAM PRASAD",
    lastName: "SAHA",
    address:
      "JAIGURU JALPAN GRIHA*BUS STAND DANTEWADA*PO-DANTEWADA*DT-BASTAR*M.P.",
    fullName: "RAM PRASAD SAHA",
    saID: "SPR3572",
  },
  {
    firstName: "KALYAN",
    lastName: "CHOUDHURY",
    address: "H-71 SOROJINI NAGAR*NEW DELHI-110023",
    fullName: "KALYAN CHOUDHURY",
    saID: "SPR3573",
  },
  {
    firstName: "KAMAL KUMAR",
    lastName: "DUTTA",
    address:
      "ANANDA DHAM*8 RAJENDRA NAGAR*BEHIND GAS GODOWN*DT-SATNA(M P) 485001.",
    fullName: "KAMAL KUMAR DUTTA",
    saID: "SPR3574",
  },
  {
    firstName: "PRANGOBINDA",
    lastName: "SAHA",
    address: "S P RESIDENCY*103/91,RISHI BANKIM ROAD*BIRATI*KOLKATA-51",
    fullName: "PRANGOBINDA SAHA",
    saID: "SPR3575",
  },
  {
    firstName: "KSHIROD KUMAR",
    lastName: "SWAIN",
    address:
      "PLOT NO-4452,BADAGADA*JHARANASAHI*BHUBANESWAR-751018*KHURDA*(PAN-AOWPS1731G)",
    fullName: "KSHIROD KUMAR SWAIN",
    saID: "SPR3576",
  },
  {
    firstName: "BANAMBAR",
    lastName: "PRUSTY",
    address: "AT-MATTAGAJPUR*PO-KADAMCHHANDA*CUTTACK-754135.",
    fullName: "BANAMBAR PRUSTY",
    saID: "SPR3577",
  },
  {
    firstName: "KRUPANIDHI",
    lastName: "BISWAL",
    address: "ODISHA BHAWAN*VASHI*NAVI MUMBAI-400703",
    fullName: "KRUPANIDHI BISWAL",
    saID: "SPR3578",
  },
  {
    firstName: "GOURANGA CHARAN",
    lastName: "SAHOO",
    address: "QR NO.C/128*BARMUNDA HOUSING BOARD COLONY*BHUBANESWAR*KHURDA",
    fullName: "GOURANGA CHARAN SAHOO",
    saID: "SPR3579",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "RAJ",
    address:
      "FLAT NO-503 DAKSHINI HARMONI*SRI SAI NAGAR*KANCHAN BAGH*HYDERABAD 58*PAN SEPPR9927",
    fullName: "DILIP KUMAR RAJ",
    saID: "SPR3580",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "DAS",
    address: "AT-NATAPADA*PO-JAJPUR RD*DIST-JAJPUR-755019",
    fullName: "SURENDRA NATH DAS",
    saID: "SPR3581",
  },
  {
    firstName: "BENUDHAR",
    lastName: "MUDULI",
    address: "AT-JAGANNATHPUR*PO/DIST-KEONJHAR-758001*PAN-AAVPM9544N",
    fullName: "BENUDHAR MUDULI",
    saID: "SPR3582",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "DAS",
    address: "VILL&PO-GHUNI*24PARGANAS (NORTH)-700059",
    fullName: "NIMAI CHANDRA DAS",
    saID: "SPR3583",
  },
  {
    firstName: "HARAN CHANDRA",
    lastName: "MANDAL",
    address: "VILL-PURBAPANCHGACHIA*PO-GOCHARAN*24PARGANAS (SOUTH)-743391",
    fullName: "HARAN CHANDRA MANDAL",
    saID: "SPR3584",
  },
  {
    firstName: "PARIMAL",
    lastName: "BARUAH",
    address: "TROILOKYA NATH GOGOI PATH*LACHIT NAGAR*PO-MORAN*DIBRUGARH 785670",
    fullName: "PARIMAL BARUAH",
    saID: "SPR3585",
  },
  {
    firstName: "LATE BIJOY",
    lastName: "BARTHAKUR",
    address: "AT-LATTAKOOJAN T E*PO-LETEKUJAN*DIST-GOLAGHAT 785613",
    fullName: "LATE BIJOY BARTHAKUR",
    saID: "SPR3586",
  },
  {
    firstName: "SANATAN",
    lastName: "MANDAL",
    address:
      "VILL-PURBA PANCHGACHIA*PO-GOCHARAN*PS-BARUIPUR*24PARGANAS (SOUTH)-743391.",
    fullName: "SANATAN MANDAL",
    saID: "SPR3587",
  },
  {
    firstName: "JAHAR LAL",
    lastName: "SAHA",
    address:
      "B 101 MOHANSRISHTI-1*PATRIPUL*PO-KALYAN (E)*MUMBAI*PAN-BGYPS1831A",
    fullName: "JAHAR LAL SAHA",
    saID: "SPR3588",
  },
  {
    firstName: "NITYAGOPAL",
    lastName: "ROY",
    address: "VILL-JAMALPAR*PO-PALONGHAT*CACHAR-788116*ASSAM",
    fullName: "NITYAGOPAL ROY",
    saID: "SPR3589",
  },
  {
    firstName: "SUBRATA",
    lastName: "MUKHARJEE",
    address:
      "SATSANG SWASTISIBIR*SECONDARPUR*PO-AMRITI*MALDA-732208*PAN-BLXPM2970E",
    fullName: "SUBRATA MUKHARJEE",
    saID: "SPR3590",
  },
  {
    firstName: "ASHOKE",
    lastName: "BASUMATARI",
    address:
      "VILL-GARH(KATHALBARI)*PO-BAGHMARA BAZAR*VIA-PATHSALA*BARPETA-781328*ASSAM",
    fullName: "ASHOKE BASUMATARI",
    saID: "SPR3591",
  },
  {
    firstName: "AMITRAJIT",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AMITRAJIT CHAKRABORTY",
    saID: "SPR3592",
  },
  {
    firstName: "AMRITA CHANDRA",
    lastName: "PAUL",
    address: "480/3, JOYPUR ROAD*DUMDUM*CALCUTTA-700074.",
    fullName: "AMRITA CHANDRA PAUL",
    saID: "SPR3593",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "GHOSH",
    address: "VILL-CHOUGACHA*PO-SONDANGA*VIA-BELPUKUR*NADIA-741126",
    fullName: "RABINDRA NATH GHOSH",
    saID: "SPR3594",
  },
  {
    firstName: "INDU BHUSAN",
    lastName: "DEY",
    address: "KARBALA EAST*PO&DT-HOOGHLY",
    fullName: "INDU BHUSAN DEY",
    saID: "SPR3595",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "CHAKRABARTY",
    address: "O/O THE MEMBER FOREIGN TRIBUNAL*PO/DIST-KARIMGANJ 788710*ASSAM",
    fullName: "AJIT KUMAR CHAKRABARTY",
    saID: "SPR3596",
  },
  {
    firstName: "SARADINDU",
    lastName: "DAS",
    address:
      "SATSANG TAPAVON NAGAR*PO/DIST-SILCHAR-788007*PAN-BSIPD7869R*ASSAM",
    fullName: "SARADINDU DAS",
    saID: "SPR3597",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DAS",
    address:
      "SAHITYA SAVA BYE LANE PATKURA*PO&DIST-COOCHBIHAR-736101.*PAN AHJPD9811G",
    fullName: "PARESH CHANDRA DAS",
    saID: "SPR3598",
  },
  {
    firstName: "LATE SHYAMA PROSAD",
    lastName: "DUTTA",
    address: "VILL&PO-BARA ANDULIA*NADIA-741 124",
    fullName: "LATE SHYAMA PROSAD DUTTA",
    saID: "SPR3599",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "SEAL",
    address: "AT&PO-MOHARCHERRA*VIA-KALYANPUR*TRIPURA WEST",
    fullName: "SUBHASH CHANDRA SEAL",
    saID: "SPR3600",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "BARUA",
    address: "VILL&PO-SEPON*DIST-SIBSAGAR*ASSAM-785673",
    fullName: "ATUL CHANDRA BARUA",
    saID: "SPR3601",
  },
  {
    firstName: "DHIRENDRA CHANDRA",
    lastName: "DUTTA",
    address:
      "EXET.ENGINEER(PWD)*FIRE SERVICE CHOWMOHANI*AGARTALA*PO-RAMNAGAR*TRIPURA   (W)799002",
    fullName: "DHIRENDRA CHANDRA DUTTA",
    saID: "SPR3602",
  },
  {
    firstName: "SANTI RANJAN",
    lastName: "SARKAR",
    address: "SATSANG VIHAR JAMALDAHA*PO-JAMALDAHA*COOCH BEHAR-735303",
    fullName: "SANTI RANJAN SARKAR",
    saID: "SPR3603",
  },
  {
    firstName: "GOPEN",
    lastName: "SWARGIARY",
    address:
      "MANASPUR, WARD NO-10*PO-BARPETA ROAD*BARPETA-781315*ASSAM*PAN-AZAPS1775P",
    fullName: "GOPEN SWARGIARY",
    saID: "SPR3604",
  },
  {
    firstName: "LATE BHUT NATH",
    lastName: "ROY",
    address: "AT-KULIYARA*PO-RAJBALHAT*HOOGHLY 712408.",
    fullName: "LATE BHUT NATH ROY",
    saID: "SPR3605",
  },
  {
    firstName: "LATE PHANINDRA CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "MANMOHINIDHAM*SATSANG*DEOGHAR   .",
    fullName: "LATE PHANINDRA CHANDRA BHATTACHARJEE",
    saID: "SPR3606",
  },
  {
    firstName: "SUSANTA KUMAR",
    lastName: "MRIDHA",
    address: "PADMAPUKUR RD*PO-FINGAPARA*24 PARGANAS (NORTH)-743129",
    fullName: "SUSANTA KUMAR MRIDHA",
    saID: "SPR3607",
  },
  {
    firstName: "RAMESH",
    lastName: "BASUMATARI",
    address: "VILL-PADUM PUKHURI*PO-FULANI BAZAR*KARBI ANGLONG-782441*ASSAM",
    fullName: "RAMESH BASUMATARI",
    saID: "SPR3608",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "",
    address: "",
    fullName: "ARKADYUTI ",
    saID: "SPR3609",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "BHOWMICK",
    address:
      "C/O-ASSISTANT ENGINEER*MAHADEVPUR SUB-DIVN*DIST-LOHIT, A.P.-792 105",
    fullName: "ASHOK KUMAR BHOWMICK",
    saID: "SPR3610",
  },
  {
    firstName: "MANBAHADUR",
    lastName: "ADHIKARI",
    address: "VILL-AMGURI*PO-PHILLOBARI*TINSUKIA-786160*ASSAM",
    fullName: "MANBAHADUR ADHIKARI",
    saID: "SPR3611",
  },
  {
    firstName: "GOLOKE",
    lastName: "DAS",
    address: "VILL&PO-KONA*HOWRAH-711114",
    fullName: "GOLOKE DAS",
    saID: "SPR3612",
  },
  {
    firstName: "KSHEMENDRA KUMAR",
    lastName: "MAJUMDAR",
    address:
      "C/O-D.K.MAJUMDER*MIRZA BAZAR*PO-MEDINIPUR*PASCHIM MEDINIPUR-721101*PAN AJUPM5421D",
    fullName: "KSHEMENDRA KUMAR MAJUMDAR",
    saID: "SPR3613",
  },
  {
    firstName: "INDRABRATA",
    lastName: "ROYCHOUDHURI",
    address: "VILL-NOAPARA*PO-N-MAHADWIPA*BIRBHUM",
    fullName: "INDRABRATA ROYCHOUDHURI",
    saID: "SPR3614",
  },
  {
    firstName: "RATHINDRA",
    lastName: "DAS",
    address: "403/C MAYA APARTMENT*NEW GUWAHATI*PO-NOONMATI*GUWAHATI 20",
    fullName: "RATHINDRA DAS",
    saID: "SPR3615",
  },
  {
    firstName: "DIPAK",
    lastName: "CHAKRAVORTY",
    address: "OLD KALIBARI LANE*KRISHNAGAR*AGARTALA-799001*TRIPURA WEST",
    fullName: "DIPAK CHAKRAVORTY",
    saID: "SPR3616",
  },
  {
    firstName: "LATE NIROD RANJAN",
    lastName: "PAL",
    address: "AJANTA STORES*NARSINGTOLA*SILCHAR-1*CACHAR",
    fullName: "LATE NIROD RANJAN PAL",
    saID: "SPR3617",
  },
  {
    firstName: "TRILOKESH",
    lastName: "BERA",
    address: "SATSANG VIHAR BADLAPUR*HENDRAPADA*PO-KULGAON*THANE-421503*M.S.",
    fullName: "TRILOKESH BERA",
    saID: "SPR3618",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "PANDIT",
    address: "SHYAMA PRASAD ROY ROAD*AT/PO-MURARAI*DIST-BIRBHUM 731219",
    fullName: "GHANASHYAM PANDIT",
    saID: "SPR3619",
  },
  {
    firstName: "SUKUMAR",
    lastName: "NANDY",
    address: "VILL&PO-DAKSHIN KHANDA*DIST-BURDWAN-713321",
    fullName: "SUKUMAR NANDY",
    saID: "SPR3620",
  },
  {
    firstName: "FALGUNI",
    lastName: "LAHA",
    address: "VILL-ANGARGARIYA*PO-KAPILPUR*DIST-BIRBHUM*PIN-731132",
    fullName: "FALGUNI LAHA",
    saID: "SPR3621",
  },
  {
    firstName: "ARABINDA",
    lastName: "SARKAR",
    address: "C/O AMULLYA CH SARKER*PURBASHA*5,ICHHLABAD*BURDWAN 713103",
    fullName: "ARABINDA SARKAR",
    saID: "SPR3622",
  },
  {
    firstName: "THULA KANTA",
    lastName: "NEOG",
    address: "TEPOR GAON(HATIMARA)*PO-MANKATA*DIST-DIBRUGARH-786001*ASSAM",
    fullName: "THULA KANTA NEOG",
    saID: "SPR3623",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "SARKAR",
    address:
      "C/O-DULAL CHANDRA SARKAR*VILL-BAGNA*PO-GAIGHATA 743249*24PARGANAS N*PAN-AVCPS7990Q",
    fullName: "RANJIT KUMAR SARKAR",
    saID: "SPR3624",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "JENA",
    address: "DIRECTORATE OF FISHERIES,ORISSA*CUTTACK 753007*PAN-AZPPJ5730N",
    fullName: "KRUSHNA CHANDRA JENA",
    saID: "SPR3625",
  },
  {
    firstName: "SUSHIL CHANDRA",
    lastName: "SEE",
    address: "AT-PANASI*PO-KHICHING*SUKRULI*MAYURBHANJ 757039",
    fullName: "SUSHIL CHANDRA SEE",
    saID: "SPR3626",
  },
  {
    firstName: "KSHIROD KUMAR",
    lastName: "DAS",
    address: "MANGALA W.C.S.LTD.*AT/PO-KORUA*VIA-TYANDAKUDA*CUTTACK-754134",
    fullName: "KSHIROD KUMAR DAS",
    saID: "SPR3627",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "PARIDA",
    address: "AT/PO-NAMPO*BALASORE-756034*PAN-CYVPP3332B",
    fullName: "SUDHIR KUMAR PARIDA",
    saID: "SPR3628",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "BANERJEE",
    address: "A/84. SECTOR-16*ROURKELA*SUNDARGARH",
    fullName: "NEPAL CHANDRA BANERJEE",
    saID: "SPR3629",
  },
  {
    firstName: "AMIYA RATAN",
    lastName: "JANA",
    address: "VILL&PO-SORRANG*PS-DANTON*PASCHIM MEDINIPUR 721426",
    fullName: "AMIYA RATAN JANA",
    saID: "SPR3630",
  },
  {
    firstName: "SASHADHAR",
    lastName: "PANI",
    address: "AT/PO-UPARADIHA*BALASORE-756058.",
    fullName: "SASHADHAR PANI",
    saID: "SPR3631",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "BASAK",
    address: "AT-BABUPARA*PO-TUFANGANJ*DIST-COOCH BEHAR 736159*PAN-AEOPB6642M",
    fullName: "RANJIT KUMAR BASAK",
    saID: "SPR3632",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "GHOSE",
    address:
      "VILL-JOYKRISHNAPUR*PO-NANDAKUMARPUR*24PARGANAS (SOUTH)-743349*PAN BTDPG5424R",
    fullName: "BASANTA KUMAR GHOSE",
    saID: "SPR3633",
  },
  {
    firstName: "THAKURDAS",
    lastName: "GHOSE",
    address: "VILL-JOYKRISHNAPUR*PO-NANDAKUMARPUR*24PARGANAS (SOUTH)-743349",
    fullName: "THAKURDAS GHOSE",
    saID: "SPR3634",
  },
  {
    firstName: "MILAN CHANDRA",
    lastName: "RABHA",
    address: "VILL&PO-JIRA-783126*VIA-KRISHNAI*GOALPARA",
    fullName: "MILAN CHANDRA RABHA",
    saID: "SPR3635",
  },
  {
    firstName: "SUBODH CHANDRA",
    lastName: "BARMAN",
    address: "PO/V-GOSAIRHAT BANDOR*COOCHBIHAR 736172",
    fullName: "SUBODH CHANDRA BARMAN",
    saID: "SPR3636",
  },
  {
    firstName: "LATE KUSUMANANDA",
    lastName: "SAHA",
    address:
      "DHALAI MALAI PART-VI*VIA-KALLACHERRA*PO-DHALAI SOUTH*DIST-HAILAKANDI 788161",
    fullName: "LATE KUSUMANANDA SAHA",
    saID: "SPR3637",
  },
  {
    firstName: "SAMIR",
    lastName: "DAS",
    address:
      "C/O-MAHANANDA SWEETS*ASHRAM PARA*36, JATIN DAS SARANI*PO-SILIGURI*DARJEELING-734001",
    fullName: "SAMIR DAS",
    saID: "SPR3638",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "SUR",
    address: "VILL/PO-DHANDALIBAR*PURBA MEDINIPUR-721452",
    fullName: "ASHOK KUMAR SUR",
    saID: "SPR3639",
  },
  {
    firstName: "MANORANJAN KUMAR",
    lastName: "ROY",
    address: "VILL-BASANTIPUR*PO-GRAHAMPUR*VIA-DINGDINGA*KOKRAJHAR-783333.",
    fullName: "MANORANJAN KUMAR ROY",
    saID: "SPR3640",
  },
  {
    firstName: "LATE PRAVAT CHANDRA",
    lastName: "ROY",
    address: "VILL-JINKATA*PO-MAHAMAYAHAT*DHUBRI.",
    fullName: "LATE PRAVAT CHANDRA ROY",
    saID: "SPR3641",
  },
  {
    firstName: "BINAY BHUSAN",
    lastName: "BANIK",
    address: "AT&PO-KHARUPETIAGHAT*DARRANG-784115*ASSAM",
    fullName: "BINAY BHUSAN BANIK",
    saID: "SPR3642",
  },
  {
    firstName: "BALIRAM",
    lastName: "SARGIARI",
    address: "BACHIMARI*PO-GERUABAZAR*DARRANG-784514*ASSAM",
    fullName: "BALIRAM SARGIARI",
    saID: "SPR3643",
  },
  {
    firstName: "NABA KUMAR",
    lastName: "PEGU",
    address: "RAJGARH TINIALI*PO-GERUKHAMUKH*DHEMAJI*LAKHIMPUR 787056",
    fullName: "NABA KUMAR PEGU",
    saID: "SPR3644",
  },
  {
    firstName: "LATE NIKHIL CHANDRA",
    lastName: "DEBNATH",
    address: "SATSANG VIHAR*PO-TURA*GAROHILLS*MEGHALAYA-794001",
    fullName: "LATE NIKHIL CHANDRA DEBNATH",
    saID: "SPR3645",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "GHOSH",
    address: "PLASSEY SUGAR MILL*NADIA-741157*PAN-AHAPG2529F",
    fullName: "DILIP KUMAR GHOSH",
    saID: "SPR3646",
  },
  {
    firstName: "NRIPENDRA KUMAR",
    lastName: "SHARMA",
    address: "VILL-SUKANTA NAGAR*PO-KUMARGHAT*TRIPURA-799264*PAN-AGZPS1378L",
    fullName: "NRIPENDRA KUMAR SHARMA",
    saID: "SPR3647",
  },
  {
    firstName: "SUPRIYA KUMAR",
    lastName: "DUTTA",
    address: "VILL&PO-NILDA*PASCHIM MEDINIPUR 721 457.",
    fullName: "SUPRIYA KUMAR DUTTA",
    saID: "SPR3648",
  },
  {
    firstName: "DHRUBA NARAYAN",
    lastName: "SAHA",
    address: "VILL-DASARATH PALLI*PO-SEVOKE ROAD(SILIGURI)*JALPAIGURI",
    fullName: "DHRUBA NARAYAN SAHA",
    saID: "SPR3649",
  },
  {
    firstName: "LATE PABITRA MOHAN",
    lastName: "BEHERA",
    address: "AT/PO-PAILIPADA*VIA-GANGPUR*DT-GANJAM",
    fullName: "LATE PABITRA MOHAN BEHERA",
    saID: "SPR3650",
  },
  {
    firstName: "SUDARSAN",
    lastName: "SAHOO",
    address: "AT-BIKASHA NAGAR*PO-JATNI*KHURDA 752050",
    fullName: "SUDARSAN SAHOO",
    saID: "SPR3651",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "NAYAK",
    address: "SHREYASIS*AT-KADUASAHI*PO-KHANDAPARA*NAYAGARH.",
    fullName: "PRAFULLA CHANDRA NAYAK",
    saID: "SPR3652",
  },
  {
    firstName: "SARAT CHANADRA",
    lastName: "MOHANTY",
    address: "AT-AMUNIA SAHI*PO-TIGIRIA*CUTTACK-754030",
    fullName: "SARAT CHANADRA MOHANTY",
    saID: "SPR3653",
  },
  {
    firstName: "BRAJABANDHU",
    lastName: "SWAIN",
    address:
      "A E,O/O DY.DIRECTOR OF HARTICULTURE*AT-FARM ROAD*PO-MODIPARA*DIST-SAMBALPUR-768002",
    fullName: "BRAJABANDHU SWAIN",
    saID: "SPR3654",
  },
  {
    firstName: "BIDYADHAR",
    lastName: "SAHOO",
    address: "BARUAN [B]*MATHAKARGOLA*DHENKANAL-759024*ODISHA",
    fullName: "BIDYADHAR SAHOO",
    saID: "SPR3655",
  },
  {
    firstName: "DAYANIDHI",
    lastName: "SAMAL",
    address: "AT-NAULA*PO-ARODEI-754138*JAGATSINGHPUR-754138*PAN-BBSPS2237P",
    fullName: "DAYANIDHI SAMAL",
    saID: "SPR3656",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "TRIPATHY",
    address: "AT-JAMARA*PO-BAGHUNI*VIA-ASURESWAR*CUTTACK 754209*PAN-ABXPT8105H",
    fullName: "JITENDRA NATH TRIPATHY",
    saID: "SPR3657",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "NAYAK",
    address: "AT-KANTOL*PO-ATHGARH*CUTTACK 754029.",
    fullName: "LAXMIDHAR NAYAK",
    saID: "SPR3658",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "PANDA",
    address: "VIIL.BELPUR*PO-SIMULIA*VIA-SORO*BALASORE-756045",
    fullName: "KAILASH CHANDRA PANDA",
    saID: "SPR3659",
  },
  {
    firstName: "SANJOY KUMAR",
    lastName: "ACHARYA",
    address: "VILL-RAYAKHANDI*PO-GOBAKUND*PURI-752045",
    fullName: "SANJOY KUMAR ACHARYA",
    saID: "SPR3660",
  },
  {
    firstName: "MAGATA",
    lastName: "SAHOO",
    address:
      "C/O S N SAHOO*QR NO 23,TYPE-V,SCHEDULE-A*RASTRAPATI ESTATE*NEW DELHI 110004",
    fullName: "MAGATA SAHOO",
    saID: "SPR3661",
  },
  {
    firstName: "KANDURI CHARAN",
    lastName: "CHAMPATI",
    address: "AT-GOLARA*PO-GADASAHI*VIA-KANAS*PURI-752017",
    fullName: "KANDURI CHARAN CHAMPATI",
    saID: "SPR3662",
  },
  {
    firstName: "PRATAP CHANDRA",
    lastName: "PARIDA",
    address:
      'AT-"PRAVAPOORI",PLOT-242/1402*LEWIS ROAD*PO-BHUBANESWAR*KHURDA 751002',
    fullName: "PRATAP CHANDRA PARIDA",
    saID: "SPR3663",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "BANERJEE",
    address: "KRISHNAPATTI DASPARA*PO-TELINIPARA*HOOGHLY-712125",
    fullName: "PRAVAKAR BANERJEE",
    saID: "SPR3664",
  },
  {
    firstName: "RADHA GOVINDA",
    lastName: "MAHAPATRA",
    address:
      '"SWASTI BITAN"*PLOT NO-4461(B),BADAGADA BRIT COLONY*BHUBANESWAR-751018*DIST-KHURDA',
    fullName: "RADHA GOVINDA MAHAPATRA",
    saID: "SPR3665",
  },
  {
    firstName: "PADMANAV",
    lastName: "SAHOO",
    address: "AT-KSHETRAPAL*PO-KANTIGARIA*JAJPUR-755049",
    fullName: "PADMANAV SAHOO",
    saID: "SPR3666",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "MALLICK",
    address: "AT-MANIA*PO-UCHAPADA*VIA-KOTASAHI*DIST-CUTTACK-754022",
    fullName: "ASHOK KUMAR MALLICK",
    saID: "SPR3667",
  },
  {
    firstName: "ABHAYA KUMAR",
    lastName: "PARIDA",
    address:
      "SATSANG VIHAR BHUBANESWAR*BHUBANESWAR-751007*KHURDA*(PAN-AIVPP7777L)",
    fullName: "ABHAYA KUMAR PARIDA",
    saID: "SPR3668",
  },
  {
    firstName: "ASHIS KUMAR",
    lastName: "GHOSH",
    address:
      "ANIMAL GENETICS & BREEDING*VETY COLLEGE;G B P U A T*PANTNAGAR*U.K.*PAN-ACAPG6261D",
    fullName: "ASHIS KUMAR GHOSH",
    saID: "SPR3669",
  },
  {
    firstName: "P ABHIMANYU",
    lastName: "REDDY",
    address: "SATSANG*PO-SATSANG*DEOGHAR 814116.",
    fullName: "P ABHIMANYU REDDY",
    saID: "SPR3670",
  },
  {
    firstName: "KRISHNA PADA",
    lastName: "SARDAR",
    address:
      "NAHARPAR CAMP-2*POWER HOUSE*PO-BHILAI 1*DIST-DURG*(M.P.) 490001*PAN- AJSPS0353B",
    fullName: "KRISHNA PADA SARDAR",
    saID: "SPR3671",
  },
  {
    firstName: "SASHANKA KUMAR",
    lastName: "BISWAS",
    address: "AT-JHOLI NO-1*PO-CHIKALPATY*BETUL-460 440*M.P.",
    fullName: "SASHANKA KUMAR BISWAS",
    saID: "SPR3672",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "BISWAS",
    address:
      "C/O KIRAN CHANDRA BISWAS,PV.NO.23*PO-MIXED FARM*DIST-KANKER 494776*C G*(ATXPB6470F)",
    fullName: "SAMIR KUMAR BISWAS",
    saID: "SPR3673",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "HALDAR",
    address:
      "UTTAR BHABANIPUR*PO-KHARAGPUR*PASCHIM MEDINIPUR-721301*PAN-AIBPH 7804G",
    fullName: "ASHUTOSH HALDAR",
    saID: "SPR3674",
  },
  {
    firstName: "ASHIM KUMAR",
    lastName: "NATH",
    address: "16 A,SADGOPE PARA ROAD*PO-BHATPARA*24PARGANAS (NORTH)-743134",
    fullName: "ASHIM KUMAR NATH",
    saID: "SPR3675",
  },
  {
    firstName: "NRIPENDRA NATH",
    lastName: "DAS",
    address: "VILL&PO-TAPAN*DAKSHIN DINAJPUR-733127*PAN-AFIPD6988B",
    fullName: "NRIPENDRA NATH DAS",
    saID: "SPR3676",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "BARMAN",
    address: "PO/VILL-BORI*DT-NALBARI .",
    fullName: "ANANTA KUMAR BARMAN",
    saID: "SPR3677",
  },
  {
    firstName: "LATE SUDHIR RANJAN",
    lastName: "DAS",
    address: "SODEPUR PANCHANANTALA*NATAGARH*24PARGANAS NORTH 700 113",
    fullName: "LATE SUDHIR RANJAN DAS",
    saID: "SPR3678",
  },
  {
    firstName: "SUDEB",
    lastName: "PURKAIT",
    address: "VILL&PO-GOKARNEE*SOUTH 24PARGANAS 743601",
    fullName: "SUDEB PURKAIT",
    saID: "SPR3679",
  },
  {
    firstName: "ANADI KUMAR",
    lastName: "BASU",
    address: "RISRA*SOUDAMINI NAGAR*NABIN PALLI*PO-MORE PUKUR*HOOGHLY-712250",
    fullName: "ANADI KUMAR BASU",
    saID: "SPR3680",
  },
  {
    firstName: "MUKUNDA RAM",
    lastName: "GHOSAL",
    address: "VILL&PO-BHANDARHATI*HOOGHLY-712301",
    fullName: "MUKUNDA RAM GHOSAL",
    saID: "SPR3681",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "GHOSH",
    address: "VILL/PO-HAZIPUR*HOOGHLY-712302",
    fullName: "DILIP KUMAR GHOSH",
    saID: "SPR3682",
  },
  {
    firstName: "ANANTA",
    lastName: "DEBNATH",
    address: "JONAI SATSANG KENDRA*PO-JONAI*DIST-DHEMAJI-787060*ASSAM",
    fullName: "ANANTA DEBNATH",
    saID: "SPR3683",
  },
  {
    firstName: "BISWANATH",
    lastName: "RAKSHIT",
    address: "VILL/PO-KAMALPUR*BANKURA 722137*PAN-ADSPR4795E",
    fullName: "BISWANATH RAKSHIT",
    saID: "SPR3684",
  },
  {
    firstName: "JAGADISH",
    lastName: "SARKAR",
    address:
      "UDAYACHAL,GOPAL NAGAR*GHOR DOUR CHATI*PO-SREE PALLY*BURDWAN-713103.",
    fullName: "JAGADISH SARKAR",
    saID: "SPR3685",
  },
  {
    firstName: "LATE RAM KUMAR",
    lastName: "SEAL",
    address: "ADVOCATE,LAWYER'S ASSOCIATION*PO-GAUHATI-781001*KAMRUP (ASSAM).",
    fullName: "LATE RAM KUMAR SEAL",
    saID: "SPR3686",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "BISWAS",
    address: "VILL&PO-PATHARGHATA*24PARGANAS (NORTH)",
    fullName: "SUNIL KUMAR BISWAS",
    saID: "SPR3687",
  },
  {
    firstName: "BIRBHADRA",
    lastName: "BISWAS",
    address: "DOCTOR KHANA PARA)*VILL&PO-BETAI*NADIA-741163",
    fullName: "BIRBHADRA BISWAS",
    saID: "SPR3688",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "BISWAS",
    address:
      "ADVOCATE*FIRE SERVICE CHOWMUHANI*RAMNAGAR*AGARTALA-799002*TRIPURA",
    fullName: "DIPAK KUMAR BISWAS",
    saID: "SPR3689",
  },
  {
    firstName: "LATE ASHIT BARAN",
    lastName: "BISWAS",
    address: "5 NO,BAKULTALA LANE,NALTA*PO-KUMAR PARA*CALCUTTA-700028.",
    fullName: "LATE ASHIT BARAN BISWAS",
    saID: "SPR3690",
  },
  {
    firstName: "LATE GOPENDRA NATH",
    lastName: "DAS",
    address: "VILL-GANGURIA(SUKANTAPALLY)*PO-SUBARNAPUR*NADIA",
    fullName: "LATE GOPENDRA NATH DAS",
    saID: "SPR3691",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "BHADRA",
    address: "PO-SIMULGURI*DIST-SIBSAGAR.",
    fullName: "LATE NANIGOPAL BHADRA",
    saID: "SPR3692",
  },
  {
    firstName: "NANDA KISHOR",
    lastName: "PANDA",
    address: "AT/PO-ARUHA*VIA-BETADA*BHADRAK 756168*PAN-CLGPP2733J",
    fullName: "NANDA KISHOR PANDA",
    saID: "SPR3693",
  },
  {
    firstName: "DHIRESH PRASAD",
    lastName: "DANI",
    address: "AT-DANISAHI*PO-BOUDHRAJ*DIST-KANDHAMAL 762014",
    fullName: "DHIRESH PRASAD DANI",
    saID: "SPR3694",
  },
  {
    firstName: "JANMEJOY",
    lastName: "MAHATA",
    address:
      "AT-KAMALPUR*PO-DANGAMAL*VIA-ISWARPUR*KENDRAPARA 754 248*PAN-BHJPM0687G",
    fullName: "JANMEJOY MAHATA",
    saID: "SPR3695",
  },
  {
    firstName: "SHAKTI RANJAN",
    lastName: "PATNAIK",
    address: '"SUNANDAN"*AT&PO-BIROL*VIA-CHOWDWAR*CUTTACK-754025',
    fullName: "SHAKTI RANJAN PATNAIK",
    saID: "SPR3696",
  },
  {
    firstName: "BRUNDABAN",
    lastName: "SAHOO",
    address: '"SHANTAYANA"*AT-PARK LANE*PO/DIST-KEONJHAR 758001',
    fullName: "BRUNDABAN SAHOO",
    saID: "SPR3697",
  },
  {
    firstName: "LATE SUBAL CHANDRA",
    lastName: "BARIK",
    address: "AT&PO-BAUNSHDIHA*VIA-KAMARDA*BALASORE 756035",
    fullName: "LATE SUBAL CHANDRA BARIK",
    saID: "SPR3698",
  },
  {
    firstName: "ADWEITA PRASAD",
    lastName: "BEHERA",
    address: "VILL-ARANA*PO-GADAMA*DIST-JAGATSINGHPUR-754159*ODISHA",
    fullName: "ADWEITA PRASAD BEHERA",
    saID: "SPR3699",
  },
  {
    firstName: "LATE DASARATHI",
    lastName: "SAHOO",
    address:
      "O/O THE E.E.,O E C F,DIVN-II*AT-JIRIDIMALI*PO-KAMAKHYANAGAR*DHENKANAL 759018",
    fullName: "LATE DASARATHI SAHOO",
    saID: "SPR3700",
  },
  {
    firstName: "MAHESWAR",
    lastName: "DAS",
    address: "VILL-BIRASWATI*PO-SRI BALADEV JEW*DIST-KENDRAPARA-754212",
    fullName: "MAHESWAR DAS",
    saID: "SPR3701",
  },
  {
    firstName: "BINODA KUMAR",
    lastName: "MISHRA",
    address: "C/O ARJUNA MISHRA*AT&PO-MARTHAPUR*DHENKANAL-759023.",
    fullName: "BINODA KUMAR MISHRA",
    saID: "SPR3702",
  },
  {
    firstName: "KISHOR KUMAR",
    lastName: "KAR",
    address: "AT-GHADUAL*PO&DIST-NAYAGARH-752069*ODISHA",
    fullName: "KISHOR KUMAR KAR",
    saID: "SPR3703",
  },
  {
    firstName: "MANORANJAN",
    lastName: "DAS",
    address: "SATSANG THAKURBARI*CHAKRATIRTHA ROAD*PURI-752002",
    fullName: "MANORANJAN DAS",
    saID: "SPR3704",
  },
  {
    firstName: "LILADHAR",
    lastName: "SHARMA",
    address: "ELECTRICAL DIVISION*PO-RAIRANGPUR*MAYURBHANJ 757043",
    fullName: "LILADHAR SHARMA",
    saID: "SPR3705",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "PARIDA",
    address: "AT-PURUNA TIGIRIA*PO-TIGIRIA*CUTTACK-754030*PAN-ASBPP4559L",
    fullName: "BIJAY KUMAR PARIDA",
    saID: "SPR3706",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SWAIN",
    address: "AT-LABOUR TENAMENT*PO-CHARBATIA*CUTTACK-754028",
    fullName: "SURENDRA NATH SWAIN",
    saID: "SPR3707",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "MOHANTA",
    address:
      "AT-RAUTARA*PO-RAJALOKA*VIA-JHARPOKHARIA*MAYURBHANJ 757086 PAN.DFDPM8781R",
    fullName: "SANTOSH KUMAR MOHANTA",
    saID: "SPR3708",
  },
  {
    firstName: "LAXMAN",
    lastName: "THAKUR",
    address: "VILL-BAIRIYA*PO&DIST-GIRIDIH-822125*PAN-AETPT7900F",
    fullName: "LAXMAN THAKUR",
    saID: "SPR3709",
  },
  {
    firstName: "GURUPADA",
    lastName: "PANDEY",
    address: "VILL-CHAITAN TALA*PO-NALAGOALA*MALDA-733124*PAN-BONPP0179K",
    fullName: "GURUPADA PANDEY",
    saID: "SPR3710",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "GHOSE",
    address: "NO-B-2287,KALYANI*CONGRESS ROAD*NADIA",
    fullName: "PRAFULLA KUMAR GHOSE",
    saID: "SPR3711",
  },
  {
    firstName: "LATE MANIK CHANDRA",
    lastName: "TALUKDER",
    address: "VILL-DHAMDHAMA*PO-NIJ DHAMDHAMA*NALBARI",
    fullName: "LATE MANIK CHANDRA TALUKDER",
    saID: "SPR3712",
  },
  {
    firstName: "UGRASEN",
    lastName: "LENKA",
    address: "AT-SAILO*PO-A.B.C.PATNA*VIA-AHIYAS*CUTTACK 755036",
    fullName: "UGRASEN LENKA",
    saID: "SPR3713",
  },
  {
    firstName: "BIDHU BHUSAN",
    lastName: "TRIPATHY",
    address:
      "PLOT NO 806(PART)*JAYDEV VIHAR*PO-R R L*BHUBANESWAR-751013*DIST-KHURDA",
    fullName: "BIDHU BHUSAN TRIPATHY",
    saID: "SPR3714",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "BHUIYAN",
    address: "AT-KUANSOLE*PO-BHAGABANPUR*VIA-LEMALO*CUTTACK-754293",
    fullName: "GOBINDA CHANDRA BHUIYAN",
    saID: "SPR3715",
  },
  {
    firstName: "KRUTTIBAS",
    lastName: "SAMAL",
    address: "QR.NO.D-12 G.M.COMPLEX*CHIRIMIRI*DIST-KORBA*C.G.",
    fullName: "KRUTTIBAS SAMAL",
    saID: "SPR3716",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "DAS",
    address: "AT-KENDUPADA*PO-SHANKARA*SUNDARGARH 770020*PAN-AESPD2142N",
    fullName: "PRASANTA KUMAR DAS",
    saID: "SPR3717",
  },
  {
    firstName: "NIRANJAN",
    lastName: "ACHARYA",
    address: "AT-PARABEDA*PO-JEYPORE*KORAPUT 764001",
    fullName: "NIRANJAN ACHARYA",
    saID: "SPR3718",
  },
  {
    firstName: "RAJANI KANTA",
    lastName: "MOHANTY",
    address: "VILL-GURUJANG*PO/DIST-KHURDA-752055",
    fullName: "RAJANI KANTA MOHANTY",
    saID: "SPR3719",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MISHRA",
    address: '"INDUSMRUTI" NAGESWARTANGI*BHUBANESWAR 751002*KHURDA',
    fullName: "RABINDRA NATH MISHRA",
    saID: "SPR3720",
  },
  {
    firstName: "SHARANGADHAR PRASAD",
    lastName: "SINGH",
    address: "C/O SRI SRI BORDA*SATSANG 814116",
    fullName: "SHARANGADHAR PRASAD SINGH",
    saID: "SPR3721",
  },
  {
    firstName: "LATE DHANANJAY",
    lastName: "MISHRA",
    address: "C/O SRI SRI BORDA*SATSANG 814116.",
    fullName: "LATE DHANANJAY MISHRA",
    saID: "SPR3722",
  },
  {
    firstName: "LATE BIMALENDU",
    lastName: "BANERJEE",
    address:
      "RLY.QR NO-196/1*ENGG.COLONY,RAIPUR*PO-GANJ*DIST-RAIPUR*CHHATISH GARH",
    fullName: "LATE BIMALENDU BANERJEE",
    saID: "SPR3723",
  },
  {
    firstName: "LATE HARE KRISHNA",
    lastName: "SINGH",
    address: "VILL&PO-KORIAPATTI*SUPOAL*SAHARSA",
    fullName: "LATE HARE KRISHNA SINGH",
    saID: "SPR3724",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "ROUT",
    address: "AT-MAHISASUR*PO-RAJNAGAR*KENDRAPARA 754225",
    fullName: "RAMESH CHANDRA ROUT",
    saID: "SPR3725",
  },
  {
    firstName: "PURUSHOTTAM",
    lastName: "SHARMA",
    address: "BALUA TAL,MOTIHARI*PO-MOTIHARI*DIST-EAST CHAMPARAN-845401*BIHAR.",
    fullName: "PURUSHOTTAM SHARMA",
    saID: "SPR3726",
  },
  {
    firstName: "NEMAI CHANDRA",
    lastName: "RAY",
    address:
      '"SWASTI NIWAS"*ANAND NAGAR*MANA CAMP*DIST-RAIPUR*C.G.-492015*PAN ACTPR3593A',
    fullName: "NEMAI CHANDRA RAY",
    saID: "SPR3727",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "RAY",
    address: "KAPSI BAZAR SAZGHAR*PO-KAPSI*BASTAR*M.P. 494771",
    fullName: "GANESH CHANDRA RAY",
    saID: "SPR3728",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "BANDOPADHYAY",
    address:
      "CHIEF CONTROLLER OF FACTORIES*11/77,MALL ROAD*PO-MORAR*GWALIOR-474006",
    fullName: "LATE NITYANANDA BANDOPADHYAY",
    saID: "SPR3729",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "MANDAL",
    address: "VILL-DIGMA(BANARAS ROAD)*PO-FUNDORDIHARY 497001*DT-SURGUJA*M.P.",
    fullName: "MANINDRA NATH MANDAL",
    saID: "SPR3730",
  },
  {
    firstName: "SURESH KUMAR",
    lastName: "DAS",
    address: "241,MANAVATA NAGAR*KENADIA ROAD*INDORE-452016*M.P.",
    fullName: "SURESH KUMAR DAS",
    saID: "SPR3731",
  },
  {
    firstName: "LATE S R",
    lastName: "REDDY",
    address:
      "CHIEF ENGR M E S(RETD)*SADHANA TIRTHA*48 S C NAGAR*BELGAUM*KARNATAKA 590006",
    fullName: "LATE S R REDDY",
    saID: "SPR3732",
  },
  {
    firstName: "LATE D B",
    lastName: "ASBE",
    address: "SATSANG VIHAR*KULGAON*THANA-411503*M.S.",
    fullName: "LATE D B ASBE",
    saID: "SPR3733",
  },
  {
    firstName: "M K",
    lastName: "PANJABI",
    address:
      "RL 70,MILAPNAGAR*M.I.D.C.RESIDENTIAL ZONE*MAHARASTRA-421203*PAN-AGJPP6058J",
    fullName: "M K PANJABI",
    saID: "SPR3734",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MANDAL",
    address: "AT&PO-BAGICHA*JASHPUR*CHHATISHGARH-496445",
    fullName: "PANCHANAN MANDAL",
    saID: "SPR3735",
  },
  {
    firstName: "MAHENDRA KUMAR",
    lastName: "MANDAL",
    address: "O/O THE EXECUTIVE ENGINEER*P W D DEPTT*SAHARSA-852201",
    fullName: "MAHENDRA KUMAR MANDAL",
    saID: "SPR3736",
  },
  {
    firstName: "MOTICHAND",
    lastName: "KASHYAP",
    address: "AT&PO-KASHAWGHAN*GARHWA*PALAMU-822114",
    fullName: "MOTICHAND KASHYAP",
    saID: "SPR3737",
  },
  {
    firstName: "VIDYANAND",
    lastName: "PODDAR",
    address: "AT&PO-BISHNUPUR*P S.DANDARI*VIA-LAKHMANIA*BEGUSARAI 851211",
    fullName: "VIDYANAND PODDAR",
    saID: "SPR3738",
  },
  {
    firstName: "JAYDEV",
    lastName: "DHAR",
    address:
      '"SWASTITAPA"SHIVMANDIR BAZAR*PO-KADAMTALA*VIA-SILIGURI*DARJEELING 734433*ABIPD0736B',
    fullName: "JAYDEV DHAR",
    saID: "SPR3739",
  },
  {
    firstName: "PANCHANAN",
    lastName: "BISWAS",
    address:
      "SAKTI FARM NO-4*PO-SAKTIFARM*UDHAMSINGH NAGAR U.P.*PAN-AHLPB8859J.",
    fullName: "PANCHANAN BISWAS",
    saID: "SPR3740",
  },
  {
    firstName: "KALIPRASAD",
    lastName: "SINGH",
    address: "283,KAILASHPURI*HUSSAINABAD*JAUNPUR*U.P.-222001",
    fullName: "KALIPRASAD SINGH",
    saID: "SPR3741",
  },
  {
    firstName: "HARINATH",
    lastName: "SARMA",
    address:
      "PUB-JYOTINAGAR(NEAR HALDI MILL)*PO-BAMUNIMAIDAN*GUWAHATI-21*ASSAM",
    fullName: "HARINATH SARMA",
    saID: "SPR3742",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "SARDAR",
    address:
      "SATSANG VIHAR,CHIMTA*PO-CHIMTA KACHARI*VIA-BHEBIA*24PARGANAS NORTH-743456",
    fullName: "KARTIK CHANDRA SARDAR",
    saID: "SPR3743",
  },
  {
    firstName: "DIMBESWAR",
    lastName: "BHUYAN",
    address: "VILL-NATUN GOAN*PO-JAGI ROAD*MORIGAON 782410",
    fullName: "DIMBESWAR BHUYAN",
    saID: "SPR3744",
  },
  {
    firstName: "JOGDHAN",
    lastName: "MANNA",
    address: "JHARBONI*PO-AMLAGORA 721121*PASCHIM MEDINIPUR",
    fullName: "JOGDHAN MANNA",
    saID: "SPR3745",
  },
  {
    firstName: "LATE KAMESWAR",
    lastName: "RAY",
    address: "PO&VILL-RAJAULI*VAISHALI 844101",
    fullName: "LATE KAMESWAR RAY",
    saID: "SPR3746",
  },
  {
    firstName: "LATE HIMANGSHU",
    lastName: "GHOSE",
    address: "SRINAGAR*PO-BANGALJHI*NADIA-741123",
    fullName: "LATE HIMANGSHU GHOSE",
    saID: "SPR3747",
  },
  {
    firstName: "NILRATAN",
    lastName: "GHOSH",
    address:
      "KANSHARI BAGAN*NILGANJ ROAD*PO-PANIHATI*24 PARGANAS (NORTH)-700114",
    fullName: "NILRATAN GHOSH",
    saID: "SPR3748",
  },
  {
    firstName: "SIDDHESWAR",
    lastName: "BISWAS",
    address: "AT/PO-GOSSAIRHAT*BANDER*COOCHBEHAR-736172",
    fullName: "SIDDHESWAR BISWAS",
    saID: "SPR3749",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "MADALI",
    address: "AT/PO-ORIKANTA*VIA-LEMALO*CUTTACK 754293.",
    fullName: "BIJAY KUMAR MADALI",
    saID: "SPR3750",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "MISHRA",
    address: "VILL-ITDA*PO-PHULBANI*DIST-KANDHAMAL-762001*ODISHA",
    fullName: "PRAKASH CHANDRA MISHRA",
    saID: "SPR3751",
  },
  {
    firstName: "HARI MADHAB",
    lastName: "DAS",
    address:
      "DHRUTI BITAN*AT-NILAMBARPUR*PO-SIMINAI*DHENKANAL-759025*PAN-BDXPD4435P",
    fullName: "HARI MADHAB DAS",
    saID: "SPR3752",
  },
  {
    firstName: "NIMAIN CHARAN",
    lastName: "DAS",
    address: "G-81, SECTOR-2*ROURKELA-769006*.SUNDARGARH.",
    fullName: "NIMAIN CHARAN DAS",
    saID: "SPR3753",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "BHUYAN",
    address: "AT-KUANSOL*PO-BHAGABANPUR*VIA-LEMALO*DIST-CUTTACK-754293",
    fullName: "RAM CHANDRA BHUYAN",
    saID: "SPR3754",
  },
  {
    firstName: "SADHAN CHANDRA",
    lastName: "MONDAL",
    address: "C12A/1, TAGORE PARK*NASKARHAT*KOLKATA-700039*PAN-ADRPM1458L",
    fullName: "SADHAN CHANDRA MONDAL",
    saID: "SPR3755",
  },
  {
    firstName: "SRIDHAR",
    lastName: "SAMAL",
    address: "AT/PO-EKATALI*VIA-BHUBAN*DHENKANAL 759017*PAN-EWIPS5522M",
    fullName: "SRIDHAR SAMAL",
    saID: "SPR3756",
  },
  {
    firstName: "DEBDUTTA",
    lastName: "BARAL",
    address:
      "VILL-GARUDABANDI*PO-ANLAJHARI*VIA-BHUBAN*DHENKANAL 759017*PAN-BAIPB4406L",
    fullName: "DEBDUTTA BARAL",
    saID: "SPR3757",
  },
  {
    firstName: "PARSURAM",
    lastName: "SWAIN",
    address: "VILL-BIRAPADA*PO-R N BETARA*DIST-BHADRAK-756100*PAN-EUEPS1038G",
    fullName: "PARSURAM SWAIN",
    saID: "SPR3758",
  },
  {
    firstName: "BHASKAR CHANDRA",
    lastName: "MAIKAP",
    address: "AT-BALISAHI*PO-PUTURA*BALASORE 756081",
    fullName: "BHASKAR CHANDRA MAIKAP",
    saID: "SPR3759",
  },
  {
    firstName: "KUSHA KUMAR",
    lastName: "MANDAL",
    address: "AT/PO-KHERNA*VIA-SARASKANA*MAYURBHANJ",
    fullName: "KUSHA KUMAR MANDAL",
    saID: "SPR3760",
  },
  {
    firstName: "RABINARAYAN",
    lastName: "MISHRA",
    address: "AT/PO-KURUNTA*VIA-TURIGARIA*BALASORE 756047",
    fullName: "RABINARAYAN MISHRA",
    saID: "SPR3761",
  },
  {
    firstName: "LAXMIKANTA",
    lastName: "MOHANTY",
    address: "AT&PO-SANKHATRAS*CUTTACK 753053",
    fullName: "LAXMIKANTA MOHANTY",
    saID: "SPR3762",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "SAHOO",
    address: "AT/PO-KHUNTUNI*VIA-ATHGARH*CUTTACK-754029",
    fullName: "PRAMOD KUMAR SAHOO",
    saID: "SPR3763",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "DAS",
    address: "AT-MAHANGAPARA*PO-ANLO-754010*VIA-BAIROI*CUTTACK",
    fullName: "GIRIDHARI DAS",
    saID: "SPR3764",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "SWAIN",
    address: "AT-RAHANSA*PO-ORIKANTA*VIA-LEMALO*CUTTACK 754203.",
    fullName: "KARTIK CHANDRA SWAIN",
    saID: "SPR3765",
  },
  {
    firstName: "KANHU CHARAN",
    lastName: "NAYAK",
    address: "HEAD MASTER*MAHLOCHANPUR M.E.SCHOOL*AT/PO-MAHAL*CUTTACK-754210.",
    fullName: "KANHU CHARAN NAYAK",
    saID: "SPR3766",
  },
  {
    firstName: "KRUSHNA CHANDRA",
    lastName: "SAHOO",
    address: "AT/PO-MURUSUAN*VIA-RAJNAGAR*KEONJHAR-758017*PAN NO.DFMPS6542K",
    fullName: "KRUSHNA CHANDRA SAHOO",
    saID: "SPR3767",
  },
  {
    firstName: "BALA KRUSHNA",
    lastName: "BEHERA",
    address: "AT-RATANPUR*PO-BRAHMAKUNDI*CUTTACK 752113.",
    fullName: "BALA KRUSHNA BEHERA",
    saID: "SPR3768",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "DAS",
    address:
      "AT- SWASTIDHAM*O T ROAD,BALASORE*PO/DT-BALASORE 756001*PAN-AAQPD0236R",
    fullName: "AJIT KUMAR DAS",
    saID: "SPR3769",
  },
  {
    firstName: "JAI NARAYAN",
    lastName: "SINGH",
    address: "VILL-SUJIBAD*PO-KUSTASHRAM(PAROW)*VARANASI-221008",
    fullName: "JAI NARAYAN SINGH",
    saID: "SPR3770",
  },
  {
    firstName: "MANORANJAN",
    lastName: "SARKAR",
    address:
      "PO&VILL-EAHGAON VILLAGE NO 5*VIA-S.K.Z.R*DIST-ADILABAD*A.P-504296.",
    fullName: "MANORANJAN SARKAR",
    saID: "SPR3771",
  },
  {
    firstName: "BIRENDRA KUMAR",
    lastName: "ROUT",
    address:
      "SATSANG VIHAR CHENNAI*NO-2 S V ROAD*SRISANKARNAGAR*PAMMAL*CHENNAI-75*PAN AQAPM4273H",
    fullName: "BIRENDRA KUMAR ROUT",
    saID: "SPR3772",
  },
  {
    firstName: "LALAN PRASAD",
    lastName: "SINGH",
    address:
      '"DEB SUDHA" BHAWAN*PROFESSOR\'S COLONY*WARD NO-19*GANGJALA*SAHARSA-852201-AIKPS5266L',
    fullName: "LALAN PRASAD SINGH",
    saID: "SPR3773",
  },
  {
    firstName: "RAMESH",
    lastName: "PRASAD",
    address: "VILL&PO-BAGA*BHOJPUR-802164",
    fullName: "RAMESH PRASAD",
    saID: "SPR3774",
  },
  {
    firstName: "RAJAPRASAD",
    lastName: "RAY",
    address: "BIBIGANJ*DANAPUR CANTT*PATNA-801503",
    fullName: "RAJAPRASAD RAY",
    saID: "SPR3775",
  },
  {
    firstName: "LATE RAM UDGAR",
    lastName: "SHARMA",
    address: "SATSANG ADHIBESHAN KENDRA*MOTIHARI*EAST-CHAMPARAN",
    fullName: "LATE RAM UDGAR SHARMA",
    saID: "SPR3776",
  },
  {
    firstName: "BINDESWARI PRASAD",
    lastName: "MAHATO",
    address: "VILL-BASUDEVPUR*PO-BARHARAKOTHI*PURNIA.",
    fullName: "BINDESWARI PRASAD MAHATO",
    saID: "SPR3777",
  },
  {
    firstName: "R",
    lastName: "PURUSHOTTAM",
    address: "3-1-466,NIMBOLI ADDA*KACHEGUDA*HYDERABAD 500027",
    fullName: "R PURUSHOTTAM",
    saID: "SPR3778",
  },
  {
    firstName: "RAMAKANT",
    lastName: "SINGH",
    address: "VILL&PO-KATHARIA*BALLIA*U.P.-221713",
    fullName: "RAMAKANT SINGH",
    saID: "SPR3779",
  },
  {
    firstName: "LATE NAGENDRA",
    lastName: "PRASAD",
    address: "INFRONT OF DUBEY PETROL TANK GALI*NEW FATEHPUR*SIWAN",
    fullName: "LATE NAGENDRA PRASAD",
    saID: "SPR3780",
  },
  {
    firstName: "SHYAMLAL",
    lastName: "PANDIT",
    address:
      "KIRIBURU TOWNSHIP,TYPE-II,31/2*P.O-KIRIBURU*DT-SINGHBHUM-833222.*PAN-AHAPP4234G",
    fullName: "SHYAMLAL PANDIT",
    saID: "SPR3781",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "SARDAR",
    address:
      "SONARPUR RURAL HOSPITAL*VILL-SUBHASHGRAM*PO-KODALIA*24PARGANAS NORTH-700146",
    fullName: "SUBHAS CHANDRA SARDAR",
    saID: "SPR3782",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "PRADHAN",
    address: "AT-JANARDANPUR*PO-LENDURA*VIA-KENDUPATNA*CUTTACK 754203",
    fullName: "DEBENDRA NATH PRADHAN",
    saID: "SPR3783",
  },
  {
    firstName: "GAUTAM CHARAN",
    lastName: "BHUYAN",
    address: "VILL-PAIKARAPUR*PO-NIKIRAI*KENDRAPARA 754 211*ORISSA",
    fullName: "GAUTAM CHARAN BHUYAN",
    saID: "SPR3784",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "DUTTA",
    address: "PO-BOKAJAN*KARBIANGLONG-782480*ASSAM",
    fullName: "TAPAN KUMAR DUTTA",
    saID: "SPR3785",
  },
  {
    firstName: "SUKHAMAY",
    lastName: "DAS",
    address: "PO-BOKAJAN*KARBIANGLONG-782480*PAN ATIPD5163E",
    fullName: "SUKHAMAY DAS",
    saID: "SPR3786",
  },
  {
    firstName: "BHOLANATH",
    lastName: "SARMA",
    address: "VILL-KALIMAHABIRBARI ROAD*PO/DIST-KARIMGANJ-788710*ASSAM",
    fullName: "BHOLANATH SARMA",
    saID: "SPR3787",
  },
  {
    firstName: "KRISHNA PRASAD",
    lastName: "KAKATI",
    address: "BHAKARIVITA;CHOUDHURY PARA*WARD NO-17*PO&DT-BONGAIGAON (ASSAM)",
    fullName: "KRISHNA PRASAD KAKATI",
    saID: "SPR3788",
  },
  {
    firstName: "LATE NABIRAM",
    lastName: "MUSHAHARY",
    address: "VILL-BASHGAON*PO-SARAIBIL*VIA-KACHNGAON*KOKRAJHAR 783350.",
    fullName: "LATE NABIRAM MUSHAHARY",
    saID: "SPR3789",
  },
  {
    firstName: "PULENDRA CHANDRA",
    lastName: "MAJUMDAR",
    address: "VILL-DOMERGURI*PO-LENGTISINGHA*GOALPARA-783384*ASSAM",
    fullName: "PULENDRA CHANDRA MAJUMDAR",
    saID: "SPR3790",
  },
  {
    firstName: "SASHI BHUSAN",
    lastName: "SARKAR",
    address: "VILL/PO-AMBASSA*DHALAI, TRIPURA-799289.",
    fullName: "SASHI BHUSAN SARKAR",
    saID: "SPR3791",
  },
  {
    firstName: "RATNAKAR",
    lastName: "MAHANTY",
    address: "AT-NATAPADA*PO-KHAIRA*DIST-BALASORE-756048*PAN-ADDPM1192R",
    fullName: "RATNAKAR MAHANTY",
    saID: "SPR3792",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "MAHAPATRA",
    address: "AT/PO-JALASUAN*VIA-SAINKUL*KEONJHAR-758043",
    fullName: "DHIRENDRA KUMAR MAHAPATRA",
    saID: "SPR3793",
  },
  {
    firstName: "SURJYAMANI",
    lastName: "TRIPATHY",
    address: "AT&PO-UDALA*MAYURBHANJ-757041",
    fullName: "SURJYAMANI TRIPATHY",
    saID: "SPR3794",
  },
  {
    firstName: "NIGAMANANDA",
    lastName: "PATTANAIK",
    address:
      "ICHINDA  WARD NO-12*AT&PO-RAIRANGPUR*MAYURBHANJ-757043*PAN-AJLPP2910N",
    fullName: "NIGAMANANDA PATTANAIK",
    saID: "SPR3795",
  },
  {
    firstName: "LATE BISHWANATH",
    lastName: "DEY",
    address: "VILL-JANGAL PARA*PO-SINGTI SHIBPUR*HOWRAH 711226",
    fullName: "LATE BISHWANATH DEY",
    saID: "SPR3796",
  },
  {
    firstName: "SURENDRA PRASAD",
    lastName: "SINHA",
    address:
      "BAGBERA COLONY*BLOCK NO-27/2/3*TATANAGAR*E.SINGHBHUM-831002*PAN-AEIPS5435B",
    fullName: "SURENDRA PRASAD SINHA",
    saID: "SPR3797",
  },
  {
    firstName: "BHASKAR CHANDRA",
    lastName: "NAYAK",
    address:
      "AT-SHYAMRAIPUR*PO-GELPUR*VIA-MADHABNAGAR*BHADRAK-756181*PAN-AFUPN3570P",
    fullName: "BHASKAR CHANDRA NAYAK",
    saID: "SPR3798",
  },
  {
    firstName: "LOKNATH",
    lastName: "HAJRA",
    address: "VILL-TAZPUR*PO-RAJARAMPUR*PURBA MEDINIPUR-721628",
    fullName: "LOKNATH HAJRA",
    saID: "SPR3799",
  },
  {
    firstName: "SUKUMAR",
    lastName: "GHOSH",
    address: "VILL&PO-KULTIKIRY*PASCHIM MEDINIPUR 721 135.",
    fullName: "SUKUMAR GHOSH",
    saID: "SPR3800",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "MONDAL",
    address: "MANIKPUR DELTA MILL*PO-DELTA MILL*HOWRAH-711309.",
    fullName: "SANTOSH KUMAR MONDAL",
    saID: "SPR3801",
  },
  {
    firstName: "BHOLESWAR",
    lastName: "PRADHAN",
    address: "AT&PO-KANKILI*VIA-TALCHER*ANGUL-759100",
    fullName: "BHOLESWAR PRADHAN",
    saID: "SPR3802",
  },
  {
    firstName: "LATE BRAJA GOPAL",
    lastName: "DUTTA",
    address:
      "C/O GOVINDA DUTTA*SHITLAPARA,W/NO-3*AT/PO-GOSSAIGAON*DIST-KOKRAJHAR-783360*ASSAM.",
    fullName: "LATE BRAJA GOPAL DUTTA",
    saID: "SPR3803",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "PATRA",
    address: "AT-GANGADHAR NAGAR*PO&DIST-BARGARH-768028*PAN-AHZPP4981D",
    fullName: "BASANTA KUMAR PATRA",
    saID: "SPR3804",
  },
  {
    firstName: "MANOJIT KUMAR",
    lastName: "RAY",
    address: "BRICKFIELD RD,CHOTONILPUR*PO-SREEPALLY*BURDWAN 713103.",
    fullName: "MANOJIT KUMAR RAY",
    saID: "SPR3805",
  },
  {
    firstName: "BIJOY KRISHNA",
    lastName: "LAHA",
    address:
      "AT-SARADA PALLY, SEHARA BAZAR*PO-SEHARA*BURDWAN-713423*PAN- AAYPL4282D",
    fullName: "BIJOY KRISHNA LAHA",
    saID: "SPR3806",
  },
  {
    firstName: "NALINI",
    lastName: "DASGUPTA",
    address: "KUMARPARA*PO-ARUNACHAL*CACHAR*PAN-AXDPG6680L",
    fullName: "NALINI DASGUPTA",
    saID: "SPR3807",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "MAHATA",
    address:
      "VILL-SONAMUKHI*PO-BIRIHANDI*DIST-PASCHIM MEDINIPUR*PAN-AHPPM2648L.",
    fullName: "JITENDRA NATH MAHATA",
    saID: "SPR3808",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "BANDOPADHYAY",
    address: "8/B JOGESH MITRA ROAD*CALCUTTA 25",
    fullName: "KARTIK CHANDRA BANDOPADHYAY",
    saID: "SPR3809",
  },
  {
    firstName: "SANTOSH",
    lastName: "MANDAL",
    address:
      "SANTOSH SWEETS,RAILPAR*PO-RAMPURHAT*BIRBHUM 731224*PAN-AECPM8813K",
    fullName: "SANTOSH MANDAL",
    saID: "SPR3810",
  },
  {
    firstName: "PRAVASH CHANDRA",
    lastName: "BAIDYA",
    address: "PO&VILL-KORAKATI*24PARGANAS NORTH-743446*PAN-PUEPB7280H",
    fullName: "PRAVASH CHANDRA BAIDYA",
    saID: "SPR3811",
  },
  {
    firstName: "MATILAL",
    lastName: "DEB",
    address: "PO/VILL-CHAKPARA*VIA-LILUAH*HOWRAH-711204",
    fullName: "MATILAL DEB",
    saID: "SPR3812",
  },
  {
    firstName: "RADHARAMAN",
    lastName: "BHATTACHARJEE",
    address:
      "AT-SATGHATA*PO-GONDALPARA*CHANDANNAGAR*HOOGHLY-712137*PAN-AEEPB1360C",
    fullName: "RADHARAMAN BHATTACHARJEE",
    saID: "SPR3813",
  },
  {
    firstName: "ASHOKE KUMAR",
    lastName: "CHAKRABARTY",
    address:
      "C/O-SANTOSH CHAKRABORTY*NAIHATI RD*PO-ASHOKENAGAR*24PARGANAS (NORTH)*PAN-ACIPC5038L",
    fullName: "ASHOKE KUMAR CHAKRABARTY",
    saID: "SPR3814",
  },
  {
    firstName: "DULAL KANTI",
    lastName: "PAL",
    address: "VILL/PO-DIGHARKHAL*CACHAR-788815*PAN-AQDPP1792R",
    fullName: "DULAL KANTI PAL",
    saID: "SPR3815",
  },
  {
    firstName: "LATE HARA KUMAR",
    lastName: "BANDOPADHYAY",
    address: "VIL&PO-SOUTHGARIA*24PARGANAS",
    fullName: "LATE HARA KUMAR BANDOPADHYAY",
    saID: "SPR3816",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "PAL",
    address:
      "H.D.A. ANUSUA QR Q-NO-4*PO-DURGACHAK(HALDIA)*PURBA MEDINIPUR 721602",
    fullName: "JAYANTA KUMAR PAL",
    saID: "SPR3817",
  },
  {
    firstName: "LATE GOBINDA",
    lastName: "KALITA",
    address: "RANGIA DIVN. (IRR.)*PO-RANGIA*KAMRUP",
    fullName: "LATE GOBINDA KALITA",
    saID: "SPR3818",
  },
  {
    firstName: "BANAMALI",
    lastName: "SAHU",
    address:
      "R.I.O, C.I.L.*VETY CAMPUS(MILITARY LINE)*BERHAMPUR-760007*GANJAM*PAN.AMWPS8218E",
    fullName: "BANAMALI SAHU",
    saID: "SPR3819",
  },
  {
    firstName: "MURALIDHAR",
    lastName: "PATRA",
    address: "FAKIR MOHAN NAGAR*BEHIND I.T.I*BALASORE-756001",
    fullName: "MURALIDHAR PATRA",
    saID: "SPR3820",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "SAHOO",
    address: "SATSANG THAKUR BARI*CHAKRATIRTHA ROAD*PURI-752002*PAN-GLIPS0785M",
    fullName: "SAROJ KUMAR SAHOO",
    saID: "SPR3821",
  },
  {
    firstName: "AKSHOY CHANDRA",
    lastName: "DASH",
    address:
      "SURAT SATSANG VIHAR*AT-BHIMRAD*PO-BHARTHANA *SURAT*GUJARAT 395007.",
    fullName: "AKSHOY CHANDRA DASH",
    saID: "SPR3822",
  },
  {
    firstName: "KUMUD CHANDRA",
    lastName: "PRADHAN",
    address: "ASSTT ENGINEER*M I SUB DIVN*ATHMALLIK*ANGUL",
    fullName: "KUMUD CHANDRA PRADHAN",
    saID: "SPR3823",
  },
  {
    firstName: "LATE DEBARAJ",
    lastName: "SAHU",
    address: "AT&PO-POLASARA*GANJAM",
    fullName: "LATE DEBARAJ SAHU",
    saID: "SPR3824",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "SAHOO",
    address: "VILL-KSHETRAPAL*PO-KANTIGERIA-755049*CUTTACK",
    fullName: "CHITTARANJAN SAHOO",
    saID: "SPR3825",
  },
  {
    firstName: "MAHABIR PRASAD",
    lastName: "TRIPATHY",
    address: "AT-GUDIANALI*PO-DHENKANAL*DHENKANAL-759001*PAN-ACFPT8007M",
    fullName: "MAHABIR PRASAD TRIPATHY",
    saID: "SPR3826",
  },
  {
    firstName: "KULAMANI",
    lastName: "DAS",
    address: "AT&PO-CHATIKONA*BISSAM CUTTACK*DIST-RAYAGADA-765019*ODISHA",
    fullName: "KULAMANI DAS",
    saID: "SPR3827",
  },
  {
    firstName: "BRAJA KISHORE",
    lastName: "BISWAL",
    address: '"ASTIKA"*ANAND VIHAR*CHAULIAGANJ*CUTTACK 753004',
    fullName: "BRAJA KISHORE BISWAL",
    saID: "SPR3828",
  },
  {
    firstName: "KALPATARU TIRTHBASI",
    lastName: "PARIDA",
    address: "AT&PO-JAMPARA*KENDRAPARA-754244*PAN-ADWPP5669H",
    fullName: "KALPATARU TIRTHBASI PARIDA",
    saID: "SPR3829",
  },
  {
    firstName: "ISWAR CHANDRA",
    lastName: "SAHOO",
    address: "4258,PANDAB NAGAR*BARAGARH*BHUBANESWAR 751018*PAN-ANDPS4142H",
    fullName: "ISWAR CHANDRA SAHOO",
    saID: "SPR3830",
  },
  {
    firstName: "R S",
    lastName: "BILGRAMI",
    address:
      "VILLA NO-250;DADDY'S GARDEN*KAMSANDRA CROSS HEBBAGODI*BANGALORE 560100*ACTPB9338H",
    fullName: "R S BILGRAMI",
    saID: "SPR3831",
  },
  {
    firstName: "KAMDEO PRASAD",
    lastName: "SHRIVASTAB",
    address: "SEIKHPURA*AKHARAGHAT*MUZAFFARPUR",
    fullName: "KAMDEO PRASAD SHRIVASTAB",
    saID: "SPR3832",
  },
  {
    firstName: "BINOD BIHARI",
    lastName: "MAHATO",
    address: "TATA SIJUA 12NO. COLONY*PO-BHELATAND*DHANBAD 828103",
    fullName: "BINOD BIHARI MAHATO",
    saID: "SPR3833",
  },
  {
    firstName: "SHITAL CHANDRA",
    lastName: "MAHATO",
    address: "VILL&PO-NIMDIH 832401*SINGHBHUM",
    fullName: "SHITAL CHANDRA MAHATO",
    saID: "SPR3834",
  },
  {
    firstName: "PASHUPATI NATH",
    lastName: "SINGH",
    address: "C/O- NAVIGANJ SATSANG VIHAR*AT/PO-CHAPRA 841 301",
    fullName: "PASHUPATI NATH SINGH",
    saID: "SPR3835",
  },
  {
    firstName: "SACHINDRA NATH",
    lastName: "PODDAR",
    address: "VILL-SHERPUR*PO-NAYASARAI*DIST-HOOGHLY-712513",
    fullName: "SACHINDRA NATH PODDAR",
    saID: "SPR3836",
  },
  {
    firstName: "DWIJENDRA NATH",
    lastName: "KARMAHAPATRA",
    address: "AT-KASBA EGRA*PO-EGRA*PURBA MEDINIPUR-721429",
    fullName: "DWIJENDRA NATH KARMAHAPATRA",
    saID: "SPR3837",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "BARMAN",
    address: "SATSANG VIHAR,LEDO*AT/PO-LEDO*DIST-TINSUKIA*ASSAM 786182",
    fullName: "MRINAL KANTI BARMAN",
    saID: "SPR3838",
  },
  {
    firstName: "RAM PRASAD",
    lastName: "GARAI",
    address: "VILL-RASULPUR*PO-KENETI-722206*BANKURA*PAN-AEHPG7104R",
    fullName: "RAM PRASAD GARAI",
    saID: "SPR3839",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "MISHRA",
    address: "SANKARPUR NALBARI*PO-CHOUK BAZAR*DT-NALBARI 781334",
    fullName: "KAILASH CHANDRA MISHRA",
    saID: "SPR3840",
  },
  {
    firstName: "NABAKUMAR",
    lastName: "GHORUI",
    address: "VILL-KEDARNAGAR*PO-PAWNAN, PS-POLBA*DIST-HOOGHLY-712305.",
    fullName: "NABAKUMAR GHORUI",
    saID: "SPR3841",
  },
  {
    firstName: "LATE DUKHABHANJAN",
    lastName: "KAR",
    address: "VILL-BANDHADANGA*PO-BUDHANPUR*BANKURA 722 137",
    fullName: "LATE DUKHABHANJAN KAR",
    saID: "SPR3842",
  },
  {
    firstName: "SRINATH",
    lastName: "HOWLADAR",
    address:
      "I-249 SUKANTA NAGAR*SALT LAKE SEC-4*KOLKATA-700 091*PAN-AAQPH8036H",
    fullName: "SRINATH HOWLADAR",
    saID: "SPR3843",
  },
  {
    firstName: "UTPAL KANTI",
    lastName: "ROY",
    address:
      "VILL&PO-KUMIR MARI*VIA-CHHOTO MOLLA KHALI*24PARGANAS SOUTH-743378",
    fullName: "UTPAL KANTI ROY",
    saID: "SPR3844",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "MEDHI",
    address: "SATSANG VIHAR*GOUHATI-781005*KAMRUP*ASSAM",
    fullName: "PRAFULLA CHANDRA MEDHI",
    saID: "SPR3845",
  },
  {
    firstName: "LATE RAMANI MOHAN",
    lastName: "CHAKRABORTY",
    address:
      "C/O-BHAJAN CHAKRABORTY*S-69, BIDHAN NAGAR HOUSING*PO-DURGAPUR-16*BURDWAN",
    fullName: "LATE RAMANI MOHAN CHAKRABORTY",
    saID: "SPR3846",
  },
  {
    firstName: "SAILENDRA",
    lastName: "MUCHAHARY",
    address: "VILL-AIPOALI*PO-TUKRIJHORA*DIST-BONGAIGAON-783394*(ASSAM)",
    fullName: "SAILENDRA MUCHAHARY",
    saID: "SPR3847",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "DUTTA",
    address: "VILL&PO-KHILPARA*UDAIPUR*TRIPURA-799114*PAN-ABSPD5415F",
    fullName: "NEPAL CHANDRA DUTTA",
    saID: "SPR3848",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "MAJUMDAR",
    address:
      "C/O-LT.KUMUDKANT GANGULY*NABAGRAM `C' BLOCK*PO-BARBAHERA*HOOGHLY-712246.",
    fullName: "SARAT CHANDRA MAJUMDAR",
    saID: "SPR3849",
  },
  {
    firstName: "BHASKAR CHANDRA",
    lastName: "SAHOO",
    address: "AT/PO-BARIKUL*VIA-RAMBAGH*JAJPUR-755 014*ODISHA.",
    fullName: "BHASKAR CHANDRA SAHOO",
    saID: "SPR3850",
  },
  {
    firstName: "JAGANNATH",
    lastName: "BISWAL",
    address: "(J.E.)*SATSANG VIHAR,ANGUL*PO/DIST-ANGUL-759122",
    fullName: "JAGANNATH BISWAL",
    saID: "SPR3851",
  },
  {
    firstName: "SUKANTA KUMAR",
    lastName: "LENKA",
    address:
      "QR NO-A/258,KOEL NAGER*ROURKELA 14*SUNDERGARH-769014*PAN-ACBPL0405H",
    fullName: "SUKANTA KUMAR LENKA",
    saID: "SPR3852",
  },
  {
    firstName: "UMAKANTA",
    lastName: "SAHOO",
    address:
      "QR.NO.VIM-798,SAILASHREE VIHAR*CHANDRASEKHARPUR*BHUBANESWAR-751016*PAN-ADQPS3195M",
    fullName: "UMAKANTA SAHOO",
    saID: "SPR3853",
  },
  {
    firstName: "PADMA LOCHAN",
    lastName: "PRADHAN",
    address: "AT-BAINCHA*PO-BADASINDHIA*VIA-MOTIGANJ*DIST-BALASORE-756003",
    fullName: "PADMA LOCHAN PRADHAN",
    saID: "SPR3854",
  },
  {
    firstName: "BHUBANESWAR",
    lastName: "SARMA",
    address: "VILL-BELKUNDA(GANNI PUR)*PO-B.L.SEN(HAJIPUR)*BAISALI 844135",
    fullName: "BHUBANESWAR SARMA",
    saID: "SPR3855",
  },
  {
    firstName: "MIHIR KANTI",
    lastName: "MANDAL",
    address: "SUBHAS PALLY*PO-PAKHANJORE*BASTAR*M.P. 494776",
    fullName: "MIHIR KANTI MANDAL",
    saID: "SPR3856",
  },
  {
    firstName: "NIRAD KUMAR",
    lastName: "NAYAK",
    address: "PLOT NO-1B/98*SECTOR-9,C.D.A.*CUTTACK-753012*PAN-AAAPN2082R",
    fullName: "NIRAD KUMAR NAYAK",
    saID: "SPR3857",
  },
  {
    firstName: "RAJENDRA NATH",
    lastName: "MAHATO",
    address: "VILL-LAKHIPUR*PO-KELIASOLE*P.S-NIRSA*DHANBAD 828205.",
    fullName: "RAJENDRA NATH MAHATO",
    saID: "SPR3858",
  },
  {
    firstName: "NITAI CHAND",
    lastName: "JANA",
    address: "VILL-BARJIBAN*PO-KUNDALPAL*DIST-PASCHIM MEDINIPUR-721140",
    fullName: "NITAI CHAND JANA",
    saID: "SPR3859",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "PAUL",
    address: "M/S ABARAN*PO-MARIANI*JORHAT-785634*PAN-AENPP4035P",
    fullName: "SUBHASH CHANDRA PAUL",
    saID: "SPR3860",
  },
  {
    firstName: "BHUSAN CHANDRA",
    lastName: "GHOSH",
    address: "THAKURBARI*P-233 C.I.T.SCHEME VI M*CALCUTTA 54",
    fullName: "BHUSAN CHANDRA GHOSH",
    saID: "SPR3861",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "DUTTA",
    address: "PO-RANGPUR*DIST-SILCHAR 788 009*ASSAM",
    fullName: "TAPAS KUMAR DUTTA",
    saID: "SPR3862",
  },
  {
    firstName: "JIBAN JYOTI",
    lastName: "KURMI",
    address: "SATSANG VIHAR*PO-TEJPUR-784001*SONITPUR",
    fullName: "JIBAN JYOTI KURMI",
    saID: "SPR3863",
  },
  {
    firstName: "HARIPADA",
    lastName: "MAHATO",
    address:
      "VILL-SAMURDIH*PO-SALBONI*P.S-BALARAMPUR*PURULIA-723143*PAN-AHXPM6818Q",
    fullName: "HARIPADA MAHATO",
    saID: "SPR3864",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "MANNA",
    address:
      "SADHUKHAPARA(JORA SIBMANDIR)*MONIRAMPUR*BARRACKPORE,*KOLKATA-20*PAN BDTPM7666P",
    fullName: "MRINAL KANTI MANNA",
    saID: "SPR3865",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "KANRAR",
    address: "VILL-UTTAR PODRAH NATUN PALLY*PO-PODRAH*HOWRAH-711109",
    fullName: "RABINDRA NATH KANRAR",
    saID: "SPR3866",
  },
  {
    firstName: "CHAMPAK",
    lastName: "DUTTA",
    address: "C/O N G DUTTA*SONAI ROAD*SILCHAR-788006.*PAN-AGCPD1297N",
    fullName: "CHAMPAK DUTTA",
    saID: "SPR3867",
  },
  {
    firstName: "LATE SHANTI RANJAN",
    lastName: "BISWAS",
    address: "VILL/PO-METELI*JALPAIGURI",
    fullName: "LATE SHANTI RANJAN BISWAS",
    saID: "SPR3868",
  },
  {
    firstName: "SHIBU CHARAN",
    lastName: "JENA",
    address: "WARD NO-15,GOBINDAPALLI*AT/PO/DIST-BARGARH-768028*ODISHA",
    fullName: "SHIBU CHARAN JENA",
    saID: "SPR3869",
  },
  {
    firstName: "DIPAK RANJAN",
    lastName: "SAHOO",
    address: "751,OLD STATION BAZAR*BHUBANESWAR 751006*KHURDA",
    fullName: "DIPAK RANJAN SAHOO",
    saID: "SPR3870",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "DALEI",
    address: "AT-KANIKA*PO-RARUAN*DIST-MAYURBHANJ 757035*PAN-AQYPD9293Q",
    fullName: "PRAVAKAR DALEI",
    saID: "SPR3871",
  },
  {
    firstName: "KHAGESWAR",
    lastName: "OJHA",
    address: "KABISAMRAT PALLY*SEC-5, ROURKELA-2.",
    fullName: "KHAGESWAR OJHA",
    saID: "SPR3872",
  },
  {
    firstName: "BISWANATH",
    lastName: "SAHU",
    address: "AT/PO-MURUSUAN*DT-KEONJHAR 758017*PAN-CBCPS7107M",
    fullName: "BISWANATH SAHU",
    saID: "SPR3873",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "SAHOO",
    address: "AT-PANUSAHOO PATNA*PO-KANTILO*NAYAGARH-752078*ORISSA",
    fullName: "BASANTA KUMAR SAHOO",
    saID: "SPR3874",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "PANDA",
    address: "S.G.HIGH SCHOOL,BILASUNI*AT/PO-BILASUNI*NIALI*CUTTACK-754004",
    fullName: "PRAMOD KUMAR PANDA",
    saID: "SPR3875",
  },
  {
    firstName: "SURATHA",
    lastName: "MALLICK",
    address: "AT&PO-ARANDUA*VIA-BASUDEBPUR*BHADRAK-756125",
    fullName: "SURATHA MALLICK",
    saID: "SPR3876",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "PATNAIK",
    address: "ADDL. TAHSILDAR, JAJPUR*AT-BHAGABATPUR*PO-DEVIDWAR*JAJPUR-755007",
    fullName: "ANIL KUMAR PATNAIK",
    saID: "SPR3877",
  },
  {
    firstName: "SAILENDRA NATH",
    lastName: "KONAR",
    address: "PO&VILL-KHANPUR*HOOGHLY-712308",
    fullName: "SAILENDRA NATH KONAR",
    saID: "SPR3878",
  },
  {
    firstName: "DHIDIPAN",
    lastName: "SARDAR",
    address: "VILL-GOBINDAKATI*PO-SRIDHARKATI*24PARGANAS",
    fullName: "DHIDIPAN SARDAR",
    saID: "SPR3879",
  },
  {
    firstName: "MAHENDRA",
    lastName: "BORA",
    address: "PO-KUSUMTALA*SONITPUR*ASSAM",
    fullName: "MAHENDRA BORA",
    saID: "SPR3880",
  },
  {
    firstName: "HRISHIKESH",
    lastName: "SARDAR",
    address: "VILL/PO-BASANTI*24PARGANAS (SOUTH)-743312*PAN ARVPS6361C",
    fullName: "HRISHIKESH SARDAR",
    saID: "SPR3881",
  },
  {
    firstName: "PRITI RANJAN",
    lastName: "MAJUMDAR",
    address:
      "KANKURGACHI C.I.T.BUILDINGS*2ND FLOOR,BLOCK-5,FLAT NO-15*PO-KANKURGACHI*KOLKATA-54",
    fullName: "PRITI RANJAN MAJUMDAR",
    saID: "SPR3882",
  },
  {
    firstName: "KUNAL",
    lastName: "SARKAR",
    address: "VILL-KHOSBAG*PO-INDAS*BANKURA 722205*PAN-EPXPS8516D",
    fullName: "KUNAL SARKAR",
    saID: "SPR3883",
  },
  {
    firstName: "RAJMOHAN",
    lastName: "DASPURAKAYASTHA",
    address: "PO-JAMUNAMUKH*NAGAON-782428*ASSAM",
    fullName: "RAJMOHAN DASPURAKAYASTHA",
    saID: "SPR3884",
  },
  {
    firstName: "BABUN",
    lastName: "WADDADAR",
    address:
      "SATSANG VIHAR GUWAHATI*BHANGAGARH*GUWAHATI-5*KAMRUP-781005*PAN-AAOPW6727G",
    fullName: "BABUN WADDADAR",
    saID: "SPR3885",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MAZUMDER",
    address: "PIPULPATI BEHIND D.I.O.*PO/DIST-HOOGHLY-712 103",
    fullName: "RANJIT KUMAR MAZUMDER",
    saID: "SPR3886",
  },
  {
    firstName: "JADUNATH",
    lastName: "MAHATO",
    address: "BANDWAN SATSANG CENTRE*PO-BANDWAN*PURULIA-723129",
    fullName: "JADUNATH MAHATO",
    saID: "SPR3887",
  },
  {
    firstName: "BISHNUPADA",
    lastName: "PANDA",
    address: "VILL-JAGANNATHPUR*PO-BOLKUSHDA*PURBA MEDINIPUR-721446",
    fullName: "BISHNUPADA PANDA",
    saID: "SPR3888",
  },
  {
    firstName: "GOLOK NATH",
    lastName: "MANDAL",
    address: "KANDI SATSANG KENDRA*VILL-ATGRAM*PO-KANDI*MURSHIDABAD-742137",
    fullName: "GOLOK NATH MANDAL",
    saID: "SPR3889",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "MAHANTY",
    address: "VILL&PO-ORIKANTA*VIA-LEMALO*CUTTACK 754293",
    fullName: "UPENDRA NATH MAHANTY",
    saID: "SPR3890",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "SINHA",
    address: "VILL-BHAKATGAON*PO-CHAPAR*DIST-DHUBRI-783371*PAN-AHWPS0487Q",
    fullName: "JAYANTA KUMAR SINHA",
    saID: "SPR3891",
  },
  {
    firstName: "KAPILA",
    lastName: "BEHURA",
    address:
      "C/O LALITA DAL  FLOUR MILL*PLOT NO-403*RASULGARH*BHUBANESWAR-751010*KHURDA",
    fullName: "KAPILA BEHURA",
    saID: "SPR3892",
  },
  {
    firstName: "KSHETRABASI",
    lastName: "PRADHAN",
    address: "JAGAPUR  ROAD*DASPALLA*DIST-NAYAGARH*PAN-AIYPP5812B",
    fullName: "KSHETRABASI PRADHAN",
    saID: "SPR3893",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "KHUNTIA",
    address: "AT-LAXMINARAYANPUR*PO-SIDDHESWARPUR*CUTTACK-754100",
    fullName: "PRAFULLA KUMAR KHUNTIA",
    saID: "SPR3894",
  },
  {
    firstName: "JAGADISH PRASAD",
    lastName: "PATNAIK",
    address:
      '"DIBYA PRASAD"*AT-KENDUPADA*PO-SANKARA*DIST-SUNDARGARH 770020*ODISHA',
    fullName: "JAGADISH PRASAD PATNAIK",
    saID: "SPR3895",
  },
  {
    firstName: "HARA PRASAD",
    lastName: "SAHOO",
    address:
      "FLAT NO.303;DHRUTISHREE*KHUSHI UPAHAR APT.;KALPANA AREA*B J B NAGAR*BHUBANESWAR 14",
    fullName: "HARA PRASAD SAHOO",
    saID: "SPR3896",
  },
  {
    firstName: "UDAY NATH",
    lastName: "MISHRA",
    address: "AT&PO-SAKSHIGOPAL*DIST-PURI*ODISHA-752014",
    fullName: "UDAY NATH MISHRA",
    saID: "SPR3897",
  },
  {
    firstName: "SACHIDANANDA",
    lastName: "MALLICK",
    address: "AT-SATSANG VIHAR KENDRAPARA*PO-PANDIRI*KENDRAPARA-754211",
    fullName: "SACHIDANANDA MALLICK",
    saID: "SPR3898",
  },
  {
    firstName: "SASHADHAR",
    lastName: "KUILA",
    address: "AT&PO-PODAASTIA*VIA-SHAMAKHUNTA*MAYURBHANJ 757 049",
    fullName: "SASHADHAR KUILA",
    saID: "SPR3899",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "SAHOO",
    address: "VILL-JUANIA*PO-GOBINDPUR KACHERY 755061*DIST-KENDRAPARA.",
    fullName: "DHIRENDRA SAHOO",
    saID: "SPR3900",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "PATI",
    address: "AT-KUNDI*PO-KUMURAJAIPUR*CUTTACK 754287*PAN-ADCPP3616L",
    fullName: "BIBHUTI BHUSAN PATI",
    saID: "SPR3901",
  },
  {
    firstName: "NITYANANDA",
    lastName: "DHAR",
    address: "EB 460 BADAGADA BRIT COLONY*BHUBANESWAR-751018*KHURDA",
    fullName: "NITYANANDA DHAR",
    saID: "SPR3902",
  },
  {
    firstName: "ACHYUTANANDA",
    lastName: "SAHOO",
    address: "AT-BAREI*PO-BADABIRUAN*VIA-SANKHACHILA*CUTTACK 755015",
    fullName: "ACHYUTANANDA SAHOO",
    saID: "SPR3903",
  },
  {
    firstName: "RAKHAL CHANDRA",
    lastName: "ADAK",
    address: "VILL/PO-DEULPUR*VIA-ANDUL*HOWRAH-712414",
    fullName: "RAKHAL CHANDRA ADAK",
    saID: "SPR3904",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "GURU",
    address:
      "VILL-KIRTIBASPUR*PO-UTTAR DHANKHAL*PS-DASPUR*PASCHIM MEDINIPUR-721211",
    fullName: "MADAN MOHAN GURU",
    saID: "SPR3905",
  },
  {
    firstName: "JAPENDRA NATH",
    lastName: "NARJARY",
    address: "GOSSAIGAON WARD NO-2*PO-GOSSAIGAON*KOKRAJHAR-783360*ASSAM",
    fullName: "JAPENDRA NATH NARJARY",
    saID: "SPR3906",
  },
  {
    firstName: "BIDHU BHUSAN",
    lastName: "DEBNATH",
    address: "MAL SATSANG VIHAR*SATSANG NAGAR*PO-MAL*JALPAIGURI-735221",
    fullName: "BIDHU BHUSAN DEBNATH",
    saID: "SPR3907",
  },
  {
    firstName: "JALADHAR",
    lastName: "PRADHAN",
    address:
      "C/O LAKSHMIPRIYA TRADERS*MECHEDA RD*PO-CONTAI*PURBA MEDINIPUR-721401*PAN-AEPPP0814E",
    fullName: "JALADHAR PRADHAN",
    saID: "SPR3908",
  },
  {
    firstName: "SISIR",
    lastName: "CHAKRABARTY",
    address:
      "RUKMINI NAGAR,SAPTARSHI PATH*PO-ASSAM SACHIVALAYA,DISPUR*GUWAHATI-6*KAMRUP*ASSAM",
    fullName: "SISIR CHAKRABARTY",
    saID: "SPR3909",
  },
  {
    firstName: "KHAGENDRA NATH",
    lastName: "GHOSH",
    address: "VILL-KALACHHARA*PO-CHANDITALA*HOOGHLY-712702",
    fullName: "KHAGENDRA NATH GHOSH",
    saID: "SPR3910",
  },
  {
    firstName: "KANAI LAL",
    lastName: "BARMAN",
    address: "VILL-PURBA CHAKCHAKA*PO-BAROBISHA*JALPAIGURI-736207",
    fullName: "KANAI LAL BARMAN",
    saID: "SPR3911",
  },
  {
    firstName: "ANIL CHANDRA",
    lastName: "GOP",
    address: "PO-BARPETA ROAD*WARD NO 1*DIST-BARPETA-781315*ASSAM",
    fullName: "ANIL CHANDRA GOP",
    saID: "SPR3912",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "MUKHERJEE",
    address: "NABAGRAM SATSANG VIHAR*PO-NABAGRAM*DIST-HOOGHLY-712246",
    fullName: "JATINDRA NATH MUKHERJEE",
    saID: "SPR3913",
  },
  {
    firstName: "ASIM CHANDRA",
    lastName: "HALDAR",
    address: "AT-HOGLARDAIR*PO-JAYKRISHNAPUR*MURSHIDABAD-742305",
    fullName: "ASIM CHANDRA HALDAR",
    saID: "SPR3914",
  },
  {
    firstName: "DINABANDHU",
    lastName: "MISHRA",
    address: "AT-NUAGAON*PO-JATNI*DT-KHURDA 752050",
    fullName: "DINABANDHU MISHRA",
    saID: "SPR3915",
  },
  {
    firstName: "BRUNDABAN",
    lastName: "MISHRA",
    address:
      "SATSANG VIHAR,ANANDAPUR*PO-GHASIPURA*KEONJHAR-758015*PAN-AHSPM7425J",
    fullName: "BRUNDABAN MISHRA",
    saID: "SPR3916",
  },
  {
    firstName: "PRAMUKH RANJAN",
    lastName: "NATH",
    address: "KABUGANJ NATUN PALLI*PO-KABUGANJ*CACHAR 788121",
    fullName: "PRAMUKH RANJAN NATH",
    saID: "SPR3917",
  },
  {
    firstName: "GAUTAM",
    lastName: "BANDOPADHYAY",
    address: "C/O GOUR BANERJEE*PO&VILL-KHANTURA*24PARGANAS (NORTH) -743273",
    fullName: "GAUTAM BANDOPADHYAY",
    saID: "SPR3918",
  },
  {
    firstName: "PARAMANANDA",
    lastName: "SAMAL",
    address: "SWASTIBITAN*GOPABANDHU NAGAR*PO-SEMILIGUDA*KORAPUT-764036",
    fullName: "PARAMANANDA SAMAL",
    saID: "SPR3919",
  },
  {
    firstName: "RAM KUMAR",
    lastName: "NATH",
    address: "'RAM NIVAS'*RATNA BAZAR*PO/DIST-DHENKANAL 759013.",
    fullName: "RAM KUMAR NATH",
    saID: "SPR3920",
  },
  {
    firstName: "SAROJ KANT",
    lastName: "NAYAK",
    address: "VILL-RAGHABPUR*PO-MANDIA*VIA-BEDARI*KENDRAPARA-754253*ODISHA",
    fullName: "SAROJ KANT NAYAK",
    saID: "SPR3921",
  },
  {
    firstName: "MAHESWAR",
    lastName: "PUHAN",
    address: "AT-MANAPUR*PO-GOBARDHAN PUR*VIA-JAJPUR ROAD*DIST-JAJPUR",
    fullName: "MAHESWAR PUHAN",
    saID: "SPR3922",
  },
  {
    firstName: "HERAMBA PRASAD",
    lastName: "MEDHI",
    address: "VILL&PO-NORTH SALMARA-783383*BONGAIGAON",
    fullName: "HERAMBA PRASAD MEDHI",
    saID: "SPR3923",
  },
  {
    firstName: "NIKUNJA CHARAN",
    lastName: "SWAIN",
    address:
      "PLOT NO-223/1755, BOMIKHAL*PO-RASULGARH*BHUBANESWAR-751010*KHURDA.",
    fullName: "NIKUNJA CHARAN SWAIN",
    saID: "SPR3924",
  },
  {
    firstName: "ALEKH CHARAN",
    lastName: "NATH",
    address: "AT-SAHAPUR*PO-GEDMA*DT-KEONJHAR 756 121",
    fullName: "ALEKH CHARAN NATH",
    saID: "SPR3925",
  },
  {
    firstName: "PARSURAM",
    lastName: "MANIK",
    address: "AT-KOTHASAHI*PO-CHHADESH*PO-OLAVER*DIST-KENDRAPARA-754227",
    fullName: "PARSURAM MANIK",
    saID: "SPR3926",
  },
  {
    firstName: "UDAY SANKAR",
    lastName: "MUKHERJEE",
    address: "VILL-DHAKCHARA*PO-KAMARGACHI*HOOGHLY.",
    fullName: "UDAY SANKAR MUKHERJEE",
    saID: "SPR3927",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "MAHANTA",
    address: "AT-BARAPADA*PO-NANDAPUR*VIA-KARANJIA*KEONJHAR 758044",
    fullName: "NEPAL CHANDRA MAHANTA",
    saID: "SPR3928",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "RAY",
    address: "PO-JOYPUR RAJABAJAR*VILL-RAJABAJAR*CACHAR",
    fullName: "TAPAN KUMAR RAY",
    saID: "SPR3929",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "CHAKRABARTY",
    address: "VILL-PRATAPGANJ*PO-BARUNDANGA*DT-DHUBRI 783334",
    fullName: "UPENDRA NATH CHAKRABARTY",
    saID: "SPR3930",
  },
  {
    firstName: "LATE PRONOBESH",
    lastName: "LAHIRI",
    address: "AT/PO-GOURIPUR WARD NO-I*DIST-DHUBRI-783331",
    fullName: "LATE PRONOBESH LAHIRI",
    saID: "SPR3931",
  },
  {
    firstName: "LATE SUSANTA KUMAR",
    lastName: "BORA",
    address: "AT-KUMARGAON*PO-TEZPUR*DT-SONITPUR",
    fullName: "LATE SUSANTA KUMAR BORA",
    saID: "SPR3932",
  },
  {
    firstName: "LATE SUKHLAL",
    lastName: "MALAKAR",
    address: "PO-HALAHALI*DHALAI*NORTH TRIPURA",
    fullName: "LATE SUKHLAL MALAKAR",
    saID: "SPR3933",
  },
  {
    firstName: "SUBODH KUMAR",
    lastName: "DAS",
    address: "19/6,HASNA BIBI LANE*SALKIA*HOWRAH-711106",
    fullName: "SUBODH KUMAR DAS",
    saID: "SPR3934",
  },
  {
    firstName: "YOGAKAR",
    lastName: "JHA",
    address: "PROFESSOR'S COLONY*PO-BILASHI TOWN*DEOGHAR*PAN-ABVPJ9325R",
    fullName: "YOGAKAR JHA",
    saID: "SPR3935",
  },
  {
    firstName: "ACHINTYA KUMAR",
    lastName: "BHATTACHARJEE",
    address: "*SATSANG 814116",
    fullName: "ACHINTYA KUMAR BHATTACHARJEE",
    saID: "SPR3936",
  },
  {
    firstName: "SUJIT KUMAR",
    lastName: "BISWAS",
    address:
      "DEPT OF MEDICINE*SILCHAR MEDICAL COLLEGE*SILCHAR 788 014*PAN-ABVPB0014F",
    fullName: "SUJIT KUMAR BISWAS",
    saID: "SPR3937",
  },
  {
    firstName: "LATE ANANTA",
    lastName: "MAHARAJ",
    address: "BANGLADESH",
    fullName: "LATE ANANTA MAHARAJ",
    saID: "SPR3938",
  },
  {
    firstName: "PRODYUT KUMAR",
    lastName: "ADHIKARI",
    address: "GAURIPUR WARD NO-1*PO-GAURIPUR-783331*DHUBRI",
    fullName: "PRODYUT KUMAR ADHIKARI",
    saID: "SPR3939",
  },
  {
    firstName: "RAM PRASAD",
    lastName: "PAL",
    address:
      "WEST BENGAL SATSANG CENTRE*68, SURYA SEN STREET*KOLKATA-700009*PAN-AORPP4157N",
    fullName: "RAM PRASAD PAL",
    saID: "SPR3940",
  },
  {
    firstName: "LATE ANATHBANDHU",
    lastName: "MUKHERJEE",
    address: "*HOWRAH",
    fullName: "LATE ANATHBANDHU MUKHERJEE",
    saID: "SPR3941",
  },
  {
    firstName: "AMITADITYA",
    lastName: "SANYAL",
    address: "C/O ASITADITYA SANYAL*SATSANG-814116.",
    fullName: "AMITADITYA SANYAL",
    saID: "SPR3942",
  },
  {
    firstName: "GURU PRASAD",
    lastName: "RAY",
    address: "UTTAR BHAWANIPUR*PALPARA*PO-CHAKDAHA*NADIA",
    fullName: "GURU PRASAD RAY",
    saID: "SPR3943",
  },
  {
    firstName: "AMIYA KUMAR",
    lastName: "RAY",
    address: "VILL-SOUTH TOKRERCHARA*PO-GOLAKGANJ*DHUBRI-783334*ASSAM",
    fullName: "AMIYA KUMAR RAY",
    saID: "SPR3944",
  },
  {
    firstName: "ANIRUDHA",
    lastName: "RAY",
    address: "KUMARGRAM DUAR ROAD*BAROBISHA*ALIPURDUAR-736207.",
    fullName: "ANIRUDHA RAY",
    saID: "SPR3945",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "BARMAN",
    address: "*SATSANG 814116",
    fullName: "MANINDRA NATH BARMAN",
    saID: "SPR3946",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "PRAMANICK",
    address: "VILL-BAISHNABPARA*PO-MOMENPUR 742303*MURSHIDABAD*PAN-AGLPP2491B",
    fullName: "SUNIL KUMAR PRAMANICK",
    saID: "SPR3947",
  },
  {
    firstName: "KAMAL",
    lastName: "SARKAR",
    address: '"NEW HOUSE"*PURANDAHA*DEOGHAR',
    fullName: "KAMAL SARKAR",
    saID: "SPR3948",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SAU",
    address: "VILL&PO-BAIDYAPUR*DIST-BARDDHAMAN-713122.*PAN APUPS0180G",
    fullName: "SURENDRA NATH SAU",
    saID: "SPR3949",
  },
  {
    firstName: "BHABESH CHANDRA",
    lastName: "DANDAPAT",
    address:
      "VILL-MAHATABPUR*PO-MEDINIPUR*DIST-PASCHIM MEDINIPUR 721101*PAN-AMSPD0960G",
    fullName: "BHABESH CHANDRA DANDAPAT",
    saID: "SPR3950",
  },
  {
    firstName: "LATE HARAPRASAD",
    lastName: "MUKHARJEE",
    address: "VILL&PO-GORALGACHA*HOOGHLY",
    fullName: "LATE HARAPRASAD MUKHARJEE",
    saID: "SPR3951",
  },
  {
    firstName: "SANTI BALLAV",
    lastName: "PODDAR",
    address: "BARANILPUR(NATUNPARA)*BURDWAN 713103",
    fullName: "SANTI BALLAV PODDAR",
    saID: "SPR3952",
  },
  {
    firstName: "GOURHARI",
    lastName: "BERA",
    address:
      "DIGHA SATSANG MANDIR*PO-DIGHA*PURBA MEDINIPUR-721428*PAN-AFXPB6898P",
    fullName: "GOURHARI BERA",
    saID: "SPR3953",
  },
  {
    firstName: "GOPAL CHARAN",
    lastName: "DAS",
    address: "VILL-DHANISHO*PO-BAIGANI*VIA-BALIKUDA*JAGATSINGHPUR-754108",
    fullName: "GOPAL CHARAN DAS",
    saID: "SPR3954",
  },
  {
    firstName: "SASANKA SEKHAR",
    lastName: "ACHARYA",
    address: "ADANG BAZAR*PO-SORO*BALASORE-756045*PAN-ADMPA6440P",
    fullName: "SASANKA SEKHAR ACHARYA",
    saID: "SPR3955",
  },
  {
    firstName: "CHAITANYA",
    lastName: "BISI",
    address:
      '"RUTAYAN"*N S C B COLLEGE CHOWK*PO-DHANUPALI*SAMBALPUR-768005*PAN-AGHPB9311Q',
    fullName: "CHAITANYA BISI",
    saID: "SPR3956",
  },
  {
    firstName: "DEEPAK KUMAR",
    lastName: "DAS",
    address:
      "HOUSE NO-FL/170*BASANTI COLONY*PO-ROURKELA-12*SUNDARGARH*PAN-APEPD0020Q",
    fullName: "DEEPAK KUMAR DAS",
    saID: "SPR3957",
  },
  {
    firstName: "PITAMBAR",
    lastName: "SAMAL",
    address: "OTR NO C-339*AT/PO-NALCO NAGAR*ANGUL-759145*PAN-ALHPS0282Q",
    fullName: "PITAMBAR SAMAL",
    saID: "SPR3958",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "PADHI",
    address:
      "NEAR SEVA  SIKSHYA NIKETAN*BIDANASI*PO-AVINAVA BIDANASI*CUTTACK 753014",
    fullName: "SANTOSH KUMAR PADHI",
    saID: "SPR3959",
  },
  {
    firstName: "AMBUJ KUMAR",
    lastName: "SHRIVASTAVA",
    address:
      "HOUSE NO-147*VILL-MATKAM HATU*PO-CHAIBASA*WEST SINGBHUM-833201*PAN-EHMPSS5424K",
    fullName: "AMBUJ KUMAR SHRIVASTAVA",
    saID: "SPR3960",
  },
  {
    firstName: "LATE PUNYA PRASAD",
    lastName: "SHARMA",
    address: "AT-DIPATALA*PO-KHARSANTALA*SONITPUR 784189",
    fullName: "LATE PUNYA PRASAD SHARMA",
    saID: "SPR3961",
  },
  {
    firstName: "AMARENDRA NATH",
    lastName: "SARMA",
    address: "VILL&PO-CHAPRAKATA*DIST-BONGAIGAON 783 380",
    fullName: "AMARENDRA NATH SARMA",
    saID: "SPR3962",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "MONDAL",
    address:
      "SATSANG VIHAR TAMLUK*PO-TAMLUK*DIST-PURBA MEDINIPUR-721636*PAN-ADSPM3287A",
    fullName: "ANANTA KUMAR MONDAL",
    saID: "SPR3963",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "PANDEY",
    address: "PURNASHRAM PURANDAH*PO-DEOGHAR 814112*PAN ALMIT2900D",
    fullName: "DILIP KUMAR PANDEY",
    saID: "SPR3964",
  },
  {
    firstName: "LATE GOURI SANKAR",
    lastName: "CHANDRA",
    address: '"ABHISRI"*PO-DULMI*PURULIA-2',
    fullName: "LATE GOURI SANKAR CHANDRA",
    saID: "SPR3965",
  },
  {
    firstName: "LATE ROY CHANDRESHWAR PD",
    lastName: "SARMA",
    address: '(ADVOCATE)*"ALKA BHAWAN",PURANDAHA*DEOGHAR',
    fullName: "LATE ROY CHANDRESHWAR PD SARMA",
    saID: "SPR3966",
  },
  {
    firstName: "MANOMOHAN",
    lastName: "DEY",
    address: "*SATSANG 814116",
    fullName: "MANOMOHAN DEY",
    saID: "SPR3967",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "GOSWAMI",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE SATISH CHANDRA GOSWAMI",
    saID: "SPR3968",
  },
  {
    firstName: "JANMENJOY",
    lastName: "JANA",
    address: "AT&PO-DAKSHIN KALYANPUR*PURBA MEDINIPUR-721455*PAN-BCXPJ6784J",
    fullName: "JANMENJOY JANA",
    saID: "SPR3969",
  },
  {
    firstName: "LATE SRI SRI MAA",
    lastName: "",
    address: "SATSANG",
    fullName: "LATE SRI SRI MAA ",
    saID: "SPR3970",
  },
  {
    firstName: "CHANDAN",
    lastName: "ACHERJEE",
    address: "VILL-KALISHAR*PS-PATIYA*DIST-CHITTAGONG*BANGLADESH",
    fullName: "CHANDAN ACHERJEE",
    saID: "SPR3971",
  },
  {
    firstName: "SITESH",
    lastName: "MEDHI",
    address: "SATSANG COLONY*JYOTI NAGAR*DIBRUGARH-786001*ASSAM",
    fullName: "SITESH MEDHI",
    saID: "SPR3972",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "KUNDU",
    address:
      "VILL-CHIKNIKANDI*PO-COATKHALI*PS-GALACHIPA*DIST-PATUYAKHALI*BANGLADESH",
    fullName: "GOBINDA CHANDRA KUNDU",
    saID: "SPR3973",
  },
  {
    firstName: "KANTI BHUSAN",
    lastName: "MAZUMDER",
    address: "PAGLA HIGH SCHOOL*KUTUBPUR*FATULLA*NARAYANGANJ*BANGLADESH",
    fullName: "KANTI BHUSAN MAZUMDER",
    saID: "SPR3974",
  },
  {
    firstName: "HIRENDRA KUMAR",
    lastName: "MITRA",
    address: "SATSANG VIHAR,BADLAPUR*HENDREPADA*PO-KULGAON*DIST-THANE 421503",
    fullName: "HIRENDRA KUMAR MITRA",
    saID: "SPR3975",
  },
  {
    firstName: "RAJESH YASHVANT",
    lastName: "BORSE",
    address:
      '"AAI" BUILDING,IST FLLOR*SANE GURUJIPATH,SHIVAJI NAGAR*NAUPADA*THANE-400602',
    fullName: "RAJESH YASHVANT BORSE",
    saID: "SPR3976",
  },
  {
    firstName: "VIIVECK V",
    lastName: "SHETTY",
    address:
      "27,GURUBHAKTI NIWAS,4TH FLOOR*GURUMANDIR ROAD*DOMBIVLI(E)*THANE-421201",
    fullName: "VIIVECK V SHETTY",
    saID: "SPR3977",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "DAS",
    address: "NORTH JAGTOLA*PO-MAHESHTOLA*S-24PARGANAS-700141*PAN-ADRPD6324Q",
    fullName: "GOPAL CHANDRA DAS",
    saID: "SPR3978",
  },
  {
    firstName: "LATE SHYAMALENDU BHUSAN",
    lastName: "PAUL",
    address: "HAGJER NAGAR*PO-HAFLONG*DIMA HASAO*PAN-BLJPP7915G",
    fullName: "LATE SHYAMALENDU BHUSAN PAUL",
    saID: "SPR3979",
  },
  {
    firstName: "RAJENDRA NATH",
    lastName: "NARZARY",
    address: "VILL/PO-JARAGURI*VIA-SRIRAMPUR*KOKRAJHAR-783361*PAN-APIPN7519L.",
    fullName: "RAJENDRA NATH NARZARY",
    saID: "SPR3980",
  },
  {
    firstName: "PADMADHAR",
    lastName: "DAS",
    address: "VILL/PO-BHIMPARA BALIJAN*LAKHIMPUR 787056.",
    fullName: "PADMADHAR DAS",
    saID: "SPR3981",
  },
  {
    firstName: "DEBASISH",
    lastName: "CHATTERJEE",
    address: "VILL/PO-BALUHATI*VIA-DOMJUR*HOWRAH-711405",
    fullName: "DEBASISH CHATTERJEE",
    saID: "SPR3982",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "DUTTA",
    address: "131/B,CHATRA BARUI PARA LANE*PO-SERAMPORE*HOOGHLY-712201",
    fullName: "GOBINDA CHANDRA DUTTA",
    saID: "SPR3983",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "PAUL",
    address: "C/O SOUND & SIGNAL HOUSE*PO-MANKACHAR*DHUBRI",
    fullName: "LATE MANORANJAN PAUL",
    saID: "SPR3984",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "RANJIT",
    address:
      "AT-BARO RAM MAITY BARH*PO-NAYAPUT*PS-CONTAI*PURBA MEDINIPUR-721401",
    fullName: "DHIRENDRA NATH RANJIT",
    saID: "SPR3985",
  },
  {
    firstName: "KANKAN",
    lastName: "MAITI",
    address: "21/A KALIDAS SINGHA LANE*CALCUTTA 700009",
    fullName: "KANKAN MAITI",
    saID: "SPR3986",
  },
  {
    firstName: "PULIN",
    lastName: "KAIRI",
    address: "PO-CHAND BASA*VIA-NEMUGURI*SIBSAGAR 785674",
    fullName: "PULIN KAIRI",
    saID: "SPR3987",
  },
  {
    firstName: "LATE LAKSHMAN CHANDRA",
    lastName: "BHAKAT",
    address: "VILL-LOKEPUR*PO-BANKURA*BANKURA",
    fullName: "LATE LAKSHMAN CHANDRA BHAKAT",
    saID: "SPR3988",
  },
  {
    firstName: "RAKHAL CHANDRA",
    lastName: "SHIL",
    address: "CHHANBAN*PO-RAJARBAG*SOUTH TRIPURA-799014",
    fullName: "RAKHAL CHANDRA SHIL",
    saID: "SPR3989",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "DEY",
    address: "*SATSANG 814116",
    fullName: "PRAKASH CHANDRA DEY",
    saID: "SPR3990",
  },
  {
    firstName: "AMAL KUMAR",
    lastName: "RANA",
    address:
      "SATSANG VIHAR*KADAMKANAN*PO-JHARGRAM*PASCHIM MEDINIPUR 721507*PAN-APDPR8156A",
    fullName: "AMAL KUMAR RANA",
    saID: "SPR3991",
  },
  {
    firstName: "BANKANIDHI",
    lastName: "PANDA",
    address: "AT-BEDPUR*PO-BANKI*CUTTACK-754008",
    fullName: "BANKANIDHI PANDA",
    saID: "SPR3992",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "MUKHERJEE",
    address: "DOCTOR'S LODGE*RAJNARAYAN BOSE RD*SATSANG,*DEOGHAR",
    fullName: "LATE SANTOSH KUMAR MUKHERJEE",
    saID: "SPR3993",
  },
  {
    firstName: "LATE DIPCHAND",
    lastName: "TAPADAR",
    address: "166K ROYPUR ROAD*KOLKATA-47.",
    fullName: "LATE DIPCHAND TAPADAR",
    saID: "SPR3994",
  },
  {
    firstName: "RABI NARAYAN",
    lastName: "RANA",
    address: "SATSANG VIHAR*AT/PO-RAJNILGIRI*BALASORE-756040",
    fullName: "RABI NARAYAN RANA",
    saID: "SPR3995",
  },
  {
    firstName: "LATE MADAN MOHAN",
    lastName: "MOHAPATRA",
    address: "VILL-MANSING BAZAR*PO-MOTIGANJ*DIST-BALASORE 756003",
    fullName: "LATE MADAN MOHAN MOHAPATRA",
    saID: "SPR3996",
  },
  {
    firstName: "TRIPAN KUMAR",
    lastName: "BHATTACHARJEE",
    address:
      "C/O LT RAGHUNATH BHATTACHERJEE*SATSANG ROAD*PO/DIST-SILCHAR-788007*(ASSAM)",
    fullName: "TRIPAN KUMAR BHATTACHARJEE",
    saID: "SPR3997",
  },
  {
    firstName: "KRISHNA PADA",
    lastName: "DEB",
    address: "*SATSANG 814116",
    fullName: "KRISHNA PADA DEB",
    saID: "SPR3998",
  },
  {
    firstName: "GOVINDA CHANDRA",
    lastName: "SAMANTARAY",
    address:
      "QR NO-N/7*RAJABAGICHA LABOUR COLONY*PO/DIST-CUTTACK-753009*PAN-BJVPS8373P",
    fullName: "GOVINDA CHANDRA SAMANTARAY",
    saID: "SPR3999",
  },
  {
    firstName: "LATE RAMESWAR",
    lastName: "SARMA",
    address: "VILL-ISMAILCHAK*PO-GOBINDCHAK*CHAPRA 841101",
    fullName: "LATE RAMESWAR SARMA",
    saID: "SPR4000",
  },
  {
    firstName: "SHIVANAND",
    lastName: "DUBEY",
    address: "VILL-SAULI*PO-JAMUA BAZAR*DIST-MIRZAPUR*U.P.-231314",
    fullName: "SHIVANAND DUBEY",
    saID: "SPR4001",
  },
  {
    firstName: "LATE RANJIT KUMAR",
    lastName: "MANDAL",
    address: "VILL/PO-KOTALPUR*BANKURA",
    fullName: "LATE RANJIT KUMAR MANDAL",
    saID: "SPR4002",
  },
  {
    firstName: "LATE JAGAT NARAYAN",
    lastName: "SINGH",
    address: "JAMTARA*DEOGHAR",
    fullName: "LATE JAGAT NARAYAN SINGH",
    saID: "SPR4003",
  },
  {
    firstName: "BASUDEV",
    lastName: "CHATTERJEE",
    address:
      "SATSANG THAKUR BUNGLOW*PO-SATSANG*DIST-DEOGHAR*(JHARKHAND)-814116.*PAN-AIOPC0780B.",
    fullName: "BASUDEV CHATTERJEE",
    saID: "SPR4004",
  },
  {
    firstName: "LATE KANAILAL",
    lastName: "GANGULY",
    address: "CALCUTTA",
    fullName: "LATE KANAILAL GANGULY",
    saID: "SPR4005",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "*SATSANG 814116",
    fullName: "NARAYAN CHANDRA BHATTACHARJEE",
    saID: "SPR4006",
  },
  {
    firstName: "PUJANIYA KAMALA",
    lastName: "DEBI",
    address: "C/O SJ AMARENDRA NATH CHAKRABORTY*SATSANG 814116",
    fullName: "PUJANIYA KAMALA DEBI",
    saID: "SPR4007",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "GANGULY",
    address:
      "VILL-JHANDABAD*PO-AMRAI*VIA-DURGAPUR-3*BARDHAMAN 713 203*PAN-APPPG6717G",
    fullName: "CHITTARANJAN GANGULY",
    saID: "SPR4008",
  },
  {
    firstName: "LATE KAMALAKSHA",
    lastName: "SARKAR",
    address: "5A/15 SECTOR-VII,BHILAI NAGAR*M.P.",
    fullName: "LATE KAMALAKSHA SARKAR",
    saID: "SPR4009",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "GHOSH",
    address: "PO/VILL-KULTIKRI*PASCHIM MEDINIPUR-721135*PAN--ADJPG9642G",
    fullName: "JATINDRA NATH GHOSH",
    saID: "SPR4010",
  },
  {
    firstName: "ROHITOSH",
    lastName: "MUHURI",
    address: "*SATSANG 814116*PAN-CJKPM3634F",
    fullName: "ROHITOSH MUHURI",
    saID: "SPR4011",
  },
  {
    firstName: "PULIN CHANDRA",
    lastName: "NEOG",
    address:
      "C/O-SATSANG VIHAR DIBRUGARH*NALIAPOOL, DURGABARI*DIBRUGARH-786001*PAN-AFKPN5338K",
    fullName: "PULIN CHANDRA NEOG",
    saID: "SPR4012",
  },
  {
    firstName: "MADHAB CHANDRA",
    lastName: "DAS",
    address: "HETAMPUR RAJBARI*BELABAGAN*PO-DEOGHAR*DEOGHAR-814116",
    fullName: "MADHAB CHANDRA DAS",
    saID: "SPR4013",
  },
  {
    firstName: "CHANDI CHARAN",
    lastName: "MAHATO",
    address: "AT/PO-SILDA*PASCHIM MEDINIPUR-721515*PAN-CXTPM6649D",
    fullName: "CHANDI CHARAN MAHATO",
    saID: "SPR4014",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "DAS",
    address: "97,PIPE ROAD*PO-BARRACKPORE*24PARGANAS (NORTH)-700120",
    fullName: "KARTICK CHANDRA DAS",
    saID: "SPR4015",
  },
  {
    firstName: "KARUNA KANTA",
    lastName: "SEN",
    address:
      "SATSANG ASHRAM ROAD*PO-VIVEKANANDA ROAD*DIST-SILCHAR*ASSAM 788 007",
    fullName: "KARUNA KANTA SEN",
    saID: "SPR4016",
  },
  {
    firstName: "ASHOK",
    lastName: "GHOSEDASTIDAR",
    address: "GABHA PRESS*PO-RANGAPARA*SONITPUR 784505",
    fullName: "ASHOK GHOSEDASTIDAR",
    saID: "SPR4017",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "GHOSH",
    address: "VILL-SADIYALER KUTI*PO-CHOUDHURYHAT*DT-COOCH BEHAR",
    fullName: "NARAYAN CHANDRA GHOSH",
    saID: "SPR4018",
  },
  {
    firstName: "NIRMAL KUMAR",
    lastName: "MANDAL",
    address: "PO/VILL-BARANACHINA*DIST-COOCH BEHAR-736135*PAN AHNPM9344H",
    fullName: "NIRMAL KUMAR MANDAL",
    saID: "SPR4019",
  },
  {
    firstName: "LATE RUPENDRA",
    lastName: "DAULAGUPHU",
    address: "AMLAPATTI*NEAR SATSANG VIHAR*PO-DIPHU*KARBIANGLONG",
    fullName: "LATE RUPENDRA DAULAGUPHU",
    saID: "SPR4020",
  },
  {
    firstName: "BHABEN",
    lastName: "TANTI",
    address: "WOKHA TEA ESTATE*PO-FURKATING*GOLAGHAT 785603.",
    fullName: "BHABEN TANTI",
    saID: "SPR4021",
  },
  {
    firstName: "LATE TARUN KUMAR",
    lastName: "MOITRA",
    address: "SANTIGARH*PO-SHYAMNAGAR*24PARGANAS NORTH 743127.",
    fullName: "LATE TARUN KUMAR MOITRA",
    saID: "SPR4022",
  },
  {
    firstName: "LATE KAMAL",
    lastName: "DEBNATH",
    address: "JAYPUR*NUTANBAZAR*DIBRUGARH",
    fullName: "LATE KAMAL DEBNATH",
    saID: "SPR4023",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "DASH",
    address: "C/O R N DASH*AT-PATTAMUNDAI PHC*PATTAMUNDAI*KENDRAPARA-754 215",
    fullName: "RABINDRA NATH DASH",
    saID: "SPR4024",
  },
  {
    firstName: "LATE PRAMATHA",
    lastName: "GANGULY",
    address: "*24PARGANAS",
    fullName: "LATE PRAMATHA GANGULY",
    saID: "SPR4025",
  },
  {
    firstName: "SAMIR",
    lastName: "BANDOPADHYAY",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "SAMIR BANDOPADHYAY",
    saID: "SPR4026",
  },
  {
    firstName: "RAVINDRA KISHORE",
    lastName: "SAHAY",
    address:
      "SARASWATI SADAN*AT-BAGMALI(LALGANJ ROAD)*PO-HAJIPUR*DIST-VAISALI-844101*BIHAR",
    fullName: "RAVINDRA KISHORE SAHAY",
    saID: "SPR4027",
  },
  {
    firstName: "BIBHU",
    lastName: "PRASAD",
    address: "101 BLOCK A;VIVEKANAND MARG*BORING ROAD*PATNA 13*PAN.AHWPP9784H",
    fullName: "BIBHU PRASAD",
    saID: "SPR4028",
  },
  {
    firstName: "LATE AWADHESH KUMAR",
    lastName: "SINGH",
    address: "ADVOCATE*AT--DAHIWATOLA*NORTH OF RLY. LINE*PO-CHAPRA*DIST-SARAN.",
    fullName: "LATE AWADHESH KUMAR SINGH",
    saID: "SPR4029",
  },
  {
    firstName: "RAMCHANDRA",
    lastName: "CHAUDHURY",
    address: "VILL-GOVINDPUR*PO-LAHERIASARAI*DT-DARBHANGA-846 001*BIHAR",
    fullName: "RAMCHANDRA CHAUDHURY",
    saID: "SPR4030",
  },
  {
    firstName: "LATE SHYAM SUNDAR",
    lastName: "SINGH",
    address:
      "C/O RAM CHANDRA SINGH*NEW KARMIK NAGAR*PO-SARAIDHELLA*DIST-DHANBAD",
    fullName: "LATE SHYAM SUNDAR SINGH",
    saID: "SPR4031",
  },
  {
    firstName: "LATE DHRUWA NATH",
    lastName: "SINGH",
    address: "STATE DISPENSARY*GORKHA, CHAPRA*SARAN",
    fullName: "LATE DHRUWA NATH SINGH",
    saID: "SPR4032",
  },
  {
    firstName: "NIBARAN CHANDRA",
    lastName: "RAY",
    address: "RANGAMATI*PO-SUKURUTU*PURULIA-723127",
    fullName: "NIBARAN CHANDRA RAY",
    saID: "SPR4033",
  },
  {
    firstName: "LATE SITA RAM",
    lastName: "SINGH",
    address: "DOCTOR,WEST CHURCH ROAD*GAYA 823001",
    fullName: "LATE SITA RAM SINGH",
    saID: "SPR4034",
  },
  {
    firstName: "LATE BISHNUDEO",
    lastName: "NARAYAN",
    address: "MO-HAMIDGANJ*NEAR PIPAL SHIVA MANDIR*PO-DALTONGANJ*PALAMU",
    fullName: "LATE BISHNUDEO NARAYAN",
    saID: "SPR4035",
  },
  {
    firstName: "YOGENDRA PRASAD",
    lastName: "SA",
    address: "DEAN STUDENTS WELFARE OFFICE*BIHAR UNIVERSITY*MUZAFFARPUR",
    fullName: "YOGENDRA PRASAD SA",
    saID: "SPR4036",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "SARMA",
    address: "C/O C K SARMA*PATHSALA TOWN*BARPETA 781325.",
    fullName: "KESHAB CHANDRA SARMA",
    saID: "SPR4037",
  },
  {
    firstName: "SATYA CHARAN",
    lastName: "PARAMANICK",
    address: "*SATSANG 814116",
    fullName: "SATYA CHARAN PARAMANICK",
    saID: "SPR4038",
  },
  {
    firstName: "DWIJENDRA NATH",
    lastName: "SEN",
    address:
      '"SHASHWATA" KAMAL APT*IST FLOOR R N 101;NAGSEN SOCIETY*PO-MANKAPUR*NAGPUR 440030',
    fullName: "DWIJENDRA NATH SEN",
    saID: "SPR4039",
  },
  {
    firstName: "SANTANU",
    lastName: "BHATTACHARJEE",
    address: "VILL-TRILOCHANPUR*PO-SIJGERIA*PASCHIM MEDINIPUR 721139.",
    fullName: "SANTANU BHATTACHARJEE",
    saID: "SPR4040",
  },
  {
    firstName: "MILAN",
    lastName: "MUKHARJEE",
    address: "*SATSANG 814116",
    fullName: "MILAN MUKHARJEE",
    saID: "SPR4041",
  },
  {
    firstName: "LATE RABINDRA NATH",
    lastName: "BANERJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE RABINDRA NATH BANERJEE",
    saID: "SPR4042",
  },
  {
    firstName: "R NARASIMHA",
    lastName: "RAMALU",
    address: "11-1-513, AGAPURA*PRAKASHNAGAR*HYDERABAD-500001.",
    fullName: "R NARASIMHA RAMALU",
    saID: "SPR4043",
  },
  {
    firstName: "K BALAKRISHNA",
    lastName: "",
    address: "H NO-1-7-80318,MOHANNAGAR*MUSHRABAD*HYDERABAD 500048",
    fullName: "K BALAKRISHNA ",
    saID: "SPR4044",
  },
  {
    firstName: "LATE KHOKAN CHANDRA",
    lastName: "SANA",
    address: "VIL&PO-16 NO.CHITTARANJANPUR*VIA-ASHTI*GODCHIRALI*M.S.",
    fullName: "LATE KHOKAN CHANDRA SANA",
    saID: "SPR4045",
  },
  {
    firstName: "SUKUMAR",
    lastName: "CHOUDHURY",
    address: "SHIBTALI-CHAKBHABANI*PO-BALURGHAT*DAKSHIN DINAJPUR-733101",
    fullName: "SUKUMAR CHOUDHURY",
    saID: "SPR4046",
  },
  {
    firstName: "LATE G K",
    lastName: "ARJUN",
    address: "H NO-1-7-630/19/3/1*GEMINI COLONY*HYDERABAD 500048",
    fullName: "LATE G K ARJUN",
    saID: "SPR4047",
  },
  {
    firstName: "SUNIL MOHAN",
    lastName: "DAS",
    address: "SATSANG ROAD, SILCHAR*PO-VIVEKANANDA RD*SILCHAR-788007.",
    fullName: "SUNIL MOHAN DAS",
    saID: "SPR4048",
  },
  {
    firstName: "ISHI PRASAD",
    lastName: "GHOSH",
    address: "AT/PO-BAROBAINAN*BURDWAN 713421",
    fullName: "ISHI PRASAD GHOSH",
    saID: "SPR4049",
  },
  {
    firstName: "NRIPENDRA CHANDRA",
    lastName: "SARKAR",
    address: "VILL-SALAIMARI*PO-KHOIRABARI*DIST-UDALGURI-784522*B.T.A.D*ASSAM",
    fullName: "NRIPENDRA CHANDRA SARKAR",
    saID: "SPR4050",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "SADHUKHAN",
    address: "VILL-SABDALPUR*PO-ARANG GHATA*NADIA-741501",
    fullName: "SUNIL KUMAR SADHUKHAN",
    saID: "SPR4051",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "BASUNIA",
    address: "PO-DEWANHAT*COOCHBIHAR-736134",
    fullName: "RABINDRA NATH BASUNIA",
    saID: "SPR4052",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "GOGOI",
    address: "MERELI PATHAR*PO-CHABUA*DIBRUGARH 786 184",
    fullName: "ATUL CHANDRA GOGOI",
    saID: "SPR4053",
  },
  {
    firstName: "PRANBALLAV",
    lastName: "SAHA",
    address: "SATSANG VIHAR ALIPURDUAR NEW TOWN*JALPAIGURI-736123",
    fullName: "PRANBALLAV SAHA",
    saID: "SPR4054",
  },
  {
    firstName: "LATE SASANKA KIRAN",
    lastName: "GHOSH",
    address: "33/1,CANAL PATTI*CALCUTTA 10",
    fullName: "LATE SASANKA KIRAN GHOSH",
    saID: "SPR4055",
  },
  {
    firstName: "JAGABANDHU",
    lastName: "BANDOPADHYAY",
    address: "VILL&PO-AJODHYA*BANKURA-722163",
    fullName: "JAGABANDHU BANDOPADHYAY",
    saID: "SPR4056",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "RAY",
    address: "AT/PO-DHARAMTUL*DIST-MARIGAON-782412*ASSAM",
    fullName: "DILIP KUMAR RAY",
    saID: "SPR4057",
  },
  {
    firstName: "AMULYA KUMAR",
    lastName: "GOSWAMI",
    address: "VILL-BEZKUCHI*PO-PATACHARKUCHI*DIST-BARPETA-781326*ASSAM",
    fullName: "AMULYA KUMAR GOSWAMI",
    saID: "SPR4058",
  },
  {
    firstName: "RANJIT",
    lastName: "CHAKRABORTY",
    address: "VILL-CHAIPANI*PO-KILLAPARA*WEST GAROHILLS*MEGHALAYA-794103",
    fullName: "RANJIT CHAKRABORTY",
    saID: "SPR4059",
  },
  {
    firstName: "KALIPADA",
    lastName: "BISWAS",
    address: "VILL-STATIONPARA(B F C NORTH)*PO-BETHUADAHARI*NADIA-741126",
    fullName: "KALIPADA BISWAS",
    saID: "SPR4060",
  },
  {
    firstName: "LATE JOYDEB",
    lastName: "SARKAR",
    address: "251 FULIA COLONY*PO-FULIA COLONY*NADIA",
    fullName: "LATE JOYDEB SARKAR",
    saID: "SPR4061",
  },
  {
    firstName: "SAMARENDRA NATH",
    lastName: "ADAK",
    address: "DEYA APARTMENT(2ND FLOOR)*STATION ROAD*DANKUNI*HOOGHLY-712311",
    fullName: "SAMARENDRA NATH ADAK",
    saID: "SPR4062",
  },
  {
    firstName: "AMULLYA KUMAR",
    lastName: "GHOSE",
    address:
      'NISHIT SARANI*"MOHAN BATI"*PO-RAIGANJ*UTTAR DINAJPUR 733134.*PAN-AFCPG7011A',
    fullName: "AMULLYA KUMAR GHOSE",
    saID: "SPR4063",
  },
  {
    firstName: "TARUN KUMAR",
    lastName: "PAUL",
    address: "SATSANG BINAPANI VIDYAMANDIR*SATSANG",
    fullName: "TARUN KUMAR PAUL",
    saID: "SPR4064",
  },
  {
    firstName: "CHATRASINGH",
    lastName: "RAMCHIARI",
    address: "VILL-PARBAHUCHUBA*PO-BHERGAON*UDALGURI-784522*ASSAM",
    fullName: "CHATRASINGH RAMCHIARI",
    saID: "SPR4065",
  },
  {
    firstName: "SUBIR KANTI",
    lastName: "DAS",
    address:
      "SWASTI HOMOEO HALL*DASMIGHAT WEST JOYNAGAR*AGARTALA*TRIPURA WEST 799001.",
    fullName: "SUBIR KANTI DAS",
    saID: "SPR4066",
  },
  {
    firstName: "RANENDRA",
    lastName: "PALCHOUDHURI",
    address:
      '"SHANTI NIWAS"*MAHILA SAMITTI ROAD*PO/DIST-HAILAKANDI 788151*ASSAM.',
    fullName: "RANENDRA PALCHOUDHURI",
    saID: "SPR4067",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "GHOSH",
    address:
      "C/O SHYAMA PADA GHOSH*VILL-KANCHMALI*PO-BOINCHEE*HOOGHLY 712134*PAN-ADPPG8498J",
    fullName: "RAM CHANDRA GHOSH",
    saID: "SPR4068",
  },
  {
    firstName: "LATE MAHESWAR",
    lastName: "GOALA",
    address: "VILL-RANGAMANI*PO-BADLIPAR*GOLAGHAT 785611",
    fullName: "LATE MAHESWAR GOALA",
    saID: "SPR4069",
  },
  {
    firstName: "LATE GIRIDHAR",
    lastName: "NARZARY",
    address: "SUPDT. OF TAXES,KOKRAJHAR*PO-KOKRAJHAR*KOKRAJHAR-783370.",
    fullName: "LATE GIRIDHAR NARZARY",
    saID: "SPR4070",
  },
  {
    firstName: "AMAL KANTI",
    lastName: "BHATTACHARJEE",
    address: "PO/VILL-RASHIDANGA II(GGM)*DT-COOCH BEHAR 736170*PAN-AEMPB2005C",
    fullName: "AMAL KANTI BHATTACHARJEE",
    saID: "SPR4071",
  },
  {
    firstName: "RANENDRA",
    lastName: "MALLICK",
    address:
      "C/O S K MODAK*F-16,SCHOOL ROAD*PO-SODEPUR*24 PARGANAS (NORTH)-700110*PAN-ADSPM8710E",
    fullName: "RANENDRA MALLICK",
    saID: "SPR4072",
  },
  {
    firstName: "NIBEDITO",
    lastName: "ROYCHAKRABARTY",
    address: "3/9 A P C PARK*BAGHAJATIN*KOLKATA 700086",
    fullName: "NIBEDITO ROYCHAKRABARTY",
    saID: "SPR4073",
  },
  {
    firstName: "SANTOSH",
    lastName: "SAHA",
    address:
      "WILLIAM NAGAR BAZAR*PO-WILLIAM NAGAR*EAST GARO HILLS, MEGHALAYA-794111.",
    fullName: "SANTOSH SAHA",
    saID: "SPR4074",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "GHOSE",
    address:
      "DESHBANDHUPALLY*PO-PURNANANDAPALLY*NAIHATI*24 PARGANAS (NORTH)-743165",
    fullName: "LATE JATINDRA NATH GHOSE",
    saID: "SPR4075",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "BHOI",
    address: "AT-JOGI SAHI*PO-F.C.PLANT*VIA-J K ROAD*JAJPUR 755020",
    fullName: "BHIKARI CHARAN BHOI",
    saID: "SPR4076",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "MANDAL",
    address: "VILL&PO-RAJPUR (BOSE PARA)*24 PARGANAS (SOUTH)-743358.",
    fullName: "BIRENDRA NATH MANDAL",
    saID: "SPR4077",
  },
  {
    firstName: "VIJAY NANDAN PRASAD",
    lastName: "SINHA",
    address: "QR NO-CD/939*BHAWNATHPUR TOWNSHIP*GARHWA-822114",
    fullName: "VIJAY NANDAN PRASAD SINHA",
    saID: "SPR4078",
  },
  {
    firstName: "LATE PRAMATHA NATH",
    lastName: "BISWAS",
    address: "SILCHAR*CACHAR",
    fullName: "LATE PRAMATHA NATH BISWAS",
    saID: "SPR4079",
  },
  {
    firstName: "BALESWAR PRASAD",
    lastName: "SINHA",
    address: '"ASHRAY"*NORTH KUSHAI*PO-DORANDA*RANCHI-834002*PAN-ADAPS5309P',
    fullName: "BALESWAR PRASAD SINHA",
    saID: "SPR4080",
  },
  {
    firstName: "JIBAN KRISHNA",
    lastName: "BARMAN",
    address: "SATSANG VIHAR,TURA*GARO HILLS*MEGHALAYA-794001.",
    fullName: "JIBAN KRISHNA BARMAN",
    saID: "SPR4081",
  },
  {
    firstName: "LATE SHANKAR KUMAR",
    lastName: "DEBNATH",
    address:
      "C/O DEVNATH OPTICAL HOUSE*HEC SECTOR 2 MARKET*PO-DHURWA*RANCHI 834004",
    fullName: "LATE SHANKAR KUMAR DEBNATH",
    saID: "SPR4082",
  },
  {
    firstName: "PREMANANDA",
    lastName: "DAS",
    address:
      "SATSANG VIHAR,SUNDARGARH*PO&DIST-SUNDARGARH-770001*PAN-ASXPD9983E",
    fullName: "PREMANANDA DAS",
    saID: "SPR4083",
  },
  {
    firstName: "GOPAL KRISHNA",
    lastName: "DEY",
    address: "MUCHIPARA G T RD(NEAR POLICE LINE*PO-BURDWAN*BURDWAN-713101",
    fullName: "GOPAL KRISHNA DEY",
    saID: "SPR4084",
  },
  {
    firstName: "LATE BANSHIDHAR",
    lastName: "PANDEY",
    address: "ASHOKPALLY*PO-NALHATI-731220*BIRBHUM",
    fullName: "LATE BANSHIDHAR PANDEY",
    saID: "SPR4085",
  },
  {
    firstName: "DIGESH CHANDRA",
    lastName: "NATH",
    address: "SAMAGURI BAZAR*VIA-BAKULIA GHAT*KARBIANGLONG 782482",
    fullName: "DIGESH CHANDRA NATH",
    saID: "SPR4086",
  },
  {
    firstName: "CHANDRA BHUSAN",
    lastName: "CHOWDHURY",
    address: "PO-SATGAON*AMSING JORABAT*GUWAHATI-781027*KAMRUP",
    fullName: "CHANDRA BHUSAN CHOWDHURY",
    saID: "SPR4087",
  },
  {
    firstName: "BINOD KUMAR",
    lastName: "JOSHI",
    address: "UPPER MOWPREM*SHILLONG*MEGHALAY-793002.",
    fullName: "BINOD KUMAR JOSHI",
    saID: "SPR4088",
  },
  {
    firstName: "RUPEN",
    lastName: "BASUMATARY",
    address: "VILL-DANKACHINGTU*P.O.-DENTAGHAT*KARBIANGLONG  782441*ASSAM",
    fullName: "RUPEN BASUMATARY",
    saID: "SPR4089",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "MONDAL",
    address: "VILL-TAFA*PO-SARISHA*24PARGANAS (SOUTH)-743368",
    fullName: "BIKASH CHANDRA MONDAL",
    saID: "SPR4090",
  },
  {
    firstName: "BENUDHAR",
    lastName: "DUTTA",
    address: "N S AVENUE*PO-SILCHAR 5*DIST-SILCHAR 788005*PAN AGDPD0285H",
    fullName: "BENUDHAR DUTTA",
    saID: "SPR4091",
  },
  {
    firstName: "SAILENDRA NATH",
    lastName: "CHAKRABARTY",
    address:
      "C/O SREEGURU BASTRALAYA*PO-NABAPALLY(BARASAT)*DT-24PARGANAS (NORTH)",
    fullName: "SAILENDRA NATH CHAKRABARTY",
    saID: "SPR4092",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "KUNDU",
    address: "13 H ,LAKE TERRACE*SANTOSHPUR*CALCUTTA 700075",
    fullName: "PARESH CHANDRA KUNDU",
    saID: "SPR4093",
  },
  {
    firstName: "LAL MADHAB",
    lastName: "MAHATO",
    address: "SATSANG GARRAGE*SATSANG 814116",
    fullName: "LAL MADHAB MAHATO",
    saID: "SPR4094",
  },
  {
    firstName: "HARISADHAN",
    lastName: "CHOUDHURY",
    address: "JAGADALLA*BANKURA",
    fullName: "HARISADHAN CHOUDHURY",
    saID: "SPR4095",
  },
  {
    firstName: "PARIMAL RANJAN",
    lastName: "DEYSARKAR",
    address:
      "C/49 ANNAPURNA APTT,BLOCK-C,FLAT-1*S K DEB ROAD*KOLKATA-700 048*PAN-AJJPS2921C",
    fullName: "PARIMAL RANJAN DEYSARKAR",
    saID: "SPR4096",
  },
  {
    firstName: "CHUNI LAL",
    lastName: "SAHA",
    address: "C/O JOYGURU STUDIO*PO-DUDHNOI*GOALPARA-783124*ASSAM",
    fullName: "CHUNI LAL SAHA",
    saID: "SPR4097",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "RAY",
    address: "C/O-NARAYAN CHANDRA ROY*NEAR POWER HOUSE*PO&DT-KOKRAJHAR-783370",
    fullName: "LATE NARAYAN CHANDRA RAY",
    saID: "SPR4098",
  },
  {
    firstName: "DILIP",
    lastName: "PAL",
    address:
      "18,SHAHID RANJAN DUTTA SARANI*PO-KONNAGAR*HOOGHLY-712235*PAN-CPKPP4954H",
    fullName: "DILIP PAL",
    saID: "SPR4099",
  },
  {
    firstName: "LATE PRODIP KUMAR",
    lastName: "BHATTACHARJEE",
    address: "LALGANESH*GREENWOOD LAND*PO-UDAL BAKHRA*GUWAHATI-781034*KAMRUP",
    fullName: "LATE PRODIP KUMAR BHATTACHARJEE",
    saID: "SPR4100",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "BISWAS",
    address:
      "42/3 MITRA PARA ROAD,SAILENDRA APPARTMENT*DAKSHIN PARA*PO-BARASAT*KOLKATA 700 124.",
    fullName: "PRAKASH CHANDRA BISWAS",
    saID: "SPR4101",
  },
  {
    firstName: "LATE ASHIT BARAN",
    lastName: "CHATTERJEE",
    address: "BASUKATI*NEAR MODEL SAINIK SCHOOL*PO-ANANDA NAGAR*HOWRAH 711227",
    fullName: "LATE ASHIT BARAN CHATTERJEE",
    saID: "SPR4102",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "DUTTA",
    address: "VILL-GANFULIA*PO-ALAMPUR*VIA-SRIKHANDA*BURDWAN 713150",
    fullName: "PABITRA KUMAR DUTTA",
    saID: "SPR4103",
  },
  {
    firstName: "BIKASH KUMAR",
    lastName: "MALLIK",
    address:
      "KANKSA MATHURDAS PARA*PO-KANKSA*VIA-PANAGARH BAZAR*BURDWAN 713148",
    fullName: "BIKASH KUMAR MALLIK",
    saID: "SPR4104",
  },
  {
    firstName: "V SURYANARAYANA",
    lastName: "MURTY",
    address:
      "DOOR NO 12-10-20*VEDULA VARI STREET*ANAKAPALLI-531001*ANDHRA PRADESH.",
    fullName: "V SURYANARAYANA MURTY",
    saID: "SPR4105",
  },
  {
    firstName: "ALOKE",
    lastName: "BISWAS",
    address: "FLAT NO-249*SECTOR-13,POCKET-A*DWARKA*NEW DELHI 110 075",
    fullName: "ALOKE BISWAS",
    saID: "SPR4106",
  },
  {
    firstName: "UTPAL",
    lastName: "GHOSH",
    address: "KL5/25 ROOM NO-14*SECTOR-2;KALAMBOLI*NEW MUMBAI*PAN-AIEPG7667H",
    fullName: "UTPAL GHOSH",
    saID: "SPR4107",
  },
  {
    firstName: "BATA KRUSHNA",
    lastName: "DAS",
    address: "AT-BADATRILOCHANPUR*PO-MASUDPUR*DIST-CUTTACK.",
    fullName: "BATA KRUSHNA DAS",
    saID: "SPR4108",
  },
  {
    firstName: "SRIKANTA",
    lastName: "GHOSE",
    address:
      "QRT. NO-615 D B.L.P.L. TOWNSHIP *PO-DHALIGAON*DIST-BONGAIGAON (ASSAM)*PAN-ACOPG950N",
    fullName: "SRIKANTA GHOSE",
    saID: "SPR4109",
  },
  {
    firstName: "LATE MUKUNDA",
    lastName: "HALOI",
    address: "VILL-BARSARKUCHI*PO-MILANPUR*DIST-NALBARI-781337*ASSAM",
    fullName: "LATE MUKUNDA HALOI",
    saID: "SPR4110",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "BARUA",
    address:
      "RATNAPUR BY LANE-12*PO-ITACHAL*NAGAON 782003(ASSAM)*PAN NO-AKXPV1415B",
    fullName: "DHIRENDRA NATH BARUA",
    saID: "SPR4111",
  },
  {
    firstName: "PRASANTA",
    lastName: "CHAKRABORTY",
    address: "GARALGACHHA PASCHIMPARA*PO-GARALGACHHA*HOOGHLY.",
    fullName: "PRASANTA CHAKRABORTY",
    saID: "SPR4112",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SAHA",
    address: "RABINDRA NAGAR*PO-CHAKDAHA*NADIA-741222",
    fullName: "ARUN KUMAR SAHA",
    saID: "SPR4113",
  },
  {
    firstName: "BANKIM",
    lastName: "SANTRA",
    address: "VILL&PO-SIMURALI*NADIA-741248*PAN-EDJPS2992R",
    fullName: "BANKIM SANTRA",
    saID: "SPR4114",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "DAS",
    address: "AT-KATWA(ATUHAT PARA)*PO-KATWA*BURDWAN 713130",
    fullName: "SANTOSH KUMAR DAS",
    saID: "SPR4115",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "HALDER",
    address: "VILL-DUTTABATI*PO-SITAHATI*BURDWAN-713130.*PAN- ABMPH9554R",
    fullName: "BIKASH CHANDRA HALDER",
    saID: "SPR4116",
  },
  {
    firstName: "RANJIT",
    lastName: "BARUAH",
    address: "BIHPURIA THANAR*LAKHIMPUR-784161*ASSAM",
    fullName: "RANJIT BARUAH",
    saID: "SPR4117",
  },
  {
    firstName: "SATYENDRA MOHAN",
    lastName: "SARKAR",
    address: "SATSANG SWASTI SIBIR*PO-AMRITI*MALDA-732208*PAN-DEMPS6914G",
    fullName: "SATYENDRA MOHAN SARKAR",
    saID: "SPR4118",
  },
  {
    firstName: "PRAMATHA RANJAN",
    lastName: "BISWAS",
    address: "VILL-THINAGAR*PO-PAKUAHAT*DIST-MALDA-732138",
    fullName: "PRAMATHA RANJAN BISWAS",
    saID: "SPR4119",
  },
  {
    firstName: "LAMBODAR",
    lastName: "SINGH",
    address: "VILL-BALIDIHA*PO-KESHIARA*DIST-BANKURA-722133",
    fullName: "LAMBODAR SINGH",
    saID: "SPR4120",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "PATRA",
    address: "AT/PO-BALANGA*DIST-PURI*ODISHA-752105",
    fullName: "PURNA CHANDRA PATRA",
    saID: "SPR4121",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MISHRA",
    address: "URJANA SECTION*PO-SATSANG*DEOGHAR  814116",
    fullName: "NIRANJAN MISHRA",
    saID: "SPR4122",
  },
  {
    firstName: "GOPINATH",
    lastName: "DEY",
    address: "JAMALDA SATSANG VIHAR*PO-JAMALDAHA*COOCH BIHAR-735303",
    fullName: "GOPINATH DEY",
    saID: "SPR4123",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "DAS",
    address:
      "VIVEKANANDA PALLY*OPP DAILY BAZAR*NALIAPOOL*PO/DIST-DIBRUGARH-786001*PAN-AGQPD4375D",
    fullName: "MRINAL KANTI DAS",
    saID: "SPR4124",
  },
  {
    firstName: "RANJIT",
    lastName: "RUDRAPAL",
    address: "VILL-TUICHINDRAI*PO-HOWAIBARI*TRIPURA-799205",
    fullName: "RANJIT RUDRAPAL",
    saID: "SPR4125",
  },
  {
    firstName: "RASARAJ",
    lastName: "SEN",
    address: "AT/PO-DUDHNAI*DIST-GOALPARA-783124*ASSAM",
    fullName: "RASARAJ SEN",
    saID: "SPR4126",
  },
  {
    firstName: "SUDHIR RANJAN",
    lastName: "BAKSHI",
    address: "SATSANG COLONY*NEAR FOREST OFFICE*SATANG 814116",
    fullName: "SUDHIR RANJAN BAKSHI",
    saID: "SPR4127",
  },
  {
    firstName: "SUKUMAR",
    lastName: "CHAKRABARTY",
    address: "SATSANG VIHAR DALKHOLA*PO-DALKHOLA*UTTAR DINAJPUR-733201",
    fullName: "SUKUMAR CHAKRABARTY",
    saID: "SPR4128",
  },
  {
    firstName: "DAMBARU",
    lastName: "CHHETRY",
    address:
      "ASSAM STATE HOUSING BOARD*PO-UTTAR LAKHIMPUR*DIST-LAKHIMPUR-787001*ASSAM",
    fullName: "DAMBARU CHHETRY",
    saID: "SPR4129",
  },
  {
    firstName: "BADAL",
    lastName: "MAITY",
    address: "VILL&PO-DAKSHIN CHATRA*24PARGANAS NORTH 743742",
    fullName: "BADAL MAITY",
    saID: "SPR4130",
  },
  {
    firstName: "PRABIR KUMAR",
    lastName: "MITRA",
    address: "VILL-KALIPUR*PO-P.N.PUR*24PARGANAS 743319",
    fullName: "PRABIR KUMAR MITRA",
    saID: "SPR4131",
  },
  {
    firstName: "ANANDA MOHAN",
    lastName: "GHOSE",
    address: "VILL/PO-ARAIDANGA*MALDA-732214",
    fullName: "ANANDA MOHAN GHOSE",
    saID: "SPR4132",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "MAITRI",
    address: "SATSANG SWASTI SIBIR*PO-KESIAKOLE*BANKURA-722155*PAN-BSJPM7936D",
    fullName: "DIPAK KUMAR MAITRI",
    saID: "SPR4133",
  },
  {
    firstName: "NRIPENDRA NATH",
    lastName: "GHOSH",
    address: "VILL-PANINALA*PO-BHANDARKHOLA*DT-NADIA-741103",
    fullName: "NRIPENDRA NATH GHOSH",
    saID: "SPR4134",
  },
  {
    firstName: "JOYDEB",
    lastName: "GHOSH",
    address: "VILL-MONBER*PO-MRIGALA*HOOGHLY-712311",
    fullName: "JOYDEB GHOSH",
    saID: "SPR4135",
  },
  {
    firstName: "LATE JIBAN",
    lastName: "CHAKRABORTY",
    address: "I.G.P.OFFICE*AGARTALA*TRIPURA-799001",
    fullName: "LATE JIBAN CHAKRABORTY",
    saID: "SPR4136",
  },
  {
    firstName: "JATIRAM",
    lastName: "BARMAN",
    address: "HOJAI NEW MARKET WARD NO-2*PO-HOJAI*DIST-NAGAON-782435*ASSAM",
    fullName: "JATIRAM BARMAN",
    saID: "SPR4137",
  },
  {
    firstName: "ARNAB KUMAR",
    lastName: "TAH",
    address: "SEHARA BAZAR*SEHARA-713423*BURDWAN",
    fullName: "ARNAB KUMAR TAH",
    saID: "SPR4138",
  },
  {
    firstName: "NITYANANDA",
    lastName: "DEBNATH",
    address: "DHALESWAR ROAD NO-2*PO-DHALESWAR*AGARTALA*TRIPURA WEST-799007",
    fullName: "NITYANANDA DEBNATH",
    saID: "SPR4139",
  },
  {
    firstName: "LATE DHANANJOY",
    lastName: "DEBANGSHI",
    address: "VIDYASAGARPALLY*PO-BOLPUR*BIRBHUM-731204",
    fullName: "LATE DHANANJOY DEBANGSHI",
    saID: "SPR4140",
  },
  {
    firstName: "SUNIL BARAN",
    lastName: "SINGHAMAHAPATRA",
    address: "VILL&PO-TILABANI(L.SAGAR)*BANKURA 722160",
    fullName: "SUNIL BARAN SINGHAMAHAPATRA",
    saID: "SPR4141",
  },
  {
    firstName: "CHANDRASEKHAR",
    lastName: "SEAL",
    address:
      "VILL-BIDHAN ROAD,GAYESHPUR*PO-JHALJHALIA*MALDA-732102*PAN- APBPS2915N",
    fullName: "CHANDRASEKHAR SEAL",
    saID: "SPR4142",
  },
  {
    firstName: "KHOKA RANJAN",
    lastName: "DEY",
    address: "ASHOK PALLY*PO-RANIGANJ*BURDWAN-713347*pan BQIPD6899M",
    fullName: "KHOKA RANJAN DEY",
    saID: "SPR4143",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "PAL",
    address:
      "BARDWALI H/S (2) SCHOOL*PO-A.D.NAGAR*AGARTALA*TRIPURA 799003*PAN-AECPP0571M",
    fullName: "DULAL CHANDRA PAL",
    saID: "SPR4144",
  },
  {
    firstName: "SUJIT KUMAR",
    lastName: "DEY",
    address: "JIRANIA CLOTH SHOP*PO-BIRENDRA NAGAR*TRIPURA-799045",
    fullName: "SUJIT KUMAR DEY",
    saID: "SPR4145",
  },
  {
    firstName: "GAJENDRA CHANDRA",
    lastName: "BRAHMA",
    address: "LECTURER,BHOLANATH COLLEGE*PO-BIDYAPARA*DHUBRI-783324*ASSAM",
    fullName: "GAJENDRA CHANDRA BRAHMA",
    saID: "SPR4146",
  },
  {
    firstName: "UMESH CHANDRA",
    lastName: "BASUMATARI",
    address: "KASHIKOTRA*DIST-CHIRANG-783373*B T A D*ASSAM",
    fullName: "UMESH CHANDRA BASUMATARI",
    saID: "SPR4147",
  },
  {
    firstName: "PARAMESH CHANDRA",
    lastName: "PAUL",
    address: "VILL-BASBARI PATHAR*PO-SANTIPARA*DIST-DIBRUGARH-786001*ASSAM",
    fullName: "PARAMESH CHANDRA PAUL",
    saID: "SPR4148",
  },
  {
    firstName: "LATE DEBDAS",
    lastName: "MUKHARJEE",
    address: "PO-BIJNI*KOKRAJHAR 783390",
    fullName: "LATE DEBDAS MUKHARJEE",
    saID: "SPR4149",
  },
  {
    firstName: "KALIPADA",
    lastName: "BAROI",
    address:
      "BISHNUPUR,KASHINAGAR ROAD*PO-GOPINATH NAGAR*GOUHATI-781016*KAMRUP",
    fullName: "KALIPADA BAROI",
    saID: "SPR4150",
  },
  {
    firstName: "LATE UPENDRA CHANDRA",
    lastName: "DAS",
    address:
      "C/O JOYGURU MEDICAL AGENCY*MUKAM ROAD,GUNIALA*SILCHAR 2*CACHAR-788002*ASSAM",
    fullName: "LATE UPENDRA CHANDRA DAS",
    saID: "SPR4151",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MAHATO",
    address: "VILL/PO-SUPURDIH*VIA-RANGADIH*PURULIA-723143",
    fullName: "RABINDRA NATH MAHATO",
    saID: "SPR4152",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "CHAKRABORTY",
    address: "BARUAPATTI, WORD NO-2*PO-GOURIPUR*DIST-DHUBRI.",
    fullName: "LATE JOGENDRA NATH CHAKRABORTY",
    saID: "SPR4153",
  },
  {
    firstName: "KRIPESH",
    lastName: "CHAKRABORTY",
    address:
      '"SWASTI DHAM"*M.A.MAJUMDER LANE*PANCHAYET ROAD*PO/DIST-SILCHAR-788 005*ASSAM',
    fullName: "KRIPESH CHAKRABORTY",
    saID: "SPR4154",
  },
  {
    firstName: "MUDADA JAGANNATH",
    lastName: "",
    address:
      "PLOT NO-1270(B)*RADHAKRUSHNA LANE*AT-SIRIPUR*BHUBANESWAR-751003*PAN-ADHPJ2662H",
    fullName: "MUDADA JAGANNATH ",
    saID: "SPR4155",
  },
  {
    firstName: "LATE BOYA",
    lastName: "CHAMPIYA",
    address:
      "AT&PO-HOROMOTO*DHANER JAYPUR*VIA-KIRIBURU HILL TOP*KEONJHAR(ORISSA)",
    fullName: "LATE BOYA CHAMPIYA",
    saID: "SPR4156",
  },
  {
    firstName: "PRANTIK KUMAR",
    lastName: "DUTTA",
    address: "MELKI*HOOGHLY 712149*PAN-CJSPD9943C",
    fullName: "PRANTIK KUMAR DUTTA",
    saID: "SPR4157",
  },
  {
    firstName: "HIRANMAY",
    lastName: "CHAKRABARTY",
    address:
      "C/O N.R.CHAKRABORTY*QR NO.ET/53 *PO-ARMAPORE ESTATE*KANPUR*U.P. 208009",
    fullName: "HIRANMAY CHAKRABARTY",
    saID: "SPR4158",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "MAHAPATRA",
    address: "AT-SATSANG VIHAR SUKINDA*PO-SUKINDA*JAJPUR-755018.",
    fullName: "GOBINDA CHANDRA MAHAPATRA",
    saID: "SPR4159",
  },
  {
    firstName: "TRINATH",
    lastName: "PARIDA",
    address: "AT/PO-RAMBHA*MAIN ROAD*GANJAM-761028",
    fullName: "TRINATH PARIDA",
    saID: "SPR4160",
  },
  {
    firstName: "KUNJA LAL",
    lastName: "ROY",
    address:
      "H/NO-255,2ND CROSS,F BLOCK,10TH MAIN*SAHAKARNAGAR*BANGALORE 560092",
    fullName: "KUNJA LAL ROY",
    saID: "SPR4161",
  },
  {
    firstName: "APARTHIB",
    lastName: "ROYCHAKRABARTY",
    address: "",
    fullName: "APARTHIB ROYCHAKRABARTY",
    saID: "SPR4162",
  },
  {
    firstName: "BIMBADHAR",
    lastName: "MAHABHOI",
    address: "*SATSANG 814116.",
    fullName: "BIMBADHAR MAHABHOI",
    saID: "SPR4163",
  },
  {
    firstName: "J MADHAB GOPAL",
    lastName: "NAYAK",
    address: "C/O-PARESH VORA*NEAR SATSANG COLLEGE*DEOGHAR 814116.",
    fullName: "J MADHAB GOPAL NAYAK",
    saID: "SPR4164",
  },
  {
    firstName: "BIRENDRA",
    lastName: "CHAKRABORTY",
    address: "SREE GURU LIBRARY*PO-DUDHNAI 783124*GOALPARA",
    fullName: "BIRENDRA CHAKRABORTY",
    saID: "SPR4165",
  },
  {
    firstName: "NIBARAN CHANDRA",
    lastName: "SARMA",
    address: "VILL-EKRABARI*PO-GHILAGURI*BONGAIGAON-783383*ASSAM",
    fullName: "NIBARAN CHANDRA SARMA",
    saID: "SPR4166",
  },
  {
    firstName: "LATE MIHIR KUMAR",
    lastName: "CHOUDHURY",
    address:
      "DHAIYA(PURAN PARAMANIK NAGAR)*PO-INDIAN SCHOOL OF MINES*DHANBAD 826004",
    fullName: "LATE MIHIR KUMAR CHOUDHURY",
    saID: "SPR4167",
  },
  {
    firstName: "ANUGOPAL",
    lastName: "KUNDU",
    address:
      "C/O-M/S GITANJALI*GOURISHANKAR MARKET(R NO.28)*BIDHAN ROAD*PO-SILLIGURI*DARJEELING",
    fullName: "ANUGOPAL KUNDU",
    saID: "SPR4168",
  },
  {
    firstName: "SHYAMAL KANTI",
    lastName: "NANDA",
    address: "NAJARGANJ (BABAJI GOLI)*PASCHIM MEDINIPUR-721101*PAN-ABMPN1097R",
    fullName: "SHYAMAL KANTI NANDA",
    saID: "SPR4169",
  },
  {
    firstName: "BIJAY",
    lastName: "CHOUDHURY",
    address: "PO-RONGPUR[SANTIPARA]*SILCHAR-788009.",
    fullName: "BIJAY CHOUDHURY",
    saID: "SPR4170",
  },
  {
    firstName: "RAMESWAR",
    lastName: "RANA",
    address:
      "DIGHA SATSANG MANDIR*PO-DIGHA*DIST-PURBA MEDINIPUR*PAN-ARQPR4429K",
    fullName: "RAMESWAR RANA",
    saID: "SPR4171",
  },
  {
    firstName: "BISWAMBHAR",
    lastName: "HAZRA",
    address: "VILL/PO-BHAJACHAULI*PURBA MEDINIPUR 721452.",
    fullName: "BISWAMBHAR HAZRA",
    saID: "SPR4172",
  },
  {
    firstName: "UMESH",
    lastName: "LAHAKAR",
    address: "AZAD ROAD*PO-DOOM DOOMA*DIBRUGARH 786151",
    fullName: "UMESH LAHAKAR",
    saID: "SPR4173",
  },
  {
    firstName: "KRIPA BIBHAB",
    lastName: "GHOSE",
    address: "VILL-SRIRAMPUR *PO-KESHABPUR*BURDWAN-713423.",
    fullName: "KRIPA BIBHAB GHOSE",
    saID: "SPR4174",
  },
  {
    firstName: "DEBASIS",
    lastName: "MANDAL",
    address: "AT&PO-SATSANG*DEOGHAR 814116*PAN-BKLPM1236Q",
    fullName: "DEBASIS MANDAL",
    saID: "SPR4175",
  },
  {
    firstName: "BISWANATH",
    lastName: "KANSARI",
    address: "M.O.,DISTRICT HOSPITAL*MALDA-732101*PAN-AGCPK9982B",
    fullName: "BISWANATH KANSARI",
    saID: "SPR4176",
  },
  {
    firstName: "RATNAKAR",
    lastName: "PRADHAN",
    address: "AT-NAPANGA*PO-BIROL*VIA-CHOUDWAR*CUTTACK 754025*PAN-AKZPP7233M",
    fullName: "RATNAKAR PRADHAN",
    saID: "SPR4177",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "DHAL",
    address: "AT&PO-SINGHPUR*JAJPUR 755016",
    fullName: "ASHOK KUMAR DHAL",
    saID: "SPR4178",
  },
  {
    firstName: "PURUSOTAM",
    lastName: "PALATASINGH",
    address: "SATSANG VIHAR BANPUR*PO-BANPUR*KHURDA-752031*PAN-AIPPP1469B",
    fullName: "PURUSOTAM PALATASINGH",
    saID: "SPR4179",
  },
  {
    firstName: "PREMANANDA",
    lastName: "PATTANAYAK",
    address: "AT/PO-ICHINDA*RAIRANGPUR*MAYURBHANJ-757043*ODISHA",
    fullName: "PREMANANDA PATTANAYAK",
    saID: "SPR4180",
  },
  {
    firstName: "NARASINGH CHARAN",
    lastName: "MOHANTY",
    address: "DEBA VIHAR*PLOT NO-157,MAHANADI VIHAR*CUTTACK 753 004",
    fullName: "NARASINGH CHARAN MOHANTY",
    saID: "SPR4181",
  },
  {
    firstName: "AJITAVA",
    lastName: "GUPTACHOUDHURY",
    address: "*SATSANG 814116",
    fullName: "AJITAVA GUPTACHOUDHURY",
    saID: "SPR4182",
  },
  {
    firstName: "LATE BIMAL KUMAR",
    lastName: "CHAUDHURY",
    address: "*SATSANG 814116",
    fullName: "LATE BIMAL KUMAR CHAUDHURY",
    saID: "SPR4183",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "HALDER",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE JOGENDRA NATH HALDER",
    saID: "SPR4184",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "DHUSIYA",
    address: "68,SURYA SEN STREET*CALCUTTA 9",
    fullName: "ANIL KUMAR DHUSIYA",
    saID: "SPR4185",
  },
  {
    firstName: "DIPENDU",
    lastName: "BHATTACHERJEE",
    address:
      "COLONY BAZAR,KAHILIPARA*NR KAHILIPARA H.S.,UPPER LANE*PO-VINOVANAGAR*GOUHATI-781018",
    fullName: "DIPENDU BHATTACHERJEE",
    saID: "SPR4186",
  },
  {
    firstName: "NARAHARI",
    lastName: "MANNA",
    address: "VILL-RAKSACHAK*PO-KOLAGHAT*PURBA MEDINIPUR-721134*PAN-AUOPM9131C",
    fullName: "NARAHARI MANNA",
    saID: "SPR4187",
  },
  {
    firstName: "LATE MUKUNDA BIHARI",
    lastName: "MONDAL",
    address: "VILL-KURULIA*PO-HATKURULIA*24PARGANAS (N)-743232",
    fullName: "LATE MUKUNDA BIHARI MONDAL",
    saID: "SPR4188",
  },
  {
    firstName: "DIBAKAR",
    lastName: "GHOSH",
    address: "VILL-KUSTHIA*PO-HANSPUKURIA*NADIA-741160",
    fullName: "DIBAKAR GHOSH",
    saID: "SPR4189",
  },
  {
    firstName: "LATE TARAKANTA",
    lastName: "HALDER",
    address: "VILL-SULTANPUR*PO-RAJBARI COLONY*CALCUTTA 700081",
    fullName: "LATE TARAKANTA HALDER",
    saID: "SPR4190",
  },
  {
    firstName: "LATE AJAY PRASAD",
    lastName: "HALDAR",
    address: "SHIB BAGAN*NIMAI TIRTHA ROAD*BAIDYABATI*HOOGHLY",
    fullName: "LATE AJAY PRASAD HALDAR",
    saID: "SPR4191",
  },
  {
    firstName: "SAILEN",
    lastName: "DEYSARKAR",
    address: '"SWASTI KUNJA"*41,S.N.PAL ROAD*CALCUTTA-700 057',
    fullName: "SAILEN DEYSARKAR",
    saID: "SPR4192",
  },
  {
    firstName: "SUKUMAR",
    lastName: "MAJI",
    address: "C/O X-RAY MART*BURGE TOWN*PO-MEDINIPUR*PASCHIM MEDINIPUR 721101",
    fullName: "SUKUMAR MAJI",
    saID: "SPR4193",
  },
  {
    firstName: "BHADRESWAR",
    lastName: "DAS",
    address: "HOUSE NO-9 A G OFFICE ROAD*BELTALA*GUWAHATI 28 PAN NO>AFXPD5771R",
    fullName: "BHADRESWAR DAS",
    saID: "SPR4194",
  },
  {
    firstName: "RAMCHANDRA PRASAD",
    lastName: "GUPTA",
    address: "NEAR  A O C NORTH*IMPHAL*MANIPUR-795001",
    fullName: "RAMCHANDRA PRASAD GUPTA",
    saID: "SPR4195",
  },
  {
    firstName: "RATAN KANTI",
    lastName: "DUTTA",
    address: "C/O-MANI STORE*PO-SAPEKHATI*SIBSAGAR 785692",
    fullName: "RATAN KANTI DUTTA",
    saID: "SPR4196",
  },
  {
    firstName: "GOBINDA",
    lastName: "HAJRA",
    address: "VILL ATKETIA*PO BAIDYAPUR*BURDWAN-713122",
    fullName: "GOBINDA HAJRA",
    saID: "SPR4197",
  },
  {
    firstName: "RATNESWAR",
    lastName: "KURMI",
    address: "VILL&PO-THELAMARA*SONITPUR",
    fullName: "RATNESWAR KURMI",
    saID: "SPR4198",
  },
  {
    firstName: "NIKUNJA",
    lastName: "MONDAL",
    address: "AT/PO-BARO NACHINA*COOCH BEHAR-736135",
    fullName: "NIKUNJA MONDAL",
    saID: "SPR4199",
  },
  {
    firstName: "ROSHENDRA",
    lastName: "HAJONG",
    address:
      "PORAKHASIYA SATSANG KENDRA*PO-DHUPKURA*PO-PORAKHASIYA*WEST GAROHILLS.",
    fullName: "ROSHENDRA HAJONG",
    saID: "SPR4200",
  },
  {
    firstName: "PRANAY RANJAN",
    lastName: "SARKAR",
    address: "VILL&PO-PHULDURI*TRIPURA.",
    fullName: "PRANAY RANJAN SARKAR",
    saID: "SPR4201",
  },
  {
    firstName: "LATE SURENDRA KUMAR",
    lastName: "DUTTA",
    address: "KUNJA KUTIR WARD NO 10*D K ROAD*PO-NORTH LAKHIMPUR*LAKHIMPUR",
    fullName: "LATE SURENDRA KUMAR DUTTA",
    saID: "SPR4202",
  },
  {
    firstName: "GAYARAM",
    lastName: "METIA",
    address: "VILL&PO-KUSHBERIA*HOWRAH 711316",
    fullName: "GAYARAM METIA",
    saID: "SPR4203",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "DAS",
    address: "VILL-MAHESHBATI*PO-RAINA*BURDWAN-713421",
    fullName: "DILIP KUMAR DAS",
    saID: "SPR4204",
  },
  {
    firstName: "BHABENDRA NARAYAN",
    lastName: "ROY",
    address:
      "SATSANG VIHAR GOALPARA*PO-BALADMARI*GOALPARA-783121*PAN-BILPR0866B*ASSAM",
    fullName: "BHABENDRA NARAYAN ROY",
    saID: "SPR4205",
  },
  {
    firstName: "LATE DARPANARAYAN",
    lastName: "BAL",
    address: "C/O K K SHUKLA*B G ROAD*PO-TALPUKUR*24PARGANAS NORTH-700123",
    fullName: "LATE DARPANARAYAN BAL",
    saID: "SPR4206",
  },
  {
    firstName: "SANKARSAN",
    lastName: "SAMAL",
    address: "SATSANG  814116*DEOGHAR*PAN-GLFPS6880G",
    fullName: "SANKARSAN SAMAL",
    saID: "SPR4207",
  },
  {
    firstName: "JATINDRA CHANDRA",
    lastName: "BISWAS",
    address: "C/O DIMAPUR PHOTO BINDING*M.P.ROAD*DIMAPUR*NAGALAND 797112",
    fullName: "JATINDRA CHANDRA BISWAS",
    saID: "SPR4208",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "SAHA",
    address: "C/O SRI SRI BARDA*PO-SATSANG 814116*SATSANG*PAN-GVHPS3624C",
    fullName: "SUBHAS CHANDRA SAHA",
    saID: "SPR4209",
  },
  {
    firstName: "NIKHIL",
    lastName: "CHAKRABARTY",
    address: "RAIPUR VIDYASAGAR PALLY*PO-GARH RAIPUR*BANKURA 722 134.",
    fullName: "NIKHIL CHAKRABARTY",
    saID: "SPR4210",
  },
  {
    firstName: "SHIBA PADA",
    lastName: "MANDAL",
    address:
      "VILL-A.G.COLONY,PLOT NO-910*PO-MANIKTALA*24PARGANAS(N)-743263*PAN-AJIPM7175M",
    fullName: "SHIBA PADA MANDAL",
    saID: "SPR4211",
  },
  {
    firstName: "LATE BALAI CHAND",
    lastName: "MAJUMDER",
    address:
      "C/O N C MAJUMDER*MANIRAMPUR,MANNAPARA*PO-BARRACKPORE*24PARGANAS (NORTH)-700110",
    fullName: "LATE BALAI CHAND MAJUMDER",
    saID: "SPR4212",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "BANDOPADHYAY",
    address:
      "C/O S P BANERJEE*DESH BANDHU ROAD,SUBHAS PALLI*PO-BURNPUR*BURDWAN*PAN-ADDPB6696B",
    fullName: "TAPAN KUMAR BANDOPADHYAY",
    saID: "SPR4213",
  },
  {
    firstName: "BROJENDRA NATH",
    lastName: "MAITY",
    address: "VILL-PADUMPUR*PO-KELOMAL*PURBA MEDINIPUR",
    fullName: "BROJENDRA NATH MAITY",
    saID: "SPR4214",
  },
  {
    firstName: "MAYADHAR",
    lastName: "RATH",
    address: "AT/PO-ADHEIGUNDI*VIA-KANPUR*CUTTACK",
    fullName: "MAYADHAR RATH",
    saID: "SPR4215",
  },
  {
    firstName: "SANJEEB KUMAR",
    lastName: "NAIK",
    address:
      '"SANDEEPANA"*NEAR SIDDHESWAR TEMPLE*CHAULIAGANJ*CUTTACK-753004*PAN-AAWPN6211E',
    fullName: "SANJEEB KUMAR NAIK",
    saID: "SPR4216",
  },
  {
    firstName: "BANSHIDHAR",
    lastName: "PANDA",
    address: "AT&PO-APANDARA*VIA-MACHHAGAON*DIST-JAGATSINGHPUR-754119",
    fullName: "BANSHIDHAR PANDA",
    saID: "SPR4217",
  },
  {
    firstName: "GOPINATH",
    lastName: "SAHOO",
    address: "AT-SAKTINAGAR PLOT NO-25*PO-A D MARKET*CUTTACK-753012",
    fullName: "GOPINATH SAHOO",
    saID: "SPR4218",
  },
  {
    firstName: "PADMA CHARAN",
    lastName: "SAHOO",
    address:
      "DEV OFFICER*NATIONAL INSURANCE CO LTD*MAIN ROAD,BARBIL*KEONJHAR 758035",
    fullName: "PADMA CHARAN SAHOO",
    saID: "SPR4219",
  },
  {
    firstName: "TUFANI",
    lastName: "THAKUR",
    address: "AT/PO-MARANGA (WEST)*DIST-PURNEA*BIHAR-854303",
    fullName: "TUFANI THAKUR",
    saID: "SPR4220",
  },
  {
    firstName: "LATE AKSHAY KUMAR",
    lastName: "PUTUTUNDU",
    address: "BANGLADESH",
    fullName: "LATE AKSHAY KUMAR PUTUTUNDU",
    saID: "SPR4221",
  },
  {
    firstName: "KAMESWAR",
    lastName: "SINGH",
    address: "SATSANG VIHAR,BADLAPUR*AT/PO-KULGAON HENDREPADA*THANE*M.S.",
    fullName: "KAMESWAR SINGH",
    saID: "SPR4222",
  },
  {
    firstName: "LATE DHIREN",
    lastName: "CHATTARAJ",
    address: "KANDI HO*MURSHIDABAD-742137",
    fullName: "LATE DHIREN CHATTARAJ",
    saID: "SPR4223",
  },
  {
    firstName: "MURALI MOHAN",
    lastName: "PATRA",
    address:
      "C/O-BAIDYANATH DUTTA*VILL-LALDANGA*PO-ADRA*PURULIA-723121*PAN-AEVPP4853M",
    fullName: "MURALI MOHAN PATRA",
    saID: "SPR4224",
  },
  {
    firstName: "SANJOY KUMAR",
    lastName: "MANDAL",
    address: "S/O DIGAMBAR MONDAL*BARSUL MILGATE*PO-BARSUL*BURDWAN-713124",
    fullName: "SANJOY KUMAR MANDAL",
    saID: "SPR4225",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "DEBNATH",
    address: "SUBHAS PALLY*PO-BAXIRHAT*COOCHBEHAR-736131.",
    fullName: "ASWINI KUMAR DEBNATH",
    saID: "SPR4226",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DEY",
    address: "C/O-SURE AIMGUN HOUSE*ZAKIESATNO ROAD*DIMAPUR*NAGALAND 797 112.",
    fullName: "PARESH CHANDRA DEY",
    saID: "SPR4227",
  },
  {
    firstName: "REV JOYGOPAL",
    lastName: "CHATTERJEE",
    address: 'FLAT NO 501 "SOVEREIGN"*KALYANINAGAR*PUNE(M.S)*PIN-411006.',
    fullName: "REV JOYGOPAL CHATTERJEE",
    saID: "SPR4228",
  },
  {
    firstName: "RATAN KUMAR",
    lastName: "MUKHERJEE",
    address: "28/2, KHANPUR RD*PO-NAKTALA*KOLKATA-700047*PAN-AERPM7242R",
    fullName: "RATAN KUMAR MUKHERJEE",
    saID: "SPR4229",
  },
  {
    firstName: "SITESH CHANDRA",
    lastName: "DAS",
    address: "*SATSANG 814116",
    fullName: "SITESH CHANDRA DAS",
    saID: "SPR4230",
  },
  {
    firstName: "LT PRAMODE",
    lastName: "MAITRA",
    address: '"SABITRI VILLA"*PURANDAHA*PO-SATSANG*SATSANG',
    fullName: "LT PRAMODE MAITRA",
    saID: "SPR4231",
  },
  {
    firstName: "MUKTIPADA",
    lastName: "SAHA",
    address:
      "AT-TOWN HALL PARA,K G BOSE SARANI*PO-KATWA*BURDWAN-713130*PAN-AKCPS6160P",
    fullName: "MUKTIPADA SAHA",
    saID: "SPR4232",
  },
  {
    firstName: "DEVAPRASAD",
    lastName: "BANERJEE",
    address: "C/O GURU GOPAL HOTEL*SONATOR PARA*PO-SURI*BIRBHUM-731101",
    fullName: "DEVAPRASAD BANERJEE",
    saID: "SPR4233",
  },
  {
    firstName: "HARE KANT",
    lastName: "JHA",
    address: "SATSANG THAKUR BARI*DUMKA POLICE LINE*DUMKA-814101",
    fullName: "HARE KANT JHA",
    saID: "SPR4234",
  },
  {
    firstName: "LATE BINODE CHANDRA",
    lastName: "BHUIYAN",
    address: "MORAN TE(KHERBARI)*PO-MORAN*DIBRUGARH 785669",
    fullName: "LATE BINODE CHANDRA BHUIYAN",
    saID: "SPR4235",
  },
  {
    firstName: "LATE SRISH CHANDRA",
    lastName: "ROYCHOUDHURY",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "LATE SRISH CHANDRA ROYCHOUDHURY",
    saID: "SPR4236",
  },
  {
    firstName: "LATE JYOTISH",
    lastName: "SENGUPTA",
    address:
      "SATSANG THAKURBARI*T N BANERJEE ROAD*PO-PANIHATI*24PARGANAS NORTH-700114",
    fullName: "LATE JYOTISH SENGUPTA",
    saID: "SPR4237",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "DAS",
    address: "VILL/PO-BRAHMANSASAN*PASCHIM MEDINIPUR",
    fullName: "LATE ANIL KUMAR DAS",
    saID: "SPR4238",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MANDAL",
    address: "VILL-SIRISHGORA*PO/PS-BANDWAN*PURULIA-723129*PAN-BSVPM3261P",
    fullName: "RANJIT KUMAR MANDAL",
    saID: "SPR4239",
  },
  {
    firstName: "SNIGDHA BARAN",
    lastName: "NAYAK",
    address: "VILL-TERAPEKHIA*PO-MAHISHADAL*MIDNAPUR",
    fullName: "SNIGDHA BARAN NAYAK",
    saID: "SPR4240",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "PAL",
    address: "VILL&PO-BANIPUR*24PARGANAS NORTH-743233",
    fullName: "BIRENDRA NATH PAL",
    saID: "SPR4241",
  },
  {
    firstName: "MADHAB",
    lastName: "DUTTA",
    address: "SATSANG VIHAR CHITTAGONG*BAKALIA*CHITTAGONG*BANGLADESH",
    fullName: "MADHAB DUTTA",
    saID: "SPR4242",
  },
  {
    firstName: "LATE PRIYAKANTA",
    lastName: "DAS",
    address: "QR NO VIIH-162,SAILASHREE VIHAR*CHANDRASEKHARPUR*BHUBANESWAR 21.",
    fullName: "LATE PRIYAKANTA DAS",
    saID: "SPR4243",
  },
  {
    firstName: "LATE BIRABHADRA",
    lastName: "SAHOO",
    address: "STATION SQUARE(A.P.RICEMILL)*PO-SORO*BALASORE-756045.",
    fullName: "LATE BIRABHADRA SAHOO",
    saID: "SPR4244",
  },
  {
    firstName: "SUSHANTA KUMAR",
    lastName: "KHAMARI",
    address: "AT-POLKAMUNDA*PO-BANER*KALAHANDI 766018",
    fullName: "SUSHANTA KUMAR KHAMARI",
    saID: "SPR4245",
  },
  {
    firstName: "PRATAP CHANDRA",
    lastName: "BRAHMA",
    address:
      '"ANANDA DHARA"*54,KHARVEL NAGAR,UNIT-3*BHUBANESWAR-751001*PAN-AMOPB6838P',
    fullName: "PRATAP CHANDRA BRAHMA",
    saID: "SPR4246",
  },
  {
    firstName: "DINESH",
    lastName: "BHATTACHARYA",
    address: "N/5/39,I R C VILLAGE*BHUBANESWAR-751015*KHURDA",
    fullName: "DINESH BHATTACHARYA",
    saID: "SPR4247",
  },
  {
    firstName: "ISWAR CHANDRA",
    lastName: "GIRI",
    address: "AT-RAIPUR*PO-DEULAHAT*BALASORE-756036",
    fullName: "ISWAR CHANDRA GIRI",
    saID: "SPR4248",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "NAYAK",
    address: "VILL-PALLI*PO-ATTO*VIA-SABARANGA*BHADRAK-756123.",
    fullName: "PRAVAKAR NAYAK",
    saID: "SPR4249",
  },
  {
    firstName: "RABINDRA PRASAD",
    lastName: "NATH",
    address: "AT&PO-NARAYANPUR*VIA-DHAMNAGAR*BHADRAK-756117",
    fullName: "RABINDRA PRASAD NATH",
    saID: "SPR4250",
  },
  {
    firstName: "ALEKHA CHANDRA",
    lastName: "ROUT",
    address:
      "DURGAGUDI SAHI*AT/PO/DT-MALKANGIRI 764045*ODISHA*(PAN-ADUPR6237R)",
    fullName: "ALEKHA CHANDRA ROUT",
    saID: "SPR4251",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "NAYAK",
    address: "SATSANG COMPUTER CENTRE*SAROSHI BHABAN*SATSANG*DEOGHAR 814116",
    fullName: "RAMAKANTA NAYAK",
    saID: "SPR4252",
  },
  {
    firstName: "SARAT CHANDRA",
    lastName: "SWAIN",
    address: "AT-JAYABAD*PO/DIST-JAGATSINGHPUR-745103*PAN-AOKPS5217B",
    fullName: "SARAT CHANDRA SWAIN",
    saID: "SPR4253",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "SARANGI",
    address: "AT&PO-GANESH BAZAR*DHENKANAL-759001*PAN-AONPS0892K",
    fullName: "SANTOSH KUMAR SARANGI",
    saID: "SPR4254",
  },
  {
    firstName: "NARAHARI",
    lastName: "BEHERA",
    address: "AT-TULAMTULA*PO-BETADA*DT-BALASORE-756168",
    fullName: "NARAHARI BEHERA",
    saID: "SPR4255",
  },
  {
    firstName: "RAJANI KANTA",
    lastName: "PANI",
    address: "PLOT NO-4435/5936*BADAGADA JHARANASAHI*BHUBANESWAR-751018*KHURDA",
    fullName: "RAJANI KANTA PANI",
    saID: "SPR4256",
  },
  {
    firstName: "SASHI MOHAN",
    lastName: "DAIMARI",
    address: "",
    fullName: "SASHI MOHAN DAIMARI",
    saID: "SPR4257",
  },
  {
    firstName: "SASHI MOHAN",
    lastName: "DAIMARI",
    address: "",
    fullName: "SASHI MOHAN DAIMARI",
    saID: "SPR4258",
  },
  {
    firstName: "SRIJNAN",
    lastName: "DEY",
    address: "68,SURYA SEN STREET*CALCUTTA 700009.",
    fullName: "SRIJNAN DEY",
    saID: "SPR4259",
  },
  {
    firstName: "MRITYUNJOY",
    lastName: "SINGHAROY",
    address: "VILL-KHIDIRPUR*PO-BETHUADAHARI*NADIA 741126*PAN-AKLPS6702Q",
    fullName: "MRITYUNJOY SINGHAROY",
    saID: "SPR4260",
  },
  {
    firstName: "HARIPADA",
    lastName: "MONDAL",
    address: "AT-GOSABA SATSANG VIHAR*PO-GOSABA*24PARGANAS (SOUTH)-743370",
    fullName: "HARIPADA MONDAL",
    saID: "SPR4261",
  },
  {
    firstName: "DHANESWAR",
    lastName: "BARMAN",
    address: "AT/PO-KARDAHA*DIST-DAKSHIN DINAJPUR-733142",
    fullName: "DHANESWAR BARMAN",
    saID: "SPR4262",
  },
  {
    firstName: "NEMAI CHANDRA",
    lastName: "SARKAR",
    address: "VILL&PO-RAJHATI BANDAR*HOOGHLY-712417",
    fullName: "NEMAI CHANDRA SARKAR",
    saID: "SPR4263",
  },
  {
    firstName: "SUBHASH KUMAR",
    lastName: "DUTTA",
    address: "VILL-CHANDRAPUR TINALI*PO-CHANDRAPUR*DT-KAMRUP 781150",
    fullName: "SUBHASH KUMAR DUTTA",
    saID: "SPR4264",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "LENKA",
    address:
      "O/O THE SUPDT POLICE*AT/PO-ROING*DIST-LOWER DIBANG VALLEY*ARUNACHAL PRADESH",
    fullName: "SANTOSH KUMAR LENKA",
    saID: "SPR4265",
  },
  {
    firstName: "NAROTTAM",
    lastName: "BARMAN",
    address: "SATSANG VIHAR MAIBONG*PO-MAIBONG*N C HILLS-788831*ASSAM",
    fullName: "NAROTTAM BARMAN",
    saID: "SPR4266",
  },
  {
    firstName: "TARANI",
    lastName: "DEBNATH",
    address: "SATSANG SWASTI SIBIR*PO-KRISHNANAGAR*DIST-NADIA-741101",
    fullName: "TARANI DEBNATH",
    saID: "SPR4267",
  },
  {
    firstName: "KANTI",
    lastName: "DEY",
    address: "C/O BHABER BHANDER*RABINDRA PALLY*PO-SURI*BIRBHUM-731101",
    fullName: "KANTI DEY",
    saID: "SPR4268",
  },
  {
    firstName: "BIMALENDU",
    lastName: "ROY",
    address: "",
    fullName: "BIMALENDU ROY",
    saID: "SPR4269",
  },
  {
    firstName: "LATE NAMUDHAR",
    lastName: "BORAH",
    address: "VILL-BARKACHARI GAON*PO-BARHOLLA*GOLAGHAT",
    fullName: "LATE NAMUDHAR BORAH",
    saID: "SPR4270",
  },
  {
    firstName: "FAKIR CHARAN",
    lastName: "PRUSTY",
    address: "AT/PO-CHHATIA*JAJPUR*PAN-AHBPP3820B",
    fullName: "FAKIR CHARAN PRUSTY",
    saID: "SPR4271",
  },
  {
    firstName: "BANCHHANIDHI",
    lastName: "BEHERA",
    address: "AT-BHAINRI*PO-MINGUR*VIA-KALAMPUR*KALAHANDI-766013",
    fullName: "BANCHHANIDHI BEHERA",
    saID: "SPR4272",
  },
  {
    firstName: "NIMAIN CHARAN",
    lastName: "BEHERA",
    address: "C/O SATSANG VIHAR ANGUL*PO/DIST-ANGUL-759122.",
    fullName: "NIMAIN CHARAN BEHERA",
    saID: "SPR4273",
  },
  {
    firstName: "KRUTTIBAS",
    lastName: "ACHARYA",
    address: "AT-BALIGHAT*JAGESWARI LANE*PURI-752002*ODISHA",
    fullName: "KRUTTIBAS ACHARYA",
    saID: "SPR4274",
  },
  {
    firstName: "GAJENDRA KUMAR",
    lastName: "SAHOO",
    address: "AT&PO-RACHIPUR*VIA-JAJPUR ROAD*DIST-JAJPUR 755019",
    fullName: "GAJENDRA KUMAR SAHOO",
    saID: "SPR4275",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SAHOO",
    address: "SATSANG THAKURBARI*C T ROAD*PO&DT-PURI 752002*PAN-GTYPS7841L",
    fullName: "ARUN KUMAR SAHOO",
    saID: "SPR4276",
  },
  {
    firstName: "BINOD KUMAR",
    lastName: "BISWAL",
    address:
      "C/O-SUDARSAN BISWAL*AT-KHARIBIL*PO-ERANCH*VIA-KASARDA*CUTTACK-754105",
    fullName: "BINOD KUMAR BISWAL",
    saID: "SPR4277",
  },
  {
    firstName: "BIRAKISHORE",
    lastName: "SAHOO",
    address: "SATSANG THAKURBARI*CHAKRATIRTHA ROAD*PURI 752002.",
    fullName: "BIRAKISHORE SAHOO",
    saID: "SPR4278",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "BARIK",
    address:
      "ORISSA STATE HANDLOOM EMP*VILL&PO-JODA BAZAR*KEONJHAR-758034*PAN-CCJPB7475D",
    fullName: "MADAN MOHAN BARIK",
    saID: "SPR4279",
  },
  {
    firstName: "SUBASH CHANDRA",
    lastName: "BEURA",
    address: "VILL-KHAMAR NUAGAON*PO-GURUDI JHATIA*CUTTACK-754020",
    fullName: "SUBASH CHANDRA BEURA",
    saID: "SPR4280",
  },
  {
    firstName: "BHOLA",
    lastName: "PRASAD",
    address:
      "SWASTI NIKETAN*RATANPUR*PO-BARHARWA*SAHEBGANJ-816101*PAN-ABXPP1172E",
    fullName: "BHOLA PRASAD",
    saID: "SPR4281",
  },
  {
    firstName: "LATE RAGHU NANDAN",
    lastName: "PRASAD",
    address:
      "C/O-LT DR HARIHAR PRASAD*AT-KASHIPUR, WORD NO-13*PO/DIST-SAMASTIPUR-848 101",
    fullName: "LATE RAGHU NANDAN PRASAD",
    saID: "SPR4282",
  },
  {
    firstName: "DAMODAR",
    lastName: "BAL",
    address: "NALCO(HOMOEO CLINIC)*PO/DIST-ANGUL-759122*PAN-AKDPB2838C",
    fullName: "DAMODAR BAL",
    saID: "SPR4283",
  },
  {
    firstName: "LATE AMULYA BANDHU",
    lastName: "DEBROY",
    address: "C/O SATSANG VIHAR,NABAGRAM*PO-BARABAHERA*HOOGHLY",
    fullName: "LATE AMULYA BANDHU DEBROY",
    saID: "SPR4284",
  },
  {
    firstName: "LAKSHMAN KUMAR",
    lastName: "DUTTA",
    address: "VILL/PO-TAHERPUR*G/5/1573*NADIA-741159",
    fullName: "LAKSHMAN KUMAR DUTTA",
    saID: "SPR4285",
  },
  {
    firstName: "DHRUBA NATH",
    lastName: "JOSHI",
    address: "UPPER MOWPREM*SHILLONG-793002*MEGHALAYA",
    fullName: "DHRUBA NATH JOSHI",
    saID: "SPR4286",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "MONDAL",
    address: "VILL-KASBA TOLA*PO-SUKSENA*DIST-MALDA-732203*PAN-BBXPM4248R",
    fullName: "SURENDRA NATH MONDAL",
    saID: "SPR4287",
  },
  {
    firstName: "BHARGA PRASAD",
    lastName: "DASSARMA",
    address: "583/C,KASTADANGA ROAD*PO-SARSUNA*CALCUTTA-700061*ADBPD3521F",
    fullName: "BHARGA PRASAD DASSARMA",
    saID: "SPR4288",
  },
  {
    firstName: "LATE MADANMOHAN",
    lastName: "PANDIT",
    address: "RUPNARAYANPUR STATION ROAD*PO-RUPNARAYANPUR*BURDWAN-713386",
    fullName: "LATE MADANMOHAN PANDIT",
    saID: "SPR4289",
  },
  {
    firstName: "KHEMO BHUSAN",
    lastName: "MANDAL",
    address: "VILL&PO-GOBINDAPUR*VIA-KHANTURA*24PARGANAS (NORTH) 743273",
    fullName: "KHEMO BHUSAN MANDAL",
    saID: "SPR4290",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "GHOSH",
    address: "AT-THUBA CHOWRANGI*GOPAL MANDIR*PO-THUBA TAKI*NORTH 24PARGANAS.",
    fullName: "LATE HARIPADA GHOSH",
    saID: "SPR4291",
  },
  {
    firstName: "DIGENDRA KUMAR",
    lastName: "DEB",
    address: "VILL&PO-CHOTOJALENGA*CACHAR",
    fullName: "DIGENDRA KUMAR DEB",
    saID: "SPR4292",
  },
  {
    firstName: "NAGENDRA NATH",
    lastName: "SARDAR",
    address: "AT&PO-SANTIGACHHI*24PARGANAS (S) 743 370.",
    fullName: "NAGENDRA NATH SARDAR",
    saID: "SPR4293",
  },
  {
    firstName: "SATYANANDA",
    lastName: "BHATTACHARYA",
    address: "VILL-HASANHATI*PO-BAIDYAPUR*BURDWAN 713122",
    fullName: "SATYANANDA BHATTACHARYA",
    saID: "SPR4294",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "DAS",
    address: "AT&PO-RENTER'S COLONY*AGARTALA*TRIPURA WEST 799004",
    fullName: "LATE SUKUMAR DAS",
    saID: "SPR4295",
  },
  {
    firstName: "BISHESHWAR",
    lastName: "PODDAR",
    address: "AT-BAGHA WARD NO.1*PO-SUHIDNAGAR*BEGUSARAI 851101",
    fullName: "BISHESHWAR PODDAR",
    saID: "SPR4296",
  },
  {
    firstName: "SHYAMLAL",
    lastName: "MURMU",
    address: "AT-GAMHARIA*PO-KHUTAHAR*VIA-NANIHAT*DUMKA-814145*PAN-AILPM3894R",
    fullName: "SHYAMLAL MURMU",
    saID: "SPR4297",
  },
  {
    firstName: "BAIDYANATH",
    lastName: "GHOSE",
    address: "VILL-BHARUCHA*PO-LOHAR*BURDWAN-713145",
    fullName: "BAIDYANATH GHOSE",
    saID: "SPR4298",
  },
  {
    firstName: "JANARDAN",
    lastName: "PANDA",
    address: "C/O JANARDAN TRAVEL*MAHABIR PARA*BHAWANI PATNA*KALAHANDI-766001",
    fullName: "JANARDAN PANDA",
    saID: "SPR4299",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SARANGI",
    address: "AT/PO-PANKAPAL*DIST-JAGATSINGHPUR-754140*ODISHA",
    fullName: "SURENDRA NATH SARANGI",
    saID: "SPR4300",
  },
  {
    firstName: "NANDA KISHORE",
    lastName: "PRADHAN",
    address: "SATSANG THAKURBARI*C T ROAD*PURI-2*(PAN-AAPPP6677F)",
    fullName: "NANDA KISHORE PRADHAN",
    saID: "SPR4301",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "KODAMASINGH",
    address:
      "ASSTT.LIBRARIAN*STATE LIBRARY ORISSA*BHUBANESWAR-751001*DIST-KHURDA",
    fullName: "RABINDRA NATH KODAMASINGH",
    saID: "SPR4302",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "DUTTA",
    address: "VILL-SADARGHAT(KHELARMATH)*BURDWAN",
    fullName: "LATE NARAYAN CHANDRA DUTTA",
    saID: "SPR4303",
  },
  {
    firstName: "LATE KANDURI CHARAN",
    lastName: "SAMAL",
    address: "SATSANG VIHAR,PHULBANI*DIST-KANDHAMAL-762001",
    fullName: "LATE KANDURI CHARAN SAMAL",
    saID: "SPR4304",
  },
  {
    firstName: "BISHNU CHARAN",
    lastName: "BORO",
    address: "SATSANG VIHAR,BHANGAGARH*PO-DISPUR*GUWAHATI-781005*KAMRUP",
    fullName: "BISHNU CHARAN BORO",
    saID: "SPR4305",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "ACHARYA",
    address: "JAGANNATH HIGH SCHOOL*AT&PO-KOLAR*JAGATSINGHPUR-754162",
    fullName: "BIJAY KUMAR ACHARYA",
    saID: "SPR4306",
  },
  {
    firstName: "RAM BHAGERAN",
    lastName: "SARMA",
    address: "AT-ISMAILCHAK*PO-GOBINDCHAK*VIA-SONEPUR*SARAN-841101*BIHAR",
    fullName: "RAM BHAGERAN SARMA",
    saID: "SPR4307",
  },
  {
    firstName: "AMARENDRA KUMAR",
    lastName: "SHARAN",
    address: "M S FASHION HOUSE*M I G 282*KANKARBAGH*PATNA-800020",
    fullName: "AMARENDRA KUMAR SHARAN",
    saID: "SPR4308",
  },
  {
    firstName: "SADHU CHARAN",
    lastName: "SWAIN",
    address: "AT-SAMBHUNAGAR(PURANA PADA)*PO-BHAWANIPATNA*KALAHANDI-766001",
    fullName: "SADHU CHARAN SWAIN",
    saID: "SPR4309",
  },
  {
    firstName: "PULIN KUMAR",
    lastName: "NAYAK",
    address:
      "ASST. MANAGER*STATE BANK OF INDIA*NIMAPARA BRANCH*AT/PO-NIMAPARA*PURI-752106",
    fullName: "PULIN KUMAR NAYAK",
    saID: "SPR4310",
  },
  {
    firstName: "AMIYA KUMAR",
    lastName: "PAUL",
    address: "BANGLADESH",
    fullName: "AMIYA KUMAR PAUL",
    saID: "SPR4311",
  },
  {
    firstName: "SWAIN SRI NRUSINGHA",
    lastName: "SRICHANDAN",
    address:
      "C/O IMFA LTD*AT-BOMIKHAL*PO-RASULGARH*BHUBANESWAR-751010*PAN-ABVPS4981Q",
    fullName: "SWAIN SRI NRUSINGHA SRICHANDAN",
    saID: "SPR4312",
  },
  {
    firstName: "ISHWAR CHANDRA",
    lastName: "GAIN",
    address:
      "KRISHNANAGAR M E L WORD NO-5*PO-M E L MULROAD*DIST-CHANDRAPUR*M.S. 442401",
    fullName: "ISHWAR CHANDRA GAIN",
    saID: "SPR4313",
  },
  {
    firstName: "DULAL KRISHNA",
    lastName: "DEY",
    address: "CHHATAL MANASAPALLY*PO-BASIRHAT*24PARGANAS (NORTH)-743 411",
    fullName: "DULAL KRISHNA DEY",
    saID: "SPR4314",
  },
  {
    firstName: "LATE SRUSTIDHAR",
    lastName: "DAS",
    address:
      '"SWASTI BHAVAN"*SIKHARCHANDI CHAUK*PO-PATIA*BHUBANESWAR-31*KHURDA',
    fullName: "LATE SRUSTIDHAR DAS",
    saID: "SPR4315",
  },
  {
    firstName: "DHANESWAR",
    lastName: "MAHALI",
    address: "AT-BEHULA*PO-TIKANPUR*VIA-GARADPUR*CUTTACK-754153.",
    fullName: "DHANESWAR MAHALI",
    saID: "SPR4316",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "MAHATO",
    address: "SERAIKELLA WARD NO-1*DIST-SERAIKELLA KHARSAWAN*JHARKHAND-833219",
    fullName: "DHIRENDRA MAHATO",
    saID: "SPR4317",
  },
  {
    firstName: "LATE THANESWAR",
    lastName: "DAS",
    address: "PV-26,RAMKRISHNA PALLY*PO-PV 26*VIA-PAKHANJORE*BASTER*M.P.",
    fullName: "LATE THANESWAR DAS",
    saID: "SPR4318",
  },
  {
    firstName: "SEKHAR",
    lastName: "BISWAS",
    address: "VILL/PO-CHANDPUR*DIST-24PARGANAS (SOUTH)",
    fullName: "SEKHAR BISWAS",
    saID: "SPR4319",
  },
  {
    firstName: "GAJENDRA CHANDRA",
    lastName: "RAY",
    address:
      "ASST TEACHER,GOURIPUR W.NO-1*PO-GOURIPUR-783331*DIST-DHUBRI*ASSAM",
    fullName: "GAJENDRA CHANDRA RAY",
    saID: "SPR4320",
  },
  {
    firstName: "GOPAL",
    lastName: "SARKAR",
    address: "THAKUR BUNGLOW*SATSANG 814116",
    fullName: "GOPAL SARKAR",
    saID: "SPR4321",
  },
  {
    firstName: "SURESH",
    lastName: "RAY",
    address:
      "SATSANG VIHAR RAJNAGAR BARAUNI*PO-GARHARA*DIST-BEGUSARAI 851126*PAN-AWGPR6108J",
    fullName: "SURESH RAY",
    saID: "SPR4322",
  },
  {
    firstName: "DEB KUMAR",
    lastName: "DUTTA",
    address: "PIRTALA*PO-CHINSURAH*MEARBAR COLONY*HOOGHLY-712101",
    fullName: "DEB KUMAR DUTTA",
    saID: "SPR4323",
  },
  {
    firstName: "SWAPAN",
    lastName: "RUDRAROY",
    address: "VILL-CHHANBAN*PO-RAJARBAG*GUMATI TRIPURA-799114.",
    fullName: "SWAPAN RUDRAROY",
    saID: "SPR4324",
  },
  {
    firstName: "LATE SK OSMAN",
    lastName: "GHANI",
    address: "VILL-GOLSI MIRIK PARA*PO-GOLSI*BURDWAN.",
    fullName: "LATE SK OSMAN GHANI",
    saID: "SPR4325",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "SARKAR",
    address:
      "H-NO-N0035,SREE BIBHA*VILL-GOURNAGAR*PO-SREE MAYAPUR*NADIA-741313.",
    fullName: "SWAPAN KUMAR SARKAR",
    saID: "SPR4326",
  },
  {
    firstName: "SHIBDYUTI",
    lastName: "BASULI",
    address: "VILL-KALBERIA*PO-BELIATORE*BANKURA-722203*PAN-AQPPB7730F",
    fullName: "SHIBDYUTI BASULI",
    saID: "SPR4327",
  },
  {
    firstName: "NEMAI CHANDRA",
    lastName: "BISWAS",
    address: "VILL/PO-NARNA*VIA-DOMJUR*HOWRAH 711405",
    fullName: "NEMAI CHANDRA BISWAS",
    saID: "SPR4328",
  },
  {
    firstName: "LATE GANENDRA NATH",
    lastName: "CHOUDHURY",
    address: "C/O NIHARENDU DEYCHOUDHURY*PO-RAGA*VIA-ZIRO*ARUNACHAL PRADESH",
    fullName: "LATE GANENDRA NATH CHOUDHURY",
    saID: "SPR4329",
  },
  {
    firstName: "SAILESH CHANDRA",
    lastName: "SAHA",
    address: "29,AVENUE IST ROAD*SANTOSHPUR*KOLKATA 700 075",
    fullName: "SAILESH CHANDRA SAHA",
    saID: "SPR4330",
  },
  {
    firstName: "SANSAR",
    lastName: "MANDAL",
    address: "VILL-THARI*PO-CHHOTIRAN BAHIYAR*DIST-DUMKA-814102",
    fullName: "SANSAR MANDAL",
    saID: "SPR4331",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "MANDAL",
    address: "VILL-PURBA PANCHGACHHIA*PO-GOCHARAN*24PARGANAS (SOUTH)-743391",
    fullName: "GOPAL CHANDRA MANDAL",
    saID: "SPR4332",
  },
  {
    firstName: "MIHIR KUMAR",
    lastName: "DAS",
    address: "SWASTI PATH*RUPNAGAR*GUWAHATI-781032*KAMRUP",
    fullName: "MIHIR KUMAR DAS",
    saID: "SPR4333",
  },
  {
    firstName: "KISHALAY",
    lastName: "MUKHARJEE",
    address:
      "C/O KANTI LAL MUKHERJEE*P,22 ARCADIA EXTENSION*BEHALA*KOLKATA-34*PAN-AEUPM5265K",
    fullName: "KISHALAY MUKHARJEE",
    saID: "SPR4334",
  },
  {
    firstName: "SADHAN KUMAR",
    lastName: "TALUKDAR",
    address:
      "TALPUKUR ROAD*VILL-KODALIA(EAST)*PO-NEW BARRACKPORE*24PARGANAS NORTH-700131",
    fullName: "SADHAN KUMAR TALUKDAR",
    saID: "SPR4335",
  },
  {
    firstName: "MAGARAM",
    lastName: "MONDAL",
    address: "VILL-DHOBAJOLE*PO-NIRISHA*BIRBHUM-731201",
    fullName: "MAGARAM MONDAL",
    saID: "SPR4336",
  },
  {
    firstName: "LATE DINESH CHANDRA",
    lastName: "GOLDAR",
    address: "VILL-GOSABA(ARAMPUR)*PO-GOSABA*24PARGANAS",
    fullName: "LATE DINESH CHANDRA GOLDAR",
    saID: "SPR4337",
  },
  {
    firstName: "STUTIRANJAN",
    lastName: "SARKAR",
    address: "VILL-BAMUNIA*PO-DALELPARA*MURSHIDABAD-742113",
    fullName: "STUTIRANJAN SARKAR",
    saID: "SPR4338",
  },
  {
    firstName: "ANIL MOHAN",
    lastName: "DAS",
    address: '"PRABHU BITAN"*KADAMKANAN*PO-JHARGRAM*PASCHIM MEDINIPUR-721 507.',
    fullName: "ANIL MOHAN DAS",
    saID: "SPR4339",
  },
  {
    firstName: "APURBA RANJAN",
    lastName: "PRAMANICK",
    address: "FR-7/6/3,SOUTH JYANGRA*CALCUTTA 700059",
    fullName: "APURBA RANJAN PRAMANICK",
    saID: "SPR4340",
  },
  {
    firstName: "GONAR PRASAD",
    lastName: "YADAV",
    address: "VILL/PO-KUMARKHAND*MADHEPURA 852 112.",
    fullName: "GONAR PRASAD YADAV",
    saID: "SPR4341",
  },
  {
    firstName: "ANAND KUMAR",
    lastName: "SINGH",
    address:
      "S/O MAHENDRA KUMAR SINGH*N 12/340 DEVPOKHARI BAJARDIHA*VARANASI-221001.",
    fullName: "ANAND KUMAR SINGH",
    saID: "SPR4342",
  },
  {
    firstName: "MILAN",
    lastName: "CHAKRABORTY",
    address: "DEPTT OF MICROBIOLOGY*AIIMS*NEWDELHI 110023",
    fullName: "MILAN CHAKRABORTY",
    saID: "SPR4343",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "BISWAS",
    address:
      "2/52 VIDYUT COLONY*GOBINDA NAGAR*KANPUR-208006*U.P*PAN-ACQPB8677E",
    fullName: "RANJIT KUMAR BISWAS",
    saID: "SPR4344",
  },
  {
    firstName: "SHYAM NARAYAN",
    lastName: "SINGH",
    address: "CHANDAN NAGAR COLONY*PO-RUJA*GHAJIPUR*UTTAR PRADESH",
    fullName: "SHYAM NARAYAN SINGH",
    saID: "SPR4345",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "GUPTA",
    address:
      "I/70,RAPTI NAGAR PHASE-IV*BEHIND JANATA INTER COLLEGE*CHARGAWAN*GORAKHPUR-273001*UP",
    fullName: "ASHOK KUMAR GUPTA",
    saID: "SPR4346",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "KAR",
    address:
      "SATSANG ADHIBESHAN KENDRA*11 GANGA VIHAR*PO-BHUPATALA*DIST-HARIDWAR*U.KHAND-249401",
    fullName: "DILIP KUMAR KAR",
    saID: "SPR4347",
  },
  {
    firstName: "HITENDRA KISHORE",
    lastName: "KUNDU",
    address: "PO-RANAGHAT*NADIA-741201",
    fullName: "HITENDRA KISHORE KUNDU",
    saID: "SPR4348",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "MUKHARJEE",
    address:
      "50,SUBHASH NAGAR 3RD BYE LANE*DUM DUM CANT RLY STN*CALCUTTA 700065",
    fullName: "LATE SUNIL KUMAR MUKHARJEE",
    saID: "SPR4349",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "DAS",
    address: "TALPUKURPARA*PO-RANAGHAT*NADIA-741201*PAN-ACWPD1392A",
    fullName: "MANINDRA NATH DAS",
    saID: "SPR4350",
  },
  {
    firstName: "HADIBANDHU",
    lastName: "PALAI",
    address: "AT-GRAMESWAR*PO-BOLAGARH*DIST-KHURDA-752 066*ODISHA",
    fullName: "HADIBANDHU PALAI",
    saID: "SPR4351",
  },
  {
    firstName: "BHUPESH KANTI",
    lastName: "MANDAL",
    address: "VILL/PO-AMTALA*DIST-MURSHIDABAD-742121*PAN-CBBPM0380D",
    fullName: "BHUPESH KANTI MANDAL",
    saID: "SPR4352",
  },
  {
    firstName: "GANGADHAR",
    lastName: "BINDHANI",
    address: "AT&PO-KARANJMAL*VIA-NAIKANDIHI*BALASORE 756164",
    fullName: "GANGADHAR BINDHANI",
    saID: "SPR4353",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "MAHATO",
    address: "VILL/PO-SIDDIH*DIST-SINGHBHUM",
    fullName: "LATE KALIPADA MAHATO",
    saID: "SPR4354",
  },
  {
    firstName: "LATE MAHESH NARAIN",
    lastName: "DAS",
    address:
      "AT-GANGJALA*POLYTECHNIC ROAD(NEAR FIRE BRIGADE STN)*SAHARSHA 852201",
    fullName: "LATE MAHESH NARAIN DAS",
    saID: "SPR4355",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "MAHATO",
    address: "VILL-BADUA*PO-JHALBARDA*DIST-BOKARO-828134",
    fullName: "AJIT KUMAR MAHATO",
    saID: "SPR4356",
  },
  {
    firstName: "RAM KRIPAL",
    lastName: "SINGH",
    address:
      "ROAD NO-1,QR NO-17*PO-TELCO COLONY*TELCO WORKS*JAMSEDPUR*SINGHBHUM-831004",
    fullName: "RAM KRIPAL SINGH",
    saID: "SPR4357",
  },
  {
    firstName: "LATE BIMALDEO",
    lastName: "SINHA",
    address: "KHABRA ROAD*MUZAFFARPUR-842001.",
    fullName: "LATE BIMALDEO SINHA",
    saID: "SPR4358",
  },
  {
    firstName: "LATE JITENDRA PRASAD",
    lastName: "SUKLA",
    address: "B/24,SACHIVALAYA COLONY*KANKARBAGH(OPP.PESU OFFICE)*PATNA",
    fullName: "LATE JITENDRA PRASAD SUKLA",
    saID: "SPR4359",
  },
  {
    firstName: "BACHCHA",
    lastName: "PRASAD",
    address:
      "AT-HARDHAN BASU LANE*PO-BHAGWANBAJAR*CHHAPRA-841301*PAN NO.BZAPP7955D",
    fullName: "BACHCHA PRASAD",
    saID: "SPR4360",
  },
  {
    firstName: "CHANDESWAR PRASAD",
    lastName: "THAKUR",
    address: "VILL-RASULPUR*PO-BISHNUDUTTPUR*MUZAFFERPUR",
    fullName: "CHANDESWAR PRASAD THAKUR",
    saID: "SPR4361",
  },
  {
    firstName: "RANENDRA NATH",
    lastName: "DUTTA",
    address: "DEB PUKUR*PO-SEWLI TELENIPARA*24 PARGANAS (NORTH)-700121",
    fullName: "RANENDRA NATH DUTTA",
    saID: "SPR4362",
  },
  {
    firstName: "ANKESH KUMAR",
    lastName: "NASKAR",
    address:
      "VILL-NARIKELDANGA*PO-MADHYA SHIBPUR*DISTT-24 PARGANAS (SOUTH)-743372",
    fullName: "ANKESH KUMAR NASKAR",
    saID: "SPR4363",
  },
  {
    firstName: "SATYAJIT",
    lastName: "DAS",
    address:
      "C/O-JOGESH CHANDRA DAS*VILL-BAKSHIGANJ*PO-HILI*DAKSHIN DINAJPUR-733126",
    fullName: "SATYAJIT DAS",
    saID: "SPR4364",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "SARKAR",
    address: "VILL/PO-CHHAGLIA*DIST-DHUBRI-783335*ASSAM",
    fullName: "SUDHIR KUMAR SARKAR",
    saID: "SPR4365",
  },
  {
    firstName: "PRAFULLA MOHAN",
    lastName: "GOSWAMI",
    address:
      "BARSAJAI (BIKASH NAGAR)*NEAR M.V.M.SCHOOL*BELTOLA*GAUHATI-29*KAMRUP",
    fullName: "PRAFULLA MOHAN GOSWAMI",
    saID: "SPR4366",
  },
  {
    firstName: "LATE SANCHAY KUMAR",
    lastName: "GHOSE",
    address:
      "SWASTINIBAS PHARMACY*1/B,BIJAY NAGAR(NEAR NETAJI SANGHA)*PO-NAIHATI*24PARGANAS (N)",
    fullName: "LATE SANCHAY KUMAR GHOSE",
    saID: "SPR4367",
  },
  {
    firstName: "GURUPADA",
    lastName: "SINGHA",
    address: "VILL-BARJURI*GOBINDADHAM*BANKURA 722155*PAN AJQPS2157R",
    fullName: "GURUPADA SINGHA",
    saID: "SPR4368",
  },
  {
    firstName: "LATE AMALENDU",
    lastName: "BISWAS",
    address: "BARANILPUR ROAD,UTTARPARA*PO-SRIPALLY*BURDWAN 713103",
    fullName: "LATE AMALENDU BISWAS",
    saID: "SPR4369",
  },
  {
    firstName: "RAMA CHANDRA",
    lastName: "SAHOO",
    address:
      "READER IN HISTORY*U N S MAHAVIDYALAYA*MUGPAL*JAJPUR-755050*PAN-BBPPS3083N",
    fullName: "RAMA CHANDRA SAHOO",
    saID: "SPR4370",
  },
  {
    firstName: "RAJ KISHORE",
    lastName: "SINGH",
    address:
      "STORE KEEPER*SURVEY SETTLEMENT OFFICE*D.V.C.MORE*HAZARIBAGH-825301*PAN-AIMPS5184R",
    fullName: "RAJ KISHORE SINGH",
    saID: "SPR4371",
  },
  {
    firstName: "LATE BIDIT BHUSAN",
    lastName: "DAS",
    address:
      "LAKSHMISAHAR WARD NO-8*PO-LAKSHMI SAHAR 788152*DIST-HAILAKANDI (ASSAM)",
    fullName: "LATE BIDIT BHUSAN DAS",
    saID: "SPR4372",
  },
  {
    firstName: "SUPEN",
    lastName: "MAHATO",
    address: "VILL-MOAMARI*PO-DAFALAPOTA*DARRANG 784114.",
    fullName: "SUPEN MAHATO",
    saID: "SPR4373",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "MAHATO",
    address: "DHANSURA*CHANCHARA*PURULIA-723148",
    fullName: "GOPAL CHANDRA MAHATO",
    saID: "SPR4374",
  },
  {
    firstName: "DAYAL KRISHNA",
    lastName: "BISWAS",
    address: "VILL-KUSTIA*PO-HANSPUKURIA*NADIA-741160",
    fullName: "DAYAL KRISHNA BISWAS",
    saID: "SPR4375",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "ROY",
    address: "VILL/PO-BAHALPUR*DT-DHUBRI 783370*PAN-ARHPR4812M",
    fullName: "PARESH CHANDRA ROY",
    saID: "SPR4376",
  },
  {
    firstName: "LATE BINOD RANJAN",
    lastName: "CHAKRABORTY",
    address: "WARD NO-6*STATION ROAD*KOKRAJHAR*ASSAM-783 370",
    fullName: "LATE BINOD RANJAN CHAKRABORTY",
    saID: "SPR4377",
  },
  {
    firstName: "NISHI BHUSAN",
    lastName: "CHAKRABARTY",
    address: "PO-DEBOTTOR HASDAHA*DHUBRI-783334*ASSAM",
    fullName: "NISHI BHUSAN CHAKRABARTY",
    saID: "SPR4378",
  },
  {
    firstName: "NARAYAN",
    lastName: "TALUKDAR",
    address: "SATSANG VIHAR;BHANGAGARH*GUWAHATI-781 005*KAMRUP*ASSAM",
    fullName: "NARAYAN TALUKDAR",
    saID: "SPR4379",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "BARMA",
    address: "THANA CHAUPATHI EAST*PO-TUFANGANJ*COOCHBIHAR*PAN-AFXPB7018R",
    fullName: "KRISHNA CHANDRA BARMA",
    saID: "SPR4380",
  },
  {
    firstName: "LILU CHANDRA",
    lastName: "DAS",
    address:
      "VILL-UTTAR BIDYANAGAR*PO-SINGARI BASTI*HOJAI*ASSAM*PAN-APUPD9292E",
    fullName: "LILU CHANDRA DAS",
    saID: "SPR4381",
  },
  {
    firstName: "JIBAN CHANDRA",
    lastName: "ROY",
    address: "AT-LASHKARPUR*PO-KATLAMARI*MALDA-732102",
    fullName: "JIBAN CHANDRA ROY",
    saID: "SPR4382",
  },
  {
    firstName: "SUKANTI",
    lastName: "DASGUPTA",
    address: "VILL/PO-WEST BHUBANBAN*TRIPURA WEST",
    fullName: "SUKANTI DASGUPTA",
    saID: "SPR4383",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "SADHAK",
    address: "C/O P N SADHAK*NORTH BASUDEVPUR*CALCUTTA 56",
    fullName: "MRINAL KANTI SADHAK",
    saID: "SPR4384",
  },
  {
    firstName: "SAGEN",
    lastName: "SARKAR",
    address: "VILL-GOURNAGAR*PO-PAKUAHAT*MALDA-732138",
    fullName: "SAGEN SARKAR",
    saID: "SPR4385",
  },
  {
    firstName: "BHAGABAN",
    lastName: "SAHOO",
    address: "SATYA NAGAR*PO-SEMILIGUDA*KORAPUT-764036.",
    fullName: "BHAGABAN SAHOO",
    saID: "SPR4386",
  },
  {
    firstName: "SANKARSAN",
    lastName: "SAMAL",
    address:
      "VILL-NUATIPILEI*PO-SURAPRATAPPUR*VIA-M K GOLA*DHENKANAL 759024*PAN-DEOPS0916C",
    fullName: "SANKARSAN SAMAL",
    saID: "SPR4387",
  },
  {
    firstName: "VIKASH KUMAR",
    lastName: "SINHA",
    address:
      "WARD NO-37,NR GAYATRI CROCKERY CENTRE*CHITRAGUPTA NAGAR*POKHARIA*BEGUSARAI",
    fullName: "VIKASH KUMAR SINHA",
    saID: "SPR4388",
  },
  {
    firstName: "LATE SATYANARAYAN",
    lastName: "CHOUDHURY",
    address: "KENDRAPARA SATSANG VIHAR*AT-DUHURIA*PO-PANDIRI*KENDRAPARA",
    fullName: "LATE SATYANARAYAN CHOUDHURY",
    saID: "SPR4389",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "BISWAS",
    address: "*SATSANG 814116",
    fullName: "KARTICK CHANDRA BISWAS",
    saID: "SPR4390",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "DAS",
    address: "VILL&PO-NALKORA*VIA-NAZAT*24 PARGANAS (NORTH)-743442",
    fullName: "AJIT KUMAR DAS",
    saID: "SPR4391",
  },
  {
    firstName: "BINAY",
    lastName: "BISWAS",
    address: "AT/PO-BOXIR HAT*COOCH BIHAR-736 131*W.B.",
    fullName: "BINAY BISWAS",
    saID: "SPR4392",
  },
  {
    firstName: "ASTO CHARAN",
    lastName: "KOLEY",
    address: "PO&VILL-KRISHNARAMPUR*HOOGHLY 712702",
    fullName: "ASTO CHARAN KOLEY",
    saID: "SPR4393",
  },
  {
    firstName: "LAKHESWAR",
    lastName: "GOALA",
    address: "SATSANG VIHAR DIGBOI*PO-ITABHATA*TINSUKIA 786171*PAN-AEEPG4879H",
    fullName: "LAKHESWAR GOALA",
    saID: "SPR4394",
  },
  {
    firstName: "KHAGEN CHANDRA",
    lastName: "DEURI",
    address:
      "NAMDEURI HIGHER SECONDARY SCHOOL*PO-NAMDEURI*VIA-KOKILAMUKH*JORHAT*PAN-ACFPD2060A",
    fullName: "KHAGEN CHANDRA DEURI",
    saID: "SPR4395",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "RAY",
    address: "VILL-RAJAKTLI PATH*PO-KISHMAT HANSDA*DHUBRI 783334",
    fullName: "ASWINI KUMAR RAY",
    saID: "SPR4396",
  },
  {
    firstName: "NIRMAL CHANDRA",
    lastName: "PAL",
    address: "TENTUL TALA*PO-MATIGARA*DARJEELING-734010",
    fullName: "NIRMAL CHANDRA PAL",
    saID: "SPR4397",
  },
  {
    firstName: "PRADYUMNA KUMAR",
    lastName: "PATRA",
    address: "AT-MEGHARA*PO-BR CHHANDIA*VIA-DHARAMGARH*KALAHANDI-766015",
    fullName: "PRADYUMNA KUMAR PATRA",
    saID: "SPR4398",
  },
  {
    firstName: "RAJ KISHORE",
    lastName: "SHARMA",
    address: "C/O E.E.P.W.D.OFFICE*PO-DONKAMOKAM*KARBI ANGLONG-782485*ASSAM",
    fullName: "RAJ KISHORE SHARMA",
    saID: "SPR4399",
  },
  {
    firstName: "LOKANATH",
    lastName: "SENAPATI",
    address: "AT-NUASUNGURA*PO-SUNGURA*VIA-DHANMANDAL*CUTTACK 754024",
    fullName: "LOKANATH SENAPATI",
    saID: "SPR4400",
  },
  {
    firstName: "SARAT KUMAR",
    lastName: "JENA",
    address: "AT&PO-KENDUPATNA*CUTTACK 754203",
    fullName: "SARAT KUMAR JENA",
    saID: "SPR4401",
  },
  {
    firstName: "JATINDRA NATH",
    lastName: "DEKA",
    address: "VILL-BARDHANARA*PO-DAKSHINGAON*NALBARI 781 350",
    fullName: "JATINDRA NATH DEKA",
    saID: "SPR4402",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "BHOWMIK",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "LATE BIMAL CHANDRA BHOWMIK",
    saID: "SPR4403",
  },
  {
    firstName: "KAMALESH",
    lastName: "BANIK",
    address: "SURI THAKURBARI*PO-KARIDHYA*BIRBHUM",
    fullName: "KAMALESH BANIK",
    saID: "SPR4404",
  },
  {
    firstName: "SANKAR KUMAR",
    lastName: "DUTTA",
    address:
      "1403/E,MAHENDRA BANERJEE ROAD*PO-BEHALA*CALCUTTA-700060*PAN-ANKPD9137E",
    fullName: "SANKAR KUMAR DUTTA",
    saID: "SPR4405",
  },
  {
    firstName: "RAGHUNANDAN",
    lastName: "DUTTA",
    address: "SATSANG PRESS*PO-SATSANG*DEOGHAR-814116*PAN-BIHPD1372C",
    fullName: "RAGHUNANDAN DUTTA",
    saID: "SPR4406",
  },
  {
    firstName: "MD NURUL",
    lastName: "ISLAM",
    address:
      "SATSANG VIHAR;ISLAMPUR* PO-ISLAMPUR*UTTAR DINAJPUR-733202*PAN-AALPI7054F",
    fullName: "MD NURUL ISLAM",
    saID: "SPR4407",
  },
  {
    firstName: "RADHA MOHAN",
    lastName: "BANDOPADHYAY",
    address: "THAKUR BANGLOW*PO-SATSANG 814116*SATSANG",
    fullName: "RADHA MOHAN BANDOPADHYAY",
    saID: "SPR4408",
  },
  {
    firstName: "SUBRATA",
    lastName: "CHAKRABORTY",
    address:
      '"SOROSHI BHAWAN"*PO-SATSANG-814116*DIST-DEOGHAR*JHARKHAND*PAN-AKFPC3121M',
    fullName: "SUBRATA CHAKRABORTY",
    saID: "SPR4409",
  },
  {
    firstName: "LATE SHYAMAL KUMAR",
    lastName: "SAMANTA",
    address: "SATSANG-814116.",
    fullName: "LATE SHYAMAL KUMAR SAMANTA",
    saID: "SPR4410",
  },
  {
    firstName: "BIRUPAKSHA",
    lastName: "RAY",
    address: "SATSANG*DEOGHAR*PIN-814116.",
    fullName: "BIRUPAKSHA RAY",
    saID: "SPR4411",
  },
  {
    firstName: "NIRANJAN",
    lastName: "BISWAS",
    address: "*SATSANG 814116",
    fullName: "NIRANJAN BISWAS",
    saID: "SPR4412",
  },
  {
    firstName: "PHANI BHUSAN",
    lastName: "PATHAK",
    address: "*SATSANG 814116",
    fullName: "PHANI BHUSAN PATHAK",
    saID: "SPR4413",
  },
  {
    firstName: "UGRASEN",
    lastName: "BARIK",
    address: "AT-MACHHAMARA*PO-NEGIMUNDA*VIA-SOHELLA*SAMBALPUR 768033",
    fullName: "UGRASEN BARIK",
    saID: "SPR4414",
  },
  {
    firstName: "BIPIN BIHARI",
    lastName: "NAIK",
    address: "VILL-HIRAPUR*PO-GARH SRIRAMPUR*VIA-BALAKATI*KHURDA 752100",
    fullName: "BIPIN BIHARI NAIK",
    saID: "SPR4415",
  },
  {
    firstName: "BIPIN BIHARI",
    lastName: "ROUT",
    address:
      "AT-ALIKANA JAINABAD*PO-ALAVAR*VIA-BALIKUDA*JAGATSINGHPUR*ODISHA 754108",
    fullName: "BIPIN BIHARI ROUT",
    saID: "SPR4416",
  },
  {
    firstName: "LATE ARABINDA KUMAR",
    lastName: "HOWLI",
    address: "VILL-DEFENCE PALLY*PO-BUDBUD*BARDDHAMAN-713403.",
    fullName: "LATE ARABINDA KUMAR HOWLI",
    saID: "SPR4417",
  },
  {
    firstName: "GOUR CHANDRA",
    lastName: "PATRA",
    address: "VILL/PO-NALKORA*VIA-NAZAT*24PARGANAS (NORTH)-743442",
    fullName: "GOUR CHANDRA PATRA",
    saID: "SPR4418",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MONDAL",
    address:
      "VILL-SWARUPKATHI*PO-AMBERIA*P.S.HINGALGANJ*24PARGANAS (NORTH)-743435",
    fullName: "NIRANJAN MONDAL",
    saID: "SPR4419",
  },
  {
    firstName: "LATE JAHARLAL",
    lastName: "GHOSE",
    address: "SATSANG VIHAR*BHANGAGHAR*GOUHATI 5",
    fullName: "LATE JAHARLAL GHOSE",
    saID: "SPR4420",
  },
  {
    firstName: "LATE SATYENDRA PRASAD",
    lastName: "SINGHAROY",
    address: "SATSANG SWASTI SIBIR*PO-KRISHNAGAR*NADIA.",
    fullName: "LATE SATYENDRA PRASAD SINGHAROY",
    saID: "SPR4421",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "BERA",
    address: "SATSANG THAKURBARI*MIRZABAZAR*PO/DIST-PASCHIM MEDINIPUR-721101",
    fullName: "AJIT KUMAR BERA",
    saID: "SPR4422",
  },
  {
    firstName: "DIPI PRASAD",
    lastName: "GHOSH",
    address: "VILL/PO-BARABAINAN*DIST-BURDWAN-713421",
    fullName: "DIPI PRASAD GHOSH",
    saID: "SPR4423",
  },
  {
    firstName: "RAMPADA",
    lastName: "BERA",
    address: "VILL-BHUBANKALUA*PO-KELOMAL*PURBA MEDINIPUR-721627.",
    fullName: "RAMPADA BERA",
    saID: "SPR4424",
  },
  {
    firstName: "BAKUL",
    lastName: "KURMI",
    address: "AT-BARBIL*PO-GHATUA*VIA-THELAMARA*DIST-SONITPUR 784149*(ASSAM)",
    fullName: "BAKUL KURMI",
    saID: "SPR4425",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "SAHU",
    address:
      "AT/PO-BANDHAGARH*VIA-PHIRINGIA*DIST-KANDHAMAL-762011*PAN-CRFPS0216N",
    fullName: "BIJAY KUMAR SAHU",
    saID: "SPR4426",
  },
  {
    firstName: "NRUSINGHA CHARAN",
    lastName: "SENAPATI",
    address: "AT-SARIDA*PO-SAMANA*VIA-FAKIRPUR*KEONJHAR-758022",
    fullName: "NRUSINGHA CHARAN SENAPATI",
    saID: "SPR4427",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "MISHRA",
    address: "AT-MANGARAJAPUR*PO-S MANGARAJAPUR*VIA-BATIPARA*CUTTACK 754218.",
    fullName: "RAMAKANTA MISHRA",
    saID: "SPR4428",
  },
  {
    firstName: "UMA",
    lastName: "KAFLE",
    address: "CHANDAMARI BANGALI*PO-KOWRIPATHAR*SONITPUR-784170.",
    fullName: "UMA KAFLE",
    saID: "SPR4429",
  },
  {
    firstName: "PRASANTA",
    lastName: "DEBROY",
    address: "JAIL ROAD BOY'S HIGH SCHOOL*PO-SHILLONG*MEGHALAYA 793001",
    fullName: "PRASANTA DEBROY",
    saID: "SPR4430",
  },
  {
    firstName: "BIMALANANDA",
    lastName: "MAHATO",
    address: "VILL&PO-BHOURIDIH*VIA-PARA*PURULIA-723155",
    fullName: "BIMALANANDA MAHATO",
    saID: "SPR4431",
  },
  {
    firstName: "RATANTI LAL",
    lastName: "KAR",
    address: "AT-MANMATHANAGAR*PO-DANKUNI*HOOGHLY  711224.",
    fullName: "RATANTI LAL KAR",
    saID: "SPR4432",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "JOARDAR",
    address: "SHIB TIRTHA*SATSANG 814116*DEOGHAR",
    fullName: "LATE NIRANJAN JOARDAR",
    saID: "SPR4433",
  },
  {
    firstName: "SUBINAY",
    lastName: "PAL",
    address: "OLD KALIBARI LANE *KRISHNA NAGAR*AGARTALA*TRIPURA WEST",
    fullName: "SUBINAY PAL",
    saID: "SPR4434",
  },
  {
    firstName: "ISTARANJAN",
    lastName: "HALDAR",
    address: "PO-RAGHUNATHPUR*PURULIA-723133",
    fullName: "ISTARANJAN HALDAR",
    saID: "SPR4435",
  },
  {
    firstName: "LATE UMESH",
    lastName: "JHA",
    address: "O/O THE CHIEF ENGINEER(POWER)*KOHIMA*NAGALAND 797001",
    fullName: "LATE UMESH JHA",
    saID: "SPR4436",
  },
  {
    firstName: "MALAY KUMAR",
    lastName: "DAM",
    address:
      "VILL-SHIV MANDIR*B.ED COLLEGE ROAD*PO-KADAMTALA*DARJEELING-734011*PAN-ACQPD1073J",
    fullName: "MALAY KUMAR DAM",
    saID: "SPR4437",
  },
  {
    firstName: "BHUBANESWAR PRASAD",
    lastName: "ACHARYA",
    address: "AT-RKV-17,HAMIRPUR*PO-ROURKELA-769003*DIST-SUNDARGARH",
    fullName: "BHUBANESWAR PRASAD ACHARYA",
    saID: "SPR4438",
  },
  {
    firstName: "MADAN MOHAN",
    lastName: "DUTTA",
    address: "MANIMALA SCHOOL PARA*PO-BALURGHAT*DIST-DAKSHIN DINAJPUR-733101",
    fullName: "MADAN MOHAN DUTTA",
    saID: "SPR4439",
  },
  {
    firstName: "BATAKRUSHNA",
    lastName: "BEHERA",
    address: "AT-DOVABIL*PO-JAYANTARA*VIA-DASARATHIPUR*JAJPUR-755006",
    fullName: "BATAKRUSHNA BEHERA",
    saID: "SPR4440",
  },
  {
    firstName: "BALADEV",
    lastName: "MAHALI",
    address:
      "AT-DAHALAPUR*PO-NADIABARAI*VIA-KARILOPATANA*KENDRAPARA*PAN-BDJPM2453A",
    fullName: "BALADEV MAHALI",
    saID: "SPR4441",
  },
  {
    firstName: "JITENDRA MOHAN",
    lastName: "SILSARMA",
    address: "KALAHBHANGA M E SCHOOL*PO-BARPETA ROAD*BARPETA-781315*ASSAM",
    fullName: "JITENDRA MOHAN SILSARMA",
    saID: "SPR4442",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "DASH",
    address:
      '"DHRUTI KUNJA",HIG-439*K-5,KALINGA NAGAR*PO-PATRAPADA*BHUBANESWAR-751019*AGMPD7569H',
    fullName: "RAMESH CHANDRA DASH",
    saID: "SPR4443",
  },
  {
    firstName: "RANENDRA",
    lastName: "SUTRADHAR",
    address: "L I C I AIZAWL*ZARKAWT;ZORUN BLDG*AIZAWL*MIZORAM*PAN.AFLPS6213H",
    fullName: "RANENDRA SUTRADHAR",
    saID: "SPR4444",
  },
  {
    firstName: "SUDIPTA KUMAR",
    lastName: "MONDAL",
    address:
      "VILL-GHOSHPARA 30 BIGHA*PO-ADCONAGAR*PS-MOGRA*DIST-HOOGHLY-712148",
    fullName: "SUDIPTA KUMAR MONDAL",
    saID: "SPR4445",
  },
  {
    firstName: "SHANKAR",
    lastName: "HAJRA",
    address: "CHINAKURI NO-3*PO-SUNDARCHAK*BURDWAN-713360",
    fullName: "SHANKAR HAJRA",
    saID: "SPR4446",
  },
  {
    firstName: "BIPUL CHANDRA",
    lastName: "SAMAJDAR",
    address: "SHIBTALI CHAKBHABANI*PO-BALURGHAT*DAKSHIN DINAJPUR 733 101",
    fullName: "BIPUL CHANDRA SAMAJDAR",
    saID: "SPR4447",
  },
  {
    firstName: "DHIREN",
    lastName: "BANDOPADHYAY",
    address: "VILL&PO-SAMSAR*BANKURA",
    fullName: "DHIREN BANDOPADHYAY",
    saID: "SPR4448",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "PAL",
    address: "VILL-NALABARA*PO-SARAPUL*SWARUP NAGAR*24PARGANAS (NORTH)-743286",
    fullName: "ASHOK KUMAR PAL",
    saID: "SPR4449",
  },
  {
    firstName: "RAMA NANDA",
    lastName: "RAY",
    address: "",
    fullName: "RAMA NANDA RAY",
    saID: "SPR4450",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "PANIGRAHI",
    address: "AT-ANUGRAHAPUR*PO-RAMPUR*VIA-MOTTO*BHADRAK-756132*PAN-DEFPP9894R",
    fullName: "SURENDRA NATH PANIGRAHI",
    saID: "SPR4451",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "DHAR",
    address: "AMBIKA PHARMACY*PO-ALIPURDUAR*JALPAIGURI",
    fullName: "LATE ANIL KUMAR DHAR",
    saID: "SPR4452",
  },
  {
    firstName: "SIBAMANGAL",
    lastName: "BISWAS",
    address:
      "KRISHNANAGAR*BIJAY KR CHOWMOHANI (W)*PO-AGARTALA*WEST TRIPURA-799001*PAN-ACSPB1353M",
    fullName: "SIBAMANGAL BISWAS",
    saID: "SPR4453",
  },
  {
    firstName: "LATE SARAT KUMAR",
    lastName: "SAHA",
    address: "VILL-KOPAI*PO-ALBANDHA*BIRBHUM 731204.",
    fullName: "LATE SARAT KUMAR SAHA",
    saID: "SPR4454",
  },
  {
    firstName: "SUDHANGSHU SEKHAR",
    lastName: "TRIPATHI",
    address: "VILL&PO-MOHANPUR*MIDNAPUR",
    fullName: "SUDHANGSHU SEKHAR TRIPATHI",
    saID: "SPR4455",
  },
  {
    firstName: "GOLOK NANDAN",
    lastName: "PARBAT",
    address: "C/O-S K PARBAT*CHANDMARI DANGA*PO-BANKURA*BANKURA 722101",
    fullName: "GOLOK NANDAN PARBAT",
    saID: "SPR4456",
  },
  {
    firstName: "LATE PABITRA SANKAR",
    lastName: "KAR",
    address: "MANMATHA NAGAR*PO-DANKUNI*HOOGHLY",
    fullName: "LATE PABITRA SANKAR KAR",
    saID: "SPR4457",
  },
  {
    firstName: "ANUP KUMAR",
    lastName: "MANDAL",
    address: "VILL&PO-DURGAMANDAP*VIA-SANDESHKHALI*24 PARGANAS NORTH-743446",
    fullName: "ANUP KUMAR MANDAL",
    saID: "SPR4458",
  },
  {
    firstName: "SUDHANGSHU SEKHAR",
    lastName: "PAUL",
    address: "PO/VILL-KATIRAIL*DIST-CACHAR 788805*ASSAM",
    fullName: "SUDHANGSHU SEKHAR PAUL",
    saID: "SPR4459",
  },
  {
    firstName: "NADSURJYA",
    lastName: "GIRI",
    address: "SATSANG SWASTI SIBIR*PO-KRISHNANAGAR*NADIA-741101",
    fullName: "NADSURJYA GIRI",
    saID: "SPR4460",
  },
  {
    firstName: "DHRITI NARAYAN",
    lastName: "MANDAL",
    address: "SATSANG SWASTI SHIBIR*KRISHNAGAR*NADIA-741101",
    fullName: "DHRITI NARAYAN MANDAL",
    saID: "SPR4461",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "PARIJA",
    address: "MUNICIPAL OFFICE, JEYPORE*PO-JEYPORE*KORAPUT 764 001.",
    fullName: "SISIR KUMAR PARIJA",
    saID: "SPR4462",
  },
  {
    firstName: "JAGAT BANDHU",
    lastName: "SARKAR",
    address: "VILL-BORBORISHA*PO-KOLAGHAT*MIDNAPORE",
    fullName: "JAGAT BANDHU SARKAR",
    saID: "SPR4463",
  },
  {
    firstName: "ANANDAMOY",
    lastName: "SAU",
    address: "SECTOR-1C,QRT.NO.805*B.S.CITY*DHANBAD 827001*PAN-AGKPS6087R",
    fullName: "ANANDAMOY SAU",
    saID: "SPR4464",
  },
  {
    firstName: "LATE BIJAY KUMAR",
    lastName: "ADHIKARI",
    address: "GOSSAIGAON W/NO-4*PO-GOSSAIGAON*KOKRAJHAR 783 360",
    fullName: "LATE BIJAY KUMAR ADHIKARI",
    saID: "SPR4465",
  },
  {
    firstName: "LATE CHAKRADHAR",
    lastName: "MUDULI",
    address: "AT-SABALANG*PO-RAYPUR*VIA-JANKIA*PS-PANAS*PURI 752020",
    fullName: "LATE CHAKRADHAR MUDULI",
    saID: "SPR4466",
  },
  {
    firstName: "JAGADISH",
    lastName: "DHAR",
    address:
      "VILL-PASIGHAT BAZAR*PO-PASIGHAT*EAST SIANG*ARUNACHALPRADESH  791102.",
    fullName: "JAGADISH DHAR",
    saID: "SPR4467",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "DAS",
    address: "VILL&PO-BINDIPARA*VIA-ALIPURDUAR*JALPAIGURI-736123",
    fullName: "MANINDRA NATH DAS",
    saID: "SPR4468",
  },
  {
    firstName: "BIDYUT KUMAR",
    lastName: "CHOWDHURY",
    address:
      "VILL-CHAKLALPUR*PO-RADHAMOHANPUR*PASCHIM MEDINIPUR-721160*PAN NO-AUSPC0914H",
    fullName: "BIDYUT KUMAR CHOWDHURY",
    saID: "SPR4469",
  },
  {
    firstName: "HARISH CHANDRA",
    lastName: "MAHATO",
    address: "VILL-JHUNJHKI*PO-KHARSAWAN*SINGHBHUM-833216",
    fullName: "HARISH CHANDRA MAHATO",
    saID: "SPR4470",
  },
  {
    firstName: "GOPAL PRASAD",
    lastName: "SINGH",
    address:
      "C/O AKHILESH KUMAR AMUL*SUNDARI BHAWAN*PO-PAKRI*ARRAH*BHOJPUR 802301*PAN-ACNPS3098M",
    fullName: "GOPAL PRASAD SINGH",
    saID: "SPR4471",
  },
  {
    firstName: "RAJ KUMAR",
    lastName: "SINGH",
    address: "H/O SHYAMSUNDER SINGH*GUJRAL PATH*KESHARI NAGAR*PATNA 800024",
    fullName: "RAJ KUMAR SINGH",
    saID: "SPR4472",
  },
  {
    firstName: "CHENIRAM",
    lastName: "KURMI",
    address: "VILL-BORBILL*PO-GHATUA*SONITPUR",
    fullName: "CHENIRAM KURMI",
    saID: "SPR4473",
  },
  {
    firstName: "LATE SITARAM",
    lastName: "PANDIT",
    address: "VILL-SARBODAYNAGAR*PO-MAGHARA*VIA-BIHARSARIF*NALANDA 803101",
    fullName: "LATE SITARAM PANDIT",
    saID: "SPR4474",
  },
  {
    firstName: "GANESH",
    lastName: "BHAGAT",
    address: "VILL-IBRAHIMPUR*PO-JICHHO*VIA-SABOUR*BHAGALPUR 813210",
    fullName: "GANESH BHAGAT",
    saID: "SPR4475",
  },
  {
    firstName: "KAUSHAL KISHORE",
    lastName: "SAH",
    address: "DESHBANDHU PARA*PO-FALAKATA*JALPAIGURI-735211",
    fullName: "KAUSHAL KISHORE SAH",
    saID: "SPR4476",
  },
  {
    firstName: "HARIPADA",
    lastName: "MISTRY",
    address: "2 NILACHAL KANAN*PO-BIRATI*CALCUTTA 700051",
    fullName: "HARIPADA MISTRY",
    saID: "SPR4477",
  },
  {
    firstName: "BANAMALI",
    lastName: "BISWAS",
    address:
      "PALASHI PARA(BANKIMPALLI)*PO-PALASHIPARA*PS-TEHATTA*DT-NADIA 741155",
    fullName: "BANAMALI BISWAS",
    saID: "SPR4478",
  },
  {
    firstName: "DULAL",
    lastName: "MANKI",
    address: "KESORGURI (BARAHAPJAN)*PO-BARAHAPJAN*TINSUKIA-786150",
    fullName: "DULAL MANKI",
    saID: "SPR4479",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "SONI",
    address: "RANI SATTI MANDIR*GAMITOLA*PO&DIST-KATIHAR.",
    fullName: "RADHESHYAM SONI",
    saID: "SPR4480",
  },
  {
    firstName: "DEOKANT",
    lastName: "JHA",
    address: "AT&PO-GAMAIL*VIA-BEHARIGANJ*MADHEYPURA    *PAN-ADYPJ1871D",
    fullName: "DEOKANT JHA",
    saID: "SPR4481",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "VARMA",
    address: "ADVOCATE,POOR COTTAGE*SUPOUL*SAHARSA-852131",
    fullName: "ANANTA KUMAR VARMA",
    saID: "SPR4482",
  },
  {
    firstName: "KISHORE CHANDRA",
    lastName: "JENA",
    address: "AT-NALAKUL*PO-KOTAPUR*JAJPUR 754550*PAN-AYSPJ1709R",
    fullName: "KISHORE CHANDRA JENA",
    saID: "SPR4483",
  },
  {
    firstName: "KISHORE CHANDRA",
    lastName: "DAS",
    address: "PLOT NO 441*BOMIKHAL*PO-RASULGARH*BHUBANESWAR-751010*KHURDA",
    fullName: "KISHORE CHANDRA DAS",
    saID: "SPR4484",
  },
  {
    firstName: "PHANIDHAR",
    lastName: "NATH",
    address: "REGIONAL MUGA RESEARCH STN./CSB*PO-BOKO-781123*KAMRUP*ASSAM",
    fullName: "PHANIDHAR NATH",
    saID: "SPR4485",
  },
  {
    firstName: "BANSHIDHARA",
    lastName: "MAHANTA",
    address:
      "PRAFULLA NAGAR WARD NO-12*BARIPADA*DIST-MAYURBHANJ 757001*PAN-ACJPM3304J",
    fullName: "BANSHIDHARA MAHANTA",
    saID: "SPR4486",
  },
  {
    firstName: "SAROJIT KUMAR",
    lastName: "BHUNIA",
    address:
      "VILL-KASHIGANJ*PO-KHIRPAI*PASCHIM MEDINIPUR-721232*PAN-AWMPB6879N",
    fullName: "SAROJIT KUMAR BHUNIA",
    saID: "SPR4487",
  },
  {
    firstName: "LATE NABATARAN",
    lastName: "MANDAL",
    address: "C/O SHIBAPADA SWARNAKAR*VILL-PARBATIPUR*PO-NAFARGANJ*24PARGANAS",
    fullName: "LATE NABATARAN MANDAL",
    saID: "SPR4488",
  },
  {
    firstName: "KALICHAND",
    lastName: "DAS",
    address:
      "C/O-M/S P C METTEL*VILL&PO-KHARIJAKAKRIBARI*COOCH BEHAR-736179*PAN.ACLPD0770D",
    fullName: "KALICHAND DAS",
    saID: "SPR4489",
  },
  {
    firstName: "DEBNATH",
    lastName: "MUKHARJEE",
    address: "54/1 MAIRA DANGA ROAD*PO-BARANAGAR*CALCUTTA 36*PAN-DCFPM8188F.",
    fullName: "DEBNATH MUKHARJEE",
    saID: "SPR4490",
  },
  {
    firstName: "SANTI RANJAN",
    lastName: "SARKAR",
    address:
      "VILL-BHATTAPUKUR SARDAR PUKURPAR*PO-ARUNDHUTINAGAR*AGARTALA*TRIPURA",
    fullName: "SANTI RANJAN SARKAR",
    saID: "SPR4491",
  },
  {
    firstName: "AJAYA KUMAR",
    lastName: "ACHARYA",
    address:
      "SAFETY & ENVT.,OIL INDIA LTD*AT/PO-DULIAJAN*DT-DIBRUGARH*ASSAM 786602*(ACGPA8703Q)",
    fullName: "AJAYA KUMAR ACHARYA",
    saID: "SPR4492",
  },
  {
    firstName: "PRASANNA KUMAR",
    lastName: "DEO",
    address:
      "AT-SUNDARA*NEAR SMITA COMPLEX*PO/VIA-BARBIL*KEONJHAR-758 035*PAN-ACIPD4265E",
    fullName: "PRASANNA KUMAR DEO",
    saID: "SPR4493",
  },
  {
    firstName: "LATE NISHIKANTA",
    lastName: "CHATTERJEE",
    address: "KHULNA*BANGLADESH",
    fullName: "LATE NISHIKANTA CHATTERJEE",
    saID: "SPR4494",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "GHOSE",
    address: "VILL&PO-HAKIMPUR*24PARGANAS (NORTH)-743273",
    fullName: "GOBINDA CHANDRA GHOSE",
    saID: "SPR4495",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "SINGH",
    address:
      "S/O DEBENDRA NATH SINGH*AT-KHATBINSAHI*PO-BUXIBAZAR*CUTTACK-753001*PAN AVFPS0291M",
    fullName: "PRASANTA KUMAR SINGH",
    saID: "SPR4496",
  },
  {
    firstName: "LATE MURALI BHUSAN",
    lastName: "MUKHERJEE",
    address: "KAPALI PARA*PO-CHANDAN NAGAR*HOOGHLY-712136",
    fullName: "LATE MURALI BHUSAN MUKHERJEE",
    saID: "SPR4497",
  },
  {
    firstName: "LATE BIMAL KRISHNA",
    lastName: "HALDAR",
    address: "MIRIDHA MARKET*PO-GOURANGA NAGAR*CALCUTTA-700059",
    fullName: "LATE BIMAL KRISHNA HALDAR",
    saID: "SPR4498",
  },
  {
    firstName: "SUDAM",
    lastName: "BISWAL",
    address: "AT-TARAPI*PO-SORAN*VIA-KUHURI*KHURDA 752027*ORISSA",
    fullName: "SUDAM BISWAL",
    saID: "SPR4499",
  },
  {
    firstName: "RAJENDRA KUMAR",
    lastName: "SHARMA",
    address:
      "PLOT NO-GA/480*PO-SAILASHREE VIHAR*BHUBANESWAR-751021*PAN-ADBPS8017P",
    fullName: "RAJENDRA KUMAR SHARMA",
    saID: "SPR4500",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "MAHANTY",
    address:
      "O/O THE D.M.(C),OFDC LTD*AT/PO-KARANJIA*MAYURBHANJ-757037*PAN-AGRPM9039L",
    fullName: "SANTOSH KUMAR MAHANTY",
    saID: "SPR4501",
  },
  {
    firstName: "ANANDA",
    lastName: "MAHATO",
    address: "VILL/PO-PATKUM*DT-SINGBHUM",
    fullName: "ANANDA MAHATO",
    saID: "SPR4502",
  },
  {
    firstName: "VIDYASAGAR",
    lastName: "SINGH",
    address: "AT-BARIDIH*PO-HANSDIHA*DIST-DUMKA",
    fullName: "VIDYASAGAR SINGH",
    saID: "SPR4503",
  },
  {
    firstName: "NIKHIL KUMAR",
    lastName: "KAR",
    address: "PO-MEMARI*BURDWAN-713146*PAN NO-ACWPK9007F",
    fullName: "NIKHIL KUMAR KAR",
    saID: "SPR4504",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "SINGH",
    address: "AT-BHILAIHAT*PO-DANGOL*VIA-BARSOIGHAT*KATIHAR",
    fullName: "SUBHAS CHANDRA SINGH",
    saID: "SPR4505",
  },
  {
    firstName: "RAJENDRA",
    lastName: "CHANDA",
    address: "",
    fullName: "RAJENDRA CHANDA",
    saID: "SPR4506",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "PRADHAN",
    address: "SATSANG VIHAR,SONEPUR*PO-SONEPUR*DIST-SUBARNAPUR*PAN-BEIPP1643H",
    fullName: "PRAFULLA KUMAR PRADHAN",
    saID: "SPR4507",
  },
  {
    firstName: "NATABAR",
    lastName: "OJHA",
    address: "MAKUBAN*NUASAHI*NABAKALEBAR ROAD*PURI",
    fullName: "NATABAR OJHA",
    saID: "SPR4508",
  },
  {
    firstName: "LATE MARKANDAY",
    lastName: "TRIPATHI",
    address: "R M VIDYALAYA,CHABUA*PO-CHABUA*DIBRUGARH 786184",
    fullName: "LATE MARKANDAY TRIPATHI",
    saID: "SPR4509",
  },
  {
    firstName: "BODHABISHNU",
    lastName: "MANDAL",
    address: "VILL-GUPTIPUR*PO-SIMLON*BURDWAN-713425",
    fullName: "BODHABISHNU MANDAL",
    saID: "SPR4510",
  },
  {
    firstName: "JUGAL CHANDRA",
    lastName: "MAITI",
    address: "VILL-GOURANGAPUR*PO-NANGULPARA*HOOGHLY-712406",
    fullName: "JUGAL CHANDRA MAITI",
    saID: "SPR4511",
  },
  {
    firstName: "RAMONI KANTA",
    lastName: "BASUMATARI",
    address: "VILL-NO-2,SIBPUR GAON*PO-GORMARA*SONITPUR-784111.",
    fullName: "RAMONI KANTA BASUMATARI",
    saID: "SPR4512",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "SAHA",
    address: "NETAJEE PATH BY LANE-2*UDALBAKRA*LAL GANESH*GAUHATI-34.",
    fullName: "SUBAL CHANDRA SAHA",
    saID: "SPR4513",
  },
  {
    firstName: "MUKUNDA",
    lastName: "MASHAHARI",
    address: "VILL-BHALUKAMARI*PO-DUFALA POTA(ORANG)*DARRANG",
    fullName: "MUKUNDA MASHAHARI",
    saID: "SPR4514",
  },
  {
    firstName: "KALIPADA",
    lastName: "RAY",
    address:
      "RTD.TEACHER*AT-JUGANI CAMP*PO-J-KALAR*VIA-PHARASGAON CAMP*BASTAR 494229*M.P.",
    fullName: "KALIPADA RAY",
    saID: "SPR4515",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "PARIDA",
    address: "AT-JEMADEIPUR*PO-HARIPUR JEMADEIPUR*VIA-SUKINDA*CUTTACK 755018",
    fullName: "KAILASH CHANDRA PARIDA",
    saID: "SPR4516",
  },
  {
    firstName: "KANAILAL",
    lastName: "GHOSH",
    address: "VILL-GANGAPUR*PO-DUTTA PUKUR*24 PARGANAS NORTH-743248",
    fullName: "KANAILAL GHOSH",
    saID: "SPR4517",
  },
  {
    firstName: "SEKHAR",
    lastName: "CHAUDHURY",
    address: "B-14, COLLINS PATH*BIDHAN NAGAR*DURGAPUR-713212*BURDWAN.",
    fullName: "SEKHAR CHAUDHURY",
    saID: "SPR4518",
  },
  {
    firstName: "LATE DILLIP KUMAR",
    lastName: "MOHANTY",
    address: "AT-KANIA*PO-BALIKUDA*JAGATSINGHPUR 754108",
    fullName: "LATE DILLIP KUMAR MOHANTY",
    saID: "SPR4519",
  },
  {
    firstName: "PRANENDU",
    lastName: "GOSWAMI",
    address: "NALINI APARTMENT*370 PARNASHREE*KOLKATA-700060.",
    fullName: "PRANENDU GOSWAMI",
    saID: "SPR4520",
  },
  {
    firstName: "LATE HEMANGA",
    lastName: "DASGUPTA",
    address: "SATSANG*DEGHAR",
    fullName: "LATE HEMANGA DASGUPTA",
    saID: "SPR4521",
  },
  {
    firstName: "RATNAKAR",
    lastName: "PANDA",
    address: "AT&PO-BASTA*BALASORE-756029",
    fullName: "RATNAKAR PANDA",
    saID: "SPR4522",
  },
  {
    firstName: "KRIPA",
    lastName: "KAMAN",
    address: "VILL-KANANGURI*PO-JONAI*DHEMAJI.",
    fullName: "KRIPA KAMAN",
    saID: "SPR4523",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "DAS",
    address: "3/60,MAHAJATI NAGAR*PO-AGARPARA*24PARGANAS (NORTH)-700109",
    fullName: "RABINDRA NATH DAS",
    saID: "SPR4524",
  },
  {
    firstName: "PHANINDRA NATH",
    lastName: "DAS",
    address:
      "3/60,MAHAJATI NAGAR*PO-AGARPARA*24PARGANAS (NORTH)-700109*PAN-ADCPD4171G.",
    fullName: "PHANINDRA NATH DAS",
    saID: "SPR4525",
  },
  {
    firstName: "MADAN KUMAR",
    lastName: "MANDAL",
    address: "C/O J MANDAL*ICHAPUR*BRAHMANPARA*24PARGANAS (NORTH)-743144",
    fullName: "MADAN KUMAR MANDAL",
    saID: "SPR4526",
  },
  {
    firstName: "KISHORE CHANDRA",
    lastName: "NAYAK",
    address: "AT-UDAYAPATANA*PO-SANKHACHILA*DIST-JAJPUR-755015*ORISSA",
    fullName: "KISHORE CHANDRA NAYAK",
    saID: "SPR4527",
  },
  {
    firstName: "JAGANNATH",
    lastName: "KHATUA",
    address:
      "C/O SUKUMAR KHATUA*AT-BHUDAN COLONY*PO/DIST-KORAPUT-764020*PAN-BBHPK1514N",
    fullName: "JAGANNATH KHATUA",
    saID: "SPR4528",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "GARTIA",
    address: "AT-SATSANG VIHAR,ANGUL*PO/DIST-ANGUL-759122",
    fullName: "KRISHNA CHANDRA GARTIA",
    saID: "SPR4529",
  },
  {
    firstName: "ADHIKARI MRITYUNJAY",
    lastName: "DASH",
    address:
      "PLOT NO-4452/6405*JHARANA SAHI*BADAGADA*BHUBANESWAR-751018*PAN-AIHPD5690G",
    fullName: "ADHIKARI MRITYUNJAY DASH",
    saID: "SPR4530",
  },
  {
    firstName: "LATE SUNIRMAL",
    lastName: "MAITI",
    address:
      "C/O PUNJAB NATIONAL BANK*SADAR BAZAR*PO/DT-BILASPUR*CHHATISGARH-495001",
    fullName: "LATE SUNIRMAL MAITI",
    saID: "SPR4531",
  },
  {
    firstName: "HARISH CHANDRA",
    lastName: "BHOIR",
    address:
      "SATSANG MANDIR BANGALI MARKET*PO-KIRANDUL*DIST-BASTAR 494556*M.P.",
    fullName: "HARISH CHANDRA BHOIR",
    saID: "SPR4532",
  },
  {
    firstName: "BICHITRA NANDA",
    lastName: "ACHARYA",
    address: "AT-KUMARPUR SASAN*PO-KUMARPUR*VIA-CHARBATIA*CUTTACK-754028",
    fullName: "BICHITRA NANDA ACHARYA",
    saID: "SPR4533",
  },
  {
    firstName: "NARENDRA NATH",
    lastName: "SAHANI",
    address: "C/O SUPER TYPEWRITER*PO/DIST-BONGAIGAON-783380.*ASSAM",
    fullName: "NARENDRA NATH SAHANI",
    saID: "SPR4534",
  },
  {
    firstName: "ANANTA CHANDRA",
    lastName: "CHAUDHURY",
    address: "BORIPARA PANDAVNAGAR*PO-PANDU*GUWAHATI-12*KAMRUP*ASSAM",
    fullName: "ANANTA CHANDRA CHAUDHURY",
    saID: "SPR4535",
  },
  {
    firstName: "AMIT KUMAR",
    lastName: "POI",
    address: "AT/PO-SATSANG*DEOGHAR-814116.",
    fullName: "AMIT KUMAR POI",
    saID: "SPR4536",
  },
  {
    firstName: "ANADI NATH",
    lastName: "PANDA",
    address: "PRANABANANDA PALLY*PO-KENDUADIHI*DIST-BANKURA*PAN-AEXPP9991A",
    fullName: "ANADI NATH PANDA",
    saID: "SPR4537",
  },
  {
    firstName: "JANARDAN PRASAD",
    lastName: "SINHA",
    address: "D A O I*R W D ROING*ARUNACHAL PRADESH 792110.",
    fullName: "JANARDAN PRASAD SINHA",
    saID: "SPR4538",
  },
  {
    firstName: "LATE GHANASHYAM",
    lastName: "MISHRA",
    address: "BARADANDA*AT&PO-ANANDAPUR*KEONJHAR 758021",
    fullName: "LATE GHANASHYAM MISHRA",
    saID: "SPR4539",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "SAMANTA",
    address: "VILL/PO-NANDAKUMAR*PURBA MEDINIPUR-721632",
    fullName: "RADHESHYAM SAMANTA",
    saID: "SPR4540",
  },
  {
    firstName: "BIDHU BHUSAN",
    lastName: "CHAKRABARTY",
    address: "VILL-GANDHINAGAR*PO-NETAJINAGAR*KARIMGANJ 788166",
    fullName: "BIDHU BHUSAN CHAKRABARTY",
    saID: "SPR4541",
  },
  {
    firstName: "BASISTHA CHARAN",
    lastName: "SINGHA",
    address: "VILL-LALGOLA NATTYA MANDIR PARA*PO-LALGOLA*MURSHIDABAD-742148",
    fullName: "BASISTHA CHARAN SINGHA",
    saID: "SPR4542",
  },
  {
    firstName: "NARAYAN",
    lastName: "BEHERA",
    address: "QR.NO. C-257*PO-NALCONAGAR*DIST-ANGUL-759145.",
    fullName: "NARAYAN BEHERA",
    saID: "SPR4543",
  },
  {
    firstName: "ADHIR",
    lastName: "DUTTA",
    address: "2B,SANSKRITI ENCLAVE*SREENAGAR*GUWAHATI-780005*KAMRUP*ASSAM",
    fullName: "ADHIR DUTTA",
    saID: "SPR4544",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "DALAI",
    address: "VILL&PO-KARANJAMAL*VIA-NAIKANIDIHI*BHADRAK 756 164.",
    fullName: "PURNA CHANDRA DALAI",
    saID: "SPR4545",
  },
  {
    firstName: "BIMALENDU",
    lastName: "SAHA",
    address: "VILL&PO-BARABELUN*BURDWAN-713158",
    fullName: "BIMALENDU SAHA",
    saID: "SPR4546",
  },
  {
    firstName: "SEKH MD",
    lastName: "MURSALIM",
    address: "KUSTORE SATSANG ADHIBESAN KENDRA*PO-MAYURESWAR*BIRBHUM-731213",
    fullName: "SEKH MD MURSALIM",
    saID: "SPR4547",
  },
  {
    firstName: "PRATAP KUMAR",
    lastName: "SEN",
    address: "VILL-MONDALAI*PO-ILSOBA MANDALAI*HOOGHLY-712146*PAN-DEMPS8331R",
    fullName: "PRATAP KUMAR SEN",
    saID: "SPR4548",
  },
  {
    firstName: "CHIRANJIB",
    lastName: "ADHIKARY",
    address:
      "A.G.M,MATERIALS DIVISION*PO-N T P S*NAMRUP*DIBRUGARH 786622*PAN-AFGPA0935F",
    fullName: "CHIRANJIB ADHIKARY",
    saID: "SPR4549",
  },
  {
    firstName: "MINTU",
    lastName: "DAS",
    address: "VILL-DUDHPUR*PO-KANCHANCHARA(82MILES)*TRIPURA NORTH 799264",
    fullName: "MINTU DAS",
    saID: "SPR4550",
  },
  {
    firstName: "UPENDRA",
    lastName: "KONWAR",
    address: "",
    fullName: "UPENDRA KONWAR",
    saID: "SPR4551",
  },
  {
    firstName: "KSHIROD MOHAN",
    lastName: "BISWAS",
    address:
      '"UTTAMA"*VILL-W.RANGAMATIA*PO-RUPNARAYANPUR BAZAR*BURDWAN 713364*PAN-AXAPB9759Q',
    fullName: "KSHIROD MOHAN BISWAS",
    saID: "SPR4552",
  },
  {
    firstName: "SUMANTA KUMAR",
    lastName: "MISHRA",
    address:
      "SEBAYATAN HOMEO CLINIC*SATSANG ROAD, CHITATASTIA*BARIPADA*MAYURBHANJ-757001",
    fullName: "SUMANTA KUMAR MISHRA",
    saID: "SPR4553",
  },
  {
    firstName: "JAGANNATH",
    lastName: "DAS",
    address: "VILL&PO-SOMRUK*HOWRAH",
    fullName: "JAGANNATH DAS",
    saID: "SPR4554",
  },
  {
    firstName: "BRAJA KISHORE",
    lastName: "MOHANTY",
    address:
      "SWASTI SUBAS*NEAR NRUSINGHA TEMPLE*BARBAJAR*PO-KHETRAJPUR*SAMBALPUR 768003",
    fullName: "BRAJA KISHORE MOHANTY",
    saID: "SPR4555",
  },
  {
    firstName: "NISHITH RANJAN",
    lastName: "BHATTACHARJEE",
    address:
      "C/O UMA CHARAN BHATTACHARJEE*PO&VILL-SINGHICHARA*KHOWAI*TRIPURA WEST-799201",
    fullName: "NISHITH RANJAN BHATTACHARJEE",
    saID: "SPR4556",
  },
  {
    firstName: "MIHIR KUMAR",
    lastName: "CHATTERJEE",
    address: "VILL-KHADINAN*PO-GOPALNAGAR*DIST-PURBA MEDINIPUR-721130",
    fullName: "MIHIR KUMAR CHATTERJEE",
    saID: "SPR4557",
  },
  {
    firstName: "ANUP",
    lastName: "MUKHARJEE",
    address: "MUKHERJEE MEDICAL HALL*DUHABI BAJAR*JOGBANI*ARARIA 854328",
    fullName: "ANUP MUKHARJEE",
    saID: "SPR4558",
  },
  {
    firstName: "LATE PRASANTA KUMAR",
    lastName: "SARKAR",
    address: "D V C PARA, PANDAVESWAR*PO-PANDAVESWAR*BURDWAN-713346",
    fullName: "LATE PRASANTA KUMAR SARKAR",
    saID: "SPR4559",
  },
  {
    firstName: "RADHA KRISHNA",
    lastName: "DASH",
    address:
      "AT/PO-SARAKANA*VIA-BALAKATI*PS-BALIANTA*DIST-KHURDA-752100*PAN-ACHPD4474G",
    fullName: "RADHA KRISHNA DASH",
    saID: "SPR4560",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "PATRA",
    address:
      "AT-AMARADA FISH FARM*PO-AMARADA*DT-MAYURBHANJ 757055*PAN-BISPP8999Q",
    fullName: "RABINDRA NATH PATRA",
    saID: "SPR4561",
  },
  {
    firstName: "NRUSINGHA PRASAD",
    lastName: "TRIPATHY",
    address:
      "NIRMALYA GARDEN*HOUSE NO-3*PO-KIIT*BHUBANESWAR-751024*PAN-ADDPT4022B",
    fullName: "NRUSINGHA PRASAD TRIPATHY",
    saID: "SPR4562",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "BISWAS",
    address: "VILL-RANINAGAR*PO-GOPINATHPUR*NADIA-741155",
    fullName: "DHIRENDRA NATH BISWAS",
    saID: "SPR4563",
  },
  {
    firstName: "RAJENDRA",
    lastName: "PRASAD",
    address: "IAT&PO-TALCHER TOWN*DIST-ANGUL 759107",
    fullName: "RAJENDRA PRASAD",
    saID: "SPR4564",
  },
  {
    firstName: "SATYAPIRA",
    lastName: "NAYAK",
    address: "J.A.O.*AT/PO-CHHATIA*JAJPUR-754023*PAN-AQAPN6619A",
    fullName: "SATYAPIRA NAYAK",
    saID: "SPR4565",
  },
  {
    firstName: "LAXMIDHAR",
    lastName: "ROUT",
    address:
      "HI-TECH PLAZA,D4 BLOCK,2ND FLOOR,FLAT-5*SUNDARPADA*BHUBANESWAR-751002*(ABMPR7088Q)",
    fullName: "LAXMIDHAR ROUT",
    saID: "SPR4566",
  },
  {
    firstName: "SATYENDRA PRASAD",
    lastName: "PANDA",
    address: "AT-BASUBRAHMAPUR*PO-CHAKJAGANNATHPUR*BALASORE 756053",
    fullName: "SATYENDRA PRASAD PANDA",
    saID: "SPR4567",
  },
  {
    firstName: "ATINDRA CHANDRA",
    lastName: "DAS",
    address:
      "VIVEKANANDA COLONY*PO-KAMAKSHYAGURI*DIST-ALIPURDUAR-735132*PAN.ADGPD8397Q",
    fullName: "ATINDRA CHANDRA DAS",
    saID: "SPR4568",
  },
  {
    firstName: "LATE NABA KISHORE",
    lastName: "MAHAPATRA",
    address: "NABINABAG*PO-NARIJANG*CUTTACK",
    fullName: "LATE NABA KISHORE MAHAPATRA",
    saID: "SPR4569",
  },
  {
    firstName: "SATRUGHAN",
    lastName: "SA",
    address: "S.K.M.V.COLLEGE*FATUAH*PATNA 803201",
    fullName: "SATRUGHAN SA",
    saID: "SPR4570",
  },
  {
    firstName: "JOYDEB",
    lastName: "MUKHERJEE",
    address: "NEW NAGRATOLI COLONY*RANCHI-834001*PAN-ACNPM3565D",
    fullName: "JOYDEB MUKHERJEE",
    saID: "SPR4571",
  },
  {
    firstName: "LATE HARI NARAYAN",
    lastName: "PAL",
    address: "SHYAMPUR COLONY*PO-DURGAPUR-713201*BURDWAN",
    fullName: "LATE HARI NARAYAN PAL",
    saID: "SPR4572",
  },
  {
    firstName: "RAJANI KANTA",
    lastName: "NAYAK",
    address: "QR NO-K/98*SUNABEDA TOWNSHIP*KORAPUT-763002",
    fullName: "RAJANI KANTA NAYAK",
    saID: "SPR4573",
  },
  {
    firstName: "MONOJ",
    lastName: "BISWAS",
    address: "RLY QTR NO-39 STADIUM COLONY*GORAKHPUR*.U.P PAN-ADJPB2819A",
    fullName: "MONOJ BISWAS",
    saID: "SPR4574",
  },
  {
    firstName: "TAPAN KISHORE",
    lastName: "DEY",
    address:
      '" KIRAN VILLA" *NEAR BARMASIA MIDDLE SCHOOL*PO&DT-DHANBAD 826001*PAN ABXPD7568A',
    fullName: "TAPAN KISHORE DEY",
    saID: "SPR4575",
  },
  {
    firstName: "BARENDRA NATH",
    lastName: "MAJUMDAR",
    address: "SARKAR NAGAR*CHANDRAPUR*MAHARASTRA 442401*PAN-AEOPM5214N",
    fullName: "BARENDRA NATH MAJUMDAR",
    saID: "SPR4576",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "MUDULI",
    address:
      '"ANANTA ASHISH"*JAGESWARI LANE*BRAHMAN POKHARI,BALIGHAT*PURI 752 002*PAN-AGEPM4925E',
    fullName: "BHIKARI CHARAN MUDULI",
    saID: "SPR4577",
  },
  {
    firstName: "CHANDRA SEKHAR",
    lastName: "MAHAPATRA",
    address: "AT/PO-BISHNUPUR*VIA-NIMAPARA*PURI-752106*PAN-AYOPM4326M",
    fullName: "CHANDRA SEKHAR MAHAPATRA",
    saID: "SPR4578",
  },
  {
    firstName: "LATE JAYANTA KUMAR",
    lastName: "GOSWAMI",
    address: "MORANHAT PIOLI NAGAR*PO-MORANHAT*DIBRUGARH 785670",
    fullName: "LATE JAYANTA KUMAR GOSWAMI",
    saID: "SPR4579",
  },
  {
    firstName: "BIMAL KUMAR",
    lastName: "JADAV",
    address: "VILL-BARHARI*PO-BARHARA KOTHI*PURNEA-854203",
    fullName: "BIMAL KUMAR JADAV",
    saID: "SPR4580",
  },
  {
    firstName: "NAGENDRA NATH",
    lastName: "GUPTA",
    address: "RETD. ACCOUNTANT,GAMI TOLA*NEAR SATSANG MANDIR*PO/DT-KATIHAR",
    fullName: "NAGENDRA NATH GUPTA",
    saID: "SPR4581",
  },
  {
    firstName: "PARTHASARATHI",
    lastName: "MUKHERJEE",
    address: "17C KALIBARI LANE*CALCUTTA-700032",
    fullName: "PARTHASARATHI MUKHERJEE",
    saID: "SPR4582",
  },
  {
    firstName: "PURANJAY",
    lastName: "MALLICK",
    address: "VILL&PO-DAKSHIN SHIBPUR*P.S.-SHYAMPUR*HOWRAH",
    fullName: "PURANJAY MALLICK",
    saID: "SPR4583",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "SHOME",
    address: "PO-PANIHATI*KSHUDIRAM BOSE ROAD*24PARGANAS (NORTH)-700114",
    fullName: "LATE PRAFULLA KUMAR SHOME",
    saID: "SPR4584",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "GHOSH",
    address: "VILL-BARANDALA*PO-KANPUR*BURDWAN-713422",
    fullName: "ANIL KUMAR GHOSH",
    saID: "SPR4585",
  },
  {
    firstName: "GOLAK BEHARI",
    lastName: "SWAIN",
    address: "68,SURYA SEN STREET*KOLKATA-700009*PAN-CNNPS7388K",
    fullName: "GOLAK BEHARI SWAIN",
    saID: "SPR4586",
  },
  {
    firstName: "MANORANJAN",
    lastName: "DAS",
    address: "VILL-RANGAVITA(KUNDUPARA)*PO-GAJOL*MALDA-732124",
    fullName: "MANORANJAN DAS",
    saID: "SPR4587",
  },
  {
    firstName: "LATE SACHINANDAN",
    lastName: "ROY",
    address: "BASISTA NAGAR,BELTALA*GAUHAT-5*KAMRUP",
    fullName: "LATE SACHINANDAN ROY",
    saID: "SPR4588",
  },
  {
    firstName: "HARADHAN",
    lastName: "GANGOPADHYAY",
    address: "6/1 PARAMAHANSA DEV ROAD*PO-NABAGRAM*HOOGHLY 712246.",
    fullName: "HARADHAN GANGOPADHYAY",
    saID: "SPR4589",
  },
  {
    firstName: "SUBO",
    lastName: "ACHARYA",
    address:
      "STATE BANK OF INDIA*SUBODH MULLICK SQUARE BRANCH*40A,NIRMAL CHANDRA ST*CALCUTTA 12",
    fullName: "SUBO ACHARYA",
    saID: "SPR4590",
  },
  {
    firstName: "RAGHUBANSH",
    lastName: "MISHRA",
    address: "AT/PO/DIST-MADHIPURA-852113.",
    fullName: "RAGHUBANSH MISHRA",
    saID: "SPR4591",
  },
  {
    firstName: "DILIP SHANKARRAO",
    lastName: "SURYAVANSHI",
    address: '"DEEP"*SHITOLE NAGAR*SANGVI HAVELI*PUNE-411027*MAHARASTRA',
    fullName: "DILIP SHANKARRAO SURYAVANSHI",
    saID: "SPR4592",
  },
  {
    firstName: "DEBENDRA KUMAR",
    lastName: "DAS",
    address: "VILL-BUDHIAR*PO-DHUSMALHAT*KISANGANJ*PURNEA-854301",
    fullName: "DEBENDRA KUMAR DAS",
    saID: "SPR4593",
  },
  {
    firstName: "LATE ANIL RANJAN",
    lastName: "DASSARMA",
    address: "NEAMATPUR G.T.ROAD WEST*PO-SITARAMPUR*DIST-BURDWAN-713359.",
    fullName: "LATE ANIL RANJAN DASSARMA",
    saID: "SPR4594",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "CHANG",
    address:
      "DALU BENGALI HIGH SCHOOL*PO-KILLAPARA*WEST GAROHILLS*MEGHALAYA-794103",
    fullName: "SWAPAN KUMAR CHANG",
    saID: "SPR4595",
  },
  {
    firstName: "KAPIL CHARAN",
    lastName: "MAHAKUL",
    address: "BAPUJI NAGAR*JEYPORE*KORAPUT 764 001",
    fullName: "KAPIL CHARAN MAHAKUL",
    saID: "SPR4596",
  },
  {
    firstName: "LATE RAMBAHADUR",
    lastName: "PRASAD",
    address:
      "ADMINISTRATIVE OFFICER*L.I.C. OF INDIA*AT/PO-BAGAHA*WEST CHAMPARAN.",
    fullName: "LATE RAMBAHADUR PRASAD",
    saID: "SPR4597",
  },
  {
    firstName: "SASHI KANTA",
    lastName: "BEHERA",
    address: "AT/PO.GOBINDAPUR*VIA.NAYAKANIDIHI*BALASORE-756164",
    fullName: "SASHI KANTA BEHERA",
    saID: "SPR4598",
  },
  {
    firstName: "ADHIR KUMAR",
    lastName: "PAL",
    address: "SWASTI NILAY*VILL&PO-DAKSHINCHATRA*24PARGANAS NORTH 743247",
    fullName: "ADHIR KUMAR PAL",
    saID: "SPR4599",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "SAHOO",
    address: "M/73,BHIMATANGI*BHUBANESWAR-2*KHURDA",
    fullName: "LATE PRAFULLA KUMAR SAHOO",
    saID: "SPR4600",
  },
  {
    firstName: "LATE RAJENDRA SHIVLAL",
    lastName: "SHAH",
    address:
      "33,VANRAI CO-OPERATIVE HOUSING SOCIETY*RAJWOODS,LONAVLA*PUNE-410401*MAHARASTRA",
    fullName: "LATE RAJENDRA SHIVLAL SHAH",
    saID: "SPR4601",
  },
  {
    firstName: "SAKTIDHAR",
    lastName: "GHOSH",
    address: "VILL/PO-GHARGOHAL*HOOGHLY-712414",
    fullName: "SAKTIDHAR GHOSH",
    saID: "SPR4602",
  },
  {
    firstName: "KANTESWAR",
    lastName: "MAHILARY",
    address: "VILL-THURIBARI*PO-AFLAGAON*KOKRAJHAR-783346",
    fullName: "KANTESWAR MAHILARY",
    saID: "SPR4603",
  },
  {
    firstName: "RABAN CHANDRA",
    lastName: "SOREN",
    address: "VILL/PO-RAMGOPALPUR*BURDWAN-713403.",
    fullName: "RABAN CHANDRA SOREN",
    saID: "SPR4604",
  },
  {
    firstName: "RABINDRA CHANDRA",
    lastName: "SARKAR",
    address: "SATSANG VIHAR, DUDHNOI*PO-DUDHNOI*GOALPARA-783124*PAN-CPZPS0427P",
    fullName: "RABINDRA CHANDRA SARKAR",
    saID: "SPR4605",
  },
  {
    firstName: "MALAY KANTI",
    lastName: "PAL",
    address: "VILL-SINGIMARI*PO-BARPATHAR*GOLAGHAT 785602",
    fullName: "MALAY KANTI PAL",
    saID: "SPR4606",
  },
  {
    firstName: "LATE LAXMAN CHANDRA",
    lastName: "GHOSE",
    address: "VILL&PO-PATNA*HOOGHLY",
    fullName: "LATE LAXMAN CHANDRA GHOSE",
    saID: "SPR4607",
  },
  {
    firstName: "DILIP",
    lastName: "GUHA",
    address: "",
    fullName: "DILIP GUHA",
    saID: "SPR4608",
  },
  {
    firstName: "LATE B R",
    lastName: "SINGH",
    address: "VICE CHANCELLOR*BIHAR UNIVERSITY*MUZAFFARPUR",
    fullName: "LATE B R SINGH",
    saID: "SPR4609",
  },
  {
    firstName: "OMPRAKASH",
    lastName: "LAL",
    address: "AT&PO-BEHARIGANJ*MADHEPURA 852101",
    fullName: "OMPRAKASH LAL",
    saID: "SPR4610",
  },
  {
    firstName: "SASHIBHUSAN",
    lastName: "PATI",
    address: "SATSANG PHILANTHROPHY,S.C.C.*SATSANG-814 116.",
    fullName: "SASHIBHUSAN PATI",
    saID: "SPR4611",
  },
  {
    firstName: "PRAN KUMAR",
    lastName: "CHAKRABARTY",
    address: "8/4 SABARNA PARA ROAD*PO-BARISHA*KOLKATA 8",
    fullName: "PRAN KUMAR CHAKRABARTY",
    saID: "SPR4612",
  },
  {
    firstName: "ASHOKE KUMAR",
    lastName: "SINGH",
    address:
      "ASST. CIVIL COURT MUZAFFARPUR*MUZAFFARPUR-842001*PAN NO.AKQPS2521N",
    fullName: "ASHOKE KUMAR SINGH",
    saID: "SPR4613",
  },
  {
    firstName: "BANI",
    lastName: "RABHA",
    address: "AT&PO-SARAI BILL*DIST-KOKRAJHAR-783350*ASSAM",
    fullName: "BANI RABHA",
    saID: "SPR4614",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "GADANAYAK",
    address: "AT&PO-GARHSANTY*VIA-BANARPAL*ANGUL",
    fullName: "ASHOK KUMAR GADANAYAK",
    saID: "SPR4615",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "MAHANTI",
    address: "AT/PO-TALPOLI*VIA-PADAMPUR*DIST-BARGARH-768036*ODISHA",
    fullName: "BIBHUTI BHUSAN MAHANTI",
    saID: "SPR4616",
  },
  {
    firstName: "LATE TAPAN KUMAR",
    lastName: "BHATTACHARJEE",
    address: '"APUR PATH",DAHIGORA*PO-GHATSILA*SINGHBHUM',
    fullName: "LATE TAPAN KUMAR BHATTACHARJEE",
    saID: "SPR4617",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "DUTTA",
    address: "PO-PASCHIM CHAMATA*NALBARI",
    fullName: "DHIRENDRA NATH DUTTA",
    saID: "SPR4618",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "DUTTA",
    address: "VILL&PO-CHELYAMA*PURULIA 723158",
    fullName: "DULAL CHANDRA DUTTA",
    saID: "SPR4619",
  },
  {
    firstName: "SATYA NARAYAN",
    lastName: "PRASAD",
    address:
      "(HD TC TTS)MOHALLA MOHANPUR*NEAR RLY SCHOOL N.2*PO-JAMALPUR*MUNGER 811214",
    fullName: "SATYA NARAYAN PRASAD",
    saID: "SPR4620",
  },
  {
    firstName: "SHEONANDAN",
    lastName: "SINGH",
    address:
      "C/O R K ENTERPRISES*AT&PO-CHATRA*DIST-CHATRA 825410*PAN NO.AJOPS5386F",
    fullName: "SHEONANDAN SINGH",
    saID: "SPR4621",
  },
  {
    firstName: "ISWAR PRASAD",
    lastName: "BARTHAKUR",
    address: "BARODA PHUKAN ROAD*RAJAMOIDAM*JORHAT*PAN-BJYPB0357B",
    fullName: "ISWAR PRASAD BARTHAKUR",
    saID: "SPR4622",
  },
  {
    firstName: "SHEODIP",
    lastName: "TEWARY",
    address:
      "MO-KATIRA*BACKSIDE OF HOME GUARD*WEST OF DR P SINGH ST RD*PO-ARRAH*BHOJPUR-802301",
    fullName: "SHEODIP TEWARY",
    saID: "SPR4623",
  },
  {
    firstName: "JAGAMOHAN",
    lastName: "ROY",
    address: "SATSANG COLONY*PO-SATSANG*DEOGHAR 814116",
    fullName: "JAGAMOHAN ROY",
    saID: "SPR4624",
  },
  {
    firstName: "SANKAR",
    lastName: "BHATTACHARJEE",
    address: "LOHARI PATTY*PO-MAKUM JUNCTION*TINSUKIA-786170*PAN-AISPB5407E",
    fullName: "SANKAR BHATTACHARJEE",
    saID: "SPR4625",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "KHANDIT",
    address: "AT-THUBA (RAJAKPARA)*PO-TAKI*24PARGANAS NORTH 743 429",
    fullName: "BIRENDRA NATH KHANDIT",
    saID: "SPR4626",
  },
  {
    firstName: "AMAR KRISHNA",
    lastName: "DAS",
    address: "VILL-SOUDUGANJ*PO-JIAGANJ*MURSHIDABAD-742123",
    fullName: "AMAR KRISHNA DAS",
    saID: "SPR4627",
  },
  {
    firstName: "AMAL CHANDRA",
    lastName: "RAY",
    address: "VILL&PO-HIJAL PUKHURIA*24PARGANAS (NORTH)-743271",
    fullName: "AMAL CHANDRA RAY",
    saID: "SPR4628",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "BANIK",
    address: "301,KASTHADANGA ROAD*PO-SARSUNA*CALCUTTA 61",
    fullName: "PRASANTA KUMAR BANIK",
    saID: "SPR4629",
  },
  {
    firstName: "LATE PRIYA RANJAN",
    lastName: "MAJUMDAR",
    address:
      "62, RAJPUKUR PATH*BANKIM NAGAR NO. 3 ATPUR*PO-ATPUR*24 PARGANAS (N).",
    fullName: "LATE PRIYA RANJAN MAJUMDAR",
    saID: "SPR4630",
  },
  {
    firstName: "GANESH",
    lastName: "SINGH",
    address:
      "CHIPPER (MECH.) HPC LTD, NPM*PO-KAGAJ NAGAR-782413*DIST-MORIGAON*ASSAM",
    fullName: "GANESH SINGH",
    saID: "SPR4631",
  },
  {
    firstName: "DURYODHAN",
    lastName: "GARAI",
    address:
      "ASSTT TEACHER, SUISA H S SCHOOL*PO-SUISA*PURULIA 723212*PAN-ADGPG2883A",
    fullName: "DURYODHAN GARAI",
    saID: "SPR4632",
  },
  {
    firstName: "CHABIN CHANDRA",
    lastName: "BORO",
    address:
      "NO 2 CHATIANGURI*PO-BIJNI-783390*DIST-CHIRANG,BTAD*ASSAM*PAN.ANUPB3291M",
    fullName: "CHABIN CHANDRA BORO",
    saID: "SPR4633",
  },
  {
    firstName: "TRILOCHAN",
    lastName: "PASAYAT",
    address: "AT/PO-JARAIKELA*VIA-BISRA*SUNDERGARH 770036*PAN-BAYPP3932P.",
    fullName: "TRILOCHAN PASAYAT",
    saID: "SPR4634",
  },
  {
    firstName: "JITENDRA KUMAR",
    lastName: "SUKLABAIDYA",
    address:
      "VILL-NAHARBANDH COLONY*PO-DWARBANDH*CACHAR 788 113*PAN NO-CDVPS9182F",
    fullName: "JITENDRA KUMAR SUKLABAIDYA",
    saID: "SPR4635",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "BARDHAN",
    address: "9 NO. WARD*PO-TUFANGANJ*DIST-COOCH BEHAR",
    fullName: "SUBHAS CHANDRA BARDHAN",
    saID: "SPR4636",
  },
  {
    firstName: "LATE RATNESWAR",
    lastName: "DASSARMA",
    address: "*SATSANG",
    fullName: "LATE RATNESWAR DASSARMA",
    saID: "SPR4637",
  },
  {
    firstName: "UMAKANTA",
    lastName: "BISWAL",
    address: "AT-ATIRA*PO-BARI-CUTTACK*CUTTACK-755003",
    fullName: "UMAKANTA BISWAL",
    saID: "SPR4638",
  },
  {
    firstName: "BAMAN CHARAN",
    lastName: "SAHOO",
    address:
      "AT-SATORI*PO-KAITHA*VIA-CHHATIA*DIST-CUTTACK-754023*PAN-AOJPS1575E",
    fullName: "BAMAN CHARAN SAHOO",
    saID: "SPR4639",
  },
  {
    firstName: "SACHIKANTA",
    lastName: "BHUYAN",
    address: "QRS NO B/197*SECTOR-22*ROURKELA 769007*SUNDERGARH*PAN-AEQPB4922E",
    fullName: "SACHIKANTA BHUYAN",
    saID: "SPR4640",
  },
  {
    firstName: "KAMALESH KUMAR",
    lastName: "JHA",
    address:
      "NEW COLONY,DHARAMPUR*WARD NO-10*SAMASTIPUR 848101*PAN NO.ACIPJ5040R",
    fullName: "KAMALESH KUMAR JHA",
    saID: "SPR4641",
  },
  {
    firstName: "PADMA PALASH",
    lastName: "ROYCHOUDHURI",
    address: "VILL-KOCHAIJOTE*PO-KHARIBARI*DARJEELING 734427",
    fullName: "PADMA PALASH ROYCHOUDHURI",
    saID: "SPR4642",
  },
  {
    firstName: "ANIL KANTA",
    lastName: "SAU",
    address: "SATSANG VIHAR,KANDUA*VILL/PO-KANDUA*HOWRAH 711302",
    fullName: "ANIL KANTA SAU",
    saID: "SPR4643",
  },
  {
    firstName: "ISWAR",
    lastName: "SAMANTARA",
    address: "C/O PROJECT DIRECTOR,WATERSHEDS,NAYAGARH*PO/DIST-NAYAGARH",
    fullName: "ISWAR SAMANTARA",
    saID: "SPR4644",
  },
  {
    firstName: "MANORANJAN",
    lastName: "KANJILAL",
    address: "103/1 M M GHOSH ROAD *KOLKATA-700074.*PAN BNGPK6032A",
    fullName: "MANORANJAN KANJILAL",
    saID: "SPR4645",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "NAYAK",
    address: "AT/PO-BINJHARPUR-755004*DIST-JAJPUR*(ODISHA)",
    fullName: "BHIKARI CHARAN NAYAK",
    saID: "SPR4646",
  },
  {
    firstName: "NIROD KUMAR",
    lastName: "MAHAPATRA",
    address: "AT-CHABISHIMANIA*PO-ANTEI*VIA-TIRTOL*JAGATSINGHPUR-754137",
    fullName: "NIROD KUMAR MAHAPATRA",
    saID: "SPR4647",
  },
  {
    firstName: "PURUSOTTAM",
    lastName: "PANDA",
    address: "AT/PO-KIAPADA*VIA-BRAHMANAGAR*BHADRAK-756165",
    fullName: "PURUSOTTAM PANDA",
    saID: "SPR4648",
  },
  {
    firstName: "ACHINTYA KUMAR",
    lastName: "MANDAL",
    address: "BALIDANGA NAZRUL PALLY*PO-SRIPALLY*BURDWAN 713103",
    fullName: "ACHINTYA KUMAR MANDAL",
    saID: "SPR4649",
  },
  {
    firstName: "JUDHISTHIR",
    lastName: "NAYAK",
    address: "BESIDE G/8 BLOCK*SUB STATION RD, PO-HIRAKUD *SAMBALPUR-768016.",
    fullName: "JUDHISTHIR NAYAK",
    saID: "SPR4650",
  },
  {
    firstName: "ALEKHA KUMAR",
    lastName: "CHAKRA",
    address:
      "AT-DIRECTOR OF AGRICULTURE &*FOOD PRODUCTION*BHUBANESWAR-751001*PAN-AHEPC8086H",
    fullName: "ALEKHA KUMAR CHAKRA",
    saID: "SPR4651",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "DAS",
    address: "AT-KUMARI*PO-JARAKA*JAJPUR-755050.",
    fullName: "RAMESH CHANDRA DAS",
    saID: "SPR4652",
  },
  {
    firstName: "PRAMATHA NATH",
    lastName: "GHOSH",
    address:
      "SATSANG SWASTI SIBIR*PO-KRISHNANAGAR*DIST-NADIA-741101*PAN-ADWPG3432B",
    fullName: "PRAMATHA NATH GHOSH",
    saID: "SPR4653",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "BAGCHI",
    address: "VILL&PO-DEBAGRAM*NADIA-741238*PAN-AZNPB7494B",
    fullName: "SWAPAN KUMAR BAGCHI",
    saID: "SPR4654",
  },
  {
    firstName: "DEBARAJ",
    lastName: "MISHRA",
    address: "AT-MANAPUR*PO-GOBARDHANPUR*VIA-J.K.ROAD*JAJPUR-755019*ODISHA",
    fullName: "DEBARAJ MISHRA",
    saID: "SPR4655",
  },
  {
    firstName: "LATE ANATHBANDHU",
    lastName: "PAL",
    address: "BARODA COLONY*RADHAMADHAB ROAD*PO-SILCHAR 1*CACHAR",
    fullName: "LATE ANATHBANDHU PAL",
    saID: "SPR4656",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "PANDA",
    address: "AT-SUKINDA SATSANG VIHAR*PO-SUKINDA*DIST-JAJPUR-755018",
    fullName: "RABINDRA KUMAR PANDA",
    saID: "SPR4657",
  },
  {
    firstName: "RADHA KANTA",
    lastName: "DOLEY",
    address: "VILL-BHELAHCHICHI*PO-GOHPUR*SONITPUR-784168*ASSAM",
    fullName: "RADHA KANTA DOLEY",
    saID: "SPR4658",
  },
  {
    firstName: "ASISH KUMAR",
    lastName: "MISHRA",
    address: "AT&PO-DEHURDA*BALASORE 756036*PAN-AGEPM5626Q",
    fullName: "ASISH KUMAR MISHRA",
    saID: "SPR4659",
  },
  {
    firstName: "HARKA BAHADUR",
    lastName: "KARKI",
    address: "VILL-RATANPUR*PO-BOGINADI*LAKHIMPUR-787032*ASSAM",
    fullName: "HARKA BAHADUR KARKI",
    saID: "SPR4660",
  },
  {
    firstName: "SHARAT CHANDRA",
    lastName: "SAMAL",
    address: "AT-PANIOLA II*PO-DANPUR*KENDRAPARA-754210",
    fullName: "SHARAT CHANDRA SAMAL",
    saID: "SPR4661",
  },
  {
    firstName: "BHAGABAT",
    lastName: "PADHI",
    address: "AT-BETIRA*PO-MAREIGAON*VIA-HATADIHI*KEONJHAR 756144",
    fullName: "BHAGABAT PADHI",
    saID: "SPR4662",
  },
  {
    firstName: "UMESH",
    lastName: "MALLABUJARBARUAH",
    address: "VILL-AMBIKAGIRI NAGAR*PO-ZOO ROAD*GUWAHATI-781 024*KAMRUP",
    fullName: "UMESH MALLABUJARBARUAH",
    saID: "SPR4663",
  },
  {
    firstName: "KRISHNA GOPAL",
    lastName: "DAS",
    address: "SATSANG TAPOVAN NAGAR*PO/DIST-SILCHAR-788007*ASSAM",
    fullName: "KRISHNA GOPAL DAS",
    saID: "SPR4664",
  },
  {
    firstName: "PRAVAKAR",
    lastName: "SWAIN",
    address: "PLOT NO.N/5-106,I R C VILLAGE*BHUBANESWAR-751015*KHURDA",
    fullName: "PRAVAKAR SWAIN",
    saID: "SPR4665",
  },
  {
    firstName: "BARENYA",
    lastName: "SEN",
    address:
      "CHIEF ENGG(RTD) VISHAKHAPATNAM*STATION ROAD*VISHAKHAPATNAM-530004*ANDHRA PRADESH",
    fullName: "BARENYA SEN",
    saID: "SPR4666",
  },
  {
    firstName: "RANJAN",
    lastName: "DUTTA",
    address: "C/O-A S BISWAS*COLONY BAZAR*GUWAHATI-781018*KAMRUP",
    fullName: "RANJAN DUTTA",
    saID: "SPR4667",
  },
  {
    firstName: "HAJARI",
    lastName: "SA",
    address: "VILL&PO-BHABHATA*WEST CHAMPARAN-845449*BIHAR",
    fullName: "HAJARI SA",
    saID: "SPR4668",
  },
  {
    firstName: "AMARENDRA KUMAR",
    lastName: "SINGH",
    address:
      "C/O-BASUDEO SINGH*LALOOCHAK*BHAGALPUR-812001*BIHAR PAN NO-AVNPS4377G",
    fullName: "AMARENDRA KUMAR SINGH",
    saID: "SPR4669",
  },
  {
    firstName: "PRAVAKAR C",
    lastName: "KALAMKAR",
    address:
      "2,CHANDRABHAGA NIBAS*SARVODAYA NAGAR*AT/PO-KULGAON*THANE*M.S. 421503",
    fullName: "PRAVAKAR C KALAMKAR",
    saID: "SPR4670",
  },
  {
    firstName: "BHAGVAT SHESHARAO",
    lastName: "PATIL",
    address:
      "406 AUTUMN HAY;NEPTUNE LIVING POINT*L B S ROAD*BHANDUP WEST*MUMBAI 400078",
    fullName: "BHAGVAT SHESHARAO PATIL",
    saID: "SPR4671",
  },
  {
    firstName: "GURUPADA",
    lastName: "MANDAL",
    address:
      "C/O KUMAR CYCLE STORES*MUL ROAD UTTAM NAGAR*PO-SASTRINAGAR*CHANDRAPUR-442401*M.S.",
    fullName: "GURUPADA MANDAL",
    saID: "SPR4672",
  },
  {
    firstName: "GANGADHAR MANOHAR",
    lastName: "TELANG",
    address:
      "B-201 RAMADARSHAN CO-OP HSG SOCIETY*SHIVAJI CHOWK*KULGAON*BADLAPUR*THANE 421503",
    fullName: "GANGADHAR MANOHAR TELANG",
    saID: "SPR4673",
  },
  {
    firstName: "NASIB",
    lastName: "SINGH",
    address: "INDRA NAGAR ROAD NO-9*PO-G.P.O*PATNA 800 001",
    fullName: "NASIB SINGH",
    saID: "SPR4674",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "RAY",
    address:
      "QR NO-413/3/B*PO-BALCO TOWN SHIP*DIST-KORBA*CHHATTISGARH  495684*PAN AFSPR1059P",
    fullName: "PARIMAL CHANDRA RAY",
    saID: "SPR4675",
  },
  {
    firstName: "ASHIM KUMAR",
    lastName: "RAY",
    address: "15/5, SECONDARY ROAD*DURGAPUR-713204.BURDWAN.",
    fullName: "ASHIM KUMAR RAY",
    saID: "SPR4676",
  },
  {
    firstName: "ARJUN CHARAN",
    lastName: "SAHOO",
    address:
      "PLOT NO-297/3459,NANDAN KANAN ROAD*NR SUBHADRA APPT*BHUBANESWAR-751024*(AFVPS1006G)",
    fullName: "ARJUN CHARAN SAHOO",
    saID: "SPR4677",
  },
  {
    firstName: "ISWAR CHANDRA",
    lastName: "DASH",
    address: "AT-CONTRACTOR COLONY*PO-BURLA*SAMBALPUR 768017*PAN-BCPPD3283E",
    fullName: "ISWAR CHANDRA DASH",
    saID: "SPR4678",
  },
  {
    firstName: "BANKIM CHANDRA",
    lastName: "MAHATO",
    address: "VILL-KUNDRISOLE*PO-CHOTONAGDONA*PASCHIM MEDINIPUR 721157.",
    fullName: "BANKIM CHANDRA MAHATO",
    saID: "SPR4679",
  },
  {
    firstName: "A SATYA NARAYAN",
    lastName: "SARAF",
    address:
      '"BIDI NILAYA"*BHAWANI NAGAR 4TH LANE*NEAR GATE BAZAR,BERHAMPUR*GANJAM-760001',
    fullName: "A SATYA NARAYAN SARAF",
    saID: "SPR4680",
  },
  {
    firstName: "AMULYA KUMAR",
    lastName: "MALI",
    address: "KAJIRBER(GODA BATTALA)*PO-LAKURDIH-713102*BURDWAN",
    fullName: "AMULYA KUMAR MALI",
    saID: "SPR4681",
  },
  {
    firstName: "KALI KANT",
    lastName: "KARN",
    address: "PATNA SATSANG VIHAR*KANKARBAGH*PATNA 800020",
    fullName: "KALI KANT KARN",
    saID: "SPR4682",
  },
  {
    firstName: "NARAYAN PRASAD",
    lastName: "JADAB",
    address: "PURUSOTTAM CHIKITSALAYA*GANGJALA CHAWK*SAHARSA-852201",
    fullName: "NARAYAN PRASAD JADAB",
    saID: "SPR4683",
  },
  {
    firstName: "SATYABADI",
    lastName: "BARAL",
    address: "AT-PURUSOTTAMPUR*PO-SISUA*VIA-SALIPUR*CUTTACK 754202",
    fullName: "SATYABADI BARAL",
    saID: "SPR4684",
  },
  {
    firstName: "TARANI KUMAR",
    lastName: "MANDAL",
    address: "VILL&PO-RAMGOPALPUR*VIA-GALSI*BURDWAN-713403.*PAN AMSPM88750",
    fullName: "TARANI KUMAR MANDAL",
    saID: "SPR4685",
  },
  {
    firstName: "LATE SURYANARAYAN",
    lastName: "SATPATHY",
    address: "AT/PO-GOLANTHARA*GANJAM",
    fullName: "LATE SURYANARAYAN SATPATHY",
    saID: "SPR4686",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "PATRA",
    address: "AT&PO-SASAN*VIA-BALIPATNA*PURI-752102.",
    fullName: "ASHOK KUMAR PATRA",
    saID: "SPR4687",
  },
  {
    firstName: "LATE KAMALAKANTA",
    lastName: "SARKER",
    address: "KHULNA*BANGLDESH",
    fullName: "LATE KAMALAKANTA SARKER",
    saID: "SPR4688",
  },
  {
    firstName: "SUBRATA",
    lastName: "CHAKRABARTY",
    address:
      "GAIL(INDIA) LTD*SHEELA NAGAR*VISHAKHAPATNAM*ANDHRA PRADESH 530012",
    fullName: "SUBRATA CHAKRABARTY",
    saID: "SPR4689",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MAHARANA",
    address: "AT&PO-NARLA ROAD*KALAHANDI 766 110",
    fullName: "RABINDRA NATH MAHARANA",
    saID: "SPR4690",
  },
  {
    firstName: "BANSROPAN",
    lastName: "RAM",
    address:
      "41/A BAJAR PARA*MAIN ROAD*KANSARI PARA*HALISAHAR*24PARGANAS NORTH-743134",
    fullName: "BANSROPAN RAM",
    saID: "SPR4691",
  },
  {
    firstName: "PRABIR",
    lastName: "MUKHERJEE",
    address:
      "14/1/1, HRIDAY KRISHNA BANERJEE*IST BYE LANE*HOWRAH SATSANG KENDRA*HOWRAH-1.",
    fullName: "PRABIR MUKHERJEE",
    saID: "SPR4692",
  },
  {
    firstName: "RAJ BANSHI",
    lastName: "SINGH",
    address: "GANDHINAGAR*KATIHAR*BIHAR",
    fullName: "RAJ BANSHI SINGH",
    saID: "SPR4693",
  },
  {
    firstName: "RAMA PRASAD",
    lastName: "MISHRA",
    address: "J-B/124,MADHUBAN,SECTOR-4*PO-PARADIP PORT*JAGATSINGHPUR 754142",
    fullName: "RAMA PRASAD MISHRA",
    saID: "SPR4694",
  },
  {
    firstName: "NANDADULAL",
    lastName: "DAS",
    address: "AT&PO-GELPUR*DIST-BHADRAK*ODISHA-756100*PAN-AGPPD4116P",
    fullName: "NANDADULAL DAS",
    saID: "SPR4695",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "PAUL",
    address: "RADHAMADHAB ASHRAM ROAD(BILPARA)*SILCHAR*CACHAR",
    fullName: "LATE GOPAL CHANDRA PAUL",
    saID: "SPR4696",
  },
  {
    firstName: "LATE GOPENDRA SUNDAR",
    lastName: "ROY",
    address: "SATSANG",
    fullName: "LATE GOPENDRA SUNDAR ROY",
    saID: "SPR4697",
  },
  {
    firstName: "RATHIBIJOY",
    lastName: "CHAKRABARTY",
    address: "BANK COLONY*KATHAL ROAD*PO/DIST-SILCHAR-788 005*ASSAM.",
    fullName: "RATHIBIJOY CHAKRABARTY",
    saID: "SPR4698",
  },
  {
    firstName: "HARADHAN CHANDRA",
    lastName: "DEY",
    address:
      "SWASTI SUDHA*52C MUKTAPUKUR RD,NATUNPARA*PO-TALPUKUR*BARRACKPUR-700120*24PGS  NORTH",
    fullName: "HARADHAN CHANDRA DEY",
    saID: "SPR4699",
  },
  {
    firstName: "BISWANATH",
    lastName: "SAU",
    address: "AT-PURBAPARA*KRISHNAPUR*KOLKATA 700 102*PAN NO.BFNPS8548Q",
    fullName: "BISWANATH SAU",
    saID: "SPR4700",
  },
  {
    firstName: "MOHAN",
    lastName: "RAY",
    address: "AT/PO-GARHARA*BEGUSARAI-851126*BIHAR",
    fullName: "MOHAN RAY",
    saID: "SPR4701",
  },
  {
    firstName: "LATE RABINDRA KUMAR",
    lastName: "DHAR",
    address: "A-21,2ND FLOOR,KRISHNA PARK*DEVLI ROAD*NEW DELHI 110062",
    fullName: "LATE RABINDRA KUMAR DHAR",
    saID: "SPR4702",
  },
  {
    firstName: "KHOKAN",
    lastName: "MANDAL",
    address: "C/O MADHU SUDAN MANDAL*VILL&PO KUMARPUR*MURSHIDABAD 742189.",
    fullName: "KHOKAN MANDAL",
    saID: "SPR4703",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "RAY",
    address: "R S BHABAN,UKILPARA*PO-RAIGANJ*UTTAR DINAJPUR-733134",
    fullName: "RABINDRA NATH RAY",
    saID: "SPR4704",
  },
  {
    firstName: "AMULYA KUMAR",
    lastName: "DEY",
    address: "AT-KURUL*PO-JANKAPUR*PASCHIM MEDINIPUR",
    fullName: "AMULYA KUMAR DEY",
    saID: "SPR4705",
  },
  {
    firstName: "SURESH",
    lastName: "PRASAD",
    address: "VILL-ASHAPATTI*PO-KARNAUL*MUZAFFARPUR",
    fullName: "SURESH PRASAD",
    saID: "SPR4706",
  },
  {
    firstName: "NARAYAN  CHANDRA",
    lastName: "UPADHYAY",
    address: "VILL-KURUNG*PO-JURIGAON*GOALPARA-783120*ASSAM",
    fullName: "NARAYAN  CHANDRA UPADHYAY",
    saID: "SPR4707",
  },
  {
    firstName: "BIRAJ KUMAR",
    lastName: "PADHI",
    address: "AT&PO-SIMULIA(MARKONA)*DIST-BALASORE 756126*PAN-AHBPP4049E",
    fullName: "BIRAJ KUMAR PADHI",
    saID: "SPR4708",
  },
  {
    firstName: "BACHCHU",
    lastName: "PRASAD",
    address: "VILL-DHANESWAR GHAT*PO-BIHARSARIF*NALANDA-803101*PAN-ALKPP7760M",
    fullName: "BACHCHU PRASAD",
    saID: "SPR4709",
  },
  {
    firstName: "GOURANGA CHANDRA",
    lastName: "DWIBEDY",
    address:
      "PLOT NO 110,MAHANADI VIHAR*NEAR AKHANDALAMANI TEMPLE*CUTTACK 753004*PAN-AZWPD5963E",
    fullName: "GOURANGA CHANDRA DWIBEDY",
    saID: "SPR4710",
  },
  {
    firstName: "PRAVASH",
    lastName: "DAS",
    address: "RATANPITH COLLEGE*BAHALPUR*PO-CHAPAR*DHUBRI-783371*ASSAM",
    fullName: "PRAVASH DAS",
    saID: "SPR4711",
  },
  {
    firstName: "DEOKUMAR LAL",
    lastName: "DAS",
    address: "RETD CONTROLLER,PTPS,PATRATU*JAGDEV PATH*TRISHUL COLONY*PATNA",
    fullName: "DEOKUMAR LAL DAS",
    saID: "SPR4712",
  },
  {
    firstName: "LATE BIRAJ KRISHNA",
    lastName: "BHATTACHARJEE",
    address: "*NADIA",
    fullName: "LATE BIRAJ KRISHNA BHATTACHARJEE",
    saID: "SPR4713",
  },
  {
    firstName: "MALAY",
    lastName: "SIKDAR",
    address: "68, SURYA SEN STREET*KOLKATA-700 009*PAN--GFMPS4516G",
    fullName: "MALAY SIKDAR",
    saID: "SPR4714",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "MERKAP",
    address: "QRS NO-F-5/M.I.,R.E.O.COLONY*NAYABAZAR*CUTTACK 753004",
    fullName: "PABITRA KUMAR MERKAP",
    saID: "SPR4715",
  },
  {
    firstName: "CHAKRADHAR",
    lastName: "JENA",
    address:
      "SATSANG VIHAR LANGALESWAR*PO&VIA-LANGALESWAR*BALASORE-756024*PAN-AZQPJ5259F",
    fullName: "CHAKRADHAR JENA",
    saID: "SPR4716",
  },
  {
    firstName: "KAJAL RANJAN",
    lastName: "DEY",
    address: "KENEDY PARK WARD NO-1*PO-MARIANI*JORHAT*ASSAM*PAN-AUXPD2375G",
    fullName: "KAJAL RANJAN DEY",
    saID: "SPR4717",
  },
  {
    firstName: "TARUN",
    lastName: "BISWAS",
    address: "PO-LONGPOTIA*DIST-SIBSAGAR-785692*ASSAM",
    fullName: "TARUN BISWAS",
    saID: "SPR4718",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MUDULI",
    address: "AT-NIMAPALI*PO-PINGAL*JAJPUR-755 026*PAN-BTBPM9086P",
    fullName: "RANJIT KUMAR MUDULI",
    saID: "SPR4719",
  },
  {
    firstName: "KAHNU CHARAN",
    lastName: "DAS",
    address:
      "C/O BHASKAR CHANDRA DAS*AT&PO-SAHADA*VIA-IRDA*BALASORE 756080*PAN-ACDPD0563A",
    fullName: "KAHNU CHARAN DAS",
    saID: "SPR4720",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "BAIDYA",
    address: "PO-MAYABANDAR*ANDAMAN",
    fullName: "LATE PARESH CHANDRA BAIDYA",
    saID: "SPR4721",
  },
  {
    firstName: "HARILAL",
    lastName: "SARMA",
    address: "VILL-MARADHANSIRI*PO-ROWTA*DIST-UDALGURI (BTAD) 784508*ASSAM",
    fullName: "HARILAL SARMA",
    saID: "SPR4722",
  },
  {
    firstName: "GANESWAR",
    lastName: "SAHOO",
    address: "PLOT NO-115/253*ACHARYA VIHAR*BHUBANESWAR-751013*KHURDA",
    fullName: "GANESWAR SAHOO",
    saID: "SPR4723",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "BODHUK",
    address: "DHARAMPUR MUKHERJEE LANE*PO-CHINSURAH*HOOGHLY 712 101.",
    fullName: "SANTOSH KUMAR BODHUK",
    saID: "SPR4724",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "BAL",
    address: "VILL&PO-RASULPUR*BURDWAN-713151",
    fullName: "BIBHUTI BHUSAN BAL",
    saID: "SPR4725",
  },
  {
    firstName: "BALARAM",
    lastName: "ROUT",
    address: "AT&PO-GHASIPURA*DT-KEONJHAR 758015",
    fullName: "BALARAM ROUT",
    saID: "SPR4726",
  },
  {
    firstName: "GOVINDA CHANDRA",
    lastName: "SAHOO",
    address: "AT-DANDILO*PO-BRAHMAN SARANGI*KHURDA-752102",
    fullName: "GOVINDA CHANDRA SAHOO",
    saID: "SPR4727",
  },
  {
    firstName: "DAYANIDHI",
    lastName: "PANIGRAHI",
    address:
      "(ADVOCATE)*SURAJ VIHAR IIND LINE*BERHAMPUR-760001*GANJAM*PAN-AHIPP0144K",
    fullName: "DAYANIDHI PANIGRAHI",
    saID: "SPR4728",
  },
  {
    firstName: "DUSHASAN",
    lastName: "JENA",
    address:
      "ITDA COLONY,NABARANGPUR*AT/PO/DIST-NABARANGPUR-764059*(PAN-AIMPJ5871G)",
    fullName: "DUSHASAN JENA",
    saID: "SPR4729",
  },
  {
    firstName: "SUJIT KUMAR",
    lastName: "MONDAL",
    address: "VILL/PO-KUSHBERIA*HOWRAH 711315",
    fullName: "SUJIT KUMAR MONDAL",
    saID: "SPR4730",
  },
  {
    firstName: "RAMESWAR",
    lastName: "BORO",
    address:
      "SATSANG SIMENCHAPARY KENDRA*PO/VIA-SIMEN CHAPARI*DIST-DHEMAJI*ASSAM",
    fullName: "RAMESWAR BORO",
    saID: "SPR4731",
  },
  {
    firstName: "NRUSINGHA CHARAN",
    lastName: "SHAW",
    address: "AT/PO-DHOBASHILA*VIA-RAJNILGIRI*BALASORE 756040.",
    fullName: "NRUSINGHA CHARAN SHAW",
    saID: "SPR4732",
  },
  {
    firstName: "DHUKA",
    lastName: "BHUSAGAR",
    address: "VILL-BAGHMARI T E*PO-BURIGANJ*SONITPUR-784176",
    fullName: "DHUKA BHUSAGAR",
    saID: "SPR4733",
  },
  {
    firstName: "BINOD KUMAR",
    lastName: "SHINHA",
    address: "C/O B.P.SINHA*SATSANG",
    fullName: "BINOD KUMAR SHINHA",
    saID: "SPR4734",
  },
  {
    firstName: "MAHITOSH",
    lastName: "HALDAR",
    address: "VILL-NORTH BHABANIPUR*PO-KHARAGPUR*PASCHIM MEDINIPUR-721301.",
    fullName: "MAHITOSH HALDAR",
    saID: "SPR4735",
  },
  {
    firstName: "BAIRAGI CHARAN",
    lastName: "DWIBEDI",
    address: "AT/PO-SUKINDA*JAJPUR 755018",
    fullName: "BAIRAGI CHARAN DWIBEDI",
    saID: "SPR4736",
  },
  {
    firstName: "PULIN CHANDRA",
    lastName: "MANDAL",
    address: "VILL&PO-PARGHUMTI*VIA-HATGACHA*NORTH  24PARGANAS 743439",
    fullName: "PULIN CHANDRA MANDAL",
    saID: "SPR4737",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "DAS",
    address: "PATHSALA SATSANG VIHAR*PO-PATHSALA*DIST-BARPETA-781325*ASSAM",
    fullName: "RAMESH CHANDRA DAS",
    saID: "SPR4738",
  },
  {
    firstName: "GOURANGA LAL",
    lastName: "KARMAKAR",
    address: "SANKAR NAGAR*BILASHPUR*CHATTISGARH-495001",
    fullName: "GOURANGA LAL KARMAKAR",
    saID: "SPR4739",
  },
  {
    firstName: "LATE NIKHIL CHANDRA",
    lastName: "CHATTERJEE",
    address: "*JALPAIGURI",
    fullName: "LATE NIKHIL CHANDRA CHATTERJEE",
    saID: "SPR4740",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "BISWAL",
    address: "AT&PO-HATIBARI*VIA-JUJOMURA*SAMBALPUR 768105",
    fullName: "NARAYAN CHANDRA BISWAL",
    saID: "SPR4741",
  },
  {
    firstName: "SUKANTA KISHORE",
    lastName: "NAYAK",
    address:
      "VILL-SARADA*PO-PURUNAHAT SASAN*DIST-CUTTACK-754 131*PAN-AJZPN5643G",
    fullName: "SUKANTA KISHORE NAYAK",
    saID: "SPR4742",
  },
  {
    firstName: "PRASANTA",
    lastName: "DEBROY",
    address: "",
    fullName: "PRASANTA DEBROY",
    saID: "SPR4743",
  },
  {
    firstName: "GHURAN",
    lastName: "JHA",
    address: "C/O SATSANG VIHAR*NALIA ROAD*PO-DIBRUGARH*DIBRUGARH 786001",
    fullName: "GHURAN JHA",
    saID: "SPR4744",
  },
  {
    firstName: "AMIYASANKAR",
    lastName: "DEBNATH",
    address: "",
    fullName: "AMIYASANKAR DEBNATH",
    saID: "SPR4745",
  },
  {
    firstName: "LATE UMANANDA",
    lastName: "RABHA",
    address: "VILL/PO-DUDHNAI*DIST-GOALPARA.",
    fullName: "LATE UMANANDA RABHA",
    saID: "SPR4746",
  },
  {
    firstName: "SUBRAT KUMAR",
    lastName: "MISHRA",
    address:
      "HOMEO STORE,JAIL ROAD*PHULBANI-762001*DIST-KANDHAMAL*ODISHA*PAN-BHEPM3732P",
    fullName: "SUBRAT KUMAR MISHRA",
    saID: "SPR4747",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "GHOSH",
    address: "JALPAIGURI",
    fullName: "LATE HARENDRA NATH GHOSH",
    saID: "SPR4748",
  },
  {
    firstName: "KHAGENDRA NATH",
    lastName: "JANA",
    address: "QRS NO-150M`E'BLOCK*PO-KANYAPUR*BURDWAN-713341*PAN-ACGPJ0431L",
    fullName: "KHAGENDRA NATH JANA",
    saID: "SPR4749",
  },
  {
    firstName: "LATE MANIK CHANDRA",
    lastName: "DAS",
    address: "VILL-SOUTH BIRNAGAR*PO-RAIGANJ*UTTAR DINAJPUR 733134",
    fullName: "LATE MANIK CHANDRA DAS",
    saID: "SPR4750",
  },
  {
    firstName: "BABUL KUMAR",
    lastName: "DUTTA",
    address: "VILL&PO-CHANDPARA BAZAR*24PARGANAS (NORTH)-743245",
    fullName: "BABUL KUMAR DUTTA",
    saID: "SPR4751",
  },
  {
    firstName: "ATUL CHANDRA",
    lastName: "DAS",
    address: "VILL&PO-NATABARI*VIA-NILKUTHI*COOCHBEHAR-736156",
    fullName: "ATUL CHANDRA DAS",
    saID: "SPR4752",
  },
  {
    firstName: "SUKHOMAY",
    lastName: "SHUKLABAIDYA",
    address: "J.C.GIRLS HIGH SCHOOL ROAD*AT/PO-UDHARBAND*CACHAR 788030",
    fullName: "SUKHOMAY SHUKLABAIDYA",
    saID: "SPR4753",
  },
  {
    firstName: "BISHNU PADA",
    lastName: "RAY",
    address:
      "M.P.,B,J.P. OFFICE*MIDDLE POINT,PORT BLAIR*ANDAMAN 744101*PAN-AEUPR9977B",
    fullName: "BISHNU PADA RAY",
    saID: "SPR4754",
  },
  {
    firstName: "BALI CHARAN",
    lastName: "BRAHMA",
    address: "ONTHAIBARI*PO-AMTEKA*KOKRAJHAR-783 390",
    fullName: "BALI CHARAN BRAHMA",
    saID: "SPR4755",
  },
  {
    firstName: "MRINAL",
    lastName: "SARMA",
    address: "SATSANG VIHAR SHILLONG*CENCH'S PRACE*SHILLONG 793004",
    fullName: "MRINAL SARMA",
    saID: "SPR4756",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "MONDAL",
    address: "MUNSHIGANJ*BANGLADESH",
    fullName: "LATE NAGENDRA NATH MONDAL",
    saID: "SPR4757",
  },
  {
    firstName: "KALYAN KUMAR",
    lastName: "CHAKRABARTY",
    address:
      "C/O DINESH CH CHAKRABORTY*VILL-BORIGAON*PO-ABHAYPURI*BONGAIGAON 783384",
    fullName: "KALYAN KUMAR CHAKRABARTY",
    saID: "SPR4758",
  },
  {
    firstName: "BHOLA NATH",
    lastName: "SAMANTA",
    address: "AT-GADAMPADA*PO-BHAWANIPATNA*KALAHANDI 766001",
    fullName: "BHOLA NATH SAMANTA",
    saID: "SPR4759",
  },
  {
    firstName: "SUKHI RAM",
    lastName: "SAHOO",
    address:
      "VILL-SANKARDEB NAGAR*PO-JORAPUKHURI*VIA-LANKA*NAGAON-782435*ASSAM",
    fullName: "SUKHI RAM SAHOO",
    saID: "SPR4760",
  },
  {
    firstName: "KRISHNA PRASAD",
    lastName: "SITAULA",
    address:
      "CHANDRABARI*C/O SATSANG KENDRA KHORIBARI*PO-KHORIBARI-734427*DARJEELING",
    fullName: "KRISHNA PRASAD SITAULA",
    saID: "SPR4761",
  },
  {
    firstName: "SUJIB KUMAR",
    lastName: "MANDAL",
    address:
      "D-5 FLAT NO-1/6*OLD CABLE TOWN*PO-GOLMURI*JAMSHEDPUR-831003*PAN-AESPM9645K",
    fullName: "SUJIB KUMAR MANDAL",
    saID: "SPR4762",
  },
  {
    firstName: "ANAND KUMAR",
    lastName: "SINGH",
    address: "AT-CHILGUDA*PO-KOKSARA*KALAHANDI-766019",
    fullName: "ANAND KUMAR SINGH",
    saID: "SPR4763",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "BAG",
    address: "TEACHER, B M N HIGH SCHOOL*PO-NANDAKUMAR*PURBA MEDINIPUR-721632",
    fullName: "SUBAL CHANDRA BAG",
    saID: "SPR4764",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "BISWAS",
    address:
      "NETAJI COLONY*PO-BHADRAWATI*DT-CHANDRAPUR*M.S.-442902*PAN-DEEPB0259P",
    fullName: "AJIT KUMAR BISWAS",
    saID: "SPR4765",
  },
  {
    firstName: "LATE MOHAN",
    lastName: "JOSHI",
    address:
      "C/O SOMA HOTEL*PO-DULAL JOTE*VIA-NUXALBARI*DARJEELING-734429*W.B.",
    fullName: "LATE MOHAN JOSHI",
    saID: "SPR4766",
  },
  {
    firstName: "NARAD",
    lastName: "KOIRALA",
    address: "C/O SATSANG VIHAR SILIGURI*DARJEELING*(SHIFTED TO NEPAL)",
    fullName: "NARAD KOIRALA",
    saID: "SPR4767",
  },
  {
    firstName: "RAS BIHARI",
    lastName: "BERA",
    address: "TUHINA ICE CANDY*PO-CHAMPADANGA*HOOGHLY-712401*pan ADXPB9430B",
    fullName: "RAS BIHARI BERA",
    saID: "SPR4768",
  },
  {
    firstName: "MAHESWAR",
    lastName: "DASH",
    address:
      "VILL-POKHARIAPARA*PO-PATTAMUNDAI*KENDRAPARA 754215*PAN-AEPPD7584M",
    fullName: "MAHESWAR DASH",
    saID: "SPR4769",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "DEY",
    address:
      "AJANTA AUTO WORKS*HOSPITAL ROAD*PO-FORBESGANJ 854318*DT-ARARIA*PAN NO-BTUPD7308C",
    fullName: "SAMIR KUMAR DEY",
    saID: "SPR4770",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "SHARAN",
    address: "1/17 TEXT BOOK COLONY*KESHARI NAGAR*PATNA 24*PAN.AQFPS1283G",
    fullName: "PRAMOD KUMAR SHARAN",
    saID: "SPR4771",
  },
  {
    firstName: "MOHAN LAL",
    lastName: "GHOSH",
    address: "VILL-JAYPUR*PO-CHHAYGHARIA*NORTH 24PARGANAS-743 249",
    fullName: "MOHAN LAL GHOSH",
    saID: "SPR4772",
  },
  {
    firstName: "LATE JABIRAM",
    lastName: "MILI",
    address: "C/O HARINATH SARMA*PO-BAMUNI MAIDAN*GOUHATI 21",
    fullName: "LATE JABIRAM MILI",
    saID: "SPR4773",
  },
  {
    firstName: "DAYAL CHANDRA",
    lastName: "HALDAR",
    address: "NO 2 COLONY*PO-R H COLONY*VIA-SINDHANOR*RAICHUR*KARNATAKA 584128",
    fullName: "DAYAL CHANDRA HALDAR",
    saID: "SPR4774",
  },
  {
    firstName: "G",
    lastName: "RAGHUNATHAM",
    address: "ANAND BOOK HOUSE*RAJENDRA NAGAR*HYDERABAD 500030.",
    fullName: "G RAGHUNATHAM",
    saID: "SPR4775",
  },
  {
    firstName: "ANATH RANJAN",
    lastName: "MANDAL",
    address: "AT/PO-ARAGAHI*DIST-BALRAMPUR*C.G. 497 220.",
    fullName: "ANATH RANJAN MANDAL",
    saID: "SPR4776",
  },
  {
    firstName: "LATE DIPTI KUMAR",
    lastName: "KARAN",
    address: "BATTALA LANE*KOTRUNG*HOOGHLY",
    fullName: "LATE DIPTI KUMAR KARAN",
    saID: "SPR4777",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "CHOUDHURY",
    address:
      "AMRITA BHANDAR*SHIB MANDIR BAZAR*PO-KADAMTALA*SILIGURI*DARJEELING 734011",
    fullName: "SWAPAN KUMAR CHOUDHURY",
    saID: "SPR4778",
  },
  {
    firstName: "LATE SANTOSH BIJAY",
    lastName: "NAG",
    address: "ANUKUL NIRAMOY*PO-PATENGA*CHITTAGONG*BANGLADESH",
    fullName: "LATE SANTOSH BIJAY NAG",
    saID: "SPR4779",
  },
  {
    firstName: "ADHIR KRISHNA",
    lastName: "BAULIA",
    address: "SATKHIRA*BANGLADESH",
    fullName: "ADHIR KRISHNA BAULIA",
    saID: "SPR4780",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "DEBNATH",
    address: "VILL/PO-BUDH HATA*PS-ASHASUNIA*SATKHIRA*BANGLADESH",
    fullName: "KARTIK CHANDRA DEBNATH",
    saID: "SPR4781",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "PAUL",
    address: "VILL/PO-TOWN SRIPUR*PS-DEBHATA*SATKHIRA*BANGLADESH",
    fullName: "SANTOSH KUMAR PAUL",
    saID: "SPR4782",
  },
  {
    firstName: "ARABINDA KUMAR",
    lastName: "SANA",
    address: "AMURKATA RANGDHANU M E SCHOOL*PO-SOLADANA*KHULNA*BANGLADESH",
    fullName: "ARABINDA KUMAR SANA",
    saID: "SPR4783",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "DUTTA",
    address: "155/2, ASHOK NAGAR*PO-ASHOK NAGAR*24 PARGANAS NORTH-743222.",
    fullName: "PRADIP KUMAR DUTTA",
    saID: "SPR4784",
  },
  {
    firstName: "DEBI PRASAD",
    lastName: "CHAUDHURY",
    address:
      "C/O NIROD KUMAR CHOUDHURY*PO-NEW BARRACKPUR(MASUNDI)*24PARGANAS (NORTH)",
    fullName: "DEBI PRASAD CHAUDHURY",
    saID: "SPR4785",
  },
  {
    firstName: "RAKHAL CHANDRA",
    lastName: "SAHA",
    address:
      "VILL-OXIGURI (HATIDHURA)*PO-SRINAGAR*VIA-TAMARHAT*KOKRAJHAR 783332*PAN-BERPS5615B",
    fullName: "RAKHAL CHANDRA SAHA",
    saID: "SPR4786",
  },
  {
    firstName: "JAGANMAY",
    lastName: "PANIGRAHI",
    address: "PO&VILL-HAIPUR*MIDNAPUR 721433",
    fullName: "JAGANMAY PANIGRAHI",
    saID: "SPR4787",
  },
  {
    firstName: "LATE KARUNAKANTA",
    lastName: "BORO",
    address: "VILL-KARAKAPARA*PO-BIRPARA*KAMRUP 781123",
    fullName: "LATE KARUNAKANTA BORO",
    saID: "SPR4788",
  },
  {
    firstName: "KANCHAN PRASAD",
    lastName: "MITRA",
    address: "",
    fullName: "KANCHAN PRASAD MITRA",
    saID: "SPR4789",
  },
  {
    firstName: "NARENDRA PRASAD",
    lastName: "BUGUDEI",
    address:
      '"NANDANIKA"*AT-APITIRA*PO-JAGESWARPADA*VIA-GOPALPUR*BALASORE 756044',
    fullName: "NARENDRA PRASAD BUGUDEI",
    saID: "SPR4790",
  },
  {
    firstName: "AMARENDRA LAL",
    lastName: "DUTTA",
    address: "DUTTA VILLA*VIDYUT MARG*BALASORE-756001*PAN-ABMPD0605K",
    fullName: "AMARENDRA LAL DUTTA",
    saID: "SPR4791",
  },
  {
    firstName: "CHINTAMANI",
    lastName: "MOHAPATRA",
    address:
      "2/21,PARISHRAM PARK*REFINERY ROAD*BAPU DARGA,GORWA*BARODA 390016*GUJARAT*ACWPM3859R",
    fullName: "CHINTAMANI MOHAPATRA",
    saID: "SPR4792",
  },
  {
    firstName: "ARABINDA",
    lastName: "PAL",
    address:
      "BADURIA NATUN RASTA*ARSULA MAJHER PARA*PO/PS-BADURIA*24PGS(N)-743401*PAN-AKLPP5939Q",
    fullName: "ARABINDA PAL",
    saID: "SPR4793",
  },
  {
    firstName: "LATE RAMCHANDRA",
    lastName: "PRASAD",
    address: '"KUMAR KUNJ"*NEW CHANDMARI*PO-MOTIHARI*EAST CHAMPARAN-845401',
    fullName: "LATE RAMCHANDRA PRASAD",
    saID: "SPR4794",
  },
  {
    firstName: "SHYAMAL CHANDRA",
    lastName: "GHOSH",
    address: "24/82/2 JAICHAND ROAD*KHAGRA*BERHAMPUR*MURSHIDABAD 742103",
    fullName: "SHYAMAL CHANDRA GHOSH",
    saID: "SPR4795",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "JOSHI",
    address: "THIKADARPARA*AT&PO-TITILAGARH*BOLANGIR 767033*PAN AOVPJ8611F",
    fullName: "PRADIP KUMAR JOSHI",
    saID: "SPR4796",
  },
  {
    firstName: "NANDAN KUNJA",
    lastName: "RAY",
    address: "VILL/PO-KORAKATI*PS&VIA-SANDESKHALI*24PARGANAS (NORTH)-743446",
    fullName: "NANDAN KUNJA RAY",
    saID: "SPR4797",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "HALDAR",
    address: "58,NETAJI SUBHASH ROAD*BEHALA*CALCUTTA-700034.",
    fullName: "DIPAK KUMAR HALDAR",
    saID: "SPR4798",
  },
  {
    firstName: "RAMAWATAR PRASAD",
    lastName: "MANDAL",
    address: "VILL&PO-FARRERH*VIA-MAHESHKHUNT*DIST-KHAGARIA.",
    fullName: "RAMAWATAR PRASAD MANDAL",
    saID: "SPR4799",
  },
  {
    firstName: "LATE HARI CHANDRA",
    lastName: "KALITA",
    address: "VILL-KOTHARA*PO-DAKSHIN GAON*VIA-SANAKUCHI*NALBARI",
    fullName: "LATE HARI CHANDRA KALITA",
    saID: "SPR4800",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "BRAHMA",
    address:
      "RAMPUR CHAWK(N)*WARD NO-1(NO 1 SAPKATI)*PO/DIST-UDALGURI*BTAD (ASSAM)*PIN-784 509",
    fullName: "BIRENDRA NATH BRAHMA",
    saID: "SPR4801",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "MAHALDER",
    address: "C/O BHOLANATH SARKAR*AT-BHOMRA*PO-KASTADANGA*NADIA",
    fullName: "LATE JAGADISH CHANDRA MAHALDER",
    saID: "SPR4802",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "SARKAR",
    address: "SATSANG VIHAR*PO-JAMALDAHA*COOCH BEHAR-735303",
    fullName: "ANIL KUMAR SARKAR",
    saID: "SPR4803",
  },
  {
    firstName: "LATE MRINAL KANTI",
    lastName: "MALLICK",
    address: "VILL-BETKATA*PO-DENATALA*DIST-BAGERHAT*BANGLADESH",
    fullName: "LATE MRINAL KANTI MALLICK",
    saID: "SPR4804",
  },
  {
    firstName: "ACHYUT CHANDRA",
    lastName: "NAYAK",
    address: "AT/PO-AMRIKHOWA*DIST-BARPETA-781307*ASSAM",
    fullName: "ACHYUT CHANDRA NAYAK",
    saID: "SPR4805",
  },
  {
    firstName: "KANCHAN PRASAD",
    lastName: "MITRA",
    address: "",
    fullName: "KANCHAN PRASAD MITRA",
    saID: "SPR4806",
  },
  {
    firstName: "SIVANAND",
    lastName: "PRASAD",
    address: "",
    fullName: "SIVANAND PRASAD",
    saID: "SPR4807",
  },
  {
    firstName: "SUBAL CHANDRA",
    lastName: "BISWAS",
    address: "VILL-MIRZAPUR*PO-BASIRHAT COLLEGE*24 PARGANAS (NORTH)",
    fullName: "SUBAL CHANDRA BISWAS",
    saID: "SPR4808",
  },
  {
    firstName: "RAJEN",
    lastName: "CHARAK",
    address: "VILL-MONABARIE T E*PO-LOHARIJAN (B CHARALI)*SONITPUR-784176.",
    fullName: "RAJEN CHARAK",
    saID: "SPR4809",
  },
  {
    firstName: "SHYAM NANDAN",
    lastName: "SINGH",
    address: "BABU SAHEB COLONY*KABIL PUR*LAHERIASARAI*DARBHANGA-846001",
    fullName: "SHYAM NANDAN SINGH",
    saID: "SPR4810",
  },
  {
    firstName: "APARTHIB",
    lastName: "ROYCHAKRABARTY",
    address: "SATSANG*DEOGHAR.",
    fullName: "APARTHIB ROYCHAKRABARTY",
    saID: "SPR4811",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "BISWAS",
    address:
      "C/O SUBODH CHANDRA ROY*VILL-SREE COLONY*PO-BAGDOGRA*DARJEELING 734422",
    fullName: "TAPAN KUMAR BISWAS",
    saID: "SPR4812",
  },
  {
    firstName: "LATE ASOK KUMAR",
    lastName: "SUR",
    address: "VILL-AHAMADPUR*PO-PATULIGRAM*HOOGHLY 712501",
    fullName: "LATE ASOK KUMAR SUR",
    saID: "SPR4813",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "CHOUDHURY",
    address: "SATSANG VIHAR*PO-BALURGHAT*DAKSHIN DINAJPUR 733101.",
    fullName: "RANJIT KUMAR CHOUDHURY",
    saID: "SPR4814",
  },
  {
    firstName: "RATI",
    lastName: "CHAKRABARTY",
    address: "SATSANG BIHAR*PO-JAMALDAH*COOCHBEHAR-735303",
    fullName: "RATI CHAKRABARTY",
    saID: "SPR4815",
  },
  {
    firstName: "RANJAN",
    lastName: "MALLICK",
    address: "VILL&PO-TILARI*VIA-BUDANGANJ*HOOGHLY 712122",
    fullName: "RANJAN MALLICK",
    saID: "SPR4816",
  },
  {
    firstName: "THULA  KANTA",
    lastName: "NEOG",
    address: "",
    fullName: "THULA  KANTA NEOG",
    saID: "SPR4817",
  },
  {
    firstName: "LATE GADADHAR PRASAD",
    lastName: "YADAV",
    address: "AT-BABUPUR*PO-TINPAHAR*SAHEBGANJ",
    fullName: "LATE GADADHAR PRASAD YADAV",
    saID: "SPR4818",
  },
  {
    firstName: "LATE GOPAL",
    lastName: "DAS",
    address: "51/12 NEOGIPARA ROAD*PO-BARANAGAR*CAL 36",
    fullName: "LATE GOPAL DAS",
    saID: "SPR4819",
  },
  {
    firstName: "PRATIKUL",
    lastName: "SARKAR",
    address:
      "AT-SUDARSHANPUR*PO-RAIGANJ*UTTAR DINAJPUR-733134*PAN-- ALWPS8005Q",
    fullName: "PRATIKUL SARKAR",
    saID: "SPR4820",
  },
  {
    firstName: "LAKSHMI KANTA",
    lastName: "RAY",
    address: "BOLPUR NUTANPALLY*PO-BOLPUR*BIRBHUM-731204*PAN-ACYPR5761P",
    fullName: "LAKSHMI KANTA RAY",
    saID: "SPR4821",
  },
  {
    firstName: "TRINATH",
    lastName: "MAHARANA",
    address:
      '"NEELACHAL"*PLOT NO-62/2392*SHATABDI NAGAR*BHUBANESWAR-751003*PAN-ACYPM6686J',
    fullName: "TRINATH MAHARANA",
    saID: "SPR4822",
  },
  {
    firstName: "BISHWESWAR",
    lastName: "DAS",
    address: "VILL-KHELMA 3 RD KHANDA*PO-SADIRKHAL*CACHAR 788815",
    fullName: "BISHWESWAR DAS",
    saID: "SPR4823",
  },
  {
    firstName: "PRABHAKAR R",
    lastName: "CHAUDHURY",
    address: "MANTHALE NAGAR LATUR*LATUR*M.S. 413512",
    fullName: "PRABHAKAR R CHAUDHURY",
    saID: "SPR4824",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "SANYAL",
    address:
      "H-3/92 MAHABIR ENCLAVE*BENGALI COLONY*NEAR KALIBARI*NEW DELHI-110045*PAN-AOOPS4915A",
    fullName: "ASHOK KUMAR SANYAL",
    saID: "SPR4825",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "CHATTERJEE",
    address: "*SATSANG",
    fullName: "LATE PRAFULLA KUMAR CHATTERJEE",
    saID: "SPR4826",
  },
  {
    firstName: "RAMASHRAY",
    lastName: "SINGH",
    address:
      "SATSANG VIHAR,DARBHANGA*AT-BHAIROPATTY*PO-MILKICHAK*DARBHANGA-846009",
    fullName: "RAMASHRAY SINGH",
    saID: "SPR4827",
  },
  {
    firstName: "JATINDRA",
    lastName: "MOHANTY",
    address: "",
    fullName: "JATINDRA MOHANTY",
    saID: "SPR4828",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "PRADHAN",
    address: "BOUDH SATSANG VIHAR*AT/PO/DIST-BOUDH 762 014*PAN-BAOPP8044E",
    fullName: "BIJAY KUMAR PRADHAN",
    saID: "SPR4829",
  },
  {
    firstName: "HARENDRA NATH",
    lastName: "MANDAL",
    address:
      "RUDRAPUR MALIK COLONY*PO-RUDRAPUR*U.S.NAGAR*UTTARANCHAL 263153*PAN AAZPM4696F",
    fullName: "HARENDRA NATH MANDAL",
    saID: "SPR4830",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "GOGOI",
    address:
      "PRABHU PUJA;2 NO BARBIL*VIA-TIPAM T E*PO-DIGBOI*DIST-TINSUKIA 786171",
    fullName: "HEMANTA KUMAR GOGOI",
    saID: "SPR4831",
  },
  {
    firstName: "NIMAI KUMAR",
    lastName: "RAY",
    address: "43,SASHI BHUSAN DEY STREET*CALCUTTA 700 012",
    fullName: "NIMAI KUMAR RAY",
    saID: "SPR4832",
  },
  {
    firstName: "SUDARSAN",
    lastName: "PANDE",
    address:
      "VILL-PABNA COLONY*PO-CHAKDAHA*DIST-NADIA-741222*W.B.*PAN-AFXPP8814K.",
    fullName: "SUDARSAN PANDE",
    saID: "SPR4833",
  },
  {
    firstName: "TANMAY CHITTA",
    lastName: "DUTTA",
    address:
      '"SRIPANCHAMI"DISHARI MARG*PO-HRIDAYPUR*24PARGANAS (NORTH)-700127*PAN-ADCPD2156M',
    fullName: "TANMAY CHITTA DUTTA",
    saID: "SPR4834",
  },
  {
    firstName: "BIKASH KANTI",
    lastName: "SHOM",
    address:
      "C/O-SWASTI BITAN,GREENWOOD LANE*PO-LALGANESH*ODALBAKRA*GUWAHATI-781034*KAMRUP",
    fullName: "BIKASH KANTI SHOM",
    saID: "SPR4835",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MAIKAP",
    address: "DIGHA SATSANG MANDIR*PO-DIGHA*PURBA MEDINIPUR-721428",
    fullName: "NIRANJAN MAIKAP",
    saID: "SPR4836",
  },
  {
    firstName: "MAHENDRA KUMAR",
    lastName: "MISHRA",
    address:
      "B-305,HORIZON*RAHEJA VIHAR*NEAR CHANDIVALI STUDIO,POWAI*MUMBAI 400072",
    fullName: "MAHENDRA KUMAR MISHRA",
    saID: "SPR4837",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "GURU",
    address: "NEAR SATSANG VIHAR,NUAPADA*PO&DIST-NUAPADA 766 105*ORISSA",
    fullName: "SAROJ KUMAR GURU",
    saID: "SPR4838",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "ROYTARAFDAR",
    address: "C/O RAMASHANKR TRIVEDI*KALACHARI*MANIK BHANDAR*TRIPURA",
    fullName: "LATE HARIPADA ROYTARAFDAR",
    saID: "SPR4839",
  },
  {
    firstName: "MAHADEV",
    lastName: "MONDAL",
    address: "260 BURIR BAGAN*PO&DIST-BURDWAN-713101*PAN-ALHPM7700R",
    fullName: "MAHADEV MONDAL",
    saID: "SPR4840",
  },
  {
    firstName: "PROMOD KUMAR",
    lastName: "JOSHI",
    address: "UPPER MAWPREM*SHILLONG-793002*MEGHALAYA",
    fullName: "PROMOD KUMAR JOSHI",
    saID: "SPR4841",
  },
  {
    firstName: "RABINDRA KUMAR",
    lastName: "SHINHA",
    address: "WB 75 GANESH NAGAR II*SHAKARPUR *DELHI-110092",
    fullName: "RABINDRA KUMAR SHINHA",
    saID: "SPR4842",
  },
  {
    firstName: "SUPADA",
    lastName: "CHAKRAVORTY",
    address: "VILL-SUNDARMAHAL*PO-SURKHALI*KHULNA*BANGLADESH",
    fullName: "SUPADA CHAKRAVORTY",
    saID: "SPR4843",
  },
  {
    firstName: "PARITOSH",
    lastName: "DAS",
    address:
      "C/O SANJOY AGARWAL*7/1 KAMARDANGA ROAD*CALCUTTA 700046*PAN-AEXPD2841R",
    fullName: "PARITOSH DAS",
    saID: "SPR4844",
  },
  {
    firstName: "JAGANNATH",
    lastName: "MANDAL",
    address: "AT&PO-PARGHUMTI*VIA-HATGACHA*24PARGANAS (NORTH)-743439",
    fullName: "JAGANNATH MANDAL",
    saID: "SPR4845",
  },
  {
    firstName: "ABHIRAM",
    lastName: "SAHOO",
    address:
      "AT-KHAJURIAKATA*PO/PS-BALIMI*DIST-DHENKANAL-759020*PAN-AMYPS2815F",
    fullName: "ABHIRAM SAHOO",
    saID: "SPR4846",
  },
  {
    firstName: "MANAS KUMAR",
    lastName: "BERA",
    address:
      "MOHANPUR(SINGROI) SATSANGKENDRA*PO-RAJNAGAR*PURBA MEDINIPUR 721436",
    fullName: "MANAS KUMAR BERA",
    saID: "SPR4847",
  },
  {
    firstName: "AWADH KUMAR",
    lastName: "SINHA",
    address:
      "592 CHHA/69 RAM NAGAR*KHARIKA,TELIBAGH*LUCKNOW 226002*PAN-AEIPS9118R",
    fullName: "AWADH KUMAR SINHA",
    saID: "SPR4848",
  },
  {
    firstName: "KAMAL",
    lastName: "PAL",
    address:
      "VILL-LABANGOAL*PO-CHARGHAT*VIA-D CHATRA*NORTH 24PARGANAS 743247*PAN-BVSPP0136Q.",
    fullName: "KAMAL PAL",
    saID: "SPR4849",
  },
  {
    firstName: "SUNIL CHANDRA",
    lastName: "DEY",
    address: "C/O GURU ELECTRICAL WORKS*GOLAGHAT ROAD*DIMAPUR*NAGALAND 797112",
    fullName: "SUNIL CHANDRA DEY",
    saID: "SPR4850",
  },
  {
    firstName: "ABHAY CHANDRA",
    lastName: "DAWN",
    address: "UKHRA PURATAN HATTALA*PO-UKHRA*BARDDHAMAN-713363*W.B.",
    fullName: "ABHAY CHANDRA DAWN",
    saID: "SPR4851",
  },
  {
    firstName: "ASISH KUMAR",
    lastName: "MUKHERJEE",
    address:
      "WEST UPCAR GARDEN*NEAR SAGARIKA FLAT*PO-ASANSOL-4*BURDWAN-713 304*PAN-ADQPM9414R",
    fullName: "ASISH KUMAR MUKHERJEE",
    saID: "SPR4852",
  },
  {
    firstName: "LATE DEBASISH",
    lastName: "MITRA",
    address: "29, JAGANNATH SUR LANE*CALCUTTA 700006.",
    fullName: "LATE DEBASISH MITRA",
    saID: "SPR4853",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "BANERJEE",
    address:
      "53/1/3/1/3A, DHARMATALA LANE*SHIBPUR*HOWRAH-711102*PAN-ADPPB6404R",
    fullName: "SUBHAS CHANDRA BANERJEE",
    saID: "SPR4854",
  },
  {
    firstName: "NANO CAR",
    lastName: "",
    address: "",
    fullName: "NANO CAR ",
    saID: "SPR4855",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "GHOSE",
    address:
      "C/O-NIRAPADA GHOSH*VILL-AYESHPUR*PO-NARAYANPUR*NADIA-741249*PAN-BEUPG6951B",
    fullName: "KRISHNA CHANDRA GHOSE",
    saID: "SPR4856",
  },
  {
    firstName: "SIDDHARTHA SANKAR",
    lastName: "PATRA",
    address: "C/O RABINARAYAN PATRA*AT&PO-JENAPUR*DIST-JAJPUR-755023",
    fullName: "SIDDHARTHA SANKAR PATRA",
    saID: "SPR4857",
  },
  {
    firstName: "BISWANATH",
    lastName: "BARMAN",
    address: "SATSANG KENDRA,RASAKHOWA*PO-RASAKHOWA*UTTAR DINAJPUR",
    fullName: "BISWANATH BARMAN",
    saID: "SPR4858",
  },
  {
    firstName: "RATAN KUMAR",
    lastName: "CHAKRABARTY",
    address: "VILL-KISMAT APURBAPUR*PO-SINGUR*HOOGHLY-712409*PAN-ACUPC4975G",
    fullName: "RATAN KUMAR CHAKRABARTY",
    saID: "SPR4859",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "SINGH",
    address: "SATSANG THAKURBARI*SATSANG*",
    fullName: "SANJAY KUMAR SINGH",
    saID: "SPR4860",
  },
  {
    firstName: "KANAN",
    lastName: "CHAUDHURY",
    address: "C/O SRI SRI BORDA*SATSANG 814116",
    fullName: "KANAN CHAUDHURY",
    saID: "SPR4861",
  },
  {
    firstName: "MANILAL",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "MANILAL CHAKRABORTY",
    saID: "SPR4862",
  },
  {
    firstName: "MANILAL",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "MANILAL CHAKRABORTY",
    saID: "SPR4863",
  },
  {
    firstName: "SWARUP MOHAN",
    lastName: "DAS",
    address: "MITALI X-RAY CLINIC*PO-TEZPUR*DT-SONITPUR 784001",
    fullName: "SWARUP MOHAN DAS",
    saID: "SPR4864",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "GHOSE",
    address: "VILL&PO-BONGAON PASCHIMPARA*24PARGANAS (NORTH)",
    fullName: "SUNIL KUMAR GHOSE",
    saID: "SPR4865",
  },
  {
    firstName: "ANIRUDH",
    lastName: "DAS",
    address: "AT&PO-BELARAHI*VIA-JHANJHARPUR*MADHUBANI 847 404",
    fullName: "ANIRUDH DAS",
    saID: "SPR4866",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MISHRA",
    address: "NIRMAYA HOMEO CLINIC*AT&PO-UMERKOTE*NABARANGPUR-764073",
    fullName: "PANCHANAN MISHRA",
    saID: "SPR4867",
  },
  {
    firstName: "GATIKRUSHNA",
    lastName: "UTTARKABAT",
    address: "AT-SABALANGA*PO-RAYPUR*VIA-JANKIA*PURI-752020",
    fullName: "GATIKRUSHNA UTTARKABAT",
    saID: "SPR4868",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MOHAPATRA",
    address:
      '"SHREYASUDHA"*BANIKANTHA NAGAR,ATHGARH*NEAR SARSWATI SISHU MANDIR*CUTTACK-754029',
    fullName: "PRAFULLA KUMAR MOHAPATRA",
    saID: "SPR4869",
  },
  {
    firstName: "MONO RANJAN",
    lastName: "THAKURIA",
    address: "VILL&PO-SATPAKHALI*KAMRUP 781132",
    fullName: "MONO RANJAN THAKURIA",
    saID: "SPR4870",
  },
  {
    firstName: "LATE MIHIR KUMAR",
    lastName: "ROYCHOUDHURI",
    address: "3,SIBTALA LANE*HOWRAH 2",
    fullName: "LATE MIHIR KUMAR ROYCHOUDHURI",
    saID: "SPR4871",
  },
  {
    firstName: "NABIN CHANDRA",
    lastName: "BORO",
    address: "C/O SATSANG VIHAR TAMULPUR*PO-TAMULPUR*NALBARI",
    fullName: "NABIN CHANDRA BORO",
    saID: "SPR4872",
  },
  {
    firstName: "MANIK CHANDRA",
    lastName: "MUKHERJEE",
    address: "VILL-CHANCHAR*PO-BANKADAHA*BANKURA-7221164",
    fullName: "MANIK CHANDRA MUKHERJEE",
    saID: "SPR4873",
  },
  {
    firstName: "DURYODHAN",
    lastName: "RATH",
    address: "SWASTI NAGAR*SATSANG VIHAR*PO/DIST-KENDRAPARA",
    fullName: "DURYODHAN RATH",
    saID: "SPR4874",
  },
  {
    firstName: "NARAYAN",
    lastName: "PARIYAR",
    address: "DUBHI BAZAR,SUNSARI*PO-JOGBANI*ARARIYA-854328",
    fullName: "NARAYAN PARIYAR",
    saID: "SPR4875",
  },
  {
    firstName: "LATE SAMARESH CHANDRA",
    lastName: "SARKAR",
    address: "SUB INSPT OF POLICE*PO-MOLLABELIA*PS-HARINGHATA*NADIA",
    fullName: "LATE SAMARESH CHANDRA SARKAR",
    saID: "SPR4876",
  },
  {
    firstName: "LATE PRAMOD KUMAR",
    lastName: "KUSHWAHA",
    address:
      "H.M.,MODERN ENGLISH SCHOOL*RAMJANKI NAGAR;PANDEY TOLA*NARKATIAGANJ 845455",
    fullName: "LATE PRAMOD KUMAR KUSHWAHA",
    saID: "SPR4877",
  },
  {
    firstName: "LATE SHITAL CHANDRA",
    lastName: "DEY",
    address: "27/2, SITANATH BOSE LANE*SALKIA*HOWRAH-711 106.",
    fullName: "LATE SHITAL CHANDRA DEY",
    saID: "SPR4878",
  },
  {
    firstName: "KANAI LAL",
    lastName: "ROYADHIKARI",
    address: "VILL-JUGIA**PO-HASNABAD*24PARGANAS NORTH-743426",
    fullName: "KANAI LAL ROYADHIKARI",
    saID: "SPR4879",
  },
  {
    firstName: "HARIPRASAD",
    lastName: "MRIDHA",
    address:
      "VILL-BHABLA KALIBARI*PO-BHABLA*BASIRHAT*24 PARGANAS (NORTH)-743422.",
    fullName: "HARIPRASAD MRIDHA",
    saID: "SPR4880",
  },
  {
    firstName: "SATYABAN",
    lastName: "TRIPATHI",
    address:
      "DEMONSTRATOR IN GEOLOGY*A S COLLEGE TIRTOL*JAGATSINGHPUR-754137*ODISHA",
    fullName: "SATYABAN TRIPATHI",
    saID: "SPR4881",
  },
  {
    firstName: "KRISHNA SAGAR",
    lastName: "BEHERA",
    address:
      "GC-2,KRISHNAKRUPA HOUSING COOP. SOCIETY*AT-PARVATGAM*VIA-BOMBAY MARKET*SURAT-395010",
    fullName: "KRISHNA SAGAR BEHERA",
    saID: "SPR4882",
  },
  {
    firstName: "KANCHAN",
    lastName: "MITRA",
    address: "",
    fullName: "KANCHAN MITRA",
    saID: "SPR4883",
  },
  {
    firstName: "NABIN KUMAR",
    lastName: "MISHRA",
    address: "BELA CHAMPABATI*PO-CHAMPABATI*VIA-SARSI*PURNEA-854306",
    fullName: "NABIN KUMAR MISHRA",
    saID: "SPR4884",
  },
  {
    firstName: "SUNIL",
    lastName: "KUNDU",
    address: "68 SURYA SEN STREET*KOLKATA 700009",
    fullName: "SUNIL KUNDU",
    saID: "SPR4885",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "MAHANTA",
    address: "AT/PO-JAMUKESWAR*VIA-JASHIPUR*MAYURBHANJ-757034*PAN-ACMPM7075K",
    fullName: "DEBENDRA NATH MAHANTA",
    saID: "SPR4886",
  },
  {
    firstName: "SIB CHARAN",
    lastName: "MALAR",
    address: "M/S-KOLONY T.E.(SATSANG KENDRA)*PO-RANGAPARA*SONITPUR 784505.",
    fullName: "SIB CHARAN MALAR",
    saID: "SPR4887",
  },
  {
    firstName: "DEBENDRA NATH",
    lastName: "DALAI",
    address: "O/O THE DY DIRECTOR OF MINES*SAMBALPUR-768001",
    fullName: "DEBENDRA NATH DALAI",
    saID: "SPR4888",
  },
  {
    firstName: "MAHESWAR",
    lastName: "BISWAL",
    address: "ALLIANCE JUTE MILL*PO-JAGATDAL*24PARGANAS (NORTH)-743 125",
    fullName: "MAHESWAR BISWAL",
    saID: "SPR4889",
  },
  {
    firstName: "ABANI KUMAR",
    lastName: "BASUMATARI",
    address: "VILL/PO-GOSSAIGAON P.H.C.*KOKRAJHAR-783360*PAN-ACRPB0792R",
    fullName: "ABANI KUMAR BASUMATARI",
    saID: "SPR4890",
  },
  {
    firstName: "BHABANI SANKAR",
    lastName: "SATPATHY",
    address:
      '"SREENIKETAN"*RAJENDRA NAGAR*PO-MADHUPATNA*CUTTACK 753010*PAN-ACKPS0674E',
    fullName: "BHABANI SANKAR SATPATHY",
    saID: "SPR4891",
  },
  {
    firstName: "LATE PRASANNA GOPALRAO",
    lastName: "KONDO",
    address: "215,SALUKE GALLI*PO-TULJAPUR*DIST-OSMANABAD*MAHARASTRA 413601",
    fullName: "LATE PRASANNA GOPALRAO KONDO",
    saID: "SPR4892",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "SAHOO",
    address:
      "QR NO.B-117*PARADEEP PHOS.LTD PARADEEP*PO-P.P.L.TOWNSHIP*JAGATSINGHPUR 754145.",
    fullName: "KAMALA KANTA SAHOO",
    saID: "SPR4893",
  },
  {
    firstName: "SAMA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "SAMA CHAKRABORTY",
    saID: "SPR4894",
  },
  {
    firstName: "AKSHOY KUMAR",
    lastName: "HAJONG",
    address: "SATSANG VIHAR GAROBADHA*PO-GAROBADHA*WEST GARO HILLS 794105.",
    fullName: "AKSHOY KUMAR HAJONG",
    saID: "SPR4895",
  },
  {
    firstName: "RATAN",
    lastName: "DEBNATH",
    address:
      "C/O FRIEND'S MEDICAL*PO-WILLIAMNAGAR*EAST GARO HILLS*MEGHALAYA 794111",
    fullName: "RATAN DEBNATH",
    saID: "SPR4896",
  },
  {
    firstName: "NARAYAN",
    lastName: "JENA",
    address: "AT&PO-KUDIARY*VIA-JATNI*KHURDHA 752050",
    fullName: "NARAYAN JENA",
    saID: "SPR4897",
  },
  {
    firstName: "LATE SAMBHU NATH",
    lastName: "SAHA",
    address: "C/O SECRETARY,'DIPTADEEN'*PO-HABRA*DT-24 PARGANAS",
    fullName: "LATE SAMBHU NATH SAHA",
    saID: "SPR4898",
  },
  {
    firstName: "BISWAMBAR",
    lastName: "NATH",
    address:
      "LIG-91,DHANUUJAYPUR H.B.COLONY*PO-KEONJHAR BAZAR*KEONJHAR-758002*PAN-AJKPN3733C",
    fullName: "BISWAMBAR NATH",
    saID: "SPR4899",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "BORAH",
    address: "VILL-UKHAMATI NAHARBARI GAON*PO-UKHAMATI*DHEMAJI-787 056*ASSAM",
    fullName: "GHANASHYAM BORAH",
    saID: "SPR4900",
  },
  {
    firstName: "GOBINDA CHANDRA",
    lastName: "SAHA",
    address:
      "KACHARI ROAD(END)*BURIAL GROUND WEST*BURDWAN 713101*PAN-AMBPS8349B",
    fullName: "GOBINDA CHANDRA SAHA",
    saID: "SPR4901",
  },
  {
    firstName: "DHRUBA CHARAN",
    lastName: "SAHU",
    address: "VILL-THORIAPARA*PO-SOMEPUR*DIST-CUTTACK-754130",
    fullName: "DHRUBA CHARAN SAHU",
    saID: "SPR4902",
  },
  {
    firstName: "LATE SUDHIR",
    lastName: "CHATTERJEE",
    address: "*SATSANG",
    fullName: "LATE SUDHIR CHATTERJEE",
    saID: "SPR4903",
  },
  {
    firstName: "MALAY",
    lastName: "CHAKRABARTY",
    address: "AT-DOMJUR SASTITALA*PO-DOMJUR*HOWRAH-711405",
    fullName: "MALAY CHAKRABARTY",
    saID: "SPR4904",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "BISWAS",
    address: "VILL-GOBINDA PUR*PO-RAJNAGAR*MURSHIDABAD-742227",
    fullName: "SHYAMAL KUMAR BISWAS",
    saID: "SPR4905",
  },
  {
    firstName: "GOPINATH",
    lastName: "MURMOO",
    address: "VILL-BALIGARIYA*PO-KULTIKRI*PASCHIM MEDINIPUR 721135",
    fullName: "GOPINATH MURMOO",
    saID: "SPR4906",
  },
  {
    firstName: "MANAHAR B",
    lastName: "PANCHAL",
    address:
      "C/303,KRISHNA PARK*OPPOSITE DAMA RESIDENCY*GANDHIBARI*UMARGAON*DIST-VALSAD*GUJRAT",
    fullName: "MANAHAR B PANCHAL",
    saID: "SPR4907",
  },
  {
    firstName: "MANIKLAL",
    lastName: "SARMA",
    address: "272 UMPLING*SHILLONG*MEGHALAYA 793 006",
    fullName: "MANIKLAL SARMA",
    saID: "SPR4908",
  },
  {
    firstName: "ARABIND",
    lastName: "KUMAR",
    address: "AT&PO-MAGHRAH*VIA-BIHARSARIF*NALANDA 803101",
    fullName: "ARABIND KUMAR",
    saID: "SPR4909",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "SINGH",
    address: "SATSANG VIHAR*BAIGNA*KATIHAR-854105*PAN NO.EPKPS0928Q",
    fullName: "PRADIP KUMAR SINGH",
    saID: "SPR4910",
  },
  {
    firstName: "ARAKHITA",
    lastName: "RANA",
    address: "VILL/PO-AMBAPUA*VIA-BELLAGUNTHA*GANJAM 761119",
    fullName: "ARAKHITA RANA",
    saID: "SPR4911",
  },
  {
    firstName: "RAM KRISHNA",
    lastName: "BANERJEE",
    address: "JAYGURU MEDICAL HALL,PAKHANJOR*PO-PAKHANJOR*BASTER*M.P. 494776",
    fullName: "RAM KRISHNA BANERJEE",
    saID: "SPR4912",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "RAKHIT",
    address: "VILL&PO-JHANTIPAHARI*BANKURA-722137",
    fullName: "SUBHASH CHANDRA RAKHIT",
    saID: "SPR4913",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "HALDAR",
    address:
      "R G P PROCESS*C P P LALKUA*DIST-NAINITAL*UTTARAKHAND 262 402*PAN-AAXPH2856A",
    fullName: "SUNIL KUMAR HALDAR",
    saID: "SPR4914",
  },
  {
    firstName: "MANOJ KUMAR",
    lastName: "KHATUA",
    address:
      "46D,CHOWRINGHEE ROAD*J K MILLENIUM CENTRE,IST FLOOR*NALCO*KOLKATA-71*PAN-AEBPK3060P",
    fullName: "MANOJ KUMAR KHATUA",
    saID: "SPR4915",
  },
  {
    firstName: "BIRAKISORE",
    lastName: "SAHOO",
    address: "AT/PO-GATI ROUT PATNA*VIA-BIRIBATI*CUTTACK-754100",
    fullName: "BIRAKISORE SAHOO",
    saID: "SPR4916",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "PRADHAN",
    address: '"PRASAD NILAY"*AT&PO-GATIROUT PATNA*CUTTACK 754 100.',
    fullName: "GIRIDHARI PRADHAN",
    saID: "SPR4917",
  },
  {
    firstName: "SAKTIPADA",
    lastName: "DUTTA",
    address: "KULTI L.C.MORE*PO-KULTI*BURDWAN-713343",
    fullName: "SAKTIPADA DUTTA",
    saID: "SPR4918",
  },
  {
    firstName: "DHIRAJ KUMAR",
    lastName: "SHOW",
    address: "322 R B C ROAD*GOURIPARA*PO-GORIFA*24PARGANS (NORTH)-743166",
    fullName: "DHIRAJ KUMAR SHOW",
    saID: "SPR4919",
  },
  {
    firstName: "NEMAI CHANDRA",
    lastName: "SAHA",
    address:
      "POSTAL STAFF*SURI HEAD POST OFFICE*PO-SURI*BIRBHUM-731101*PAN-FSDPS9232N",
    fullName: "NEMAI CHANDRA SAHA",
    saID: "SPR4920",
  },
  {
    firstName: "TEJENDRA NATH",
    lastName: "MANDAL",
    address:
      "C/O N C CHAKRABORTY*VILL-GHASIYARA,SONAJHIL*PO-SONARPUR*24 PARGANAS SOUTH-700150",
    fullName: "TEJENDRA NATH MANDAL",
    saID: "SPR4921",
  },
  {
    firstName: "AMULYA CHANDRA",
    lastName: "PANDA",
    address: "AT/PO-KAKRIGUMA*DIST-KORAPUT 765013",
    fullName: "AMULYA CHANDRA PANDA",
    saID: "SPR4922",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "BARGOHAIN",
    address: "WARD NO-3,BIHPURIYA*PO-BIHUPURIYA*LAKHIMPUR-784 161*ASSAM",
    fullName: "PRASANTA KUMAR BARGOHAIN",
    saID: "SPR4923",
  },
  {
    firstName: "JITENDRA KUMAR",
    lastName: "ROUT",
    address:
      "PARDESHI PARA*AT/PO-KHETRAJPUR*DIST-SAMBALPUR-768003*PAN-AXKPR3333E",
    fullName: "JITENDRA KUMAR ROUT",
    saID: "SPR4924",
  },
  {
    firstName: "PROBIN KUMAR",
    lastName: "PAYENG",
    address: "(PHE) BIHPURIA SUB-DIVISION*PO-BIHUPURIA-784161*NORTH LAKHIMPUR",
    fullName: "PROBIN KUMAR PAYENG",
    saID: "SPR4925",
  },
  {
    firstName: "LATE PRAFULLA NARAYAN",
    lastName: "CHAKRABARTY",
    address: "AT-CHIRAILPARA(N)*PO-KALIYAGANJ*UTTAR DINAJPUR 733129",
    fullName: "LATE PRAFULLA NARAYAN CHAKRABARTY",
    saID: "SPR4926",
  },
  {
    firstName: "JOGESH CHANDRA",
    lastName: "BAISHYA",
    address:
      "BEHARBARI;KALIMANDIR PATH*HOUSE NO-33 NEAR A G COLONY*PO-BASISTHA*GUWAHATI 781029",
    fullName: "JOGESH CHANDRA BAISHYA",
    saID: "SPR4927",
  },
  {
    firstName: "DEBABRATA",
    lastName: "GANGOPADHYAY",
    address: "PANDUA DIGHIR PURBAPAR*PO-PANDUA*HOOGHLY-712149",
    fullName: "DEBABRATA GANGOPADHYAY",
    saID: "SPR4928",
  },
  {
    firstName: "SUJIT",
    lastName: "CHANDA",
    address:
      "C/O-SADHAN DAS*SIBBARI ROAD,TARAPUR*RABINDRA SARANI*SILCHAR-788003, PAN-ABPPC6472Q.",
    fullName: "SUJIT CHANDA",
    saID: "SPR4929",
  },
  {
    firstName: "SADHAN",
    lastName: "CHAKRABORTY",
    address: "PO-BAGMA*TRIPURA 799119",
    fullName: "SADHAN CHAKRABORTY",
    saID: "SPR4930",
  },
  {
    firstName: "RAJIB",
    lastName: "BARUA",
    address:
      "MARGHERITA MAKUM PATHER*PO-MAKUM PATHER*VIA-MARGHERITA*DIBRUGARH 786181",
    fullName: "RAJIB BARUA",
    saID: "SPR4931",
  },
  {
    firstName: "JAIPRAKASH",
    lastName: "AGARWAL",
    address:
      "48,EAST GHOSEPARA ROAD*AT/PO-AUTHPORE*24PARGANAS NORTH-743128*PAN-AVFPA0693R",
    fullName: "JAIPRAKASH AGARWAL",
    saID: "SPR4932",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "MISHRA",
    address: "AT&PO-SATSANG*DEOGHAR 814116",
    fullName: "ARUN KUMAR MISHRA",
    saID: "SPR4933",
  },
  {
    firstName: "GOKUL CHANDRA",
    lastName: "MAHATO",
    address:
      "UTKALMANI VIDYA MANDIR,H.S.*AT/PO-CHAKRADHARPUR-833102*SINGHBHUM (W)*PAN-BGQPM0286D",
    fullName: "GOKUL CHANDRA MAHATO",
    saID: "SPR4934",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "BISWAS",
    address: "MOBIL FOOD NUTRAN EX UNIT*DPT PORT BLAIR*ANDAMAN 744104",
    fullName: "ASHOK KUMAR BISWAS",
    saID: "SPR4935",
  },
  {
    firstName: "KALA CHAND",
    lastName: "SAH",
    address:
      "PO&VILL-HATIGARH*VIA-BORIO*SAHEBGANJ-816120*JHARKHAND*PAN-AOMPS7911J",
    fullName: "KALA CHAND SAH",
    saID: "SPR4936",
  },
  {
    firstName: "KANDURI CHARAN",
    lastName: "PRADHAN",
    address:
      "VILL-MATHAPUR*PO-GHIAKHALA*VIA-BALUGAON*KHURDA 752030*PAN-AHCPP7387C",
    fullName: "KANDURI CHARAN PRADHAN",
    saID: "SPR4937",
  },
  {
    firstName: "CHAKRADHAR",
    lastName: "BANDHA",
    address:
      "AT-BRAJANANDAPUR*PO-GOBINDAPUR*VIA-NAYAKANIDIHI*BHADRAK-756164*PAN-CGXPB9587J",
    fullName: "CHAKRADHAR BANDHA",
    saID: "SPR4938",
  },
  {
    firstName: "PRADEEPTA",
    lastName: "MOHAPATRA",
    address: "MACHHUATI*SALIPUR*CUTTACK-754202",
    fullName: "PRADEEPTA MOHAPATRA",
    saID: "SPR4939",
  },
  {
    firstName: "MANOJIT",
    lastName: "MANDAL",
    address: "VILL-THUBA(TAKI STATION PARA)*PO-TAKI*24PARGANAS NORTH-743429",
    fullName: "MANOJIT MANDAL",
    saID: "SPR4940",
  },
  {
    firstName: "JITENDRA KUMAR",
    lastName: "PATI",
    address:
      "LECTURER IN ENGLISH*S S D MAHAVIDYALAYA*GURUDIJHATIA*CUTTACK-754028*PAN-AJKPP8002G",
    fullName: "JITENDRA KUMAR PATI",
    saID: "SPR4941",
  },
  {
    firstName: "GOURHARI",
    lastName: "NANDA",
    address:
      "KIMIRDOLI;NIHALSINGH CHHAK*TOWARDS DHRUPADA ROAD*PO-OLD TOWN*KEONJHAR 758001",
    fullName: "GOURHARI NANDA",
    saID: "SPR4942",
  },
  {
    firstName: "SITA NATH",
    lastName: "CHAKRABARTY",
    address: "40,RADHAMADHAB SAHA LANE*CALCUTTA 700007",
    fullName: "SITA NATH CHAKRABARTY",
    saID: "SPR4943",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "PANIGRAHI",
    address: "AT&PO-BOULAGAM*GANJAM 761026*PAN-BVNPP6431M",
    fullName: "PRADIP KUMAR PANIGRAHI",
    saID: "SPR4944",
  },
  {
    firstName: "NARAHARI",
    lastName: "THAPALIA",
    address: "POST BOX NO-22*SATSANG MANDIR*JOGBANI*ARARIA-854328",
    fullName: "NARAHARI THAPALIA",
    saID: "SPR4945",
  },
  {
    firstName: "ABHIMANYU",
    lastName: "SARKAR",
    address: "TIKARHAT*PO-LAKURDDI*BURDWAN 713102",
    fullName: "ABHIMANYU SARKAR",
    saID: "SPR4946",
  },
  {
    firstName: "RATHINDRA",
    lastName: "DEBRAY",
    address: "WATCH HOUSE& PHOTOGRAPHY*PO-JOWAI NEW HILL*MEGHALAYA 793150",
    fullName: "RATHINDRA DEBRAY",
    saID: "SPR4947",
  },
  {
    firstName: "ANANDA",
    lastName: "BOURI",
    address:
      "ASHRAM PARA*LOWER JHARIADIH*PO-ADRA*PURULIA-723121*PAN-AFMPB2572F",
    fullName: "ANANDA BOURI",
    saID: "SPR4948",
  },
  {
    firstName: "ANANDA",
    lastName: "PATIRI",
    address: "VILL-MAIDHYASATRANG*PO-GAHAPUR*SONITPUR 784168",
    fullName: "ANANDA PATIRI",
    saID: "SPR4949",
  },
  {
    firstName: "BAIDYA NATH",
    lastName: "JHA",
    address:
      "BARBIL STEEL FABRICATION WORKS*IND. ESTATE,BARBIL*PO-MATKAMBEDA*KEONJHAR-758035",
    fullName: "BAIDYA NATH JHA",
    saID: "SPR4950",
  },
  {
    firstName: "SADANANDA",
    lastName: "SARKAR",
    address:
      "KALYAN KUNJA*AMBIKA MUKHARJEE ROAD,PURBACHAL*PO-NATAGARH*24 PARGANAS (NORTH)",
    fullName: "SADANANDA SARKAR",
    saID: "SPR4951",
  },
  {
    firstName: "SURENDRA PRASAD",
    lastName: "PATNAIK",
    address:
      "KALYAN NAGAR*MAHATAB SARANI*PO/DIST-BALASORE 756001*PAN-AGJPP2635H",
    fullName: "SURENDRA PRASAD PATNAIK",
    saID: "SPR4952",
  },
  {
    firstName: "SARAT KUMAR",
    lastName: "DASH",
    address: "E D & SS/FRTG/IGCAR*PO-KALPAKKAM-603102.T.N.*PAN NO.AABPD8419R",
    fullName: "SARAT KUMAR DASH",
    saID: "SPR4953",
  },
  {
    firstName: "PRADYOT KUMAR",
    lastName: "DEBRAY",
    address:
      "A P PALLY*A P C ROY ROAD*PO-AGARPARA*KOLKATA-700109*(PAN-BDCPD4831N)",
    fullName: "PRADYOT KUMAR DEBRAY",
    saID: "SPR4954",
  },
  {
    firstName: "RABILOCHAN",
    lastName: "BRAHMA",
    address: "VILL+PO-DOTMA*KOKRAJHAR 783347",
    fullName: "RABILOCHAN BRAHMA",
    saID: "SPR4955",
  },
  {
    firstName: "ARUN",
    lastName: "KUMAR",
    address:
      "CHETNA AROGYA BHAWAN*MANGLA NAGAR*BIHAR SARIF*NALANDA-80310*PAN ANOPK7265J",
    fullName: "ARUN KUMAR",
    saID: "SPR4956",
  },
  {
    firstName: "SUSHANTA",
    lastName: "SENGUPTA",
    address: "SHYAMA KUNJ*M.I.G.-159*M.P.HOUSING BOARD*BHILAI*M.P. 490026",
    fullName: "SUSHANTA SENGUPTA",
    saID: "SPR4957",
  },
  {
    firstName: "SURANJIT",
    lastName: "DUTTA",
    address: "B.E-73,JANAKPURI*NEW DELHI-110 058*PAN-ACJPD6037F",
    fullName: "SURANJIT DUTTA",
    saID: "SPR4958",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "BISWAS",
    address: "VILL-ARABINDA PALLY*PO-GAJOLE*MALDA-732124",
    fullName: "DIPAK KUMAR BISWAS",
    saID: "SPR4959",
  },
  {
    firstName: "BIRENDRA NARAYAN",
    lastName: "SINGH",
    address:
      "DAYAL MEDICAL HALL*MANSA CHOWK,MILKPUR*PO-KHIJURIWAS*ALWAR*RAJSTHAN*PAN-ASGPS8294L",
    fullName: "BIRENDRA NARAYAN SINGH",
    saID: "SPR4960",
  },
  {
    firstName: "SALIL KUMAR",
    lastName: "MUKHARJEE",
    address: "SANGHATAPARA,DHULIDANGA RD*PO-RAMPURHAT*BIRBHUM  731224",
    fullName: "SALIL KUMAR MUKHARJEE",
    saID: "SPR4961",
  },
  {
    firstName: "MANINDRA CHANDRA",
    lastName: "DAS",
    address:
      "FLAT NO.A501 BPTP RESORT*FARIDABAD, SEC-75*HARIYANA-1210001.*PAN-ACPPD9659P.",
    fullName: "MANINDRA CHANDRA DAS",
    saID: "SPR4962",
  },
  {
    firstName: "SUBHASH",
    lastName: "KALWAR",
    address: "C/O MANIK KALWAR*SAFRAI STATION*PO-MANOMOHANGOUN*SIBSAGAR",
    fullName: "SUBHASH KALWAR",
    saID: "SPR4963",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "PAL",
    address: "VILL-NATUNGRAM*PO-HABRA-PRAFULLANAGAR*24PARGANAS (NORTH)-743268",
    fullName: "MADHUSUDAN PAL",
    saID: "SPR4964",
  },
  {
    firstName: "JAGANNATH",
    lastName: "DEY",
    address:
      "C/O-SWASTI DISTRIBUTOR*S P MALLICK ROAD*JALAGHATA;SINGUR*HOOGHLY PAN-ADAPD1699P",
    fullName: "JAGANNATH DEY",
    saID: "SPR4965",
  },
  {
    firstName: "PRAKASH KUMAR",
    lastName: "SATPATHY",
    address: "AT/PO-LATHANGA*VIA-RAHAMA*JAGATSINGHPUR-754140*PAN-AXOPS0572N",
    fullName: "PRAKASH KUMAR SATPATHY",
    saID: "SPR4966",
  },
  {
    firstName: "LALIT NARAYAN",
    lastName: "OJHA",
    address:
      "AT-SADHAN JYOTI*N4/77,NAYAPALLI*BHUBANESWAR 751 015*PAN-AAFPO7934E",
    fullName: "LALIT NARAYAN OJHA",
    saID: "SPR4967",
  },
  {
    firstName: "LATE SANTI NATH",
    lastName: "BANDOPADHYAY",
    address: "MEJHIA SCHOOL PARA*PO-MEJHIA*BANKURA",
    fullName: "LATE SANTI NATH BANDOPADHYAY",
    saID: "SPR4968",
  },
  {
    firstName: "LALLAN",
    lastName: "SINGH",
    address: "SASWAT BHAWAN*NORTH JAIPRAKASH NAGAR*ROAD NO 3*PATNA-800025",
    fullName: "LALLAN SINGH",
    saID: "SPR4969",
  },
  {
    firstName: "DEBANGSHU",
    lastName: "SHARMA",
    address: "ASHOK ASHRAM*SATSANG 814 116",
    fullName: "DEBANGSHU SHARMA",
    saID: "SPR4970",
  },
  {
    firstName: "RAM RANJAN",
    lastName: "BISWAS",
    address: "VILL&PO-TEHATTA*NADIA 741160*PAN-AEIPB6235L.",
    fullName: "RAM RANJAN BISWAS",
    saID: "SPR4971",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "PANDA",
    address: "COLECTORATE JAJPUR*JAJPUR 751001.",
    fullName: "SANJAY KUMAR PANDA",
    saID: "SPR4972",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "RAY",
    address: "AT&PO-BANAMALIPUR*VIA-DHARMASALA*JAJPUR",
    fullName: "KAMALA KANTA RAY",
    saID: "SPR4973",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "PANDA",
    address: "AT&PO-KESHPUR*KHALLIKOTE (R.S)*GANJAM 761029",
    fullName: "RANJIT KUMAR PANDA",
    saID: "SPR4974",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "JHA",
    address:
      "MIG-163 SASTRINAGAR*PO-GORAKHNATH*GORAKHPUR-273015*U.P. PAN NO.ANKPJ96320",
    fullName: "PRAKASH CHANDRA JHA",
    saID: "SPR4975",
  },
  {
    firstName: "DULAL",
    lastName: "MAJUMDAR",
    address:
      "AJANTA HOUSING FLAT-1,BLOCK-2*CHANDMARI ROAD*PO-DANES SEKH LANE*HOWRAH  9",
    fullName: "DULAL MAJUMDAR",
    saID: "SPR4976",
  },
  {
    firstName: "LATE K C",
    lastName: "BARMAN",
    address: "ELETRICAL SUB DIVISON NO 3*PO-ITANAGAR*ARUNACHALPRADESH 791111",
    fullName: "LATE K C BARMAN",
    saID: "SPR4977",
  },
  {
    firstName: "PARTHA PRATIM",
    lastName: "ROY",
    address:
      "FLAT NO-605 SUKHSHANTI C H S LTD*SEC-8 PLOT-8;AIROLI*NAVI MUMBAI 400708",
    fullName: "PARTHA PRATIM ROY",
    saID: "SPR4978",
  },
  {
    firstName: "RAMESH",
    lastName: "PRASAD",
    address: "VILL/PO-BAHURUPIA*VIA TURKOLIA*EAST CHAMPARAN 845437",
    fullName: "RAMESH PRASAD",
    saID: "SPR4979",
  },
  {
    firstName: "VIJOY",
    lastName: "KUMAR",
    address: "FAIR PRICE SHOP DEALER*RLY MARKET*PO-GARHARA*BEGUSARAI",
    fullName: "VIJOY KUMAR",
    saID: "SPR4980",
  },
  {
    firstName: "MURARI MOHAN",
    lastName: "BANDOPADHYAY",
    address:
      "KALARAMJOTE(GHOSHPARA)*PO-RANGAPANI*SILIGURI*DARJEELING-734434*PAN-AITPB2048L",
    fullName: "MURARI MOHAN BANDOPADHYAY",
    saID: "SPR4981",
  },
  {
    firstName: "KASHINATH",
    lastName: "MANDAL",
    address: "SATSANG 814116*DEOGHAR",
    fullName: "KASHINATH MANDAL",
    saID: "SPR4982",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "GANGOPADHYAY",
    address: "VILL-PACHIPARA*PO-FATEPUR*MURSHIDABAD-742132",
    fullName: "NIMAI CHANDRA GANGOPADHYAY",
    saID: "SPR4983",
  },
  {
    firstName: "KISHORE CHANDRA",
    lastName: "DASH",
    address: "QR NO-B/405,FERTILIZER TOWNSHIP*ROURKELA-769007*SUNDARGARH",
    fullName: "KISHORE CHANDRA DASH",
    saID: "SPR4984",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "DUTTA",
    address:
      "BIJPUR SATSANG VIHAR*HALISAHAR,TENTULTALA*24 PARGANAS NORTH-743134",
    fullName: "SANJIB KUMAR DUTTA",
    saID: "SPR4985",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "GHOSE",
    address: "REGIONAL HOSPITAL MUGMA,(E.C.LTD)*PO-MUGMA*DHANBAD-828204",
    fullName: "AJIT KUMAR GHOSE",
    saID: "SPR4986",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MAITI",
    address: "VILL-TALLA*PO-HERIA*PURBA MEDINIPUR-721430.",
    fullName: "RABINDRA NATH MAITI",
    saID: "SPR4987",
  },
  {
    firstName: "JUGAL KISHORE",
    lastName: "SARMA",
    address: '"JASODA KUTHI"*VILL&PO-BIHPUR*BHAGALPUR',
    fullName: "JUGAL KISHORE SARMA",
    saID: "SPR4988",
  },
  {
    firstName: "GODADHAR",
    lastName: "KAMAN",
    address: "VILL-NEW PURUPBARI*PO-GOHPUR*BISWANATH CHARIALI-784168.",
    fullName: "GODADHAR KAMAN",
    saID: "SPR4989",
  },
  {
    firstName: "BISWANATH",
    lastName: "CHOUDHURY",
    address: "VILL-CHAVOT*PO-ITAHAR*UTTAR DINAJPUR 733128*PAN NO.BBQPC9400E",
    fullName: "BISWANATH CHOUDHURY",
    saID: "SPR4990",
  },
  {
    firstName: "LATE HRUSHIKESH",
    lastName: "MISHRA",
    address: "AT&PO-DUNGURIPALI*DIST-SONEPUR-767023*ORISSA",
    fullName: "LATE HRUSHIKESH MISHRA",
    saID: "SPR4991",
  },
  {
    firstName: "BIJAY KETAN",
    lastName: "PATTANAYAK",
    address: "AT/PO-ALALBINDHA*VIA-DEHURDA*BALASORE 756036",
    fullName: "BIJAY KETAN PATTANAYAK",
    saID: "SPR4992",
  },
  {
    firstName: "SHASHI BHUSHAN",
    lastName: "DASH",
    address: "QR NO B/158,SECTOR-4*ROURKELA-769002*SUNDARGARH*(ODISHA)",
    fullName: "SHASHI BHUSHAN DASH",
    saID: "SPR4993",
  },
  {
    firstName: "ANUDYUTI",
    lastName: "MAITRA",
    address:
      "62/102,PRATAP NAGAR*HOUSING BOARD COLONY,SANGANER*JAIPUR-303906*(PAN-AAOPM9395J)",
    fullName: "ANUDYUTI MAITRA",
    saID: "SPR4994",
  },
  {
    firstName: "HRITBALLAV",
    lastName: "NARAYAN",
    address: "BELABAGAN*DURGABARI ROAD*DEOGHAR",
    fullName: "HRITBALLAV NARAYAN",
    saID: "SPR4995",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "SATSANG*PO-SATSANG*DEOGHAR.",
    fullName: "ARKADYUTI CHAKRAVARTY",
    saID: "SPR4996",
  },
  {
    firstName: "ANIRUDDHA",
    lastName: "CHAKRAVARTY",
    address: "*SATSANG 814116",
    fullName: "ANIRUDDHA CHAKRAVARTY",
    saID: "SPR4997",
  },
  {
    firstName: "ANINDYADYUTI",
    lastName: "CHAKRAVARTY",
    address: "*SATSANG 814116.",
    fullName: "ANINDYADYUTI CHAKRAVARTY",
    saID: "SPR4998",
  },
  {
    firstName: "AMITRAJIT",
    lastName: "CHAKRAVORTY",
    address: "*SATSANG 814 116*PAN NO-AFOPC5817N",
    fullName: "AMITRAJIT CHAKRAVORTY",
    saID: "SPR4999",
  },
  {
    firstName: "ANIMESH",
    lastName: "BISWAS",
    address: "C/O SATSANG VIHAR UDALGURI*PO-UDALGURI*DARRANG 784 509",
    fullName: "ANIMESH BISWAS",
    saID: "SPR5000",
  },
  {
    firstName: "SUKHENDU",
    lastName: "BHATTACHARJEE",
    address: "VILL-SANTOSHPUR*PO-ADIKASHIMPUR*24PARGANAS (NORTH)-743248",
    fullName: "SUKHENDU BHATTACHARJEE",
    saID: "SPR5001",
  },
  {
    firstName: "RITISH",
    lastName: "SARKAR",
    address: "*SATSANG 814116",
    fullName: "RITISH SARKAR",
    saID: "SPR5002",
  },
  {
    firstName: "ARCHIT KUMAR",
    lastName: "DHAR",
    address: "AT/PO-SATSANG 814116*DOST-DEOGHAR*PAN NO-AFVPD3624D",
    fullName: "ARCHIT KUMAR DHAR",
    saID: "SPR5003",
  },
  {
    firstName: "KISHOR CHANDRA B",
    lastName: "PANCHAL",
    address:
      "AMAR JYOTI CHS LTD*BLDG NO-19,FLAT-201*SHANTI NAGAR, SEC-10*MIRA RD-401107, THANE",
    fullName: "KISHOR CHANDRA B PANCHAL",
    saID: "SPR5004",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "KALINDI",
    address: "VILL-BHELATAND*PO-BHELATAND*DHANBAD 828103",
    fullName: "RAMESH CHANDRA KALINDI",
    saID: "SPR5005",
  },
  {
    firstName: "SUDHANSU SEKHAR",
    lastName: "ROUT",
    address: "AT-BAHARBIL*PO/VIA-DHUSURI*BHADRAK-756119*PAN-AEFPR1062N",
    fullName: "SUDHANSU SEKHAR ROUT",
    saID: "SPR5006",
  },
  {
    firstName: "KARTICK CHANDRA",
    lastName: "MAL",
    address: "GHATAL SATSANG VIHAR*PO-GHATAL*PASCHIM MEDINIPUR 721212",
    fullName: "KARTICK CHANDRA MAL",
    saID: "SPR5007",
  },
  {
    firstName: "SUMAN KALYAN",
    lastName: "DAS",
    address:
      '"GITANJALI"*AT/PO-KANCHANNAGAR*(MANTESWAR TALA)*BURDWAN 713102*PAN.AEUPD0944E',
    fullName: "SUMAN KALYAN DAS",
    saID: "SPR5008",
  },
  {
    firstName: "DEBASIS",
    lastName: "MANDAL",
    address: "SATSANG COMPUTER CENTER*SATSANG 814 116",
    fullName: "DEBASIS MANDAL",
    saID: "SPR5009",
  },
  {
    firstName: "RAJESHWAR PRASAD",
    lastName: "RAY",
    address: "JUNIOR ENGINEER,RENTAL FLAT NO-122*KANKARBAGH*PATNA-800020",
    fullName: "RAJESHWAR PRASAD RAY",
    saID: "SPR5010",
  },
  {
    firstName: "BRAHMA NARAYAN",
    lastName: "ADHIKARI",
    address: "AT-BANGKANDI*PO-JATESWAR*JALPAIGURI-735216",
    fullName: "BRAHMA NARAYAN ADHIKARI",
    saID: "SPR5011",
  },
  {
    firstName: "MAKHAN LAL",
    lastName: "PATRA",
    address: "SHAKTI STORE*BARIPADA*MAYURBHANJ 757001",
    fullName: "MAKHAN LAL PATRA",
    saID: "SPR5012",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "JANA",
    address: "EKBBARPUR*PO-JUJERSA*HOWRAH",
    fullName: "LATE SUNIL KUMAR JANA",
    saID: "SPR5013",
  },
  {
    firstName: "HAKIM",
    lastName: "RABHA",
    address: "VILL-RABHAGAON*PO-TELAM*DHEMAJI-787060.",
    fullName: "HAKIM RABHA",
    saID: "SPR5014",
  },
  {
    firstName: "AJITAVA",
    lastName: "CHAKRAVORTY",
    address: "SATSANG*DEOGHAR 814 116",
    fullName: "AJITAVA CHAKRAVORTY",
    saID: "SPR5015",
  },
  {
    firstName: "MAHENDRA",
    lastName: "THAKUR",
    address: "CHANDNI CHOWK*PO-RAMANUJGANJ*DT-SARGUJA*C.G. 497220",
    fullName: "MAHENDRA THAKUR",
    saID: "SPR5016",
  },
  {
    firstName: "FULLADYUTI",
    lastName: "HALDAR",
    address:
      "DEVELOPMENT OFFICER*ORIENTAL INSURENCE CO LTD*UDAY NAGAR,KANKER*BASTAR*M.P. 494334",
    fullName: "FULLADYUTI HALDAR",
    saID: "SPR5017",
  },
  {
    firstName: "NARENDRA NATH",
    lastName: "SARKAR",
    address:
      "RABINDRA PLACE*GHOLA KACHARI ROAD*PO-KAZIPARA,BARASAT*24PGS(N)*PAN-BSLPS9442Q",
    fullName: "NARENDRA NATH SARKAR",
    saID: "SPR5018",
  },
  {
    firstName: "RAJKISHORE",
    lastName: "MISHRA",
    address:
      "SWASTI NIWAS;AZAD NAGAR*B C ROAD*PO-J K PUR-765001*RAYAGADA*PAN-BUGPM3714E",
    fullName: "RAJKISHORE MISHRA",
    saID: "SPR5019",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "BHATTACHARJEE",
    address:
      "SREERAM APPARTMENT,FLAT-A/2*255/43 NSC BOSE ROAD*NAKTALA*KOLKATA-700047",
    fullName: "DILIP KUMAR BHATTACHARJEE",
    saID: "SPR5020",
  },
  {
    firstName: "DIPENDRA NATH",
    lastName: "RAY",
    address: "1 NO KALIGHAT ROAD*PO-GURIAHATI*COOCHBIHAR 736101",
    fullName: "DIPENDRA NATH RAY",
    saID: "SPR5021",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "RAY",
    address:
      "VILL-HATPARA*PO-NADIA SUNDULPUR*VIA-BAGEHIJAMSORE*NADIA-741122*PAN-CGPPR0174P",
    fullName: "TAPAN KUMAR RAY",
    saID: "SPR5022",
  },
  {
    firstName: "LAKSHMI PRASAD",
    lastName: "GUPTA",
    address: "C/O M/S AGRO GREEN VALLEY*PO-BISWANATH CHARIALI*SONITPUR 784176",
    fullName: "LAKSHMI PRASAD GUPTA",
    saID: "SPR5023",
  },
  {
    firstName: "HAREN CHANDRA",
    lastName: "DAS",
    address: "VILL/PO-DAKHALA*VIA-BIJAYNAGAR*KAMRUP-781122*ASSAM",
    fullName: "HAREN CHANDRA DAS",
    saID: "SPR5024",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "PAL",
    address: "TENTULTALA DIGANTIKA*PO-R GOPALPUR*KOLKATA-700 136",
    fullName: "JITENDRA NATH PAL",
    saID: "SPR5025",
  },
  {
    firstName: "SUDHIR YASHWANT",
    lastName: "VALAME",
    address:
      "3 CHANDRANIL;PLOT NO.22*NAUPADA*HIGHWAY CO-OP HSG SOCIETY*GAYAND PATHY*THANE 400602",
    fullName: "SUDHIR YASHWANT VALAME",
    saID: "SPR5026",
  },
  {
    firstName: "HARIHAR",
    lastName: "SAHOO",
    address: "AT-ADIA*PO-WADA*VIA-SORO*BALASORE 756045.",
    fullName: "HARIHAR SAHOO",
    saID: "SPR5027",
  },
  {
    firstName: "LATE BABLU KUMAR",
    lastName: "GHOSE",
    address: "SATSANG SWASTI SIBIR*PO-AMRITY*MALDA 732208",
    fullName: "LATE BABLU KUMAR GHOSE",
    saID: "SPR5028",
  },
  {
    firstName: "LAKHINDAR",
    lastName: "CHAUDHURY",
    address: "VILL&PO-KAMALPUR BITHAROULI*VIA-MOTIPUR *MUZAFFARPUR-843111",
    fullName: "LAKHINDAR CHAUDHURY",
    saID: "SPR5029",
  },
  {
    firstName: "AJAY KUMAR",
    lastName: "PANI",
    address: "C/O CHANDRA SEKHAR PANI*AT-DATATOTA*PO/DT-PURI-752001.",
    fullName: "AJAY KUMAR PANI",
    saID: "SPR5030",
  },
  {
    firstName: "HARIHAR",
    lastName: "PANDA",
    address:
      "AT-BHUBANESWAR SATSANG VIHAR*PO-VANIVIHAR*BHUBANESWAR-751007*KHURDA",
    fullName: "HARIHAR PANDA",
    saID: "SPR5031",
  },
  {
    firstName: "GOVINDRAO YASHWANT",
    lastName: "LONKAR",
    address: "B9/61,RUSTOM COLONY*CHINCHAWAD*PUNE-411033*M.S.*AAJPL0350P",
    fullName: "GOVINDRAO YASHWANT LONKAR",
    saID: "SPR5032",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "MUKHARJEE",
    address: "VILL&PO-SOUTH GORIA*SOUTH 24PARGANAS 743613",
    fullName: "TAPAN KUMAR MUKHARJEE",
    saID: "SPR5033",
  },
  {
    firstName: "BIGHNARAJ",
    lastName: "INDRAJIT",
    address: "QRS NO F-53/7,BURLA*PO-BURLA*SAMBALPUR 768017",
    fullName: "BIGHNARAJ INDRAJIT",
    saID: "SPR5034",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "SAHOO",
    address: "QRS NO-1D,STREET NO-36,SECTOR-9*BHILAI-490009*CHHATISHGARH",
    fullName: "PURNA CHANDRA SAHOO",
    saID: "SPR5035",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "LENKA",
    address: "KENDUAPADA BAZAR*BHADRAK-756142*DIST-BHADRAK",
    fullName: "GOPAL CHANDRA LENKA",
    saID: "SPR5036",
  },
  {
    firstName: "DIBYENDU",
    lastName: "CHOUDHURY",
    address:
      "1/S-CHOUDHURY GAS AGENCY*PO-GANKI*KHOWAI TRIPURA-799201*PAN ACNPC8058K",
    fullName: "DIBYENDU CHOUDHURY",
    saID: "SPR5037",
  },
  {
    firstName: "DEVENDRA NATH",
    lastName: "NAYAK",
    address: "LECTURER IN SANSKRIT*B.B.MAHAVIDYALAYA*CHANDIKHOL*JAJPUR-755044",
    fullName: "DEVENDRA NATH NAYAK",
    saID: "SPR5038",
  },
  {
    firstName: "DINABANDHU",
    lastName: "KHAN",
    address: "VILL-SIBGACHIA*PO-CHAKHAYATPUR*HOWRAH",
    fullName: "DINABANDHU KHAN",
    saID: "SPR5039",
  },
  {
    firstName: "BALARAM",
    lastName: "DAS",
    address: "KAMAL TEXTILES*HASANPUR ROAD*SAMASTIPUR-848205*PAN BLGPD9309B",
    fullName: "BALARAM DAS",
    saID: "SPR5040",
  },
  {
    firstName: "SHIVA SHANKAR",
    lastName: "PANDEY",
    address:
      "NEAR PAGALBABA ASHRAM*RAMJANKI MANDIR ROAD*PO-JASIDIH*DEOGHAR*PAN-AFEPP2216C",
    fullName: "SHIVA SHANKAR PANDEY",
    saID: "SPR5041",
  },
  {
    firstName: "GOLAK BIHARI",
    lastName: "ACHARYA",
    address: "AT-ANLADOBA WARD NO-10*PO-RAIRANGPUR*MAYURBHANJ 757043",
    fullName: "GOLAK BIHARI ACHARYA",
    saID: "SPR5042",
  },
  {
    firstName: "BRAHMADEO",
    lastName: "SINGH",
    address: "C/O MA GENERAL STORE*GANGJALA*SAHARSA PAN NO.GXCPS9316C",
    fullName: "BRAHMADEO SINGH",
    saID: "SPR5043",
  },
  {
    firstName: "ALOK",
    lastName: "MAHATO",
    address: "PO&VILL-MATYALA*BALAKDIH*PURULIA-723128",
    fullName: "ALOK MAHATO",
    saID: "SPR5044",
  },
  {
    firstName: "HIMANGSHU BHUSAN",
    lastName: "NATH",
    address: "WARD NO-26,LANE NO-28,H/NO-5*SILCHAR-7*DIST-CACHAR*ASSAM",
    fullName: "HIMANGSHU BHUSAN NATH",
    saID: "SPR5045",
  },
  {
    firstName: "PRASANTA",
    lastName: "NATTA",
    address: "1/119,MOTILAL COLONY*DUM DUM*CALCUTTA 28",
    fullName: "PRASANTA NATTA",
    saID: "SPR5046",
  },
  {
    firstName: "RAKESH",
    lastName: "PAUL",
    address:
      "SURESH BHAWAN,OPP.S.B.DEORAH COLLEGE,G.S.ROAD*ULUBARI*GUWAHATI 7*KAMRUP",
    fullName: "RAKESH PAUL",
    saID: "SPR5047",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "DAS",
    address:
      "AT-GANDHINAGAR*PO-PANIHATI*DIST-24 PGS (NORTH)-700114*PAN-ACMPD7537M",
    fullName: "KRISHNA CHANDRA DAS",
    saID: "SPR5048",
  },
  {
    firstName: "VIJAY KANT",
    lastName: "JHA",
    address: "AT-MADHUBANI COLONY*PO-MADHUBANI*PURNEA-854301",
    fullName: "VIJAY KANT JHA",
    saID: "SPR5049",
  },
  {
    firstName: "LALIT KUMAR",
    lastName: "POTHAL",
    address:
      "B3 TRISHNA ENCLAVE,PHASE-2*KIIT COLLEGE ROAD*PATIA*BHUBANESWAR-24*PAN-AEIPP0201J",
    fullName: "LALIT KUMAR POTHAL",
    saID: "SPR5050",
  },
  {
    firstName: "SATYENDRA KUMAR",
    lastName: "DUTTA",
    address: "B-III/22 BALUDIH*MUNIDIH*DHANBAD-826001*PAN-ACAPD7716N.",
    fullName: "SATYENDRA KUMAR DUTTA",
    saID: "SPR5051",
  },
  {
    firstName: "PATITAPABANA",
    lastName: "KHANDAGIRI",
    address:
      "ASST EX-ENGINEER*O/O D.D.A.JHARSUGUDA*DIST-JHARSUGUDA*ODISHA PAN.ADMPK2259E",
    fullName: "PATITAPABANA KHANDAGIRI",
    saID: "SPR5052",
  },
  {
    firstName: "MATHUR  CHANDRA",
    lastName: "KHAMRUI",
    address: "AT-SUNDARPUR*PO-MAROKHANA*HOOGHLY",
    fullName: "MATHUR  CHANDRA KHAMRUI",
    saID: "SPR5053",
  },
  {
    firstName: "LAHIRAM",
    lastName: "DAIMARY",
    address: "SATSANG KENDRA,MODERTOLI*PO-MODERTOLI*DIST-NAGAON*ASSAM",
    fullName: "LAHIRAM DAIMARY",
    saID: "SPR5054",
  },
  {
    firstName: "BISHNU PADA",
    lastName: "MAITI",
    address: "SANTINIRH-SOLAGERIA*LUTUNIA*PASCHIM MIDNAPUR*PIN 721166",
    fullName: "BISHNU PADA MAITI",
    saID: "SPR5055",
  },
  {
    firstName: "MANORANJAN",
    lastName: "BISWAL",
    address: "247 SARDARPARA ROAD*PO-BRAHMAPUR*KOLKATA-700 096.",
    fullName: "MANORANJAN BISWAL",
    saID: "SPR5056",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "PRADHAN",
    address: "AT- BEHERA CYCLE MART*MAIN ROAD*KEONJHAR 758001",
    fullName: "PURNA CHANDRA PRADHAN",
    saID: "SPR5057",
  },
  {
    firstName: "LATE LAXMIKANTA",
    lastName: "GHOSE",
    address: "VILL&PO-JAMALPUR*BURDWAN-713408",
    fullName: "LATE LAXMIKANTA GHOSE",
    saID: "SPR5058",
  },
  {
    firstName: "LATE BHAGIRATHI",
    lastName: "GUPTA",
    address: "AT/PO-RAMANUJGANJ*DIST-SURGUJA*C.G.-497220",
    fullName: "LATE BHAGIRATHI GUPTA",
    saID: "SPR5059",
  },
  {
    firstName: "SHYAMSUNDAR",
    lastName: "DAS",
    address: "M/S SHILPASREE*PO-SEHARA*BURDWAN-713423*PAN-AZSPD7309Q",
    fullName: "SHYAMSUNDAR DAS",
    saID: "SPR5060",
  },
  {
    firstName: "BABLU",
    lastName: "KHANRA",
    address: "VILL&PO-JUJERSHA*VIA-ANDULMOURI*HOWRAH-711302*PAN-EFVPK7753A",
    fullName: "BABLU KHANRA",
    saID: "SPR5061",
  },
  {
    firstName: "FULLA CHANDRA",
    lastName: "MISTRI",
    address: "BADLAPUR SATSANG VIHAR*L B S MARG*KULGAON-421503*THANE*M.S.",
    fullName: "FULLA CHANDRA MISTRI",
    saID: "SPR5062",
  },
  {
    firstName: "DASARATHI",
    lastName: "DALAI",
    address: "AT-NANDAPUR*PO-BAJPUR*DIST-KHURDA-752060*ODISHA",
    fullName: "DASARATHI DALAI",
    saID: "SPR5063",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "PAL",
    address: "SATITALA WARD NO-2*PO-ARAMBAGH*HOOGHLY-712601.",
    fullName: "ASHUTOSH PAL",
    saID: "SPR5064",
  },
  {
    firstName: "SITANGSHU",
    lastName: "MANDAL",
    address: "VILL&PO FATEPUR*NADIA-741249",
    fullName: "SITANGSHU MANDAL",
    saID: "SPR5065",
  },
  {
    firstName: "SUBIMAL",
    lastName: "SARKAR",
    address: "VILL-RAINAGAR (HOSPITAL MORE)*PO-HILI*DAKSHIN DINAJPUR-733126",
    fullName: "SUBIMAL SARKAR",
    saID: "SPR5066",
  },
  {
    firstName: "SRIKANTA",
    lastName: "NAYAK",
    address: "AT&PO-KORUA*KENDRAPARA 754134",
    fullName: "SRIKANTA NAYAK",
    saID: "SPR5067",
  },
  {
    firstName: "GOPAL CHARAN",
    lastName: "MOHANTY",
    address: "AT-KHARADI*PO-DANAGADI*DIST-JAJPUR 755026.",
    fullName: "GOPAL CHARAN MOHANTY",
    saID: "SPR5068",
  },
  {
    firstName: "LATE SIB NATH",
    lastName: "DEY",
    address: "VILL-CHARIPHARA ROAD NO-14*PO-SARBADHARMA MISSION*WEST TRIPURA",
    fullName: "LATE SIB NATH DEY",
    saID: "SPR5069",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "DUBEY",
    address:
      "RLY. QTRS NO-341/B*CENTRAL COLONY*PO-BHAKTINAGAR*SILIGURI-734007*DARJEELING",
    fullName: "SANJAY KUMAR DUBEY",
    saID: "SPR5070",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "BARIK",
    address: "K M C STAFF QRTS*22 NO SURYA SEN STREET*KOLKATA 12",
    fullName: "KARTIK CHANDRA BARIK",
    saID: "SPR5071",
  },
  {
    firstName: "ANUKUL CHANDRA",
    lastName: "BISWAS",
    address: "AT-HALISAHAR*PO-NAIHATI*24PARGANAS",
    fullName: "ANUKUL CHANDRA BISWAS",
    saID: "SPR5072",
  },
  {
    firstName: "DIPANKAR",
    lastName: "PAL",
    address:
      "C/O-BINAPANI PAL*VILL-BAGMORE*PO-KANCHRAPARA*24 PARGANAS NORTH-743145",
    fullName: "DIPANKAR PAL",
    saID: "SPR5073",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "SAHOO",
    address: "AT-GIRIMA*PO-KARANJIA*MAYURBHANJ 757037.",
    fullName: "KARTIK CHANDRA SAHOO",
    saID: "SPR5074",
  },
  {
    firstName: "GOUTAM",
    lastName: "BARMAN",
    address: "VILL/PO-BIRULIA*PS-NANDIGRAM*DIST-PURBA MEDINIPUR-721650.",
    fullName: "GOUTAM BARMAN",
    saID: "SPR5075",
  },
  {
    firstName: "SADHAN",
    lastName: "MISHRA",
    address: "VILL-UKHRA (ANANDA MORE)*PO-UKHRA*BURDWAN-713363*PAN-CXBTM9482J",
    fullName: "SADHAN MISHRA",
    saID: "SPR5076",
  },
  {
    firstName: "SAUBHAGYA KUMAR",
    lastName: "SAHOO",
    address: "AT-MAKHI*PO-MOHIUDDINPUR*DIST-JAGATSINGHPUR-754103*ODISHA",
    fullName: "SAUBHAGYA KUMAR SAHOO",
    saID: "SPR5077",
  },
  {
    firstName: "SATINATH",
    lastName: "MUKHERJEE",
    address: "JAMALPUR SATSANG KENDRA*PO&VILL-JAMALPUR*BURDWAN-713408",
    fullName: "SATINATH MUKHERJEE",
    saID: "SPR5078",
  },
  {
    firstName: "SHIVJEE",
    lastName: "THAKUR",
    address: "QR NO-E TYPE 52/2*PO-KIRIBURU TOWNSHIP*SINGHBHUM (WEST)-833222.",
    fullName: "SHIVJEE THAKUR",
    saID: "SPR5079",
  },
  {
    firstName: "LATE SUDHANGSHU",
    lastName: "CHAKRAVORTY",
    address: "14/2, D H ROAD*FLAT-C, IST FLOOR*KOLKATA-700008.",
    fullName: "LATE SUDHANGSHU CHAKRAVORTY",
    saID: "SPR5080",
  },
  {
    firstName: "KRISHNA PRASAD",
    lastName: "GUPTA",
    address:
      "157,MADHYAPARA,ROYNAGAR*PO-BANSDRONI*CALCUTTA 700 070*PAN NO.BKFPG3211D",
    fullName: "KRISHNA PRASAD GUPTA",
    saID: "SPR5081",
  },
  {
    firstName: "RANJAN",
    lastName: "GOALA",
    address:
      "2 NO BURAGUHAINKHAT*PO-BOKIAL*VIA-LETTEKUJAN*GOLAGHAT 785613*ASSAM",
    fullName: "RANJAN GOALA",
    saID: "SPR5082",
  },
  {
    firstName: "BIKASH KUMAR",
    lastName: "RAY",
    address: "VILL&PO-MALDANGA BAZAR*BURDWAN 713145",
    fullName: "BIKASH KUMAR RAY",
    saID: "SPR5083",
  },
  {
    firstName: "KUNTAL",
    lastName: "GOSWAMI",
    address:
      "C/O JAYANTA KUMAR GOSWAMI*PO-MORANHAT*DIBRUGARH 785670*PAN-AODPG2845J",
    fullName: "KUNTAL GOSWAMI",
    saID: "SPR5084",
  },
  {
    firstName: "ARABINDA",
    lastName: "MANDAL",
    address:
      "RATION DEALER*VILL&PO-ATURIA*VIA-KATIAHAT*NORTH 24PGS 743427*PAN-BGSPM7434F",
    fullName: "ARABINDA MANDAL",
    saID: "SPR5085",
  },
  {
    firstName: "BHASKAR CHANDRA",
    lastName: "LENKA",
    address:
      "PLOT NO-2; 19 TH STREET*SRI SHANKAR NAGAR*PAMMAL*CHENNAI 600075*AKRPB0259K",
    fullName: "BHASKAR CHANDRA LENKA",
    saID: "SPR5086",
  },
  {
    firstName: "CHANDRA KANTA",
    lastName: "MANDAL",
    address:
      "INDIAN BANK,USUPUR BRANCH*MARIAPPA NAGAR,CHIDAMBARAM*TAMILNADU*PAN-AJAPM5880B",
    fullName: "CHANDRA KANTA MANDAL",
    saID: "SPR5087",
  },
  {
    firstName: "PRADIP",
    lastName: "CHAKRABORTY",
    address:
      "DIR.OF ECONOMICS & STATISTICS*ITANAGAR-791113*ARUNACHAL PRADESH*PAN-ANKPC7980Q",
    fullName: "PRADIP CHAKRABORTY",
    saID: "SPR5088",
  },
  {
    firstName: "SUREN CHANDRA",
    lastName: "HAJARIKA",
    address: "PO-DWARKUCHI*VILL-PATHALIKUCHI*BAKSHA 781376*PAN.NO.ADXPH5874P",
    fullName: "SUREN CHANDRA HAJARIKA",
    saID: "SPR5089",
  },
  {
    firstName: "KAPILENDRA",
    lastName: "SWAIN",
    address: "AT-NABINBAG*PO/DIST-KHURDA-752055",
    fullName: "KAPILENDRA SWAIN",
    saID: "SPR5090",
  },
  {
    firstName: "BIBHUPRASAD",
    lastName: "ACHARYA",
    address: "AT/PO-JIGNIPUR*CUTTACK 754284.*PAN-ADTPA3622J",
    fullName: "BIBHUPRASAD ACHARYA",
    saID: "SPR5091",
  },
  {
    firstName: "PARIKSHITA",
    lastName: "RATH",
    address:
      "C/O-RAMA CHANDRA RATH*AT-BHUBANPUR*PO-GARHMADHUPUR*JAJPUR 755 041.",
    fullName: "PARIKSHITA RATH",
    saID: "SPR5092",
  },
  {
    firstName: "DEBASISH",
    lastName: "GHOSH",
    address: "VILL&PO-GOCHARAN*24 PARGANAS (SOUTH)-743391",
    fullName: "DEBASISH GHOSH",
    saID: "SPR5093",
  },
  {
    firstName: "DEBENDRA",
    lastName: "DEKA",
    address: "SATSANG VIHAR,TANGLA*PO-PURANI TANGLA*UDALGURI PIN 784528 *ASSAM",
    fullName: "DEBENDRA DEKA",
    saID: "SPR5094",
  },
  {
    firstName: "SUBHASH",
    lastName: "CHAKRABARTY",
    address:
      "D/14,KALITALA*NEAR JUBASANGHA PLAY GR.*PO-LASKARPUR*KOLKATA-700 153",
    fullName: "SUBHASH CHAKRABARTY",
    saID: "SPR5095",
  },
  {
    firstName: "NARENDRA KUMAR",
    lastName: "MISHRA",
    address: "AT-PANISIALI*PO-S KALAPATHAR*SONEPUR*ORISSA",
    fullName: "NARENDRA KUMAR MISHRA",
    saID: "SPR5096",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "DUTTA",
    address:
      "SUBHA POLLY (KARBALA MORE)*VIVEKANANDA ROAD*PO/DIST-HOOGHLY-712103",
    fullName: "RAM CHANDRA DUTTA",
    saID: "SPR5097",
  },
  {
    firstName: "ABHIMANYU",
    lastName: "MAHAPATRA",
    address: "AT-MALLIKADEIPUR*PO-NAYAKANIDIHI*BHADRAK 756164",
    fullName: "ABHIMANYU MAHAPATRA",
    saID: "SPR5098",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "DAS",
    address: "VILL+PO-KRISHNARAMPUR*HOOGHLY 712705",
    fullName: "SUNIL KUMAR DAS",
    saID: "SPR5099",
  },
  {
    firstName: "BIKASH CHANDRA",
    lastName: "SARKAR",
    address: "*SATSANG 814116",
    fullName: "BIKASH CHANDRA SARKAR",
    saID: "SPR5100",
  },
  {
    firstName: "TARANI",
    lastName: "PANDEY",
    address: "VILL/PO-MAHESHPUR*MALDA 732138",
    fullName: "TARANI PANDEY",
    saID: "SPR5101",
  },
  {
    firstName: "SUBAS CHANDRA",
    lastName: "DASH",
    address:
      "C/O SIVA PRASAD ACHARYA*BHABANI SADAN*AT-SANTINAGAR*PO-BUDHARAJA*SAMBALPUR 768004",
    fullName: "SUBAS CHANDRA DASH",
    saID: "SPR5102",
  },
  {
    firstName: "APURBA KUMAR",
    lastName: "PAL",
    address: "C/O ARABINDA BH PAL*PO-HAFLONG*N.C.HILLS 788819",
    fullName: "APURBA KUMAR PAL",
    saID: "SPR5103",
  },
  {
    firstName: "RABI RAM",
    lastName: "BRAHMA",
    address: "VILL-BORAGARI*PO-DOTMA*DHUBRI 783347",
    fullName: "RABI RAM BRAHMA",
    saID: "SPR5104",
  },
  {
    firstName: "RAMLAKHAN PRASAD",
    lastName: "JADAB",
    address: "AT-TIKKAR*PO-TUNGI*GAYA*pan ABDPY1871D",
    fullName: "RAMLAKHAN PRASAD JADAB",
    saID: "SPR5105",
  },
  {
    firstName: "BANARASI PRASAD",
    lastName: "YADAV",
    address: "BHARATIYA NAGAR*WARD NO-26*BATRAHA*SAHARSA*PAN.AAIPY3083H",
    fullName: "BANARASI PRASAD YADAV",
    saID: "SPR5106",
  },
  {
    firstName: "CHANCHAL",
    lastName: "DAS",
    address: "C/O PRAKRITI KUMAR DAS*AT&PO-TAKIPUR *24PARGANAS (NORTH)-743429",
    fullName: "CHANCHAL DAS",
    saID: "SPR5107",
  },
  {
    firstName: "KIRTAN CHANDRA",
    lastName: "RABHA",
    address: "C/O SATSANG VIHAR*PO-TURA*GAROHILLS-794001",
    fullName: "KIRTAN CHANDRA RABHA",
    saID: "SPR5108",
  },
  {
    firstName: "SHOVAN KUMAR",
    lastName: "LAHIRI",
    address: "81  NO.BARENDRAGALI*PO-HALISAHAR*24PARGANAS NORTH",
    fullName: "SHOVAN KUMAR LAHIRI",
    saID: "SPR5109",
  },
  {
    firstName: "SUBRATA",
    lastName: "GHOSAL",
    address:
      "C/O-SUSHAMA GHOSAL*C-E-8,RABINDRANAGAR*PO-RABINDRANAGAR*KOLKATA-1*PAN-AVVPG4041K",
    fullName: "SUBRATA GHOSAL",
    saID: "SPR5110",
  },
  {
    firstName: "SATYA NARAYAN",
    lastName: "NANDI",
    address: "VILL+PO-AKUI*BANKURA 722201*PAN-BCLPN2466H",
    fullName: "SATYA NARAYAN NANDI",
    saID: "SPR5111",
  },
  {
    firstName: "NAREN",
    lastName: "KURMI",
    address: "DEOPANI TEA ESTATE*PO-TIPHUK*SIBSAGAR-785684*ASSAM",
    fullName: "NAREN KURMI",
    saID: "SPR5112",
  },
  {
    firstName: "DEBESH CHANDRA",
    lastName: "SARKAR",
    address: "VILL+PO-MARUGANJ*COOCHBIHAR 736156",
    fullName: "DEBESH CHANDRA SARKAR",
    saID: "SPR5113",
  },
  {
    firstName: "RAMESWAR",
    lastName: "SAHU",
    address: "LABOUR INSPECTOR*SATSANG VIHAR*MISSION ROAD*TINSUKIA 786125",
    fullName: "RAMESWAR SAHU",
    saID: "SPR5114",
  },
  {
    firstName: "LATE DWIJENDRA KUMAR",
    lastName: "PATI",
    address:
      "SECTOR-2/242,NILADRI VIHAR*CHANDRASEKHARPUR*BHUBANESWAR-16*KHURDA",
    fullName: "LATE DWIJENDRA KUMAR PATI",
    saID: "SPR5115",
  },
  {
    firstName: "LATE AKHIL",
    lastName: "ROYCHOUDHURI",
    address: "MOTIGANJ*BONGAON*24 PARGANAS (NORTH).",
    fullName: "LATE AKHIL ROYCHOUDHURI",
    saID: "SPR5116",
  },
  {
    firstName: "RAMAWADH",
    lastName: "PRASAD",
    address: "BHORE MAIN ROAD*AT&PO-BHORE*GOPALGANJ-841426",
    fullName: "RAMAWADH PRASAD",
    saID: "SPR5117",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "DAS",
    address: "AT-MUHULBARI*PO-BANGRIPOSI*MAYURBHANJ 757032",
    fullName: "CHITTARANJAN DAS",
    saID: "SPR5118",
  },
  {
    firstName: "PUJANIYA RANGA MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGA MAA ",
    saID: "SPR5119",
  },
  {
    firstName: "MILAN  RANI",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "MILAN  RANI MUKHERJEE",
    saID: "SPR5120",
  },
  {
    firstName: "KAMALESH",
    lastName: "BANIK",
    address: "",
    fullName: "KAMALESH BANIK",
    saID: "SPR5121",
  },
  {
    firstName: "PHANIBHUSAN",
    lastName: "PATHAK",
    address: "",
    fullName: "PHANIBHUSAN PATHAK",
    saID: "SPR5122",
  },
  {
    firstName: "PUJANIYA KALYANI",
    lastName: "MAITRA",
    address: "",
    fullName: "PUJANIYA KALYANI MAITRA",
    saID: "SPR5123",
  },
  {
    firstName: "BISHU",
    lastName: "BARUA",
    address: "",
    fullName: "BISHU BARUA",
    saID: "SPR5124",
  },
  {
    firstName: "ATUL",
    lastName: "GANGULY",
    address: "",
    fullName: "ATUL GANGULY",
    saID: "SPR5125",
  },
  {
    firstName: "DR.BINAYAK",
    lastName: "MAHAPATRA",
    address: "",
    fullName: "DR.BINAYAK MAHAPATRA",
    saID: "SPR5126",
  },
  {
    firstName: "JYOTIN",
    lastName: "BOSE",
    address: "",
    fullName: "JYOTIN BOSE",
    saID: "SPR5127",
  },
  {
    firstName: "SUREN",
    lastName: "MISHRA",
    address: "",
    fullName: "SUREN MISHRA",
    saID: "SPR5128",
  },
  {
    firstName: "REV ANURADHA",
    lastName: "DEVI",
    address: "",
    fullName: "REV ANURADHA DEVI",
    saID: "SPR5129",
  },
  {
    firstName: "GOKUN CHANDRA",
    lastName: "BISWAS",
    address: "",
    fullName: "GOKUN CHANDRA BISWAS",
    saID: "SPR5130",
  },
  {
    firstName: "SHYAMAPRASAD",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "SHYAMAPRASAD MUKHERJEE",
    saID: "SPR5131",
  },
  {
    firstName: "SHYAMA PRASAD",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "SHYAMA PRASAD MUKHERJEE",
    saID: "SPR5132",
  },
  {
    firstName: "RAHITOSH",
    lastName: "MUHURI",
    address: "",
    fullName: "RAHITOSH MUHURI",
    saID: "SPR5133",
  },
  {
    firstName: "MA",
    lastName: "",
    address: "",
    fullName: "MA ",
    saID: "SPR5134",
  },
  {
    firstName: "DADA",
    lastName: "",
    address: "",
    fullName: "DADA ",
    saID: "SPR5135",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "MISHRA",
    address: "",
    fullName: "RAMAKANTA MISHRA",
    saID: "SPR5136",
  },
  {
    firstName: "BRAJAGOPAL",
    lastName: "GURU",
    address: "",
    fullName: "BRAJAGOPAL GURU",
    saID: "SPR5137",
  },
  {
    firstName: "KIRTTAN BIHARI",
    lastName: "SWAIN",
    address: "M/S NABA PARIDHAN*BANK STREET*JAJPUR ROAD*DIST-JAJPUR 755019",
    fullName: "KIRTTAN BIHARI SWAIN",
    saID: "SPR5138",
  },
  {
    firstName: "GURUKINKAR",
    lastName: "PANDEY",
    address: "",
    fullName: "GURUKINKAR PANDEY",
    saID: "SPR5139",
  },
  {
    firstName: "ARKADWUTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ARKADWUTI CHAKRABARTY",
    saID: "SPR5140",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "PRADHAN",
    address:
      "DIGHA SATSANG MANDIR*PO-DIGHA*PURBA MEDINIPUR-721428*PAN-CYKPP7728N",
    fullName: "DILIP KUMAR PRADHAN",
    saID: "SPR5141",
  },
  {
    firstName: "SIBAPADA",
    lastName: "DAS",
    address: "",
    fullName: "SIBAPADA DAS",
    saID: "SPR5142",
  },
  {
    firstName: "BRAHMA PRASAD",
    lastName: "DAS",
    address: "",
    fullName: "BRAHMA PRASAD DAS",
    saID: "SPR5143",
  },
  {
    firstName: "NEHER",
    lastName: "DAS",
    address: "",
    fullName: "NEHER DAS",
    saID: "SPR5144",
  },
  {
    firstName: "NITYA GOPAL",
    lastName: "ACHERJEE",
    address: "",
    fullName: "NITYA GOPAL ACHERJEE",
    saID: "SPR5145",
  },
  {
    firstName: "MANTU",
    lastName: "SARKAR",
    address: "",
    fullName: "MANTU SARKAR",
    saID: "SPR5146",
  },
  {
    firstName: "DINANATH",
    lastName: "DEBSARMA",
    address: "",
    fullName: "DINANATH DEBSARMA",
    saID: "SPR5147",
  },
  {
    firstName: "AMITAVA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AMITAVA CHAKRABORTY",
    saID: "SPR5148",
  },
  {
    firstName: "RADHA MOHAN",
    lastName: "BANERJEE",
    address: "",
    fullName: "RADHA MOHAN BANERJEE",
    saID: "SPR5149",
  },
  {
    firstName: "REV ASHOK DA",
    lastName: "",
    address: "",
    fullName: "REV ASHOK DA ",
    saID: "SPR5150",
  },
  {
    firstName: "PUJANIYA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BARABOUDI ",
    saID: "SPR5151",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "HALDER",
    address: "",
    fullName: "ASIT BARAN HALDER",
    saID: "SPR5152",
  },
  {
    firstName: "LASIT BARAN",
    lastName: "HALDER",
    address: "",
    fullName: "LASIT BARAN HALDER",
    saID: "SPR5153",
  },
  {
    firstName: "BABAI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "BABAI CHAKRABORTY",
    saID: "SPR5154",
  },
  {
    firstName: "RATAN",
    lastName: "PRAMANIK",
    address: "",
    fullName: "RATAN PRAMANIK",
    saID: "SPR5155",
  },
  {
    firstName: "RATAN",
    lastName: "PRAMANIK",
    address: "",
    fullName: "RATAN PRAMANIK",
    saID: "SPR5156",
  },
  {
    firstName: "RATAN",
    lastName: "PRAMANIK",
    address: "",
    fullName: "RATAN PRAMANIK",
    saID: "SPR5157",
  },
  {
    firstName: "HAJARILAL",
    lastName: "BISWAS",
    address: "",
    fullName: "HAJARILAL BISWAS",
    saID: "SPR5158",
  },
  {
    firstName: "JATIN",
    lastName: "MOHANTY",
    address: "",
    fullName: "JATIN MOHANTY",
    saID: "SPR5159",
  },
  {
    firstName: "JARINDRA NATH",
    lastName: "MOHANTY",
    address: "",
    fullName: "JARINDRA NATH MOHANTY",
    saID: "SPR5160",
  },
  {
    firstName: "BABAIDA",
    lastName: "",
    address: "",
    fullName: "BABAIDA ",
    saID: "SPR5161",
  },
  {
    firstName: "BABAIDA",
    lastName: "",
    address: "",
    fullName: "BABAIDA ",
    saID: "SPR5162",
  },
  {
    firstName: "ALO",
    lastName: "JOARDAR",
    address: "",
    fullName: "ALO JOARDAR",
    saID: "SPR5163",
  },
  {
    firstName: "GURU KINKAR",
    lastName: "PANDEY",
    address: "",
    fullName: "GURU KINKAR PANDEY",
    saID: "SPR5164",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5165",
  },
  {
    firstName: "RANJIT",
    lastName: "MANDAL",
    address: "",
    fullName: "RANJIT MANDAL",
    saID: "SPR5166",
  },
  {
    firstName: "REV DIPTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV DIPTI CHAKRABARTY",
    saID: "SPR5167",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABARTY",
    saID: "SPR5168",
  },
  {
    firstName: "PUJANIA CHHOTOMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA CHHOTOMA ",
    saID: "SPR5169",
  },
  {
    firstName: "SANKARSHAN",
    lastName: "SAMAL",
    address: "",
    fullName: "SANKARSHAN SAMAL",
    saID: "SPR5170",
  },
  {
    firstName: "PUJANIYA BOROBOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BOROBOURANI ",
    saID: "SPR5171",
  },
  {
    firstName: "KANAN",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "KANAN CHOUDHURY",
    saID: "SPR5172",
  },
  {
    firstName: "PUJANIA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIA MA ",
    saID: "SPR5173",
  },
  {
    firstName: "C P",
    lastName: "DAS",
    address: "",
    fullName: "C P DAS",
    saID: "SPR5174",
  },
  {
    firstName: "SUBHAS",
    lastName: "SAHA",
    address: "",
    fullName: "SUBHAS SAHA",
    saID: "SPR5175",
  },
  {
    firstName: "ANUDYUTI",
    lastName: "MAITRA",
    address: "",
    fullName: "ANUDYUTI MAITRA",
    saID: "SPR5176",
  },
  {
    firstName: "AJIT BARAN",
    lastName: "HALDAR",
    address: "",
    fullName: "AJIT BARAN HALDAR",
    saID: "SPR5177",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "SOREN",
    address: "",
    fullName: "RAM CHANDRA SOREN",
    saID: "SPR5178",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "KAMPA",
    address:
      "PLOT NO-121,SECTOR-5*NILADRI VIHAR*CHANDRASEKHARPUR*BHUBANESWAR-21*KHURDA*ODISHA.",
    fullName: "GHANASHYAM KAMPA",
    saID: "SPR5179",
  },
  {
    firstName: "REV ASHIT",
    lastName: "MATRA",
    address: "",
    fullName: "REV ASHIT MATRA",
    saID: "SPR5180",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5181",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5182",
  },
  {
    firstName: "ASIT BARAN",
    lastName: "SAHA",
    address: "",
    fullName: "ASIT BARAN SAHA",
    saID: "SPR5183",
  },
  {
    firstName: "REV ARKADYUTI",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV ARKADYUTI CHAKRAVORTY",
    saID: "SPR5184",
  },
  {
    firstName: "SHYAMA PADA",
    lastName: "PANDEY",
    address: "",
    fullName: "SHYAMA PADA PANDEY",
    saID: "SPR5185",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "SEN",
    address: "",
    fullName: "MANINDRA NATH SEN",
    saID: "SPR5186",
  },
  {
    firstName: "REV KARTA",
    lastName: "",
    address: "",
    fullName: "REV KARTA ",
    saID: "SPR5187",
  },
  {
    firstName: "REV KARTAMA",
    lastName: "",
    address: "",
    fullName: "REV KARTAMA ",
    saID: "SPR5188",
  },
  {
    firstName: "AMARBIJOY",
    lastName: "SENSHARMA",
    address: "",
    fullName: "AMARBIJOY SENSHARMA",
    saID: "SPR5189",
  },
  {
    firstName: "BHUTNATH",
    lastName: "DUTTA",
    address: "VILL-ORIYA*PO-MURU*VIA-CHANDIL*SINGHBHUM-832401",
    fullName: "BHUTNATH DUTTA",
    saID: "SPR5190",
  },
  {
    firstName: "ARJAMA",
    lastName: "MAITRA",
    address: "",
    fullName: "ARJAMA MAITRA",
    saID: "SPR5191",
  },
  {
    firstName: "REV ANANDAMAYEE",
    lastName: "DEBI",
    address: "",
    fullName: "REV ANANDAMAYEE DEBI",
    saID: "SPR5192",
  },
  {
    firstName: "A/C CAR A T",
    lastName: "JOARDAR",
    address: "",
    fullName: "A/C CAR A T JOARDAR",
    saID: "SPR5193",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5194",
  },
  {
    firstName: "PRADIP",
    lastName: "RAY",
    address: "",
    fullName: "PRADIP RAY",
    saID: "SPR5195",
  },
  {
    firstName: "MURARI MOHAN",
    lastName: "DWA",
    address: "",
    fullName: "MURARI MOHAN DWA",
    saID: "SPR5196",
  },
  {
    firstName: "GOURMOHAN",
    lastName: "BANERJEE",
    address: "",
    fullName: "GOURMOHAN BANERJEE",
    saID: "SPR5197",
  },
  {
    firstName: "PUJANIYA SIPRA",
    lastName: "DEVI",
    address: "",
    fullName: "PUJANIYA SIPRA DEVI",
    saID: "SPR5198",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5199",
  },
  {
    firstName: "RABINDA",
    lastName: "",
    address: "",
    fullName: "RABINDA ",
    saID: "SPR5200",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5201",
  },
  {
    firstName: "KAJAL SANKAR",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "KAJAL SANKAR CHOUDHURY",
    saID: "SPR5202",
  },
  {
    firstName: "PHANI BHUSHAN",
    lastName: "PATHAK",
    address: "",
    fullName: "PHANI BHUSHAN PATHAK",
    saID: "SPR5203",
  },
  {
    firstName: "SUBIR KUMAR",
    lastName: "BISWAS",
    address: "KALYAN KUTIR*SATSANG",
    fullName: "SUBIR KUMAR BISWAS",
    saID: "SPR5204",
  },
  {
    firstName: "DIPTIS MARRIGE",
    lastName: "",
    address: "",
    fullName: "DIPTIS MARRIGE ",
    saID: "SPR5205",
  },
  {
    firstName: "SANKARSAN",
    lastName: "SHYAMAL",
    address: "",
    fullName: "SANKARSAN SHYAMAL",
    saID: "SPR5206",
  },
  {
    firstName: "CAR A/C A T JOARDAR",
    lastName: "",
    address: "",
    fullName: "CAR A/C A T JOARDAR ",
    saID: "SPR5207",
  },
  {
    firstName: "BIDYA MA",
    lastName: "",
    address: "",
    fullName: "BIDYA MA ",
    saID: "SPR5208",
  },
  {
    firstName: "MURARI MOHAN",
    lastName: "DAW",
    address: "",
    fullName: "MURARI MOHAN DAW",
    saID: "SPR5209",
  },
  {
    firstName: "PUJYANIYARANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJYANIYARANGAMA ",
    saID: "SPR5210",
  },
  {
    firstName: "BIPIN CHANDRA",
    lastName: "MOHANTY",
    address: "RAJIB NAGAR, 4TH LINE*BACK AT GOVT ITI*BERHAMPUR-10*GANJAM.",
    fullName: "BIPIN CHANDRA MOHANTY",
    saID: "SPR5211",
  },
  {
    firstName: "PUJANIYA MEJOBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MEJOBOUDI ",
    saID: "SPR5212",
  },
  {
    firstName: "PUJANIYA SEJOBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA SEJOBOUDI ",
    saID: "SPR5213",
  },
  {
    firstName: "RAMA SANKAR",
    lastName: "TRIVEDI",
    address: "",
    fullName: "RAMA SANKAR TRIVEDI",
    saID: "SPR5214",
  },
  {
    firstName: "SOMENDRA PADA",
    lastName: "SINGHROY",
    address: "",
    fullName: "SOMENDRA PADA SINGHROY",
    saID: "SPR5215",
  },
  {
    firstName: "SONENDRA PADA",
    lastName: "SINGHROY",
    address: "",
    fullName: "SONENDRA PADA SINGHROY",
    saID: "SPR5216",
  },
  {
    firstName: "HARIDAS",
    lastName: "BHATTACHARJEE",
    address: "SATSANG NAGAR*SATSANG",
    fullName: "HARIDAS BHATTACHARJEE",
    saID: "SPR5217",
  },
  {
    firstName: "NARESH CHANDRA",
    lastName: "ADHIKARY",
    address: "",
    fullName: "NARESH CHANDRA ADHIKARY",
    saID: "SPR5218",
  },
  {
    firstName: "SHEO SANKAR PRASAD",
    lastName: "SINHA",
    address:
      "B E O OFFICE RAMANUJGANJ*PO-RAMANUJGANJ*DIST-BALRAMPUR*C.G.-497220",
    fullName: "SHEO SANKAR PRASAD SINHA",
    saID: "SPR5219",
  },
  {
    firstName: "FANI BHUSAN",
    lastName: "PATHAK",
    address: "",
    fullName: "FANI BHUSAN PATHAK",
    saID: "SPR5220",
  },
  {
    firstName: "JITENDRA CHANDRA",
    lastName: "SARMA",
    address: "",
    fullName: "JITENDRA CHANDRA SARMA",
    saID: "SPR5221",
  },
  {
    firstName: "WIFE OF MAHENDRA",
    lastName: "HALDER",
    address: "",
    fullName: "WIFE OF MAHENDRA HALDER",
    saID: "SPR5222",
  },
  {
    firstName: "BHARAT BHUSAN",
    lastName: "DASH",
    address:
      "AT/PO-PANCHAPADA(MATHASAHI)*VIA-CHANDBALI*DIST-BHADRAK-756 133*PAN-AJDPD0720E",
    fullName: "BHARAT BHUSAN DASH",
    saID: "SPR5223",
  },
  {
    firstName: "NIHAR",
    lastName: "BHATTACHARJEE",
    address: "SATSANG",
    fullName: "NIHAR BHATTACHARJEE",
    saID: "SPR5224",
  },
  {
    firstName: "BHUBAN CHANDRA",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "BHUBAN CHANDRA DAS",
    saID: "SPR5225",
  },
  {
    firstName: "AMIT",
    lastName: "HALDAR",
    address: "SATSANG",
    fullName: "AMIT HALDAR",
    saID: "SPR5226",
  },
  {
    firstName: "RADHA RANI",
    lastName: "HALDAR",
    address: "SATSANG",
    fullName: "RADHA RANI HALDAR",
    saID: "SPR5227",
  },
  {
    firstName: "ARYAMA",
    lastName: "SANYAL",
    address: "CALCUTTA",
    fullName: "ARYAMA SANYAL",
    saID: "SPR5228",
  },
  {
    firstName: "ANUPAM",
    lastName: "PATRA",
    address: "MIDNAPUR",
    fullName: "ANUPAM PATRA",
    saID: "SPR5229",
  },
  {
    firstName: "ASIT",
    lastName: "SANYAL",
    address: "SATSANG",
    fullName: "ASIT SANYAL",
    saID: "SPR5230",
  },
  {
    firstName: "SANAT",
    lastName: "SARKAR",
    address: "SATSANG",
    fullName: "SANAT SARKAR",
    saID: "SPR5231",
  },
  {
    firstName: "SANKAR",
    lastName: "SENSARMA",
    address: "SATSANG",
    fullName: "SANKAR SENSARMA",
    saID: "SPR5232",
  },
  {
    firstName: "INDUMADHAB",
    lastName: "SIL",
    address: "SATSANG",
    fullName: "INDUMADHAB SIL",
    saID: "SPR5233",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5234",
  },
  {
    firstName: "ANANTAMA",
    lastName: "",
    address: "SATSANG",
    fullName: "ANANTAMA ",
    saID: "SPR5235",
  },
  {
    firstName: "DULALIMA",
    lastName: "",
    address: "SATSANG",
    fullName: "DULALIMA ",
    saID: "SPR5236",
  },
  {
    firstName: "JEEP BRL",
    lastName: "",
    address: "",
    fullName: "JEEP BRL ",
    saID: "SPR5237",
  },
  {
    firstName: "PUJANIYA ANNAPURNAMA",
    lastName: "",
    address: "SATSANG",
    fullName: "PUJANIYA ANNAPURNAMA ",
    saID: "SPR5238",
  },
  {
    firstName: "PUJANIYA PISIMA",
    lastName: "",
    address: "SATSANG",
    fullName: "PUJANIYA PISIMA ",
    saID: "SPR5239",
  },
  {
    firstName: "REV CHHOTDA",
    lastName: "",
    address: "SATSANG",
    fullName: "REV CHHOTDA ",
    saID: "SPR5240",
  },
  {
    firstName: "REV CHUNUDA",
    lastName: "",
    address: "SATSANG",
    fullName: "REV CHUNUDA ",
    saID: "SPR5241",
  },
  {
    firstName: "REV KAJALDA",
    lastName: "",
    address: "SATSANG",
    fullName: "REV KAJALDA ",
    saID: "SPR5242",
  },
  {
    firstName: "REV NABOUDI",
    lastName: "",
    address: "SATSANG",
    fullName: "REV NABOUDI ",
    saID: "SPR5243",
  },
  {
    firstName: "SAILAMA",
    lastName: "",
    address: "SATSANG",
    fullName: "SAILAMA ",
    saID: "SPR5244",
  },
  {
    firstName: "SUNDARIMA",
    lastName: "",
    address: "SATSANG",
    fullName: "SUNDARIMA ",
    saID: "SPR5245",
  },
  {
    firstName: "WIFE OF SARATDA",
    lastName: "",
    address: "SATSANG",
    fullName: "WIFE OF SARATDA ",
    saID: "SPR5246",
  },
  {
    firstName: "REV TUPAI",
    lastName: "BABU",
    address: "SATSANG",
    fullName: "REV TUPAI BABU",
    saID: "SPR5247",
  },
  {
    firstName: "PRASANNA",
    lastName: "BAGCHI",
    address: "SATSANG",
    fullName: "PRASANNA BAGCHI",
    saID: "SPR5248",
  },
  {
    firstName: "NIKHIL",
    lastName: "BANERJEE",
    address: "",
    fullName: "NIKHIL BANERJEE",
    saID: "SPR5249",
  },
  {
    firstName: "ANIMESH",
    lastName: "BANIK",
    address: "",
    fullName: "ANIMESH BANIK",
    saID: "SPR5250",
  },
  {
    firstName: "S M",
    lastName: "BARMAN",
    address: "",
    fullName: "S M BARMAN",
    saID: "SPR5251",
  },
  {
    firstName: "REV CHHOTO BAURANI",
    lastName: "",
    address: "SATSANG",
    fullName: "REV CHHOTO BAURANI ",
    saID: "SPR5252",
  },
  {
    firstName: "BENU",
    lastName: "BEHERA",
    address: "",
    fullName: "BENU BEHERA",
    saID: "SPR5253",
  },
  {
    firstName: "RAJLAKSHMI",
    lastName: "BEHERA",
    address: "",
    fullName: "RAJLAKSHMI BEHERA",
    saID: "SPR5254",
  },
  {
    firstName: "RAMNATH",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "RAMNATH BHATTACHARJEE",
    saID: "SPR5255",
  },
  {
    firstName: "LATE KAMAKSHYA",
    lastName: "BHATTACHARJEE",
    address: "SATSANG",
    fullName: "LATE KAMAKSHYA BHATTACHARJEE",
    saID: "SPR5256",
  },
  {
    firstName: "MOTILAL",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "MOTILAL BHATTACHARJEE",
    saID: "SPR5257",
  },
  {
    firstName: "WIFE OF SARANAN",
    lastName: "BHATTACHARJEE",
    address: "MIDNAPUR",
    fullName: "WIFE OF SARANAN BHATTACHARJEE",
    saID: "SPR5258",
  },
  {
    firstName: "R K",
    lastName: "BHUIYAN",
    address: "",
    fullName: "R K BHUIYAN",
    saID: "SPR5259",
  },
  {
    firstName: "BABUL",
    lastName: "BISWAS",
    address: "",
    fullName: "BABUL BISWAS",
    saID: "SPR5260",
  },
  {
    firstName: "BIPIN CHANDRA",
    lastName: "BORA",
    address: "",
    fullName: "BIPIN CHANDRA BORA",
    saID: "SPR5261",
  },
  {
    firstName: "KANAILAL",
    lastName: "BOSE",
    address: "",
    fullName: "KANAILAL BOSE",
    saID: "SPR5262",
  },
  {
    firstName: "ABINASH",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABINASH CHAKRABORTY",
    saID: "SPR5263",
  },
  {
    firstName: "REBATI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REBATI CHAKRABORTY",
    saID: "SPR5264",
  },
  {
    firstName: "SRIPADA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "SRIPADA CHAKRABARTY",
    saID: "SPR5265",
  },
  {
    firstName: "BHISMADEV",
    lastName: "CHATTERJEE",
    address: "BARDWAN",
    fullName: "BHISMADEV CHATTERJEE",
    saID: "SPR5266",
  },
  {
    firstName: "PARITOSH",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "PARITOSH CHOUDHURY",
    saID: "SPR5267",
  },
  {
    firstName: "KRISHNA GOPAL",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "KRISHNA GOPAL CHOUDHURY",
    saID: "SPR5268",
  },
  {
    firstName: "A T",
    lastName: "DAS",
    address: "",
    fullName: "A T DAS",
    saID: "SPR5269",
  },
  {
    firstName: "AMIT",
    lastName: "DAS",
    address: "",
    fullName: "AMIT DAS",
    saID: "SPR5270",
  },
  {
    firstName: "ANIL BARAN",
    lastName: "DAS",
    address: "",
    fullName: "ANIL BARAN DAS",
    saID: "SPR5271",
  },
  {
    firstName: "ASIT",
    lastName: "DAS",
    address: "",
    fullName: "ASIT DAS",
    saID: "SPR5272",
  },
  {
    firstName: "BHUPEN",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "BHUPEN DAS",
    saID: "SPR5273",
  },
  {
    firstName: "BINAPANI",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "BINAPANI DAS",
    saID: "SPR5274",
  },
  {
    firstName: "SASHIBHUSAN",
    lastName: "DAS",
    address: "",
    fullName: "SASHIBHUSAN DAS",
    saID: "SPR5275",
  },
  {
    firstName: "SHIBDAYAL",
    lastName: "DAS",
    address: "",
    fullName: "SHIBDAYAL DAS",
    saID: "SPR5276",
  },
  {
    firstName: "SWADESH RANJAN",
    lastName: "DAS",
    address: "",
    fullName: "SWADESH RANJAN DAS",
    saID: "SPR5277",
  },
  {
    firstName: "GADADHAR",
    lastName: "DAW",
    address: "",
    fullName: "GADADHAR DAW",
    saID: "SPR5278",
  },
  {
    firstName: "LAKSHMI KANTA",
    lastName: "DAW",
    address: "SATSANG",
    fullName: "LAKSHMI KANTA DAW",
    saID: "SPR5279",
  },
  {
    firstName: "LALIT MOHAN",
    lastName: "DEB",
    address: "",
    fullName: "LALIT MOHAN DEB",
    saID: "SPR5280",
  },
  {
    firstName: "TAPAN",
    lastName: "DEB",
    address: "",
    fullName: "TAPAN DEB",
    saID: "SPR5281",
  },
  {
    firstName: "P",
    lastName: "DEB",
    address: "",
    fullName: "P DEB",
    saID: "SPR5282",
  },
  {
    firstName: "BIMAN",
    lastName: "DEY",
    address: "",
    fullName: "BIMAN DEY",
    saID: "SPR5283",
  },
  {
    firstName: "KUMUD KUMAR",
    lastName: "DEY",
    address: "",
    fullName: "KUMUD KUMAR DEY",
    saID: "SPR5284",
  },
  {
    firstName: "MANGAL CHANDRA",
    lastName: "DAS",
    address: "SATSANG*DEOGHAR*JHARKHAND 814 116",
    fullName: "MANGAL CHANDRA DAS",
    saID: "SPR5285",
  },
  {
    firstName: "PRAVASH",
    lastName: "DEY",
    address: "",
    fullName: "PRAVASH DEY",
    saID: "SPR5286",
  },
  {
    firstName: "SOMNATH",
    lastName: "DEY",
    address: "",
    fullName: "SOMNATH DEY",
    saID: "SPR5287",
  },
  {
    firstName: "BARUNA",
    lastName: "DUTTA",
    address: "",
    fullName: "BARUNA DUTTA",
    saID: "SPR5288",
  },
  {
    firstName: "BHANU MADHAB",
    lastName: "DUTTA",
    address: "",
    fullName: "BHANU MADHAB DUTTA",
    saID: "SPR5289",
  },
  {
    firstName: "KALIPADA",
    lastName: "GHOSH",
    address: "",
    fullName: "KALIPADA GHOSH",
    saID: "SPR5290",
  },
  {
    firstName: "MURARI",
    lastName: "GHOSH",
    address: "",
    fullName: "MURARI GHOSH",
    saID: "SPR5291",
  },
  {
    firstName: "NISHIKANTA",
    lastName: "GHOSH",
    address: "",
    fullName: "NISHIKANTA GHOSH",
    saID: "SPR5292",
  },
  {
    firstName: "SADAGOPAL",
    lastName: "GHOSH",
    address: "",
    fullName: "SADAGOPAL GHOSH",
    saID: "SPR5293",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "GIRI",
    address: "",
    fullName: "DHARANIDHAR GIRI",
    saID: "SPR5294",
  },
  {
    firstName: "SACHIN",
    lastName: "GUHA",
    address: "",
    fullName: "SACHIN GUHA",
    saID: "SPR5295",
  },
  {
    firstName: "SADHAN",
    lastName: "HALDAR",
    address: "",
    fullName: "SADHAN HALDAR",
    saID: "SPR5296",
  },
  {
    firstName: "LABODHAR",
    lastName: "KALITA",
    address: "",
    fullName: "LABODHAR KALITA",
    saID: "SPR5297",
  },
  {
    firstName: "SARAJINI",
    lastName: "KAR",
    address: "",
    fullName: "SARAJINI KAR",
    saID: "SPR5298",
  },
  {
    firstName: "SUNIL",
    lastName: "KAR",
    address: "",
    fullName: "SUNIL KAR",
    saID: "SPR5299",
  },
  {
    firstName: "NIRMAL",
    lastName: "KARMAKAR",
    address: "",
    fullName: "NIRMAL KARMAKAR",
    saID: "SPR5300",
  },
  {
    firstName: "ANADI MOHAN",
    lastName: "MAITY",
    address: "",
    fullName: "ANADI MOHAN MAITY",
    saID: "SPR5301",
  },
  {
    firstName: "KATU",
    lastName: "MAJUMDAR",
    address: "",
    fullName: "KATU MAJUMDAR",
    saID: "SPR5302",
  },
  {
    firstName: "BASANTA",
    lastName: "MALLICK",
    address: "",
    fullName: "BASANTA MALLICK",
    saID: "SPR5303",
  },
  {
    firstName: "DIPDOLAN",
    lastName: "MONDAL",
    address: "",
    fullName: "DIPDOLAN MONDAL",
    saID: "SPR5304",
  },
  {
    firstName: "SAMIR",
    lastName: "MONDAL",
    address: "",
    fullName: "SAMIR MONDAL",
    saID: "SPR5305",
  },
  {
    firstName: "B N",
    lastName: "MANNA",
    address: "",
    fullName: "B N MANNA",
    saID: "SPR5306",
  },
  {
    firstName: "ARJUN",
    lastName: "MISHRA",
    address: "",
    fullName: "ARJUN MISHRA",
    saID: "SPR5307",
  },
  {
    firstName: "A K",
    lastName: "MOHANTY",
    address: "",
    fullName: "A K MOHANTY",
    saID: "SPR5308",
  },
  {
    firstName: "SARADA PRASANNA",
    lastName: "MOHANTY",
    address: "",
    fullName: "SARADA PRASANNA MOHANTY",
    saID: "SPR5309",
  },
  {
    firstName: "AMIYA",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "AMIYA MUKHERJEE",
    saID: "SPR5310",
  },
  {
    firstName: "MADHU SUDAN",
    lastName: "NAYAK",
    address: "",
    fullName: "MADHU SUDAN NAYAK",
    saID: "SPR5311",
  },
  {
    firstName: "KSHIROD",
    lastName: "NAYAK",
    address: "",
    fullName: "KSHIROD NAYAK",
    saID: "SPR5312",
  },
  {
    firstName: "KEKA",
    lastName: "PAL",
    address: "",
    fullName: "KEKA PAL",
    saID: "SPR5313",
  },
  {
    firstName: "MANASA CHANDRA",
    lastName: "PAL",
    address: "",
    fullName: "MANASA CHANDRA PAL",
    saID: "SPR5314",
  },
  {
    firstName: "RAJAT",
    lastName: "PAL",
    address: "",
    fullName: "RAJAT PAL",
    saID: "SPR5315",
  },
  {
    firstName: "SHIBANI",
    lastName: "PAL",
    address: "",
    fullName: "SHIBANI PAL",
    saID: "SPR5316",
  },
  {
    firstName: "SUSHIL KUMAR",
    lastName: "PAL",
    address: "",
    fullName: "SUSHIL KUMAR PAL",
    saID: "SPR5317",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "PATI",
    address: "",
    fullName: "SANTOSH KUMAR PATI",
    saID: "SPR5318",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "DEY",
    address: "C/O-KRISHNA PADA ROY*VILL-BASTA*PO-ARANGHATA*NADIA 741501",
    fullName: "JAYANTA KUMAR DEY",
    saID: "SPR5319",
  },
  {
    firstName: "NARESH",
    lastName: "RAHA",
    address: "",
    fullName: "NARESH RAHA",
    saID: "SPR5320",
  },
  {
    firstName: "K P",
    lastName: "RANA",
    address: "",
    fullName: "K P RANA",
    saID: "SPR5321",
  },
  {
    firstName: "M M",
    lastName: "ROUT",
    address: "",
    fullName: "M M ROUT",
    saID: "SPR5322",
  },
  {
    firstName: "BIMAL CHANDRA",
    lastName: "ROY",
    address: "",
    fullName: "BIMAL CHANDRA ROY",
    saID: "SPR5323",
  },
  {
    firstName: "DWIP KUMAR",
    lastName: "ROY",
    address: "",
    fullName: "DWIP KUMAR ROY",
    saID: "SPR5324",
  },
  {
    firstName: "JYOTISH",
    lastName: "ROY",
    address: "",
    fullName: "JYOTISH ROY",
    saID: "SPR5325",
  },
  {
    firstName: "KABI NANDAN",
    lastName: "ROY",
    address: "",
    fullName: "KABI NANDAN ROY",
    saID: "SPR5326",
  },
  {
    firstName: "ANIL BARAN",
    lastName: "SAHA",
    address: "",
    fullName: "ANIL BARAN SAHA",
    saID: "SPR5327",
  },
  {
    firstName: "NAREN CHANDRA",
    lastName: "SAHA",
    address: "",
    fullName: "NAREN CHANDRA SAHA",
    saID: "SPR5328",
  },
  {
    firstName: "MADHU SUDAN",
    lastName: "SAHOO",
    address: "",
    fullName: "MADHU SUDAN SAHOO",
    saID: "SPR5329",
  },
  {
    firstName: "JAMINI",
    lastName: "SARKAR",
    address: "",
    fullName: "JAMINI SARKAR",
    saID: "SPR5330",
  },
  {
    firstName: "RAMA",
    lastName: "SARKAR",
    address: "",
    fullName: "RAMA SARKAR",
    saID: "SPR5331",
  },
  {
    firstName: "RENUKA",
    lastName: "SARKAR",
    address: "",
    fullName: "RENUKA SARKAR",
    saID: "SPR5332",
  },
  {
    firstName: "AMAL",
    lastName: "SARMA",
    address: "",
    fullName: "AMAL SARMA",
    saID: "SPR5333",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "SARMA",
    address: "",
    fullName: "DHIRENDRA NATH SARMA",
    saID: "SPR5334",
  },
  {
    firstName: "DINESH CHANDRA",
    lastName: "SARMA",
    address: "",
    fullName: "DINESH CHANDRA SARMA",
    saID: "SPR5335",
  },
  {
    firstName: "NIRMAL",
    lastName: "SEN",
    address: "",
    fullName: "NIRMAL SEN",
    saID: "SPR5336",
  },
  {
    firstName: "K",
    lastName: "SENGUPTA",
    address: "",
    fullName: "K SENGUPTA",
    saID: "SPR5337",
  },
  {
    firstName: "S K",
    lastName: "SENGUPTA",
    address: "",
    fullName: "S K SENGUPTA",
    saID: "SPR5338",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "SIL",
    address: "",
    fullName: "NEPAL CHANDRA SIL",
    saID: "SPR5339",
  },
  {
    firstName: "P",
    lastName: "SIL",
    address: "",
    fullName: "P SIL",
    saID: "SPR5340",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "SINGH",
    address: "",
    fullName: "ASHUTOSH SINGH",
    saID: "SPR5341",
  },
  {
    firstName: "GOURI PRASAD",
    lastName: "SINGHAROY",
    address: "",
    fullName: "GOURI PRASAD SINGHAROY",
    saID: "SPR5342",
  },
  {
    firstName: "E J SPENCER",
    lastName: "",
    address: "",
    fullName: "E J SPENCER ",
    saID: "SPR5343",
  },
  {
    firstName: "ARUN CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "ARUN CHANDRA DAS",
    saID: "SPR5344",
  },
  {
    firstName: "KRITIDIPA MARRIAGE",
    lastName: "",
    address: "",
    fullName: "KRITIDIPA MARRIAGE ",
    saID: "SPR5345",
  },
  {
    firstName: "CAR/A T",
    lastName: "JOARDAR",
    address: "",
    fullName: "CAR/A T JOARDAR",
    saID: "SPR5346",
  },
  {
    firstName: "ANANDA",
    lastName: "BOXI",
    address: "",
    fullName: "ANANDA BOXI",
    saID: "SPR5347",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5348",
  },
  {
    firstName: "BIDYAMA",
    lastName: "",
    address: "",
    fullName: "BIDYAMA ",
    saID: "SPR5349",
  },
  {
    firstName: "JNANABISHNU",
    lastName: "MONDAL",
    address: "SATSANG*DEOGHAR-814116",
    fullName: "JNANABISHNU MONDAL",
    saID: "SPR5350",
  },
  {
    firstName: "KRISHNENDU",
    lastName: "MANDAL",
    address:
      "PO&VILL-MAITIRHAT*VIA-MAGRAHAT*24 PARGANAS (SOUTH)-743355*PAN.AEKPM7426A",
    fullName: "KRISHNENDU MANDAL",
    saID: "SPR5351",
  },
  {
    firstName: "PRATAP KUMAR",
    lastName: "SWAIN",
    address: "AT-BALUNKA*PO-BAHARANA*PURI-752120",
    fullName: "PRATAP KUMAR SWAIN",
    saID: "SPR5352",
  },
  {
    firstName: "FANIBHUSAN",
    lastName: "PATHAK",
    address: "",
    fullName: "FANIBHUSAN PATHAK",
    saID: "SPR5353",
  },
  {
    firstName: "BAISHNAB CHARAN",
    lastName: "BHUYAN",
    address:
      "AT-BAGHADA ROAD(BALARAMPUR)*PO-BARIPADA,WARD NO.19*DIST-MAYURBHANJ 757 001",
    fullName: "BAISHNAB CHARAN BHUYAN",
    saID: "SPR5354",
  },
  {
    firstName: "ACHINTA KUMAR",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "ACHINTA KUMAR BHATTACHERJEE",
    saID: "SPR5355",
  },
  {
    firstName: "SOURINDRA PRASAD",
    lastName: "SINGHAROY",
    address: "",
    fullName: "SOURINDRA PRASAD SINGHAROY",
    saID: "SPR5356",
  },
  {
    firstName: "RAM MILAN",
    lastName: "SINGH",
    address:
      "VILL-HARIHARPUR*PO-SIRAULI*VIA-SITAMARHI BAZAR*SITAMARHI-843302*PAN-ANNPS6345J",
    fullName: "RAM MILAN SINGH",
    saID: "SPR5357",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5358",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5359",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5360",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5361",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5362",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5363",
  },
  {
    firstName: "SUREN",
    lastName: "MISTRY",
    address: "",
    fullName: "SUREN MISTRY",
    saID: "SPR5364",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "GANGULI",
    address:
      "73/1 MAHENDRA BANERJEE RD*BEHALA*PO-PARNASRIPALLY*CALCUTTA-60*PAN-AFYPG7134G",
    fullName: "SHYAMAL KUMAR GANGULI",
    saID: "SPR5365",
  },
  {
    firstName: "SABJIB",
    lastName: "MONDAL",
    address: "",
    fullName: "SABJIB MONDAL",
    saID: "SPR5366",
  },
  {
    firstName: "GOUTAM KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "GOUTAM KUMAR CHAKRABORTY",
    saID: "SPR5367",
  },
  {
    firstName: "MADHAB",
    lastName: "HALDER",
    address: "",
    fullName: "MADHAB HALDER",
    saID: "SPR5368",
  },
  {
    firstName: "RADHAMOHAN",
    lastName: "BANERJEE",
    address: "",
    fullName: "RADHAMOHAN BANERJEE",
    saID: "SPR5369",
  },
  {
    firstName: "RADHAMOHAN",
    lastName: "BANERJEE",
    address: "",
    fullName: "RADHAMOHAN BANERJEE",
    saID: "SPR5370",
  },
  {
    firstName: "KARTIK CHANDRA",
    lastName: "MIDDYA",
    address: "",
    fullName: "KARTIK CHANDRA MIDDYA",
    saID: "SPR5371",
  },
  {
    firstName: "RANJAN KUMAR",
    lastName: "DHAL",
    address: "AT-BARANGA*PO-BARITHENGARH*VIA-DHANMANDAL*DIST-JAJPUR.",
    fullName: "RANJAN KUMAR DHAL",
    saID: "SPR5372",
  },
  {
    firstName: "PUJANIA KARTA",
    lastName: "",
    address: "",
    fullName: "PUJANIA KARTA ",
    saID: "SPR5373",
  },
  {
    firstName: "PUJANIYA NABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA NABOURANI ",
    saID: "SPR5374",
  },
  {
    firstName: "JAYANTA",
    lastName: "CHAKRABARTY",
    address:
      "VILL-JUMBASTI WARD NO-4*PO-BADARPUR*KARIMGANJ-788806*PAN-AESPC3726M",
    fullName: "JAYANTA CHAKRABARTY",
    saID: "SPR5375",
  },
  {
    firstName: "BHUPENDRNATH",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "BHUPENDRNATH CHAKRAVORTY",
    saID: "SPR5376",
  },
  {
    firstName: "GURUPRASANNA",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "GURUPRASANNA BHATTACHERJEE",
    saID: "SPR5377",
  },
  {
    firstName: "C R",
    lastName: "DAS",
    address: "",
    fullName: "C R DAS",
    saID: "SPR5378",
  },
  {
    firstName: "REV BARABOUDI",
    lastName: "",
    address: "",
    fullName: "REV BARABOUDI ",
    saID: "SPR5379",
  },
  {
    firstName: "REV BOURANI",
    lastName: "",
    address: "",
    fullName: "REV BOURANI ",
    saID: "SPR5380",
  },
  {
    firstName: "BIDYA MAA",
    lastName: "",
    address: "",
    fullName: "BIDYA MAA ",
    saID: "SPR5381",
  },
  {
    firstName: "SUNIL",
    lastName: "DUTTA",
    address: "477 ARABINDOPALLY*PO-KONNAGAR*HOOGHLY 712235",
    fullName: "SUNIL DUTTA",
    saID: "SPR5382",
  },
  {
    firstName: "HAZARILAL",
    lastName: "BISWAS",
    address: "",
    fullName: "HAZARILAL BISWAS",
    saID: "SPR5383",
  },
  {
    firstName: "JYATI",
    lastName: "MAHANTI",
    address: "",
    fullName: "JYATI MAHANTI",
    saID: "SPR5384",
  },
  {
    firstName: "MADHABA NANDA",
    lastName: "MAHARANA",
    address: "AGRONOMIST I B F E P(HFC LTD)*AT&PO-DHARAMGARH*KALAHANDI-766015",
    fullName: "MADHABA NANDA MAHARANA",
    saID: "SPR5385",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "BEHERA",
    address: "BARMA BYPASS*BHADRAK-PIN 756100*ODISHA*PAN-AEUPB7615Q",
    fullName: "PRASANTA KUMAR BEHERA",
    saID: "SPR5386",
  },
  {
    firstName: "AVIJIT",
    lastName: "CHATTERJEE",
    address: "VILL&PO-GHOSHLA*P.S.-DENIAKHALI*HOOGHLY 712303",
    fullName: "AVIJIT CHATTERJEE",
    saID: "SPR5387",
  },
  {
    firstName: "NIRMALYA SEKHAR",
    lastName: "SINGHACHOUDHURY",
    address:
      "SHANTIPARA NO-2*NEAR GOBINDA ASHRAM*PO-LUMDING*DIST-NAGAON-782447*PAN-AHDPS9017P",
    fullName: "NIRMALYA SEKHAR SINGHACHOUDHURY",
    saID: "SPR5388",
  },
  {
    firstName: "RAJEN",
    lastName: "BANERJEE",
    address: "",
    fullName: "RAJEN BANERJEE",
    saID: "SPR5389",
  },
  {
    firstName: "KALO",
    lastName: "JOARDER",
    address: "",
    fullName: "KALO JOARDER",
    saID: "SPR5390",
  },
  {
    firstName: "RAJEN",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RAJEN CHAKRABORTY",
    saID: "SPR5391",
  },
  {
    firstName: "JATIN",
    lastName: "BASU",
    address: "",
    fullName: "JATIN BASU",
    saID: "SPR5392",
  },
  {
    firstName: "JANINDRA NATH",
    lastName: "BOSE",
    address: "",
    fullName: "JANINDRA NATH BOSE",
    saID: "SPR5393",
  },
  {
    firstName: "ASHOK",
    lastName: "BOSE",
    address:
      "283,SHANTI NAGAR*KHOSH BASH MAHALLA*PO-CHAKDAH*NADIA-741222*PAN-ADVPB1970G",
    fullName: "ASHOK BOSE",
    saID: "SPR5394",
  },
  {
    firstName: "JYOTISH CHANDRA",
    lastName: "MAJUMDAR",
    address: "PO-TUFANGANJ *ILA DEVI RD*DIST-COOCH BEHAR-736159.",
    fullName: "JYOTISH CHANDRA MAJUMDAR",
    saID: "SPR5395",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "ROUT",
    address: "",
    fullName: "SANTOSH KUMAR ROUT",
    saID: "SPR5396",
  },
  {
    firstName: "LINGARAJ",
    lastName: "SWAIN",
    address: "AT/PO-RUGUMU*VIA-DHARAKOTE*GANJAM 761107*PAN.CLIPS1742N",
    fullName: "LINGARAJ SWAIN",
    saID: "SPR5397",
  },
  {
    firstName: "NIRAD KUMAR",
    lastName: "ACHARYA",
    address: "AT/PO-LATHANG*VIA-RAHAMA*JAGATSINGHPUR 754140",
    fullName: "NIRAD KUMAR ACHARYA",
    saID: "SPR5398",
  },
  {
    firstName: "NILKANTHA",
    lastName: "MAITY",
    address: "",
    fullName: "NILKANTHA MAITY",
    saID: "SPR5399",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "ROUT",
    address: "",
    fullName: "AJIT KUMAR ROUT",
    saID: "SPR5400",
  },
  {
    firstName: "PUJANIA ARKYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIA ARKYADYUTI CHAKRABORTY",
    saID: "SPR5401",
  },
  {
    firstName: "PARTHA PRATIM",
    lastName: "BANERJEE",
    address: "",
    fullName: "PARTHA PRATIM BANERJEE",
    saID: "SPR5402",
  },
  {
    firstName: "NIRMALENDU",
    lastName: "MONDAL",
    address: "C/O RANAJIT KUMAR MONDAL*AT/PO-BHAGYABANTAPUR*NADIA 713130",
    fullName: "NIRMALENDU MONDAL",
    saID: "SPR5403",
  },
  {
    firstName: "KISHUN LAL",
    lastName: "AGARWALA",
    address: "",
    fullName: "KISHUN LAL AGARWALA",
    saID: "SPR5404",
  },
  {
    firstName: "A T JOARDAR  CAR",
    lastName: "",
    address: "",
    fullName: "A T JOARDAR  CAR ",
    saID: "SPR5405",
  },
  {
    firstName: "ASHISH KUMAR",
    lastName: "MUKHERJEE",
    address: "BANKURA SWASTI SIBIR*KESIAKOL*BANKURA-722155",
    fullName: "ASHISH KUMAR MUKHERJEE",
    saID: "SPR5406",
  },
  {
    firstName: "JAGADISH PRASAD",
    lastName: "SINGH",
    address: "RAJLAXMI BIJLI HOUSE*BANGAON ROAD*SAHARSA",
    fullName: "JAGADISH PRASAD SINGH",
    saID: "SPR5407",
  },
  {
    firstName: "PRASENJIT",
    lastName: "DASCHOUDHURY",
    address:
      "W/NO-3 BYE LANE 3*SATSANG ASHRAM ROAD*PO-SETTLEMENT RD*KARIMGANJ-788712*AIHPC2470R",
    fullName: "PRASENJIT DASCHOUDHURY",
    saID: "SPR5408",
  },
  {
    firstName: "JAGADISH",
    lastName: "PRASAD",
    address: "C/O-RAMKRISHNA THAKUR*BATRAHA RD (WORD NO-I)*SAHARSA-852201",
    fullName: "JAGADISH PRASAD",
    saID: "SPR5409",
  },
  {
    firstName: "BHADRESWAR",
    lastName: "BASUMATARY",
    address:
      "VILL-SINGRAMARI*PO-PAKRIBARI*VIA-KUMARIKATA*BAKSA,BTAD*(ASSAM) 781 360",
    fullName: "BHADRESWAR BASUMATARY",
    saID: "SPR5410",
  },
  {
    firstName: "JAGADISH",
    lastName: "BRAHMA",
    address: "PO-SIMALUGURI*LAKHIMPUR 784165",
    fullName: "JAGADISH BRAHMA",
    saID: "SPR5411",
  },
  {
    firstName: "ARKADUTY",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADUTY CHAKRABORTY",
    saID: "SPR5412",
  },
  {
    firstName: "PUJANIYA NUTAN BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA NUTAN BOUDI ",
    saID: "SPR5413",
  },
  {
    firstName: "SATYA",
    lastName: "DEY",
    address: "",
    fullName: "SATYA DEY",
    saID: "SPR5414",
  },
  {
    firstName: "NANI GOPAL",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "NANI GOPAL CHAKRABORTY",
    saID: "SPR5415",
  },
  {
    firstName: "SANTIRBAZAR S/VIHAR",
    lastName: "",
    address: "",
    fullName: "SANTIRBAZAR S/VIHAR ",
    saID: "SPR5416",
  },
  {
    firstName: "KHOWAI",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "KHOWAI SATSANGVIHAR",
    saID: "SPR5417",
  },
  {
    firstName: "KUMARGHAT",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "KUMARGHAT SATSANGVIHAR",
    saID: "SPR5418",
  },
  {
    firstName: "PURBA PRATAPGARH",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "PURBA PRATAPGARH SATSANGVIHAR",
    saID: "SPR5419",
  },
  {
    firstName: "USHABAZAR",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "USHABAZAR SATSANGVIHAR",
    saID: "SPR5420",
  },
  {
    firstName: "JIRONIA",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "JIRONIA SATSANGVIHAR",
    saID: "SPR5421",
  },
  {
    firstName: "DHARMANAGAR",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "DHARMANAGAR SATSANGVIHAR",
    saID: "SPR5422",
  },
  {
    firstName: "KAILASAHAR",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "KAILASAHAR SATSANGVIHAR",
    saID: "SPR5423",
  },
  {
    firstName: "BILONIA",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "BILONIA SATSANGVIHAR",
    saID: "SPR5424",
  },
  {
    firstName: "MANUBAZAR",
    lastName: "SATSANGKENDRA",
    address: "",
    fullName: "MANUBAZAR SATSANGKENDRA",
    saID: "SPR5425",
  },
  {
    firstName: "SABROOM",
    lastName: "SATSANGVIHAR",
    address: "",
    fullName: "SABROOM SATSANGVIHAR",
    saID: "SPR5426",
  },
  {
    firstName: "NIRANJAN",
    lastName: "BEHERA",
    address:
      '"TAPABIBHA"*686/2882,SHANTINAGAR*CANAL ROAD,JHARPADA*BHUBANESWAR*KHURDA',
    fullName: "NIRANJAN BEHERA",
    saID: "SPR5427",
  },
  {
    firstName: "RITURAJ",
    lastName: "BORGOHAIN",
    address:
      "A/C`S DEPT,COAL INDIA LTD*PO-MARGHERITA*DIST-TINSUKIA-781181*PAN ACHPB8394D",
    fullName: "RITURAJ BORGOHAIN",
    saID: "SPR5428",
  },
  {
    firstName: "1UJANIYA RANGA MAA",
    lastName: "",
    address: "",
    fullName: "1UJANIYA RANGA MAA ",
    saID: "SPR5429",
  },
  {
    firstName: "BHUPENDRA",
    lastName: "DEV",
    address: "",
    fullName: "BHUPENDRA DEV",
    saID: "SPR5430",
  },
  {
    firstName: "PUJANIYA SILPI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SILPI CHAKRABORTY",
    saID: "SPR5431",
  },
  {
    firstName: "PURNA  CHANDRA",
    lastName: "ACHARYA",
    address: '"SWASTI KUNJA"*SAHOO BAGICHA,BALIGHAT*PURI-752002',
    fullName: "PURNA  CHANDRA ACHARYA",
    saID: "SPR5432",
  },
  {
    firstName: "BIJOY KRISHNA",
    lastName: "BARMAN",
    address:
      "(ASSTT TEACHER)*KELETI G A VIDYAPITH*PO-KELETI*VIA-GUSHKARA*BURDWAN*PAN-ADEPB8299H",
    fullName: "BIJOY KRISHNA BARMAN",
    saID: "SPR5433",
  },
  {
    firstName: "CHANDRA KANTA",
    lastName: "MOURJYA",
    address: "C/O R.K.LAHA*D.T.O.PORT BLAIR*ANDAMAN.",
    fullName: "CHANDRA KANTA MOURJYA",
    saID: "SPR5434",
  },
  {
    firstName: "BHUMIDHAR",
    lastName: "RAY",
    address: "VILL&PO-KISMAT HASDAHA*DHUBRI-783334",
    fullName: "BHUMIDHAR RAY",
    saID: "SPR5435",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "DEB",
    address: 'C/O-SREEMA PRESS*"SITALA BARI"*PO-DIPHU*KARBIANGLONG 782460',
    fullName: "SWAPAN KUMAR DEB",
    saID: "SPR5436",
  },
  {
    firstName: "HARI BAHADUR",
    lastName: "RANA",
    address: "",
    fullName: "HARI BAHADUR RANA",
    saID: "SPR5437",
  },
  {
    firstName: "REV",
    lastName: "",
    address: "",
    fullName: "REV ",
    saID: "SPR5438",
  },
  {
    firstName: "REV KARTAMA",
    lastName: "",
    address: "",
    fullName: "REV KARTAMA ",
    saID: "SPR5439",
  },
  {
    firstName: "KARUNASINDHU",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "KARUNASINDHU MUKHERJEE",
    saID: "SPR5440",
  },
  {
    firstName: "MANOHAR",
    lastName: "MAHATO",
    address: "",
    fullName: "MANOHAR MAHATO",
    saID: "SPR5441",
  },
  {
    firstName: "BALARAM",
    lastName: "BEHERA",
    address: "NEW STREET*NEAR CINEMA HALL*AT/PO-CHHATRAPUR*GANJAM 761020",
    fullName: "BALARAM BEHERA",
    saID: "SPR5442",
  },
  {
    firstName: "MURARIMOHAN",
    lastName: "DAW",
    address: "",
    fullName: "MURARIMOHAN DAW",
    saID: "SPR5443",
  },
  {
    firstName: "MURARIMOHAN",
    lastName: "DAW",
    address: "",
    fullName: "MURARIMOHAN DAW",
    saID: "SPR5444",
  },
  {
    firstName: "LATE MRITYUNJOY",
    lastName: "DEY",
    address: "NETAJI PARK II*PO-BANDEL*DIST-HOOGHLY-712123",
    fullName: "LATE MRITYUNJOY DEY",
    saID: "SPR5445",
  },
  {
    firstName: "ANURUDHYA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANURUDHYA CHAKRABORTY",
    saID: "SPR5446",
  },
  {
    firstName: "AMAR BIJAY",
    lastName: "SENSARMA",
    address: "",
    fullName: "AMAR BIJAY SENSARMA",
    saID: "SPR5447",
  },
  {
    firstName: "HAJARI LAL",
    lastName: "BISWAS",
    address: "",
    fullName: "HAJARI LAL BISWAS",
    saID: "SPR5448",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5449",
  },
  {
    firstName: "SIDHAR SUNDAR",
    lastName: "MISHRA",
    address: "",
    fullName: "SIDHAR SUNDAR MISHRA",
    saID: "SPR5450",
  },
  {
    firstName: "HARIBAHADUR",
    lastName: "RANA",
    address: "",
    fullName: "HARIBAHADUR RANA",
    saID: "SPR5451",
  },
  {
    firstName: "PUJANIA KALYANI",
    lastName: "MAITRA",
    address: "",
    fullName: "PUJANIA KALYANI MAITRA",
    saID: "SPR5452",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "MONDAL",
    address: "VILL&PO-SITANAGAR*DT-ANDAMAN NORTH 744202",
    fullName: "KESHAB CHANDRA MONDAL",
    saID: "SPR5453",
  },
  {
    firstName: "SUBODH",
    lastName: "DEBBARMA",
    address: "AT-AMPURA BARI*PO-BHARAT SARDARPUR*TRIPURA",
    fullName: "SUBODH DEBBARMA",
    saID: "SPR5454",
  },
  {
    firstName: "SRIKRISHNA GOPAL",
    lastName: "CHOWDHURY",
    address: "",
    fullName: "SRIKRISHNA GOPAL CHOWDHURY",
    saID: "SPR5455",
  },
  {
    firstName: "PHANI BHUSAN",
    lastName: "SADHAK",
    address: "",
    fullName: "PHANI BHUSAN SADHAK",
    saID: "SPR5456",
  },
  {
    firstName: "DINABANDHU",
    lastName: "PRASAD",
    address: "AT&PO-PANAPUR DHARAMPUR*VIA-HAJIPUR*VAISALI-844102*BIHAR",
    fullName: "DINABANDHU PRASAD",
    saID: "SPR5457",
  },
  {
    firstName: "BIDYABATI",
    lastName: "SARKAR",
    address: "",
    fullName: "BIDYABATI SARKAR",
    saID: "SPR5458",
  },
  {
    firstName: "BAMBOO",
    lastName: "",
    address: "",
    fullName: "BAMBOO ",
    saID: "SPR5459",
  },
  {
    firstName: "SHANKAR PRASAD",
    lastName: "SINGH",
    address:
      "C/O KAILASH PRASAD SINGH*VILL-RAMNAGAR*PO-JAMALPUR*DT-MUNGYR-811214",
    fullName: "SHANKAR PRASAD SINGH",
    saID: "SPR5460",
  },
  {
    firstName: "LATE BRAJKISHORE",
    lastName: "MISHRA",
    address: "PIPRAKATA*AT/PO/DT-GARWA",
    fullName: "LATE BRAJKISHORE MISHRA",
    saID: "SPR5461",
  },
  {
    firstName: "P K",
    lastName: "DEBNATH",
    address: "",
    fullName: "P K DEBNATH",
    saID: "SPR5462",
  },
  {
    firstName: "SHYAM KUMAR",
    lastName: "CHOUDHURY",
    address: "C/O-BIMAL STORES*PO-JOGBANI*PURNIA-854328",
    fullName: "SHYAM KUMAR CHOUDHURY",
    saID: "SPR5463",
  },
  {
    firstName: "KEDARESWAR",
    lastName: "SAHOO",
    address: "(B C ROAD)*PO-J K PUR 765017*DIST-RAYAGADA*ORISSA",
    fullName: "KEDARESWAR SAHOO",
    saID: "SPR5464",
  },
  {
    firstName: "JAGANNATH",
    lastName: "DEBNATH",
    address: "S/O BINANDA DEBNATH*VILL&PO-PATIRAM*DAKSHIN DINAJPUR",
    fullName: "JAGANNATH DEBNATH",
    saID: "SPR5465",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5466",
  },
  {
    firstName: "SATYENDRA NATH",
    lastName: "SOM",
    address: "BABUPARA*PO-SEARSOL RAJBARI*RANIGANJ-713358*BURDWAN",
    fullName: "SATYENDRA NATH SOM",
    saID: "SPR5467",
  },
  {
    firstName: "HIRU CHANDRA",
    lastName: "PAL",
    address: "",
    fullName: "HIRU CHANDRA PAL",
    saID: "SPR5468",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5469",
  },
  {
    firstName: "PARIMAL RANJAN",
    lastName: "BHUYAN",
    address: "AT&PO-NAYA BAZAR*VIA-JALESWAR*BALASORE-756032*PAN-ACLPB1452E",
    fullName: "PARIMAL RANJAN BHUYAN",
    saID: "SPR5470",
  },
  {
    firstName: "CHHOTMA",
    lastName: "",
    address: "",
    fullName: "CHHOTMA ",
    saID: "SPR5471",
  },
  {
    firstName: "A K",
    lastName: "MISHRA",
    address: "",
    fullName: "A K MISHRA",
    saID: "SPR5472",
  },
  {
    firstName: "PUJANIYABARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYABARABOUDI ",
    saID: "SPR5473",
  },
  {
    firstName: "NAPU RANJAN",
    lastName: "DEBBARMA",
    address:
      "VILL-NEW TAKLAPARA*PO-NORTH CHEBRI*DIST-KHOWAI(TRIPURA)-799207*PAN-CIWPD0536J",
    fullName: "NAPU RANJAN DEBBARMA",
    saID: "SPR5474",
  },
  {
    firstName: "PUJANIA MEJA BAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA MEJA BAUDI ",
    saID: "SPR5475",
  },
  {
    firstName: "PUJANIA SEJA BAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA SEJA BAUDI ",
    saID: "SPR5476",
  },
  {
    firstName: "PUJANIA NOA BAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA NOA BAUDI ",
    saID: "SPR5477",
  },
  {
    firstName: "PUJANIA BARA BAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BAUDI ",
    saID: "SPR5478",
  },
  {
    firstName: "BRAHMAPADA",
    lastName: "DAS",
    address: "",
    fullName: "BRAHMAPADA DAS",
    saID: "SPR5479",
  },
  {
    firstName: "DIBYENDU",
    lastName: "CHAKRABORTY",
    address:
      "11,HILL VIEW PARK(NORTH)*PO-ASANSOL*BURDWAN-713304*PAN-ACKPC8595N",
    fullName: "DIBYENDU CHAKRABORTY",
    saID: "SPR5480",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "SANTRA",
    address: "",
    fullName: "SWAPAN KUMAR SANTRA",
    saID: "SPR5481",
  },
  {
    firstName: "SATYEN",
    lastName: "SARKAR",
    address: "",
    fullName: "SATYEN SARKAR",
    saID: "SPR5482",
  },
  {
    firstName: "KRITI SUNDAR",
    lastName: "GURU",
    address: "THAKUR BUNGLOW*PO-SATSANG*DEOGHAR 814116*PAN-AFWPG7116E",
    fullName: "KRITI SUNDAR GURU",
    saID: "SPR5483",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SARKAR",
    address: "",
    fullName: "SURENDRA NATH SARKAR",
    saID: "SPR5484",
  },
  {
    firstName: "NISIR KUMAR",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "NISIR KUMAR CHATTERJEE",
    saID: "SPR5485",
  },
  {
    firstName: "LATE RAMAKANT",
    lastName: "JHA",
    address: "BATRAHA WARD NO-15*SAHARSA*(BIHAR)",
    fullName: "LATE RAMAKANT JHA",
    saID: "SPR5486",
  },
  {
    firstName: "RUE",
    lastName: "",
    address: "",
    fullName: "RUE ",
    saID: "SPR5487",
  },
  {
    firstName: "GOLEKHA",
    lastName: "SAHOO",
    address: "",
    fullName: "GOLEKHA SAHOO",
    saID: "SPR5488",
  },
  {
    firstName: "DIGEN",
    lastName: "TANTI",
    address: "WAKHA TEA ESTATE*PO-FURKATING*JORHAT-785603*ASSAM",
    fullName: "DIGEN TANTI",
    saID: "SPR5489",
  },
  {
    firstName: "BARENDRA KRISHNA",
    lastName: "KONAR",
    address: "VILL&PO-KULINGRAM*BURDWAN 713166",
    fullName: "BARENDRA KRISHNA KONAR",
    saID: "SPR5490",
  },
  {
    firstName: "NITYANANDA",
    lastName: "MANDAL",
    address: "",
    fullName: "NITYANANDA MANDAL",
    saID: "SPR5491",
  },
  {
    firstName: "VINAY KUMAR",
    lastName: "PAL",
    address: "DHARAMJAIGARH COLONY*SHAHPUR*RAIGARH*M.P.-496116",
    fullName: "VINAY KUMAR PAL",
    saID: "SPR5492",
  },
  {
    firstName: "PUJANIYA SIPRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SIPRA CHAKRABORTY",
    saID: "SPR5493",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5494",
  },
  {
    firstName: "MRINAL",
    lastName: "MISHRA",
    address: "SANKARPUR*NALBARI*ASSAM-781 334.",
    fullName: "MRINAL MISHRA",
    saID: "SPR5495",
  },
  {
    firstName: "NATABAR",
    lastName: "SAHU",
    address: "AT/PO-KOUDUKOL*VIA-DHANMANDAL*JAJPUR-754024",
    fullName: "NATABAR SAHU",
    saID: "SPR5496",
  },
  {
    firstName: "ONKAR",
    lastName: "SINGH",
    address: "LAB.TECHNICIAN*DISTRICT HOSPITAL,SIDHI*M.P. 486 661",
    fullName: "ONKAR SINGH",
    saID: "SPR5497",
  },
  {
    firstName: "MATILAL",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "MATILAL CHATTERJEE",
    saID: "SPR5498",
  },
  {
    firstName: "REBATI",
    lastName: "UPADHYAY",
    address:
      "AT-ROWTA STATION*PO-ROWTA 784508*DIST-UDALGURI*ASSAM PAN NO.AEFPU2407N",
    fullName: "REBATI UPADHYAY",
    saID: "SPR5499",
  },
  {
    firstName: "HIRANMOY",
    lastName: "GHOSH",
    address: "",
    fullName: "HIRANMOY GHOSH",
    saID: "SPR5500",
  },
  {
    firstName: "NITYA DA",
    lastName: "",
    address: "",
    fullName: "NITYA DA ",
    saID: "SPR5501",
  },
  {
    firstName: "MADAN DA",
    lastName: "",
    address: "",
    fullName: "MADAN DA ",
    saID: "SPR5502",
  },
  {
    firstName: "R S MISRA (PURI)",
    lastName: "",
    address: "",
    fullName: "R S MISRA (PURI) ",
    saID: "SPR5503",
  },
  {
    firstName: "ALEKHA PRASAD",
    lastName: "MOHARANA",
    address: "AT&PO-HARIPUR*JEMADEIPUR*VIA-SUKINDA*JAJPUR 755018",
    fullName: "ALEKHA PRASAD MOHARANA",
    saID: "SPR5504",
  },
  {
    firstName: "PUJANIYA BABAI DA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BABAI DA ",
    saID: "SPR5505",
  },
  {
    firstName: "6EV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6EV ASOK CHAKRABORTY",
    saID: "SPR5506",
  },
  {
    firstName: "DILIP",
    lastName: "CHANDA",
    address:
      "C/O-BROJENDRA CHANDA*VILL-GANDACHARA*PO-SARMA,DHALAI*TRIPURA-799289",
    fullName: "DILIP CHANDA",
    saID: "SPR5507",
  },
  {
    firstName: "DIPESH",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "DIPESH CHAKRABORTY",
    saID: "SPR5508",
  },
  {
    firstName: "PRAVAT",
    lastName: "TARAFDAR",
    address: "AT&PO-BAGDA*24PARGANAS NORTH-743232",
    fullName: "PRAVAT TARAFDAR",
    saID: "SPR5509",
  },
  {
    firstName: "W/O MAHENDRA NATH",
    lastName: "HALDER",
    address: "",
    fullName: "W/O MAHENDRA NATH HALDER",
    saID: "SPR5510",
  },
  {
    firstName: "SILPI BOUDI",
    lastName: "",
    address: "",
    fullName: "SILPI BOUDI ",
    saID: "SPR5511",
  },
  {
    firstName: "KIRITI BHUSHAN",
    lastName: "SINHAMOHAPATRA",
    address: "VILL&PO-KADMA*BANKURA-722151",
    fullName: "KIRITI BHUSHAN SINHAMOHAPATRA",
    saID: "SPR5512",
  },
  {
    firstName: "DEBASIS(VDO CASSET)",
    lastName: "MANDAL",
    address: "",
    fullName: "DEBASIS(VDO CASSET) MANDAL",
    saID: "SPR5513",
  },
  {
    firstName: "MAGARAM",
    lastName: "BAURI",
    address: "",
    fullName: "MAGARAM BAURI",
    saID: "SPR5514",
  },
  {
    firstName: "CAR ASHUTOSH",
    lastName: "JOARDER",
    address: "",
    fullName: "CAR ASHUTOSH JOARDER",
    saID: "SPR5515",
  },
  {
    firstName: "REV BARAMA",
    lastName: "",
    address: "",
    fullName: "REV BARAMA ",
    saID: "SPR5516",
  },
  {
    firstName: "DEBIPRASAD",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "DEBIPRASAD MUKHERJEE",
    saID: "SPR5517",
  },
  {
    firstName: "RAMHARI",
    lastName: "DASCHAKRABORTY",
    address: "VILL-SITARAMPUR*PO-CHINGRA*DT-BANKURA",
    fullName: "RAMHARI DASCHAKRABORTY",
    saID: "SPR5518",
  },
  {
    firstName: "ARUN",
    lastName: "MANDAL",
    address: "",
    fullName: "ARUN MANDAL",
    saID: "SPR5519",
  },
  {
    firstName: "P N",
    lastName: "SINGHA",
    address: "",
    fullName: "P N SINGHA",
    saID: "SPR5520",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5521",
  },
  {
    firstName: "DURGANATH",
    lastName: "DEBSARMA",
    address: "",
    fullName: "DURGANATH DEBSARMA",
    saID: "SPR5522",
  },
  {
    firstName: "PANCHUGOPAL",
    lastName: "MUKHARJEE",
    address:
      "PRITI ASHRAM*AT-BALLIPINDI STREET*PO-BUGUDA*GANJAM-761118*PAN-BFTPM9819Q",
    fullName: "PANCHUGOPAL MUKHARJEE",
    saID: "SPR5523",
  },
  {
    firstName: "KUMUD",
    lastName: "BISWAS",
    address: "",
    fullName: "KUMUD BISWAS",
    saID: "SPR5524",
  },
  {
    firstName: "KRIPASINDHU",
    lastName: "RAKSHIT",
    address: "",
    fullName: "KRIPASINDHU RAKSHIT",
    saID: "SPR5525",
  },
  {
    firstName: "LATE BYOMKESH",
    lastName: "DEY",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE BYOMKESH DEY",
    saID: "SPR5526",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5527",
  },
  {
    firstName: "BISHU",
    lastName: "SARKAR",
    address: "",
    fullName: "BISHU SARKAR",
    saID: "SPR5528",
  },
  {
    firstName: "DINA NATH",
    lastName: "SHARMA",
    address: "",
    fullName: "DINA NATH SHARMA",
    saID: "SPR5529",
  },
  {
    firstName: "NABANANDAN",
    lastName: "TALUKDAR",
    address:
      "BEHIND KALIBARI*DNK COLONY*AT&PO-KONDAGAON*DIST-BASTAR*C.G.494 226",
    fullName: "NABANANDAN TALUKDAR",
    saID: "SPR5530",
  },
  {
    firstName: "PUJANIYA SIKHA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SIKHA CHAKRABORTY",
    saID: "SPR5531",
  },
  {
    firstName: "HEM KANTA",
    lastName: "BHUNIYA",
    address: "",
    fullName: "HEM KANTA BHUNIYA",
    saID: "SPR5532",
  },
  {
    firstName: "UJJWAL",
    lastName: "MANDAL",
    address: "KANDI SATSANG KENDRA*PO-KANDI*MURSHIDABAD-742137",
    fullName: "UJJWAL MANDAL",
    saID: "SPR5533",
  },
  {
    firstName: "RAJENDRA NATH",
    lastName: "BANERJEE",
    address: "",
    fullName: "RAJENDRA NATH BANERJEE",
    saID: "SPR5534",
  },
  {
    firstName: "SAILESH",
    lastName: "DAS",
    address: "",
    fullName: "SAILESH DAS",
    saID: "SPR5535",
  },
  {
    firstName: "DILLIP",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "DILLIP CHOUDHURY",
    saID: "SPR5536",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "TRIPATHY",
    address:
      "C/O-RAMA CHANDRA TRIPATHY*AT/PO-RAJU STREET*DIST-NABARANGPUR-764059*ODISHA",
    fullName: "ASHOK KUMAR TRIPATHY",
    saID: "SPR5537",
  },
  {
    firstName: "DEBI PRAKASH",
    lastName: "HOTA",
    address: "SATSANG KENDRA, GIRIDIH*BARACHAK*GIRIDIH*PAN--AELPH9871D",
    fullName: "DEBI PRAKASH HOTA",
    saID: "SPR5538",
  },
  {
    firstName: "DULAL",
    lastName: "MAJUMDAR",
    address: "",
    fullName: "DULAL MAJUMDAR",
    saID: "SPR5539",
  },
  {
    firstName: "GADADHAR",
    lastName: "BISWAL",
    address: "NO 2 SATSANG VIHAR ROAD*SRI SHANKARA NAGAR*PAMMAL*CHENNAI 75",
    fullName: "GADADHAR BISWAL",
    saID: "SPR5540",
  },
  {
    firstName: "HARLAL",
    lastName: "ROY",
    address: "VILL-HOWDAVITA*PO-KHORIBARI*DARJEELING-734427",
    fullName: "HARLAL ROY",
    saID: "SPR5541",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5542",
  },
  {
    firstName: "PUJANIA",
    lastName: "",
    address: "",
    fullName: "PUJANIA ",
    saID: "SPR5543",
  },
  {
    firstName: "LATE PULIN BIHARI",
    lastName: "SARKAR",
    address:
      "VILL-SALKA (HOSPITAL PARA)*PO-SIMULPUR*24 PARGANAS (NORTH)-743289.",
    fullName: "LATE PULIN BIHARI SARKAR",
    saID: "SPR5544",
  },
  {
    firstName: "REV KARTAMA",
    lastName: "",
    address: "",
    fullName: "REV KARTAMA ",
    saID: "SPR5545",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "BOSE",
    address: "DUM DUM AIRPORT*MANIKPUR RAMKRISHNANAGAR*PO-ITALGACHA*KOLKATA-76",
    fullName: "SWAPAN KUMAR BOSE",
    saID: "SPR5546",
  },
  {
    firstName: "PAWAN KUMAR",
    lastName: "BANSAL",
    address:
      "C/O SRI KAPUR CHAND BANSAL*PO-JAMURIA BAJAR*BURDWAN 713336*PAN-ADKPB9521A",
    fullName: "PAWAN KUMAR BANSAL",
    saID: "SPR5547",
  },
  {
    firstName: "ADHIR",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "ADHIR MUKHERJEE",
    saID: "SPR5548",
  },
  {
    firstName: "G B",
    lastName: "PARIDA",
    address: "",
    fullName: "G B PARIDA",
    saID: "SPR5549",
  },
  {
    firstName: "SUBIR",
    lastName: "SAHA",
    address:
      "C/O SWAPAN KUMAR SAHA*CHOWK BAZAR*PO&DIST-GOLAGHAT*ASSAM-785 621.",
    fullName: "SUBIR SAHA",
    saID: "SPR5550",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABARTY",
    saID: "SPR5551",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5552",
  },
  {
    firstName: "LATE GANESH PRASAD",
    lastName: "YADAV",
    address: "SUB MAJOR(HONY CAPT)*MAINPURA*PO-DANAPUR CANTT*PATNA",
    fullName: "LATE GANESH PRASAD YADAV",
    saID: "SPR5553",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR5554",
  },
  {
    firstName: "6EV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6EV ASOK CHAKRABORTY",
    saID: "SPR5555",
  },
  {
    firstName: "DHIREN",
    lastName: "MALLIK",
    address: "",
    fullName: "DHIREN MALLIK",
    saID: "SPR5556",
  },
  {
    firstName: "BISWAJIT",
    lastName: "DAS",
    address: "P.W.D.COLONY*PO-BALADMARI*NEAR BIHU FIELD*GOALPARA-783121*ASSAM",
    fullName: "BISWAJIT DAS",
    saID: "SPR5557",
  },
  {
    firstName: "NIRMAL RANJAN",
    lastName: "DAS",
    address: "NARANARAYAN ROAD(NORTH)*RAIL GUMTI*PO&DIST-COOCHBEHAR 736101.",
    fullName: "NIRMAL RANJAN DAS",
    saID: "SPR5558",
  },
  {
    firstName: "REV ARUN KUMAR",
    lastName: "GANGULY",
    address: "",
    fullName: "REV ARUN KUMAR GANGULY",
    saID: "SPR5559",
  },
  {
    firstName: "JOYJIT",
    lastName: "ROY",
    address: "VILL-KATLA*PO-DANGARHAT*DIST-DAKSHIN DINAJPUR 733 141.",
    fullName: "JOYJIT ROY",
    saID: "SPR5560",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "RAY",
    address: "C/O-JHOTKAN SINGH*POSTAL STAFF*PO-NAXAL BARI*DARJEELING 734 429.",
    fullName: "DILIP KUMAR RAY",
    saID: "SPR5561",
  },
  {
    firstName: "DIPAK",
    lastName: "CHATTERJEE",
    address: "SATSANG COMPUTER CENTRE*SATSANG",
    fullName: "DIPAK CHATTERJEE",
    saID: "SPR5562",
  },
  {
    firstName: "BANAMBAR",
    lastName: "PRADHAN",
    address: "AT-PARALA*PO-CHILIKA NUAPARA*VIA-BRAHMAGIRI*PURI",
    fullName: "BANAMBAR PRADHAN",
    saID: "SPR5563",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5564",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "CHOWDHURY",
    address: "",
    fullName: "BIRENDRA NATH CHOWDHURY",
    saID: "SPR5565",
  },
  {
    firstName: "BIRAKISHORE",
    lastName: "MOHAPATRA",
    address: "",
    fullName: "BIRAKISHORE MOHAPATRA",
    saID: "SPR5566",
  },
  {
    firstName: "BANGSHIDHAR",
    lastName: "SAMANTAROY",
    address: "",
    fullName: "BANGSHIDHAR SAMANTAROY",
    saID: "SPR5567",
  },
  {
    firstName: "DHARANIDHAR",
    lastName: "MAHATO",
    address: "RETD POSTMASTER*AT&PO-BANWARIPUR*VIA-TEGHRA*BEGUSARAI",
    fullName: "DHARANIDHAR MAHATO",
    saID: "SPR5568",
  },
  {
    firstName: "MURARI MOHAN",
    lastName: "GHOSH",
    address:
      "UTTAR JOJRA MADAN MOHAN COLONY*PO-ROHANDA*24 PARGANS (NORTH)-700135*PAN-AUXPG7529F",
    fullName: "MURARI MOHAN GHOSH",
    saID: "SPR5569",
  },
  {
    firstName: "PUJANIABARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIABARABOURANI ",
    saID: "SPR5570",
  },
  {
    firstName: "SMRITIKANA",
    lastName: "DHAR",
    address: "",
    fullName: "SMRITIKANA DHAR",
    saID: "SPR5571",
  },
  {
    firstName: "DEBARAJ",
    lastName: "MALLICK",
    address: "O M P ROAD*AT/PO/DIST-RAYAGADA 765001*PAN-AGOPM7998M",
    fullName: "DEBARAJ MALLICK",
    saID: "SPR5572",
  },
  {
    firstName: "NITAI",
    lastName: "BHOUMICK",
    address: "AT&PO-HARINA*SOUTH TRIPURA-799145*PAN-ATGPB6123J",
    fullName: "NITAI BHOUMICK",
    saID: "SPR5573",
  },
  {
    firstName: "LATE MRINAL",
    lastName: "BANERJEE",
    address: "68 SURYA SEN  STREET*KOLKATA 700009",
    fullName: "LATE MRINAL BANERJEE",
    saID: "SPR5574",
  },
  {
    firstName: "B D",
    lastName: "SAMANTAROY",
    address: "",
    fullName: "B D SAMANTAROY",
    saID: "SPR5575",
  },
  {
    firstName: "B K",
    lastName: "MAHAPATRA",
    address: "",
    fullName: "B K MAHAPATRA",
    saID: "SPR5576",
  },
  {
    firstName: "PUJANIYABARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYABARABAUDI ",
    saID: "SPR5577",
  },
  {
    firstName: "RAMPRASAD",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "RAMPRASAD BHATTACHERJEE",
    saID: "SPR5578",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5579",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5580",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "DAS",
    address: "",
    fullName: "HRUSHIKESH DAS",
    saID: "SPR5581",
  },
  {
    firstName: "NIRANJAN",
    lastName: "JENA",
    address: "AT-GARH HARISHPUR*PO-OLARAH*VIA-BORIKINA*JAGATSINGHPUR (ORISSA)",
    fullName: "NIRANJAN JENA",
    saID: "SPR5582",
  },
  {
    firstName: "SUSHANTA KUMAR",
    lastName: "GHOSE",
    address: "VILL-DANGA*PO-BODAI*24PARGANAS (NORTH).",
    fullName: "SUSHANTA KUMAR GHOSE",
    saID: "SPR5583",
  },
  {
    firstName: "GIRIJA PRASANNA",
    lastName: "MOHANTY",
    address:
      "SRIGURU KALYAN MANDAP*805,JAYDEV VIHAR*PO-R R L*BHUBANESWAR-751013*KHURDA",
    fullName: "GIRIJA PRASANNA MOHANTY",
    saID: "SPR5584",
  },
  {
    firstName: "KRISHNAGOPAL",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "KRISHNAGOPAL CHOUDHURY",
    saID: "SPR5585",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5586",
  },
  {
    firstName: "SANKAR",
    lastName: "SAMAL",
    address: "",
    fullName: "SANKAR SAMAL",
    saID: "SPR5587",
  },
  {
    firstName: "AJIT",
    lastName: "TANTI",
    address: "RANGAGARAH T E*PO-BADULIPAR*GOLAGHAT-785611*PAN-ADEPT7331G*ASSAM",
    fullName: "AJIT TANTI",
    saID: "SPR5588",
  },
  {
    firstName: "MARRAIGE FOR ARJYAMA",
    lastName: "",
    address: "",
    fullName: "MARRAIGE FOR ARJYAMA ",
    saID: "SPR5589",
  },
  {
    firstName: "PABITRA",
    lastName: "DAS",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "PABITRA DAS",
    saID: "SPR5590",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "MUKHARJEE",
    address: "VILL-BAHARA*PO-SATPALSA*BIRBHUM 731234",
    fullName: "SWAPAN KUMAR MUKHARJEE",
    saID: "SPR5591",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5592",
  },
  {
    firstName: "HARIBAHADUR",
    lastName: "JANA",
    address: "",
    fullName: "HARIBAHADUR JANA",
    saID: "SPR5593",
  },
  {
    firstName: "BIRMAN",
    lastName: "THAPA",
    address: "",
    fullName: "BIRMAN THAPA",
    saID: "SPR5594",
  },
  {
    firstName: "D N",
    lastName: "SARMA",
    address: "",
    fullName: "D N SARMA",
    saID: "SPR5595",
  },
  {
    firstName: "L B",
    lastName: "HALDAR",
    address: "",
    fullName: "L B HALDAR",
    saID: "SPR5596",
  },
  {
    firstName: "AKHSYA",
    lastName: "MISHRA",
    address: "",
    fullName: "AKHSYA MISHRA",
    saID: "SPR5597",
  },
  {
    firstName: "RAS RANJAN",
    lastName: "SEN",
    address: "PO&VILL-MARJAT KANDI*DT-KARIMGANJ-788701 (ASSAM)*PAN-CCXPS3778J",
    fullName: "RAS RANJAN SEN",
    saID: "SPR5598",
  },
  {
    firstName: "SANJOY",
    lastName: "SARKAR",
    address: "",
    fullName: "SANJOY SARKAR",
    saID: "SPR5599",
  },
  {
    firstName: "SAJOY",
    lastName: "SARKAR",
    address: "",
    fullName: "SAJOY SARKAR",
    saID: "SPR5600",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5601",
  },
  {
    firstName: "SUBODH CHANDRA",
    lastName: "NAG",
    address: "VILL-TENGENMARI*PO-RAJERHAT*COOCHBIHAR 736101",
    fullName: "SUBODH CHANDRA NAG",
    saID: "SPR5602",
  },
  {
    firstName: "KARUNASINDHU",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "KARUNASINDHU MUKHERJEE",
    saID: "SPR5603",
  },
  {
    firstName: "DR B",
    lastName: "MAHAPATRA",
    address: "",
    fullName: "DR B MAHAPATRA",
    saID: "SPR5604",
  },
  {
    firstName: "ARKYADYUTI",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "ARKYADYUTI CHAKRAVORTY",
    saID: "SPR5605",
  },
  {
    firstName: "PHANI BHUSAN",
    lastName: "PHATAK",
    address: "",
    fullName: "PHANI BHUSAN PHATAK",
    saID: "SPR5606",
  },
  {
    firstName: "MIHIR",
    lastName: "BAIRAGI",
    address: "SURYA CLINIC,SUBASHGRAM*DIGLIPUR*NORTH ANDAMAN 744 202.",
    fullName: "MIHIR BAIRAGI",
    saID: "SPR5607",
  },
  {
    firstName: "BHABAGRAHI",
    lastName: "SWAIN",
    address: "AT-POPARADA*PO-NAYABAZAR*CUTTACK 753004",
    fullName: "BHABAGRAHI SWAIN",
    saID: "SPR5608",
  },
  {
    firstName: "PUJANIYA CHOTOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHOTOBAUDI ",
    saID: "SPR5609",
  },
  {
    firstName: "TRILOCHAN",
    lastName: "BEHURA",
    address: "AT/PO-NAUGAON*JAGATSINGHPUR 754113",
    fullName: "TRILOCHAN BEHURA",
    saID: "SPR5610",
  },
  {
    firstName: "SOUMEN",
    lastName: "DASGUPTA",
    address: "31 A SHAKTINAGAR*NIWARU ROAD*JHOTWARA*JAIPUR-302012*RAJASTHAN",
    fullName: "SOUMEN DASGUPTA",
    saID: "SPR5611",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5612",
  },
  {
    firstName: "R S",
    lastName: "MISHRA",
    address: "",
    fullName: "R S MISHRA",
    saID: "SPR5613",
  },
  {
    firstName: "SATYABRATA",
    lastName: "SAHOO",
    address:
      "SATSANG VIHAR,CHENNAI*PLOT NO-2,19TH STREET*SRISANKAR NAGAR,PAMMAL*CHENNAI 600075",
    fullName: "SATYABRATA SAHOO",
    saID: "SPR5614",
  },
  {
    firstName: "REV AMITRAJIT",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV AMITRAJIT CHAKRABORTY",
    saID: "SPR5615",
  },
  {
    firstName: "ANANTA",
    lastName: "BEHERA",
    address: "C-82,NEW ASHOK NAGAR*DELHI-110096",
    fullName: "ANANTA BEHERA",
    saID: "SPR5616",
  },
  {
    firstName: "ANINDYADYUTI",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "ANINDYADYUTI CHAKRAVORTY",
    saID: "SPR5617",
  },
  {
    firstName: "SURABALA",
    lastName: "KALITA",
    address: "",
    fullName: "SURABALA KALITA",
    saID: "SPR5618",
  },
  {
    firstName: "SHIB KALI",
    lastName: "SAHA",
    address: "",
    fullName: "SHIB KALI SAHA",
    saID: "SPR5619",
  },
  {
    firstName: "PUJANIYA BAROBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BAROBOUDI ",
    saID: "SPR5620",
  },
  {
    firstName: "PUJANIYA BAROBOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BAROBOURANI ",
    saID: "SPR5621",
  },
  {
    firstName: "DURGA PADA",
    lastName: "GHOSH",
    address: "",
    fullName: "DURGA PADA GHOSH",
    saID: "SPR5622",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5623",
  },
  {
    firstName: "SANTI KUMAR",
    lastName: "SAHA",
    address: "",
    fullName: "SANTI KUMAR SAHA",
    saID: "SPR5624",
  },
  {
    firstName: "PUJANIYA PISIMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA PISIMA ",
    saID: "SPR5625",
  },
  {
    firstName: "ARKHADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKHADYUTI CHAKRABORTY",
    saID: "SPR5626",
  },
  {
    firstName: "PUJANIYA MEJOBAURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MEJOBAURANI ",
    saID: "SPR5627",
  },
  {
    firstName: "PUJANIYA SEJOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA SEJOBAUDI ",
    saID: "SPR5628",
  },
  {
    firstName: "PUJANIYA NUABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA NUABAUDI ",
    saID: "SPR5629",
  },
  {
    firstName: "PUJANIA NUTANBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA NUTANBAUDI ",
    saID: "SPR5630",
  },
  {
    firstName: "CAR A/C A T JOARDAR",
    lastName: "",
    address: "",
    fullName: "CAR A/C A T JOARDAR ",
    saID: "SPR5631",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "SRIVASTAB",
    address: "NEAR HANUMAN MANDIR*SASTRINAGAR*SIWAN-841226*PAN-ANDPS0719C",
    fullName: "KRISHNA CHANDRA SRIVASTAB",
    saID: "SPR5632",
  },
  {
    firstName: "PARIMAL",
    lastName: "ROY",
    address: "M M C ROAD*KARIMGANJ*ASSAM 788710",
    fullName: "PARIMAL ROY",
    saID: "SPR5633",
  },
  {
    firstName: "REV KARTAMA",
    lastName: "",
    address: "",
    fullName: "REV KARTAMA ",
    saID: "SPR5634",
  },
  {
    firstName: "REV KARTA",
    lastName: "",
    address: "",
    fullName: "REV KARTA ",
    saID: "SPR5635",
  },
  {
    firstName: "AMBARISH",
    lastName: "BRAHMA",
    address: "",
    fullName: "AMBARISH BRAHMA",
    saID: "SPR5636",
  },
  {
    firstName: "BIJOY KUMAR",
    lastName: "BISWAL",
    address:
      "AT-SATSANG VIHAR,SAMBALPUR*PO-DHANKAUDA*DIST-SAMBALPUR*PAN-BJRPB8926K",
    fullName: "BIJOY KUMAR BISWAL",
    saID: "SPR5637",
  },
  {
    firstName: "BISHNU CHARAN",
    lastName: "SAMAL",
    address: "AT-BARANSO*PO-RASULPUR*CUTTACK 755035",
    fullName: "BISHNU CHARAN SAMAL",
    saID: "SPR5638",
  },
  {
    firstName: "BARABOURANI",
    lastName: "",
    address: "",
    fullName: "BARABOURANI ",
    saID: "SPR5639",
  },
  {
    firstName: "REV BARAMA",
    lastName: "",
    address: "",
    fullName: "REV BARAMA ",
    saID: "SPR5640",
  },
  {
    firstName: "BANABIHARI",
    lastName: "DAS",
    address: "AT&PO-PUTINA*BALASORE 756035",
    fullName: "BANABIHARI DAS",
    saID: "SPR5641",
  },
  {
    firstName: "PUJANIYA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BARABOUDI ",
    saID: "SPR5642",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5643",
  },
  {
    firstName: "PUJANIYA BARABAURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BARABAURANI ",
    saID: "SPR5644",
  },
  {
    firstName: "SRI SRI BARAMA",
    lastName: "",
    address: "",
    fullName: "SRI SRI BARAMA ",
    saID: "SPR5645",
  },
  {
    firstName: "KASHINATH",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "KASHINATH CHAKRABORTY",
    saID: "SPR5646",
  },
  {
    firstName: "REV ALAKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALAKE CHAKRABORTY",
    saID: "SPR5647",
  },
  {
    firstName: "SANKAR  KUMAR",
    lastName: "PAL",
    address: "VILL-ALUTIA*PO-GUSHKARA*BURDWAN-713128*PAN-AEUPP 0297E",
    fullName: "SANKAR  KUMAR PAL",
    saID: "SPR5648",
  },
  {
    firstName: "PANDAB CHARAN",
    lastName: "PRADHAN",
    address:
      "C.H.S.E.*C/2,SAMANTAPUR*CHANDRASEKHARPUR*BHUBANESWAR-751016*PAN-BRWPP8641Q",
    fullName: "PANDAB CHARAN PRADHAN",
    saID: "SPR5649",
  },
  {
    firstName: "SUDHANAND",
    lastName: "PRASAD",
    address:
      "A-404 MONTVERT GRANDE*PASHAN SUTARAWADI ROAD*PASHAN*PUNE*PAN AAIPP6527B",
    fullName: "SUDHANAND PRASAD",
    saID: "SPR5650",
  },
  {
    firstName: "TRILOCHAN",
    lastName: "DAS",
    address: "AT/PO-KHANKHIRA*VIA-GONDIAPATNA*DIST-DHENKANAL-759016",
    fullName: "TRILOCHAN DAS",
    saID: "SPR5651",
  },
  {
    firstName: "SHYAMAL KUMAR",
    lastName: "SAMANTA",
    address: "",
    fullName: "SHYAMAL KUMAR SAMANTA",
    saID: "SPR5652",
  },
  {
    firstName: "HARI",
    lastName: "BAHADUR",
    address: "",
    fullName: "HARI BAHADUR",
    saID: "SPR5653",
  },
  {
    firstName: "PHOTO",
    lastName: "",
    address: "",
    fullName: "PHOTO ",
    saID: "SPR5654",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5655",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5656",
  },
  {
    firstName: "AMRITAJIT",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AMRITAJIT CHAKRABORTY",
    saID: "SPR5657",
  },
  {
    firstName: "NANI",
    lastName: "BHAGAT",
    address: "",
    fullName: "NANI BHAGAT",
    saID: "SPR5658",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5659",
  },
  {
    firstName: "SAMIR RANJAN",
    lastName: "BEHERA",
    address: "RAMKRISHNANAGAR*PO/DIST-RAYAGADA - 765001*ODISHA",
    fullName: "SAMIR RANJAN BEHERA",
    saID: "SPR5660",
  },
  {
    firstName: "DEBARAJ",
    lastName: "SAHU",
    address: "AT/PO-PURUNA CUTTACK-762013*DIST-BOUDH*ORISSA",
    fullName: "DEBARAJ SAHU",
    saID: "SPR5661",
  },
  {
    firstName: "PUJANIYA CHHOTOMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHHOTOMA ",
    saID: "SPR5662",
  },
  {
    firstName: "MANGAL CHANDRA",
    lastName: "DEY",
    address: "",
    fullName: "MANGAL CHANDRA DEY",
    saID: "SPR5663",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5664",
  },
  {
    firstName: "ARKHYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKHYADYUTI CHAKRABORTY",
    saID: "SPR5665",
  },
  {
    firstName: "NABAKISHORE",
    lastName: "PATI",
    address:
      "AT-PANCHUREKHI*PO-FATEPUR*VIA-ASURALI*BHADRAK-756137*PAN-AZCPP8745Q",
    fullName: "NABAKISHORE PATI",
    saID: "SPR5666",
  },
  {
    firstName: "RAMANI MOHAN",
    lastName: "SARKAR",
    address: "",
    fullName: "RAMANI MOHAN SARKAR",
    saID: "SPR5667",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "KUMAR",
    address: "",
    fullName: "JITENDRA NATH KUMAR",
    saID: "SPR5668",
  },
  {
    firstName: "SIBA PRASAD",
    lastName: "GHOSH",
    address: "",
    fullName: "SIBA PRASAD GHOSH",
    saID: "SPR5669",
  },
  {
    firstName: "MANOJ PRASAD",
    lastName: "JAISWAL",
    address: "DHRITI CONSTRUCTION*AZAD BASTI*PO-BASTI*PO-JODA*KEONJHAR-758034",
    fullName: "MANOJ PRASAD JAISWAL",
    saID: "SPR5670",
  },
  {
    firstName: "WIFE OF DINADA",
    lastName: "",
    address: "",
    fullName: "WIFE OF DINADA ",
    saID: "SPR5671",
  },
  {
    firstName: "KEDAR NATH",
    lastName: "ROY",
    address:
      "RAILWAY COLONY WEST.WARD NO 27*SARDANAGAR SAHARSA*PO&DIST-SAHARSA-852201*BIHAR",
    fullName: "KEDAR NATH ROY",
    saID: "SPR5672",
  },
  {
    firstName: "PUJANIYA GITA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA GITA CHAKRABORTY",
    saID: "SPR5673",
  },
  {
    firstName: "PUJANIYA NANDINI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA NANDINI CHAKRABORTY",
    saID: "SPR5674",
  },
  {
    firstName: "PUJANIYA SIBANI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SIBANI CHAKRABORTY",
    saID: "SPR5675",
  },
  {
    firstName: "RAMPRAKASH",
    lastName: "SINGH",
    address: "AT-PIPRABISO*PO-RAJEPUR*MUZAFFARPUR-843111",
    fullName: "RAMPRAKASH SINGH",
    saID: "SPR5676",
  },
  {
    firstName: "A/C HARIDWAR S CENTR",
    lastName: "",
    address: "",
    fullName: "A/C HARIDWAR S CENTR ",
    saID: "SPR5677",
  },
  {
    firstName: "MANINDRA LAL",
    lastName: "SADHUKHAN",
    address: "",
    fullName: "MANINDRA LAL SADHUKHAN",
    saID: "SPR5678",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "JENA",
    address: "AT-KUMARI*PO-JARAKA*DIST-JAJPUR-755050*ODISHA.",
    fullName: "RAMESH CHANDRA JENA",
    saID: "SPR5679",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "ROUT",
    address:
      "I.I.M.LIBRARY,I.I.M.LUCKNOW*AT/PO-PRABHANDA NAGAR*SITAPUR ROAD*LUCKNOW-226013*U.P.",
    fullName: "TAPAS KUMAR ROUT",
    saID: "SPR5680",
  },
  {
    firstName: "DHANANJAY CHANDRA",
    lastName: "CHAND",
    address:
      "PO&VILL-ADARDIH*VIA-CHANDIL*DIST-SERAIKELA KHARSAWAN-832401*PAN-AAXPC1531H",
    fullName: "DHANANJAY CHANDRA CHAND",
    saID: "SPR5681",
  },
  {
    firstName: "BATA KRISHNA",
    lastName: "DAKUA",
    address: "AT/PO-SANTAPUR*KALAHANDI 766001",
    fullName: "BATA KRISHNA DAKUA",
    saID: "SPR5682",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5683",
  },
  {
    firstName: "SUBRATA",
    lastName: "MOULIK",
    address:
      "C/O RAJIV RANJAN PANDEY*7,OLD BANK COLONY*ADAMPUR*BHAGALPUR-812001*PAN-AFMPM6141B",
    fullName: "SUBRATA MOULIK",
    saID: "SPR5684",
  },
  {
    firstName: "BAHADUR PRASAD",
    lastName: "RAI",
    address:
      "C-56/N H-2;N T P C VINDHYACHAL*PO-VINDHYANAGAR*SINGRAULI*M P 486885*PAN.ABGPR5776H",
    fullName: "BAHADUR PRASAD RAI",
    saID: "SPR5685",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "DHARA",
    address: "24/7,P.K.ROY CHOWDHURY LANE*PO-B.GARDEN*HOWRAH 711103",
    fullName: "MADHUSUDAN DHARA",
    saID: "SPR5686",
  },
  {
    firstName: "LATE NIRMAL",
    lastName: "SARKAR",
    address: "RAINAGAR(HOSPITAL MORE)*PO-HILI*DAKSHIN DINAJPUR 733126",
    fullName: "LATE NIRMAL SARKAR",
    saID: "SPR5687",
  },
  {
    firstName: "BIREN",
    lastName: "GUHA",
    address: "",
    fullName: "BIREN GUHA",
    saID: "SPR5688",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR5689",
  },
  {
    firstName: "SMARAN",
    lastName: "DASGUPTA",
    address: "SATSANG*DEOGHAR",
    fullName: "SMARAN DASGUPTA",
    saID: "SPR5690",
  },
  {
    firstName: "SAMSUNDAR",
    lastName: "SARKAR",
    address: "",
    fullName: "SAMSUNDAR SARKAR",
    saID: "SPR5691",
  },
  {
    firstName: "MUKTIKANTA",
    lastName: "NAYAK",
    address:
      "C/O GANGADHAR NAYAK*AT-BARIPADA SPINNG MILL*KATHAPOL*PO-BARIPADA*MAYURBHANJ 757001",
    fullName: "MUKTIKANTA NAYAK",
    saID: "SPR5692",
  },
  {
    firstName: "BHUPEN",
    lastName: "SARKAR",
    address: "",
    fullName: "BHUPEN SARKAR",
    saID: "SPR5693",
  },
  {
    firstName: "HEMKANTA",
    lastName: "GOHAIN",
    address: "",
    fullName: "HEMKANTA GOHAIN",
    saID: "SPR5694",
  },
  {
    firstName: "DHIRENDRA  KUMAR",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA  KUMAR BISWAS",
    saID: "SPR5695",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA KUMAR BISWAS",
    saID: "SPR5696",
  },
  {
    firstName: "AMAL",
    lastName: "CHAKRABARTY",
    address:
      "ADVOCATE,DEOGHAR COURT*KALYANKUTIR*PO-SATSANG 814 116*PAN-AVEPC3813G",
    fullName: "AMAL CHAKRABARTY",
    saID: "SPR5697",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5698",
  },
  {
    firstName: "REV KARTAMA",
    lastName: "",
    address: "",
    fullName: "REV KARTAMA ",
    saID: "SPR5699",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "BHATTACHARJEE",
    address: "AT-JAGADISHBATI*PO-RAMPUR*DAKSHIN DINAJPUR-733140",
    fullName: "TAPAN KUMAR BHATTACHARJEE",
    saID: "SPR5700",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "DUTTA",
    address:
      "121/1,NILMANI BHATTACHARJEE LANE*PO-BERHAMPORE*DT-MURSHIDABAD 742101*PAN-ANEPD6121G",
    fullName: "ASHOK KUMAR DUTTA",
    saID: "SPR5701",
  },
  {
    firstName: "PRATUL CHANDRA",
    lastName: "SINGHA",
    address:
      "C/O PANCHPOTA SATSANG VIHAR *VILL&PO-PANCHPOTA*24PARGANAS NORTH-743273",
    fullName: "PRATUL CHANDRA SINGHA",
    saID: "SPR5702",
  },
  {
    firstName: "PRITI MOHAN",
    lastName: "DAS",
    address:
      "C/O P K DAS*KANDARPAPUR BOALIA*PO-GARIA*24PARGANAS (SOUTH)  700084",
    fullName: "PRITI MOHAN DAS",
    saID: "SPR5703",
  },
  {
    firstName: "BALARAM",
    lastName: "DAS",
    address: "C/O-N C DAS*GANDHI NAGAR*PO-PANIHATI*24PARGANAS (NORTH)",
    fullName: "BALARAM DAS",
    saID: "SPR5704",
  },
  {
    firstName: "GOUR KRISHNA",
    lastName: "NAYAK",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "GOUR KRISHNA NAYAK",
    saID: "SPR5705",
  },
  {
    firstName: "PUJANIYA KATTA MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA KATTA MAA ",
    saID: "SPR5706",
  },
  {
    firstName: "BIRDYUTI",
    lastName: "PAL",
    address: "AT&PO-ARANGHATA*NADIA-741501*PAN NO.AEKPP0141H",
    fullName: "BIRDYUTI PAL",
    saID: "SPR5707",
  },
  {
    firstName: "BHASKAR",
    lastName: "GHOSH",
    address: "AT-RAMAPUR*PO-HASNABAD RAMAPUR*24PARGANAS (NORTH)-743439",
    fullName: "BHASKAR GHOSH",
    saID: "SPR5708",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SARKAR",
    address: "VILL-MALLIKPUR*PO-BAUL*DAKSHIN DINAJPUR",
    fullName: "ARUN KUMAR SARKAR",
    saID: "SPR5709",
  },
  {
    firstName: "SOUREN PRASAD",
    lastName: "SINGHAROY",
    address: "",
    fullName: "SOUREN PRASAD SINGHAROY",
    saID: "SPR5710",
  },
  {
    firstName: "NAGENDRA KUMAR",
    lastName: "MOHANTY",
    address: "AT-JASUAPUR*PO-SATSANKHA*PURI 752046",
    fullName: "NAGENDRA KUMAR MOHANTY",
    saID: "SPR5711",
  },
  {
    firstName: "PRADIP",
    lastName: "ROY",
    address: "",
    fullName: "PRADIP ROY",
    saID: "SPR5712",
  },
  {
    firstName: "SUREN",
    lastName: "BISWAS",
    address: "",
    fullName: "SUREN BISWAS",
    saID: "SPR5713",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5714",
  },
  {
    firstName: "BIPINCHANDRA",
    lastName: "BORO",
    address: "",
    fullName: "BIPINCHANDRA BORO",
    saID: "SPR5715",
  },
  {
    firstName: "REV`",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV` CHAKRABORTY",
    saID: "SPR5716",
  },
  {
    firstName: "SUDHANSU",
    lastName: "MAHATO",
    address: "SATSANG COMPUTER CENTRE*SATSANG 814 116",
    fullName: "SUDHANSU MAHATO",
    saID: "SPR5717",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRABORTY",
    saID: "SPR5718",
  },
  {
    firstName: "KHAGENDRA",
    lastName: "MONDAL",
    address: "",
    fullName: "KHAGENDRA MONDAL",
    saID: "SPR5719",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5720",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "ADHIKARY",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE NARESH CHANDRA ADHIKARY",
    saID: "SPR5721",
  },
  {
    firstName: "GOBARDHAN",
    lastName: "PARIDA",
    address: "",
    fullName: "GOBARDHAN PARIDA",
    saID: "SPR5722",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "SAHOO",
    address:
      "JUNIOR ENGINEER*LIFT IRRIGATION*AT/PO-GHASIPUT*VIA-BANKI*CUTTACK-754008",
    fullName: "DILIP KUMAR SAHOO",
    saID: "SPR5723",
  },
  {
    firstName: "NITYANANDA",
    lastName: "MANDAL",
    address: "",
    fullName: "NITYANANDA MANDAL",
    saID: "SPR5724",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "ROY",
    address:
      "C3L-107 GOPABANDHUNAGAR*RDA COLONY,CHHEND*ROURKELA-769015*SUNDARGARH*PAN-AEKPR0206D",
    fullName: "ASHOK KUMAR ROY",
    saID: "SPR5725",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "MISHRA",
    address: "AT/PO-SAMAL*TALCHER*ANGUL*ORISSA 759037*PAN-ARWPM8578N",
    fullName: "CHITTARANJAN MISHRA",
    saID: "SPR5726",
  },
  {
    firstName: "TUPAI BABU",
    lastName: "",
    address: "",
    fullName: "TUPAI BABU ",
    saID: "SPR5727",
  },
  {
    firstName: "HEMANT B",
    lastName: "PATEL",
    address: '"SWASTIKA"*9, SUNDARAM PARK*JITODIA ROAD, ANAND-388001.',
    fullName: "HEMANT B PATEL",
    saID: "SPR5728",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "NAYAK",
    address: "",
    fullName: "RAMAKANTA NAYAK",
    saID: "SPR5729",
  },
  {
    firstName: "KAMAL MOHAN",
    lastName: "PRASAD",
    address:
      "407 PRINCE APARTMENT*MAGISTRATE COLONY*PATNA 800025*PAN-NO.BLIPM7872B",
    fullName: "KAMAL MOHAN PRASAD",
    saID: "SPR5730",
  },
  {
    firstName: "SANTI",
    lastName: "ADHIKARY",
    address: "",
    fullName: "SANTI ADHIKARY",
    saID: "SPR5731",
  },
  {
    firstName: "IRIBAHADUR",
    lastName: "RANA",
    address: "",
    fullName: "IRIBAHADUR RANA",
    saID: "SPR5732",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "MISHRA",
    address: "QRS NO-F/17,NEAR T B WARD*AT/PO-BURLA*SAMBALPUR 768017",
    fullName: "KAILASH CHANDRA MISHRA",
    saID: "SPR5733",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "SUREN",
    address: "",
    fullName: "RAM CHANDRA SUREN",
    saID: "SPR5734",
  },
  {
    firstName: "SUNIL",
    lastName: "PANDEY",
    address: "",
    fullName: "SUNIL PANDEY",
    saID: "SPR5735",
  },
  {
    firstName: "RATAN",
    lastName: "SARKAR",
    address: "71,RADHA GOBINDA NAGAR ROAD*HINDMOTOR*HOOGHLY",
    fullName: "RATAN SARKAR",
    saID: "SPR5736",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR5737",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5738",
  },
  {
    firstName: "SRI PRADIP",
    lastName: "ROY",
    address: "",
    fullName: "SRI PRADIP ROY",
    saID: "SPR5739",
  },
  {
    firstName: "SUDHANSU KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "SUDHANSU KUMAR DAS",
    saID: "SPR5740",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "BHUKTA",
    address: "",
    fullName: "DHIRENDRA NATH BHUKTA",
    saID: "SPR5741",
  },
  {
    firstName: "DR HEMANTA",
    lastName: "HALDAR",
    address: "",
    fullName: "DR HEMANTA HALDAR",
    saID: "SPR5742",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABARTY",
    saID: "SPR5743",
  },
  {
    firstName: "GOURI PRASAD",
    lastName: "BHATTACHERJEE",
    address: "SATSANG*DEOGHAR 814116",
    fullName: "GOURI PRASAD BHATTACHERJEE",
    saID: "SPR5744",
  },
  {
    firstName: "NARESWAR",
    lastName: "BARMAN",
    address:
      "VILL-MITHAM*PO-DHEKIAJULI*DIST-SONITPUR-784110*PAN-AJPPB9322A*ASSAM",
    fullName: "NARESWAR BARMAN",
    saID: "SPR5745",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR5746",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR5747",
  },
  {
    firstName: "ADHIKARI",
    lastName: "RANA",
    address: "",
    fullName: "ADHIKARI RANA",
    saID: "SPR5748",
  },
  {
    firstName: "NAGEN",
    lastName: "DAS",
    address: "",
    fullName: "NAGEN DAS",
    saID: "SPR5749",
  },
  {
    firstName: "JAGAT BANDHU",
    lastName: "SAHU",
    address: "AT-RAJU STREET*PO/DIST-NABARANGPUR 764059",
    fullName: "JAGAT BANDHU SAHU",
    saID: "SPR5750",
  },
  {
    firstName: "RANJIT",
    lastName: "KUMAR",
    address: "LAKSHMI NARAYAN NAGAR*PO-R K ASHRAM,BELA*MUZAFFARPUR 843116",
    fullName: "RANJIT KUMAR",
    saID: "SPR5751",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "",
    address: "",
    fullName: "REV ALOK KUMAR ",
    saID: "SPR5752",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR5753",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR5754",
  },
  {
    firstName: "KRISHNAGOPAL",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "KRISHNAGOPAL CHOUDHURY",
    saID: "SPR5755",
  },
  {
    firstName: "C K",
    lastName: "BISWAL",
    address: "",
    fullName: "C K BISWAL",
    saID: "SPR5756",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5757",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK CHAKRABARTY",
    saID: "SPR5758",
  },
  {
    firstName: "TUPAI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "TUPAI CHAKRABORTY",
    saID: "SPR5759",
  },
  {
    firstName: "ABIT",
    lastName: "KAR",
    address: "",
    fullName: "ABIT KAR",
    saID: "SPR5760",
  },
  {
    firstName: "UPANAYAN",
    lastName: "",
    address: "",
    fullName: "UPANAYAN ",
    saID: "SPR5761",
  },
  {
    firstName: "SASHI BHUSAN",
    lastName: "PATI",
    address: "",
    fullName: "SASHI BHUSAN PATI",
    saID: "SPR5762",
  },
  {
    firstName: "ISHIPRASAD",
    lastName: "DEY",
    address: "",
    fullName: "ISHIPRASAD DEY",
    saID: "SPR5763",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "BAR",
    address: "",
    fullName: "SURENDRA NATH BAR",
    saID: "SPR5764",
  },
  {
    firstName: "REV BODABAUDI",
    lastName: "",
    address: "",
    fullName: "REV BODABAUDI ",
    saID: "SPR5765",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5766",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "MANDAL",
    address: "VILL-KAMARDA*PO-KAMARDA BAZAR*PURBA MEDINIPUR 721432",
    fullName: "PRASANTA KUMAR MANDAL",
    saID: "SPR5767",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA BISWAS",
    saID: "SPR5768",
  },
  {
    firstName: "NIRAN",
    lastName: "CHOWDHURY",
    address: "",
    fullName: "NIRAN CHOWDHURY",
    saID: "SPR5769",
  },
  {
    firstName: "BANAMBAR",
    lastName: "SAHOO",
    address: "",
    fullName: "BANAMBAR SAHOO",
    saID: "SPR5770",
  },
  {
    firstName: "REV TUPAI BABU",
    lastName: "",
    address: "",
    fullName: "REV TUPAI BABU ",
    saID: "SPR5771",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5772",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5773",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5774",
  },
  {
    firstName: "KALYANI",
    lastName: "MAITRA",
    address: "",
    fullName: "KALYANI MAITRA",
    saID: "SPR5775",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5776",
  },
  {
    firstName: "BIRENDRA KUMAR",
    lastName: "BISWAS",
    address: "",
    fullName: "BIRENDRA KUMAR BISWAS",
    saID: "SPR5777",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "BRAHMA",
    address: "",
    fullName: "PRASANTA KUMAR BRAHMA",
    saID: "SPR5778",
  },
  {
    firstName: "KRISHNA  LAL",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "KRISHNA  LAL CHATTERJEE",
    saID: "SPR5779",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5780",
  },
  {
    firstName: "BHUDHAR CHANDRA",
    lastName: "MANDAL",
    address: "",
    fullName: "BHUDHAR CHANDRA MANDAL",
    saID: "SPR5781",
  },
  {
    firstName: "DEBENDRA BIJOY",
    lastName: "ACHARJEE",
    address: "VILL-LAKSHMIPUR*PO-HILARA*CACHAR-788808*PAN-AXHPA4895R",
    fullName: "DEBENDRA BIJOY ACHARJEE",
    saID: "SPR5782",
  },
  {
    firstName: "DWIPNATH",
    lastName: "SARMA",
    address: "",
    fullName: "DWIPNATH SARMA",
    saID: "SPR5783",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5784",
  },
  {
    firstName: "KALYAN KUMAR",
    lastName: "NANDY",
    address:
      "C/O-CHENNAI SATSANG VIHAR*2 S V ROAD,SHANKARNAGAR*PAMMAL*CHENNAI-75*PAN AADPN0929N",
    fullName: "KALYAN KUMAR NANDY",
    saID: "SPR5785",
  },
  {
    firstName: "DR.SRIKRISHNAGOPAL",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "DR.SRIKRISHNAGOPAL CHOUDHURY",
    saID: "SPR5786",
  },
  {
    firstName: "BYONKESH",
    lastName: "GHOSH",
    address: "",
    fullName: "BYONKESH GHOSH",
    saID: "SPR5787",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5788",
  },
  {
    firstName: "PUJANIA SIBANI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIA SIBANI CHAKRABORTY",
    saID: "SPR5789",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "GHOSH",
    address: "COMPUTER CENTRE,SATSANG*SATSANG  814116.",
    fullName: "RABINDRA NATH GHOSH",
    saID: "SPR5790",
  },
  {
    firstName: "NIRANJAN",
    lastName: "DAS",
    address: "AT-BABALPUR*PO-NANDIPUR*VIA-DASARATHPUR*JAJPUR",
    fullName: "NIRANJAN DAS",
    saID: "SPR5791",
  },
  {
    firstName: "NITYANANDA",
    lastName: "NAYAK",
    address: "AT-SATSANG VIHAR,VYASANAGAR*PO-JAJPUR ROAD*JAJPUR 755019*ORISSA",
    fullName: "NITYANANDA NAYAK",
    saID: "SPR5792",
  },
  {
    firstName: "SATIPRASAD",
    lastName: "DALAI",
    address: "",
    fullName: "SATIPRASAD DALAI",
    saID: "SPR5793",
  },
  {
    firstName: "SHYAMSUNDAR",
    lastName: "DEY",
    address: "PO-LALABAZAR*HAILAKANDI*CACHAR",
    fullName: "SHYAMSUNDAR DEY",
    saID: "SPR5794",
  },
  {
    firstName: "BASANT KUMAR",
    lastName: "SAHU",
    address: "AT&PO-BAHABALPUR*VIA-HALDIPADA*BALASORE 756027",
    fullName: "BASANT KUMAR SAHU",
    saID: "SPR5795",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR5796",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5797",
  },
  {
    firstName: "G C",
    lastName: "BISWAS",
    address: "",
    fullName: "G C BISWAS",
    saID: "SPR5798",
  },
  {
    firstName: "RE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RE CHAKRABORTY",
    saID: "SPR5799",
  },
  {
    firstName: "LATE GANENDRA NATH",
    lastName: "CHOUDHURY",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE GANENDRA NATH CHOUDHURY",
    saID: "SPR5800",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "SAHA",
    address: "ASHOKGARH,BADU ROAD*MADHYAMGRAM*KOLKATA 155*PAN-ANMPS1826K.",
    fullName: "SANJIB KUMAR SAHA",
    saID: "SPR5801",
  },
  {
    firstName: "LATE ASHOK",
    lastName: "KURMI",
    address: "OIL INDIA LTD MORAN*CIVIL ENGG DEPTT*SIBSAGAR 785669*ASSAM",
    fullName: "LATE ASHOK KURMI",
    saID: "SPR5802",
  },
  {
    firstName: "REV  ANIRUDDHA",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV  ANIRUDDHA CHAKRAVORTY",
    saID: "SPR5803",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5804",
  },
  {
    firstName: "HIREN",
    lastName: "CHAKRABORTY",
    address: "SATSANG VIHAR ABHAYAPURI*BONGAIGAON-783384*ASSAM",
    fullName: "HIREN CHAKRABORTY",
    saID: "SPR5805",
  },
  {
    firstName: "ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ASHOK CHAKRABORTY",
    saID: "SPR5806",
  },
  {
    firstName: "BIRANCHI NARAYAN",
    lastName: "SAMANTASINGHAR",
    address: "AT-RENGAL*PO-GODIPUT MATIAPADA*VIA-JATNI*PURI 752050",
    fullName: "BIRANCHI NARAYAN SAMANTASINGHAR",
    saID: "SPR5807",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5808",
  },
  {
    firstName: "HIMANSU SEKHAR",
    lastName: "MAHANTA",
    address: "QRS NO-E/79,SECTOR-07*ROURKELA-3*SUNDARGARH",
    fullName: "HIMANSU SEKHAR MAHANTA",
    saID: "SPR5809",
  },
  {
    firstName: "GOURI",
    lastName: "JANA",
    address: "",
    fullName: "GOURI JANA",
    saID: "SPR5810",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR5811",
  },
  {
    firstName: "LATE KASHISWAR",
    lastName: "DASSARMA",
    address: "ENGLISH BAZAR*MALDA",
    fullName: "LATE KASHISWAR DASSARMA",
    saID: "SPR5812",
  },
  {
    firstName: "SOVA RANI",
    lastName: "ROYCHATTERJEE",
    address: "",
    fullName: "SOVA RANI ROYCHATTERJEE",
    saID: "SPR5813",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MANDAL",
    address:
      "VILL-GOPALPUR*PO-GAIGHATA*24PARGANAS (NORTH)-743249*PAN-BHAPM1160M",
    fullName: "PANCHANAN MANDAL",
    saID: "SPR5814",
  },
  {
    firstName: "UPENDRA",
    lastName: "PODDAR",
    address: "AT-GARHARA BAJAR*PO-GARHARA*BEGUSARAI-851126*BIHAR",
    fullName: "UPENDRA PODDAR",
    saID: "SPR5815",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5816",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "NAIK",
    address:
      "PROP.SWASTIK ENGG.WORKES*AT&PO-BHODRASAHI*VIA-BARBIL*KEONJHAR-758035",
    fullName: "MADHUSUDAN NAIK",
    saID: "SPR5817",
  },
  {
    firstName: "JAGAJYOTI",
    lastName: "DAS",
    address: "L I C OF INDIA*KARIMGANJ BRANCH*PO-KARIMGANJ*KARIMGANJ  788710",
    fullName: "JAGAJYOTI DAS",
    saID: "SPR5818",
  },
  {
    firstName: "ABHIRAM",
    lastName: "JENA",
    address: "AT-JAGATSAHUPATNA*PO-KUNDAPATNA*JAJPUR*CUTTACK 755050",
    fullName: "ABHIRAM JENA",
    saID: "SPR5819",
  },
  {
    firstName: "REV ASHOKD",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOKD CHAKRAVARTY",
    saID: "SPR5820",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK CHAKRABORTY",
    saID: "SPR5821",
  },
  {
    firstName: "PUJANIYA NUTAN BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA NUTAN BOUDI ",
    saID: "SPR5822",
  },
  {
    firstName: "PUJANIA",
    lastName: "",
    address: "",
    fullName: "PUJANIA ",
    saID: "SPR5823",
  },
  {
    firstName: "SIBA CHARAN",
    lastName: "SWAIN",
    address:
      "QR NO.33218/3*AT&PO-ORDNANCE FACTORY,BADMAL*BOLANGIR 767070*PAN- BBOPS7452N",
    fullName: "SIBA CHARAN SWAIN",
    saID: "SPR5824",
  },
  {
    firstName: "SANJOY",
    lastName: "CHOUDHURY",
    address: "SWASTIPALLY,GANGPUR*PO-JOTERAM*DIST-BURDWAN-713104",
    fullName: "SANJOY CHOUDHURY",
    saID: "SPR5825",
  },
  {
    firstName: "MANAS",
    lastName: "MISHRA",
    address:
      "SUPDT ENGR(E & M)*C M P D I*SEEPAT ROAD*BILASPUR 594006*PAN-ACNPM4691E",
    fullName: "MANAS MISHRA",
    saID: "SPR5826",
  },
  {
    firstName: "KAMALESH",
    lastName: "BALA",
    address: "C/O RAMESH CH BALA*P.V.NO-6*PO-KAPSI*KANKER*C.G.-794771.",
    fullName: "KAMALESH BALA",
    saID: "SPR5827",
  },
  {
    firstName: "LATE HOMDEEP",
    lastName: "BALA",
    address:
      "C/O RAMESH CH BALA*SATYANANDA PALLY*PAKHANJORE*KANKER*CHHATISHGARH 494776",
    fullName: "LATE HOMDEEP BALA",
    saID: "SPR5828",
  },
  {
    firstName: "RANAJIT KUMAR",
    lastName: "ROY",
    address: "AT-BASANTIPUR*PO-GRAHAMPUR*KOKRAJHAR 783 333",
    fullName: "RANAJIT KUMAR ROY",
    saID: "SPR5829",
  },
  {
    firstName: "ANIMESH CHANDRA",
    lastName: "SARKAR",
    address: "VILL&PO-BOGRA*PURULIA 723145",
    fullName: "ANIMESH CHANDRA SARKAR",
    saID: "SPR5830",
  },
  {
    firstName: "L N",
    lastName: "MOHANTY",
    address: "",
    fullName: "L N MOHANTY",
    saID: "SPR5831",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5832",
  },
  {
    firstName: "BHUWAN MOHAN",
    lastName: "PODDER",
    address:
      "NAYACHAK LANE*PURABTOLA*MOZAHIDPUR*BHAGALPUR-812002*PAN-AEIPP2561B",
    fullName: "BHUWAN MOHAN PODDER",
    saID: "SPR5833",
  },
  {
    firstName: "I G",
    lastName: "NAIDU",
    address: "BRANCH POST MASTER*AT&PO-DONDILI*RAYAGADA 765017",
    fullName: "I G NAIDU",
    saID: "SPR5834",
  },
  {
    firstName: "TARUN KUMAR",
    lastName: "BRAHMA",
    address: "",
    fullName: "TARUN KUMAR BRAHMA",
    saID: "SPR5835",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5836",
  },
  {
    firstName: "AMIYANGSHU",
    lastName: "CHAKRABARTY",
    address: "*SATSANG",
    fullName: "AMIYANGSHU CHAKRABARTY",
    saID: "SPR5837",
  },
  {
    firstName: "SHYAM SUNDAR",
    lastName: "NAYAK",
    address: "AT-SHANTI NAGAR*PO-BUDHARAJA*SAMBALPUR 768004",
    fullName: "SHYAM SUNDAR NAYAK",
    saID: "SPR5838",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5839",
  },
  {
    firstName: "BIMAL KUMAR",
    lastName: "PANDIT",
    address: "AT-SAIPUR*PO-RENJURA*PASCHIM MEDINIPUR-721443",
    fullName: "BIMAL KUMAR PANDIT",
    saID: "SPR5840",
  },
  {
    firstName: "BIJITESH KUMAR",
    lastName: "ADHIKARY",
    address: "VILL-BICHANDAI*PO-BARACHARAIKHOLA*DHUBRI.",
    fullName: "BIJITESH KUMAR ADHIKARY",
    saID: "SPR5841",
  },
  {
    firstName: "DILIP",
    lastName: "URANG",
    address: "VILL-KENDUBAM(CHAKHURA BASTI)*PO-KHETRI*KAMRUP-782403*ASSAM",
    fullName: "DILIP URANG",
    saID: "SPR5842",
  },
  {
    firstName: "SITENDRA KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "SITENDRA KUMAR DAS",
    saID: "SPR5843",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5844",
  },
  {
    firstName: "SHILLONG",
    lastName: "MAJUMDAR",
    address: "VILL-BISWANATHPUR*PO-DEGANGA*NORTH 24 PARGANAS 743423",
    fullName: "SHILLONG MAJUMDAR",
    saID: "SPR5845",
  },
  {
    firstName: "CHANDRA MOHAN",
    lastName: "THAKUR",
    address: "OPP POST OFFICE*AT/PO-VIA-TRIBENIGANJ*DIST-SUPOUL 852139",
    fullName: "CHANDRA MOHAN THAKUR",
    saID: "SPR5846",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5847",
  },
  {
    firstName: "BARO BOUDI",
    lastName: "",
    address: "",
    fullName: "BARO BOUDI ",
    saID: "SPR5848",
  },
  {
    firstName: "SMT MILAN",
    lastName: "MUKHERJEE",
    address: "",
    fullName: "SMT MILAN MUKHERJEE",
    saID: "SPR5849",
  },
  {
    firstName: "LATE PRATIRAM",
    lastName: "MANDAL",
    address: "SAILYAKHALI*BANGALDESH",
    fullName: "LATE PRATIRAM MANDAL",
    saID: "SPR5850",
  },
  {
    firstName: "MOHAN CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "MOHAN CHANDRA DAS",
    saID: "SPR5851",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABARTY",
    saID: "SPR5852",
  },
  {
    firstName: "BIRU",
    lastName: "RAY",
    address: "",
    fullName: "BIRU RAY",
    saID: "SPR5853",
  },
  {
    firstName: "N C",
    lastName: "RAHAROY",
    address: "",
    fullName: "N C RAHAROY",
    saID: "SPR5854",
  },
  {
    firstName: "KALIDAS",
    lastName: "BAGCHI",
    address: "58/463 SASHTRINAGAR*NARAYAN PURA*AHMEDABAD*GUJRAT-380013",
    fullName: "KALIDAS BAGCHI",
    saID: "SPR5855",
  },
  {
    firstName: "PUJANIA BARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOURANI ",
    saID: "SPR5856",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA KUMAR BISWAS",
    saID: "SPR5857",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5858",
  },
  {
    firstName: "REV DR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR CHAKRABORTY",
    saID: "SPR5859",
  },
  {
    firstName: "RAJESH KUMAR",
    lastName: "KHANDELWAL",
    address:
      '"SWASTIDHAM"*MAHARANA SAHI OLD TOWN*BHUBANESWAR-751002*PAN-CJSPK4884P',
    fullName: "RAJESH KUMAR KHANDELWAL",
    saID: "SPR5860",
  },
  {
    firstName: "NANI",
    lastName: "BHADRA",
    address: "",
    fullName: "NANI BHADRA",
    saID: "SPR5861",
  },
  {
    firstName: "ABANI KUMAR",
    lastName: "PATRA",
    address:
      "PLOT NO-15,SECTOR-A,ZONE-B*MANCHESWAR IND ESTATE*BHUBANESWAR-751010*DIST-KHURDA",
    fullName: "ABANI KUMAR PATRA",
    saID: "SPR5862",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR5863",
  },
  {
    firstName: "LATE J",
    lastName: "YEME",
    address:
      "C/O-E A C'S OFFICE*PO-BHALUKPONG*WEST KAMENG*ARUNACHALPRADESH 790114",
    fullName: "LATE J YEME",
    saID: "SPR5864",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5865",
  },
  {
    firstName: "RANTU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RANTU CHAKRABORTY",
    saID: "SPR5866",
  },
  {
    firstName: "SHYAMANANDA",
    lastName: "MAJUMDAR",
    address: "SATSANG COAL-DEPOT*SATSANG  814116",
    fullName: "SHYAMANANDA MAJUMDAR",
    saID: "SPR5867",
  },
  {
    firstName: "RAJKUMAR",
    lastName: "BANERJEE",
    address: "SATSANG COMPUTER CENTER*SATSANG*DEOGHAR",
    fullName: "RAJKUMAR BANERJEE",
    saID: "SPR5868",
  },
  {
    firstName: "LATE BIRENDRA",
    lastName: "BISWAS",
    address: "B BISWAS&CO*M P ROAD,DIMAPUR*NAGALAND",
    fullName: "LATE BIRENDRA BISWAS",
    saID: "SPR5869",
  },
  {
    firstName: "BINTU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "BINTU CHAKRABORTY",
    saID: "SPR5870",
  },
  {
    firstName: "SUBODH",
    lastName: "JOSHI",
    address: "C/O D N JOSHI M L A*UPPER MAWPREMM*SHILLONG-793 002*MEGHALAYA",
    fullName: "SUBODH JOSHI",
    saID: "SPR5871",
  },
  {
    firstName: "MAHESH CHANDRA",
    lastName: "DEBSARMA",
    address: "VILL-JANBARI*PO-GOSANIMARI*COOCHBIHAR 736145",
    fullName: "MAHESH CHANDRA DEBSARMA",
    saID: "SPR5872",
  },
  {
    firstName: "LATE RASHBIHARI",
    lastName: "ADITYA",
    address: "BANGLADESH SATSANG ASHRAM*PAKUTIA*TANGAIL*BANGLADESH",
    fullName: "LATE RASHBIHARI ADITYA",
    saID: "SPR5873",
  },
  {
    firstName: "HARAKANTA",
    lastName: "BORA",
    address: "NAZIRA*SIBSAGAR-785685*ASSAM",
    fullName: "HARAKANTA BORA",
    saID: "SPR5874",
  },
  {
    firstName: "NATHSURYA",
    lastName: "GIRI",
    address: "",
    fullName: "NATHSURYA GIRI",
    saID: "SPR5875",
  },
  {
    firstName: "NANI GOPAL",
    lastName: "ROY",
    address: "",
    fullName: "NANI GOPAL ROY",
    saID: "SPR5876",
  },
  {
    firstName: "UTPAL",
    lastName: "SARMA",
    address: "VILL-SATRANG GURI*PO-GOHPUR*SONITPUR 784168.",
    fullName: "UTPAL SARMA",
    saID: "SPR5877",
  },
  {
    firstName: "GANESH PRASAD",
    lastName: "SONI",
    address:
      "NAVJEEVAN VIHAR*VINDHYA NAGAR*DIST-SINGRAULI*MADHYA PRADESH 486885*pan-AYXPS4553P",
    fullName: "GANESH PRASAD SONI",
    saID: "SPR5878",
  },
  {
    firstName: "REVASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVASHOK CHAKRABORTY",
    saID: "SPR5879",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5880",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5881",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5882",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "MANDAL",
    address: "VILL&PO-SUBHASHGRAM*24PARGANAS (SOUTH)-743362*PAN-AWUPM9665R",
    fullName: "RABINDRA NATH MANDAL",
    saID: "SPR5883",
  },
  {
    firstName: "PHANINDRA CHANDRA",
    lastName: "BHUIYAN",
    address: "",
    fullName: "PHANINDRA CHANDRA BHUIYAN",
    saID: "SPR5884",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK CHAKRABARTY",
    saID: "SPR5885",
  },
  {
    firstName: "REV AMAL",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV AMAL CHAKRABARTY",
    saID: "SPR5886",
  },
  {
    firstName: "PUJANIYA PISHI MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA PISHI MAA ",
    saID: "SPR5887",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA BISWAS",
    saID: "SPR5888",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "SHIL",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE KUMUD RANJAN SHIL",
    saID: "SPR5889",
  },
  {
    firstName: "DHRUJYOTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "DHRUJYOTI CHAKRABORTY",
    saID: "SPR5890",
  },
  {
    firstName: "PUNANIYA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUNANIYA RANGAMA ",
    saID: "SPR5891",
  },
  {
    firstName: "SANAT",
    lastName: "SAHA",
    address: "",
    fullName: "SANAT SAHA",
    saID: "SPR5892",
  },
  {
    firstName: "SELF ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "SELF ASHOK KUMAR CHAKRABORTY",
    saID: "SPR5893",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR5894",
  },
  {
    firstName: "BISWANATH",
    lastName: "SAIKIA",
    address: "AT-BARACHUBA*PO-HAZARIKAPARA*DARRANG 784145",
    fullName: "BISWANATH SAIKIA",
    saID: "SPR5895",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5896",
  },
  {
    firstName: "K",
    lastName: "MOHANTY",
    address: "",
    fullName: "K MOHANTY",
    saID: "SPR5897",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5898",
  },
  {
    firstName: "MILAN CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "MILAN CHANDRA DAS",
    saID: "SPR5899",
  },
  {
    firstName: "B SANTOSH KUMAR",
    lastName: "SUBUDHI",
    address:
      "QR NO 13B FLAT 6,NORTH COLONY*GARDEN REACH*KOLKATA-43*PAN-AKWPS5122P",
    fullName: "B SANTOSH KUMAR SUBUDHI",
    saID: "SPR5900",
  },
  {
    firstName: "SITENDU MOHAN",
    lastName: "BISWAS",
    address: "SATSANG VIHAR*PO-DEWANGANJ*COOCHBEHAR-735122",
    fullName: "SITENDU MOHAN BISWAS",
    saID: "SPR5901",
  },
  {
    firstName: "MANJU SUNDAR",
    lastName: "MISHRA",
    address:
      "C/O-NANDAN KUNJA MISHRA*SATSANG ASHRAM,KARKULI*PO-CONTAI*PURBA MEDINIPUR 721 401",
    fullName: "MANJU SUNDAR MISHRA",
    saID: "SPR5902",
  },
  {
    firstName: "SIBAPADA",
    lastName: "DAS",
    address: "",
    fullName: "SIBAPADA DAS",
    saID: "SPR5903",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "GANGULI",
    address: "SATSANG VIHAR GANGARAMPUR*PO-GANGARAMPUR*DAKSHIN DINAJPUR 733124",
    fullName: "TAPAS KUMAR GANGULI",
    saID: "SPR5904",
  },
  {
    firstName: "KULAMANI",
    lastName: "RANA",
    address: "AT-KUKUDAKHALI*GARHMADHUPUR*JAJPUR 755 041",
    fullName: "KULAMANI RANA",
    saID: "SPR5905",
  },
  {
    firstName: "S",
    lastName: "SAMAL",
    address: "",
    fullName: "S SAMAL",
    saID: "SPR5906",
  },
  {
    firstName: "MANIK",
    lastName: "BISWAS",
    address: "VILL-DANGAR BAZAR*PO-BALAD*E.K.HILLS*MEGHALAYA  793 106",
    fullName: "MANIK BISWAS",
    saID: "SPR5907",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5908",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR5909",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5910",
  },
  {
    firstName: "ANIL",
    lastName: "MONDAL",
    address: "",
    fullName: "ANIL MONDAL",
    saID: "SPR5911",
  },
  {
    firstName: "GAJENDRA",
    lastName: "BHARTIA",
    address: "TRIBENIGANJ*SUPAUL PIN-852139",
    fullName: "GAJENDRA BHARTIA",
    saID: "SPR5912",
  },
  {
    firstName: "S K",
    lastName: "DASGUPTA",
    address: "",
    fullName: "S K DASGUPTA",
    saID: "SPR5913",
  },
  {
    firstName: "S K",
    lastName: "DASGUPTA",
    address: "",
    fullName: "S K DASGUPTA",
    saID: "SPR5914",
  },
  {
    firstName: "JANARDAN",
    lastName: "SAHOO",
    address:
      "PLOT NO-3E/479,SECTOR-9,CDA*PO-AVINAB BIDANASI*CUTTACK-753014*PAN-APUPS5759M",
    fullName: "JANARDAN SAHOO",
    saID: "SPR5915",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR5916",
  },
  {
    firstName: "DUD KUMAR",
    lastName: "POLLEY",
    address: "",
    fullName: "DUD KUMAR POLLEY",
    saID: "SPR5917",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR5918",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5919",
  },
  {
    firstName: "BISWAJIT",
    lastName: "BHOUMIK",
    address: "PO-JAIRAMPUR,NEW MARKET*ARUNACHAL PRADESH 792 121*PAN-AIDPB3960B",
    fullName: "BISWAJIT BHOUMIK",
    saID: "SPR5920",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5921",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5922",
  },
  {
    firstName: "PRITI SUNDAR",
    lastName: "DEBSARMA",
    address:
      "DHARMAPUR R.D*PO-ABHAYAPURI*DIST-BONGAIGAON 783384*PAN-AHZPD6925F",
    fullName: "PRITI SUNDAR DEBSARMA",
    saID: "SPR5923",
  },
  {
    firstName: "KEDARNATH",
    lastName: "MOHANTY",
    address:
      "S/O BANABIHARI MOHANTY*AT&PO-PANCHARUKHI*VIA-BALIAPAL*BALASORE 756026*(AJHPM3610J)",
    fullName: "KEDARNATH MOHANTY",
    saID: "SPR5924",
  },
  {
    firstName: "AVIIPRASAD",
    lastName: "RAM",
    address: "",
    fullName: "AVIIPRASAD RAM",
    saID: "SPR5925",
  },
  {
    firstName: "AVI PRASAD",
    lastName: "RAM",
    address: "",
    fullName: "AVI PRASAD RAM",
    saID: "SPR5926",
  },
  {
    firstName: "PRABAB",
    lastName: "RAY",
    address: "",
    fullName: "PRABAB RAY",
    saID: "SPR5927",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR5928",
  },
  {
    firstName: "06V ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "06V ASHOK CHAKRABORTY",
    saID: "SPR5929",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "MUKHOPADHYAY",
    address:
      "72 KAWABAGH RLY COLONY,RLY ROAD NO-7*GORAKHPUR-273012(UP)*PAN-ACJPM7598L",
    fullName: "SANJAY KUMAR MUKHOPADHYAY",
    saID: "SPR5930",
  },
  {
    firstName: "JATINDRA KUMAR",
    lastName: "SAHU",
    address: "AT/PO-KANRALI*VIA-REMUNA*BALASORE*PAN-EYVPS7175D",
    fullName: "JATINDRA KUMAR SAHU",
    saID: "SPR5931",
  },
  {
    firstName: "SUBHAS",
    lastName: "NANDY",
    address: "VILL-CHATRA*PO-DARAPUR*BANKURA-722141",
    fullName: "SUBHAS NANDY",
    saID: "SPR5932",
  },
  {
    firstName: "OMPRAKASH",
    lastName: "SHARMA",
    address: "156, A P C ROAD*ROOM NO-M-14*HARIDAS SAHA MARKET*CALCUTTA-700006",
    fullName: "OMPRAKASH SHARMA",
    saID: "SPR5933",
  },
  {
    firstName: "SWATIPRASAD",
    lastName: "DOLUI",
    address: "",
    fullName: "SWATIPRASAD DOLUI",
    saID: "SPR5934",
  },
  {
    firstName: "RE ASHOK",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "RE ASHOK CHAKRAVORTY",
    saID: "SPR5935",
  },
  {
    firstName: "AKSHAYA",
    lastName: "MISHAR",
    address: "",
    fullName: "AKSHAYA MISHAR",
    saID: "SPR5936",
  },
  {
    firstName: "LATE MADHU SUDAN",
    lastName: "SINGH",
    address: "ADVOCATE*DIST BAR ASSOCIATION*PO/DIST-BEGUSARAI 851 101",
    fullName: "LATE MADHU SUDAN SINGH",
    saID: "SPR5937",
  },
  {
    firstName: "TARUN KUMAR",
    lastName: "BANERJEE",
    address:
      "DESHBANDHU ROAD*SUBHASPALLI*BURNPUR 713325*BURDWAN*PAN-AGYPB5785B",
    fullName: "TARUN KUMAR BANERJEE",
    saID: "SPR5938",
  },
  {
    firstName: "GOURANGA",
    lastName: "PAL",
    address: "",
    fullName: "GOURANGA PAL",
    saID: "SPR5939",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5940",
  },
  {
    firstName: "PUJANIA BARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOURANI ",
    saID: "SPR5941",
  },
  {
    firstName: "RAMU",
    lastName: "BHATTACHERJEE",
    address:
      "SATSANG VIHAR GUWAHATI*PO-BHANGAGARH*GUWAHATI-781005*KAMRUP*ASSAM",
    fullName: "RAMU BHATTACHERJEE",
    saID: "SPR5942",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR5943",
  },
  {
    firstName: "BIBHU PADA",
    lastName: "DAS",
    address: "MAHESHBATI*PO-RAINA*BURDWAN-713421",
    fullName: "BIBHU PADA DAS",
    saID: "SPR5944",
  },
  {
    firstName: "VIJAYENDRA",
    lastName: "KUMAR",
    address: "VIDYAPURI MELA ROAD,WORD-2*SUPAUL-852131.",
    fullName: "VIJAYENDRA KUMAR",
    saID: "SPR5945",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR5946",
  },
  {
    firstName: "BISWAJIT",
    lastName: "DEY",
    address:
      "S/O-SHRI B B DEY*SATSANG VIHAR SHILLONG*BISHNUPUR-793004*MEGHALAYA",
    fullName: "BISWAJIT DEY",
    saID: "SPR5947",
  },
  {
    firstName: "S",
    lastName: "PUHAN",
    address: "",
    fullName: "S PUHAN",
    saID: "SPR5948",
  },
  {
    firstName: "LATE HALADHAR",
    lastName: "NEOG",
    address: "MANKATA TEPORGAON*PO-MANKATA*DIBRUGARH",
    fullName: "LATE HALADHAR NEOG",
    saID: "SPR5949",
  },
  {
    firstName: "MANOJ",
    lastName: "CHAKRABORTY",
    address: "VILL-BORIGAON*PO-ABHAYAPURI*DIST-BONGAIGAON-783384.",
    fullName: "MANOJ CHAKRABORTY",
    saID: "SPR5950",
  },
  {
    firstName: "MANOMOHINI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "MANOMOHINI CHAKRABORTY",
    saID: "SPR5951",
  },
  {
    firstName: "SUNIL",
    lastName: "SARKAR",
    address: "VILL-NORTH SIMULPUR*PO-THAKURNAGAR*24PARGANAS (NORTH)-743287",
    fullName: "SUNIL SARKAR",
    saID: "SPR5952",
  },
  {
    firstName: "AKUL",
    lastName: "PATI",
    address: "C/O LAXMAN DAKUA*BALLIGUDA*PHULBANI",
    fullName: "AKUL PATI",
    saID: "SPR5953",
  },
  {
    firstName: "JAYDEV",
    lastName: "SARKAR",
    address: "VILL-PARIHARPUR*PO-CHAWTARA*VIA-SAINTHIA*DIST-BIRBHUM-731234",
    fullName: "JAYDEV SARKAR",
    saID: "SPR5954",
  },
  {
    firstName: "RAJKUMAR",
    lastName: "SHAW",
    address:
      "NO 2, KANTADANGA ROAD*RLY. GATE NO-29*PO-KANKINARA*24 PARGANAS (NORTH)-743126.",
    fullName: "RAJKUMAR SHAW",
    saID: "SPR5955",
  },
  {
    firstName: "JAMINI",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "JAMINI CHOUDHURY",
    saID: "SPR5956",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5957",
  },
  {
    firstName: "JYOTI PRASAD",
    lastName: "PRADHAN",
    address:
      "C/O S.B.PRADHAN*VILL-KALYANPUR*PO-BARIDA,EGRA*PURBA MIDNAPUR*PAN BXPPP7053M",
    fullName: "JYOTI PRASAD PRADHAN",
    saID: "SPR5958",
  },
  {
    firstName: "RAMAKANTA",
    lastName: "MAHATO",
    address: "",
    fullName: "RAMAKANTA MAHATO",
    saID: "SPR5959",
  },
  {
    firstName: "PUJANIYASIKHABUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYASIKHABUDI ",
    saID: "SPR5960",
  },
  {
    firstName: "PRATAP CHANDRA",
    lastName: "KONAR",
    address: "VILL&PO-SUREKALNA*PS-JAMALPUR*BURDWAN 713408*PAN-BBAPK8211R",
    fullName: "PRATAP CHANDRA KONAR",
    saID: "SPR5961",
  },
  {
    firstName: "6EV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6EV CHAKRABORTY",
    saID: "SPR5962",
  },
  {
    firstName: "LATE DILIP KUMAR",
    lastName: "DAS",
    address: "AT-SHYAMPUR COLONY*PO-DURGAPUR-1*BURDWAN",
    fullName: "LATE DILIP KUMAR DAS",
    saID: "SPR5963",
  },
  {
    firstName: "NARAYAN",
    lastName: "PRADHAN",
    address: "AT/PO-CHHATIA*DIST-JAJPUR-754023*ORISSA",
    fullName: "NARAYAN PRADHAN",
    saID: "SPR5964",
  },
  {
    firstName: "MADAN KUMAR",
    lastName: "ADHIKARI",
    address: "C/O SILIGURI SATSANG VIHAR*SILIGURI*DARJEELING",
    fullName: "MADAN KUMAR ADHIKARI",
    saID: "SPR5965",
  },
  {
    firstName: "HRITI KUMAR",
    lastName: "GANGULY",
    address: "SABITRI VILLA*PURANDAH*DEOGHAR 814112",
    fullName: "HRITI KUMAR GANGULY",
    saID: "SPR5966",
  },
  {
    firstName: "BAIRAGI CHARAN",
    lastName: "SAHOO",
    address: "VILL/PO-PINGUA*DIST-DHENKANAL 759 016*PAN-ALSPS2999D",
    fullName: "BAIRAGI CHARAN SAHOO",
    saID: "SPR5967",
  },
  {
    firstName: "GOPINATH",
    lastName: "KHAMARI",
    address: "",
    fullName: "GOPINATH KHAMARI",
    saID: "SPR5968",
  },
  {
    firstName: "A C",
    lastName: "DUTTA",
    address: "",
    fullName: "A C DUTTA",
    saID: "SPR5969",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR5970",
  },
  {
    firstName: "GURUDUTTA",
    lastName: "DASH",
    address: "C/O DR SACHIDANANDA DASH*PO-PALLAHATA*KHURDA-752056.",
    fullName: "GURUDUTTA DASH",
    saID: "SPR5971",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR5972",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "MAITY",
    address: "",
    fullName: "SANTOSH KUMAR MAITY",
    saID: "SPR5973",
  },
  {
    firstName: "SANKAR",
    lastName: "PAL",
    address: "(APU)*SHANTINAGAR*JAGDALPUR*DT-BASTER*M.P.-494001",
    fullName: "SANKAR PAL",
    saID: "SPR5974",
  },
  {
    firstName: "BIDYABATI",
    lastName: "SARKAR",
    address: "",
    fullName: "BIDYABATI SARKAR",
    saID: "SPR5975",
  },
  {
    firstName: "NIKHILESH DEY",
    lastName: "MANDAL",
    address: "",
    fullName: "NIKHILESH DEY MANDAL",
    saID: "SPR5976",
  },
  {
    firstName: "REBATI",
    lastName: "HALDAR",
    address: "",
    fullName: "REBATI HALDAR",
    saID: "SPR5977",
  },
  {
    firstName: "06REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "06REV CHAKRABORTY",
    saID: "SPR5978",
  },
  {
    firstName: "SHYAMAL",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "SHYAMAL CHAKRAVORTY",
    saID: "SPR5979",
  },
  {
    firstName: "HIRENDRA NATH",
    lastName: "PANJA",
    address: "",
    fullName: "HIRENDRA NATH PANJA",
    saID: "SPR5980",
  },
  {
    firstName: "SOMNATH",
    lastName: "GHOSH",
    address: "11/10 AMARTYA ABASAN*AL-BLOCK,SECTOR-II*SALT LAKE*KOLKATA 700091",
    fullName: "SOMNATH GHOSH",
    saID: "SPR5981",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR5982",
  },
  {
    firstName: "UMA SHANKAR",
    lastName: "MISHRA",
    address: "AT/PO-BIRA PRATAPPUR*CHANDANPUR*PURI 752012",
    fullName: "UMA SHANKAR MISHRA",
    saID: "SPR5983",
  },
  {
    firstName: "JOGDHAN",
    lastName: "MANA",
    address: "",
    fullName: "JOGDHAN MANA",
    saID: "SPR5984",
  },
  {
    firstName: "LATE DAVID",
    lastName: "LICHTGARN",
    address: "379, PLAIVILLE AVE.*UNIONVILLE, CT*USA 06085.",
    fullName: "LATE DAVID LICHTGARN",
    saID: "SPR5985",
  },
  {
    firstName: "LAKSHMI",
    lastName: "GUHA",
    address: "",
    fullName: "LAKSHMI GUHA",
    saID: "SPR5986",
  },
  {
    firstName: "DEBAL CHANDRA",
    lastName: "SARKAR",
    address:
      "STREET NO-31;QTR NO-29 B*PO-CHITTARANJAN*BURDWAN-713331*PAN-BPUPS4914M",
    fullName: "DEBAL CHANDRA SARKAR",
    saID: "SPR5987",
  },
  {
    firstName: "KAMALAKSHA",
    lastName: "GOSWAMI",
    address: "C/O-JAYANTA KUMAR GOSWAMI*PO-MORANHAT*DIBRUGARH-785670.",
    fullName: "KAMALAKSHA GOSWAMI",
    saID: "SPR5988",
  },
  {
    firstName: "CHANDRA SEKHAR",
    lastName: "DHAR",
    address: "AT-MUNDIAPASI*PO-RASOL*DIST-DHENKANAL 759021*ODISHA",
    fullName: "CHANDRA SEKHAR DHAR",
    saID: "SPR5989",
  },
  {
    firstName: "GAJEN CHANDRA",
    lastName: "DEKA",
    address:
      "MANGALDAI TOWN, WORD NO-6*LACHIT NAGAR*PO-MANGALDAI*DARRANG-784125*PAN-BBSPD2985D",
    fullName: "GAJEN CHANDRA DEKA",
    saID: "SPR5990",
  },
  {
    firstName: "GOPINATH",
    lastName: "PATRA",
    address:
      "LIVE STOCK INSPECTOR*AT&PO-MUNIGUDA*DIST-RAYAGADA 765 021*PAN-AIYPP1234F",
    fullName: "GOPINATH PATRA",
    saID: "SPR5991",
  },
  {
    firstName: "DHIREN",
    lastName: "BHUKTA",
    address: "",
    fullName: "DHIREN BHUKTA",
    saID: "SPR5992",
  },
  {
    firstName: "DHRITI MOHAN",
    lastName: "PAUL",
    address:
      "AT-KUSHPATA (VIVEKANANDAPALLY)*PO-GHATAL*DIST-PASCHIM MEDINIPUR-721212.",
    fullName: "DHRITI MOHAN PAUL",
    saID: "SPR5993",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR5994",
  },
  {
    firstName: "SAMARPIT",
    lastName: "ROYCHAKRABORTY",
    address: "VILL-BENEGARIAH*PO-JHARGRAM*PASCHIM MEDINIPUR-721507",
    fullName: "SAMARPIT ROYCHAKRABORTY",
    saID: "SPR5995",
  },
  {
    firstName: "6EV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6EV CHAKRABORTY",
    saID: "SPR5996",
  },
  {
    firstName: "ANINDADITYA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANINDADITYA CHAKRABORTY",
    saID: "SPR5997",
  },
  {
    firstName: "JAYDIPTI",
    lastName: "MANDAL",
    address: "VILL&PO-KORAKATI*VIA-SANDESHKHALI*24 PARGANAS (NORTH)-743446",
    fullName: "JAYDIPTI MANDAL",
    saID: "SPR5998",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR5999",
  },
  {
    firstName: "DHRITI SUNDAR",
    lastName: "BISWAS",
    address: "9, KALIBARI ROAD, NALTA*DUM DUM CANT.*CALCUTTA-700028.",
    fullName: "DHRITI SUNDAR BISWAS",
    saID: "SPR6000",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR6001",
  },
  {
    firstName: "SUVENDU",
    lastName: "SARKAR",
    address:
      "INDRA NARAYANPUR OUT COLONY*PO-GANGARAMPUR*DAKSHIN DINAJPUR-733124",
    fullName: "SUVENDU SARKAR",
    saID: "SPR6002",
  },
  {
    firstName: "MANINDRA CHANDRA",
    lastName: "",
    address: "",
    fullName: "MANINDRA CHANDRA ",
    saID: "SPR6003",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6004",
  },
  {
    firstName: "NAGEN",
    lastName: "BARMAN",
    address: "",
    fullName: "NAGEN BARMAN",
    saID: "SPR6005",
  },
  {
    firstName: "NILKANTA",
    lastName: "GHOSH",
    address: "49 DEFENCE COLONY*MEDAHALLY*VIRGO NAGAR PO*BANGALORE 560049",
    fullName: "NILKANTA GHOSH",
    saID: "SPR6006",
  },
  {
    firstName: "6REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6REV CHAKRABORTY",
    saID: "SPR6007",
  },
  {
    firstName: "SUSHIL CHANDRA",
    lastName: "NANDY",
    address: "VILL&PO-MOHANPUR*NADIA 741246.",
    fullName: "SUSHIL CHANDRA NANDY",
    saID: "SPR6008",
  },
  {
    firstName: "NIRANJAN",
    lastName: "MISHRA",
    address: "",
    fullName: "NIRANJAN MISHRA",
    saID: "SPR6009",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6010",
  },
  {
    firstName: "6EV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "6EV CHAKRAVORTY",
    saID: "SPR6011",
  },
  {
    firstName: "SEL",
    lastName: "",
    address: "",
    fullName: "SEL ",
    saID: "SPR6012",
  },
  {
    firstName: "REV ASHOKR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKR CHAKRABORTY",
    saID: "SPR6013",
  },
  {
    firstName: "DHIRENDRA",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA BISWAS",
    saID: "SPR6014",
  },
  {
    firstName: "RAJENDRA KUMAR",
    lastName: "TRIPATHY",
    address:
      "AT-KORKOR (ITUA)*PO-BIROL*VIA-CHOUDWAR*DIST-CUTTACK-754025*PAN-AREPT1339E",
    fullName: "RAJENDRA KUMAR TRIPATHY",
    saID: "SPR6015",
  },
  {
    firstName: "NILAMANI",
    lastName: "SUTAR",
    address: "AT-JAMUPOSI*PO-AMPALABA*VIA-SUKINDA*JAJPUR",
    fullName: "NILAMANI SUTAR",
    saID: "SPR6016",
  },
  {
    firstName: "PARESH NATH",
    lastName: "ROY",
    address: "156,A,P,C,ROAD,ROOM NO-M-14*KOLKATA-6",
    fullName: "PARESH NATH ROY",
    saID: "SPR6017",
  },
  {
    firstName: "T CHANDRA SEKHAR",
    lastName: "RAO",
    address: "BLOCK-IV;S R ESTATE*F C I COLONY*MIYAPUR--500 049*HYDERABAD 49",
    fullName: "T CHANDRA SEKHAR RAO",
    saID: "SPR6018",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6019",
  },
  {
    firstName: "DUSMANTA KUMAR",
    lastName: "BEHERA",
    address: "AT-SATSANG VIHAR*PO-BANI VIHAR*DIST-PURI 751 004",
    fullName: "DUSMANTA KUMAR BEHERA",
    saID: "SPR6020",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6021",
  },
  {
    firstName: "SABYASACHI ROY",
    lastName: "CHURAMANI",
    address: "AT/PO-BHODAR*VIA-BRAMHA KUNDA*DIST-PURI 752 113",
    fullName: "SABYASACHI ROY CHURAMANI",
    saID: "SPR6022",
  },
  {
    firstName: "MANTU",
    lastName: "MIRDHA",
    address: "",
    fullName: "MANTU MIRDHA",
    saID: "SPR6023",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "PAL",
    address: "",
    fullName: "ASHUTOSH PAL",
    saID: "SPR6024",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "ROY",
    address:
      "C/O-SOMNATH ROY*VILL&PO-TAKI MAMUDPUR*DIST-24 PARGANAS (N)-743435*PAN-AOPPR3080N",
    fullName: "SAMIR KUMAR ROY",
    saID: "SPR6025",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6026",
  },
  {
    firstName: "SIBI SUNDAR",
    lastName: "DUTTA",
    address: "AT-RAJADWAR*NORTH GUWAHATI 781030*KAMRUP*ASSAM",
    fullName: "SIBI SUNDAR DUTTA",
    saID: "SPR6027",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6028",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6029",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR6030",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "BISWAS",
    address: "",
    fullName: "DHIRENDRA NATH BISWAS",
    saID: "SPR6031",
  },
  {
    firstName: "KAMAL LOCHAN",
    lastName: "JANA",
    address:
      "VILL&PO-TANUA*PS-MOHANPUR*PASCHIM MEDINIPUR 721436*PAN-ADRPJ3319H",
    fullName: "KAMAL LOCHAN JANA",
    saID: "SPR6032",
  },
  {
    firstName: "PINAKI NATH",
    lastName: "MUKHARJEE",
    address: "NEAR KALIBARI,GANDHINAGAR*PO-KHAGARIA*KHAGARIA 851204",
    fullName: "PINAKI NATH MUKHARJEE",
    saID: "SPR6033",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6034",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6035",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6036",
  },
  {
    firstName: "PUJANIYA KALYANI",
    lastName: "MITRA",
    address: "",
    fullName: "PUJANIYA KALYANI MITRA",
    saID: "SPR6037",
  },
  {
    firstName: "PUJANIA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOUDI ",
    saID: "SPR6038",
  },
  {
    firstName: "MEJO BOURANIMA",
    lastName: "",
    address: "",
    fullName: "MEJO BOURANIMA ",
    saID: "SPR6039",
  },
  {
    firstName: "ASHOK",
    lastName: "DAS",
    address: "VILL-MAHESHBATI*PO-RAINA*BURDWAN-713421",
    fullName: "ASHOK DAS",
    saID: "SPR6040",
  },
  {
    firstName: "BABUL",
    lastName: "GHOSE",
    address: "C/O D M O OFFICE*PO-CHANGLANG*CHANGLANG*ARUNACHAL PRADESH 792120",
    fullName: "BABUL GHOSE",
    saID: "SPR6041",
  },
  {
    firstName: "SUBRAT KUMAR",
    lastName: "MISHRA",
    address: "",
    fullName: "SUBRAT KUMAR MISHRA",
    saID: "SPR6042",
  },
  {
    firstName: "REV BABAIDA",
    lastName: "",
    address: "",
    fullName: "REV BABAIDA ",
    saID: "SPR6043",
  },
  {
    firstName: "RAJKUMAR",
    lastName: "BANERJEE",
    address: "",
    fullName: "RAJKUMAR BANERJEE",
    saID: "SPR6044",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6045",
  },
  {
    firstName: "JUGESWAR",
    lastName: "GAYARI",
    address: "",
    fullName: "JUGESWAR GAYARI",
    saID: "SPR6046",
  },
  {
    firstName: "UMA KANTA",
    lastName: "BRAHMA",
    address: "",
    fullName: "UMA KANTA BRAHMA",
    saID: "SPR6047",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6048",
  },
  {
    firstName: "JAHAR LAL",
    lastName: "GHOSH",
    address: "",
    fullName: "JAHAR LAL GHOSH",
    saID: "SPR6049",
  },
  {
    firstName: "ANUP KUMAR",
    lastName: "PAL",
    address: "VILL-POURA 8NO WARD*PO-GAYESHPUR*NADIA-741234",
    fullName: "ANUP KUMAR PAL",
    saID: "SPR6050",
  },
  {
    firstName: "K NARAHARI",
    lastName: "REDDY",
    address: "PLOT NO-104, 29TH STREET*SANKAR NAGAR*PO-PAMMAL*CHENNAI-75.",
    fullName: "K NARAHARI REDDY",
    saID: "SPR6051",
  },
  {
    firstName: "BISWAJIT",
    lastName: "CHOUDHURY",
    address: "THAKUR BUNGLOW*SATSANG",
    fullName: "BISWAJIT CHOUDHURY",
    saID: "SPR6052",
  },
  {
    firstName: "DHRITIMAN",
    lastName: "SINGH",
    address: "THAKUR BUNGLOW*SATSANG-814116.",
    fullName: "DHRITIMAN SINGH",
    saID: "SPR6053",
  },
  {
    firstName: "RICKSUNDAR",
    lastName: "SARKAR",
    address: "KALYAN KUTIR*SATSANG-814116.",
    fullName: "RICKSUNDAR SARKAR",
    saID: "SPR6054",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6055",
  },
  {
    firstName: "LATE KAILASH",
    lastName: "BHAGAT",
    address:
      "ASTT ENGRR;NORTH KOEL NAHAR AWAR PRAMANDAL NO-1*PO-AMBA*AURANGABAD",
    fullName: "LATE KAILASH BHAGAT",
    saID: "SPR6056",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6057",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6058",
  },
  {
    firstName: "PRITAM",
    lastName: "SINGH",
    address: "",
    fullName: "PRITAM SINGH",
    saID: "SPR6059",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6060",
  },
  {
    firstName: "A B",
    lastName: "HALDER",
    address: "",
    fullName: "A B HALDER",
    saID: "SPR6061",
  },
  {
    firstName: "GOUTAM KUMAR",
    lastName: "SAHA",
    address: "PO-DUDHNAI*DIST-GOALPARA-783124*ASSAM",
    fullName: "GOUTAM KUMAR SAHA",
    saID: "SPR6062",
  },
  {
    firstName: "PRANKRISHNA",
    lastName: "NATH",
    address: "JHAGRARPAR SATSANG KENDRA*PO&DIST-DHUBRI",
    fullName: "PRANKRISHNA NATH",
    saID: "SPR6063",
  },
  {
    firstName: "PRASANNA KUMAR",
    lastName: "HOTA",
    address:
      "SUDHRUTI BITAN*LALTIKRA(IRR.COLONY BACKSIDE)*PO-RAJENDRA COLLEGE*DT-BOLANGIR-767001",
    fullName: "PRASANNA KUMAR HOTA",
    saID: "SPR6064",
  },
  {
    firstName: "LATE SHYAMSUNDAR",
    lastName: "GURU",
    address: "VILL-GOBINDAPUR*PO-JOTBANI*MIDNAPUR",
    fullName: "LATE SHYAMSUNDAR GURU",
    saID: "SPR6065",
  },
  {
    firstName: "SITAL PRASAD",
    lastName: "SINHA",
    address: "VILL/PO-KAPASARIA*PURBA MEDINIPUR-721628",
    fullName: "SITAL PRASAD SINHA",
    saID: "SPR6066",
  },
  {
    firstName: "HRISHIKESH",
    lastName: "MISHRA",
    address:
      "VILL-KULTI SIMULGRAM,QR NO-E-14/2*PO-RANITALA*DIST-BURDWAN-713349",
    fullName: "HRISHIKESH MISHRA",
    saID: "SPR6067",
  },
  {
    firstName: "SABITA SUNDAR",
    lastName: "TALUKDAR",
    address:
      "SWASTI DEEPA-B-17*AMRAPALI CO.SOCIETY*NEAR PANCHPERI NAKA*RAIPUR*C.G.492 001",
    fullName: "SABITA SUNDAR TALUKDAR",
    saID: "SPR6068",
  },
  {
    firstName: "KANAK KANTI",
    lastName: "JHA",
    address: "COLONY NO-1*PO&DIST-KATIHAR-854105",
    fullName: "KANAK KANTI JHA",
    saID: "SPR6069",
  },
  {
    firstName: "MUKULDA",
    lastName: "",
    address: "",
    fullName: "MUKULDA ",
    saID: "SPR6070",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6071",
  },
  {
    firstName: "DIBAKAR",
    lastName: "SWAIN",
    address: "AT/PO-PAHANGA*VIA-SANKHACHILA*DIST-JAJPUR-755015",
    fullName: "DIBAKAR SWAIN",
    saID: "SPR6072",
  },
  {
    firstName: "PUJANIA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOUDI ",
    saID: "SPR6073",
  },
  {
    firstName: "SARAT KUMAR",
    lastName: "SAHOO",
    address:
      "D-63,2ND FLOOR,PARYAVARAN COMPLEX*IGNOU ROAD*SAKET*NEW DELHI-110030",
    fullName: "SARAT KUMAR SAHOO",
    saID: "SPR6074",
  },
  {
    firstName: "ARKYADUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKYADUTI CHAKRABORTY",
    saID: "SPR6075",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6076",
  },
  {
    firstName: "GOBINDA",
    lastName: "SINGHA",
    address: "VILL&PO-PANCHPOTA*NORTH 24 PARGANAS-743273.",
    fullName: "GOBINDA SINGHA",
    saID: "SPR6077",
  },
  {
    firstName: "JAJNESWAR",
    lastName: "BERA",
    address: "VILL&PO-SIBNAGAR ABAD*PS-NAMKHANA*24 PARGANAS (SOUTH)-743357",
    fullName: "JAJNESWAR BERA",
    saID: "SPR6078",
  },
  {
    firstName: "MAHADEV",
    lastName: "RUDRA",
    address: "VILL&PO-GANGADHARI*DIST-MURSHIDABAD-742121.*PAN NO.ACLPR0761J",
    fullName: "MAHADEV RUDRA",
    saID: "SPR6079",
  },
  {
    firstName: "MANISH RANJAN",
    lastName: "BHADURI",
    address: "C/O SATWATA MEDICALS*PO-SONARPUR*DIST-KAMRUP (M)*ASSAM-782402",
    fullName: "MANISH RANJAN BHADURI",
    saID: "SPR6080",
  },
  {
    firstName: "DEEPAK",
    lastName: "CHAKRABORTY",
    address: "C/O-M CHAKRABORTY &CO*DAILY BAZAR*TINSUKIA-786125*ASSAM",
    fullName: "DEEPAK CHAKRABORTY",
    saID: "SPR6081",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6082",
  },
  {
    firstName: "PUJANIYA RAMGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RAMGAMA ",
    saID: "SPR6083",
  },
  {
    firstName: "BHUDAR",
    lastName: "MONDAL",
    address: "",
    fullName: "BHUDAR MONDAL",
    saID: "SPR6084",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6085",
  },
  {
    firstName: "DULAL",
    lastName: "",
    address: "",
    fullName: "DULAL ",
    saID: "SPR6086",
  },
  {
    firstName: "REV AKRADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV AKRADYUTI CHAKRABORTY",
    saID: "SPR6087",
  },
  {
    firstName: "MAHESWAR",
    lastName: "DASS",
    address:
      "DELHI SATSANG VIHAR*A-15 QUTUB INSTL AREA*NEW DELHI-67*PAN-AEQPD3919M",
    fullName: "MAHESWAR DASS",
    saID: "SPR6088",
  },
  {
    firstName: "BRAJA GOPAL",
    lastName: "SAHOO",
    address: "",
    fullName: "BRAJA GOPAL SAHOO",
    saID: "SPR6089",
  },
  {
    firstName: "SATI PROSAD",
    lastName: "DOLAI",
    address: "",
    fullName: "SATI PROSAD DOLAI",
    saID: "SPR6090",
  },
  {
    firstName: "DWIP",
    lastName: "SARMA",
    address: "",
    fullName: "DWIP SARMA",
    saID: "SPR6091",
  },
  {
    firstName: "CHANDRA SEN",
    lastName: "GANGWAR",
    address: "",
    fullName: "CHANDRA SEN GANGWAR",
    saID: "SPR6092",
  },
  {
    firstName: "NABIN CHANDRA",
    lastName: "BISWAS",
    address: "VILL-NABAGRAM*PO-KALIGHAT*ANDAMAN N 744202",
    fullName: "NABIN CHANDRA BISWAS",
    saID: "SPR6093",
  },
  {
    firstName: "MEZBAUDI",
    lastName: "",
    address: "",
    fullName: "MEZBAUDI ",
    saID: "SPR6094",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6095",
  },
  {
    firstName: "BIDYAPATI",
    lastName: "SARKAR",
    address: "",
    fullName: "BIDYAPATI SARKAR",
    saID: "SPR6096",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6097",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6098",
  },
  {
    firstName: "B",
    lastName: "PARIDA",
    address: "",
    fullName: "B PARIDA",
    saID: "SPR6099",
  },
  {
    firstName: "NARENDRA KUMAR",
    lastName: "PARIDA",
    address:
      "DEBANJAN 31 GOYEL VIHAR AVENUE*NEAR KHAJRANA GANESH TEMPLE*INDORE 452008*M P",
    fullName: "NARENDRA KUMAR PARIDA",
    saID: "SPR6100",
  },
  {
    firstName: "SITESH CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "SITESH CHANDRA DAS",
    saID: "SPR6101",
  },
  {
    firstName: "GAUTAM",
    lastName: "NANDI",
    address:
      "S E C L ACCOUNTS DEPTT*CENTRAL WORKSHOP,KORBA*DIST-KORBA*C.G.-495677",
    fullName: "GAUTAM NANDI",
    saID: "SPR6102",
  },
  {
    firstName: "PEV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PEV CHAKRABORTY",
    saID: "SPR6103",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6104",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "VARMA",
    address: "",
    fullName: "AJIT KUMAR VARMA",
    saID: "SPR6105",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6106",
  },
  {
    firstName: "TRIDIP",
    lastName: "SARMA",
    address: "",
    fullName: "TRIDIP SARMA",
    saID: "SPR6107",
  },
  {
    firstName: "RAKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RAKADYUTI CHAKRABORTY",
    saID: "SPR6108",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6109",
  },
  {
    firstName: "LATE JAGANNATH",
    lastName: "NAG",
    address: "OLD TOKOK*PO-SONARI*SIBSAGAR 785690",
    fullName: "LATE JAGANNATH NAG",
    saID: "SPR6110",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6111",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6112",
  },
  {
    firstName: "KESHAB CHANDRA",
    lastName: "PANI",
    address: "AT&PO-DEBIDWAR*JAJPUR-755007",
    fullName: "KESHAB CHANDRA PANI",
    saID: "SPR6113",
  },
  {
    firstName: "PRALAY",
    lastName: "GHOSH",
    address: "KL5/25/14 SEC 2*KALAMBOLI*NEW  MUMBAI 410218",
    fullName: "PRALAY GHOSH",
    saID: "SPR6114",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6115",
  },
  {
    firstName: "NARESH KUMAR",
    lastName: "SINGH",
    address: "INCOMETAX COMPOUND*KACHARI ROAD*BEGUSARAI-851101*BIHAR",
    fullName: "NARESH KUMAR SINGH",
    saID: "SPR6116",
  },
  {
    firstName: "DIBYENDU",
    lastName: "GUHA",
    address: "*SATSANG 814116",
    fullName: "DIBYENDU GUHA",
    saID: "SPR6117",
  },
  {
    firstName: "BISWAJIT",
    lastName: "BANERJEE",
    address: "509 KARMACHARI NAGAR*IZZATNAGAR(BARELI)*U.P.",
    fullName: "BISWAJIT BANERJEE",
    saID: "SPR6118",
  },
  {
    firstName: "DEB DULAL",
    lastName: "RAY",
    address: "VILL/PO-KOTASUR*DIST-BIRBHUM 731 213",
    fullName: "DEB DULAL RAY",
    saID: "SPR6119",
  },
  {
    firstName: "NIRMAL  CHANDRA",
    lastName: "SAHAROY",
    address: "",
    fullName: "NIRMAL  CHANDRA SAHAROY",
    saID: "SPR6120",
  },
  {
    firstName: "PABITRA",
    lastName: "MUKHERJEE",
    address: "C/O B BANERJEE*VILL&PO-PURBA SATGACHIA*KALNA*BURDWAN  PIN-712512",
    fullName: "PABITRA MUKHERJEE",
    saID: "SPR6121",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6122",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6123",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6124",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6125",
  },
  {
    firstName: "UPALI",
    lastName: "RAY",
    address: "",
    fullName: "UPALI RAY",
    saID: "SPR6126",
  },
  {
    firstName: "ARARTHIB",
    lastName: "ROYCHAKRABORTY",
    address: "",
    fullName: "ARARTHIB ROYCHAKRABORTY",
    saID: "SPR6127",
  },
  {
    firstName: "JOYDEB",
    lastName: "MOHANTY",
    address: "",
    fullName: "JOYDEB MOHANTY",
    saID: "SPR6128",
  },
  {
    firstName: "SAMARENDRA",
    lastName: "DAS",
    address:
      "FLAT NO.5 II FLOOR;ANAND APARTMENT*DESO ROAD;MEHRAULI*NEW DELHI 30*PAN.SCIPD3040K",
    fullName: "SAMARENDRA DAS",
    saID: "SPR6129",
  },
  {
    firstName: "BISWA NATH",
    lastName: "BARUA",
    address: "",
    fullName: "BISWA NATH BARUA",
    saID: "SPR6130",
  },
  {
    firstName: "DIP",
    lastName: "SARMA",
    address: "",
    fullName: "DIP SARMA",
    saID: "SPR6131",
  },
  {
    firstName: "HOPAN",
    lastName: "HEMBRAM",
    address:
      "D W & S DIVN  TENUGHAT*PO-R B T*DIST-BOKARO-829123*JHARKHAND*PAN-AAWPH7637J",
    fullName: "HOPAN HEMBRAM",
    saID: "SPR6132",
  },
  {
    firstName: "CHANDAN KUMAR",
    lastName: "DAS",
    address:
      "C/O-PARESH CHANDRA DAS*AT/PO-HASNABAD*NEAR REGT.OFFICE*24PARGANAS (NORTH)-743426",
    fullName: "CHANDAN KUMAR DAS",
    saID: "SPR6133",
  },
  {
    firstName: "SHIBA PRASAD",
    lastName: "DEY",
    address:
      "BL- 2B; DISHANT APARTMENT*KAMAKHYA NAGAR*PO-GUWAHATI 12 PAN NO.ACFPD2036C",
    fullName: "SHIBA PRASAD DEY",
    saID: "SPR6134",
  },
  {
    firstName: "UDAY SHANKAR",
    lastName: "GHOSH",
    address: "DHARAMPUR (BARAL BAGAN)*PO-CHINSURA*HOOGHLY-712101",
    fullName: "UDAY SHANKAR GHOSH",
    saID: "SPR6135",
  },
  {
    firstName: "SASTI PADA",
    lastName: "MAHATO",
    address:
      "C/O-JHUNU MAHATO*C F R I*PO-F R I*DHANBAD-828108*PAN NO.ADDPM7026J",
    fullName: "SASTI PADA MAHATO",
    saID: "SPR6136",
  },
  {
    firstName: "DHRITI PRAKASH",
    lastName: "SANA",
    address:
      "STAFF QR NO-2/5*BANGALI CAMP*CHANDRAPUR-442401*M.S.PAN NO-AQLPS0138A",
    fullName: "DHRITI PRAKASH SANA",
    saID: "SPR6137",
  },
  {
    firstName: "LATE M S",
    lastName: "ANAND",
    address:
      "FE2,SRI NARKANI HOUSE*63,P.A.KOIL ST.*SATHYA NARAYANAPURAM*MOULIVAKKAM*CHENNAI-116.",
    fullName: "LATE M S ANAND",
    saID: "SPR6138",
  },
  {
    firstName: "REV ALOKE KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOKE KUMAR CHAKRABARTY",
    saID: "SPR6139",
  },
  {
    firstName: "KHAGEN",
    lastName: "BARUAH",
    address: "",
    fullName: "KHAGEN BARUAH",
    saID: "SPR6140",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6141",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6142",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6143",
  },
  {
    firstName: "PRANAB KUMAR",
    lastName: "BISWAS",
    address: "3 NO, GARH SHYAMNAGAR*PO-SHYAMNAGAR*24 PARGANAS (NORTH)-700135",
    fullName: "PRANAB KUMAR BISWAS",
    saID: "SPR6144",
  },
  {
    firstName: "PARTHA SARATHI",
    lastName: "DUTTA",
    address: "VILL&PO-ARANGHATA*NADIA-741501",
    fullName: "PARTHA SARATHI DUTTA",
    saID: "SPR6145",
  },
  {
    firstName: "MRIGANKA SEKHAR",
    lastName: "MAJUMDAR",
    address: "SATSANG*DEOGHAR*PAN BBDPM 8631C",
    fullName: "MRIGANKA SEKHAR MAJUMDAR",
    saID: "SPR6146",
  },
  {
    firstName: "SUNITH KUMAR",
    lastName: "KARAN",
    address: "SATSANG*DEOGHAR*PAN-CZTPK7769N",
    fullName: "SUNITH KUMAR KARAN",
    saID: "SPR6147",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6148",
  },
  {
    firstName: "DHARANI KANTA",
    lastName: "DEB",
    address: "",
    fullName: "DHARANI KANTA DEB",
    saID: "SPR6149",
  },
  {
    firstName: "KUMUDBANDHU",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "KUMUDBANDHU BHATTACHERJEE",
    saID: "SPR6150",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR6151",
  },
  {
    firstName: "ARKADYUTY",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "ARKADYUTY CHAKRAVORTY",
    saID: "SPR6152",
  },
  {
    firstName: "KUMUD",
    lastName: "PRAMANIK",
    address: "",
    fullName: "KUMUD PRAMANIK",
    saID: "SPR6153",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6154",
  },
  {
    firstName: "ARABINDA NATH",
    lastName: "DAS",
    address:
      "C/O DAYAL BHAWAN*LACHIT NAGAR BYE LANE 4*E/CHAWKIDINGEE*DIBRUGARH*PAN-AIVPD3264A",
    fullName: "ARABINDA NATH DAS",
    saID: "SPR6155",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6156",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6157",
  },
  {
    firstName: "DHARMENDRA",
    lastName: "BASUMATARI",
    address: "VILL&PO-DAPDAPI*DIST-SONITPUR 784505",
    fullName: "DHARMENDRA BASUMATARI",
    saID: "SPR6158",
  },
  {
    firstName: "DHIREN",
    lastName: "MUKHARJEE",
    address: "AT/PO-PANDAVESWAR*BURDWAN 713346",
    fullName: "DHIREN MUKHARJEE",
    saID: "SPR6159",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6160",
  },
  {
    firstName: "NIMAI",
    lastName: "DOLUI",
    address: "",
    fullName: "NIMAI DOLUI",
    saID: "SPR6161",
  },
  {
    firstName: "NIMAI",
    lastName: "DOLUI",
    address: "",
    fullName: "NIMAI DOLUI",
    saID: "SPR6162",
  },
  {
    firstName: "BINTU BABU",
    lastName: "",
    address: "",
    fullName: "BINTU BABU ",
    saID: "SPR6163",
  },
  {
    firstName: "RANTU BABU",
    lastName: "",
    address: "",
    fullName: "RANTU BABU ",
    saID: "SPR6164",
  },
  {
    firstName: "GOBINDA",
    lastName: "TANTI",
    address: "DHOEDAAM T.E*PO-BARHAPJAN*TINSUKIA",
    fullName: "GOBINDA TANTI",
    saID: "SPR6165",
  },
  {
    firstName: "PRADIP",
    lastName: "ROY",
    address: "",
    fullName: "PRADIP ROY",
    saID: "SPR6166",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6167",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6168",
  },
  {
    firstName: "UTTAM KUMAR",
    lastName: "RAKSHIT",
    address: "VILL-PORADIH*PO-BHAGABANDH *PURULIA 723151",
    fullName: "UTTAM KUMAR RAKSHIT",
    saID: "SPR6169",
  },
  {
    firstName: "PUJANIA BARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOURANI ",
    saID: "SPR6170",
  },
  {
    firstName: "JOGDAN",
    lastName: "MANNA",
    address: "",
    fullName: "JOGDAN MANNA",
    saID: "SPR6171",
  },
  {
    firstName: "PRAFULLA",
    lastName: "BISWA",
    address: "",
    fullName: "PRAFULLA BISWA",
    saID: "SPR6172",
  },
  {
    firstName: "REVASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVASHOK CHAKRABORTY",
    saID: "SPR6173",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6174",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6175",
  },
  {
    firstName: "KAMALESH",
    lastName: "MAJUMDER",
    address: "",
    fullName: "KAMALESH MAJUMDER",
    saID: "SPR6176",
  },
  {
    firstName: "JYOTIAH CHANDRA",
    lastName: "SEN",
    address: "",
    fullName: "JYOTIAH CHANDRA SEN",
    saID: "SPR6177",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6178",
  },
  {
    firstName: "0UJANIYA",
    lastName: "",
    address: "",
    fullName: "0UJANIYA ",
    saID: "SPR6179",
  },
  {
    firstName: "PADMAMADHAB",
    lastName: "KIRTANIA",
    address: "SULTANPUR*PO-RAJBARI*CALCUTTA 81",
    fullName: "PADMAMADHAB KIRTANIA",
    saID: "SPR6180",
  },
  {
    firstName: "ANAND D",
    lastName: "SURYAVANSHI",
    address: "SATSANG PUBLISHING HOUSE*PO-SATSANG*DEOGHAR",
    fullName: "ANAND D SURYAVANSHI",
    saID: "SPR6181",
  },
  {
    firstName: "SON OF SUNTIDA",
    lastName: "",
    address: "",
    fullName: "SON OF SUNTIDA ",
    saID: "SPR6182",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6183",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6184",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6185",
  },
  {
    firstName: "ARINDAM",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARINDAM CHAKRABORTY",
    saID: "SPR6186",
  },
  {
    firstName: "AVARAPRATIK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AVARAPRATIK CHAKRABORTY",
    saID: "SPR6187",
  },
  {
    firstName: "AMIANGSHU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AMIANGSHU CHAKRABORTY",
    saID: "SPR6188",
  },
  {
    firstName: "ABHRAPATIK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ABHRAPATIK CHAKRABARTY",
    saID: "SPR6189",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR6190",
  },
  {
    firstName: "PREM CHAND",
    lastName: "SHAW",
    address: "40,LOCK GATE ROAD*PO-COSSIPORE*KOLKATA-700 002*PAN-ATOPS8188C",
    fullName: "PREM CHAND SHAW",
    saID: "SPR6191",
  },
  {
    firstName: "ARABINDA",
    lastName: "DASGUPTA",
    address: "KANCHANCHHARA*82 MILES*TRIPURA NORTH 799264",
    fullName: "ARABINDA DASGUPTA",
    saID: "SPR6192",
  },
  {
    firstName: "GOPINATH",
    lastName: "DAS",
    address: "AT-PALASHPUR*PO-TRIBENI*HOOGHLY-712503*PAN-AVYPD3809R",
    fullName: "GOPINATH DAS",
    saID: "SPR6193",
  },
  {
    firstName: "CHINTU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "CHINTU CHAKRABORTY",
    saID: "SPR6194",
  },
  {
    firstName: "ABHRAPRATIK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABHRAPRATIK CHAKRABORTY",
    saID: "SPR6195",
  },
  {
    firstName: "B B",
    lastName: "SAHOO",
    address: "",
    fullName: "B B SAHOO",
    saID: "SPR6196",
  },
  {
    firstName: "RAJIB KUMAR",
    lastName: "PAL",
    address:
      "SUBHADIP PRINTERS*SURESH BHAWAN*OP. S B SEORAH COLLEGE,J S RD*GOUHATI-781007*KAMRUP",
    fullName: "RAJIB KUMAR PAL",
    saID: "SPR6197",
  },
  {
    firstName: "LATE SUDHIR",
    lastName: "MALAKAR",
    address: "SELMATOR*PO&DIST-SYLHET*BANGLADESH",
    fullName: "LATE SUDHIR MALAKAR",
    saID: "SPR6198",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "RAI",
    address:
      "C-33/45,E-1 CHANDWA CHHITTUPUR*VARANASI*U.P.-221001*PAN AAYPR0987M",
    fullName: "SUNIL KUMAR RAI",
    saID: "SPR6199",
  },
  {
    firstName: "GOUTAM",
    lastName: "CHAKRABARTY",
    address:
      "RAJANI KANTA GOSWAMI PATH*LAL GANESH*PO-UDALBAKRA*GUWAHATI-781034*KAMRUP*AFOPC6724J",
    fullName: "GOUTAM CHAKRABARTY",
    saID: "SPR6200",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "MANDAL",
    address:
      "AT/PO-JAYKRISHNAPUR*P.S.-GALSI*DIST-BURDWAN 713406*PAN-CGTPM3128C.",
    fullName: "SANJIB KUMAR MANDAL",
    saID: "SPR6201",
  },
  {
    firstName: "PUJANIYA CHOTOBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHOTOBOUDI ",
    saID: "SPR6202",
  },
  {
    firstName: "06V ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "06V ASHOK CHAKRABORTY",
    saID: "SPR6203",
  },
  {
    firstName: "PUJANIYABARBAURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYABARBAURANI ",
    saID: "SPR6204",
  },
  {
    firstName: "RANGAMA",
    lastName: "",
    address: "",
    fullName: "RANGAMA ",
    saID: "SPR6205",
  },
  {
    firstName: "0EV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "0EV ASHOK CHAKRABORTY",
    saID: "SPR6206",
  },
  {
    firstName: "RV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "RV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6207",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6208",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6209",
  },
  {
    firstName: "PUJANIA  CHOTOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA  CHOTOBAUDI ",
    saID: "SPR6210",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK CHAKRABARTY",
    saID: "SPR6211",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6212",
  },
  {
    firstName: "PUJANIYA CHOTOBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHOTOBOUDI ",
    saID: "SPR6213",
  },
  {
    firstName: "SAROJ KUMAR",
    lastName: "PARIDA",
    address:
      '"SWASTI SUDHA"*66 SATSANG VIHAR ROAD*SRI SHANKAR NAGAR*PAMMAL*CHENNAI 75',
    fullName: "SAROJ KUMAR PARIDA",
    saID: "SPR6214",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6215",
  },
  {
    firstName: "RABI SANKAR",
    lastName: "PAN",
    address:
      "ICAR RCER,RESEARCH CENTRE,PLANDU*NAMKUM*RANCHI 834010 PAN.AFPPP7672B",
    fullName: "RABI SANKAR PAN",
    saID: "SPR6216",
  },
  {
    firstName: "PRASUN",
    lastName: "CHAKRABORTY",
    address:
      "SATSANG GOSSAINGAON*PO-GOSSAINGAON*KOKRAJHAR-783360*ASSAM*PAN-APUPC4443G",
    fullName: "PRASUN CHAKRABORTY",
    saID: "SPR6217",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6218",
  },
  {
    firstName: "RAGHUNATH",
    lastName: "MOHANTY",
    address: "AT-BHIRILINGI*ICHAPURAM*SRIKAKULAM 532 312",
    fullName: "RAGHUNATH MOHANTY",
    saID: "SPR6219",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6220",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6221",
  },
  {
    firstName: "SATYEN",
    lastName: "MAHATO",
    address: "VILL-RUCHAP*PO-KURNI*VIA-RANGADIH*DT-PURULIA 723143",
    fullName: "SATYEN MAHATO",
    saID: "SPR6222",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6223",
  },
  {
    firstName: "MRITYUNJAY KUMAR",
    lastName: "SINGH",
    address: "CIVIL COURT BETTIAH*WEST CHAMPARAN 845438*PAN-CZBPS0370P",
    fullName: "MRITYUNJAY KUMAR SINGH",
    saID: "SPR6224",
  },
  {
    firstName: "PRANAB",
    lastName: "DEB",
    address: "",
    fullName: "PRANAB DEB",
    saID: "SPR6225",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6226",
  },
  {
    firstName: "ALAKESH CHANDRA",
    lastName: "ROY",
    address:
      "R.K.MISSION ROAD*SANKAR DEVAPATH BY LANE*PO-BIDYAPARA*DIST-DHUBRI 783324*AGRPR7408L",
    fullName: "ALAKESH CHANDRA ROY",
    saID: "SPR6227",
  },
  {
    firstName: "PABITRA MOHAN",
    lastName: "MISHRA",
    address: "BANIBIHAR 2ND LINE*AT/PO-ASKA*GANJAM-761110.*PAN.BDJPM6369R",
    fullName: "PABITRA MOHAN MISHRA",
    saID: "SPR6228",
  },
  {
    firstName: "SIB RAM",
    lastName: "CHAKRABORTY",
    address: "VILL/PO-NASIBPUR*HOOGHLY 712 223",
    fullName: "SIB RAM CHAKRABORTY",
    saID: "SPR6229",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "SEN",
    address: "",
    fullName: "MADHUSUDAN SEN",
    saID: "SPR6230",
  },
  {
    firstName: "TAPAS KUMAR",
    lastName: "SAHOO",
    address:
      "PLOT NO.216/40/1 AERODROME AREA*BHUBANESWAR 751009*PAN-AVEPS8140C",
    fullName: "TAPAS KUMAR SAHOO",
    saID: "SPR6231",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6232",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK CHAKRABORTY",
    saID: "SPR6233",
  },
  {
    firstName: "REV ASHOK",
    lastName: "",
    address: "",
    fullName: "REV ASHOK ",
    saID: "SPR6234",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6235",
  },
  {
    firstName: "SRI DIPAK KUMAR",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "SRI DIPAK KUMAR CHATTERJEE",
    saID: "SPR6236",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6237",
  },
  {
    firstName: "KHUSH BIHARI LAL",
    lastName: "KARN",
    address: "AT/PO-BELARAHI*MADHUBANI-847404",
    fullName: "KHUSH BIHARI LAL KARN",
    saID: "SPR6238",
  },
  {
    firstName: "A K (PHOTO)",
    lastName: "ROYCHOUDHURY",
    address: "",
    fullName: "A K (PHOTO) ROYCHOUDHURY",
    saID: "SPR6239",
  },
  {
    firstName: "SATIPRASAD",
    lastName: "PALAI",
    address: "",
    fullName: "SATIPRASAD PALAI",
    saID: "SPR6240",
  },
  {
    firstName: "GOPAL DA",
    lastName: "",
    address: "",
    fullName: "GOPAL DA ",
    saID: "SPR6241",
  },
  {
    firstName: "PUJANIA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOUDI ",
    saID: "SPR6242",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6243",
  },
  {
    firstName: "PEV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PEV CHAKRABORTY",
    saID: "SPR6244",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6245",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6246",
  },
  {
    firstName: "BAIDYANATH",
    lastName: "GUPTA",
    address: "",
    fullName: "BAIDYANATH GUPTA",
    saID: "SPR6247",
  },
  {
    firstName: "PRADIP",
    lastName: "MONDAL",
    address: "",
    fullName: "PRADIP MONDAL",
    saID: "SPR6248",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6249",
  },
  {
    firstName: "REVA SHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVA SHOK CHAKRABORTY",
    saID: "SPR6250",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6251",
  },
  {
    firstName: "DIPRANJAN",
    lastName: "SARKAR",
    address: "SATSANG THAKUR BARI*SATSANG",
    fullName: "DIPRANJAN SARKAR",
    saID: "SPR6252",
  },
  {
    firstName: "AMIT KUMAR",
    lastName: "ROY",
    address: "203 SATIN SEN NAGAR*CALCUTTA 700035",
    fullName: "AMIT KUMAR ROY",
    saID: "SPR6253",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6254",
  },
  {
    firstName: "MANAS RANJAN",
    lastName: "PRADHAN",
    address:
      "M-S PALLI CLINIC*BRUNDABAN COLONY NO-1*PO-PARADEEP*JAGATSINGHPUR 754142",
    fullName: "MANAS RANJAN PRADHAN",
    saID: "SPR6255",
  },
  {
    firstName: "PUJANIYA  BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA  BARABAUDI ",
    saID: "SPR6256",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYUTI CHAKRABORTY",
    saID: "SPR6257",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6258",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6259",
  },
  {
    firstName: "EV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "EV ASHOK CHAKRABORTY",
    saID: "SPR6260",
  },
  {
    firstName: "DINA NATH",
    lastName: "SARKAR",
    address: "",
    fullName: "DINA NATH SARKAR",
    saID: "SPR6261",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6262",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6263",
  },
  {
    firstName: "PUJANIYA RANGABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGABOUDI ",
    saID: "SPR6264",
  },
  {
    firstName: "PUJANIYA RANGABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGABOUDI ",
    saID: "SPR6265",
  },
  {
    firstName: "LATE RICHISH",
    lastName: "SENGUPTA",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE RICHISH SENGUPTA",
    saID: "SPR6266",
  },
  {
    firstName: "LATE R",
    lastName: "SEETHARAMAN",
    address: "13 DEIVASIGAMANI STREET*ROYAPETTA*CHENNAI 600014",
    fullName: "LATE R SEETHARAMAN",
    saID: "SPR6267",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6268",
  },
  {
    firstName: "KIRAN",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "KIRAN CHAKRABORTY",
    saID: "SPR6269",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6270",
  },
  {
    firstName: "DHARMENDRA",
    lastName: "NARAYAN",
    address:
      "S/O BISHNUDEO NARAYAN*NEAR PIPALSHIV MANDIR*HAMIDGANJ*PO-DALTANGANJ*PALAMU 822101",
    fullName: "DHARMENDRA NARAYAN",
    saID: "SPR6271",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MANDAL",
    address: "C/O-SANJAY KUMAR MANDAL*VILL/PO-BARSUL*BURDWAN-713124",
    fullName: "RANJIT KUMAR MANDAL",
    saID: "SPR6272",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6273",
  },
  {
    firstName: "SANTIMOY",
    lastName: "MONDAL",
    address: "AT-DIGNALA*PO-ANDAL*BURDWAN-713321*PAN-CNJPM5767N",
    fullName: "SANTIMOY MONDAL",
    saID: "SPR6274",
  },
  {
    firstName: "PUJANIA BARA BAUMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BAUMA ",
    saID: "SPR6275",
  },
  {
    firstName: "PUJANIA BARA BAUMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BAUMA ",
    saID: "SPR6276",
  },
  {
    firstName: "REV ASHOK9",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK9 CHAKRABORTY",
    saID: "SPR6277",
  },
  {
    firstName: "SUBRATA",
    lastName: "DAS",
    address: "SRICHAKRA CYCLE STORES*PO-THELAMARA*SONTPUR (ASSAM) 784149",
    fullName: "SUBRATA DAS",
    saID: "SPR6278",
  },
  {
    firstName: "G D",
    lastName: "PRADHAN",
    address: "",
    fullName: "G D PRADHAN",
    saID: "SPR6279",
  },
  {
    firstName: "ABHIRUP",
    lastName: "MANDAL",
    address: "VILL-CHARPANPARA*PO-HARIPUR NADIA*NADIA-741404",
    fullName: "ABHIRUP MANDAL",
    saID: "SPR6280",
  },
  {
    firstName: "SOMA DIDI",
    lastName: "",
    address: "",
    fullName: "SOMA DIDI ",
    saID: "SPR6281",
  },
  {
    firstName: "AVRA  BHAI",
    lastName: "",
    address: "",
    fullName: "AVRA  BHAI ",
    saID: "SPR6282",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6283",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6284",
  },
  {
    firstName: "AKHIL CHANDRA",
    lastName: "MANDAL",
    address: "VILL-NISHINDA*PO-GHOSHPARA*DIST-HOWRAH-711401",
    fullName: "AKHIL CHANDRA MANDAL",
    saID: "SPR6285",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6286",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6287",
  },
  {
    firstName: "RE",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "RE CHAKRABARTY",
    saID: "SPR6288",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6289",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6290",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6291",
  },
  {
    firstName: "ACBHGGGG",
    lastName: "",
    address: "",
    fullName: "ACBHGGGG ",
    saID: "SPR6292",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABARTY",
    saID: "SPR6293",
  },
  {
    firstName: "DHRITISUNDER",
    lastName: "BHATTACHARJEE",
    address: "PO-SATSANG*DEOGHAR-814116",
    fullName: "DHRITISUNDER BHATTACHARJEE",
    saID: "SPR6294",
  },
  {
    firstName: "SUBIR",
    lastName: "CHAKRABORTY",
    address: "CITY STORE*PO-BINOVA NAGAR*GUWAHATI-18*KAMRUP-781018.",
    fullName: "SUBIR CHAKRABORTY",
    saID: "SPR6295",
  },
  {
    firstName: "PUJANIA  BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA  BARABOUDI ",
    saID: "SPR6296",
  },
  {
    firstName: "SOMA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "SOMA CHAKRABARTY",
    saID: "SPR6297",
  },
  {
    firstName: "AMRITENDU",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "AMRITENDU CHAKRABARTY",
    saID: "SPR6298",
  },
  {
    firstName: "ANUBRATA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ANUBRATA CHAKRABARTY",
    saID: "SPR6299",
  },
  {
    firstName: "AGNIDEV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "AGNIDEV CHAKRABARTY",
    saID: "SPR6300",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6301",
  },
  {
    firstName: "BRINDABAN",
    lastName: "MAHATO",
    address: "AT-KUMARDIH*PO-ICHHAR*PURULIA 723145",
    fullName: "BRINDABAN MAHATO",
    saID: "SPR6302",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6303",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6304",
  },
  {
    firstName: "PUJANIYA DIPTI DI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA DIPTI DI ",
    saID: "SPR6305",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6306",
  },
  {
    firstName: "KRITI KUMAR",
    lastName: "GANGULI",
    address:
      "2/304, NEW MALHAR*SAHARA STATE, JANKIPURAM*LUCKNOW-226001*PAN AFIPG4609J",
    fullName: "KRITI KUMAR GANGULI",
    saID: "SPR6307",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6308",
  },
  {
    firstName: "SUKASH RANJAN",
    lastName: "BHOWMIK",
    address:
      "DEEPSHIKHA TOWER(A3)*AT-MAKARBAG SAHI*PO-BUXI BAZAR*CUTTACK-753001.",
    fullName: "SUKASH RANJAN BHOWMIK",
    saID: "SPR6309",
  },
  {
    firstName: "MALAY",
    lastName: "SARKAR",
    address: "SATSANG*DEOGHAR-814116.",
    fullName: "MALAY SARKAR",
    saID: "SPR6310",
  },
  {
    firstName: "RADHAGOBINDA",
    lastName: "DAS",
    address: "SATSANG*DEOGHAR",
    fullName: "RADHAGOBINDA DAS",
    saID: "SPR6311",
  },
  {
    firstName: "THAKUR PRASAD",
    lastName: "SINGH",
    address: "R F 319*KANKAR BAGH*PATNA 800020",
    fullName: "THAKUR PRASAD SINGH",
    saID: "SPR6312",
  },
  {
    firstName: "ANUPA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ANUPA CHAKRABARTY",
    saID: "SPR6313",
  },
  {
    firstName: "ANUPA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ANUPA CHAKRABARTY",
    saID: "SPR6314",
  },
  {
    firstName: "DIPTI",
    lastName: "MAITRA",
    address: "",
    fullName: "DIPTI MAITRA",
    saID: "SPR6315",
  },
  {
    firstName: "PUJANIA BAROBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BAROBAUDI ",
    saID: "SPR6316",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6317",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6318",
  },
  {
    firstName: "S N",
    lastName: "MISRA",
    address: "",
    fullName: "S N MISRA",
    saID: "SPR6319",
  },
  {
    firstName: "PUJANIYA PISHIMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA PISHIMA ",
    saID: "SPR6320",
  },
  {
    firstName: "PUJANIYA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGAMA ",
    saID: "SPR6321",
  },
  {
    firstName: "REVA SHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVA SHOKE CHAKRABORTY",
    saID: "SPR6322",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "ROYCHOUDHURY",
    address: "",
    fullName: "ARUN KUMAR ROYCHOUDHURY",
    saID: "SPR6323",
  },
  {
    firstName: "DIPRANJA",
    lastName: "SARKAR",
    address: "",
    fullName: "DIPRANJA SARKAR",
    saID: "SPR6324",
  },
  {
    firstName: "NILAY",
    lastName: "MUKHERJEE",
    address: "P/22,ARCADIA  EXTENSION*BEHALA*CALCUTTA-700034.",
    fullName: "NILAY MUKHERJEE",
    saID: "SPR6325",
  },
  {
    firstName: "RABINDRANATH",
    lastName: "DUTTA",
    address: "",
    fullName: "RABINDRANATH DUTTA",
    saID: "SPR6326",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "SAHOO",
    address: "AT-HARIDAS*PO-KUSABENTI*PURI",
    fullName: "RAMESH CHANDRA SAHOO",
    saID: "SPR6327",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6328",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6329",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6330",
  },
  {
    firstName: "GOURHARI",
    lastName: "CHAKRABORTY",
    address: "AT/PO-AHARPADA*VIA-GUJIDARODA*BHADRAK-756128*PAN-AIPPC1727R",
    fullName: "GOURHARI CHAKRABORTY",
    saID: "SPR6331",
  },
  {
    firstName: "RANDHIR KUMAR",
    lastName: "THAKUR",
    address:
      "C-303 TULSI PRERNA CO.OP.HS LTD*SEC-I PLOT-9 KHANDA COLONY*NEW PANVEL*RAIGAD 410206",
    fullName: "RANDHIR KUMAR THAKUR",
    saID: "SPR6332",
  },
  {
    firstName: "NAGEN CHANDRA",
    lastName: "DAS",
    address: "RAMPUR AJAGARA*PO-BARAJOL*NALBARI-781369.",
    fullName: "NAGEN CHANDRA DAS",
    saID: "SPR6333",
  },
  {
    firstName: "TAPASH",
    lastName: "PAUL",
    address: "SATSANG VIHAR*PO-LABAN *SHILLONG-793004*MEGHALAYA",
    fullName: "TAPASH PAUL",
    saID: "SPR6334",
  },
  {
    firstName: "SWARUP KUMAR",
    lastName: "MATHUR",
    address: "63,SHASTRINAGAR*LUCKNOW-226004*U.P.*PAN-ANFPM2622L",
    fullName: "SWARUP KUMAR MATHUR",
    saID: "SPR6335",
  },
  {
    firstName: "TAPAN",
    lastName: "PAL",
    address: "RILBONG*SHILONG-793004*MEGHALAYA",
    fullName: "TAPAN PAL",
    saID: "SPR6336",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "BANERJEE",
    address: "HUCHUKPARA*PO & DT-PURULIA*PAN-ADAPB4784D",
    fullName: "CHITTARANJAN BANERJEE",
    saID: "SPR6337",
  },
  {
    firstName: "BHABATOSH",
    lastName: "PAL",
    address: "",
    fullName: "BHABATOSH PAL",
    saID: "SPR6338",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6339",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6340",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6341",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6342",
  },
  {
    firstName: "BIKASH",
    lastName: "NANDI",
    address: "VILL-CHATRA*PO-DARAPUR*DIST-BANKURA 722141",
    fullName: "BIKASH NANDI",
    saID: "SPR6343",
  },
  {
    firstName: "BIRANCHI NARAYAN",
    lastName: "DAS",
    address: "SATSANG VIHAR,VYASANAGAR*JAJPUR ROAD*DIST-JAJPUR-755019",
    fullName: "BIRANCHI NARAYAN DAS",
    saID: "SPR6344",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6345",
  },
  {
    firstName: "R K",
    lastName: "MISHRA",
    address: "",
    fullName: "R K MISHRA",
    saID: "SPR6346",
  },
  {
    firstName: "ANUPAM",
    lastName: "PATRA",
    address: "",
    fullName: "ANUPAM PATRA",
    saID: "SPR6347",
  },
  {
    firstName: "BINTU",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "BINTU CHAKRAVORTY",
    saID: "SPR6348",
  },
  {
    firstName: "REVCHAKRABARTY",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REVCHAKRABARTY CHAKRABARTY",
    saID: "SPR6349",
  },
  {
    firstName: "GADADHAR",
    lastName: "PARIDA",
    address: "",
    fullName: "GADADHAR PARIDA",
    saID: "SPR6350",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6351",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6352",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "SHADANGI",
    address:
      "AT/PO-PARSURAMPUR*VIA-TEKKALI*DIST-SRIKAKULUM*ANDHRA PRADESH 532202",
    fullName: "SANTOSH KUMAR SHADANGI",
    saID: "SPR6353",
  },
  {
    firstName: "DILIP",
    lastName: "RAY",
    address: "PO-JAGI ROAD*MORIGAON-782 410*ASSAM*PAN-AGMPR0585M",
    fullName: "DILIP RAY",
    saID: "SPR6354",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "SARKAR",
    address: "",
    fullName: "JITENDRA NATH SARKAR",
    saID: "SPR6355",
  },
  {
    firstName: "NIRANJAN",
    lastName: "ROUT",
    address:
      "QRS NO-23504/5,TYPE-II*AT&PO-O F BADMAL*BOLANGIR 767 070*PAN-AUDPR7671R",
    fullName: "NIRANJAN ROUT",
    saID: "SPR6356",
  },
  {
    firstName: "RATAN KUMAR",
    lastName: "DANA",
    address: "VILL&PO-BARABAINAN*BURDWAN-713421",
    fullName: "RATAN KUMAR DANA",
    saID: "SPR6357",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6358",
  },
  {
    firstName: "BODHIBYAS",
    lastName: "BHATTACHARYA",
    address: "AT/PO-SATSANG*DEOGHAR 814116",
    fullName: "BODHIBYAS BHATTACHARYA",
    saID: "SPR6359",
  },
  {
    firstName: "PUJANIA BARA BOWDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BOWDI ",
    saID: "SPR6360",
  },
  {
    firstName: "GOBINDA",
    lastName: "ROY",
    address: "VILL-MITTROPUR*PO-KASTADANGA*NADIA 741257",
    fullName: "GOBINDA ROY",
    saID: "SPR6361",
  },
  {
    firstName: "DAYAL PRASAD",
    lastName: "BHATTACHARJEE",
    address: "KARIDHYA SATSANG COLONY*KARIDHYA, BIRBHUM.",
    fullName: "DAYAL PRASAD BHATTACHARJEE",
    saID: "SPR6362",
  },
  {
    firstName: "KSHOUNISH",
    lastName: "KIRTANIA",
    address: "RLY QRT NO-3/A*NAVA BARRACKPORE*24 PGS NORTH-700131",
    fullName: "KSHOUNISH KIRTANIA",
    saID: "SPR6363",
  },
  {
    firstName: "HRIDESH",
    lastName: "RANJAN",
    address: "",
    fullName: "HRIDESH RANJAN",
    saID: "SPR6364",
  },
  {
    firstName: "REVA SHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVA SHOK CHAKRABORTY",
    saID: "SPR6365",
  },
  {
    firstName: "PUJANIA BAROBOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BAROBOUDI ",
    saID: "SPR6366",
  },
  {
    firstName: "PUJANIYA MOUMITA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MOUMITA ",
    saID: "SPR6367",
  },
  {
    firstName: "CHHOTO BAURANIMA",
    lastName: "",
    address: "",
    fullName: "CHHOTO BAURANIMA ",
    saID: "SPR6368",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6369",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6370",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6371",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6372",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6373",
  },
  {
    firstName: "MAHUA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "MAHUA CHAKRABORTY",
    saID: "SPR6374",
  },
  {
    firstName: "MOUMITA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "MOUMITA CHAKRABORTY",
    saID: "SPR6375",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "GIRI",
    address: "VILL-MADHYA JHORAHAT*NEAR KALITALA*PO-JHORHAT*HOWRAH 711 302",
    fullName: "PABITRA KUMAR GIRI",
    saID: "SPR6376",
  },
  {
    firstName: "REV RANGAMA",
    lastName: "",
    address: "",
    fullName: "REV RANGAMA ",
    saID: "SPR6377",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6378",
  },
  {
    firstName: "NIRA PADA",
    lastName: "PANDA",
    address: "",
    fullName: "NIRA PADA PANDA",
    saID: "SPR6379",
  },
  {
    firstName: "S",
    lastName: "mahato",
    address: "",
    fullName: "S mahato",
    saID: "SPR6380",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6381",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK CHAKRABARTY",
    saID: "SPR6382",
  },
  {
    firstName: "REV AMAL",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV AMAL CHAKRABARTY",
    saID: "SPR6383",
  },
  {
    firstName: "PUJANIYA SEJABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA SEJABOUDI ",
    saID: "SPR6384",
  },
  {
    firstName: "PUJANIYA NABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA NABOUDI ",
    saID: "SPR6385",
  },
  {
    firstName: "M C",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "M C CHAKRABORTY",
    saID: "SPR6386",
  },
  {
    firstName: "M C",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "M C CHAKRABORTY",
    saID: "SPR6387",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6388",
  },
  {
    firstName: "LATE SUKUMAR",
    lastName: "DEY",
    address:
      "C/O-GOPAL CHANDRA DEY*48/4, HARISH MUKHERJEE LANE*PO-BHADRAKHALI*HOOGHLY.",
    fullName: "LATE SUKUMAR DEY",
    saID: "SPR6389",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASOK CHAKRABORTY",
    saID: "SPR6390",
  },
  {
    firstName: "BYOMKESH",
    lastName: "DEY",
    address: "",
    fullName: "BYOMKESH DEY",
    saID: "SPR6391",
  },
  {
    firstName: "BYOMKESH",
    lastName: "DEY",
    address: "",
    fullName: "BYOMKESH DEY",
    saID: "SPR6392",
  },
  {
    firstName: "BYOMKESH",
    lastName: "DEY",
    address: "",
    fullName: "BYOMKESH DEY",
    saID: "SPR6393",
  },
  {
    firstName: "BYOMKESH",
    lastName: "DEY",
    address: "",
    fullName: "BYOMKESH DEY",
    saID: "SPR6394",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6395",
  },
  {
    firstName: "DIPADRU",
    lastName: "DEV",
    address: "PO-SRIPURIA*TINSUKIA-786145*ASSAM.",
    fullName: "DIPADRU DEV",
    saID: "SPR6396",
  },
  {
    firstName: "PUJANIYA BHOURANIMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BHOURANIMA ",
    saID: "SPR6397",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYUTI CHAKRABORTY",
    saID: "SPR6398",
  },
  {
    firstName: "JAGATJYOTI",
    lastName: "ROYCHATTERJEE",
    address: "",
    fullName: "JAGATJYOTI ROYCHATTERJEE",
    saID: "SPR6399",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6400",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6401",
  },
  {
    firstName: "ANUP KUMAR",
    lastName: "CHAKRABORTY",
    address:
      "C/O-SHANKAR CHAKRABORTY*VILL-HARIHARPUR*PO-HRIDAYPUR*24PARGANAS (NORTH)-743204.",
    fullName: "ANUP KUMAR CHAKRABORTY",
    saID: "SPR6402",
  },
  {
    firstName: "REV",
    lastName: "",
    address: "",
    fullName: "REV ",
    saID: "SPR6403",
  },
  {
    firstName: "PITA SIV CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "PITA SIV CHANDRA CHAKRAVARTY",
    saID: "SPR6404",
  },
  {
    firstName: "MATA MANA MOHINI",
    lastName: "DEVI",
    address: "",
    fullName: "MATA MANA MOHINI DEVI",
    saID: "SPR6405",
  },
  {
    firstName: "ARCHANA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARCHANA CHAKRABORTY",
    saID: "SPR6406",
  },
  {
    firstName: "SARANHDHAR",
    lastName: "SINGH",
    address: "",
    fullName: "SARANHDHAR SINGH",
    saID: "SPR6407",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6408",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6409",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6410",
  },
  {
    firstName: "JAGABANDHU",
    lastName: "DASSARMA",
    address: "",
    fullName: "JAGABANDHU DASSARMA",
    saID: "SPR6411",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6412",
  },
  {
    firstName: "HARI PADA",
    lastName: "GHOSH",
    address: "",
    fullName: "HARI PADA GHOSH",
    saID: "SPR6413",
  },
  {
    firstName: "BISWANATH",
    lastName: "SENAPATI",
    address: "",
    fullName: "BISWANATH SENAPATI",
    saID: "SPR6414",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6415",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6416",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6417",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6418",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6419",
  },
  {
    firstName: "REV ASHO KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHO KUMAR CHAKRAVARTY",
    saID: "SPR6420",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6421",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SWAIN",
    address: "",
    fullName: "SURENDRA NATH SWAIN",
    saID: "SPR6422",
  },
  {
    firstName: "SUNIL",
    lastName: "DAS",
    address: "",
    fullName: "SUNIL DAS",
    saID: "SPR6423",
  },
  {
    firstName: "GPENDRA",
    lastName: "SWARGIARY",
    address: "",
    fullName: "GPENDRA SWARGIARY",
    saID: "SPR6424",
  },
  {
    firstName: "GOPENDRA",
    lastName: "SWARGIARY",
    address: "",
    fullName: "GOPENDRA SWARGIARY",
    saID: "SPR6425",
  },
  {
    firstName: "GOPENDRA",
    lastName: "SWARGIARY",
    address: "",
    fullName: "GOPENDRA SWARGIARY",
    saID: "SPR6426",
  },
  {
    firstName: "GOPENDRA",
    lastName: "SWARGIARY",
    address: "",
    fullName: "GOPENDRA SWARGIARY",
    saID: "SPR6427",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6428",
  },
  {
    firstName: "PUJANIYA MOUMITA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "PUJANIYA MOUMITA CHAKRABARTY",
    saID: "SPR6429",
  },
  {
    firstName: "PUJANIYA MAHUA",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "PUJANIYA MAHUA CHAKRABARTY",
    saID: "SPR6430",
  },
  {
    firstName: "BINAY KUMAR",
    lastName: "NAYAK",
    address: "",
    fullName: "BINAY KUMAR NAYAK",
    saID: "SPR6431",
  },
  {
    firstName: "PANKAJ",
    lastName: "CHAKRABORTY",
    address: "121 RAMESH GOSWAMI RD*KANCHRAPARA*24 PARGANAS-(NORTH)-743 145.",
    fullName: "PANKAJ CHAKRABORTY",
    saID: "SPR6432",
  },
  {
    firstName: "SUDHANGSHU SHEKHAR",
    lastName: "MAJUMDER",
    address: "AT/PO-AMBADOLA*RAYAGADA 765021,ORISSA *PAN-BFUPM7156M.",
    fullName: "SUDHANGSHU SHEKHAR MAJUMDER",
    saID: "SPR6433",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "NAYAK",
    address: "17E/580 CHOPASNI HOUSING BOARD*JODHPUR*RAJASTHAN 342008",
    fullName: "DILIP KUMAR NAYAK",
    saID: "SPR6434",
  },
  {
    firstName: "RITISH",
    lastName: "SENGUPTA",
    address: "SATSANG COLONY*SATSANG*DEOGHAR",
    fullName: "RITISH SENGUPTA",
    saID: "SPR6435",
  },
  {
    firstName: "MANOJ",
    lastName: "DAS",
    address: "OUPHULIA TE*PO-DIKSOM DUPHULIA*DIBRUGARH 786 613",
    fullName: "MANOJ DAS",
    saID: "SPR6436",
  },
  {
    firstName: "GOURISH CHANDRA",
    lastName: "CHAKRAVORTY",
    address:
      "C/O R B ENCLAVE*SRIKANTA DUTTA PATH*LALGANESH,UDALBAKRA*GUWAHATI-34*PAN-AKZPC7116L",
    fullName: "GOURISH CHANDRA CHAKRAVORTY",
    saID: "SPR6437",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6438",
  },
  {
    firstName: "MATUK",
    lastName: "MAHATO",
    address: "VILL-LAKHIPUR*PO-KELIASHOLE*DHANBAD-828205",
    fullName: "MATUK MAHATO",
    saID: "SPR6439",
  },
  {
    firstName: "SUREN",
    lastName: "MITRA",
    address: "",
    fullName: "SUREN MITRA",
    saID: "SPR6440",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6441",
  },
  {
    firstName: "PUJANIA",
    lastName: "",
    address: "",
    fullName: "PUJANIA ",
    saID: "SPR6442",
  },
  {
    firstName: "TARAPADA",
    lastName: "ROY",
    address: "",
    fullName: "TARAPADA ROY",
    saID: "SPR6443",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "NATH",
    address: "AT-NANDAN KANAN*PO-NABAPALLI*24PARGANAS NORTH 743203",
    fullName: "PARIMAL CHANDRA NATH",
    saID: "SPR6444",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6445",
  },
  {
    firstName: "TAPAN",
    lastName: "SANYAL",
    address:
      "PRANTIK PHASE IV;BL-6;FLAT-16*THAKURPUKUR CO-OP HSG SOCIETY LTD*KOL-104*CRZPS6607R.",
    fullName: "TAPAN SANYAL",
    saID: "SPR6446",
  },
  {
    firstName: "REC",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REC CHAKRABORTY",
    saID: "SPR6447",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6448",
  },
  {
    firstName: "RADHA",
    lastName: "MISTRI",
    address: "",
    fullName: "RADHA MISTRI",
    saID: "SPR6449",
  },
  {
    firstName: "PUJANIYA CHOTOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHOTOBAUDI ",
    saID: "SPR6450",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6451",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6452",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6453",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6454",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6455",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6456",
  },
  {
    firstName: "ANUBRATAV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ANUBRATAV CHAKRABARTY",
    saID: "SPR6457",
  },
  {
    firstName: "REV RANGA MAA",
    lastName: "",
    address: "",
    fullName: "REV RANGA MAA ",
    saID: "SPR6458",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6459",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6460",
  },
  {
    firstName: "ABINENDRA NATH",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ABINENDRA NATH CHAKRABARTY",
    saID: "SPR6461",
  },
  {
    firstName: "LATE GIRISH",
    lastName: "BHATTACHARJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE GIRISH BHATTACHARJEE",
    saID: "SPR6462",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "DAS",
    address: "C/O SRI SANJIB KUMAR MONDAL*SATSANG",
    fullName: "ASHUTOSH DAS",
    saID: "SPR6463",
  },
  {
    firstName: "AMIYANSHU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AMIYANSHU CHAKRABORTY",
    saID: "SPR6464",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6465",
  },
  {
    firstName: "RATI RANJAN",
    lastName: "DUTTA",
    address: "",
    fullName: "RATI RANJAN DUTTA",
    saID: "SPR6466",
  },
  {
    firstName: "REV.",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV. CHAKRAVORTY",
    saID: "SPR6467",
  },
  {
    firstName: "PUJANIA RANGA MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGA MAA ",
    saID: "SPR6468",
  },
  {
    firstName: "PUJANIA RANGA MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGA MAA ",
    saID: "SPR6469",
  },
  {
    firstName: "PUJANIA BARA BAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BAUDI ",
    saID: "SPR6470",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6471",
  },
  {
    firstName: "KAILASH",
    lastName: "DUBEY",
    address: "",
    fullName: "KAILASH DUBEY",
    saID: "SPR6472",
  },
  {
    firstName: "PRABIR KANTI",
    lastName: "CHOUDHURY",
    address: "SIBMANDIR*PO-KADAMTALA*DARJEELING-734011*PAN-AEIPC4088C",
    fullName: "PRABIR KANTI CHOUDHURY",
    saID: "SPR6473",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6474",
  },
  {
    firstName: "PUJANIYA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ",
    saID: "SPR6475",
  },
  {
    firstName: "6EV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "6EV ASHOK CHAKRABORTY",
    saID: "SPR6476",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR6477",
  },
  {
    firstName: "BHABANI PRASAD",
    lastName: "DINDA",
    address: "VILL-PURUSHOTTAMPUR*PO-SAGAR BAGBAJAR*24PARGANAS (SOUTH)  743373",
    fullName: "BHABANI PRASAD DINDA",
    saID: "SPR6478",
  },
  {
    firstName: "JITENDRA NATH",
    lastName: "DEBBARMA",
    address: "",
    fullName: "JITENDRA NATH DEBBARMA",
    saID: "SPR6479",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6480",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6481",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6482",
  },
  {
    firstName: "KRITI RANJAN",
    lastName: "PRADHAN",
    address: "DT-30/1,MAMC TOWNSHIP*PO-DURGAPUR-713210*BURDWAN*PAN-AKSPP7613C",
    fullName: "KRITI RANJAN PRADHAN",
    saID: "SPR6483",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR6484",
  },
  {
    firstName: "DIPTI DIDI",
    lastName: "",
    address: "",
    fullName: "DIPTI DIDI ",
    saID: "SPR6485",
  },
  {
    firstName: "SATWATA",
    lastName: "MAITRA",
    address: "",
    fullName: "SATWATA MAITRA",
    saID: "SPR6486",
  },
  {
    firstName: "SWATARUPA",
    lastName: "MAITRA",
    address: "",
    fullName: "SWATARUPA MAITRA",
    saID: "SPR6487",
  },
  {
    firstName: "AVIK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "AVIK CHAKRABARTY",
    saID: "SPR6488",
  },
  {
    firstName: "ACHISMAN",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ACHISMAN CHAKRABARTY",
    saID: "SPR6489",
  },
  {
    firstName: "PRASANTA KUMAR",
    lastName: "PRADHAN",
    address: "SRIDHAR NAGAR-2*PO-PARLAKHEMUNDI*GANJAM-761200.",
    fullName: "PRASANTA KUMAR PRADHAN",
    saID: "SPR6490",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6491",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6492",
  },
  {
    firstName: "PUJANIYA ANURADHA",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "PUJANIYA ANURADHA BHATTACHERJEE",
    saID: "SPR6493",
  },
  {
    firstName: "SATARUPA",
    lastName: "MAITRA",
    address: "",
    fullName: "SATARUPA MAITRA",
    saID: "SPR6494",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6495",
  },
  {
    firstName: "P",
    lastName: "MUKHARJEE",
    address: "",
    fullName: "P MUKHARJEE",
    saID: "SPR6496",
  },
  {
    firstName: "RAM KINKAR",
    lastName: "THAKUR",
    address:
      "12 PRATHAM ENCLAVE*ATILADARA ROAD*VADODARA*GUJARAT 390012*PAN-ACVPT6200A",
    fullName: "RAM KINKAR THAKUR",
    saID: "SPR6497",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6498",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "SINGH",
    address: "",
    fullName: "ARUN KUMAR SINGH",
    saID: "SPR6499",
  },
  {
    firstName: "REV BARADIDI",
    lastName: "",
    address: "",
    fullName: "REV BARADIDI ",
    saID: "SPR6500",
  },
  {
    firstName: "BAKULJYOTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "BAKULJYOTI CHAKRABORTY",
    saID: "SPR6501",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR6502",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR6503",
  },
  {
    firstName: "LATE PRASAD",
    lastName: "CHATTERJEE",
    address: "AT-PALPARA*MANKUNDU*PO-M G COLONY*HOOGHLY",
    fullName: "LATE PRASAD CHATTERJEE",
    saID: "SPR6504",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6505",
  },
  {
    firstName: "SHANKAR SAN",
    lastName: "SHYAMAL",
    address: "",
    fullName: "SHANKAR SAN SHYAMAL",
    saID: "SPR6506",
  },
  {
    firstName: "MURALI DHAR",
    lastName: "PAUL",
    address: "",
    fullName: "MURALI DHAR PAUL",
    saID: "SPR6507",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6508",
  },
  {
    firstName: "JAGAJYOTI",
    lastName: "ROY",
    address: "",
    fullName: "JAGAJYOTI ROY",
    saID: "SPR6509",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6510",
  },
  {
    firstName: "TARASANKAR",
    lastName: "BANERJEE",
    address: "",
    fullName: "TARASANKAR BANERJEE",
    saID: "SPR6511",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR6512",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6513",
  },
  {
    firstName: "ARKDUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKDUTI CHAKRABORTY",
    saID: "SPR6514",
  },
  {
    firstName: "SARANGSHAR",
    lastName: "SINGH",
    address: "",
    fullName: "SARANGSHAR SINGH",
    saID: "SPR6515",
  },
  {
    firstName: "PUJANIA BARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOURANI ",
    saID: "SPR6516",
  },
  {
    firstName: "SITESH",
    lastName: "DAS",
    address: "",
    fullName: "SITESH DAS",
    saID: "SPR6517",
  },
  {
    firstName: "6EV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "6EV CHAKRAVORTY",
    saID: "SPR6518",
  },
  {
    firstName: "ANURADHA",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "ANURADHA BHATTACHARJEE",
    saID: "SPR6519",
  },
  {
    firstName: "ANURADHA",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "ANURADHA BHATTACHARJEE",
    saID: "SPR6520",
  },
  {
    firstName: "DIPTI",
    lastName: "MOITRA",
    address: "",
    fullName: "DIPTI MOITRA",
    saID: "SPR6521",
  },
  {
    firstName: "ARCHISMAN",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARCHISMAN CHAKRABORTY",
    saID: "SPR6522",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6523",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "PODDAR",
    address: "",
    fullName: "SANTOSH KUMAR PODDAR",
    saID: "SPR6524",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6525",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6526",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6527",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6528",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6529",
  },
  {
    firstName: "REV BABAIDADA",
    lastName: "",
    address: "",
    fullName: "REV BABAIDADA ",
    saID: "SPR6530",
  },
  {
    firstName: "DIPTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "DIPTI CHAKRABARTY",
    saID: "SPR6531",
  },
  {
    firstName: "ANURADHA",
    lastName: "MAITRA",
    address: "",
    fullName: "ANURADHA MAITRA",
    saID: "SPR6532",
  },
  {
    firstName: "SATYARUPA",
    lastName: "MAITRA",
    address: "",
    fullName: "SATYARUPA MAITRA",
    saID: "SPR6533",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6534",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6535",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6536",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6537",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6538",
  },
  {
    firstName: "LATE KEDAR NATH",
    lastName: "BHATTACHARJEE",
    address: "SATSANG",
    fullName: "LATE KEDAR NATH BHATTACHARJEE",
    saID: "SPR6539",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6540",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6541",
  },
  {
    firstName: "RE ASOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "RE ASOK CHAKRABARTY",
    saID: "SPR6542",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6543",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6544",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR6545",
  },
  {
    firstName: "RE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RE CHAKRABORTY",
    saID: "SPR6546",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "GHOSH",
    address: "54A/2 SISIR BAGAN ROAD*BEHALA*CALCUTTA 700034",
    fullName: "TAPAN KUMAR GHOSH",
    saID: "SPR6547",
  },
  {
    firstName: "REV",
    lastName: "",
    address: "",
    fullName: "REV ",
    saID: "SPR6548",
  },
  {
    firstName: "REV RANGAMA",
    lastName: "",
    address: "",
    fullName: "REV RANGAMA ",
    saID: "SPR6549",
  },
  {
    firstName: "ABINENDRANATH",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABINENDRANATH CHAKRABORTY",
    saID: "SPR6550",
  },
  {
    firstName: "SAJAL",
    lastName: "PAL",
    address: "",
    fullName: "SAJAL PAL",
    saID: "SPR6551",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR6552",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6553",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6554",
  },
  {
    firstName: "HARIAS",
    lastName: "MALLIK",
    address: "",
    fullName: "HARIAS MALLIK",
    saID: "SPR6555",
  },
  {
    firstName: "SASWATA",
    lastName: "MAITRA",
    address: "",
    fullName: "SASWATA MAITRA",
    saID: "SPR6556",
  },
  {
    firstName: "PUJANIA",
    lastName: "",
    address: "",
    fullName: "PUJANIA ",
    saID: "SPR6557",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6558",
  },
  {
    firstName: "PUJANIYA KALYANIDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA KALYANIDI ",
    saID: "SPR6559",
  },
  {
    firstName: "DHARANI KANTA",
    lastName: "ROY",
    address: "",
    fullName: "DHARANI KANTA ROY",
    saID: "SPR6560",
  },
  {
    firstName: "REV PRASADI PISHIMA",
    lastName: "",
    address: "",
    fullName: "REV PRASADI PISHIMA ",
    saID: "SPR6561",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6562",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR6563",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6564",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6565",
  },
  {
    firstName: "BASAT",
    lastName: "SAHOO",
    address: "",
    fullName: "BASAT SAHOO",
    saID: "SPR6566",
  },
  {
    firstName: "AVRAPRATIP",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "AVRAPRATIP CHAKRABARTY",
    saID: "SPR6567",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6568",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6569",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR6570",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6571",
  },
  {
    firstName: "HRIK SUNDAR",
    lastName: "SARKAR",
    address: "",
    fullName: "HRIK SUNDAR SARKAR",
    saID: "SPR6572",
  },
  {
    firstName: "EV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "EV CHAKRABORTY",
    saID: "SPR6573",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6574",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6575",
  },
  {
    firstName: "DIPRANJAN",
    lastName: "SARKARSARMA",
    address: "",
    fullName: "DIPRANJAN SARKARSARMA",
    saID: "SPR6576",
  },
  {
    firstName: "LATE ABINASH",
    lastName: "BHATTACHARJEE",
    address: "*CALCUTTA",
    fullName: "LATE ABINASH BHATTACHARJEE",
    saID: "SPR6577",
  },
  {
    firstName: "RAMASANKA",
    lastName: "TRIBEDI",
    address: "",
    fullName: "RAMASANKA TRIBEDI",
    saID: "SPR6578",
  },
  {
    firstName: "LATE ATUL CHANDRA",
    lastName: "BHATTACHERJEE",
    address: "CALCUTTA",
    fullName: "LATE ATUL CHANDRA BHATTACHERJEE",
    saID: "SPR6579",
  },
  {
    firstName: "LATE AMAR NATH",
    lastName: "TARAFDAR",
    address: "HOOGHLY",
    fullName: "LATE AMAR NATH TARAFDAR",
    saID: "SPR6580",
  },
  {
    firstName: "LATE AMULYA KUMAR",
    lastName: "BHATTACHARJEE",
    address: "24PARGANAS",
    fullName: "LATE AMULYA KUMAR BHATTACHARJEE",
    saID: "SPR6581",
  },
  {
    firstName: "KRISHANLAL",
    lastName: "AGARWALA",
    address: "",
    fullName: "KRISHANLAL AGARWALA",
    saID: "SPR6582",
  },
  {
    firstName: "CHIRA SUNDAR",
    lastName: "SEN",
    address: "PO-BAGHMARA*DIST-SOUTH GARO HILLS*MEGHALAYA-794102",
    fullName: "CHIRA SUNDAR SEN",
    saID: "SPR6583",
  },
  {
    firstName: "B ASIS KUMAR",
    lastName: "SUBUDHI",
    address:
      "SATSANG VIHAR,CHATRAPUR*NEAR NEW POLICE LINE*PO-CHATRAPUR*GANJAM*PAN.BHAPS2611H",
    fullName: "B ASIS KUMAR SUBUDHI",
    saID: "SPR6584",
  },
  {
    firstName: "DHANJAY",
    lastName: "SHARMA",
    address: "",
    fullName: "DHANJAY SHARMA",
    saID: "SPR6585",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6586",
  },
  {
    firstName: "MULARIDHAR",
    lastName: "PAL",
    address: "",
    fullName: "MULARIDHAR PAL",
    saID: "SPR6587",
  },
  {
    firstName: "SAUTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "SAUTI CHAKRABARTY",
    saID: "SPR6588",
  },
  {
    firstName: "L M",
    lastName: "SARKAR",
    address: "",
    fullName: "L M SARKAR",
    saID: "SPR6589",
  },
  {
    firstName: "PJNY CHHOTO",
    lastName: "",
    address: "",
    fullName: "PJNY CHHOTO ",
    saID: "SPR6590",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "BANERJEE",
    address: "PO-DIGBOI*LAKHIMPUR",
    fullName: "LATE ANIL KUMAR BANERJEE",
    saID: "SPR6591",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "BANERJEE",
    address: "24PARGANAS",
    fullName: "LATE ASHUTOSH BANERJEE",
    saID: "SPR6592",
  },
  {
    firstName: "LATE AMARENDRA NATH",
    lastName: "GHOSH",
    address: "*BURDWAN",
    fullName: "LATE AMARENDRA NATH GHOSH",
    saID: "SPR6593",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "DUTTA",
    address: "NADIA",
    fullName: "LATE ASHUTOSH DUTTA",
    saID: "SPR6594",
  },
  {
    firstName: "LATE APURBA KUMAR",
    lastName: "GHOSH",
    address: "CALCUTTA",
    fullName: "LATE APURBA KUMAR GHOSH",
    saID: "SPR6595",
  },
  {
    firstName: "LATE ANUKUL CHANDRA",
    lastName: "BASUMATARY",
    address: "NADIA",
    fullName: "LATE ANUKUL CHANDRA BASUMATARY",
    saID: "SPR6596",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "CHATTERJEE",
    address: "CALCUTTA",
    fullName: "LATE AJIT KUMAR CHATTERJEE",
    saID: "SPR6597",
  },
  {
    firstName: "LATE ASHUTOSH",
    lastName: "MUKHERJEE",
    address: "MUZAFFERPUR",
    fullName: "LATE ASHUTOSH MUKHERJEE",
    saID: "SPR6598",
  },
  {
    firstName: "LATE AMULYA CHARAN",
    lastName: "CHAKRABORTY",
    address: "(MUKHERJEE)*HOWRAH",
    fullName: "LATE AMULYA CHARAN CHAKRABORTY",
    saID: "SPR6599",
  },
  {
    firstName: "LATE AMULYA KUMAR",
    lastName: "ROY",
    address: "SHYAMNAGAR*24PARGANAS",
    fullName: "LATE AMULYA KUMAR ROY",
    saID: "SPR6600",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "ROY",
    address: "JAMSHEDPUR",
    fullName: "LATE BIRENDRA NATH ROY",
    saID: "SPR6601",
  },
  {
    firstName: "LATE BIRENDRA NATH",
    lastName: "BISWAS",
    address: "HOWRAH",
    fullName: "LATE BIRENDRA NATH BISWAS",
    saID: "SPR6602",
  },
  {
    firstName: "LATE BHABATARAN",
    lastName: "BOSE",
    address: "CALCUTTA",
    fullName: "LATE BHABATARAN BOSE",
    saID: "SPR6603",
  },
  {
    firstName: "LATE BRAJENDRA CHANDRA",
    lastName: "BHATTACHARJEE",
    address: "JAMSHEDPUR",
    fullName: "LATE BRAJENDRA CHANDRA BHATTACHARJEE",
    saID: "SPR6604",
  },
  {
    firstName: "LATE BRAJENDRA",
    lastName: "ACHARJEE",
    address: "CALCUTTA",
    fullName: "LATE BRAJENDRA ACHARJEE",
    saID: "SPR6605",
  },
  {
    firstName: "LATE BRAJENDRA KUMAR",
    lastName: "DAS",
    address: "24PARGANAS",
    fullName: "LATE BRAJENDRA KUMAR DAS",
    saID: "SPR6606",
  },
  {
    firstName: "LATE BADRIBISHAL",
    lastName: "SRIVASTAVA",
    address: "SONEPUR",
    fullName: "LATE BADRIBISHAL SRIVASTAVA",
    saID: "SPR6607",
  },
  {
    firstName: "LATE BAIDYANATH",
    lastName: "MUKHERJEE",
    address: "DHANBAD",
    fullName: "LATE BAIDYANATH MUKHERJEE",
    saID: "SPR6608",
  },
  {
    firstName: "LATE BHOLANATH",
    lastName: "SARKAR",
    address: "SATSANG",
    fullName: "LATE BHOLANATH SARKAR",
    saID: "SPR6609",
  },
  {
    firstName: "LATE BANKIM CHANDRA",
    lastName: "NANDI",
    address: "HOOGHLY",
    fullName: "LATE BANKIM CHANDRA NANDI",
    saID: "SPR6610",
  },
  {
    firstName: "LATE BUDHENDU KUMAR",
    lastName: "MUKHERJEE",
    address: "JOMOPARA*BATANAGAR*24PARGANAS",
    fullName: "LATE BUDHENDU KUMAR MUKHERJEE",
    saID: "SPR6611",
  },
  {
    firstName: "LATE CHARU CHANDRA",
    lastName: "CHAKRABORTY",
    address: "CALCUTTA",
    fullName: "LATE CHARU CHANDRA CHAKRABORTY",
    saID: "SPR6612",
  },
  {
    firstName: "LATE DHIRAJ",
    lastName: "MUKHERJEE",
    address: "24PGS",
    fullName: "LATE DHIRAJ MUKHERJEE",
    saID: "SPR6613",
  },
  {
    firstName: "LATE DURGA NATH",
    lastName: "SANYAL",
    address: "SATSANG",
    fullName: "LATE DURGA NATH SANYAL",
    saID: "SPR6614",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "JHA",
    address: "MALDA",
    fullName: "LATE DEBENDRA NATH JHA",
    saID: "SPR6615",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "ROY",
    address: "MURSHIDABAD",
    fullName: "LATE DHIRENDRA NATH ROY",
    saID: "SPR6616",
  },
  {
    firstName: "LATE DEBENDRA BIJAY",
    lastName: "ROY",
    address: "CALCUTTA",
    fullName: "LATE DEBENDRA BIJAY ROY",
    saID: "SPR6617",
  },
  {
    firstName: "LATE DAYANIDHI",
    lastName: "SAHOO",
    address: "ORISSA",
    fullName: "LATE DAYANIDHI SAHOO",
    saID: "SPR6618",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "KARJI",
    address: "COOCH BEHAR",
    fullName: "LATE DEBENDRA NATH KARJI",
    saID: "SPR6619",
  },
  {
    firstName: "LATE DHIRENDRA NATH",
    lastName: "DAS",
    address: "24PARGANAS",
    fullName: "LATE DHIRENDRA NATH DAS",
    saID: "SPR6620",
  },
  {
    firstName: "LATE GOURANGA",
    lastName: "MUKHERJEE",
    address: "CALCUTTA",
    fullName: "LATE GOURANGA MUKHERJEE",
    saID: "SPR6621",
  },
  {
    firstName: "LATE GIRISH CHANDRA",
    lastName: "KAVYATIRTHA",
    address: "SATSANG",
    fullName: "LATE GIRISH CHANDRA KAVYATIRTHA",
    saID: "SPR6622",
  },
  {
    firstName: "LATE GAHINDRA KUMAR",
    lastName: "DAS",
    address: "CACHAR",
    fullName: "LATE GAHINDRA KUMAR DAS",
    saID: "SPR6623",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "DAS",
    address: "KAMRUP",
    fullName: "LATE GANGADHAR DAS",
    saID: "SPR6624",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "SASTRI",
    address: "HOOGHLY",
    fullName: "LATE GOPAL CHANDRA SASTRI",
    saID: "SPR6625",
  },
  {
    firstName: "LATE GIRINDRA KUMAR",
    lastName: "LASKAR",
    address: "PO/VILL-LALAR CHAK*CACHAR",
    fullName: "LATE GIRINDRA KUMAR LASKAR",
    saID: "SPR6626",
  },
  {
    firstName: "LATE HEMANTA KUMAR",
    lastName: "MUKHERJEE",
    address: "MURSHIDABAD",
    fullName: "LATE HEMANTA KUMAR MUKHERJEE",
    saID: "SPR6627",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "BAGCHI",
    address: "MIDNAPORE",
    fullName: "LATE HARIPADA BAGCHI",
    saID: "SPR6628",
  },
  {
    firstName: "LATE HARISH CHANDRA",
    lastName: "GOON",
    address: "SATSANG",
    fullName: "LATE HARISH CHANDRA GOON",
    saID: "SPR6629",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "ROY",
    address: "24PARGANAS",
    fullName: "LATE HARENDRA CHANDRA ROY",
    saID: "SPR6630",
  },
  {
    firstName: "LATE HARALAL",
    lastName: "BAIDYA",
    address: "CALCUTTA",
    fullName: "LATE HARALAL BAIDYA",
    saID: "SPR6631",
  },
  {
    firstName: "LATE HARENDRA",
    lastName: "BAIDYA",
    address: "CALCUTTA",
    fullName: "LATE HARENDRA BAIDYA",
    saID: "SPR6632",
  },
  {
    firstName: "HARENDRA CHANDRA",
    lastName: "DEB",
    address: "DHARMANAGR*TRIPURA",
    fullName: "HARENDRA CHANDRA DEB",
    saID: "SPR6633",
  },
  {
    firstName: "LATE HEMKESH",
    lastName: "CHOUDHURY",
    address: "KHARGRAM*BURDWAN",
    fullName: "LATE HEMKESH CHOUDHURY",
    saID: "SPR6634",
  },
  {
    firstName: "LATE HIRALAL",
    lastName: "MUKHERJEE",
    address: "BELSRI T.E.*PO-DARRANG PANBARI*DARRANG",
    fullName: "LATE HIRALAL MUKHERJEE",
    saID: "SPR6635",
  },
  {
    firstName: "LATE INDU BHUSHAN",
    lastName: "BOSE",
    address: "SATSANG",
    fullName: "LATE INDU BHUSHAN BOSE",
    saID: "SPR6636",
  },
  {
    firstName: "LATE HEMENDRA NATH",
    lastName: "BARAT",
    address: "C/O HIRENDRA NATH BARAT*16,LAKE SQUARE*NEW DELHI",
    fullName: "LATE HEMENDRA NATH BARAT",
    saID: "SPR6637",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "MUKHERJEE",
    address: "MUZAFFERPUR",
    fullName: "LATE JATINDRA NATH MUKHERJEE",
    saID: "SPR6638",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "MUKHERJEE",
    address: "HOWRAH",
    fullName: "LATE JITENDRA NATH MUKHERJEE",
    saID: "SPR6639",
  },
  {
    firstName: "LATE JATINDRA NARAYAN",
    lastName: "CHATTERJEE",
    address: "MURSHIDABAD",
    fullName: "LATE JATINDRA NARAYAN CHATTERJEE",
    saID: "SPR6640",
  },
  {
    firstName: "LATE JNANENDRA NATH",
    lastName: "DUTTA",
    address: "SATSANG",
    fullName: "LATE JNANENDRA NATH DUTTA",
    saID: "SPR6641",
  },
  {
    firstName: "LATE JAGADISH NARAYAN",
    lastName: "SRIVASTAB",
    address: "SONEPUR",
    fullName: "LATE JAGADISH NARAYAN SRIVASTAB",
    saID: "SPR6642",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "GUHA",
    address: "MURSHIDABAD",
    fullName: "LATE JATINDRA NATH GUHA",
    saID: "SPR6643",
  },
  {
    firstName: "SEKHAR RANJAN",
    lastName: "MANDAL",
    address:
      "ADARSA NAGAR*PO-PANCHPOTA*24PARGANAS(SOUTH)-700152*PAN NO.AOHPM0430R",
    fullName: "SEKHAR RANJAN MANDAL",
    saID: "SPR6644",
  },
  {
    firstName: "SANTANU KUMAR",
    lastName: "NAYAK",
    address:
      "QR NO H/169,SECTOR-1*PO-ROURKELA*DIST-SUNDARGARH*(PAN-ADCPN8138K)",
    fullName: "SANTANU KUMAR NAYAK",
    saID: "SPR6645",
  },
  {
    firstName: "PARESH CHNADRA",
    lastName: "BHORA",
    address: "",
    fullName: "PARESH CHNADRA BHORA",
    saID: "SPR6646",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "PRADHAN",
    address:
      "PLOT-LP-650, PATIA *PRASANTI VIHAR*PO-KIIT*BHUBANESWAR-751024.*KHURDA",
    fullName: "RABINDRA NATH PRADHAN",
    saID: "SPR6647",
  },
  {
    firstName: "LATE JIBAN RAM",
    lastName: "BARMAN",
    address: "NAWGAON",
    fullName: "LATE JIBAN RAM BARMAN",
    saID: "SPR6648",
  },
  {
    firstName: "LATE J L",
    lastName: "DUTTA",
    address: "CALCUTTA 28",
    fullName: "LATE J L DUTTA",
    saID: "SPR6649",
  },
  {
    firstName: "LATE KARAM CHAND",
    lastName: "PODDAR",
    address: "BHAGABANGOLA*MURSHIDABAD",
    fullName: "LATE KARAM CHAND PODDAR",
    saID: "SPR6650",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "LATE KSHITISH CHANDRA DAS",
    saID: "SPR6651",
  },
  {
    firstName: "LATE KISHORI MOHAN",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "LATE KISHORI MOHAN DAS",
    saID: "SPR6652",
  },
  {
    firstName: "LATE KSHETRA NATH",
    lastName: "BANERJEE",
    address: "HOOGHLY",
    fullName: "LATE KSHETRA NATH BANERJEE",
    saID: "SPR6653",
  },
  {
    firstName: "LATE KEDAR NATH",
    lastName: "BANERJEE",
    address: "MALDA",
    fullName: "LATE KEDAR NATH BANERJEE",
    saID: "SPR6654",
  },
  {
    firstName: "LATE KUNJA BEHARI",
    lastName: "PAL",
    address: "CALCUTTA",
    fullName: "LATE KUNJA BEHARI PAL",
    saID: "SPR6655",
  },
  {
    firstName: "LATE KUNJA BEHARI",
    lastName: "DAS",
    address: "WEST DINAJPUR",
    fullName: "LATE KUNJA BEHARI DAS",
    saID: "SPR6656",
  },
  {
    firstName: "LATE KALIPADA",
    lastName: "BANERJEE",
    address: "CALCUTTA",
    fullName: "LATE KALIPADA BANERJEE",
    saID: "SPR6657",
  },
  {
    firstName: "LATE KHAGENDRA NATH",
    lastName: "BAGCHI",
    address: "CALCUTTA",
    fullName: "LATE KHAGENDRA NATH BAGCHI",
    saID: "SPR6658",
  },
  {
    firstName: "LATE KSHETRANATH",
    lastName: "BOSE",
    address: "MURSHIDABAD",
    fullName: "LATE KSHETRANATH BOSE",
    saID: "SPR6659",
  },
  {
    firstName: "LATE KSHETRANATH",
    lastName: "SAMANTA",
    address: "HOOGHLY",
    fullName: "LATE KSHETRANATH SAMANTA",
    saID: "SPR6660",
  },
  {
    firstName: "LATE KISHORI MOHAN",
    lastName: "MONDAL",
    address: "SATSANG",
    fullName: "LATE KISHORI MOHAN MONDAL",
    saID: "SPR6661",
  },
  {
    firstName: "LATE KAMAKHYA CHARAN",
    lastName: "BHATTACHARJEE",
    address: "CACHAR",
    fullName: "LATE KAMAKHYA CHARAN BHATTACHARJEE",
    saID: "SPR6662",
  },
  {
    firstName: "LATE KSHETRA MOHAN",
    lastName: "SIKDAR",
    address: "CIVIL COURT RANAGHAT*RANAGHAT*NADIA",
    fullName: "LATE KSHETRA MOHAN SIKDAR",
    saID: "SPR6663",
  },
  {
    firstName: "SHANTI",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "SHANTI CHAKRAVORTY",
    saID: "SPR6664",
  },
  {
    firstName: "LATE KUMUD BANDHU",
    lastName: "CHAKRABORTY",
    address: "JALPAIGURI",
    fullName: "LATE KUMUD BANDHU CHAKRABORTY",
    saID: "SPR6665",
  },
  {
    firstName: "LATE LAL BEHARI",
    lastName: "DUTTA",
    address: "SOUTH STATION ROAD*AGARPARA*24PARGANAS",
    fullName: "LATE LAL BEHARI DUTTA",
    saID: "SPR6666",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "ACHARJEE",
    address: "CALCUTTA",
    fullName: "LATE MANORANJAN ACHARJEE",
    saID: "SPR6667",
  },
  {
    firstName: "LATE MAHANANDA",
    lastName: "KUNDU",
    address: "CALCUTTA",
    fullName: "LATE MAHANANDA KUNDU",
    saID: "SPR6668",
  },
  {
    firstName: "LATE MATILAL",
    lastName: "SHARMA",
    address: "DARRANG",
    fullName: "LATE MATILAL SHARMA",
    saID: "SPR6669",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "MASHAHARY",
    address: "DARRANG",
    fullName: "LATE NAGENDRA NATH MASHAHARY",
    saID: "SPR6670",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "SANYAL",
    address: "CALCUTTA",
    fullName: "LATE NARENDRA NATH SANYAL",
    saID: "SPR6671",
  },
  {
    firstName: "LATE NANI LAL",
    lastName: "GANGULI",
    address: "HOOGHLY",
    fullName: "LATE NANI LAL GANGULI",
    saID: "SPR6672",
  },
  {
    firstName: "LATE NIBARAN",
    lastName: "BAGCHI",
    address: "SATSANG",
    fullName: "LATE NIBARAN BAGCHI",
    saID: "SPR6673",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "GHOSH",
    address: "JAMSHEDPUR",
    fullName: "LATE NIRMAL CHANDRA GHOSH",
    saID: "SPR6674",
  },
  {
    firstName: "LATE NANDAGOPAL",
    lastName: "MUKHERJEE",
    address: "CALCUTTA",
    fullName: "LATE NANDAGOPAL MUKHERJEE",
    saID: "SPR6675",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "GHOSAL",
    address: "CALCUTTA",
    fullName: "LATE NAGENDRA NATH GHOSAL",
    saID: "SPR6676",
  },
  {
    firstName: "LATE NILRATAN",
    lastName: "BANERJEE",
    address: "BURDWAN",
    fullName: "LATE NILRATAN BANERJEE",
    saID: "SPR6677",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "CHATTERJEE",
    address: "MALDA",
    fullName: "LATE NANIGOPAL CHATTERJEE",
    saID: "SPR6678",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "BHATTACHARJEE",
    address: "CALCUTTA",
    fullName: "LATE NARENDRA NATH BHATTACHARJEE",
    saID: "SPR6679",
  },
  {
    firstName: "LATE NIBARAN CHANDRA",
    lastName: "CHAKRABORTY",
    address: "CALCUTTA",
    fullName: "LATE NIBARAN CHANDRA CHAKRABORTY",
    saID: "SPR6680",
  },
  {
    firstName: "LATE NIRMALANGSHU",
    lastName: "CHAKRABORTY",
    address: "SANTHAL PARGANAS",
    fullName: "LATE NIRMALANGSHU CHAKRABORTY",
    saID: "SPR6681",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "MUKHERJEE",
    address: "24PARGANAS",
    fullName: "LATE NANIGOPAL MUKHERJEE",
    saID: "SPR6682",
  },
  {
    firstName: "LATE NIRMAL KANTI",
    lastName: "GHOSH",
    address: "SATSANG",
    fullName: "LATE NIRMAL KANTI GHOSH",
    saID: "SPR6683",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "BISWAS",
    address: "KAMARPARA*JALPAIGURI",
    fullName: "LATE NANIGOPAL BISWAS",
    saID: "SPR6684",
  },
  {
    firstName: "6EV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "6EV CHAKRAVORTY",
    saID: "SPR6685",
  },
  {
    firstName: "GANGA PRASAD",
    lastName: "SINGH",
    address:
      "JAY PRAKASH NAGAR;ROAD NO-5*SANJAY PATH*DIMNA ROAD*MANGO*JAMSHEDPUR-831012",
    fullName: "GANGA PRASAD SINGH",
    saID: "SPR6686",
  },
  {
    firstName: "RADHAGOBINDA",
    lastName: "DAS",
    address: "",
    fullName: "RADHAGOBINDA DAS",
    saID: "SPR6687",
  },
  {
    firstName: "REV ALOKE RANJAN",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOKE RANJAN CHAKRABARTY",
    saID: "SPR6688",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6689",
  },
  {
    firstName: "K",
    lastName: "SARKAR",
    address: "",
    fullName: "K SARKAR",
    saID: "SPR6690",
  },
  {
    firstName: "G K",
    lastName: "PANDEY",
    address: "",
    fullName: "G K PANDEY",
    saID: "SPR6691",
  },
  {
    firstName: "HARIPRASHANA",
    lastName: "BISWAS",
    address: "",
    fullName: "HARIPRASHANA BISWAS",
    saID: "SPR6692",
  },
  {
    firstName: "REV ANURADHA",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "REV ANURADHA BHATTACHARJEE",
    saID: "SPR6693",
  },
  {
    firstName: "PUJANIYA SANTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SANTI CHAKRABORTY",
    saID: "SPR6694",
  },
  {
    firstName: "PRABITRA",
    lastName: "DAS",
    address: "",
    fullName: "PRABITRA DAS",
    saID: "SPR6695",
  },
  {
    firstName: "ANINDADYOTY",
    lastName: "",
    address: "",
    fullName: "ANINDADYOTY ",
    saID: "SPR6696",
  },
  {
    firstName: "REV ASHIK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHIK CHAKRABARTY",
    saID: "SPR6697",
  },
  {
    firstName: "P K",
    lastName: "DUTTA",
    address: "",
    fullName: "P K DUTTA",
    saID: "SPR6698",
  },
  {
    firstName: "ASHOKE",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "ASHOKE CHAKRAVORTY",
    saID: "SPR6699",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6700",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6701",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6702",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6703",
  },
  {
    firstName: "JAISHANKAR",
    lastName: "SINGH",
    address: "",
    fullName: "JAISHANKAR SINGH",
    saID: "SPR6704",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6705",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6706",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6707",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR6708",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABARTY",
    saID: "SPR6709",
  },
  {
    firstName: "REVASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVASHOK CHAKRABORTY",
    saID: "SPR6710",
  },
  {
    firstName: "REV ARKADUTY",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKADUTY CHAKRABORTY",
    saID: "SPR6711",
  },
  {
    firstName: "REV ARKADUTY",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKADUTY CHAKRABORTY",
    saID: "SPR6712",
  },
  {
    firstName: "PROMESH CHANDRA",
    lastName: "",
    address: "",
    fullName: "PROMESH CHANDRA ",
    saID: "SPR6713",
  },
  {
    firstName: "TAPAN",
    lastName: "ROY",
    address: "",
    fullName: "TAPAN ROY",
    saID: "SPR6714",
  },
  {
    firstName: "REV ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKADYUTI CHAKRABORTY",
    saID: "SPR6715",
  },
  {
    firstName: "REV SHPRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV SHPRA CHAKRABORTY",
    saID: "SPR6716",
  },
  {
    firstName: "PUJANIA",
    lastName: "",
    address: "",
    fullName: "PUJANIA ",
    saID: "SPR6717",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "PRAFULLA KUMAR DAS",
    saID: "SPR6718",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6719",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6720",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6721",
  },
  {
    firstName: "NIKHIL RANJAN",
    lastName: "HALDAR",
    address: "2 NO, RABINDRA PALLY*PO-MADRAL*24 PARGANAS (NORTH).PIN-743126",
    fullName: "NIKHIL RANJAN HALDAR",
    saID: "SPR6722",
  },
  {
    firstName: "PITRY",
    lastName: "",
    address: "",
    fullName: "PITRY ",
    saID: "SPR6723",
  },
  {
    firstName: "BORDA",
    lastName: "",
    address: "",
    fullName: "BORDA ",
    saID: "SPR6724",
  },
  {
    firstName: "REV BARDA",
    lastName: "",
    address: "",
    fullName: "REV BARDA ",
    saID: "SPR6725",
  },
  {
    firstName: "MANOJ",
    lastName: "PATRA",
    address:
      "B-203 THE CANOPY;BABUSAPALYA*KALYAN NAGAR*BANGALORE 560043*PAN.ADTPP9077G",
    fullName: "MANOJ PATRA",
    saID: "SPR6726",
  },
  {
    firstName: "BAIDYANATH",
    lastName: "CHANDA",
    address:
      "FLAT NO-202,B-WING, SHALOM GARDEN*BEVERLY PARK, KANAKIA*MIRA RD (E), THANE-401107.",
    fullName: "BAIDYANATH CHANDA",
    saID: "SPR6727",
  },
  {
    firstName: "HARI PRASSANA",
    lastName: "BISWAS",
    address: "",
    fullName: "HARI PRASSANA BISWAS",
    saID: "SPR6728",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6729",
  },
  {
    firstName: "JANANIKA",
    lastName: "ROYCHOUDHURY",
    address: "",
    fullName: "JANANIKA ROYCHOUDHURY",
    saID: "SPR6730",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR6731",
  },
  {
    firstName: "JAGADISH CHAND",
    lastName: "KATOCH",
    address: "VILL&PO-PATTI *TEH-PALAMPUR*DIST-KANGRA*HIMACHAL PRADESH-176061",
    fullName: "JAGADISH CHAND KATOCH",
    saID: "SPR6732",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR6733",
  },
  {
    firstName: "REV",
    lastName: "",
    address: "",
    fullName: "REV ",
    saID: "SPR6734",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6735",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6736",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6737",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6738",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6739",
  },
  {
    firstName: "KALIKINKAR",
    lastName: "BANDOPADHYAY",
    address:
      "SCIENTIST, I C A R*PLOT NO-506(SADHAN TIRTHA)*NAYA PALLI*BHUBANESWAR-751012*KHURDA",
    fullName: "KALIKINKAR BANDOPADHYAY",
    saID: "SPR6740",
  },
  {
    firstName: "RATIKANTA",
    lastName: "SENAPATI",
    address: "",
    fullName: "RATIKANTA SENAPATI",
    saID: "SPR6741",
  },
  {
    firstName: "PUJANIA BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABAUDI ",
    saID: "SPR6742",
  },
  {
    firstName: "A",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "A CHAKRABORTY",
    saID: "SPR6743",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASOK CHAKRABARTY",
    saID: "SPR6744",
  },
  {
    firstName: "JAMINI",
    lastName: "ROYCHOWDHURY",
    address: "",
    fullName: "JAMINI ROYCHOWDHURY",
    saID: "SPR6745",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6746",
  },
  {
    firstName: "PUJANIYANUTANBOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYANUTANBOURANI ",
    saID: "SPR6747",
  },
  {
    firstName: "GOPI KISHORE",
    lastName: "KAR",
    address: "",
    fullName: "GOPI KISHORE KAR",
    saID: "SPR6748",
  },
  {
    firstName: "DIBYADYUTI",
    lastName: "BHATTACHARJEE",
    address:
      "OPP. TIRUPATI BALAJI TEMPLE*AHOMGAON, NH-37*GUWAHATI-781035*KAMRUP*ASSAM",
    fullName: "DIBYADYUTI BHATTACHARJEE",
    saID: "SPR6749",
  },
  {
    firstName: "RE ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RE ASHOK CHAKRABORTY",
    saID: "SPR6750",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASOK CHAKRABORTY",
    saID: "SPR6751",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6752",
  },
  {
    firstName: "JITENDRANATH",
    lastName: "DEBBARMAN",
    address: "",
    fullName: "JITENDRANATH DEBBARMAN",
    saID: "SPR6753",
  },
  {
    firstName: "RTV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "RTV CHAKRABORTY",
    saID: "SPR6754",
  },
  {
    firstName: "PUJNY NA",
    lastName: "",
    address: "",
    fullName: "PUJNY NA ",
    saID: "SPR6755",
  },
  {
    firstName: "PUJNY NUTAN",
    lastName: "",
    address: "",
    fullName: "PUJNY NUTAN ",
    saID: "SPR6756",
  },
  {
    firstName: "MRIGANKA SEKHAR",
    lastName: "MAJUMDER",
    address: "",
    fullName: "MRIGANKA SEKHAR MAJUMDER",
    saID: "SPR6757",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6758",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "BARIK",
    address: "",
    fullName: "PARESH CHANDRA BARIK",
    saID: "SPR6759",
  },
  {
    firstName: "BIBHASENDU",
    lastName: "MOHAPATRA",
    address:
      "E/92 GALAXY APPT,NEAR GRAND BHAGABAN HOTEL*POST-BODAK DEU*S.G.HIGHWAY*GUJRAT 380054",
    fullName: "BIBHASENDU MOHAPATRA",
    saID: "SPR6760",
  },
  {
    firstName: "BIKRAM",
    lastName: "THAPA",
    address: "C/O SATSANG CENTER KOHIMA*NAGALAND-797001",
    fullName: "BIKRAM THAPA",
    saID: "SPR6761",
  },
  {
    firstName: "PJNY",
    lastName: "",
    address: "",
    fullName: "PJNY ",
    saID: "SPR6762",
  },
  {
    firstName: "SATYA JYOTI",
    lastName: "BOSE",
    address: "C/O N R BOSE*PURBACHAL HOUSING ESTATE*SALT LAKE*KOLKATA-91",
    fullName: "SATYA JYOTI BOSE",
    saID: "SPR6763",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6764",
  },
  {
    firstName: "N",
    lastName: "MISHRA",
    address: "",
    fullName: "N MISHRA",
    saID: "SPR6765",
  },
  {
    firstName: "DEBASISH",
    lastName: "MISRA",
    address:
      "C/O SADHAN MISRA*RLY.COLONY,QRS-71B*PO-JHALJHALIA*MALDA-732102*PAN-AMMPM4939Q",
    fullName: "DEBASISH MISRA",
    saID: "SPR6766",
  },
  {
    firstName: "REV RANGAMA",
    lastName: "",
    address: "",
    fullName: "REV RANGAMA ",
    saID: "SPR6767",
  },
  {
    firstName: "SRI SRI BORMA",
    lastName: "",
    address: "",
    fullName: "SRI SRI BORMA ",
    saID: "SPR6768",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASOK CHAKRABORTY",
    saID: "SPR6769",
  },
  {
    firstName: "SRI SRI ASHOK DA",
    lastName: "",
    address: "",
    fullName: "SRI SRI ASHOK DA ",
    saID: "SPR6770",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASOK CHAKRABORTY",
    saID: "SPR6771",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR6772",
  },
  {
    firstName: "SUDIPTA",
    lastName: "SINHAROY",
    address: "VILL-KHIDIRPUR*PO-BETHUADAHARI*DIST-NADIA-741126*PAN-AKLPS6700N",
    fullName: "SUDIPTA SINHAROY",
    saID: "SPR6773",
  },
  {
    firstName: "DENEN",
    lastName: "MANDAL",
    address: "",
    fullName: "DENEN MANDAL",
    saID: "SPR6774",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR6775",
  },
  {
    firstName: "PUNAJIYA MEJOBAURANI",
    lastName: "",
    address: "",
    fullName: "PUNAJIYA MEJOBAURANI ",
    saID: "SPR6776",
  },
  {
    firstName: "DHINEN",
    lastName: "MANDAL",
    address: "",
    fullName: "DHINEN MANDAL",
    saID: "SPR6777",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6778",
  },
  {
    firstName: "GADADHAR",
    lastName: "SHAW",
    address: "PURANDAHA*SATSANG",
    fullName: "GADADHAR SHAW",
    saID: "SPR6779",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "SHAW",
    address: "VILL/PO-ITAKHOLA*SONITPUR*ASSAM.",
    fullName: "RANJIT KUMAR SHAW",
    saID: "SPR6780",
  },
  {
    firstName: "BISWAJIT",
    lastName: "DAS",
    address:
      "C/O BENUMADHAB DAS*AT&PO-KAMPUR*DIST-NAGAON-782426*PAN-BLWPD0287N",
    fullName: "BISWAJIT DAS",
    saID: "SPR6781",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6782",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6783",
  },
  {
    firstName: "HIMANSU BHUSAN",
    lastName: "SAHOO",
    address:
      "PLOT NO-15,SECTOR-A,ZONE-B*MANCHESWAR IND ESTATE*BHUBANESWAR-751010*DIST-KHURDA",
    fullName: "HIMANSU BHUSAN SAHOO",
    saID: "SPR6784",
  },
  {
    firstName: "REV ANINDADUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ANINDADUTI CHAKRABORTY",
    saID: "SPR6785",
  },
  {
    firstName: "REVALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVALOK CHAKRABORTY",
    saID: "SPR6786",
  },
  {
    firstName: "RATAN",
    lastName: "SARMA",
    address: "SATSANG*JHARKHAND",
    fullName: "RATAN SARMA",
    saID: "SPR6787",
  },
  {
    firstName: "SUBRAT KUMAR",
    lastName: "BISWAL",
    address:
      '"CHARYABITAN",PLOT NO-N6/291*PO-I R C VILLAGE*BHUBANESWAR-751015*DIST-KHURDA',
    fullName: "SUBRAT KUMAR BISWAL",
    saID: "SPR6788",
  },
  {
    firstName: "GOURLAL",
    lastName: "GHOSH",
    address: "",
    fullName: "GOURLAL GHOSH",
    saID: "SPR6789",
  },
  {
    firstName: "ABINDEB",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABINDEB CHAKRABORTY",
    saID: "SPR6790",
  },
  {
    firstName: "SUBRATA",
    lastName: "CHATTOPADHYAY",
    address:
      "C/O NIMAI CHATTERJEE*PANCHABATI APT,BLOCK C*1/1 T N MUKHERJEE ROAD*KOLKATA 700 035",
    fullName: "SUBRATA CHATTOPADHYAY",
    saID: "SPR6791",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6792",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR6793",
  },
  {
    firstName: "AMARENDRA KUMAR",
    lastName: "DAS",
    address: "4 STH BAGAN ROAD*MANOHAR COLONY*KOLKATA 30",
    fullName: "AMARENDRA KUMAR DAS",
    saID: "SPR6794",
  },
  {
    firstName: "AMULYA KUMAR",
    lastName: "PATRA",
    address: "CHOUDHURY BAZAR*CUTTACK",
    fullName: "AMULYA KUMAR PATRA",
    saID: "SPR6795",
  },
  {
    firstName: "LATE AMULYA KUMAR",
    lastName: "DAS",
    address: "KOLKATA",
    fullName: "LATE AMULYA KUMAR DAS",
    saID: "SPR6796",
  },
  {
    firstName: "LATE AMULYA RATAN",
    lastName: "ADHIKARY",
    address: "BARADURGPUR*PO-KALAKAHARBER*KHULNA",
    fullName: "LATE AMULYA RATAN ADHIKARY",
    saID: "SPR6797",
  },
  {
    firstName: "AMULYA CHARAN",
    lastName: "RAY",
    address: "PO/VILL-SORISHA*24PARGANAS",
    fullName: "AMULYA CHARAN RAY",
    saID: "SPR6798",
  },
  {
    firstName: "LATE AMULYA KUMAR",
    lastName: "GHOSH",
    address: "SATSANG PRESS*DEOGHAR",
    fullName: "LATE AMULYA KUMAR GHOSH",
    saID: "SPR6799",
  },
  {
    firstName: "LATE ANANTA",
    lastName: "DHALI",
    address: "DIGRAJ*PO-BURIARDANGA*KHULNA",
    fullName: "LATE ANANTA DHALI",
    saID: "SPR6800",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "CHATTERJEE",
    address: "SATSANG",
    fullName: "LATE ANANTA KUMAR CHATTERJEE",
    saID: "SPR6801",
  },
  {
    firstName: "LATE ANANTA KUMAR",
    lastName: "BAIDYA",
    address: "KORAKATI*24PARGANAS",
    fullName: "LATE ANANTA KUMAR BAIDYA",
    saID: "SPR6802",
  },
  {
    firstName: "LATE ANIL KUMAR",
    lastName: "KUNDU",
    address: "RLY QR NO-7/177*PO-RANAGHAT*NADIA",
    fullName: "LATE ANIL KUMAR KUNDU",
    saID: "SPR6803",
  },
  {
    firstName: "ANIL",
    lastName: "SARKAR",
    address: "51 CHITTARANJAN AVENUE*CALCUTTA 12",
    fullName: "ANIL SARKAR",
    saID: "SPR6804",
  },
  {
    firstName: "ANJAN KUMAR",
    lastName: "BISWAS",
    address: "PO/VILL-CHUNAKURI*KHULNA",
    fullName: "ANJAN KUMAR BISWAS",
    saID: "SPR6805",
  },
  {
    firstName: "ARABINDA",
    lastName: "KAR",
    address: "VILL-BARABATI*BALASORE",
    fullName: "ARABINDA KAR",
    saID: "SPR6806",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "DAS",
    address: "(KABIRAJ)*SATSANG",
    fullName: "ASWINI KUMAR DAS",
    saID: "SPR6807",
  },
  {
    firstName: "LATE ASWINI KUMAR",
    lastName: "DAS",
    address: "MOHANGANJ*MAIMANSINGH*BANGLADESH",
    fullName: "LATE ASWINI KUMAR DAS",
    saID: "SPR6808",
  },
  {
    firstName: "LATE ATUL KUMAR",
    lastName: "SARKAR",
    address: "KHEJURDANGA*PO-BINARPARA*KHULNA",
    fullName: "LATE ATUL KUMAR SARKAR",
    saID: "SPR6809",
  },
  {
    firstName: "LATE BIDUR CHANDRA",
    lastName: "MOHAPATRA",
    address: "MECHANICAL SUPERVISOR*IRRIGATION WORKS*CUTTACK",
    fullName: "LATE BIDUR CHANDRA MOHAPATRA",
    saID: "SPR6810",
  },
  {
    firstName: "BISWANATH JAGANNATH",
    lastName: "METRE",
    address: "JAYGURU PANDURAM WADI*GOREGAON*MUMBAI-63",
    fullName: "BISWANATH JAGANNATH METRE",
    saID: "SPR6811",
  },
  {
    firstName: "BHAJAHARI",
    lastName: "PAL",
    address: "SATSANG MANDIR*BONGAON*24PARGANAS",
    fullName: "BHAJAHARI PAL",
    saID: "SPR6812",
  },
  {
    firstName: "LATE BANKIM CHANDRA",
    lastName: "GHATAK",
    address: "21 SHYAMNAGAR ROAD*CALCUTTA 53",
    fullName: "LATE BANKIM CHANDRA GHATAK",
    saID: "SPR6813",
  },
  {
    firstName: "LATE BIRENDRA NARAYAN",
    lastName: "GOSWAMI",
    address: "CHAKRAVARTYPARA*PO-BARNIPUR*24PARGANAS",
    fullName: "LATE BIRENDRA NARAYAN GOSWAMI",
    saID: "SPR6814",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "SWAIN",
    address: "MERIA BAZAR*CUTTACK",
    fullName: "BASANTA KUMAR SWAIN",
    saID: "SPR6815",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "MISHRA",
    address: "SATSANG KENDRA*BAKSHATULI LANE*MALDA",
    fullName: "LATE BIBHUTI BHUSAN MISHRA",
    saID: "SPR6816",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "CHAKRAVARTY",
    address: "BARAIL*PO-PUTULIGRAM*HOOGHLY",
    fullName: "BIBHUTI BHUSAN CHAKRAVARTY",
    saID: "SPR6817",
  },
  {
    firstName: "LATE BRAJENDRA KANTA",
    lastName: "MAJUMDER",
    address: "24PARGANS",
    fullName: "LATE BRAJENDRA KANTA MAJUMDER",
    saID: "SPR6818",
  },
  {
    firstName: "LATE BINOD BIHARI",
    lastName: "MRIDHA",
    address: "AHMEDPUR*BIRBHUM",
    fullName: "LATE BINOD BIHARI MRIDHA",
    saID: "SPR6819",
  },
  {
    firstName: "BHAGABAN",
    lastName: "PRUSTY",
    address: "CUTTACK",
    fullName: "BHAGABAN PRUSTY",
    saID: "SPR6820",
  },
  {
    firstName: "LATE BIJAY KUMAR",
    lastName: "RAY",
    address: "PATUAKHALI*KHULNA",
    fullName: "LATE BIJAY KUMAR RAY",
    saID: "SPR6821",
  },
  {
    firstName: "BHUPENDRA NATH",
    lastName: "SIKDER",
    address: "MASUNDA*PO-NEW BARRACKPORE*24PARGANAS",
    fullName: "BHUPENDRA NATH SIKDER",
    saID: "SPR6822",
  },
  {
    firstName: "BATAKRISHNA",
    lastName: "SATPATHY",
    address: "CUTTACK",
    fullName: "BATAKRISHNA SATPATHY",
    saID: "SPR6823",
  },
  {
    firstName: "LATE BASUDEB",
    lastName: "GOSWAMI",
    address: "SATSANG",
    fullName: "LATE BASUDEB GOSWAMI",
    saID: "SPR6824",
  },
  {
    firstName: "LATE BRAJAGOPAL",
    lastName: "RAY",
    address: "CIVIL COURT*KANDI*MURSIDAAD",
    fullName: "LATE BRAJAGOPAL RAY",
    saID: "SPR6825",
  },
  {
    firstName: "LATE BIRENDRA KUMAR",
    lastName: "DAS",
    address: "BARISAL*BANGLADESH",
    fullName: "LATE BIRENDRA KUMAR DAS",
    saID: "SPR6826",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "BISWAS",
    address: "SUNDARPUR*GILATALA*KHULNA",
    fullName: "BIRENDRA NATH BISWAS",
    saID: "SPR6827",
  },
  {
    firstName: "BRAJENDRA KUMAR",
    lastName: "GHOSH",
    address: "MANDARAM*HOOGHLY",
    fullName: "BRAJENDRA KUMAR GHOSH",
    saID: "SPR6828",
  },
  {
    firstName: "LATE BRAJA GOPAL",
    lastName: "DUTTARAY",
    address: "SATSANG",
    fullName: "LATE BRAJA GOPAL DUTTARAY",
    saID: "SPR6829",
  },
  {
    firstName: "BISWESWAR",
    lastName: "DAS",
    address: "FEROZPUR*BARISAL",
    fullName: "BISWESWAR DAS",
    saID: "SPR6830",
  },
  {
    firstName: "BIPIN PRITAMRAI",
    lastName: "DHOLAKIA",
    address: "BOMBAY",
    fullName: "BIPIN PRITAMRAI DHOLAKIA",
    saID: "SPR6831",
  },
  {
    firstName: "LATE BRAJESWAR",
    lastName: "MUKHERJEE",
    address: "BARRACKPORE*24PGS",
    fullName: "LATE BRAJESWAR MUKHERJEE",
    saID: "SPR6832",
  },
  {
    firstName: "LATE BHAGABAN",
    lastName: "SAHOO",
    address: "CUTTACK",
    fullName: "LATE BHAGABAN SAHOO",
    saID: "SPR6833",
  },
  {
    firstName: "LATE CHANDRA BHUSAN",
    lastName: "MUKHERJEE",
    address: "SINDGORA*JAMSHEDPUR*SINGHBHUM",
    fullName: "LATE CHANDRA BHUSAN MUKHERJEE",
    saID: "SPR6834",
  },
  {
    firstName: "LATE CHITRA NARAYAN",
    lastName: "LAL",
    address: "TEACHER,K.E.H.S. SCHOOL*PO/DT-SAMASTIPUR",
    fullName: "LATE CHITRA NARAYAN LAL",
    saID: "SPR6835",
  },
  {
    firstName: "DR CHANDRA BHUSAN",
    lastName: "CHOUDHURY",
    address: "SAMASTIPUR",
    fullName: "DR CHANDRA BHUSAN CHOUDHURY",
    saID: "SPR6836",
  },
  {
    firstName: "LATE CHANDRAKANTA P",
    lastName: "MEHTA",
    address: "SHANTI BHAWAN*MALAVIA ROAD*VILE PARLE EAST*BOMBAY 57",
    fullName: "LATE CHANDRAKANTA P MEHTA",
    saID: "SPR6837",
  },
  {
    firstName: "CHANDRANATH",
    lastName: "CHAKRAVARTY",
    address: "CHITTAGONG",
    fullName: "CHANDRANATH CHAKRAVARTY",
    saID: "SPR6838",
  },
  {
    firstName: "LATE BHUPATI CHARAN",
    lastName: "PAL",
    address: "BARISAL",
    fullName: "LATE BHUPATI CHARAN PAL",
    saID: "SPR6839",
  },
  {
    firstName: "LATE BIDHU NATH",
    lastName: "ROYCHOUDHURY",
    address: "ACHARYA PRAFULLA NAGAR*KALYANGARH*HABRA*24PARGANAS",
    fullName: "LATE BIDHU NATH ROYCHOUDHURY",
    saID: "SPR6840",
  },
  {
    firstName: "PUJANIYA SOUTI DEBI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA SOUTI DEBI ",
    saID: "SPR6841",
  },
  {
    firstName: "ARUP RANJAN",
    lastName: "PRADHAN",
    address: "CALCUTTA 6",
    fullName: "ARUP RANJAN PRADHAN",
    saID: "SPR6842",
  },
  {
    firstName: "LATE AMAR NATH",
    lastName: "DHAR",
    address: "PATUAKHALI*BANGLADESH",
    fullName: "LATE AMAR NATH DHAR",
    saID: "SPR6843",
  },
  {
    firstName: "BINAY BHUSAN",
    lastName: "MAJUMDER",
    address: "NOAKHALI*BANGLADESH",
    fullName: "BINAY BHUSAN MAJUMDER",
    saID: "SPR6844",
  },
  {
    firstName: "LATE BHASKAR",
    lastName: "BANERJEE",
    address: "PATNA",
    fullName: "LATE BHASKAR BANERJEE",
    saID: "SPR6845",
  },
  {
    firstName: "LATE BISHNU PADA",
    lastName: "MANDAL",
    address: "KHULNA",
    fullName: "LATE BISHNU PADA MANDAL",
    saID: "SPR6846",
  },
  {
    firstName: "LATE BHUBANESWAR",
    lastName: "PAL",
    address: "NADIA",
    fullName: "LATE BHUBANESWAR PAL",
    saID: "SPR6847",
  },
  {
    firstName: "BAIKUNTHA NATH",
    lastName: "MAJUMDER",
    address: "KUMILLA*BANGLADESH",
    fullName: "BAIKUNTHA NATH MAJUMDER",
    saID: "SPR6848",
  },
  {
    firstName: "BANKADIDHI",
    lastName: "PANDA",
    address: "",
    fullName: "BANKADIDHI PANDA",
    saID: "SPR6849",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "CHOUDHURY",
    address: "CHITTAGONG",
    fullName: "LATE AJIT KUMAR CHOUDHURY",
    saID: "SPR6850",
  },
  {
    firstName: "LATE ASUTOSH",
    lastName: "DEBROY",
    address: "NETRAKONA*BANGLADESH",
    fullName: "LATE ASUTOSH DEBROY",
    saID: "SPR6851",
  },
  {
    firstName: "ARUN",
    lastName: "CHAKRAVARTY",
    address: "AT/PO-JALABHASA*NORTH TRIPURA.",
    fullName: "ARUN CHAKRAVARTY",
    saID: "SPR6852",
  },
  {
    firstName: "ARABINDA",
    lastName: "SARKAR",
    address: "VILL-KHEJURDANGA*PO-BINARPOTA*PS/DIST-SATKHIRA*BANGLADESH",
    fullName: "ARABINDA SARKAR",
    saID: "SPR6853",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "DAS",
    address: "C/O SUKUMAR DAS*UPATYAKA 10*SUNAMGANJ*BANGLADESH",
    fullName: "ASHUTOSH DAS",
    saID: "SPR6854",
  },
  {
    firstName: "LATE AMULYA RATAN",
    lastName: "SAHA",
    address: "RONGPUR*BANGLADESH",
    fullName: "LATE AMULYA RATAN SAHA",
    saID: "SPR6855",
  },
  {
    firstName: "AJAY KUMAR",
    lastName: "SARKAR",
    address: "KHULNA*BANGLADESH",
    fullName: "AJAY KUMAR SARKAR",
    saID: "SPR6856",
  },
  {
    firstName: "ACHINTYA KUMAR",
    lastName: "BAHADUR",
    address: "KHULNA*BANGLADESH",
    fullName: "ACHINTYA KUMAR BAHADUR",
    saID: "SPR6857",
  },
  {
    firstName: "AMALENDU",
    lastName: "DEBROY",
    address: "NETRAKONA*BANGLADESH",
    fullName: "AMALENDU DEBROY",
    saID: "SPR6858",
  },
  {
    firstName: "AMALENDU",
    lastName: "DEYCHOUDHURY",
    address: "SYLHET*BANGLADESH",
    fullName: "AMALENDU DEYCHOUDHURY",
    saID: "SPR6859",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6860",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "SARKAR",
    address: "GAIBANDHA*BANGLADESH",
    fullName: "ANIL KUMAR SARKAR",
    saID: "SPR6861",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "BHATTACHERJEE",
    address: "BANGLADESH",
    fullName: "LATE AJIT KUMAR BHATTACHERJEE",
    saID: "SPR6862",
  },
  {
    firstName: "AMAL CHANDRA",
    lastName: "RAY",
    address: "AMTALI*BARGUNA*BANGLADESH",
    fullName: "AMAL CHANDRA RAY",
    saID: "SPR6863",
  },
  {
    firstName: "ANIL CHANDRA",
    lastName: "RAY",
    address:
      "PARULIA TAFSILI H.S.& COLLEGE*PO-DAKSHIN PARULIA*DIST-LALMANIRHAT*BANGLADESH",
    fullName: "ANIL CHANDRA RAY",
    saID: "SPR6864",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "BISWAS",
    address: "KHULNA*BANGLADESH",
    fullName: "ASHUTOSH BISWAS",
    saID: "SPR6865",
  },
  {
    firstName: "ARABINDA KUMAR",
    lastName: "BAIRAGI",
    address: "VILL-RUPRAMPUR*PO-THUKRA*PS-DUMURIA*DIST-KHULNA*BANGLADESH",
    fullName: "ARABINDA KUMAR BAIRAGI",
    saID: "SPR6866",
  },
  {
    firstName: "ASIM",
    lastName: "ACHARYA",
    address: "CHITTAGONG",
    fullName: "ASIM ACHARYA",
    saID: "SPR6867",
  },
  {
    firstName: "LATE BAISHNAB CHANDRA",
    lastName: "MANDAL",
    address: "BHATKHALI*SATKHIRA*BANGLADESH",
    fullName: "LATE BAISHNAB CHANDRA MANDAL",
    saID: "SPR6868",
  },
  {
    firstName: "BIMAL KRISHNA",
    lastName: "GAYEN",
    address: "SATKHIRA*BANGLADESH",
    fullName: "BIMAL KRISHNA GAYEN",
    saID: "SPR6869",
  },
  {
    firstName: "BIBEKANANDA",
    lastName: "MONDAL",
    address: "SATKHIRA*BANGLADESH",
    fullName: "BIBEKANANDA MONDAL",
    saID: "SPR6870",
  },
  {
    firstName: "BIBHUTI BHUSAN",
    lastName: "SARKAR",
    address: "SATKHIRA*BANGLADESH",
    fullName: "BIBHUTI BHUSAN SARKAR",
    saID: "SPR6871",
  },
  {
    firstName: "BHABADISH",
    lastName: "BANERJEE",
    address: "DINAJPUR*BANGLADESH",
    fullName: "BHABADISH BANERJEE",
    saID: "SPR6872",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "DEBSARMA",
    address: "DINAJPUR*BANGLADESH",
    fullName: "BIJAY KUMAR DEBSARMA",
    saID: "SPR6873",
  },
  {
    firstName: "BICHITRA RANJAN",
    lastName: "SIKARI",
    address: "MAHADEVPUR*PATUAKHALI*BANGLADESH",
    fullName: "BICHITRA RANJAN SIKARI",
    saID: "SPR6874",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR6875",
  },
  {
    firstName: "CHITTARANJAN",
    lastName: "ROY",
    address: "VILL-WEST RASULPUR*PO-RASULPUR*PS-MEMARI*DIST-BURDWAN-713146",
    fullName: "CHITTARANJAN ROY",
    saID: "SPR6876",
  },
  {
    firstName: "CHIRASUNDAR",
    lastName: "MANDAL",
    address: "SATKHIRA*BANGLADESH",
    fullName: "CHIRASUNDAR MANDAL",
    saID: "SPR6877",
  },
  {
    firstName: "BIMAL KRISHNA",
    lastName: "SANA",
    address: "SATKHIRA*BANGLADESH",
    fullName: "BIMAL KRISHNA SANA",
    saID: "SPR6878",
  },
  {
    firstName: "BIMAL KRISHNA",
    lastName: "MANDAL",
    address: "TANGAIL*BANGLADESH",
    fullName: "BIMAL KRISHNA MANDAL",
    saID: "SPR6879",
  },
  {
    firstName: "LATE BHUPENDRA KUMAR",
    lastName: "TALUKDAR",
    address: "SUNAMGANJ*BANGLADESH",
    fullName: "LATE BHUPENDRA KUMAR TALUKDAR",
    saID: "SPR6880",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6881",
  },
  {
    firstName: "ANIL KANTI",
    lastName: "NATH",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "ANIL KANTI NATH",
    saID: "SPR6882",
  },
  {
    firstName: "ANUKUL CHANDRA",
    lastName: "TARAFDAR",
    address: "JESSORE*BANGLADESH",
    fullName: "ANUKUL CHANDRA TARAFDAR",
    saID: "SPR6883",
  },
  {
    firstName: "ADHIR KRISHNA",
    lastName: "MONDAL",
    address:
      "VILL&PO-PASCHIM  PARGHUMTI*P S-HINGALGANJ*24PARGANAS NORTH-743439",
    fullName: "ADHIR KRISHNA MONDAL",
    saID: "SPR6884",
  },
  {
    firstName: "BISWANATH",
    lastName: "GHARAMI",
    address: "SATKHIRA*BANGLADESH",
    fullName: "BISWANATH GHARAMI",
    saID: "SPR6885",
  },
  {
    firstName: "BIMAL CHANDRA",
    lastName: "MITRA",
    address: '"BANALATA"*KHEYAGHAT ROAD*CHARNAWABAD*BHOLA*BANGLADESH',
    fullName: "BIMAL CHANDRA MITRA",
    saID: "SPR6886",
  },
  {
    firstName: "BIBEKANANDA",
    lastName: "NAG",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "BIBEKANANDA NAG",
    saID: "SPR6887",
  },
  {
    firstName: "BABUL CHANDRA",
    lastName: "DAS",
    address: "FENI*BANGLADESH",
    fullName: "BABUL CHANDRA DAS",
    saID: "SPR6888",
  },
  {
    firstName: "BISWANATH",
    lastName: "DEY",
    address: "GAZIPUR*BANGLADESH",
    fullName: "BISWANATH DEY",
    saID: "SPR6889",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6890",
  },
  {
    firstName: "LATE DWIJENDRA NATH",
    lastName: "BOSE",
    address: "CUTTACK",
    fullName: "LATE DWIJENDRA NATH BOSE",
    saID: "SPR6891",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "NATH",
    address: "GARIFA*24PGS",
    fullName: "DULAL CHANDRA NATH",
    saID: "SPR6892",
  },
  {
    firstName: "LATE DURGESH CHANDRA",
    lastName: "MALAKAR",
    address: "THAKUR BUNGLOW*SATSANG",
    fullName: "LATE DURGESH CHANDRA MALAKAR",
    saID: "SPR6893",
  },
  {
    firstName: "LATE DEGLAL",
    lastName: "RAM",
    address: "SATSANG",
    fullName: "LATE DEGLAL RAM",
    saID: "SPR6894",
  },
  {
    firstName: "LATE EDMAND J",
    lastName: "SPENCER",
    address: "SATSANG",
    fullName: "LATE EDMAND J SPENCER",
    saID: "SPR6895",
  },
  {
    firstName: "FANINDRA LAL",
    lastName: "NASKAR",
    address: "BADKULLA*NADIA",
    fullName: "FANINDRA LAL NASKAR",
    saID: "SPR6896",
  },
  {
    firstName: "LATE GANESH CHANDRA",
    lastName: "MANDAL",
    address: "HARINAGAR*KHULNA",
    fullName: "LATE GANESH CHANDRA MANDAL",
    saID: "SPR6897",
  },
  {
    firstName: "LATE GURUDAS",
    lastName: "SINGH",
    address: "SATSANG",
    fullName: "LATE GURUDAS SINGH",
    saID: "SPR6898",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "RAY",
    address: "JALPAIGURI",
    fullName: "GOPAL CHANDRA RAY",
    saID: "SPR6899",
  },
  {
    firstName: "LATE GURUDAS",
    lastName: "BANERJEE",
    address: "THAKURPALLI*CHHOTONILPUR*BURDWAN",
    fullName: "LATE GURUDAS BANERJEE",
    saID: "SPR6900",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "CHOUDHURY",
    address: "BANGLADESH",
    fullName: "LATE GANGADHAR CHOUDHURY",
    saID: "SPR6901",
  },
  {
    firstName: "LATE HIMANGSHU",
    lastName: "KHANRA",
    address: "HOWRAH",
    fullName: "LATE HIMANGSHU KHANRA",
    saID: "SPR6902",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "TARAFDAR",
    address: "KHULNA",
    fullName: "LATE HARIPADA TARAFDAR",
    saID: "SPR6903",
  },
  {
    firstName: "LATE HARIDAS",
    lastName: "SARKAR",
    address: "SAHEBNAGAR*NADIA",
    fullName: "LATE HARIDAS SARKAR",
    saID: "SPR6904",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "RAY",
    address: "RAJARAMPUR*PO-NASIPUR*DINAJPUR*BANGLADESH",
    fullName: "DHIRENDRA NATH RAY",
    saID: "SPR6905",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR6906",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "NATH",
    address: "TANGAIL*BANGLADESH",
    fullName: "LATE DHIRENDRA KUMAR NATH",
    saID: "SPR6907",
  },
  {
    firstName: "LATE DULAL CHANDRA",
    lastName: "DAS",
    address: "DAYALKHALI*CHITTAGONG",
    fullName: "LATE DULAL CHANDRA DAS",
    saID: "SPR6908",
  },
  {
    firstName: "LATE DULAL CHANDRA",
    lastName: "RAY",
    address: "BANGLADESH",
    fullName: "LATE DULAL CHANDRA RAY",
    saID: "SPR6909",
  },
  {
    firstName: "DHARANI KANTA",
    lastName: "SHAW",
    address: "GAIBANDHA*BANGLADESH",
    fullName: "DHARANI KANTA SHAW",
    saID: "SPR6910",
  },
  {
    firstName: "DEBDAS",
    lastName: "MONDAL",
    address: "SATKHIRA*BANGLADESH",
    fullName: "DEBDAS MONDAL",
    saID: "SPR6911",
  },
  {
    firstName: "DHIRENDRA KUMAR",
    lastName: "PAL",
    address: "PAKUTIA*BANGLADESH",
    fullName: "DHIRENDRA KUMAR PAL",
    saID: "SPR6912",
  },
  {
    firstName: "DIPANKAR",
    lastName: "BHATTACHARYA",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "DIPANKAR BHATTACHARYA",
    saID: "SPR6913",
  },
  {
    firstName: "DILIP",
    lastName: "BHOWMIK",
    address: "PARAM PARASH*VIVEKANANDA NAGAR*MADHYAMGRAM*KOLKATA-700129.",
    fullName: "DILIP BHOWMIK",
    saID: "SPR6914",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "GAYEN",
    address: "SATKHIRA*BANGLADESH",
    fullName: "DILIP KUMAR GAYEN",
    saID: "SPR6915",
  },
  {
    firstName: "DHIRANGSHU BHUSAN",
    lastName: "BISWAS",
    address: "PATUAKHALI*BANGLADESH",
    fullName: "DHIRANGSHU BHUSAN BISWAS",
    saID: "SPR6916",
  },
  {
    firstName: "DULAL CHANDRA",
    lastName: "MANDAL",
    address: "RUPRAMPUR*PO-THUKRA*KHULNA*BANGLADESH",
    fullName: "DULAL CHANDRA MANDAL",
    saID: "SPR6917",
  },
  {
    firstName: "DIPAK",
    lastName: "TALUKDER",
    address: "HABIGANJ*BANGLADESH",
    fullName: "DIPAK TALUKDER",
    saID: "SPR6918",
  },
  {
    firstName: "GOURANGA CHANDRA",
    lastName: "PAL",
    address: "SUNAMGANJ*BANGLADESH",
    fullName: "GOURANGA CHANDRA PAL",
    saID: "SPR6919",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "HALDER",
    address: "CHARHOGHA*MOHANDIGOJ*BARISAL*BANGLADESH",
    fullName: "GOPAL CHANDRA HALDER",
    saID: "SPR6920",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "BANIK",
    address: "SONAMGANJ*BANGLADESH",
    fullName: "LATE GOPAL CHANDRA BANIK",
    saID: "SPR6921",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "RAY",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "DILIP KUMAR RAY",
    saID: "SPR6922",
  },
  {
    firstName: "DEBENDRA CHANDRA",
    lastName: "BUNERJEE",
    address: "MOULAVI BAZAR,KALIGHAT*BANGLADESH",
    fullName: "DEBENDRA CHANDRA BUNERJEE",
    saID: "SPR6923",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "BISWAS",
    address: "SATKHIRA*BANGLADESH",
    fullName: "DIPAK KUMAR BISWAS",
    saID: "SPR6924",
  },
  {
    firstName: "DINESH CHANDRA",
    lastName: "ROY",
    address: "DINAJPUR*BANGLADESH",
    fullName: "DINESH CHANDRA ROY",
    saID: "SPR6925",
  },
  {
    firstName: "DIPAK",
    lastName: "ACHARJEE",
    address: "FATAHABAD*CHITAGANG*BANGLADESH",
    fullName: "DIPAK ACHARJEE",
    saID: "SPR6926",
  },
  {
    firstName: "LATE DWIJENDRA LAL",
    lastName: "SARKAR",
    address: "VILL-SHYAMKHALI*PO-ANDABUNIA*PS-KAYRA*KHULNA*BANGLADESH",
    fullName: "LATE DWIJENDRA LAL SARKAR",
    saID: "SPR6927",
  },
  {
    firstName: "HARENDRA KUMAR",
    lastName: "DEB",
    address: "SUNAMGANJ*BANGLADESH",
    fullName: "HARENDRA KUMAR DEB",
    saID: "SPR6928",
  },
  {
    firstName: "DINESH CHANDRA",
    lastName: "SEALSARMA",
    address: "VILL-SHATAGRAM*PO-JHARBARI*BIRGANJ*DINAJPUR*BANGLADESH",
    fullName: "DINESH CHANDRA SEALSARMA",
    saID: "SPR6929",
  },
  {
    firstName: "HIMANGSHU",
    lastName: "BAHADUR",
    address: "KHULNA*BANGLADESH",
    fullName: "HIMANGSHU BAHADUR",
    saID: "SPR6930",
  },
  {
    firstName: "HIMANGSHU",
    lastName: "TALUKDER",
    address: "HABIGANJ*BANGLADESH",
    fullName: "HIMANGSHU TALUKDER",
    saID: "SPR6931",
  },
  {
    firstName: "HARIPADA",
    lastName: "TALUKDER",
    address: "BANGLADESH*BAGHERHAT",
    fullName: "HARIPADA TALUKDER",
    saID: "SPR6932",
  },
  {
    firstName: "HARIPADA",
    lastName: "DAS",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "HARIPADA DAS",
    saID: "SPR6933",
  },
  {
    firstName: "INDUBHUSAN",
    lastName: "DAS",
    address: "SYLHET*BANGLADESH",
    fullName: "INDUBHUSAN DAS",
    saID: "SPR6934",
  },
  {
    firstName: "DHRITABRATA",
    lastName: "ADITYA",
    address: "SATSANG ASHRAM PAKUTIA*TANGAIL*BANGLADESH",
    fullName: "DHRITABRATA ADITYA",
    saID: "SPR6935",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "SINGHA",
    address: "THAKURGAON*BANGLADESH",
    fullName: "GANESH CHANDRA SINGHA",
    saID: "SPR6936",
  },
  {
    firstName: "GOPIKA RANJAN",
    lastName: "TALUKDER",
    address: "NETRAKONA*BANGLADESH",
    fullName: "GOPIKA RANJAN TALUKDER",
    saID: "SPR6937",
  },
  {
    firstName: "A",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "A CHAKRABORTY",
    saID: "SPR6938",
  },
  {
    firstName: "LATE JANARDAN",
    lastName: "MUKHERJEE",
    address: "CALCUTTA",
    fullName: "LATE JANARDAN MUKHERJEE",
    saID: "SPR6939",
  },
  {
    firstName: "LATE JATINDRA MOHAN",
    lastName: "SARKAR",
    address: "DANGARHAT*WEST DINAJPUR",
    fullName: "LATE JATINDRA MOHAN SARKAR",
    saID: "SPR6940",
  },
  {
    firstName: "JANARDAN",
    lastName: "BOSE",
    address: "121/2/1A,MANOHAR PUKUR ROAD*CALCUTTA 26",
    fullName: "JANARDAN BOSE",
    saID: "SPR6941",
  },
  {
    firstName: "LATE JAYANTA KUMAR",
    lastName: "BOSE",
    address: "NADIA",
    fullName: "LATE JAYANTA KUMAR BOSE",
    saID: "SPR6942",
  },
  {
    firstName: "LATE JYOTIRMAY",
    lastName: "GHOSH",
    address: "LAL BAZAR*PO-BARIPADA*MAYURBHANJ",
    fullName: "LATE JYOTIRMAY GHOSH",
    saID: "SPR6943",
  },
  {
    firstName: "JANAKINATH",
    lastName: "DEY",
    address: "JESSORE*BANGLADESH",
    fullName: "JANAKINATH DEY",
    saID: "SPR6944",
  },
  {
    firstName: "LATE JAGADISH",
    lastName: "MAHALDAR",
    address: "KHULNA*BANGLADESH",
    fullName: "LATE JAGADISH MAHALDAR",
    saID: "SPR6945",
  },
  {
    firstName: "LATE KUNJA BIHARI",
    lastName: "MAJUMDER",
    address: "TANGAIL*BANGLADESH",
    fullName: "LATE KUNJA BIHARI MAJUMDER",
    saID: "SPR6946",
  },
  {
    firstName: "KALIPADA",
    lastName: "MANDAL",
    address: "NORTH RUPAI SAYER*PO-AMRITAPARA*BANKURA",
    fullName: "KALIPADA MANDAL",
    saID: "SPR6947",
  },
  {
    firstName: "KRISHNA CHANDRA",
    lastName: "BISWAS",
    address: "FARIDPUR*BANGLADESH",
    fullName: "KRISHNA CHANDRA BISWAS",
    saID: "SPR6948",
  },
  {
    firstName: "LATE KHAGAPATI",
    lastName: "MANDAL",
    address: "CHAKDAH*NADIA",
    fullName: "LATE KHAGAPATI MANDAL",
    saID: "SPR6949",
  },
  {
    firstName: "LATE KRISHNA CHANDRA",
    lastName: "DAS",
    address: "SATSANG CENTRE*ALIPURDUAR*JALPAIGURI",
    fullName: "LATE KRISHNA CHANDRA DAS",
    saID: "SPR6950",
  },
  {
    firstName: "LATE KHAGENDRA",
    lastName: "MOULIK",
    address: "METIGANJ*PO-BONGAON*24PGS",
    fullName: "LATE KHAGENDRA MOULIK",
    saID: "SPR6951",
  },
  {
    firstName: "KANSARI LAL",
    lastName: "NEOGI",
    address: "CHITRANAGAR*PO-HARINKHOLA*24PGS",
    fullName: "KANSARI LAL NEOGI",
    saID: "SPR6952",
  },
  {
    firstName: "KALI KRISHNA",
    lastName: "SAHA",
    address: "KALIKAPOTA*24PGS",
    fullName: "KALI KRISHNA SAHA",
    saID: "SPR6953",
  },
  {
    firstName: "KALIPADA",
    lastName: "MISTRY",
    address: "KHULNA*BANGLADESH",
    fullName: "KALIPADA MISTRY",
    saID: "SPR6954",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "MANDAL",
    address: "KHULNA*BANGLADESH",
    fullName: "LATE KIRAN CHANDRA MANDAL",
    saID: "SPR6955",
  },
  {
    firstName: "KESHO",
    lastName: "SINGH",
    address: "DARBHANGA",
    fullName: "KESHO SINGH",
    saID: "SPR6956",
  },
  {
    firstName: "LATE KUNJA BIHARI",
    lastName: "RAKSHIT",
    address: "BONGAON*24PGS",
    fullName: "LATE KUNJA BIHARI RAKSHIT",
    saID: "SPR6957",
  },
  {
    firstName: "LATE KALIKANTA",
    lastName: "DAS",
    address: "DINAJPUR*BANGLADESH",
    fullName: "LATE KALIKANTA DAS",
    saID: "SPR6958",
  },
  {
    firstName: "LATE JOGENDRA NATH",
    lastName: "BISWAS",
    address: "PO-PAKHRHAT*DINAJPUR*BANGLADESH",
    fullName: "LATE JOGENDRA NATH BISWAS",
    saID: "SPR6959",
  },
  {
    firstName: "LATE JITENDRA KISHORE",
    lastName: "KUNDU",
    address: "NACHRAPARA*RANAGHAT*NADIA",
    fullName: "LATE JITENDRA KISHORE KUNDU",
    saID: "SPR6960",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "MOHANTA",
    address: "RONGPUR*BANGLADESH",
    fullName: "LATE JAGADISH CHANDRA MOHANTA",
    saID: "SPR6961",
  },
  {
    firstName: "JAYHARI",
    lastName: "BARMAN",
    address: "RONGPUR*BANGLADESH",
    fullName: "JAYHARI BARMAN",
    saID: "SPR6962",
  },
  {
    firstName: "JANARDAN",
    lastName: "BHATTACHERJEE",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "JANARDAN BHATTACHERJEE",
    saID: "SPR6963",
  },
  {
    firstName: "JAYDEB",
    lastName: "BISWAS",
    address: "KHULNA*BANGLADESH",
    fullName: "JAYDEB BISWAS",
    saID: "SPR6964",
  },
  {
    firstName: "JOGENDRA NATH",
    lastName: "MISTRY",
    address: "DHAKA*BANGLADESH",
    fullName: "JOGENDRA NATH MISTRY",
    saID: "SPR6965",
  },
  {
    firstName: "JADULAL",
    lastName: "MAJUMDER",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "JADULAL MAJUMDER",
    saID: "SPR6966",
  },
  {
    firstName: "DHRUTIKAM",
    lastName: "MOHANTY",
    address:
      "PLOT NO-216-B/6,SECTOR-A,ZONE-B*MANCHESWAR IND ESTATE*BHUBANESWAR 751010*KHURDA",
    fullName: "DHRUTIKAM MOHANTY",
    saID: "SPR6967",
  },
  {
    firstName: "UTTAM",
    lastName: "DEY",
    address:
      "C/O S S THAKUR BHANDAR*MAIN ROAD(NEAR OVER BRIDGE)*PO/DIST-DIMAPUR*NAGALAND",
    fullName: "UTTAM DEY",
    saID: "SPR6968",
  },
  {
    firstName: "KALIPADA",
    lastName: "BAGCHI",
    address: "BTIAGHATA*KHULNA*BANGLADESH",
    fullName: "KALIPADA BAGCHI",
    saID: "SPR6969",
  },
  {
    firstName: "KSHITISH",
    lastName: "DUTTA",
    address: "CHANDPUR BAGAN*HOBIGANJ*BANGLADESH",
    fullName: "KSHITISH DUTTA",
    saID: "SPR6970",
  },
  {
    firstName: "KUMUD RANJAN",
    lastName: "DAS",
    address: "MOULOBI BAZAR*BANGLADESH",
    fullName: "KUMUD RANJAN DAS",
    saID: "SPR6971",
  },
  {
    firstName: "KUNJA BEHARI",
    lastName: "ADITYA",
    address: "PO-SATSANG ASHRAM*PAKUTIA*BANGLADESH",
    fullName: "KUNJA BEHARI ADITYA",
    saID: "SPR6972",
  },
  {
    firstName: "KABIKANKAN",
    lastName: "HALDAR",
    address: "BANGERHAT*KHULNA*BANGALADESH",
    fullName: "KABIKANKAN HALDAR",
    saID: "SPR6973",
  },
  {
    firstName: "KIRAN CHANDRA",
    lastName: "BISWAS",
    address: "BAHARBONIA*BAGERHAT*KHULNA*BANGLADESH",
    fullName: "KIRAN CHANDRA BISWAS",
    saID: "SPR6974",
  },
  {
    firstName: "KSHITISH",
    lastName: "SEAL",
    address: "DINAJPUR ACCADEMY*PO&DIST-DINAJPUR*BANGLADESH",
    fullName: "KSHITISH SEAL",
    saID: "SPR6975",
  },
  {
    firstName: "KANAILAL",
    lastName: "PAL",
    address: "KHANJAPUR*BARISHAL*BANGLADESH",
    fullName: "KANAILAL PAL",
    saID: "SPR6976",
  },
  {
    firstName: "LATE KANAI LAL",
    lastName: "BISWAS",
    address: "BOYARSINGH*KHULNA*BANGLADESH",
    fullName: "LATE KANAI LAL BISWAS",
    saID: "SPR6977",
  },
  {
    firstName: "KARUNASINDHU",
    lastName: "TALUKDAR",
    address: "SUNAMGANJ*ABIR NAGAR*BANGLADESH",
    fullName: "KARUNASINDHU TALUKDAR",
    saID: "SPR6978",
  },
  {
    firstName: "KRISHNA KANTA",
    lastName: "MAHAJAN",
    address: "MIRZARHAT*CHITTAGONG*BANGLADESH",
    fullName: "KRISHNA KANTA MAHAJAN",
    saID: "SPR6979",
  },
  {
    firstName: "KANTI BHUSAN",
    lastName: "MANDAL",
    address: "GOPIGRAM*GAIBANDHA*BANGLADESH",
    fullName: "KANTI BHUSAN MANDAL",
    saID: "SPR6980",
  },
  {
    firstName: "KRISHNA PADA",
    lastName: "MANDAL",
    address: "AMURKOTA*KHULNA*BANGLADESH",
    fullName: "KRISHNA PADA MANDAL",
    saID: "SPR6981",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "TAPASWI",
    address: "PO-PATIHAL*HOWRAH",
    fullName: "LATE MANORANJAN TAPASWI",
    saID: "SPR6982",
  },
  {
    firstName: "MAHENDRA NATH",
    lastName: "SARKAR",
    address: "NABAPALLI*BARASAT*24PGS",
    fullName: "MAHENDRA NATH SARKAR",
    saID: "SPR6983",
  },
  {
    firstName: "MANADA RANJAN",
    lastName: "DEY",
    address: "SYLHET*BANGLADESH",
    fullName: "MANADA RANJAN DEY",
    saID: "SPR6984",
  },
  {
    firstName: "LATE MANOHAR CHANDRA",
    lastName: "BISWAS",
    address: "KHULNA*BANGLADESH",
    fullName: "LATE MANOHAR CHANDRA BISWAS",
    saID: "SPR6985",
  },
  {
    firstName: "MRINAL KANTI",
    lastName: "MOULIK",
    address: "KHULNA*BANGLADESH",
    fullName: "MRINAL KANTI MOULIK",
    saID: "SPR6986",
  },
  {
    firstName: "LATE MANIMOHAN",
    lastName: "SIKDER",
    address: "KHULNA*BANGLADESH",
    fullName: "LATE MANIMOHAN SIKDER",
    saID: "SPR6987",
  },
  {
    firstName: "LATE MANORANJAN",
    lastName: "PAL",
    address: "PAKBIJAYNAGAR*NOAKHALI*BANGLADESH",
    fullName: "LATE MANORANJAN PAL",
    saID: "SPR6988",
  },
  {
    firstName: "MURALIDHAR",
    lastName: "JHA",
    address: "RCC CHIEF CONTROLLER'S OFFICE*SAHEBGANJ",
    fullName: "MURALIDHAR JHA",
    saID: "SPR6989",
  },
  {
    firstName: "LATE NIRODBARAN",
    lastName: "GHOSH",
    address: "LALBAZAR*BARIPADA*MAYURBHANJ",
    fullName: "LATE NIRODBARAN GHOSH",
    saID: "SPR6990",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "SAHA",
    address: "GEONKHALI*MIDNAPORE",
    fullName: "LATE NANIGOPAL SAHA",
    saID: "SPR6991",
  },
  {
    firstName: "NIRMALENDU",
    lastName: "CHAKRAVARTY",
    address: "SANTOSHPUR ROAD QR NO-171*CALCUTTA 24",
    fullName: "NIRMALENDU CHAKRAVARTY",
    saID: "SPR6992",
  },
  {
    firstName: "LATE NIRODBARAN",
    lastName: "MAITI",
    address: "PO-DUMURDARI*MIDNAPORE",
    fullName: "LATE NIRODBARAN MAITI",
    saID: "SPR6993",
  },
  {
    firstName: "LATE NIDHIRAM",
    lastName: "BISWAS",
    address: "PO/VILL-RAGHUNATHPUR*NADIA",
    fullName: "LATE NIDHIRAM BISWAS",
    saID: "SPR6994",
  },
  {
    firstName: "NEPAL CHANDRA",
    lastName: "SARKAR",
    address: "JESSORE*BANGLADESH",
    fullName: "NEPAL CHANDRA SARKAR",
    saID: "SPR6995",
  },
  {
    firstName: "LATE NIRANJAN",
    lastName: "RAY",
    address: "19,SHYAM ROAD*PO-NAIHATI*24PGS",
    fullName: "LATE NIRANJAN RAY",
    saID: "SPR6996",
  },
  {
    firstName: "NABA KUMAR",
    lastName: "BISWAS",
    address: "KHULNA*BANGLADESH",
    fullName: "NABA KUMAR BISWAS",
    saID: "SPR6997",
  },
  {
    firstName: "LATE NIKHIL CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "DIGHARA*PO-DUTTAPUKUR*24PGS",
    fullName: "LATE NIKHIL CHANDRA CHAKRAVARTY",
    saID: "SPR6998",
  },
  {
    firstName: "LATE NEPAL CHANDRA",
    lastName: "SAHA",
    address: "PAKBIJAY NAGAR*NOAKHALI*BANGLADESH",
    fullName: "LATE NEPAL CHANDRA SAHA",
    saID: "SPR6999",
  },
  {
    firstName: "LATE MADHUSUDAN",
    lastName: "BISWAS",
    address: "BARISAL*BANGLADESH",
    fullName: "LATE MADHUSUDAN BISWAS",
    saID: "SPR7000",
  },
  {
    firstName: "MUKTIPADA",
    lastName: "MANDAL",
    address: "PAIKGACHHA*KHULNA*BANGLADESH",
    fullName: "MUKTIPADA MANDAL",
    saID: "SPR7001",
  },
  {
    firstName: "MANINDRA NATH",
    lastName: "BISWAS",
    address: "SATKHIRA*BANGLADESH",
    fullName: "MANINDRA NATH BISWAS",
    saID: "SPR7002",
  },
  {
    firstName: "MUKURDIPI",
    lastName: "MANDAL",
    address: "SATKHIRA*BANGLADESH",
    fullName: "MUKURDIPI MANDAL",
    saID: "SPR7003",
  },
  {
    firstName: "MOHINI KANTA",
    lastName: "RAY",
    address: "BARA BAUL*DINAJPUR*BANGLADESH",
    fullName: "MOHINI KANTA RAY",
    saID: "SPR7004",
  },
  {
    firstName: "MOTI LAL",
    lastName: "RAY",
    address: "SATKHIRA*BANGLADESH",
    fullName: "MOTI LAL RAY",
    saID: "SPR7005",
  },
  {
    firstName: "MAHANTA KUMAR",
    lastName: "HALDAR",
    address: "C/O BIPLAB CHAKRAVARTY*BARAJIRACKPUR*PO-BASIRHAT*24PARGANAS(N)",
    fullName: "MAHANTA KUMAR HALDAR",
    saID: "SPR7006",
  },
  {
    firstName: "MANIRATH",
    lastName: "DAS",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "MANIRATH DAS",
    saID: "SPR7007",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "SEALSARMA",
    address: "MATH BARIA*FEROZPUR*BANGLADESH",
    fullName: "MADHUSUDAN SEALSARMA",
    saID: "SPR7008",
  },
  {
    firstName: "MANOJ KUMAR",
    lastName: "SARKAR",
    address: "TIKARAMPUR*SATKHIRA*BANGLADESH",
    fullName: "MANOJ KUMAR SARKAR",
    saID: "SPR7009",
  },
  {
    firstName: "MANORANJAN",
    lastName: "DAS",
    address: "SUNAMGANJ*BANGLADESH",
    fullName: "MANORANJAN DAS",
    saID: "SPR7010",
  },
  {
    firstName: "MANILAL",
    lastName: "SANA",
    address: "PO-SABHANA*KHULNA*BANGLADESH",
    fullName: "MANILAL SANA",
    saID: "SPR7011",
  },
  {
    firstName: "MOHIT CHANDRA",
    lastName: "MANDAL",
    address: "TUARDANGA*SATKHIRA*BANGLADESH",
    fullName: "MOHIT CHANDRA MANDAL",
    saID: "SPR7012",
  },
  {
    firstName: "MADHUSUDAN",
    lastName: "SARKAR",
    address: "SUNAMGANJ*BANGLADESH",
    fullName: "MADHUSUDAN SARKAR",
    saID: "SPR7013",
  },
  {
    firstName: "ARUP",
    lastName: "GANGULY",
    address: "",
    fullName: "ARUP GANGULY",
    saID: "SPR7014",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "DAS",
    address: "MAYMANSINGH*BANGLADESH",
    fullName: "NANIGOPAL DAS",
    saID: "SPR7015",
  },
  {
    firstName: "NIRAPADA",
    lastName: "SARKAR",
    address: "SATKHIRA*BANGLADESH",
    fullName: "NIRAPADA SARKAR",
    saID: "SPR7016",
  },
  {
    firstName: "LATE NANIGOPAL",
    lastName: "DUTTA",
    address: "PATUAKHALI*PURANA BAZAR*BANGLADESH",
    fullName: "LATE NANIGOPAL DUTTA",
    saID: "SPR7017",
  },
  {
    firstName: "NITAI CHANDRA",
    lastName: "BAIDYA",
    address:
      "C/O NIMAI CHAND BAIDYA*35,BIRATI NEW ROAD*MANDIR PARA*NIMTA*KOLKATA-51",
    fullName: "NITAI CHANDRA BAIDYA",
    saID: "SPR7018",
  },
  {
    firstName: "NABA TARAN",
    lastName: "MANDAL",
    address: "NAFARGANJ*BANGLADESH",
    fullName: "NABA TARAN MANDAL",
    saID: "SPR7019",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "RAY",
    address: "PO-SRIRAMKATHI*SATKHIRA*BANGLADESH",
    fullName: "NIMAI CHANDRA RAY",
    saID: "SPR7020",
  },
  {
    firstName: "NAREN CHANDRA",
    lastName: "BARMAN",
    address: "RONGPUR*BANGLADESH",
    fullName: "NAREN CHANDRA BARMAN",
    saID: "SPR7021",
  },
  {
    firstName: "NIBARAN CHANDRA",
    lastName: "DEB",
    address: "HABIGANJ*BANGLADESH",
    fullName: "NIBARAN CHANDRA DEB",
    saID: "SPR7022",
  },
  {
    firstName: "NARENDRA NATH",
    lastName: "SHIL",
    address: "DINAJPUR*BANGLADESH",
    fullName: "NARENDRA NATH SHIL",
    saID: "SPR7023",
  },
  {
    firstName: "NEPAL KANTI",
    lastName: "DUTTA",
    address: "GORAKHGHATA*FOXBAZAR*BANGLADESH",
    fullName: "NEPAL KANTI DUTTA",
    saID: "SPR7024",
  },
  {
    firstName: "NIRANJAN KUMAR",
    lastName: "TANTI",
    address: "MOULAVI BAZAR*BANGLADESH",
    fullName: "NIRANJAN KUMAR TANTI",
    saID: "SPR7025",
  },
  {
    firstName: "ANIL",
    lastName: "MAITY",
    address: "VILL-PARJAPUR*PO-NOAPARA*24PARGANAS (NORTH)-700125",
    fullName: "ANIL MAITY",
    saID: "SPR7026",
  },
  {
    firstName: "KRUTIKAM",
    lastName: "MOHANTY",
    address:
      "216B/6,SECTOR-A,ZONE-B*MANCHESWAR IND ESTATE*BHUBANESWAR-751010*KHURDA",
    fullName: "KRUTIKAM MOHANTY",
    saID: "SPR7027",
  },
  {
    firstName: "LATE PRAVASH CHANDRA",
    lastName: "KHAN",
    address: "13/2,CHANDSARAK*PO-KRISHNAGAR*NADIA",
    fullName: "LATE PRAVASH CHANDRA KHAN",
    saID: "SPR7028",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "BANERJEE",
    address: "PATNA",
    fullName: "LATE PRAFULLA KUMAR BANERJEE",
    saID: "SPR7029",
  },
  {
    firstName: "LATE PARBATI CHARAN",
    lastName: "BARMA",
    address: "JOGNAI*PO-DEORH*WEST DINAJPUR",
    fullName: "LATE PARBATI CHARAN BARMA",
    saID: "SPR7030",
  },
  {
    firstName: "LATE PRIYA NATH",
    lastName: "SENKARMAKAR",
    address: "BARISAL*BANGLADESH",
    fullName: "LATE PRIYA NATH SENKARMAKAR",
    saID: "SPR7031",
  },
  {
    firstName: "PATIRAM",
    lastName: "MANDAL",
    address: "KHULNA*SAHEBKHALI*BANGLADESH",
    fullName: "PATIRAM MANDAL",
    saID: "SPR7032",
  },
  {
    firstName: "LATE PURNENDU BIKASH",
    lastName: "PAL",
    address: "LEWIS STREET*RANGOON*MAYNMAR",
    fullName: "LATE PURNENDU BIKASH PAL",
    saID: "SPR7033",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "BISWAS",
    address: "VILL-BANDA*PO-GHONA BANDA*PS-DUMURIA*DIST-KHULNA*BANGLADESH",
    fullName: "PARESH CHANDRA BISWAS",
    saID: "SPR7034",
  },
  {
    firstName: "PRAMATHA RANJAN",
    lastName: "MISTRY",
    address: "BARISAL*BANGLADESH",
    fullName: "PRAMATHA RANJAN MISTRY",
    saID: "SPR7035",
  },
  {
    firstName: "LATE RAY ARCHUR",
    lastName: "HOUSERMAN",
    address: "USA",
    fullName: "LATE RAY ARCHUR HOUSERMAN",
    saID: "SPR7036",
  },
  {
    firstName: "LATE RAMANATH",
    lastName: "BANERJEE",
    address: "RAMCHANDRAPUR ROAD*PO-SODEPUR*224PGS",
    fullName: "LATE RAMANATH BANERJEE",
    saID: "SPR7037",
  },
  {
    firstName: "LATE RAM PRASAD",
    lastName: "DEBNATH",
    address: "BAGHERHAT*KHULNA*BANGLADESH",
    fullName: "LATE RAM PRASAD DEBNATH",
    saID: "SPR7038",
  },
  {
    firstName: "PRALAY KUMAR",
    lastName: "MAJUMDER",
    address: "BAGUIATI*PO-ASWININAGAR*24PGS",
    fullName: "PRALAY KUMAR MAJUMDER",
    saID: "SPR7039",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "DEB",
    address: "CHITTAGONG*HAZARI LANE*BANGLADESH",
    fullName: "PRADIP KUMAR DEB",
    saID: "SPR7040",
  },
  {
    firstName: "LATE PRADIP",
    lastName: "MAJUMDER",
    address: "MITHAKHALI BAZAR*BAGHERHAT*BANGLADESH",
    fullName: "LATE PRADIP MAJUMDER",
    saID: "SPR7041",
  },
  {
    firstName: "PIJUSH KANTI",
    lastName: "PALIT",
    address: "ROUJAN*CHITTAGONG*BANGLADESH",
    fullName: "PIJUSH KANTI PALIT",
    saID: "SPR7042",
  },
  {
    firstName: "PANCHANAN",
    lastName: "MANDAL",
    address: "PO-GOALPOTA*SATKHIRA*BANGLADESH",
    fullName: "PANCHANAN MANDAL",
    saID: "SPR7043",
  },
  {
    firstName: "PRAMATHA RANJAN",
    lastName: "HALDAR",
    address: "VILL-NUNIADANGA*PO-BAINCHI*HOOGHLY-712134",
    fullName: "PRAMATHA RANJAN HALDAR",
    saID: "SPR7044",
  },
  {
    firstName: "PRABIR",
    lastName: "CHOUDHURY",
    address: "UTTAR TEMSHA*CHITTAGONG*BANGLADESH",
    fullName: "PRABIR CHOUDHURY",
    saID: "SPR7045",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "SHIL",
    address: "BAJNABAZAR*KHULNA*BANGLADESH",
    fullName: "PRAFULLA KUMAR SHIL",
    saID: "SPR7046",
  },
  {
    firstName: "PULIN CHANDRA",
    lastName: "RAY",
    address: "PO-MEHERPUR*DINAJPUR*BANGLADESH",
    fullName: "PULIN CHANDRA RAY",
    saID: "SPR7047",
  },
  {
    firstName: "PRAVAT CHANDRA",
    lastName: "SHIL",
    address: "ANANDABAZAR BANDAR*CHITTAGONG*BANGLADESH",
    fullName: "PRAVAT CHANDRA SHIL",
    saID: "SPR7048",
  },
  {
    firstName: "PRAVASH CHANDRA",
    lastName: "RAY",
    address: "PO-GARIARDANGA*KHULNA*BANGLADESH",
    fullName: "PRAVASH CHANDRA RAY",
    saID: "SPR7049",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "MANDAL",
    address: "GITA HOMOEO HALL*BARA ARIABAZAR*BATIAGHATA*KHULNA*BANGLADESH",
    fullName: "PRADIP KUMAR MANDAL",
    saID: "SPR7050",
  },
  {
    firstName: "PRATAP KUMAR",
    lastName: "RAY",
    address: "PO-BHABKI*DINAJPUR*BANGLADESH",
    fullName: "PRATAP KUMAR RAY",
    saID: "SPR7051",
  },
  {
    firstName: "PRANABENDU",
    lastName: "MANDAL",
    address: "PANJAPARA*PO-SIKDER MALLIK*FEROZPUR*BANGLADESH",
    fullName: "PRANABENDU MANDAL",
    saID: "SPR7052",
  },
  {
    firstName: "PARIMAL KANTI",
    lastName: "ACHARYA",
    address: "UTTAR HALISAHAR*PO-HOUSING STATE*CHITTAGONG*BANGLADESH",
    fullName: "PARIMAL KANTI ACHARYA",
    saID: "SPR7053",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MANDAL",
    address: "TANGRAMARI*SALDANGA*KHULNA*BANGLADESH",
    fullName: "PRAFULLA KUMAR MANDAL",
    saID: "SPR7054",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "RAY",
    address: "MAMINHATA*PO-SEKANPUKUR*BOGURA*BANGLADESH",
    fullName: "PARIMAL CHANDRA RAY",
    saID: "SPR7055",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "BARMAN",
    address: "PO-HATHDARIAPUR*GAIBANDHA*BANGLADESH",
    fullName: "PURNA CHANDRA BARMAN",
    saID: "SPR7056",
  },
  {
    firstName: "LATE RAMNATH",
    lastName: "PAL",
    address: "TERI BAZAR*CHITTAGONG*BANGLADESH",
    fullName: "LATE RAMNATH PAL",
    saID: "SPR7057",
  },
  {
    firstName: "RANJIT",
    lastName: "RAY",
    address: "123,HRISHIKESH DAS ROAD*PO-LAXMI BAZAR*DHAKA-1100*BANGLADESH",
    fullName: "RANJIT RAY",
    saID: "SPR7058",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "BAHADUR",
    address: "SARISAMATH*KHULNA*BANGLADESH",
    fullName: "RABINDRA NATH BAHADUR",
    saID: "SPR7059",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "MANDAL",
    address: "AMURKATA*KHULNA*BANGLADESH",
    fullName: "RANJIT KUMAR MANDAL",
    saID: "SPR7060",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "CHOUDHURY",
    address: "SUNAMGANJ*CHHATAK*BANGLADESH",
    fullName: "RANJIT KUMAR CHOUDHURY",
    saID: "SPR7061",
  },
  {
    firstName: "RAKHAL CHANDRA",
    lastName: "DEY",
    address: "PRADHANSARAK*PO/DIST-BANDARBAN*BANGLADESH",
    fullName: "RAKHAL CHANDRA DEY",
    saID: "SPR7062",
  },
  {
    firstName: "RAMESH CHANDRA",
    lastName: "PAL",
    address: "BEHALA*SARSUNA*KOLKATA-61",
    fullName: "RAMESH CHANDRA PAL",
    saID: "SPR7063",
  },
  {
    firstName: "LATE RATHINDRA NARAYAN",
    lastName: "BHATTACHERJEE",
    address: "86 UPKARN LANE NO-2*GPO CHITTAGONG*BANGLADESH",
    fullName: "LATE RATHINDRA NARAYAN BHATTACHERJEE",
    saID: "SPR7064",
  },
  {
    firstName: "RAKHAL RAJ",
    lastName: "MANDAL",
    address: "PO-BETAJI*DIST-BARGUNA*BANGLADESH",
    fullName: "RAKHAL RAJ MANDAL",
    saID: "SPR7065",
  },
  {
    firstName: "RATAN KUMAR",
    lastName: "DEB",
    address: "HOSPITAL ROAD*HABIGANJ*BANGLADESH",
    fullName: "RATAN KUMAR DEB",
    saID: "SPR7066",
  },
  {
    firstName: "RANATOSH",
    lastName: "SEN",
    address: "KENISAHAR*PATIA*CHITTAGONG*BANGLADESH",
    fullName: "RANATOSH SEN",
    saID: "SPR7067",
  },
  {
    firstName: "RANJIT",
    lastName: "CHOUDHURY",
    address: "SHIKARPUR*CHITTAGONG*BANGLADESH",
    fullName: "RANJIT CHOUDHURY",
    saID: "SPR7068",
  },
  {
    firstName: "RANJAN KUMAR",
    lastName: "MANDAL",
    address: "AMURKATA*KHULNA*BANGLADESH",
    fullName: "RANJAN KUMAR MANDAL",
    saID: "SPR7069",
  },
  {
    firstName: "LATE RAKESH RANJAN",
    lastName: "CHAKRAVARTY",
    address: "NILAY 174/1*SUNAMGANJ*BANGLADESH",
    fullName: "LATE RAKESH RANJAN CHAKRAVARTY",
    saID: "SPR7070",
  },
  {
    firstName: "LATE SUSHIL RANJAN",
    lastName: "DAS",
    address: "DAS VILLA*ISHANNAGAR*BALASORE",
    fullName: "LATE SUSHIL RANJAN DAS",
    saID: "SPR7071",
  },
  {
    firstName: "LATE SUDHIR RANJAN",
    lastName: "CHOUDHURY",
    address: "CK 63/2,CHHOTEPEARY*VARANASI*U P",
    fullName: "LATE SUDHIR RANJAN CHOUDHURY",
    saID: "SPR7072",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "MUKHERJEE",
    address: "BHARADWAJ SMRITI*THANAMORE*PO-KANCHRAPARA*24PGS",
    fullName: "LATE SUDHIR KUMAR MUKHERJEE",
    saID: "SPR7073",
  },
  {
    firstName: "LATE SATYA KINKAR",
    lastName: "PALIT",
    address: "CROSS ROAD 19,QR-35*SIDHGORA*PO-AGRICO*JAMSHEDPUR*SINGHBHUM",
    fullName: "LATE SATYA KINKAR PALIT",
    saID: "SPR7074",
  },
  {
    firstName: "LATE SAILESH",
    lastName: "BANERJEE",
    address: "PLEADER*PO-SAMASTIPUR*DARBHANGA",
    fullName: "LATE SAILESH BANERJEE",
    saID: "SPR7075",
  },
  {
    firstName: "LATE SANTOSH",
    lastName: "CHATTERJEE",
    address: "SANTOSH CLINIC*JAGADISHPUR*TATANAGAR*SINGHBHUM",
    fullName: "LATE SANTOSH CHATTERJEE",
    saID: "SPR7076",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "MANDAL",
    address: "SANYALCHAR*NADIA",
    fullName: "LATE SATISH CHANDRA MANDAL",
    saID: "SPR7077",
  },
  {
    firstName: "SATYADEO NARAYAN",
    lastName: "PRASAD",
    address: "PO-AGARHAT*DARBHANGA",
    fullName: "SATYADEO NARAYAN PRASAD",
    saID: "SPR7078",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "BISWAS",
    address: "POSTAL INSPECTOR CHANDIGARH*PO-KORA CHANDIGARH*24PGS",
    fullName: "LATE SUDHIR KUMAR BISWAS",
    saID: "SPR7079",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "MUKHERJEE",
    address: "NAIHATI*24PARGANAS",
    fullName: "LATE SUDHIR KUMAR MUKHERJEE",
    saID: "SPR7080",
  },
  {
    firstName: "LATE SUSHIL CHANDRA",
    lastName: "BOSE",
    address: "SATSANG",
    fullName: "LATE SUSHIL CHANDRA BOSE",
    saID: "SPR7081",
  },
  {
    firstName: "LATE SIB SANKAR",
    lastName: "CHATTERJEE",
    address: "56/1,NABIN SENAPATI LANE*HOWRAH",
    fullName: "LATE SIB SANKAR CHATTERJEE",
    saID: "SPR7082",
  },
  {
    firstName: "LATE SASHI KUMAR",
    lastName: "DEY",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "LATE SASHI KUMAR DEY",
    saID: "SPR7083",
  },
  {
    firstName: "SAMSAR",
    lastName: "RAJBANSHI",
    address: "C/O DIPLAL SAH*VILL-PAIAMARI*PO-THIRANIGANJ*PURNEA",
    fullName: "SAMSAR RAJBANSHI",
    saID: "SPR7084",
  },
  {
    firstName: "LATE SURENDRA",
    lastName: "SARMACHARYA",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE SURENDRA SARMACHARYA",
    saID: "SPR7085",
  },
  {
    firstName: "SAMAR KUMAR",
    lastName: "MUKHERJEE",
    address: "ASHOK PHARMACY*BIRAT NAGAR*JOGBANI*PURNEA",
    fullName: "SAMAR KUMAR MUKHERJEE",
    saID: "SPR7086",
  },
  {
    firstName: "LATE SUBHAS CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "NILACHAL*PO-KHARDAHA*24PARGANAS",
    fullName: "LATE SUBHAS CHANDRA CHAKRAVARTY",
    saID: "SPR7087",
  },
  {
    firstName: "SUDHANGSHU MOHAN",
    lastName: "DAS",
    address: "BARABISHA*DAKSHIN RAMPUR*JALPAIGURI",
    fullName: "SUDHANGSHU MOHAN DAS",
    saID: "SPR7088",
  },
  {
    firstName: "TARAPADA",
    lastName: "PAN",
    address: "BOKARO CIVIL DIVISION(D.V.C)*BOKARO",
    fullName: "TARAPADA PAN",
    saID: "SPR7089",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "SEAL",
    address: "C/O SUDHIR KUMAR PAL*CHHOTONILPUR*BURDWAN",
    fullName: "LATE UPENDRA NATH SEAL",
    saID: "SPR7090",
  },
  {
    firstName: "UPENDRA KUMAR",
    lastName: "PAL",
    address: "PO-KANCHANBARI*TRIPURA",
    fullName: "UPENDRA KUMAR PAL",
    saID: "SPR7091",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "RAY",
    address: "PRIMARY HEALTH CENTRE*PO-BAKULIA*HOOGHLY",
    fullName: "LATE SANTOSH KUMAR RAY",
    saID: "SPR7092",
  },
  {
    firstName: "SHYAMGOPAL",
    lastName: "DEY",
    address: "BHARATPUR*RAJASTHAN",
    fullName: "SHYAMGOPAL DEY",
    saID: "SPR7093",
  },
  {
    firstName: "SATYA JYOTI",
    lastName: "BOSE",
    address:
      "195 WELLINGTON STREET*SOUTH APTT NO-313,HAMILTON-20*ONTARIO*CANADA",
    fullName: "SATYA JYOTI BOSE",
    saID: "SPR7094",
  },
  {
    firstName: "SACHINDRA NATH",
    lastName: "SEALSARMA",
    address: "MAHARAJGANJ*DINAJPUR*BANGLADESH",
    fullName: "SACHINDRA NATH SEALSARMA",
    saID: "SPR7095",
  },
  {
    firstName: "LATE SRINATH",
    lastName: "TALUKDAR",
    address: "PO-SONAUTA*PATUAKHALI*BANGLADESH",
    fullName: "LATE SRINATH TALUKDAR",
    saID: "SPR7096",
  },
  {
    firstName: "SISIR BINDU",
    lastName: "SARKAR",
    address: "KHEJURDANGA*BINARPOTA*SATKHIRA*BANGLADESH",
    fullName: "SISIR BINDU SARKAR",
    saID: "SPR7097",
  },
  {
    firstName: "SUNIL KANTI",
    lastName: "DAS",
    address: "VILL-DHEMSA*CHOUDHURY HAT*CHITTAGONG*BANGLADESH",
    fullName: "SUNIL KANTI DAS",
    saID: "SPR7098",
  },
  {
    firstName: "SANKAR",
    lastName: "CHAKRAVARTY",
    address: "PAIKGACHHA SATSANG VIHAR*BATI KHALI;PAIKGACHHA*KHULNA*BANGLADESH",
    fullName: "SANKAR CHAKRAVARTY",
    saID: "SPR7099",
  },
  {
    firstName: "SUNIL KANTI",
    lastName: "CHAKRAVARTY",
    address: "FATEYABAD*CHITTAGONG*BANGLADESH",
    fullName: "SUNIL KANTI CHAKRAVARTY",
    saID: "SPR7100",
  },
  {
    firstName: "LATE SUBODH RANJAN",
    lastName: "RAY",
    address: "BOALMARI*FARIDPUR*BANGLADESH",
    fullName: "LATE SUBODH RANJAN RAY",
    saID: "SPR7101",
  },
  {
    firstName: "SATYA RANJAN",
    lastName: "MANDAL",
    address: "VILL-NUNIADANGA*PO-BOINCHI*HOOGHLYA",
    fullName: "SATYA RANJAN MANDAL",
    saID: "SPR7102",
  },
  {
    firstName: "SUBRATA",
    lastName: "RAY",
    address: "CHALNA BAZAR*KHULNA*BANGLADESH",
    fullName: "SUBRATA RAY",
    saID: "SPR7103",
  },
  {
    firstName: "SUDHANYA KUMAR",
    lastName: "MANDAL",
    address: "PURBA MUNSHIGANJ*SATKHIRA*BANGLADESH",
    fullName: "SUDHANYA KUMAR MANDAL",
    saID: "SPR7104",
  },
  {
    firstName: "LATE SATYENDRA",
    lastName: "CHANDA",
    address: "BANIACHANG*HABIGANJ*BANGLADESH",
    fullName: "LATE SATYENDRA CHANDA",
    saID: "SPR7105",
  },
  {
    firstName: "SUKUMAR",
    lastName: "DAS",
    address: "BANIACHANG*HABIGANJ*BANGLADESH",
    fullName: "SUKUMAR DAS",
    saID: "SPR7106",
  },
  {
    firstName: "LATE SHYAMAL CHANDRA",
    lastName: "SEALSARMA",
    address: "PO-KALIBARI*TANGAIL*BANGLADESH",
    fullName: "LATE SHYAMAL CHANDRA SEALSARMA",
    saID: "SPR7107",
  },
  {
    firstName: "SANTI RANJAN",
    lastName: "SARKAR",
    address:
      "GAJIPUR SIMANTA SATSANG SRIMANDIR*PO-BHARUKHALI*DIST-SATKHIRA, BANGLADESH.",
    fullName: "SANTI RANJAN SARKAR",
    saID: "SPR7108",
  },
  {
    firstName: "SUDHANGSHU KUMAR",
    lastName: "MALLIK",
    address: "KODALA*PO-BARA ARIA*KHULNA*BANGLADESH",
    fullName: "SUDHANGSHU KUMAR MALLIK",
    saID: "SPR7109",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "MANDAL",
    address: "BAINBARIA*KHULNA*BANGLADESH",
    fullName: "SWAPAN KUMAR MANDAL",
    saID: "SPR7110",
  },
  {
    firstName: "LATE SREEPADA",
    lastName: "KUNDU",
    address: "DEWANBARI ROAD*RONGPUR*BANGLADESH",
    fullName: "LATE SREEPADA KUNDU",
    saID: "SPR7111",
  },
  {
    firstName: "SHYAMA PRASAD",
    lastName: "BHATTACHERJEE",
    address: "GOALA BAZAR*SYLHET*BANGLADESH",
    fullName: "SHYAMA PRASAD BHATTACHERJEE",
    saID: "SPR7112",
  },
  {
    firstName: "SUBID",
    lastName: "CHAKRAVARTY",
    address: "249 BAGBARI*SWASTI VILLA*PO/DIST-SYLHET*BANGLADESH",
    fullName: "SUBID CHAKRAVARTY",
    saID: "SPR7113",
  },
  {
    firstName: "SUMATI RANJAN",
    lastName: "DASGUPTA",
    address: "DAKSHIN SULTANPUR*CHHITIAPARA*RAUJAN*CHITTAGONG*BANGLADESH",
    fullName: "SUMATI RANJAN DASGUPTA",
    saID: "SPR7114",
  },
  {
    firstName: "SUBHASH CHANDRA",
    lastName: "ACHARYA",
    address: "RAHAMAT GANJ*CHITTAGONG*BANGLADESH",
    fullName: "SUBHASH CHANDRA ACHARYA",
    saID: "SPR7115",
  },
  {
    firstName: "SUBHASH",
    lastName: "SENKARMAKAR",
    address: "PO/DIST-BHOLA*BARISAL*BANGLADESH",
    fullName: "SUBHASH SENKARMAKAR",
    saID: "SPR7116",
  },
  {
    firstName: "LATE TARANI CHARAN",
    lastName: "SEAL",
    address: "BANIACHANG*SYLHET*BANGLADESH",
    fullName: "LATE TARANI CHARAN SEAL",
    saID: "SPR7117",
  },
  {
    firstName: "TULSHI KANTA",
    lastName: "RAY",
    address: "PO-PALASHBARI*DINAJPUR*BANGLADESH",
    fullName: "TULSHI KANTA RAY",
    saID: "SPR7118",
  },
  {
    firstName: "TRIDIB TARAN",
    lastName: "MANDAL",
    address: "HARINAGAR*SATKHIRA*BANGLADESH",
    fullName: "TRIDIB TARAN MANDAL",
    saID: "SPR7119",
  },
  {
    firstName: "TAMAL",
    lastName: "DASGUPTA",
    address: "PURANGARH*CHITTAGONG*BANGLADESH",
    fullName: "TAMAL DASGUPTA",
    saID: "SPR7120",
  },
  {
    firstName: "TIMIR KANTI",
    lastName: "SEN",
    address: "126 HAZARI LANE*CHITTAGONG*BANGLADESH",
    fullName: "TIMIR KANTI SEN",
    saID: "SPR7121",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "SARKAR",
    address: "HARINKHOLA*PO-TIKARAMPUR*SATKHIRA*BANGLADESH",
    fullName: "UPENDRA NATH SARKAR",
    saID: "SPR7122",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "RAY",
    address: "BHOLAGURI*LAMANIRHAT*BANGLADESH",
    fullName: "UPENDRA NATH RAY",
    saID: "SPR7123",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "GHOSH",
    address: "BACHAMARI GOVT COLONY*PO-BACHAMARI(MANGALBARI)*MALDA-732142",
    fullName: "SURESH CHANDRA GHOSH",
    saID: "SPR7124",
  },
  {
    firstName: "SRISH CHANDRA",
    lastName: "RAY",
    address: "MANMATHAPUR*DINAJPUR*BANGLADESH",
    fullName: "SRISH CHANDRA RAY",
    saID: "SPR7125",
  },
  {
    firstName: "LATE SUBHASH CHANDRA",
    lastName: "SARKAR",
    address: "KAGACHI PARA*KHULNA*BANGLADESH",
    fullName: "LATE SUBHASH CHANDRA SARKAR",
    saID: "SPR7126",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "RAY",
    address: "PO-KALIBARI HAT*FEROZPUR*BANGLADESH",
    fullName: "SUDHIR KUMAR RAY",
    saID: "SPR7127",
  },
  {
    firstName: "SUKUMAR",
    lastName: "DEY",
    address: "PO-KUNDESWARI BHAWAN*CHITTAGONG*BANGLADESH",
    fullName: "SUKUMAR DEY",
    saID: "SPR7128",
  },
  {
    firstName: "SUBRATA",
    lastName: "ADITYA",
    address: "SATSANG ASHRAM PAKUTIA*PO-B PAKUTIA*TANGAIL*BANGLADESH",
    fullName: "SUBRATA ADITYA",
    saID: "SPR7129",
  },
  {
    firstName: "SANDEEP RAMDAS",
    lastName: "SURYAWANSHI",
    address: "AT/PO-CHAKAN*LATAKUNJ*PUNE-410501*MAHARASTRA*PAN-AWZPS3400P",
    fullName: "SANDEEP RAMDAS SURYAWANSHI",
    saID: "SPR7130",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "PAL",
    address: "VILL-GHOSHGHATI*ULLAPARA*SIRAJGANJ*BANGLADESH",
    fullName: "SUNIL KUMAR PAL",
    saID: "SPR7131",
  },
  {
    firstName: "TARASANKAR",
    lastName: "MALLIK",
    address: "GAJENDRAPUR*RONGPUR KALIBARI*DEMURIA*KHULNA*BANGLADESH",
    fullName: "TARASANKAR MALLIK",
    saID: "SPR7132",
  },
  {
    firstName: "LATE BIRENDRA KUMAR",
    lastName: "MUHURI",
    address: "4 GORFA 4TH LANE*CALCUTTA 32",
    fullName: "LATE BIRENDRA KUMAR MUHURI",
    saID: "SPR7133",
  },
  {
    firstName: "LATE CHANDRANATH",
    lastName: "BAIDYA",
    address: "98 ND/BLOCK-E,N R AVENEUE*NEW ALIPUR*CALCUTTA 53",
    fullName: "LATE CHANDRANATH BAIDYA",
    saID: "SPR7134",
  },
  {
    firstName: "JOGINDRA NATH",
    lastName: "MISHRA",
    address: "67, J M AVENUE*CALCUTTA 5",
    fullName: "JOGINDRA NATH MISHRA",
    saID: "SPR7135",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "ROYDAS",
    address: "11 GORA CHAND LANE*CALCUTTA 14",
    fullName: "LATE KSHITISH CHANDRA ROYDAS",
    saID: "SPR7136",
  },
  {
    firstName: "LATE KIRAN CHANDRA",
    lastName: "MUKHERJEE",
    address: "40 BADRIDAS TEMPLE STREET*CALCUTTA 4",
    fullName: "LATE KIRAN CHANDRA MUKHERJEE",
    saID: "SPR7137",
  },
  {
    firstName: "LATE LALMOHAN",
    lastName: "DAS",
    address: "INFORMATION PUBLIC RELATION*WRITERS BUILDING*CALCUTTA 1",
    fullName: "LATE LALMOHAN DAS",
    saID: "SPR7138",
  },
  {
    firstName: "NARAYAN DAS",
    lastName: "BHATTACHERJEE",
    address: "151 SUBHASH NAGAR BYE LANE*CALCUTTA 28",
    fullName: "NARAYAN DAS BHATTACHERJEE",
    saID: "SPR7139",
  },
  {
    firstName: "LATE AHIBHUSAN",
    lastName: "PRADHAN",
    address: "PO/VILL-GOBARDANGA*24PARGANAS",
    fullName: "LATE AHIBHUSAN PRADHAN",
    saID: "SPR7140",
  },
  {
    firstName: "KALPANATH",
    lastName: "SAMADDAR",
    address: "SUB REGISTRY OFFICE*BASIRHAT*24PARGANAS",
    fullName: "KALPANATH SAMADDAR",
    saID: "SPR7141",
  },
  {
    firstName: "LATE KHAGENDRA NATH",
    lastName: "MALLIK",
    address: "METIGANJ*PO-BANGAON*24PARGANAS",
    fullName: "LATE KHAGENDRA NATH MALLIK",
    saID: "SPR7142",
  },
  {
    firstName: "MUKUNDALAL",
    lastName: "GUHA",
    address: "PO/VILL-GOSABA*24PARGANAS",
    fullName: "MUKUNDALAL GUHA",
    saID: "SPR7143",
  },
  {
    firstName: "LATE PRAFULLA RANJAN",
    lastName: "BANERJEE",
    address: "CHAMPADALI*BARASAT*24PARGANAS",
    fullName: "LATE PRAFULLA RANJAN BANERJEE",
    saID: "SPR7144",
  },
  {
    firstName: "LATE SURENDRA NATH",
    lastName: "BHATTACHERJEE",
    address: "PALLA*24PARGANAS",
    fullName: "LATE SURENDRA NATH BHATTACHERJEE",
    saID: "SPR7145",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "MANDAL",
    address: "PO/VILL-MASLANDAPUR*24PARGANAS",
    fullName: "LATE SARAT CHANDRA MANDAL",
    saID: "SPR7146",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "BANERJEE",
    address: "BIJAY NAGAR*GOSABA*24PARGANAS",
    fullName: "LATE SUBODH CHANDRA BANERJEE",
    saID: "SPR7147",
  },
  {
    firstName: "SAHAY RAM",
    lastName: "NATH",
    address: "GEORGE ROAD NO-6*NAIHATI*24PARGANAS",
    fullName: "SAHAY RAM NATH",
    saID: "SPR7148",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "SINGHA",
    address: "T-304 NEGALI NAGAR COLONY*PO-NAIHATI*24PARGANAS",
    fullName: "LATE SAILENDRA NATH SINGHA",
    saID: "SPR7149",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "CHATTERJEE",
    address: "BARIA*PO-BASULDANGA*24PARGANAS",
    fullName: "LATE SAILENDRA NATH CHATTERJEE",
    saID: "SPR7150",
  },
  {
    firstName: "LATE SRIPADA",
    lastName: "BHATTACHERJEE",
    address: "LABOUR BRANCH*RIFLE FACTORY*ICHHAPUR*24PARGANAS",
    fullName: "LATE SRIPADA BHATTACHERJEE",
    saID: "SPR7151",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "MUKHERJEE",
    address: "JAN MAHAMMADGHAT ROAD*PO-NAIHATI*24PARGANAS",
    fullName: "LATE SUDHIR KUMAR MUKHERJEE",
    saID: "SPR7152",
  },
  {
    firstName: "LATE SURESH CHANDRA",
    lastName: "BISWAS",
    address: "14 CANNINGHAM ROAD*PO-NAIHATI*24PARGANAS",
    fullName: "LATE SURESH CHANDRA BISWAS",
    saID: "SPR7153",
  },
  {
    firstName: "LATE HARENDRA NATH",
    lastName: "GHOSH",
    address: "SATSANG ADHIBESAN KENDRA*BARASAT*24PARGANAS",
    fullName: "LATE HARENDRA NATH GHOSH",
    saID: "SPR7154",
  },
  {
    firstName: "JATINDRA MOHAN",
    lastName: "GHOSH",
    address: "(GOSWAMI)*117/1,HOWRAH ROAD*PO-SALKIA*HOWRAH",
    fullName: "JATINDRA MOHAN GHOSH",
    saID: "SPR7155",
  },
  {
    firstName: "LATE KRISHNA CHANDRA",
    lastName: "BANERJEE",
    address: "158/3 SHIBPUR ROAD*PO-SHIBPUR*HOWRAH",
    fullName: "LATE KRISHNA CHANDRA BANERJEE",
    saID: "SPR7156",
  },
  {
    firstName: "LATE MRIGANKA BHUSAN",
    lastName: "BERA",
    address: "SHITALPUR*PO-SHYAMPUR*HOWRAH",
    fullName: "LATE MRIGANKA BHUSAN BERA",
    saID: "SPR7157",
  },
  {
    firstName: "LATE MANINDR NATH",
    lastName: "DUTTA",
    address: "118/1 BRINDABAN MALLIK LANE*HOWRAH",
    fullName: "LATE MANINDR NATH DUTTA",
    saID: "SPR7158",
  },
  {
    firstName: "LATE NIRAPADA",
    lastName: "RAY",
    address: "29/14 NARSINGH DUTTA ROAD*PO-KADAMTALA*HOWRAH",
    fullName: "LATE NIRAPADA RAY",
    saID: "SPR7159",
  },
  {
    firstName: "LATE SUDHIR CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "78/1/3/1,NANDALAL MUKHERJEE LANE*SANTRAGACHI*HOWRAH",
    fullName: "LATE SUDHIR CHANDRA CHAKRAVARTY",
    saID: "SPR7160",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "GHOSHSHASTRI",
    address: "BUROBIBI LANE*PO-SRIRAMPUR*HOOGHLY",
    fullName: "LATE GOPAL CHANDRA GHOSHSHASTRI",
    saID: "SPR7161",
  },
  {
    firstName: "LATE GADADHAR",
    lastName: "CHAKRAVARTY",
    address: "PO/VILL-BHADRAKALI*HOOGHLY",
    fullName: "LATE GADADHAR CHAKRAVARTY",
    saID: "SPR7162",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "CHATTERJEE",
    address: "JAMINDAR ROAD*GORA BAZAR*BERHAMPUR*MURSHIDABAD",
    fullName: "LATE PRAFULLA KUMAR CHATTERJEE",
    saID: "SPR7163",
  },
  {
    firstName: "LATE SATYANARAYAN",
    lastName: "BANERJEE",
    address: "KALA BAGAN*PO-KANDI*MURSHIDABAD",
    fullName: "LATE SATYANARAYAN BANERJEE",
    saID: "SPR7164",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "GUHA",
    address: "PO/VILL-PRITI NAGAR*NADIA",
    fullName: "LATE KSHITISH CHANDRA GUHA",
    saID: "SPR7165",
  },
  {
    firstName: "LATE MUKUNDA BIHARI",
    lastName: "SIKDER",
    address: "EAST JAGADANANDAPUR*PO-BETHUADAHARI*NADIA",
    fullName: "LATE MUKUNDA BIHARI SIKDER",
    saID: "SPR7166",
  },
  {
    firstName: "ASWINI KUMAR",
    lastName: "DAS",
    address: "AMIYA MEDICAL STORES*NEW TOWN*PO-GUSHKARA*BURDWAN",
    fullName: "ASWINI KUMAR DAS",
    saID: "SPR7167",
  },
  {
    firstName: "LATE DWIJENDRA NATH",
    lastName: "PANDA",
    address: "PADUMBASAN*PO-TAMLUK*MIDNAPORE",
    fullName: "LATE DWIJENDRA NATH PANDA",
    saID: "SPR7168",
  },
  {
    firstName: "LATE SANTALAL",
    lastName: "SINGHA",
    address: "KHEMPUR*PO-CHARALMONI*MALDA",
    fullName: "LATE SANTALAL SINGHA",
    saID: "SPR7169",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "ROY",
    address: "SATSANG KENDRA*PO-JATESWAR*JALPAIGURI",
    fullName: "LATE JAGADISH CHANDRA ROY",
    saID: "SPR7170",
  },
  {
    firstName: "LATE RAMANI MOHAN",
    lastName: "SAHA",
    address: "DESHBANDHUPALLY*PO-ALIPURDUAR*JALPAIGURI",
    fullName: "LATE RAMANI MOHAN SAHA",
    saID: "SPR7171",
  },
  {
    firstName: "LATE GOPAL CHANDRA",
    lastName: "ROYBARMAN",
    address: "(DAKUA)*PO-DEOGAON*JALPAIGURI",
    fullName: "LATE GOPAL CHANDRA ROYBARMAN",
    saID: "SPR7172",
  },
  {
    firstName: "RAJAT BARAN",
    lastName: "DUTTARAY",
    address: "NABAPALLY*PO-BARASAT*24PARGANAS",
    fullName: "RAJAT BARAN DUTTARAY",
    saID: "SPR7173",
  },
  {
    firstName: "LATE BIJAY BHUSAN",
    lastName: "RAY",
    address: "SITALKUCHI*PO-GOSAIRHAT-BANDAR*COOCH BEHAR",
    fullName: "LATE BIJAY BHUSAN RAY",
    saID: "SPR7174",
  },
  {
    firstName: "LATE PRAN KUMAR",
    lastName: "NATH",
    address: "SATSANG KENDRA*PO-HOJAI*NAGAON",
    fullName: "LATE PRAN KUMAR NATH",
    saID: "SPR7175",
  },
  {
    firstName: "LATE HARENDRA CHANDRA",
    lastName: "DEY",
    address: "M/S BANIK STORES*PO-DHARMANAGAR*TRIPURA",
    fullName: "LATE HARENDRA CHANDRA DEY",
    saID: "SPR7176",
  },
  {
    firstName: "LATE BASANTA KUMAR",
    lastName: "SARMA",
    address: "TOPRA*PO-HEMKUNJA*PURNEA",
    fullName: "LATE BASANTA KUMAR SARMA",
    saID: "SPR7177",
  },
  {
    firstName: "LATE DASARATH PRASAD",
    lastName: "SINGH",
    address: "AMIRABAD*PO-KATAKOSH*KATIHAR",
    fullName: "LATE DASARATH PRASAD SINGH",
    saID: "SPR7178",
  },
  {
    firstName: "LATE RAM KUMAR",
    lastName: "KUNAR",
    address: "GUARD N.E.RLY*PO/DT-SAMASTIPUR",
    fullName: "LATE RAM KUMAR KUNAR",
    saID: "SPR7179",
  },
  {
    firstName: "LATE CHANDRESWAR PD",
    lastName: "THAKUR",
    address: "RASOLPUR*PO-BISHNUDATPUR*MUZAFFARPUR",
    fullName: "LATE CHANDRESWAR PD THAKUR",
    saID: "SPR7180",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "LATE JATINDRA NATH DAS",
    saID: "SPR7181",
  },
  {
    firstName: "LATE BHUSAN CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "SATSANG",
    fullName: "LATE BHUSAN CHANDRA CHAKRAVARTY",
    saID: "SPR7182",
  },
  {
    firstName: "LATE BIMAL CHANDRA",
    lastName: "MUKHERJEE",
    address: "SATSANG",
    fullName: "LATE BIMAL CHANDRA MUKHERJEE",
    saID: "SPR7183",
  },
  {
    firstName: "LATE BANBIHARI",
    lastName: "GHOSH",
    address: "SATSANG",
    fullName: "LATE BANBIHARI GHOSH",
    saID: "SPR7184",
  },
  {
    firstName: "LATE KALIDAS",
    lastName: "MAJUMDER",
    address: "SATSANG",
    fullName: "LATE KALIDAS MAJUMDER",
    saID: "SPR7185",
  },
  {
    firstName: "LATE DEBENDRA NATH",
    lastName: "RAY",
    address: "SATSANG",
    fullName: "LATE DEBENDRA NATH RAY",
    saID: "SPR7186",
  },
  {
    firstName: "LATE SACHINDRA NATH",
    lastName: "GANGULI",
    address: "SATSANG",
    fullName: "LATE SACHINDRA NATH GANGULI",
    saID: "SPR7187",
  },
  {
    firstName: "LATE NAGENDRA NATH",
    lastName: "SEN",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE NAGENDRA NATH SEN",
    saID: "SPR7188",
  },
  {
    firstName: "LATE NIROD BIHARI",
    lastName: "MAJUMDER",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE NIROD BIHARI MAJUMDER",
    saID: "SPR7189",
  },
  {
    firstName: "LATE KALI BHUSAN",
    lastName: "CHAKRAVARTY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE KALI BHUSAN CHAKRAVARTY",
    saID: "SPR7190",
  },
  {
    firstName: "LATE PRATUL CHANDRA",
    lastName: "DEB",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE PRATUL CHANDRA DEB",
    saID: "SPR7191",
  },
  {
    firstName: "LATE KRISHNA CHARAN",
    lastName: "CHAKRAVARTY",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE KRISHNA CHARAN CHAKRAVARTY",
    saID: "SPR7192",
  },
  {
    firstName: "LATE BATA KRISHNA",
    lastName: "GANGULI",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE BATA KRISHNA GANGULI",
    saID: "SPR7193",
  },
  {
    firstName: "LATE AKHIL NATH",
    lastName: "GANGULI",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE AKHIL NATH GANGULI",
    saID: "SPR7194",
  },
  {
    firstName: "LATE RAJENDRA KISHORE",
    lastName: "PAL",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE RAJENDRA KISHORE PAL",
    saID: "SPR7195",
  },
  {
    firstName: "LATE JATINDRA NATH",
    lastName: "MAJUMDER",
    address: "SATSANG*DEOGHAR",
    fullName: "LATE JATINDRA NATH MAJUMDER",
    saID: "SPR7196",
  },
  {
    firstName: "LATE KSHITISH CHANDRA",
    lastName: "ROY",
    address: "I.A.S*5D KALU GHOSH LANE*CALCUTTA 9",
    fullName: "LATE KSHITISH CHANDRA ROY",
    saID: "SPR7197",
  },
  {
    firstName: "LATE PRAVASH CHANDRA",
    lastName: "BRAHMA",
    address: "OFFICE SUPERINTENDENT,COLLECTORATE*BALASORE",
    fullName: "LATE PRAVASH CHANDRA BRAHMA",
    saID: "SPR7198",
  },
  {
    firstName: "RABINDRA NARAYAN",
    lastName: "DEY",
    address: "STENO GRAPHER,ZILA PARISHAD*BALASORE",
    fullName: "RABINDRA NARAYAN DEY",
    saID: "SPR7199",
  },
  {
    firstName: "LATE SARAT CHANDRA",
    lastName: "DEO",
    address: "DY MAGISTRATE,TOUZI OFFICER*BALASORE",
    fullName: "LATE SARAT CHANDRA DEO",
    saID: "SPR7200",
  },
  {
    firstName: "LATE BISWANATH JAGANNATH",
    lastName: "MATRE",
    address: "JOYGURU PANDURAM*WADI,GOREGAON*BOMBAY-63",
    fullName: "LATE BISWANATH JAGANNATH MATRE",
    saID: "SPR7201",
  },
  {
    firstName: "LATE RAJENDRA NATH",
    lastName: "MUKHERJEE",
    address: "B 20/185 BHELUPARA*VARANASI*U.P.",
    fullName: "LATE RAJENDRA NATH MUKHERJEE",
    saID: "SPR7202",
  },
  {
    firstName: "LATE RAJPALTAN",
    lastName: "SINGH",
    address: "ACOS(R) DCO'S OFFICE*GORAKHPUR*U.P.",
    fullName: "LATE RAJPALTAN SINGH",
    saID: "SPR7203",
  },
  {
    firstName: "NARMAN D",
    lastName: "FENN",
    address: "CARLETON UNIVERSITY*OTTOWA*CANADA",
    fullName: "NARMAN D FENN",
    saID: "SPR7204",
  },
  {
    firstName: "REV ASHOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE KUMAR CHAKRABORTY",
    saID: "SPR7205",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7206",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7207",
  },
  {
    firstName: "GOSTH ABIHARI",
    lastName: "BHOWMIK",
    address: "",
    fullName: "GOSTH ABIHARI BHOWMIK",
    saID: "SPR7208",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR7209",
  },
  {
    firstName: "RAJ SINGH",
    lastName: "DKHAR",
    address:
      "AGRICULTURE QR NO 14*FRUIT GARDEN VIA FISHERIES*RIA COLONY*LAITUMKHRAH*SHILLONG 3",
    fullName: "RAJ SINGH DKHAR",
    saID: "SPR7210",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7211",
  },
  {
    firstName: "LATE PARESH CHANDRA",
    lastName: "DUTTAGUPTA",
    address: "4/1 BAMA CHARAN RAY ROAD*CALCUTTA 34",
    fullName: "LATE PARESH CHANDRA DUTTAGUPTA",
    saID: "SPR7212",
  },
  {
    firstName: "LATE HARALAL",
    lastName: "MUKHERJEE",
    address: "8 HASTINGS ROAD*CALCUTTA 1",
    fullName: "LATE HARALAL MUKHERJEE",
    saID: "SPR7213",
  },
  {
    firstName: "LATE SATYENDRA NATH",
    lastName: "CHATTERJEE",
    address: "3/1/1B RANI SHANKAR LANE*CALCUTTA 26",
    fullName: "LATE SATYENDRA NATH CHATTERJEE",
    saID: "SPR7214",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "BANERJEE",
    address: "BARISAL*BANGLADESH",
    fullName: "LATE SUDHIR KUMAR BANERJEE",
    saID: "SPR7215",
  },
  {
    firstName: "LATE TAPATI",
    lastName: "MUKHERJEE",
    address: "56,KAILASH BOSE STREET*CALCUTTA 6",
    fullName: "LATE TAPATI MUKHERJEE",
    saID: "SPR7216",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "BHATTACHERJEE",
    address: "17A KALIMUDDIN LANE*CALCUTTA 4",
    fullName: "LATE UPENDRA NATH BHATTACHERJEE",
    saID: "SPR7217",
  },
  {
    firstName: "LATE BIDHUBHUSAN",
    lastName: "MUKHERJEE",
    address: "BELEGHATA*CALCUTTA",
    fullName: "LATE BIDHUBHUSAN MUKHERJEE",
    saID: "SPR7218",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "BHATTACHERJEE",
    address: "ALAM BAZAR*DAKHINESWAR*CALCUTTA",
    fullName: "LATE SUDHIR KUMAR BHATTACHERJEE",
    saID: "SPR7219",
  },
  {
    firstName: "LATE KRISHNA CHANDRA",
    lastName: "CHATTERJEE",
    address: "78B,SALIMPUR ROAD*CALCUTTA 31",
    fullName: "LATE KRISHNA CHANDRA CHATTERJEE",
    saID: "SPR7220",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "ROYCHOUDHURY",
    address: "CALCUTTA",
    fullName: "LATE SUDHIR KUMAR ROYCHOUDHURY",
    saID: "SPR7221",
  },
  {
    firstName: "LATE ASIT KUMAR",
    lastName: "BANERJEE",
    address: "NEPAL BHATTACHERJEE IST LANE*CALCUTTA 26",
    fullName: "LATE ASIT KUMAR BANERJEE",
    saID: "SPR7222",
  },
  {
    firstName: "LATE NARESH CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "ASST HEADMASTER*33 MALONGA LANE*CALCUTTA 12",
    fullName: "LATE NARESH CHANDRA CHAKRAVARTY",
    saID: "SPR7223",
  },
  {
    firstName: "LATE JADUNATH",
    lastName: "DEY",
    address: "7/11,KULIAFANGRA 4TH NORTH LANE*CALCUTTA 15",
    fullName: "LATE JADUNATH DEY",
    saID: "SPR7224",
  },
  {
    firstName: "LATE ABANI MOHAN",
    lastName: "CHOUDHURY",
    address: "15/2 SETT BAGAN ROAD*CALCUTTA 30",
    fullName: "LATE ABANI MOHAN CHOUDHURY",
    saID: "SPR7225",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "MUKHERJEE",
    address: "AUDITOR*23 STRAND ROAD*CALCUTTA 1",
    fullName: "LATE PRAFULLA KUMAR MUKHERJEE",
    saID: "SPR7226",
  },
  {
    firstName: "LATE SUNIL",
    lastName: "PALMER",
    address: "(SEN)*CALCUTTA",
    fullName: "LATE SUNIL PALMER",
    saID: "SPR7227",
  },
  {
    firstName: "LATE BANACHARI",
    lastName: "MISHRA",
    address: "ASTROLOGER*MIDNAPORE",
    fullName: "LATE BANACHARI MISHRA",
    saID: "SPR7228",
  },
  {
    firstName: "LATE NARENDRA NARAYAN",
    lastName: "TAPASWI",
    address: "24 PARGANAS",
    fullName: "LATE NARENDRA NARAYAN TAPASWI",
    saID: "SPR7229",
  },
  {
    firstName: "LATE TARAPADA",
    lastName: "DEY",
    address: "235/5,SUBHASH NAGAR BYE-LANE*CALCUTTA 28",
    fullName: "LATE TARAPADA DEY",
    saID: "SPR7230",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "MUKHERJEE",
    address: "CALCUTTA",
    fullName: "LATE AJIT KUMAR MUKHERJEE",
    saID: "SPR7231",
  },
  {
    firstName: "LATE RAMGOPAL",
    lastName: "DEY",
    address: "180 BIDHAN SARANI*CALCUTTA 6",
    fullName: "LATE RAMGOPAL DEY",
    saID: "SPR7232",
  },
  {
    firstName: "DEBI PRASAD",
    lastName: "CHAKRAVARTY",
    address: "CALCUTTA",
    fullName: "DEBI PRASAD CHAKRAVARTY",
    saID: "SPR7233",
  },
  {
    firstName: "LATE MANMATHA NATH",
    lastName: "MOHANTY",
    address: "52 GIRISH MUKHERJEE ROAD*CALCUTTA 25",
    fullName: "LATE MANMATHA NATH MOHANTY",
    saID: "SPR7234",
  },
  {
    firstName: "LATE SUBODH CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "39/1/A,MIRZAPUR STREET*CALCUTTA 9",
    fullName: "LATE SUBODH CHANDRA CHAKRAVARTY",
    saID: "SPR7235",
  },
  {
    firstName: "LATE DHIRENDRA KUMAR",
    lastName: "MITRA",
    address: "NABAPALLY,BATANAGAR*24PARGANAS",
    fullName: "LATE DHIRENDRA KUMAR MITRA",
    saID: "SPR7236",
  },
  {
    firstName: "LATE KUNJA BIHARI",
    lastName: "CHAKRAVARTY",
    address: "PO/VILL-SARISA*24PARGANAS",
    fullName: "LATE KUNJA BIHARI CHAKRAVARTY",
    saID: "SPR7237",
  },
  {
    firstName: "LATE ASUTOSH",
    lastName: "BHATTACHERJEE",
    address: "TEACHER*BALIBHARA*PO-MALANCHA*24PARGANAS",
    fullName: "LATE ASUTOSH BHATTACHERJEE",
    saID: "SPR7238",
  },
  {
    firstName: "LATE SANTOSH  KUMAR",
    lastName: "BOSE",
    address: "SUBHASH COLONY EAST*BARRACKPORE*24PARGANAS",
    fullName: "LATE SANTOSH  KUMAR BOSE",
    saID: "SPR7239",
  },
  {
    firstName: "LATE SAILENDRA NATH",
    lastName: "MUKHERJEE",
    address: "GARIFA*24PARGANAS",
    fullName: "LATE SAILENDRA NATH MUKHERJEE",
    saID: "SPR7240",
  },
  {
    firstName: "LATE BHUPENDRA NATH",
    lastName: "BHADURI",
    address: "BONGAON*24PARGANAS",
    fullName: "LATE BHUPENDRA NATH BHADURI",
    saID: "SPR7241",
  },
  {
    firstName: "NITAI CHANDRA",
    lastName: "BARMAN",
    address: "DANTALPUR*KHORDOKOMORPUR*GAIBANDHA*BANGLADESH",
    fullName: "NITAI CHANDRA BARMAN",
    saID: "SPR7242",
  },
  {
    firstName: "LATE SUNIL KUMAR",
    lastName: "MUKHERJEE",
    address: "CANNING*24PARGANAS",
    fullName: "LATE SUNIL KUMAR MUKHERJEE",
    saID: "SPR7243",
  },
  {
    firstName: "PIKLU KUMAR",
    lastName: "KAR",
    address: "CHECHURIA*K.B.BAZAR*CHITTAGANG*BANGLADESH",
    fullName: "PIKLU KUMAR KAR",
    saID: "SPR7244",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "KARAN",
    address: "NALUA*SHYAM MOHURIHAT*DIST-CHITTAGONG*BANGLADESH",
    fullName: "DILIP KUMAR KARAN",
    saID: "SPR7245",
  },
  {
    firstName: "LATE KESHAB CHANDRA",
    lastName: "SAU",
    address: "JAGADDAL BEHARAPARA*24PARGANAS",
    fullName: "LATE KESHAB CHANDRA SAU",
    saID: "SPR7246",
  },
  {
    firstName: "LATE MADHAB KRISHNA",
    lastName: "MITRA",
    address: "BELEGHATA*24PARGANAS",
    fullName: "LATE MADHAB KRISHNA MITRA",
    saID: "SPR7247",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "KHAN",
    address: "(PARASAVSHARMA)*24PARGANAS",
    fullName: "LATE SATISH CHANDRA KHAN",
    saID: "SPR7248",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "CHOUDHURY",
    address: "PO-CHALNA*KHULNA*BANGLADESH",
    fullName: "LATE HARIPADA CHOUDHURY",
    saID: "SPR7249",
  },
  {
    firstName: "GAUTAM",
    lastName: "GHOSE",
    address: "OLDSATKHIRA*GHOSEPARA*SATKHIRA*BANGLADESH",
    fullName: "GAUTAM GHOSE",
    saID: "SPR7250",
  },
  {
    firstName: "LATE JITENDRA NATH",
    lastName: "RAY",
    address: "KHASPUR*TENTULIA*24PARGANAS",
    fullName: "LATE JITENDRA NATH RAY",
    saID: "SPR7251",
  },
  {
    firstName: "LATE KASISWAR",
    lastName: "ROYCHOUDHURY",
    address: "144/33A,DHARAMTALA NEW COLONY*PO-SALKIA*HOWRAH",
    fullName: "LATE KASISWAR ROYCHOUDHURY",
    saID: "SPR7252",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "SENSHARMA",
    address: "78/1/32,NANDALAL MUKHERJEE LANE*SANTRAGACHI*HOWRAH",
    fullName: "LATE UPENDRA NATH SENSHARMA",
    saID: "SPR7253",
  },
  {
    firstName: "LATE DHARANIDHAR",
    lastName: "RAY",
    address: "BALI DURGAPUR*UTTARPARA*HOWRAH",
    fullName: "LATE DHARANIDHAR RAY",
    saID: "SPR7254",
  },
  {
    firstName: "LATE DHURJATI PRASAD",
    lastName: "CHAKRAVARTY",
    address: "SHITALPUR*SHYAMPUR*HOWRAH",
    fullName: "LATE DHURJATI PRASAD CHAKRAVARTY",
    saID: "SPR7255",
  },
  {
    firstName: "LATE PURNA CHANDRA",
    lastName: "ADHIKARY",
    address: "NADIA",
    fullName: "LATE PURNA CHANDRA ADHIKARY",
    saID: "SPR7256",
  },
  {
    firstName: "LATE SATISH CHANDRA",
    lastName: "ROYKARMAKAR",
    address: "ASWINI DUTTA NAGAR COLONY*KOTRANG*BHADRAKALI*HOOGHLY",
    fullName: "LATE SATISH CHANDRA ROYKARMAKAR",
    saID: "SPR7257",
  },
  {
    firstName: "LATE BISHNU CHARAN",
    lastName: "GHOSH",
    address: "GHOSHPARA*KOTRANG*BHADRAKALI*HOOGHLY",
    fullName: "LATE BISHNU CHARAN GHOSH",
    saID: "SPR7258",
  },
  {
    firstName: "LATE JAGADINDU NATH",
    lastName: "DUTTA",
    address: "CRIMINAL BAR LIBRARY*KRISHNANAGAR*NADIA",
    fullName: "LATE JAGADINDU NATH DUTTA",
    saID: "SPR7259",
  },
  {
    firstName: "LATE SAKTIPADA",
    lastName: "BHATTACHERJEE",
    address: "ELECTRIC PARTS SHOP*RANAGHAT*NADIA",
    fullName: "LATE SAKTIPADA BHATTACHERJEE",
    saID: "SPR7260",
  },
  {
    firstName: "LATE SRINIBASH",
    lastName: "SAHA",
    address: "TEACHER HINDI SCHOOL*RANAGHAT*NADIA",
    fullName: "LATE SRINIBASH SAHA",
    saID: "SPR7261",
  },
  {
    firstName: "LATE BIBHUTI BHUSAN",
    lastName: "TEWARY",
    address: "PO/VILL-BISHNUPUR*NADIA",
    fullName: "LATE BIBHUTI BHUSAN TEWARY",
    saID: "SPR7262",
  },
  {
    firstName: "LATE KSHIROD CHANDRA",
    lastName: "SARMA",
    address: "SAVITRY OUSADHALAYA*CHAKDAH*NADIA",
    fullName: "LATE KSHIROD CHANDRA SARMA",
    saID: "SPR7263",
  },
  {
    firstName: "LATE PRABIR KUMAR",
    lastName: "BHATTACHERJEE",
    address: "COOPER'S CAMP*RANAGHAT*NADIA",
    fullName: "LATE PRABIR KUMAR BHATTACHERJEE",
    saID: "SPR7264",
  },
  {
    firstName: "LATE NARENDRA NATH",
    lastName: "RAYPARASAVSARMA",
    address: "TARAKNAGAR*PO-GAJNA*NADIA",
    fullName: "LATE NARENDRA NATH RAYPARASAVSARMA",
    saID: "SPR7265",
  },
  {
    firstName: "LATE BARINDRA KUMAR",
    lastName: "BISWAS",
    address: "RANAGHAT*NADIA",
    fullName: "LATE BARINDRA KUMAR BISWAS",
    saID: "SPR7266",
  },
  {
    firstName: "LATE ROHIT KUMAR",
    lastName: "MAJUMDER",
    address: "NADIA",
    fullName: "LATE ROHIT KUMAR MAJUMDER",
    saID: "SPR7267",
  },
  {
    firstName: "LATE ROY BHABANATH",
    lastName: "SARKAR",
    address: "NADIA",
    fullName: "LATE ROY BHABANATH SARKAR",
    saID: "SPR7268",
  },
  {
    firstName: "PRAFULLA CHANDRA",
    lastName: "DAS",
    address: "NADIA",
    fullName: "PRAFULLA CHANDRA DAS",
    saID: "SPR7269",
  },
  {
    firstName: "PULIN BIHARI",
    lastName: "BISWAS",
    address: "F-BLOCK ROOM-12*COOPER'S CAMP*NADIA",
    fullName: "PULIN BIHARI BISWAS",
    saID: "SPR7270",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "GOPESARMA",
    address: "(GHOSH)*C/O SHIBAPADA GHOSH*DALIMANLA*RUPDAHA*NADIA",
    fullName: "LATE SUDHIR KUMAR GOPESARMA",
    saID: "SPR7271",
  },
  {
    firstName: "LATE CHITTARANJAN",
    lastName: "ROYCHOUDHURY",
    address: "TARA KUTIR*PO-NASRA*RANAGHAT*NADIA",
    fullName: "LATE CHITTARANJAN ROYCHOUDHURY",
    saID: "SPR7272",
  },
  {
    firstName: "LATE RAJENDRA NARAYAN",
    lastName: "CHAKRAVARTY",
    address: "BURDWAN",
    fullName: "LATE RAJENDRA NARAYAN CHAKRAVARTY",
    saID: "SPR7273",
  },
  {
    firstName: "LATE GANGADHAR",
    lastName: "MUKHERJEE",
    address: "GOPALDASPUR*PO-BAIDYAPUR*BURDWAN",
    fullName: "LATE GANGADHAR MUKHERJEE",
    saID: "SPR7274",
  },
  {
    firstName: "LATE SWAROJ KUMAR",
    lastName: "RAY",
    address: "PO/VILL-AGRADWIP*BURDWAN",
    fullName: "LATE SWAROJ KUMAR RAY",
    saID: "SPR7275",
  },
  {
    firstName: "SUDHIR KUMAR",
    lastName: "NANDY",
    address: "BURDWAN",
    fullName: "SUDHIR KUMAR NANDY",
    saID: "SPR7276",
  },
  {
    firstName: "LATE LALITKESH",
    lastName: "TRIPATHY",
    address: "PURUSOTTAMPUR*PO-NANDIGRAM*MIDNAPORE",
    fullName: "LATE LALITKESH TRIPATHY",
    saID: "SPR7277",
  },
  {
    firstName: "LATE UMA CHARAN",
    lastName: "BANERJEE",
    address: "PO/VILL-DIHIMARIRHAT*MIDNAPORE",
    fullName: "LATE UMA CHARAN BANERJEE",
    saID: "SPR7278",
  },
  {
    firstName: "LATE JAGADISH CHANDRA",
    lastName: "PANDA",
    address: "PO/VILL-MAHISHADAL*MIDNAPORE",
    fullName: "LATE JAGADISH CHANDRA PANDA",
    saID: "SPR7279",
  },
  {
    firstName: "LATE SRIKANTA",
    lastName: "UTTHASANIK",
    address: "PO/VILL-MAHISHADAL*MIDNAPORE",
    fullName: "LATE SRIKANTA UTTHASANIK",
    saID: "SPR7280",
  },
  {
    firstName: "LATE BHUTNATH",
    lastName: "ACHERJEE",
    address: "SATSANG KENDRA*MOHANPUR*MIDNAPORE",
    fullName: "LATE BHUTNATH ACHERJEE",
    saID: "SPR7281",
  },
  {
    firstName: "LATE BROJENDRA LAL",
    lastName: "CHAKRAVARTY",
    address: "MIDNAPORE",
    fullName: "LATE BROJENDRA LAL CHAKRAVARTY",
    saID: "SPR7282",
  },
  {
    firstName: "LATE SANTOSH KUMAR",
    lastName: "KAR",
    address: "JIT SAGAR*PO-RAGHUNATHBARI*MIDNAPORE",
    fullName: "LATE SANTOSH KUMAR KAR",
    saID: "SPR7283",
  },
  {
    firstName: "SADANANDA",
    lastName: "BISWAS",
    address: "SATSANG COLONY,SURI*BIRBHUM",
    fullName: "SADANANDA BISWAS",
    saID: "SPR7284",
  },
  {
    firstName: "LATE GURUDAS",
    lastName: "RAY",
    address: "BIRBHUM",
    fullName: "LATE GURUDAS RAY",
    saID: "SPR7285",
  },
  {
    firstName: "LATE BIDYANANDA",
    lastName: "MISHRA",
    address: "ANTI MALARIA OFFICE*PURULIA",
    fullName: "LATE BIDYANANDA MISHRA",
    saID: "SPR7286",
  },
  {
    firstName: "LATE JAGAT KISHOR",
    lastName: "PAL",
    address: "BAIRATIGURI*JALPAIGURI",
    fullName: "LATE JAGAT KISHOR PAL",
    saID: "SPR7287",
  },
  {
    firstName: "LATE LAKSHMI NARAYAN",
    lastName: "MAJUMDER",
    address: "DARJEELING",
    fullName: "LATE LAKSHMI NARAYAN MAJUMDER",
    saID: "SPR7288",
  },
  {
    firstName: "CHANDRA KANTA",
    lastName: "BHATTACHERJEE",
    address: "PO/VILL-JAMALDAH*COOCH BEHAR",
    fullName: "CHANDRA KANTA BHATTACHERJEE",
    saID: "SPR7289",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "CHATTERJEE",
    address: "COOCH BEHAR",
    fullName: "PRAFULLA KUMAR CHATTERJEE",
    saID: "SPR7290",
  },
  {
    firstName: "LATE DWIJENDRA LAL",
    lastName: "BARMA",
    address: "PARMEKHLIGANJ*COOCH BEHAR",
    fullName: "LATE DWIJENDRA LAL BARMA",
    saID: "SPR7291",
  },
  {
    firstName: "LATE JITENDRA CHANDRA",
    lastName: "BHATTACHERJEE",
    address: "SINGHATALA*MALDA",
    fullName: "LATE JITENDRA CHANDRA BHATTACHERJEE",
    saID: "SPR7292",
  },
  {
    firstName: "LATE KALIRATAN",
    lastName: "DEBKRORI",
    address: "KAMRUP",
    fullName: "LATE KALIRATAN DEBKRORI",
    saID: "SPR7293",
  },
  {
    firstName: "BHUBANESWAR",
    lastName: "DEBCHOUDHURY",
    address: "PO-PATHSALA*KAMRUP",
    fullName: "BHUBANESWAR DEBCHOUDHURY",
    saID: "SPR7294",
  },
  {
    firstName: "MUNINDRA NATH",
    lastName: "SARMA",
    address: "PATHSALA*KAMRUP",
    fullName: "MUNINDRA NATH SARMA",
    saID: "SPR7295",
  },
  {
    firstName: "LATE DEB NARAYAN",
    lastName: "UPADHYAY",
    address: "(SUBEDI)*DARRANG",
    fullName: "LATE DEB NARAYAN UPADHYAY",
    saID: "SPR7296",
  },
  {
    firstName: "BHAKTA BAHADUR",
    lastName: "PRADHAN",
    address: "STATION ROAD*PO-DIGBOI*LAKHIMPUR",
    fullName: "BHAKTA BAHADUR PRADHAN",
    saID: "SPR7297",
  },
  {
    firstName: "LATE SAMAR",
    lastName: "DEB",
    address: "REFINERY A.C. SETTLEMENT AREA*LAKHIMPUR",
    fullName: "LATE SAMAR DEB",
    saID: "SPR7298",
  },
  {
    firstName: "LATE NITYANANDA",
    lastName: "SHARMABARDOLOI",
    address: "RANGAJAN*PO-DOOMDOOMA*LAKHIMPUR",
    fullName: "LATE NITYANANDA SHARMABARDOLOI",
    saID: "SPR7299",
  },
  {
    firstName: "LATE SAMARENDRA",
    lastName: "CHOUDHURY",
    address: "CACHAR",
    fullName: "LATE SAMARENDRA CHOUDHURY",
    saID: "SPR7300",
  },
  {
    firstName: "LATE AIMAN",
    lastName: "THAPA",
    address: "HOSPITAL ROAD*PO-SILCHAR*CACHAR",
    fullName: "LATE AIMAN THAPA",
    saID: "SPR7301",
  },
  {
    firstName: "LATE KUMUD RANJAN",
    lastName: "DEB",
    address: "MALUGRAM*PO-SILCHAR*CACHAR",
    fullName: "LATE KUMUD RANJAN DEB",
    saID: "SPR7302",
  },
  {
    firstName: "LATE KEDAR NATH",
    lastName: "DEB",
    address: "KUMBHIR*CACHAR",
    fullName: "LATE KEDAR NATH DEB",
    saID: "SPR7303",
  },
  {
    firstName: "GOPESWAR",
    lastName: "SARMA",
    address: "CACHAR",
    fullName: "GOPESWAR SARMA",
    saID: "SPR7304",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "DEB",
    address: "CACHAR",
    fullName: "PARESH CHANDRA DEB",
    saID: "SPR7305",
  },
  {
    firstName: "LATE SANTI RANJAN",
    lastName: "CHAKRAVARTY",
    address: "CACHAR",
    fullName: "LATE SANTI RANJAN CHAKRAVARTY",
    saID: "SPR7306",
  },
  {
    firstName: "ARKADYUTRI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYUTRI CHAKRABORTY",
    saID: "SPR7307",
  },
  {
    firstName: "RAM SANKAR",
    lastName: "SINGH",
    address: "LABOUR LEADER*PO-SINDHRI*DHANBAD",
    fullName: "RAM SANKAR SINGH",
    saID: "SPR7308",
  },
  {
    firstName: "LATE KALACHAND",
    lastName: "SEN",
    address: "DHANBAD",
    fullName: "LATE KALACHAND SEN",
    saID: "SPR7309",
  },
  {
    firstName: "LATE MANIK CHANDRA",
    lastName: "CHOUDHURY",
    address: "DHANBAD",
    fullName: "LATE MANIK CHANDRA CHOUDHURY",
    saID: "SPR7310",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "CHAKRAVARTY",
    address: "KUSUNDA KUSTORE COLLIERY*DHANBAD",
    fullName: "LATE NIRMAL CHANDRA CHAKRAVARTY",
    saID: "SPR7311",
  },
  {
    firstName: "LATE AMULYA BHUSAN",
    lastName: "CHATTERJEE",
    address: "SAKCHI*67 DWARIKA ROAD*JAMSHEDPUR 1*SINGHBHUM",
    fullName: "LATE AMULYA BHUSAN CHATTERJEE",
    saID: "SPR7312",
  },
  {
    firstName: "LATE SACHINDRA BHUSAN",
    lastName: "CHAKRAVARTY",
    address: "SADHANA OUSADHALAY*JAMSHEDPUR 1*SINGHBHUM",
    fullName: "LATE SACHINDRA BHUSAN CHAKRAVARTY",
    saID: "SPR7313",
  },
  {
    firstName: "LATE BHABATARAN",
    lastName: "DAS",
    address: "JAMSHEDPUR*SINGHBHUM",
    fullName: "LATE BHABATARAN DAS",
    saID: "SPR7314",
  },
  {
    firstName: "LATE KAMESWAR PRASAD",
    lastName: "SINGH",
    address: "ADVOCATE*SAMASTIPUR",
    fullName: "LATE KAMESWAR PRASAD SINGH",
    saID: "SPR7315",
  },
  {
    firstName: "SURYA NARAYAN",
    lastName: "SARMA",
    address: "PO/VILL-PANCHRUKHI*VIA-YAINI*DARBHANGA",
    fullName: "SURYA NARAYAN SARMA",
    saID: "SPR7316",
  },
  {
    firstName: "BISHNU",
    lastName: "SARMACHATURVEDI",
    address: "VILL-MALIKAUR*PO-DIGHRA*DARBHANGA",
    fullName: "BISHNU SARMACHATURVEDI",
    saID: "SPR7317",
  },
  {
    firstName: "LATE BAIDYANATH",
    lastName: "SARMA",
    address: "ADVOCATE*MUZAFFARPUR",
    fullName: "LATE BAIDYANATH SARMA",
    saID: "SPR7318",
  },
  {
    firstName: "LATE DEBNANDAN",
    lastName: "CHOUDHURY",
    address: "AT/PO-KHARANA*MUZAFFARPUR",
    fullName: "LATE DEBNANDAN CHOUDHURY",
    saID: "SPR7319",
  },
  {
    firstName: "NANDADULAL",
    lastName: "BOSE",
    address: "S.E.RLY BUNGLOW*PO-BHAGA*DHANBAD",
    fullName: "NANDADULAL BOSE",
    saID: "SPR7320",
  },
  {
    firstName: "LATE INDIRA KANTA",
    lastName: "SARMA",
    address: "NABIGANJ*CHAPRA",
    fullName: "LATE INDIRA KANTA SARMA",
    saID: "SPR7321",
  },
  {
    firstName: "LATE BINOD BIHARI",
    lastName: "MITRA",
    address: "MAHTMA GANDHI ROAD*JHARIA*DHANBAD",
    fullName: "LATE BINOD BIHARI MITRA",
    saID: "SPR7322",
  },
  {
    firstName: "LATE HARA PRASANNA",
    lastName: "DAS",
    address: "SATSANG",
    fullName: "LATE HARA PRASANNA DAS",
    saID: "SPR7323",
  },
  {
    firstName: "LATE DAYAMAY",
    lastName: "BOSE",
    address: "SATSANG",
    fullName: "LATE DAYAMAY BOSE",
    saID: "SPR7324",
  },
  {
    firstName: "LATE SUDHANGSHU SEKHAR",
    lastName: "SARANGI",
    address: "SATSANG",
    fullName: "LATE SUDHANGSHU SEKHAR SARANGI",
    saID: "SPR7325",
  },
  {
    firstName: "LATE MOHIT KUMAR",
    lastName: "MUKHERJEE",
    address: "KANPUR*U.P.",
    fullName: "LATE MOHIT KUMAR MUKHERJEE",
    saID: "SPR7326",
  },
  {
    firstName: "LATE JOGENDRA MOHAN",
    lastName: "BANERJEE",
    address: "VARANASI",
    fullName: "LATE JOGENDRA MOHAN BANERJEE",
    saID: "SPR7327",
  },
  {
    firstName: "LATE NABAKISHALAY",
    lastName: "MUKHERJEE",
    address: "SATSANG",
    fullName: "LATE NABAKISHALAY MUKHERJEE",
    saID: "SPR7328",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "SASTRI",
    address: "SATSANG",
    fullName: "LATE HEM CHANDRA SASTRI",
    saID: "SPR7329",
  },
  {
    firstName: "LATE HEM CHANDRA",
    lastName: "MAJUMDER",
    address: "BURDWAN",
    fullName: "LATE HEM CHANDRA MAJUMDER",
    saID: "SPR7330",
  },
  {
    firstName: "LATE SUBODH",
    lastName: "BHATTACHERJEE",
    address: "BURDWAN",
    fullName: "LATE SUBODH BHATTACHERJEE",
    saID: "SPR7331",
  },
  {
    firstName: "LATE RAMANI MOHAN",
    lastName: "MRIDHA",
    address: "24PARGANAS",
    fullName: "LATE RAMANI MOHAN MRIDHA",
    saID: "SPR7332",
  },
  {
    firstName: "LATE SUBIR KUMAR",
    lastName: "BISWAS",
    address: "24PARGANAS",
    fullName: "LATE SUBIR KUMAR BISWAS",
    saID: "SPR7333",
  },
  {
    firstName: "LATE PANDIT JAGANNATH",
    lastName: "MISHRA",
    address: "MAYNMAR",
    fullName: "LATE PANDIT JAGANNATH MISHRA",
    saID: "SPR7334",
  },
  {
    firstName: "LATE MADHABI KANCHAN",
    lastName: "SINGHACHOUDHURY",
    address: "U.P.",
    fullName: "LATE MADHABI KANCHAN SINGHACHOUDHURY",
    saID: "SPR7335",
  },
  {
    firstName: "LATE HARENDRA",
    lastName: "CHAKRAVARTY",
    address: "ADVOCATE*MYANMAR",
    fullName: "LATE HARENDRA CHAKRAVARTY",
    saID: "SPR7336",
  },
  {
    firstName: "LATE HARENDRA KUMAR",
    lastName: "DASCHOUDHURY",
    address: "MYANMAR",
    fullName: "LATE HARENDRA KUMAR DASCHOUDHURY",
    saID: "SPR7337",
  },
  {
    firstName: "LATE RAMESH CHANDRA",
    lastName: "GHOSH",
    address: "FARIDPUR*BANGLADESH",
    fullName: "LATE RAMESH CHANDRA GHOSH",
    saID: "SPR7338",
  },
  {
    firstName: "LATE LUTFAR",
    lastName: "RAHMAN",
    address: "JESSORE*BANGLADESH",
    fullName: "LATE LUTFAR RAHMAN",
    saID: "SPR7339",
  },
  {
    firstName: "LATE PRIYANATH",
    lastName: "BOSE",
    address: "FARIDPUR*BANGLADESH",
    fullName: "LATE PRIYANATH BOSE",
    saID: "SPR7340",
  },
  {
    firstName: "LATE MAHENDRA LAL",
    lastName: "BHATTACHERJEE",
    address: "CHITTAGONG*BANGLADESH",
    fullName: "LATE MAHENDRA LAL BHATTACHERJEE",
    saID: "SPR7341",
  },
  {
    firstName: "LATE AJIT KUMAR",
    lastName: "DUTTA",
    address: "DINAJPUR*BANGLADESH",
    fullName: "LATE AJIT KUMAR DUTTA",
    saID: "SPR7342",
  },
  {
    firstName: "LATE MOULAVI KAZI ABDUL",
    lastName: "HYE",
    address: "DHAKA*BANGLADESH",
    fullName: "LATE MOULAVI KAZI ABDUL HYE",
    saID: "SPR7343",
  },
  {
    firstName: "LATE RANJIT",
    lastName: "CHATTERJEE",
    address: "KAYLAGHAT*KHULNA*BANGLADESH",
    fullName: "LATE RANJIT CHATTERJEE",
    saID: "SPR7344",
  },
  {
    firstName: "LATE SUDHIR KUMAR",
    lastName: "INDRA",
    address: "KALIBARI ROAD*KHULNA*BANGLADESH",
    fullName: "LATE SUDHIR KUMAR INDRA",
    saID: "SPR7345",
  },
  {
    firstName: "LATE PURNA CHANDRA",
    lastName: "PRAMANIK",
    address: "KUSTHIA*BANGLADESH",
    fullName: "LATE PURNA CHANDRA PRAMANIK",
    saID: "SPR7346",
  },
  {
    firstName: "LATE PARESH NATH",
    lastName: "GHOSH",
    address: "AMANATGANJ*BARISAL*BANGLADESH",
    fullName: "LATE PARESH NATH GHOSH",
    saID: "SPR7347",
  },
  {
    firstName: "LATE RAMRANJAN",
    lastName: "CHAKRAVARTY",
    address: "PO/VILL-SHYAMPUR*BARISAL*BANGLADESH",
    fullName: "LATE RAMRANJAN CHAKRAVARTY",
    saID: "SPR7348",
  },
  {
    firstName: "LATE BINOD BIHARI",
    lastName: "MAJUMDER",
    address: "GOLOKPURA SATSANG KENDRA*PO-KHASERHAT*BARISAL*BANGLADESH",
    fullName: "LATE BINOD BIHARI MAJUMDER",
    saID: "SPR7349",
  },
  {
    firstName: "LATE NIRAPADA",
    lastName: "CHAKRAVARTY",
    address: "PO-MEGHCHAMI*FARIDPUR",
    fullName: "LATE NIRAPADA CHAKRAVARTY",
    saID: "SPR7350",
  },
  {
    firstName: "LATE PRAFULLA KUMAR",
    lastName: "RAY",
    address: "JESSORE*BANGLADESH",
    fullName: "LATE PRAFULLA KUMAR RAY",
    saID: "SPR7351",
  },
  {
    firstName: "LATE MANINDRA NATH",
    lastName: "CHAKRAVARTY",
    address: "PO-KALIA*JESSORE*BANGLADESH",
    fullName: "LATE MANINDRA NATH CHAKRAVARTY",
    saID: "SPR7352",
  },
  {
    firstName: "LATE FAKIR KRISHNA",
    lastName: "CHAKRAVARTY",
    address: "KADIRKOLE*GAMMA*JESSORE*BANGLADESH",
    fullName: "LATE FAKIR KRISHNA CHAKRAVARTY",
    saID: "SPR7353",
  },
  {
    firstName: "LATE NIRMAL CHANDRA",
    lastName: "RAY",
    address: "GODKHALI*JESSORE*BANGLADESH",
    fullName: "LATE NIRMAL CHANDRA RAY",
    saID: "SPR7354",
  },
  {
    firstName: "LATE KAMINI MOHAN",
    lastName: "DUTTA",
    address: "PO/VILL-LAKSHIPUR*NOAKHALI*BANGLADESH",
    fullName: "LATE KAMINI MOHAN DUTTA",
    saID: "SPR7355",
  },
  {
    firstName: "LATE JAMINI BANDHU",
    lastName: "CHAKRAVARTY",
    address: "AMODPUR*PO-NARENDRAPUR*DHAKA*BANGLADESH",
    fullName: "LATE JAMINI BANDHU CHAKRAVARTY",
    saID: "SPR7356",
  },
  {
    firstName: "LATE JAY KUMAR",
    lastName: "DAS",
    address: "GHOSHGAON*PO-BAROCHAPA*DHAKA*BANGLADESH",
    fullName: "LATE JAY KUMAR DAS",
    saID: "SPR7357",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "GUHA",
    address: "(PROFESSOR-RANCHI)*MUNSHIGANJ*DHAKA*BANGLADESH",
    fullName: "LATE HARIPADA GUHA",
    saID: "SPR7358",
  },
  {
    firstName: "LATE DWIJENDRA LAL",
    lastName: "BARMAN",
    address: "RONGPUR*BANGLADESH",
    fullName: "LATE DWIJENDRA LAL BARMAN",
    saID: "SPR7359",
  },
  {
    firstName: "LATE BEDEHI RANJAN",
    lastName: "KAR",
    address: "KARERGRAM*PO-KULAURA*SYLHET*BANGLADESH",
    fullName: "LATE BEDEHI RANJAN KAR",
    saID: "SPR7360",
  },
  {
    firstName: "LATE UPENDRA NATH",
    lastName: "RAY",
    address: "PO/VILL-BAMAI*SYLHET*BANGLADESH",
    fullName: "LATE UPENDRA NATH RAY",
    saID: "SPR7361",
  },
  {
    firstName: "LATE KAILASH CHANDRA",
    lastName: "MALAKAR",
    address: "HARIPUR*SYLHET*BANGLADESH",
    fullName: "LATE KAILASH CHANDRA MALAKAR",
    saID: "SPR7362",
  },
  {
    firstName: "LATE KALYAN KUMAR",
    lastName: "BISWAS",
    address: "PO/VILL-PAIL*SYLHET*BANGLADESH",
    fullName: "LATE KALYAN KUMAR BISWAS",
    saID: "SPR7363",
  },
  {
    firstName: "LATE NARENDRA CHANDRA",
    lastName: "DEBSARMA",
    address: "CHANDPUR*PO-MUNSHIBAZAR*SYLHET*BANGLADESH",
    fullName: "LATE NARENDRA CHANDRA DEBSARMA",
    saID: "SPR7364",
  },
  {
    firstName: "BASANTA KUMAR",
    lastName: "RAY",
    address: "SYLHET*BANGLADESH",
    fullName: "BASANTA KUMAR RAY",
    saID: "SPR7365",
  },
  {
    firstName: "LATE JAGAT BANDHU",
    lastName: "DUTTA",
    address: "MANTRIBARI ROAD*PO-AGARTALA*TRIPURA",
    fullName: "LATE JAGAT BANDHU DUTTA",
    saID: "SPR7366",
  },
  {
    firstName: "GIRINDRA KUMAR",
    lastName: "DAS",
    address: "MAJLISHPUR*DIRAICHANDPUR*SUNAMGANJ*BANGLADESH",
    fullName: "GIRINDRA KUMAR DAS",
    saID: "SPR7367",
  },
  {
    firstName: "LATE HARIPADA",
    lastName: "GOSWAMI",
    address: "NAGAON",
    fullName: "LATE HARIPADA GOSWAMI",
    saID: "SPR7368",
  },
  {
    firstName: "THAKUR PRASAD",
    lastName: "BISWAS",
    address: "SATSANG*DEOGHAR",
    fullName: "THAKUR PRASAD BISWAS",
    saID: "SPR7369",
  },
  {
    firstName: "THAKUR PRASAD",
    lastName: "BISWAS",
    address: "",
    fullName: "THAKUR PRASAD BISWAS",
    saID: "SPR7370",
  },
  {
    firstName: "SOURENDRA PRASAD",
    lastName: "SINGHA",
    address: "",
    fullName: "SOURENDRA PRASAD SINGHA",
    saID: "SPR7371",
  },
  {
    firstName: "REV ASOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASOK CHAKRABORTY",
    saID: "SPR7372",
  },
  {
    firstName: "PUJANIYA SOUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA SOUTI CHAKRABORTY",
    saID: "SPR7373",
  },
  {
    firstName: "PUJANIYA RAMGABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RAMGABOUDI ",
    saID: "SPR7374",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR7375",
  },
  {
    firstName: "PUJANIYA RANGA BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGA BOUDI ",
    saID: "SPR7376",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7377",
  },
  {
    firstName: "KARUNA NANDA",
    lastName: "DEKA",
    address: "DURGASAROBAR*PO-BHARALUKMUKH*GUWAHATI-781 009*KAMRUP*ASSAM",
    fullName: "KARUNA NANDA DEKA",
    saID: "SPR7378",
  },
  {
    firstName: "PUJANIA RANGA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGA MA ",
    saID: "SPR7379",
  },
  {
    firstName: "SUKUMAR",
    lastName: "BACHHAR",
    address: "VILL-MADHYAKATIA(MATHPARA)*PO/DIST-SATKHIRA*BANGLADESH",
    fullName: "SUKUMAR BACHHAR",
    saID: "SPR7380",
  },
  {
    firstName: "RADHAKANTA",
    lastName: "BISWAS",
    address: "VILL-MAHISABHANGA*PO-GUNAKARKATI*SATKHIRA*BANGLADESH",
    fullName: "RADHAKANTA BISWAS",
    saID: "SPR7381",
  },
  {
    firstName: "NIBASH CHANDRA",
    lastName: "KARMAKAR",
    address: "VILL-SHIKARPUR PALPARA*FEROZPUR*BANGLADESH",
    fullName: "NIBASH CHANDRA KARMAKAR",
    saID: "SPR7382",
  },
  {
    firstName: "PANCHAM",
    lastName: "GURU",
    address: "",
    fullName: "PANCHAM GURU",
    saID: "SPR7383",
  },
  {
    firstName: "ARP",
    lastName: "GANGULY",
    address: "",
    fullName: "ARP GANGULY",
    saID: "SPR7384",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7385",
  },
  {
    firstName: "BIRUPAKSHYA",
    lastName: "ROY",
    address: "",
    fullName: "BIRUPAKSHYA ROY",
    saID: "SPR7386",
  },
  {
    firstName: "BIRUPAKSHYA",
    lastName: "ROY",
    address: "",
    fullName: "BIRUPAKSHYA ROY",
    saID: "SPR7387",
  },
  {
    firstName: "BIRUOPAKSHYA",
    lastName: "",
    address: "",
    fullName: "BIRUOPAKSHYA ",
    saID: "SPR7388",
  },
  {
    firstName: "RATAN",
    lastName: "SARMA",
    address: "",
    fullName: "RATAN SARMA",
    saID: "SPR7389",
  },
  {
    firstName: "TAPAS",
    lastName: "DEY",
    address: "",
    fullName: "TAPAS DEY",
    saID: "SPR7390",
  },
  {
    firstName: "TAPAS",
    lastName: "DEY",
    address: "",
    fullName: "TAPAS DEY",
    saID: "SPR7391",
  },
  {
    firstName: "PUJANIYA MEJOBOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MEJOBOURANI ",
    saID: "SPR7392",
  },
  {
    firstName: "RATAN",
    lastName: "CHAKRABORTY",
    address: "KISHMATTALA*HOOGHLY",
    fullName: "RATAN CHAKRABORTY",
    saID: "SPR7393",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7394",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7395",
  },
  {
    firstName: "PUJANIYA RANGA BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGA BOUDI ",
    saID: "SPR7396",
  },
  {
    firstName: "SUBHANKAR",
    lastName: "SAHOO",
    address: "C/O-K.C.SAHOO*GOBINDAPUR KUTCHERY*KENDRAPARA-755061",
    fullName: "SUBHANKAR SAHOO",
    saID: "SPR7397",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7398",
  },
  {
    firstName: "BIJAY KUMAR",
    lastName: "SAHOO",
    address:
      "AT-NILAKANTHANAGAR IST LANE*PO-BIJIPUR*BRAHMAPUR*DIST-GANJAM*ODISHA",
    fullName: "BIJAY KUMAR SAHOO",
    saID: "SPR7399",
  },
  {
    firstName: "MYRALIDHAR",
    lastName: "PAUL",
    address: "",
    fullName: "MYRALIDHAR PAUL",
    saID: "SPR7400",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7401",
  },
  {
    firstName: "NAGENDRA NARAYAN",
    lastName: "DEY",
    address: "",
    fullName: "NAGENDRA NARAYAN DEY",
    saID: "SPR7402",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7403",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7404",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7405",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7406",
  },
  {
    firstName: "PUJANIYA BORA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BORA MA ",
    saID: "SPR7407",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7408",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7409",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7410",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7411",
  },
  {
    firstName: "J",
    lastName: "MUDULI",
    address: "",
    fullName: "J MUDULI",
    saID: "SPR7412",
  },
  {
    firstName: "PUJANIA BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABAUDI ",
    saID: "SPR7413",
  },
  {
    firstName: "SUDHANHSU KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "SUDHANHSU KUMAR DAS",
    saID: "SPR7414",
  },
  {
    firstName: "D N",
    lastName: "SENA",
    address: "",
    fullName: "D N SENA",
    saID: "SPR7415",
  },
  {
    firstName: "PUJANIYA ANANDAMOYEE",
    lastName: "DEVI",
    address: "",
    fullName: "PUJANIYA ANANDAMOYEE DEVI",
    saID: "SPR7416",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7417",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7418",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7419",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7420",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR7421",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7422",
  },
  {
    firstName: "AMITANANDA",
    lastName: "HALDAR",
    address: "",
    fullName: "AMITANANDA HALDAR",
    saID: "SPR7423",
  },
  {
    firstName: "ARKDYT",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ARKDYT CHAKRABARTY",
    saID: "SPR7424",
  },
  {
    firstName: "RADHA SHYAM",
    lastName: "MISHRA",
    address: "",
    fullName: "RADHA SHYAM MISHRA",
    saID: "SPR7425",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7426",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7427",
  },
  {
    firstName: "AMITESH",
    lastName: "BAGCHI",
    address: "SATSANG COLONY*SATSANG*DEOGHAR 814 116",
    fullName: "AMITESH BAGCHI",
    saID: "SPR7428",
  },
  {
    firstName: "ASHIMA",
    lastName: "BHATTACHARYA",
    address: "",
    fullName: "ASHIMA BHATTACHARYA",
    saID: "SPR7429",
  },
  {
    firstName: "S S",
    lastName: "MISHRA",
    address: "",
    fullName: "S S MISHRA",
    saID: "SPR7430",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7431",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7432",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7433",
  },
  {
    firstName: "BRUNDABAN",
    lastName: "SARMA",
    address: "",
    fullName: "BRUNDABAN SARMA",
    saID: "SPR7434",
  },
  {
    firstName: "RWEFS",
    lastName: "",
    address: "",
    fullName: "RWEFS ",
    saID: "SPR7435",
  },
  {
    firstName: "SAMIR",
    lastName: "PARBAT",
    address: "",
    fullName: "SAMIR PARBAT",
    saID: "SPR7436",
  },
  {
    firstName: "PUJANIA BARA BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BOUDI ",
    saID: "SPR7437",
  },
  {
    firstName: "APARTHIB",
    lastName: "ROYCHAKRABORTY",
    address: "",
    fullName: "APARTHIB ROYCHAKRABORTY",
    saID: "SPR7438",
  },
  {
    firstName: "REV ARKYADUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKYADUTI CHAKRABORTY",
    saID: "SPR7439",
  },
  {
    firstName: "KIRTI SUNDAR",
    lastName: "DASH",
    address:
      "AT-UTEIPUR*PO-DEHURI ANANDAPUR*VIA-DASARATHPUR*DIST-JAJPUR-755006*ODISHA",
    fullName: "KIRTI SUNDAR DASH",
    saID: "SPR7440",
  },
  {
    firstName: "AMAL KUMAR",
    lastName: "SASMAL",
    address: "",
    fullName: "AMAL KUMAR SASMAL",
    saID: "SPR7441",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7442",
  },
  {
    firstName: "ARKDYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKDYUTI CHAKRABORTY",
    saID: "SPR7443",
  },
  {
    firstName: "SRINIVAS",
    lastName: "TIWARY",
    address: "VILL-BAGHRI*PO-ZAMANIA*GHAZIPUR-232329*U.P.*PAN NO-ALHPT4049C",
    fullName: "SRINIVAS TIWARY",
    saID: "SPR7444",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7445",
  },
  {
    firstName: "PUJANIYA BORO BEUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BORO BEUDI ",
    saID: "SPR7446",
  },
  {
    firstName: "PUJANIYA CHOTOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA CHOTOBAUDI ",
    saID: "SPR7447",
  },
  {
    firstName: "REV DR ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK KUMAR CHAKRABORTY",
    saID: "SPR7448",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7449",
  },
  {
    firstName: "DEB KUMAR",
    lastName: "BARMAN",
    address: "",
    fullName: "DEB KUMAR BARMAN",
    saID: "SPR7450",
  },
  {
    firstName: "SASANKA",
    lastName: "SAMAL",
    address: "",
    fullName: "SASANKA SAMAL",
    saID: "SPR7451",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7452",
  },
  {
    firstName: "PUJANIYA  BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA  BOUDI ",
    saID: "SPR7453",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7454",
  },
  {
    firstName: "SAOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "SAOKE CHAKRABORTY",
    saID: "SPR7455",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7456",
  },
  {
    firstName: "ANWITA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANWITA CHAKRABORTY",
    saID: "SPR7457",
  },
  {
    firstName: "NITYANANDA",
    lastName: "MUKHERJEE",
    address: "17/C KALIBARI LANE*JADAVPUR*KOLKATA-700 032",
    fullName: "NITYANANDA MUKHERJEE",
    saID: "SPR7458",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7459",
  },
  {
    firstName: "GYANRANJAN",
    lastName: "HALDAR",
    address: "",
    fullName: "GYANRANJAN HALDAR",
    saID: "SPR7460",
  },
  {
    firstName: "GAUTAM",
    lastName: "GHOSH",
    address: "AT&PO-LANKA*NAGAON-782446*ASSAM",
    fullName: "GAUTAM GHOSH",
    saID: "SPR7461",
  },
  {
    firstName: "Y   S",
    lastName: "BORSE",
    address: "",
    fullName: "Y   S BORSE",
    saID: "SPR7462",
  },
  {
    firstName: "YASAWANT",
    lastName: "BORSE",
    address: "",
    fullName: "YASAWANT BORSE",
    saID: "SPR7463",
  },
  {
    firstName: "YASAWANT",
    lastName: "BORSE",
    address: "",
    fullName: "YASAWANT BORSE",
    saID: "SPR7464",
  },
  {
    firstName: "YASAWANT",
    lastName: "BORSE",
    address: "",
    fullName: "YASAWANT BORSE",
    saID: "SPR7465",
  },
  {
    firstName: "BAJRADIPAN",
    lastName: "DASGUPTA",
    address: "3A/30MAITRINAGAR*BHILAI*CHHATISGARH",
    fullName: "BAJRADIPAN DASGUPTA",
    saID: "SPR7466",
  },
  {
    firstName: "MANIK",
    lastName: "TANTI",
    address: "AT&PO-HAUTLEY T E*GOLAGHAT 785621",
    fullName: "MANIK TANTI",
    saID: "SPR7467",
  },
  {
    firstName: "RAMKRISHNA",
    lastName: "TRIPATHY",
    address: "VILL&PO-MOHANPUR*DIST-PASCHIM MEDINIPUR-721436*PAN ASYPT8547M",
    fullName: "RAMKRISHNA TRIPATHY",
    saID: "SPR7468",
  },
  {
    firstName: "BITUL",
    lastName: "RAJKHOWA",
    address: "PATORGAON*SIVSAGAR*PAN.NO.ARBPR4287N",
    fullName: "BITUL RAJKHOWA",
    saID: "SPR7469",
  },
  {
    firstName: "RAJENDRA",
    lastName: "PARIDA",
    address: "JUANIA*GOBINDAPUR KUTCHERY*KENDRAPARA-755061*PAN NO.AIMPP4370L",
    fullName: "RAJENDRA PARIDA",
    saID: "SPR7470",
  },
  {
    firstName: "RATANLAL",
    lastName: "BHUYAN",
    address:
      "SATSANG VIHAR BINJHARPUR*AT-GOUDASAHI*PO-MASHARA*JAJPUR-755012*PAN-BAOPB2237B",
    fullName: "RATANLAL BHUYAN",
    saID: "SPR7471",
  },
  {
    firstName: "JAMUNA",
    lastName: "BORO",
    address: "VILL-MAHIAKHAT*PO-KHOIRABARI*UDDALGURI(BTAD)-784522.",
    fullName: "JAMUNA BORO",
    saID: "SPR7472",
  },
  {
    firstName: "AMITRA",
    lastName: "SARKAR",
    address: "",
    fullName: "AMITRA SARKAR",
    saID: "SPR7473",
  },
  {
    firstName: "ARKADYUTICHAKRABORTY",
    lastName: "",
    address: "",
    fullName: "ARKADYUTICHAKRABORTY ",
    saID: "SPR7474",
  },
  {
    firstName: "SRIDHARSUNDAR",
    lastName: "MISHRA",
    address: "",
    fullName: "SRIDHARSUNDAR MISHRA",
    saID: "SPR7475",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVORTY",
    saID: "SPR7476",
  },
  {
    firstName: "ABINENDU",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABINENDU CHAKRABORTY",
    saID: "SPR7477",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7478",
  },
  {
    firstName: "HRISESH RANJAN",
    lastName: "DAS",
    address: "",
    fullName: "HRISESH RANJAN DAS",
    saID: "SPR7479",
  },
  {
    firstName: "RAMNARAYAN",
    lastName: "BHATTACHERJEE",
    address: "16/A-PATALDANGA*KOL-700009",
    fullName: "RAMNARAYAN BHATTACHERJEE",
    saID: "SPR7480",
  },
  {
    firstName: "PUJANIA BARABAURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABAURANI ",
    saID: "SPR7481",
  },
  {
    firstName: "DR ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "DR ALOK KUMAR CHAKRABORTY",
    saID: "SPR7482",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR7483",
  },
  {
    firstName: "ABINENDRA",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "ABINENDRA CHAKRAVORTY",
    saID: "SPR7484",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYUTI CHAKRABORTY",
    saID: "SPR7485",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7486",
  },
  {
    firstName: "D K",
    lastName: "MALLICK",
    address: "",
    fullName: "D K MALLICK",
    saID: "SPR7487",
  },
  {
    firstName: "B M",
    lastName: "DAS",
    address: "",
    fullName: "B M DAS",
    saID: "SPR7488",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR7489",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7490",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7491",
  },
  {
    firstName: "CHAKRADHARI",
    lastName: "NAYAK",
    address:
      "C/O GIRIDHARI NAYAK*SATSANG VIHAR*PO-VANIVIHAR*BHUBANESWAR-751004*KHURDA",
    fullName: "CHAKRADHARI NAYAK",
    saID: "SPR7492",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR7493",
  },
  {
    firstName: "GARIDAS",
    lastName: "SINGH",
    address: "",
    fullName: "GARIDAS SINGH",
    saID: "SPR7494",
  },
  {
    firstName: "REVREV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVREV CHAKRABORTY",
    saID: "SPR7495",
  },
  {
    firstName: "TREV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "TREV CHAKRABORTY",
    saID: "SPR7496",
  },
  {
    firstName: "PUJIANIA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJIANIA BARABOUDI ",
    saID: "SPR7497",
  },
  {
    firstName: "REV RANGAMA",
    lastName: "",
    address: "",
    fullName: "REV RANGAMA ",
    saID: "SPR7498",
  },
  {
    firstName: "PUJANIA BARABOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOUDI ",
    saID: "SPR7499",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7500",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7501",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7502",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7503",
  },
  {
    firstName: "REV ANUPAM",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ANUPAM CHAKRABORTY",
    saID: "SPR7504",
  },
  {
    firstName: "DUTHARI",
    lastName: "KAR",
    address: "",
    fullName: "DUTHARI KAR",
    saID: "SPR7505",
  },
  {
    firstName: "SRINIVAS",
    lastName: "MONDAL",
    address: "",
    fullName: "SRINIVAS MONDAL",
    saID: "SPR7506",
  },
  {
    firstName: "REV ARKADUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKADUTI CHAKRABORTY",
    saID: "SPR7507",
  },
  {
    firstName: "KANU",
    lastName: "DUTTA",
    address: "",
    fullName: "KANU DUTTA",
    saID: "SPR7508",
  },
  {
    firstName: "AMITESH",
    lastName: "BAGCHI",
    address: "",
    fullName: "AMITESH BAGCHI",
    saID: "SPR7509",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7510",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7511",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7512",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRABARTY",
    saID: "SPR7513",
  },
  {
    firstName: "JANAKI RAM",
    lastName: "CHOUHAN",
    address: "",
    fullName: "JANAKI RAM CHOUHAN",
    saID: "SPR7514",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7515",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7516",
  },
  {
    firstName: "AKRADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKRADYUTI CHAKRABORTY",
    saID: "SPR7517",
  },
  {
    firstName: "SUBHRANSU SEKHAR",
    lastName: "PANDA",
    address:
      "2/3,KAMRAJ NAGAR*3RD STREET CHOOLAIMEDU*CHENNAI-600094*PAN-AIDPP7261R",
    fullName: "SUBHRANSU SEKHAR PANDA",
    saID: "SPR7518",
  },
  {
    firstName: "PUJANIAANANDAMAYEEMA",
    lastName: "",
    address: "",
    fullName: "PUJANIAANANDAMAYEEMA ",
    saID: "SPR7519",
  },
  {
    firstName: "ABINENDRA",
    lastName: "",
    address: "",
    fullName: "ABINENDRA ",
    saID: "SPR7520",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7521",
  },
  {
    firstName: "LATE GURU PRASAD",
    lastName: "HALDAR",
    address: '"ISTALAYA"*PURANDAHA*PO/DIST-DEOGHAR',
    fullName: "LATE GURU PRASAD HALDAR",
    saID: "SPR7522",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7523",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7524",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7525",
  },
  {
    firstName: "MAUMITAMA",
    lastName: "",
    address: "",
    fullName: "MAUMITAMA ",
    saID: "SPR7526",
  },
  {
    firstName: "BIRUPRAKASH",
    lastName: "RAY",
    address: "",
    fullName: "BIRUPRAKASH RAY",
    saID: "SPR7527",
  },
  {
    firstName: "KANTI",
    lastName: "KUMARI",
    address: "",
    fullName: "KANTI KUMARI",
    saID: "SPR7528",
  },
  {
    firstName: "PUJANIA ANANDAMAYEE",
    lastName: "",
    address: "",
    fullName: "PUJANIA ANANDAMAYEE ",
    saID: "SPR7529",
  },
  {
    firstName: "REV ANINDADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ANINDADYUTI CHAKRABORTY",
    saID: "SPR7530",
  },
  {
    firstName: "REVANINDADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVANINDADYUTI CHAKRABORTY",
    saID: "SPR7531",
  },
  {
    firstName: "PRIYA KANTA",
    lastName: "DAS",
    address: "",
    fullName: "PRIYA KANTA DAS",
    saID: "SPR7532",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7533",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7534",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7535",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRABARTY",
    saID: "SPR7536",
  },
  {
    firstName: "NIKHIL",
    lastName: "CHATTERJEE",
    address: "SATSANG*DEOGHAR",
    fullName: "NIKHIL CHATTERJEE",
    saID: "SPR7537",
  },
  {
    firstName: "LATE RAMAN CHANDRA",
    lastName: "PAL",
    address: "SYLHET*BANGLADESH",
    fullName: "LATE RAMAN CHANDRA PAL",
    saID: "SPR7538",
  },
  {
    firstName: "SASHIKALA",
    lastName: "DANDAPAT",
    address: "",
    fullName: "SASHIKALA DANDAPAT",
    saID: "SPR7539",
  },
  {
    firstName: "HARIMOHAN",
    lastName: "DEBNATH",
    address: "",
    fullName: "HARIMOHAN DEBNATH",
    saID: "SPR7540",
  },
  {
    firstName: "PUJANIYA  MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA  MA ",
    saID: "SPR7541",
  },
  {
    firstName: "SUBODH",
    lastName: "BISWAS",
    address: "",
    fullName: "SUBODH BISWAS",
    saID: "SPR7542",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7543",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7544",
  },
  {
    firstName: "ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ALOK CHAKRABORTY",
    saID: "SPR7545",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7546",
  },
  {
    firstName: "AKRADUTY",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKRADUTY CHAKRABORTY",
    saID: "SPR7547",
  },
  {
    firstName: "MONAJ",
    lastName: "GOSWAMI",
    address: "",
    fullName: "MONAJ GOSWAMI",
    saID: "SPR7548",
  },
  {
    firstName: "REV",
    lastName: "",
    address: "",
    fullName: "REV ",
    saID: "SPR7549",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "NAYAK",
    address: "",
    fullName: "PURNA CHANDRA NAYAK",
    saID: "SPR7550",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7551",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7552",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7553",
  },
  {
    firstName: "ASOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ASOKE KUMAR CHAKRABORTY",
    saID: "SPR7554",
  },
  {
    firstName: "PUJANIYA  MAA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA  MAA ",
    saID: "SPR7555",
  },
  {
    firstName: "B",
    lastName: "DEBANGSHI",
    address: "",
    fullName: "B DEBANGSHI",
    saID: "SPR7556",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7557",
  },
  {
    firstName: "KAILASH",
    lastName: "DUBEY",
    address: "",
    fullName: "KAILASH DUBEY",
    saID: "SPR7558",
  },
  {
    firstName: "ATUL KUMAR",
    lastName: "GANGULI",
    address: "",
    fullName: "ATUL KUMAR GANGULI",
    saID: "SPR7559",
  },
  {
    firstName: "REV  ALOK",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV  ALOK CHAKRAVORTY",
    saID: "SPR7560",
  },
  {
    firstName: "AJITABHA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AJITABHA CHAKRABORTY",
    saID: "SPR7561",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7562",
  },
  {
    firstName: "REV RANGAMA",
    lastName: "",
    address: "",
    fullName: "REV RANGAMA ",
    saID: "SPR7563",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7564",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRAVARTY",
    saID: "SPR7565",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7566",
  },
  {
    firstName: "REV AMAL KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV AMAL KUMAR CHAKRABORTY",
    saID: "SPR7567",
  },
  {
    firstName: "REV BARA BAUDI",
    lastName: "",
    address: "",
    fullName: "REV BARA BAUDI ",
    saID: "SPR7568",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRAVARTY",
    saID: "SPR7569",
  },
  {
    firstName: "DINANATH",
    lastName: "SARKAR",
    address: "",
    fullName: "DINANATH SARKAR",
    saID: "SPR7570",
  },
  {
    firstName: "KAILASH CHNADRA",
    lastName: "TRIPATHY",
    address: "",
    fullName: "KAILASH CHNADRA TRIPATHY",
    saID: "SPR7571",
  },
  {
    firstName: "MADHAB GOPAL",
    lastName: "NAYAK",
    address: "",
    fullName: "MADHAB GOPAL NAYAK",
    saID: "SPR7572",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7573",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7574",
  },
  {
    firstName: "ARKYA DYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKYA DYUTI CHAKRABORTY",
    saID: "SPR7575",
  },
  {
    firstName: "ABHINENDRA NMATH",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABHINENDRA NMATH CHAKRABORTY",
    saID: "SPR7576",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7577",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7578",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7579",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7580",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7581",
  },
  {
    firstName: "ARIRUDDHA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARIRUDDHA CHAKRABORTY",
    saID: "SPR7582",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7583",
  },
  {
    firstName: "ARDHENDU",
    lastName: "DEYCHOUDHURY",
    address: "VILL-ROUTGRAM*PO-CHATULBAZAR*DIST-SYLHET*BANGLADESH",
    fullName: "ARDHENDU DEYCHOUDHURY",
    saID: "SPR7584",
  },
  {
    firstName: "LATE DEBENDRA CHANDRA",
    lastName: "BANERJEE",
    address: "PHULCHERRA T.E.*PO-KALIGHAT*DIST-MOULAVI BAZAR*BANGLADESH",
    fullName: "LATE DEBENDRA CHANDRA BANERJEE",
    saID: "SPR7585",
  },
  {
    firstName: "SINGDHA",
    lastName: "ROYCHOUDHURY",
    address: "",
    fullName: "SINGDHA ROYCHOUDHURY",
    saID: "SPR7586",
  },
  {
    firstName: "NILKAMAL",
    lastName: "SWARNAKAR",
    address: "",
    fullName: "NILKAMAL SWARNAKAR",
    saID: "SPR7587",
  },
  {
    firstName: "DRICK SUNDAR",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "DRICK SUNDAR CHATTERJEE",
    saID: "SPR7588",
  },
  {
    firstName: "APRNA PADA",
    lastName: "MITRA",
    address: "",
    fullName: "APRNA PADA MITRA",
    saID: "SPR7589",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7590",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7591",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7592",
  },
  {
    firstName: "ARKARDYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKARDYUTI CHAKRABORTY",
    saID: "SPR7593",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7594",
  },
  {
    firstName: "AKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKADYUTI CHAKRABORTY",
    saID: "SPR7595",
  },
  {
    firstName: "MOUMITA MA",
    lastName: "",
    address: "",
    fullName: "MOUMITA MA ",
    saID: "SPR7596",
  },
  {
    firstName: "ANANDAMAYEE MA",
    lastName: "",
    address: "",
    fullName: "ANANDAMAYEE MA ",
    saID: "SPR7597",
  },
  {
    firstName: "ANANDA MAYEE MA",
    lastName: "",
    address: "",
    fullName: "ANANDA MAYEE MA ",
    saID: "SPR7598",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV CHAKRAVARTY",
    saID: "SPR7599",
  },
  {
    firstName: "REVRT",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REVRT CHAKRABORTY",
    saID: "SPR7600",
  },
  {
    firstName: "AMNATAMA",
    lastName: "PATRA",
    address: "",
    fullName: "AMNATAMA PATRA",
    saID: "SPR7601",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7602",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7603",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR7604",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7605",
  },
  {
    firstName: "MEJO BAUDI",
    lastName: "",
    address: "",
    fullName: "MEJO BAUDI ",
    saID: "SPR7606",
  },
  {
    firstName: "BAURANI",
    lastName: "",
    address: "",
    fullName: "BAURANI ",
    saID: "SPR7607",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ASHOK CHAKRABARTY",
    saID: "SPR7608",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7609",
  },
  {
    firstName: "BIRUPAKSHA",
    lastName: "ROY",
    address: "",
    fullName: "BIRUPAKSHA ROY",
    saID: "SPR7610",
  },
  {
    firstName: "KALIKINKAR",
    lastName: "BANERJEE",
    address: "",
    fullName: "KALIKINKAR BANERJEE",
    saID: "SPR7611",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7612",
  },
  {
    firstName: "PUJANIYA BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BOUDI ",
    saID: "SPR7613",
  },
  {
    firstName: "NINDADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "NINDADYUTI CHAKRABORTY",
    saID: "SPR7614",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7615",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7616",
  },
  {
    firstName: "BRAHA GOPAL",
    lastName: "GURU",
    address: "",
    fullName: "BRAHA GOPAL GURU",
    saID: "SPR7617",
  },
  {
    firstName: "PUJANIYAMEJABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIYAMEJABOURANI ",
    saID: "SPR7618",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7619",
  },
  {
    firstName: "REV9",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV9 CHAKRABORTY",
    saID: "SPR7620",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV CHAKRABARTY",
    saID: "SPR7621",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7622",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7623",
  },
  {
    firstName: "HENASINH",
    lastName: "INGLENG",
    address: "",
    fullName: "HENASINH INGLENG",
    saID: "SPR7624",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7625",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7626",
  },
  {
    firstName: "PUJANIYA MOUMITA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MOUMITA ",
    saID: "SPR7627",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7628",
  },
  {
    firstName: "SATYASWARAN",
    lastName: "GAYEN",
    address:
      "AT-SUNDERNAGAR*PO-KHUDIRAMPALLY*VIA-MULCHERA*DIST-GARHCHIROLI*M S 442707",
    fullName: "SATYASWARAN GAYEN",
    saID: "SPR7629",
  },
  {
    firstName: "PYJYAPAD",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PYJYAPAD CHAKRABORTY",
    saID: "SPR7630",
  },
  {
    firstName: "ABINDNDRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABINDNDRA CHAKRABORTY",
    saID: "SPR7631",
  },
  {
    firstName: "AMITESH",
    lastName: "BAGCHI",
    address: "",
    fullName: "AMITESH BAGCHI",
    saID: "SPR7632",
  },
  {
    firstName: "PUJANINA BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANINA BARABAUDI ",
    saID: "SPR7633",
  },
  {
    firstName: "ASHALATA",
    lastName: "ROYCHOUDHURY",
    address: "",
    fullName: "ASHALATA ROYCHOUDHURY",
    saID: "SPR7634",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR7635",
  },
  {
    firstName: "SUMIT",
    lastName: "SARKAR",
    address:
      "KALYAN KUNJA*AMBIKA MUKHERJEE ROAD,PURBACHAL*PO-NATAGARH*24PARGANAS NORTH-700113",
    fullName: "SUMIT SARKAR",
    saID: "SPR7636",
  },
  {
    firstName: "PUJYAPADASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJYAPADASHOK CHAKRABORTY",
    saID: "SPR7637",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7638",
  },
  {
    firstName: "BHUPESH KANTI",
    lastName: "MONDAL",
    address: "",
    fullName: "BHUPESH KANTI MONDAL",
    saID: "SPR7639",
  },
  {
    firstName: "PRASUN",
    lastName: "SAMANTA",
    address: "POST OFFICE GALI*SATSANG-814116",
    fullName: "PRASUN SAMANTA",
    saID: "SPR7640",
  },
  {
    firstName: "DRICK SUNDAR",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "DRICK SUNDAR CHATTERJEE",
    saID: "SPR7641",
  },
  {
    firstName: "DRICK SUNDAR",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "DRICK SUNDAR CHATTERJEE",
    saID: "SPR7642",
  },
  {
    firstName: "NISHI KANTA",
    lastName: "BISWAS",
    address: "",
    fullName: "NISHI KANTA BISWAS",
    saID: "SPR7643",
  },
  {
    firstName: "PULIN BIHARI",
    lastName: "SARKAR",
    address: "",
    fullName: "PULIN BIHARI SARKAR",
    saID: "SPR7644",
  },
  {
    firstName: "FALGUNI",
    lastName: "MAHATO",
    address: "",
    fullName: "FALGUNI MAHATO",
    saID: "SPR7645",
  },
  {
    firstName: "PRAVAT KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "PRAVAT KUMAR DAS",
    saID: "SPR7646",
  },
  {
    firstName: "RAJENDRANATH",
    lastName: "BANERJEE",
    address: "",
    fullName: "RAJENDRANATH BANERJEE",
    saID: "SPR7647",
  },
  {
    firstName: "RAMANI MOHAN",
    lastName: "ROY",
    address: "",
    fullName: "RAMANI MOHAN ROY",
    saID: "SPR7648",
  },
  {
    firstName: "RATAN CHANDRA",
    lastName: "SARKAR",
    address: "",
    fullName: "RATAN CHANDRA SARKAR",
    saID: "SPR7649",
  },
  {
    firstName: "RATAN KRISHNA",
    lastName: "SIL",
    address: "",
    fullName: "RATAN KRISHNA SIL",
    saID: "SPR7650",
  },
  {
    firstName: "RATANKRISNA",
    lastName: "SIL",
    address: "",
    fullName: "RATANKRISNA SIL",
    saID: "SPR7651",
  },
  {
    firstName: "PULIN BIHARI",
    lastName: "SARKAR",
    address: "",
    fullName: "PULIN BIHARI SARKAR",
    saID: "SPR7652",
  },
  {
    firstName: "JYOTIRMAY",
    lastName: "CHOUDHURY",
    address: "",
    fullName: "JYOTIRMAY CHOUDHURY",
    saID: "SPR7653",
  },
  {
    firstName: "RADHESHYAM",
    lastName: "SAMANTA",
    address: "",
    fullName: "RADHESHYAM SAMANTA",
    saID: "SPR7654",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "SARDAR",
    address: "",
    fullName: "SUBHAS CHANDRA SARDAR",
    saID: "SPR7655",
  },
  {
    firstName: "RATNESWAR",
    lastName: "KONWAR",
    address: "",
    fullName: "RATNESWAR KONWAR",
    saID: "SPR7656",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "PARHI",
    address: "",
    fullName: "PURNA CHANDRA PARHI",
    saID: "SPR7657",
  },
  {
    firstName: "BATAKRUSHNA",
    lastName: "JENA",
    address: "",
    fullName: "BATAKRUSHNA JENA",
    saID: "SPR7658",
  },
  {
    firstName: "JUJYAPADA ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "JUJYAPADA ASHOK CHAKRABORTY",
    saID: "SPR7659",
  },
  {
    firstName: "SITAKANTA",
    lastName: "MOHANTY",
    address: "",
    fullName: "SITAKANTA MOHANTY",
    saID: "SPR7660",
  },
  {
    firstName: "BRAJA KISHOR",
    lastName: "MISHRA",
    address: "",
    fullName: "BRAJA KISHOR MISHRA",
    saID: "SPR7661",
  },
  {
    firstName: "BRAJA KISHOR",
    lastName: "MOHANTY",
    address: "",
    fullName: "BRAJA KISHOR MOHANTY",
    saID: "SPR7662",
  },
  {
    firstName: "DHIRENDRA NATH",
    lastName: "BARUAH",
    address: "",
    fullName: "DHIRENDRA NATH BARUAH",
    saID: "SPR7663",
  },
  {
    firstName: "SATYENDRA NATH",
    lastName: "JANA",
    address: "",
    fullName: "SATYENDRA NATH JANA",
    saID: "SPR7664",
  },
  {
    firstName: "SANTANU",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "SANTANU BHATTACHERJEE",
    saID: "SPR7665",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SAHU",
    address: "",
    fullName: "SURENDRA NATH SAHU",
    saID: "SPR7666",
  },
  {
    firstName: "DHRITI SUNDAR",
    lastName: "BISWAS",
    address: "",
    fullName: "DHRITI SUNDAR BISWAS",
    saID: "SPR7667",
  },
  {
    firstName: "PRABHAT CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "PRABHAT CHANDRA DAS",
    saID: "SPR7668",
  },
  {
    firstName: "PRAVAT CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "PRAVAT CHANDRA DAS",
    saID: "SPR7669",
  },
  {
    firstName: "PABITRA",
    lastName: "DAS",
    address: "",
    fullName: "PABITRA DAS",
    saID: "SPR7670",
  },
  {
    firstName: "PABITRA KUMAAR",
    lastName: "DAS",
    address: "",
    fullName: "PABITRA KUMAAR DAS",
    saID: "SPR7671",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "NATH",
    address: "",
    fullName: "PARESH CHANDRA NATH",
    saID: "SPR7672",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "JHA",
    address: "",
    fullName: "PRAKASH CHANDRA JHA",
    saID: "SPR7673",
  },
  {
    firstName: "PRATAP CHANDRAA",
    lastName: "TALUKDAR",
    address: "",
    fullName: "PRATAP CHANDRAA TALUKDAR",
    saID: "SPR7674",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR7675",
  },
  {
    firstName: "PUJANIYA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA RANGAMA ",
    saID: "SPR7676",
  },
  {
    firstName: "1UJYAPAD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "1UJYAPAD ASHOK CHAKRABORTY",
    saID: "SPR7677",
  },
  {
    firstName: "MRIDUL KANTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "MRIDUL KANTI CHAKRABARTY",
    saID: "SPR7678",
  },
  {
    firstName: "JOGENDRA",
    lastName: "KALITA",
    address: "",
    fullName: "JOGENDRA KALITA",
    saID: "SPR7679",
  },
  {
    firstName: "JOGESH",
    lastName: "BAISHYA",
    address: "",
    fullName: "JOGESH BAISHYA",
    saID: "SPR7680",
  },
  {
    firstName: "DIPAK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "DIPAK CHAKRABARTY",
    saID: "SPR7681",
  },
  {
    firstName: "NIMAI",
    lastName: "GHOSH",
    address: "",
    fullName: "NIMAI GHOSH",
    saID: "SPR7682",
  },
  {
    firstName: "PUJYA PADA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJYA PADA CHAKRABORTY",
    saID: "SPR7683",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR7684",
  },
  {
    firstName: "SASANKA KUMAR",
    lastName: "MISHRA",
    address: "",
    fullName: "SASANKA KUMAR MISHRA",
    saID: "SPR7685",
  },
  {
    firstName: "UMAKANTA",
    lastName: "SAHOO",
    address: "",
    fullName: "UMAKANTA SAHOO",
    saID: "SPR7686",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "BARIK",
    address: "",
    fullName: "BHIKARI CHARAN BARIK",
    saID: "SPR7687",
  },
  {
    firstName: "TARUN",
    lastName: "BISWAS",
    address: "",
    fullName: "TARUN BISWAS",
    saID: "SPR7688",
  },
  {
    firstName: "BHOLANATH",
    lastName: "SARMA",
    address: "",
    fullName: "BHOLANATH SARMA",
    saID: "SPR7689",
  },
  {
    firstName: "PRIYA RANJAN",
    lastName: "MAJUMDAR",
    address: "",
    fullName: "PRIYA RANJAN MAJUMDAR",
    saID: "SPR7690",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "AJIT KUMAR DAS",
    saID: "SPR7691",
  },
  {
    firstName: "BIDHU BHUSAN",
    lastName: "DEBNATH",
    address: "",
    fullName: "BIDHU BHUSAN DEBNATH",
    saID: "SPR7692",
  },
  {
    firstName: "SAMIRAN KUMAR",
    lastName: "DEY",
    address: "",
    fullName: "SAMIRAN KUMAR DEY",
    saID: "SPR7693",
  },
  {
    firstName: "NARAYAN",
    lastName: "TALUKDAR",
    address: "",
    fullName: "NARAYAN TALUKDAR",
    saID: "SPR7694",
  },
  {
    firstName: "HARIDAS",
    lastName: "BHATTACHARJEE",
    address: "",
    fullName: "HARIDAS BHATTACHARJEE",
    saID: "SPR7695",
  },
  {
    firstName: "MUKUNDA",
    lastName: "ROYGHOSAL",
    address: "",
    fullName: "MUKUNDA ROYGHOSAL",
    saID: "SPR7696",
  },
  {
    firstName: "MUKUNDA ROY",
    lastName: "GHOSAL",
    address: "",
    fullName: "MUKUNDA ROY GHOSAL",
    saID: "SPR7697",
  },
  {
    firstName: "KRUTIBAS",
    lastName: "ACHARYA",
    address: "",
    fullName: "KRUTIBAS ACHARYA",
    saID: "SPR7698",
  },
  {
    firstName: "BISWAPATI",
    lastName: "SARKAR",
    address: "",
    fullName: "BISWAPATI SARKAR",
    saID: "SPR7699",
  },
  {
    firstName: "SIBA PRASAD",
    lastName: "DEWANJI",
    address: "",
    fullName: "SIBA PRASAD DEWANJI",
    saID: "SPR7700",
  },
  {
    firstName: "GOBINDA LAL",
    lastName: "DAWN",
    address: "",
    fullName: "GOBINDA LAL DAWN",
    saID: "SPR7701",
  },
  {
    firstName: "KSHIROD MOHAN",
    lastName: "BISWAS",
    address: "",
    fullName: "KSHIROD MOHAN BISWAS",
    saID: "SPR7702",
  },
  {
    firstName: "RADHA KRISHNA",
    lastName: "MAITY",
    address: "",
    fullName: "RADHA KRISHNA MAITY",
    saID: "SPR7703",
  },
  {
    firstName: "GIRIDHARI",
    lastName: "JENA",
    address: "",
    fullName: "GIRIDHARI JENA",
    saID: "SPR7704",
  },
  {
    firstName: "HRUSHIKESH",
    lastName: "MISHRA",
    address: "",
    fullName: "HRUSHIKESH MISHRA",
    saID: "SPR7705",
  },
  {
    firstName: "BASUDEB",
    lastName: "PAL",
    address: "",
    fullName: "BASUDEB PAL",
    saID: "SPR7706",
  },
  {
    firstName: "BARENDRA NATH",
    lastName: "MAJUMDAR",
    address: "",
    fullName: "BARENDRA NATH MAJUMDAR",
    saID: "SPR7707",
  },
  {
    firstName: "ANIL CHANDRAH",
    lastName: "PAL",
    address: "",
    fullName: "ANIL CHANDRAH PAL",
    saID: "SPR7708",
  },
  {
    firstName: "RAKHAL KRISHNA",
    lastName: "NANDI",
    address: "",
    fullName: "RAKHAL KRISHNA NANDI",
    saID: "SPR7709",
  },
  {
    firstName: "NANIGOPAL",
    lastName: "BHADRA",
    address: "",
    fullName: "NANIGOPAL BHADRA",
    saID: "SPR7710",
  },
  {
    firstName: "MULINDRA",
    lastName: "HAJANG",
    address: "",
    fullName: "MULINDRA HAJANG",
    saID: "SPR7711",
  },
  {
    firstName: "SUBHAS CHANDRA",
    lastName: "HAJANG",
    address: "",
    fullName: "SUBHAS CHANDRA HAJANG",
    saID: "SPR7712",
  },
  {
    firstName: "MADAN CHANDRAA",
    lastName: "KAMAN",
    address: "",
    fullName: "MADAN CHANDRAA KAMAN",
    saID: "SPR7713",
  },
  {
    firstName: "SITENDU KUMAR",
    lastName: "DAS",
    address: "",
    fullName: "SITENDU KUMAR DAS",
    saID: "SPR7714",
  },
  {
    firstName: "SANJAY",
    lastName: "MANDAL",
    address: "",
    fullName: "SANJAY MANDAL",
    saID: "SPR7715",
  },
  {
    firstName: "DHANANJAY",
    lastName: "DEBANGSHI",
    address: "",
    fullName: "DHANANJAY DEBANGSHI",
    saID: "SPR7716",
  },
  {
    firstName: "AJITAVAAY",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AJITAVAAY CHAKRABORTY",
    saID: "SPR7717",
  },
  {
    firstName: "AJITAVA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AJITAVA CHAKRABORTY",
    saID: "SPR7718",
  },
  {
    firstName: "KASHINATH",
    lastName: "MANDAL",
    address: "",
    fullName: "KASHINATH MANDAL",
    saID: "SPR7719",
  },
  {
    firstName: "KASHI NATH",
    lastName: "MANDAL",
    address: "",
    fullName: "KASHI NATH MANDAL",
    saID: "SPR7720",
  },
  {
    firstName: "JAGAI CHANDRA",
    lastName: "DAS",
    address: "",
    fullName: "JAGAI CHANDRA DAS",
    saID: "SPR7721",
  },
  {
    firstName: "ASHUTOSH",
    lastName: "GANGULY",
    address: "",
    fullName: "ASHUTOSH GANGULY",
    saID: "SPR7722",
  },
  {
    firstName: "KASHI NATH",
    lastName: "MANDAL",
    address: "",
    fullName: "KASHI NATH MANDAL",
    saID: "SPR7723",
  },
  {
    firstName: "SRIDAM CHANDRA",
    lastName: "BISWAS",
    address: "",
    fullName: "SRIDAM CHANDRA BISWAS",
    saID: "SPR7724",
  },
  {
    firstName: "GANESH CHANDRA",
    lastName: "JENA",
    address: "",
    fullName: "GANESH CHANDRA JENA",
    saID: "SPR7725",
  },
  {
    firstName: "SARBESWAR",
    lastName: "DAS",
    address: "",
    fullName: "SARBESWAR DAS",
    saID: "SPR7726",
  },
  {
    firstName: "NIKHIL",
    lastName: "GHATAK",
    address: "",
    fullName: "NIKHIL GHATAK",
    saID: "SPR7727",
  },
  {
    firstName: "RABINDRA NATH",
    lastName: "DUTTA",
    address: "",
    fullName: "RABINDRA NATH DUTTA",
    saID: "SPR7728",
  },
  {
    firstName: "SAROJ KANTA",
    lastName: "DAS",
    address: "",
    fullName: "SAROJ KANTA DAS",
    saID: "SPR7729",
  },
  {
    firstName: "NARAHARI",
    lastName: "MANNA",
    address: "",
    fullName: "NARAHARI MANNA",
    saID: "SPR7730",
  },
  {
    firstName: "BAIRAGI",
    lastName: "SAHU",
    address: "",
    fullName: "BAIRAGI SAHU",
    saID: "SPR7731",
  },
  {
    firstName: "GOUTAM",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "GOUTAM CHAKRABORTY",
    saID: "SPR7732",
  },
  {
    firstName: "OUJYAPAD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "OUJYAPAD ASHOK CHAKRABORTY",
    saID: "SPR7733",
  },
  {
    firstName: "REV ARKYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKYADYUTI CHAKRABORTY",
    saID: "SPR7734",
  },
  {
    firstName: "PUJANIYA ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA ASHOKE CHAKRABORTY",
    saID: "SPR7735",
  },
  {
    firstName: "GOUR",
    lastName: "SINGHAROY",
    address: "",
    fullName: "GOUR SINGHAROY",
    saID: "SPR7736",
  },
  {
    firstName: "RADHA KRISHNA",
    lastName: "LAL",
    address: "",
    fullName: "RADHA KRISHNA LAL",
    saID: "SPR7737",
  },
  {
    firstName: "RAJENDRA S",
    lastName: "SHAH",
    address: "",
    fullName: "RAJENDRA S SHAH",
    saID: "SPR7738",
  },
  {
    firstName: "SAMIR KUMAR",
    lastName: "DASGUPTA",
    address: "",
    fullName: "SAMIR KUMAR DASGUPTA",
    saID: "SPR7739",
  },
  {
    firstName: "JOGESH CHANDRA",
    lastName: "HAIT",
    address: "",
    fullName: "JOGESH CHANDRA HAIT",
    saID: "SPR7740",
  },
  {
    firstName: "PRAVAT KUMARRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PRAVAT KUMARRA CHAKRABORTY",
    saID: "SPR7741",
  },
  {
    firstName: "ANINDADYUTIRRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANINDADYUTIRRA CHAKRABORTY",
    saID: "SPR7742",
  },
  {
    firstName: "ANINDADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANINDADYUTI CHAKRABORTY",
    saID: "SPR7743",
  },
  {
    firstName: "BASUDEB",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "BASUDEB CHATTERJEE",
    saID: "SPR7744",
  },
  {
    firstName: "KASTURI",
    lastName: "MURMU",
    address: "",
    fullName: "KASTURI MURMU",
    saID: "SPR7745",
  },
  {
    firstName: "REV      KAJAL",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV      KAJAL CHAKRABORTY",
    saID: "SPR7746",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRABORTY",
    saID: "SPR7747",
  },
  {
    firstName: "BABUL KANTI",
    lastName: "DAS",
    address:
      "BARALIA HEALTH & FAMILT WELFARE CENTRE*PO-MOULAVIRHAT*CHITTAGONG*BANGLADESH",
    fullName: "BABUL KANTI DAS",
    saID: "SPR7748",
  },
  {
    firstName: "PUJANIYA ASHOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "PUJANIYA ASHOK CHAKRABARTY",
    saID: "SPR7749",
  },
  {
    firstName: "GANAPATI",
    lastName: "PAM",
    address: "",
    fullName: "GANAPATI PAM",
    saID: "SPR7750",
  },
  {
    firstName: "RAJKISHORE",
    lastName: "MISHRA",
    address: "",
    fullName: "RAJKISHORE MISHRA",
    saID: "SPR7751",
  },
  {
    firstName: "PUJANIYA ASHOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA ASHOK KUMAR CHAKRABORTY",
    saID: "SPR7752",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV ALOK CHAKRAVORTY",
    saID: "SPR7753",
  },
  {
    firstName: "MIHIR",
    lastName: "GHOSH",
    address: "DUILLYA NABANAGAR*BADAMTALA*PO-DUILLYA*HOWRAH-711302",
    fullName: "MIHIR GHOSH",
    saID: "SPR7754",
  },
  {
    firstName: "REV ASHOK  KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK  KUMAR CHAKRABORTY",
    saID: "SPR7755",
  },
  {
    firstName: "CHANDRIKA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "CHANDRIKA CHAKRABORTY",
    saID: "SPR7756",
  },
  {
    firstName: "ARNADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARNADYUTI CHAKRABORTY",
    saID: "SPR7757",
  },
  {
    firstName: "HIRA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "HIRA CHAKRABORTY",
    saID: "SPR7758",
  },
  {
    firstName: "ABHIDEEP",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABHIDEEP CHAKRABORTY",
    saID: "SPR7759",
  },
  {
    firstName: "AKRYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKRYADYUTI CHAKRABORTY",
    saID: "SPR7760",
  },
  {
    firstName: "PUJYAPASD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJYAPASD ASHOK CHAKRABORTY",
    saID: "SPR7761",
  },
  {
    firstName: "REV ARKYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKYADYUTI CHAKRABORTY",
    saID: "SPR7762",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK CHAKRABORTY",
    saID: "SPR7763",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7764",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR7765",
  },
  {
    firstName: "MANISH",
    lastName: "PUNJABI",
    address:
      "RL 70 RITAYANI*MILAP NAGAR*MIDC,DOMBIVILI EAST*MAHARASHTRA 421203*PAN-AIFPP9166P",
    fullName: "MANISH PUNJABI",
    saID: "SPR7766",
  },
  {
    firstName: "PUJANIA MEJOBOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA MEJOBOURANI ",
    saID: "SPR7767",
  },
  {
    firstName: "ANINDITA",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ANINDITA CHAKRAVARTY",
    saID: "SPR7768",
  },
  {
    firstName: "ARONI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ARONI CHAKRAVARTY",
    saID: "SPR7769",
  },
  {
    firstName: "PUYAPAD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUYAPAD ASHOK CHAKRABORTY",
    saID: "SPR7770",
  },
  {
    firstName: "BIRUPAKHYA",
    lastName: "ROY",
    address: "",
    fullName: "BIRUPAKHYA ROY",
    saID: "SPR7771",
  },
  {
    firstName: "1AHENDRA",
    lastName: "BORA",
    address: "",
    fullName: "1AHENDRA BORA",
    saID: "SPR7772",
  },
  {
    firstName: "PUJYANIYA BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJYANIYA BARABAUDI ",
    saID: "SPR7773",
  },
  {
    firstName: "PUJTYAPAD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJTYAPAD ASHOK CHAKRABORTY",
    saID: "SPR7774",
  },
  {
    firstName: "REV ARKYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKYADYUTI CHAKRABORTY",
    saID: "SPR7775",
  },
  {
    firstName: "PUJYAPACD ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJYAPACD ASHOK CHAKRABORTY",
    saID: "SPR7776",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7777",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7778",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK CHAKRABORTY",
    saID: "SPR7779",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR7780",
  },
  {
    firstName: "PUJYAPADAHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJYAPADAHOK CHAKRABORTY",
    saID: "SPR7781",
  },
  {
    firstName: "SUNIL KUMAR",
    lastName: "ADITYA",
    address: "PAKUTIA*PO-D.PAKUTIA*TANGAIL*BANGLADESH",
    fullName: "SUNIL KUMAR ADITYA",
    saID: "SPR7782",
  },
  {
    firstName: "KRISHNA MILAN",
    lastName: "MAJUMDAR",
    address: "VILL-CHARTI*PO-DURDURI*PS-SATKANIA*DIST-CHITTAGONG*BANGLADESH",
    fullName: "KRISHNA MILAN MAJUMDAR",
    saID: "SPR7783",
  },
  {
    firstName: "DEBRANJAN",
    lastName: "MONDAL",
    address: "VILL-KAYRA*PO-MADINABAD*DIST-KHULNA*BANGLADESH",
    fullName: "DEBRANJAN MONDAL",
    saID: "SPR7784",
  },
  {
    firstName: "SHYAMLAL",
    lastName: "MONDAL",
    address:
      "KHULNA SATSANG VIHAR*127,HAZI ISMAIL ROAD*BANORGHATI*KHULNA*BANGLADESH",
    fullName: "SHYAMLAL MONDAL",
    saID: "SPR7785",
  },
  {
    firstName: "MRIDUL KANTI",
    lastName: "DHAR",
    address: "VILL/PO-CHIRINGA*PS-CHAKARIA*DIST-COXBAZAR*BANGLADESH",
    fullName: "MRIDUL KANTI DHAR",
    saID: "SPR7786",
  },
  {
    firstName: "BIPLAB",
    lastName: "BISWAS",
    address: "VILL-ASHATA*PO-SHOVANDONDI*PS-PATIYA*DIST-CHITTAGONG*BANGLADESH",
    fullName: "BIPLAB BISWAS",
    saID: "SPR7787",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "BISWAS",
    address: "SATSANG KENDRA CHATTAGRAM*126,HAZARI LANE*CHITTAGONG*BANGLADESH",
    fullName: "SANJAY KUMAR BISWAS",
    saID: "SPR7788",
  },
  {
    firstName: "SWARNA",
    lastName: "DAS",
    address: "SATSANG KENDRA CHATTAGRAM*126,HAZARI LANE*CHITTAGONG*BANGLADESH",
    fullName: "SWARNA DAS",
    saID: "SPR7789",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "SINHA",
    address: "C",
    fullName: "DILIP KUMAR SINHA",
    saID: "SPR7790",
  },
  {
    firstName: "REV CHAKRABORTY",
    lastName: "",
    address: "",
    fullName: "REV CHAKRABORTY ",
    saID: "SPR7791",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7792",
  },
  {
    firstName: "PUJANIYA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA CHAKRABORTY",
    saID: "SPR7793",
  },
  {
    firstName: "SRISRI BORDA",
    lastName: "",
    address: "",
    fullName: "SRISRI BORDA ",
    saID: "SPR7794",
  },
  {
    firstName: "SRISRI BOROMA",
    lastName: "",
    address: "",
    fullName: "SRISRI BOROMA ",
    saID: "SPR7795",
  },
  {
    firstName: "SANJAY",
    lastName: "NANDI",
    address:
      "C/O SUBHANKAR NANDI*VILL-CHATRA*PO-DARAPUR*BANKURA-722141*PAN.AEQPN6909R",
    fullName: "SANJAY NANDI",
    saID: "SPR7796",
  },
  {
    firstName: "KAMALESWAR",
    lastName: "GHOSAL",
    address: "",
    fullName: "KAMALESWAR GHOSAL",
    saID: "SPR7797",
  },
  {
    firstName: "REV ASHOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK KUMAR CHAKRAVARTY",
    saID: "SPR7798",
  },
  {
    firstName: "REV ALOKE",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ALOKE CHAKRAVARTY",
    saID: "SPR7799",
  },
  {
    firstName: "PUJANIA RANGAMA",
    lastName: "",
    address: "",
    fullName: "PUJANIA RANGAMA ",
    saID: "SPR7800",
  },
  {
    firstName: "APU",
    lastName: "BISWAS",
    address: "",
    fullName: "APU BISWAS",
    saID: "SPR7801",
  },
  {
    firstName: "REV ARKYADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKYADYUTI CHAKRABORTY",
    saID: "SPR7802",
  },
  {
    firstName: "B M",
    lastName: "DAS",
    address: "",
    fullName: "B M DAS",
    saID: "SPR7803",
  },
  {
    firstName: "D K",
    lastName: "MALLIK",
    address: "",
    fullName: "D K MALLIK",
    saID: "SPR7804",
  },
  {
    firstName: "REV DR ALOKR KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOKR KUMAR CHAKRABORTY",
    saID: "SPR7805",
  },
  {
    firstName: "PUJANIA BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABAUDI ",
    saID: "SPR7806",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7807",
  },
  {
    firstName: "PUJANIYA ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA ASHOK CHAKRABORTY",
    saID: "SPR7808",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7809",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7810",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7811",
  },
  {
    firstName: "BIDHAN CHANDRA",
    lastName: "MONDAL",
    address: "KAPASDANGA*NAGARGHATA*TALA SATKHIRA*BANGLADESH.",
    fullName: "BIDHAN CHANDRA MONDAL",
    saID: "SPR7812",
  },
  {
    firstName: "SADHAN CHANDRA",
    lastName: "SUSHIL",
    address:
      "JANAR KEOCHIA MODEL HIGH SCHOOL*PO-KERANIHAT*PS-SATKHIRA*CHITTAGONG*BANGLADESH",
    fullName: "SADHAN CHANDRA SUSHIL",
    saID: "SPR7813",
  },
  {
    firstName: "RANJAN KUMAR",
    lastName: "GHOSH",
    address: "SATSANG KENDRA,CHITTAGONG*126,HAZARI LANE*CHITTAGONG*BANGLADESH",
    fullName: "RANJAN KUMAR GHOSH",
    saID: "SPR7814",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7815",
  },
  {
    firstName: "RANJAN",
    lastName: "HALDER",
    address: "",
    fullName: "RANJAN HALDER",
    saID: "SPR7816",
  },
  {
    firstName: "KHIM PRASAD",
    lastName: "THAPA",
    address: "AT-RATHKHOLA*PO-NAXALBARI*DIST-DARJEELING-734429",
    fullName: "KHIM PRASAD THAPA",
    saID: "SPR7817",
  },
  {
    firstName: "REV",
    lastName: "CHAKRAVORTY",
    address: "",
    fullName: "REV CHAKRAVORTY",
    saID: "SPR7818",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7819",
  },
  {
    firstName: "ARKADUTI",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "ARKADUTI CHAKRABARTY",
    saID: "SPR7820",
  },
  {
    firstName: "DHIMADHAB",
    lastName: "KIRTANIA",
    address:
      '"DEVOMAY"*SULTANPUR 2 NO.AIRPORT GATE*PO-RAJBARI COLONY*KOLKATA-81*PAN-AHQPK9962J',
    fullName: "DHIMADHAB KIRTANIA",
    saID: "SPR7821",
  },
  {
    firstName: "NANDADYUTI",
    lastName: "ROY",
    address:
      "S/O NARENDRANATH ROY*MANOBOTA SCHOOL ROAD*PO-HRIDAYPUR*24PARGANAS (NORTH)-700127.",
    fullName: "NANDADYUTI ROY",
    saID: "SPR7822",
  },
  {
    firstName: "PUJANIYA MAUMITA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA MAUMITA CHAKRABORTY",
    saID: "SPR7823",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7824",
  },
  {
    firstName: "ANISH",
    lastName: "MAITRA",
    address: "206, JODHPUR PARK*KOLKATA-700068",
    fullName: "ANISH MAITRA",
    saID: "SPR7825",
  },
  {
    firstName: "AMIYANGSHU (TUPAIDA)",
    lastName: "CHAKRABORTY",
    address: "SATSANG*DEOGHAR 814116.",
    fullName: "AMIYANGSHU (TUPAIDA) CHAKRABORTY",
    saID: "SPR7826",
  },
  {
    firstName: "PUJANIYA ABINBABU",
    lastName: "",
    address: "",
    fullName: "PUJANIYA ABINBABU ",
    saID: "SPR7827",
  },
  {
    firstName: "SUKUMAR",
    lastName: "DAS",
    address: "C/O RADHA KRISHNA LAL*SATSANG*DEOGHAR",
    fullName: "SUKUMAR DAS",
    saID: "SPR7828",
  },
  {
    firstName: "PUJANIA  BARABAUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA  BARABAUDI ",
    saID: "SPR7829",
  },
  {
    firstName: "SOUMITRA",
    lastName: "MANDAL",
    address: "",
    fullName: "SOUMITRA MANDAL",
    saID: "SPR7830",
  },
  {
    firstName: "NARENDRA CHANDRA",
    lastName: "PANDIT",
    address: "AT/PO-KALIHATI*DIST-TANGAIL*BANGLADESH",
    fullName: "NARENDRA CHANDRA PANDIT",
    saID: "SPR7831",
  },
  {
    firstName: "AJAY",
    lastName: "DASGUPTA",
    address: "SENPARA*RONGPUR*BANGLADESH",
    fullName: "AJAY DASGUPTA",
    saID: "SPR7832",
  },
  {
    firstName: "SUDHIRAM",
    lastName: "BARMAN",
    address: "SAYEBPUR*JAHANGIRABAD*DIST-RONGPUR*BANGLADESH",
    fullName: "SUDHIRAM BARMAN",
    saID: "SPR7833",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "DEB",
    address: "KADAMHAT*MOULAVI BAZAR*BANGLADESH",
    fullName: "PABITRA KUMAR DEB",
    saID: "SPR7834",
  },
  {
    firstName: "MAHENDRA NATH",
    lastName: "ROY",
    address: "VILL-KISAMAT MADHABPUR*PO-JOYDEBPUR*SADAR DINAJPUR*BANGLADESH",
    fullName: "MAHENDRA NATH ROY",
    saID: "SPR7835",
  },
  {
    firstName: "ADHAR LAL",
    lastName: "NATH",
    address: "SATSANG COX'S BAZAR*BANGLADESH",
    fullName: "ADHAR LAL NATH",
    saID: "SPR7836",
  },
  {
    firstName: "REV ALOKE KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ALOKE KUMAR CHAKRAVARTY",
    saID: "SPR7837",
  },
  {
    firstName: "PUJANIA BARA BOUDI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARA BOUDI ",
    saID: "SPR7838",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRAVARTY",
    saID: "SPR7839",
  },
  {
    firstName: "ABHINAV",
    lastName: "CHOUDHURY",
    address: "NORTH OF DR S PANDIT*DEOPALI*POKHARIA*BEGUSARAI 851 101",
    fullName: "ABHINAV CHOUDHURY",
    saID: "SPR7840",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR7841",
  },
  {
    firstName: "SUBRATA",
    lastName: "RAY",
    address: "VILL-KHUMURIA*PO-PIROJPUR*THANA/DIST-PIROJPUR*BANGLADESH",
    fullName: "SUBRATA RAY",
    saID: "SPR7842",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ALOK CHAKRAVARTY",
    saID: "SPR7843",
  },
  {
    firstName: "BHIM BAHADUR",
    lastName: "GURUNG",
    address: "VILL-SARALPARA*PO-ULTAPANI*KOKRAJHAR-783370*ASSAM",
    fullName: "BHIM BAHADUR GURUNG",
    saID: "SPR7844",
  },
  {
    firstName: "CHHOTON",
    lastName: "BANERJEE",
    address: "NEAR POST OFFICE GALI*SATSANG-814 116*DEOGHAR*JHARKHAND",
    fullName: "CHHOTON BANERJEE",
    saID: "SPR7845",
  },
  {
    firstName: "PUJANIYA BAURNI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA BAURNI CHAKRABORTY",
    saID: "SPR7846",
  },
  {
    firstName: "NAGENDRA KUMAR",
    lastName: "MAHANTA",
    address: "",
    fullName: "NAGENDRA KUMAR MAHANTA",
    saID: "SPR7847",
  },
  {
    firstName: "SANAT KUMAR",
    lastName: "GHOSH",
    address: "VILL-BOGRA PALLI BIDYUT SAMITY*SILIMPUR,BOGRA*BANGLADESH",
    fullName: "SANAT KUMAR GHOSH",
    saID: "SPR7848",
  },
  {
    firstName: "SUDIP",
    lastName: "DAS",
    address: "VILL-SOUDHERSREE*PO-KADIRGANJ*DIST-SUNAMGANJ *BANGLADESH",
    fullName: "SUDIP DAS",
    saID: "SPR7849",
  },
  {
    firstName: "BHAGABAT CHARAN",
    lastName: "NAYAK",
    address: "",
    fullName: "BHAGABAT CHARAN NAYAK",
    saID: "SPR7850",
  },
  {
    firstName: "PUJANIYA MA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA MA ",
    saID: "SPR7851",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK CHAKRABORTY",
    saID: "SPR7852",
  },
  {
    firstName: "REV AMAL KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV AMAL KUMAR CHAKRABORTY",
    saID: "SPR7853",
  },
  {
    firstName: "PABITRA",
    lastName: "SETHY",
    address: "",
    fullName: "PABITRA SETHY",
    saID: "SPR7854",
  },
  {
    firstName: "UTTAM",
    lastName: "NARZARI",
    address: "DINESH OJHA PATH*KAMAKHYA NAGAR*GUWAHATI 781005",
    fullName: "UTTAM NARZARI",
    saID: "SPR7855",
  },
  {
    firstName: "NARAYAN",
    lastName: "BISWAS",
    address:
      "H/O LT NRIPENDRA KUMAR BISWAS*SITLABARI*PO-DIPHU*KARBIANLONG*PAN ACGPB8364A",
    fullName: "NARAYAN BISWAS",
    saID: "SPR7856",
  },
  {
    firstName: "NILOY",
    lastName: "MAJUMDER",
    address:
      "OLD  KAPASDANGA, KAZIR BAGAN*PO/DIST-HOOGHLY-712103*PAN-AFBPM1596B",
    fullName: "NILOY MAJUMDER",
    saID: "SPR7857",
  },
  {
    firstName: "RANJIT KUMAR",
    lastName: "GHORUI",
    address: "VILL-SIBPUR*PO-SINGTI SIBPUR*HOWRAH-711226.",
    fullName: "RANJIT KUMAR GHORUI",
    saID: "SPR7858",
  },
  {
    firstName: "NABA",
    lastName: "KALITA",
    address: "C/O KIRAN PRAKASHAN*AT/PO/DIST-DHEMAJI*ASSAM 787057",
    fullName: "NABA KALITA",
    saID: "SPR7859",
  },
  {
    firstName: "ANUKUL",
    lastName: "PAUL",
    address: "MAKALI BHANDAR DIPHU*PO-DIPHU*KARBIANGLONG-782460*PAN-ADRPP1033A",
    fullName: "ANUKUL PAUL",
    saID: "SPR7860",
  },
  {
    firstName: "DIBENDU",
    lastName: "DEY",
    address:
      "C/O JAYGURU BOOK BINDING WORKS*PO DIPHU*KARBIANGLONG*PAN-AJFPD8469E",
    fullName: "DIBENDU DEY",
    saID: "SPR7861",
  },
  {
    firstName: "DIPAK",
    lastName: "SAHA",
    address:
      "NTPC/KHSTPP*PTS-B-263,KAHALGAON STPP*BHAGALPUR-813 214*PAN AITPS1518C",
    fullName: "DIPAK SAHA",
    saID: "SPR7862",
  },
  {
    firstName: "SADANAND",
    lastName: "JHA",
    address: "RETD STATION MANAGER*CHIRAIYABAD*PO-RATANPUR*MUNGER 811211",
    fullName: "SADANAND JHA",
    saID: "SPR7863",
  },
  {
    firstName: "PRAKASH",
    lastName: "DEBBARMA",
    address: "HARIDHAN CHOUDHURY  PARA*RAJNAGAR*TRIPURA(W)",
    fullName: "PRAKASH DEBBARMA",
    saID: "SPR7864",
  },
  {
    firstName: "UTJAL",
    lastName: "DEBBARMA",
    address:
      "VILL-RABICHARAN CHOUKIDARPARA*PO-RAJNAGAR*DIST-KHOWAI*TRIPURA*PAN-CLGPD7206Q",
    fullName: "UTJAL DEBBARMA",
    saID: "SPR7865",
  },
  {
    firstName: "PARTHA SARATHI",
    lastName: "DAS",
    address:
      "RAMYADIPA*KISHORNAGAR*NR CONTAI R C VIDYAPITH*PO-CONTAI*PURBA MEDINIPUR-721401",
    fullName: "PARTHA SARATHI DAS",
    saID: "SPR7866",
  },
  {
    firstName: "AMIT",
    lastName: "MISRA",
    address: "AT-LOUDANDA*PO-NAMAL*PURBA MEDINIPUR-721401*PAN-ANUPM3531M",
    fullName: "AMIT MISRA",
    saID: "SPR7867",
  },
  {
    firstName: "PURNA CHANDRA",
    lastName: "HALDAR",
    address: "SARAT PALLI*PO-RANAGHAT*NADIA-741201*PAN NO.AIMPH1969H",
    fullName: "PURNA CHANDRA HALDAR",
    saID: "SPR7868",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOK CHAKRABORTY",
    saID: "SPR7869",
  },
  {
    firstName: "REV DR ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK KUMAR CHAKRABORTY",
    saID: "SPR7870",
  },
  {
    firstName: "AGNIMUKH",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "AGNIMUKH CHAKRABARTY",
    saID: "SPR7871",
  },
  {
    firstName: "5004183657",
    lastName: "",
    address: "",
    fullName: "5004183657 ",
    saID: "SPR7872",
  },
  {
    firstName: "ARKADYURTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYURTI CHAKRABORTY",
    saID: "SPR7873",
  },
  {
    firstName: "ANUDUDDHA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANUDUDDHA CHAKRABORTY",
    saID: "SPR7874",
  },
  {
    firstName: "ANINDADYUTI",
    lastName: "",
    address: "",
    fullName: "ANINDADYUTI ",
    saID: "SPR7875",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "BEHERA",
    address: "",
    fullName: "PARESH CHANDRA BEHERA",
    saID: "SPR7876",
  },
  {
    firstName: "RAJIV",
    lastName: "JAISWAL",
    address: "",
    fullName: "RAJIV JAISWAL",
    saID: "SPR7877",
  },
  {
    firstName: "KASHI NATH",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "KASHI NATH BHATTACHERJEE",
    saID: "SPR7878",
  },
  {
    firstName: "JAGANATH",
    lastName: "SAHA",
    address:
      "BG-1 AMIDHARA COMPLEX*NR AMRUT APPT*SHIBMANDIR RAGINI BHADKODRA*ANKLESHWAR-393001",
    fullName: "JAGANATH SAHA",
    saID: "SPR7879",
  },
  {
    firstName: "PRASHANTA KUMAR",
    lastName: "PATTANAYAK",
    address:
      "415,5TH D-MAIN,IIND BLOCK*HRBR LAYOUT*KALYAN NAGAR*BANGALORE-560043*PAN-ALKPP1977A",
    fullName: "PRASHANTA KUMAR PATTANAYAK",
    saID: "SPR7880",
  },
  {
    firstName: "PRASUN",
    lastName: "DAS",
    address:
      "BANGALORE SATSANG VIHAR*I ST MAIN I ST CROSS*KARNASHREE LAYOUT*MEDAHALLI*BANGALORE",
    fullName: "PRASUN DAS",
    saID: "SPR7881",
  },
  {
    firstName: "RAHUL",
    lastName: "THAKUR",
    address:
      "C/O SATSANG VIHAR BANGALORE*KARNASREE LAYOUT*MEDAHALLI*BANGALORE 560049*AFKPT3210N",
    fullName: "RAHUL THAKUR",
    saID: "SPR7882",
  },
  {
    firstName: "ASOKE",
    lastName: "GIDWANI",
    address: "SATSANG VIHAR*HOUSE NO-1-1-5/1,RAJENDRANGAR*HYDERABAD",
    fullName: "ASOKE GIDWANI",
    saID: "SPR7883",
  },
  {
    firstName: "UTTAM KUMAR",
    lastName: "SENGUPTA",
    address: "C/O SATSANG VIHAR GOSAIGAON*PO-GOSAIGAON*KOKRAJHAR-783360*ASSAM",
    fullName: "UTTAM KUMAR SENGUPTA",
    saID: "SPR7884",
  },
  {
    firstName: "B C",
    lastName: "NAYAK",
    address: "",
    fullName: "B C NAYAK",
    saID: "SPR7885",
  },
  {
    firstName: "N K",
    lastName: "MAHANTY",
    address: "",
    fullName: "N K MAHANTY",
    saID: "SPR7886",
  },
  {
    firstName: "HARISHANKAR",
    lastName: "SAH",
    address: "H K GLASS HOUSE*KOHIMA*NAGALAND-797 001",
    fullName: "HARISHANKAR SAH",
    saID: "SPR7887",
  },
  {
    firstName: "SUKUMAR CHANDRA",
    lastName: "SAHA",
    address: "VILL-KANDIRPARA*PO/DIST-B-BARIYA*BANGLADESH",
    fullName: "SUKUMAR CHANDRA SAHA",
    saID: "SPR7888",
  },
  {
    firstName: "PAPAI",
    lastName: "CHANDA",
    address: "ADVOCATE*LHOMITHI COLONY*DIMAPUR,*NAGALAND.",
    fullName: "PAPAI CHANDA",
    saID: "SPR7889",
  },
  {
    firstName: "UPENDRA NATH",
    lastName: "KAR",
    address: "VILL-PAKUTIA*PO-D.PAKUTIA*PS-GHATAIL*DIST-TANGAIL*BANGLADESH",
    fullName: "UPENDRA NATH KAR",
    saID: "SPR7890",
  },
  {
    firstName: "GOPAL CHANDRA",
    lastName: "CHETRI",
    address: "PO&VILL-KHERONI GHAT*KARBIANGLONG 782448*PAN AGDPC3350Q",
    fullName: "GOPAL CHANDRA CHETRI",
    saID: "SPR7891",
  },
  {
    firstName: "SIBAPADA",
    lastName: "PAL",
    address:
      "ENGINEER SE,IID/SOG;VSSC*ISRO-PO,*TRIVANDRUM-695022*KERALA*PAN-AEMPD2359D",
    fullName: "SIBAPADA PAL",
    saID: "SPR7892",
  },
  {
    firstName: "PRAKASH CHANDRA",
    lastName: "BEHERA",
    address: "NUTAN PATNA*HINDOL*DHENKANAL-759022",
    fullName: "PRAKASH CHANDRA BEHERA",
    saID: "SPR7893",
  },
  {
    firstName: "PALLAB",
    lastName: "SARKAR",
    address:
      "FLAT NO 202,MILLINEUM APARTMENTS*BD-182,RAVINDRAPALLY*KRISHNAPUR*KOLKATA-700101.",
    fullName: "PALLAB SARKAR",
    saID: "SPR7894",
  },
  {
    firstName: "NABIN",
    lastName: "SAHOO",
    address:
      "HOUSE NO-LIG-7,STAGE-I*BRIT COLONY*PO-CITY POST OFFICE*BERHAMPUR-2*GANJAM*PI-760002",
    fullName: "NABIN SAHOO",
    saID: "SPR7895",
  },
  {
    firstName: "BHUPATI LAL",
    lastName: "MEHERA",
    address: "VILL/PO-PAILAPOOL*CACHAR-788098.",
    fullName: "BHUPATI LAL MEHERA",
    saID: "SPR7896",
  },
  {
    firstName: "NATHURAM",
    lastName: "ISLARY",
    address: "GOSSAIGAON*KOKRAJHAR*PAN-AAWPI9715J",
    fullName: "NATHURAM ISLARY",
    saID: "SPR7897",
  },
  {
    firstName: "ANANTA KUMAR",
    lastName: "JHA",
    address:
      "C/O RATNESWAR JHA*D S E OFFICE,MOTIHARI*AT/PO-MOTIHARI*EASTCHAMPARAN 845401.",
    fullName: "ANANTA KUMAR JHA",
    saID: "SPR7898",
  },
  {
    firstName: "PRODIP KUMAR",
    lastName: "DEY",
    address: "C/O SWASTI GUN HOUSE*PO-DIMAPUR*NAGALAND-797112",
    fullName: "PRODIP KUMAR DEY",
    saID: "SPR7899",
  },
  {
    firstName: "TUKARAM PANDURANG",
    lastName: "YADAV",
    address:
      "C/O-TAPSYA LAUNDRY*AT/PO-CHAKAN*DIST-PUNE, M.S. 410501.*AHQPJ0754P",
    fullName: "TUKARAM PANDURANG YADAV",
    saID: "SPR7900",
  },
  {
    firstName: "MANOJ KUMAR",
    lastName: "DAS",
    address: "PO&AT-GARAJANGA*VIA-MARSHAGHAI*KENDRAPARA-754213",
    fullName: "MANOJ KUMAR DAS",
    saID: "SPR7901",
  },
  {
    firstName: "ULASH KUMAR",
    lastName: "PRADHAN",
    address:
      "AT-BLOCK COLONY(LINEMAN SAHI)*PO-MALKANGIRI COLONY*DIST-MALKANGIRI 764048",
    fullName: "ULASH KUMAR PRADHAN",
    saID: "SPR7902",
  },
  {
    firstName: "KHAGENDRA",
    lastName: "SAHU",
    address:
      "L.I.,C.D.V.O. OFFICE*AT-MALKANGIRI*PO/DT-MALKANGIRI-764051*PAN-DIZPS5571Q",
    fullName: "KHAGENDRA SAHU",
    saID: "SPR7903",
  },
  {
    firstName: "INDRAJIT",
    lastName: "POLLEY",
    address: "AT/PO-GONDALPARA*HOWRAH-711302",
    fullName: "INDRAJIT POLLEY",
    saID: "SPR7904",
  },
  {
    firstName: "JOGESH CHANDRA",
    lastName: "MANDAL",
    address: "VILL-ANDHUA*PO-BENIAGRAM*P.S-FARAKKA*MURSHIDABAD 742212",
    fullName: "JOGESH CHANDRA MANDAL",
    saID: "SPR7905",
  },
  {
    firstName: "SARAD PRAVA",
    lastName: "KUNDU",
    address: "10 GEORGE ROAD*PO-NAIHATI*24PARGANAS NORTH-743165",
    fullName: "SARAD PRAVA KUNDU",
    saID: "SPR7906",
  },
  {
    firstName: "MAHENDRA PRASAD",
    lastName: "SINGH",
    address:
      "HETAMPUR RAJ BARI*BELABAGAN*PO&DIST-DEOGHAR 814 112*PAN-BMHPS0851F",
    fullName: "MAHENDRA PRASAD SINGH",
    saID: "SPR7907",
  },
  {
    firstName: "MADHUP KUMAR",
    lastName: "SINGH",
    address: "C/O SADAN PD. SINGH*`MOHAN KANAN'*BELABAGAN,DABURGRAM*DEOGHAR.",
    fullName: "MADHUP KUMAR SINGH",
    saID: "SPR7908",
  },
  {
    firstName: "PANKAJ KUMAR",
    lastName: "SINGH",
    address: "A-15 DOCTOR'S COLONY*PANDEYPUR*VARANASI-221001",
    fullName: "PANKAJ KUMAR SINGH",
    saID: "SPR7909",
  },
  {
    firstName: "JHONTU CHANDRA",
    lastName: "OJHA",
    address: "10/E/2,MADRASSA ROAD*MAHAMMADPUR*DHAKA-1207*BANGLADESH",
    fullName: "JHONTU CHANDRA OJHA",
    saID: "SPR7910",
  },
  {
    firstName: "BINOY",
    lastName: "PRASAD",
    address: "AT-DR HARADHAN BASU LANE*PO-BHAGAWAN BAZAR*CHHAPRA-841301",
    fullName: "BINOY PRASAD",
    saID: "SPR7911",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "MOHARANA",
    address: "AT/PO-PATAPUR*VIA-BANKI*DIST-CUTTACK-754008",
    fullName: "GHANASHYAM MOHARANA",
    saID: "SPR7912",
  },
  {
    firstName: "ARKADYURI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARKADYURI CHAKRABORTY",
    saID: "SPR7913",
  },
  {
    firstName: "AMRITENDU",
    lastName: "CHAKRABORTY",
    address: "AT/PO-SATSANG*DEOGHAR(JHARKHAND)-814116",
    fullName: "AMRITENDU CHAKRABORTY",
    saID: "SPR7914",
  },
  {
    firstName: "DIPAK",
    lastName: "BISWAS",
    address: "TARULIYA PATRA PARA*PO-KRISHNAPUR*KOLKATA-700102",
    fullName: "DIPAK BISWAS",
    saID: "SPR7915",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7916",
  },
  {
    firstName: "SRIDHAR",
    lastName: "MISHRA",
    address: "",
    fullName: "SRIDHAR MISHRA",
    saID: "SPR7917",
  },
  {
    firstName: "RAMBILASH",
    lastName: "CHOUDHURY",
    address:
      "ASST PROF APPLIED PHYSICS*INDIAN SCHOOL OF MINES*DHANBAD-826004*PAN-AFWPC1824R",
    fullName: "RAMBILASH CHOUDHURY",
    saID: "SPR7918",
  },
  {
    firstName: "ARABINDA KUMAR",
    lastName: "PANDEY",
    address:
      "NEW JANTA HARDWARE*PALI ROAD RIMJHIM HOTEL*PO-DEHRI-ON-SONE*ROHTAS-821307",
    fullName: "ARABINDA KUMAR PANDEY",
    saID: "SPR7919",
  },
  {
    firstName: "MAGENDRA",
    lastName: "MOHANTY",
    address: "",
    fullName: "MAGENDRA MOHANTY",
    saID: "SPR7920",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR7921",
  },
  {
    firstName: "A",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "A CHATTERJEE",
    saID: "SPR7922",
  },
  {
    firstName: "A",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "A CHATTERJEE",
    saID: "SPR7923",
  },
  {
    firstName: "REV  ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV  ARKADYUTI CHAKRABORTY",
    saID: "SPR7924",
  },
  {
    firstName: "ANJAN KUMAR",
    lastName: "DEVROY",
    address:
      "UNIT-2.23 RACHEL STREET*DARLING HEIGHTS*TOOWOOMBA,QLD-4350*AUSTRALIA",
    fullName: "ANJAN KUMAR DEVROY",
    saID: "SPR7925",
  },
  {
    firstName: "JAYGOPAL",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "JAYGOPAL CHATTERJEE",
    saID: "SPR7926",
  },
  {
    firstName: "RAGHUNATH",
    lastName: "BORO",
    address:
      "HATIMATHA KOKRAJHAR WARD NO-8*PO/DIST-KOKRAJHAR (BTAD)-783370*PAN-AHMPB6860E",
    fullName: "RAGHUNATH BORO",
    saID: "SPR7927",
  },
  {
    firstName: "RATUL",
    lastName: "BHATTACHARJEE",
    address: "VILL-GELAKI*PO-SILJURI*VIA-BOKAKHAT*GOLAGHAT 785612",
    fullName: "RATUL BHATTACHARJEE",
    saID: "SPR7928",
  },
  {
    firstName: "UDAY SHANKAR",
    lastName: "UPADHYAY",
    address: "LAXMAN BAGH*PURANI SARAI*NATHNAGAR*BHAGALPUR-812006*BIHAR",
    fullName: "UDAY SHANKAR UPADHYAY",
    saID: "SPR7929",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "SINGH",
    address:
      "SWASTI  NIKET,NEW KARMIK NAGAR*PO-SARAIDHELA*DHANBAD-828127*PAN-CCBPS5306L",
    fullName: "RAM CHANDRA SINGH",
    saID: "SPR7930",
  },
  {
    firstName: "SATYENDRA",
    lastName: "KUMAR",
    address:
      "M O I/C REFLOR HOSPITAL*LAXMIPUR*JAMUI-811312*BIHAR*PAN-ADPPK2752F",
    fullName: "SATYENDRA KUMAR",
    saID: "SPR7931",
  },
  {
    firstName: "JAGANNATH LAL",
    lastName: "DAS",
    address: "AT&PO-BELARAHI*JHANJHARPUR*MADHUBANI-847404.",
    fullName: "JAGANNATH LAL DAS",
    saID: "SPR7932",
  },
  {
    firstName: "BHOLA",
    lastName: "PRASAD",
    address: "VILL/PO-GADIYANI*MADHUBANI 847 211*PAN NO.AGCPP8064H",
    fullName: "BHOLA PRASAD",
    saID: "SPR7933",
  },
  {
    firstName: "AMBAR BAHADUR",
    lastName: "BASNET",
    address: "POST BOX NO 22*JOGBANI*PURNEA-854328",
    fullName: "AMBAR BAHADUR BASNET",
    saID: "SPR7934",
  },
  {
    firstName: "VIJAY KANT",
    lastName: "JHA",
    address:
      "C/O-GOURI KANT JHA*AT&PO-BHADRESWAR*VIA-BATHANAHA*ARARIA-854316*PAN-AYNPJ3445K",
    fullName: "VIJAY KANT JHA",
    saID: "SPR7935",
  },
  {
    firstName: "UMESH",
    lastName: "MAHATO",
    address: "VILL-RANIGONJ*PO-MERIGANJ*DIST-ARARIA-854311*AFJPM714E",
    fullName: "UMESH MAHATO",
    saID: "SPR7936",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "MAHATO",
    address:
      "L.I.G.ROW HOUSE-68*ADITYAPUR-2*SARAIKELA KHARSWAN*JHARKHAND-832109",
    fullName: "ANIL KUMAR MAHATO",
    saID: "SPR7937",
  },
  {
    firstName: "SURESH",
    lastName: "JHA",
    address: "KENDRIYA VIDYALAYA*JAWAHAR NAGAR*DIST-SHEOHAR*BIHAR",
    fullName: "SURESH JHA",
    saID: "SPR7938",
  },
  {
    firstName: "BRUNDABAN",
    lastName: "SAHU",
    address: "H.NO-G-1/57*SOUTH CITY-2*GURGAON*PIN-122018",
    fullName: "BRUNDABAN SAHU",
    saID: "SPR7939",
  },
  {
    firstName: "ANIL",
    lastName: "BALLABH",
    address:
      "MS2/306 KENDRIYA VIHAR*SECTOR-56,GURGAON*HARYANA-122 002*PAN-AFBPB5062R",
    fullName: "ANIL BALLABH",
    saID: "SPR7940",
  },
  {
    firstName: "SATYABADI",
    lastName: "MUDULI",
    address:
      "3909/FIRST FLOOR*H/B COLONY,SECTOR-3*FARIDABAD*HARYANA-121004*PAN-AMKPM3588R",
    fullName: "SATYABADI MUDULI",
    saID: "SPR7941",
  },
  {
    firstName: "NABAKISHOR",
    lastName: "SAMANTA",
    address: "68/5352-53 REGHARPURA*KAROL BAGH*NEW DELHI 5",
    fullName: "NABAKISHOR SAMANTA",
    saID: "SPR7942",
  },
  {
    firstName: "BHAWANI SANKAR",
    lastName: "PRADHAN",
    address:
      "H.NO-384, SEC-21, POCKET-A*GURGAON*HARIYANA 122016*PAN-AAHPP1551C",
    fullName: "BHAWANI SANKAR PRADHAN",
    saID: "SPR7943",
  },
  {
    firstName: "MANORANJAN",
    lastName: "SAHOO",
    address:
      "AT-GOPINATHPUR*PO-DARPA NARAYAN PUR*NAYAGARH-752079*PAN-BLTPS8963F",
    fullName: "MANORANJAN SAHOO",
    saID: "SPR7944",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "BADATYA",
    address:
      "C/O-ASHOK SARMA*H NO 5489*M S ROAD,POOJA WALA MAHALLA*BHATINDA*PUNJAB 151 001",
    fullName: "SANTOSH KUMAR BADATYA",
    saID: "SPR7945",
  },
  {
    firstName: "PRAMOD KUMAR",
    lastName: "DAS",
    address:
      "STATE BANK OF BIKANER & JAIPUR*GANAPATI PLAZA BRANCH*M I ROAD*JAIPUR-302001.",
    fullName: "PRAMOD KUMAR DAS",
    saID: "SPR7946",
  },
  {
    firstName: "BHIKARI CHARAN",
    lastName: "PATRA",
    address: "AT-GANDHINAGAR*PO-BALUGAON*KHURDA-752030",
    fullName: "BHIKARI CHARAN PATRA",
    saID: "SPR7947",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "MATIA",
    address:
      "AT&PO-BHANJOKIA*BLOCK-RARUAN;P.S.JOSHIPUR*MAYURBHANJ 757091*PAN-AESPM4254Q",
    fullName: "TAPAN KUMAR MATIA",
    saID: "SPR7948",
  },
  {
    firstName: "MITHILESH",
    lastName: "ORAON",
    address: "VILL-MISHIR GONDA*KANKE ROAD*RANCHI 834008*PAN-AACPO0962D",
    fullName: "MITHILESH ORAON",
    saID: "SPR7949",
  },
  {
    firstName: "KAILASH",
    lastName: "DULEY",
    address: "",
    fullName: "KAILASH DULEY",
    saID: "SPR7950",
  },
  {
    firstName: "JIBAN",
    lastName: "MITRA",
    address: "AT/PO-LALUK*LAKHIMPUR (N) 784160",
    fullName: "JIBAN MITRA",
    saID: "SPR7951",
  },
  {
    firstName: "RAGHUBANSH",
    lastName: "PANDEY",
    address: "PANDEY BIGHA*PO-BALIA(AMBA)*AURANGABAD 824111*PAN BGTPP5577P",
    fullName: "RAGHUBANSH PANDEY",
    saID: "SPR7952",
  },
  {
    firstName: "MRITYUNJAY KUMAR",
    lastName: "DWIVEDI",
    address:
      "BLOCK ROAD*PO-NARKATIAGANJ*PS-SHIKARPUR,WARD NO-20*WEST CHAMPARAN 845455",
    fullName: "MRITYUNJAY KUMAR DWIVEDI",
    saID: "SPR7953",
  },
  {
    firstName: "LATE LAKSHMI KANTA",
    lastName: "MAHATO",
    address: "O/O GENERAL MANAGER*SUDAMDIH AREA*PO-SUDAMDIH*DHANBAD",
    fullName: "LATE LAKSHMI KANTA MAHATO",
    saID: "SPR7954",
  },
  {
    firstName: "ANAND",
    lastName: "SINGH",
    address:
      "C/O-R.C.SINGH*SWASTI NIKET*NEW KARMIK NAGAR,NEAR DPS*PO-I.S.M*DHANBAD-826004.",
    fullName: "ANAND SINGH",
    saID: "SPR7955",
  },
  {
    firstName: "KRISHNA MURARI",
    lastName: "SINGH",
    address:
      "BABUSAHEB COLONY*NEAR BYAPAR MANDAL*LAHERIASARAI*DARBHANGA-846001*PAN NO-ALBPS3473R",
    fullName: "KRISHNA MURARI SINGH",
    saID: "SPR7956",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "CHOURASIA",
    address: "NIRWAD ROAD CARRIER*PO-RAXAUL*EAST CHAMPARAN-845305",
    fullName: "ASHOK KUMAR CHOURASIA",
    saID: "SPR7957",
  },
  {
    firstName: "SONA",
    lastName: "YADAV",
    address: "VILL-PAKRIYA*PO&DIST-CHATRA*JHARKHAND-825 401.",
    fullName: "SONA YADAV",
    saID: "SPR7958",
  },
  {
    firstName: "KANHAIYA PRASAD",
    lastName: "YADAV",
    address: "AT-SATSANG CENTRE,KORRAH*NEW COLONY*HAZARIBAG 825301",
    fullName: "KANHAIYA PRASAD YADAV",
    saID: "SPR7959",
  },
  {
    firstName: "DINESH PRASAD",
    lastName: "SHARMA",
    address:
      "S/O-LT PUNIT PD SHARMA*AT-LARHI*PO-GORIYAMI*DIST-KHAGARIA-851203*PAN-AQRPS0347R",
    fullName: "DINESH PRASAD SHARMA",
    saID: "SPR7960",
  },
  {
    firstName: "SURYA  NARAYAN",
    lastName: "PRASAD",
    address: "WORD NO-14*JAMALPUR GOGARI*DT-KHAGARIA",
    fullName: "SURYA  NARAYAN PRASAD",
    saID: "SPR7961",
  },
  {
    firstName: "MOHAN PRASAD",
    lastName: "YADAV",
    address:
      "SATSANG ADHIBESAN KENDRA*191,MANSI VIHAR,SECTOR-23*GHAZIABAD-201001*U.P*AJVPP8115H",
    fullName: "MOHAN PRASAD YADAV",
    saID: "SPR7962",
  },
  {
    firstName: "NARESH",
    lastName: "PRASAD",
    address: "KHARJAMBA*PO-MADHRA*NALANDA",
    fullName: "NARESH PRASAD",
    saID: "SPR7963",
  },
  {
    firstName: "KAUSHAL KUMAR",
    lastName: "SINGH",
    address: "SARVODAY NAGAR*DAM SIDE*KANKE ROAD*RANCHI",
    fullName: "KAUSHAL KUMAR SINGH",
    saID: "SPR7964",
  },
  {
    firstName: "ANAND",
    lastName: "KUMAR",
    address:
      "HILL TOP-2*NEAR M I G 150*ADITYAPUR 2*JAMSHEDPUR-831 013*PAN-AOKPK7436A",
    fullName: "ANAND KUMAR",
    saID: "SPR7965",
  },
  {
    firstName: "BISHESWAR",
    lastName: "RAY",
    address:
      "C/O SUDHIR KUMAR ROY*C-15 SECTOR-I AVANTI VIHAR*PO-RABIGRAM*RAIPUR 492006",
    fullName: "BISHESWAR RAY",
    saID: "SPR7966",
  },
  {
    firstName: "PHANI BHUSAN",
    lastName: "SAHA",
    address: "ANJALI NAGAR*PO-PAKHANJORE*KANKER*CHATTISGARH-494776",
    fullName: "PHANI BHUSAN SAHA",
    saID: "SPR7967",
  },
  {
    firstName: "KAMALA KANTA",
    lastName: "SAHOO",
    address: "AT/PO-DABUGAM*DIST-NABARANGPUR-764072*ODISHA",
    fullName: "KAMALA KANTA SAHOO",
    saID: "SPR7968",
  },
  {
    firstName: "YUDHISTHIR",
    lastName: "ROY",
    address: "SATSANG ASHRAM*AT/PO-SANTIR BAZAR*TRIPURA (S) 799144",
    fullName: "YUDHISTHIR ROY",
    saID: "SPR7969",
  },
  {
    firstName: "RATAN",
    lastName: "PAL",
    address:
      "C/O-JIRANIA SATSANG KENDRA*VILL/PO-BIRENDRA NAGAR*TRIPURA(W)-799045*PAN-AIXPP6206G",
    fullName: "RATAN PAL",
    saID: "SPR7970",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "SAHA",
    address:
      "C/O NARAYAN CH SAHA*RADHABAZAR,BAIDIK PARA*PO-NABADWIP*NADIA-741302*PAN-DVZPS4921P",
    fullName: "TAPAN KUMAR SAHA",
    saID: "SPR7971",
  },
  {
    firstName: "PRAVIN",
    lastName: "KUMAR",
    address: "KISHORILAL CHOWK*MADHUBANI 847211*BIHAR",
    fullName: "PRAVIN KUMAR",
    saID: "SPR7972",
  },
  {
    firstName: "DHRITISUNDAR",
    lastName: "LAL",
    address: "VILL-JOUNGIPUR*NAGARUNTARI*DIST-GARHWA*JHARKHAND",
    fullName: "DHRITISUNDAR LAL",
    saID: "SPR7973",
  },
  {
    firstName: "KRITISUNDAR",
    lastName: "LAL",
    address:
      "VILL-JOUNGIPUR*PO-NAGAR UNTARI*DIST-GARHWA*JHARKHAND*(PAN-ADUPL8238N)",
    fullName: "KRITISUNDAR LAL",
    saID: "SPR7974",
  },
  {
    firstName: "DEBASHISH",
    lastName: "SAHA",
    address: "PURBA PARA*HALDIBARI*DIST-COOCHBEHAR-735122",
    fullName: "DEBASHISH SAHA",
    saID: "SPR7975",
  },
  {
    firstName: "DEBASISH",
    lastName: "ROY",
    address: "PO-DEWANGANJ*COOCHBIHAR-735122",
    fullName: "DEBASISH ROY",
    saID: "SPR7976",
  },
  {
    firstName: "RAMANI KANTA",
    lastName: "ROY",
    address: "VILL-MAHERPUR*BOCHAGANJ*DINAJPUR*BANGLADESH",
    fullName: "RAMANI KANTA ROY",
    saID: "SPR7977",
  },
  {
    firstName: "BIRENDRA NATH",
    lastName: "BARMAN",
    address: "VILL-GANGANARAYAN*PO-KAUNIA*DIST-RONGPUR*BANGLADESH",
    fullName: "BIRENDRA NATH BARMAN",
    saID: "SPR7978",
  },
  {
    firstName: "SURESH CHANDRA",
    lastName: "RAY",
    address: "VILL-PARAMESWARIPUR*PS-BAHAROL*DINAJPUR*BANGLADESH",
    fullName: "SURESH CHANDRA RAY",
    saID: "SPR7979",
  },
  {
    firstName: "CHANDAN",
    lastName: "BHATTACHERJEE",
    address: "VILL-MATHPARA*PO-ALIBHAT*CHITTAGONG*BANGLADESH",
    fullName: "CHANDAN BHATTACHERJEE",
    saID: "SPR7980",
  },
  {
    firstName: "SUBRATA",
    lastName: "HORE",
    address: "PO-GOSSAINGAON*KOKRAJHAR 783 360*PAN AAJPH6336D",
    fullName: "SUBRATA HORE",
    saID: "SPR7981",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7982",
  },
  {
    firstName: "ARADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARADYUTI CHAKRABORTY",
    saID: "SPR7983",
  },
  {
    firstName: "REV DR ALOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOKE KUMAR CHAKRABORTY",
    saID: "SPR7984",
  },
  {
    firstName: "D",
    lastName: "MANDOL",
    address: "",
    fullName: "D MANDOL",
    saID: "SPR7985",
  },
  {
    firstName: "SUNIL BARAN",
    lastName: "MAITRA",
    address: "",
    fullName: "SUNIL BARAN MAITRA",
    saID: "SPR7986",
  },
  {
    firstName: "SUBRATA",
    lastName: "DEY",
    address:
      "S.D.M.O.& HEALTH OFFICER-I(I/C)*KATLICHARRA BLOCK P.H.E*HAILAKANDI*PAN-ACFPD5950P",
    fullName: "SUBRATA DEY",
    saID: "SPR7987",
  },
  {
    firstName: "KRISHNA MOHAN",
    lastName: "ASTHANA",
    address:
      "H.NO.49/10,MATBAR GANJ*BEHIND ARYASAMAJ MANDIR*AZAMGARH*U.P.276001.",
    fullName: "KRISHNA MOHAN ASTHANA",
    saID: "SPR7988",
  },
  {
    firstName: "BISHNU",
    lastName: "ROY",
    address: "",
    fullName: "BISHNU ROY",
    saID: "SPR7989",
  },
  {
    firstName: "DR SWARUP",
    lastName: "CHAKRABARTY",
    address:
      "SANTI KUNJA*BHOLAGIRI ASHRAM ROAD*RANGIRKHARI*PO/DIST-SILCHAR-788005*PAN-ADSPC3276D",
    fullName: "DR SWARUP CHAKRABARTY",
    saID: "SPR7990",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7991",
  },
  {
    firstName: "SANJIT KUMAR",
    lastName: "CHOUDHARY",
    address:
      "FH-484,SEC-F,DEEN DAYAL NAGAR*PO-GOLE KA MANDIR*NEAR NEW TEMPO STAND*GWALIOR 474005",
    fullName: "SANJIT KUMAR CHOUDHARY",
    saID: "SPR7992",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7993",
  },
  {
    firstName: "SUDARSAN",
    lastName: "DEY",
    address: "",
    fullName: "SUDARSAN DEY",
    saID: "SPR7994",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR7995",
  },
  {
    firstName: "RAJARSHI",
    lastName: "ROY",
    address:
      "SREEJIDHAM*FLAT NO-1203;PLOT NO-7*SECTOR-7-AIROLI*NAVI MUMBAI 400708*PAN-AFIPR1307L",
    fullName: "RAJARSHI ROY",
    saID: "SPR7996",
  },
  {
    firstName: "DEBSUNDAR",
    lastName: "SWAMI",
    address: "C/O-SWARAJ SWAMI*BISHNUPUR*SHILLONG*MEGHALAYA-793004",
    fullName: "DEBSUNDAR SWAMI",
    saID: "SPR7997",
  },
  {
    firstName: "SMRUTI RANJAN",
    lastName: "BHUYAN",
    address:
      "13, 1ST CROSS 1ST MAIN*KARNASHREE LAYOUT*PO-VIRGONAGAR*MEDAHALLI*BANGALORE 49",
    fullName: "SMRUTI RANJAN BHUYAN",
    saID: "SPR7998",
  },
  {
    firstName: "PRASANTA",
    lastName: "MISHRA",
    address: "F C I,B/273*PO-VIKRAMPUR*ANGUL 759106*PAN.AIRPM4978N",
    fullName: "PRASANTA MISHRA",
    saID: "SPR7999",
  },
  {
    firstName: "ANANTA CHARAN",
    lastName: "SAHOO",
    address: "AT-BAMADEIPUR*PO-ARISANDHA*PURI-752106",
    fullName: "ANANTA CHARAN SAHOO",
    saID: "SPR8000",
  },
  {
    firstName: "BISWARANJAN",
    lastName: "NAYAK",
    address: "AT-SARADA*PO-SUBALAYA*NAYAGARH-752091",
    fullName: "BISWARANJAN NAYAK",
    saID: "SPR8001",
  },
  {
    firstName: "BINAY KUMAR",
    lastName: "DAS",
    address:
      "AT-NEAR BAMRA HIGH SCHOOL*PO-BAMRA-768221*DIST-SAMBALPUR*PAN-AGWPD1980E",
    fullName: "BINAY KUMAR DAS",
    saID: "SPR8002",
  },
  {
    firstName: "JAYANTA KUMAR",
    lastName: "DAS",
    address:
      "C/O GAGAN CHANDRA DAS*AT/PO-BAMRA*SAMBALPUR-768221*PAN-BTMPD0536J",
    fullName: "JAYANTA KUMAR DAS",
    saID: "SPR8003",
  },
  {
    firstName: "N HIMANSHU",
    lastName: "CHOUDHURY",
    address:
      "3RD LANE BRAJANAGAR*BERHAMPUR*GANJAM-760001*ODISHA*PAN-AJHPC4542K",
    fullName: "N HIMANSHU CHOUDHURY",
    saID: "SPR8004",
  },
  {
    firstName: "TAPASH KUMAR",
    lastName: "NANDI",
    address: "D 29/14,DEBNATHPURA*VARANASI-221001*U.P.",
    fullName: "TAPASH KUMAR NANDI",
    saID: "SPR8005",
  },
  {
    firstName: "SANJAY  KUMAR",
    lastName: "MAHAKUR",
    address: "AT-ARIGAON*PO-SINDURPUR*DIST-SONEPUR*ODISHA",
    fullName: "SANJAY  KUMAR MAHAKUR",
    saID: "SPR8006",
  },
  {
    firstName: "HIMANSHU SEKHAR",
    lastName: "SAHU",
    address: "AT/PO-MURUSUAN*VIA-RAJNAGAR*KEONJHAR-758017*ODISHA",
    fullName: "HIMANSHU SEKHAR SAHU",
    saID: "SPR8007",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "DAS",
    address: "AT&PO-MAIDALPUR*VIA-PAPADAHANDI*DIST-NABARANGPUR",
    fullName: "PRADIP KUMAR DAS",
    saID: "SPR8008",
  },
  {
    firstName: "PRADIPTA KUMAR",
    lastName: "BEHERA",
    address:
      "C/O PARAMANANDA BEHERA*AT-BAHADAPITHA*PO-RANGANIPATANA*ODAGAON*NAYAGARH  752081.",
    fullName: "PRADIPTA KUMAR BEHERA",
    saID: "SPR8009",
  },
  {
    firstName: "ADHAR KUMAR",
    lastName: "MAHAPATRA",
    address: "QR NO C/88,SECTOR-1*ROURKELLA-769 008*DIST-SUNDARGARH*ODISHA",
    fullName: "ADHAR KUMAR MAHAPATRA",
    saID: "SPR8010",
  },
  {
    firstName: "RAJ KISHORE",
    lastName: "SINGH",
    address: "",
    fullName: "RAJ KISHORE SINGH",
    saID: "SPR8011",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRABORTY",
    saID: "SPR8012",
  },
  {
    firstName: "KRISHAN LAL",
    lastName: "AGARWAL",
    address: "",
    fullName: "KRISHAN LAL AGARWAL",
    saID: "SPR8013",
  },
  {
    firstName: "PUJANIYA ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA ARKADYUTI CHAKRABORTY",
    saID: "SPR8014",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8015",
  },
  {
    firstName: "CHIRANJIB",
    lastName: "MANDAL",
    address: "CENTRAL PARK*PO-SREEPALLY*DIST-BURDWAN-713103*PAN ADXPM7601D",
    fullName: "CHIRANJIB MANDAL",
    saID: "SPR8016",
  },
  {
    firstName: "REV DR ALOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOKE KUMAR CHAKRABORTY",
    saID: "SPR8017",
  },
  {
    firstName: "KRITI SUNDAR",
    lastName: "DEKA",
    address: "HOUSE NO-I,RIDDHI PATH*RUPNAGAR*GUWAHATI*KAMRUP 781032*ASSAM",
    fullName: "KRITI SUNDAR DEKA",
    saID: "SPR8018",
  },
  {
    firstName: "SANKAR KUMAR",
    lastName: "MANDAL",
    address:
      "SHREE RAM ESTATE,FL NO-10,BLOCK-IV*129,HO-CHIMINH SARANI*BEHALA*KOLKATA-700008",
    fullName: "SANKAR KUMAR MANDAL",
    saID: "SPR8019",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8020",
  },
  {
    firstName: "RAJESH",
    lastName: "MAKKAR",
    address: "B-398 NIRMAN VIHAR*DELHI 110092",
    fullName: "RAJESH MAKKAR",
    saID: "SPR8021",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8022",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8023",
  },
  {
    firstName: "LATE NARAYAN CHANDRA",
    lastName: "PAL",
    address: "1/I/11 MAHAVIR NAGAR,EXTENTION*KOTA*RAJASTHAN 354009.",
    fullName: "LATE NARAYAN CHANDRA PAL",
    saID: "SPR8024",
  },
  {
    firstName: "TUSHAR",
    lastName: "MAZUMDAR",
    address:
      "18-B,RUBANDHA SECTOR(B.S.P.)*BHILAI*DIST-DURG*CHHATISHGARH 490006.",
    fullName: "TUSHAR MAZUMDAR",
    saID: "SPR8025",
  },
  {
    firstName: "REV DR ALOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOKE KUMAR CHAKRABORTY",
    saID: "SPR8026",
  },
  {
    firstName: "NIRMAL CHANDRA",
    lastName: "MISTRY",
    address:
      "TALIYA FIELD*NEAR MAHARAJA NARENDRA SINGH DEGREE COLLEGE*PO PANNA*M.P.-488 001",
    fullName: "NIRMAL CHANDRA MISTRY",
    saID: "SPR8027",
  },
  {
    firstName: "P BABU",
    lastName: "RAO",
    address: "404 SILVER SHADE APT;ALKAPUR TOWNSHIP*MANIKONDA*HYDERABAD 500075",
    fullName: "P BABU RAO",
    saID: "SPR8028",
  },
  {
    firstName: "DINABANDHU",
    lastName: "SOREN",
    address: "D-97 GAIL VIHAR*SECTOR-23*NOIDA*U.P.201301*PAN-ACWPS6296G",
    fullName: "DINABANDHU SOREN",
    saID: "SPR8029",
  },
  {
    firstName: "JASABANT NARAYAN",
    lastName: "JENA",
    address:
      "19/3 MUTHUMANI ST*VARADARAJA (BACK SIDE M.COLLEGE)*KODAMBAKA*CHHENAI*PAN-ADXPJ1657L",
    fullName: "JASABANT NARAYAN JENA",
    saID: "SPR8030",
  },
  {
    firstName: "SATYASWARUP",
    lastName: "TRIPATHY",
    address:
      "ASSO PROF,DEPT OF PLASTIC SURGERY*NEHRU HOSPITAL,PGIMER*CHANDIGARH-12*(AEMPT8075Q)",
    fullName: "SATYASWARUP TRIPATHY",
    saID: "SPR8031",
  },
  {
    firstName: "DULAL",
    lastName: "SAHOO",
    address: "KAPIL CHANDRA SEED HOUSE*PO-NALHATI*BIRBHUM-731220",
    fullName: "DULAL SAHOO",
    saID: "SPR8032",
  },
  {
    firstName: "BIRBIVA",
    lastName: "GHOSH",
    address: "511,RABINDRA SARANI*PO-HATKHOLA*KOLKATA-700 005*(PAN-ADVPG2466L)",
    fullName: "BIRBIVA GHOSH",
    saID: "SPR8033",
  },
  {
    firstName: "MOHAN P",
    lastName: "KALAMKAR",
    address:
      "2,CHANDRABHAGA NIWAS*SARVODAY NAGAR,KULGAON*BADLAPUR(W)*DIST-THANE*M.S.",
    fullName: "MOHAN P KALAMKAR",
    saID: "SPR8034",
  },
  {
    firstName: "RAMLAKHAN PRASAD",
    lastName: "SARMA",
    address: "ADVOCATE,SRIKRISHNA NAGAR*MOTIHARI*EASTCHAMPARAN 845401",
    fullName: "RAMLAKHAN PRASAD SARMA",
    saID: "SPR8035",
  },
  {
    firstName: "UMESH",
    lastName: "PRASAD",
    address: "C/O PUNAY MAHATO*VILL/PO-AMBARI*DIST-SHEKHAPURA 811 103",
    fullName: "UMESH PRASAD",
    saID: "SPR8036",
  },
  {
    firstName: "AJAY",
    lastName: "MISHRA",
    address: "B-495, NEW ASHOK NAGAR*DELHI 110096*PAN-AGBPM4398J",
    fullName: "AJAY MISHRA",
    saID: "SPR8037",
  },
  {
    firstName: "TAPAN",
    lastName: "KARMAKAR",
    address:
      "MOHAN TULSI VIHAR;A I 404 4TH FLOOR*HENDREPADA*KULGAON-421503*BADLAPUR W*ALQPK8904N",
    fullName: "TAPAN KARMAKAR",
    saID: "SPR8038",
  },
  {
    firstName: "BAIKUNTHA BIHARI",
    lastName: "SAHOO",
    address:
      "D-302 AHIMSA TERRACE*AHIMSA MARG;OPP SUBKUCH MARKET*OFF LINK RD*MALAD W *MUMBAI 64",
    fullName: "BAIKUNTHA BIHARI SAHOO",
    saID: "SPR8039",
  },
  {
    firstName: "TUSHAR KANTI",
    lastName: "CHANDA",
    address: "CHHOTA ATTERMURA*RAIGARH-496001*CHHATISHGARH*PAN - AFGPC4448H",
    fullName: "TUSHAR KANTI CHANDA",
    saID: "SPR8040",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "MOHANTY",
    address: "604,MAHANADI VIHAR*NAYABAZAR*CUTTACK-4",
    fullName: "PRAFULLA KUMAR MOHANTY",
    saID: "SPR8041",
  },
  {
    firstName: "PRAFULLA KUMAR",
    lastName: "BHADRA",
    address:
      "JAMMU SATSANG ADHIBESHAN KENDRA*H.NO-51,DEEP NAGAR*PO-GANGYAL*JAMMU    & KASHMIR",
    fullName: "PRAFULLA KUMAR BHADRA",
    saID: "SPR8042",
  },
  {
    firstName: "BIPIN BIHARI",
    lastName: "MISHRA",
    address:
      "C-201 SHARAN SAPPHIRE*MOTERA KOTESWAR ROAD*MOTERA*AHMEDABAD 380005*PAN .ALBPM6064A",
    fullName: "BIPIN BIHARI MISHRA",
    saID: "SPR8043",
  },
  {
    firstName: "DHISHNU KUMAR",
    lastName: "SAU",
    address:
      "MITRA COMPOUND*STATION RD,WARD NO-23,H.N-5*MIDNAPUR SADAR KOTWALI*PASCHIM MEDINIPUR",
    fullName: "DHISHNU KUMAR SAU",
    saID: "SPR8044",
  },
  {
    firstName: "DEBDULAL",
    lastName: "PRADHAN",
    address:
      "VILL-KALYANPUR*PO-BARIDA(EGRA)*PURBA MEDINIPUR-721429*PAN ARBPP7O44H",
    fullName: "DEBDULAL PRADHAN",
    saID: "SPR8045",
  },
  {
    firstName: "GORACHAND",
    lastName: "SAU",
    address:
      "BAISHNABCHAK M C HIGH SCHOOL*PO-BAISHNABCHAK-721158*PURBA MEDINIPUR",
    fullName: "GORACHAND SAU",
    saID: "SPR8046",
  },
  {
    firstName: "PRANAB",
    lastName: "KUNDU",
    address: "BERPARA KALAMDARI MORE*PO-SANTIPUR*NADIA*PAN-AZIPK6164C",
    fullName: "PRANAB KUNDU",
    saID: "SPR8047",
  },
  {
    firstName: "JOYDEB",
    lastName: "GHOSH",
    address: "VILL-KAMDEBPUR*PO-MIRHATI*24PARGANAS NORTH",
    fullName: "JOYDEB GHOSH",
    saID: "SPR8048",
  },
  {
    firstName: "HRISINDHU",
    lastName: "DUTTACHOUDHURY",
    address: "LAXMI BAZAR RD*KARIMGANJ 788710*ASSAM",
    fullName: "HRISINDHU DUTTACHOUDHURY",
    saID: "SPR8049",
  },
  {
    firstName: "DILLIRAM",
    lastName: "SARMA",
    address: "C/O TELEPHONE EXCHANGE POWER DEPTT*PO-RANIPOOL*SIKKIM 737135",
    fullName: "DILLIRAM SARMA",
    saID: "SPR8050",
  },
  {
    firstName: "SIBA PADA",
    lastName: "DAS",
    address: "",
    fullName: "SIBA PADA DAS",
    saID: "SPR8051",
  },
  {
    firstName: "KALYAN CHAND",
    lastName: "CHAMAN",
    address: "H NO-1221 UNIVERSAL ENCLAVE*SECTOR 48B*CHANDIGARH 160047",
    fullName: "KALYAN CHAND CHAMAN",
    saID: "SPR8052",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8053",
  },
  {
    firstName: "NARAYAN",
    lastName: "BHATTACHERJEE",
    address: "",
    fullName: "NARAYAN BHATTACHERJEE",
    saID: "SPR8054",
  },
  {
    firstName: "KANAK KANTI",
    lastName: "DAWN",
    address: "",
    fullName: "KANAK KANTI DAWN",
    saID: "SPR8055",
  },
  {
    firstName: "MALAYAJ",
    lastName: "DAS",
    address:
      "25,VIMALSHREE RESIDENCY*BRAJESWARI MAIN*INDORE-452016*PAN-BAJPD7482D",
    fullName: "MALAYAJ DAS",
    saID: "SPR8056",
  },
  {
    firstName: "PARITOSH",
    lastName: "SARKAR",
    address: "",
    fullName: "PARITOSH SARKAR",
    saID: "SPR8057",
  },
  {
    firstName: "RATHIN",
    lastName: "PARAMANIK",
    address: "",
    fullName: "RATHIN PARAMANIK",
    saID: "SPR8058",
  },
  {
    firstName: "RATHIN",
    lastName: "PARAMANIK",
    address: "",
    fullName: "RATHIN PARAMANIK",
    saID: "SPR8059",
  },
  {
    firstName: "ATINDRA RAJ",
    lastName: "MOHAN",
    address: "S/O ATUL CHANDRA BARUA*PO-SEPON*DIST-SIBSAGAR-785673*ASSAM",
    fullName: "ATINDRA RAJ MOHAN",
    saID: "SPR8060",
  },
  {
    firstName: "SUDHA RANJAN",
    lastName: "DEBNATH",
    address: "SORASHI BHAWAN*PO-SATSANG*DEOGHAR 814116*PAN NO.AMXPD3607K",
    fullName: "SUDHA RANJAN DEBNATH",
    saID: "SPR8061",
  },
  {
    firstName: "DHRITI SUNDAR",
    lastName: "DUTTA",
    address: "SATSANG VIHAR*GUWAHATI, BHANGAGARH*KAMRUP-781005.",
    fullName: "DHRITI SUNDAR DUTTA",
    saID: "SPR8062",
  },
  {
    firstName: "KRISHNA JIBAN",
    lastName: "DAS",
    address:
      "VILL-BHAGALPUR*PO-JADUPUR*PS-KALIACHAK*MALDA 732212*PAN.NO.AGAPD1292R",
    fullName: "KRISHNA JIBAN DAS",
    saID: "SPR8063",
  },
  {
    firstName: "SANJIB",
    lastName: "DAS",
    address: "C/O-GOPAL DAS*BARASAT*24PARGANAS(N)",
    fullName: "SANJIB DAS",
    saID: "SPR8064",
  },
  {
    firstName: "ABIN",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ABIN CHAKRABORTY",
    saID: "SPR8065",
  },
  {
    firstName: "DEEPANAND",
    lastName: "PRASAD",
    address: "AT/PO-SATSANG*DEOGHAR-814116*JHARKHAND",
    fullName: "DEEPANAND PRASAD",
    saID: "SPR8066",
  },
  {
    firstName: "DEBASHIS",
    lastName: "BHATTACHARJEE",
    address: "59/8, JORABAGAN ROAD*KOLKATA-700047*PAN-ADJPB8647C",
    fullName: "DEBASHIS BHATTACHARJEE",
    saID: "SPR8067",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8068",
  },
  {
    firstName: "DHRITABRATA",
    lastName: "NARZARY",
    address: "VILL-KUMGURI*PO-SERFANGURI*DIST-KOKRAJHAR-783346*PAN-AFOPN8182R.",
    fullName: "DHRITABRATA NARZARY",
    saID: "SPR8069",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "ROY",
    address: "VILL-BARAKHASHUA*PO-SRI NAGAR(HATIDHURA)*KOKRAJHAR-783332.",
    fullName: "TAPAN KUMAR ROY",
    saID: "SPR8070",
  },
  {
    firstName: "SUSHANTA",
    lastName: "NARJARI",
    address:
      "MAIBANG NAGAR*NEAR COMMERCE COLLEGE*WARD NO-5*KOKRAJHAR*PAN.ABQPN3823K",
    fullName: "SUSHANTA NARJARI",
    saID: "SPR8071",
  },
  {
    firstName: "DIPAK",
    lastName: "DEKA",
    address:
      "G N B M HIGH SCHOOL*DABAR CHUPA*PO-SARTHEBARI*DIST-BARPETA*ASSAM 781307",
    fullName: "DIPAK DEKA",
    saID: "SPR8072",
  },
  {
    firstName: "KANU",
    lastName: "GHOSH",
    address: "68,SURYA SEN STREET*KOLKATA-9",
    fullName: "KANU GHOSH",
    saID: "SPR8073",
  },
  {
    firstName: "BRAJA MOHAN",
    lastName: "PANDA",
    address: "FLAT NO.OM RESIDENCY HANSPAL*BALIANTA*BHUBANESWAR 752101",
    fullName: "BRAJA MOHAN PANDA",
    saID: "SPR8074",
  },
  {
    firstName: "GOPA BANDHU",
    lastName: "PATRA",
    address: "AT-KOTAPUR*PO-P N PUR*JAJPUR 755011*PAN.AIQPP1408D",
    fullName: "GOPA BANDHU PATRA",
    saID: "SPR8075",
  },
  {
    firstName: "SURENDRA NATH",
    lastName: "SATAPATHY",
    address: "AT&PO-HARICHANDANPUR*VIA-GAMBHARIMUNDA*KHURDA-7520355",
    fullName: "SURENDRA NATH SATAPATHY",
    saID: "SPR8076",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "LAHA",
    address: "VILL&PO-RAMSAGAR*BANKURA-722147*PAN NO.AAVPL4431B",
    fullName: "PRADIP KUMAR LAHA",
    saID: "SPR8077",
  },
  {
    firstName: "BINOY",
    lastName: "MANDAL",
    address:
      "HEMANTA POLLY*IN FRONT OF BASE SOAP FACTORY*AT/PO-SAINTHIA*BIRBHUM 731234",
    fullName: "BINOY MANDAL",
    saID: "SPR8078",
  },
  {
    firstName: "GOUR PADA",
    lastName: "DUTTA",
    address:
      "C/O KARTIK CHANDRA DUTTA*AT-BIRESH PALLY*PO-MADHYAMGRAM*24PARGANAS (NORTH)-700129",
    fullName: "GOUR PADA DUTTA",
    saID: "SPR8079",
  },
  {
    firstName: "BASAB BARAN",
    lastName: "SARDAR",
    address:
      "C/O BIBHUTI BHUSAN SARDER*VILL/PO-SREEKRISHNANAGAR*24PARGANAS SOUTH-743372",
    fullName: "BASAB BARAN SARDAR",
    saID: "SPR8080",
  },
  {
    firstName: "BIBHU DATTA",
    lastName: "GURU",
    address:
      "C/O SAROJ KUMAR GURU*SATSANG VIHAR,NUAPADA*PO/DIST-NUAPADA 766105*ODISHA",
    fullName: "BIBHU DATTA GURU",
    saID: "SPR8081",
  },
  {
    firstName: "BIPAD TARAN",
    lastName: "PAL",
    address: "SATSANG VIHAR SONAMUKHI*BANKURA-722207",
    fullName: "BIPAD TARAN PAL",
    saID: "SPR8082",
  },
  {
    firstName: "SANJIB KUMAR",
    lastName: "PATRA",
    address:
      "VILL-SOUTH MONOHARCHAK*BANKIM SARANI ROAD*PO-CONTAI-721401*PURBA MEDINIPUR",
    fullName: "SANJIB KUMAR PATRA",
    saID: "SPR8083",
  },
  {
    firstName: "MUKTI PADA",
    lastName: "DASH",
    address: "VILL/PO-HAIPUR*PS-CONTAI*DIST-PURBA MEDINIPUR-721433",
    fullName: "MUKTI PADA DASH",
    saID: "SPR8084",
  },
  {
    firstName: "ANUP KUMAR",
    lastName: "SARDAR",
    address: "PO/VILL-NARIDANA*CHAMPAHATI*PS-BARUIPUR*24PARGANAS (SOUTH)743330",
    fullName: "ANUP KUMAR SARDAR",
    saID: "SPR8085",
  },
  {
    firstName: "NEMAI",
    lastName: "BOSE",
    address: "VILL&PO-KODALIA*24PARGANAS (SOUTH)-700146.",
    fullName: "NEMAI BOSE",
    saID: "SPR8086",
  },
  {
    firstName: "SUBHASH",
    lastName: "SUTRADHAR",
    address: "WARD NO 10 FOREST COLONY*PO-BARPETA ROAD*BARPETA*ASSAM 781315",
    fullName: "SUBHASH SUTRADHAR",
    saID: "SPR8087",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "PANDA",
    address:
      "QR NO-17/5 ,TYPE-3*WEST LAND*ORDNANCE FACTORY KHAMARIA*JABALPUR*M.P.-482 005",
    fullName: "HEMANTA KUMAR PANDA",
    saID: "SPR8088",
  },
  {
    firstName: "RASHMI RANJAN",
    lastName: "JENA",
    address: "AT-BADHIASAHI*PO-KASARDA*CUTTACK-754105*PAN-AEZPJ4223P",
    fullName: "RASHMI RANJAN JENA",
    saID: "SPR8089",
  },
  {
    firstName: "SISIR KUMAR",
    lastName: "DAS",
    address: "AT-MANGARAJPUR*PO-BATIRA*RAHAMA*KENDRAPARA",
    fullName: "SISIR KUMAR DAS",
    saID: "SPR8090",
  },
  {
    firstName: "SASHIKANTA",
    lastName: "NAYAK",
    address: "AT&PO-KORUA*VIA-TYANDAKURA*KENDRAPARA*PAN-ACQPN2257C",
    fullName: "SASHIKANTA NAYAK",
    saID: "SPR8091",
  },
  {
    firstName: "SANTOSH KUMAR",
    lastName: "DASH",
    address: "NEAR SUB JAIL*MALKANGIRI-764045*ODISHA",
    fullName: "SANTOSH KUMAR DASH",
    saID: "SPR8092",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "SAMAL",
    address: "QR NO 2RP,385*AT&PO-BALIMELA*MALKANGIRI-764051",
    fullName: "KAILASH CHANDRA SAMAL",
    saID: "SPR8093",
  },
  {
    firstName: "DHIRAJ KUMAR",
    lastName: "SANA",
    address: "AT-M.V.98*PO-CHITRANGA PALLY*MALKANGIRI-764086.",
    fullName: "DHIRAJ KUMAR SANA",
    saID: "SPR8094",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "RAY",
    address:
      "M P V-74 NALA GUNTI*PO-M V-72*DIST-MALKANGIRI-764047*ODISHA*PAN-ARJPR7978J",
    fullName: "DILIP KUMAR RAY",
    saID: "SPR8095",
  },
  {
    firstName: "NARASINGH",
    lastName: "GURUPATTANAIK",
    address: "AT-GUNUPUR*GRAMADEVTI TEMPLE*PO-GUNUPUR*RAYAGADA*PAN-BBRPP5208C",
    fullName: "NARASINGH GURUPATTANAIK",
    saID: "SPR8096",
  },
  {
    firstName: "PARIMAL",
    lastName: "MANDAL",
    address: "VILL-M V.75*PO-MANYAM KUNDA*MALKANGIRI-764047.*PAN NO.AZKPM3229L",
    fullName: "PARIMAL MANDAL",
    saID: "SPR8097",
  },
  {
    firstName: "BINAY KUMAR",
    lastName: "BISWAL",
    address: "AT-NARADA*PO-TURINTIRA*VIA-BALIPATNA*DIST-KHURDA-752 102",
    fullName: "BINAY KUMAR BISWAL",
    saID: "SPR8098",
  },
  {
    firstName: "ANTARYAMI",
    lastName: "SAHOO",
    address:
      "C/O BHAGIRATHI SAHOO*AT-BAJAPUR*PO-K.N.PATNA*VIA-KAKATPUR*DIST-PURI",
    fullName: "ANTARYAMI SAHOO",
    saID: "SPR8099",
  },
  {
    firstName: "ABHIJIT",
    lastName: "ROY",
    address:
      "BENGALI PARA*GIRL'S HIGH SCHOOL ROAD*JHARSUGUDA-768201*PAN-AOGPR9060P",
    fullName: "ABHIJIT ROY",
    saID: "SPR8100",
  },
  {
    firstName: "SANTANU",
    lastName: "MAHATO",
    address:
      "C/O BHISMAPADA MAHATO*SONAMUKHI SIDDHANTA PARA*DIST-BANKURA-722207",
    fullName: "SANTANU MAHATO",
    saID: "SPR8101",
  },
  {
    firstName: "MANAS",
    lastName: "KARMAKAR",
    address: "SATSANG VIHAR SONAMUKHI*BANKURA-722207",
    fullName: "MANAS KARMAKAR",
    saID: "SPR8102",
  },
  {
    firstName: "SANJIB",
    lastName: "PATRA",
    address:
      "JAYANTI APARTMENT*7 SECOND STREET*MODERN PARK*SANTOSHPUR*CALCUTTA 75",
    fullName: "SANJIB PATRA",
    saID: "SPR8103",
  },
  {
    firstName: "NANDADULAL",
    lastName: "JANA",
    address: "VILL-KALYANPUR*PO-BARIDA*VIA-EGRA*PURBA MIDNAPUR*PAN-AEBPJ9114R",
    fullName: "NANDADULAL JANA",
    saID: "SPR8104",
  },
  {
    firstName: "MANAS",
    lastName: "SARKAR",
    address:
      "C/O-LT RAJANI KANTA SARKAR*NORTH KRISHNAPALLY*TARU KUNJA*MALDA-732101.",
    fullName: "MANAS SARKAR",
    saID: "SPR8105",
  },
  {
    firstName: "SATYAJIT",
    lastName: "MANDAL",
    address: "VILL-PIALI KHOLAGHATA*PO-KALARIA,BARUIPUR*24PGS SOUTH",
    fullName: "SATYAJIT MANDAL",
    saID: "SPR8106",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "MANDAL",
    address:
      "VILL-NORTH TALDI(STATION PARA)*PO-TALDI*PS-CANNING*24 PARGANAS (SOUTH).",
    fullName: "SWAPAN KUMAR MANDAL",
    saID: "SPR8107",
  },
  {
    firstName: "BAPPADITYA",
    lastName: "SARDAR",
    address: "VILL&PO-RAMGOPALPUR*PS-BARUIPUR*24 PGS(SOUTH)",
    fullName: "BAPPADITYA SARDAR",
    saID: "SPR8108",
  },
  {
    firstName: "PARESH CHANDRA",
    lastName: "BISWAS",
    address: "C/O-AJIT KUMAR BAIRAGI*MEMARI*UDAYPALLY*BURDWAN-713146",
    fullName: "PARESH CHANDRA BISWAS",
    saID: "SPR8109",
  },
  {
    firstName: "AKRABYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKRABYUTI CHAKRABORTY",
    saID: "SPR8110",
  },
  {
    firstName: "RUHIDAS",
    lastName: "MONDAL",
    address: "VILL-RUPRAMPUR*PO-THUKRA*DIST-KHULNA*BANGLADESH",
    fullName: "RUHIDAS MONDAL",
    saID: "SPR8111",
  },
  {
    firstName: "ALOKE KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ALOKE KUMAR CHAKRABORTY",
    saID: "SPR8112",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8113",
  },
  {
    firstName: "QARADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "QARADYUTI CHAKRABORTY",
    saID: "SPR8114",
  },
  {
    firstName: "ARASYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARASYUTI CHAKRABORTY",
    saID: "SPR8115",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "JHA",
    address:
      "VILL-NETAJI PALLY*PO-ISLAMPUR*UTTAR DINAJPUR 733202*PAN-AMSPJ5238R.",
    fullName: "ARUN KUMAR JHA",
    saID: "SPR8116",
  },
  {
    firstName: "KAMAL",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "KAMAL CHAKRABORTY",
    saID: "SPR8117",
  },
  {
    firstName: "HARIPADA",
    lastName: "DATTA",
    address:
      "24 OFFICE LANE*PO--AGARTALA*TRIPURA WEST 799001*PAN NO.ACIPD1672K",
    fullName: "HARIPADA DATTA",
    saID: "SPR8118",
  },
  {
    firstName: "DYUTIDIBYA",
    lastName: "DAS",
    address: "VILL/PO-BINJHARPUR*DIST-JAJPUR-755004*PAN-AOIPD7030D",
    fullName: "DYUTIDIBYA DAS",
    saID: "SPR8119",
  },
  {
    firstName: "DHARA",
    lastName: "PRASAD",
    address: "",
    fullName: "DHARA PRASAD",
    saID: "SPR8120",
  },
  {
    firstName: "AKRADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "AKRADYUTI CHAKRABORTY",
    saID: "SPR8121",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8122",
  },
  {
    firstName: "DHRITI SUNDAR",
    lastName: "CHANDA",
    address:
      "202 B WING,SHALOM GARDEN*BEVERLY PARK,KANAKIA*MIRA ROAD(E)*THANE 7 PAN.AHMPC4903F",
    fullName: "DHRITI SUNDAR CHANDA",
    saID: "SPR8123",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "SARKAR",
    address: "PO-MOHANGANJ*DIST-NETRAKONA*BANGLADESH",
    fullName: "NARAYAN CHANDRA SARKAR",
    saID: "SPR8124",
  },
  {
    firstName: "MOTINDRA CHANDRA",
    lastName: "DAS",
    address: "VIIL-DOULATPUR*PO-MOHONGANJ*DIST-NETRAKONA*BANGLADESH",
    fullName: "MOTINDRA CHANDRA DAS",
    saID: "SPR8125",
  },
  {
    firstName: "ARABINDA",
    lastName: "CHOWDHURY",
    address:
      "VILL-KULIAR CHAR DASPARA*PO-KULIARCHAR*DIST-KISHOREGANJ*BANGLADESH",
    fullName: "ARABINDA CHOWDHURY",
    saID: "SPR8126",
  },
  {
    firstName: "SUDHANYA KUMAR",
    lastName: "KARMAKAR",
    address: "AT/PO-KHEPUPARA*PATUAKHALI*BANGLADESH",
    fullName: "SUDHANYA KUMAR KARMAKAR",
    saID: "SPR8127",
  },
  {
    firstName: "DHIRAJ MOHAN",
    lastName: "SHARMA",
    address:
      "VILL-GABOR BUALI*PO-CHARJITHAR*PS-ISWARGANJ*MYMENSINGH*BANGLADESH",
    fullName: "DHIRAJ MOHAN SHARMA",
    saID: "SPR8128",
  },
  {
    firstName: "ASISH",
    lastName: "CHOUDHURY",
    address: "126,HAZARI LANE*CHITTAGONG SATSANG KENDRA*CHITTAGONG*BANGLADESH",
    fullName: "ASISH CHOUDHURY",
    saID: "SPR8129",
  },
  {
    firstName: "SANJAY KUMAR",
    lastName: "CHOUDHURY",
    address: "VILL-DIAKUL SATSANGPARA*PO-BAJALIA*DIST-CHITTAGONG*BANGLADESH",
    fullName: "SANJAY KUMAR CHOUDHURY",
    saID: "SPR8130",
  },
  {
    firstName: "UTPAL",
    lastName: "BASU",
    address: "NORTH BAGURA RD*NATUN BAZAR, BARISHAL SADAR*BANGLADESH.  .",
    fullName: "UTPAL BASU",
    saID: "SPR8131",
  },
  {
    firstName: "PARIMAL CHANDRA",
    lastName: "MONDAL",
    address: "SATSANG VIHAR,KHULNA*BANGLADESH",
    fullName: "PARIMAL CHANDRA MONDAL",
    saID: "SPR8132",
  },
  {
    firstName: "RATAN KANTI",
    lastName: "PALIT",
    address: "VILL-SULTANPUR*PO-FAKIRHAT*PS-RAOJAN*CHITTAGONG*BANGLADESH",
    fullName: "RATAN KANTI PALIT",
    saID: "SPR8133",
  },
  {
    firstName: "KAJAL KANTI",
    lastName: "DAS",
    address: "C/O BAIBITAN*BAGHMARA*PO/DIST-BANDARBAN*BANGLADESH",
    fullName: "KAJAL KANTI DAS",
    saID: "SPR8134",
  },
  {
    firstName: "DIPANKAR",
    lastName: "TALUKDAR",
    address:
      "VILL-PURBACHILA*PO-PASCHIM CHILA*PS-AMTALI*DIST-BORGUNA*BANGLADESH",
    fullName: "DIPANKAR TALUKDAR",
    saID: "SPR8135",
  },
  {
    firstName: "BIJAN KUMAR",
    lastName: "HALDAR",
    address: "BUET GIRL'S SCHOOL*BUET CAMPUS*DIST-DHAKA*BANGLADESH",
    fullName: "BIJAN KUMAR HALDAR",
    saID: "SPR8136",
  },
  {
    firstName: "UPANANDA",
    lastName: "DEB",
    address: "JAKANDI*PO-DASBAHAPIA*DIST-MOULAVI BAZAR *BANGLADESH",
    fullName: "UPANANDA DEB",
    saID: "SPR8137",
  },
  {
    firstName: "BHAGENDRA NATH",
    lastName: "SEALSARMA",
    address: "VILL-DHANGAON*PO-DHIRGANJ*DIST-THAKURGAON*BANGLADESH",
    fullName: "BHAGENDRA NATH SEALSARMA",
    saID: "SPR8138",
  },
  {
    firstName: "SAJAL KUMAR",
    lastName: "SIKARY",
    address: "136/A,RAKHER BAZAR*BINMANDI*DIST-DHAKA*BANGLADESH",
    fullName: "SAJAL KUMAR SIKARY",
    saID: "SPR8139",
  },
  {
    firstName: "DHARANI KUMAR",
    lastName: "ROY",
    address: "VILL-SEN GRAM*PO-SANAKA*DIST-DINAJPUR*BANGLADESH",
    fullName: "DHARANI KUMAR ROY",
    saID: "SPR8140",
  },
  {
    firstName: "NISHIKANTA",
    lastName: "DAS",
    address: "JASHER ABDA*PO/DIST-HABIGANJ*BANGLADESH",
    fullName: "NISHIKANTA DAS",
    saID: "SPR8141",
  },
  {
    firstName: "NIMAI CHANDRA",
    lastName: "BARMAN",
    address: "VILL-DAULATPUR*PO-MORDAKOMARPUR*DIST-GAIBANDHA*BANGLADESH",
    fullName: "NIMAI CHANDRA BARMAN",
    saID: "SPR8142",
  },
  {
    firstName: "MILANKANTI",
    lastName: "DUTTA",
    address: "VILL/PO-CHOFALDANDI*DIST-COXBAZAR*BANGLADESH",
    fullName: "MILANKANTI DUTTA",
    saID: "SPR8143",
  },
  {
    firstName: "BINAY KUMAR",
    lastName: "ROY",
    address: "VILL-KALCHA*PO-AMGAON*DIST-THAKURGAON*BANGLADESH",
    fullName: "BINAY KUMAR ROY",
    saID: "SPR8144",
  },
  {
    firstName: "RANABIR",
    lastName: "DEBROY",
    address: "VILL-PASCHIM BHAG*PO-SHIBAPASA*DIST-HABIGANJ*BANGLADESH",
    fullName: "RANABIR DEBROY",
    saID: "SPR8145",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "MISTRY",
    address: "KACHUA*BAGERHAT*BANGLADESH",
    fullName: "TAPAN KUMAR MISTRY",
    saID: "SPR8146",
  },
  {
    firstName: "REV ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ARKADYUTI CHAKRAVARTY",
    saID: "SPR8147",
  },
  {
    firstName: "SUSANTA KUMAR",
    lastName: "SATAPATHY",
    address: "AT/PO-AMBADOLA*DIST-RAYAGADA*ODISHA*PAN-BYGPS5538G",
    fullName: "SUSANTA KUMAR SATAPATHY",
    saID: "SPR8148",
  },
  {
    firstName: "SUHAG RANJAN",
    lastName: "KHAMARI",
    address: "SATSANG VIHAR*BHAWANIPATNA*KALAHANDI-766001*PAN-ALXPK2605H",
    fullName: "SUHAG RANJAN KHAMARI",
    saID: "SPR8149",
  },
  {
    firstName: "CHANDRAKANTA",
    lastName: "OJHA",
    address: "AT-OSALANGA*PO-KOTANGA*VIA-BANGURIGAN*DIST-PURI",
    fullName: "CHANDRAKANTA OJHA",
    saID: "SPR8150",
  },
  {
    firstName: "DIPAK",
    lastName: "DAS",
    address:
      "CHOUDHURYPARA*NEAR KURIPUKUR*PO/PS-AMATALI*BISHALGARH*TRIPURA WEST",
    fullName: "DIPAK DAS",
    saID: "SPR8151",
  },
  {
    firstName: "NABAKUMAR",
    lastName: "HAZRA",
    address: "DEBPUR*PO-RADHAKANTAPUR*BURDWAN",
    fullName: "NABAKUMAR HAZRA",
    saID: "SPR8152",
  },
  {
    firstName: "ARUP",
    lastName: "DAS",
    address: "64/4 GARFA MAIN ROAD*KOL-75",
    fullName: "ARUP DAS",
    saID: "SPR8153",
  },
  {
    firstName: "SANJIB",
    lastName: "SAMANTA",
    address: "VILL&PO-KOTRA*SHYAMPUR*HOWRAH*PAN GKTPS1005L",
    fullName: "SANJIB SAMANTA",
    saID: "SPR8154",
  },
  {
    firstName: "SUKUMAR",
    lastName: "BERA",
    address:
      "VILL/PO-KISHMATSHIBRAMNAGAR*HALDIA*DIST-PURBA MEDINIPUR*PAN-BDSPB2987F",
    fullName: "SUKUMAR BERA",
    saID: "SPR8155",
  },
  {
    firstName: "GOURHARI",
    lastName: "BISWAS",
    address: "C/O-RAM RANJAN BISWAS*VILL&PO-TEHATTA*NADIA*PAN-AWQPB0458H",
    fullName: "GOURHARI BISWAS",
    saID: "SPR8156",
  },
  {
    firstName: "SAJAL KUMAR",
    lastName: "MUKHERJEE",
    address: "PO-KANYANAGAR*24PARGANAS SOUTH-743398",
    fullName: "SAJAL KUMAR MUKHERJEE",
    saID: "SPR8157",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "SINGH",
    address: "BARIYACHAK*DALTANGANJ*PALAMOU-822101",
    fullName: "ANIL KUMAR SINGH",
    saID: "SPR8158",
  },
  {
    firstName: "JITENDRA PRASAD",
    lastName: "BHUKTA",
    address: "",
    fullName: "JITENDRA PRASAD BHUKTA",
    saID: "SPR8159",
  },
  {
    firstName: "K M",
    lastName: "ROY",
    address: "",
    fullName: "K M ROY",
    saID: "SPR8160",
  },
  {
    firstName: "HAREN CHANDRA",
    lastName: "SARMA",
    address: "KALACHAND HIGH SCHOOL*PO-KHEJURBAND*DIMA HASAO-788834*ASSAM",
    fullName: "HAREN CHANDRA SARMA",
    saID: "SPR8161",
  },
  {
    firstName: "PINTU",
    lastName: "GHOSH",
    address: "VILL-CHANDPUR*PO-KHAYERPUR*PS-EAST AGARTALA*TRIPURA WEST-799008",
    fullName: "PINTU GHOSH",
    saID: "SPR8162",
  },
  {
    firstName: "REV ARKRADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKRADYUTI CHAKRABORTY",
    saID: "SPR8163",
  },
  {
    firstName: "ARJADYUTTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARJADYUTTI CHAKRABORTY",
    saID: "SPR8164",
  },
  {
    firstName: "SIDDHARTHA",
    lastName: "DEY",
    address:
      "FLAT NO-10 ANANYA APT*28 JAHURA BAZAR LANE*PO-&P.S. KASBA*KOLKATA-42*PAN-AGDPD8642C",
    fullName: "SIDDHARTHA DEY",
    saID: "SPR8165",
  },
  {
    firstName: "SIDHARTHA SHANKAR",
    lastName: "BASA",
    address:
      "SATSANG VIHAR BAHARAGORA*AT&PO-BAHARAGORA*EAST SINGHBHUM 832101*PAN-AIBPB7928H",
    fullName: "SIDHARTHA SHANKAR BASA",
    saID: "SPR8166",
  },
  {
    firstName: "HARIPADA",
    lastName: "GHOSH",
    address: "VILL-CHANPUR*PO-KHAYERPUR*TRIPURA WEST 799001.",
    fullName: "HARIPADA GHOSH",
    saID: "SPR8167",
  },
  {
    firstName: "REV ASHOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV ASHOK CHAKRAVARTY",
    saID: "SPR8168",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRAVARTY",
    saID: "SPR8169",
  },
  {
    firstName: "ATANU",
    lastName: "BAG",
    address: "BAHUMALI COMPLEX*SILVASSA*DADRA & NAGAR HAVELI",
    fullName: "ATANU BAG",
    saID: "SPR8170",
  },
  {
    firstName: "JAY M",
    lastName: "RAY",
    address:
      "NEEL SANKUL CHS;BLDG-2 R N.402*SECTOR 11 KALAMBALI*NAVI MUMBAI 410218*PAN.AHUPR346L",
    fullName: "JAY M RAY",
    saID: "SPR8171",
  },
  {
    firstName: "JAYANTA",
    lastName: "PARIDA",
    address:
      "SATSANG BHAWAN*CS-2/2,THAKURBADI*NAIGAON CROSS ROAD*DADAR(EAST)*MUMBAI-14",
    fullName: "JAYANTA PARIDA",
    saID: "SPR8172",
  },
  {
    firstName: "MILAN",
    lastName: "MONDAL",
    address:
      "C/O BINOY MONDAL*AT/PO-SONAMUKHI*DIST-BANKURA-722207*PAN BKRPM4099R",
    fullName: "MILAN MONDAL",
    saID: "SPR8173",
  },
  {
    firstName: "BODHADITYA",
    lastName: "PATRA",
    address:
      "C/O BANKIM CHANDRA PATRA*AT-STATION ROAD,KALIMELA*PS/PO-BISHNUPUR*BANKURA-722122.",
    fullName: "BODHADITYA PATRA",
    saID: "SPR8174",
  },
  {
    firstName: "DEBRANJAN",
    lastName: "KESH",
    address: "SATSANG VIHAR SONAMUKHI*BANKURA-722207*ALHPK6615R",
    fullName: "DEBRANJAN KESH",
    saID: "SPR8175",
  },
  {
    firstName: "DIPU",
    lastName: "SARKAR",
    address:
      "AB 8/41,DESHBANDHU NAGAR*FLAT NO-3B,LALBARI*KOLKATA 700059*PAN-ALCPS0406P",
    fullName: "DIPU SARKAR",
    saID: "SPR8176",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8177",
  },
  {
    firstName: "DWIJESH",
    lastName: "MALAKAR",
    address:
      "M/S R.S.CLINIC*PO-PATHARQUERRY*GUWAHATI-781171*DIST-KAMRUP*PAN-AHCPM0647A",
    fullName: "DWIJESH MALAKAR",
    saID: "SPR8178",
  },
  {
    firstName: "JUGAL",
    lastName: "DAS",
    address: "VILL&PO-SATPAKHALI*KAMRUP",
    fullName: "JUGAL DAS",
    saID: "SPR8179",
  },
  {
    firstName: "NABAJIT",
    lastName: "TALUKDAR",
    address: "SICHAPITH*BOKO*KAMRUP-781123*ASSAM*PAN-ADXPT7756Q",
    fullName: "NABAJIT TALUKDAR",
    saID: "SPR8180",
  },
  {
    firstName: "SANJEET",
    lastName: "CHAKRABORTY",
    address: "SARUPATHAR*GOLAGHAT*ASSAM",
    fullName: "SANJEET CHAKRABORTY",
    saID: "SPR8181",
  },
  {
    firstName: "NATOKISHOR",
    lastName: "ADITYA",
    address: "D.PAKUTIA*GHATAIL*TANGAIL*BANGLADESH",
    fullName: "NATOKISHOR ADITYA",
    saID: "SPR8182",
  },
  {
    firstName: "RICHI PROSAD",
    lastName: "MISTRY",
    address: "B-505,LALWANI VASTU*VIMAN NAGAR*PUNE*M S",
    fullName: "RICHI PROSAD MISTRY",
    saID: "SPR8183",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "BHATTACHERJEE",
    address: "VILL-PAKUTIA*PO-D PAKUTIA*UP-GHATAIL*DIST-TANGAIL*BANGLADESH",
    fullName: "SWAPAN KUMAR BHATTACHERJEE",
    saID: "SPR8184",
  },
  {
    firstName: "RATAN CHANDRA",
    lastName: "NAYEK",
    address: "PURBASA ABASIK ELAKA*SREEMANGAL*MOULAVI BAZAR*BANGLADESH",
    fullName: "RATAN CHANDRA NAYEK",
    saID: "SPR8185",
  },
  {
    firstName: "PRAMOD CHANDRA",
    lastName: "PRADHAN",
    address: "AT-BONAIGARH(NEAR CHURCH)*PO-BONAIGARH*SUNDARGARH 770 038",
    fullName: "PRAMOD CHANDRA PRADHAN",
    saID: "SPR8186",
  },
  {
    firstName: "KAILASH CHANDRA",
    lastName: "DASH",
    address: "AT/PO-KENDIKALA*SUNDARGARH 770038",
    fullName: "KAILASH CHANDRA DASH",
    saID: "SPR8187",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "MALLICK",
    address: "ARAMBAG SATSANG UPASANA KENDRA*WARD NO-3,ARAMBAG*HOOGHLY",
    fullName: "SWAPAN KUMAR MALLICK",
    saID: "SPR8188",
  },
  {
    firstName: "AMAR NATH",
    lastName: "GHOSH",
    address: "VILL-PAIRAGACHA,WEST*PO-JANAI*HOOGHLY 712304",
    fullName: "AMAR NATH GHOSH",
    saID: "SPR8189",
  },
  {
    firstName: "SMRITI KANTA",
    lastName: "BARMAN",
    address: "SAHEED KSHUDIRAM COLLEGE*KAMAKHYAGURI*ALIPURDUAR-736202",
    fullName: "SMRITI KANTA BARMAN",
    saID: "SPR8190",
  },
  {
    firstName: "HARIKANTA",
    lastName: "SARKAR",
    address:
      "C/O LATE-HARENDRA CHANDRA SARKAR*AT-SAMUKTALA*PO-SAONTALPUR*JALPAIGURI 736206",
    fullName: "HARIKANTA SARKAR",
    saID: "SPR8191",
  },
  {
    firstName: "BAKUL CHANDRA",
    lastName: "PAUL",
    address:
      "VILL/PO-DAKSHIN NARARTHALI(BATTALA)*VIA-KAMAKSHYAGURI*DIST-ALIPURDUAR-736202",
    fullName: "BAKUL CHANDRA PAUL",
    saID: "SPR8192",
  },
  {
    firstName: "PRASANTA",
    lastName: "PALMAJUMDAR",
    address: "VILL&PO-BUNIADPUR*DAKSHIN DINAJPUR 733 121",
    fullName: "PRASANTA PALMAJUMDAR",
    saID: "SPR8193",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MONDAL",
    address:
      "VILL-GANGARAMPUR SUBASH PALLY*PO-GANGARAMPUR*DAKHIN DINAJPUR 733 124",
    fullName: "NARAYAN CHANDRA MONDAL",
    saID: "SPR8194",
  },
  {
    firstName: "SANTANU",
    lastName: "NARZARY",
    address: "VILL-GURIHATI*PO-KHOIRABARI*DARRANG*ASSAM*PIN-784522",
    fullName: "SANTANU NARZARY",
    saID: "SPR8195",
  },
  {
    firstName: "MALAY",
    lastName: "BASUMATARY",
    address: "AT-JANPARA*PO-GORESWAR*DIST-BAKSA (BTAD)-781366*ASSAM",
    fullName: "MALAY BASUMATARY",
    saID: "SPR8196",
  },
  {
    firstName: "SAMARENDRA",
    lastName: "SARKAR",
    address:
      "VILL-PARA BAGULA*PO-CHAR BHAIRABI*UPAZILLA-HAIMCHAR*DIST-CHANDPUR*BANGLADESH",
    fullName: "SAMARENDRA SARKAR",
    saID: "SPR8197",
  },
  {
    firstName: "HARSHA BINOD",
    lastName: "PARASAVSHARMA",
    address:
      "VILL-PAJRAPARA*PO-SHIKDAR MALLIK*PS-NAZIRPUR*DIST-FIROZPUR*BANGLADESH",
    fullName: "HARSHA BINOD PARASAVSHARMA",
    saID: "SPR8198",
  },
  {
    firstName: "PRITISH KUMAR",
    lastName: "SAHA",
    address:
      "13C, CHANDITALA LANE*PO-REGENT PARK*CALCUTTA-700040*PAN AKLPS6550J",
    fullName: "PRITISH KUMAR SAHA",
    saID: "SPR8199",
  },
  {
    firstName: "SARAJU",
    lastName: "PAL",
    address:
      "VILL-HAGJAR NAGAR*PO-HAFLONG*DIST-DIMA HASAO-788819*ASSAM*PAN-BFRPP5124D",
    fullName: "SARAJU PAL",
    saID: "SPR8200",
  },
  {
    firstName: "NIRMALYA",
    lastName: "DAS",
    address: "MAIBONG BAZAR*N C HILLS-788831*ASSAM",
    fullName: "NIRMALYA DAS",
    saID: "SPR8201",
  },
  {
    firstName: "MANORANJAN",
    lastName: "DAS",
    address: "BANARUPA PARA*BANDARBON SADAR*BANGLADESH",
    fullName: "MANORANJAN DAS",
    saID: "SPR8202",
  },
  {
    firstName: "SARAT KUMAR",
    lastName: "JENA",
    address: "AT-SRIHARI NAGAR*PO-ANKOLI*BERHAMPORE*GANJAM*PAN.AECPJ8726L",
    fullName: "SARAT KUMAR JENA",
    saID: "SPR8203",
  },
  {
    firstName: "NIRMAL",
    lastName: "MAJUMDAR",
    address: "68 SURYA SEN ST*CALCUTTA 700009",
    fullName: "NIRMAL MAJUMDAR",
    saID: "SPR8204",
  },
  {
    firstName: "HEMANTA KUMAR",
    lastName: "NAYAK",
    address:
      "AT-DOHARAPATANA*PO-SUNDARPUR*P.S.-DHUSURI*BHADRAK 756119*PAN AELPN5228C",
    fullName: "HEMANTA KUMAR NAYAK",
    saID: "SPR8205",
  },
  {
    firstName: "DIBYENDU",
    lastName: "SAMANTA",
    address: "",
    fullName: "DIBYENDU SAMANTA",
    saID: "SPR8206",
  },
  {
    firstName: "BIRU",
    lastName: "PURAKAYASTHA",
    address: "",
    fullName: "BIRU PURAKAYASTHA",
    saID: "SPR8207",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "DAS",
    address: "G.M.B. JHANDA CHAWK* BHATTA BAZAR*DIST-PURNIA-854 301",
    fullName: "TAPAN KUMAR DAS",
    saID: "SPR8208",
  },
  {
    firstName: "BISWANATH",
    lastName: "SADHU",
    address: "MAHULIA*E SINGBHUM-832304*PAN.EGVPS5623E",
    fullName: "BISWANATH SADHU",
    saID: "SPR8209",
  },
  {
    firstName: "TRAILAKSHYA NATH",
    lastName: "RAY",
    address: "VILL-EAST SAITARA*PO-PALASHBARI*DIST-DINAJPUR*BANGLADESH",
    fullName: "TRAILAKSHYA NATH RAY",
    saID: "SPR8210",
  },
  {
    firstName: "PUJANIA BARABOURANI",
    lastName: "",
    address: "",
    fullName: "PUJANIA BARABOURANI ",
    saID: "SPR8211",
  },
  {
    firstName: "REV",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV CHAKRABORTY",
    saID: "SPR8212",
  },
  {
    firstName: "BHILAI RANJAN",
    lastName: "GOCHHAIT",
    address: "AT&PO-JANGALPARA*VIA-TARAKESWAR*HOOGHLY-712701*PAN- ABXPG1284E",
    fullName: "BHILAI RANJAN GOCHHAIT",
    saID: "SPR8213",
  },
  {
    firstName: "TRILOKESH",
    lastName: "NARZARY",
    address:
      "C/O-GIRIDHAR NARZARY*BANGSHALI WARD NO-7*PO/DIST-KOKRAJHAR-783370*B T A D*ASSAM",
    fullName: "TRILOKESH NARZARY",
    saID: "SPR8214",
  },
  {
    firstName: "DHRITI RANJAN",
    lastName: "KAR",
    address: "VILL-BANDHDANGA*PO-BUDHANPUR*PS-CHATNA*DIST-BANKURA 722137",
    fullName: "DHRITI RANJAN KAR",
    saID: "SPR8215",
  },
  {
    firstName: "DIPIRAG",
    lastName: "BAIDYA",
    address: "C/O CANARA BANK*BANSPHATAK*VARANASI-221001*PAN-ASHPR5559G",
    fullName: "DIPIRAG BAIDYA",
    saID: "SPR8216",
  },
  {
    firstName: "SWARUP",
    lastName: "CHAKRABARTY",
    address:
      "VILL-KUCHAIPUR*PO-LUTUNIA*PASCHIM MEDINIPUR 721166*PAN-ADNPC6177G",
    fullName: "SWARUP CHAKRABARTY",
    saID: "SPR8217",
  },
  {
    firstName: "ARUNODOY",
    lastName: "CHATTERJEE",
    address: "",
    fullName: "ARUNODOY CHATTERJEE",
    saID: "SPR8218",
  },
  {
    firstName: "BHUAL",
    lastName: "VISHWAKARMA",
    address:
      "28B/101/D/1,NETA CHAURAHA*ALLAPUR*ALLAHABAD-211006*PAN ANHPV3629C",
    fullName: "BHUAL VISHWAKARMA",
    saID: "SPR8219",
  },
  {
    firstName: "RABINDRA CHANDRA",
    lastName: "SARMA",
    address: "AT -NABAB BAG*KHAGRA*PO&DIST-KISHANGANJ-855107*BIHAR.",
    fullName: "RABINDRA CHANDRA SARMA",
    saID: "SPR8220",
  },
  {
    firstName: "RAMA CHANDRA",
    lastName: "GAUD",
    address: "AT/PO-JILLUNDI*VIA-BHANJANAGAR*GANJAM 761133",
    fullName: "RAMA CHANDRA GAUD",
    saID: "SPR8221",
  },
  {
    firstName: "BHAGABAN",
    lastName: "BADHEI",
    address: "AT-TUNTUNIA SAHI*PO-BUGUDA*GANJAM-761118*PAN-BNBPB8365C",
    fullName: "BHAGABAN BADHEI",
    saID: "SPR8222",
  },
  {
    firstName: "SARAJIT KUMAR",
    lastName: "DAS",
    address: "AT- BHATGRAM*PO-KALAIN*DIST-CACHAR*ASSAM.",
    fullName: "SARAJIT KUMAR DAS",
    saID: "SPR8223",
  },
  {
    firstName: "JITENDRA LAL",
    lastName: "RAY",
    address: "NEAR SUGAR MILL*PO-RATABARI*KARIMGANJ 788735.",
    fullName: "JITENDRA LAL RAY",
    saID: "SPR8224",
  },
  {
    firstName: "SIBA NATH",
    lastName: "BARMAN",
    address: "VILL&PO-KOMARAKATA*HOJAI*NAGAON-782240*ASSAM",
    fullName: "SIBA NATH BARMAN",
    saID: "SPR8225",
  },
  {
    firstName: "PRAJESH RANJAN",
    lastName: "DAS",
    address: "SANJIBANI PHARMACY*PO-MARGHERITA*DT-TINSUKIA  786181 ASSAM",
    fullName: "PRAJESH RANJAN DAS",
    saID: "SPR8226",
  },
  {
    firstName: "PRATIT SUNDAR",
    lastName: "DEBROY",
    address: "OXFORD HILLS ROAD*KENCH'S TRACE*SHILLONG-793004*MEGHALAYA",
    fullName: "PRATIT SUNDAR DEBROY",
    saID: "SPR8227",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "SARKAR",
    address:
      "SWASTI HOMEO HALL*CHANDINA PURBA BAZAR*PO-CHANDINA*COOMILLA*BANGLADESH",
    fullName: "TAPAN KUMAR SARKAR",
    saID: "SPR8228",
  },
  {
    firstName: "JAGADISH CHANDRA",
    lastName: "HOWLADER",
    address:
      "35,SARAT GUPTA ROAD*MANIK VILLA 2ND FLOOR*NARINDA*DHAKA*BANGLADESH",
    fullName: "JAGADISH CHANDRA HOWLADER",
    saID: "SPR8229",
  },
  {
    firstName: "DIPOK RANJAN",
    lastName: "DHAR",
    address: "VILL-TEROHATI*PO-GOALA BAZAR*SYLHET*BANGLADESH",
    fullName: "DIPOK RANJAN DHAR",
    saID: "SPR8230",
  },
  {
    firstName: "NIRAMAY",
    lastName: "KANJILAL",
    address: "126/2 HAJI ISMAIL ROAD*BANORGATI*KHULNA*BANGLADESH",
    fullName: "NIRAMAY KANJILAL",
    saID: "SPR8231",
  },
  {
    firstName: "KRISHNA PADA",
    lastName: "MANDAL",
    address:
      "PRINCIPAL,SUBIDKHALI COLLEGE*PO-SUBIDKHALI*DIST-PATUAKHALI*BANGLADESH",
    fullName: "KRISHNA PADA MANDAL",
    saID: "SPR8232",
  },
  {
    firstName: "NIRANJAN CHANDRA",
    lastName: "RAY",
    address: "VILL-KHATAPARA*PO-BOIDYER BZ*LALMONIRHAT*BANGLADESH",
    fullName: "NIRANJAN CHANDRA RAY",
    saID: "SPR8233",
  },
  {
    firstName: "BHABENDRA",
    lastName: "ROY",
    address: "VILL-PANCHAGRAM ALABOX*PO-BAROBARI*LALMONIRHAT*BANGLADESH",
    fullName: "BHABENDRA ROY",
    saID: "SPR8234",
  },
  {
    firstName: "SRINATH",
    lastName: "MANDAL",
    address: "VILL-TIURA*PO-KOCHDIHI*DIST-BANKURA 722207*PAN BJDPM1444D",
    fullName: "SRINATH MANDAL",
    saID: "SPR8235",
  },
  {
    firstName: "RAJ KISHORE",
    lastName: "SINGH",
    address:
      "AT-BAHADURPUR BARAITOLA*PO-ARERAJ*EAST CHAMPARAN 84541*PAN.CYXPS7067Q",
    fullName: "RAJ KISHORE SINGH",
    saID: "SPR8236",
  },
  {
    firstName: "MADAN",
    lastName: "PRASAD",
    address:
      "VILL-TARWARA(KHUSHWA NAGAR)*PO-TARWARA*SIWAN(BIHAR)-841506*PAN-AKSPP0912E",
    fullName: "MADAN PRASAD",
    saID: "SPR8237",
  },
  {
    firstName: "NARAYAN",
    lastName: "DUTTA",
    address:
      "AINJEEBEE ANEX BHABAN-2*ROOM NO-4018,COURT HILL*CHITTAGONG*BANGLADESH",
    fullName: "NARAYAN DUTTA",
    saID: "SPR8238",
  },
  {
    firstName: "RANJIT ANANTA",
    lastName: "MANDAL",
    address:
      "NETAJI SUBHASH CH SCIENCE COLLEGE*MULCHERA*DIST-GODCHIROLI-442707*M.S.",
    fullName: "RANJIT ANANTA MANDAL",
    saID: "SPR8239",
  },
  {
    firstName: "PRADIP",
    lastName: "GHOSH",
    address: "VILL-SRINATHPUR*PO-SRIMAYAPUR*NADIA-741313*PAN-APHPG5043F*W.B.",
    fullName: "PRADIP GHOSH",
    saID: "SPR8240",
  },
  {
    firstName: "ARLADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ARLADYUTI CHAKRABORTY",
    saID: "SPR8241",
  },
  {
    firstName: "AJAY BALLAV",
    lastName: "ROY",
    address:
      "QTR NO-DI/4*D-TYPE OFFICERS QRS.*NEAR D C RESIDENCE*DEOGHAR-814112*PAN-ACDPR9551L",
    fullName: "AJAY BALLAV ROY",
    saID: "SPR8242",
  },
  {
    firstName: "REV DR ALOK KUMAR",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV DR ALOK KUMAR CHAKRAVARTY",
    saID: "SPR8243",
  },
  {
    firstName: "SADHU VENKATESWARA",
    lastName: "RAO",
    address:
      "AT-QTR.N0-1363,TYPE-I,BLK-49*PO-SALIGRAMAPURAM*VISHAKHAPATANAM*A.P*PAN-AZHPS4812J",
    fullName: "SADHU VENKATESWARA RAO",
    saID: "SPR8244",
  },
  {
    firstName: "RAJENDRA KUMAR",
    lastName: "MANDAL",
    address:
      "SAIGANESH CO-OP HSG SOCIETY*PL NO RH-72;FLAT NO-27*SAHUNAGAR,CHINCHWAD*PUNE-19.",
    fullName: "RAJENDRA KUMAR MANDAL",
    saID: "SPR8245",
  },
  {
    firstName: "TAPAN KUMAR",
    lastName: "KHATUA",
    address:
      "H NO-D/1 SWAMI DHAM COMPLEX*AMLI BAVISHA FALIA*SILVASSA*U T OF DADAR & NAGAR HAVELI",
    fullName: "TAPAN KUMAR KHATUA",
    saID: "SPR8246",
  },
  {
    firstName: "DHRITI SUNDAR",
    lastName: "SARKAR",
    address: "SATSANG*DEOGHAR",
    fullName: "DHRITI SUNDAR SARKAR",
    saID: "SPR8247",
  },
  {
    firstName: "SABYASACHI",
    lastName: "BISWAS",
    address:
      "FL NO-10301,PRESTIGE MONTE CARLO*PUTTENAHALLI*YELAHANKA*BANGALORE-560064*ADSPB0384R",
    fullName: "SABYASACHI BISWAS",
    saID: "SPR8248",
  },
  {
    firstName: "UPANANDA",
    lastName: "SINGHA",
    address:
      "SATSANG VIHAR BANGALORE*SATSANG RD;KARNASHREE LAYOUT*MEDAHALLI*BANGALORE 560049",
    fullName: "UPANANDA SINGHA",
    saID: "SPR8249",
  },
  {
    firstName: "DHRITISUNDER",
    lastName: "BHATTACHARYA",
    address:
      "NO.14/2 2 ND CROSS KARNASHREE LAYOUT*MEDAHALLI,VIRGONAGAR POST*BANGALORE 560049",
    fullName: "DHRITISUNDER BHATTACHARYA",
    saID: "SPR8250",
  },
  {
    firstName: "LATE ANIL",
    lastName: "KUMAR",
    address: "B.D.O. BASANT BIHAR COLONEY*PO-KORRAH*DIST-HAZARIBAGH",
    fullName: "LATE ANIL KUMAR",
    saID: "SPR8251",
  },
  {
    firstName: "RICHIK RANJAN",
    lastName: "CHOUDHURY",
    address: "VILL-KHARGRAM*PO-KHARGA-KARANDA*BURDWAN.",
    fullName: "RICHIK RANJAN CHOUDHURY",
    saID: "SPR8252",
  },
  {
    firstName: "TAPAN",
    lastName: "SINGH",
    address: "VILL-DULALI BAZAR*PO-RASULPUR*BURDWAN",
    fullName: "TAPAN SINGH",
    saID: "SPR8253",
  },
  {
    firstName: "GURUDEVAN",
    lastName: "MITRA",
    address:
      "PAN NO-AMGPM7980F*1/6707-B 4TH FLOOR,ST-4*EAST ROHTAS NAGAR*SHAHDARA*DELHI 110032",
    fullName: "GURUDEVAN MITRA",
    saID: "SPR8254",
  },
  {
    firstName: "CHAINA",
    lastName: "PEGU",
    address: "PO-SILAPATHAR*DIST-LAKHIMPUR*ASSAM",
    fullName: "CHAINA PEGU",
    saID: "SPR8255",
  },
  {
    firstName: "PRATUL KUMAR",
    lastName: "JENA",
    address:
      "SATSANG VIHAR BANGALORE*SATSANG RD;KARNASHREE LAYOUT*MEDAHALLI*BANGALORE-560049",
    fullName: "PRATUL KUMAR JENA",
    saID: "SPR8256",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ALOK CHAKRABORTY",
    saID: "SPR8257",
  },
  {
    firstName: "JAGANNATH",
    lastName: "SANYAL",
    address:
      "H/3/92 UPPER GROUND FRONT*MAHAVIR ENCLAVE*NEW DELHI 45*PAN NO.BIDPS2349C          )",
    fullName: "JAGANNATH SANYAL",
    saID: "SPR8258",
  },
  {
    firstName: "NEELESH KUMAR",
    lastName: "SRIVASTAV",
    address:
      "KUMAR SURGICAL/MATERNITY NURSING HOME*MANIKCHOWK*JAUNPUR 222001*U.P.*PAN-AXZPS3852P",
    fullName: "NEELESH KUMAR SRIVASTAV",
    saID: "SPR8259",
  },
  {
    firstName: "RIDDHI RAMAN",
    lastName: "BAGCHI",
    address:
      "SWATI  GARDEN FLAT 2A(BLOCK B)*238 N S C BOSE ROAD*KOLKATA 700040",
    fullName: "RIDDHI RAMAN BAGCHI",
    saID: "SPR8260",
  },
  {
    firstName: "PARITOSH",
    lastName: "SAPUI",
    address: "",
    fullName: "PARITOSH SAPUI",
    saID: "SPR8261",
  },
  {
    firstName: "PARITOSH",
    lastName: "SAPUI",
    address: "",
    fullName: "PARITOSH SAPUI",
    saID: "SPR8262",
  },
  {
    firstName: "REV ASHOKE",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ASHOKE CHAKRABORTY",
    saID: "SPR8263",
  },
  {
    firstName: "UTTAM",
    lastName: "MAITY",
    address: "E10/HOUSE NO-A10*33 FUTA ROAD*DAYALPUR*DELHI-94",
    fullName: "UTTAM MAITY",
    saID: "SPR8264",
  },
  {
    firstName: "SHYAMPRASAD",
    lastName: "LALL",
    address: "",
    fullName: "SHYAMPRASAD LALL",
    saID: "SPR8265",
  },
  {
    firstName: "BISHNU PRASAD",
    lastName: "SEAL",
    address: "VILL-NAWAB NAGAR COLONY*PO-SAHEBGANJ*DIST-BURDWAN",
    fullName: "BISHNU PRASAD SEAL",
    saID: "SPR8266",
  },
  {
    firstName: "HRIDAYMANI",
    lastName: "BRAHMA",
    address: "VILL-ONTHAIBARI*PO-AMTEKA*CHIRANG 783393",
    fullName: "HRIDAYMANI BRAHMA",
    saID: "SPR8267",
  },
  {
    firstName: "SADASHIB",
    lastName: "NAYAK",
    address: "VILL&PO-DAMANBHUMI*VIA-JANKIA*DT-KHURDA*ODISHA.",
    fullName: "SADASHIB NAYAK",
    saID: "SPR8268",
  },
  {
    firstName: "REV DR ALOK KUMAR",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV DR ALOK KUMAR CHAKRABORTY",
    saID: "SPR8269",
  },
  {
    firstName: "YOGESH",
    lastName: "JOSHI",
    address: "20 KSHAMA, SBI, NAUPADA*THANE 400602*MAHARASTRA",
    fullName: "YOGESH JOSHI",
    saID: "SPR8270",
  },
  {
    firstName: "KAMAL KRISHNA",
    lastName: "HAZRA",
    address: "VAISAMAU*B K T*LUCKNOW",
    fullName: "KAMAL KRISHNA HAZRA",
    saID: "SPR8271",
  },
  {
    firstName: "PUJANIYA",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA CHAKRABORTY",
    saID: "SPR8272",
  },
  {
    firstName: "SRI SRI DADA",
    lastName: "",
    address: "",
    fullName: "SRI SRI DADA ",
    saID: "SPR8273",
  },
  {
    firstName: "PUJANIA  GUDDU BABU",
    lastName: "",
    address: "",
    fullName: "PUJANIA  GUDDU BABU ",
    saID: "SPR8274",
  },
  {
    firstName: "SHAILENDRA KUMAR",
    lastName: "SHARMA",
    address: "VIL&&PO-RAMPUR CHOURAM*DIST-ARWAL*PAN-CBDPS1173E",
    fullName: "SHAILENDRA KUMAR SHARMA",
    saID: "SPR8275",
  },
  {
    firstName: "SANDIP",
    lastName: "SARKAR",
    address: "",
    fullName: "SANDIP SARKAR",
    saID: "SPR8276",
  },
  {
    firstName: "PARMARTHA",
    lastName: "PANIGRAHI",
    address: "VILL-HATUARY*PO-BADASUANLO*DIST-DHENKANAL-759039*ODISHA",
    fullName: "PARMARTHA PANIGRAHI",
    saID: "SPR8277",
  },
  {
    firstName: "UMESH",
    lastName: "MAHATO",
    address: "AT-CHARIA BARIARPUR*PO-SIRIPUR*BEGUSARAI",
    fullName: "UMESH MAHATO",
    saID: "SPR8278",
  },
  {
    firstName: "SARATA KUMAR",
    lastName: "PATI",
    address: "NO 4 F.FLOOR,SREE AWAS*SEC.18B,PHAGE 2*DWARKA*NEW DELHI-110 078",
    fullName: "SARATA KUMAR PATI",
    saID: "SPR8279",
  },
  {
    firstName: "PUJANIYA BOURANIMA",
    lastName: "",
    address: "",
    fullName: "PUJANIYA BOURANIMA ",
    saID: "SPR8280",
  },
  {
    firstName: "PUJYAPADABARA BOWDI",
    lastName: "",
    address: "",
    fullName: "PUJYAPADABARA BOWDI ",
    saID: "SPR8281",
  },
  {
    firstName: "DILIP",
    lastName: "BARUAH",
    address: "MILANPUR*PO-B CHARIALI*SONITPUR 784176",
    fullName: "DILIP BARUAH",
    saID: "SPR8282",
  },
  {
    firstName: "SUSANTA KUMAR",
    lastName: "PATRA",
    address:
      "(NOTARY MAGISTRATE)*AT/PO-SATPATNA*DASPALLA*DIST-NAYAGARH*PAN NO.ABMPP5725G",
    fullName: "SUSANTA KUMAR PATRA",
    saID: "SPR8283",
  },
  {
    firstName: "RAJ KUMAR",
    lastName: "MARASINI",
    address: "MS 2/306,KENDRIYA VIHAR *SEC-56,GURGAON*HARIYANA",
    fullName: "RAJ KUMAR MARASINI",
    saID: "SPR8284",
  },
  {
    firstName: "NIRMAL SINGH",
    lastName: "CHOUHAN",
    address: "VILL/PO -TINGRAI CHARIALI*DIST-DIBRUGARH*ASSAM",
    fullName: "NIRMAL SINGH CHOUHAN",
    saID: "SPR8285",
  },
  {
    firstName: "SUBHASISH",
    lastName: "MUKHARJEE",
    address:
      "302.EKDANT BLDG*PLOT NO-109,134&135*SECTOR-9 NEW PANVEL 410206*M.S> PAN.AFTPM8430Q",
    fullName: "SUBHASISH MUKHARJEE",
    saID: "SPR8286",
  },
  {
    firstName: "DEBJYOTI",
    lastName: "LAHIRI",
    address: "C-1/7,L I C COLONY*S V ROAD*SANTACRUZ(W)*MUMBAI-400054",
    fullName: "DEBJYOTI LAHIRI",
    saID: "SPR8287",
  },
  {
    firstName: "AJITESH",
    lastName: "MANDAL",
    address: "VILL-SUNDARMAL*PO-BARAAULIA*PS-BATIAGHAT*DIST-KHULNA*BANGLADESH",
    fullName: "AJITESH MANDAL",
    saID: "SPR8288",
  },
  {
    firstName: "KRITIRANJAN",
    lastName: "RAY",
    address: "KAMARGRAM*PS-BOALMARI*FARIDPUR*BANGLADESH",
    fullName: "KRITIRANJAN RAY",
    saID: "SPR8289",
  },
  {
    firstName: "KRISHNASISH",
    lastName: "DAS",
    address: "KARNAFULY SATSANG VIHAR*CHITTAGONG",
    fullName: "KRISHNASISH DAS",
    saID: "SPR8290",
  },
  {
    firstName: "BASUDEB",
    lastName: "GOSWAMI",
    address:
      "VILL-UTTAR BATTRISH HAZARI*PO-CHAPARHAT*PS-KALIGONJ*DIST-LALMANIRHAT*BANGLADESH",
    fullName: "BASUDEB GOSWAMI",
    saID: "SPR8291",
  },
  {
    firstName: "BHUPATI BHUSAN",
    lastName: "DAS",
    address: "UTTAR FALIA*GAIBANDHA SADAR*GAIBANDHA*BANGLADESH",
    fullName: "BHUPATI BHUSAN DAS",
    saID: "SPR8292",
  },
  {
    firstName: "GUNASINDHU",
    lastName: "CHAKRABORTY",
    address:
      "64/A,BADHAN MOHILA COLLEGE ROAD*SUNAMGANJ SADAR*SUNAMGONJ*BANGLADESH",
    fullName: "GUNASINDHU CHAKRABORTY",
    saID: "SPR8293",
  },
  {
    firstName: "DIPEN",
    lastName: "RAY",
    address: "VILL-RAJARCHAR*PO-SHAHEBER HAT*PS/DIST-BARISAL*BANGLADESH",
    fullName: "DIPEN RAY",
    saID: "SPR8294",
  },
  {
    firstName: "TARUN KUMAR",
    lastName: "SAHA",
    address:
      "GANGAIL ROAD(GANDHIGHAT)*NEAR VIVEKANANDA BYAYAMGAR*PO-AGARTALA*TRIPURA WEST 799001",
    fullName: "TARUN KUMAR SAHA",
    saID: "SPR8295",
  },
  {
    firstName: "AJIT KUMAR",
    lastName: "MAITI",
    address:
      "NO-10H M ROAD,WEST JOYRAMPUR JALA*BEHALA*CALCUTTA-700060*PAN-ANGPM7737F",
    fullName: "AJIT KUMAR MAITI",
    saID: "SPR8296",
  },
  {
    firstName: "SHUBHRANSHU",
    lastName: "SARKER",
    address:
      "SATSANG THAKURBARI PANIHATI*T N BANERJEE ROAD*KOLKATA-700 114*PAN-BDPPS5096H",
    fullName: "SHUBHRANSHU SARKER",
    saID: "SPR8297",
  },
  {
    firstName: "AKSHAY KUMAR",
    lastName: "BARMAN",
    address:
      "VILL-JOYRAMPUR ANWAR*PO-BEGUM ROKEYA SMRITY*PS-MITHAPUKUR*DIST-RONGPUR*BANGLADESH",
    fullName: "AKSHAY KUMAR BARMAN",
    saID: "SPR8298",
  },
  {
    firstName: "ATUL",
    lastName: "NARZARY",
    address: "AT-MAGURMARI*PO-NAOKATA*DIST-BAKSA(BTAD)-781 366*ASSAM",
    fullName: "ATUL NARZARY",
    saID: "SPR8299",
  },
  {
    firstName: "SURUJ",
    lastName: "BARTHAKUR",
    address: "W NO-1*DERGAN*GOLAGHAT*PAN CHOPB6426Q",
    fullName: "SURUJ BARTHAKUR",
    saID: "SPR8300",
  },
  {
    firstName: "NIRANJAN",
    lastName: "CHANDA",
    address: "BANIA CHANG*JATRAPASHA*DIST-HABIGANJ*BANGLADESH",
    fullName: "NIRANJAN CHANDA",
    saID: "SPR8301",
  },
  {
    firstName: "BICHITRANANDA",
    lastName: "PATNAIK",
    address: "ICHINDA WARD NO-12*RAIRANGPUR*MAYURBHANJ*ODISHA 757043",
    fullName: "BICHITRANANDA PATNAIK",
    saID: "SPR8302",
  },
  {
    firstName: "JYOTI KISHORE",
    lastName: "DALAI",
    address: "AT-KANIKA*PO-RARUAN*DIST-MAYURABHANJ",
    fullName: "JYOTI KISHORE DALAI",
    saID: "SPR8303",
  },
  {
    firstName: "KANAI LAL",
    lastName: "MAIKAP",
    address:
      "AT-JAGANNATH CHAK*PO-MADHUSUDHAN CHAK*PS-MATHURAPUR*24PARGANAS 743349",
    fullName: "KANAI LAL MAIKAP",
    saID: "SPR8304",
  },
  {
    firstName: "ABHIJIT",
    lastName: "PANIGRAHI",
    address: "GARASANG ADA*BALASORE*PAN-AIPPA0457Q",
    fullName: "ABHIJIT PANIGRAHI",
    saID: "SPR8305",
  },
  {
    firstName: "TUSAR",
    lastName: "SWAIN",
    address: "AT&PO-OSAKANA*VIA-MACHHGAN*JAGATSINGPUR*754119",
    fullName: "TUSAR SWAIN",
    saID: "SPR8306",
  },
  {
    firstName: "GHANASHYAM",
    lastName: "GIRI",
    address:
      "B-505 LALWANI VASTU*VIMAN NAGAR*PUNE 411014*MAHARASHTRA*PAN-AIDPG4644B",
    fullName: "GHANASHYAM GIRI",
    saID: "SPR8307",
  },
  {
    firstName: "DIPAN",
    lastName: "BHATTACHARJEE",
    address:
      "UDALBAKRA,LALGANESH*GREEN WOOD LANE*H NO-49,DEBALAYA BLDG*GUWAHATI-34*KAMRUP",
    fullName: "DIPAN BHATTACHARJEE",
    saID: "SPR8308",
  },
  {
    firstName: "REV DR ALOK",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "REV DR ALOK CHAKRAVARTY",
    saID: "SPR8309",
  },
  {
    firstName: "NIHAR RANJAN",
    lastName: "MISHRA",
    address:
      "C/O-NIRANJAN MISHRA*COLLEGE ROAD*DR.S.S.KAR LANE*PO/DIST-KEONJHAR-758001*ODISHA.",
    fullName: "NIHAR RANJAN MISHRA",
    saID: "SPR8310",
  },
  {
    firstName: "SWAPAN",
    lastName: "CHAUDHURY",
    address:
      "GRANT ESTATE*BACKSIDE  OF CAREER PRESS*DUMKA-814101*PAN-ACZPC8151H",
    fullName: "SWAPAN CHAUDHURY",
    saID: "SPR8311",
  },
  {
    firstName: "NEERAJ",
    lastName: "KUMAR",
    address:
      "KENDRIYA VIDYALAYA NO-2*BAILY ROAD*SEKHPURA MORE*PATNA*PAN-BYQPK2260K",
    fullName: "NEERAJ KUMAR",
    saID: "SPR8312",
  },
  {
    firstName: "NAWAL KISHORE",
    lastName: "MISHRA",
    address:
      "VAIDEHI APT FLAT NO-2A*JAGDEO PATH*PO-B V COLLEGE*PATNA 14*PAN AFZPM5312B",
    fullName: "NAWAL KISHORE MISHRA",
    saID: "SPR8313",
  },
  {
    firstName: "OM",
    lastName: "PRAKASH",
    address: "PRIYADARSHI NAGAR*NAYATOLA*KUMHRAR*PATNA 26*PAN NO.ABKPP7846C",
    fullName: "OM PRAKASH",
    saID: "SPR8314",
  },
  {
    firstName: "BRAJ",
    lastName: "KISHORE",
    address: "PHARMACEUTICAL COLONY*KANKARBAGH*PATNA 26",
    fullName: "BRAJ KISHORE",
    saID: "SPR8315",
  },
  {
    firstName: "ANIL KUMAR",
    lastName: "SINGH",
    address:
      "A TYPE QTRS 2,L S COLLEGE CAMPUS*MUZAFFARPUR-842001*PAN-ANRPS6844C",
    fullName: "ANIL KUMAR SINGH",
    saID: "SPR8316",
  },
  {
    firstName: "SHANKAR SUWAN",
    lastName: "SRIVASTAV",
    address: "QRT NO-D/438*BHAWANATHPUR TOWN SHIP*GARHWA",
    fullName: "SHANKAR SUWAN SRIVASTAV",
    saID: "SPR8317",
  },
  {
    firstName: "KEDAR",
    lastName: "PRASAD",
    address: "ASHOKE VIHAR GARWAH*PO-GARWAH*GARWAH JHARKHAND",
    fullName: "KEDAR PRASAD",
    saID: "SPR8318",
  },
  {
    firstName: "NAGENDRA KUMAR",
    lastName: "SHINHA",
    address: "SINHA MEDICAL HALL*MAIN ROAD*CHHATARPUR*PALAMU",
    fullName: "NAGENDRA KUMAR SHINHA",
    saID: "SPR8319",
  },
  {
    firstName: "SARAT KUMAR",
    lastName: "PANDA",
    address: "FLAT NO 12,BLOCK 1*ISM DHANBAD*DHANBAD*PAN-BDSPP1466P",
    fullName: "SARAT KUMAR PANDA",
    saID: "SPR8320",
  },
  {
    firstName: "ANANDA KUMAR",
    lastName: "SINGH",
    address: "VILL&PO-HARIHARPUR*GARAWA",
    fullName: "ANANDA KUMAR SINGH",
    saID: "SPR8321",
  },
  {
    firstName: "AJAY KUMAR",
    lastName: "SINGH",
    address: '"SHAKUN NIVAS"BELABAGAN*DEOGHAR*PAN-AEJPS3088R',
    fullName: "AJAY KUMAR SINGH",
    saID: "SPR8322",
  },
  {
    firstName: "VIDYANANDA",
    lastName: "DAS",
    address: "VILL&PO-SANHOULI*KHAGARIA 851 205*PAN-AGPPD7306M",
    fullName: "VIDYANANDA DAS",
    saID: "SPR8323",
  },
  {
    firstName: "SATYANARAYAN",
    lastName: "JHA",
    address: "GANDHI NAGAR KATRAHIYA*PO-LAXMI SAGAR*DIST-DARBHANGA",
    fullName: "SATYANARAYAN JHA",
    saID: "SPR8324",
  },
  {
    firstName: "PANKAJ KUMAR",
    lastName: "BARAL",
    address:
      "AT-SRIPUR COLONY*PO-SONGHADHWA*VIA-BATHUA BAZAR*GOPALGANJ*PAN-AUOPB6914E",
    fullName: "PANKAJ KUMAR BARAL",
    saID: "SPR8325",
  },
  {
    firstName: "ANUPAM",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANUPAM CHAKRABORTY",
    saID: "SPR8326",
  },
  {
    firstName: "SHIBNATH",
    lastName: "DEBNATH",
    address: "VILL-DHUBAHAR*PO-HALIDANA*NORTHDINAJPUR 733127",
    fullName: "SHIBNATH DEBNATH",
    saID: "SPR8327",
  },
  {
    firstName: "ASH BAHADUR",
    lastName: "ALE",
    address:
      "QR NO NT-III/319 MAITRINAGAR*ARMAPUR ESTATE*KANPUR*U.P PAN AEGPA4842J",
    fullName: "ASH BAHADUR ALE",
    saID: "SPR8328",
  },
  {
    firstName: "BANA BIHARI",
    lastName: "MISHRA",
    address:
      "F-28,STAFF QUARTER CAMPUS-5*KIMS KIIT UNIVERSITY*PATIA*BHUBANESWAR 751 024",
    fullName: "BANA BIHARI MISHRA",
    saID: "SPR8329",
  },
  {
    firstName: "BARA BOURANI MAA",
    lastName: "",
    address: "",
    fullName: "BARA BOURANI MAA ",
    saID: "SPR8330",
  },
  {
    firstName: "AJOY KUMAR",
    lastName: "BEHERA",
    address: "AT-NALAPADA*PO-DEULPARA*CUTTACK 754212*PAN-AIWPB7713P",
    fullName: "AJOY KUMAR BEHERA",
    saID: "SPR8331",
  },
  {
    firstName: "RAMA CHANDRA",
    lastName: "NAYAK",
    address:
      "AT-PLOT NO-2440,BADAGADA *PO-BADAGADA*BHUBANESWAR-18*DIST-KHURDA*ODISHA",
    fullName: "RAMA CHANDRA NAYAK",
    saID: "SPR8332",
  },
  {
    firstName: "DILIP KUMAR",
    lastName: "TARAFDER",
    address: "BRDB*BAGERHAT*BANGLADESH",
    fullName: "DILIP KUMAR TARAFDER",
    saID: "SPR8333",
  },
  {
    firstName: "MILAN CHANDRA",
    lastName: "BISWAS",
    address: "VILL-BADARPUR*PO-HOSSAINPUR*DIST-NARSINGDI*BANGLADESH",
    fullName: "MILAN CHANDRA BISWAS",
    saID: "SPR8334",
  },
  {
    firstName: "MONIMOHAN",
    lastName: "BISWAS",
    address: "VILL-DOHARI*PO-SOFALA KATHI*PS-KESHABPUR*DIST-JESSORE*BANGLADESH",
    fullName: "MONIMOHAN BISWAS",
    saID: "SPR8335",
  },
  {
    firstName: "TAPAN",
    lastName: "TRIPURA",
    address: "VILL-SUKENDRAIPARA*PO-RAMGHAR*DIST-KHAGRACHARI*BANGLADESH",
    fullName: "TAPAN TRIPURA",
    saID: "SPR8336",
  },
  {
    firstName: "PRADIP KUMAR",
    lastName: "CHOWDHURY",
    address: "VILL-GARJANTALI*PO/PS-RAMGARH*DIST-KHAGRACHARI*BANGLADESH",
    fullName: "PRADIP KUMAR CHOWDHURY",
    saID: "SPR8337",
  },
  {
    firstName: "ANJAN KUMAR",
    lastName: "SHIL",
    address: "ANJAN TAILORS*AT/PO-BHANDARIA-8550*DIST-PIROJPUR*BANGLADESH",
    fullName: "ANJAN KUMAR SHIL",
    saID: "SPR8338",
  },
  {
    firstName: "SHISHU RANJAN",
    lastName: "ROY",
    address: "PHIROJPUR SADAR*PHIROJPUR*BANGLADESH.",
    fullName: "SHISHU RANJAN ROY",
    saID: "SPR8339",
  },
  {
    firstName: "RAJ KUMAR",
    lastName: "SARAF",
    address:
      "C/O SHANKAR LAL SARAF*PO-GAURIPUR*WARD NO-4*DHUBRI*PAN-AIBPS5052P",
    fullName: "RAJ KUMAR SARAF",
    saID: "SPR8340",
  },
  {
    firstName: "GAUTAM",
    lastName: "BRAHMA",
    address: "GAURIPUR WORD NO-1*PO-GAURIPUR*DHUBRI 783331",
    fullName: "GAUTAM BRAHMA",
    saID: "SPR8341",
  },
  {
    firstName: "ASHOKE KUMAR",
    lastName: "SARAF",
    address: "C/O-RIDDHI BASTRALAYA*PO-GAURIPUR*DHUBRI 783331*PAN-AJCPS6153M",
    fullName: "ASHOKE KUMAR SARAF",
    saID: "SPR8342",
  },
  {
    firstName: "BHAGABAN",
    lastName: "DAS",
    address: "MANIMUGDHANAGAR,SASAL,SIXMILE*PO-KHANAPARA*GAUHATI*KAMRUP METRO",
    fullName: "BHAGABAN DAS",
    saID: "SPR8343",
  },
  {
    firstName: "GURUPADA",
    lastName: "SARMA",
    address:
      "C/O SWASTI MEDICAL STORE*PO-PATHSALA*BARPETA-781325*PAN-AFUPS5471E",
    fullName: "GURUPADA SARMA",
    saID: "SPR8344",
  },
  {
    firstName: "SUDHIR",
    lastName: "KUMAR",
    address: "DURGESH SADAN*ASHOK NAGAR*POKHARIA*BEGUSARAI*PAN-AHCPK8105B",
    fullName: "SUDHIR KUMAR",
    saID: "SPR8345",
  },
  {
    firstName: "RAMNATH",
    lastName: "MARANDI",
    address: "VILL-KOCHAGORA*PO-BANDI*DIST-BOKARO*PAN-AJVPM9268Q",
    fullName: "RAMNATH MARANDI",
    saID: "SPR8346",
  },
  {
    firstName: "JANAK PRASAD",
    lastName: "SINGH",
    address: "MAURYA VIHAR COLONY*TRANSPORT NAGAR*KUMHRAR*PATNA 26",
    fullName: "JANAK PRASAD SINGH",
    saID: "SPR8347",
  },
  {
    firstName: "SAJAL KUMAR",
    lastName: "GHOSH",
    address:
      "FLAT NO 9.SARTHI SWAPNA*PAUD ROAD*KOTHRUD*PUNE-411038*PAN-ACKPG2176M",
    fullName: "SAJAL KUMAR GHOSH",
    saID: "SPR8348",
  },
  {
    firstName: "HIRAMAN",
    lastName: "PAL",
    address:
      "SAGAR COMPLEX FLAT NO-15*BHEKRAI NAGAR*FURSUNGI*PUNE 8*MAHARASHTRA*PAN-AJNPP3111B",
    fullName: "HIRAMAN PAL",
    saID: "SPR8349",
  },
  {
    firstName: "BANKIM CHANDRA",
    lastName: "PAL",
    address: "AT/PO-KAMARDA*BALASORE.",
    fullName: "BANKIM CHANDRA PAL",
    saID: "SPR8350",
  },
  {
    firstName: "GURU PRASANNA",
    lastName: "PANDA",
    address:
      "C/O-GOPINATH PANDA*SWASTI CHALAN BUILDING*NEAR PWD BULDG*UTTARESWAR,SORO*BALASORE.",
    fullName: "GURU PRASANNA PANDA",
    saID: "SPR8351",
  },
  {
    firstName: "LAMBODAR",
    lastName: "MOHANTA",
    address: "AT-ANLABENI*PO-MAUDI*MAYURBHANJ 757 034",
    fullName: "LAMBODAR MOHANTA",
    saID: "SPR8352",
  },
  {
    firstName: "DIPAK KUMAR",
    lastName: "DEB",
    address: "VILL/PO-RAJAPUR*VIA-GARJEE*TRIPURA SOUTH 799125*PAN CIFPD7537N",
    fullName: "DIPAK KUMAR DEB",
    saID: "SPR8353",
  },
  {
    firstName: "PARIMAL",
    lastName: "DEBNATH",
    address: "VILL-CHAKBERA*PO-KHOWAI T E*TRIPURA (W)",
    fullName: "PARIMAL DEBNATH",
    saID: "SPR8354",
  },
  {
    firstName: "RATNA PRASAD",
    lastName: "MALLICK",
    address: "RAMNAGAR ROAD NO.5*PO-RAMNAGAR*AGARTALA*TRIPURA WEST 799002",
    fullName: "RATNA PRASAD MALLICK",
    saID: "SPR8355",
  },
  {
    firstName: "HARIDAS",
    lastName: "DEBRAY",
    address: "VILL-TARANAGAR*PO-MOHANPUR*TRIPURA WEST",
    fullName: "HARIDAS DEBRAY",
    saID: "SPR8356",
  },
  {
    firstName: "ANISH RANJAN",
    lastName: "BHATTACHERJEE",
    address: "SWASTI KUTIR*B.S.F.PARA*AGARTALA*TRIPURA WEST*PAN AMTPB2038A",
    fullName: "ANISH RANJAN BHATTACHERJEE",
    saID: "SPR8357",
  },
  {
    firstName: "SATYA RANJAN",
    lastName: "DAS",
    address: "VILL&PO-SEKERKOTE*TRIPURA (WEST)-799130",
    fullName: "SATYA RANJAN DAS",
    saID: "SPR8358",
  },
  {
    firstName: "RUPLAL",
    lastName: "MURMU",
    address: "VILL-MAHISARADI*PO-TILURI*BANKURA",
    fullName: "RUPLAL MURMU",
    saID: "SPR8359",
  },
  {
    firstName: "ADAITYA",
    lastName: "NASKAR",
    address: "PO/VILL-NASKARPARA*JALABERIA*24PARGANAS (SOUTH)",
    fullName: "ADAITYA NASKAR",
    saID: "SPR8360",
  },
  {
    firstName: "KRITESWAR",
    lastName: "MISHRA",
    address:
      "KAKDWIP SATSANG VIHAR*PO-KAKDWIP*24 PARGANAS (SOUTH)*PAN-AIHPM5987H",
    fullName: "KRITESWAR MISHRA",
    saID: "SPR8361",
  },
  {
    firstName: "RADHAKANTA",
    lastName: "MAHANTA",
    address: "SAGARBHANGA COLONY,F-95*DURGAPUR-11*DIST-BURDWAN*PAN-AKPPM6524Q",
    fullName: "RADHAKANTA MAHANTA",
    saID: "SPR8362",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "SINGH",
    address: "97/B,GANGA NAGAR*DEWAS*M.P.-455001.",
    fullName: "ASHOK KUMAR SINGH",
    saID: "SPR8363",
  },
  {
    firstName: "TARAPADA",
    lastName: "MONDAL",
    address: "FRAV-22,ULLASH MINI TOWNSHIP*PO-JOTRAM*BURDWAN 713 101.",
    fullName: "TARAPADA MONDAL",
    saID: "SPR8364",
  },
  {
    firstName: "TARUN KANTI",
    lastName: "KARMAKAR",
    address:
      "QR NO-B 29/2,M T P S COLONEY*PO-M T P S DVC*PO&DT-BANKURA 722183*PAN-AGLPK2234K",
    fullName: "TARUN KANTI KARMAKAR",
    saID: "SPR8365",
  },
  {
    firstName: "SUJAN",
    lastName: "MAHAJAN",
    address: "VILL-HABILASHDWIP*PO_SAKPURA*CHITTAGAON*BANGLADESH",
    fullName: "SUJAN MAHAJAN",
    saID: "SPR8366",
  },
  {
    firstName: "SUDIP",
    lastName: "CHOWDHURY",
    address: "SANCHARI*MOHANA B-91,KORERPARA*PATHANTULA*SYLHET*BANGLADESH",
    fullName: "SUDIP CHOWDHURY",
    saID: "SPR8367",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "ROY",
    address: "VILL-MADHUBATI*PO-SRIPUR*VIA-KAMARPUKUR*DIST-HOOGHLY-712612",
    fullName: "SWAPAN KUMAR ROY",
    saID: "SPR8368",
  },
  {
    firstName: "ARUN KUMAR",
    lastName: "VERMA",
    address: "10/82 A GANDHINAGAR*SHUKLAGANJ*DIST-UNNAO*U.P.*PAN-AJFPV6277J",
    fullName: "ARUN KUMAR VERMA",
    saID: "SPR8369",
  },
  {
    firstName: "MANOJ",
    lastName: "KUMAR",
    address: "RATU ROAD*RANCHI*PAN-BJJPK9608L",
    fullName: "MANOJ KUMAR",
    saID: "SPR8370",
  },
  {
    firstName: "BARABAURANIMA",
    lastName: "",
    address: "",
    fullName: "BARABAURANIMA ",
    saID: "SPR8371",
  },
  {
    firstName: "SAGAR KUMAR",
    lastName: "PATI",
    address:
      "CS 2/2 THAKUR BARI*NAIGAON CROSS ROAD*MMGS MARG*NEAR DADAR P.O.*DADAR (E)*MUMBAI 14",
    fullName: "SAGAR KUMAR PATI",
    saID: "SPR8372",
  },
  {
    firstName: "SHYAMAL",
    lastName: "BHATTACHERJEE",
    address: "C/O-SATSANG VIHAR,AGARTALA*PO-AGARTALA*799001*TRIPURA WEST",
    fullName: "SHYAMAL BHATTACHERJEE",
    saID: "SPR8373",
  },
  {
    firstName: "PUJANIYA ASHOK",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "PUJANIYA ASHOK CHAKRABORTY",
    saID: "SPR8374",
  },
  {
    firstName: "ALOK",
    lastName: "NATH",
    address: "",
    fullName: "ALOK NATH",
    saID: "SPR8375",
  },
  {
    firstName: "SUSANTA KUMAR",
    lastName: "DAS",
    address: "AT-KUNINDA*PO-RENANDAPATANA*VIA-BHUBAN*DHENKANAL*PAN-AHLPD6802D",
    fullName: "SUSANTA KUMAR DAS",
    saID: "SPR8376",
  },
  {
    firstName: "AMITESH",
    lastName: "SARKAR",
    address: "PO/VILL-MASHYAMPUR*24PARGANAS (N)",
    fullName: "AMITESH SARKAR",
    saID: "SPR8377",
  },
  {
    firstName: "TUMBHANATH",
    lastName: "BEHERA",
    address: "",
    fullName: "TUMBHANATH BEHERA",
    saID: "SPR8378",
  },
  {
    firstName: "TUMBHANATH",
    lastName: "BEHERA",
    address: "",
    fullName: "TUMBHANATH BEHERA",
    saID: "SPR8379",
  },
  {
    firstName: "ARKADYUTI",
    lastName: "CHAKRAVARTY",
    address: "",
    fullName: "ARKADYUTI CHAKRAVARTY",
    saID: "SPR8380",
  },
  {
    firstName: "TRIDIBNATH",
    lastName: "SAHA",
    address: "PO/VILL-ABHOYNAGAR*AGARTALA*WEST TRIPURA-799005*PAN-BHKPS1084C",
    fullName: "TRIDIBNATH SAHA",
    saID: "SPR8381",
  },
  {
    firstName: "DIPAK",
    lastName: "DAS",
    address:
      "40 (93.1)MUKHERJEE PARA*HARISABA*PO-CHATRA,SREERAMPUR*HOOGHLY 712 204.",
    fullName: "DIPAK DAS",
    saID: "SPR8382",
  },
  {
    firstName: "PRABHASH CHANDRA",
    lastName: "ROY",
    address: "VILL-MADHYA CHHEKAMARI*MADARIHAT*DIST-ALIPURDUAR*PAN-APFPR3476M",
    fullName: "PRABHASH CHANDRA ROY",
    saID: "SPR8383",
  },
  {
    firstName: "GOURKISHOR",
    lastName: "DEYJOARDAR",
    address:
      "GOSSSAIGAON WORD NO-4*PO-GOSSAIGAON*KOKRAJHAR 783360*PAN-AKAPD5782G",
    fullName: "GOURKISHOR DEYJOARDAR",
    saID: "SPR8384",
  },
  {
    firstName: "SALIL KUMAR",
    lastName: "MAITI",
    address:
      '"DHRITAYAN"*ROW HOUSE # 1,S NO-76/1/1*SAI RESIDENCY*PO-BANER*PUNE-45*PAN-AAPPM9997B',
    fullName: "SALIL KUMAR MAITI",
    saID: "SPR8385",
  },
  {
    firstName: "RABINDRA CHANDRA",
    lastName: "DAS",
    address: "VILL-BIRBAL DAS PARK*PO-AMARPUR*TRIPURA SOUTH",
    fullName: "RABINDRA CHANDRA DAS",
    saID: "SPR8386",
  },
  {
    firstName: "PABITRA KUMAR",
    lastName: "DEB",
    address: "VILL-GOPALNAGAR*PO-KALYANPUR*TRIPURA WEST",
    fullName: "PABITRA KUMAR DEB",
    saID: "SPR8387",
  },
  {
    firstName: "NARAYAN CHANDRA",
    lastName: "MAHATO",
    address: "AT&PO-BAGHMARA BALIAPUR*DHANBAD 828201*PAN-AXWPM1882D",
    fullName: "NARAYAN CHANDRA MAHATO",
    saID: "SPR8388",
  },
  {
    firstName: "",
    lastName: "",
    address: "",
    fullName: " ",
    saID: "SPR8389",
  },
  {
    firstName: "RAM CHANDRA",
    lastName: "SWARNAKAR",
    address: "VILL-TINGHARIA*PO-CHARBABUPUR*KALIACHAK*MALDA*PAN-BVTPS1925D",
    fullName: "RAM CHANDRA SWARNAKAR",
    saID: "SPR8390",
  },
  {
    firstName: "SUMAN",
    lastName: "ISLAM",
    address: "ISLAMPUR SATSANG VIHAR*ISLAMPUR*UTTAR DINAJPUR*PAN NO.ADQPI7856P",
    fullName: "SUMAN ISLAM",
    saID: "SPR8391",
  },
  {
    firstName: "DEBENDRA",
    lastName: "MAHATO",
    address:
      "GYANDEEP COLONY,PANDAV NAGAR NO-4*CHAKRAPANI BASAHAT ROAD*BHIOSARI*PUNE*AKIPM8588R",
    fullName: "DEBENDRA MAHATO",
    saID: "SPR8392",
  },
  {
    firstName: "RAM BABU",
    lastName: "RAI",
    address:
      "C/O SATSANG VIHAR CHAKAN*AT&PO-CHAKAN,TAL KHED*PUNE.*PAN-AFSPR2461K",
    fullName: "RAM BABU RAI",
    saID: "SPR8393",
  },
  {
    firstName: "MUKESH KUMAR",
    lastName: "JHA",
    address:
      "VILL-SANKARPUR*PO-ANDAHA*VIA-PANDUAL*DT-MADHUBANI BIHAR*PAN- ANOPJ3961G",
    fullName: "MUKESH KUMAR JHA",
    saID: "SPR8394",
  },
  {
    firstName: "SWAPAN KUMAR",
    lastName: "BOSE",
    address:
      "19 RADHA GOVINDA STREET*CIRCULAR ROAD*RANCHI-843001*PAN-ACCPU2818H",
    fullName: "SWAPAN KUMAR BOSE",
    saID: "SPR8395",
  },
  {
    firstName: "ARUP  KUMAR",
    lastName: "PAL",
    address: "THARPAKHNA*RANCHI*PAN-ALHPP 6399R",
    fullName: "ARUP  KUMAR PAL",
    saID: "SPR8396",
  },
  {
    firstName: "SHRAWAN",
    lastName: "PRADHAN",
    address:
      "C 120 SATELITE COLONY*PO-DHURWA*RANCHI 834004 JHARKHAND*PAN-ABYPP1730R",
    fullName: "SHRAWAN PRADHAN",
    saID: "SPR8397",
  },
  {
    firstName: "SOUMYA SIDDHA",
    lastName: "CHAKRABORTY",
    address:
      "227/2/C,RAJA RAMMOHAN ROY ROAD*NETAJI SARAK*BARISHA(E)*KOLKATA-8 PAN AZVPC4591Q",
    fullName: "SOUMYA SIDDHA CHAKRABORTY",
    saID: "SPR8398",
  },
  {
    firstName: "DEBASHISH",
    lastName: "GUPTA",
    address:
      "E2-3B* DHRITI ASHRAY*SAIL CITY,NEW PUNDAG*DHURVA*RANCHI*PAN-ACEPG4145Q",
    fullName: "DEBASHISH GUPTA",
    saID: "SPR8399",
  },
  {
    firstName: "UTTAM KUMAR",
    lastName: "SHINHA",
    address: "ELECTRICAL WIRES SUB-DIVN*KANKE*RANCHI 834006*PAN-ADAPS5204R",
    fullName: "UTTAM KUMAR SHINHA",
    saID: "SPR8400",
  },
  {
    firstName: "ARJUN PRASAD",
    lastName: "SINGH",
    address: "VILL-AURSUNDY*PO-BORIA*RANCHI 834007.*PAN-AGKPS1207R",
    fullName: "ARJUN PRASAD SINGH",
    saID: "SPR8401",
  },
  {
    firstName: "RUDRA",
    lastName: "BHUJEL",
    address:
      "SILIGURI SATSANG VIHAR*PO KADAM TALA*DARJEELING(SHIFTED TO NEPAL)",
    fullName: "RUDRA BHUJEL",
    saID: "SPR8402",
  },
  {
    firstName: "ANIDYANDADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "ANIDYANDADYUTI CHAKRABORTY",
    saID: "SPR8403",
  },
  {
    firstName: "SUBHENDRA NATH",
    lastName: "NAYAK",
    address:
      '""ASHIRWAD""*AT/PO-B.SITHALO*VIA-BETNOTI*BAHANADA ROAD*MAYURBHANJ-757026',
    fullName: "SUBHENDRA NATH NAYAK",
    saID: "SPR8404",
  },
  {
    firstName: "BROJENDRA NATH",
    lastName: "NARJARI",
    address: "H M,JARAGURI HIGH SCHOOL*PO-JARAGURI*KOKRAJHAR",
    fullName: "BROJENDRA NATH NARJARI",
    saID: "SPR8405",
  },
  {
    firstName: "VISWA BHUSAN",
    lastName: "JOSHI",
    address: "UPPER MAWPREM*PO-SHILLONG*MEGHALAYA-793002",
    fullName: "VISWA BHUSAN JOSHI",
    saID: "SPR8406",
  },
  {
    firstName: "ANISH",
    lastName: "SAHA",
    address: "SATSANG VIHAR TURA*DT-WEST GARO HILLS*MEGHALAYA*PAN-BPUPS8428A",
    fullName: "ANISH SAHA",
    saID: "SPR8407",
  },
  {
    firstName: "BIRBAL",
    lastName: "DEBNATH",
    address: "C/O-LHOMITHI COLONEY*P/O-DIMAPUR.KOHIMA*NAGALAND 797112",
    fullName: "BIRBAL DEBNATH",
    saID: "SPR8408",
  },
  {
    firstName: "MANORANJAN",
    lastName: "PRUSTY",
    address: "NO 5 4th MAIN*HOSAPALYA*BOMMANAHALLI*BANGALORE 68*PAN AKOPP6483G",
    fullName: "MANORANJAN PRUSTY",
    saID: "SPR8409",
  },
  {
    firstName: "SANKARSAN",
    lastName: "SAHOO",
    address:
      "C/O-ASTIK ELECTRONICS*TULASICHOURA*BARIPADA*MAYURBHANJ 757001*PAN-AWNTS9070M",
    fullName: "SANKARSAN SAHOO",
    saID: "SPR8410",
  },
  {
    firstName: "BIRABAR",
    lastName: "ROUT",
    address:
      "252/3323,TAPASWINI*KIIT POST OFFICE*BHUBANESWAR-751024*(PAN-ACBPR8626C)",
    fullName: "BIRABAR ROUT",
    saID: "SPR8411",
  },
  {
    firstName: "SALIL KUMAR",
    lastName: "DAS",
    address: "V-LASKARPARA*PO-BARABISHA*DIST-ALIPURDUAR*PAN-AEZPD5499P",
    fullName: "SALIL KUMAR DAS",
    saID: "SPR8412",
  },
  {
    firstName: "MANJIT KUMAR",
    lastName: "GAGAI",
    address: "TWIN BOX*B G ROAD*SIBSAGAR 785640*PAN-AEKPG7218J",
    fullName: "MANJIT KUMAR GAGAI",
    saID: "SPR8413",
  },
  {
    firstName: "PRADIPTA",
    lastName: "DAS",
    address: "VILL-BALIPIPLA*PO-BALIPIPLA BAZAR*KARIMGANJ*PAN-BHJPD8155N",
    fullName: "PRADIPTA DAS",
    saID: "SPR8414",
  },
  {
    firstName: "CHANDI CHARAN",
    lastName: "MAHATO",
    address: "VILL/PO-SILDA*DT-PASCHIM MEDNIPUR*CXTPM6649D",
    fullName: "CHANDI CHARAN MAHATO",
    saID: "SPR8415",
  },
  {
    firstName: "NITYA BINOD",
    lastName: "WARIE",
    address:
      "VILL-NO-1 SEKADANI*PO-KACHUGAON*DIST-KOKRAJHAR,B T C*ASSAM  783350",
    fullName: "NITYA BINOD WARIE",
    saID: "SPR8416",
  },
  {
    firstName: "NANADAN KUMAR",
    lastName: "PAUL",
    address: "140,HEALE AVENUE*SCARBOROUGH,ONTARIO*CANADA,MI3Y1",
    fullName: "NANADAN KUMAR PAUL",
    saID: "SPR8417",
  },
  {
    firstName: "REV ARKDYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "REV ARKDYUTI CHAKRABORTY",
    saID: "SPR8418",
  },
  {
    firstName: "KANA",
    lastName: "SAHA",
    address: "BURABURITOLA.RATHBARI*MALDA*PAN-FUGPS7998Q",
    fullName: "KANA SAHA",
    saID: "SPR8419",
  },
  {
    firstName: "ASHOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "76,B.B.GANGULY STREET*BLOCK-B,IST FLOOR*KOLKATA-700012.",
    fullName: "ASHOK KUMAR CHAKRABARTY",
    saID: "SPR8420",
  },
  {
    firstName: "SUNIRMAL SURENDRA",
    lastName: "KABIRAJ",
    address: "KRISHNARJUN APARTMENTS*MANKAPUR*NAGPUR*M.S 440030",
    fullName: "SUNIRMAL SURENDRA KABIRAJ",
    saID: "SPR8421",
  },
  {
    firstName: "]ARKADYUTI",
    lastName: "CHAKRABORTY",
    address: "",
    fullName: "]ARKADYUTI CHAKRABORTY",
    saID: "SPR8422",
  },
  {
    firstName: "TRIDIBESH",
    lastName: "NARZARY",
    address: "H.NO-194,W.NO-7,BAGANSHALI*PO/DIST-KOKRAJHAR",
    fullName: "TRIDIBESH NARZARY",
    saID: "SPR8423",
  },
  {
    firstName: "SUDHINDRA KUMAR",
    lastName: "DASH",
    address:
      "HOUSE NO-415;5TH D MAIN*SECOND BLOCK;HRBR LAYOUT*KALYAN NAGAR*BANGALORE 43",
    fullName: "SUDHINDRA KUMAR DASH",
    saID: "SPR8424",
  },
  {
    firstName: "RANJIT",
    lastName: "DHAR",
    address: "KHARULIA*RAMU*COX BAZAR*BANGLADESH",
    fullName: "RANJIT DHAR",
    saID: "SPR8425",
  },
  {
    firstName: "KIRAN CHANDRA",
    lastName: "MITRI",
    address:
      "VILL-UTTAR KAKCHIRA*PO-GUDIGHATA KAKCHIRA*UZ-BAMANA*DIST-BARGUNA*BANGLADESH",
    fullName: "KIRAN CHANDRA MITRI",
    saID: "SPR8426",
  },
  {
    firstName: "KAMENDRA",
    lastName: "SARKAR",
    address: "SATPAI*PO/DIST-NETROKUNA*POST CODE-2400*BANGLADESH",
    fullName: "KAMENDRA SARKAR",
    saID: "SPR8427",
  },
  {
    firstName: "NISHITH NATH",
    lastName: "SWAIN",
    address: '"SUDHA NILAY"*1331,MAHANADI VIHAR*CUTTACK-4',
    fullName: "NISHITH NATH SWAIN",
    saID: "SPR8428",
  },
  {
    firstName: "BISWESWAR",
    lastName: "CHATTERJEE",
    address: "AT&PO-JAMALPUR*BURDWAN",
    fullName: "BISWESWAR CHATTERJEE",
    saID: "SPR8429",
  },
  {
    firstName: "SUBHASIS",
    lastName: "HARCHOWDHURY",
    address: "1/14 KUMUD GHOSAL ROAD*BAISHALI*KOL-700057",
    fullName: "SUBHASIS HARCHOWDHURY",
    saID: "SPR8430",
  },
  {
    firstName: "DWIJEN",
    lastName: "MAITY",
    address:
      "C/O DEBENDRA NATH MAITY*JAMALDAHA SATSANG VIHAR*VILL&PO-JAMALDAHA*COOCHBIHAR",
    fullName: "DWIJEN MAITY",
    saID: "SPR8431",
  },
  {
    firstName: "PUJONIYA  CHATOBAUDI",
    lastName: "",
    address: "",
    fullName: "PUJONIYA  CHATOBAUDI ",
    saID: "SPR8432",
  },
  {
    firstName: "REV ALOK",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK CHAKRABARTY",
    saID: "SPR8433",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRABARTY",
    saID: "SPR8434",
  },
  {
    firstName: "REV ALOK KUMAR",
    lastName: "CHAKRABARTY",
    address: "",
    fullName: "REV ALOK KUMAR CHAKRABARTY",
    saID: "SPR8435",
  },
  {
    firstName: "ANTIK",
    lastName: "",
    address: "",
    fullName: "ANTIK ",
    saID: "SPR8436",
  },
  {
    firstName: "SUBINOY",
    lastName: "DAS",
    address: "NEW YORK,USA",
    fullName: "SUBINOY DAS",
    saID: "SPR8450",
  },
  {
    firstName: "BISWAJIT",
    lastName: "GOUDA",
    address: "3105 HERITAGE DRIVE 12 6128063254 MINNEAPOLIS, MN,USA",
    fullName: "BISWAJIT GOUDA",
    saID: "SPR8451",
  },
  {
    firstName: "PRIYAM",
    lastName: "BAIDYA",
    address: "465 BUCKLAND HILLS DR APT# 25314 MANCHESTER, CT,USA",
    fullName: "PRIYAM BAIDYA",
    saID: "SPR8452",
  },
  {
    firstName: "SAMINDRA",
    lastName: "MISHRA",
    address: "DELAWARE,USA",
    fullName: "SAMINDRA MISHRA",
    saID: "SPR8453",
  },
  {
    firstName: "DHANRAJ",
    lastName: "BHATTRAI",
    address: "CHANDRA NAGAR,BHUTWAL-6,BHUTWAL,NEPAL",
    fullName: "DHANRAJ BHATTRAI",
    saID: "SPR8454",
  },
];
