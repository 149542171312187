import React, { useContext, useEffect } from "react";
import AlertContext from "../../context/alert/alertContext";
import PropTypes from "prop-types";

const AlertContainer = () => {
  const alertContext = useContext(AlertContext);
  // if (msg.length == 0) {
  //   msg = accountContext.alertMessage
  // }
  const { type, msg } = alertContext;

  if (msg != "") {
    document.getElementById("showmodal").click();
  }

  let value;
  switch (type.toLowerCase()) {
    case "success": {
      value = " uk-modal-body uk-background-success uk-text-center";
      break;
    }
    case "danger":
      value = "uk-modal-body uk-background-danger uk-text-center";
      break;
    case "info":
      value = " uk-modal-body uk-background-default uk-text-center";
      break;
    default:
      value = " uk-modal-body uk-background-default uk-text-center";
      break;
  }

  return (
    <>
      <button
        id="showmodal"
        className="uk-hidden"
        uk-toggle="target: #alertdialog"
        type="button"
      ></button>

      <div id="alertdialog" uk-modal="true">
        <div className="uk-modal-dialog uk-card-small">
          <div className="uk-modal-header">
            <h2 className="uk-modal-title uk-text-lead uk-text-center">
              Message
            </h2>
          </div>

          <div className={value}>{msg}</div>

          <div className="uk-modal-footer uk-text-right">
            <button
              className="uk-button uk-button-secondary uk-align-center uk-modal-close"
              type="button"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

AlertContainer.prototype = {
  type: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
};

AlertContainer.defaultProps = {
  type: "",
  msg: "",
};
export default AlertContainer;
