import React, { useState, useContext, useEffect } from "react";
import download from "downloadjs";

import AccountContext from "../../context/accounts/accountContext";
import SpinnerContext from "../../context/spinner/spinnerContext";

const ThreeYearReport = ({country}) => {
  const accountContext = useContext(AccountContext);
  const { getKarmiList, getKarmi3yrReport } = accountContext;

  const spinnerConext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerConext;

  const [state, setState] = useState({ karmilist: [], karmi: 0 });
  const { karmilist, karmi } = state;

  useEffect(() => {
    const getList = async () => {
      startSpinner();
     
      const { type, msg } = await getKarmiList(country);
      setState({ ...state, karmilist: msg });
      stopSpinner();
    };

    getList();
  }, [country]);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onGenerate = async () => {
    startSpinner();
    let res = await getKarmi3yrReport(karmi);
    const content = res.headers["content-type"];
    //const blob = await res.blob()
    download(res.data, "KarmiReport.pdf", content);
    stopSpinner();
  };

  return (
    <div className="uk-container uk-card-body">
      <h3 className="uk-card-title">3 Year Karmi Report</h3>
      <div>
        <select
          className="uk-select uk-width-2-5@s"
          name="karmi"
          onChange={onChange}
        >
          <option value="0">--Select Karmi--</option>
          {karmilist.map((karmi) => (
            <option value={karmi._id}>
              {karmi.name}({karmi.fcnum})
            </option>
          ))}
        </select>
      </div>
      <br></br>
      <div>
        <button
          className="uk-button uk-button-primary uk-align-left"
          type="button"
          onClick={onGenerate}
        >
          GENERATE
        </button>
      </div>
    </div>
  );
};

export default ThreeYearReport;
