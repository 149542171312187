import React, { createContext, useContext, useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest } from "../request/api_request";

const BooksContext = createContext();

export const BooksProvider = ({ children }) => {
    const [booksState, setBooksState] = useState({
        books: [],
        book: {},
        loading: false,
        error: null,
        fetched: false, // To track if data is already fetched
    });

    const fetchBooks = useCallback(async (id) => {
       // if (booksState.fetched) return; // Skip fetching if already fetched
        setBooksState((prev) => ({ ...prev, loading: true }));
        console.log('fetchBooks', id)
        const endpoint = !!id ? ENDPOINTS.LIBRARY_BOOKS+`?bookId=${id}` : ENDPOINTS.LIBRARY_BOOKS;
        try {
            const res = await sendGetRequest({ endpoint });
            !!id ? setBooksState({ book: res.data.data[0],books:[], loading: false, error: null, fetched: true }) :
            setBooksState({ books: res.data.data,book:{}, loading: false, error: null, fetched: true });
        } catch (error) {
            setBooksState((prev) => ({ ...prev, loading: false, error: error.message }));
        }
    }, [booksState.fetched]);

    return (
        <BooksContext.Provider value={{ ...booksState, fetchBooks }}>
            {children}
        </BooksContext.Provider>
    );
};

export const useBooks = () => useContext(BooksContext);
