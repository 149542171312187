import React, { useState, useEffect } from 'react';
import { Button } from '../presentation';

const AddEditBook = ({
  title = '',
  description = '',
  image = '',
  totalCopies = 0,
  language = 'English',
  onSubmit,
}) => {
  // State for form fields, initialized based on props or default to initial state
  const [formState, setFormState] = useState({
    title,
    description,
    image,
    language,
    totalCopies,
  });

  // Effect to update form state when props change (for editing case)
  useEffect(() => {
    setFormState({ title, description, image, totalCopies,language });
  }, [title, description, image, totalCopies, language]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formState);
    // Call the onSubmit function passed as a prop, passing formState
    onSubmit(formState);
    setFormState({ title: '', description: '', image: '', totalCopies: 0 });
  };

  return (
    <div className="uk-card uk-card-default uk-card-body uk-margin">
      <h3 className="uk-card-title">{title ? 'Edit Book' : 'Add New Book'}</h3>
      <form onSubmit={handleSubmit} className="uk-form-stacked">
        {/* Book Title */}
        <div className="uk-margin">
          <label htmlFor="title" className="uk-form-label">
            Book Title
          </label>
          <div className="uk-form-controls">
            <input
              id="title"
              name="title"
              type="text"
              className="uk-input"
              placeholder="Enter book title"
              value={formState.title}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Description */}
        <div className="uk-margin">
          <label htmlFor="description" className="uk-form-label">
            Description
          </label>
          <div className="uk-form-controls">
            <textarea
              id="description"
              name="description"
              className="uk-textarea"
              placeholder="Enter book description"
              value={formState.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
        </div>

        {/* Image URL */}
        <div className="uk-margin">
          <label htmlFor="image" className="uk-form-label">
            Image URL
          </label>
          <div className="uk-form-controls">
            <input
              id="image"
              name="image"
              type="text"
              className="uk-input"
              placeholder="Enter image URL"
              value={formState.image}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Total Copies */}
        <div className="uk-margin">
          <label htmlFor="totalCopies" className="uk-form-label">
            Total Copies
          </label>
          <div className="uk-form-controls">
            <input
              id="totalCopies"
              name="totalCopies"
              type="number"
              className="uk-input"
              min="1"
              value={formState.totalCopies}
              onChange={handleChange}
              required
            />
          </div>
        </div>

         {/* Language */}
         <div className="uk-margin">
          <label htmlFor="language" className="uk-form-label">
            Language
          </label>
          <div className="uk-form-controls">
            <input
              id="language"
              name="language"
              type="text"
              className="uk-input"
              min="1"
              value={formState.language}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="uk-margin">
          <Button type="submit" className="uk-button uk-button-primary"
           label= {title ? 'Update Book' : 'Add Book'}
           disabled={!formState.title || !formState.description || +formState.totalCopies <=0}
           
          />
        </div>
      </form>
    </div>
  );
};

export default AddEditBook;
