import React, { useContext, useState, useEffect } from 'react'
import AccountContext from '../../context/accounts/accountContext'
import AlertContext from '../../context/alert/alertContext';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios';
import EventTable from './eventtable';
import SpinnerContext from '../../context/spinner/spinnerContext'

const Events = (props) => {
  const accountContext = useContext(AccountContext);
  const alertContext = useContext(AlertContext);
  const {  user } = accountContext;


  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext

  const intialContext = {
    fcnum: user.fcnum,
    eventname: "",
    eventdate: "",
    eventtime: "",
    eventcountry: "",
    eventstate: "",
    eventhost: "",
    eventaddress: "",
    eventphone: ""
  }

  useEffect(() => {
    startSpinner();
    getMyEvents();
    stopSpinner();
  }, [])

  const [events, setEvents] = useState([]);
  const [state, setState] = useState(intialContext);

  const { eventname, eventdate, eventcountry, eventstate, eventaddress, eventphone, eventhost, eventtime } = state;
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  const selectCountry = (val) => {
    setState({ ...state, ["eventcountry"]: val });
  }
  const selectRegion = (val) => {
    setState({ ...state, ["eventstate"]: val });
  }
  const onReset = (e) => {
    e.preventDefault();
    setState(intialContext);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    startSpinner();
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token")
      }
    }
    try {
      let resp = await axios.post("api/events/add", state, config);
      alertContext.setAlert({ type: "success", msg: "Event added succesfully!" })
      setState(intialContext);
      getMyEvents();
    } catch (error) {
      alertContext.setAlert({ type: "danger", msg: error.response.data.msg })
    }
    stopSpinner();
  }

  const getMyEvents = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",

      }
    }
    const payload = {
      fcnum: user.fcnum
    }
    try {
      let resp = await axios.post(process.env.REACT_APP_API_URL+"/api/events/getevents", payload, config);
      setEvents(resp.data);
    } catch (error) {
      alertContext.setAlert({ type: "danger", msg: error.response.data.msg })
    }
  }

  return (
    <div>


      <div id="my-id" uk-modal="true">
        <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close="true"></button>
          <div className="  uk-card-default uk-width-3-4 uk-align-center">

            <h5 className="uk-text-center"><u>ADD EVENT</u></h5>

            <form className="uk-form" onSubmit={onSubmit}>
              <div className="uk-padding-small">
                <span className="uk-text">Event Description:</span>
                <input className="uk-input" name="eventname" value={eventname} placeholder="Event Description" onChange={onChange} />
              </div>
              <div className="uk-padding-small">
                <span className="uk-text">Event Date:</span>
                <input className="uk-input" type="date" name="eventdate" value={eventdate} placeholder="Event Date" onChange={onChange} />
              </div>
              <div className="uk-padding-small">
                <span className="uk-text">Event Time:</span>
                <input className="uk-input" name="eventtime" value={eventtime} placeholder="Event Time" onChange={onChange} />
              </div>

              <div className="uk-padding-small">
                <span className="uk-text"> Country:</span>
                <CountryDropdown name="eventcountry" whitelist="AU,IND,US,UK,FR,IT,NL" className="uk-select" value={state.eventcountry} onChange={(val) => selectCountry(val)} /></div>
              <div className="uk-padding-small">
                <span className="uk-text"> State:</span>
                <RegionDropdown
                  className="uk-select"
                  country={eventcountry}
                  value={eventstate}
                  onChange={(val) => selectRegion(val)} />
              </div>
              <div className="uk-padding-small">
                <span className="uk-text"> Host Name:</span>
                <input className="uk-input" name="eventhost" value={eventhost} placeholder="Host Name" onChange={onChange} />
              </div>
              <div className="uk-padding-small">
                <span className="uk-text"> Address:</span>
                <textarea className="uk-textarea" name="eventaddress" value={eventaddress} placeholder="Event Address" onChange={onChange} />
              </div>
              <div className="uk-padding-small">
                <span className="uk-text" > Contact Number:</span>
                <input className="uk-input" type="tel" name="eventphone" value={eventphone} placeholder="Phone Number" onChange={onChange} />
              </div>
              <div className="uk-padding-small uk-flex uk-flex-center">
                <button type="submit" className="uk-button uk-button-success">ADD</button>
                <button className="uk-button uk-button-primary" onClick={onReset}>RESET</button>
              </div>

            </form>


          </div>
        </div>
      </div>


      <h5 className="uk-text-center"><u>MY EVENTS</u></h5>
      <button className="uk-button uk-button-primary" uk-toggle="target: #my-id" type="button">ADD EVENT</button>
      <EventTable events={events} history={props.history} url="event" />

    </div>


  )
}

export default Events
