import React, { useContext, useState, useEffect } from "react";
import TotalDeposits from "./TotalDeposit";
import DepositSummary from "./DepositSummary";
import DepositSummaryMobile from "./DepositSummaryMobile";
import IstavrityContext from "../../context/istavrity/istavrityContext";
import AccountConext from "../../context/accounts/accountContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import AlertContext from "../../context/alert/alertContext";
import download from "downloadjs";
import ENUMS from "../../enums/Enums";

export const Summary = (props) => {
  const _id = props.match.params._id;
  const istavrityContext = useContext(IstavrityContext);
  const {
    sendEmailWithArghyaPraswasti,
    approveSubmission,
    getDeposit,
    current,
    setCurrentStatus,
    onDelete,
    deposit_codes,
    getArghyaPraswasti
  } = istavrityContext;

  useEffect(() => {
    getTheDeposit(_id);
  }, []);

  const getTheDeposit = async (_id) => {
    startSpinner();
    let res = await getDeposit(_id);
    setState({ ...state, submission: res });
    stopSpinner();
  };

  const initialState = {
    loading: false,
    disabled: "",
    res: {
      type: "",
      msg: "",
    },
    submission: {},
    showalert: false,
  };
  const [state, setState] = useState(initialState);
  const { disabled, submission } = state;

  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const accountContext = useContext(AccountConext);
  const { country, role } = accountContext.user;
  // const submission = istavrityContext.current;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  let headers = {};
  let codes = [];
  const {
    fcnum,
    depositDetails,
    systemcode,
    total,
    sumTotal,
    status,
    depositDate = "",
    type,
    paymentreference,
    documenturl,
    currency,
    approvedby,
    refId
  } = submission;

  if (depositDetails != undefined) {
    headers = depositDetails[0].amount;
    codes = Object.keys(headers);
  }


  const getKeyByValue = (value) => {
    let deposit_code = deposit_codes.find((deposit) => deposit.code == value);
    return deposit_code.key;
  };
  const approve = async () => {
    startSpinner();
    await approveSubmission();
    stopSpinner();
    setAlert({
      type: "Success",
      msg: "The transaction has been succesfully approved",
    });
    await getTheDeposit(_id);
  };

  const onDeleteDeposit = async () => {
    await onDelete(_id);
    props.history.push("/submissions");
  };
  return (
    <div>
      {Object.entries(submission).length > 0 && (
        <>
          <div className="uk-box-shadow-small">
            <h3 className="uk-card-title uk-text-center ">
              {" "}
              <u>SUMMARY</u>
            </h3>
            {status != "Draft" &&
              <div className="uk-flex uk-flex-right">
                <span>
                <span className="uk-text-bold">Transaction Reference No:</span> 
                <span className="uk-text-large uk-margin-left uk-text-success" id="paymentRef">{paymentreference}</span>
                </span>
                
              </div>}

            <div id="summary0">
              <table className="uk-table uk-table-small uk-table-large@s">
                <thead>
                  <tr className="">
                    <th className="uk-text-center uk-background-cb-2">
                      <span className="uk-text-bold uk-text-white uk-text-large@s uk-text-center">
                        Action
                      </span>
                    </th>
                    <th className="uk-text-center uk-background-cb-2">
                      <span className="uk-text-bolder uk-text-large@s uk-text-center uk-text-white">
                        Status
                      </span>
                    </th>
                    <th className="uk-text-center uk-background-cb-2">
                      <span className="uk-text-bolder uk-text-large@s uk-text-center uk-text-white">
                        Deposit Reference
                      </span>
                    </th>
                    <th className="uk-text-center uk-background-cb-2">
                      <span className="uk-text-bolder uk-text-large@s uk-text-center uk-text-white">
                        Approved By
                      </span>
                    </th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="uk-flex uk-flex-center" id="action">
                        {status != "Done" && role == 0 && (
                          <button
                            className="uk-button uk-button-danger "
                            onClick={onDeleteDeposit}
                          >
                            DELETE<span uk-icon="file-delete"></span>
                          </button>
                        )}
                        {status == "Done" && (
                          <>
                           
                            <button
                              className="uk-button uk-button-small@s uk-button-primary"
                              {...disabled}
                              onClick={async ()=>{const res = await getArghyaPraswasti(_id)
                                const content = res.headers["content-type"];
                                //const blob = await res.blob()
                                download(res.data, "ArghyaPraswasti.pdf", content);
                              }}
                            >
                             
                                {" "}
                                Download Receipt
                             
                            </button>
                          </>
                        )}
                        {status == "Submitted" && role == 1 && (
                          <button
                            className="uk-button uk-button-success"
                            onClick={approve}
                          >
                            Approve
                          </button>
                        )}
                      </div>
                    </td>
                    <td className="uk-text-lead uk-text-large uk-text-center" id="depositStatus">
                      {/* {status == "Draft" && (
                        <span className=" uk-text-large ">{status}</span>
                      )}
                      {status == "Submitted" && (
                        <>
                          <span className="uk-text-large ">{status}</span>
                        </>
                      )} */}
                      {status == "Done" ? (
                        <span className=" uk-text-xlarge uk-text-bold uk-background-success">
                          Completed
                        </span>
                      ):
                      (
                        <>
                          <span className="uk-text-large " >{status}</span>
                        </>
                      )
                    }
                    </td>
                    <td className="uk-text-lead uk-text-large uk-text-center" id="depositRef">
                      {refId}
                    </td>
                    <td className="uk-text-lead uk-text-large uk-text-center" id="approver">
                     
                       

                          {approvedby}
                       
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div id="summary1">
              <table className="uk-table uk-table-small uk-table-large@s">
                <thead>
                  <tr className="uk-text-white">
                    <th className="uk-text-center uk-background-grey" >
                      Family code
                    </th>
                    <th className="uk-text-center uk-background-grey">
                      System code
                    </th>
                    <th className="uk-text-center uk-background-grey" >
                      Deposit Date
                    </th>
                    <th className="uk-text-center uk-background-grey" >
                      Submission type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="uk-text-center" id="fccode">{fcnum}</td>
                    <td className="uk-text-center">{systemcode}</td>
                    <td className="uk-text-center" id="despoitDate">
                      {depositDate.substr(0, 10)}
                    </td>
                    <td className="uk-text-center" id="depositType">{type.toUpperCase()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className=" uk-box-shadow-small">
            <h3 className="uk-card-title uk-text-center">
              <u>DEPOSIT SUMMARY</u>
            </h3>
            <div id="summary2" className="uk-overflow-auto uk-visible@s">
              <table className="uk-table uk-table-small uk-table-divider uk-custom-table">
                <thead>
                  <tr>
                    <th className="uk-text-center uk-background-grey">Name</th>
                    <th className="uk-text-center uk-background-grey">
                      Ritwik Name
                    </th>

                    {codes.map((code,index) => (
                      <th  key={index} className="uk-text-center uk-background-grey">
                        {code}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {depositDetails.map((deposit, index) => (
                    <DepositSummary key={index} deposit={deposit} ></DepositSummary>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="uk-hidden@s">
              <ul uk-accordion="true">
                {depositDetails.map((deposit,index) => (
                  <DepositSummaryMobile
                  key={index}
                    deposit={deposit}
                    getKeyByValue={getKeyByValue}
                  ></DepositSummaryMobile>
                ))}
              </ul>
            </div>
          </div>

          <TotalDeposits
            total={total}
            sumTotal={sumTotal}
            currency={currency}
            getKeyByValue={getKeyByValue}
          />

          {status == "Draft" && (role == ENUMS.ACCOUNT_TYPE.MEMBER) && (
            <button
              className="uk-button uk-button-success uk-button-link uk-align-right uk-text-bolder uk-text-large"
              uk-icon="arrow-right"
              onClick={() => {
                props.history.push("/payment");
              }}
            >
              Proceed To Payment
            </button>
          )}
        </>
      )}
    </div>
  );
};

