import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest, sendPostRequest, sendPutRequest } from "../request/api_request";

export const useAddUpdateBook = () => {
    const initState = {
        msg: "",
        loading: false,
        error: null,
        errors: [],
    };

    const [state, setState] = useState(initState);
    const {msg, loading, error, errors } = state;

    const addBook = useCallback(async (payload) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_ADD_EDIT_BOOK ,
                payload:[payload]
            });
            setState({ msg:"Book added Successfully ", loading: false, error: null, errors: [] });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message, errors: err.response.data.errors }));
        }
    }, []); // `useCallback` memoizes `getBooks` and prevents unnecessary re-creation

    const editBook = useCallback(async (payload) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPutRequest({ endpoint: ENDPOINTS.LIBRARY_ADD_EDIT_BOOK ,
                payload
            });
            setState({ msg:"Book updated Successfully ", loading: false, error: null, errors: [] });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message, errors: err.response.data.errors }));
        }
    }, []);

    return { msg, loading, error, errors, addBook, editBook };
};
