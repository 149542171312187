import React from "react";

const AustraliaDirectTransfer = ({ bsb, accountnum, bank, refId }) => {

  const getLine = (label, value) => {
    return (
      <span className="uk-text uk-text-large">
        <span className="uk-text-bold uk-text-secondary ">{label}: </span>
        <span className="uk-text-primary " id={label}>{value} </span>

      </span>
    )
  }
  return (
    <div>
      <section>
        <hr class="uk-divider-icon"></hr>
        <div className="uk-card uk-card-body uk-background-grey uk-text-white">
          <h4 className="uk-text-white">Steps:</h4>
          <ul className="uk-text-large uk-text-left">
            <li>Log into your internet banking</li>
            <li className="uk-margin-top">Navigate to the Payment section</li>
            <li className="uk-margin-top">Enter the <b>BSB</b> and <b>Account Number</b> as below</li>
            <li className="uk-margin-top">In the description field, enter "<b id="submissionId">{refId}</b>"</li>
            <li className="uk-margin-top">After succesful transfer , click the "SUBMIT" button</li>
            <li className="uk-margin-top">Inform the admin(Sree Subhas dada) to check the bank details and approve the submission</li>
          </ul>
        </div>


        <hr class="uk-divider-icon"></hr>
        <div className="uk-card uk-card-body uk-background-grey uk-text-white">
          <h4 className="uk-text-white">Bank Details: </h4>
          <div className="uk-card uk-card-body uk-background-muted">
            {getLine("BSB", bsb)}
            <br></br>
            <span class="uk-margin-auto">
              {getLine("Account", accountnum)}
            </span>


            <br></br>
            {getLine("Bank", bank)}

          </div>
        </div>


      </section>
    </div>
  );
};

export default AustraliaDirectTransfer;
