import React from 'react'

export const Button = (props) => {
   const { className, type, onClick, label,disabled, show }=props
   const getClassName=()=>{
        if(disabled){
            return className+" uk-button-disabled uk-button-mute"
        }
        return className
   }
   return (
    show ? (
        <button
            className={getClassName() + " uk-margin-top"}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {props.children}
            {label.toUpperCase()}
        </button>
    ):<></>
);
}
Button.defaultProps = {
    className: "uk-button uk-button-bold uk-button-primary uk-margin-top",
    type: "button",
    onClick: ()=>{},
    label: "Click me!",
    disabled:false,
    show:true
}
