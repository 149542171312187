import React, { useState, useContext } from "react";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import axios from "axios";
import { Link } from "react-router-dom";
import ENDPOINTS from "../../enums/endpoints";

const RestPassword = (props) => {
  const token = props.match.params.token;
  const alertContext = useContext(AlertContext);
  const spinnerContext = useContext(SpinnerContext);
  const initialState = {
    password: "",
    password2: "",
  };
  const [state, setState] = useState(initialState);
  const { password, password2 } = state;

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password != password2) {
      alertContext.setAlert({ type: "danger", msg: "Password do not match" });
      return;
    }
    spinnerContext.startSpinner();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const payload = { token, password };
    try {
      let resp = await axios.post(
        process.env.REACT_APP_API_URL + ENDPOINTS.RESET_PASSWORD,
        payload,
        config
      );
      console.log(resp.data.msg);
      spinnerContext.stopSpinner();
      alertContext.setAlert({ type: "success", msg: resp.data.data.msg });
      setState(initialState);
    } catch (error) {
      alertContext.setAlert({ type: "danger", msg: error.response.data.errors[0] });
      setState(initialState);
      spinnerContext.stopSpinner();
    }
  };

  return (
    <div className=" uk-width-1-2 uk-align-center uk-padding-large">
      <h2 className="uk-text-center">Password Reset Page</h2>
      <form className="ukform" onSubmit={onSubmit}>
        <div className="uk-margin-top">
          <span className="uk-text-middlle uk-text-large">Password:</span>
          <input
            type="password"
            placeholder="Password - minimum 8 charcters"
            name="password"
            minlength="8"
            value={password}
            onChange={onChange}
            required
          />
        </div>
        <div className="uk-margin-top uk-margin-bottom">
          <span className="uk-text-middlle uk-text-large">
            Confirm Password:
          </span>
          <input
            type="password"
            placeholder="Reenter Password - minimum 8 charcters"
            name="password2"
            minlength="8"
            value={password2}
            onChange={onChange}
            required
          />
        </div>

        <button
          type="submit"
          className="uk-button uk-button-primary uk-width-expand uk-flex-center "
        >
          Reset
        </button>
        <Link className="uk-align-right" to="/login">
          Continue to Login
        </Link>
      </form>
    </div>
  );
};

export default RestPassword;
