import React,{useState, useEffect} from 'react'
import StripeCard from './StripeCard'
import { loadStripe } from "@stripe/stripe-js";

const borderStyle={'border' : 'black' , 'borderStyle' : 'double'}

export const CardComponent = ({ type, _id, getStripeKeys, payDeposits, startSpinner, stopSpinner }) => {

    const [state, setState] = useState({
        sumTotal: 0,
        clientsecret: "",
        stripePromise: "",
        finalAmount: 0,
        charge: 0,
        currency: "",
    });
    const { sumTotal, clientsecret, stripePromise, finalAmount, charge, currency } = state;

    useEffect(() => {
        const retrieveKeys = async () => {
             startSpinner();
            _id && await getClientKeys();
             stopSpinner();
        };
        retrieveKeys();
    }, []);

    const getClientKeys = async () => {
        var result = await getStripeKeys({depositId: _id });
        setState({
          ...state,
          clientsecret: result.client_secret,
          stripePromise: loadStripe(result.pkey),
          finalAmount: result.finalAmount,
          charge: result.charges.toFixed(2),
          sumTotal: result.sumTotal,
          currency: result.currency

        
        });
      };
    return (
        <div className="uk-padding uk-margin-top uk-background-cb-2">
            <div  style={borderStyle} className="uk-card uk-card-default uk-card-body uk-card-default uk-align-center  uk-width-1-2@s">
                <div className="uk-flex uk-flex-row">
                    <div className="uk-width-1-2  uk-flex uk-flex-column uk-background-muted">
                        <span className="uk-text-center uk-text-xlarge  uk-text-primary uk-text-middle">
                            PAY
                    </span>
                        <span className="uk-text-center uk-text-xlarge uk-text-bolder uk-text-primary uk-text-middle" id="depositType">
                            {type.toUpperCase()}
                        </span>
                    </div>

                    <div className="uk-width-1-2 uk-flex uk-flex-column uk-flex-right">
                        <img src="../cards2.PNG" className="uk-height-xsmall " />
                        <span className="uk-padding-top uk-text-secondary uk-text-right">
                            Incurs extra surcharge: 2% + 30c
                         </span>
                    </div>
                </div>
                <hr className="uk-divider-icon"></hr>
                <div className="uk-text-center uk-background-muted ">
                    <p className="uk-text-xlarge" id="amount"> Amount :  {sumTotal}</p>
                    <p className="uk-text-xlarge" id="charges">  Charges: {charge}</p>
                    <p className="uk-text-xlarge uk-text-lead" id="total"> Total  :<b>{finalAmount.toFixed(2)} {currency}</b></p>
                </div>
                <hr className="uk-divider-icon"></hr>
                <h5 className="uk-text-center uk-text-primary uk-text-xlarge uk-underline">CARD DETAILS</h5>
                <StripeCard 
                    clientsecret={clientsecret}
                    stripePromise={stripePromise}
                    _id={_id}
                    payDeposits={payDeposits}
    
                />
            </div>

        </div>
    )
}
CardComponent.defaultProps = {
    type: "istavrity",
    sumTotal: 0,
    charge: 0,
    finalAmount: 0

}