import  API_VERSIONS from '../config/apiversions';

const DEPOSITS= `/api/${API_VERSIONS.DEPOSITS}/deposits`;
const ENDPOINTS = {
    REGISTER : `/api/${API_VERSIONS.ACCOUNT}/accounts/registerUserAccount`,
    GET_ALL_USERS: `/api/${API_VERSIONS.ACCOUNT}/accounts/getAccounts`,
    GET_ACCOUNT_BYID:`/api/${API_VERSIONS.ACCOUNT}/account/getAccountById`,
    TOGGLE_ACCOUNT_LOCK: `/api/${API_VERSIONS.ACCOUNT}/account/toggleAccountStatus`,
    IMPERSONATE_USER: `/api/${API_VERSIONS.ACCOUNT}/account/impersonateUser`,
    GET_KARMILIST: `/api/${API_VERSIONS.ACCOUNT}/account/getKarmiList`,
    REVOKE_IMPERSONATION: `/api/${API_VERSIONS.ACCOUNT}/account/revokeImpersonation`,
    LOGIN: `/api/${API_VERSIONS.ACCOUNT}/account/signin`,
    FORGET_PASSWORD:`/api/${API_VERSIONS.ACCOUNT}/password/requestResetPasswordForm`,
    RESET_PASSWORD:`/api/${API_VERSIONS.ACCOUNT}/password/resetPassword`,
    AGREE_TnC: `/api/${API_VERSIONS.ACCOUNT}/account/agreeTnC`,
    GET_LOGGED_ON_USER_DETAILS: `/api/${API_VERSIONS.ACCOUNT}/account/loggedOnUserDetails`,
    GET_MEMBERS: `/api/${API_VERSIONS.MEMBERS}/members`,
    ADD_MEMBER: `/api/${API_VERSIONS.MEMBERS}/members/add`,
    DELETE_MEMBER: `/api/${API_VERSIONS.MEMBERS}/members`,
    GET_DEPOSIT_CODES: `/api/${API_VERSIONS.DEPOSITS}/deposits/codes`,
    GET_DEPOSIT_CODES_MISC: `/api/${API_VERSIONS.DEPOSITS}/miscCode/codes`,
    GET_MISC_CODE_DATA: `/api/${API_VERSIONS.DEPOSITS}/miscCode/key/getDepositData`,
    SAVE_DEPOSIT:`/api/${API_VERSIONS.DEPOSITS}/deposits/save`,
    UPDATE_MISC_CODE_DATA: `/api/${API_VERSIONS.DEPOSITS}/miscCode/codes`,
    DEPOSITS,
    GET_ALL_DEPOSITS: `/api/${API_VERSIONS.DEPOSITS}/deposits/getAllDeposits`,
    GET_LAST_DEPOSIT: `/api/${API_VERSIONS.DEPOSITS}/deposits/getLastDeposit`,
    GET_STRIPE_KEYS: `/api/${API_VERSIONS.CHARGE}/charge/getKeys`,
    CONFIRM_PAYMENT:`/api/${API_VERSIONS.CHARGE}/charge/createCharge`,
    GET_ARGHYA_PRASAWASTI: `/api/${API_VERSIONS.DEPOSITS}/deposits/getArghyaPraswasti`,
    SUBMIT_DEPOSIT: DEPOSITS+'/updatePaymentReference',
    APPROVE_DEPOSIT: DEPOSITS+'/approveDeposit',
    ADD_INITIATION_DETAILS: `/api/${API_VERSIONS.REPORTS}/reports/addinitiationdetails`,
    GET_INITIATION_DETAILS: `/api/${API_VERSIONS.REPORTS}/reports/getinitiationdetails`,
    GET_THREE_YEAR_KARMI_REPORT: `/api/${API_VERSIONS.REPORTS}/reports/getThreeYearKarmiReport`,
    UPDATE_USER: `/api/${API_VERSIONS.ACCOUNT}/account/updateAccount`,
    LIBRARY_SET_MEMBER_TYPE:  `/api/${API_VERSIONS.ACCOUNT}/account/updateLibraryMemberType`,
    LIBRARY_BOOKS:`/api/${API_VERSIONS.LIBRARY}/library/books/inventoryByUser`,
    LIBRARY_GET_ACCOUNTS_PER_STATE:`/api/${API_VERSIONS.ACCOUNT}/accounts/getAccountsPerState`,
    LIBRARY_LEND_BOOK:`/api/${API_VERSIONS.LIBRARY}/library/book/lend`,
    LIBRARY_BORROWING_HISTORY:`/api/${API_VERSIONS.LIBRARY}/library/books/borrowing`,
    LIBRARY_RETURN_BOOK:`/api/${API_VERSIONS.LIBRARY}/library/book/return`,
    LIBRARY_EXPRESS_INTEREST:`/api/${API_VERSIONS.LIBRARY}/library/book/interest`,
    LIBRARY_INTERESTS:`/api/${API_VERSIONS.LIBRARY}/library/books/getInterests`,
    LIBRARY_TRANSFER_BOOK:`/api/${API_VERSIONS.LIBRARY}/library/books/transfer`,
    LIBRARY_ADD_EDIT_BOOK:`/api/${API_VERSIONS.LIBRARY}/library/books`,
    LIBRARY_GET_STATE_LIBRARIANS:`/api/${API_VERSIONS.ACCOUNT}/accounts/getStateLibrarians`,
    ISTAVRITY_REPORTS:`/api/${API_VERSIONS.REPORTS}/reports/getIstavrityReports`,

}

export default ENDPOINTS;