import { set } from "lodash";
import {
  clearLocalStorage,
  removeDepositCodesFromStorage,
  removeFilterCriteria,
  removeImpersonated,
  removeImpersonatedBy,
  removeImpersonatedToken,
  removeMembersFromStorage,
  setImpersonated,
  setImpersonatedBy,
  setImpersonatedToken,
  setMembersInStorage,
  setTnCApprovalDate,
  setToken,
  setUserInStorage,
} from "../../storage";
import {
  ADD_MEMBER,
  LOGIN,
  LOGOUT,
  GET_ACCOUNTS,
  GET_MEMBERS,
  IMPERSONATE_USER,
  REVOKE_IMPERSONATION,
  UPDATE_EMAIL,
  CHANGE_ADDRESS,
  GET_LOGGED_ON_USER,
} from "../types";
import Enums from "../../enums/Enums";

const getLibraryMemberType=(memberType)=>{
 // if(!memberType) return "NA"
  console.log('MemberType:',memberType)
  console.log(Object.entries(Enums.LIBRARY_ACCOUNT_TYPE).find(([key,value])=>value=== memberType))
  return Object.entries(Enums.LIBRARY_ACCOUNT_TYPE).find(([key,value])=>+value=== +memberType)[0]
}

export default (state, action) => {
  switch (action.type) {
    case ADD_MEMBER:
      return {
        ...state,
        members: [...state.members, action.payload],
      };

    case GET_MEMBERS:
      setMembersInStorage(action.payload);
      return {
        ...state,
        members: action.payload,
      };

    case GET_LOGGED_ON_USER:
      var { token, ...user } = action.payload;
      user={...user,libraryMemberLabel: getLibraryMemberType(user.libraryMemberType)}
      setUserInStorage(user);
      return {
        ...state,
        user: user,
        isloggedOn: true,
        alertMessage: "",
      };

    case LOGIN:
      setToken(action.payload.token);
      setTnCApprovalDate(action.payload.tncApprovalDate);
      break;

    case CHANGE_ADDRESS:
      setToken(action.payload.token);
      return {
        ...state,
        user: {
          ...state.user,
          fcnum: action.payload.fcnum,
          country: action.payload.country,
          address: action.payload.address,
          systemcode: action.payload.systemcode,
        },
        isloggedOn: true,
        alertMessage: "",
      };

    case IMPERSONATE_USER:
      setToken(action.payload.token);
      setImpersonatedToken(action.payload.impersonatedToken);
      setImpersonated(true);
      setImpersonatedBy(action.admin);
      removeDepositCodesFromStorage();
      var { token, impersonatedToken, ...rest } = action.payload;
      setUserInStorage({ ...rest });
      removeFilterCriteria();
      return {
        ...state,
        user: rest,
        isloggedOn: true,
        alertMessage: "",
        impersonated: true,
        impersonatedBy: action.admin,
      };

    case REVOKE_IMPERSONATION:
      var { token, ...user } = action.payload;
      setToken(token);
      removeImpersonatedBy();
      removeImpersonated();
      removeImpersonatedToken();
      removeMembersFromStorage();
      setUserInStorage({ ...user });
      removeFilterCriteria();
    

      return {
        ...state,
        user: user,
        isloggedOn: true,
        alertMessage: "",
        impersonated: false,
        impersonatedBy: "",
        members: [],
      };

    case LOGOUT:
     clearLocalStorage();
      return {
        ...state,
        user: {},
        members: [],
        alertMessage: "",
        isloggedOn: false,
      };

    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };

    case UPDATE_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };
  }
};
