import tapIcon from '../../img/tapIcon.png'
import React from 'react'

const CardComponentWithClickIcon = ({ children }) => {
    return (
        <div className='uk-card uk-card-body uk-background-muted uk-margin-top'>
        
        {children}
        <div className='uk-flex uk-flex-right'>
        <img src={tapIcon}  height="50" width="50" />
        </div>
        
                
        </div>
    )
}

export default CardComponentWithClickIcon