import React, { useState, useContext, useEffect } from "react";
import AccountContext from "../../context/accounts/accountContext";
import AlertContext from "../../context/alert/alertContext";
import SpinnerConext from "../../context/spinner/spinnerContext";

const InitiatonDetails = () => {
  const accountContext = useContext(AccountContext);
  const spinnerContext = useContext(SpinnerConext);
  const alertContext = useContext(AlertContext);
  const { members, addInitiatedMember, getInitiatedMembers } = accountContext;
  const { startSpinner, stopSpinner } = spinnerContext;
  const { setAlert } = alertContext;

  const initialState = {
    name: "",
    initiationdate: "",
    fatherhusbandName: "",
    address: "",
    permanentAddress: "",
    sameAs: true,
    refid: "",
    ext_refid: "",
    initiationType: "",
    phoneNumber: "",
    age: "",
    race: "",
    varna: "",
    qualification: "",
    occupation: "",
    numberOfInitiatedInFamily: 0,
    currview: "add",
  };

  const [ritwik, setRitwik] = useState({ ritwikid: "" });
  const { ritwikid } = ritwik;
  const [list, setList] = useState({
    initiatedlist: [],
  });
  const { initiatedlist } = list;
  const [state, setState] = useState(initialState);

  const {
    name,
    initiationdate,
    address,
    permanentAddress,
    sameAs,
    refid,
    ext_refid,
    initiationType,
    fatherhusbandName,
    phoneNumber,
    age,
    race,
    varna,
    qualification,
    occupation,
    numberOfInitiatedInFamily,
    currview,
  } = state;

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const isDisabled = () => {
    if (
      ritwikid == "" ||
      name == "" ||
      initiationdate == "" ||
      address == "" ||
      initiationType == ""
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    startSpinner();

    let res = await addInitiatedMember({ ...state, ritwikid });
    await getInitiatesList(ritwikid);
    setState({ ...initialState, currview: "view" });
    stopSpinner();
    setAlert(res);
    // document.getElementById("view").click();
  };

  const onSelectRitwik = async (e) => {
    setRitwik({ ritwikid: e.target.value });
    //setState({ ...state, [e.target.name]: e.target.value });
    let value = e.target.value;
    await getInitiatesList(value);
  };

  const getInitiatesList = async (value) => {
    startSpinner();
    let res = await getInitiatedMembers(value);
    setList({ initiatedlist: res });
    stopSpinner();
  };

  const onEdit = (member) => {
    member.initiationdate = member.initiationdate.substring(0, 10);
    setState({ ...member, currview: "add" });
    // document.getElementById("add").click();
  };

  const onCheck = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
        permanentAddress: address,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
        permanentAddress: "",
      });
    }
  };

  const setAddress = (e) => {
    if (sameAs) {
      setState({
        ...state,
        address: e.target.value,
        permanentAddress: e.target.value,
      });
      return;
    }
    setState({ ...state, address: e.target.value, permanentAddress: "" });
  };

  const onReset = () => {
    setState(initialState);
  };

  const toggleView = () => {
    let newView = "";
    if (currview == "add") {
      newView = "view";
    } else {
      newView = "add";
    }

    setState({ ...state, currview: newView });
  };

  return (
    <div className="uk-container uk-width-3-4@s">
      <h3 className="uk-text-center">Initiation Details Page</h3>

      <div className="uk-card-body">
        <h5>Select Ritwik</h5>
        <select className="uk-select" name="ritwikid" onChange={onSelectRitwik}>
          <option value="">--Select--</option>
          {members.map(
            (member) =>
              member.memberType == 3 && (
                <option value={member._id}>{member.name}</option>
              )
          )}
        </select>
      </div>
      <hr class="uk-divider-icon"></hr>

      <a
        className="uk_button uk-text-uppercase uk-button-link uk-align-right uk-text-bold uk-text-large uk-text-primary"
        onClick={toggleView}
      >
        {currview == "add" ? "VIEW MEMBERS" : "ADD MEMBER"}
      </a>
      <div className=" ">
        {currview == "add" ? (
          <div className="uk-card-body uk-background-muted ">
            <form className="uk-form uk-padding " onSubmit={onSubmit}>
              <div class="uk-card-header uk-flex uk-flex-center">
                <h3 class="uk-card-title uk-text-lead uk-flex-center">
                  DIKSHYA FORM
                </h3>
              </div>
              <div className="uk-margin-small">
                <span className="uk-text-bold">Name:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Father / Husband's Name:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="fatherhusbandName"
                  value={fatherhusbandName}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Age:</span>
                <input
                  type="number"
                  className="uk-text"
                  name="age"
                  value={age}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Race:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="race"
                  value={race}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Varna:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="varna"
                  value={varna}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Qualification:</span>
                <select
                  className="uk-select uk-margin-top"
                  name="qualification"
                  value={qualification}
                  onChange={onChange}
                  required
                >
                  <option> Illiterate</option>
                  <option>Literate</option>
                  <option>Secondary</option>
                  <option>Higher Secondary</option>
                  <option>Undergraduate</option>
                  <option>Graduate</option>
                  <option selected={qualification == "Post-Graduate"}>
                    Post-Graduate
                  </option>
                  <option>Doctorate</option>
                </select>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Occupation:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="occupation"
                  value={occupation}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">
                  Number of Initiated Family Members:
                </span>
                <input
                  type="number"
                  className="uk-text"
                  name="numberOfInitiatedInFamily"
                  value={numberOfInitiatedInFamily}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Date Of Initiation:</span>
                <input
                  type="date"
                  className="uk-text uk-text-uppercase"
                  name="initiationdate"
                  value={initiationdate}
                  onChange={onChange}
                  required
                ></input>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Address:</span>
                <h6 className="uk-text-italicize uk-text-meta">
                  Do not provide exact address (Ex: Unit Number)
                </h6>
                <textarea
                  className="uk-textarea"
                  rows="3"
                  name="address"
                  value={address}
                  onChange={setAddress}
                  required
                ></textarea>
                <br></br>
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Permanent Address:</span>
                <br></br>
                <br></br>
                <span className="uk-margin-top">Same As Above</span>
                <input
                  type="checkbox"
                  className="uk-checkbox"
                  name="sameAs"
                  defaultChecked={sameAs}
                  value={sameAs}
                  onChange={onCheck}
                ></input>
                {!sameAs && (
                  <div>
                    <h6 className="uk-text-italicize uk-text-meta">
                      Do not provide exact address (Ex: Unit Number)
                    </h6>
                    <textarea
                      className="uk-textarea"
                      rows="3"
                      name="permanentAddress"
                      value={permanentAddress}
                      onChange={onChange}
                      required
                    ></textarea>
                  </div>
                )}
              </div>

              <div className="uk-margin-small">
                <span className="uk-text-bold">Member Ref ID:</span>
                <input
                  type="text"
                  className="uk-text"
                  name="refid"
                  value={refid}
                  onChange={onChange}
                ></input>
              </div>
              <div className="uk-margin-small">
                <span className="uk-text-bold">
                  Ashram Family Code / Ext Ref Id:
                </span>
                <input
                  type="text"
                  className="uk-text"
                  name="ext_refid"
                  value={ext_refid}
                  onChange={onChange}
                ></input>
              </div>
              <div className="uk-margin-small">
                <span className="uk-text-bold">Initiation Type:</span>
                <select
                  className="uk-select uk-margin-top"
                  name="initiationType"
                  value={initiationType}
                  onChange={onChange}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="initiated">DIKSHYA</option>
                  <option value="swastayani">SWASTAYANI</option>
                </select>
              </div>
              <div className="uk-flex uk-flex-center">
                <button
                  type="submit"
                  className="uk-button uk-button uk-button-success uk-padding-right"
                  disabled={isDisabled()}
                >
                  SUBMIT
                </button>
                <button
                  type="button"
                  className="uk-button uk-padding-right uk-button-primary "
                  onClick={onReset}
                >
                  RESET
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div>
              <h5 className="uk-text uk-text-lead uk-text-center">
                <u>Users in the System</u>
              </h5>
              <span className="uk-text-meta">Click record to edit</span>
              <table className="uk-table uk-custom-table uk-table-hover">
                <thead>
                  <th>NAME</th>
                  <th>ADDRESS</th>
                  <th>DOI</th>
                  <th>INITIATION TYPE</th>
                  <th>MEMBER_ID</th>
                </thead>
                <tbody>
                  {initiatedlist.length > 0 &&
                    initiatedlist.map(
                      (initiated) =>
                        initiated.refid.trim().length > 0 && (
                          <>
                            <tr onClick={() => onEdit(initiated)}>
                              <td>{initiated.name}</td>
                              <td>{initiated.address}</td>
                              <td>
                                {initiated.initiationdate.substring(0, 10)}
                              </td>
                              <td className="uk-capitalize">
                                {initiated.initiationType}
                              </td>
                              <td>{initiated.refid}</td>
                            </tr>
                          </>
                        )
                    )}
                </tbody>
              </table>
            </div>
            <hr class="uk-divider-icon"></hr>
            <div>
              <h5 className="uk-text uk-text-lead uk-text-center">
                <u>EXTERNAL USERS</u>
              </h5>

              <table className="uk-table uk-custom-table uk-table-hover">
                <thead>
                  <th>NAME</th>
                  <th>ADDRESS</th>
                  <th>DOI</th>
                  <th>INITIATION TYPE</th>
                  <th>MEMBER_ID</th>
                </thead>
                <tbody>
                  {initiatedlist.length > 0 &&
                    initiatedlist.map(
                      (initiated) =>
                        initiated.refid.trim().length == 0 && (
                          <>
                            <tr onClick={() => onEdit(initiated)}>
                              <td>{initiated.name}</td>
                              <td>{initiated.address}</td>
                              <td>
                                {initiated.initiationdate.substring(0, 10)}
                              </td>
                              <td className="uk-capitalize">
                                {initiated.initiationType}
                              </td>
                              <td>{initiated.ext_refid}</td>
                            </tr>
                          </>
                        )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default InitiatonDetails;
