import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerComponent = ({ name, onChange, value }) => {
    return (
        <div>
            <DatePicker
                name={name}
                className="uk-input"
                dateFormat='dd/MM/yyyy'
                selected={value}
                maxDate={new Date()}
                onSelect={date=>onChange(date)}
                showYearDropdown={true}
                autoComplete="off"
                required
            />
        </div>
    )
}

DatePickerComponent.defaultProps = {
    name: "inputdate",
    control: () => { }
}

