import React, { useState, useEffect } from "react";
import { YearSelect, MonthSelect } from "@srph/react-date-select";
import { CountryDropdown } from "react-country-region-selector";
import { getFilterCriteria, setFilterCriteria } from "../../storage";

const Filters = ({
  getAllSubmissions,
  startSpinner,
  stopSpinner,
  submissions,
  user,
  getWhitelistedCountries,
}) => {
  const intialFilter = {
    status: "",
    year: "",
    month: "",
    type: "",
    country: "",
    fcnum: "",
    systemcode: "",
    refId: "",
  };
  const savedFilter = getFilterCriteria();
  Object.keys(savedFilter).forEach((key) => {
    intialFilter[key] = savedFilter[key];
  });
  const hasSavedFilters = Object.keys(savedFilter).length > 0;
  const [filter, setFilter] = useState(intialFilter);
  const { status, year, type, month, country, fcnum, systemcode, refId } =
    filter;

  let filtercriteria = {};

  const returnSetFilterItems = (filters) =>
    Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );

  const getPayload = () => {
    if (hasSavedFilters) {
      return returnSetFilterItems(filter);
    }
    if (user.role == 0) {
      const { systemcode } = user;
      return {
        systemcode,
        status: "Draft",
        ...returnSetFilterItems(filter),
      };
    } else if (user.role == 1) {
      return {
        country: user.country,
        status: "Submitted",
        ...returnSetFilterItems(filter),
      };
    }
  };

  const [filterVisible, setFilterVisible] = useState(true);

  const showFilters = () => (filterVisible ? "uk-visible" : "uk-hidden");

  useEffect(() => {
    console.log("Filters.js: useEffect");
    const fetchData = async (payload) => {
     // if (submissions.length == 0) {
        startSpinner();
        await getAllSubmissions(payload);
        stopSpinner();
     // }
    };

    //setCurrentSubmission("");
    const payload = getPayload();
    setFilter({ ...filter, ...payload });
    fetchData(payload);
  }, []);

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const setYear = (value) => {
    if (isNaN(parseInt(value))) {
      value = "";
    }
    //if(isNaN(parseInt(value))){value=""}
    setFilter({ ...filter, year: value });
  };
  const setMonth = (value) => {
    if (isNaN(parseInt(value))) {
      value = "";
    }
    setFilter({ ...filter, month: value });
  };
  const selectCountry = (val) => {
    setFilter({ ...filter, country: val });
  };

  const onReset = () => {
    setFilter(intialFilter);
  };

  const onFilter = async (e) => {
    e.preventDefault();
    startSpinner();
    filtercriteria = {};
    if (status.length > 0) {
      filtercriteria.status = status;
    }
    if (year.length > 0) {
      filtercriteria.year = year;
    }
    if (month.length > 0) {
      filtercriteria.month = month;
    }
    if (country.length > 0) {
      filtercriteria.country = country;
    }
    if (fcnum.length > 0) {
      filtercriteria.fcnum = fcnum;
    }
    if (type.length > 0) {
      filtercriteria.type = type;
    }
    if (systemcode.length > 0) {
      filtercriteria.systemcode = systemcode;
    }
    if (!!refId) {
      filtercriteria.refId = refId;
    }

    switch (user.role) {
      case 0:
        filtercriteria.systemcode = user.systemcode;
        break;
      // case 1:
      //   filtercriteria.country = user.country;
      //   break;
    }
    await getAllSubmissions(filtercriteria);
    setFilterCriteria(filtercriteria);
    stopSpinner();
  };
  return (
    <div>
      <div className={showFilters()}>
        <form
          onSubmit={onFilter}
          className="uk-padding-small uk-background-cb-2"
        >
          {user.role != 0 && (
            <>
              {user.role == 2 && (
                <>
                  <div>
                    <span className="uk-text uk-text-meta uk-text-bold uk-align-right">
                      Enter system code or select country OR select both
                    </span>
                  </div>

                  <div className="uk-padding-small">
                    <input
                      type="text"
                      placeholder="System Code"
                      className="uk-input"
                      name="systemcode"
                      onChange={onChange}
                      value={systemcode}
                    />
                  </div>
                  <hr></hr>
                </>
              )}
              <div className="uk-padding-small">
                <CountryDropdown
                  name="country"
                  whitelist={getWhitelistedCountries()}
                  className="uk-select"
                  value={filter.country}
                  onChange={(val) => selectCountry(val)}
                  showDefaultOption={true}
                  // defaultOptionLabel={"Australia"}
                  required={systemcode == ""}
                />
              </div>
              <div className="uk-padding-small">
                <input
                  type="text"
                  placeholder="Family code"
                  className="uk-input"
                  name="fcnum"
                  onChange={onChange}
                  value={fcnum}
                />
              </div>
              <div className="uk-padding-small">
                <input
                  type="text"
                  placeholder="Deposit Reference ID"
                  className="uk-input"
                  name="refId"
                  onChange={onChange}
                  value={refId}
                />
              </div>
            </>
          )}
          <div className="uk-padding-small">
            <select
              className="uk-select "
              name="status"
              value={status}
              onChange={onChange}
            >
              <option value="">--Select Status--</option>
              <option value="Draft" selected={status == "Draft"}>
                Draft
              </option>
              <option value="Submitted" selected={status == "Submitted"}>
                Submitted
              </option>
              <option value="Done" selected={status == "Done"}>
                Completed
              </option>
            </select>
          </div>
          <div className="uk-padding-small">
            <select
              class="uk-select "
              name="type"
              value={type}
              onChange={onChange}
            >
              <option value="">--Select Type--</option>
              <option value="istavrity">Istavrity</option>
              <option value="arghya">Arghya</option>
              <option value="pronami">Pronami</option>
            </select>
          </div>
          <div className="uk-padding-small">
            <YearSelect
              className="uk-select"
              start="2019"
              value={year}
              onChange={setYear}
            />
          </div>
          <div className="uk-padding-small">
            <MonthSelect
              className="uk-select uk-text-small@s"
              value={month}
              onChange={setMonth}
            />
          </div>

          <div className="uk-container uk-flex uk-flex-center uk-padding-small">
            <button
              type="submit"
              className="uk-button uk-button-small@s uk-button-warning"
            >
              SEARCH
            </button>
            {/* <button
            className="uk-button uk-button-small@s uk-background-co-1"
            onClick={onReset}
          >
            Reset Filter
          </button> */}
          </div>
        </form>
      </div>
      <div className="uk-hidden@s">
        <button
          className="uk-button-link"
          onClick={() => setFilterVisible(false)}
        >
          Hide Filters
        </button>
        <button
          className="uk-button-link uk-align-right"
          onClick={() => setFilterVisible(true)}
        >
          Show Filters
        </button>
      </div>
    </div>
  );
};

export default React.memo(Filters);
