import React, { useContext, useEffect, useState, useMemo } from "react";
import AccountContext from "../../context/accounts/accountContext";
import IstavrityContext from "../../context/istavrity/istavrityContext";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import { useHistory } from "react-router-dom"
import { DepositForm } from './view/DepositForm'
import ENUMS from '../../enums/Enums'
import SelectDepositType from "./view/SelectDepositType";
import SelectDepositDate from "./view/SelectDepositDate";
import SelectArghyaSubType from "./view/SelectArghyaSubType";


export const Deposit = () => {

  const accountContext = useContext(AccountContext);
  const alertContext = useContext(AlertContext);
  const istavrityContext = useContext(IstavrityContext);
  const spinnerContext = useContext(SpinnerContext);

  const { setDepositItem, deposit_codes, getLastDeposit } = istavrityContext;
  const { startSpinner, stopSpinner } = spinnerContext;
  const { members, user:{currency} } = accountContext;
  const { setAlert } = alertContext;
  const history = useHistory()
  const [depositState, setDepositState] = useState({
    type: "",
    subType: "general",
    depositDate: "",
    depositDetails: {},
    arghyaSubTypeSet: false,
    sum: 0,
    lastDeposit:null
  })
  const { type, subType, depositDate, depositDetails, sum , arghyaSubTypeSet} = depositState;


  useEffect(() => {
    console.log('running useeffect')
    const getCodes = async () => {
      startSpinner();
      await istavrityContext.getDepositCodes();
      stopSpinner();
    }
    let details = {};

    areCodesLoaded() ?
      (details = generateEmptyDepositDetails(members)) :
      getCodes();
    setDepositState({
      ...depositState, depositDetails: details
    })
  }, [type, subType, deposit_codes])

  const returnCodes = () => {
    if (type.toLowerCase() == "istavrity") {
      return deposit_codes['istavrity_codes']
    }
    if (type.toLowerCase() == "arghya" && subType == "general") {
      return deposit_codes['argya_codes'];
    }
    if (type.toLowerCase() == "arghya" && subType == "karmi") {
      return deposit_codes['arghya_karmi_codes'];
    }
    return deposit_codes['pronami_codes'];
  };

  const resolveAmount = (amount) => {
    Object.keys(amount).map(key => {
      amount[`${key}`] = +amount[`${key}`];
    })
    const total = Object.keys(amount).reduce((sum, a) => +sum + +amount[`${a}`], 0);
    return { amount, total }
  }

  const retriveLastDeposit = async () => {
    startSpinner();
   console.log('Existing deposit', depositDetails)
    const res = await getLastDeposit();
    if(res){
      const newDepositDetails = Object.values(depositDetails).reduce((agg, item, index) => {
        const { name  } = item;
        const lastDeposit = res.find(d => d.name == name)
        if(lastDeposit){
        return {...agg, [index]:lastDeposit}
      }else{
        return {...agg, [index]:item}
      }
        
      },{})


      setDepositState({ ...depositState, depositDetails: newDepositDetails })
     
    }else{
      setAlert({
        type: "danger",
        msg: "No last deposit found, Please enter deposit details manually",
      });
    }
    stopSpinner();
    
  }


  const onSubmitDeposit = async (e) => {
    e.preventDefault();
    startSpinner();
    let details = Object.values(depositDetails).reduce((agg, item) => {
      const { name = "", amount = {} } = item;
      const { total, amount: amount_resolved } = resolveAmount(amount)
      if (name.length && Object.entries(amount).length && total) {
        agg.push({ ...item, total, amount: amount_resolved });
      }
      return agg;
    }, [])

    if (!details.length) {
      setAlert({
        type: "danger",
        msg: "Amount must be filled for atleast one member",
      });
      stopSpinner();
      return;
    }
    let validationResult = details.reduce((result, detail) => {
      const { name, amount } = detail;
      const { ISTAVRITY, PRONAMI, ...rest } = amount;
      const max_value = Math.max(...Object.values(rest));
      const maxFieldName = Object.keys(amount).find(key => amount[key] === max_value)
      if (ISTAVRITY < max_value) {
        result.invalid = true;
        result.name = name;
        result.maxFieldName = maxFieldName;
        return result;
      }
      return result;
    }, { name: "", invalid: false })

    if (validationResult.invalid) {
      setAlert({
        type: "danger",
        msg: validationResult.maxFieldName + " can not be greater than ISTAVRITY for " + validationResult.name,
      });
      stopSpinner();
      return;
    }
    const payload = {
      type, subType, depositDate, depositDetails: details
    }
    let res = await setDepositItem(payload);
    if (res.type == "danger") {
      stopSpinner();
      setAlert(res);
      return;
    } else {
      history.push("/deposititemsummary/" + res.msg);
    }
    stopSpinner();
  };

  const onChange = (e) => {
    setDepositState({ ...depositState, [e.target.name]: e.target.value });
  }

  const onAddValues = (data) => {
    let details = depositDetails;
    details[`${data.idx}`]['amount'][data.key] = data.value
    setDepositState({ ...depositState, depositDetails: details, sum: sum + +data.value })
  }

  const areCodesLoaded = () => Object.keys(deposit_codes).length > 0;

  const generateEmptyDepositDetails = (members) => {
    let details = {};
    members.forEach((member, idx) => {
      const { _id, name, memberType, ritwik } = member;
      const codes = returnCodes();
      const amount = codes.reduce((agg, code) => {
        agg[`${code.key}`] = 0;
        return agg;
      }, {})

      details = {
        ...details, [idx]: {
          name,
          memberId: _id,
          ritwik: memberType ? ritwik.fullName : "",
          amount,
          memberType,
          disabled: (type == ENUMS.DEPOSIT_TYPE.arghya && subType == ENUMS.DEPOSIT_SUBTYPE.karmi
            && memberType < 2)
        }
      }


    })
    return details
  }

  const setDepositType = (type) => {
    setDepositState({ ...depositState, type })
  }

  const isDepositTypeArghya = () => type == ENUMS.DEPOSIT_TYPE.arghya;

  const shouldShowDepositSubTypes = useMemo(() => {
    console.log('karmis present:',members.some(member => member.memberType > 1))
    console.log('arghyaSubTypeSet:',arghyaSubTypeSet);
    console.log('type:',type);
    return isDepositTypeArghya()
    && members.some(member => member.memberType > 1)
  },[members,type])


  const setArghyaSubType = (subType) => {
    setDepositState({ ...depositState, subType , arghyaSubTypeSet:true})
  }

  const setDepositDate = (date) => {
    setDepositState({ ...depositState, depositDate: date.toLocaleDateString("sv") })
  }

  const goBack = (selection) => {
    return () => {
    switch(selection){
      case 'type':
        return setDepositState({ ...depositState, type:'' })
       
      case 'subType':
        return setDepositState({ ...depositState, subType:'', arghyaSubTypeSet:false })
       
      case 'date':
       return setDepositState({ ...depositState, depositDate:'' })
       
      default:
        return '';
        
    } 
  }
  }
  if (!type) {
    return (
      <SelectDepositType
        setDepositType={setDepositType}
      />
    )
  }
  if (shouldShowDepositSubTypes && !arghyaSubTypeSet) {
    return (
      <SelectArghyaSubType
        setArghyaSubType={setArghyaSubType}
        goBack = {goBack('type')}
      />
    )
  }
  if (!depositDate) {
    return (
      <SelectDepositDate
        setDepositDate={setDepositDate}
        type={type}
        goBack = {shouldShowDepositSubTypes ? goBack('subType') : goBack('type')}
      />
    )
  }

  


  return (
    <DepositForm
      state={depositState}
      onChange={onChange}
      onAddValues={onAddValues}
      onSubmitDeposit={onSubmitDeposit}
      goBack = {goBack('date')}
      retriveLastDeposit={retriveLastDeposit}
      currency={currency}
      codes={returnCodes()}
     

    />
  );
};


