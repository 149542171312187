import React from 'react'
import CardClickWithIcon from '../../presentation/CardClickWithIcon'
import BackButton from './BackButton'

const SelectArghyaSubType = ({ setArghyaSubType, goBack }) => {


    const cards = [
        {
            title: 'General',
            description: 'Deposit for general purpose to be used by Satsang Oceania'
        },
        {
            title: 'Karmi',
            description: 'Deposit Arghya for Karmis and SPRs only'

        }
    ]

    const getBlankDiv = () => {
        return (
            <div className='uk-width-1-3'> </div>
        )
    }
    return (
        <div className='uk-container uk-align-center uk-margin-large-top'>
            <BackButton
                onClick={goBack}
            />
            <div class="uk-grid-match uk-width-1-1 uk-child-width-1-2@s uk-text-center uk-margin" uk-grid="true">
                {getBlankDiv()}
                <div className='uk-margin-top '>
                    <h2>Select Arghya type</h2>
                    <CardClickWithIcon
                        contents={cards}
                        action={setArghyaSubType}

                    />
                </div>
                {getBlankDiv()}
            </div>
        </div>

    )
}

export default SelectArghyaSubType