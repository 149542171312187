import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import {Controller} from 'react-hook-form'

export const Recaptcha = ({control}) => {
    return (
        <div>
            <Controller
                 name="token"
                 control={control}
                 render={({onChange,value})=>
                  <ReCAPTCHA
                  id="captcha"
                  className="uk-flex uk-flex-center uk-margin-bottom"
                  value={value}
                  onChange={onChange}
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                 
                />
                 }
              />
        </div>
    )
}
