import React, { useEffect } from "react";
import { FaBookOpen, FaPlus, FaHistory, FaStar } from "react-icons/fa"; // Import React Icons
import { useHistory } from "react-router-dom";
import AccountContext from "../../context/accounts/accountContext";
import { useGetAccounts } from "../../hooks/useGetAccounts";
import Enums from "../../enums/Enums";

const LandingPage = () => {
  const history = useHistory();
  const accountContext = React.useContext(AccountContext);
  const { user } = accountContext;
  const { accounts, getStateLibrarians } = useGetAccounts();

  useEffect(() => {
    getStateLibrarians();
  }
  , []);

  const sections={
    catlogue:'catlogue',
    add:'add',
    history:'history',
    interests:'interests'

  }

  const selectOption = (option) => {
    console.log("Option Selected", option);
    switch (option.toLowerCase()) {
      case "catlogue":
        history.push("/library/catelogue");
        break;
      case "interests":
        history.push("/library/interests");
        break;
      case "history":
        history.push("/library/history");
        console.log("history");
        break;
      case "add":
        history.push("/library/add");
        console.log("add");
        break;
      default:
        console.log("default");
        break;
    }
    //   setOption(option)
  };

  const CustomButton = ({ className, label, children,onClick }) => {
    return (
      <button className={className} onClick={onClick}>
        {children} {label}
      </button>
    );
  };

  const getButton = ({ label, ICON, className, show = true ,onClick}) => {
    if (!show) {
      return <></>;
    }
    return (
      <div>
        <CustomButton
          className={
            className +
            " uk-button uk-button-large uk-width-1-1 uk-box-shadow-large uk-flex uk-flex-middle uk-box-shadow-bottom"
          }
          label={label}
          onClick={onClick}
        >
          {ICON}
        </CustomButton>
      </div>
    );
  };

  const librarians = [
    {
      name: "John Doe",
      email: "john.doe@example.com",
      role: "Chief Librarian",
      photoUrl: "path/to/photo.jpg",
    },
    {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      role: "Assistant Librarian",
      photoUrl: "path/to/photo2.jpg",
    },
  ];
  

  return (
    <div
  className="uk-background-cover uk-background-center-center uk-height-viewport uk-flex uk-flex-column uk-flex-middle"
  style={{ backgroundImage: "url(images/library2.jpg)" }}
>
  {/* Header that spans the full width */}
  <div
    id="header"
    className="uk-text-center uk-margin-large-top uk-padding"
    style={{ width: "100vw" }} // Ensures header takes up the full viewport width
  >
    <div className="uk-background-muted uk-text-secondary">
      <h1 className="uk-margin-remove-bottom uk-text-secondary">
        Welcome to Satsang Library
      </h1>
      <p className="uk-text-lead uk-margin-small-top">
        Your digital library at your fingertips
      </p>
    </div>
  </div>

  

  {/* Grid layout for options */}
  <div
    className="uk-grid uk-grid-match uk-child-width-1-3@m uk-child-width-1-2@s uk-margin-large-top"
    uk-grid="true"
    id="options"
    style={{ width: "100%" }} // Make sure the options grid takes the full width
  >
    {/* Catalogue Button */}
    {getButton({
      label: "Browse Catalogues",
      ICON: <FaBookOpen className="uk-margin-small-right" />,
      className: "uk-button-primary",
      show: true, // Show the button based on some condition
      onClick: () => selectOption(sections.catlogue)
    })}

    {/* Add Book Button */}
    {getButton({
      label: "Add New Book",
      ICON: <FaPlus className="uk-margin-small-right" />,
      className: "uk-button-success",
      show: { user } && user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.ADMIN,
      onClick: () => selectOption(sections.add)
    })}

    {/* View History Button */}
    {getButton({
      label: "View History",
      ICON: <FaHistory className="uk-margin-small-right" />,
      className: "uk-button-warning",
      show: true, // This button will be hidden based on some condition
      onClick: () => selectOption(sections.history)
    })}

    {/* Search Button */}
    {getButton({
      label: "Expression of Interest",
      ICON: <FaStar className="uk-margin-small-right" />,
      className: "uk-button-secondary",
      show: true, // Show the button based on some condition
      onClick: () => selectOption(sections.interests)
    })}
  </div>

  {/* Librarian Details Section */}
  {user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.MEMBER && (
  <div
    id="librarians"
    className="uk-section uk-section-muted uk-padding uk-margin"
    style={{
      width: "100%",
      backgroundColor: "#f5f5f5",
    }}
  >
    <h2 className="uk-text-center uk-text-secondary">Meet Your Librarian(s)</h2>
    <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-margin-top uk-flex-center" uk-grid="true">
      {accounts.map((librarian, index) => (
        <div key={index} className="uk-card uk-card-default uk-card-body uk-text-center">
          <div className="uk-margin-bottom">
            <img
              src={librarian.photoUrl || "https://via.placeholder.com/150"}
              alt={`${librarian.name}'s photo`}
              className="uk-border-circle"
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            />
          </div>
          <h3 className="uk-card-title">{librarian.name}</h3>
          <p>{librarian.phone || "Librarian"}</p>
          <p>{librarian.email}</p>
        </div>
      ))}
    </div>
  </div>
  )}
</div>

  );
};

export default LandingPage;



//  <div
//       className="uk-background-cover uk-background-center-center uk-height-viewport uk-flex uk-flex-column uk-flex-middle"
//       style={{ backgroundImage: "url(images/library2.jpg)" }}
//     >
//       {/* Header that spans the full width */}
//       <div
//         id="header"
//         className="uk-text-center uk-margin-large-top uk-padding"
//         style={{ width: "100vw" }} // Ensures header takes up the full viewport width
//       >
//         <div className="uk-background-muted uk-text-secondary">
//           <h1 className="uk-margin-remove-bottom uk-text-secondary">
//             Welcome to Satsang Library
//           </h1>
//           <p className="uk-text-lead uk-margin-small-top">
//             Your digital library at your fingertips
//           </p>
//         </div>
//       </div>

//       {/* Grid layout for options */}
//       <div
//         className="uk-grid uk-grid-match uk-child-width-1-3@m uk-child-width-1-2@s uk-margin-large-top"
//         uk-grid="true"
//         id="options"
//         style={{ width: "100%" }} // Make sure the options grid takes the full width
//       >
//         {/* Catalogue Button */}
//         {getButton({
//           label: "Browse Catalogues",
//           ICON: <FaBookOpen className="uk-margin-small-right" />,
//           className: "uk-button-primary",
//           show: true, // Show the button based on some condition
//           onClick: () => selectOption(sections.catlogue)
//         })}

//         {/* Add Book Button */}
//         {getButton({
//           label: "Add New Book",
//           ICON: <FaPlus className="uk-margin-small-right" />,
//           className: "uk-button-success",
//           show: { user } && user.libraryMemberType === "ADMIN",
//           onClick: () => selectOption(sections.add)
//         })}

//         {/* View History Button */}
//         {getButton({
//           label: "View History",
//           ICON: <FaHistory className="uk-margin-small-right" />,
//           className: "uk-button-warning",
//           show: true, // This button will be hidden based on some condition
//           onClick: () => selectOption(sections.history)
//         })}

//         {/* Search Button */}
//         {getButton({
//           label: "Expression of Interest",
//           ICON: <FaStar className="uk-margin-small-right" />,
//           className: "uk-button-secondary",
//           show: true, // Show the button based on some condition
//           onClick: () => selectOption(sections.interests)
//         })}
//       </div>
//     </div>

