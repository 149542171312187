import React, { useState } from "react";
import { useGetBooks } from "../../hooks/useGetBooks";
import { Button, CountrySelector, RegionSelector, TextBox } from "../presentation";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { set } from "lodash";

const Transfer = ({ allowedCountries , transferBook}) => {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [quantity, setQuantity] = useState(0);

  const onSubmit=async()=>{
  
    await transferBook({quantity, region, country});
   
    setCountry("");
    setRegion("");
    setQuantity(0);
  }

  return (
    <div className="uk-container uk-margin-top">
      <form className="uk-form uk-card-body uk-card-default">
      <h2 className='uk-text-center'>Transfer</h2>
      <CountryDropdown
        value={country}
        className="uk-select uk-form-select"
        onChange={(val) => setCountry(val)}
        whitelist={allowedCountries}
      />
      <RegionDropdown
        country={country}
        className="uk-select uk-margin-top"
        value={region}
        onChange={(val) => setRegion(val)}
      />
      <TextBox
        type="number"
        label="Quantity"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
      />
      <Button label="Submit"  className='uk-button uk-align-center uk-button-primary'onClick={onSubmit} />
      </form>
    </div>
   
  );
};

export default Transfer;
