import React from 'react'
import { Button } from '../../presentation'

const BackButton = ({onClick}) => {
  return (
    <div>
        <Button className='uk-button uk-button-bold uk-button-warning uk-text-black' onClick={onClick} label='Back'  />
    </div>
  )
}

export default BackButton