import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AccountContext from "../../context/accounts/accountContext";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import ENDPOINTS from "../../enums/endpoints";
import { getToken } from "../../storage";


const MiscCodes = ({ props, country }) => {
  const accountContext = useContext(AccountContext);
  const alertContext = useContext(AlertContext);
  const spinnerContext = useContext(SpinnerContext);
  const { user, getWhitelistedCountries } = accountContext;
  const { startSpinner, stopSpinner } = spinnerContext;
  //const {country}=props;
  //alert(country)

  const initialState = {
    codes: [],
    count: 0,
    key: "",
    desc: "",
    country: user.country,
    showActive: true,
    showArchieved: false,
  };

  const [state, setState] = useState(initialState);
  let { codes, count, key, desc, showActive, showArchieved } = state;

  useEffect(() => {
    console.log('Running useEffect')
    getMiscCodes();
  }, [count]);

  const getMiscCodes = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      },
    };

    try {
      let res = await axios.get(
        process.env.REACT_APP_API_URL + ENDPOINTS.GET_DEPOSIT_CODES_MISC,
        config
      );
      setState({ ...state, codes: res.data.data, count: res.data.datalength });
    } catch (error) {
      alertContext.setAlert({ type: "danger", msg: error.msg });
      // console.log(error.msg);
    }
  };

  const updateCode = async (_id, field, status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      },
    };
    const body = {
      [field]: status
      
    };

    try {
      let res = await axios.put(
        process.env.REACT_APP_API_URL + ENDPOINTS.UPDATE_MISC_CODE_DATA +'/'+ _id,
        body,
        config
      );
    } catch (error) {
      console.log(error.msg);
    }
  };
  const onCheck = (e) => {
    updateCode(e.target.name, "active", e.target.checked);
  };

  const onArchieve = async(e) => {
    console.log('Archieving code:',e.target.name,'status:',e.target.checked)
    await updateCode(e.target.name, "archieved", true);
    //getMiscCodes();
    setState({ ...state, count: count - 1 });
  };

  const onDelete = (e) => {
    const deleteCode = async (_id, status) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getToken(),
        },
      };

      try {
        startSpinner();
        let res = await axios.delete(
          process.env.REACT_APP_API_URL +ENDPOINTS.UPDATE_MISC_CODE_DATA +'/'+ _id,
          config
        );
        const {type="success",msg} = res.data.data;
      
        stopSpinner();
        alertContext.setAlert({
          type,
          msg
        });
       

        setState({ ...state, count: count - 1 });
      } catch (error) {
        stopSpinner();
        alertContext.setAlert(error.response.data);
      }
    };

    deleteCode(e.target.name);
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onAddCode = (e) => {
    let value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value.replace(/[^A-Z0-9]+/i, "").toUpperCase(),
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    startSpinner();
    const addCode = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getToken(),
        },
      };
      const body = {
        key,
        desc,
        country: country.toUpperCase(),
      };

     

      try {
        let res = await axios.post(
          process.env.REACT_APP_API_URL + ENDPOINTS.GET_DEPOSIT_CODES_MISC,
          body,
          config
        );
        const {type="success",msg} = res.data.data;
        setState({ ...state, count: count + 1, key: "", desc: "" });
        stopSpinner();
        alertContext.setAlert({
          type,
          msg
        });
      } catch (error) {
        stopSpinner();
        console.log(error.response.data);
        alertContext.setAlert({ type: "danger", msg: error.response.data.msg });
      }
    };
    addCode();
  };

  const onShowContri = (misccode) => {
    console.log(JSON.stringify(misccode));
    props.history.push("/showcontributiondetails", { ...misccode });
  };

  const showActiveList = () => {
    setState({ ...state, showActive: true, showArchieved: false });
  };
  const showArchieveList = () => {
    setState({ ...state, showActive: false, showArchieved: true });
  };
  return (
    <>
      <div>
        <div id="addCode" className="uk-container uk-width-3-4">
          <h3 className="uk-text-lead uk-text-center">ADD MISC CODES</h3>
          <form className="uk-form uk-bordered" onSubmit={onSubmit}>
         
            <span className="uk-text-bold">Misc Code:</span>
            <input
              className="uk-text-bold uk-text-capitalize"
              name="key"
              value={key}
              type="text"
              onChange={onAddCode}
              minLength="4"
              maxLength="10"
              required
            />
            <span className="uk-text-bold">Description:</span>
            <input
              className="uk-text"
              name="desc"
              value={desc}
              type="text"
              onChange={onChange}
              required
            />
            <button className="uk-button uk-button-primary uk-align-center">
              SUBMIT
            </button>
          </form>
        </div>
        <hr></hr>
        {showActive && (
          <div id="misccodesactive" className="uk-container">
            <h3 className="uk-text-lead uk-text-center">MISC CODES</h3>
            <button
              className="uk-button uk-button-link uk-align-right uk-text-bold"
              onClick={showArchieveList}
            >
              VIEW ARCHIEVED
            </button>
            <span className="uk-text-meta">
              Click the KEY to get contribution details
            </span>
            <table className="uk-table uk-table-small uk-table-divider uk-box-shadow-small">
              <thead>
                <th className="uk-width-small">KEY</th>
                <th className="uk-table-expand">DESCRIPTION</th>
                <th>ACTIVE</th>
                <th className="uk-margin-left">ACTION</th>
                <th>COUNTRY</th>
              </thead>

              <tbody>
                {codes.length > 0 &&
                  codes
                    .filter((c) => c.archieved == false)
                    .map((code) => (
                      <tr>
                        <td>
                          <a
                            className="uk-link-text"
                            onClick={() => onShowContri(code)}
                          >
                            <u>{code.key}</u>
                          </a>
                        </td>
                        <td>{code.desc}</td>
                        <td>
                          <input
                            className="uk-checkbox"
                            type="checkbox"
                            name={code._id}
                            defaultChecked={code.active}
                            onChange={onCheck}
                          />
                        </td>
                        <td>
                          <button
                            className="uk-button uk-button-danger uk-button-small"
                            name={code._id}
                            type="button"
                            onClick={onDelete}
                          >
                            {" "}
                            DELETE
                          </button>
                        
                        <button
                            className="uk-button uk-button-primary uk-button-small uk-margin-small-left"
                            name={code._id}
                            type="button"
                            onClick={onArchieve}
                          >
                            {" "}
                            ARCHIEVE
                          </button>
                          {/* <input
                            className="uk-checkbox"
                            type="checkbox"
                            name={code._id}
                            defaultChecked={false}
                            onChange={onArchieve}
                          /> */}
                        </td>
                        <td>{code.country}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
        {showArchieved && (
          <div id="misccodesarchieved" className="uk-container">
            <h3 className="uk-text-lead uk-text-center">MISC CODES</h3>
            <button
              className="uk-button uk-button-link uk-align-right uk-text-bold"
              onClick={showActiveList}
            >
              VIEW ACTIVE
            </button>
            <span className="uk-text-meta">
              Click the KEY to get contribution details
            </span>
            <table className="uk-table uk-table-small uk-table-divider uk-box-shadow-small">
              <thead>
                <th className="">KEY</th>
                <th className="">DESCRIPTION</th>
                <th>ACTION</th>
              </thead>

              <tbody>
                {codes.length > 0 &&
                  codes
                    .filter((c) => c.archieved == true)
                    .map((code) => (
                      <tr>
                        <td>
                          <a
                            className="uk-link-text"
                            onClick={() => onShowContri(code)}
                          >
                            <u>{code.key}</u>
                          </a>
                        </td>
                        <td>{code.desc}</td>
                        <td>
                          <button
                            className="uk-button uk-button-danger"
                            name={code._id}
                            type="button"
                            onClick={onDelete}
                          >
                            {" "}
                            DELETE
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
export default MiscCodes;
