import React from "react";

import { Route, Redirect } from "react-router-dom";
import { getToken } from "../../storage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedOn = getToken()== null ? false : true;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedOn ? <Redirect to="/login" /> :
        <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
