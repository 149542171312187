import React, { useState } from "react";
import SpinnerContext from "./spinnerContext";

const SpinnerState = (props) => {
  const initialState = {
    spinner: false,
  };

  const [state, setState] = useState(initialState);

  const startSpinner = () => {
    setState({ ...state, spinner: true });
  };
  const stopSpinner = () => {
    setState({ ...state, spinner: false });
  };

  return (
    <SpinnerContext.Provider
      value={{ startSpinner, stopSpinner, spinner: state.spinner }}
    >
      {props.children}
    </SpinnerContext.Provider>
  );
};

export default SpinnerState;
