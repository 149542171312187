import React from 'react'

const StateInventory = ({data}) => {
  return (
    <div className='uk-card-default uk-margin-top'>
        <h2 className='uk-text-center'>State Inventory</h2>
        <table className="uk-table uk-table-divider">
          <thead>
            <tr>
              <th>State</th>
              <th>Available</th>
              <th>Lent</th>
             
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.state}</td>
                <td>{item.copiesAvailable}</td>
                <td>{item.copiesAllocated}</td>
               
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  )
}

export default StateInventory