import React from 'react'
import {RegionSelector} from "./CountrySelector"
import {TextBox} from "./TextBox"

const getMessages = (errors) => {
  const addressErrors = errors["address"];
  if(!addressErrors) return null;
  const messages = Object.values(addressErrors).map(error => error.message);
  return (
    <div>
    { messages.map(message => <p className="uk-text uk-text-danger uk-margin-top">{message}</p>)}
    </div>
  )

}
export const Address = ({control,country,register,errors}) => {
    return (
        <div>
        
            <RegionSelector
               control={control}
               name="address.state"
               country={country}
            />
            
             <TextBox
                placeholder="Address line 1"
                name="address.line1"
                register={register}
                required="true"
                errors={errors}
              />

              <TextBox
                placeholder="Street / Locality"
                name="address.line2"
                register={register}
                required={false}
              />
              <TextBox
                placeholder="Suburb / City *"
                name="address.suburb"
                register={register}
                required="true"
                errors={errors}
              />
              <TextBox
                placeholder="Post Code *"
                name="address.postcode"
                register={register}
                required="true"
                errors={errors}
              />
               {getMessages(errors)}
        </div>
    )
}
