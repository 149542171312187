import React from 'react'

export const Checkbox = ({name,register,required,label,labelIsLink,toggleTarget,errors}) => {
    const showLabel=()=>{
        if(labelIsLink){
            return (
                <button className="uk-button uk-button-link" type="button" uk-toggle={toggleTarget}>{label}</button> 
            )
        }else{
            return(label)
           
        }
    }
    return (
     <>
            <input
            type="checkbox"
            className="uk-checkbox uk-margin-small-right"
            name={name}
            ref={register({required:required})}
            />
           {showLabel()}
           <p className="uk-text uk-text-danger">{errors[name] && errors[name].message}</p>
      </> 
    )
}

Checkbox.defaultProps={
    labelIsLink:false,
    required:false,
    errors:{}
}
