import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest, sendPostRequest } from "../request/api_request";

export const useLendBook = () => {
    const initState = {
        msg: "",
        loading: false,
        error: null,
        errors: [],
    };

    const [state, setState] = useState(initState);
    const { msg, loading, error , errors} = state;

    const submitLendRequest = useCallback(async ({bookId,userId}) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_LEND_BOOK ,
                payload:{
                    bookId,
                    accountId:userId
                }
            });
            console.log("Response1", res.data.data.msg);
            setState({ msg: res.data.data.msg, loading: false, error: null, errors: [] });
        } catch (err) {
            console.log("Error", err.response.data);
            setState((prev) => ({ ...prev, loading: false, error: err?.message||'', errors: err.response.data.errors }));
        }
    }, []); // `useCallback` memoizes `getBooks` and prevents unnecessary re-creation

    const transferBook = async (payload) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_TRANSFER_BOOK ,
                payload
            });
            console.log("Response1", res.data.data.msg);
            setState({...state, msg: res.data.data.msg, loading: false, error: null, errors: [] });
        } catch (err) {
            console.log("Error", err.response.data);
            setState((prev) => ({ ...prev, loading: false, error: err?.message||'', errors: err.response.data.errors }));
        }
    };

    return { msg, loading, error,errors, submitLendRequest,transferBook };
};
