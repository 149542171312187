
import React,{useEffect} from 'react'
import { TextBox } from "./TextBox"
import tapIcon from '../../img/tapIcon.png'
import { ritvikList as inputlist } from '../../ritwik';

export const RitwikSearchComponent = ({ onChange, errors={},selectedRitwik={} }) => {
  //const inputlist = data.ritvikJSONObject.ritvikList;
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [ritwik, setRitwik] = React.useState({});

  const isRitwikSelected = () => {
    return ritwik && ritwik.saID;
  }

  useEffect(() => {
    if (Object.hasOwn(selectedRitwik,'saID')) {
      setRitwik(selectedRitwik);
    }
  }, [selectedRitwik])

  const selectRitwik = (ritwik) => {
    setRitwik(ritwik);
    toggleOpen();
    onChange(ritwik.saID);
  }
  const toggleOpen = () => setIsOpen(prev => !prev);
  const resetRitwik = () => {
    setRitwik({});
  }

  const showSuggestion = () => {
    const input = searchText;
    if (input.length < 3) {
      return []
    }
    let suggestions = [
      ...inputlist.filter(r=>r.fullName.toLowerCase().includes(input.toLowerCase())),
      ...inputlist.filter(r=>r.address.toLowerCase().includes(input.toLowerCase())),
    ]
    return suggestions;
  }
  return (
    <div>
      <div className="uk-background-default" id="select_ritwik">
        <div class=" ">
         
          {isRitwikSelected() && (
           <div className="uk-width-2-3@s">
            
              <div>

            <span
              className='uk-text uk-text-large uk-margin-bottom uk-text-break uk-text-secondary'
              style={{ lineHeight: "1.5" }}
            >
             <b>RITWIK:</b>{ritwik.fullName} , {ritwik.address}
              </span>
              </div>

       
          </div>
  )
}
         
          <button id="searchRitwikButton" type="button" className="uk-button uk-button-width-1-1 uk-button-secondary dropdown__toggle uk-align-right" onClick={()=> {resetRitwik() ; toggleOpen()}}>
            {isRitwikSelected() ? 'Reset' : 'Select Ritwik'}
          </button>
         
        </div>
        <div className='' >
     
          
          
          {errors.saID && (<p className="uk-text uk-text-danger">{errors.saID.message}</p>)}
          {isOpen && (
            <>

              <TextBox
                type="text"
                placeholder="Enter Ritwik Details * (Enter atleast 3 characters)"
                name="searchRitwikTextBox"
                className="uk-input uk-form-width-large "
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />

             
            
             { showSuggestion().map((suggestion,index) => (
                  <div id={`ritwik-result-${index}`} className="uk-card uk-text-small uk-card-hover uk-card-body"
                 
                  key={suggestion.name} onClick={() => {
                    selectRitwik(suggestion);
                  }}>
                     
                    <h3>
                    <img className='uk-align-right' src={tapIcon} height="30" width="30" />
                      <b>{suggestion.fullName}</b></h3>
                    <span className='uk-text-secondary'>
                    {suggestion.address}
                    </span>
                    <hr />
                  </div>
                  ))}
               
            </>
          )}
        </div>

        {/* <button type="button" className="uk-button  uk-button-secondary uk-text-black uk-button-small"
          disabled={disabled}
          onClick={() => { setValue("searchRitwik", ""); setValue("saID", "") }}>Reset</button>
      </div> */}

        {/* <TextBox
        placeholder="Ritwik Id"
        name="saID"
        register={register}
        errors={errors}
        hidden={true}
      /> */}

      </div>

    </div>
  )
}
