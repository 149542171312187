import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import React, { useMemo } from "react";
import useResponsiveFontSize from "./useResponsibleFontSize"

import "./style.css";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};
const StripeComponent = ({ onToken, clientsecret, user, setAlert ,startSpinner, stopSpinner}) => {



  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();



  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
     
    if (!stripe || !elements) {
      return;
    }

    startSpinner();
    const cardNumberElement = elements.getElement(CardNumberElement);
    const result = await stripe.confirmCardPayment(clientsecret, {
      payment_method: {
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: user.name,
          email: user.email,
        },
      },
      receipt_email: user.email,
    });
    stopSpinner();
    if (result.error) {
      // setAlert()
      setAlert({ type: "danger", msg: result.error.message });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        onToken(result.paymentIntent.id);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div id="stripecard" className="uk-card-body uk-background-muted">

        <div>
          <span class="uk-text uk-text-bold uk-text-large">
            Card Number
        </span>
          <CardNumberElement
            options={options}
            className="uk-stretch"
          />
        </div>

        <div class="uk-grid uk-child-width-1-2">
          <div>
          <span class="uk-text uk-text-bold uk-text-large">
              Expiry
        </span>
            <CardExpiryElement
              options={options}
            />
          </div>
          <div>
          <span class="uk-text uk-text-bold uk-text-large">
              CVC
        </span>
            <CardCvcElement
              options={options}
            />
          </div>
        </div>



       
      </div>
      <button
          className="uk-button uk-align-center uk-button-large uk-button-primary uk-icon"
          uk-icon="triangle-right"
          type="submit"
          disabled={!stripe}
        >
          Pay Now
        </button>
    </form>
  );
};


export default StripeComponent;
