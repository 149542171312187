import React, { useEffect, useContext } from 'react'
import { useGetBorrowingHistory } from '../../hooks/useGetBorrowingHistory'
import AccountContext from '../../context/accounts/accountContext';
import SpinnerContext from '../../context/spinner/spinnerContext';
import History from './History';
import Enums from '../../enums/Enums';
const BorrowingHistory = () => {
  const { history: borrowedHistory, getHistory: getBorrowedHistory, returnBook} = useGetBorrowingHistory();
  const { history: returnedHistory, getHistory: getReturnedHistory } = useGetBorrowingHistory();
  const accountContext = useContext(AccountContext);
  const {user} = accountContext;

  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  useEffect(() => {
    getHistories();
  }, [getBorrowedHistory, getReturnedHistory]);

  const getHistories= async()=>{
    await startSpinner();
    await getBorrowedHistory({status:'BORROWED'});
    await getReturnedHistory({status:'RETURNED'});
    await stopSpinner();
  }

  const onReturn = async (bookId, accountId) => {
    await returnBook(bookId, accountId);
    await getHistories();
  };
  return (
    <div> <div>
    {/* Lending History Section */}
    <hr className="uk-divider-icon" />
    <h2 className="uk-heading-line">
      <span>Currently Borrowed Books</span>
    </h2>

  
    <History
      data={borrowedHistory}
      status="BORROWED"
      showBookName={true}
      memberType={user.libraryMemberType}
      onReturn={onReturn}
      
    />
    <hr className="uk-divider-icon" />
    <h2 className="uk-heading-line">
      <span>Returned History</span>
    </h2>
    <History
      data={returnedHistory}
      status="RETURNED"
      showBookName={true}
      memberType={user.libraryMemberType}
     
      
    />
    
  </div></div>
  )
}

export default BorrowingHistory