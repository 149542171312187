import React, { useContext } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import AccountContext from "../../context/accounts/accountContext";
import IstavrityContext from "../../context/istavrity/istavrityContext";
import AlertContext from "../../context/alert/alertContext";
import { getImpersonated, getTnCApprovalDate, getToken } from "../../storage";


const NavbarList = () => {

  const history = useHistory();
  const accountContext = useContext(AccountContext);
  const istavrityContext = useContext(IstavrityContext);
  const alertContext = useContext(AlertContext);

  const { user, members, revokeImpersonization } = accountContext;
  const isLoggedOn = getToken() == null ? false : true;
  const impersonated = getImpersonated();
  console.log("impersonated", impersonated);


  const onClick = (e) => {
    accountContext.logout();
    istavrityContext.clearDeposits();
    alertContext.removeAlert();
  };

  const onRevoke = async (e) => {
    await revokeImpersonization();
    history.push("/home");
    //  document.getElementById("profile").click();
  };

  const fetchTnCApprovalDate = () => {
    return getTnCApprovalDate() == null ? false : true;
  }

  const getTnCAcceptedFlag = () => {
    try {
      const tncAccepted =
        fetchTnCApprovalDate() == 0
          ? false
          : true;
      return tncAccepted;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {!isLoggedOn || !fetchTnCApprovalDate() ? (
        <>
          <li>
            <NavLink  to="/" activeClassName="uk-active"></NavLink>
          </li>
        </>
      ) : (
        
        <>
          <li>
            <NavLink to="/home" activeClassName="uk-active" id="profile">
              Home
            </NavLink>
          </li>
          {user.role == 0 && (
            <li>
              <NavLink to="/deposit" activeClassName="uk-active">
                Deposit
              </NavLink>
            </li>
          )}

          
          <li>
            <NavLink to="/submissions" activeClassName="uk-active">
              Submissions
            </NavLink>
          </li>

          {user.role == 1 && (
            <li>
              <NavLink to="/administration/" activeClassName="uk-active">
                ADMINISTRATION
              </NavLink>
            </li>
          )}
          {user.role == 2 && (
            <li>
              <NavLink to="/dashboard" activeClassName="uk-active">
                DASHBOARD
              </NavLink>
            </li>
          )}
          {impersonated == "true" && (
            <li>
              <Link onClick={onRevoke}>REVOKE</Link>
            </li>
          )}
          {members.filter((m) => m.memberType == 3).length > 0 && (
            <li>
              <NavLink to="/initiatondetails" activeClassName="uk-active">
                RITWIK
              </NavLink>
            </li>
          )}
          <li>
          <NavLink to="/feedback" activeClassName="uk-active">
              CONTACT US
            </NavLink>
          </li>
          {user.role == 0 && (
          <li>
            <NavLink to="/profile" activeClassName="uk-active">
              Profile
            </NavLink>
          </li>
          
          )}
          <li>
          <NavLink to="/library" activeClassName="uk-active">
            Library(New)
          </NavLink>
        </li>
          <li>
            <Link to="" onClick={onClick}>
              LogOut
            </Link>
          </li>
        </>
        
      )}
    </>
  );
};

export default NavbarList;
