import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form"
import { TextBox } from "../../presentation"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import pranaamLogo from "../../../img/pranaam.jpg"


const validationSchema = yup.object().shape({
  email: yup.string().required("Email field is mandatory"),
  password: yup.string().required("Password is mandatory").min(8, "Password should be more than 7 chars"),
});

const LoginPage = ({
  onLogin,
  showRegistrationForm,
}) => {

  const { register, handleSubmit, errors } = useForm({ resolver: yupResolver(validationSchema) });
  const submitLogin = (data) => {
    onLogin(data);
  }
  return (
    <div className="uk-flex uk-background-muted uk-card uk-card-body uk-card-default uk-margin-top-l@s">


      <div className="uk-width-3-4  uk-flex-1 uk-background-primary uk-padding uk-visible@s">
        <h3 className="uk-text-xlarge uk-text-center uk-text-bolder uk-text-white">Joyguru</h3>
        <h3 className="uk-text-xlarge uk-text-center uk-text-bolder uk-text-white">Welcome to Istavrity Portal</h3>
        <img src={pranaamLogo} alt="istavrity-logo" className="uk-align-center uk-margin-top uk-margin-bottom uk-margin-large-top uk-margin-large-bottom uk-width-1-2 uk-height-1-2" />
      </div>
      <div className="uk-flex uk-width-1-2@s">

        <div
          id="formlogin"
          className="  uk-margin-xlarge-top uk-margin-xlarge-bottom   uk-width-2-3@s uk-align-center uk-background-muted"
        >
        
          <div className="uk-card-header uk-flex uk-flex-center">
            <h3 className="uk-card-title uk-text-lead uk-flex-center">Login</h3>
          </div>
          <div className="  ">
            <form onSubmit={handleSubmit(submitLogin)}>
              <div className="uk-width-1-1">

                <input
                  type="email"
                  placeholder="Email"
                  className="uk-input uk-padding-small uk-underline uk-loginInputs"
                  name="email"
                  ref={register}
                  errors={errors}
                  border="none"

                  icon="mail"

                />
              </div>
              <div className="uk-margin-top uk-width-1-1">

                <TextBox
                  className="uk-input uk-loginInputs"
                  type="password"
                  placeholder="Password"
                  name="password"
                  register={register}
                  errors={errors}
                />

              </div>
              <div className="uk-margin uk-text-right">
                <Link to="forgetpassword">
                  <span className="uk-text-center uk-margin-top uk-padding-top">Forgot your password?</span>
                </Link>
              </div>
              <div className="uk-margin-top">
                <button
                  type="submit"
                  className="uk-button uk-button-success uk-width-1-3@s uk-align-center "
                >
                  Login
                </button>
              </div>
              <div className="uk-margin-top-xlarge uk-padding uk-margin-bottom uk-text-center uk-text-primary">
                <label for="registerNewAccount">Not yet registered?</label>
                <button id="registerNewAccount" className="uk-button  uk-button-bold uk-button-warning uk-margin-top
                uk-text-wrap uk-padding-top uk-align-center
                "
                  onClick={showRegistrationForm}
                >
                  REGISTER
                </button>
                {/* <a>
                <span
                  className="uk-text-center uk-text-primary"
                  onClick={showRegistrationForm}
                >
                  Not yet registered?
                  <u>REGISTER</u>
                </span>
              </a> */}
              </div>
            
            
            </form>
            <div>
            <i>Need help with signing in or registration? </i>
            <a className="uk-link uk-link-secondary uk-text-primary uk-text-underline"
              href="https://forms.gle/skp4SBxc6qHdCE9g7"
              target='_blank'
            ><u>Contact us.</u></a>
            </div>
            
       
          </div>
        </div>
      </div>

    </div>
  );
};

export default LoginPage;
