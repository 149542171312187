import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest } from "../request/api_request";

export const useGetAccounts = () => {
    const initState = {
        accounts: [],
        loading: false,
        error: null,
    };

    const [state, setState] = useState(initState);
    const { accounts, loading, error } = state;

    const getAccounts = useCallback(async () => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendGetRequest({ endpoint: ENDPOINTS.LIBRARY_GET_ACCOUNTS_PER_STATE });
            setState({ accounts: res.data.data.accounts, loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    }, []); // `useCallback` memoizes `getBooks` and prevents unnecessary re-creation

    const getStateLibrarians = useCallback(async () => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendGetRequest({ endpoint: ENDPOINTS.LIBRARY_GET_STATE_LIBRARIANS });
            setState({ accounts: res.data.data, loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    });

    return { accounts, loading, error, getAccounts , getStateLibrarians};
};
