import React, { useContext, useEffect, useState } from "react";
import {useHistory} from 'react-router-dom'
import AccountContext from "../../../../context/accounts/accountContext";
import IstavrityContext from "../../../../context/istavrity/istavrityContext";
import SpinnerContext from "../../../../context/spinner/spinnerContext";
import AlertContext from "../../../../context/alert/alertContext";

import {
  Elements,

} from "@stripe/react-stripe-js";

import StripeComponent from "./StripeComponent";

const StripeCard = ({clientsecret,stripePromise,_id, payDeposits}) => {
  const history=useHistory();
  // useEffect(() => {
  //   const retrieveKeys=async()=>{
  //     startSpinner();
  //     await getClientKeys();
  //     stopSpinner();
  //   }
  //   retrieveKeys();
  // }, []);

  // const istavrityContext = useContext(IstavrityContext);
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const accountContext = useContext(AccountContext);
  const {
    user,
    //, setAlert
  } = accountContext;

  // const initialState = istavrityContext.current;
  // const [state, setState] = useState({
  //   ...initialState,
  //   stripePromise:"",
  //   clientsecret:""
  // });

  // let {
  //   sumTotal,
  //   _id,
  //   stripePromise,
  //   clientsecret,
  // } = state;

  //  const getClientKeys = async () => {
  //   var result = await istavrityContext.getStripeKeys({depositId: _id });
  //   setState({
  //     ...state,
  //     clientsecret: result.client_secret,
  //     stripePromise: loadStripe(result.pkey),
  //   // stripePromise:loadStripe("pk_test_51GsiP7Ke85zZDYbjoVm4EUlTeO16S07keDrJ5nLWihIjeFzIZ0l6RbC0m6848AR2R6C9DjBAHI8eorJ1GCB92RHr00e5x7KGyu")
  //   });
  // };


  const onToken = async (token) => {
    startSpinner();

    const payload = {
      depositId: _id,
      country: user.country,
      paymentref: token,
    };

    let res = await payDeposits(payload);
    history.push("/deposititemsummary/" + _id);
    stopSpinner();

    if (res.length > 0) {
      setAlert({ type: "danger", msg: res });
    }
  };

  return (
    <>
   
      {stripePromise != "" ? (
         <Elements stripe={stripePromise}>
         <StripeComponent
           onToken={onToken}
           clientsecret={clientsecret}
           user={user}
           setAlert={setAlert}
           startSpinner={startSpinner}
           stopSpinner={stopSpinner}
         />
       </Elements>
      ):(
        <p className="uk-text-large uk-text-center"> CARD COMPONENT LOADING...</p>
      )
    }
    </>
  );
};

export default StripeCard;
