import React, { useEffect } from "react";
import { Button, Dropdown } from "../presentation";

const Lend = ({
  getAccounts,
  accounts,
  onSelectedAccount,
  onClickSubmit,
  selectedAccount,
}) => {
  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const getOptions = () => {
    return accounts.reduce((acc, account) => {
      return {
        ...acc,
        [account._id]: `${account.name}(${account.fcnum}), Email: ${account.email}`,
      };
    }, {});
  };

  return (
    <>
      <div>
        <Dropdown
          className={"uk-select"}
          name="selectAccount"
          options={getOptions()}
          onChange={onSelectedAccount}
        />

        <Button
          title={"Lend"}
          onClick={() => onClickSubmit(selectedAccount)}
          name="Submit"
          label="Submit"
        />
      </div>
    </>
  );
};

export default Lend;
