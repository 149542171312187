import React from "react";
import CardComponentWithClickIcon from "../presentation/CardComponentWithClickIcon";

const SubmissionsCards = ({ submissions, onClick }) => {
  return (
    <div className="uk-hidden@s uk-margin-top">
      {submissions.map((submission) => (
        <CardComponentWithClickIcon>
        <div className="" onClick={() => onClick(submission)}>
          <div>
            <span>Family Code: {submission.fcnum}</span>
            <p className="uk-padding-left">Name: {submission.name}</p>
          </div>
          <div>
            <span>Deposit Date: {new Date(submission.depositDate).toLocaleDateString()}</span>
            <p className="uk-margin-top">Amount: {submission.sumTotal} {submission.currency}</p>
          </div>
          <div>
            <span>Status: {submission.status}</span>
            <p>Reference: {submission.refId}</p>
          </div>
        </div>
        </CardComponentWithClickIcon>
      ))}
    </div>
  );
};

export default SubmissionsCards;
