import { setDepositCodesInStorage } from "../../storage";
import {
  SET_DEPOSIT_ITEM,
  CLEAR_DEPOSITS,
  SET_CURRENT_SUBMISSION,
  SET_ERROR,
  GET_ALL_SUBMISISSIONS,
  SET_CURRENT_STATUS,
  SET_DEPOSIT_CODES,
  RESET_ALL_STATES,
} from "../types";

export default (state, { type, payload }) => {
  switch (type) {
    case SET_DEPOSIT_ITEM:
      return { ...state, currentSubmission: payload };

    case CLEAR_DEPOSITS:
      return {
        ...state,
        submissions: [],
        currentSubmission: {},
        deposit_codes: {},
      };

    case RESET_ALL_STATES:
      return {
        ...state,
        submissions: [],
        currentSubmission: {},
        deposit_codes: {},
      };

    case SET_CURRENT_SUBMISSION:
      return { ...state, currentSubmission: payload };
    case SET_ERROR:
      return { ...state, error: payload, test: "xyz" };

    case GET_ALL_SUBMISISSIONS:
      return { ...state, submissions: payload };
    case SET_CURRENT_STATUS:
      return {
        ...state,
        currentSubmission: { ...state.currentSubmission, status: payload },
      };

    case SET_DEPOSIT_CODES:
      setDepositCodesInStorage(payload);
      return { ...state, deposit_codes: payload };

    default:
      return state;
  }
};
