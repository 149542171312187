
import React from 'react'
import { Modal } from '../../presentation'
import { AddMember, UpdateEmail, ChangeAddress } from '../forms'
import { v4 as uuidv4 } from "uuid"
import { capitalize } from 'lodash'
import Enums from '../../../enums/Enums'


export const MemberProfile = ({ user, updateEmail, updateAddress, countries }) => {


    const getFormattedName = (item) => {

        switch (item) {
            case "ashramfcnum":
                var name = "ashram family code";
                break;
            case "fcnum":
                var name = "family code"
                break;
            case "systemcode":
                var name = "system code"
                break;
            default:
                var name = item
        }
        return name.toUpperCase();
    }

    const loadAdminProfile = (user) => {

        return (
            <div class="uk-container uk-margin-large-top uk-padding-top">
                <div class="uk-card uk-card-default uk-card-body  uk-align-center">
                    <h2 class="uk-card-title uk-text-center">Admin Profile</h2>
                    <hr></hr>
                    <div class="uk-grid-small uk-child-width-expand@s uk-text-center" uk-grid="true">
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: mail"></span>
                            <a className='uk-text-break'>{user.email}</a>
                        </div>
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: user"></span>
                            ADMIN
                        </div>
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: location"></span>
                            Country: Oceania
                        </div>
                       
                    </div>
                </div>
            </div>
        )

    }

    const loadMemberProfile = (user) => {
        return (
            <div class="uk-container uk-margin-top">
             
                <div class="uk-card uk-card-default uk-card-body uk-background-muted">

                    <h1 class="uk-card-title uk-text-center uk-text-primary uk-text-uppercase" id="user.name">{user.name}</h1>
                    <hr></hr>
                    <div class="uk-grid-small uk-child-width-expand@s uk-text-center uk-text-large" uk-grid="true">
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: mail"></span>
                            <a className='uk-text-break' id="user.email">{user.email}</a>
                        </div>
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: home" ></span>
                            <span id="user.address">{user.address} {user.country}</span>
                        </div>
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: credit-card" ></span>
                            Family Code: 
                            <span id="user.fcnum">{user.fcnum}</span>
                        </div>
                       {user.ashramfcnum && <div>
                            <span class="uk-margin-small-right" uk-icon="icon: lock" ></span>
                            Asharm Family Code: 
                            <span id="user.ashramfcnum">{user.ashramfcnum}</span>
                        </div>}
                        <div>
                            <span class="uk-margin-small-right" uk-icon="icon: receiver" ></span>
                            
                            <span id="user.phone">{user.phone}</span>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
    const getElements = () => {
        return (
            <div>

                {loadMemberProfile(user)}
                {/* { Object.keys(user).map((item) => (
                <div key={uuidv4()}>
                    <h5>{getFormattedName(item)}</h5>
                    <span>{user[item]}</span>
                    {/* { (["email"].includes(item)) &&
                        <Modal
                            Form={UpdateEmail}
                            onSubmit={updateEmail}
                            data={{ user }}
                            title={"EDIT " + item.toUpperCase()}
                            buttonText="Edit"
                        />
                    }
                    { (["address"].includes(item)) &&
                        <Modal
                            Form={ChangeAddress}
                            onSubmit={updateAddress}
                            title={"EDIT ADDRESS"}
                            buttonText="Edit"
                            data={{ user, countries }}
                        />
                    } }
                </div>
                
            ))} */}
            </div>
        )
    }
    return (
        <div>
            <div className="uk-card uk-card-body ">
                
                <div

                    className=""
                >
                    {user.role === Enums.ACCOUNT_TYPE.MEMBER && loadMemberProfile(user)} 
                    {user.role === Enums.ACCOUNT_TYPE.COUNTRY_ADMIN && loadAdminProfile(user)}

                    {/* <div>
                        <div>
                        <h5>ADDRESS
                        <Modal
                            Form={ChangeAddress}
                            onSubmit={updateAddress}
                            title={"EDIT ADDRESS"}
                            buttonText="Edit"
                            data={{user,countries}}
                        />
                        </h5>
                        
                        {formAddress()}
                        </div>
                       
                    </div> */}


                </div>
            </div>
        </div>
    )
}
