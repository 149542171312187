import React, { useEffect, useContext, useState, useMemo } from "react";
import IstavrityContext from "../../context/istavrity/istavrityContext";
import AccountContext from "../../context/accounts/accountContext";
import SpinnerContext from "../../context/spinner/spinnerContext";

import CsvDownload from "react-json-to-csv";
import Filters from "../submissions/Filters";
import SubmissionsTable from "../submissions/SubmissionsTable";
import SubmissionsCards from "../submissions/SubmissionsCards";

const Submissions = (props) => {
  const istavrityContext = useContext(IstavrityContext);
  const accountContext = useContext(AccountContext);
  const {
    submissions,
    getAllSubmissions,
    getTotalAmountOfAllSubmissions,
  } = istavrityContext;
  const { user ,getWhitelistedCountries} = accountContext;

  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;



  const getSelectedData = () => {
    let data = submissions.map(({ _id, ...rest }) => rest);

    return data;
  };
  const onClick = (submission) => {
    const { _id } = submission;
    //setCurrentSubmission(submission);
    props.history.push("/deposititemsummary/" + _id);
  };
  
  return (
    <div uk-grid="true" className=" uk-child-width-1-1 uk-child-width-1-2@s uk-padding-small ">
      {/* {FiltersWrapper({startSpinner,stopSpinner, getAllSubmissions,submissions,user, getWhitelistedCountries}) */}
      <Filters
        startSpinner={startSpinner}
        stopSpinner={stopSpinner}
        getAllSubmissions={getAllSubmissions}
        submissions={submissions}
        user={user}
        getWhitelistedCountries={getWhitelistedCountries} />
      {/* } */
      }
      <div>
        <div className="uk-card uk-card-body uk-card-small uk-background-muted">
          <h5 className="uk-text uk-text-bold uk-align-center">
            Total Amount:{getTotalAmountOfAllSubmissions()}
          </h5>
        </div>

        <div>
          <CsvDownload
            className="uk-button uk-button-primary uk-button-small uk-align-right"
            data={getSelectedData()}
            filename={"SubmissionReport.csv"}
          />
          <span className="uk-text-muted uk-text-large uk-align-left">
            Click on any record to view its details
          </span>
        </div>

        <SubmissionsTable submissions={submissions} onClick={onClick} />
        <SubmissionsCards submissions={submissions} onClick={onClick} />
      </div>
    </div>
  );
};

export default Submissions;
