import React, { useContext } from "react";

import SpinnerContext from "../../context/spinner/spinnerContext";

const SpinnerComponent = () => {
  const spinnerContext = useContext(SpinnerContext);
  const { spinner } = spinnerContext;

  return (
    <>
      {spinner && (
        <div className="uk-position-cover uk-overlay uk-overlay-default  ">
          <div className="uk-flex uk-flex-center">
            <span
              className="uk-padding-large uk-padding-remove-left"
              uk-spinner="ratio: 4.5"
            ></span>
          </div>
        </div>
      )}
    </>
  );
};

export default SpinnerComponent;
