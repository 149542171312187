import React, { useEffect } from "react";
import Enums from "../../enums/Enums";

const History = ({ data, status, showBookName, onReturn,memberType }) => {


  return (
    <div>
      <table className="uk-table uk-table-divider uk-custom-table uk-table-striped uk-table-responsive">
        <thead>
          <tr>
            {showBookName && <th>Book</th>}
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Issue Date</th>
            {memberType == Enums.LIBRARY_ACCOUNT_TYPE.ADMIN && <th>State</th>}
            {status === "BORROWED" && memberType == Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN? (
              <th> Mark as Returned</th>
            ) : (
              <th>Return Date</th>
            )}
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {showBookName &&    <td>{item.book.title}</td>}
                <td>{item.user.name}</td>
                <td>{item.user.phone}</td>
                <td>{item.user.email}</td>
                <td>{item.borrowDate}</td>
                {memberType == Enums.LIBRARY_ACCOUNT_TYPE.ADMIN && <td>{item.state}</td>}
                {status === "BORROWED"  && memberType == Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN? (
                  <td>
                    <button
                      className="uk-button uk-button-primary"
                      onClick={()=>onReturn(item.book._id,item.user._id)}
                    >
                      Return
                    </button>
                  </td>
                ) : (
                  <td>{item.returnDate}</td>
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

//Default props
History.defaultProps = {
  data: [],
  status: "BORROWED",
  showBookName: true,
  memberType: Enums.LIBRARY_ACCOUNT_TYPE.MEMBER
};

export default History;
