/* eslint-disable eqeqeq */
import React, { memo, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import {
  TextBox,
  RitwikSearchComponent,
  Dropdown,
  Button,
} from "../../presentation";
import ENUMS from "../../../enums/Enums";
//import * as data from "../../../ritwik";
import { ritvikList } from "../../../ritwik";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name field is mandatory"),
  memberType: yup
    .string()
    .notOneOf(["----Select Member Type----"], "Please select a valid value"),
  // saID: yup.string().required("Ritwik details are mandatory for initiated members"),
  saID: yup
  .string()
  .when("memberType", {
    is: (value) => +value > 0,
    then: yup.string().required("Ritwik details are mandatory for initiated members"),
    otherwise: yup.string().notRequired(),
  }),
});



export const AddMember = memo(({ addMember, setMember, onResetForm }) => {

  const {ritwik={}}= setMember;
  useEffect(() => {
    if (Object.entries(setMember).length > 1) {
      const { _id, name, memberref, memberType, ritwik } = setMember;
      setValue("name", name);
      setValue("_id", _id);
      setValue("memberref", memberref);
      setValue("memberType", memberType);
      memberType > 0 && setValue("saID", ritwik.saID);
    }
  }, [setMember]);

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    watch,
    setValue,
    trigger,
    reset,
    clearErrors

  } = useForm({ resolver: yupResolver(validationSchema) });

  const setRitwik = (ritwikId) => {
    setValue("saID", ritwikId);
    clearErrors("saID");
  }

  const onSubmit = (input) => {
    const inputlist = ritvikList;
    const { searchRitwik, memberType, saID, _id, ...rest } = input;
    const ritwik = inputlist.find((l) => l.saID == saID) || {};

    if (+memberType === -1) {
      window.alert("Select Member type");
      return;
    }
    if (+memberType > 0 && Object.entries(ritwik).length == 0) {
      window.alert("Ritwik is mandatory");
      return;
    }
    addMember({ ...rest, ritwik, memberType, _id });
    reset();
    onResetForm();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="addMemberForm">
        <TextBox
          placeholder="Ritwik Id"
          name="saID"
          register={register}
          errors={errors}
          hidden={true}
        />
        <div>
          <TextBox
            placeholder="Member Id"
            name="_id"
            register={register}
            errors={errors}
            hidden={true}
          />
        </div>
        {Object.entries(setMember).length > 1 && (
          <div>
            <span>Member Ref:</span>
            <TextBox
              placeholder="Member Ref"
              name="memberref"
              register={register}
              errors={errors}
              disabled={true}
            />
          </div>
        )}

        <TextBox
          placeholder="Enter Member Name"
          name="name"
          register={register}
          required={true}
          errors={errors}
          disabled={false}
          defaultValue=""
        />
        <Dropdown
          register={register}
          title="----Select Member Type----"
          options={ENUMS.MEMBER_TYPES}
          name="memberType"
          errors={errors}
          required={true}
        />

        {+watch("memberType") > 0 && (
          <div className="uk-margin-top">
            <RitwikSearchComponent
              onChange={setRitwik}
              selectedRitwik={ritwik}
             
            />
          </div>)}

        <div className="uk-flex">
          <Button
            className="uk-button uk-button-success"
            type="submit"
            label="SUBMIT"
          />
          <Button
            className="uk-button uk-background-co-1 uk-margin-left"
            type="button"
            label="Clear"
            onClick={() => {
              reset();
              setValue("name", "");
              onResetForm();
            }}
          />
        </div>
      </form>
    </div>
  );
});

AddMember.defaultProps = {
  setMember: {},
};
