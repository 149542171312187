import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../presentation";
import Enums from "../../enums/Enums";
import Lend from "./Lend";
import ReturnBook from "./ReturnBook";

import { useGetAccounts } from "../../hooks/useGetAccounts";
import { useLendBook } from "../../hooks/useLendBook";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import AccountContext from "../../context/accounts/accountContext";
import { useBooks } from "../../context/BooksContext";
import { useGetBorrowingHistory } from "../../hooks/useGetBorrowingHistory";

import History from "./History";
import BookInterests from "./BookInterests";
import StateInventory from "./StateInventory";
import Transfer from "./Transfer";
import AddEditBook from "./AddEditBook";
import { useAddUpdateBook } from "../../hooks/useAddUpdateBook";

const BookPage = (props) => {
  const id = props.match.params.id;
  console.log("BookPage", id);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const accountContext = useContext(AccountContext);
  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext;
  const { user, getWhitelistedCountries } = accountContext;
  const { fetchBooks, book, loading: bookLoading } = useBooks();
 


  const {
    history: lendingHistory,
    getHistory,
    returnBook,
    loading: historyLoading,
  } = useGetBorrowingHistory();

  const {
    msg: addUpdateMsg,
    loading: addUpdateLoading,
    errors: addUpdateErrors,
    editBook
  } = useAddUpdateBook()

  const { accounts, loading, error, getAccounts } = useGetAccounts();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const {
    msg,
    loading: lendLoading,
    error: lendError,
    errors,
    submitLendRequest,
    transferBook,
  } = useLendBook();

  useEffect(() => {
    console.log("BookPage useEffect");
    const getBookById = async (id) => {
      await startSpinner();
      await fetchBooks(id);
      await stopSpinner();
    };
    getBookById(id);
  }, [fetchBooks]);



  useEffect(() => {
    console.log("BookPage useEffect lendingHistory");
    //console.log(props)
    getHistory({ book: id });
  }, [getHistory]);

  const onReturn = async (bookId, accountId) => {
    await startSpinner();
    await returnBook(bookId, accountId);
    await fetchBooks(bookId);
    await getHistory({ book: id });
    await stopSpinner();
  };

  const [section, setSection] = useState(5);
  const sections = {
    NOTHING: 0,
    TRANSFER: 1,
    LEND: 2,
    RETURN: 3,
    HISTORY: 4,
    INTERESTS: 5,
    EDIT: 6,
  };

 

  useEffect(() => {
    console.log("BookPage useEffect lendError", lendError);
    console.log("Updated msg:", msg, "Updated errors:", errors);
    if (errors.length) {
      setAlert({ type: "danger", msg: errors[0].msg });
    } else {
      setAlert({ type: "success", msg });
    }
  }, [msg, errors]);

  

  const onSubmitLendRequest = async (userId) => {
    await startSpinner();
    await submitLendRequest({ bookId: id, userId });
    await fetchBooks(id);
    await getHistory({ book: id });
    await stopSpinner();
    setSection(sections.HISTORY);
  };

  const onSelectedAccount = (e) => {
    console.log("selectedAccount", e.target.name, e.target.value);
    setSelectedAccount(e.target.value);
  };

  const initiateBookTransfer = async ({ quantity, region, country }) => {
    await startSpinner();
    await transferBook([
      { bookId: id, noOfCopies: +quantity, state: region, country },
    ]);

    await fetchBooks(id);
    await stopSpinner();
  };

  const onEditBook = async (payload)=>{
    await startSpinner();
    await editBook({...payload, _id:id});
    await fetchBooks(id);
    await stopSpinner();
    setSection(sections.NOTHING);

  }
  const showSection = () => {
    switch (section) {
      case sections.TRANSFER:
        return (
          <Transfer
            allowedCountries={getWhitelistedCountries()}
            bookId={id}
            transferBook={initiateBookTransfer}
          />
        );
      case sections.LEND:
        return (
          <Lend
            getAccounts={getAccounts}
            accounts={accounts}
            //loading={loading}
            onSelectedAccount={onSelectedAccount}
            // submitLendRequest={submitLendRequest}
            onClickSubmit={onSubmitLendRequest}
            selectedAccount={selectedAccount}
          />
        );
      case sections.RETURN:
        return <ReturnBook />;
      case sections.HISTORY:
        return (
          <>
            {lendingHistory.length ? (
              <>
                <hr className="uk-divider-icon" />
                <h2 className="uk-heading-line">
                  <span>Current Borrowing</span>
                </h2>

                <History
                  data={lendingHistory.filter(
                    (results) => results.status === "BORROWED"
                  )}
                  status="BORROWED"
                  showBookName={false}
                  memberType={Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN}
                  onReturn={onReturn}
                />

                <hr className="uk-divider-icon" />
                <h2 className="uk-heading-line">
                  <span>HISTORY</span>
                </h2>
                <History
                  data={lendingHistory.filter(
                    (results) => results.status === "RETURNED"
                  )}
                  status="RETURNED"
                  showBookName={false}
                  memberType={Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN}
                />
              </>
            ) : (
              <div>No records found...</div>
            )}
          </>
        );

      case sections.INTERESTS:
        return (
          <div>
            <BookInterests
              book={id}
              submitLendRequest={onSubmitLendRequest}
              totalCopies={book.totalCopies}
              isInterestPage={false}
            />
          </div>
        );

      case sections.EDIT:
        return <AddEditBook
        title={book.title}
        description={book.description}
        image={book.image}
        totalCopies={book.totalCopies}
        language={book.language}
        onSubmit={onEditBook}
         />;
      default:
        return null;
    }
  };

  //const [isInterested, setIsInterested] = useState(false);

  // const handleButtonClick = async () => {
  //   setIsInterested(!isInterested);
  //   await expressInterest({ bookId: id, accountId: user._id });
  // };

  return (
    <div>
      {bookLoading && <div>Loading...</div>}
      <div className="uk-container uk-margin-large-top">
        {/* Book Details Section */}
        <div className="uk-grid-large uk-flex-middle" uk-grid="true">
          {/* Left Side: Large Cover Picture */}
          <div className="uk-width-1-3@s uk-flex uk-flex-center">
            <div
              className="uk-cover-container"
              style={{ width: "300px", height: "400px" }}
            >
              <img
                src={
                  book.image ||
                  "https://nnpdev.wustl.edu/img/BookCovers/genericBookCover.jpg"
                }
                alt="Book Cover"
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
              />
            </div>
          </div>

          {/* Right Side: Book Information */}
          <div className="uk-width-2-3@s">
            <h1 className="uk-heading-medium uk-text-capitalize uk-text-primary">
              {book.title || "Unknown Title"}
            </h1>
            <p className="uk-text-lead">
              {book.description || "No description available."}
            </p>
            <p className="uk-text-lead">
              Language:{book.language}
            </p>
            <p>
              <strong>Stock Available:</strong> {book.totalCopies || 0}
            </p>

            {/* Buttons */}
            <div className="uk-margin ">
              <Button
                className={"uk-button uk-button-primary uk-margin-small-right "}
                label="Transfer"
                show={
                  user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.ADMIN
                }
                onClick={() => setSection(sections.TRANSFER)}
              />
              <Button
                className={"uk-button uk-button-primary uk-margin-small-right "}
                label="Edit"
                show={
                  user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.ADMIN
                }
                onClick={() => setSection(sections.EDIT)}
              />
              <Button
                className={
                  "uk-button uk-button-secondary uk-margin-small-right" +
                  (section === sections.LEND
                    ? " uk-box-shadow-bottom uk-border-circle "
                    : "")
                }
                label="Lend"
                show={
                  user.libraryMemberType ===
                  Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN
                }
                onClick={() => setSection(sections.LEND)}
                disabled={!book.totalCopies}
              />

              <Button
                className={
                  "uk-button uk-button-success uk-margin-small-right" +
                  (section === sections.HISTORY
                    ? " uk-box-shadow-bottom uk-border-circle "
                    : "")
                }
                label="History"
                show={
                  user.libraryMemberType ===
                  Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN
                }
                onClick={() => setSection(sections.HISTORY)}
              />
              <Button
                className={
                  "uk-button uk-button-warning uk-margin-small-right" +
                  (section === sections.INTERESTS
                    ? " uk-box-shadow-bottom uk-border-circle "
                    : "")
                }
                label="Interests"
                show={
                  user.libraryMemberType ===
                  Enums.LIBRARY_ACCOUNT_TYPE.STATE_ADMIN
                }
                onClick={() => setSection(sections.INTERESTS)}
              />
            </div>
          </div>
        </div>
        {showSection()}
        {user.libraryMemberType === Enums.LIBRARY_ACCOUNT_TYPE.ADMIN && (
          <StateInventory data={book.stateInventory || []} />
        )}
      </div>
    </div>
  );
};

export default BookPage;
