import React from 'react'

const SubmissionsTable = ({submissions, onClick}) => {
  return (
    <div className='uk-visible@s'>
          <table
          id="submissiontable"
          className="uk-table uk-table-hover uk-margin-small"
        >
          <thead>
            <tr>
              <th>FC</th>
              <th>Name</th>

              <th>Date</th>

              <th>Type</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <>
                <tr onClick={() => onClick(submission)}>
                  <td>{submission.fcnum}</td>
                  <td>{submission.name}</td>
                  <td>
                    { new Date(submission.depositDate).toLocaleDateString()}
                  </td>
                  <td className="uk-text-capitalize">{submission.type}</td>
                  <td>{submission.status=="Done"?"Completed":submission.status}</td>
                  <td>{submission.sumTotal} {submission.currency}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
    </div>
  )
}

export default SubmissionsTable