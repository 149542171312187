import React, { useState } from "react";
import ENUMS from "../../../enums/Enums";
import "react-phone-number-input/style.css";
import { getPrivacyPolicy } from "../statichtml/privacyPolicyHtml";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useForm } from "react-hook-form";
import {
  TextBox,
  CountrySelector,
  PhoneInputField,
  RitwikSearchComponent,
  Dropdown,
  Recaptcha,
  Checkbox,
  Button,
  Address,
} from "../../presentation";

import { ritvikList } from "../../../ritwik";

const phoneSchema = yup
  .string()
  .test("phone", "Invalid phone number", (value) => {
    if (!value) {
      return true; // the value is blank, fail the validation
    }
    return isValidPhoneNumber(value);
  });

const validationSchema = yup.object().shape({
  name: yup.string().required("Name field is mandatory"),
  email: yup.string().required("Email field is mandatory"),
  country: yup.string().required("Country is mandatory"),
  password: yup
    .string()
    .required("Password is mandatory")
    .min(8, "Password should be more than 7 chars"),
  password2: yup
    .string()
    .required("Please re-enter the password")
    .min(8, "Confirm Password should be more than 7 chars")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  //phone: yup.string().required("Phone number is mandatory"),
  phone: phoneSchema.required("Phone number is mandatory"),
  // saID: yup.string().notOneOf([undefined, ""], "Please enter Ritwik Details"),
  memberType: yup
    .string()
    .notOneOf(["----Select Member Type----"], "Please select a valid value"),
  terms: yup.boolean().isTrue("You must agree to the privacy policy"),
  address: yup.object({
    line1: yup.string().required("Address Line One is mandatory"),

    state: yup.string().required("State / Province is mandatory"),
    postcode: yup.string().required("Postcode is mandatory"),
    suburb: yup.string().required("City/Suburb is mandatory"),
  }),
  saID: yup.string().when("memberType", {
    is: (value) => +value > 0,
    then: yup
      .string()
      .required("Ritwik details are mandatory for initiated members"),
    otherwise: yup.string().notRequired(),
  }),
});

const RegisterPage = ({ onRegister, showLoginForm, countries }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    setError,
    trigger,
    validate,
    clearErrors,
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const initialState = {
    memberSection: true,
    addressSection: false,
    finalSection: false,
  };

  const [section, setSection] = useState(initialState);

  const { memberSection, addressSection, finalSection } = section;

  const showSection = async (sectionName) => {
    switch (sectionName) {
      case "memberSection":
        setSection({
          memberSection: true,
          addressSection: false,
          finalSection: false,
        });
        break;
      case "addressSection":
        // clearErrors("saID");
        const fieldValidations = [
          "name",
          "email",
          "memberType",
          "phone",
          "saID",
        ];
        // (+watch("memberType") > 0) ? ["name", "email", "memberType", "phone", "saID"] :
        //   ["name", "email", "memberType", "phone"];

        const isValid = await trigger(fieldValidations);
        isValid &&
          setSection({
            memberSection: false,
            addressSection: true,
            finalSection: false,
          });
        break;
      case "finalSection":
        const isValidAddress = await trigger([
          "country",
          "address.line1",
          "address.state",
          "address.postcode",
          "address.suburb",
        ]);
        isValidAddress &&
          setSection({
            memberSection: false,
            addressSection: false,
            finalSection: true,
          });
        break;
      default:
        setSection({
          memberSection: true,
          addressSection: false,
          finalSection: false,
        });
    }
  };

  const navigationButton = ({ nextSectionName, previousSectionName }) => {
    return (
      <div class="uk-button-group uk-flex uk-flex-center">
        {previousSectionName && (
          <div className="uk-align-left">
            <button
              class="uk-button uk-button-secondary "
              type="button"
              onClick={() => showSection(previousSectionName)}
            >
              Back
            </button>
          </div>
        )}
        {nextSectionName && (
          <div className="uk-align-right">
            <button
              class="uk-button uk-button-success"
              type="button"
              id="nextButton"
              onClick={() => showSection(nextSectionName)}
            >
              NEXT
            </button>
          </div>
        )}
      </div>
    );
  };

  const setRitwik = (ritwikId) => {
    setValue("saID", ritwikId);
  };

  const inputlist = ritvikList;
  const submitRegistrationForm = (data, submit = true) => {
    const { saID, searchRitwik, password2, name, memberType, ...rest } = data;
    const ritwik = inputlist.find((l) => l.saID == saID);
    const payload = { ...rest, name, ritwik, memberType };
    submit && onRegister(payload);
  };
  return (
    <div>
      <div className="uk-flex uk-background-muted uk-card uk-card-body uk-card-default uk-margin-top-l@s">
        <div className="uk-width-3-4  uk-flex-1 uk-background-primary uk-padding uk-visible@m uk-flex uk-align-center">
          <div className="uk-grid uk-child-width-1-1" uk-grid="true">
            <div>
              <h3 className="uk-text-xxxlarge uk-text-center uk-text-bolder uk-text-white uk-align-center">
                REGISTER
              </h3>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-width-1-2@s">
          <div
            id="formregister"
            className="uk-card uk-card-small  uk-background-white uk-margin-left@s uk-width-5-6@l uk-align-center uk-background-muted"
          >
            <div class="uk-card-header uk-flex uk-flex-center"></div>
            <div className=" uk-card-body uk-text-large">
              <form onSubmit={handleSubmit(submitRegistrationForm)}>
                <div className="uk-margin-top uk-margin-bottom uk-text-right">
                  <a>
                    <span
                      className="uk-text-center uk-text-primary"
                      onClick={showLoginForm}
                    >
                      Back to Login
                    </span>
                  </a>
                </div>
                <div
                  id="memberDetails"
                  className={!memberSection && "uk-hidden"}
                >
                  <span className="uk-header uk-text-bold uk-text-middlle">
                    MEMBER DETAILS:
                  </span>
                  <hr></hr>
                  <TextBox
                    type="text"
                    placeholder="Name *"
                    name="name"
                    register={register}
                    errors={errors}
                  />

                  <TextBox
                    type="email"
                    placeholder="Email *"
                    name="email"
                    register={register}
                    errors={errors}
                  />
                  <PhoneInputField
                    placeholder="Enter phone number"
                    defaultCountry="AU"
                    control={control}
                    name="phone"
                    errors={errors}
                  />
                  <TextBox
                    placeholder="Ashram family code(Optional)"
                    name="ashramfcnum"
                    register={register}
                    errors={errors}
                  />
                  <Dropdown
                    register={register}
                    title="----Select Member Type----"
                    options={ENUMS.MEMBER_TYPES}
                    name="memberType"
                    errors={errors}
                    required={true}
                  />

                  {+watch("memberType") > 0 && (
                    <div className="uk-margin-bottom uk-margin-top">
                      <RitwikSearchComponent onChange={setRitwik} />
                      <TextBox
                        placeholder="Ritwik Id"
                        name="saID"
                        register={register}
                        errors={errors}
                        hidden={true}
                      />
                    </div>
                  )}
                  <div></div>

                  <hr></hr>

                  {navigationButton({
                    nextSectionName: "addressSection",
                  })}
                </div>
                <div
                  className={!addressSection && "uk-hidden"}
                  id="addressDetails"
                >
                  <span className="uk-text-bold uk-text-middlle ">
                    ADDRESS:
                  </span>
                  <hr></hr>
                  <CountrySelector
                    countries={countries}
                    control={control}
                    errors={errors}
                  />

                  <Address
                    control={control}
                    country={watch("country")}
                    register={register}
                    errors={errors}
                  />
                  {navigationButton({
                    nextSectionName: "finalSection",
                    previousSectionName: "memberSection",
                  })}
                </div>

                <div id="finalSection" className={!finalSection && "uk-hidden"}>
                  <div className="uk-margin-top">
                    <span className="uk-header uk-text-bold uk-text-middlle">
                      PASSWORD:
                    </span>
                    <hr></hr>
                    <TextBox
                      type="password"
                      placeholder="Password- minimum 8 charcters *"
                      name="password"
                      register={register}
                      required="true"
                      errors={errors}
                    />
                    <TextBox
                      type="password"
                      placeholder="Confirm *"
                      name="password2"
                      register={register}
                      required="true"
                      errors={errors}
                    />
                  </div>
                  <div className="uk-margin-top">
                    <Recaptcha control={control} />

                    <div className="uk-text-center">
                      <Checkbox
                        register={register}
                        label="I have read and understood the privacy policy"
                        name="terms"
                        required="true"
                        labelIsLink="true"
                        toggleTarget="target: #terms"
                        errors={errors}
                      />
                    </div>
                    <div id="terms" uk-modal="true">
                      <div class="uk-modal-dialog uk-modal-body">
                        <h3 class="uk-modal-title uk-text-center">
                          PRIVACY POLICY
                        </h3>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: getPrivacyPolicy(),
                          }}
                        />
                        <Button
                          className="uk-modal-close uk-background-primary uk-button-primary uk-align-center"
                          type="button"
                          onClick={() => setValue("terms", true)}
                          label=" I UNDERSTAND"
                        />
                      </div>
                    </div>
                    <Button
                      id="registerbtn"
                      type="submit"
                      className="uk-button uk-button-success uk-width-1-3@s uk-align-center "
                      label="Register"
                    />
                  </div>
                  <hr></hr>

                  {navigationButton({
                    previousSectionName: "addressSection",
                  })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
