import React, { useEffect, useContext, useState, useRef } from "react";
import AccountContext from "../../../context/accounts/accountContext";
import SpinnerContext from "../../../context/spinner/spinnerContext";
import { useHistory } from 'react-router-dom'
import {v4} from 'uuid'
import { getSearchResults } from "../../../storage";

const UserAccounts = () => {

  const searchRef = useRef();
  const history = useHistory();
  const accountContext = useContext(AccountContext);
  const spinnerContext = useContext(SpinnerContext);
  const { getAccounts } = accountContext;
  const { startSpinner, stopSpinner } = spinnerContext;

  const initialState = {
    accounts: getSearchResults() || [],
    searchresults: [],
    country: "Australia"
  };
  const [state, setState] = useState(initialState);
  const { accounts, searchresults } = state;

  useEffect(() => {
    const fetchResults = async () => {
      startSpinner();
      let res = await getAccounts();
      setState({ ...state, accounts: res.data.data.accounts ,searchresults:res.data.data.accounts});
      stopSpinner();
    };
    accounts.length == 0 ? fetchResults() : setState({ ...state, searchresults: accounts })
  }, []);


  const filterResults = () => {
    let result = []
    let search = searchRef.current.value
    if (search.length == 0) { result = accounts }
    else {
      result = accounts.filter((a) =>
        a.fcnum.toLowerCase().includes(search.toLowerCase())
        ||
        a.name.toLowerCase().includes(search.toLowerCase())
        ||
        a.email.toLowerCase().includes(search.toLowerCase())
        ||
        a.country.toLowerCase().includes(search.toLowerCase())
        || a.address.state.toLowerCase().includes(search.toLowerCase())
      )
    }
    setState({ ...state, searchresults: result })
  }
  const onChange = (e) => {
    filterResults();
  };
  const onSelect = (account) => {
    history.push({
      pathname: "/memberprofile",
      state: account
    });
  };
  return (
    <div className="">
      <div className="uk-card uk-card-body uk-card-default">
        <h6 className="uk-title uk-text-center">FILTER</h6>
        <span className="uk-text uk-text-meta">
          Search by family code OR member name OR email
        </span>
        <div className="uk-flex">
          <input
            type="text"
            name="search"
            ref={searchRef}
            onChange={onChange}
            placeholder="Enter Text"
          />
        </div>
      </div>
      <span className="uk-text uk-text-lead uk-float-left uk-padding-small">
        Number of Accounts :{searchresults.length}
      </span>
      <span className="uk-text uk-text-meta uk-float-right uk-padding-small">
        Click on any record to view details
      </span>
      <table
        id="allaccounts"
        className="uk-table uk-table-small uk-table-hover uk-table-stripped uk-table-center uk-table-divider"
      >
        <thead>
          <tr>
            <th>FAMILY CODE</th>
            <th>NAME</th>
            <th className="uk-visible@s">EMAIL</th>
            <th>country</th>
            <th>state</th>
          </tr>
        </thead>
        <tbody>
          {searchresults.map((account) => (
            <tr key={v4()} onClick={() => onSelect(account)}>
              <td>{account.fcnum}</td>
              <td>{account.name}</td>
              <td className="uk-visible@s">{account.email}</td>
              <td >{account.country}</td>
              <td >{account.address.state}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserAccounts;
