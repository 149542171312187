
import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest, sendPostRequest } from "../request/api_request";

export const useLibraryInterest = () => {
    const initState = {
        msg: "",
        loading: false,
        error: null,
        errors: [],
        interests:[]
    };

    const [state, setState] = useState(initState);
    const { msg, loading, error , errors,interests} = state;

    const expressInterest = useCallback(async ({bookId,accountId, _id}) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_EXPRESS_INTEREST ,
                payload:{
                    bookId,
                    accountId,
                    _id
                }
            });
            console.log("Response1", res.data.data.msg);
            setState({...state, msg: res.data.data.msg, loading: false, error: null, errors: [] });
        } catch (err) {
            console.log("Error", err.response.data);
            setState((prev) => ({ ...prev, loading: false, error: err?.message||'', errors: err.response.data.errors }));
        }
    }, []); 

    const getInterests = useCallback(async (payload) => {
        
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_INTERESTS,
            payload
             });
            setState({ interests: res.data.data, loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    }, []);

    return { msg, loading, error,errors,interests, expressInterest,getInterests };
};
