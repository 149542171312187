import React, { useEffect } from 'react'
import { useGetIstavrityReports } from '../../hooks/useGetIstavrityReports'

const IstavrityReport = ({state}) => {
    
    const {details, loading, error, getReport} = useGetIstavrityReports();
    useEffect(() => {
        getReport();
    },[]);

    const filterDeposits = (details) => {
        if(state){
            return details.filter((detail) => detail.state === state);
        }
        return details;
    }

    const displayDeposits = (deposits) => {
        if(!deposits.length){
            return <p>No deposits</p>
        }

        return deposits.map((deposit) => {
            return (
                <div>
                   
                    <p>{deposit?.month}-{deposit?.year}</p>
                   
                </div>
            )
        }
        )
    };

    if(loading){
        return <div>Loading...</div>
    }

  return (
    <div>
        <table className='uk-custom-table uk-table uk-table-striped'>
                <thead>
                    <tr>
                        <th>Fcnum</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>State</th>
                        <th>Last Payment Details</th>
                    </tr>
                </thead>
       
            
                <tbody>
                {filterDeposits(details).map((detail) => (
                    <tr>
                        <td>{detail.fcnum}</td>
                        <td>{detail.name}</td>
                        <td>{detail.phone}</td>
                        <td>{detail.state}</td>
                        <td>{displayDeposits(detail.deposits)}</td>
                    </tr>
        ))}
                </tbody>
            </table>
            
        
    </div>
  )
}

// Default props
IstavrityReport.defaultProps = {
    state: null
}

export default IstavrityReport