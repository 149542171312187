import React, { useState, useContext} from "react";
import AlertContext from "../../context/alert/alertContext";
import SpinnerContext from "../../context/spinner/spinnerContext";
import axios from "axios";
import { Link } from "react-router-dom";
import ENDPOINTS from "../../enums/endpoints";

const ForgetPassword = () => {

  
  const alertContext = useContext(AlertContext);
  const spinnerContext = useContext(SpinnerContext);



  const initialState = {
    email: "",
  };
  const [state, setState] = useState(initialState);
  const { email } = state;
  const onChange = (e) => {
    setState({ ...state, email: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    spinnerContext.startSpinner();
    const config = {
      headers: {
        "Content-Type": "application.json",
      },
    };
    try {
      let resp = await axios.get(
        process.env.REACT_APP_API_URL + ENDPOINTS.FORGET_PASSWORD+`?email=${email}`,
        config
      );
      spinnerContext.stopSpinner();
      alertContext.setAlert({ type: "success", msg: resp.data.data.msg });
      setState(initialState);
    } catch (error) {
      alertContext.setAlert({ type: "danger", msg: error.response.data.errors[0] });
      setState(initialState);
      spinnerContext.stopSpinner();
    }
  };

 
  return (
    <div className="uk-card uk-card-body uk-padding-large  uk-margin-top uk-width-2-3@s uk-container uk-background-muted">
      <form className="uk-form uk-padding-small" onSubmit={onSubmit}>
        <span className="uk-text  uk-text-large ">Enter your email:</span>
        <input
          className="uk-text"
          type="email"
          value={email}
          placeholder="email"
          onChange={onChange}
          required
        />
        <br></br>
        <button
          type="submit"
          className="uk-button  uk-button-primary uk-width-1-4 uk-align-center"
        >
          Submit
        </button>
        <Link className="uk-align-right uk-text-bold" to="/login">
          <u>Back to login</u>
        </Link>
      </form>
    </div>
  );
};

export default ForgetPassword;
