import React from "react";
import PaymentAustralia from "./Australia/AustraliaCheckoutForm";


export const PaymentHome = (props) => {


  const renderCountry = () => {
    return <PaymentAustralia history={props.history} />;
  };
  return <div>{renderCountry()}</div>;
};

