import { kebabCase } from "lodash";
import React from "react";
import ENUMS from "../../../enums/Enums";
import { Button } from "../../presentation";

const MemberItem = ({ member, onDelete, onEdit, impersonated }) => {
  const { name, ritwik = {}, memberType, memberref } = member;
  let type = ENUMS.MEMBER_TYPES[`${memberType}`];
  return (
    <div
      className="uk-card uk-card-primary uk-card-body uk-card-small uk-margin-small uk-background-cb-2  uk-card-hover"
      id={kebabCase(name)}
    >
      <div className="uk-text-break">
        <span className="uk-text-left uk-text-bold uk-text-secondary ">
          Name:{" "}
        </span>
        <span
          className="uk-text-capitalize uk-text-white uk-text-large"
          data-test-id="member.name"
        >
          {name}
        </span>
        <br></br>
        <span className="uk-text-left uk-text-bold uk-text-secondary ">
          Ritwik Name:{" "}
        </span>
        <span
          className="uk-text-capitalize uk-text-white uk-text-large"
          data-test-id="ritwik.name"
        >
          {memberType > 0 ? ritwik.fullName.toLowerCase() : "Not Applicable"}
        </span>
        <br></br>
        <span className="uk-text-left uk-text-bold uk-text-secondary ">
          Member Type:{" "}
        </span>
        <span
          className="uk-text-capitalize uk-text-white uk-text-large"
          data-test-id="memberType"
        >
          {type}
        </span>
        <br></br>
        <span className="uk-text-left uk-text-bold uk-text-secondary ">
          Member Ref:{" "}
        </span>
        <span
          className="uk-text-capitalize uk-text-white uk-text-large"
          data-test-id="memberRef"
        >
          {memberref}
        </span>
        <br></br>
        <br></br>
        <Button
          className="uk-button uk-button-bold uk-button-danger uk-text-bolder"
          type="button"
          onClick={() => onDelete(member._id)}
          label="DELETE"
          show={impersonated}
        />
        {/* <button
          className="uk-button uk-button-bold uk-button-danger uk-text-bolder"
          type="button"
          onClick={() => onDelete(member._id)}
        >
          DELETE
        </button> */}
        <button
          className="uk-button uk-button-bold uk-background-co-1 uk-text-bolder uk-margin-left"
          type="button"
          onClick={() => onEdit(member)}
        >
          EDIT
        </button>
      </div>
    </div>
  );
};

MemberItem.defaultProps = {
  member: [],
  onDelete: () => {},
};

export default MemberItem;
