import React from 'react'

export const TextBox = (props) => {
const {type,placeholder,defaultValue,name,register ,hidden, required,className,errors,disabled,onChange}=props
    return (
       <>
        <p className="uk-text uk-text-danger uk-margin-top">{errors[name] && errors[name].message}</p>
            <input
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={className}
                name={name}
                id={name}
                ref={register}
                hidden={hidden}
                disabled={disabled}
                onChange={onChange}
                
                {...props}
                
            />
            
        </>
    )
}

TextBox.defaultProps={
    className:"uk-input uk-padding-small uk-input-large@s uk-margin-bottom",
    required:false,
    hidden:false,
    type:"text",
    defaultValue:"",
    placeholder:"Enter",
    errors:{},
    disabled:false,
   // register:"",
    onChange:()=>{}
}