import { capitalize } from 'lodash'
import React from 'react'
import * as ELEMENTS from "../../presentation"
import BackButton from './BackButton'

const SelectDepositDate = ({setDepositDate, type, goBack}) => {

    const getBlankDiv = () => {
         return (
            <div className='uk-width-1-4'> </div>
         )
    }
    return (
        <div className='uk-container uk-align-center uk-margin-large-top'>
             <BackButton
                onClick={goBack}
            />
            <div class="uk-grid-match uk-width-2-3@s   uk-text-center uk-margin " uk-grid="true">
                {getBlankDiv()}
                <div className='uk-margin-top uk-width-2-3 uk-align-center'>
                <h2>Select {capitalize(type)} date</h2>
                <ELEMENTS.DatePickerComponent
                    name="depositDate"
                    onChange={setDepositDate}
                    required
                />
                </div>
           
                {getBlankDiv()}
            </div>
            
        </div>

    )
}

export default SelectDepositDate