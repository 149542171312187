import React, { Fragment } from "react";
import "./uikit.css";
import { ModalProvider } from "react-modal-hook";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { LoginRegister, AgreeTnC } from "./components/auth";

import Navbar from "./components/layout/Navbar";
import Alert from "./components/layout/AlertContainer";
import { Footer } from "./components/layout/Footer";
import { Home, OnePageProfile } from "./components/home";
import { Summary } from "./components/deposit_summary";
import { Deposit } from "./components/deposits";
import { PaymentHome } from "./components/payment";
import AccountState from "./context/accounts/AccountState";

import IstavrityState from "./context/istavrity/IstavrityState";
import PrivateRoute from "./components/routing/PrivateRoute";
import Submissions from "./components/pages/Submissions";

import Spinner from "./components/layout/Spinner";
import AlertState from "./context/alert/AlertState";
import Events from "./components/events/Events";
import Event from "./components/events/event";
import SpinnerState from "./context/spinner/SpinnerState";
import ResetPassword from "./components/pages/RestPassword";
import ForgetPassword from "./components/pages/ForgetPassword";
import Dashboard from "./components/superadmin/Dashboard";

import Administration from "./components/admin/Administration";
import InitiatonDetails from "./components/ritwik/InitiationDetails";
import ContributionDetails from "./components/admin/ContributionDetails";
import Feedback from "./components/pages/Feedback";
import Profile from "./components/pages/Profile";
import Library from "./components/library/Index";
import Catelogue from "./components/library/Catelogue";
import Transfer from "./components/library/Transfer";
import Lend from "./components/library/Lend";
import BookPage from "./components/library/BookPage";
import {BooksProvider} from "./context/BooksContext";
import BorrowingHistory from "./components/library/BorrowingHistory";
import AddBook from "./components/library/AddBook";
import BookInterests from "./components/library/BookInterests";


require("dotenv").config();

function App() {
  return (
    <ModalProvider>
      <AccountState>
        <IstavrityState>
          <AlertState>
            <SpinnerState>
              <BooksProvider>
              <Router>
                <Fragment>
                  <Navbar />

                  <div className="uk-inline uk-flex" id="content-wrap">
                    <div className="uk-container uk-width-1-1 uk-width-3-4@s">
                      <Alert />
                      <Switch>
                        <Route exact path="/" component={LoginRegister} />
                        <Route path="/index.html" component={LoginRegister} />
                        <Route exact path="/login" component={LoginRegister} />
                        <Route
                          exact
                          path="/resetpassword/:token"
                          component={ResetPassword}
                        />
                        <Route
                          exact
                          path="/forgetpassword"
                          component={ForgetPassword}
                        />
                        <Route exact path="/feedback" component={Feedback} />
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          component={Dashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/deposit"
                          component={Deposit}
                        />
                        <PrivateRoute exact path="/home" component={Home} />
                        <PrivateRoute
                          exact
                          path="/memberprofile"
                          component={OnePageProfile}
                        />
                        <PrivateRoute
                          exact
                          path="/deposititemsummary/:_id"
                          component={Summary}
                        />
                        <PrivateRoute
                          exact
                          path="/submissions"
                          component={Submissions}
                        />
                        <PrivateRoute
                          exact
                          path="/payment"
                          component={PaymentHome}
                        />
                        <PrivateRoute exact path="/Events" component={Events} />
                        <PrivateRoute exact path="/event/" component={Event} />
                        <PrivateRoute
                          exact
                          path="/administration/"
                          component={Administration}
                        />

                        <PrivateRoute
                          exact
                          path="/initiatondetails/"
                          component={InitiatonDetails}
                        />
                        <PrivateRoute
                          exact
                          path="/showcontributiondetails"
                          component={ContributionDetails}
                        />
                        <PrivateRoute
                          exact
                          path="/termsandconditions"
                          component={AgreeTnC}
                        />
                        <PrivateRoute
                          exact
                          path="/profile"
                          component={Profile}
                        />
                         <PrivateRoute
                          exact
                          path="/library"
                          component={Library}
                        />
                        <PrivateRoute
                          exact
                          path="/library/catelogue"
                          component={Catelogue}
                        />
                        <PrivateRoute
                          exact
                          path="/library/transfer"
                          component={Transfer}
                        />
                        <PrivateRoute
                          exact
                          path="/library/lend"
                          component={Lend}
                        />
                        <PrivateRoute
                          exact
                          path="/book/:id"
                          component={BookPage}
                        />
                        <PrivateRoute
                          exact
                          path="/library/history"
                          component={BorrowingHistory}
                        />
                        <PrivateRoute
                          exact
                          path="/library/add"
                          component={AddBook}
                        />
                        <PrivateRoute
                          exact
                          path="/library/interests"
                          component={BookInterests}
                        />
                      </Switch>
                    </div>

                    <Spinner />
                  </div>
                  <Footer />
                </Fragment>
              </Router>
              </BooksProvider>
            </SpinnerState>
          </AlertState>
        </IstavrityState>
      </AccountState>
    </ModalProvider>
  );
}

export default App;
