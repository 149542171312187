import React from 'react'
import * as ELEMENTS from "../../presentation"
import Enums from "../../../enums/Enums"
import BackButton from './BackButton'
import { capitalize } from 'lodash'


export const DepositForm = ({ state, onChange, onSubmitDeposit, onAddValues, goBack, retriveLastDeposit, currency ,codes}) => {
    const { type, subType, depositDetails, depositDate } = state;

    return (
        <div className="uk-container">
            <BackButton
                onClick={goBack}
            />

            <div className='uk-margin-top uk-card uk-card-body'>
                <h3 className='uk-align-left '>Deposit date: <span className='uk-text-primary'>{depositDate}</span></h3>
                <h3 className='uk-align-right@s ' id="currency">CURRENCY: <span className='uk-text-primary'>{currency}</span></h3>
            </div>

            <div className='uk-margin-top'>
                {type === Enums.DEPOSIT_TYPE.istavrity &&
                    <button type="button" className=" uk-button uk-text-bold uk-text-xlarge@s uk-align-right uk-underline"
                        onClick={retriveLastDeposit}

                    ><span uk-icon="icon: pull; ratio: 2"></span>Load Previous Deposit </button>
                }
            </div>
            <br></br>
            <hr className="uk-divider-icon" />
            <div className='uk-margin-top'>
                <h5 className='uk-text-center uk-text-bold uk-padding-top'>Enter {capitalize(type)} Details</h5>
            </div>
            {type === Enums.DEPOSIT_TYPE.arghya &&
            
            (

           <>
                <table  id="codedesc" className='uk-table uk-table-striped'>
                     <caption>Description of Codes</caption>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {codes.map(code =>(
                            <tr>
                                <td>{code.key}</td>
                                <td>{code.desc}</td>
                            </tr>
                        ))}
                    </tbody>
                    
                </table>
                <hr class="uk-divider-icon"></hr>
                </>
                 )}

            <form onSubmit={onSubmitDeposit} className='uk-margin-top' >
                <div className="uk-background-white">

                    {Object.entries(depositDetails).map(([index, deposit], idx) =>
                        <>
                            <div key={idx} className="uk-margin-top">
                                <div className=" uk-card-body uk-border uk-padding-small uk-box-shadow-large uk-background-white">
                                    <ul id="acccontent" uk-accordion="true" >
                                        <li class="uk-open">
                                            <span id={`x-${deposit.memberId}`} className="uk-accordion-title uk-text uk-text-large uk-text-bold"
                                            >
                                                {" "}
                                                {deposit.name}
                                            </span>
                                            <div className="uk-accordion-content uk-background-muted">
                                                <div className="uk-grid uk-padding-small ">
                                                    {Object.entries(deposit.amount).map(([key, value]) =>

                                                    (

                                                        <div className="uk-margin-top">
                                                            <label className="uk-margin-bottom">{key}</label>
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    className='uk-input uk-margin-top'
                                                                    name={key + "-" + index}
                                                                    value={(depositDetails[`${index}`])['amount'][`${key}`]}
                                                                    onChange={e => onAddValues({ value: e.target.value, idx, key })}
                                                                    defaultValue="0"
                                                                    disabled={deposit.disabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <hr className="uk-divider-icon"></hr>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <ELEMENTS.Button
                    label="CONFIRM"
                    type="submit"
                    className="uk-button uk-button-bold uk-button-success uk-button-xlarge uk-align-center uk-margin-large"
                    uk-icon="arrow-right"
                />
            </form>
        </div>
    )
}
