import React, { useContext, useEffect } from "react";
import AccountContext from "../../context/accounts/accountContext";
import { useAddUpdateBook } from "../../hooks/useAddUpdateBook";
import SpinnerContext from "../../context/spinner/spinnerContext";
import AlertContext from "../../context/alert/alertContext";
import AddEditBook from "./AddEditBook";

const AddBook = () => {
  const accountContext = useContext(AccountContext);
  const { user } = accountContext;
  const { msg, errors, addBook } = useAddUpdateBook();
  const spinnerContext = useContext(SpinnerContext);
  const alertContext = useContext(AlertContext);
  const { startSpinner, stopSpinner } = spinnerContext;
  const { setAlert } = alertContext;

  useEffect(() => {
    if (errors.length) {
          setAlert({ msg: errors[0], type: "danger" });
       } else {
          setAlert({ msg: msg, type: "success" });
       }
  },[msg,errors]);

  const addNewBook = async (payload) => {
    await startSpinner();
    await addBook({ ...payload, createdBy: user._id });
  
   
    await stopSpinner();
  };
  return <div>
    <AddEditBook
    onSubmit={addNewBook}

/>
  </div>;
};

export default AddBook;
