import axios from "axios";
import ENUMS from "../enums/Enums";
import { getToken } from "../storage";

const make_api_request = ((method) => async ({ authenticated = true, endpoint, payload, responseType = "JSON" }) => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        },
        responseType
    };
    const url = process.env.REACT_APP_API_URL + endpoint;
    (authenticated) && (config.headers["x-auth-token"] = getToken());
    try {
        switch (method) {
            case (ENUMS.API_REQUEST_TYPE.GET):
                return await axios.get(url, config);

            case (ENUMS.API_REQUEST_TYPE.PUT):
                return await axios.put(url, payload, config);
            case (ENUMS.API_REQUEST_TYPE.POST):
                return await axios.post(url, payload, config);
            case (ENUMS.API_REQUEST_TYPE.DELETE):
                return await axios.delete(url, config);
            default:
                throw new Error("Invalid request type");
        }
    } catch (error) {
       
       if(!error.response){
        cleanUpWithAlert("Something wrong,Please contact admin");
        return;
       }
        switch(error.response.status){
            case 401:
                cleanUpWithAlert("Session expired,Please login again");
                break;
            case 500: case 502:
                cleanUpWithAlert("Some error occurred,Please login and try again");
                break;
            case 400:
                throw error;
            case "failed":
                cleanUpWithAlert("Something wrong,Please contact admin");
                break;
            default:
                cleanUpWithAlert("Something wrong,Please contact admin");
                break;
        }
       

    }
})

const cleanUpWithAlert=(msg)=>{
    console.log("erroe msg",msg)
    window.alert(msg);
    window.location.href = "/";
}

export const sendGetRequest = make_api_request(ENUMS.API_REQUEST_TYPE.GET);
export const sendPostRequest = make_api_request(ENUMS.API_REQUEST_TYPE.POST);
export const sendPutRequest = make_api_request(ENUMS.API_REQUEST_TYPE.PUT);
export const sendDeleteRequest = make_api_request(ENUMS.API_REQUEST_TYPE.DELETE);