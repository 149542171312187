import React, { useContext } from 'react'
import AccountContext from "../../context/accounts/accountContext";
export const Footer = () => {
    const accountContext = useContext(AccountContext);
    const { user } = accountContext;
    const isLoggedOn = Object.entries(user).length > 0 ? true : false;
  
    return (
        <>
            {isLoggedOn &&
                <footer id="footer">

                    <div className="uk-card uk-card-default uk-card-body uk-background-cb-2 uk-margin-top"

                    >  <div className="uk-flex@s uk-flex-between">
                            <div>
                                <span>For any queries or issues, kindly navigate to the '<b>CONTACT US</b>' section or alternatively, you may send an email to <b>satsangoceania@gmail.com</b>.</span>
                            </div>
                        </div>

                    </div>
                </footer>
            }
        </>
    )
}
