import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form"
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerFormComponent = ({ name,control,onChange }) => {
    return (
        <div>
            <Controller
                name={name}
                control={control}
                defaultValue={false}
                rules={{required:true}}
                onChange={onChange}
                render={({ onChange, value }) =>
                    <DatePicker
                        className="uk-input"
                       dateFormat='dd/MM/yyyy'
                        selected={value}
                        maxDate={new Date()}
                        onChange={onChange}
                        showYearDropdown={true}
                       
                    />}
            />
        </div>
    )
}

DatePickerFormComponent.defaultProps={
    name:"inputdate",
    control:()=>{}
}

