import React, { ReactDOM } from "react";
import { v4 as uuidv4 } from 'uuid';
import {kebabCase} from 'lodash';

const DepositSummary = ({ deposit }) => {
  const { name, ritwik, amount, memberId } = deposit;

  let values = Object.values(amount);

  const getValue = (value) => {
    if (typeof value === "string" || typeof value === "number") {
      return <td key={uuidv4()}>{value}</td>;
    } else {
      var res = "";
      value.map((val) => {
        res =
          res + "" + val.desc + "(" + val.code + "):" + val.item_amount + "|";
      });
      return <td key={res}>{res}</td>;
    }
  };

  return (
    <tr id={kebabCase(name)} key={uuidv4()}>
      <td >{name}</td>
      <td>{ritwik}</td>
      {values.map((value) => getValue(value))}
    </tr>
  );
};

export default DepositSummary;
