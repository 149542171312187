import { useState, useCallback } from 'react';
import ENDPOINTS from "../enums/endpoints";
import { sendGetRequest, sendPostRequest } from "../request/api_request";

export const useGetBorrowingHistory = () => {
    const initState = {
        history: [],
        loading: false,
        error: null,
    };

    const [state, setState] = useState(initState);
    const { history, loading, error } = state;

    const getHistory = useCallback(async (queryParams) => {
        const params = new URLSearchParams(queryParams).toString();
        console.log("params",params)
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendGetRequest({ endpoint: ENDPOINTS.LIBRARY_BORROWING_HISTORY+`?${params}` });
            setState({ history: res.data.data, loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    }, []); // `useCallback` memoizes `getBooks` and prevents unnecessary re-creation

    const returnBook = async (bookId,accountId) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const res = await sendPostRequest({ endpoint: ENDPOINTS.LIBRARY_RETURN_BOOK,
                payload:{
                    bookId,
                    accountId
                }
            });
            //);
            setState({ ...state,loading: false, error: null });
        } catch (err) {
            setState((prev) => ({ ...prev, loading: false, error: err.message }));
        }
    }

    return { history, loading, error, getHistory, returnBook };
};
