import React, { useReducer } from "react";
import AccountContext from "./accountContext";
import accountReducer from "./accountReducer";
import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from "../../request/api_request"
import ENDPOINTS from '../../enums/endpoints'
import {
  LOGOUT,
  GET_ACCOUNTS,
  GET_MEMBERS,
  IMPERSONATE_USER,
  REVOKE_IMPERSONATION,
  UPDATE_EMAIL,
  GET_LOGGED_ON_USER
} from "../types";
import { getImpersonatedBy, getImpersonatedToken, getMembersFromStorage, getUserFromStorage, setTnCApprovalDate, setToken, setUserInStorage } from "../../storage";

const AccountState = (props) => {

  let initialState = {
    isloggedOn: false,
    members: [],
    accounts: [],
    impersonated: false,
    impersonatedBy: "",
    user:{}
  };

  var user = getUserFromStorage();
 
  //Object.keys(JSON.parse(user)).length && (initialState.isloggedOn = true);
  if (!user) {
    initialState.user = {
      fcnum: 0,
      country: "",
      email: "",
      role: 2,
      _id: "",
      address: {},
      systemcode: 0,
    };
  } else {
    initialState.isloggedOn = true;
    initialState.user = getUserFromStorage();
  }
   getMembersFromStorage().length  &&
  (initialState.members = getMembersFromStorage());

  const [state, dispatch] = useReducer(accountReducer, initialState);
  const addMember = async (member) => {
    try {
      await sendPutRequest({
        endpoint: ENDPOINTS.ADD_MEMBER,
        payload: member
      })
      getMembers();
      return { type: "success", msg: "Member added/updated succesfully" };
    } catch (error) {
      return error.response.data.message;
    }
  };

  const registerUser = async (payload) => {

    try {
      const res = await sendPostRequest({
        endpoint: ENDPOINTS.REGISTER,
        authenticated: false,
        payload,
      })
      return { type: "success", msg: res.data.data.msg };
    } catch (error) {
      return { type: "danger", msg: error.response.data.errors[0] };
    }

  };

  //LOGIN
  const login = async (user) => {

    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.LOGIN,
        payload: user,
        authenticated: false
      })
      console.log('Responsse login:', JSON.stringify(res.data))
      // dispatch({ type: LOGIN, payload: res.data });
      setToken(res.data.data.token);
      setTnCApprovalDate(res.data.data.tncApprovalDate);
      return {
        type: "success",
        msg: "",
      };
    } catch (error) {
      return {
        type: "danger",
        msg: error.response.data.errors[0],
      };
    }
  };

  //Agree TnC

  const agreeTnC = async () => {
    await sendGetRequest({
      endpoint: ENDPOINTS.AGREE_TnC,
    })
  }

  //Update Addresss
  const updateAddress = async (payload) => {

    try {
      let res = await sendPostRequest({
        endpoint: "/api/user/changeaddress",
        payload
      })
      return {
        type: "success",
        msg: "Address has been succesfully updated. Please login again",
      };
    } catch (error) {
      return {
        type: "danger",
        msg: error.response.data.msg,
      };
    }
  };

  const deleteMember = async (payload) => {
    try {
      await sendDeleteRequest({
        endpoint: ENDPOINTS.DELETE_MEMBER + '/' + payload,
        authenticated: true
      })
      getMembers();
      return { type: "success", msg: "Member deleted succesfully" };
    } catch (error) {
      // window.alert(error.response.data.msg);
      return { type: "danger", msg: error.response.data.msg };
    }
  }

  //IMPERSONATE
  const impersonate = async (email) => {
    const payload = {
      email,
    };

    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.IMPERSONATE_USER,
        payload
      })
      dispatch({
        type: IMPERSONATE_USER,
        payload: res.data.data,
        admin: state.user.email,
      });
      return {
        type: "success",
        msg: "",
      };

    } catch (error) {
      return {
        type: "danger",
        msg: error.response.data.msg,
      };
    }
  };

  const revokeImpersonization = async () => {
    const payload = {
      adminEmail: getImpersonatedBy(),
      impersonatedToken: getImpersonatedToken(),
    };
    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.REVOKE_IMPERSONATION,
        payload
      })
      dispatch({ type: REVOKE_IMPERSONATION, payload: res.data.data });
      return {
        type: "success",
        msg: "",
      };
    } catch (error) {
      return {
        type: "danger",
        msg: error.response.data.errors[0],
      };
    }
  };

  const setLibraryMemberType = async(fcnum,type) => {
    let res = await sendPostRequest({
      endpoint: ENDPOINTS.LIBRARY_SET_MEMBER_TYPE,
      payload:{fcnum,libraryMemberType:type}
    })
    return res.data.data;
  };

  //LOGOUT
  const logout = () => {
    dispatch({ type: LOGOUT, payload: {} });
  };

  const getLoggedOnUserDetails = async () => {
    let res = await sendGetRequest({
      endpoint: ENDPOINTS.GET_LOGGED_ON_USER_DETAILS
    })
    dispatch({ type: GET_LOGGED_ON_USER, payload: res.data.data })
  }
  //Get Members
  const getMembers = async () => {
    try {
      let res = await sendGetRequest({
        endpoint: ENDPOINTS.GET_MEMBERS
      })
      dispatch({ type: GET_MEMBERS, payload: res.data.data });
    } catch (error) {
      logout();
    }
  };

  const getAccounts = async () => {

    return await sendGetRequest({
      endpoint: ENDPOINTS.GET_ALL_USERS 
    });
  };

  const updateEmail = async (email) => {
    const payload = {
      email,
      _id: state.user._id,
    };
    try {
      let res = await sendPostRequest({
        endpoint: "/api/user/updateemailid",
        payload
      })
      dispatch({ type: UPDATE_EMAIL, payload: email });
      setUserInStorage({
        ...state.user, email  })
      return { type: "success", msg: res.data.msg };
    } catch (error) {
      return { type: "danger", msg: error.response.data.msg };
    }
  };

  const addInitiatedMember = async (payload) => {

    try {
      let res = await sendPostRequest({
        endpoint: ENDPOINTS.ADD_INITIATION_DETAILS,
        payload
      })
      return { type: "success", msg: "Member added succesfully" };
    } catch (error) {
      return { type: "danger", msg: error.response.data.msg };
    }
  };

  const getInitiatedMembers = async (id) => {
    try {
      let res = await sendGetRequest({
        endpoint: ENDPOINTS.GET_INITIATION_DETAILS + '/' + id
      })
      return res.data.data;
    } catch (error) {
      return { type: "danger", msg: error.response.data.msg };
    }
  };

  const getKarmiList = async (country) => {
    try {
      let res = await sendGetRequest({
        endpoint: ENDPOINTS.GET_KARMILIST+'/'+country
      })
      return { type: "success", msg: res.data.data };
    } catch (error) {
      return { type: "danger", msg: error.response.data.msg };
    }
  };

  const getKarmi3yrReport = async (id) => {

    try {
      let res = await sendGetRequest({
        endpoint: ENDPOINTS.GET_THREE_YEAR_KARMI_REPORT +'/' + id,
        responseType: "blob"
      })
      return res;
    } catch (error) {
      return { type: "danger", msg: error.response.data.msg };
    }
  };

  const inviteCountryAdmin = async (payload) => {
    return await sendPostRequest({
      endpoint: "/api/user/registeradmin",
      payload
    })
  }
  const getWhitelistedCountries = () => {
    return ["AU", "NZ"];
  };

  const changeAccountStatus = async (payload) => {
    return await sendPostRequest({
      endpoint: ENDPOINTS.TOGGLE_ACCOUNT_LOCK,
      payload
    })
  }

  const getUserById = async (_id) => {
    const response = await sendGetRequest({
      endpoint: ENDPOINTS.GET_ACCOUNT_BYID +'/'+ _id
    })
    return response.data.data;
  }

  const updateUser = async (payload) => {
    try {
      const res= await sendPostRequest({
        endpoint: ENDPOINTS.UPDATE_USER,
        payload
      })
      dispatch({ type: GET_LOGGED_ON_USER, payload: res.data.data })
      return true
    } catch (error) {
      return false
    }
    
  }

  return (
    <AccountContext.Provider
      value={{
        user: state.user,
        members: state.members,
        registerUser,
        addMember,
        getMembers,
        isloggedOn: state.isloggedOn,
        login,
        logout,
        getAccounts,
        accounts: state.accounts,
        impersonate,
        revokeImpersonization,
        impersonated: state.impersonated,
        updateEmail,
        addInitiatedMember,
        getInitiatedMembers,
        getKarmiList,
        getKarmi3yrReport,
        updateAddress,
        getWhitelistedCountries,
        agreeTnC,
        getLoggedOnUserDetails,
        deleteMember,
        inviteCountryAdmin,
        changeAccountStatus,
        getUserById,
        updateUser,
        setLibraryMemberType
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export default AccountState;
